import { DragEndHandlerParams } from '../types'

export function getCorrespondingCardTitle(tabIndex: number, type: 'finance' | 'signup'): string {
  if (type === 'finance') {
    switch (tabIndex) {
      case 0:
        return 'Doações do mês'
      case 1:
        return 'Doações de hoje'
      case 2:
        return 'Recorrências do mês'
      default:
        return ''
    }
  }

  if (type === 'signup') {
    switch (tabIndex) {
      case 0:
        return 'Cadastros do mês'
      case 1:
        return 'Cadastros de hoje'
      case 2:
        return 'Leads do mês'
      default:
        return ''
    }
  }

  return ''
}

export function handleDragEnd({ _, info, activeTab, setActiveTab, slides }: DragEndHandlerParams) {
  const threshold = 50
  if (info.offset.x > threshold && activeTab > 0) {
    setActiveTab(activeTab - 1)
  } else if (info.offset.x < -threshold && activeTab < slides.length - 1) {
    setActiveTab(activeTab + 1)
  }
}
