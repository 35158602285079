import { Col, Image, Row } from 'antd'
import BaseCard from '../../../../../../../../components/card/BaseCard'

interface ObservacoesFamiliaProps {
  foto?: string
  observacao?: string
}

const { Meta } = BaseCard

export function ObservacoesFamilia({ foto, observacao }: ObservacoesFamiliaProps) {
  return (
    <>
      {foto || observacao ? (
        <Row justify="center">
          <Col xs={24}>
            <BaseCard
              cover={
                <>
                  {foto && (
                    <Row justify="center">
                      <Image width={350} height={350} src={foto} />
                    </Row>
                  )}
                </>
              }
              size="small"
            >
              {observacao && <Meta title={<h3>Observações</h3>} description={<p>{observacao || ' - '}</p>} />}
            </BaseCard>
          </Col>
        </Row>
      ) : (
        <BaseCard>
          <p>Sem informações cadastradas para essa família.</p>
        </BaseCard>
      )}
    </>
  )
}
