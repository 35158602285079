import React from 'react';

// Gradients.js

export const QUERY_KEYS = {
  ANONYMOUS_OR_REGISTERED: 'anonymousOrRegistered',
  BENFEITORES_RANKING: 'benfeitoresRanking',
  BY_METHOD_PAYMENT_DATA: 'byMethodPaymentData',
  VALUE_DONATIONS: 'valueDonations',
  DONATIONS_BY_WEEK_DAY: 'donationsByWeekDay',
  CURRENT_MONTH_DONATIONS: 'currentMonthDonations',
  DONATIONS_BY_CAMPAIGN: 'donationsByCampaign',
  DONATIONS_BY_HOUR: 'donationsByHour',
  LATEST_DONATIONS: 'latestDonations',
  MONTH_TO_MONTH_DONATIONS: 'monthToMonthDonations',
  SINGLE_OR_RECURRING: 'singleOrRecurring',
};

export const paymentMethodOptions = [
  { value: '', label: 'Todos' },
  { value: '1', label: 'Cartão de Crédito' },
  { value: '2', label: 'Pix' },
  { value: '3', label: 'Boleto' },
];

export const statusOptions = [
  { value: '', label: 'Todos' },
  { value: '1', label: 'Aprovado' },
  { value: '2', label: 'Negado' },
];

export const latestDonationsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Dia e hora',
    dataIndex: 'dia_hora',
    key: 'dia_hora',
  },
  {
    title: 'Benfeitor',
    dataIndex: 'benfeitor',
    key: 'benfeitor',
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    key: 'cpf',
  },
  {
    title: 'Campanha',
    dataIndex: 'campanha',
    key: 'campanha',
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
    key: 'valor',
  },
  {
    title: 'Forma de Pagamento',
    dataIndex: 'forma_pagamento',
    key: 'forma_pagamento',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

export const benfeitoresRankingColumn = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Benfeitor',
    dataIndex: 'benfeitor',
    key: 'benfeitor',
    with: 50,
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    key: 'cpf',
  },
  {
    title: 'Doações',
    dataIndex: 'doacoes',
    key: 'doacoes',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

export const Gradients = () => (
  <defs>
    <linearGradient id="greenGradientType1" x1="0" y1="0" x2="0" y2="1">
      <stop offset="30%" stopColor="var(--finance-chart-color)" />
      <stop offset="100%" stopColor="var(--finance-chart-color-fade-stop)" />
    </linearGradient>
    <linearGradient id="greenGradientOnHover" x1="0" y1="0" x2="0" y2="1">
      <stop offset="30%" stopColor="var(--finance-chart-color-hover)" />
      <stop offset="100%" stopColor="var(--finance-chart-color-hover-end)" />
    </linearGradient>
    <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="var(--finance-chart-color)" stopOpacity={1} />
      <stop offset="100%" stopColor="var(--finance-chart-color)" stopOpacity={0} />
    </linearGradient>
  </defs>
);
