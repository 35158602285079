/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Col, Image, Row, Skeleton, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CampaignCardMoreInfo, CampaingCard, CampaingCardButton, Description, StyledNavLink } from './styles';
import { Cross } from '@phosphor-icons/react';
import { DataService } from '../../../../../../lib/dataService';

interface CampaignCardProps {
  id?: string;
  titulo?: string;
  imagem_campanha?: string;
  descricao?: string;
  objetivo?: string;
  wrapDesc?: boolean;
  isCampaignsLoading?: boolean;
}

export const CampaignCard = ({
  id,
  titulo,
  imagem_campanha,
  descricao,
  objetivo,
  wrapDesc,
  isCampaignsLoading,
}: CampaignCardProps) => {
  return (
    <CampaingCard
      hoverable
      cover={
        imagem_campanha ? (
          <Image preview={{ mask: <p>Ver mais</p> }} alt={titulo} src={imagem_campanha} />
        ) : (
          <Skeleton.Image active={isCampaignsLoading} />
        )
      }
    >
      {isCampaignsLoading ? (
        <Row justify="center">
          <Skeleton style={{ minHeight: 200 }} paragraph={{ rows: 4 }} />

          <Row>
            <Skeleton.Button active style={{ width: '10rem', marginBottom: '1rem' }} />
          </Row>
        </Row>
      ) : (
        <NavLink
          to={{
            pathname: `/benfeitor/campanha/${id}`,
            state: 'info',
          }}
        >
          <Card.Meta
            title={titulo}
            description={
              <StyledNavLink
                className={
                  (objetivo === 'cadastro' && 'cadastro_campanha') || (objetivo === 'doacao' && 'doacao_campanha')
                }
                to={{
                  pathname: `/benfeitor/campanha/${id}`,
                  state: 'info',
                }}
              >
                <Description wrapper={wrapDesc}>{isCampaignsLoading ? <Skeleton /> : descricao}</Description>
                <div>
                  <NavLink
                    to={{
                      pathname: `/benfeitor/campanha/${id}`,
                      state: 'donation',
                    }}
                  >
                    <CampaingCardButton disabled={isCampaignsLoading} type="primary" block>
                      <Cross size={20} /> {objetivo === 'cadastro' ? 'FAZER CADASTRO' : 'FAZER DOAÇÃO'}
                    </CampaingCardButton>
                  </NavLink>
                  {!isCampaignsLoading && <CampaignCardMoreInfo>Mais informações</CampaignCardMoreInfo>}
                </div>
              </StyledNavLink>
            }
          />
        </NavLink>
      )}
    </CampaingCard>
  );
};
