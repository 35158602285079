import { ChartCard } from '@/container/admin/components/Cards/ChartCard'
import { RootState } from '@/redux/rootReducers'
import { useSelector } from 'react-redux'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { useMessagesByUserType } from '../../hooks/use-messages-by-user-type'

const COLORS = ['#FF6B00', '#FF9248', '#FFB37E']

export function MessagesByUserType() {
  const { data, isLoading } = useMessagesByUserType()
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  return (
    <ChartCard title="Mensagens por Tipo de Usuário" loading={isLoading}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            label={{
              fill: isDark ? '#E1E1E1' : '#000'
            }}
          >
            {data?.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: isDark ? '#1E1E1E' : '#fff',
              border: `1px solid ${isDark ? '#333333' : '#f0f0f0'}`,
              color: isDark ? '#E1E1E1' : '#000'
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartCard>
  )
} 