import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetListarDoacoesRecebidasProps {
  fk_familia_agape_id: number
  filters?: {
    queryString?: string
    page?: number
    per_page?: number
  }
}

interface GetListarDoacoesRecebidasResponse {
  doacoes_recebidas: {
    dia_horario: string
    fk_doacao_agape_id: number
    fk_instancia_acao_agape_id: number
    nome_acao: string
    recibos: string[]
  }[]
  page: number
  total: number
}
export function useGetListarDoacoesRecebidas({ fk_familia_agape_id, filters }: GetListarDoacoesRecebidasProps) {
  const doacoesRecebidas = useQuery({
    queryKey: ['LISTAR_DOACOES_RECEBIDAS', fk_familia_agape_id, filters],
    queryFn: async () => {
      const response = await DataService.get<GetListarDoacoesRecebidasResponse>(
        `/agape/listar-doacoes-recebidas/${fk_familia_agape_id}?${filters?.queryString ?? ''}`,
      )

      return response.data
    },
  })

  return doacoesRecebidas
}
