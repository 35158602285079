import logosImage from '../../images/logos.svg'
import { Button, Form, Input, Row } from 'antd'
import { Container } from '../create-group-video/styles'
import { GoldenHeader } from '../components/golden-header/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useResetPassword } from '../../../admin/hooks/users/Mutations/useResetPassword'
import { validatePassword } from '../../../../utility/utility'

export function ResetPassword() {
  const [form] = Form.useForm()
  const history = useHistory()
  const params: { token: string } = useParams()

  const { mutateAsync: resetPassword, isPending: isPendingResetPassword } = useResetPassword()

  const handleSubmit = ({ new_password }: { new_password: string }) => {
    resetPassword({ new_password, token: params?.token }).then(() => {
      history.push('/membro-exercito/validar-usuario')
    })
  }

  return (
    <Container>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={logosImage} alt="Logo" />
        </div>
        <div style={{ maxWidth: '500px', marginInline: 'auto', marginTop: '7rem' }}>
          <GoldenHeader style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Redefina a sua senha: </GoldenHeader>
          <Form onFinish={handleSubmit} form={form} layout="vertical">
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
              label="Nova Senha"
            >
              <Input.Password placeholder="Nova Senha" />
            </Form.Item>

            <Form.Item
              name="new_password"
              rules={[
                {
                  message: 'Por favor, confirme sua nova senha!',
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('As senhas digitadas não coincidem!'))
                  },
                }),
              ]}
              label="Confirmar Senha"
            >
              <Input.Password placeholder="Confirmar Senha" />
            </Form.Item>

            <Row justify="center">
              <Button htmlType="submit" loading={isPendingResetPassword}>
                Atualizar
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </Container>
  )
}
