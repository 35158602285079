import { Col, Row, Skeleton } from 'antd'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts'
import moment from 'moment'
import { useFetchRecurrenceChart } from '../../../../hooks/donations/queries/useFetchRecurrenceChart'
import { ErrorResult } from '../../../../components/result/resultStatus/ErrorResult'
import { BlurDiv } from '@/context/Blur/BlurDiv'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ background: 'var(--background-color)', padding: '10px', borderRadius: '5px', fontSize: '1rem' }}>
        <h4>{`${label}`}</h4>
        <BlurDiv>
          {payload[0] && (
            <p style={{ color: 'var(--success-color)' }}>{`Pago: R$ ${payload[0].value.toLocaleString('pt-BR')}`}</p>
          )}
          {payload[1] && (
            <p style={{ color: 'var(--error-color)' }}>{`Não pago: R$ ${payload[1].value.toLocaleString('pt-BR')}`}</p>
          )}
        </BlurDiv>
      </div>
    )
  }

  return null
}

const gerarDados = (efetuadas, esperadas) => {
  return efetuadas
    ?.sort((a, b) => moment(a.mes_ano, 'YYYY-MM').diff(moment(b.mes_ano, 'YYYY-MM')))
    .map((efetuada) => {
      const correspondenteEsperada = esperadas?.find((esperada) => esperada?.mes_ano === efetuada?.mes_ano)

      const mesFormatado = moment(efetuada.mes_ano, 'YYYY-MM').format('MMMM YYYY')
      const valorPago = parseFloat(efetuada.valor_apurado)
      const valorNaoPago = parseFloat(correspondenteEsperada?.valor_apurado)

      return {
        mes: mesFormatado.charAt(0).toUpperCase() + mesFormatado.slice(1),
        pago: valorPago,
        nao_pago: valorNaoPago,
      }
    })
}

export function RecurrenceChart() {
  const {
    data: recurrenceChart,
    isLoading: isLoadingRecurrenceChart,
    isError: isErrorRecurrenceChart,
  } = useFetchRecurrenceChart()

  const dataTransformed = gerarDados(recurrenceChart?.recorrentes_efetuadas, recurrenceChart?.recorrentes_nao_efetuadas)

  if (isLoadingRecurrenceChart) {
    return (
      <Col xs={24}>
        <Row justify="center" style={{ overflow: 'hidden' }}>
          <Skeleton.Node active style={{ width: '90vw', height: '300px', fontSize: '8rem' }} />
        </Row>
      </Col>
    )
  }

  if (isErrorRecurrenceChart) {
    return <ErrorResult />
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={dataTransformed}>
        <XAxis dataKey="mes" />

        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Line type="monotone" dataKey="pago" stroke="var(--success-color)" activeDot={{ r: 4 }} dot={false} />
        <Line type="monotone" dataKey="nao_pago" stroke="var(--error-color)" activeDot={{ r: 4 }} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}
