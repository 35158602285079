import { UploadOutlined } from '@ant-design/icons'
import type { RcFile } from 'antd/es/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import { useEffect, useState } from 'react'
import BaseModal from '../modal/BaseModal'
import { BaseUpload } from './BaseUpload'
import BaseButton from '../button/BaseButton'
import { getBase64 } from '../../../../utility/utility'
import { Col, notification, Row } from 'antd'

interface BaseMultiplePhotosProps {
  initialFileList: UploadFile<any>[]
  setImage: (files: UploadFile<any>[]) => void
  numeroFotos: number | false
  description?: string
  listInRow: boolean
  validateField: () => void
}

export type Photo = {
  uid: string
  name: string
  file: File
}

export function BaseMultiplePhotos({
  initialFileList,
  setImage,
  numeroFotos,
  description,
  validateField,
  listInRow,
}: BaseMultiplePhotosProps) {
  const [previewImage, setPreviewImage] = useState<string | undefined>()
  const [previewOpen, setPreviewOpen] = useState<boolean>(false)
  const [hasInteracted, setHasInteracted] = useState<boolean>(false)

  const runValidateField = () => {
    if (validateField) {
      validateField()
    }
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [hasInteracted])

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleRemove = (file: UploadFile) => {
    const updatedFileList = initialFileList.filter((item) => item.uid !== file.uid)
    setImage(updatedFileList)
    setHasInteracted(true)
  }

  const handleBeforeUpload = async (file: RcFile) => {
    const isImage = file.type.startsWith('image/') && file.type !== 'image/gif'
    if (!isImage) {
      notification.error({ message: 'Apenas imagens são permitidas!' })
      return false
    }

    let src = file.url
    if (!src) {
      src = await getBase64(file)
    }

    const image: UploadFile = {
      uid: file.uid,
      name: file.name,
      status: 'done',
      url: src,
      originFileObj: file,
    }

    setImage([...initialFileList, image])
    setHasInteracted(true)
    return false
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [initialFileList, hasInteracted])

  return (
    <>
      <BaseUpload
        listType="picture"
        fileList={initialFileList}
        beforeUpload={handleBeforeUpload}
        onPreview={handlePreview}
        onRemove={handleRemove}
        listInRow={listInRow}
      >
        {(!numeroFotos || initialFileList.length < numeroFotos) && (
          <Row justify="center" gutter={[20, 20]}>
            <Col xs={24}>{description && <BaseUpload.Description text={description} />}</Col>
            <Col style={{ marginTop: '1rem' }}>
              <BaseButton>
                <UploadOutlined style={{ marginRight: '0.5rem' }} />
                Selecione
              </BaseButton>
            </Col>
          </Row>
        )}
      </BaseUpload>
      <BaseModal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img alt="Imagem" style={{ width: '100%' }} src={previewImage} />
      </BaseModal>
    </>
  )
}
