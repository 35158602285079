import { useState } from 'react'

import { VocacionalInitialFiltersProps } from '../../utils'
import { RecusadosTabela } from './tabela'
import {
  GetAllRecusadosProps,
  useFetchGetAllRecusados,
} from '../../../../hooks/vocacional/queries/useFetchGetAllRecusados'
import { generateQueryString, handleFilterChangeAndPagination } from '../../../../../../utility/utility'

export function Recusados({ vocacionalGender }: VocacionalInitialFiltersProps) {
  const FiltrosRecusados = { genero: vocacionalGender }

  const initialFilters = {
    queryString: generateQueryString(FiltrosRecusados),
    ...FiltrosRecusados,
  } as const

  const [filters, setFilters] = useState<GetAllRecusadosProps['filters']>(initialFilters)

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: recusados, isLoading, isError } = useFetchGetAllRecusados({ filters })

  return (
    <RecusadosTabela
      data={recusados}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      isError={isError}
      filters={filters}
    />
  )
}
