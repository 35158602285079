import { useEffect, useState } from 'react'
import { useAuth } from '../../../../context/Auth'
import { useGetLandingPageById } from '../../hooks/api/landingpage/queries/useGetLandingPageById'
import { UserFlow } from './components/user-flow'
import { CheckEmail } from './components/check-email'
import { ErrorResult } from '../../../admin/components/result/resultStatus/ErrorResult'
import { CenteredSpin, MainContent } from './styles'
import { EventoPresencialResult } from './components/evento-presencial-result'
import { useFindUserByDocumentNumber } from '../../../admin/hooks/users/Queries/useFindUserByDocumentNumber'

export function EventoPresencial() {
  const [email, setEmail] = useState<string>('')
  const [isFinished, setIsFinished] = useState<string>('')

  const { logout } = useAuth()
  const { data: userData, isLoading: isPending, isError } = useFindUserByDocumentNumber({ email })

  const {
    data: landingPageData,
    isPending: isPendingLandingPage,
    isError: isLandingPageError,
  } = useGetLandingPageById({ id: '18' }) // id 18: Campanha do Evento Presencial

  useEffect(() => {
    logout()
  }, [])

  if (isPendingLandingPage) {
    return <CenteredSpin data-testid="loading-spin" />
  }

  if (isError || isLandingPageError) {
    return <ErrorResult status={500} />
  }

  if (isFinished) {
    return <EventoPresencialResult isFinished={isFinished} />
  }

  return (
    <MainContent>
      <CheckEmail handleEmailSubmit={(values: { email: string }) => setEmail(values.email)} isPending={isPending} />
      {userData && <UserFlow userData={userData} landingPageData={landingPageData!} markAsFinished={setIsFinished} />}
    </MainContent>
  )
}
