import styled from 'styled-components'
import { DatePicker } from 'antd'
import { BORDER_RADIUS } from '../../../../styles/Themes/Admin/constants'

// Estilizando o wrapper do DatePicker
export const StyledDatePicker = styled(DatePicker)`
  background-color: var(--background-color) !important;
  border-color: var(--input-border-color);
  border-radius: ${BORDER_RADIUS};
  height: 3rem;

  &:hover {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  &.ant-picker {
    .ant-picker-input {
      input {
        color: var(--text-main-color) !important;
      }

      svg {
        color: var(--text-main-color);
      }

      .ant-picker-clear {
        background-color: var(--background-color) !important;
      }
    }
  }
`

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  background-color: var(--background-color);
  border-color: var(--input-border-color);
  border-radius: ${BORDER_RADIUS};
  height: 3rem;

  svg {
    color: var(--text-main-color);
  }

  &:hover {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  .ant-picker-active-bar {
    background-color: var(--text-main-color);
  }

  .ant-picker-clear {
    background-color: var(--background-color);
  }

  &.ant-picker {
    .ant-picker-input {
      input {
        color: var(--text-main-color);
      }

      svg {
        color: var(--text-main-color);
      }
    }
  }
`
