export const SET_FORM_DATA = 'SET_FORM_DATA'
export const SET_CAPA_IMAGE = 'SET_CAPA_IMAGE'
export const SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE'
export const SET_BANNER_IMAGE = 'SET_BANNER_IMAGE'
export const SET_FORM_FIELD = 'SET_FORM_FIELD'
export const RESET_FORM = 'RESET_FORM'

export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  payload: data,
})

export const setCapaImage = (image) => ({
  type: SET_CAPA_IMAGE,
  payload: image,
})

export const setBackgroundImage = (image) => ({
  type: SET_BACKGROUND_IMAGE,
  payload: image,
})

export const setBannerImage = (image) => ({
  type: SET_BANNER_IMAGE,
  payload: image,
})

export const setFormField = (field) => {
  const [key, value] = Object.entries(field)[0]
  return {
    type: SET_FORM_FIELD,
    payload: { key, value },
  }
}

export const resetForm = () => ({
  type: RESET_FORM,
})
