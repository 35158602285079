import React from 'react'
import { Footer } from './components/Footer'
import { HeaderBenfeitor } from './components/Header'
import { LayoutWrapper } from './components/LayoutWrapper'
import { ContentWrapper } from './components/Content'

export const BenfeitorLayout = ({ children }) => {
  return (
    <LayoutWrapper>
      <HeaderBenfeitor />
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </LayoutWrapper>
  )
}
