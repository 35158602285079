import { Skeleton } from 'antd'
import { useFetchTodaySignupsPerHour } from '../../../../../hooks/dashboards/signups/queries/useFetchTodaySignups'
import { Chart } from './Chart'

export function Content({ filters, handleToggleModal }) {
  const query = useFetchTodaySignupsPerHour(filters)

  if (query?.isPending) {
    return (
      <div style={{ height: '25rem', display: 'grid', placeItems: 'center', padding: '1rem' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (query?.isError) {
    return (
      <h4 style={{ color: 'red', height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )
  }

  return <Chart data={query?.data} filters={filters} handleToggleModal={handleToggleModal} />
}
