import { Form, FormInstance } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { StyledGrid } from '../../styles'
import InputMask from 'react-input-mask'
import { fillAddressFromCep } from '../../utils'
import { StyledInput } from '../../../../../components/Inputs'
import { useEffect, useState } from 'react'

import { formatCEP } from '../../../../../../../utility/utility'
import { estadosBrasil } from '../../../../../../../utility/estados'
import { DataService } from '../../../../../../../lib/dataService'
import { useFetchUserMeAddress } from '../../../../../../admin/hooks'

interface AddressAutoComplete {
  bairro: string
  cidade: string
  estado: string
  rua: string
}

interface IAddressForm extends AddressAutoComplete {
  numero: number
  complemento: string
  cep: string
}

interface AddressFormProps {
  form: FormInstance<IAddressForm>
  style?: React.CSSProperties
  commonInputStyles?: any
  showBrazil?: boolean
  hasFormBeenFetchedFromApi?: boolean
}

export function AddressForm({
  form,
  style,
  commonInputStyles = {},
  showBrazil = false,
  hasFormBeenFetchedFromApi = false,
}: AddressFormProps) {
  const [disabledFields, setDisabledFields] = useState<string[]>([])
  const { data: userMeAddress } = useFetchUserMeAddress()
  const selectedCountry = Form.useWatch('pais', form)

  const handleCepChange = async () => {
    fetchAddress()
  }

  const fetchAddress = async () => {
    try {
      const { filledFields } = await fillAddressFromCep({ form })
      setDisabledFields(filledFields)
    } catch (error) {
      console.error('Error fetching address from CEP:', error)
    }
  }

  async function disableFieldsComingFromApi() {
    const { data: addressAutoComplete }: { data: AddressAutoComplete } = await DataService.get(
      `/services/search-cep/${form.getFieldValue('cep')}`,
    )

    const filledFields = Object.keys(addressAutoComplete).filter(
      (key) => addressAutoComplete[key as keyof AddressAutoComplete],
    )

    setDisabledFields(filledFields)
  }

  useEffect(() => {
    disableFieldsComingFromApi()

    if (!hasFormBeenFetchedFromApi && userMeAddress) {
      form.setFieldsValue({
        bairro: userMeAddress?.bairro,
        cep: formatCEP(userMeAddress?.cep),
        cidade: userMeAddress?.cidade,
        complemento: userMeAddress?.complemento,
        estado: userMeAddress?.estado,
        numero: userMeAddress?.numero,
        rua: userMeAddress?.rua,
      })
    }
  }, [form, userMeAddress, hasFormBeenFetchedFromApi])

  return (
    <div style={style}>
      {selectedCountry === 'brasil' || showBrazil ? (
        <div>
          <StyledGrid columns={1}>
            <FormItem
              name="cep"
              label="CEP"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                flexDirection: 'column',
              }}
              rules={[
                { required: true, message: 'Por favor, insira seu CEP!' },
                { pattern: /^[0-9]{5}-[0-9]{3}$/, message: 'Formato inválido! Utilize 00000-000.' },
              ]}
            >
              <InputMask mask="99999-999" maskChar={null} placeholder="00000-000" onChange={handleCepChange}>
                {(inputProps) => <StyledInput {...inputProps} {...commonInputStyles} minWidth={20} />}
              </InputMask>
            </FormItem>
          </StyledGrid>

          <StyledGrid columns={3}>
            <Form.Item
              name="estado"
              label="Estado"
              rules={[
                { required: true, message: 'Por favor, insira seu estado!' },
                {
                  pattern: new RegExp(`^(${estadosBrasil.join('|')})$`, 'i'),
                  message: 'O estado deve ser uma abreviação de 2 letras (ex: SP, RJ)!',
                },
              ]}
            >
              <StyledInput
                {...commonInputStyles}
                placeholder="Apenas texto"
                disabled={disabledFields.includes('estado')}
              />
            </Form.Item>

            <FormItem
              name="cidade"
              label="Cidade"
              rules={[{ required: true, message: 'Por favor, insira sua cidade!' }]}
            >
              <StyledInput
                {...commonInputStyles}
                placeholder="Apenas texto"
                disabled={disabledFields.includes('cidade')}
              />
            </FormItem>

            <FormItem
              name="bairro"
              label="Bairro"
              rules={[{ required: true, message: 'Por favor, insira seu bairro!' }]}
            >
              <StyledInput
                {...commonInputStyles}
                placeholder="Apenas texto"
                disabled={disabledFields.includes('bairro')}
              />
            </FormItem>
          </StyledGrid>

          <StyledGrid columns={3}>
            <FormItem name="rua" label="Rua" rules={[{ required: true, message: 'Por favor, insira sua rua!' }]}>
              <StyledInput {...commonInputStyles} placeholder="Logradouro" disabled={disabledFields.includes('rua')} />
            </FormItem>

            <FormItem
              name="numero"
              label="Número"
              rules={[
                { required: true, message: 'Por favor, insira seu número!' },
                { pattern: /^[0-9]*$/, message: 'Apenas números são permitidos!' },
              ]}
            >
              <StyledInput {...commonInputStyles} placeholder="000" />
            </FormItem>

            <FormItem name="complemento" label="Complemento">
              <StyledInput {...commonInputStyles} placeholder="Casa ou apt" />
            </FormItem>
          </StyledGrid>
        </div>
      ) : (
        <StyledGrid columns={1}>
          <FormItem
            name="detalhe_estrangeiro"
            label="Código postal ou ZIP Code"
            rules={[{ required: true, message: 'Por favor, insira sua localização!' }]}
          >
            <StyledInput {...commonInputStyles} lineHeight={2.2} placeholder="Insira o estado ou região" />
          </FormItem>
        </StyledGrid>
      )}
    </div>
  )
}
