import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { DataService } from '../../../../../lib/dataService'

export function useGetAvailableLiveDates(setSelectedDate) {
  const availableLiveDates = useQuery({
    queryKey: ['AVAILABLE_LIVE_DATES'],
    queryFn: async () => {
      const response = await DataService.get(`/youtube/lives/datas`)
      const lastAvailableDate = response?.data?.datas?.[response?.data?.datas?.length - 1]

      const parsedData = moment(lastAvailableDate)
      setSelectedDate(parsedData)

      return response?.data
    },
  })

  return availableLiveDates
}
