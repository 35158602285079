import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { ListagemVocacional } from './useUpdateVocacionalStatus'
import { DataService } from '../../../../../lib/dataService'

export type DeleteVocacionalUserProps = {
  fk_usuario_vocacional_id: number
  listagem: ListagemVocacional | 'desistentes' | 'recusados'
}

export function useDeleteUserVocacional() {
  const queryClient = useQueryClient()

  const deleteUserVocacional = useMutation({
    mutationFn: async ({ fk_usuario_vocacional_id }: DeleteVocacionalUserProps) => {
      await DataService.delete(`/vocacional/deletar-vocacional/${fk_usuario_vocacional_id}`)
    },
    onSuccess: (_, { listagem }) => {
      notification.success({ message: 'Usuário excluído com sucesso!' })

      if (listagem === 'pre-cadastro') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_PRE_CADASTRO'] })
      }

      if (listagem === 'cadastro') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_CADASTRO'] })
      }

      if (listagem === 'ficha-vocacional') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_FICHA_VOCACIONAL'] })
      }

      if (listagem === 'desistentes') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_DESISTENCIA'] })
      }

      if (listagem === 'recusados') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_RECUSADOS'] })
      }
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao excluir o usuário.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return deleteUserVocacional
}
