import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetEstatisticasFamiliaResponse {
  familias_ativas: string
  familias_cadastradas: string
  familias_inativas: string
  membros_por_familia: string
  renda_media: string
}

export function useGetEstatisticasFamilia() {
  const estatisticasFamilia = useQuery({
    queryKey: ['ESTATISTICAS_FAMILIA'],
    queryFn: async () => {
      const response = await DataService.get<GetEstatisticasFamiliaResponse>('/agape/cards-estatisticas-familias-agape')

      return response.data
    },
  })

  return estatisticasFamilia
}
