import styled from 'styled-components'
import { Tabs as AntdTabs } from 'antd'
import { getHexFromCssVar, hexToRgba } from '../../../../utility/utility'

export const Tabs = styled(AntdTabs)`
  color: var(--text-main-color); // Cor do conteúdo

  .ant-tabs-tab {
    color: ${() => {
      const textColor = getHexFromCssVar('--text-secondary-color')
      return hexToRgba(textColor, 0.5)
    }}; /* Cor do texto da tab inativa */

    :hover {
      color: ${() => {
        const textColor = getHexFromCssVar('--text-secondary-color')
        return hexToRgba(textColor, 0.8)
      }};
    }
  }

  .ant-tabs-tab-btn:focus {
    color: inherit; /* Mantém a cor do texto */
  }

  .ant-tabs-nav::before {
    border-bottom: 2px solid
      ${() => {
        const textColor = getHexFromCssVar('--text-secondary-color')
        return hexToRgba(textColor, 0.7) // Opacidade na linha
      }};
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--text-main-color); /* Cor do texto da tab ativa */
    }
  }

  .ant-tabs-ink-bar {
    background-color: var(--text-main-color); /* Cor da linha abaixo da tab ativa */
  }
`
