import { useEffect } from 'react'
import { ContainerWrapper, ContentWrapper, InstitutionalHeaderTittle, SectionWrapper } from './styles'

export function Termos() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <ContentWrapper>
      <ContainerWrapper>
        <InstitutionalHeaderTittle>
        Termos de uso da Conta Instituto Hesed
          </InstitutionalHeaderTittle>
        <h3 style={{ textAlign: 'right' }}>Data Atualização: 23/04/2024</h3>
        <SectionWrapper>
          <p>
            Bem-vindo ao Instituto Hesed! Este é um serviço do Instituto Hesed
            que conecta nossos clientes a todos os outros serviços Instituto
            Hesed, como acesso a vídeos, conteúdos e outros produtos de
            entretenimento audiovisual, transmitidos pela Internet para
            televisores, computadores e outros aparelhos conectados à Internet.
            Nestes Termos de Uso incluem a utilização dos dados e privacidade
            para todas as empresas interligadas ao Instituto Hesed, como:
            Livraria Imaculada, São José, Centro de Treinamento Instituto Hesed
            (CTH) e nosso canal do Youtube, garantindo uma abordagem unificada e
            transparente em relação à proteção de dados. Estes Termos de uso
            regem seu uso do serviço Instituto Hesed. Usados nestes Termos de
            uso, os termos “serviço Instituto Hesed,” “nosso serviço” ou “o
            serviço” significam o serviço fornecido pelo Instituto Hesed,
            incluindo todos os recursos e funcionalidades, o site da Web e a
            interface do usuário, assim como todo o conteúdo e software
            associado ao serviço.
          </p>

          <h2>1. Aceitação dos Termos de uso </h2>

          <ol>
            <li>
              <p>
                Os presentes Termos de uso do Instituto Hesed, que incluem a
                Política de privacidade (
                <a
                  href="https://doe.institutohesed.org.br/benfeitor/politicas-de-privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://doe.institutohesed.org.br/benfeitor/politicas-de-privacidade
                </a>
                ) acessar, usar ou navegar pelo serviço Instituto Hesed, você
                aceita e concorda com os presentes Termos de uso.
              </p>
            </li>

            <li>
              <p>
                A aceitação destes “Termos de Uso” pelo usuário se dará no ato
                do seu clique no botão “Li e concordo”, ao se cadastrar na
                plataforma e, em relação ao visitante, quando faz a navegação e
                utilização da plataforma.
              </p>
            </li>

            <li>
              <p>
                Caso você não concorde com os presentes Termos de Uso,
                recomendamos que não prossiga com o cadastramento na plataforma,
                bem como que se abstenha de acessá-la e utilizá-la.
              </p>
            </li>
          </ol>

          <h2>2. Alterações aos Termos de uso</h2>

          <p>
            O Instituto Hesed poderá alterar esses Termos de uso periodicamente,
            inclusive a Política de privacidade, Contrato de licença de usuário
            final e os Termos de redes sociais. Essas alterações entrarão em
            vigor imediatamente, porém, para assinantes atuais, as alterações
            entrarão em vigor 30 dias após sua publicação, exceto mediante
            especificação contrária. O Instituto Hesed procurará publicar
            versões anteriores dos Termos de uso, se houver, publicadas nos
            últimos 12 meses.
          </p>

          <h2>3. Privacidade</h2>
          <p>
            Informações pessoais que identifiquem os indivíduos estão sujeitas à
            nossa Política de privacidade (
            <a
              href="https://doe.institutohesed.org.br/benfeitor/politicas-de-privacidade"
              target="_blank"
              rel="noreferrer"
            >
              https://doe.institutohesed.org.br/benfeitor/politicas-de-privacidade
            </a>
            ) e seus termos estão incorporados neste documento. Consulte a
            Política de privacidade para entender nossas práticas.
          </p>

          <h2>4. Preferências de comunicação</h2>
          <p>
            Ao utilizar o serviço Instituto Hesed, você consente receber
            comunicados por meios eletrônicos do Instituto Hesed relacionados à
            sua conta. A comunicação incluirá o envio de e-mails para o endereço
            de e-mail informado durante a inscrição, a publicação de comunicados
            no serviço Instituto Hesed ou na página “Sua conta”, e poderá
            incluir avisos relacionados à sua conta (mudanças de senha,
            autorizações de pagamento ou forma de pagamento, confirmações e
            outras informações relativas a transações). Tal comunicação
            constituirá parte do seu relacionamento com o Instituto Hesed. Você
            concorda que quaisquer avisos, contratos, divulgações ou outras
            comunicações enviadas pelo Instituto Hesed para você,
            eletronicamente, satisfazem os requisitos legais de comunicação,
            incluindo o requisito de que tais comunicações sejam por escrito.
            Mantenha cópias das comunicações eletrônicas imprimindo uma cópia em
            papel ou salvando uma cópia eletrônica. Você também concorda em
            receber outras comunicações do Instituto Hesed, como boletins
            informativos sobre novas funcionalidades e conteúdo do Instituto
            Hesed, ofertas especiais, promoções e pesquisas de mercado, seja por
            e-mail ou outros meios de comunicação.
          </p>

          <h2>5. Assinatura</h2>
          <p>
            Período de utilização gratuita, sem cobranças e cancelamento. O
            Login Instituto Hesed atualmente é um serviço gratuito. Porém, há
            recursos digitais em que o Instituto Hesed se resguardará às devidas
            cobranças, tais como: cursos oferecidos pelo “Centro de Treinamento
            Instituto Hesed - CTH”.
          </p>

          <h2>6. Serviço Instituto Hesed</h2>
          <ol>
            <li>
              <p>
                O serviço Instituto Hesed e todo o conteúdo visualizado por
                este, destina-se, exclusivamente, ao uso pessoal e não
                comercial. Nenhum outro direito, titularidade ou participação
                lhe é concedido. Você concorda em não utilizar o serviço para
                exibições públicas.
              </p>
            </li>

            <li>
              <p>
                Você pode assistir a um vídeo por intermédio do serviço
                Instituto Hesed principalmente no país em que você se inscreveu
                e somente nos países onde o serviço Instituto Hesed é oferecido
                e onde a Instituto Hesed possui licenças para ditos filmes ou
                séries. O conteúdo disponível varia segundo o país. O Instituto
                Hesed utiliza tecnologias para verificar sua localização
                geográfica, o número de aparelhos nos quais você pode assistir a
                conteúdo Instituto Hesed ao mesmo tempo atualmente é ilimitado.
                O número de aparelhos disponíveis para utilização e de
                transmissões online simultâneas poderão ser alteradas
                periodicamente a critério do Instituto Hesed.
              </p>
            </li>
            <li>
              <p>
                O Instituto Hesed atualiza o serviço continuadamente, incluindo
                o catálogo de conteúdo. Além disso, diferentes aspectos do
                serviço são testados continuadamente, incluindo o site,
                interfaces de usuário, níveis de serviço, planos, recursos
                promocionais, disponibilidade de vídeos e publicações,
                transmissão e preços. O Instituto Hesed reserva o direito de
                conduzir testes, e ao utilizar o serviço você concorda que o
                Instituto Hesed poderá incluir ou excluir você destes testes sem
                aviso prévio. O Instituto Hesed reserva o direito, exercido
                totalmente segundo critérios próprios, de alterar periodicamente
                e sem aviso prévio a forma como o serviço é oferecido e operado.
              </p>
            </li>
            <li>
              <p>
                Você concorda em usar o serviço Instituto Hesed, incluindo todos
                os recursos e suas funcionalidades, segundo todas as leis,
                regulamentos e códigos aplicáveis, assim como outras restrições
                de uso do serviço ou conteúdo previstas nas mesmas. Você
                concorda em não{' '}
                <span
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                >
                  arquivar
                </span>
                ,{' '}
                <span
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                >
                  baixar
                </span>{' '}
                (exceto o cache necessário para o uso pessoal), reproduzir,
                distribuir, modificar, exibir, publicar, licenciar ou criar
                trabalhos derivados, colocar à venda ou utilizar (exceto das
                formas expressamente autorizadas por esses Termos de uso) o
                conteúdo e as informações contidas ou obtidas do ou por
                intermédio do serviço Instituto Hesed sem a autorização expressa
                e por escrito do Instituto Hesed e dos licenciadores. Você
                também concorda em não: evadir, remover, alterar, desativar,
                degradar ou adulterar quaisquer das proteções de conteúdo do
                serviço Instituto Hesed, usar qualquer robô, espião, scraper ou
                outras formas automatizadas para acessar o serviço Instituto
                Hesed, descompilar, executar engenharia reversa ou desmembrar
                qualquer software ou outros produtos ou processos acessíveis
                pelo serviço Instituto Hesed, inserir qualquer código ou
                produto, manipular o conteúdo do serviço Instituto Hesed de
                qualquer forma ou usar métodos de data mining, coleta de dados
                ou extração de dados. Além disso, você concorda em não fazer
                upload, publicar, enviar por e-mail, comunicar ou transmitir de
                qualquer forma qualquer material designado para interromper,
                destruir ou limitar a funcionalidade de qualquer software,
                hardware ou equipamento de telecomunicações associado ao serviço
                Instituto Hesed, incluindo vírus de software, código, arquivos
                ou programas.
              </p>
            </li>

            <li>
              <p>
                Todo o conteúdo produzido por você no Instituto Hesed utilizando
                o serviço de login terão seus direitos de uso cedidos a mesma.
              </p>
            </li>

            <li>
              <p>
                A disponibilidade de vídeos, áudios e publicações do catálogo
                mudará periodicamente e de um país para o outro. A qualidade de
                imagem de áudio e vídeo pode variar de computador para
                computador e de aparelho para aparelho, e pode ser afetada por
                diversos fatores, incluindo sua localização, a largura de banda
                disponível e a velocidade da sua conexão com a Internet. A
                disponibilidade de alta definição (HD) e ultra alta definição
                (Ultra HD) está sujeita a seu serviço de Internet e à
                funcionalidade do aparelho usado. Nem todo conteúdo está
                disponível em HD ou Ultra HD. As configurações padrão de
                reprodução em redes de telefonia celular excluirão conteúdo HD
                (alta definição) e Ultra HD (ultra alta definição). Recomenda-se
                uma velocidade mínima de download de 5.0 Mbps para receber
                conteúdo em HD, definido como resolução 720 p ou superior.
                Recomenda-se uma velocidade mínima de download de 25.0 Mbps para
                receber conteúdo em Ultra HD, definido como resolução superior a
                1080 p. Você se responsabiliza por todas as tarifas de acesso à
                Internet. Consulte seu provedor de internet para obter
                informações sobre os custos de utilização de dados. O Instituto
                Hesed não faz afirmações ou garantias com respeito à qualidade
                da imagem na sua tela. O tempo de inicialização de um áudio ou
                vídeo poderá variar segundo uma série de fatores, incluindo sua
                localização, a largura de banda então disponível, o filme ou
                série selecionado a as configurações do aparelho compatível com
                o Instituto Hesed utilizado.
              </p>
            </li>

            <li>
              <p>
                O Instituto Hesed poderá encerrar ou restringir seu uso do
                serviço sem indenização ou aviso prévio se houver suspeita de:
                <ol style={{ listStyleType: 'lower-roman' }}>
                  <li>infração de qualquer dos presentes Termos de uso ou</li>
                  <li>envolvimento com uso ilegal ou inadequado do serviço.</li>
                </ol>
              </p>
            </li>
          </ol>

          <h2>7. Senhas e acesso à conta</h2>
          <ol>
            <li>
              <p>
                O assinante que criou a conta no Instituto Hesed é designado
                como o titular da conta nos presentes Termos de uso. O titular
                da conta tem acesso e controle sobre a conta Instituto Hesed. O
                controle do titular da conta é exercido por meio da senha do
                titular, portanto, para manter controle exclusivo sobre a conta,
                o titular não deverá revelar sua senha a ninguém. Além disso,
                caso o titular da conta queira impedir outros de entrar em
                contato com o serviço de atendimento ao cliente e potencialmente
                alterar o controle do titular, o titular não deverá revelar os
                detalhes da sua forma de pagamento (sejam os últimos quatro
                dígitos do cartão de débito ou crédito ou o endereço de e-mail,
                se for utilizado o PayPal) associada à conta. Você é responsável
                por atualizar e manter a veracidade e acuidade das informações
                fornecidas ao Instituto Hesed relativas à sua conta.
              </p>
            </li>

            <li>
              <p>
                Para simplificar o acesso à sua conta e ajudar a administrar o
                serviço Instituto Hesed, o Instituto Hesed emprega tecnologias
                que nos permitem reconhecer você como o titular da conta e lhe
                dar acesso direto à sua conta sem exigir que você digite sua
                senha ou qualquer outra informação de identificação pessoal
                quando você volta a acessar o serviço Instituto Hesed, o que
                inclui acessar o Instituto Hesed por meio de aparelhos ou do
                site.
              </p>
            </li>

            <li>
              <p>
                Exerça cautela com respeito a comunicados que solicitem o envio
                de dados de cartão de crédito ou outras informações sobre sua
                conta. Responder a comunicados dessa natureza com informações
                pessoais poderá resultar no roubo de seus dados pessoais. Sempre
                acesse informações pessoais sobre sua conta diretamente no site
                do <strong>Instituto Hesed</strong> domínio digital{' '}
                <strong>(institutohesed.org.br)</strong>, e não clicando em
                links em e-mails ou outros tipos de comunicados eletrônicos,
                mesmo se o comunicado lhe parecer oficial. O Instituto Hesed
                reserva-se no direito de suspender qualquer conta a qualquer
                momento, com ou sem aviso prévio ao titular da conta, para
                proteger a si mesma e empresas associadas de atividades
                suspeitas de serem fraudulentas. O Instituto Hesed não será
                obrigado a reembolsar ou dar descontos a assinantes cujas contas
                foram suspensas por um representante do Instituto Hesed ou por
                processos automatizados implementados pelo Instituto Hesed.
              </p>
            </li>
          </ol>

          <h2>
            8. Termos de isenção de garantias e limitações de responsabilidade
          </h2>
          <ol>
            <li>
              <p>
                o serviço Instituto Hesed e todos os conteúdos e softwares
                associados, assim como quaisquer outros recursos ou
                funcionalidades associadas ao serviço Instituto Hesed, são
                fornecidos “no seu estado atual” e “conforme disponibilidade”,
                com todas as falhas e sem garantias de qualquer espécie. o
                Instituto Hesed não faz afirmações ou dá garantias de qualquer
                tipo de que seu uso do serviço Instituto Hesed será contínuo ou
                livre de erros. o Instituto Hesed isenta-se, especificamente, de
                qualquer responsabilidade pelo uso de aplicativos, aparelhos
                compatíveis com o Instituto Hesed e softwares do Instituto Hesed
                (incluindo a compatibilidade contínua com nosso serviço).
              </p>
            </li>

            <li>
              <p>
                até a máxima extensão permitida por lei, em nenhum evento o
                Instituto Hesed, suas subsidiárias ou seus acionistas,
                diretores, executivos, funcionários ou licenciadores deverão ser
                responsabilizados (subsidiariamente ou solidariamente) em
                relação a você por danos especiais, incidentais, indiretos ou
                consequenciais de qualquer natureza, ou quaisquer tipos de
                danos.
              </p>
            </li>

            <li>
              <p>
                determinadas jurisdições não permitem a exclusão de determinadas
                garantias ou da limitação ou exclusão de determinados tipos de
                danos. Portanto, determinadas limitações desta seção podem não
                se aplicar a você.
              </p>
            </li>

            <li>
              <p>
                nenhuma disposição nesses termos deverá afetar qualquer direito
                legal que lhe assista. se qualquer disposição ou disposições
                desses Termos de uso forem consideradas inválidas, ilegais ou
                não aplicáveis, a validade, legalidade e aplicabilidade das
                demais disposições devem permanecer em pleno vigor.
              </p>
            </li>
          </ol>

          <h2>9. Propriedade intelectual</h2>
          <ol>
            <li>
              <p>
                <span style={{ textDecoration: 'underline' }}>
                  Direitos autorais
                </span>
                . O serviço O Instituto Hesed, incluindo todo o conteúdo
                fornecido pelo serviço O Instituto Hesed, é protegido por
                direitos autorais, segredos comerciais e outras leis e tratados
                de propriedade intelectual.
              </p>
            </li>
            <li>
              <p>
                <span style={{ textDecoration: 'underline' }}>
                  Marcas registradas
                </span>
                . O Instituto Hesed é uma marca registrada.
              </p>
            </li>
            <li>
              <p>
                <span style={{ textDecoration: 'underline' }}>Patentes</span>. O
                Instituto Hesed tem patentes que se aplicam ao serviço Instituto
                Hesed.
              </p>
            </li>
            <li>
              <p>
                <span style={{ textDecoration: 'underline' }}>
                  Alegações de infração de direitos autorais
                </span>
                . Alegação de infração de direitos autorais. Se você acredita
                que seu trabalho foi reproduzido ou distribuído de forma que
                constitui infração de direitos autorais ou se você tem
                conhecimento de material que infrinja as leis de direitos
                autorais por meio do serviço Instituto Hesed, notifique-nos
                preenchendo o formulário de Contato (
                <a
                  href="http://www.institutohesed.org.br/ajuda"
                  target="_blank"
                  rel="noreferrer"
                >
                  http://www.institutohesed.org.br/ajuda
                </a>
                ).
              </p>
            </li>
          </ol>

          <h2>10. Legislação vigente</h2>
          <ol>
            <li>
              <p>
                Os presentes Termos de Uso serão regidos, interpretados e
                executados de acordo com as leis da República Federativa do
                Brasil, independentemente dos conflitos dessas leis com leis de
                outros estados ou países, sendo competente o Foro de
                FORTALEZA/CE, no Brasil, para dirimir qualquer dúvida decorrente
                deste instrumento. Você consente, expressamente, com a
                competência desse juízo, e renúncia, neste ato, à competência de
                qualquer outro foro, por mais privilegiado que seja ou venha a
                ser.
              </p>
            </li>
            <li>
              <p>
                Os dados pessoais coletados no portal
                <a
                  href="http://www.institutohesed.org.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  http://www.institutohesed.org.br
                </a>{' '}
                serão tratados em conformidade com a Lei Geral de Proteção de
                Dados Pessoais – Lei 13.709/18 – LGPD
              </p>
            </li>

            <li>
              <p>
                Em caso de dúvidas a respeito dos direitos do titular, você
                poderá solicitar esclarecimentos através do e-mail do
                encarregado de dados pessoais:{' '}
                <a
                  href="mailto:privacidade@institutohesed.org.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacidade@institutohesed.org.br
                </a>
              </p>
            </li>
            <li>
              <p>
                Você também pode ter direito a determinados direitos de proteção
                ao consumidor sob a legislação de sua jurisdição local.
              </p>
            </li>
          </ol>

          <h2>11. Aplicativos</h2>
          <p>
            Você poderá encontrar aplicativos de terceiros (incluindo, mas não
            limitando-se a websites, widgets, software ou outros utilitários de
            software) (“aplicativo(s)”) que interagem com o serviço Instituto
            Hesed. Esses aplicativos podem importar dados relacionados à sua
            conta Instituto Hesed e atividade e obter dados de você. Esses
            aplicativos são fornecidos somente para sua conveniência. O
            Instituto Hesed não é responsável por eles. Esses aplicativos são de
            propriedade e operados por terceiros não relacionados nem
            patrocinados pelo Instituto Hesed e podem não estar autorizados para
            uso com o serviço Instituto Hesed em todos os países. A utilização
            de um aplicativo fica a seu critério e risco.
          </p>

          <h2>12. Uso das informações fornecidas.</h2>
          <p>
            O Instituto Hesed terá liberdade de usar quaisquer comentários,
            informações, ideias, conceitos, opiniões, técnicas ou quaisquer
            outros materiais contidos em qualquer comunicação que você envie
            para nós (“Feedback”), incluindo respostas a questionários por meio
            do serviço Instituto Hesed, incluindo o site do Instituto Hesed e
            suas interfaces de usuário, em caráter global e perpétuo, sem
            remuneração, reconhecimento ou pagamento a você por qualquer motivo,
            incluindo, mas não se limitando, a desenvolvimento, fabricação e
            comercialização de produtos e criação, modificação ou aprimoramento
            do serviço Instituto Hesed. Além disso, você concorda em não
            reivindicar quaisquer “direitos morais” ao Feedback, até a extensão
            permitida pelas leis aplicáveis. Observe que o Instituto Hesed não
            aceita materiais não solicitados ou ideias de filmes e séries e não
            é responsável pela semelhança de seu conteúdo ou programação em
            qualquer mídia aos materiais ou ideias transmitidas ao Instituto
            Hesed. Se você enviar quaisquer materiais ou ideias não solicitadas,
            faça-o com o entendimento de que não será devido qualquer pagamento
            de qualquer natureza a você e de que você está concordando em
            isentar o Instituto Hesed e suas afiliadas de qualquer reclamação ou
            processo relativo ao uso de tais materiais e ideias, mesmo que o
            material ou ideia seja usado e seja substancialmente semelhante ao
            material ou ideia enviados por você.
          </p>

          <h2>13. Atendimento ao cliente</h2>
          <p>
            Para obter mais informações sobre o serviço Instituto Hesed e seus
            recursos, ou se precisar de ajuda com sua conta, acesse o Centro de
            ajuda Instituto Hesed (
            <a
              href="http://www.institutohesed.org.br/ajuda"
              target="_blank"
              rel="noreferrer"
            >
              http://www.institutohesed.org.br/ajuda
            </a>
            ). Em alguns casos, a melhor opção para o Atendimento ao cliente é
            utilizar uma ferramenta de acesso remoto que dá acesso total ao seu
            computador. Se você não quiser que tenhamos esse acesso, não
            concorde com o uso da ferramenta de suporte por acesso remoto. O
            Instituto Hesed ajudará você de outras formas. No caso de quaisquer
            conflitos entre esses Termos de uso e informações fornecidas pelo
            Atendimento ao cliente, esses Termos de uso prevalecerão.
          </p>

          <h2>14. Validade das cláusulas</h2>
          <p>
            Se qualquer disposição ou disposições desses Termos de uso forem
            consideradas inválidas, ilegais ou não aplicáveis, a validade,
            legalidade e aplicabilidade das demais disposições devem permanecer
            em pleno vigor.
          </p>
        </SectionWrapper>
      </ContainerWrapper>
    </ContentWrapper>
  )
}

