import { useQuery } from '@tanstack/react-query'

interface CarouselData {
  totalSent: number
  totalRead: number
  totalIgnored: number
  totalPending: number
}

export function useCarouselData() {
  return useQuery<CarouselData>({
    queryKey: ['messages-overview'],
    queryFn: async () => {
      return {
        totalSent: 15783,
        totalRead: 12456,
        totalIgnored: 2145,
        totalPending: 1182
      }
    }
  })
} 