import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, Popup, LayersControl, useMap } from 'react-leaflet'
import { BaseLayers, BasePinIcon } from '../../../../../../../../../../lib/react-leaflet'

interface MapaEnderecoFamiliaProps {
  endereco?: {
    endereco: string
    latitude: number | undefined
    longitude: number | undefined
  }
}

const MapRecenter = ({ center, zoom }: { center: [number, number]; zoom: number }) => {
  const map = useMap()

  setTimeout(() => {
    map.setView(center, zoom)
  }, 0)

  return null
}

export function MapaEnderecoFamilia({ endereco }: MapaEnderecoFamiliaProps) {
  const centro = [endereco?.latitude, endereco?.longitude] as [number, number]

  if (centro && endereco) {
    return (
      <MapContainer
        center={centro}
        zoom={13}
        style={{
          height: '50vh',
          margin: '0 auto',
          borderRadius: '8px',
          marginBottom: '1rem',
        }}
      >
        <MapRecenter center={centro} zoom={13} />

        <LayersControl position="topleft">
          <LayersControl.BaseLayer checked name="Normal">
            {BaseLayers.googleRegular}
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satélite">
            {BaseLayers.googleSatelite}
          </LayersControl.BaseLayer>
        </LayersControl>

        <Marker position={centro} icon={BasePinIcon}>
          <Popup>{endereco.endereco}</Popup>
        </Marker>
      </MapContainer>
    )
  }
  return null
}
