import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetAllCadastroProps {
  filters?: {
    queryString?: string | null
    nome?: string
    email?: string
    pais?: string
    documento_identidade?: string
    genero?: 'masculino' | 'feminino'
    status?: 'aprovado' | 'pendente'
    data_inicio?: string
    data_fim?: string
    page?: number
    per_page?: number
  }
}

export interface GetAllCadastroResponse {
  cadastros_vocacionais: {
    bairro: string
    cep: string
    cidade: string
    created_at: string
    data_nascimento: string
    detalhe_estrangeiro?: string
    responsavel?: string
    documento_identidade: string
    email: string
    estado: string
    fk_usuario_vocacional_id: number
    genero: 'masculino' | 'feminino'
    id: number
    nome: string
    numero: string
    pais: string
    rua: string
    status: 'aprovado' | 'pendente'
    telefone: string
    complemento?: string
  }[]

  page: number
  total: number
}

export function useFetchGetAllCadastros({ filters }: GetAllCadastroProps) {
  const allCadastro = useQuery({
    queryKey: ['GET_ALL_CADASTRO', filters],
    queryFn: async () => {
      const response = await DataService.get<GetAllCadastroResponse>(
        `/vocacional/listar-cadastros-vocacionais?${filters?.queryString ?? ''}`,
      )
      return response.data
    },
  })

  return allCadastro
}
