import FeatherIcon from 'feather-icons-react'
import { notification, Space } from 'antd'
import BaseCard from '../../../../card/BaseCard'
import { DonationRow } from './../components/DonationsListTable'
import { DonationsTableVisibleFilters } from '..'
import { DonationTableFilters } from '../components/Filters/DonationTableFilters'
import { BlurDiv } from '@/context/Blur/BlurDiv'

interface DonationsTableUseGetColumns {
  dataSource: DonationRow[] | undefined
  handleInputChange: Function
  visibleFilters: DonationsTableVisibleFilters[]
  filters: any
}

export function useGetDonationsColumns({
  filters,
  handleInputChange,
  visibleFilters,
  dataSource,
}: DonationsTableUseGetColumns) {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (item) => <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>{item}</div>,
    },
    {
      title: 'Método',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
      align: 'center',
      width: 150,
      ...(visibleFilters.includes('forma_pagamento') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.MetodoPagamento
              handleInputChange={handleInputChange}
              value={filters?.tipo_pagamento}
            />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.tipo_pagamento && [filters?.tipo_pagamento],
    },
    {
      title: 'Referência',
      dataIndex: 'codigo_referencia',
      key: 'codigo_referencia',
      render: (codigo_referencia: { copyValue: () => void }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FeatherIcon
            icon="copy"
            size={20}
            style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
            onClick={codigo_referencia.copyValue}
          />
        </div>
      ),
      align: 'center',
      ...(visibleFilters.includes('codigo_referencia') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.CodigoReferencia
              handleInputChange={handleInputChange}
              value={filters?.codigo_referencial}
            />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.codigo_referencial && [filters?.codigo_referencial],
    },
    {
      title: 'Recorrencia',
      dataIndex: 'recorrencia',
      key: 'recorrencia',
      align: 'center',
      ...(visibleFilters.includes('recorrencia') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.Recorencia handleInputChange={handleInputChange} value={filters?.recorrencia} />
          </BaseCard>
        ),
      }),
      render: ({ recurrency }) => recurrency(),
      filteredValue: filters?.recorrencia && [filters?.recorrencia],
    },
    {
      title: 'Comprovante Id',
      dataIndex: 'comprovante',
      key: 'comprovante',
      align: 'center',
      render: (donation) => {
        if (donation?.processamento?.id_pagamento !== null) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FeatherIcon
                icon="copy"
                size={20}
                style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(donation?.processamento?.id_pagamento)
                    .then(() => {
                      notification.success({
                        message: (
                          <div style={{ fontWeight: 'bold', color: '#50556f' }}>Código de Transação Copiado</div>
                        ),
                        description: (
                          <div>
                            <div style={{ color: '#757ca3', fontWeight: 600 }}>{donation?.benfeitor?.nome}</div>
                            <div style={{ color: '#8b8ea4' }}>{donation?.processamento?.id_pagamento}</div>
                          </div>
                        ),
                      })
                    })
                    .catch((error) => {
                      notification.error({
                        message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
                      })
                      console.error('Falha ao copiar o valor para a área de transferência:', error)
                    })
                }}
              />
            </div>
          )
        }
        return null
      },
    },
    {
      title: 'Transação Id',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (data) => (
        <>
          {data?.hasTransactionId && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FeatherIcon
                icon="copy"
                size={20}
                style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
                onClick={data?.copyValue}
              />
            </div>
          )}
        </>
      ),
      align: 'center',
      ...(visibleFilters.includes('transaction_id') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.Transaction handleInputChange={handleInputChange} value={filters.transaction_id} />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.transaction_id && [filters?.transaction_id],
    },
    {
      title: 'Cliente',
      dataIndex: 'nome',
      key: 'nome',
      ...(visibleFilters.includes('nome') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.NomeAutoComplete
              suggestions={dataSource}
              value={filters?.filter_multiple}
              handleInputChange={handleInputChange}
            />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.filter_multiple && [filters?.filter_multiple],
    },
    {
      title: 'Data',
      dataIndex: 'data_doacao',
      key: 'data_doacao',
      ...(visibleFilters.includes('data') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.DataHora
              value={{ data_inicial: filters.data_inicial, data_final: filters.data_final }}
              handleInputChange={handleInputChange}
            />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.data_inicial || (filters?.data_final && [filters?.data_inicial, filters?.data_final]),
    },
    {
      title: 'Valor',
      dataIndex: 'valor_doacao',
      key: 'valor_doacao',
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      ...(visibleFilters.includes('status') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.StatusProcessamento handleInputChange={handleInputChange} value={filters?.status} />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.status && [filters?.status],
    },
    {
      title: 'Gateway',
      dataIndex: 'gateway',
      key: 'gateway',
      align: 'left',
      ...(visibleFilters.includes('gateway') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.Gateway
              handleInputChange={handleInputChange}
              value={filters.fk_gateway_pagamento_id}
            />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.fk_gateway_pagamento_id && [filters?.fk_gateway_pagamento_id],
    },
    {
      title: 'Campanha',
      dataIndex: 'titulo_campanha',
      key: 'titulo_campanha',
      align: 'left',
      ...(visibleFilters.includes('campanha') && {
        filterDropdown: () => (
          <BaseCard>
            <DonationTableFilters.Campanhas handleInputChange={handleInputChange} value={filters?.campaign_name} />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.campaign_name && [filters?.campaign_name],
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      align: 'center',
      render: ({ ac }) => <Space size="middle">{ac}</Space>,
    },
  ]
}
