import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

type EditActionProps = {
  fk_acao_id: string
  formData: {
    background: File
    banner: File
    data: FormData
  }
}

export function useEditAction() {
  const queryClient = useQueryClient()
  const history = useHistory()

  const editAction = useMutation({
    mutationFn: async ({ fk_acao_id, formData }: EditActionProps) => {
      return DataService.put(`/administradores/editar-acao/${fk_acao_id}`, formData)
    },
    onSuccess: (_, { fk_acao_id }) => {
      queryClient.invalidateQueries({ queryKey: ['ALL_ACTIONS'] })
      queryClient.invalidateQueries({ queryKey: ['ACTION_DETAILS', fk_acao_id] })
      history.push('/admin/actions/view-all')

      notification.success({ message: 'Ação atualizada com sucesso' })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao editar ação',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return editAction
}
