import { Link } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import * as S from './LoginPage.Styles'
import logoImg from '../../../../../../static/logo/light/light-logo-image.svg'
import logoText from '../../../../../../static/logo/light/light-logo-text.svg'
import { useFetchUserMe } from '../../../../../admin/hooks'
import { useAuth } from '../../../../../../context/Auth'
import { InputPassword } from '../../../../../admin/components/inputs/InputPassword/InputPassword.Styles'
import BaseButton from '../../../../../admin/components/button/BaseButton'
import BaseForm from '../../../../../admin/components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../../admin/components/inputs/BaseInput/BaseInput'

// TODO: instead of hardcode, put some get from some route later, backend needed
const imageLink = 'https://hesed-bucket.s3.sa-east-1.amazonaws.com/home-background/bg2.png'

export function LoginPage() {
  const history = useHistory()
  // const dispatch = useDispatch();

  const userMe = useFetchUserMe()

  const { login, userAuthState } = useAuth()

  const [form] = BaseForm.useForm()

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields()

    const payload = {
      email: values.username,
      password: values.password,
    }

    await login(payload).then(() => {
      userMe
        .refetch()
        .then((res) => res.data)
        .then((user) => {
          if (user?.super_perfil) {
            if (user?.fk_perfil_id === 1) {
              history.push('/admin/dashboard/financeiro')
              return
            }

            history.push('/admin')
          }
        })
    })
  }, [form, login])

  useEffect(() => {
    if (userAuthState.isAuthenticated) {
      history.push('/')
    }
  }, [userAuthState])

  return (
    <S.LoginBackgroundImage src={imageLink}>
      <S.LoginFormCard>
        <S.LoginLogoWrraper>
          <a href="/">
            <img src={logoImg} alt="Logo Acutis" />
            <img src={logoText} alt="Logo Acutis" />
          </a>
        </S.LoginLogoWrraper>

        <BaseForm form={form} onFinish={handleSubmit}>
          <Row gutter={[20, 10]} style={{ minHeight: '25rem' }}>
            <Col xs={24}>
              <BaseForm.Item
                name="username"
                rules={[{ message: 'Por favor, insira seu e-mail!', required: true }]}
                label="E-mail"
              >
                <BaseInput placeholder="nome@email.com" />
              </BaseForm.Item>
            </Col>

            <Col xs={24}>
              <BaseForm.Item
                name="password"
                rules={[{ message: 'Por favor, insira sua senha!', required: true }]}
                label="Senha"
              >
                <InputPassword
                  placeholder="Digite sua senha"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </BaseForm.Item>
            </Col>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'stretch',
              }}
            >
              <Link className="forgot-pass-link" to="/login/forgotPassword" style={{ color: 'var(--error-color)' }}>
                Esqueceu a senha?
              </Link>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: '1rem',
              }}
            >
              <BaseButton htmlType="submit" style={{ width: '100%' }}>
                Entrar
              </BaseButton>

              <Link to="/login/register" style={{ color: '#3E3331' }}>
                Criar conta
              </Link>
            </div>
          </Row>
        </BaseForm>
      </S.LoginFormCard>
    </S.LoginBackgroundImage>
  )
}
