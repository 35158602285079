import { http, HttpResponse } from 'msw'
import { UserMeAddress } from '../../container/admin/hooks/users/Queries/useFetchUserMeAddress'
import { currentUserEmail, EMAIL_BENFEITOR_ESTRANGEIRO_MOCK } from './login-mock'

const ADDRESS_RESPONSE = {
  bairro: 'Jardim S\u00e3o Paulo',
  cep: '60010110',
  cidade: 'Jo\u00e3o Pessoa',
  complemento: null,
  data_alteracao: null,
  data_criacao: '2025-02-05T03:17:42.840000',
  detalhe_estrangeiro: null,
  estado: 'PB',
  fk_clifor_id: 140004,
  id: 66748,
  numero: '55',
  obriga_atualizar_endereco: false,
  rua: 'Rua Ant\u00f4nio Laurentino Ramos',
  ultima_atualizacao_endereco: null,
  usuario_alteracao: null,
  usuario_criacao: 0,
}
const ESTRANGEIRO_ADDRESS_RESPONSE = {
  bairro: null,
  cep: null,
  cidade: null,
  complemento: null,
  data_alteracao: '2025-02-05T09:49:18.300000',
  data_criacao: '2025-02-05T09:35:55.783000',
  detalhe_estrangeiro: 'Border Street, Radius, 50',
  estado: null,
  fk_clifor_id: 140005,
  id: 66749,
  numero: null,
  obriga_atualizar_endereco: false,
  rua: null,
  ultima_atualizacao_endereco: '2025-02-05',
  usuario_alteracao: 69064,
  usuario_criacao: 0,
}

export const userMeAddressMock = http.get<never, never, UserMeAddress>('/addresses/me', () => {
  if (currentUserEmail === EMAIL_BENFEITOR_ESTRANGEIRO_MOCK) {
    return HttpResponse.json(ESTRANGEIRO_ADDRESS_RESPONSE, { status: 200 })
  }

  return HttpResponse.json(ADDRESS_RESPONSE, { status: 200 })
})
