import { Form, notification, Spin } from 'antd';
import { StyledForm } from '../../../components/Form/styles';
import { CpfCnpjInput } from '../../Campaign/CampaignIdentification/components/IdentificationForm/CpfCnpjInput';
import { StyledButton } from '../../../components/Button';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { DataService } from '../../../../../lib/dataService';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useAuth } from '../../../../../context/Auth';

interface StatusRegistroResponse {
  atualizar_conta: boolean;
  possui_conta: boolean;
}

interface CadastroCarteiraFormBody {
  cpf?: string;
  cnpj?: string;
}

const NotificationButton = styled.button`
  background-color: #2a2420;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #181512;
    transition: all 0.2s ease-in-out;
  }
`;

export function CarteiraPrimeiraEtapa() {
  const { logout } = useAuth();

  const isEmbed = location.pathname.includes('embed');

  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const isFetching = useIsFetching({ queryKey: ['status-registro'] });

  useEffect(() => {
    dispatch(() => logout());
  }, [dispatch]);

  const handleFinish = async (values: CadastroCarteiraFormBody) => {
    const { cpf, cnpj } = values;

    const tipo_documento = (cpf && 'cpf') || (cnpj && 'cnpj');
    const numero_documento = cpf || cnpj;

    if (!tipo_documento || !numero_documento) {
      throw new Error('Documento inválido. Por favor, insira um CPF, CNPJ ou documento de identidade.');
    }

    try {
      const data = await queryClient.fetchQuery<StatusRegistroResponse>({
        queryKey: ['status-registro', tipo_documento, numero_documento],
        queryFn: async () => {
          const response = await DataService.get<StatusRegistroResponse>(
            `users/buscar-status-registro?numero_documento=${numero_documento}&tipo_documento=${tipo_documento}`,
          );
          return response?.data;
        },
      });

      const { possui_conta, atualizar_conta } = data;

      if (possui_conta && !atualizar_conta) {
        const routeToPush = isEmbed ? '/embed/carteira/confirmada' : '/carteira/confirmada';
        history.push(routeToPush);
      }

      if (possui_conta && atualizar_conta) {
        notification.warning({
          message: 'Localizamos seu cadastro!',
          description: <p style={{ color: 'gray' }}>Por favor, faça o login para atualizar suas informações.</p>,
          placement: 'top',
          btn: (
            <NotificationButton
              onClick={() => {
                history.push('/login');
                notification.destroy();
              }}
            >
              Efetuar Login
            </NotificationButton>
          ),
          duration: 30,
        });
      }

      if (!possui_conta) {
        const routeToPush = isEmbed ? '/embed/carteira/cadastro' : '/carteira/cadastro';

        notification.error({
          message: 'Não localizamos seu cadastro!',
          description: <p style={{ color: 'gray' }}>Por favor, clique nesse botão abaixo para prosseguir.</p>,
          placement: 'top',
          btn: (
            <NotificationButton
              onClick={() => {
                history.push(routeToPush, {
                  numero_documento: cpf || cnpj,
                  tipo_documento: (cpf && 'cpf') || (cnpj && 'cnpj'),
                });
                notification.destroy();
              }}
            >
              Efetuar cadastro
            </NotificationButton>
          ),
          duration: 30,
        });
      }
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '0 1.3rem', minHeight: isEmbed ? '0vh' : '60vh' }}>
      <StyledForm form={form} onFinish={handleFinish} layout="vertical">
        <CpfCnpjInput form={form} nostyle />

        <StyledButton htmlType="submit" style={{ margin: '2rem auto' }} disabled={!!isFetching}>
          Enviar {isFetching ? <Spin /> : null}
        </StyledButton>
      </StyledForm>
    </div>
  );
}
