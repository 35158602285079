import styled from 'styled-components';

interface BackgroundImageProps {
  backgroundImage: string;
}

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background-image: url(${(props) => props.backgroundImage});
  height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  overflow: visible;

  @media (max-width: 768px) {
    height: 100vh;
    width: 100vw;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(40, 32, 30) 100%);
    pointer-events: none;
    z-index: 0;
  }
`;

interface TextureContainer {
  texture: string;
}

export const TextureContainer = styled.div<TextureContainer>`
  background-image: url(${(props) => props.texture});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #28201e;
  /* min-height: 30vh; */
  width: 100%;
  padding-top: 10rem;
  overflow: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (min-width: 576px) {
    padding-top: 3rem;
  }

  @media (min-width: 768px) {
    padding-top: 8rem;
  }

  @media (min-width: 992px) {
    padding-top: 18rem;
  }

  @media (min-width: 992px) {
    padding-top: 18rem;
  }

  @media (min-width: 2500px) {
    padding-top: 23rem;
  }
`;

export const ContentWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
`;

export const WrapperCampaigns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vh;

  @media (min-width: 992px) {
    height: 100vh;
  }

  @media (min-width: 1536px) {
    height: 100vh;
  }
`;

export const SliderWrapper = styled.div`
  /* .slick-track {
    display: flex;
    gap: 48px;
  } */
  overflow: hidden;

  @media (min-width: 768px) {
    max-width: 1180px;
    margin: 0 auto;
  }

  .slick-track {
    display: flex !important;
    align-items: center;
  }

  @media (max-width: 768px) {
    & .slick-list {
      margin: 0 -7px;
      & .slick-slide > div {
        padding: 0 10px;
      }
    }
  }

  @media (min-width: 768px) {
    .slick-slide {
      margin: 0 20px;
    }
  }
`;

export const MessageContainer = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  width: 100%;

  padding: 0 1rem;

  p:first-child {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.family.secondary};
    font-weight: 900;
    text-shadow: 3px 3px 1px rgba(0, 0, 0, 1);
    font-size: 1.5rem;
    padding-top: 2rem;

    @media (min-width: 1536px) {
      font-size: 1.875rem !important;
    }
  }

  p:nth-child(2) {
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 2px 2px 2px #000000be;
    font-family: ${({ theme }) => theme.fonts.family.secondary};
    font-size: ${({ theme }) => theme.fonts.size.small};
    margin-bottom: 0.5rem;
    opacity: 0.8;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1rem;
    @media (min-width: 1536px) {
      max-width: 850px;
    }
  }

  p:nth-child(3) {
    text-align: right;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.family.secondary};
    font-size: ${({ theme }) => theme.fonts.size.small};
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 480px) {
      padding: 0 1rem;
    }

    @media (min-width: 1536px) {
      max-width: 950px;
      font-size: 1rem;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-top: 2rem;
`;

export const TitleStyled = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 900;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.5rem;

  @media (min-width: 1536px) {
    font-size: 1.875rem !important;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
`;
