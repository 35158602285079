import styled from 'styled-components';

export const LiveMetricsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const MetricsCard = styled.div`
  background-color: var(--card-bg-color);
  display: flex;
  gap: 0.5rem;
  flex: 1;
  max-width: 600px;
  border-radius: 12px;
  padding: 1rem;

  .icon {
    color: var(--text-main-color);
    border-radius: 50%;
    margin: auto 0;
  }

  h4 {
    margin: 0;
  }

  p {
    color: var(--text-main-color);
    opacity: 50%;
    margin: 0;
  }
`;
