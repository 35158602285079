import React, { ReactNode } from 'react';
import { Select as AntSelect, ConfigProvider, SelectProps as AntSelectProps } from 'antd';
import moment from 'moment';
import ptBR from 'antd/lib/locale/pt_BR';
import * as S from './BaseSelect.Styles';
import 'moment/locale/pt-br';
import BaseSpin from '../spin/BaseSpin';

moment.locale('pt-br');

export const { Option } = AntSelect;

interface BaseSelectProps extends AntSelectProps {
  className?: string;
  width?: string;
  shadow?: boolean;
  children?: ReactNode;
  isLoading?: boolean;
}

export const BaseSelect = React.forwardRef<any, BaseSelectProps>(
  ({ className, width, shadow, children, isLoading, ...props }, ref) => {
    return (
      <ConfigProvider locale={ptBR}>
        <S.Select
          getPopupContainer={(triggerNode) => triggerNode}
          ref={ref}
          className={className}
          notFoundContent={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isLoading ? <BaseSpin /> : <h4 style={{ margin: 0 }}>Sem resultados.</h4>}
            </div>
          }
          $width={width}
          $shadow={shadow}
          {...props}
        >
          {children}
        </S.Select>
      </ConfigProvider>
    );
  },
);

BaseSelect.displayName = 'BaseSelect';
