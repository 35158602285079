import { useState } from 'react'
import BaseModal from '../../../../../components/modal/BaseModal'
import { StyledMenuFinance, Label, ModalContent, StyledFilterIcon } from '../../../styles'

import { BaseSelect } from '../../../../../components/select/BaseSelect'
import { MultipleSelect } from '../../../../../components/multiple-select/multiple-select'
import { useFetchCampaignsOptions } from '../../../../../hooks'
import { paymentMethodOptions } from '../../../utils-constants'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'

export function FilterModal({ filters, setFilters }) {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })
  const { data: allCampaigns = [] } = useFetchCampaignsOptions()

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <StyledFilterIcon onClick={() => setIsModalOpen(true)} />
      <BaseModal
        title="Filtros da Lista de Doações de Hoje"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        closeIcon="X"
        size="large"
      >
        <ModalContent>
          <div>
            <Label>Método de Pagamento:</Label>
            <BaseSelect
              width="11rem"
              dropdownRender={(menu) => <StyledMenuFinance>{menu}</StyledMenuFinance>}
              allowClear
              options={paymentMethodOptions}
              value={filters.tipo_pagamento || ''}
              onChange={(value) => handleInputChange({ tipo_pagamento: value })}
            />
          </div>
          <div>
            <Label>Campanhas:</Label>
            <MultipleSelect
              list={allCampaigns?.map((campaign) => campaign.value)}
              options={allCampaigns}
              value={filters.fk_campanha_id}
              onChange={(value) => handleInputChange({ fk_campanha_id: value })}
            />
          </div>
        </ModalContent>
      </BaseModal>
    </>
  )
}
