import { useLocation } from 'react-router-dom'
import { StyledContent } from './styles'
import React from 'react'

interface ContentWrapperProps {
  children: React.ReactNode
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  const location = useLocation()
  const isRootPath = location.pathname === '/'

  return <StyledContent isRootPath={isRootPath}>{children}</StyledContent>
}
