import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Tooltip, UploadFile } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'
import {
  RegisterGroupUserBodySchema,
  useRegisterGroupUser,
} from '../../../admin/hooks/users/Mutations/useRegisterGroupUser'
import { InfoCircleOutlined } from '@ant-design/icons'
import { TutorialModal } from './tutorial-modal'

export type ImageFile = UploadFile & {
  file: File
}

interface GeneralFormProps {
  markAsFinished?: Function
  initialFields?: Record<string, any>
}

export function GeneralForm({ markAsFinished, initialFields }: Readonly<GeneralFormProps>) {
  const history = useHistory()

  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { mutateAsync: registerGroupUser, isPending: isPendingGroupUser } = useRegisterGroupUser()

  async function handleFinish() {
    await form.validateFields()

    const formFields: RegisterGroupUserBodySchema['data'] = form.getFieldsValue([
      'quant_membros_grupo',
      'tempo_de_administrador',
      'nome_grupo',
      'link_grupo',
    ])

    const formattedData: RegisterGroupUserBodySchema['data'] = {
      ...formFields,
      fk_usuario_id: initialFields?.usuario_id,
    }

    try {
      await registerGroupUser({
        data: formattedData,
      })

      if (markAsFinished) {
        markAsFinished()
      }

      history.push('/membro-exercito/aguardar-aprovacao')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (initialFields) {
      return form.setFieldsValue({
        ...initialFields,
        telefone: initialFields?.telefone?.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3'),
        cep: initialFields?.cep?.toString().replace(/^(\d{5})(\d{3})$/, '$1-$2'),
      })
    }
  }, [form, initialFields])

  return (
    <>
      <TutorialModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Form form={form} layout="vertical">
        <div>
          <Row gutter={30}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                name="nome"
                label="Nome Completo"
                rules={[
                  { min: 3, message: 'Mínimo 3 caracteres' },
                  { required: true, message: 'Obrigatório' },
                ]}
              >
                <Input placeholder="Nome Completo" disabled={Boolean(initialFields?.nome)} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'Deve ser um e-mail válido',
                    whitespace: true,
                  },
                  { required: true, message: 'Obrigatório' },
                ]}
              >
                <Input placeholder="E-mail" disabled={initialFields?.email} />
              </Form.Item>
            </Col>
          </Row>

          {/* not editable when the form is autofilled with email from api */}
          {!initialFields?.email && (
            <Row gutter={30}>
              <Col xl={24} md={24} xs={24}>
                <Form.Item
                  name="new_email"
                  label="Confirmar E-mail"
                  dependencies={['email']}
                  rules={[
                    { type: 'email', message: '' },
                    { required: true, message: 'Obrigatório' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('email') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Confirmar" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={30}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                name="telefone"
                label="Telefone"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o número de telefone!',
                  },
                  {
                    ...(initialFields?.pais === 'brasil' && {
                      min: 15,
                      message: 'O número deve ter 10 dígitos!',
                    }),
                  },
                  {
                    ...(initialFields?.pais !== 'brasil' && {
                      max: 30,
                      message: 'O número deve ter no máximo 30 dígitos!',
                    }),
                  },
                ]}
              >
                {initialFields?.pais !== 'brasil' ? (
                  <Input placeholder="Telefone" disabled={initialFields?.telefone} />
                ) : (
                  <ReactInputMask mask="(99) 99999-9999" maskChar={null} disabled={initialFields?.telefone}>
                    {() => <Input placeholder="(99) 99999-9999" disabled={initialFields?.telefone} />}
                  </ReactInputMask>
                )}
              </Form.Item>

              {/* <Form.Item
                name="telefone"
                label="Telefone"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o número de telefone!',
                  },
                  {
                    pattern: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                    message: 'Telefone inválido!',
                  },
                ]}
              >
                <ReactInputMask mask="(99) 99999-9999" maskChar={null} disabled={initialFields?.telefone}>
                  {() => <Input placeholder="(99) 99999-9999" disabled={initialFields?.telefone} />}
                </ReactInputMask>
              </Form.Item> */}
            </Col>

            <Col xl={12} md={12} xs={24}>
              <Form.Item
                name="nome_grupo"
                label="Nome do grupo"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o nome do grupo!',
                  },
                  { type: 'string', min: 5, message: 'Mínimo 5 caracteres' },
                  { type: 'string', max: 25, message: 'Máximo 25 caracteres' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24}>
              <Form.Item
                name="link_grupo"
                label={
                  <Tooltip title="Como obter o link?" popupVisible={false}>
                    <div
                      style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }}
                      onClick={() => {
                        setIsModalOpen(true)
                      }}
                    >
                      Link do grupo
                      <InfoCircleOutlined />
                    </div>
                  </Tooltip>
                }
                rules={[
                  { required: true, message: 'Por favor, insira o link do grupo!' },
                  {
                    pattern: /^https:\/\/chat\.whatsapp\.com\/[A-Za-z0-9]{22}$/,
                    message: 'Por favor, insira um link válido do WhatsApp!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24}>
              <Form.Item
                name="quant_membros_grupo"
                label="Quantidade de membros no grupo"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira a quantidade de membros!',
                  },
                  {
                    type: 'number',
                    min: 1,
                    max: 1024,
                    message: 'A quantidade deve estar entre 1 e 1024 membros!',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24}>
              <Form.Item
                name="tempo_de_administrador"
                label="Há quantos tempo administra? (meses)"
                initialValue={0}
                rules={[
                  {
                    required: false,
                    // message: 'Por favor, insira o tempo de membro!',
                  },
                  {
                    type: 'number',
                    min: 0,
                    max: 120,
                    message: 'O tempo deve estar entre 1 e 120 meses!',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            {/* </>
            )} */}
          </Row>

          <Button
            style={{ width: '100%' }}
            onClick={() => handleFinish()}
            disabled={isPendingGroupUser}
            loading={isPendingGroupUser}
          >
            Concluir
          </Button>
        </div>
      </Form>
    </>
  )
}
