import styled from 'styled-components'
import BaseCard from '../../../../../admin/components/card/BaseCard'

export const LoginBackgroundImage = styled.div`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoginFormCard = styled(BaseCard)`
  max-width: 35rem;

  background-color: #dfdbd9;

  box-shadow: 0px 0px 42px #000000a7;

  label {
    color: #3e3331 !important;
  }

  input {
    background-color: transparent !important;
    color: #3e3331 !important;
  }

  .ant-input-affix-wrapper.ant-input-password {
    input {
      color: #3e3331 !important;
      background-color: transparent !important;
    }
    background-color: transparent !important;
  }

  .ant-input-suffix {
    svg {
      color: #3e3331 !important;
    }
  }

  h4 {
    color: #3e3331 !important;
  }

  button {
    color: #e4e4e4 !important;
    background-color: #1f1d1d !important;
  }
`

export const LoginLogoWrraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem;
  padding: 1rem;

  img {
    width: 8rem;
    height: 8rem;
  }

  @media (max-width: 576px) {
    img {
      width: 6rem;
      height: 6rem;
    }
  }
`
