import { Col, Row, Typography } from 'antd'
import { GetFamiliaPorCpfResponse } from '../../../../../../hooks/agape/queries/useGetFamiliaPorCpf'
import { GetEnderecoCicloDoacaoResponse } from '../../../../../../hooks/agape/queries/useGetEnderecoCicloDoacao'
import { BaseDivider } from '../../../../../../components/divider/BaseDivider'

const { Title, Text } = Typography

interface VisualizacaoEnderecoFamiliaProps {
  enderecoFamilia: GetFamiliaPorCpfResponse['endereco']
  enderecoCicloDoacao: GetEnderecoCicloDoacaoResponse
}

const estilos = {
  border: '1px solid var(--text-secondary-color)',
  borderRadius: '10px',
  padding: '10px',
  width: '50%',
  textAlign: 'center',
  margin: '0 auto',
}

export function VisualizacaoEnderecoFamilia({
  enderecoFamilia,
  enderecoCicloDoacao,
}: VisualizacaoEnderecoFamiliaProps) {
  const { abrangencia } = enderecoCicloDoacao

  const getEstiloCampoAbrangencia = (field: string) => (field === abrangencia ? estilos : {})

  return (
    <Row gutter={16} style={{ padding: '20px' }}>
      {/* Primeiro endereço */}
      <Col xs={24} sm={24} md={11}>
        <Title level={3}>Endereço da família</Title>
        <div style={getEstiloCampoAbrangencia('cep')}>
          <Text strong>CEP: </Text>
          <Text>{enderecoFamilia.cep}</Text>
        </div>
        <br />

        <div style={getEstiloCampoAbrangencia('cidade')}>
          <Text strong>Cidade: </Text>
          <Text>{enderecoFamilia.cidade}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('bairro')}>
          <Text strong>Bairro: </Text>
          <Text>{enderecoFamilia.bairro}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('rua')}>
          <Text strong>Rua: </Text>
          <Text>{enderecoFamilia.rua}</Text>
        </div>
        <br />
        <div>
          <Text strong>Número: </Text>
          <Text>{enderecoFamilia.numero}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('complemento')}>
          <Text strong>Complemento: </Text>
          <Text>{enderecoFamilia.complemento}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('estado')}>
          <Text strong>Estado: </Text>
          <Text>{enderecoFamilia.estado}</Text>
        </div>
      </Col>

      {/* Divider vertical */}
      <Col xs={24} sm={24} md={2} style={{ textAlign: 'center' }}>
        <BaseDivider type="vertical" style={{ height: '100%' }} />
      </Col>

      {/* Segundo endereço */}
      <Col xs={24} sm={24} md={11}>
        <Title level={3}>Endereço Ciclo de Doação</Title>
        <div style={getEstiloCampoAbrangencia('cep')}>
          <Text strong>CEP: </Text>
          <Text>{enderecoCicloDoacao.cep}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('cidade')}>
          <Text strong>Cidade: </Text>
          <Text>{enderecoCicloDoacao.cidade}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('bairro')}>
          <Text strong>Bairro: </Text>
          <Text>{enderecoCicloDoacao.bairro}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('rua')}>
          <Text strong>Rua: </Text>
          <Text>{enderecoCicloDoacao.rua}</Text>
        </div>
        <br />
        <div>
          <Text strong>Número: </Text>
          <Text>{enderecoCicloDoacao.numero}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('complemento')}>
          <Text strong>Complemento: </Text>
          <Text>{enderecoCicloDoacao.complemento}</Text>
        </div>
        <br />
        <div style={getEstiloCampoAbrangencia('estado')}>
          <Text strong>Estado: </Text>
          <Text>{enderecoCicloDoacao.estado}</Text>
        </div>
      </Col>
    </Row>
  )
}
