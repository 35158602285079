import { Col, Form, Row } from 'antd'
import { ErrorResult } from '../../../../../../../../components/result/resultStatus/ErrorResult'
import { useGetEnderecoFamilia } from '../../../../../../../../hooks/agape/queries/useGetEnderecoFamilia'
import { BaseSpin } from '../../../../../../../../components/spin/BaseSpin.Styles'
import { Check, Pen, X } from '@phosphor-icons/react'
import BaseButton from '../../../../../../../../components/button/BaseButton'
import { useEffect, useState } from 'react'
import { AddressForm } from '../../../../../../../../components/forms/components/Address/AddressAdmin'
import BaseForm from '../../../../../../../../components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../../../../../components/inputs/BaseInput/BaseInput'
import { EnderecoFormularioCadastroFamilia } from '../../../../../CadastrarFamilia/FormularioCadastrarFamilia'
import { useEditarEnderecoFamilia } from '../../../../../../../../hooks/agape/mutations/useEditarEnderecoFamilia'
import { useGetPermissions } from '../../../../../../../../hooks/users/Queries/useGetSelfPermissions'
import { formatCEP } from '../../../../../../../../../../utility/utility'
import { MapaEnderecoFamilia } from './MapaEnderecoFamilia'

interface EnderecoFamiliaProps {
  idFamilia: number
}

export function EnderecoFamilia({ idFamilia }: EnderecoFamiliaProps) {
  const { data: permissions } = useGetPermissions()

  const [form] = Form.useForm()

  const [isEditing, setIsEditing] = useState(false)

  const { mutateAsync: editarEnderecoFamilia, isPending: isPendingEditarEnderecoFamilia } = useEditarEnderecoFamilia()

  const {
    data: enderecoFamilia,
    isLoading: isLoadingEnderecoFamilia,
    isError: isErrorEnderecoFamilia,
  } = useGetEnderecoFamilia({ fk_familia_agape_id: idFamilia })

  useEffect(() => {
    if (enderecoFamilia) {
      form.setFieldsValue({
        ...enderecoFamilia,
        cep: formatCEP(enderecoFamilia.cep),
      })
    }
  }, [enderecoFamilia])

  if (isErrorEnderecoFamilia) {
    return <ErrorResult />
  }

  if (isLoadingEnderecoFamilia) {
    return (
      <Col xs={24}>
        <Row justify="center">
          <BaseSpin />
        </Row>
      </Col>
    )
  }

  function handleIsEditing() {
    setIsEditing((prev) => !prev)
  }

  function handleCancel() {
    handleIsEditing()

    form.setFieldsValue(enderecoFamilia)
  }

  function handleSubmitForm() {
    form.submit()
  }

  async function handleChangeAdddress(data: EnderecoFormularioCadastroFamilia) {
    await editarEnderecoFamilia({ fk_familia_agape_id: idFamilia, endereco: data })

    handleIsEditing()
  }

  const obterEnderecoFamiliaFormatado = () => {
    return {
      endereco: [
        enderecoFamilia?.cep,
        enderecoFamilia?.estado,
        enderecoFamilia?.cidade,
        enderecoFamilia?.bairro,
        enderecoFamilia?.rua,
        enderecoFamilia?.numero,
        enderecoFamilia?.complemento ?? null,
        enderecoFamilia?.ponto_referencia ?? null,
      ]
        .filter((parte) => parte)
        .join(' - '),
      latitude: enderecoFamilia?.latitude,
      longitude: enderecoFamilia?.longitude,
    }
  }

  const enderecoFamiliaFormatado = obterEnderecoFamiliaFormatado()

  return (
    <>
      <MapaEnderecoFamilia endereco={enderecoFamiliaFormatado} />

      {permissions?.permissoes.familia_agape.editar === 1 && (
        <Row justify="end" style={{ marginBottom: '1rem' }}>
          <Col>
            {isEditing ? (
              <Row gutter={[10, 10]}>
                <Col>
                  <BaseButton size="small" onClick={handleCancel}>
                    <X size={15} />
                  </BaseButton>
                </Col>

                <Col>
                  <BaseButton size="small" onClick={handleSubmitForm} loading={isPendingEditarEnderecoFamilia}>
                    {!isPendingEditarEnderecoFamilia && <Check size={15} />}
                  </BaseButton>
                </Col>
              </Row>
            ) : (
              <BaseButton type="ghost" onClick={handleIsEditing}>
                <Pen size={20} />
              </BaseButton>
            )}
          </Col>
        </Row>
      )}

      <Form form={form} disabled={!isEditing} onFinish={handleChangeAdddress} layout="vertical">
        <AddressForm form={form} />
        <BaseForm.Item name="ponto_referencia" label="Ponto de referência">
          <BaseInput placeholder="Local de referência" />
        </BaseForm.Item>
      </Form>
    </>
  )
}
