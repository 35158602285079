import { Row, Skeleton } from 'antd';
import styled from 'styled-components';

export const CampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
`;

export const CampaignContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  justify-content: center;
`;

export const CampaignImageWrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
`;

export const CampaignImage = styled.img`
  width: 350px;
  border-radius: 1rem;
`;

export const CampaignSkeletonImage = styled(Skeleton.Image)`
  width: 350px !important;
  height: 250px !important;
  border-radius: 1rem;
`;

export const CampaignDescription = styled.p`
  max-width: 35rem;
  font-weight: 600;
  padding-inline: 1rem;
  font-family: ${(props) => props.theme.fonts.family.secondary};
`;

export const ButtonRow = styled(Row)`
  justify-content: center;
  gap: 2rem;
`;

export const CampaignDetailTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  color: ${({ theme }) => theme.colors['gray-800']};
  font-weight: 900;
`;
