import { http, HttpResponse } from 'msw'
import { LoginPayload } from '../../context/Auth'

export const EMAIL_ADMIN_MOCK = 'usuario-admin@gmail.com'
export const EMAIL_BENFEITOR_MOCK = 'usuario-benfeitor@gmail.com'
export const EMAIL_BENFEITOR_ESTRANGEIRO_MOCK = 'usuario-benfeitor-estrangeiro@gmail.com'

export let currentUserEmail: string | null = null
export const setCurrentUserEmail = (email: string | null) => {
  currentUserEmail = email
}

const response = {
  access_token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3Mzg2ODIyNDksImV4cCI6NDEwNTQzNzQzMywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.blat6m-RfnOJjo8yEdjH8OOXSMLazirKTAT8u924axY',
  refresh_token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3Mzg2ODIyNDksImV4cCI6NDEwNTQzNzQzMywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.blat6m-RfnOJjo8yEdjH8OOXSMLazirKTAT8u924axY',
  type_token: 'Bearer',
  perfil_id: 1,
}

export const loginMock = http.post<never, LoginPayload>('/auth/login', async ({ request }) => {
  const { email } = await request.json()

  if (email === EMAIL_ADMIN_MOCK || email === EMAIL_BENFEITOR_MOCK || email === EMAIL_BENFEITOR_ESTRANGEIRO_MOCK) {
    setCurrentUserEmail(email)

    return HttpResponse.json(response, { status: 200 })
  }

  return new HttpResponse(null, { status: 500 })
})
