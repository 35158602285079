import { PreCadastroForm } from './PreCadastroForm';
import {} from '../styles';
import { Title } from '../../../components/Title';
import { useRouteMatch } from 'react-router-dom';

export function PreCadastroVocacional() {
  const { path } = useRouteMatch();

  const vocacional = path.includes('masculino') ? 'masculino' : 'feminino';

  return (
    <>
      <Title>Pré-cadastro vocacional {vocacional}</Title>
      <PreCadastroForm vocacional={vocacional} />
    </>
  );
}
