import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setTheme } from '../../../redux/theme/theme'
import { MoonSunSwitch } from '../../../container/admin/components/MoonSunSwitch/MoonSunSwitch'

export const ThemePicker = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.theme.theme)

  const handleClickButton = (theme) => {
    dispatch(setTheme(theme))
  }

  return (
    <MoonSunSwitch
      isMoonActive={theme === 'dark'}
      onClickMoon={() => handleClickButton('dark')}
      onClickSun={() => handleClickButton('light')}
    />
  )
}
