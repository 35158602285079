import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { Endereco } from '../../../../../utility/endereco'

interface GetEnderecoFamiliaProps {
  fk_familia_agape_id: number
}

export interface EnderecoFamiliaResponse extends Endereco {
  id: number
  ponto_referencia: string
  latitude: number
  longitude: number
}

export function useGetEnderecoFamilia({ fk_familia_agape_id }: GetEnderecoFamiliaProps) {
  const enderecoFamilia = useQuery({
    queryKey: ['ENDERECO_FAMILIA', fk_familia_agape_id],
    queryFn: async () => {
      const response = await DataService.get<EnderecoFamiliaResponse>(
        `/agape/buscar-endereco-familia/${fk_familia_agape_id}`,
      )

      return response.data
    },
  })

  return enderecoFamilia
}
