import { DollarOutlined } from '@ant-design/icons'
import { BaseCarousel } from '../../../../components/carousel/BaseCarousel'
import { GroupCard } from '../../../../components/GroupCard'
import { formatToBrazilianCurrency } from '../../../../../../utility/utility'
import { useFetchTodayDonation } from '../../../../hooks/donations/queries/useFetchTodayDonation'
import { useFetchMonthTotalDonations } from '../../../../hooks/donations/queries/useFetchMonthTotalDonations'
import { useFetchDailyAverageDonation } from '../../../../hooks/donations/queries/useFetchDailyAverageDonation'
import { useFetchMonthAverageDonation } from '../../../../hooks/donations/queries/useFetchMonthAverageDonation'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function DonationsListCards() {
  const todayDonation = useFetchTodayDonation()
  const monthTotalDonations = useFetchMonthTotalDonations()
  const dailyAverageDonation = useFetchDailyAverageDonation()
  const monthAverageDonation = useFetchMonthAverageDonation()

  const donationsData = [
    {
      title: 'Total do Dia',
      icon: DollarOutlined,
      isError: todayDonation?.isError,
      balance: todayDonation?.data?.porcentagem_dia,
      isLoading: todayDonation?.isPending,
      number: (
        <BlurDiv>
          {`${formatToBrazilianCurrency(todayDonation?.data?.valor_total)} | ${
            todayDonation?.data?.quant_pedidos
          } doações`}
        </BlurDiv>
      ),
    },
    {
      title: 'Total do Mês',
      icon: DollarOutlined,
      isError: monthTotalDonations?.isError,
      balance: monthTotalDonations?.data?.porcentagem_valor_mes,
      isLoading: monthTotalDonations?.isPending,
      number: (
        <BlurDiv>
          {`${
            monthTotalDonations?.data?.valor_total_mes
              ? formatToBrazilianCurrency(monthTotalDonations?.data?.valor_total_mes)
              : '-'
          } | ${monthTotalDonations?.data?.quant_pedidos_mes ?? 0} doações`}
        </BlurDiv>
      ),
    },
    {
      title: 'Média Diária',
      icon: DollarOutlined,
      isError: dailyAverageDonation?.isError,
      balance: undefined,
      isLoading: dailyAverageDonation?.isPending,
      number: (
        <BlurDiv>
          {`${
            dailyAverageDonation?.data?.media_valores_diaria
              ? formatToBrazilianCurrency(dailyAverageDonation?.data?.media_valores_diaria)
              : '-'
          }`}
        </BlurDiv>
      ),
    },
    {
      title: 'Média Mensal',
      icon: DollarOutlined,
      isError: monthAverageDonation?.isError,
      balance: undefined,
      isLoading: monthAverageDonation?.isPending,
      number: (
        <BlurDiv>
          {' '}
          {`${
            monthAverageDonation?.data?.media_valor_mensal
              ? formatToBrazilianCurrency(monthAverageDonation?.data?.media_valor_mensal)
              : '-'
          }`}
        </BlurDiv>
      ),
    },
  ]

  return (
    <div style={{ marginBottom: '1.2rem' }}>
      <BaseCarousel slidesToShow={3} slidesToScroll={3} title="" style={{}}>
        {donationsData.map((donation, index) => (
          <GroupCard key={index} {...donation} />
        ))}
      </BaseCarousel>
    </div>
  )
}
