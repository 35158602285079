import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface ListagemVoluntariosResponse {
  page: number
  pages: number
  total: number
  voluntarios: {
    data_inicio: string
    data_ultimo_acesso: string
    id: number
    nome: string
  }[]
}

export interface ListagemVoluntariosProps {
  filters?: {
    queryString?: string
    page?: number
    per_page?: number
  }
}

export function useGetListagemVoluntarios({ filters }: ListagemVoluntariosProps) {
  const listagemVoluntarios = useQuery({
    queryKey: ['LISTAGEM_VOLUNTARIOS', filters],
    queryFn: async () => {
      const response = await DataService.get<ListagemVoluntariosResponse>(
        `/agape/listar-voluntarios-agape?${filters?.queryString ?? ''}`,
      )

      return response.data
    },
  })

  return listagemVoluntarios
}
