import { Input } from 'antd'
import styled from 'styled-components'

import { StylesProps } from '.'

export const StyledInputStyles = styled(Input)<StylesProps>`
  :not(.ant-input-status-error, .ant-input-affix-wrapper-status-error) {
    border: ${({ border }) => border || `1.5px solid #d9d9d9`};
  }

  border-radius: ${({ borderRadius = 8 }) => `${borderRadius}px`} !important;
  line-height: ${({ lineHeight }) => lineHeight || 2.5} !important;
  min-width: ${({ minWidth }) => `${minWidth}rem`};

  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.white} !important;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors['gray-400']} !important;
  }

  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors['gray-800']} !important;
  }

  &:disabled {
    background-color: #f0f0f0 !important;
  }

  /* Estilizando o placeholder */
  &::placeholder {
    color: ${({ strongPlaceholder }) => (strongPlaceholder ? 'black' : '#bfbfbf')};
    font-weight: ${({ strongPlaceholder }) => (strongPlaceholder ? 'bold' : 'normal')};
  }
`

export const StyledInputPassword = styled(Input.Password)<StylesProps>`
  padding: 0.5rem;

  border: ${({ border }) => border || `1.5px solid d9d9d9`};

  border-radius: ${({ borderRadius = 8 }) => `${borderRadius}px`} !important;

  line-height: ${({ lineHeight }) => lineHeight || 1.9} !important;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors['gray-400']} !important;
  }

  &:disabled {
    background-color: #f0f0f0 !important;
  }

  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors['gray-800']} !important;
  }
`
