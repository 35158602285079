// import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { notification } from 'antd'
import FeatherIcon from 'feather-icons-react'
import moment, { Moment } from 'moment'
import { formatToBrazilianCurrency } from '../../../../../../utility/utility'
import BaseCard from '../../../../components/card/BaseCard'
import { BaseSelect, Option } from '../../../../components/select/BaseSelect'
import { BaseSpin } from '../../../../components/spin/BaseSpin.Styles'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { useFetchCampaignsOptions } from '../../../../hooks'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import { BlurDiv } from '@/context/Blur/BlurDiv'

const ColumnsFieldMap = {
  id: 'id',
  metodo: 'metodo',
  pedido: 'pedido_id',
  cliente: 'benfeitor',
  data: 'data',
  campanha: 'campanha',
  valor: 'valor',
}

interface FiltersType {
  nome?: string
  campanha_id?: string
  data_inicio?: string
  data_fim?: string
  forma_pagamento?: string
  page?: number
  per_page?: number
}

interface RecurrenceDonationTableProps {
  dados?: any
  handleInputChange: (filters: Record<string, any>, isPagination?: boolean) => void
  isLoading: boolean
  isError: boolean
  visibleFilters?: Array<'nome' | 'data' | 'campanha' | 'forma_pagamento'>
  visibleColumns?: Array<'id' | 'metodo' | 'pedido' | 'cliente' | 'data' | 'campanha' | 'valor'>
  filters?: FiltersType
}

export function RecurrenceDonationTable({
  dados,
  handleInputChange,
  isLoading,
  isError,
  visibleFilters = [],
  visibleColumns = [],
  filters,
}: RecurrenceDonationTableProps) {
  const campaignsOptions = useFetchCampaignsOptions()

  const disabledDate = (current: Moment) => {
    const today = moment()
    return current && (current.year() !== today.year() || current.month() !== today.month())
  }

  const dataSource = dados?.data?.lista?.map((item, key) => {
    return {
      key: key,
      benfeitor: item.nome,
      campanha: item.campanha,
      id: item.clifor_id,
      data: item.data_donation,
      metodo: item.metodo_pagamento,
      pedido_id: {
        copyValue: () => {
          navigator.clipboard
            .writeText(item.pedido_id)
            .then(() => {
              notification.success({
                message: <div style={{ fontWeight: 'bold', color: '#50556f' }}>Id do pedido copiado</div>,
                description: (
                  <div>
                    <div style={{ color: '#757ca3', fontWeight: 600 }}>{item?.nome}</div>
                    <div style={{ color: '#8b8ea4' }}>{item?.pedido_id}</div>
                  </div>
                ),
              })
            })
            .catch((error) => {
              notification.error({
                message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
              })
              console.error('Falha ao copiar id da transferência para a área de transferência:', error)
            })
        },
      },
      valor: formatToBrazilianCurrency(item?.valor),
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Cliente',
      dataIndex: 'benfeitor',
      key: 'benfeitor',
      align: 'center',
      ...(visibleFilters.includes('nome') && {
        filterDropdown: () => (
          <BaseCard>
            <BaseSelect
              showSearch
              placeholder="Nome"
              allowClear
              style={{ width: '10rem' }}
              notFoundContent={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {' '}
                  {isLoading ? <BaseSpin /> : <h4 style={{ margin: 0 }}>Sem resultados.</h4>}{' '}
                </div>
              }
              suffixIcon={<FeatherIcon icon="search" size={14} />}
              onSearch={(value) => handleInputChange({ nome: value })}
              onChange={(value) => handleInputChange({ nome: value })}
            >
              {dados?.lista?.map((item, key) => (
                <Option value={item.nome} key={key}>
                  {item.nome}
                </Option>
              ))}
            </BaseSelect>
          </BaseCard>
        ),
      }),
      filteredValue: filters?.nome && [filters?.nome],
    },
    {
      title: 'Campanha',
      dataIndex: 'campanha',
      key: 'campanha',
      align: 'center',
      ...(visibleFilters.includes('campanha') &&
        campaignsOptions?.data && {
          filterDropdown: () => (
            <BaseCard>
              <BaseSelect
                showSearch
                placeholder="Selecione a campanha"
                allowClear
                style={{ width: '100%' }}
                size="middle"
                onChange={(value) => handleInputChange({ campanha_id: value })}
                notFoundContent={
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {' '}
                    {isLoading ? <BaseSpin /> : <h4 style={{ margin: 0 }}>Sem resultados.</h4>}{' '}
                  </div>
                }
              >
                {campaignsOptions?.data?.map((c, key) => {
                  if (c.objetivo === 'doacao') {
                    return (
                      <Option value={c.value} key={key}>
                        {c.label}
                      </Option>
                    )
                  }
                  return null
                })}
              </BaseSelect>
            </BaseCard>
          ),
        }),
      filteredValue: filters?.campanha_id && [filters?.campanha_id],
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      align: 'center',
      ...(visibleFilters.includes('data') && {
        filterDropdown: () => (
          <BaseCard>
            <BaseDatePicker
              range
              onChange={(_: null, dateStrings: string[]) => {
                const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
                const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

                handleInputChange({
                  data_inicio: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
                  data_fim: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
                })
              }}
              format="DD/MM/YYYY"
              placeholder={['Inicial', 'Final']}
              disabledDate={disabledDate}
            />
          </BaseCard>
        ),
      }),
      filteredValue: filters?.data_inicio && filters?.data_fim && [filters?.data_inicio, filters?.data_fim],
    },
    {
      title: 'Método',
      dataIndex: 'metodo',
      key: 'metodo',
      align: 'center',
      ...(visibleFilters.includes('forma_pagamento') && {
        filterDropdown: () => (
          <BaseCard>
            <BaseSelect
              placeholder="Selecione o método"
              allowClear
              style={{ width: '100%' }}
              onChange={(value) => handleInputChange({ forma_pagamento: value })}
            >
              <Option value="2">Pix</Option>
              <Option value="3">Boleto</Option>
              <Option value="1">Cartão de crédito</Option>
            </BaseSelect>
          </BaseCard>
        ),
      }),
      filteredValue: filters?.forma_pagamento && [filters?.forma_pagamento],
    },
    {
      title: 'Pedido',
      dataIndex: 'pedido_id',
      key: 'pedido_id',
      align: 'center',
      render: ({ copyValue }: { copyValue: () => void }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FeatherIcon
            icon="copy"
            size={20}
            style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
            onClick={copyValue}
          />
        </div>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      align: 'center',
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
  ]

  return (
    <BaseTable
      dataSource={dataSource}
      columns={columns.filter((column) =>
        visibleColumns.includes(Object.keys(ColumnsFieldMap).find((key) => ColumnsFieldMap[key] === column.dataIndex)),
      )}
      pagination={{
        total: dados?.data?.pagination?.total_items,
        pageSize: dados?.data?.pagination?.per_page,
        showSizeChanger: true,
      }}
      isError={isError}
      isLoading={isLoading}
      onChange={(pagination) => {
        handleInputChange({ page: pagination.current }, true)
        handleInputChange({ per_page: pagination.pageSize }, true)
      }}
    />
  )
}
