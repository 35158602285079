import styled from 'styled-components'
import { GradientHeaderProps } from '.'

export const StyledGradientHeader = styled.div<GradientHeaderProps>`
  background: linear-gradient(20deg, ${(props) => props.colorOne} 20%, ${(props) => props.colorTwo} 100%);

  -webkit-background-clip: text;
  background-clip: text;

  color: transparent;
  text-align: center;
  font-size: ${(props) => props.fontSize || '24px'};
  font-family: 'DM Serif Display', serif;
  line-height: 115%;
`
