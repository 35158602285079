import styled from 'styled-components'

interface CardContainerProps {
  active: boolean
  height?: string
}

export const CardContainer = styled.div<CardContainerProps>`
  width: 100%;
  border-radius: 12px !important;
  background-color: ${({ active }) => (active ? '#5b5b5b' : '#e9e9e9')};
  display: flex;
  flex-direction: column;
  border: solid #cccccc 2px;
  border-bottom: solid ${({ active }) => (active ? '#1f1f1f' : '#c3c3c3')} 0.40rem;
  cursor: pointer;
  justify-content: center;

  &:hover {
    background-color: ${({ active }) => (!active ? '#c3c3c3' : "#353535")};
    border-bottom: solid ${({ active }) => (active ? '#1f1f1f' : '#909090')} 0.40rem;
  }

  transition: all 0.2s;
  box-sizing: border-box;
`;

export const IconContainer = styled.div<{ active: boolean }>`
  color: ${({ active }) => (active ? '#ffffff' : '#434343')};
  text-align: center;
`

export const TextContainer = styled.div<{ active: boolean }>`
  font-weight: 500;
  text-align: center;
  font-size: 1.15rem;
  font-family: 'Roboto';
  color: ${({ active }) => (active ? '#ffffff' : '#434343')};
  /* padding: 0.5rem 0.8rem; */

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`

export const ValueContainer = styled.div`
  text-align: center;
  padding-top: 0.55rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`
