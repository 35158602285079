import styled from 'styled-components'

export const Container = styled.div`
  margin: 4.5rem 0 2rem 0;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 600px) {
    max-width: 1264px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 800px) {
    margin-bottom: 5rem;
  }
`

export const TextWrapper = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #efe5d8;

  span {
    font-weight: bold;
  }
`

export const Button = styled.button`
  background-color: #19c78a;
  color: white;
  display: flex;
  border: 0;
  text-align: center;
  padding: 1rem 4rem;
  margin: 0 auto;
  border-radius: 40px;
  div {
    line-height: 1.5;
  }
`
