import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetAllPreCadastroProps {
  filters?: {
    queryString?: string | null
    created_at?: string
    email?: string
    nome?: string
    telefone?: string
    pais?: string
    vocacional?: 'masculino' | 'feminino'
    id?: string
    status?: 'pendente' | 'aprovado'
    page?: number
    per_page?: number
  }
}

export interface GetAllPreCadastroResponse {
  current_page: number
  pages: number
  total: number
  pre_cadastros: Array<{
    created_at: string
    email: string
    id: number
    nome: string
    status: 'aprovado' | 'pendente'
    telefone: string
    pais: string
    responsavel: string | null
  }>
}

export function useFetchGetAllPreCadastro({ filters }: GetAllPreCadastroProps) {
  const allPreCadastro = useQuery({
    queryKey: ['GET_ALL_PRE_CADASTRO', filters],
    queryFn: async () => {
      const response = await DataService.get<GetAllPreCadastroResponse>(
        `vocacional/listar-pre-cadastros?${filters?.queryString ?? ''}`,
      )
      return response.data
    },
  })

  return allPreCadastro
}
