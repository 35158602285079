import GroupCardAnalytics from './GroupCardAnalytics'
import GroupCardBalance from './GroupCardBalance'
import GroupCardContent from './GroupCardContent'
import GroupCardNumber from './GroupCardNumber'
import GroupCardRoot from './GroupCardRoot'
import GroupCardTitle from './GroupCardTitle'

interface GroupCardProps {
  isLoading: boolean
  isError: boolean
  icon: any
  number: any
  balance?: number
  title: string
}

export const GroupCardBase = {
  Root: GroupCardRoot,
  Title: GroupCardTitle,
  Content: GroupCardContent,
  Number: GroupCardNumber,
  Analytics: GroupCardAnalytics,
  Balance: GroupCardBalance,
}

export function GroupCard({ isLoading, isError, icon, number, balance, title }: GroupCardProps) {
  return (
    <GroupCardBase.Root isLoading={isLoading} isError={isError} style={{ height: '100% ' }}>
      <GroupCardBase.Content>
        <GroupCardBase.Title title={title} Icon={icon} />
        <GroupCardBase.Analytics>
          <GroupCardBase.Number info={number} />
          {balance && <GroupCardBase.Balance number={balance} />}
        </GroupCardBase.Analytics>
      </GroupCardBase.Content>
    </GroupCardBase.Root>
  )
}
