import { MagnifyingGlass } from '@phosphor-icons/react'
import BaseCard from '../../../../../components/card/BaseCard'
import { BaseInput } from '../../../../../components/inputs/BaseInput/BaseInput'
import moment from 'moment'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'
import { BaseSelect } from '../../../../../components/select/BaseSelect'
import BaseRadio from '../../../../../components/radio/BaseRadio'

const etapaOptions = [
  {
    label: 'Pré-Cadastro',
    value: 'pre-cadastro',
  },
  {
    label: 'Cadastro',
    value: 'cadastro',
  },
  {
    label: 'Ficha Vocacional',
    value: 'ficha-vocacional',
  },
]
type IdFilterProps = {
  value: string | undefined
  handleInputChange: ({ id }: { id: string }) => void
}

function IdFilter({ handleInputChange, value }: IdFilterProps) {
  return (
    <BaseCard>
      <h4>Id</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Id"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ id: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type NomeFilterProps = {
  value: string | undefined
  handleInputChange: ({ nome }: { nome: string }) => void
}

function NomeFilter({ handleInputChange, value }: NomeFilterProps) {
  return (
    <BaseCard>
      <h4>Nome</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Nome"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ nome: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type EmailFilterProps = {
  value: string | undefined
  handleInputChange: ({ email }: { email: string }) => void
}

function EmailFilter({ handleInputChange, value }: EmailFilterProps) {
  return (
    <BaseCard>
      <h4>Email</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="E-mail"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ email: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type TelefoneFilterProps = {
  value: string | undefined
  handleInputChange: ({ telefone }: { telefone: string }) => void
}

function TelefoneFilter({ handleInputChange, value }: TelefoneFilterProps) {
  return (
    <BaseCard>
      <h4>Telefone</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Telefone"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ telefone: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type CadastroEmFilterProps = {
  value: { initial_date: string; end_date: string } | undefined
  handleInputChange: ({ initial_date, end_date }: { initial_date: string; end_date: string }) => void
}

function CadastroEmFilter({ handleInputChange, value }: CadastroEmFilterProps) {
  const formattedValue = [
    value?.initial_date ? moment(value.initial_date, 'YYYY-MM-DD') : null,
    value?.end_date ? moment(value.end_date, 'YYYY-MM-DD') : null,
  ]

  return (
    <div>
      <h4>Cadastro em</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        style={{ width: '100%' }}
        onChange={(_: never, dateStrings: [string, string]) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

          handleInputChange({
            initial_date: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            end_date: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY"
        placeholder={['Inicial', 'Final']}
      />
    </div>
  )
}

type EtapaFilterProps = {
  value: 'pre-cadastro' | 'cadastro' | 'ficha-vocacional' | undefined
  handleInputChange: ({ etapa }: { etapa: 'pre-cadastro' | 'cadastro' | 'ficha-vocacional' }) => void
}

function EtapaFilter({ handleInputChange, value }: EtapaFilterProps) {
  return (
    <div>
      <h4>Etapa</h4>
      <BaseSelect
        placeholder="Selecione a etapa"
        value={value}
        allowClear
        onChange={(value) => {
          handleInputChange({ etapa: value })
        }}
        options={etapaOptions}
      />
    </div>
  )
}

type PaisFilterProps = {
  value: string | undefined
  handleInputChange: ({ pais }: { pais: string }) => void
}

function PaisFilter({ value, handleInputChange }: PaisFilterProps) {
  return (
    <BaseCard>
      <h4>País</h4>
      <BaseRadio.Group onChange={(e) => handleInputChange({ pais: e.target.value })} value={value}>
        <BaseRadio.Button value="brasil">Brasileiro</BaseRadio.Button>
        <BaseRadio.Button value="estrangeiro">Estrangeiro</BaseRadio.Button>
        <BaseRadio.Button>X</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

type StatusFilterProps = {
  value: 'pendente' | 'aprovado' | undefined
  handleInputChange: ({ status }: { status: 'pendente' | 'aprovado' }) => void
}

function StatusFilter({ value, handleInputChange }: StatusFilterProps) {
  return (
    <BaseCard>
      <h4>Status</h4>
      <BaseRadio.Group onChange={(e) => handleInputChange({ status: e.target.value })} value={value}>
        <BaseRadio.Button value="pendente">Pendente</BaseRadio.Button>
        <BaseRadio.Button value="aprovado">Aprovado</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

export const VocacionalPreCadastroFilters = {
  Id: IdFilter,
  Nome: NomeFilter,
  Telefone: TelefoneFilter,
  Pais: PaisFilter,
  Email: EmailFilter,
  CadastroEm: CadastroEmFilter,
  Etapa: EtapaFilter,
  Status: StatusFilter,
}
