import React from 'react'
import { NavLink } from 'react-router-dom'
// import Heading from '../../../../components/heading/heading'
import permissionDeniedPhoto from '../../../../static/img/pages/404.svg'
import styled from 'styled-components'
import BaseButton from '../button/BaseButton'

export function PermissionDenied() {
  return (
    <div>
      <ErrorWrapper>
        <img src={permissionDeniedPhoto} alt="404" />
        <h1 style={{ fontSize: '3.6rem', lineHeight: '2rem' }}>Permissão Negada!</h1>
        <h4>Desculpe! você não tem permissão para visualizar essa página.</h4>
        <NavLink to="/admin">
          <BaseButton size="default" to="/admin">
            Voltar
          </BaseButton>
        </NavLink>
      </ErrorWrapper>
    </div>
  )
}

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* min-height: 100vh; */
  height: 70vh;
  text-align: center;
  img {
    margin-bottom: 100px;
    max-width: 400px;
    width: 100%;
    @media only screen and (max-width: 575px) {
      margin-bottom: 30px;
    }
  }
  .error-text {
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 35px;
    color: ${({ theme }) => theme['extra-light-color']};
  }
  p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 26px;
  }
  button {
    height: 44px;
  }
`
