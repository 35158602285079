import { Container } from './styles'
import logosImage from '../../images/logos.svg'
import { Result } from 'antd'
export function WaitForApprove() {
  return (
    <Container>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={logosImage} alt="Logo" />
        </div>
        <div className="text-wrapper">
          <Result
            status={'success'}
            title={'Bendito seja Deus pelo seu SIM!'}
            subTitle={
              <>
                'Em breve entraremos em contato com você através do e-mail cadastrado. Por meio dele, você receberá o
                acesso ao CTH, a plataforma de treinamento do Exército de São Miguel!'
                <br />
                <div style={{ marginTop: '1rem' }}>Quem como Deus!</div>
              </>
            }
          />
        </div>
      </div>
    </Container>
  )
}
