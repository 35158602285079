import BaseCard from '../../../../../components/card/BaseCard'
import BaseRadio from '../../../../../components/radio/BaseRadio'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'
import moment from 'moment'
import { useGetItensEstoque } from '../../../../../hooks/agape/queries/useGetItensEstoque'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'
import { TipoMovimentacoesHistorico } from '../../../../../hooks/agape/queries/useGetHistoricoMovimentacoes'

interface NomeFilterProps {
  value?: number | null
  handleInputChange: ({ fk_estoque_agape_id }: { fk_estoque_agape_id: number }) => void
}

function NomeFilter({ value, handleInputChange }: NomeFilterProps) {
  const { data: itensEstoque } = useGetItensEstoque()

  return (
    <BaseCard>
      <h4>Nome</h4>

      <BaseSelect
        placeholder="Nome do item"
        value={value}
        onChange={(value) => handleInputChange({ fk_estoque_agape_id: value })}
        allowClear
      >
        {itensEstoque?.estoques.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.item}
          </Option>
        ))}
      </BaseSelect>
    </BaseCard>
  )
}

interface TipoMovimentacaoFilter {
  value?: TipoMovimentacoesHistorico | null
  handleInputChange: ({ tipo_movimentacao }: { tipo_movimentacao: TipoMovimentacoesHistorico }) => void
}

function TipoMovimentacaoFilter({ value, handleInputChange }: TipoMovimentacaoFilter) {
  return (
    <BaseCard>
      <h4>Tipo de movimentação</h4>
      <BaseRadio.Group value={value} onChange={(e) => handleInputChange({ tipo_movimentacao: e.target.value })}>
        <BaseRadio.Button value="entrada">Entrada</BaseRadio.Button>
        <BaseRadio.Button value="saida">Saída</BaseRadio.Button>
        <BaseRadio.Button>X</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

type DataFilterProps = {
  value: { data_movimentacao_inicial: string; data_movimentacao_final: string } | undefined
  handleInputChange: ({
    data_movimentacao_inicial,
    data_movimentacao_final,
  }: {
    data_movimentacao_inicial: string
    data_movimentacao_final: string
  }) => void
}

function DataFilter({ value, handleInputChange }: DataFilterProps) {
  const formattedValue = [
    value?.data_movimentacao_inicial ? moment(value.data_movimentacao_inicial, 'YYYY-MM-DD') : null,
    value?.data_movimentacao_final ? moment(value.data_movimentacao_final, 'YYYY-MM-DD') : null,
  ]

  return (
    <BaseCard>
      <h4>Data da movimentação</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        style={{ width: '100%' }}
        onChange={(_: never, dateStrings: [string, string]) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

          handleInputChange({
            data_movimentacao_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_movimentacao_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY"
        placeholder={['Inicial', 'Final']}
      />
    </BaseCard>
  )
}
export const HistoricoMovimentacoesFilters = {
  Nome: NomeFilter,
  TipoMovimentacao: TipoMovimentacaoFilter,
  Data: DataFilter,
}
