import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Row } from 'antd'

import { StyledButton } from '../../../components/Button'
import { StyledForm } from '../../../components/Form/styles'
import { MainCard } from '../../../components/Card'
import { Title } from '../../../components/Title'
import { StyledInput } from '../../../components/Inputs'
import { useSendEmailResetPassword } from '../../../../admin/hooks/users/Mutations/useSendEmailResetPassword'

function ForgotPassword() {
  const { mutateAsync: resetPassword, isPending: isPendingResetPassword } = useSendEmailResetPassword()

  const [form] = Form.useForm()

  const handleSubmit = async ({ email }) => {
    try {
      await resetPassword({ email })
      form.resetFields()
    } catch {}
  }

  return (
    <div style={{ width: '100%', maxWidth: '1120px', marginInline: 'auto' }}>
      <MainCard>
        <StyledForm form={form} name="forgotPass" onFinish={(values) => handleSubmit(values)} layout="vertical">
          <Title>Esqueceu sua senha?</Title>
          <p>
            Digite o endereço de e-mail que você usou quando se inscreveu e enviaremos instruções para redefinir sua
            senha.
          </p>
          <Form.Item
            label="Seu e-mail"
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor digite seu email de acesso!',
                type: 'email',
              },
            ]}
          >
            <StyledInput placeholder="email@exemplo.com" />
          </Form.Item>
          <Form.Item>
            <Row justify="center">
              <StyledButton htmlType="submit" loading={isPendingResetPassword}>
                Enviar instruções de reinicialização
              </StyledButton>
            </Row>
          </Form.Item>
          <p className="return-text">
            Voltar para <NavLink to="/login">login</NavLink>.
          </p>
        </StyledForm>
      </MainCard>
    </div>
  )
}

export default ForgotPassword
