import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Form, Switch } from 'antd';
import { StyledInput } from '../../../../../components/Inputs';
import styled from 'styled-components';
import { usePaymentMethod } from '../../context';

export const CpfCnpjInput = () => {
  const { form } = usePaymentMethod();

  const [value, setValue] = useState('');
  const [isCnpj, setIsCnpj] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
    form.setFieldValue('cpf_cnpj', event.target.value);
  };

  const toggleType = (checked) => {
    setIsCnpj(checked);
    setValue('');
    form.setFieldValue('cpf_cnpj', '');
  };

  const mask = isCnpj ? '99.999.999/9999-99' : '999.999.999-99';
  const placeholder = isCnpj ? 'CNPJ' : 'CPF';

  return (
    <>
      <Form.Item
        className="cpf-cnpj-item"
        name="cpf_cnpj"
        label="CPF/CNPJ"
        style={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'relative' }}
        rules={[
          { min: 11, message: 'Mínimo 11 caracteres' },
          { required: true, message: 'Obrigatório' },
        ]}
      >
        <CustomSwitch>
          <Switch checked={isCnpj} onChange={toggleType} checkedChildren="CNPJ" unCheckedChildren="CPF" />
        </CustomSwitch>
        <InputMask mask={mask} value={value} onChange={handleChange} placeholder={placeholder}>
          {(inputProps) => <StyledInput border="2.5px solid #acacac" lineHeight={2.5} {...inputProps} />}
        </InputMask>
      </Form.Item>
    </>
  );
};

const CustomSwitch = styled.div`
  position: relative;

  .ant-switch {
    background-color: #463b3a;
    margin-left: 116px;
    margin-top: -40px;
    position: absolute;
    z-index: 999;
  }

  .ant-switch-checked {
    background-color: #463b3a !important;
  }
`;
