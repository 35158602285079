import React from 'react'
import { Checkbox, Col, Form, InputNumber, Row } from 'antd'
import ReactInputMask from 'react-input-mask'
import { StyledInput } from '../../../../../components/Inputs'
import { PhotoUpload } from '../../../../../components/UploadPhoto'
import { ImageFile } from '..'

interface StepOneGeneral {
  imageUrl: ImageFile[]
  setImageUrl: React.Dispatch<React.SetStateAction<ImageFile[]>>
  initialFields: Record<string, any>
}

export function StepOneGeneral({ imageUrl, setImageUrl, initialFields }: StepOneGeneral) {
  return (
    <div>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="nome"
            label="Nome Completo"
            rules={[
              { min: 3, message: 'Mínimo 3 caracteres' },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="Nome Completo" disabled={initialFields?.nome} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Deve ser um e-mail válido',
                whitespace: true,
              },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="E-mail" disabled={initialFields?.email} />
          </Form.Item>
        </Col>
      </Row>

      {/* not editable when the form is autofilled with email from api */}
      {!initialFields?.email && (
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="new_email"
              label="Confirmar E-mail"
              dependencies={['email']}
              rules={[
                { type: 'email', message: '' },
                { required: true, message: 'Obrigatório' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
                  },
                }),
              ]}
            >
              <StyledInput placeholder="Confirmar" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="telefone"
            label="Telefone"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o número de telefone!',
              },
              {
                pattern: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                message: 'Telefone inválido!',
              },
            ]}
          >
            <ReactInputMask mask="(99) 99999-9999" maskChar={null} disabled={initialFields?.telefone}>
              {() => <StyledInput placeholder="(99) 99999-9999" disabled={initialFields?.telefone} />}
            </ReactInputMask>
          </Form.Item>
        </Col>

        <Col xl={12} md={12} xs={24}>
          <Form.Item
            name="quant_membros_grupo"
            label="Quantidade de membros no grupo"
            rules={[
              {
                required: true,
                message: 'Por favor, insira a quantidade de membros!',
              },
              {
                type: 'number',
                min: 1,
                max: 500,
                message: 'A quantidade deve estar entre 1 e 500 membros!',
              },
            ]}>
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            name="tempo_de_membro"
            label="Há quantos meses é membro do Exército De São Miguel"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o tempo de membro!',
              },
              {
                type: 'number',
                min: 1,
                max: 120,
                message: 'O tempo deve estar entre 1 e 120 meses!',
              },
            ]}>
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      </Row >


      <Row justify="center">
        <Form.Item
          label={`Print de tela do grupo do WhatsApp`}
          name="image"
          required={false}
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>

            <PhotoUpload
              initialFileList={imageUrl}
              setImage={(image) => {
                setImageUrl(image as ImageFile[])
              }}
              numeroFotos={1}
            />
          </div>
        </Form.Item>
      </Row>


      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                  new Error(
                    'É necessário aceitar nossos Termos de Serviços e Política de Privacidade',
                  ),
                ),
          },
        ]}
      >
        <Checkbox>
          Criar uma conta significa que você concorda com nossos Termos de Uso e Política de
          Privacidade.
        </Checkbox>
      </Form.Item>
    </div>
  )
}
