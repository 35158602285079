import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetTodayDonationsPerHour(filters) {
  return useQuery({
    queryKey: ['TODAY_DONATIONS_PER_HOUR', filters],
    queryFn: async () => {
      const query = await DataService.get(
        `admin/dash-board/donations/doacoes-diaria-per-hour?${filters?.queryString || ''}`,
      )
      return query?.data
    },
  })
}
