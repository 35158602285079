import { List } from 'antd'
import styled from 'styled-components'

export const StyledList = styled(List)`
  color: var(--text-main-color);
  background-color: var(--background-color);

  word-break: break-word; // Wrap palavras longas

  .ant-list-header {
    font-weight: bold;
  }

  .ant-list-header,
  .ant-spin-nested-loading,
  .ant-spin-container {
    margin: 0;
  }

  .ant-list-item {
    color: var(--text-main-color);
  }
`
