import { Descriptions } from 'antd'
import styled from 'styled-components'

export const StyledDescription = styled(Descriptions)`
  color: var(--text-main-color);
  background-color: var(--modal-background-color);

  .ant-descriptions-title {
    color: var(--text-main-color);
  }

  .ant-descriptions-view {
    border: 2px solid var(--input-border-color);
    border-radius: 10px;
    padding: 0.2rem;
  }

  .ant-descriptions-item-label {
    color: var(--text-main-color);
    background-color: var(--background-color);
    border-color: var(--input-border-color);
    text-align: start;
    font-weight: bold;
  }

  .ant-descriptions-row {
    border-color: var(--input-border-color);
  }

  .ant-descriptions-item-content {
    color: var(--text-main-color);
    border-right-color: var(--input-border-color);
  }

  .ant-typography {
    margin: 0;
  }
`
