import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { useParams, Link } from 'react-router-dom'
import { PageHeader } from '../../components/page-headers/page-headers'
import { useGetActionDetails } from '../../hooks/actions/queries/useGetActionDetails'
import { RecordViewWrapper } from '../users/Style'
import BaseCard from '../../components/card/BaseCard'
import BaseButton from '../../components/button/BaseButton'
import BaseForm from '../../components/forms/BaseForm/BaseForm'
import BaseSpin from '../../components/spin/BaseSpin'
import { BaseInput } from '../../components/inputs/BaseInput/BaseInput'
import BaseRadio from '../../components/radio/BaseRadio'

import { BaseUploadPhoto } from '../../components/upload/BaseUploadPhoto'
import { useEditAction } from '../../hooks/actions/mutations/useEditAction'

const EditActions = () => {
  const { id } = useParams()
  const { data: leads, isPending: isActionPending } = useGetActionDetails(id)
  const [form] = Form.useForm()
  const { mutateAsync: editAction, isPending: isSubmitting } = useEditAction()
  const [hasPhoto, setHasPhoto] = useState(false)

  const [backgroundImage, setBackgroundImage] = useState([])
  const [bannerImage, setBannerImage] = useState([])

  useEffect(() => {
    if (leads) {
      if (leads?.banner) {
        setBannerImage([
          {
            url: leads.banner,
          },
        ])
      }
      if (leads?.background) {
        setBackgroundImage([
          {
            url: leads.background,
          },
        ])
      }
      form.setFieldsValue(leads)
      setHasPhoto(!!form.getFieldValue('label_foto'))
    }
  }, [leads, form])

  const handleEditAction = async (values) => {
    try {
      const formValues = {
        ...values,
      }

      const formData = new FormData()

      formData.append('data', JSON.stringify(formValues))
      formData.append('banner', bannerImage[0]?.file)
      formData.append('background', backgroundImage[0]?.file)

      await editAction({ fk_acao_id: id, formData })
    } catch {}
  }

  return (
    <RecordViewWrapper>
      <PageHeader
        buttons={[
          <div>
            <BaseButton>
              <Link to="/admin/actions/view-all">
                <span>Listagem</span>
              </Link>
            </BaseButton>
          </div>,
        ]}
        ghost
        title="Editar Ação"
      />

      <Row gutter={15}>
        <Col className="w-100" md={24}>
          <BaseCard>
            {leads && (
              <BaseForm form={form} onFinish={handleEditAction} layout="vertical">
                <Row>
                  <Col xs={24} sm={12} md={6}>
                    <BaseForm.Item
                      name="status"
                      label="Ação Ativa"
                      rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
                    >
                      <BaseRadio.Group>
                        <BaseRadio.Button value={true}>Sim</BaseRadio.Button>
                        <BaseRadio.Button value={false}>Não</BaseRadio.Button>
                      </BaseRadio.Group>
                    </BaseForm.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12}>
                    <BaseForm.Item
                      name="preenchimento_foto"
                      label="Preenchimento de foto"
                      rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
                    >
                      <BaseRadio.Group onChange={(e) => setHasPhoto(e.target.value)}>
                        <BaseRadio.Button value={true}>Sim</BaseRadio.Button>
                        <BaseRadio.Button value={false}>Não</BaseRadio.Button>
                      </BaseRadio.Group>
                    </BaseForm.Item>
                  </Col>
                </Row>
                <Row gutter={[40, 10]}>
                  <Col md={12}>
                    <BaseForm.Item name="titulo" label="Título" rules={[{ required: true, message: 'Obrigatório' }]}>
                      <BaseInput placeholder="Título" />
                    </BaseForm.Item>
                  </Col>
                  <Col md={12}>
                    <BaseForm.Item name="nome" label="Nome" rules={[{ required: true, message: 'Obrigatório' }]}>
                      <BaseInput placeholder="Nome" />
                    </BaseForm.Item>
                  </Col>
                </Row>

                <Row gutter={[40, 10]}>
                  <Col md={12}>
                    <BaseForm.Item
                      name="descricao"
                      label="Descrição"
                      rules={[{ required: true, message: 'Obrigatório' }]}
                    >
                      <BaseInput.TextArea placeholder="Descrição" />
                    </BaseForm.Item>
                  </Col>
                  <Col md={12}>
                    {hasPhoto && (
                      <BaseForm.Item
                        name="label_foto"
                        label="Label da Foto"
                        rules={[{ required: true, message: 'Por favor, insira o label da foto' }]}
                      >
                        <BaseInput style={{ width: '100%' }} placeholder="Insira uma label para a foto" />
                      </BaseForm.Item>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <BaseForm.Item
                      name="banner"
                      label="Imagem do Banner"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => e.fileList}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, envie uma imagem!',
                          validator: () => {
                            if (bannerImage.length === 0) {
                              return Promise.reject(new Error('Por favor, envie uma imagem!'))
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <BaseUploadPhoto
                        initialFileList={bannerImage}
                        setImage={setBannerImage}
                        validateField={() => form.validateFields(['banner'])}
                        numeroFotos={1}
                        aspect={9 / 2}
                        description="Altura 1440px | Largura 320px"
                      />
                    </BaseForm.Item>
                  </Col>
                  <Col md={12} sm={24}>
                    <BaseForm.Item
                      name="background"
                      label="Imagem do Background"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => e.fileList}
                    >
                      <BaseUploadPhoto
                        initialFileList={backgroundImage}
                        setImage={setBackgroundImage}
                        numeroFotos={1}
                        aspect={9 / 2}
                        description="Altura 1440px | Largura 320px"
                      />
                    </BaseForm.Item>
                  </Col>
                </Row>

                <BaseButton
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  style={{ marginTop: '1rem' }}
                >
                  Enviar
                </BaseButton>
              </BaseForm>
            )}
            {isActionPending && (
              <div style={{ display: 'grid', placeItems: 'center', height: '40vh' }}>
                <BaseSpin />
              </div>
            )}
          </BaseCard>
        </Col>
      </Row>
    </RecordViewWrapper>
  )
}

export default EditActions
