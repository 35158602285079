import { Form, Row } from 'antd'
import ReactInputMask from 'react-input-mask'
import { validateTelefone } from '../../../../utility/utility'
import { StyledForm } from '../../../benfeitor/components/Form/styles'
import { StyledInput } from '../../../benfeitor/components/Inputs'
import { StyledTextArea } from '../../components/TextArea'
import { useRegisterIntentionLead } from '../../../admin/hooks/users/Mutations/useRegisterIntentionLead'
import { IntentionFormWrapper, IntentionLeadButton } from './styles'

interface CadastroLeadIntencaoFormData {
  nome: string
  email: string
  telefone: string
  intencao: string
}

export function CadastroLeadIntencaoForm() {
  const [form] = Form.useForm()

  const {
    mutateAsync: registerLeadIntention,
    isPending: isPendingRegisterLeadIntention,
    isSuccess,
  } = useRegisterIntentionLead()

  async function handleFormSubmit(values: CadastroLeadIntencaoFormData) {
    try {
      const { nome, email, telefone, intencao } = values

      await registerLeadIntention({ nome, email, telefone, intencao })
    } catch (error) {
      console.log(error)
    }
  }

  if (isSuccess) {
    return (
      <iframe
        width="1000"
        height="650"
        id="embeddedFrame"
        src="https://missaohesed11448.ac-page.com/saojose-confirmado"
      ></iframe>
    )
  }

  return (
    <IntentionFormWrapper>
      <StyledForm
        form={form}
        onFinish={(values) => handleFormSubmit(values as CadastroLeadIntencaoFormData)}
        layout="vertical"
      >
        <Form.Item name="nome" rules={[{ required: true, message: 'Obrigatório' }]}>
          <StyledInput strongPlaceholder placeholder="Digite seu nome" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            { type: 'email', message: 'Deve ser um e-mail válido', whitespace: true },
            { required: true, message: 'Obrigatório' },
          ]}
        >
          <StyledInput strongPlaceholder placeholder="Digite seu email" />
        </Form.Item>

        <Form.Item
          name="telefone"
          rules={[
            {
              required: true,
              message: 'Obrigatório',
            },
            {
              validator: validateTelefone,
            },
          ]}
        >
          <ReactInputMask mask="(99) 99999-9999" maskChar="" placeholder="Digite seu telefone">
            {(inputProps: any) => <StyledInput strongPlaceholder {...inputProps} />}
          </ReactInputMask>
        </Form.Item>

        <Form.Item
          name="intencao"
          rules={[
            {
              required: true,
              message: 'Obrigatório',
            },
          ]}
        >
          <StyledTextArea strongPlaceholder placeholder="Digite suas intenções" showCount maxLength={250} />
        </Form.Item>

        <Row justify="center">
          <IntentionLeadButton block htmlType="submit" loading={isPendingRegisterLeadIntention}>
            ENVIAR INTENÇÃO
          </IntentionLeadButton>
        </Row>
      </StyledForm>
    </IntentionFormWrapper>
  )
}
