import { Modal } from 'antd'
import BaseButton from '../../../../components/button/BaseButton'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDeleteUserVocacional } from '../../../../hooks/vocacional/mutations/useDeleteVocacionalUser'
import { useState } from 'react'
import { ListagemVocacional } from '../../../../hooks/vocacional/mutations/useUpdateVocacionalStatus'

const { confirm } = Modal

interface ExcluirUsuarioVocacionalProps {
  fk_usuario_vocacional_id: number
  nome_usuario: string
  listagem: ListagemVocacional | 'desistentes' | 'recusados'
}

export function ExcluirUsuarioVocacional({
  fk_usuario_vocacional_id,
  nome_usuario,
  listagem,
}: ExcluirUsuarioVocacionalProps) {
  const [selectedRow, setSelectedRow] = useState<number | undefined>()

  const { mutateAsync: deleteUserVocacional } = useDeleteUserVocacional()

  async function handleDeleteUserVocacional() {
    try {
      setSelectedRow(fk_usuario_vocacional_id)
      await deleteUserVocacional({ fk_usuario_vocacional_id, listagem })
    } finally {
      setSelectedRow(undefined)
    }
  }

  const confirmarExclusaoUsuarioVocacional = () => {
    confirm({
      title: `Tem certeza que deseja apagar o usuário ${nome_usuario}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Apagar',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        handleDeleteUserVocacional()
      },
    })
  }

  return (
    <BaseButton
      onClick={confirmarExclusaoUsuarioVocacional}
      severity="error"
      loading={selectedRow === fk_usuario_vocacional_id}
    >
      Excluir
    </BaseButton>
  )
}
