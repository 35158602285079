import styled from 'styled-components'
import { Modal } from 'antd'
import { BASE_COLORS, FONT_WEIGHT } from '../../../../styles/Themes/Admin/constants'

const BORDER_RADIUS = '16px'

export const StyledModal = styled(Modal)`
  color: var(--modal-text);

  .ant-modal-content {
    /* position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%); */

    border-radius: ${BORDER_RADIUS};
    background-color: var(--modal-background-color);

    .ant-modal-close {
      span {
        svg {
          color: var(--text-main-color);
        }
      }
    }
  }

  .ant-modal-title {
    color: var(--modal-text);
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .ant-modal-body {
    background-color: var(--modal-background-color);
    color: var(--modal-text);
    overflow-y: visible;
    border-radius: ${BORDER_RADIUS};
  }

  .ant-modal-header {
    background-color: var(--modal-background-color);
    border-top-left-radius: ${BORDER_RADIUS};
    border-top-right-radius: ${BORDER_RADIUS};
    border-bottom: 1px solid var(--modal-border-color) !important;
  }

  .ant-modal-footer {
    border-bottom-left-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};

    background-color: var(--modal-background-color);

    border-top: 1px solid var(--modal-border-color) !important;

    .ant-btn {
      font-weight: ${FONT_WEIGHT.medium};
    }

    .ant-btn:first-child {
      background-color: transparent;
      color: var(--modal-first-btn-color);
      border-color: var(--modal-first-btn-color);

      &:hover {
        color: var(--modal-first-btn-color-hover);
        border-color: var(--modal-first-btn-color-hover);
      }
    }

    .ant-btn:nth-child(2) {
      background-color: var(--modal-second-btn-bg-color);
      border-color: var(--modal-second-btn-bg-color);
      &:hover {
        background-color: var(--modal-second-btn-bg-color-hover);
        border-color: var(--modal-second-btn-bg-color-hover);
      }
    }
  }

  .ant-modal-close-x {
    color: ${BASE_COLORS.gray};
  }
`
