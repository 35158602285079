import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface PermissoesUsuario {
  data_criacao_usuario: string
  fk_perfil_id: number
  fk_usuario_id: number
  id: number
  nome_usuario: string
  status_usuario: boolean
}

interface ListarUsuariosPerfilResponse {
  permissoes_usuarios: PermissoesUsuario[]
  page: number
  total: number
}

interface SearchUserProps {
  user?: string
}

export function useSearchUser({ user }: SearchUserProps) {
  const users = useQuery({
    queryKey: ['BUSCA_USUARIO', user],
    queryFn: async () => {
      const filter = `filtro_nome_email_cpf=${user}`

      const response = await DataService.get<ListarUsuariosPerfilResponse>(
        `administradores/listar-usuarios-perfil?${filter}`,
      )
      return response?.data
    },
    enabled: !!user,
  })

  return users
}
