import { TimePicker } from 'antd'
import styled from 'styled-components'

export const StyledTimePicker = styled(TimePicker)`
  background-color: #2b2a2a !important;
  height: 40px !important;
  border-radius: 8px;

  background-color: var(--background-color) !important;
  border-color: var(--input-border-color) !important;
  color: var(--text-main-color);

  &:hover {
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 1px var(--input-border-color) !important;
  }

  .ant-picker-suffix {
    color: var(--text-main-color) !important;
  }

  .ant-picker-input > input{
    color: var(--text-main-color) !important;
  }
`
