import { RootState } from '@/redux/rootReducers'
import { Card } from 'antd'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface ChartCardProps {
  title: string
  children: ReactNode
  loading?: boolean
}

const StyledCard = styled(Card) <{ isDark: boolean }>`
  background: ${({ isDark }) => isDark ? '#1E1E1E' : '#fff'};
  border: 1px solid ${({ isDark }) => isDark ? '#333333' : '#f0f0f0'};
  border-radius: 8px;

  .ant-card-head {
    background: ${({ isDark }) => isDark ? '#1E1E1E' : '#fafafa'};
    border-bottom: 1px solid ${({ isDark }) => isDark ? '#333333' : '#f0f0f0'};
    
    .ant-card-head-title {
      color: ${({ isDark }) => isDark ? '#E1E1E1' : '#000'};
    }
  }

  .ant-card-body {
    background: ${({ isDark }) => isDark ? '#1E1E1E' : '#fff'};
  }
`

export function ChartCard({ title, children, loading }: ChartCardProps) {
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  return (
    <StyledCard title={title} loading={loading} isDark={isDark}>
      {children}
    </StyledCard>
  )
} 