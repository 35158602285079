import BaseButton from '@/container/admin/components/button/BaseButton'
import { useAuth } from '@/context/Auth'

export function SignOutButton() {
  const { logout } = useAuth()

  return (
    <BaseButton
      onClick={() => logout()}
      type="text"
      size="large"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <p style={{ color: 'var(--text-secondary-color)' }}>Sair</p>
    </BaseButton>
  )
}
