import { useState } from 'react'

import { VocacionalInitialFiltersProps } from '../../utils'

import { DesistenciaTabela } from './tabela'
import {
  GetAllDesistenciasProps,
  useFetchGetllAllDesistencias,
} from '../../../../hooks/vocacional/queries/useFetchGetAllDesistencias'
import { generateQueryString, handleFilterChangeAndPagination } from '../../../../../../utility/utility'

export function Desistentes({ vocacionalGender }: VocacionalInitialFiltersProps) {
  const FiltrosDesistencia = { genero: vocacionalGender }

  const initialFilters = {
    queryString: generateQueryString(FiltrosDesistencia),
    ...FiltrosDesistencia,
  } as const

  const [filters, setFilters] = useState<GetAllDesistenciasProps['filters']>(initialFilters)

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: desistentes, isLoading, isError } = useFetchGetllAllDesistencias({ filters })

  return (
    <DesistenciaTabela
      data={desistentes}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      isError={isError}
      filters={filters}
    />
  )
}
