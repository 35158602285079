import styled from 'styled-components'

export const ThirdSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem;
  gap: 2rem;

  .header {
    font-size: 36px;
    line-height: 43px;
  }

  p {
    color: #fbf9f3;
    text-align: center;
    font-size: 22px;
    line-height: 35px;
  }

  @media (min-width: 900px) {
    p {
      max-width: 1075px;
      margin-left: auto;
      margin-right: auto;
      font-size: 32px;
      line-height: 52px;
    }

    .header {
      margin-top: 5rem;
      font-size: 47px;
      line-height: 52px;
    }
  }
`
