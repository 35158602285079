import { Col, Row, Modal } from 'antd'
import BaseButton from '../../../../../../components/button/BaseButton'
import { useState } from 'react'
import { useIniciarCicloDoacao } from '../../../../../../hooks/agape/mutations/useIniciarCicloDoacao'
import { EditarCicloDoacao } from '../EditarCicloDoacao/EditarCicloDoacao'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useExcluirCicloDoacao } from '../../../../../../hooks/agape/mutations/useExcluirCicloDoacao'

const { confirm } = Modal

interface AcoesNaoIniciadoProps {
  idCicloDoacao: number
  nomeAcao: string
}

export function AcoesNaoIniciado({ idCicloDoacao, nomeAcao }: AcoesNaoIniciadoProps) {
  const [isOpenEditarCicloDoacao, setIsOpenEditarCicloDoacao] = useState(false)
  const [loadingIniciarCicloDoacao, setLoadingIniciarCicloDoacao] = useState<number | null>(null)
  const [loadingExcluirCicloDoacao, setLoadingExcluirCicloDoacao] = useState<number | null>(null)

  const { mutateAsync: iniciarCicloDoacao } = useIniciarCicloDoacao()
  const { mutateAsync: excluirCicloDoacao } = useExcluirCicloDoacao()

  function handleIsOpenEditarCicloDoacao() {
    setIsOpenEditarCicloDoacao((prev) => !prev)
  }

  async function handleIniciarCicloDoacao() {
    try {
      setLoadingIniciarCicloDoacao(idCicloDoacao)
      await iniciarCicloDoacao({ idCicloDoacao })
    } finally {
      setLoadingIniciarCicloDoacao(null)
    }
  }

  const confirmarIniciarCicloDoacao = () => {
    confirm({
      title: `Tem certeza que deseja iniciar o ciclo de doação para ${nomeAcao}?`,
      icon: <ExclamationCircleOutlined />,
      cancelText: 'Cancelar',
      okText: 'Sim',
      onOk() {
        handleIniciarCicloDoacao()
      },
    })
  }

  async function handleExcluirCicloDoacao() {
    try {
      setLoadingExcluirCicloDoacao(idCicloDoacao)
      await excluirCicloDoacao({ idCicloDoacao })
    } finally {
      setLoadingExcluirCicloDoacao(null)
    }
  }

  const confirmarExcluirCicloDoacao = () => {
    confirm({
      title: `Tem certeza que deseja excluir o ciclo de doação para ${nomeAcao}?`,
      icon: <ExclamationCircleOutlined />,
      cancelText: 'Cancelar',
      okType: 'danger',
      okText: 'Sim',
      onOk() {
        handleExcluirCicloDoacao()
      },
    })
  }

  return (
    <>
      <Row justify="center" gutter={[10, 10]}>
        <Col>
          <BaseButton
            severity="success"
            onClick={confirmarIniciarCicloDoacao}
            loading={loadingIniciarCicloDoacao === idCicloDoacao}
          >
            Iniciar
          </BaseButton>
        </Col>
        <Col>
          <BaseButton severity="warning" onClick={handleIsOpenEditarCicloDoacao}>
            Detalhes
          </BaseButton>
        </Col>
        <Col>
          <BaseButton
            severity="error"
            onClick={confirmarExcluirCicloDoacao}
            loading={loadingExcluirCicloDoacao === idCicloDoacao}
          >
            Excluir
          </BaseButton>
        </Col>
      </Row>

      <EditarCicloDoacao
        open={isOpenEditarCicloDoacao}
        handleIsOpenAcaoDoacao={handleIsOpenEditarCicloDoacao}
        idCicloDoacao={idCicloDoacao}
        nomeAcao={nomeAcao}
        podeEditar
      />
    </>
  )
}
