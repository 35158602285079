import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import { Membro } from '../../../pages/agape/Familia/CadastrarFamilia/FormularioCadastrarFamilia'

type AdicionarMembroProps = {
  membros: Membro[]
  idFamilia: number
}

export function useAdicionarMembros() {
  const queryClient = useQueryClient()

  const adicionarMembro = useMutation({
    mutationFn: async ({ idFamilia, membros }: AdicionarMembroProps) => {
      await DataService.post(`agape/cadastrar-membros/${idFamilia}`, { membros })
    },
    onSuccess: (_, { idFamilia }) => {
      notification.success({ message: 'Membro(s) cadastrado(s) com sucesso!' })
      queryClient.invalidateQueries({ queryKey: ['GET_MEMBROS_FAMILIA', idFamilia] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_FAMILIA'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar o(s) membro(s)',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return adicionarMembro
}
