import { GetCiclosDoacoesProps } from '../../../../../../hooks/agape/queries/useGetCiclosDoacoes'
import { useGetPermissions } from '../../../../../../hooks/users/Queries/useGetSelfPermissions'
import { CiclosDeDoacaoFilters } from '../filters'

interface GetColumnsProps {
  filters: GetCiclosDoacoesProps['filters']
  handleInputChange: () => void
}

export const getColumns = ({ filters, handleInputChange }: GetColumnsProps) => {
  const { data: permissions } = useGetPermissions()

  const podeFiltrarStatus = permissions?.permissoes.acao_doacao_agape.editar === 1

  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Ação solidária',
      dataIndex: 'acao_solidaria',
      key: 'acao_solidaria',
      filterDropdown: () => (
        <CiclosDeDoacaoFilters.Nome value={filters?.fk_acao_agape_id} handleInputChange={handleInputChange} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...(podeFiltrarStatus && {
        filterDropdown: () => (
          <CiclosDeDoacaoFilters.Status value={filters?.status} handleInputChange={handleInputChange} />
        ),
      }),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
    },
    {
      title: 'Localização',
      dataIndex: 'localizacao',
      key: 'localizacao',
    },
  ]
}
