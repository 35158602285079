import { Steps } from 'antd'
import { StyledSteps } from '../../../validate-user/styles'
import { Step } from '../../../../../benfeitor/pages/Carteira/Cadastro/hooks/useMultistepForm'

interface StepsProgresProps {
  steps: Step[]
  currentStep: number
}

export function StepsProgress({ steps, currentStep }: Readonly<StepsProgresProps>) {
  return (
    <StyledSteps current={currentStep} labelPlacement="vertical">
      {steps.map((item, index) => (
        <Steps.Step icon={item.icon} key={index} title={item.title} />
      ))}
    </StyledSteps>
  )
}
