import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../lib/dataService'

export function useGetAllLogs(filters) {
  const getAllLogs = useQuery({
    queryKey: ['ALL_LOGS', filters],
    queryFn: async () => {
      const response = await DataService.get(`logs?${filters?.queryString ?? ''}`)
      return response?.data
    },
  })

  return getAllLogs
}
