import { Collapse } from 'antd';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    background-color: transparent; /* Garantir transparência no modo ghost */
    border: none; /* Remover bordas externas */
  }

  .ant-collapse-header {
    color: var(--text-main-color) !important;
    font-weight: bold; /* Negrito no texto do cabeçalho */
    padding: 12px 16px; /* Padding no cabeçalho */
    background-color: transparent; /* Fundo transparente para ghost */
    border-radius: 4px; /* Bordas arredondadas para um efeito mais suave */

    &:hover {
      background-color: rgba(0, 0, 0, 0.05); /* Fundo ao passar o mouse */
    }
  }

  .ant-collapse-item {
    border-bottom: 1px solid var(--input-border-color); /* Bordas entre os itens */

    &:last-child {
      border-bottom: none; /* Remover a borda do último item */
    }

    pre {
      color: var(--text-main-color);
    }
  }

  .ant-collapse-content-box {
    background-color: var(--collapse-box-background-color) !important;
    color: var(--text-main-color) !important;
  }
`
