export function AdminMenuDoacoes() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 30 50">
        <g id="money-tree-plant-grow-svgrepo-com" transform="translate(1.517 1.5)">
          <line
            id="Linha_1"
            data-name="Linha 1"
            y2={1.825}
            transform="translate(16.502 5.476)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <line
            id="Linha_2"
            data-name="Linha 2"
            y2={1.825}
            transform="translate(16.502 18.253)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <path
            id="Caminho_9"
            data-name="Caminho 9"
            d="M19.476,7H16.738A2.807,2.807,0,0,0,14,9.738h0a2.807,2.807,0,0,0,2.738,2.738h1.825A2.807,2.807,0,0,1,21.3,15.214h0a2.807,2.807,0,0,1-2.738,2.738H15.825"
            transform="translate(-1.148 0.301)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <circle
            id="Elipse_1"
            data-name="Elipse 1"
            cx={12.777}
            cy={12.777}
            r={12.777}
            transform="translate(3.725 0)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <line
            id="Linha_3"
            data-name="Linha 3"
            y1={21.904}
            transform="translate(16.502 25.555)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <path
            id="Caminho_10"
            data-name="Caminho 10"
            d="M19.811,23.684c4.563,4.563,3.651,12.777,3.651,12.777s-8.214.913-12.777-3.651S7.034,20.034,7.034,20.034,15.248,19.121,19.811,23.684Z"
            transform="translate(-6.959 10.997)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <path
            id="Caminho_11"
            data-name="Caminho 11"
            d="M19.684,23.684c-4.563,4.563-3.651,12.777-3.651,12.777s8.214.913,12.777-3.651,3.651-12.777,3.651-12.777S24.248,19.121,19.684,23.684Z"
            transform="translate(0.469 10.997)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
        </g>
      </svg>
    </div>
  )
}
