import { css } from 'styled-components'
import { BASE_COLORS } from './constants'
import { darkColorsTheme } from './dark/darkTheme'
import { lightColorsTheme, newLightColorsTheme } from './light/lightTheme'
// import { hexToRGB } from '../../utility/utility';

export const themeObject = {
  light: newLightColorsTheme, // newLightColorsTheme - lightColorsTheme
  dark: darkColorsTheme,
}

const getThemeVariables = (theme) => css`
  --background-color: ${themeObject[theme].backgroundColor};
  --text-main-color: ${themeObject[theme].textMainColor};
  --text-secondary-color: ${themeObject[theme].textSecondaryColor};
  --success-color: ${themeObject[theme].successColor};
  --error-color: ${themeObject[theme].errorColor};
  --warning-color: ${themeObject[theme].warningColor};
  --info-color: ${themeObject[theme].infoColor};

  /* sidebar */
  --logo-text-color: ${themeObject[theme].logoTextColor};
  --sidebar-background-color: ${themeObject[theme].sidebarBackgroundColor};
  --sidebar-background-darker-color: ${themeObject[theme].sidebarBackgroundDarkerColor};
  --sidebar-selected-color: ${themeObject[theme].sidebarSelectedColor};
  --collapse-background-color: ${themeObject[theme].collapseBackgroundColor};
  --border-color: ${themeObject[theme].borderColor};
  --text-sidebutton-color: ${themeObject[theme].textSideButtonColor};
  --sidebar-button-background-color: ${themeObject[theme].sidebarButtonBackgroundColor};
  --sidebar-text-color: ${themeObject[theme].sidebarTextColor};
  --sidebar-submenu-text-color: ${themeObject[theme].sidebarSubmenuTextColor};
  --sidebar-active-submenu-text-color: ${themeObject[theme].sidebarActiveSubmenuTextColor};
  --sidebar-selected-menu-background-color: ${themeObject[theme].sidebarSelectedMenuBackgroundColor};
  --sidebar-selected-menu-text-color: ${themeObject[theme].sidebarSelectedMenuColor};
  --sidebar-selected-menu-text-hover-color: ${themeObject[theme].sidebarSelectedMenuColorHover};

  /* base table */
  --table-cell-color: ${themeObject[theme].tableCellColor};
  --table-cell-hover-color: ${themeObject[theme].tableCellHoverColor};
  --table-font-hover-color: ${themeObject[theme].tableFontHoverColor};
  --table-border-color: ${themeObject[theme].tableBorderColor};
  --table-header-bg: ${themeObject[theme].tableHeaderBg};
  --table-header-color: ${themeObject[theme].tableHeaderColor};

  /* card */
  --card-bg-color: ${themeObject[theme].cardBgColor};
  --card-border: ${themeObject[theme].cardBorder};

  /* group card */
  --negative-balance-color: ${themeObject[theme].negativeBalanceColor};
  --positive-balance-color: ${themeObject[theme].positiveBalanceColor};

  /* progress */
  --trail-color: ${themeObject[theme].trailColor};
  --stroke-color: ${themeObject[theme].strokeColor};

  /* input */
  --input-border-color: ${themeObject[theme].inputBorderColor};

  /* button */
  --button-bg-color: ${themeObject[theme].buttonBgColor};
  --button-text-color: ${themeObject[theme].buttonTextColor};
  --button-bg-hover-color: ${themeObject[theme].buttonBgHoverColor};
  --button-text-hover-color: ${themeObject[theme].buttonTextHoverColor};

  /* modal */
  --modal-background-color: ${themeObject[theme].modalBackgroundColor};
  --modal-text: ${themeObject[theme].modalText};
  --modal-border-color: ${themeObject[theme].modalBorderColor};

  /* switch */
  --unchecked-switch-color: ${themeObject[theme].uncheckedSwitchColor};

  /* notification */
  --notification-success-color: ${themeObject[theme].notificationSuccess};
  --notification-info-color: ${themeObject[theme].notificationInfo};
  --notification-warning-color: ${themeObject[theme].notificationWarning};
  --notification-error-color: ${themeObject[theme].notificationError};

  /* modal buttons */

  /* 1st btn */
  --modal-first-btn-color: ${themeObject[theme].modalFirstBtnColor};
  --modal-first-btn-color-hover: ${themeObject[theme].modalFirstBtnColorHover};

  /* 2nd btn */
  --modal-second-btn-bg-color: ${themeObject[theme].modalSecondBtnBgColor};
  --modal-second-btn-bg-color-hover: ${themeObject[theme].modalSecondBtnBgColorHover};

  /* moonsun switch theme picker */
  --theme-picker-button-bg-color: ${themeObject[theme].themePickerButtonBgColor};
  --theme-picker-selected-theme-bg-color: ${themeObject[theme].themePickerSelectedThemeBgColor};
  --theme-picker-selected-theme-icon-color: ${themeObject[theme].themePickerSelectedThemeIconColor};

  /*
  --text-light-color: ${themeObject[theme].textLight};
  --text-superLight-color: ${themeObject[theme].textSuperLight};
  --text-secondary-color: ${themeObject[theme].textSecondary};
  --text-dark-color: ${themeObject[theme].textDark};
  --text-nft-light-color: ${themeObject[theme].textNftLight};
  --text-sider-primary-color: ${themeObject[theme].textSiderPrimary};
  --text-sider-secondary-color: ${themeObject[theme].textSiderSecondary};
  --subtext-color: ${themeObject[theme].subText}; */

  --campaing-details-primary-text: ${themeObject[theme].campaignDetailsPrimaryText};
  --campaing-details-secondary-text: ${themeObject[theme].campaignDetailsSecondaryText};

  --campaign-card-color: ${themeObject[theme].campaignCardColor};
  --campaign-card-bg-color: ${themeObject[theme].campaignCardBgColor};

  --campaign-details-bg-color: ${themeObject[theme].campaignDetailsBgColor};
  --campaign-details-color: ${themeObject[theme].campaignDetailsColor};
  --campaign-details-color: ${themeObject[theme].campaign};

  --calendar-bg: ${themeObject[theme].calendarHeader};
  --calendar-weekdays: ${themeObject[theme].calendarWeekDays};
  --calendar-disabled-days: ${themeObject[theme].calendarDisabledDays};
  --calendar-selected-day-bg: ${themeObject[theme].calendarSelectedDayBg};
  --calendar-selected-day-color: ${themeObject[theme].calendarSelectedDayColor};
  --calendar-footer-live-color: ${themeObject[theme].calendarFooterLiveColor};
  --calendar-icon-color: ${themeObject[theme].calendarIconColor};
  --calendar-footer-live-border-color: ${themeObject[theme].calendarFooterLiveBorderColor};
  --calendar-not-disabled-days: ${themeObject[theme].calendarNotDisabledDays};

  --right-section-bg-color: ${themeObject[theme].rightSectionBgColor};
  --animated-card-bg-color: ${themeObject[theme].animatedCardBgColor};
  --custom-table-bg-color: ${themeObject[theme].customTableBgColor};
  --custom-table-bg-color-hover: ${themeObject[theme].customTableBgColorHover};
  --filter-icon-color: ${themeObject[theme].filterIconColor};

  /* pie charts */

  --chart-tooltip-bg: ${themeObject[theme].chartTooltipBg};
  --chart-pie-legend-color: ${themeObject[theme].chartPieLegendColor};

  /* finances chart */

  --finance-chart-color: ${themeObject[theme].chartColor};
  --finance-chart-color-fade-stop: ${themeObject[theme].chartColorFadeStop};

  --finance-chart-color-hover: ${themeObject[theme].chartColorHover};
  --finance-chart-color-hover-end: ${themeObject[theme].chartColorHoverEnd};

  /* signup chart */

  --signup-chart-color: ${themeObject[theme].signupChartColor};
  --signup-chart-color-fade-stop: ${themeObject[theme].signupChartColorFadeStop};

  --signup-chart-color-stroke: ${themeObject[theme].signupChartColorStroke};

  --signup-chart-color-hover: ${themeObject[theme].signupChartColorHover};
  --signup-chart-color-hover-end: ${themeObject[theme].signupChartColorHoverEnd};

  /* others */
  --separator-color: ${themeObject[theme].separatorColor};
  --lighter-color-chart: ${themeObject[theme].lighterColorChart};

  --collapse-box-background-color: ${themeObject[theme].collapseBoxBackgroundColor};
`

export const lightThemeVariables = css`
  ${getThemeVariables('light')}
`

export const darkThemeVariables = css`
  ${getThemeVariables('dark')}
`

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`

// export const antOverrideCssVariables = css`
//   --ant-primary-1: var(--primary1-color) !important;
// `;
