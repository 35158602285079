import { http, HttpResponse } from 'msw'
import { WithdrawalVocacionalProps } from '../../../container/benfeitor/hooks/api/vocacional/mutations/useWithdrawalVocacional'

export const withdrawalVocacionalMock = http.post<never, WithdrawalVocacionalProps>(
  '/vocacional/registrar-desistencia/:fk_usuario_vocacional_id',
  async ({ request }) => {
    const fk_usuario_vocacional_id = request.url.split('/').pop()

    if (fk_usuario_vocacional_id === '1') {
      return new HttpResponse(null, { status: 201 })
    }

    return new HttpResponse(null, { status: 400 })
  },
)
