import { Col, RadioChangeEvent, Row } from 'antd'
import { useState } from 'react'
import { RecurrenceChart } from './components/recurrence-chart'
import { RecordViewWrapper } from '../../users/Style'
import { useRedirectOnPermDenied } from '../../../hooks'
import { handleFilterChangeAndPagination } from '../../../../../utility/utility'
import BaseCard from '../../../components/card/BaseCard'
import BaseTabs from '../../../components/tabs/BaseTabs'
import { useFetchRecurrenteEfetuadas } from '../../../hooks/donations/queries/useFetchRecurrenceEfetuadas'
import { useFetchRecurrenteNaoEfetuadas } from '../../../hooks/donations/queries/useFetchRecurrenceNaoEfetuadas'
import { useFetchRecurrencePrevistas } from '../../../hooks/donations/queries/useFetchRecurrencePrevistas'
import BaseRadio from '../../../components/radio/BaseRadio'
import { RecurrencyCanceledTable } from './components/RecurrencyCanceledTable'
import { NotPaidRecurrenceTable } from './components/NotPaidRecurrenceTable'
import { useGetPrevistaDonationsCards } from '../../../hooks/donations/queries/useGetPrevistaDonationsCard'
import { RecurrenceCards } from './components/recurrence-cards'
import UnpaidRecurrence from './components/unpaid-recurrence'
import { RecurrenceDonationTable } from './components/recurrence-table'

export const RecurrenceTypeEnum = {
  PREVISTAS: 'PREVISTAS',
  NAO_PAGAS: 'NAO_PAGAS',
  EFETUADAS: 'EFETUADAS',
} as const

export default function RecurringDonations() {
  useRedirectOnPermDenied('doacoes')

  const [activeTabKey, setActiveTabKey] = useState('ativas')

  const [filtersEfetuadas, setFiltersEfetuadas] = useState({})
  const [filtersPrevistas, setFiltersPrevistas] = useState({})
  const [filtersNaoEfetuadas, setFiltersNaoEfetuadas] = useState({})

  const handleInputChangeEfetuadas = handleFilterChangeAndPagination({ setFilters: setFiltersEfetuadas })
  const handleInputChangePrevistas = handleFilterChangeAndPagination({ setFilters: setFiltersPrevistas })
  const handleInputChangeNaoEfetuadas = handleFilterChangeAndPagination({ setFilters: setFiltersNaoEfetuadas })

  const recorrentesEfetuadas = useFetchRecurrenteEfetuadas(filtersEfetuadas)
  const recorrentesPrevistas = useFetchRecurrencePrevistas(filtersPrevistas)
  const recorrentesNaoPagas = useFetchRecurrenteNaoEfetuadas(filtersNaoEfetuadas)
  const recorrentesPrevistasCard = useGetPrevistaDonationsCards()

  const ActiveRecurrencesTabsItems = [
    {
      label: 'Efetuadas',
      key: '1',
      children: (
        <RecurrenceDonationTable
          dados={recorrentesEfetuadas || []}
          handleInputChange={handleInputChangeEfetuadas}
          isLoading={recorrentesPrevistasCard.isPending}
          isError={recorrentesPrevistasCard.isError}
          visibleColumns={['id', 'metodo', 'pedido', 'cliente', 'data', 'campanha', 'valor']}
          visibleFilters={['nome', 'data', 'campanha', 'forma_pagamento']}
          filters={filtersEfetuadas}
        />
      ),
    },
    {
      label: 'Previstas',
      key: '2',
      children: (
        <RecurrenceDonationTable
          dados={recorrentesPrevistas || []}
          handleInputChange={handleInputChangePrevistas}
          isLoading={recorrentesPrevistas.isPending}
          isError={recorrentesPrevistas.isError}
          visibleColumns={['id', 'metodo', 'pedido', 'cliente', 'data', 'campanha', 'valor']}
          visibleFilters={['nome', 'data', 'campanha', 'forma_pagamento']}
          filters={filtersPrevistas}
        />
      ),
    },
    {
      label: 'Não pagas',
      key: '3',
      children: (
        <NotPaidRecurrenceTable
          dados={recorrentesNaoPagas?.data}
          handleInputChange={handleInputChangeNaoEfetuadas}
          isLoading={recorrentesNaoPagas.isPending}
          isError={recorrentesNaoPagas.isError}
          visibleColumns={['id', 'metodo', 'pedido', 'cliente', 'data', 'campanha', 'valor', 'lembrete', 'acoes']}
          visibleFilters={['nome', 'data', 'campanha', 'forma_pagamento']}
          filters={filtersNaoEfetuadas}
        />
      ),
    },
  ]

  const pages = [
    {
      key: 'ativas',
      children: <BaseTabs items={ActiveRecurrencesTabsItems} />,
    },
    {
      key: 'canceladas',
      children: <RecurrencyCanceledTable />,
    },
    {
      key: 'inadimplentes',
      children: <UnpaidRecurrence />,
    },
  ]

  const handlePagesTabsChange = (e: RadioChangeEvent) => {
    setActiveTabKey(e.target.value)
  }

  const handleActiveRecurrenceTabsChange = (key: string) => {
    setActiveTabKey(key)
  }

  return (
    <RecordViewWrapper>
      <Col md={24}>
        <RecurrenceCards
          recorrentesEfetuadas={recorrentesEfetuadas}
          recorrentesNaoPagas={recorrentesNaoPagas}
          recorrentesPrevistasCard={recorrentesPrevistasCard}
        />

        <BaseCard title="Doações recorrentes">
          <RecurrenceChart />
        </BaseCard>

        {/* Pages Tabs */}
        <Row justify="center" style={{ gap: '0.5rem', marginBlock: '1rem' }}>
          <BaseRadio.Group onChange={handlePagesTabsChange} value={activeTabKey}>
            <BaseRadio.Button value="ativas">Ativas</BaseRadio.Button>
            <BaseRadio.Button value="canceladas">Canceladas</BaseRadio.Button>
            <BaseRadio.Button value="inadimplentes">Inadimplentes</BaseRadio.Button>
          </BaseRadio.Group>
        </Row>

        {/* ActiveRecurrenceTabs */}
        <BaseCard>
          <BaseTabs
            activeKey={activeTabKey}
            onChange={handleActiveRecurrenceTabsChange}
            tabBarStyle={{ display: 'none' }}
            items={pages}
          />
        </BaseCard>
      </Col>
    </RecordViewWrapper>
  )
}
