import { ResponsivePie } from '@nivo/pie'
import { Tooltip } from './styles'
import { formatNumber } from '../../../../../../../utility/utility'

export function Chart({ campaignsStats, setCampaignsState }) {
  const extendedConfig = {
    responsive: true,
    margin: { top: 30, right: 0, bottom: 30, left: 0 },
    startAngle: 0,
    endAngle: 360,
    innerRadius: 0.85,
    padAngle: 2,
    cornerRadius: 0,
    fit: false,
    activeOuterRadiusOffset: 12,
    colors: ['var(--signup-chart-color)', '#8405f4', '#ea5472', '#5fc70b', '#efb108', '#e67f4f'],
    borderWidth: 1,
    enableArcLinkLabels: false,
    enableArcLabels: false,
    borderColor: {
      from: 'color',
      modifiers: [['darker', '0.2']],
    },
    tooltip: ({ datum }) => (
      <Tooltip color={datum.color}>
        <strong>
          {datum.label}: {datum.value}
        </strong>
      </Tooltip>
    ),
    onMouseEnter: (node) => {
      setCampaignsState((prevState) => ({
        ...prevState,
        statusColor: node.color,
        percentage: node.data.percentage,
        value: node.value,
      }))
    },
    layers: [
      'arcs',
      'legends',
      ({ centerX, centerY, radius }) => (
        <g
          key={`${campaignsStats.percentage}-${campaignsStats.value}`}
          style={{
            transition: '0.4s',
            transform: 'scale(1)',
            opacity: 1,
          }}
        >
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fill: campaignsStats.statusColor }}
          >
            <tspan x={centerX} dy={-15} style={{ fontWeight: 'bold', fontSize: radius * 0.35 }}>
              {campaignsStats.percentage}%
            </tspan>
            <tspan x={centerX} dy={40} style={{ fontSize: radius * 0.25 }}>
              {`${formatNumber(campaignsStats.value)}`}
            </tspan>
          </text>
        </g>
      ),
    ],
  }

  return <ResponsivePie {...extendedConfig} data={campaignsStats.formattedData} />
}
