import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../lib/dataService'

export function useGetPaymentsGateway() {
  const paymentsGateway = useQuery({
    queryKey: ['paymentGateways'],
    queryFn: async () => {
      const response = await DataService.get('/payments-gateway')
      return response?.data
    },
  })

  return paymentsGateway
}
