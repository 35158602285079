import { TileLayer } from 'react-leaflet'
import L from 'leaflet'
import { MapPin } from '@phosphor-icons/react/dist/ssr'
import { renderToStaticMarkup } from 'react-dom/server'

export const BaseLayers = {
  googleRegular: (
    <TileLayer
      attribution="Google Maps"
      url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
      maxZoom={20}
      subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
    />
  ),
  googleSatelite: (
    <TileLayer
      attribution="Google Maps"
      url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
      maxZoom={20}
      subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
    />
  ),
}

export const BasePinIcon = L.divIcon({
  html: `<div style="display: flex; align-items: center; justify-content: center; width:32px; height:32px;">
           ${renderToStaticMarkup(<MapPin size={32} color="red" weight="fill" />)}
         </div>`,
  className: 'custom-leaflet-icon', // ! -> PARA REMOVER FUNDO DO ÍCONE
  iconAnchor: [16, 32],
})
