import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface ListarNomeAcoesSolidariasResponse {
  acoes_agape: {
    id: number
    nome: string
  }[]
}

export function useListarNomeAcoesSolidarias() {
  const nomeAcoesSolidarias = useQuery({
    queryKey: ['LISTAR_NOME_ACOES_SOLIDARIAS'],
    queryFn: async () => {
      const response = await DataService.get<ListarNomeAcoesSolidariasResponse>('/agape/listar-nomes-acoes-agape')

      return response.data
    },
  })

  return nomeAcoesSolidarias
}
