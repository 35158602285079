export const ResponsavelFuncaoFamiliarOptions = [
  {
    label: 'Pai',
    value: 'pai',
  },
  {
    label: 'Mãe',
    value: 'mae',
  },
  {
    label: 'Tio',
    value: 'tio',
  },
  {
    label: 'Tia',
    value: 'tia',
  },
  {
    label: 'Avô',
    value: 'avo',
  },
  {
    label: 'Avó',
    value: 'avoa',
  },
];

export const MembroFuncaoFamiliarOptions = [
  {
    label: 'Filho',
    value: 'filho',
  },
  {
    label: 'Filha',
    value: 'filha',
  },
  {
    label: 'Sobrinho',
    value: 'sobrinho',
  },
  {
    label: 'Sobrinha',
    value: 'sobrinha',
  },
  {
    label: 'Neto',
    value: 'neto',
  },
  {
    label: 'Neta',
    value: 'neta',
  },
];
