import styled from 'styled-components'
import BaseAvatar from '../BaseAvatar'
import { getFirstAndLastName, getFirstAndSecondName } from '../../../../../utility/utility'

export const StyledAvatarPhoto = styled(BaseAvatar)`
  position: relative;
  border: 2px solid var(--input-border-color);
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-avatar-string {
    font-size: ${({ fontSize }) => fontSize || '40px'};
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ hoverContent, firstAndSecondName = false }) =>
    hoverContent &&
    `
      ::after {
          content: "${firstAndSecondName ? getFirstAndSecondName(hoverContent) : getFirstAndLastName(hoverContent)}";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          color: var(--white);
          display: flex;
          flex-direction: column; 
          align-items: center;
          justify-content: center;
          opacity: 0;
          border-radius: 50%;
          transition: opacity 0.3s ease;
          font-size: 1rem; 
          white-space: pre-line; 
          text-align: center; 
          line-height: 1; 
      }

      :hover::after {
          opacity: 1;
      }

      :hover {
        cursor: pointer;
    }
    `}
`
