import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useDeleteProfilePhoto() {
  const deleteImage = useMutation({
    mutationFn: async () => {
      await DataService.delete('users/deletar-foto-perfil')
    },
    onSuccess: () => {
      notification.success({
        message: 'Foto de perfil excluída com sucesso',
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao excluir a foto de perfil',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return deleteImage
}
