import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetItensEstoqueResponse {
  estoques: {
    id: number
    item: string
    quantidade: number
  }[]
}

export function useGetItensEstoque() {
  const itensEstoque = useQuery({
    queryKey: ['ITENS_ESTOQUE'],
    queryFn: async () => {
      const response = await DataService.get<GetItensEstoqueResponse>('/agape/listar-itens-estoque')

      return response.data
    },
  })

  return itensEstoque
}
