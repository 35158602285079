import { ErrorBoundary as SentryErrorBoundary, withScope } from "@sentry/react";
import { ErrorFallback } from './error-fallback-screen';

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const beforeCapture = () => {
    withScope((scope) => {
      scope.setLevel('error');
    });
  };

  return (
    <SentryErrorBoundary
      fallback={ErrorFallback}
      beforeCapture={beforeCapture}
    >
      {children}
    </SentryErrorBoundary>
  );
};