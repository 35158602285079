import { useEffect } from 'react'
import { DataService } from '../../../lib/dataService'
import { useAuth } from '../../../context/Auth'
import { useQuery } from '@tanstack/react-query'
import { useGetPublicCampaigns } from './api/campaign/useGetPublicCampaigns'
import { useHistory } from 'react-router-dom'

export function authUserFromEmailRedirect() {
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)
  const tokenFromUrl = queryParams.get('token')

  // const dispatch = useDispatch()

  const { loginAfterRegister } = useAuth()
  // const { data: userMe } = useFetchUserMe()

  const { data: campaignsResponse } = useGetPublicCampaigns()

  const { data: userTokenFromEmail } = useQuery({
    queryKey: ['token_email', tokenFromUrl],
    queryFn: async () => {
      const response = await DataService.post(`users/active-account/${tokenFromUrl}`)
      return response
    },
    enabled: !!tokenFromUrl,
  })

  useEffect(() => {
    if (userTokenFromEmail?.data) {
      const { access_token, refresh_token, campanha_origem } = userTokenFromEmail?.data

      // if the user creates account from sao miguel lp, and confirm e-mail, bring him back to flow redirecting to the member lp with the data to be filled on general form later
      // if (landing_id === 18) {
      //    window.location.href = `https://cadastro.institutohesed.org.br/membro-exercito/landing-page-membros?alistamento_esm_token=${alistamento_esm_token}`
      //   return
      // }

      loginAfterRegister({ access_token, refresh_token })

      const campanha = campaignsResponse?.campanhas.find((c) => parseInt(c.id) === parseInt(campanha_origem))

      if (campanha && campanha.objetivo !== null) {
        history.push(`benfeitor/campanha/${campanha_origem}/identificacao`)
      }

      // else {
      //   if (userMe?.obriga_atualizar_cadastro || userMe?.obriga_atualizar_endereco) {
      //     dispatch(openUpdateModal());
      //   }
      // }
    }
  }, [userTokenFromEmail, campaignsResponse])
}
