import { Col, Form, Row } from 'antd'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import BaseButton from '../../../../components/button/BaseButton'
import { BaseSteps } from '../../../../components/steps'
import { CadastrarDoacaoSteps } from './utils/CadastrarCicloDoacaoStepItems'
import { validateCPF } from '../../../../../../utility/utility'
import ReactInputMask from 'react-input-mask'

interface BuscarPorCpfProps {
  cpf: string
  setCpf: (cpf: string) => void
  executarBuscaFamiliaPorCpf: () => void
  isLoadingBuscarFamiliarPorCpf: boolean
}

export function BuscarPorCpf({
  cpf,
  setCpf,
  executarBuscaFamiliaPorCpf,
  isLoadingBuscarFamiliarPorCpf,
}: BuscarPorCpfProps) {
  const [form] = Form.useForm()

  function handleExecutarBusca() {
    executarBuscaFamiliaPorCpf()
  }

  return (
    <>
      <BaseSteps current={0} items={CadastrarDoacaoSteps} />

      <Col xs={24} style={{ marginTop: '1.2rem' }}>
        <h3 style={{ textAlign: 'center' }}>Informe o CPF do responsável</h3>

        <Row justify="center">
          <Form form={form} layout="vertical" onFinish={handleExecutarBusca}>
            <BaseForm.Item
              name="cpf"
              label="CPF"
              rules={[{ required: true, message: 'Obrigatório' }, { validator: validateCPF }]}
              initialValue={cpf}
            >
              <ReactInputMask
                mask="999.999.999-99"
                name="cpf_cnpj"
                maskChar={null}
                placeholder="999.999.999-99"
                onChange={(e) => setCpf(e.target.value)}
                allowClear
                size="large"
              >
                {(inputProps) => <BaseInput {...inputProps} />}
              </ReactInputMask>
            </BaseForm.Item>

            <Row justify="center">
              <BaseButton block htmlType="submit" loading={isLoadingBuscarFamiliarPorCpf}>
                Localizar
              </BaseButton>
            </Row>
          </Form>
        </Row>
      </Col>
    </>
  )
}
