export const SET_FILTERS = 'SET_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'

export const setFiltersAction = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
})

export const resetFiltersAction = () => ({
  type: RESET_FILTERS,
})
