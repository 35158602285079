import { useFetchCurrentMonthDonations } from '../../../../../../hooks/donations/queries/useFetchCurrentMonthDonations'
import { useFetchDailyAverageDonation } from '../../../../../../hooks/donations/queries/useFetchDailyAverageDonation'
import { useFetchMonthAverageDonation } from '../../../../../../hooks/donations/queries/useFetchMonthAverageDonation'
import { useFetchTodayDonations } from '../../../../../../hooks/donations/queries/useFetchTodayDonations'

export const useFinanceDashboardCardsData = () => {
  const currentMonthDonations = useFetchCurrentMonthDonations()
  const todayDonations = useFetchTodayDonations()
  const monthAverageDonation = useFetchMonthAverageDonation()
  const dailyAverageDonation = useFetchDailyAverageDonation()

  return {
    currentMonthDonations,
    todayDonations,
    monthAverageDonation,
    dailyAverageDonation,
  }
}
