import { Modal, Row } from 'antd'
import BaseButton from '../../../../../components/button/BaseButton'
import { useRemoverVoluntario } from '../../../../../hooks/agape/mutations/useRemoverVoluntario'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'

interface RemoverVoluntarioProps {
  fk_usuario_id: number
  nome_voluntario: string
}

const { confirm } = Modal

export function RemoverVoluntario({ fk_usuario_id, nome_voluntario }: RemoverVoluntarioProps) {
  const [loadingRemoverMembro, setLoadingRemoverMembro] = useState<number | null>(null)

  const { mutateAsync: removerVoluntario } = useRemoverVoluntario()

  async function handleRemoverMembro() {
    try {
      setLoadingRemoverMembro(fk_usuario_id)
      await removerVoluntario({ fk_usuario_id })
    } finally {
      setLoadingRemoverMembro(null)
    }
  }

  const confirmarRemoverVoluntario = () => {
    confirm({
      title: `Tem certeza que deseja remover o voluntário ${nome_voluntario}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Remover',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        handleRemoverMembro()
      },
    })
  }

  return (
    <Row justify="center">
      <BaseButton
        severity="error"
        onClick={confirmarRemoverVoluntario}
        loading={loadingRemoverMembro === fk_usuario_id}
      >
        Remover
      </BaseButton>
    </Row>
  )
}
