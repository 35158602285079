import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useGetLiveDetails(liveDetailToBeFetched) {
  const liveDetails = useQuery({
    queryKey: ['LIVE_DETAIL', liveDetailToBeFetched],
    queryFn: async () => {
      const response = await DataService.get(
        `/youtube/live/histograma?filtro_titulo_live=${liveDetailToBeFetched.titulo}`,
      )

      return response
    },
    enabled: !!liveDetailToBeFetched.titulo,
  })

  return liveDetails
}
