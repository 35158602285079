import { useHistory } from 'react-router-dom'
import numAndLivesCardsImg from '../../../../images/num_and_live_cards_2.png'
import { SmBaseButton } from '../../../components/button/styles'
import { GoldenHeader } from '../../../components/golden-header/styles'
import { Container, ContentWrapper, Image, ListWrapper, Text } from './styles'

export function SecondCta() {
  const history = useHistory()

  return (
    <Container>
      <div className="img-container">
        <Image src={numAndLivesCardsImg} alt="Num and Lives Cards" />
      </div>
      <ContentWrapper>
        <div style={{ maxWidth: '250px', marginRight: 'auto' }}>
          <GoldenHeader fontSize={34} lineHeight="34px" className="header">
            Não espere mais para fazer parte desta família.
          </GoldenHeader>
        </div>
        <Text>
          O<span> combate espiritual </span>é real, e juntos somos mais fortes. Estamos com você nos mais diversos
          horários do dia, para que você tenha a certeza de que <span>não está sozinho nos seus combates:</span>
        </Text>
        <ListWrapper>
          <li>Santo Rosário na madrugada às 04:45h;</li>
          <li>Escola de formação e oração;</li>
          <li>Meditação diária da Palavra de Deus;</li>
          <li>Novena da Pompeia ao meio dia;</li>
          <li>Terço da Misericórdia às 14:30h;</li>
          <li>Terço do Combate às 18:45h;</li>
        </ListWrapper>
        <SmBaseButton>
          <div onClick={() => history.push('/membro-exercito/validar-usuario')}>
            ALISTE-SE AGORA NO EXÉRCITO DE SÃO MIGUEL.
          </div>
        </SmBaseButton>
      </ContentWrapper>
    </Container>
  )
}
