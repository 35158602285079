import styled from 'styled-components'

export const FooterStyled = styled.div`
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  text-align: center;

  p {
    color: #fbf9f3;
    font-family: 'DM Serif Display';
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;

    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  }

  background: linear-gradient(to right, #c98f00, #ffe198);
`
