import styled from 'styled-components'

export const ListItemNumber = styled.div`
  color: #fbf9f3;
  background-color: #c98f00;
  width: 38px;
  border-radius: 0 0 0 12px;
  padding: 0 35px 0 10px;
  font-family: DM Serif Display;
  font-size: 22px;
`

export const ListItemText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  span {
    font-weight: 700;
  }
`
