import { useHistory } from 'react-router-dom'
import { SmBaseButton } from '../../../components/button/styles'
import { Container, TextWrapper } from './styles'

export function FirstCta() {
  const history = useHistory()

  return (
    <Container onClick={() => history.push('/membro-exercito/validar-usuario')}>
      <TextWrapper>
        <span>Seu nome será consagrado no Monte Gargano</span>, local sagrado da aparição de São Miguel Arcanjo, assim
        como já fizemos com milhares de outros membros que testemunham uma força sobrenatural após esta solene
        consagração.
      </TextWrapper>
      <SmBaseButton>
        <div>QUERO ME TORNAR MEMBRO DESTA FAMÍLIA DE ORAÇÃO.</div>
      </SmBaseButton>
    </Container>
  )
}
