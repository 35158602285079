import { http, HttpResponse } from 'msw'
import { GetEstatisticasEstoqueResponse } from '../../../../../hooks/agape/queries/useGetEstatisticasEstoque'

const response = {
  itens_em_estoque: '465 | Em estoque',
  ultima_acao: '29/01/2025 | 28 Itens',
  ultima_entrada: '29/01/2025 | 99 Itens',
}

export const cardsEstatisticasEstoqueMock = http.get<never, never, GetEstatisticasEstoqueResponse>(
  '/agape/cards-estatisticas-itens-estoque',
  () => {
    return HttpResponse.json(response)
  },
)
