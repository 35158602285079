/* eslint-disable react/destructuring-assignment */

import { Wrapper } from './styles';
import { PreCadastroForm } from '../PreCadastroForm';
import { useRouteMatch } from 'react-router-dom';

export function PreCadastroVocacionalEmbed(url) {
  const { path } = useRouteMatch();

  const vocacional = path.includes('masculino') ? 'masculino' : 'feminino';

  const queryParams = new URLSearchParams(url.location.search);

  const bgColor = queryParams.get('bg-color');
  const labelColor = queryParams.get('label-color');

  document.body.style.backgroundColor = `${bgColor || 'transparent'}`;

  const iframeStyles = {
    labelColor: labelColor || 'black',
  };

  return (
    <Wrapper iframeStyles={iframeStyles}>
      <PreCadastroForm vocacional={vocacional} />
    </Wrapper>
  );
}
