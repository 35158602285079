import Paragraph from 'antd/lib/typography/Paragraph'
import BaseCard from '../../components/card/BaseCard'
import BaseSpin from '../../components/spin/BaseSpin'
import { useFetchUserDonations } from '../../hooks/donations/queries/useFetchUserDonations'
import { formatToBrazilianCurrency } from '../../../../utility/utility'
import { BaseBadge } from '../../components/badge/BaseBadge'
import { BaseDescription } from '../../components/description/BaseDescription'
import { BaseCollapse } from '../../components/collapse/BaseCollapse'
import { ErrorResult } from '../../components/result/resultStatus/ErrorResult'
import { EmptyResult } from '../../components/result/resultStatus/EmptyResult'
import { useFetchDonationsMe } from '../../../benfeitor/hooks/api/doacoes/queries'
import { Image } from 'antd'

export const ProfileDonations = () => {
  const { data: donations, isLoading, isError, status } = useFetchDonationsMe({})

  const getStatus = (status) => {
    switch (status) {
      case 'Expirado':
      case 'Não efetuado':
        return 'error'
      case 'Em processamento':
        return 'processing'
      case 'Pago':
        return 'success'
      default:
        return 'default'
    }
  }

  if (isError) {
    return (
      <BaseCard style={{ minHeight: '80vh' }} title="Doações do usuário">
        <ErrorResult />
      </BaseCard>
    )
  }

  if (isLoading) {
    return (
      <BaseCard style={{ minHeight: '80vh' }} title="Doações do usuário">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <BaseSpin />
        </div>
      </BaseCard>
    )
  }

  if (donations?.doacoes?.length === 0 || status === 204) {
    return (
      <BaseCard style={{ minHeight: '80vh' }} title="Doações do usuário">
        <EmptyResult />
      </BaseCard>
    )
  }

  return (
    <BaseCard style={{ minHeight: '80vh' }} title="Doações do usuário">
      <BaseCollapse>
        {donations?.doacoes?.map((item) => (
          <BaseCollapse.Panel
            header={`${formatToBrazilianCurrency(item.valor_doacao)} - ${item.data_doacao} - ${item.recorrencia}`}
            key={item.id}
          >
            <BaseDescription layout="vertical" bordered>
              <BaseDescription.Item label="Campanha">
                {' '}
                <Image src={item.imagem_campanha} alt="Campanha" width={300} />
              </BaseDescription.Item>
              <BaseDescription.Item label="Descrição">{item.descricao}</BaseDescription.Item>
              <BaseDescription.Item label="Data">{item.data_doacao}</BaseDescription.Item>
              <BaseDescription.Item label="Valor">{formatToBrazilianCurrency(item.valor_doacao)}</BaseDescription.Item>
              <BaseDescription.Item label="Forma de pagamento">{item.forma_pagamento}</BaseDescription.Item>
              <BaseDescription.Item label="Recorrente">
                <BaseBadge status={item.recorrencia ? 'success' : 'error'} text={item.recorrencia ? 'Sim' : 'Não'} />
              </BaseDescription.Item>
              {/* <BaseDescription.Item label="Código de referência">
                <Paragraph
                  copyable={{
                    tooltips: ['Copiar', 'Copiado!'],
                  }}
                >
                  {item.codigo_referencia}
                </Paragraph>
              </BaseDescription.Item>
              <BaseDescription.Item label="Código da transação">
                <Paragraph
                  copyable={{
                    tooltips: ['Copiar', 'Copiado!'],
                  }}
                >
                  {item.transaction_id}
                </Paragraph>
              </BaseDescription.Item> */}
            </BaseDescription>
          </BaseCollapse.Panel>
        ))}
      </BaseCollapse>
    </BaseCard>
  )
}
