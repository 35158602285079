import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface Donation {
  campanha: string
  clifor_id: number
  data_donation: string
  metodo_pagamento: string
  nome: string
  pedido_id: number
  valor: string
}

interface Pagination {
  current_page: number
  per_page: number
  total_items: number
}

interface InfoCard {
  total_items: number
  valor_total: string
}

export interface RecorrenteEfetuadasResponse {
  info_card: InfoCard
  lista: Donation[]
  pagination: Pagination
}

export function useFetchRecurrenteEfetuadas(filters: { queryString?: string }) {
  const donationsEfetuadas = useQuery<RecorrenteEfetuadasResponse>({
    queryKey: ['RECURRENCE_DONATIONS_EFETUADAS', filters],
    queryFn: async () => {
      const response = await DataService.get(`checkout/list-recurrence-donations-made?${filters.queryString || ''}`)
      return response?.data
    },
  })

  return donationsEfetuadas
}
