// modalSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalState {
  isLoginModalOpen: boolean
  isSignUpModalOpen: boolean
}

const initialState: ModalState = {
  isLoginModalOpen: false,
  isSignUpModalOpen: false,
}

const authModalsSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openLoginModal: (state) => {
      state.isLoginModalOpen = true
      state.isSignUpModalOpen = false
    },
    openSignUpModal: (state) => {
      state.isSignUpModalOpen = true
      state.isLoginModalOpen = false
    },
    closeModals: (state) => {
      state.isLoginModalOpen = false
      state.isSignUpModalOpen = false
    },
  },
})

export const { openLoginModal, openSignUpModal, closeModals } = authModalsSlice.actions
export default authModalsSlice.reducer
