import React from 'react'
import styled from 'styled-components'

const DescriptionContainer = styled.div`
  .title {
    color: var(--text-main-color);
  }
  .description {
    color: var(--text-secondary-color);
    font-size: 0.8rem;
  }

  h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
`

const StyledUploadDescription = ({
  title,
  text,
}: {
  title?: string
  text: string
}) => {
  return (
    <DescriptionContainer>
      {title && <p className="title">{title}</p>}
      {text && <p className="description">{text}</p>}
    </DescriptionContainer>
  )
}

export default StyledUploadDescription
