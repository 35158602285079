import { Form, notification, Spin, Steps, UploadFile } from 'antd'
import { StyledForm } from '../../../components/Form/styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { AddressForm } from '../../Campaign/CampaignIdentification/components/AddressForm'
import { StyledButton } from '../../../components/Button'
import { StepOne } from './stepOne'
import { useMultistepForm } from './hooks/useMultistepForm'
import { formatDateToUs } from '../../../../../utility/utility'
import { useUserRegisterFullData, Usuario } from '../../../../admin/hooks/users/Mutations/useUserRegisterFullData'
import { Endereco } from '../../../../../utility/endereco'

export interface CarteiraLocationState {
  numero_documento: string
  tipo_documento: 'cpf' | 'cnpj'
}

export function CarteiraCadastro() {
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()

  const isEmbed = location.pathname.includes('embed')
  const [file, setFile] = useState<UploadFile[]>([])

  const { numero_documento, tipo_documento } = (location?.state || {}) as CarteiraLocationState
  const { mutateAsync: registerUserComplete, isPending } = useUserRegisterFullData()

  const steps = [
    { title: 'Informações Pessoais', content: <StepOne file={file} setFile={setFile} form={form} /> },
    { title: 'Endereço', content: <AddressForm showBrazil form={form} /> },
  ]

  const { currentStep, next, prev, isFirstStep, isLastStep } = useMultistepForm(steps, form)

  const handleFinish = () => {
    const formData = form.getFieldsValue([
      'nome',
      'cpf',
      'cnpj',
      'nome_social',
      'data_nascimento',
      'telefone',
      'email',
      'password',
      'cep',
      'estado',
      'cidade',
      'bairro',
      'rua',
      'numero',
      'complemento',
      'avatar',
      'sexo',
    ])

    const usuario: Usuario = {
      nome: formData.nome,
      email: formData.email,
      nome_social: formData.nome_social,
      data_nascimento: formatDateToUs(formData.data_nascimento),
      password: formData.password,
      numero_documento: numero_documento,
      tipo_documento: tipo_documento,
      telefone: formData.telefone,
      sexo: formData.sexo,
    }

    const endereco: Endereco = {
      bairro: formData.bairro,
      cep: formData.cep,
      estado: formData.estado,
      cidade: formData.cidade,
      complemento: formData.complemento,
      numero: formData.numero,
      rua: formData.rua,
    }

    const pais = 'brasil'

    const image = formData.avatar

    form
      .validateFields()
      .then(() => {
        registerUserComplete({ usuario, endereco, image, pais })
      })
      .catch((error) => {
        console.error('Form validation failed:', error)
      })
  }

  useEffect(() => {
    if (!numero_documento) {
      const routeToPush = isEmbed ? '/embed/carteira/primeira-etapa' : '/carteira/primeira-etapa'
      history.push(routeToPush)
    } else {
      form.setFieldsValue({
        pais: 'brasil',
        cpf: numero_documento,
        cnpj: numero_documento,
      })
    }
  }, [form, numero_documento, isEmbed, history])

  return (
    <StyledForm
      form={form}
      layout="vertical"
      style={{ maxWidth: '600px', margin: '0 auto', minHeight: '60vh', padding: '0 1.3rem' }}
    >
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Steps.Step key={index} title={step.title} />
        ))}
      </Steps>
      <div style={{ marginTop: 24 }}>{steps[currentStep].content}</div>
      <div style={{ marginTop: 24, display: 'flex' }}>
        {!isFirstStep && (
          <StyledButton type="ghost" style={{ marginRight: 8 }} onClick={prev}>
            Voltar
          </StyledButton>
        )}
        {!isLastStep && <StyledButton onClick={next}>Próximo</StyledButton>}
        {isLastStep && (
          <StyledButton onClick={handleFinish} disabled={isPending}>
            {isPending && <Spin />}Concluir
          </StyledButton>
        )}
      </div>
    </StyledForm>
  )
}
