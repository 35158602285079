import { Chart } from './Chart'
import { ErrorMessage, LoadingContainer } from '../../../styles'
import { useGetDonationsPerMonth } from '../../../../../hooks/dashboards/finances/queries/useGetDonationsPerMonth'

export function Content({ filters, setCampaignsOptions }) {
  const query = useGetDonationsPerMonth(filters)

  if (query?.data?.campaigns) {
    setCampaignsOptions(query?.data?.campaigns)
  }

  if (query?.isPending) {
    return <LoadingContainer />
  }

  if (query?.isError) {
    return <ErrorMessage />
  }

  const dataToChart = filters?.visualizar_por === 'campanha' ? query?.data : query?.data?.data

  return <Chart data={dataToChart} filters={filters} />
}
