import { ComposedCard } from "@/container/admin/components/composed-card"
import { BlurDiv } from "@/context/Blur/BlurDiv"
import { formatNumber, formatToBrazilianCurrency } from "@/utility/utility"
import { motion } from 'motion/react'
import { CardsSection } from "../../../styles"
import { springTransition } from "../constants"
import { CarouselCardsProps } from "../types"

export function CarouselCards({ activeTab, slides, type }: Readonly<CarouselCardsProps>) {
  return (
    <CardsSection>
      <motion.div className="cards-container">
        {slides[activeTab].cards.map((card) => {
          let displayValue = null;
          if (type === "finance") {
            displayValue = formatToBrazilianCurrency(card.value || 0);
          } else if (type === "signup") {
            displayValue = formatNumber(card.value || 0);
          }

          return (
            <motion.div key={card.title} layoutId={`card-${card.title}`} transition={springTransition}>
              <ComposedCard>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ComposedCard.Title>
                      <BlurDiv style={{ lineHeight: '1.3' }}>
                        {displayValue}
                      </BlurDiv>
                    </ComposedCard.Title>
                    <ComposedCard.Subtitle>
                      <div style={{ lineHeight: '1.3' }}>{card.title}</div>
                    </ComposedCard.Subtitle>
                  </div>
                  <BlurDiv>
                    {typeof card.percentage !== 'undefined' && (
                      <ComposedCard.Balance isPositive={card.percentage >= 0}>
                        {card.percentage}%
                      </ComposedCard.Balance>
                    )}
                  </BlurDiv>
                </div>
              </ComposedCard>
            </motion.div>
          );
        })}
      </motion.div>
    </CardsSection>
  );
}
