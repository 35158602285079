import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface EncerrarCicloDoacaoProps {
  idCicloDoacao: number
}

export function useEncerrarCicloDoacao() {
  const queryClient = useQueryClient()

  const encerrarCicloDoacao = useMutation({
    mutationFn: async ({ idCicloDoacao }: EncerrarCicloDoacaoProps) => {
      await DataService.put(`/agape/finalizar-ciclo-acao-agape/${idCicloDoacao}`)
    },
    onSuccess: () => {
      notification.success({ message: 'Ciclo de doação encerrado com sucesso!' })

      queryClient.invalidateQueries({ queryKey: ['CICLOS_DOACOES'] })
      queryClient.invalidateQueries({ queryKey: ['ITENS_ESTOQUE'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_ESTOQUE'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao encerrar o ciclo de doação',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return encerrarCicloDoacao
}
