import React from 'react'
import { ConfigProvider } from 'antd'
import * as S from './DatePicker.Styles'
import { BaseDatePickerLocale } from './BaseDatePickerLocale'

const getPlaceholder = (pickerType, isRange) => {
  const placeholders = {
    date: 'Selecionar data',
    week: 'Selecionar semana',
    month: 'Selecionar mês',
    year: 'Selecionar ano',
    quarter: 'Selecionar trimestre',
    range: ['Data inicial', 'Data final'],
  }

  const rangePlaceholders = {
    week: ['Semana inicial', 'Semana final'],
    month: ['Mês inicial', 'Mês final'],
    year: ['Ano inicial', 'Ano final'],
    quarter: ['Trimestre inicial', 'Trimestre final'],
    date: ['Data inicial', 'Data final'],
    range: ['Data inicial', 'Data final'],
  }

  if (isRange) {
    return rangePlaceholders[pickerType] || rangePlaceholders.date
  }

  return placeholders[pickerType] || placeholders.date
}

export default function BaseDatePicker({ ...props }) {
  // Verifica se é um RangePicker
  const isRangePicker = props.range

  return (
    <ConfigProvider locale={BaseDatePickerLocale}>
      {isRangePicker ? (
        <S.StyledRangePicker {...props} placeholder={getPlaceholder(props.picker, isRangePicker)} />
      ) : (
        <S.StyledDatePicker {...props} placeholder={getPlaceholder(props.picker, isRangePicker)} />
      )}
    </ConfigProvider>
  )
}
