import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface CanceledRecurrenceFilters {
  nome_usuario: string
  data_final: string
  data_inicial: string
  campanha_id: string
  queryString: string
}

interface RecurringDonation {
  benfeitor: string
  campanha: string
  cancelada_em: string
  data_pedido: string
  id: number
  metodo: string
  valor: string
}

interface DonationsResponse {
  card_qtd_doacoes: number
  card_soma_valor_doacoes: string
  doacoes_recorrentes_canceladas: RecurringDonation[]
  page: number
  pages: number
  total: number
}

export function useFetchCanceledRecurrence(filters: CanceledRecurrenceFilters | undefined) {
  const canceledRecurrency = useQuery<DonationsResponse>({
    queryKey: ['CANCELED_DONATIONS', filters],
    queryFn: async () => {
      const response = await DataService.get<DonationsResponse>(
        `/checkout/recorrencias-canceladas?${filters?.queryString || ''}`,
      )
      return response?.data
    },
  })

  return canceledRecurrency
}
