import styled from 'styled-components'

export const FifthSectionStyled = styled.div`
  .content {
    margin-top: 5rem;

    padding-left: 2.5rem;
    padding-right: 2.5rem;

    color: #fbf9f3;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    .header {
      font-size: 40px;
      line-height: 40px;
      text-align: left;
    }

    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    span {
      font-weight: 700;
    }

    p {
      margin-bottom: 0rem;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      list-style: none;

      li {
        position: relative;
        padding-left: 1rem; /* Ensure text aligns correctly */

        &::before {
          content: '•'; /* Custom bullet */
          color: #c98f00; /* Bullet color */
          position: absolute;
          left: 0; /* Align with padding */
        }
      }
    }
  }

  .separator {
    height: 1px;
    background-color: #5b4816;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1250px) {
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    margin-top: 10rem;
  }

  .content-wrapper {
    @media (min-width: 1250px) {
      max-width: 1500px;
      display: flex;
      flex-direction: row;
    }
  }

  .content {
    @media (min-width: 1250px) {
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      max-width: 800px;
    }
  }
`

export const AngelImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  img {
    display: block;
    margin-top: -4rem;
    max-width: 500px;
    width: 100%;
  }

  @media (min-width: 1250px) {
    img {
      height: 800px;
      margin-top: 0rem;
    }

    transform: scale(1.2);
  }
`
