import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { DataService } from '../../../../../lib/dataService'; // Make sure the path is correct

export interface RegisterUserReqBody {
  campanha_origem?: number;
  email: string;
  nome: string;
  numero_documento: string;
  pais: string;
  password: string;
  tipo_documento: 'cpf' | 'cnpj' | 'identidade_estrangeira';
}

export function useRegisterUser() {
  const history = useHistory();

  const mutation = useMutation({
    mutationFn: async (formatedValues: RegisterUserReqBody) => {
      const response = await DataService.post('/users/register', formatedValues);
      return response;
    },
    onSuccess: () => {
      notification.success({
        message: 'Cadastro com sucesso!',
        description: 'Por favor, confirme seu e-mail para poder prosseguir!',
        duration: 30,
      });
      history.push('/benfeitor/confirmar-email');
    },
    onError: (error: any) => {
      notification.error({
        message: 'Falha ao Cadastrar',
        description: error?.response?.data?.error || 'Algo deu errado, por favor, tente novamente!',
        duration: 10,
      });
    },
  });

  return mutation;
}
