import { notification } from 'antd'
import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import { DataService } from '../../../../../../lib/dataService'

export interface CreateFichaVocacionalProps {
  aceitacao_familiar: string
  cursos: string
  deixou_religiao_anterior_em: string
  descricao_problema_saude: string
  escolaridade: string
  estado_civil: string
  fk_usuario_vocacional_id: number
  identificacao_instituto: string
  motivacao_admissao_vocacional: string
  motivacao_instituto: string
  motivo_divorcio: string
  profissao: string
  referencia_conhecimento_instituto: string
  remedio_controlado: string[]
  rotina_diaria: string
  seminario_realizado_em: string
  testemunho_conversao: string
  foto_vocacional: File
}

export function useCreateFichaVocacional() {
  const createFichaVocacional = useMutation({
    mutationFn: async ({ ...vocacionalData }: CreateFichaVocacionalProps) => {
      const fichaVocacionalDataToSubmit = {
        ...vocacionalData,
        deixou_religiao_anterior_em: vocacionalData.deixou_religiao_anterior_em
          ? moment(vocacionalData.deixou_religiao_anterior_em, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        seminario_realizado_em: vocacionalData.seminario_realizado_em
          ? moment(vocacionalData.seminario_realizado_em, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        remedio_controlado_inicio: vocacionalData.remedio_controlado
          ? moment(vocacionalData.remedio_controlado[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        remedio_controlado_termino: vocacionalData.remedio_controlado
          ? moment(vocacionalData.remedio_controlado[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
      }

      const formData = new FormData()

      formData.append('ficha_vocacional', JSON.stringify(fichaVocacionalDataToSubmit))
      formData.append('foto_vocacional', vocacionalData.foto_vocacional)

      await DataService.post('/vocacional/registrar-ficha-vocacional', formData)
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao preencher a ficha vocacional',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return createFichaVocacional
}
