import { http, HttpResponse } from 'msw'
import { EsmUserData } from '../../container/alistamento-sm/contexts/alistamento-sm-context'

const EsmTokenFromEmail =
  '.eJwVjMEKwzAMQ__F5zK2DpK2p532G8VNXDAkdolTdhj797lCJz1JXxCtBAu8bTc3DEAVuXiS1DiRxXF8oWQW-tgtafVGp0K7yjV7PubJFacYHZx2YmNdOcMS5nsIAxzI5r2tofmrb_nQNWFG600dYGHrWEm6wu8PxQguXg.Z6Pmbg.bi5Je364cOqb3g7TfVfebFXPgZs'

export const usersActiveAccountTokenMock = http.post<{ token: string }, never, EsmUserData>(
  `/users/active-account/:token`,
  async ({ params }) => {
    const { token } = params

    if (token === EsmTokenFromEmail) {
      return HttpResponse.json({
        email: 'testtokenfromemail@gmail.com',
        nome: 'testeTokenFromEmail',
        pais: 'brasil',
        telefone: '31988887877',
        usuario_id: 69066,
      })
    }
  },
)
