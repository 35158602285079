import { SmBaseButton } from '../../../components/button/styles'
import { GoldenHeader } from '../../../components/golden-header/styles'
import { AngelImageWrapper, FifthSectionStyled } from './styles'
import standingAngel2Img from '../../../../images/standing_angel_img-3.png'
import { useHistory } from 'react-router-dom'

export function FifthSection() {
  const history = useHistory()

  return (
    <FifthSectionStyled>
      <div className="content-wrapper">
        <div className="content">
          <GoldenHeader className="header">Deus preparou algo especial para você:</GoldenHeader>
          <p>Como General Oficial, você terá:</p>
          <ul>
            <li>
              <span>Terá acesso integral ao CTH</span> (CENTRO DE TREINAMENTO HESED), a plataforma de cursos do
              Instituto Hesed.
            </li>
            <li>
              Acesso a <span>módulos especiais e exclusivos</span> de formação;
            </li>
            <div className="separator" />
            <li>
              <span>Receberá a Carteira FÍSICA</span> de identificação.
            </li>
            <div className="separator" />
            <li>
              <span>Participará de uma comunidade EXCLUSIVA,</span>
              onde receberá o acompanhamento direto realizado pelo Quartel General do Exército de São Miguel.
            </li>
            <div className="separator" />
            <li>
              <span>Participará de uma comunidade EXCLUSIVA,</span> onde receberá o acompanhamento direto realizado pelo
              Quartel General do Exército de São Miguel.
            </li>
            <div className="separator" />
            <li>
              Participará de <span>encontros EXCLUSIVOS </span>com os irmãos e irmãs do Instituto Hesed.
            </li>
          </ul>
          <div style={{ marginBottom: '5rem' }}>
            <SmBaseButton
              onClick={() => {
                history.push(`/membro-exercito/checar-grupo`)
              }}
            >
              SIM, QUERO ACEITAR ESTE CHAMADO ESPECIAL
            </SmBaseButton>
          </div>
        </div>

        <AngelImageWrapper>
          <img src={standingAngel2Img} />
        </AngelImageWrapper>
      </div>
    </FifthSectionStyled>
  )
}
