import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { defineColorBySeverity } from '../../../../utility/utility'

export const StyledButton = styled(Button)`
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  gap: 0.3rem !important;
  color: var(--button-text-color) !important;
  background-color: var(--button-bg-color) !important;
  border: 1px solid transparent !important;
  padding: 1.2rem !important;
  border-radius: 10px !important;
  font-weight: bold !important;

  &.ant-btn-loading {
    cursor: not-allowed;
  }

  &.ant-btn-background-ghost {
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      color: var(--text-secondary-color) !important;
      border-color: var(--text-secondary-color) !important;
      cursor: not-allowed;
    }

    &:hover,
    &:focus {
      background-color: var(--button-bg-hover-color) !important;
      color: var(--button-text-color) !important;
      border-color: var(--button-bg-hover-color) !important;
    }
  }

  ${(props) =>
    props.$noStyle &&
    css`
      width: unset !important;
      padding: 0 !important;
      height: unset !important;
    `}

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--button-text-hover-color) !important;
    background-color: var(--button-bg-hover-color) !important;
  }

  ${(props) =>
    !props.danger &&
    css`
      ${props.$severity &&
      css`
        background-color: ${defineColorBySeverity(props.$severity, 0.38)} !important;
        color: ${defineColorBySeverity(props.$severity)} !important;
        border-color: ${defineColorBySeverity(props.$severity)} !important;

        &:hover {
          background-color: ${defineColorBySeverity(props.$severity)} !important;
          border-color: ${defineColorBySeverity(props.$severity)} !important;
          color: var(--text-main-color) !important;
        }

        &:focus {
          background-color: ${defineColorBySeverity(props.$severity)} !important;
          border-color: ${defineColorBySeverity(props.$severity)} !important;
          color: var(--text-main-color) !important;
        }
      `}

      ${props.type === 'text' &&
      css`
        background: transparent !important;
        color: var(--text-main-color) !important;

        :hover,
        :active,
        :focus {
          background-color: transparent !important;
          font-weight: 500;
          color: var(--text-main-color) !important;
          opacity: 0.5;
        }
      `}

      ${props.type === 'ghost' &&
      css`
        color: var(--text-main-color) !important;
        background-color: var(--background-color) !important;
        border: 1px solid var(--input-border-color) !important;

        &:hover {
          color: var(--button-text-color) !important;
          background-color: var(--button-bg-color) !important;
        }
      `}

      ${props.type === 'primary' &&
      css`
        background: var(--primary-color) !important;

        &:hover {
          background: var(--secondary-color) !important;
          border-color: var(--secondary-color) !important;
          color: var(--button-text-hover-color) !important;
        }
      `}

     ${props.type === 'link' &&
      css`
        background-color: transparent !important;
        color: var(--info-color) !important;
        text-decoration: underline !important;
        padding: 0 !important;
        :hover,
        :active,
        :focus {
          background-color: inherit !important;
          color: var(--info-color) !important;
          opacity: 0.8;
        }
      `};
    `}
`
