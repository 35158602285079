import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

async function fetchCampaignsOptions() {
  try {
    const response = await DataService.get('campaigns/info')
    const campaigns = response?.data?.campanhas?.map((campaign, index) => {
      return {
        value: campaign.id,
        label: campaign.titulo,
        objetivo: campaign.objetivo,
      }
    })
    return campaigns
  } catch (error) {
    console.error('Error fetching campaigns:', error)

    throw new Error('Error fetching campaigns')
  }
}

export function useFetchCampaignsOptions() {
  const campaignsOptions = useQuery({
    queryKey: ['CAMPAIGN_OPTIONS'],
    queryFn: fetchCampaignsOptions,
  })

  return campaignsOptions
}
