import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useGetActionDetails(id) {
  const actionDetails = useQuery({
    queryKey: ['ACTION_DETAILS', id],
    queryFn: async () => {
      const response = await DataService.get(`/administradores/buscar-detalhes-acao/${id}`)
      return response.data
    },
  })

  return actionDetails
}
