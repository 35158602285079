const SET_THEME = 'SET_THEME'

export const setTheme = (theme) => {
  return (dispatch) => {
    localStorage.setItem('theme', theme)
    dispatch({
      type: SET_THEME,
      payload: theme,
    })
  }
}

// const preferredTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
export const defaultTheme = localStorage.getItem('theme') || 'dark'
localStorage.setItem('theme', defaultTheme)

const initialState = {
  theme: defaultTheme,
}

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      }
    default:
      return state
  }
}

export default themeReducer
