import styled from 'styled-components'

const Container = styled.div`
  margin-top: 6.5rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;

  @media (min-width: 600px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 1200px;
    margin-top: 4rem;
  }
`

const SmCard = styled.div`
  background-color: #efe5d8;
  border-radius: 50px;
  padding: 40px;

  p {
    text-align: center;
    /* text-align: left; */
    font-weight: 400;
    line-height: 22px;
  }

  span {
    font-weight: 700;
  }
`
const SmCardTitle = styled.div`
  font-family: '#DM Serif Display';
  font-weight: bold;
  font-size: 34px;
  line-height: 42.7px;
  text-align: center;
  color: #172743;
  margin-bottom: 2rem;
`

export function CardWhatIsSaoMiguel() {
  return (
    <Container>
      <SmCard>
        <SmCardTitle>O que é o Exército de São Miguel</SmCardTitle>
        <p>
          O Exército de São Miguel surgiu como um <span>apelo do coração de Deus</span> às Madres fundadoras do
          Instituto Hesed: Me. Jane Madeleine e Me. Kelly Patrícia.
        </p>
        <p>
          Em meio à pandemia, tempo em que a humanidade vivia uma angústia e desolação sem tamanho,
          <span> Deus nos inspirou a levar a Sua Presença Eucarística</span> através das adorações transmitidas por meio
          das redes sociais ao maior número de lares. Assim, proclamando que não viveríamos nenhum dia sem Jesus, vimos
          formar-se um verdadeiro Exército de adoradores.
        </p>
        <p>
          Por meio da récita do Santo Rosário e do amor à Eucaristia, milhares de testemunhos começaram a chegar: vidas
          e famílias estavam sendo transformadas através da oração. Desse modo, as inúmeras curas, conversões e
          libertações nos impulsionaram a continuar.
        </p>
        <p>
          Apoiados na profecia do livro de Daniel, acreditando que nesse tempo de angústia, surgiria Miguel para vir em
          nosso socorro e inspirados pelo próprio Deus, resolvemos tornar pública uma devoção vivida há mais de 25 anos
          internamente pelos irmãos do Instituto Hesed: A devoção ao Glorioso São Miguel.
        </p>
        <p>
          Não somente durante os 40 dias da Quaresma de São Miguel, mas de forma contínua, diariamente através das
          várias transmissões que perpassam os mais diversos horários do dia abraçamos a missão de propagar a devoção
          aos Santos Anjos, conscientes de que Deus é o centro do mundo Angélico. Assim, milhares de pessoas já puderam
          testemunhar os frutos da devoção à São Miguel em suas vidas.{' '}
        </p>
      </SmCard>
    </Container>
  )
}
