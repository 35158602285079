import { useState } from 'react'
import { generateQueryString, handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import { useFetchDonations } from '../../../../hooks/donations/queries/useFetchDonations'
import { useDonationActionModal } from './hooks/useDonationActionModal'
import { useDonationsDataSource } from './hooks/useDonationDataSource'
import { useDonationDetailsModal } from './hooks/useDonationDetailsModal'
import { DonationActionsModal } from './components/DonationActionsModal'
import { DonationDetailsModal } from './components/DonationDetailsModal'
import { DonationsListTable } from './components/DonationsListTable'
import { DonationTableFiltersModal } from './components/Filters/DonationTableFilters'

export type DonationsTableVisibleColumns =
  | 'id'
  | 'recorrencia'
  | 'cliente'
  | 'data'
  | 'valor'
  | 'status'
  | 'campanha'
  | 'acoes'

export type DonationsTableVisibleFilters =
  | 'nome'
  | 'campanha'
  | 'status'
  | 'data'
  | 'forma_pagamento'
  | 'tipo_doacao'
  | 'gateway'
  | 'codigo_referencia'
  | 'transaction_id'
  | 'recorrencia'

interface DonationTableProps {
  visibleFilters?: DonationsTableVisibleFilters[]
  defaultFilters?: Record<string, any>
  visibleColumns?: DonationsTableVisibleColumns[]
}

export default function DonationTable({
  visibleFilters = [],
  defaultFilters = {},
  visibleColumns = [],
}: DonationTableProps) {
  const initialFilters = {
    ...defaultFilters,
    queryString: generateQueryString(defaultFilters),
  }

  const [filters, setFilters] = useState(initialFilters)
  const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false)
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { actionModalContent, handleOpenActionModal, handleCloseActionModal } = useDonationActionModal()
  const { donationDetailsModalContent, handleOpenDetailsModal, handleCloseDetailsModal } = useDonationDetailsModal()

  const donations = useFetchDonations(filters)
  const dataSource = useDonationsDataSource(donations?.data, handleOpenDetailsModal, handleOpenActionModal, filters)

  return (
    <>
      <DonationActionsModal actionModalContent={actionModalContent} handleCloseActionModal={handleCloseActionModal} />
      <DonationDetailsModal
        donationDetailsModalContent={donationDetailsModalContent}
        handleCloseDetailsModal={handleCloseDetailsModal}
      />
      <DonationTableFiltersModal
        open={openFiltersModal}
        handleInputChange={handleInputChange}
        handleClose={() => setOpenFiltersModal(false)}
        visibleFilters={visibleFilters}
        dataSource={dataSource}
        filters={filters}
      />
      <DonationsListTable
        visibleColumns={visibleColumns}
        handleInputChange={handleInputChange}
        dataSource={dataSource}
        donations={donations}
        handleOpenFiltersModal={() => setOpenFiltersModal(true)}
        filters={filters}
        visibleFilters={visibleFilters}
      />
    </>
  )
}
