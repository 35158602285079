import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetMembrosFamiliaResponse {
  membros: {
    cpf: string
    email: string
    id: number
    idade: number
    nome: string
    ocupacao: string
    renda: string
    telefone: string
    responsavel: boolean
  }[]

  page: number
  total: number
}

export function useGetMembrosFamilia({ idFamilia }: { idFamilia: number }): UseQueryResult<GetMembrosFamiliaResponse> {
  const familias = useQuery({
    queryKey: ['GET_MEMBROS_FAMILIA', idFamilia],
    queryFn: async () => {
      const response = await DataService.get(`agape/listar-membros/${idFamilia}`)
      return response.data
    },
  })

  return familias
}
