import { Button, Form, Input, Spin } from 'antd'
import { GoldenHeader } from '../../components/golden-header/styles'
import { validateCPF } from '../../../../../utility/utility'
import ReactInputMask from 'react-input-mask'
import React from 'react'
import { SetNationalityButton } from '../styles'
import { DocumentoIdentidade } from '..'

interface CheckUserDocumentNumberProps {
  handleSearchDocumentNumber: Function
  isPending: boolean
  isForeigner: boolean
  setIsForeigner: React.Dispatch<React.SetStateAction<boolean>>
}

export function CheckUserDocumentNumber({
  handleSearchDocumentNumber,
  isPending,
  isForeigner,
  setIsForeigner,
}: Readonly<CheckUserDocumentNumberProps>) {
  const [form] = Form.useForm()

  return (
    <>
      <GoldenHeader className="header-pc">
        VERIFIQUE SE O SEU NOME ESTÁ INSCRITO NO LIVRO DE MEMBROS OFICIAIS DO EXÉRCITO DE SÃO MIGUEL:
      </GoldenHeader>
      <Form
        name="email-form"
        layout="vertical"
        form={form}
        onFinish={({ numero_documento }) => {
          return handleSearchDocumentNumber({
            numero_documento,
            tipo_documento: isForeigner ? DocumentoIdentidade.IDENTIDADE_ESTRANGEIRA : DocumentoIdentidade.CPF,
          })
        }}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}
      >
        <div style={{ height: '105px', width: '100%' }}>
          {isForeigner ? (
            <>
              <Form.Item
                name="numero_documento"
                label="Insira o seu Documento de Identidade"
                style={{ marginBottom: '8px' }}
                rules={[
                  { required: true, message: 'Documento é obrigatório' },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Apenas números são válidos',
                  },
                  { max: 20, message: 'Máximo 20 dígitos' },
                ]}
              >
                <Input placeholder="999999" />
              </Form.Item>
              <SetNationalityButton
                onClick={() => {
                  setIsForeigner(false)
                }}
              >
                Reside no Brasil?
              </SetNationalityButton>
            </>
          ) : (
            <>
              <Form.Item
                name="numero_documento"
                label="Insira o seu CPF"
                style={{ marginBottom: '8px' }}
                rules={[{ required: true, message: 'CPF é obrigatório' }, { validator: validateCPF }]}
              >
                <ReactInputMask mask="999.999.999-99" maskChar={null} placeholder="999.999.999-99">
                  {(inputProps) => <Input {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
              <SetNationalityButton
                onClick={() => {
                  setIsForeigner(true)
                }}
              >
                Reside no exterior?
              </SetNationalityButton>
            </>
          )}
        </div>

        <Button htmlType="submit" disabled={isPending} loading={isPending} style={{ borderRadius: '10px' }}>
          Enviar
        </Button>
      </Form>
    </>
  )
}
