import { Col, Form, Row } from 'antd'
import { PlusCircle, TrashSimple } from '@phosphor-icons/react'
import BaseCard from '../../../../../components/card/BaseCard'
import BaseButton from '../../../../../components/button/BaseButton'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'
import { BaseInputNumber } from '../../../../../components/inputs/InputNumber/BaseInputNumber'
import { BaseDivider } from '../../../../../components/divider/BaseDivider'
import { FormInstance } from 'antd/es/form/Form'
import { useState } from 'react'

interface ListaFormularioItensDoacaoProps {
  form: FormInstance

  itensEstoque: {
    id: number
    item: string
    quantidade?: number
  }[]

  desativarAdicionarItem: boolean
}

export interface EstoqueAgapeItemFormResponse {
  fk_estoque_agape_id: {
    label: string
    value: number
    key: string
  }
  quantidade: number
}

export function transformarArrayDoacoes(arrayDoacoes: EstoqueAgapeItemFormResponse[]) {
  return arrayDoacoes.map((item) => ({
    fk_estoque_agape_id: item.fk_estoque_agape_id.value,
    quantidade: item.quantidade,
  }))
}

export function ListaFormularioItensDoacao({
  form,
  itensEstoque,
  desativarAdicionarItem,
}: ListaFormularioItensDoacaoProps) {
  const itensSelecionados: EstoqueAgapeItemFormResponse[] = Form.useWatch('doacoes', form) || []

  const filteredEstoque = itensEstoque?.filter(
    (item) =>
      !itensSelecionados.some(
        (doacao: { fk_estoque_agape_id: { value: number } }) =>
          doacao.fk_estoque_agape_id?.value === item.id,
      ),
  )

  return (
    <Form.List name="doacoes" initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => {
            const itemAtual = itensEstoque?.find(
              (item) => item.id === itensSelecionados[index]?.fk_estoque_agape_id?.value,
            )

            return (
              <BaseCard
                size="small"
                key={index}
                title={
                  <h6>
                    <Row justify="space-between"> Item {index + 1}</Row>
                  </h6>
                }
                extra={
                  index > 0 && (
                    <Row justify="space-between">
                      <BaseButton
                        type="text"
                        onClick={() => {
                          remove(field.name)
                        }}
                        style={{ color: 'var(--text-main-color)' }}
                      >
                        <TrashSimple size={25} />
                      </BaseButton>
                    </Row>
                  )
                }
              >
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={12}>
                    <BaseForm.Item
                      name={[field.name, 'fk_estoque_agape_id']}
                      label="Item"
                      rules={[{ required: true, message: 'Obrigatório' }]}
                    >
                      <BaseSelect
                        placeholder="Pesquise e selecione uma opção"
                        showSearch
                        allowClear
                        labelInValue
                      >
                        {filteredEstoque?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.item}
                          </Option>
                        ))}
                      </BaseSelect>
                    </BaseForm.Item>

                    <p style={{ margin: 0, textAlign: 'center' }}>
                      {itemAtual && `${itemAtual?.quantidade} disponíveis`}
                    </p>
                  </Col>

                  <Col xs={24} sm={12}>
                    <BaseForm.Item
                      name={[field.name, 'quantidade']}
                      label="Quantidade"
                      rules={[{ required: true, message: 'Obrigatório' }]}
                    >
                      <BaseInputNumber
                        block
                        controls={false}
                        prefix=""
                        placeholder="Mínimo: 1"
                        min={1}
                        formatter={(value) =>
                          value
                            ? `${value
                              .toString()
                              .replace(/\D/g, '')
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                              .replace(/\.(\d{2})$/, ',$1')}`
                            : ''
                        }
                        parser={(value) =>
                          value ? Number(value.replace(/\./g, '').replace(',', '.')) : 0
                        }
                      />
                    </BaseForm.Item>
                  </Col>
                </Row>

                <BaseDivider />
              </BaseCard>
            )
          })}
          <Row justify="center">
            <BaseButton
              severity="info"
              onClick={() => add({})}
              style={{ marginBottom: '2.5rem' }}
              disabled={fields.length >= itensEstoque?.length || desativarAdicionarItem}
            >
              Adicionar item
              <PlusCircle size={30} />
            </BaseButton>
          </Row>
        </>
      )}
    </Form.List>
  )
}
