import styled from 'styled-components'
import validationPageBg from '../../images/validation-page-bg.png'
import { Steps, Upload } from 'antd'

export const Container = styled.div`
  background-image: url(${validationPageBg});
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 600px) {
    padding-top: 0rem;
  }

  .nums-bottom {
    display: flex;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    transform: scale(1.25);
    object-fit: contain;
    margin-top: 5rem;
    pointer-events: none;

    @media (min-width: 1000px) {
      max-width: 1000px;
      margin-top: 3rem;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 6rem;
    margin-top: 2rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 2rem;
  padding-right: 2rem;

  @media (max-width: 600px) {
    justify-content: initial;
  }
`
export const StyledGrid = styled.div<{ columns: number }>`
  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};

    column-gap: 2rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }
`

export const ImgStyled = styled.img`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`

export const StyledSteps = styled(Steps)``

export const StyledUpload = styled(Upload)``

export const SetNationalityButton = styled.div`
  font-size: 12px;
  color: #cccccc;
  cursor: pointer;

  &:hover {
    transition: 0.2s all;
    color: #99998d;
  }
`

export const RecoverPasswordContainer = styled.div`
  color: #c98f00;
  cursor: pointer;
  font-size: 14px;
  margin-top: 1rem;
  display: flex;
  margin-right: auto;

  &:hover {
    color: #956a00;
  }
`
