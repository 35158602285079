import { cadastrarFamiliaMock } from './admin/agape/familia/cadastrar-familia-mock'
import { cardsEstatisticasEstoqueMock } from './admin/agape/familia/cards-estatisticas-estoque-mock'
import { cardsEstatisticasFamiliasMock } from './admin/agape/familia/cards-estatisticas-familias-mock'
import { listarEnderecosFamiliasMock } from './admin/agape/familia/listar-enderecos-familias-mock'
import { listarFamiliasMock } from './admin/agape/familia/listar-familias-mock'
import { createCadastroMock } from './benfeitor/vocacional/create-cadastro-mock'
import { createFichaVocacionalMock } from './benfeitor/vocacional/create-ficha-vocacional-mock'
import { createPreCadastroMock } from './benfeitor/vocacional/create-pre-cadastro-mock'
import { decodeTokenVocacionalMock } from './benfeitor/vocacional/decode-token-vocacional-mock'
import { withdrawalVocacionalMock } from './benfeitor/vocacional/withdrawal-vocacional'
import { buscarUsuarioPorNumeroDocumentoMock } from './esm/buscar-usuario-por-numero-documento-mock'
import { cadastrarUsuarioCompletoMock } from './esm/cadastrar-usuario-completo-mock'
import { forgotPasswordMock } from './esm/forgot-password-mock'
import { newPasswordTokenMock } from './esm/new-password-token-mock'
import { registrarGeneralMock } from './esm/registar-general-mock'
import { usersActiveAccountTokenMock } from './esm/users-active-account-token-mock'
import { usersUpdateMock } from './esm/users-update-mock'
import { searchCepMock } from './utils/cep-search-mock'
import { loginMock } from './utils/login-mock'
import { userMeAddressMock } from './utils/user-me-address-mock'
import { userMeMock } from './utils/user-me-mock'
import { userPermissionsMock } from './utils/user-me-permissions-mocks'

export const apiHandlers = [
  // utils
  searchCepMock,
  loginMock,
  userMeAddressMock,
  userMeMock,
  userPermissionsMock,
  // esm:
  buscarUsuarioPorNumeroDocumentoMock,
  cadastrarUsuarioCompletoMock,
  forgotPasswordMock,
  newPasswordTokenMock,
  registrarGeneralMock,
  usersActiveAccountTokenMock,
  usersUpdateMock,
  // agape familia handlers:
  cardsEstatisticasFamiliasMock,
  cardsEstatisticasEstoqueMock,
  listarEnderecosFamiliasMock,
  listarFamiliasMock,
  cadastrarFamiliaMock,
  // vocacional handlers:
  createPreCadastroMock,
  decodeTokenVocacionalMock,
  createCadastroMock,
  withdrawalVocacionalMock,
  createFichaVocacionalMock,
]
