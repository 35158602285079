import { Col, Row } from 'antd'
import { Package } from '@phosphor-icons/react'
import { GroupCard } from '../../../../../components/GroupCard'
import { useGetEstatisticasEstoque } from '../../../../../hooks/agape/queries/useGetEstatisticasEstoque'

export function CardsItens() {
  const {
    data: estatisticasEstoque,
    isLoading: isLoadingEstatisticasEstoque,
    isError: isErrorEstatisticasEstoque,
  } = useGetEstatisticasEstoque()

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} sm={24} md={8} lg={6}>
        <GroupCard
          title="Itens"
          icon={Package}
          number={estatisticasEstoque?.itens_em_estoque}
          balance={undefined}
          isLoading={isLoadingEstatisticasEstoque}
          isError={isErrorEstatisticasEstoque}
        />
      </Col>

      <Col xs={24} sm={24} md={8} lg={6}>
        <GroupCard
          title="Última ação"
          icon={Package}
          number={estatisticasEstoque?.ultima_acao}
          balance={undefined}
          isLoading={isLoadingEstatisticasEstoque}
          isError={isErrorEstatisticasEstoque}
        />
      </Col>

      <Col xs={24} sm={24} md={8} lg={6}>
        <GroupCard
          title="Última entrada"
          icon={Package}
          number={estatisticasEstoque?.ultima_entrada}
          balance={undefined}
          isLoading={isLoadingEstatisticasEstoque}
          isError={isErrorEstatisticasEstoque}
        />
      </Col>
    </Row>
  )
}
