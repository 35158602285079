import { BaseSteps } from '../../../../../components/steps'
import { EnviarRecibosForm } from '../../Recibos/EnviarRecibos'
import { CadastrarDoacaoSteps } from '../utils/CadastrarCicloDoacaoStepItems'

interface EnviarRecibosProps {
  idDoacao: number
  avancarParaSucesso: () => void
}

export function EnviarRecibos({ idDoacao, avancarParaSucesso }: EnviarRecibosProps) {
  return (
    <>
      <BaseSteps current={3} items={CadastrarDoacaoSteps} />

      <EnviarRecibosForm idDoacao={idDoacao} acaoPosEnviarRecibo={avancarParaSucesso} />
    </>
  )
}
