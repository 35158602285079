import styled from 'styled-components';

export const DonationContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 3rem;

  @media (max-width: 576px) {
    /* padding: 0 1rem; */
    padding: 0 0.5rem;
  }
`;

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  column-gap: 5rem;

  @media (max-width: 1400px) {
    display: flex;
    flex-direction: column;

    .campaign-container {
      margin-top: 2rem;
      justify-content: flex-start;
      max-width: 700px;
      
      img {
        max-width: 450px !important;
      }

    }
  }
`;
