import { useRef } from 'react'
import styled from 'styled-components'
import BaseSpin from '../../../../../components/spin/BaseSpin'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { ChartTitle } from '../../../styles'

export function ListTable({ query }) {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = query

  const tableRef = useRef(null)

  const handleScroll = () => {
    const bottom = tableRef.current.scrollHeight === tableRef.current.scrollTop + tableRef.current.clientHeight
    if (bottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  return (
    <>
      <ChartTitle>Lista de Doações de Hoje</ChartTitle>
      <ScrollableTable ref={tableRef} onScroll={handleScroll}>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Hora</th>
              <th>Benfeitor</th>
              <th>Valor</th>
              <th>Campanha</th>
              <th>Método</th>
            </tr>
          </thead>
          <tbody>
            {isPending ? (
              <tr>
                <td colSpan="5" style={{ textAlign: 'center', padding: '8px' }}>
                  <BaseSpin tip="Carregando..." />
                </td>
              </tr>
            ) : (
              data?.pages?.map((page) =>
                page?.data?.map((donation) => (
                  <tr key={donation?.pedido?.fk_pedido_id}>
                    <td>{donation?.pedido?.fk_pedido_id}</td>
                    <td>{donation?.pedido?.data_doacao.split(' ')[1]}</td>
                    <td>{donation?.benfeitor?.nome}</td>
                    <td>{formatToBrazilianCurrency(donation?.pedido?.valor_doacao)}</td>
                    <td>{donation?.campanha?.titulo}</td>
                    <td>{donation?.pedido?.forma_pagamento}</td>
                  </tr>
                )),
              )
            )}
            {isFetchingNextPage && (
              <tr>
                <td colSpan="6" style={{ textAlign: 'center', padding: '1rem', color: 'gray' }}>
                  <>
                    <div>Carregando mais...</div>
                    <BaseSpin />
                  </>
                </td>
              </tr>
            )}
            {data && !isPending && !isFetchingNextPage && !hasNextPage && (
              <tr>
                <td colSpan="6" style={{ textAlign: 'center', padding: '16px', color: 'gray' }}>
                  Não há mais itens para carregar...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScrollableTable>
    </>
  )
}

const ScrollableTable = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border: none !important;
  color: var(--text-main-color);
  background-color: #2c2c2c;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th {
      position: sticky;
      top: 0;
      background-color: var(--custom-table-bg-color);
      color: var(--text-main-color);
      padding: 1rem;
      font-weight: 600;
      text-align: center;
      border-bottom: 1px solid #4a4a4a;
    }

    tbody {
      tr {
        background-color: var(--card-bg-color);

        &:hover {
          background-color: var(--custom-table-bg-color-hover) !important;
          cursor: pointer;
        }

        td {
          padding: 1rem;
          text-align: center;
          border-top: 1px solid #4a4a4a;
          overflow: hidden;
        }
      }
    }
  }
`
