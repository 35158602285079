import { RESET_FILTERS, SET_FILTERS } from './actions'

const initialState = {
  filters: {},
  queryString: '',
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS: {
      const updatedFilters = {
        ...state.filters,
        ...action.payload,
      }

      const newQueryString = Object.entries(updatedFilters)
        .filter(([, v]) => v !== undefined && v !== null && v !== '')
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&')

      return {
        ...state,
        filters: updatedFilters,
        queryString: newQueryString,
      }
    }
    case RESET_FILTERS:
      return initialState
    default:
      return state
  }
}

export default filterReducer
