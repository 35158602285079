import { Col, Row, Skeleton } from 'antd'
import { BaseCarousel } from '../../../../components/carousel/BaseCarousel'
import { FetchTopDonorsProps, useFetchTopDonors } from '../../../../hooks/donations/queries/useFetchTopDonorsCarousel'
import { BaseAvatarPhoto } from '../../../../components/avatar/BaseAvatarPhoto/BaseAvatarPhoto'
import { useHistory } from 'react-router-dom'
import { getFirstAndSecondName } from '../../../../../../utility/utility'
import BaseAlert from '../../../../components/alert/BaseAlert'

interface TopDonorsCarouselProps {
  filters: FetchTopDonorsProps['filters']
}

export function TopDonorsCarousel({ filters }: TopDonorsCarouselProps) {
  const history = useHistory()
  const { data: topDonors, isLoading: isLoadingTopDonors, isError: isErrorTopDonors } = useFetchTopDonors({ filters })

  const emptyTopDonors = topDonors?.top_doadores.length === 0

  if (isErrorTopDonors) {
    return (
      <Row justify="center">
        <Col>
          <BaseAlert showIcon message="Houve um erro ao exibir esse conteúdo" type="error" />
        </Col>
      </Row>
    )
  }

  if (emptyTopDonors) {
    return (
      <Row justify="center">
        <Col>
          <BaseAlert showIcon message="Não há doadores para essa filtragem." type="info" />
        </Col>
      </Row>
    )
  }

  return (
    <BaseCarousel infinite={false} slidesToShow={7} slidesToScroll={7} title={undefined} style={undefined}>
      {isLoadingTopDonors &&
        Array.from({ length: 10 }).map((_, index) => (
          <div key={index} className="user-table-avatar-wrapper">
            <Skeleton.Avatar active size={100} shape="circle" />
          </div>
        ))}

      {topDonors?.top_doadores.map((user) => {
        return (
          <div className="user-table-avatar-wrapper" key={user.fk_usuario_id}>
            <BaseAvatarPhoto
              key={user.fk_usuario_id}
              src={user?.avatar}
              hoverContent={user?.fk_usuario_id ? user?.nome : false}
              style={{ whiteSpace: 'pre-line', lineHeight: '1' }}
              fontSize="20px"
              firstAndSecondName
              size={{
                xs: 150,
                sm: 150,
                md: 150,
                lg: 150,
                xl: 100,
                xxl: 150,
              }}
              onClick={() => {
                if (user?.fk_usuario_id) {
                  history.push(`/admin/users/details/${user.fk_usuario_id}`)
                }
              }}
            >
              {getFirstAndSecondName(user?.nome, false)}
            </BaseAvatarPhoto>
          </div>
        )
      })}
    </BaseCarousel>
  )
}
