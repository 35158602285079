import { Button, Form, FormInstance, Spin } from 'antd'
import { MultiStepFormProps, Step } from '../../../../../benfeitor/pages/Carteira/Cadastro/hooks/useMultistepForm'
import { StepsProgress } from './steps-progress'

interface StepsContentProps {
  steps: Step[]
  form: FormInstance
  handleFinish: Function
  isPending: boolean
  multiStepForm: MultiStepFormProps
}

export function StepsContent({ steps, form, handleFinish, isPending, multiStepForm }: Readonly<StepsContentProps>) {
  return (
    <>
      <div>
        <StepsProgress steps={steps} currentStep={multiStepForm.currentStep} />
      </div>
      <Form form={form} layout="vertical">
        <div>{steps[multiStepForm?.currentStep].content}</div>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
          {!multiStepForm?.isFirstStep && (
            <Button style={{ marginRight: 8, width: '30%' }} onClick={() => multiStepForm?.prev()}>
              Voltar
            </Button>
          )}
          {!multiStepForm?.isLastStep && (
            <Button style={{ width: '100%' }} onClick={() => multiStepForm?.next()}>
              Próximo
            </Button>
          )}
          {multiStepForm?.isLastStep && (
            <Button style={{ width: '65%' }} onClick={() => handleFinish()} disabled={isPending} loading={isPending}>
              Concluir
            </Button>
          )}
        </div>
      </Form>
    </>
  )
}
