import { Button, Form, Input } from 'antd'
import ReactInputMask from 'react-input-mask'
import { useState } from 'react'
import SuccessPage from './SuccessPage'
import { useRegisterLeadUser } from '../../../hooks/users/Mutations/useRegisterLeadUser'
import { validateTelefone } from '../../../../../utility/utility'
import { StyledForm } from '../../../../benfeitor/components/Form/styles'
import { StyledInput } from '../../../../benfeitor/components/Inputs'
import { StyledButton } from '../../../../benfeitor/components/Button'

export default function ActionEmbedForm({ origem }) {
  const [form] = Form.useForm()

  const [isFnisihed, setIsFinished] = useState(false)

  const { mutateAsync: registerLead, isPending } = useRegisterLeadUser()

  const handleFormSubmit = async (values) => {
    try {
      const formData = new FormData()
      formData.append('nome', values.nome)
      formData.append('email', values.email)
      formData.append('telefone', values.telefone)
      formData.append('origem', origem)

      await registerLead(formData)
      setIsFinished(true)
    } catch (error) {
      console.error(error)
      setIsFinished(false)
    }
  }

  if (isFnisihed) {
    return <SuccessPage />
  }

  return (
    <StyledForm form={form} onFinish={handleFormSubmit} layout="vertical">
      <Form.Item name="nome" label="Nome" rules={[{ required: true, message: 'Obrigatório' }]}>
        <StyledInput placeholder="Digite seu nome completo" />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          { type: 'email', message: 'Deve ser um e-mail válido', whitespace: true },
          { required: true, message: 'Obrigatório' },
        ]}
      >
        <StyledInput placeholder="E-mail" />
      </Form.Item>

      <Form.Item
        name="new_email"
        label="Confirmar E-mail"
        dependencies={['email']}
        rules={[
          { type: 'email', message: '' },
          { required: true, message: 'Obrigatório' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
            },
          }),
        ]}
      >
        <StyledInput placeholder="Confirmar" />
      </Form.Item>

      <Form.Item
        name="telefone"
        label="Telefone"
        rules={[
          {
            required: true,
            message: 'Obrigatório',
          },
          {
            validator: validateTelefone,
          },
        ]}
      >
        <ReactInputMask mask="(99) 99999-9999" maskChar="">
          {(inputProps) => <StyledInput {...inputProps} placeholder="Digite seu telefone" />}
        </ReactInputMask>
      </Form.Item>
      <StyledButton htmlType="submit" loading={isPending}>
        Enviar
      </StyledButton>
    </StyledForm>
  )
}
