import {
  DollarOutlined,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
  SettingOutlined,
  AudioOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  UploadOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { DatePicker, Space, Typography } from 'antd'
import { useState } from 'react'
import moment from 'moment'
import { BaseTableUsage } from './table/BaseTable/BaseTableUsage'
import BaseButton from './button/BaseButton'
import BasePopover from './popover/BasePopover'
import BaseModal from './modal/BaseModal'
import { BaseBadge } from './badge/BaseBadge'
import BaseSwitch from './switch/BaseSwitch'
import BaseAvatar from './avatar/BaseAvatar'
import BaseTabs from './tabs/BaseTabs'
import BasePagination from './pagination/BasePagination'
import { BaseNotification } from './notification/BaseNotification'
import BaseCard from './card/BaseCard'
import BaseSpin from './spin/BaseSpin'
import BaseAlert from './alert/BaseAlert'
import BaseProgress from './progress/BaseProgress'
import { BaseInput } from './inputs/BaseInput/BaseInput'
import { SearchInput } from './inputs/SearchInput/SearchInput'
import { InputPassword } from './inputs/InputPassword/InputPassword.Styles'
import BaseForm from './forms/BaseForm/BaseForm'
import BaseButtonsForm from './forms/BaseButtonsForm/BaseButtonsForm'
import BaseRadio from './radio/BaseRadio'
import { BaseUpload } from './upload/BaseUpload'
import { BaseSelect, Option } from './select/BaseSelect'
import DraggerUpload from './upload/DraggerUpload'
import BaseDatePicker from './datepicker/BaseDatePicker'
import { BaseCarousel } from './carousel/BaseCarousel'
import ProgressPerformance from '../../../routes/admin/ProgressPerformance/ProgressPerformance'
import { MultipleDatePicker } from './datepicker/MultipleDatePicker'
import { BaseSteps } from './steps'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export default function uiComponents() {
  const [isBasicModalOpen, setIsBasicModalOpen] = useState(false)
  const [isBasicModalSmallOpen, setIsBasicModalSmallOpen] = useState(false)
  const [isBasicModalMediumOpen, setIsBasicModalMediumOpen] = useState(false)
  const [isBasicModalLargeOpen, setIsBasicModalLargeOpen] = useState(false)
  const [isBasicModalFullscreenOpen, setIsBasicModalFullscreenOpen] = useState(false)

  // const success = () => {
  //   BaseModal.success({
  //     title: 'modals.successTitle',
  //     content: 'modals.someContent',
  //   });
  // };

  // const info = () => {
  //   BaseModal.info({
  //     title: 'modals.infoTitle',
  //     content: 'modals.someContent',
  //   });
  // };

  // const warning = () => {
  //   BaseModal.warning({
  //     title: 'modals.warningTitle',
  //     content: 'modals.someContent',
  //   });
  // };

  // const error = () => {
  //   BaseModal.error({
  //     title: 'modals.errorTitle',
  //     content: 'modals.someContent',
  //   });
  // };

  const uploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: false,
    listType: 'picture',
    onChange: (info) => {
      console.log(info)
    },
  }

  return (
    <>
      <div>
        <BaseTableUsage />
        <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
          {/* <GroupCard.Root>

            <GroupCard.Content>
              <GroupCard.Title title="Total em doações" Icon={DollarOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="R$ 3.854.485,00" />
                <GroupCard.Balance number="3.5" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root>

          <GroupCard.Root isLoading>

            <GroupCard.Content>
              <GroupCard.Title title="Total em doações" Icon={DollarOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="R$ 3.854.485,00" />
                <GroupCard.Balance number="3.5" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root>

          <GroupCard.Root>

            <GroupCard.Content>
              <GroupCard.Title title="Doações do mês" Icon={DollarOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="R$ 267.450,50 | 3 doações" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root>

          <GroupCard.Root>

            <GroupCard.Content>
              <GroupCard.Title title="Doações de hoje" Icon={DollarOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="R$ 611.486" />
                <GroupCard.Balance number="3.5" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root>

          <GroupCard.Root>

            <GroupCard.Content>
              <GroupCard.Title title="Cadastros do mês" Icon={UserOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="421" />
                <GroupCard.Balance number="-40" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root>

          <GroupCard.Root>

            <GroupCard.Content>
              <GroupCard.Title title="Cadastros totais" Icon={UserOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="38.485" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root>

          <GroupCard.Root>

            <GroupCard.Content>
              <GroupCard.Title title="Leads" Icon={UserOutlined} />
              <GroupCard.Analytics>
                <GroupCard.Number info="611.486" />
              </GroupCard.Analytics>
            </GroupCard.Content>
          </GroupCard.Root> */}

          <BaseSpin size="small" />
          <BaseSpin />
          <BaseSpin size="large" />
          <BaseSpin tip="Carregando..." />

          <BaseSpin spinnin>
            <BaseAlert message="Mensagem de alerta" description="Mensagem de descrição do alerta." type="info" />
          </BaseSpin>

          <BaseAlert message="Mensagem de alerta" description="Mensagem de descrição do alerta." type="success" />
          <BaseAlert message="Mensagem de alerta" description="Mensagem de descrição do alerta." type="warning" />
          <BaseAlert message="Mensagem de alerta" description="Mensagem de descrição do alerta." type="error" />
          <BaseAlert message="Mensagem de alerta" description="Mensagem de descrição do alerta." type="info" />

          <BaseAlert message="Sucesso" type="success" showIcon />
          <BaseAlert message="Mensagem" description="Mensagem de descrição do alerta." type="success" showIcon />
          <BaseAlert message="Mensagem" description="Mensagem de descrição do alerta." type="info" showIcon />
          <BaseAlert message="Mensagem" description="Fechar alerta." type="warning" showIcon closable />
          <BaseAlert message="Mensagem" description="Mensagem de descrição do alerta." type="error" showIcon />
        </div>

        <BaseAlert
          style={{ marginTop: '1rem' }}
          message="Ações"
          description="Alerta com ações"
          type="info"
          action={
            <>
              <Space direction="vertical">
                <BaseButton>Ação 01 vertical</BaseButton>
                <BaseButton danger type="primary">
                  Ação 02 vertical
                </BaseButton>
              </Space>
              <Space>
                <BaseButton>Ação 01</BaseButton>
                <BaseButton danger type="primary">
                  Ação 02
                </BaseButton>
              </Space>
            </>
          }
        />

        <div
          style={{
            display: 'flex',
            gap: '3rem',
            marginTop: '1rem',
            flexWrap: 'wrap',
          }}
        >
          <BaseButton>Finalizar</BaseButton>

          <BaseButton disabled>Hover me!</BaseButton>

          <BasePopover
            title="Título do popover"
            content={
              <div>
                <p>Contéudo do popover</p>
                <BaseButton
                  onClick={() => {
                    BaseNotification.warning({
                      message: 'ALERTA',
                      description: 'Alerta Popover',
                    })
                  }}
                  size="small"
                >
                  Alerta
                </BaseButton>
              </div>
            }
          >
            <BaseButton>Hover me!</BaseButton>
          </BasePopover>

          <BaseButton
            severity="success"
            onClick={() => {
              BaseNotification.success({
                message: 'Sucesso!',
                description: 'Ação realizada.',
              })
            }}
          >
            Sucesso
          </BaseButton>

          <BaseButton
            severity="info"
            onClick={() => {
              BaseNotification.info({
                message: 'Informação',
                description: 'Descrição.',
              })
            }}
          >
            Info
          </BaseButton>
          <BaseButton
            severity="warning"
            onClick={() => {
              BaseNotification.warning({
                message: 'Atenção',
                description: 'Descrição!',
              })
            }}
          >
            Atenção
          </BaseButton>

          <BaseButton
            severity="error"
            onClick={() => {
              BaseNotification.error({
                message: 'Erro',
                description: 'Algo deu errado',
              })
            }}
          >
            Erro
          </BaseButton>

          <BaseSwitch defaultChecked />
          <BaseSwitch loading defaultChecked />
          <BaseSwitch checkedChildren="YES" unCheckedChildren="NO" defaultChecked />
          <BaseSwitch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked />
          <BaseSwitch defaultChecked disabled />
          <BaseSwitch disabled />
        </div>

        <div
          style={{
            display: 'flex',
            gap: '1rem',
            marginTop: '1rem',
            flexWrap: 'wrap',
          }}
        >
          <Space.Compact>
            <BaseButton severity="success">Sucesso</BaseButton>
            <BaseButton severity="info">Info</BaseButton>
            <BaseButton severity="warning">Atenção</BaseButton>
            <BaseButton severity="error">Erro</BaseButton>
          </Space.Compact>
          <Space.Compact direction="vertical">
            <BaseButton severity="success">Sucesso</BaseButton>
            <BaseButton severity="info">Info</BaseButton>
            <BaseButton severity="warning">Atenção</BaseButton>
            <BaseButton severity="error">Erro</BaseButton>
          </Space.Compact>
        </div>

        {/* MODAL  */}
        <div style={{ marginTop: '2rem' }}>
          <Typography.Title level={1} style={{ color: 'white' }}>
            Basic Modal
          </Typography.Title>
          <BaseButton onClick={() => setIsBasicModalOpen(true)}>Abrir Modal</BaseButton>
          <BaseModal
            title="dsadsa"
            open={isBasicModalOpen}
            onOk={() => setIsBasicModalOpen(false)}
            onCancel={() => setIsBasicModalOpen(false)}
            closeIcon="X"
          >
            <p>modals.someContent</p>
            <p>modals.someContent</p>
            <p>modals.someContent</p>
          </BaseModal>
        </div>
        <Typography.Title level={1} style={{ color: 'white', marginTop: '2rem' }}>
          Modal Sizes
        </Typography.Title>
        <div style={{ display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
          {/* Small Modal */}
          <BaseButton onClick={() => setIsBasicModalSmallOpen(true)}>Small</BaseButton>
          <BaseModal
            title="dsadsa"
            open={isBasicModalSmallOpen}
            onOk={() => setIsBasicModalSmallOpen(false)}
            onCancel={() => setIsBasicModalSmallOpen(false)}
            closeIcon="X"
            size="small"
          >
            <p>modals.someContent</p>
          </BaseModal>
          {/* Small Modal End */}
          {/* Medium Modal */}
          <BaseButton onClick={() => setIsBasicModalMediumOpen(true)}>Medium</BaseButton>
          <BaseModal
            title="dsadsa"
            open={isBasicModalMediumOpen}
            onOk={() => setIsBasicModalMediumOpen(false)}
            onCancel={() => setIsBasicModalMediumOpen(false)}
            closeIcon="X"
            size="medium"
          >
            <p>modals.someContent</p>
          </BaseModal>
          {/* Medium Modal End */}
          {/* Large Modal */}
          <BaseButton onClick={() => setIsBasicModalLargeOpen(true)}>Large</BaseButton>
          <BaseModal
            title="dsadsa"
            open={isBasicModalLargeOpen}
            onOk={() => setIsBasicModalLargeOpen(false)}
            onCancel={() => setIsBasicModalLargeOpen(false)}
            closeIcon="X"
            size="large"
          >
            <p>modals.someContent</p>
          </BaseModal>
          {/* Large Modal End */}
          {/* Fullscreen Modal */}
          <BaseButton onClick={() => setIsBasicModalFullscreenOpen(true)}>Fullscreen</BaseButton>
          <BaseModal
            title="dsadsa"
            open={isBasicModalFullscreenOpen}
            onOk={() => setIsBasicModalFullscreenOpen(false)}
            onCancel={() => setIsBasicModalFullscreenOpen(false)}
            closeIcon="X"
            width="100%"
          >
            <p>modals.someContent</p>
          </BaseModal>
          {/* Fullscreen Modal End */}
        </div>
        {/* <Typography.Title level={1} style={{ color: 'white', marginTop: '2rem' }}>
          Basic Modal
          <div style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}>
            <BaseButton onClick={success}>Success</BaseButton>
            <BaseButton onClick={info}>Info</BaseButton>
            <BaseButton onClick={warning}>Warnings</BaseButton>
            <BaseButton onClick={error}>Error</BaseButton>
          </div>
        </Typography.Title> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'star',
            alignItems: 'center',
            gap: '1.5rem',
            marginTop: '1.2rem',
            flexWrap: 'wrap',
          }}
        >
          <BaseBadge size="default" count={5} status="warning">
            <BaseAvatar shape="square" size="large" />
          </BaseBadge>

          <BaseBadge size="small" count={5} status="error">
            <BaseAvatar shape="square" size="large" />
          </BaseBadge>

          <BaseBadge size="small" shape="square" count={12}>
            <BaseAvatar shape="round" size={50} icon={<UserOutlined />} style={{ fontSize: '1rem' }} />
          </BaseBadge>

          <BaseBadge size="small" shape="square" count={100}>
            <BaseAvatar shape="square" icon={<UserOutlined />} style={{ fontSize: '1rem' }} />
          </BaseBadge>

          <BaseBadge size="small" dot status="success">
            <BaseAvatar shape="square" icon={<UserOutlined />} style={{ fontSize: '1rem' }} />
          </BaseBadge>

          <BaseAvatar size="large">H</BaseAvatar>

          <BaseAvatar.Group>
            <BaseAvatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
            <a href="https://ant.design" target="_blank" rel="noreferrer">
              <BaseAvatar
                style={{
                  backgroundColor: '#f56a00',
                }}
              >
                K
              </BaseAvatar>
            </a>
            <BasePopover title="Ant User" content="Popover do usuário" placement="top">
              <BaseAvatar
                style={{
                  backgroundColor: '#87d068',
                }}
                icon={<UserOutlined />}
              />
            </BasePopover>
            <BaseAvatar
              style={{
                backgroundColor: '#1677ff',
              }}
              icon={<DollarOutlined />}
            />
          </BaseAvatar.Group>

          <BaseBadge status="success" />
          <BaseBadge status="error" />
          <BaseBadge status="default" />
          <BaseBadge status="processing" />
          <BaseBadge status="warning" />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'star',
            alignItems: 'center',
            gap: '1.5rem',
            marginTop: '0.5rem',
            flexWrap: 'wrap',
          }}
        >
          <BaseBadge status="success" text="Sucesso" />

          <BaseBadge status="error" text="Erro" />

          <BaseBadge status="default" text="Padrão" />

          <BaseBadge status="processing" text="Processando" />

          <BaseBadge status="warning" text="Atenção" />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            marginBlock: '1rem',
            flexWrap: 'wrap',
          }}
        >
          <BaseBadge.Ribbon text="Grave" color="red">
            <BaseCard>Card com Ribbon</BaseCard>
          </BaseBadge.Ribbon>
          <BaseBadge.Ribbon text="Novo" color="blue">
            <BaseCard title="Título">Card com Ribbon e título</BaseCard>
          </BaseBadge.Ribbon>
          <BaseBadge.Ribbon text="Amarelo" color="yellow">
            <BaseCard title="Título" bordered={false}>
              Card com Ribbon e título sem bordas
            </BaseCard>
          </BaseBadge.Ribbon>
          <BaseBadge.Ribbon text="OK" color="green">
            <BaseCard bordered={false}>Card com Ribbon, sem título e sem bordas (Wrapper)</BaseCard>
          </BaseBadge.Ribbon>
        </div>
        <BaseBadge.Ribbon text="Amarelo" color="yellow">
          <h1 style={{ marginTop: '0.5rem' }}>Texto com Ribbon</h1>
        </BaseBadge.Ribbon>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          marginBlock: '1rem',
        }}
      >
        <BaseCard title="Título do card">
          <p>Card</p>
        </BaseCard>
        <BaseCard bordered={false}>
          <p>Card</p>
        </BaseCard>
      </div>

      <BaseCarousel>
        <BaseCard>1</BaseCard>
        <BaseCard>2</BaseCard>
        <BaseCard>3</BaseCard>
        <BaseCard>4</BaseCard>
      </BaseCarousel>

      <BaseTabs
        style={{ marginBottom: '2rem' }}
        size="large"
        items={[
          {
            key: '1',
            label: <span>Cadastros</span>,
            children: 'user',
          },
          {
            key: '2',
            label: (
              <span>
                <DollarOutlined />
                Doações
              </span>
            ),
            children: 'donation',
          },
          {
            key: '3',
            label: <span>Frequências</span>,
            children: 'frequency',
          },
        ]}
      />

      <BasePagination defaultCurrent={1} total={50} />
      <br />
      <BasePagination defaultCurrent={6} total={500} />
      <br />
      <BasePagination
        showSizeChanger
        defaultCurrent={3}
        total={500}
        showSizeChangerRender={(sizeOptions, defaultValue, onChange) => (
          <BaseSelect defaultValue={defaultValue} onChange={onChange}>
            {sizeOptions.map((size) => (
              <BaseSelect.Option key={size} value={size}>
                {size} items/page
              </BaseSelect.Option>
            ))}
          </BaseSelect>
        )}
      />
      <br />
      <BasePagination total={50} showSizeChanger showQuickJumper />
      <br />
      <BasePagination total={50} showSizeChanger showQuickJumper disabled />

      <BaseProgress percent={30} />
      <BaseProgress percent={50} status="active" />
      <BaseProgress percent={70} status="exception" />
      <BaseProgress percent={100} />
      <BaseProgress percent={50} showInfo={false} />

      <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
        <BaseProgress type="circle" percent={70} />
        <ProgressPerformance percent={51} />
        <BaseProgress type="circle" percent={70} status="exception" />
        <BaseProgress type="circle" percent={100} />
      </div>

      <BaseCard bordered={false} style={{ marginTop: '1rem' }}>
        <BaseForm>
          <BaseForm.Item label="Nome" name="nome" rules={[{ required: true, message: 'Obrigatório' }]}>
            <BaseInput placeholder="Nome completo" />
          </BaseForm.Item>

          <BaseForm.Item label="Senha" name="senha" rules={[{ required: true, message: 'Obrigatório' }]}>
            <InputPassword
              placeholder="Digite sua senha"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </BaseForm.Item>

          <BaseForm.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Obrigatório' },
              { type: 'email', message: 'Deve ser um email válido.' },
            ]}
          >
            <BaseInput placeholder="Email" />
          </BaseForm.Item>

          <BaseInput size="small" placeholder="Usuário" prefix={<UserOutlined />} />
          <BaseInput placeholder="Usuário" prefix={<UserOutlined />} />
          <BaseInput size="large" placeholder="Usuário" prefix={<UserOutlined />} />

          <BaseInput addonBefore="http://" addonAfter=".com" defaultValue="mysite" />
          <BaseInput addonBefore="doe.institutohesed.org.br/landingpage/" defaultValue="anual" />

          <BaseInput addonAfter={<SettingOutlined />} defaultValue="mysite" />

          <SearchInput placeholder="Pesquisar" allowClear />
          <SearchInput addonBefore="https://" placeholder="Pesquisar" allowClear />
          <SearchInput placeholder="Digite..." enterButton="Buscar" size="large" suffix={<AudioOutlined />} />

          <BaseButtonsForm.Item
            name="radio-button"
            label="Campanha ativa"
            rules={[{ required: true, message: 'Selecione' }]}
          >
            <BaseRadio.Group>
              <BaseRadio.Button value="Sim" defaultChecked>
                Sim
              </BaseRadio.Button>
              <BaseRadio.Button value="Não">Não</BaseRadio.Button>
            </BaseRadio.Group>
          </BaseButtonsForm.Item>

          <BaseUpload {...uploadProps}>
            <BaseButton icon={<UploadOutlined />} type="ghost">
              Upload
            </BaseButton>
          </BaseUpload>

          <DraggerUpload />

          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseSelect defaultValue="Selecione" width={120}>
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="disabled" disabled>
                Desativado
              </Option>
              <Option value="Yiminghe">Yiminghe</Option>
            </BaseSelect>
            <BaseSelect defaultValue="lucy" width={200} disabled>
              <Option value="lucy">Lucy</Option>
            </BaseSelect>
            <BaseSelect defaultValue="lucy" width={120} loading>
              <Option value="lucy">Lucy</Option>
            </BaseSelect>
            <BaseSelect defaultValue="lucy" width={120} allowClear>
              <Option value="lucy">Lucy</Option>
            </BaseSelect>
          </div>

          <h2>Datas</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1rem',
              gap: '1rem',
            }}
          >
            <BaseDatePicker format="DD/MM/YYYY HH:mm" />
            <BaseDatePicker
              picker="week"
              format={(value) => {
                if (!value) return ''
                const weekNumber = moment(value).week()
                const year = moment(value).year()
                return `${weekNumber}º/${year}`
              }}
            />
            <BaseDatePicker picker="month" format="MM/YYYY" />
            <BaseDatePicker picker="quarter" format="Q/YYYY" />
            <BaseDatePicker picker="year" />
            <BaseDatePicker range format="DD/MM/YYYY" />

            {/* <MultipleDatePicker /> */}
          </div>
        </BaseForm>
      </BaseCard>

      <BaseSteps
        current={0}
        items={[
          {
            title: 'Login',
            icon: <UserOutlined />,
          },
          {
            title: 'Verification',

            icon: <SolutionOutlined />,
          },
          {
            title: 'Pay',

            icon: <LoadingOutlined />,
          },
          {
            title: 'Done',

            icon: <SmileOutlined />,
          },
        ]}
      />

      <BlurDiv>
        <h1 style={{ marginTop: '1rem' }}> R$150.151</h1>
      </BlurDiv>
    </>
  )
}
