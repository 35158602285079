import styled from 'styled-components'
import validationPageBg from '../../images/validation-page-bg.png'
import { Upload } from 'antd'

export const Container = styled.div`
  background-image: url(${validationPageBg});
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;

  padding: 2rem;

  .container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 900px) {
      gap: 3rem;
    }
  }
`

export const StyledGrid = styled.div<{ columns: number }>`
  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};

    column-gap: 2rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }
`

export const ImgStyled = styled.img`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`

export const StyledUpload = styled(Upload)``

export const CenteredContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 15rem;
`
