import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, Popup, LayersControl } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { BaseLayers, BasePinIcon } from '../../../../../../lib/react-leaflet'
import { useEffect, useState } from 'react'
import { Row } from 'antd'
import { EmptyResult } from '../../../../components/result/resultStatus/EmptyResult'

interface MapaDistribuicaoGeograficaProps {
  arrayEnderecos?: {
    endereco: string
    latitude: number
    longitude: number
  }[]
}

type MarcadoresType = {
  geocode: [number, number]
  popUp: string
}

export function MapaDistribuicaoGeografica({ arrayEnderecos }: MapaDistribuicaoGeograficaProps) {
  const [marcadores, setMarcadores] = useState<MarcadoresType[] | []>([])

  useEffect(() => {
    if (arrayEnderecos) {
      const array = arrayEnderecos
        ?.map((item) => {
          const { endereco, latitude, longitude } = item

          return {
            geocode: [latitude, longitude] as [number, number],
            popUp: endereco,
          }
        })
        .filter((item) => item?.geocode[0] !== null)

      setMarcadores(array)
    }
  }, [arrayEnderecos])

  if (marcadores.length === 0) {
    return (
      <Row justify="center">
        <EmptyResult />
      </Row>
    )
  }

  if (marcadores.length > 0) {
    return (
      <div style={{ height: '100vh', width: '100%', position: 'relative' }}>
        <MapContainer
          center={marcadores[0]?.geocode}
          zoom={13}
          style={{
            height: '90vh',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >
          <LayersControl position="topleft">
            <LayersControl.BaseLayer checked name="Normal">
              {BaseLayers.googleRegular}
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satélite">{BaseLayers.googleSatelite}</LayersControl.BaseLayer>
          </LayersControl>

          <MarkerClusterGroup chunkedLoading>
            {marcadores?.map((marker, index) => (
              <Marker position={marker?.geocode} key={index} icon={BasePinIcon}>
                <Popup>{marker.popUp}</Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    )
  }

  return null
}
