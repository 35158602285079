import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export type RecibosFilterValues = 'com_recibo' | 'sem_recibo'

export interface GetBeneficiadosProps {
  idCicloDoacao: number

  filters?: {
    queryString?: string
    fk_instancia_acao_agape_id?: number
    page?: number
    per_page?: number
    cpf?: string
    data_inicial?: string
    data_final?: string
    recibos?: RecibosFilterValues
  }
}

interface GetBeneficiadosResponse {
  beneficiarios: {
    data_hora_doacao: string
    fk_doacao_agape_id: number
    nome_familia: string
    recibos: string[]
  }[]
  page: number
  total: number
}

export function useGetBeneficiados({ idCicloDoacao, filters }: GetBeneficiadosProps) {
  const beneficiados = useQuery({
    queryKey: ['BENEFICIADOS', idCicloDoacao, filters],
    queryFn: async () => {
      const response = await DataService.get<GetBeneficiadosResponse>(
        `/agape/listar-beneficiarios/${idCicloDoacao}?${filters?.queryString ?? ''}`,
      )
      return response.data
    },
  })

  return beneficiados
}
