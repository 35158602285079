import darkLogoImage from '../../../../static/logo/dark/dark-logo-image.svg'
import lightLogoImage from '../../../../static/logo/light/light-logo-image.svg'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export function NotFoundPage() {
  const theme = useSelector((state) => state.theme.theme)

  const adminLogo = theme === 'dark' ? darkLogoImage : lightLogoImage

  return <NotFound src={adminLogo} />
}

export const NotFound = styled.div`
  min-height: 70vh;
  background-image: url(${(props) => props.src});
  background-size: 10rem;
  background-repeat: no-repeat;
  background-position: center;
`
