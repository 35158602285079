import { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { Col, Divider, Form, Row, Switch } from 'antd';
import { Title } from '../../../../benfeitor/components/Title';
import { countries } from '../../../../../utility/allCountries';
import { MainCard } from '../../../../benfeitor/components/Card';
import { StyledInput } from '../../../../benfeitor/components/Inputs';
import { StyledButton } from '../../../../benfeitor/components/Button';
import { StyledForm } from '../../../../benfeitor/components/Form/styles';
import { StyledSelect } from '../../../../benfeitor/components/Select/styles';
import { validateCNPJ, validateCPF, validatePassword } from '../../../../../utility/utility';
import { RegisterUserReqBody, useRegisterUser } from '../../../../benfeitor/hooks/user/mutations/registerUser';
import { SwitchContainer } from '../../../layouts/benfeitor-layout/components/Header/Modals/SignUpModal/styles';
import { CreateAccountTitle, DividerText } from '../../../layouts/benfeitor-layout/components/Header/Modals/styles';
import { AcceptTerms } from '../../../layouts/benfeitor-layout/components/Header/AcceptTerms';

export interface SignUpForm {
  nome: string;
  email: string;
  confirm_email: string;
  password: string;

  cpf?: string;
  cnpj?: string;
  identidade_estrangeira?: string;

  pais: string;
  agreement: boolean;
}

export function RegisterPage() {
  const [form] = Form.useForm();
  const { mutateAsync: handleSubmit, isPending } = useRegisterUser();

  const selectedCountry = Form.useWatch('pais', form);

  const [isCpf, setIsCpf] = useState(true);
  const toggleInputType = () => {
    setIsCpf((prev) => !prev);
  };

  const handleRegisterSubmit = (values: SignUpForm) => {
    const formattedValues: RegisterUserReqBody = {
      email: values.email,
      nome: values.nome,
      numero_documento: values.cpf || values.cnpj || values.identidade_estrangeira!,
      pais: values.pais,
      password: values.password,
      tipo_documento:
        (values.cpf && 'cpf') ||
        (values.cnpj && 'cnpj') ||
        ((values.identidade_estrangeira && 'identidade_estrangeira') as RegisterUserReqBody['tipo_documento']),
    };

    handleSubmit(formattedValues);
  };

  return (
    <div style={{ width: '100%', maxWidth: '1120px', marginInline: 'auto' }}>
      <MainCard>
        <StyledForm
          form={form}
          name="register"
          onFinish={(values) => handleRegisterSubmit(values as SignUpForm)}
          layout="vertical"
        >
          <Row justify="center">
            <Title>Cadastre-se</Title>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CreateAccountTitle>
              Já possui uma conta? <Link to="/login">Entrar</Link>
            </CreateAccountTitle>
          </div>
          <Divider plain>
            <DividerText>Ou preencha seus dados abaixo</DividerText>
          </Divider>
          <Row gutter={30}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                name="pais"
                label="País"
                initialValue={'brasil'}
                rules={[{ required: true, message: 'Obrigatório' }]}
              >
                <StyledSelect
                  height={45}
                  borderRadius={8}
                  border={'1.5px solid #d9d9d9'}
                  defaultValue="brasil"
                  options={countries}
                />
              </Form.Item>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[
                  { min: 3, message: 'Mínimo 3 caracteres' },
                  { required: true, message: 'Obrigatório' },
                ]}
              >
                <StyledInput
                  placeholder="Nome Completo"
                  lineHeight={2.5715}
                  borderRadius={8}
                  border={'1.5px solid #d9d9d9'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  { type: 'email', message: 'Deve ser um e-mail válido' },
                  { required: true, message: 'Obrigatório' },
                ]}
              >
                <StyledInput placeholder="E-mail" lineHeight={2.5715} borderRadius={8} border={'1.5px solid #d9d9d9'} />
              </Form.Item>
              <Form.Item
                name="confirm_email"
                label="Confirmar E-mail"
                dependencies={['email']}
                rules={[
                  { type: 'email', message: '' },
                  { required: true, message: 'Obrigatório' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Os e-mails devem ser idênticos.'));
                    },
                  }),
                ]}
              >
                <StyledInput
                  lineHeight={2.5715}
                  borderRadius={8}
                  border={'1.5px solid #d9d9d9'}
                  placeholder="Confirmar"
                />
              </Form.Item>
            </Col>
          </Row>
          {selectedCountry === 'brasil' ? (
            <Row gutter={30}>
              <Col xl={24} md={24} xs={24}>
                <Form.Item
                  name={isCpf ? 'cpf' : 'cnpj'}
                  label={
                    <SwitchContainer>
                      <span>{isCpf ? 'CPF' : 'CNPJ'}</span>
                      <Switch checked={!isCpf} onChange={toggleInputType} />
                    </SwitchContainer>
                  }
                  rules={[
                    { required: true, message: isCpf ? 'CPF é obrigatório' : 'CNPJ é obrigatório' },
                    { validator: isCpf ? validateCPF : validateCNPJ },
                  ]}
                >
                  <ReactInputMask
                    mask={isCpf ? '999.999.999-99' : '99.999.999/9999-99'}
                    placeholder={isCpf ? 'Inserir CPF' : 'Inserir CNPJ'}
                    maskChar={null}
                  >
                    {(inputProps) => (
                      <StyledInput
                        {...inputProps}
                        border={'1.5px solid #d9d9d9'}
                        lineHeight={2.5715}
                        borderRadius={8}
                      />
                    )}
                  </ReactInputMask>
                </Form.Item>{' '}
              </Col>
            </Row>
          ) : (
            <Row gutter={30}>
              <Col xl={24} md={24} xs={24}>
                <Form.Item
                  name="identidade_estrangeira"
                  label="Documento de Identidade"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório, digite o número do documento',
                    },
                  ]}
                >
                  <StyledInput
                    name="identidade_estrangeira"
                    type="number"
                    placeholder="Número Documento de Identificação"
                    lineHeight={2.5715}
                    borderRadius={8}
                    border={'1.5px solid #d9d9d9'}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={30}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                name="password"
                label="Senha"
                rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
              >
                <StyledInput.Password
                  placeholder="Senha"
                  lineHeight={2.5715}
                  borderRadius={8}
                  border={'1.5px solid #d9d9d9'}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                      new Error('É necessário aceitar nossos Termos de Serviços e Política de Privacidade'),
                    ),
              },
            ]}
          >
            <AcceptTerms />
          </Form.Item>

          <Form.Item>
            <StyledButton style={{ width: '100%' }} htmlType="submit" disabled={isPending}>
              Cadastrar
            </StyledButton>
          </Form.Item>
        </StyledForm>
      </MainCard>
    </div>
  );
}
// SignUp.propTypes = {
//   isModal: PropTypes.bool,
//   changeLogin: PropTypes.any,
// }
