import { Button, Form, Input, Result } from 'antd' // Assuming you're using Ant Design for the Result component
import { UseFindUserDocumentNumberResponse } from '../../../../admin/hooks/users/Queries/useFindUserByDocumentNumber'
import { useHistory } from 'react-router-dom'
import { Documento } from '..'
import { validatePassword } from '../../../../../utility/utility'
import { useAuth } from '../../../../../context/Auth'
import { RecoverPasswordContainer } from '../styles'

interface UserResultFlowProps {
  userData: UseFindUserDocumentNumberResponse
  document: Documento
  isForeigner: boolean
}

export function UserResultFlow({ userData, document, isForeigner }: Readonly<UserResultFlowProps>) {
  const history = useHistory()

  // Case 1: User does not have account, redirect to sign-up before continue to general form
  if (!userData?.usuario) {
    history.push('/membro-exercito/cadastro-usuario', { document, isForeigner })
  }

  // Case 2: User has an account but is not general yet
  if (userData?.usuario && !userData?.general) {
    const [form] = Form.useForm()
    const { login } = useAuth()

    const handleSubmit = async (values: { email: string; password: string }) => {
      const payload = {
        email: values.email,
        password: values.password,
      }

      await login(payload)
      history.push('/membro-exercito/cadastro-usuario', { document, isForeigner })
    }

    const emailFound = userData?.email?.split('@')[0]

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Result
          status="info"
          title="Localizamos seu cadastro"
          subTitle={
            <>
              <div>
                Por favor, efetue seu login através do e-mail:{' '}
                <span style={{ fontWeight: 'bolder' }}>{userData?.email}</span> para prosseguir
              </div>
              <div style={{ marginTop: '1rem' }}>
                <Form
                  name="login"
                  form={form}
                  onFinish={handleSubmit}
                  layout="vertical"
                  initialValues={{ email: emailFound ? `${emailFound}` : '' }}
                >
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        message: 'Por favor, insira seu e-mail!',
                        required: true,
                      },
                      {
                        validator: (_, value) => {
                          if (!value || value.startsWith(`${emailFound}@`)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error(`O e-mail deve começar com ${emailFound}@`))
                        },
                      },
                    ]}
                  >
                    <Input placeholder="seuemail@gmail.com" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Senha"
                    rules={[
                      { message: 'Por favor, insira sua senha!', required: true },
                    ]}
                  >
                    <Input.Password placeholder="********" />
                  </Form.Item>

                  <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                    Enviar
                  </Button>

                  <RecoverPasswordContainer
                    onClick={() => {
                      history.push('/membro-exercito/recuperar-senha')
                    }}
                  >
                    Recuperar senha
                  </RecoverPasswordContainer>
                </Form>
              </div>
            </>
          }
        />
      </div>
    )
  }

  // Case 3: User is already a general
  if (userData?.general) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Result
          status="success"
          title="Localizamos seu cadastro como General."
          subTitle="Já existe um General Oficial com o número de documento informado."
        />
      </div>
    )
  }

  return null
}
