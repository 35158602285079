import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../../context/Auth'

export const useRedirectOnUnauthenticated = () => {
  const history = useHistory()

  const {
    userAuthState: { isAuthenticated },
  } = useAuth()

  if (!isAuthenticated) {
    history.push('/')
  }
}
