import { createGlobalStyle } from 'styled-components'

interface GlobalStylesProps {
  embedComponent?: boolean
}

export const BenfeitorGlobalStyles = createGlobalStyle<GlobalStylesProps>`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :focus {
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font: 400 1rem Roboto Medium, sans-serif;

    background-color: ${({ embedComponent }) => (embedComponent ? 'transparent' : 'white')};
  }

  .md-chat-widget-btn-wrapper.md-fade-when-visible.md-chat-widget-btn-open.md-fade-to-visible,
  .md-chat-widget.fancy-scroll,
  .md-chat-widget-btn-wrapper.md-fade-when-visible.md-fade-to-visible.md-chat-widget-btn-close{
     background-color: ${(props) => props.theme.colors['gray-500']} !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #64524f !important;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: #64524f;
  }

  .ant-steps-item-finish .ant-steps-item-icon{
    border-color: #64524f !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
    color:#64524f
  }

  .ant-steps-item-finish .ant-steps-item-title::after {
    background-color: #64524f !important;
  }

  .ant-switch {
    background-color: #463b3a !important;
  }

  .ant-switch-checked {
    background-color: #463b3a !important;
  }
`
