import { ChartCard } from '@/container/admin/components/Cards/ChartCard'
import { RootState } from '@/redux/rootReducers'
import { Table } from 'antd'
import { useSelector } from 'react-redux'
import { useTodayMessages } from '../../hooks/use-today-messages'
import styled from 'styled-components'

const StyledTable = styled(Table) <{ isDark: boolean }>`
  .ant-table {
    background: ${({ isDark }) => isDark ? '#1E1E1E' : '#fff'};
    color: ${({ isDark }) => isDark ? '#E1E1E1' : '#000'};
  }

  .ant-table-thead > tr > th {
    background: ${({ isDark }) => isDark ? '#141414' : '#fafafa'};
    color: ${({ isDark }) => isDark ? '#E1E1E1' : '#000'};
    border-bottom: 1px solid ${({ isDark }) => isDark ? '#333333' : '#f0f0f0'};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${({ isDark }) => isDark ? '#333333' : '#f0f0f0'};
  }

  .ant-table-tbody > tr:hover > td {
    background: ${({ isDark }) => isDark ? '#141414' : '#fafafa'} !important;
  }
`

export function TodayMessagesList() {
  const { data, isLoading } = useTodayMessages()
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  const columns = [
    { title: 'Usuário', dataIndex: 'user', key: 'user' },
    { title: 'Tipo', dataIndex: 'type', key: 'type' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Horário', dataIndex: 'time', key: 'time' }
  ]

  return (
    <ChartCard title="Mensagens de Hoje" loading={isLoading}>
      <StyledTable 
        dataSource={data} 
        columns={columns} 
        pagination={false}
        isDark={isDark}
      />
    </ChartCard>
  )
} 