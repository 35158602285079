import FeatherIcon from 'feather-icons-react'
import { useState } from 'react'
import { formatToBrazilianCurrency, handleFilterChangeAndPagination } from '../../../../../utility/utility'
import { BaseTable } from '../../../components/table/BaseTable/BaseTable'
import BaseCard from '../../../components/card/BaseCard'
import { BaseNotification } from '../../../components/notification/BaseNotification'
import { BaseTag } from '../../../components/tag/BaseTag'
import { BaseLink } from '../../../components/link/BaseLink'
import BaseButton from '../../../components/button/BaseButton'
import { useConsiderDesconsiderDonation } from '../../../hooks/donations/mutations/useConsiderDesconsiderDonation'
import { useFetchDesconsideredDonations } from '../../../hooks/donations/queries/useFetchDesconsideredDonations'
import { useHistory } from 'react-router-dom'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export default function DesconsideredDonations() {
  const history = useHistory()

  const [filters, setFilters] = useState<Record<string, any> | undefined>()
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: desconsideredDonations, isPending, isError } = useFetchDesconsideredDonations({ filters })

  const { mutateAsync: reconsiderDonation } = useConsiderDesconsiderDonation()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Método',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
      align: 'center',
      width: 150,
    },
    {
      title: 'Referência',
      dataIndex: 'codigo_referencia',
      key: 'codigo_referencia',
      render: ({ copyValue }: { copyValue: () => void }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FeatherIcon
            icon="copy"
            size={20}
            style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
            onClick={copyValue}
          />
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Recorrencia',
      dataIndex: 'recorrencia',
      key: 'recorrencia',
      align: 'center',
    },
    {
      title: 'Transação Id',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (data: { hasTransactionId: number | null; copyValue: () => void }) => (
        <>
          {data?.hasTransactionId && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FeatherIcon
                icon="copy"
                size={20}
                style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
                onClick={data?.copyValue}
              />
            </div>
          )}
        </>
      ),
      align: 'center',
    },
    {
      title: 'Cliente',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Data',
      dataIndex: 'data_doacao',
      key: 'data_doacao',
    },
    {
      title: 'Valor',
      dataIndex: 'valor_doacao',
      key: 'valor_doacao',
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Gateway',
      dataIndex: 'gateway',
      key: 'gateway',
      align: 'left',
    },
    {
      title: 'Campanha',
      dataIndex: 'titulo_campanha',
      key: 'titulo_campanha',
      align: 'left',
    },
    {
      title: 'Reconsiderar Doação',
      dataIndex: 'reconsiderar',
      key: 'acoes',
      align: 'center',
      render: ({ donation }: { donation: Doacao }) => {
        return (
          <BaseButton
            onClick={() => {
              reconsiderDonation({
                fk_processamento_pedido_id: donation?.processamento?.fk_processamento_pedido_id,
                considerar: donation?.pedido?.contabilizar_doacao,
                benfeitor: donation?.benfeitor?.nome,
                filters,
              })
            }}
          >
            {donation?.pedido?.contabilizar_doacao ? 'Desconsiderar' : 'Reconsiderar'}
          </BaseButton>
        )
      },
    },
  ]

  const dataSource = desconsideredDonations?.data?.map((donation) => {
    return {
      key: donation?.pedido?.fk_pedido_id,
      id: donation?.pedido?.fk_pedido_id,
      forma_pagamento: donation?.pedido?.forma_pagamento,
      data_doacao: donation?.pedido?.data_doacao,
      titulo_campanha: donation?.campanha?.titulo,
      status: donation?.processamento?.status,
      valor_doacao: formatToBrazilianCurrency(donation?.pedido?.valor_doacao),
      gateway: donation?.pedido?.gateway_pagamento?.nome,
      nome: (
        <>
          {donation?.benfeitor?.user_id ? (
            <BaseLink
              onClick={() => {
                history.push(`/admin/users/details/${donation?.benfeitor?.user_id}`)
              }}
            >
              {donation?.benfeitor?.nome}
            </BaseLink>
          ) : (
            <span>{donation?.benfeitor?.nome}</span>
          )}
        </>
      ),
      recorrencia: (
        <BaseTag color={donation?.pedido?.recorrencia ? 'success' : 'default'}>
          {donation?.pedido?.recorrencia ? 'Sim' : 'Não'}
        </BaseTag>
      ),
      codigo_referencia: {
        copyValue: () => {
          navigator.clipboard
            .writeText(donation?.processamento?.codigo_referencia)
            .then(() => {
              BaseNotification.success({
                message: <div style={{ fontWeight: 'bold', color: '#50556f' }}>Código de Referência Copiado</div>,
                description: (
                  <div>
                    <div style={{ color: '#757ca3', fontWeight: 600 }}>{donation?.nome}</div>
                    <div style={{ color: '#8b8ea4' }}>{donation?.processamento?.codigo_referencia}</div>
                  </div>
                ),
              })
            })
            .catch((error) => {
              BaseNotification.error({
                message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
              })
              console.error('Falha ao copiar o valor para a área de transferência:', error)
            })
        },
      },
      transaction_id: {
        hasTransactionId: donation?.processamento?.transaction_id,
        copyValue: () => {
          if (donation?.processamento?.transaction_id) {
            navigator.clipboard
              .writeText(donation?.processamento?.transaction_id)
              .then(() => {
                BaseNotification.success({
                  message: <div style={{ fontWeight: 'bold', color: '#50556f' }}>Id da Transação Copiado</div>,
                  description: (
                    <div>
                      <div style={{ color: '#757ca3', fontWeight: 600 }}>{donation?.benfeitor?.nome}</div>
                      <div style={{ color: '#8b8ea4' }}>{donation?.processamento?.transaction_id}</div>
                    </div>
                  ),
                })
              })
              .catch((error) => {
                BaseNotification.error({
                  message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
                })
                console.error('Falha ao copiar o valor para a área de transferência:', error)
              })
          }
        },
      },
      reconsiderar: {
        donation,
      },
    }
  })

  return (
    <BaseCard title="Doações Desconsideradas">
      <BaseTable
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: desconsideredDonations?.page,
          total: desconsideredDonations?.total,
          showSizeChanger: true,
        }}
        isPending={isPending}
        isError={isError}
        onChange={(pagination) => {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }}
      />
    </BaseCard>
  )
}
