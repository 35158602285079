import { Col, Row } from 'antd'
import { ConfirmarDadosTabela } from './tabela'
import { BaseSteps } from '../../../../../components/steps'
import { CadastrarDoacaoSteps } from '../utils/CadastrarCicloDoacaoStepItems'
import BaseButton from '../../../../../components/button/BaseButton'
import { GetFamiliaPorCpfResponse } from '../../../../../hooks/agape/queries/useGetFamiliaPorCpf'

interface VerificarOsDadosProps {
  retornarParaBusca: () => void
  irParaSelecioarItens: () => void
  dados: GetFamiliaPorCpfResponse | undefined
  idCicloDoacao: number
}

export function VerificarOsDados({
  retornarParaBusca,
  irParaSelecioarItens,
  dados,
  idCicloDoacao,
}: VerificarOsDadosProps) {
  return (
    <>
      <BaseSteps current={1} items={CadastrarDoacaoSteps} />

      <h3 style={{ textAlign: 'center', marginBlock: '1.2rem' }}>Confira os dados</h3>

      <div style={{ margin: '1.2rem 0 1rem' }}>
        <ConfirmarDadosTabela dados={dados} idCicloDoacao={idCicloDoacao} />
      </div>

      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} sm={12}>
          <Row justify="center">
            <BaseButton block type="ghost" onClick={retornarParaBusca}>
              Buscar por CPF novamente
            </BaseButton>
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row justify="center" onClick={irParaSelecioarItens}>
            <BaseButton block>Confirmar</BaseButton>
          </Row>
        </Col>
      </Row>
    </>
  )
}
