import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { PreCadastroVocacional } from '../../../container/benfeitor/pages/Vocacional/PreCadastro'
import { CadastroVocacional } from '../../../container/benfeitor/pages/Vocacional/Cadastro'
import { DesistenciaVocacional } from '../../../container/benfeitor/pages/Vocacional/Desistencia'
import { VocacionalWrapper } from '../../../container/benfeitor/pages/Vocacional/styles'
import { FichaVocacional } from '../../../container/benfeitor/pages/Vocacional/FichaVocacional'

export function VocacionalRoutes() {
  const { path } = useRouteMatch()

  return (
    <VocacionalWrapper>
      <Switch>
        <Route exact path={`${path}/pre-cadastro-masculino`} component={PreCadastroVocacional} />
        <Route exact path={`${path}/pre-cadastro-feminino`} component={PreCadastroVocacional} />
        <Route exact path={`${path}/cadastro/:token`} component={CadastroVocacional} />
        <Route exact path={`${path}/ficha-vocacional/:token`} component={FichaVocacional} />
        <Route exact path={`${path}/desistencia/:token`} component={DesistenciaVocacional} />
      </Switch>
    </VocacionalWrapper>
  )
}
