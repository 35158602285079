import styled from 'styled-components'
import AspasA from '../../../../../static/img/aspas.svg'
import AspasB from '../../../../../static/img/aspas_b.svg'

const ActionWrapper = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  color: #fff;
  font-family: Inter;

  label {
    color: rgb(121, 85, 75);
  }

  .step-title {
    color: #fff;
  }

  .submit-button {
    width: 100%;
    border-radius: 3px;
    border: 0;
    height: 48px;
    background-color: #10996b;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #248b64;
    }

    &:disabled {
      background-color: #248b64;
      cursor: not-allowed;
    }
  }

  .ant-select-selector {
    min-height: 48px;
    align-items: center;
  }
`

export const ContainerWapperLanding = styled.div`
  background-color: rgb(255, 255, 255);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #000;
    font-family: Montserrat;
  }
  div {
    h1 {
      color: #000;
      font-weight: normal;
      text-shadow: 1px 1px 1px #4a4a4a;
    }
  }

  p {
    font-family: Montserrat;
    font-size: 16px;
  }

  blockquote {
    position: relative;
    padding: 20px;
  }

  blockquote::before,
  blockquote::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  blockquote::before {
    background-image: url(${AspasA});
    top: 0;
    left: 0;
  }

  blockquote::after {
    background-image: url(${AspasB});
    bottom: 0;
    right: 0;
  }
`

export { ActionWrapper }
