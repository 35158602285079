import { useState } from 'react'
import { Content } from './Content'
import { FilterModal } from './FilterModal'
import BaseCard from '../../../../../components/card/BaseCard'
import { getCurrentMonthQueryString } from '../../../../users/Imported/utils'

export const DonationsBarPerCampaign = () => {
  const initialQueryString = getCurrentMonthQueryString()
  const [filters, setFilters] = useState(initialQueryString)

  return (
    <BaseCard padding="0px">
      <FilterModal filters={filters} setFilters={setFilters} />
      <Content filters={filters} />
    </BaseCard>
  )
}
