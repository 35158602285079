import { useState } from 'react'
import { Content } from './Content'
import { FiltersModal } from './FiltersModal'
import { AnimatedBaseCard } from '../../../styles'
import { getCurrentMonthQueryString } from '../../../../users/Imported/utils'

export function DonationsCountByHour() {
  const initialQueryString = getCurrentMonthQueryString()
  const [filters, setFilters] = useState(initialQueryString)

  const [campaignsOptions, setCampaignsOptions] = useState([])

  return (
    <AnimatedBaseCard padding="0.5rem">
      <FiltersModal filters={filters} setFilters={setFilters} campaignsOptions={campaignsOptions} />
      <Content filters={filters} setCampaignsOptions={setCampaignsOptions} />
    </AnimatedBaseCard>
  )
}
