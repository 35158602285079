import { BlurDiv } from '@/context/Blur/BlurDiv'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { TooltipContainer, TooltipDivider } from '../../../styles'

// Helper function to format the current month and year
export const getCurrentMonthAndYear = () => {
  const currentDate = new Date()
  const month = currentDate.toLocaleString('pt-BR', { month: 'long' }).replace(/^\w/, (c) => c.toUpperCase())
  return `${month} ${currentDate.getFullYear()}`
}

// Helper function to transform chart data for daily donations
export const transformDailyDonations = (data) => {
  if (!data?.daily_donations || typeof data.daily_donations !== 'object') return []

  return Object.entries(data.daily_donations).map(([date, { total }]) => ({
    date,
    donation: parseFloat(total),
  }))
}

// Helper function to flatten the data for campaign-based visualization
export const transformCampaignData = (data) => {
  if (!Array.isArray(data)) return []
  return data.map((dayData) => {
    const newDayData = { day: dayData.day }
    dayData.campaigns.forEach((campaign) => {
      newDayData[campaign.name] = campaign.valor_campanha
    })
    return newDayData
  })
}

// Helper function to extract unique campaign names
export const extractCampaignNames = (data) => {
  if (!Array.isArray(data)) return []
  return [...new Set(data.flatMap((dayData) => dayData.campaigns.map((campaign) => campaign.name)))]
}

// Helper function to generate color map based on campaign names
export const generateColorMap = (campaignNames) => {
  const colors = ['#57b457', '#2196F3', '#FFC107', '#FF9800', '#f05e54', '#9C27B0', '#5868c0']

  return campaignNames.reduce((acc, campaignName, index) => {
    acc[campaignName] = colors[index % colors.length]
    return acc
  }, {})
}

// Tooltip content for the AreaChart
export const renderAreaTooltip = (payload) => {
  if (!payload || payload.length === 0) return null
  const { date, donation } = payload[0]?.payload || {}
  return (
    <TooltipContainer type="finance">
      <div>
        <strong>{date ? date.replace(/-/g, '/') : 'Sem data'}</strong>
      </div>
      <BlurDiv>{donation !== undefined ? formatToBrazilianCurrency(donation) : 'Nenhum valor encontrado'}</BlurDiv>
    </TooltipContainer>
  )
}

// Tooltip content for the BarChart
export const renderBarTooltip = (payload, data, colorMap) => {
  if (!payload || payload.length === 0) return null
  const { day } = payload[0]?.payload || {}
  const campaignsForDay = data.find((data) => data.day === day)
  if (!campaignsForDay) return null

  const formattedDate = day ? day.split('-').reverse().join('/') : 'Data Inválida'
  const totalDonations = campaignsForDay.campaigns.reduce(
    (sum, campaign) => sum + parseFloat(campaign.valor_campanha),
    0,
  )
  const totalQuantity = campaignsForDay.campaigns.reduce((sum, campaign) => sum + campaign.quantidade_total, 0)

  // Sort campaigns by valor_campanha in descending order
  const sortedCampaigns = campaignsForDay.campaigns.sort((a, b) => {
    return parseFloat(b.valor_campanha) - parseFloat(a.valor_campanha)
  })

  return (
    <TooltipContainer type="finance" style={{ alignItems: 'flex-start', padding: '1rem' }}>
      <h4>{formattedDate}</h4>
      <BlurDiv>
        <div>{`Valor Total: ${formatToBrazilianCurrency(totalDonations)} - ${totalQuantity} Doações`}</div>
        <TooltipDivider style={{ marginTop: '0.8rem' }} />
        {sortedCampaigns.map((campaignData) => (
          <div key={campaignData.name} style={{ color: colorMap[campaignData.name] }}>
            <p>{`${campaignData.name}: ${formatToBrazilianCurrency(parseFloat(campaignData.valor_campanha))} | ${
              campaignData.percentage
            }% | ${campaignData.quantidade_total} Doações`}</p>
          </div>
        ))}
      </BlurDiv>
    </TooltipContainer>
  )
}
