import firstIcon from '../../images/firstIcon.svg';
import secondIcon from '../../images/secondIcon.svg';
import thirdIcon from '../../images/thirdIcon.svg';
import fourthIcon from '../../images/fourthIcon.svg';

import circleWhite from '../../images/circleBgWhite.svg';
import circleGray from '../../images/circleBgGray.svg';
import whiteLines from '../../images/whiteLines.svg';

// Utility function to move elements upwards by a given amount
const adjustPosition = (originalTop, offset = 70) => {
  const numericValue = parseFloat(originalTop); // Convert 'px' value to number
  return `${numericValue - offset}px`; // Subtract offset and return new 'px' value
};

export function ImageStepsDonationVertical() {
  const imagePositions = [
    { src: firstIcon, top: '135px' },
    { src: secondIcon, top: '570px' },
    { src: thirdIcon, top: '700px' },
    { src: fourthIcon, top: '1145px' },
  ];

  const ImageOverlay = ({ src, top }) => (
    <img
      src={src}
      alt=""
      style={{
        position: 'absolute',
        top: adjustPosition(top),
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 999,
      }}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        fontFamily: 'MontSerrat',
      }}
    >
      <img
        src={whiteLines}
        alt=""
        style={{
          position: 'absolute',
          top: adjustPosition('500px'),
          left: '50%',
          transform: 'translateX(-50%) rotate(90deg)',
        }}
      />
      <div style={{ position: 'absolute', top: adjustPosition('100px'), left: '50%', transform: 'translateX(-50%)' }}>
        <img src={circleGray} alt="" style={{ zIndex: 999, transform: 'rotate(90deg)' }} />
        <div
          style={{
            position: 'absolute',
            top: 130,
            left: 0,
            right: 0,
            textAlign: 'center',
            fontWeight: '400',
            color: 'white',
            fontSize: '22px',
          }}
        >
          Clique no botão
          <br />
          <div style={{ fontWeight: '500' }}>fazer doação!</div>
          <div style={{ position: 'absolute', top: 85, left: 0, right: 0, fontFamily: 'Roboto' }}>01</div>
        </div>
      </div>
      <div style={{ position: 'absolute', top: adjustPosition('380px'), left: '50%', transform: 'translateX(-50%)' }}>
        <img src={circleWhite} alt="" style={{ zIndex: 999, transform: 'rotate(90deg)' }} />
        <div
          style={{
            position: 'absolute',
            top: 70,
            textAlign: 'center',
            fontSize: '18px',
            color: '#434343',
          }}
        >
          Na página de <br /> pagamentos escolha <br /> Se deseja doar <br />{' '}
          <span style={{ fontWeight: '600' }}> uma única vez</span>, ou de{' '}
          <span style={{ fontWeight: '600' }}>forma recorrente</span>
          <div style={{ position: 'absolute', top: -40, left: 0, right: 0, fontFamily: 'Roboto', fontSize: '26px' }}>
            02
          </div>
        </div>
      </div>
      <div style={{ position: 'absolute', top: adjustPosition('660px'), left: '50%', transform: 'translateX(-50%)' }}>
        <img src={circleGray} alt="" style={{ zIndex: 999, transform: 'rotate(90deg)' }} />
        <div
          style={{
            position: 'absolute',
            top: 110,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'white',
            fontSize: '18px',
          }}
        >
          Escolha o valor. Não importa qual a quantia, <br />
          só{' '}
          {/* <strong>
            não deixe de fazer
            <br /> a sua doação.
          </strong> */}
          <span style={{ fontWeight: '500' }}>
            {' '}
            não deixe de fazer
            <br /> a sua doação.
          </span>
          <div style={{ position: 'absolute', top: 110, left: 0, right: 0 }}>03</div>
        </div>
      </div>
      <div style={{ position: 'absolute', top: adjustPosition('940px'), left: '50%', transform: 'translateX(-50%)' }}>
        <img src={circleWhite} alt="" style={{ zIndex: 999, transform: 'rotate(90deg)' }} />
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, textAlign: 'center', color: 'white' }}>
          <div
            style={{
              position: 'absolute',
              top: 80,
              textAlign: 'center',
              color: '#434343',
              fontSize: '20px',
            }}
          >
            Preencha seus <br />
            dados e clique em{' '}
            <span style={{ fontWeight: '600' }}>
              {' '}
              "Quero ser <br />
              benfeitor Hesed"
            </span>
            <div style={{ position: 'absolute', top: -40, left: 0, right: 0 }}>04</div>
          </div>
        </div>
      </div>
      {imagePositions.map((image) => (
        <ImageOverlay key={image.src} src={image.src} top={image.top} />
      ))}
      <div style={{ marginTop: adjustPosition('1300px'), textAlign: 'center', color: 'white', paddingBottom: '3rem' }}>
        Caso queira contribuir de outra forma, <br />
        <a
          href="https://institutohesed.org.br/contact/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: 'white' }}
        >
          entre em contato
        </a>{' '}
        e fale com uma de nossas irmãs
      </div>
    </div>
  );
}
