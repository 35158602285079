import { ReactNode, useRef } from 'react'
import { Layout } from 'antd'
import { getMenuItems } from '../menuItems'
import { SiderLogo } from '../components/SiderLogo/SiderLogo'
import { useFetchUserMe } from '@/container/admin/hooks'
import { ProfileMobile } from './ProfileMobile'
import { useAdminContext } from '@/container/admin/context/AdminLayout'
import { BottomMenu } from './MobileMenuStyles'
import { MobileMenuHeaderPage } from './MobileMenuHeaderPage'

const { Header } = Layout

interface MobileLayoutProps {
  children: ReactNode
}

export function MobileLayout({ children }: MobileLayoutProps) {
  const { data: userMe } = useFetchUserMe()
  const popupContainerRef = useRef(null)

  const { MobileProfileDropdown } = useAdminContext()

  const activePage = MobileMenuHeaderPage()

  return (
    <Layout style={{ height: '100vh' }}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
          backgroundColor: 'var(--sidebar-background-color)',
        }}
      >
        <div>
          <SiderLogo />
        </div>

        <div>
          <h2 style={{ margin: 0 }}>{activePage}</h2>
        </div>

        <div>
          <ProfileMobile menuItems={MobileProfileDropdown} userAvatar={userMe?.avatar} userName={userMe?.nome} />
        </div>
      </Header>

      {/* Conteúdo */}
      {children}

      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100vw',
          background: 'var(--sidebar-background-color)',
          zIndex: 999,
        }}
      >
        <div ref={popupContainerRef} id="mobile-popup-container" style={{ position: 'absolute' }}></div>
        <BottomMenu
          mode="vertical"
          items={getMenuItems()}
          getPopupContainer={() => popupContainerRef.current ?? document.body}
        />
      </div>
    </Layout>
  )
}
