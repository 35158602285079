import { Suspense } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { benfeitorTheme } from '../../styles/Themes/Benfeitor/benfeitorTheme'
import { BenfeitorGlobalStyles } from '../../styles/Themes/Benfeitor/globalStyles'
import { ProfileRoutes } from './Profile'
import { CampaignRoutes } from './Campaign'
import { Politicas } from '../../container/benfeitor/pages/Institucional/Politicas'
import { Termos } from '../../container/benfeitor/pages/Institucional/Termos'
import { Institucional } from '../../container/benfeitor/pages/Institucional/Institutional'
import { NotFound } from '../../container/benfeitor/pages/NotFound'
import { Home } from '../../container/benfeitor/pages/Home'
import { VocacionalRoutes } from './Vocacional'
import { EmbedRoutes } from '../embed/routes'
import { LandingPageRoutes } from './LandingPage'
import { ConfirmEmail } from '../../container/benfeitor/pages/ConfirmEmail'
import { AuthRoutes } from '../auth'
import { StyledSpin } from '../../container/benfeitor/components/Spin/styles'
import { CarteiraRoutes } from './Carteira'
import { EventoPresencial } from '../../container/benfeitor/pages/EventoPresencial'
import { LoginPage } from '../../container/benfeitor/pages/Profile/Authentication/LoginPage/LoginPage'
import { BenfeitorLayout } from '../../container/benfeitor/layouts/benfeitor-layout'

const urlRaizBenfeitor = '/benfeitor'

export function BenfeitorRoutes() {
  const { pathname } = useLocation()
  const history = useHistory()

  if (pathname === '/benfeitor/step1/16') {
    history.push('/benfeitor/campanha/16/identificacao')
  }

  return (
    <ThemeProvider theme={benfeitorTheme}>
      <BenfeitorGlobalStyles embedComponent={pathname.includes('/embed/')} />
      <Switch>
        <Route path={`/embed`} component={EmbedRoutes} />
        <Route path={`/landingpage`} component={LandingPageRoutes} />
        <Route exact path="/login">
          <Suspense
            fallback={
              <div className="spin">
                <StyledSpin />
              </div>
            }
          >
            <LoginPage />
          </Suspense>
        </Route>

        <Route>
          <BenfeitorLayout>
            <Suspense
              fallback={
                <div className="fallback-spin">
                  <StyledSpin />
                </div>
              }
            >
              <Switch>
                <Route path={`/carteira`} component={CarteiraRoutes} />
                <Route path={`/evento-presencial`} component={EventoPresencial} />
                <Route path={`${urlRaizBenfeitor}/profile`} component={ProfileRoutes} />
                <Route path={`${urlRaizBenfeitor}/campanha`} component={CampaignRoutes} />
                <Route path={`${urlRaizBenfeitor}/confirmar-email`} component={ConfirmEmail} />
                <Route path={`${urlRaizBenfeitor}/politicas-de-privacidade`} component={Politicas} />
                <Route path={`${urlRaizBenfeitor}/termos-de-uso`} component={Termos} />
                <Route path={`${urlRaizBenfeitor}/institucional`} component={Institucional} />
                <Route path={`${urlRaizBenfeitor}/vocacional`} component={VocacionalRoutes} />
                <Route path={`/login`} component={AuthRoutes} />
                <Route exact path="/" component={Home} />

                {/* Rota 404 */}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </BenfeitorLayout>
        </Route>
      </Switch>
    </ThemeProvider>
  )
}
