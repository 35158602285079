import { Form } from 'antd'
import { StepOneSignUpForm } from './components/step-one-sign-up-form'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { StepsContent } from './components/steps-content'
import { LandingPageProps } from '../../../../benfeitor/pages/LandingPage/types/LandingPageProps'
import { AddressForm } from './components/step-address-form'
import {
  MultiStepFormProps,
  useMultistepForm,
} from '../../../../benfeitor/pages/Carteira/Cadastro/hooks/useMultistepForm'
import { useEsmSignUpFormLogic } from '../../../hooks/use-esm-sign-up-form-logic'
import { useRegisterCompleteUser } from '../../../../admin/hooks/users/Mutations/useRegisterCompleteUser'
import { useFetchUserMe } from '../../../../admin/hooks'
import { useUpdateUser } from '../../../../benfeitor/hooks/api/user/useUpdateUserProfile'
import { GoldenHeader } from '../../components/golden-header/styles'

interface SignUpForm {
  markAsFinished: Function
  landingPageData: LandingPageProps
  initialFields?: Record<string, any>
}

export function SignUpForm({ markAsFinished, landingPageData, initialFields }: Readonly<SignUpForm>) {
  const [form] = Form.useForm()
  const isForeigner = initialFields?.tipo_documento === 'identidade_estrangeira'

  const { mutateAsync: registerUser, isPending: isPendingRegisterUser } = useRegisterCompleteUser()
  const { mutateAsync: updateUser, isPending: isPendingUpdateUser } = useUpdateUser()
  const { data: userMe } = useFetchUserMe()

  const campanhaId = landingPageData?.campanha_id

  const { handleFinish, hasStepTwoFormBeenFilled } = useEsmSignUpFormLogic({
    sendForm: userMe ? updateUser : registerUser,
    markAsFinished,
    campanhaId,
    initialFields,
    form,
    isForeigner,
    userMe,
  })

  const steps = [
    {
      title: <div>Identificação</div>,
      content: <StepOneSignUpForm initialFields={initialFields} isForeigner={isForeigner} />,
      icon: <UserOutlined />,
    },
    {
      title: <div>Endereço</div>,
      content: (
        <AddressForm
          form={form}
          showBrazil={!isForeigner}
          hasFormBeenFetchedFromApi={hasStepTwoFormBeenFilled}
          title={
            <GoldenHeader style={{ marginBottom: '2rem', fontSize: '16px' }}>
              SEU CERTIFICADO DE MEMBRO OFICIAL SERÁ ENVIADO PARA O ENDEREÇO INFORMADO ABAIXO
            </GoldenHeader>
          }
        />
      ),
      icon: <HomeOutlined />,
    },
  ]

  const multiStepForm: MultiStepFormProps = useMultistepForm(steps, form)

  return (
    <>
      <GoldenHeader>
        <div>TORNE-SE UM MEMBRO OFICIAL DO EXÉRCITO DE SÃO MIGUEL</div>
      </GoldenHeader>
      <StepsContent
        form={form}
        steps={steps}
        handleFinish={handleFinish}
        isPending={isPendingRegisterUser || isPendingUpdateUser}
        multiStepForm={multiStepForm}
      />
    </>
  )
}
