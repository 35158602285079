import { http, HttpResponse } from 'msw'

interface Request {
  new_password: string
}

interface Response {
  msg: string
}

const EsmTokenFromEmail = 'InlhbmJyNzYzQGdtYWlsLmNvbSI.Z6QhBg.XR2z765-BPm5o2JE6IbeVJUGMto'

export const newPasswordTokenMock = http.post<never, Request, Response>(`/auth/new-password`, async ({ request }) => {
  const { new_password } = await request.json()

  const url = new URL(request.url)
  const token = url.searchParams.get('token')

  if (new_password === 'New_password35$' && token === EsmTokenFromEmail) {
    return HttpResponse.json({
      msg: 'Senha alterada com sucesso.',
    })
  }
})
