import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AgapeAssistencia } from '../../container/admin/pages/agape'
import { RegistrarDoacao } from '../../container/admin/pages/agape/Doacao/RegistrarDoacao'

export function AgapeRoutesAdmin() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/assistencia/familia`} component={AgapeAssistencia} />
      <Route exact path={`${path}/assistencia/itens`} component={AgapeAssistencia} />
      <Route exact path={`${path}/assistencia/doacao`} component={AgapeAssistencia} />
      <Route exact path={`${path}/assistencia/gestao`} component={AgapeAssistencia} />
      <Route exact path={`${path}/assistencia/doacao/:idCicloDoacao/:nomeAcao`} component={RegistrarDoacao} />
    </Switch>
  )
}
