import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export interface CadastrarAcaoSolidariaRequestData {
  nome: string
}

export function useCadastrarAcaoSolidaria() {
  const queryClient = useQueryClient()

  const cadastrarAcaoSolidaria = useMutation({
    mutationFn: async ({ nome }: CadastrarAcaoSolidariaRequestData) => {
      await DataService.post('/agape/cadastrar-nome-acao-agape', { nome })
    },
    onSuccess: (_, { nome }) => {
      notification.success({ message: `${nome} cadastrada com sucesso!` })
      queryClient.invalidateQueries({ queryKey: ['LISTAR_NOME_ACOES_SOLIDARIAS'] })
      queryClient.invalidateQueries({ queryKey: ['ACOES_SOLIDARIAS'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar ação solidária',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return cadastrarAcaoSolidaria
}
