import * as S from './BaseImageCrop.Styles'

export function BaseImageCrop({ children, ...props }) {
  return (
    <S.StyledModalCrop>
      <S.StyledImageCrop
        {...props}
        quality={1}
        showGrid
        showReset
        resetText="Restaurar"
      >
        {children}
      </S.StyledImageCrop>
    </S.StyledModalCrop>
  )
}
