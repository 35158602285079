import { useEffect } from 'react'

type SetResType = React.Dispatch<React.SetStateAction<'desktop' | 'mobile'>>

export function handleRes({ setRes }: { setRes: SetResType }) {
  useEffect(() => {
    const handleResize = () => {
      setRes(window.innerWidth > 600 ? 'desktop' : 'mobile')
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
}
