import { Result } from 'antd'
import { ResultProps } from 'antd/lib/result'

export function StyledResult({
  status,
  title,
  subTitle,
  extra,
  ...props
}: ResultProps) {
  return (
    <Result
      status={status || '500'}
      title={title || 'Erro'}
      subTitle={subTitle || 'Houve um erro ao acessar esse conteúdo.'}
      extra={extra}
      {...props}
    />
  )
}
