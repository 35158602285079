import { useInfiniteQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetInfiniteAllTodayDonations(filters) {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  const startOfDay = `${year}-${month}-${day}T00:00:00`
  const endOfDay = `${year}-${month}-${day}T23:59:59`

  return useInfiniteQuery({
    queryKey: ['TODAY_DONATIONS_INFINITE', filters],
    queryFn: async ({ pageParam = 1 }) => {
      const query = await DataService.get(
        `checkout/donations?status=1&data_inicial=${startOfDay}&data_final=${endOfDay}&page=${pageParam}${
          filters?.tipo_pagamento ? `&tipo_pagamento=${filters.tipo_pagamento}` : ''
        }`,
      )
      return query?.data
    },
    getNextPageParam: (lastPage) => {
      return lastPage.page < lastPage.pages ? lastPage.page + 1 : null
    },
  })
}
