import Cookies from 'js-cookie'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useThemeWatcher } from '../container/admin/hooks/useThemeWatcher'
import { useEffect } from 'react'
import { manageChatWidget } from '../utility/utility'
// import CookiesNotification from '../layout/benfeitor/components/CookiesModal/CookiesNotification'
import { ProtectedRoute } from './protectedRoute'
import { BenfeitorRoutes } from './_benfeitor'
import Admin from './admin'
import { useAuth } from '../context/Auth'
import { AlistamentoSmRoutes } from './evento-presencial/routes'
import { RenderBoleto } from '../container/benfeitor/components/Boleto/boleto'

export const Routes = () => {
  const location = useLocation()
  const acceptCookies = Cookies.get('accept_cookies')

  const {
    userAuthState: { isAuthenticated },
  } = useAuth()
  useThemeWatcher()

  useEffect(() => {
    manageChatWidget(location)
  }, [location.pathname])

  return (
    <>
      {/* {!isAuthenticated && !acceptCookies && <CookiesNotification />} */}
      <Switch>
        <Route exact path="/boleto" component={RenderBoleto} />
        <Route path={`/membro-exercito/`} component={AlistamentoSmRoutes} />
        <ProtectedRoute path="/admin" component={Admin} />
        <BenfeitorRoutes />
      </Switch>
    </>
  )
}
