import { Drawer } from 'antd';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    .ant-drawer-title {
      color: var(--text-main-color);
    }
    color: var(--text-main-color);
    background-color: var(--modal-background-color);
  }
`;
