import { UploadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { UploadFile } from 'antd/es/upload/interface' // Importando a interface para UploadFile
import { StyledButton } from '../Button'
import { ImageCrop } from '../ImageCrop'
import { StyledUpload } from '../Upload'
import StyledUploadDescription from '../Upload/UploadDescription'
import { StyledModal } from '../Modal'

// Extendendo UploadFile para incluir a propriedade 'file'
interface CustomUploadFile extends UploadFile {
  file: File // Adicionando a propriedade file do tipo File
}

interface AvatarPhotoUploadProps {
  initialFileList: CustomUploadFile[] // Tipo para a lista de arquivos iniciais
  setImage: (images: CustomUploadFile[]) => void // Função para definir a imagem
  numeroFotos: number // Número máximo de fotos
  aspect?: number // Proporção da imagem
  description?: string // Descrição opcional
  validateField?: () => void // Função de validação opcional
  listType?: 'text' | 'picture' | 'picture-card' // Tipos possíveis para listType
}

export function AvatarPhotoUpload({
  initialFileList,
  setImage,
  numeroFotos,
  aspect,
  description,
  validateField,
  listType = 'picture',
}: AvatarPhotoUploadProps) {
  const [previewImage, setPreviewImage] = useState<{
    name: string
    url: string
  }>({ name: '', url: '' })
  const [previewOpen, setPreviewOpen] = useState<boolean>(false)
  const [hasInteracted, setHasInteracted] = useState<boolean>(false)

  const runValidateField = () => {
    if (validateField) {
      validateField()
    }
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [hasInteracted])

  const handleCroppedImage = async (
    file: CustomUploadFile,
    preview?: boolean,
  ): Promise<CustomUploadFile> => {
    const dataToTransform = preview ? file.originFileObj : file

    let src = file.url || file.thumbUrl
    if (!src) {
      src = await new Promise<string>((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(dataToTransform as Blob) // Casting para Blob
        reader.onload = () => resolve(reader.result as string) // Afirmando que o resultado é uma string
      })
    }

    return {
      file,
      uid: '-1',
      name: file.name,
      status: 'done',
      url: src,
    }
  }

  const onPreview = async (file: CustomUploadFile) => {
    const croppedImage = await handleCroppedImage(file, true)
    setPreviewImage(croppedImage)
    setPreviewOpen(true)
  }

  const handleCancel = () => setPreviewOpen(false)

  const handleRemove = () => {
    setPreviewImage({ name: '', url: '' })
    setImage([])
    setHasInteracted(true)
  }

  const handleBeforeUpload = async (
    file: CustomUploadFile,
  ): Promise<boolean> => {
    const croppedImage = await handleCroppedImage(file)
    setImage([croppedImage])
    setHasInteracted(true)
    return false
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [initialFileList, hasInteracted])

  const isCard = listType === 'picture-card'

  return (
    <>
      <ImageCrop
        modalTitle="Editar imagem"
        modalOk="Confirmar"
        modalCancel="Cancelar"
        aspect={aspect && !isCard ? aspect || 16 / 9 : 1 / 1}
        cropShape={isCard ? 'round' : 'rect'}
      >
        <StyledUpload
          listType={listType}
          fileList={initialFileList}
          beforeUpload={handleBeforeUpload}
          onPreview={onPreview}
          maxCount={numeroFotos}
          onRemove={handleRemove}
        >
          {initialFileList.length === 0 && (
            <>
              {isCard ? (
                <div>
                  <UploadOutlined style={{ marginRight: '0.5rem' }} />
                  Selecione
                </div>
              ) : (
                <>
                  <StyledUploadDescription text={description} />
                  <StyledButton icon={<UploadOutlined />}>
                    Enviar Imagem
                  </StyledButton>
                </>
              )}
            </>
          )}
        </StyledUpload>
      </ImageCrop>

      <StyledModal
        open={previewOpen}
        title={previewImage.name}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <img alt="Imagem" style={{ width: '100%' }} src={previewImage.url} />
      </StyledModal>
    </>
  )
}
