import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

export default function GroupCardBalance({ number }) {
  const increase = number >= 0
  return (
    <div
      className={increase ? 'increase-info' : 'negative-info'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {increase && <CaretUpOutlined />}
      {!increase && <CaretDownOutlined />}
      <p style={{ margin: 0 }}>{number}%</p>
    </div>
  )
}
