import styled from 'styled-components'

export const StyledForm = styled.div`
  label {
    font-family: ${({ theme }) => theme.fonts.family.secondary};
    font-weight: 600;
    font-size: 14px;
  }

  .ant-upload.ant-upload-select .ant-btn {
    border: 1.5px solid #d9d9d9 !important;
    border-radius: 8px !important;
    width: 100% !important;
    height: 47.5px !important;
  }

  .ant-upload-list-item {
    border-radius: 8px !important;
    height: 47.5px !important;
    background-color: #ffffff !important;
    border: 1.5px solid #d9d9d9 !important;
  }
`
