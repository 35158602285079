import { BarCode, CreditCard, PixLogo } from '@phosphor-icons/react';
import { PaymentCard } from '../PaymentCard';
import { Form } from 'antd';
import { PaymentMethodState, usePaymentMethod } from '../../context';
import { Barcode } from '@phosphor-icons/react/dist/ssr';

export function PaymentMethod() {
  const { activePaymentMethod, dispatch, pixQrCode, boletoData } = usePaymentMethod();

  const handleCardClick = (cardType: PaymentMethodState['activePaymentMethod']) => {
    dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: cardType });
  };

  if (pixQrCode || boletoData) return null;

  return (
    <div style={{ display: 'flex', maxWidth: '700px' }}>
      <Form.Item rules={[{ required: true }]} noStyle initialValue={'card'}>
        <div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
          <input type="radio" value="card" style={{ display: 'none' }} />
          <PaymentCard
            text="Cartão"
            icon={<CreditCard />}
            active={activePaymentMethod === 'card'}
            onClick={() => handleCardClick('card')}
            style={{ height: '140px' }}
          />

          <input type="radio" value="pix" style={{ display: 'none' }} />
          <PaymentCard
            text="Pix"
            icon={<PixLogo />}
            active={activePaymentMethod === 'pix'}
            onClick={() => handleCardClick('pix')}
          />

          <input type="radio" value="boleto" style={{ display: 'none' }} />
          <PaymentCard
            text="Boleto"
            icon={<Barcode />}
            active={activePaymentMethod === 'boleto'}
            onClick={() => handleCardClick('boleto')}
          />
        </div>
      </Form.Item>
    </div>
  );
}
