import { useMutation } from '@tanstack/react-query'
import { notification } from 'antd'
import { DataService } from '../../../../../lib/dataService'

export type ResendEmailToApprovedProps = {
  usuario_vocacional_id: number
}

export function useResendEmailToApproved() {
  const resendEmailToApproved = useMutation({
    mutationFn: async ({ usuario_vocacional_id }: ResendEmailToApprovedProps) => {
      await DataService.post(`vocacional/reenviar-email-vocacional`, {
        usuario_vocacional_id,
      })
    },
    onSuccess: () => {
      notification.success({ message: 'E-mail reenviado com sucesso.' })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao reenviar e-mail.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return resendEmailToApproved
}
