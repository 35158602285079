import { useState } from 'react'
import BaseCard from '../../../../../components/card/BaseCard'
import { useGetFamilias } from '../../../../../hooks/agape/queries/useGetFamilias'
import { handleFilterChangeAndPagination, useLoadQueryStringFromUrl } from '../../../../../../../utility/utility'
import { FamiliasCadastradasTabela } from './tabela/FamiliasCadastradasTabela'

export function FamiliasCadastradas() {
  const [filters, setFilters] = useState({})
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  useLoadQueryStringFromUrl({ filters, setFilters })

  const {
    data: dadosGetFamilias,
    isLoading: isLoadingGetFamilias,
    isError: isErrorGetFamilias,
  } = useGetFamilias({ filters })

  const pagination = {
    page: dadosGetFamilias?.page,
    total: dadosGetFamilias?.total,
  }

  return (
    <BaseCard title="Famílias Cadastradas">
      <FamiliasCadastradasTabela
        filters={filters}
        handleInputChange={handleInputChange}
        lista={dadosGetFamilias?.familias}
        pagination={pagination}
        isLoading={isLoadingGetFamilias}
        isError={isErrorGetFamilias}
      />
    </BaseCard>
  )
}
