import { HandHeart } from '@phosphor-icons/react'
import { BaseResult } from '../../../../../components/result/BaseResult'
import { BaseSteps } from '../../../../../components/steps'
import { CadastrarDoacaoSteps } from '../utils/CadastrarCicloDoacaoStepItems'
import BaseButton from '../../../../../components/button/BaseButton'
import { Row } from 'antd'

interface SucessoDoacaoProps {
  retornarParaBusca: () => void
}

export function SucessoDoacao({ retornarParaBusca }: SucessoDoacaoProps) {
  return (
    <>
      <BaseSteps current={4} items={CadastrarDoacaoSteps} />

      <BaseResult
        style={{ marginTop: '2.4rem' }}
        status="success"
        icon={<HandHeart size={120} color="var(--success-color)" />}
        title="Doação registrada com sucesso"
        extra={
          <Row justify="center">
            <BaseButton severity="info" onClick={retornarParaBusca}>
              Nova doação
            </BaseButton>
          </Row>
        }
      />
    </>
  )
}
