import { } from "@/utility/utility"
import { SlideProps } from "../../financesDashboard/components/AnimatedCarousel/types"
import { CurrentMonthLeads } from "../components/CurrentMonthLeads"
import { CurrentMonthSignups } from "../components/CurrentMonthSignups"
import { TodaySignups } from "../components/Today"


export function useSignupDashboardSlides(): SlideProps[] {
  // const { currentMonthDonations, todayDonations, monthAverageDonation, dailyAverageDonation } =
  //   useFinanceDashboardCardsData()

  return [
    {
      chart: <CurrentMonthSignups />,
      cards: [
        {
          value: 5412,
          title: 'Cadastros de hoje',
          percentage: 12,
        },
        {
          value: 9125,
          title: 'Média de cadastros por dia',
        },
        {
          value: 15120,
          title: 'Leads do mês',
          percentage: -15,
        },
        {
          value: 15120,
          title: 'Média de cadastros por mês',
        },
      ],
    },
    {
      chart: <TodaySignups />,
      cards: [
        {
          value: 5412,
          title: 'Cadastros do mês',
          percentage: 32,
        },
        {
          value: 9125,
          title: 'Média de cadastros por dia',
        },
        {
          value: 3120,
          title: 'Leads do mês',
          percentage: -22,
        },
        {
          value: 15120,
          title: 'Média de cadastros por mês',
        },
      ],
    },
    {
      chart: <CurrentMonthLeads />,
      cards: [
        {
          value: 5031,
          title: 'Cadastros do mês',
          percentage: 44,
        },
        {
          value: 7122,
          title: 'Média de cadastros por dia',
        },
        {
          value: 9215,
          title: 'Cadastros de hoje',
          percentage: -23,
        },
        {
          value: 15522,
          title: 'Média de cadastros por mês',
        },
      ],
    },
  ]
}
