import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useCancelRecurrence() {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (pedidoId: number) => {
      return DataService.post(`checkout/payment/cancel-recurrence/${pedidoId}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['UNPAID_RECURRENCES'] })
      notification.success({
        message: 'Recorrência cancelada com sucesso',
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao cancelar a recorrência',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente.',
      })
    },
  })

  return mutation
}
