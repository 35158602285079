import { BotoesHeaderItens } from './BotoesHeaderItens'
import { CardsItens } from './Header/Cards/CardsItens'
import { SecaoTabelas } from './SecaoTabelas'

export function ItensPage() {
  return (
    <>
      <h2>Itens</h2>

      <CardsItens />

      <div style={{ marginBlock: '2rem' }}>
        <BotoesHeaderItens />
      </div>

      <SecaoTabelas />
    </>
  )
}
