import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { CampaignDetail } from '../../../container/benfeitor/pages/Campaign/CampaignDetail'
import { CampaignSuccess } from '../../../container/benfeitor/pages/Campaign/CampaignDonationSuccess'
import { CampaignIdentification } from '../../../container/benfeitor/pages/Campaign/CampaignIdentification'
import { PaymentMethodProvider } from '../../../container/benfeitor/pages/Campaign/CampaignDonation/context'
import { CampaignDonation } from '../../../container/benfeitor/pages/Campaign/CampaignDonation'

export function CampaignRoutes() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:campaignId`} exact={true} component={CampaignDetail} />
      <Route path={`${path}/:campaignId/identificacao`} component={CampaignIdentification} />
      <Route path={`${path}/:campaignId/doacao/success`} component={CampaignSuccess} />
      <PaymentMethodProvider>
        <Route path={`${path}/:campaignId/doacao`} component={CampaignDonation} />
      </PaymentMethodProvider>
    </Switch>
  )
}
