import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface GetCsvFamiliasResponse {
  url: string
}

export function useGetCsvFamilias() {
  const csvFamilias = useMutation({
    mutationFn: async () => {
      const response = await DataService.get<GetCsvFamiliasResponse>('/agape/exportar-familias')

      return response.data
    },
    onSuccess: async ({ url }) => {
      const a = document.createElement('a')
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    onError: (error: any) => {
      notification.error({
        message: 'Houve um erro ao fazer o download do CSV.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return csvFamilias
}
