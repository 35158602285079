import ImgCrop from 'antd-img-crop'
import { ReactNode } from 'react'

export function ImageCrop({ children, ...props }: { children: ReactNode }) {
  return (
    <div>
      <ImgCrop {...props} quality={1} showGrid showReset resetText="Restaurar">
        {children}
      </ImgCrop>
    </div>
  )
}
