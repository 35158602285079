import { useState } from 'react'
import { BaseTable } from '../../../../table/BaseTable/BaseTable'
import BaseAlert from '../../../../alert/BaseAlert'
import { handleOpenActionModalProps } from './useDonationDataSource'

export interface DonationActionModalState {
  titulo: string
  conteudo: JSX.Element[] | string
  acao: string
  open: boolean
  benfeitor: string
  objectId: number | string
  isTransactionId?: string | boolean
}

export const useDonationActionModal = () => {
  const [actionModalContent, setActionModalContent] = useState<DonationActionModalState>({
    open: false,
    titulo: '',
    conteudo: '',
    acao: '',
    benfeitor: '',
    objectId: '',
  })

  const handleOpenActionModal = ({
    titulo,
    acao,
    benfeitor,
    metodo,
    valor,
    recorrencia,
    campanha,
    objectId,
    isTransactionId,
  }: handleOpenActionModalProps) => {
    const table = (
      <div className="table-data-view table-responsive">
        <BaseTable
          pagination={false}
          dataSource={[
            {
              benfeitor,
              metodo,
              valor,
              recorrencia,
              campanha,
            },
          ]}
          columns={[
            {
              title: 'Benfeitor',
              dataIndex: 'benfeitor',
              key: 'benfeitor',
            },
            {
              title: 'Método',
              dataIndex: 'metodo',
              key: 'metodo',
            },
            {
              title: 'Valor',
              dataIndex: 'valor',
              key: 'valor',
            },
            {
              title: 'Recorrência',
              dataIndex: 'recorrencia',
              key: 'recorrencia',
              render: (item: boolean) => (
                <div className={`status ${item ? 'active' : 'deactivate'}`}>{item ? 'Sim' : 'Não'}</div>
              ),
            },
            {
              title: 'Campanha',
              dataIndex: 'campanha',
              key: 'campanha',
              align: 'left',
            },
          ]}
        />
      </div>
    )

    const actionMessages: Record<string, { message: string; description: string }> = {
      Reenviar: {
        message: 'Deseja reenviar a fatura?',
        description: 'Ao solicitar o reenvio o usuário receberá um e-mail com os dados para o pagamento.',
      },
      Estornar: {
        message: 'Deseja estornar a transferência?',
        description: 'Ao efetuar o estorno a operação não poderá ser desfeita!',
      },
      'Cancelar recorrência': {
        message: 'Deseja cancelar a recorrência?',
        description: 'Ao cancelar a recorrência, o usuário não será mais cobrado!',
      },
    }

    if (!actionMessages[acao]) {
      throw new Error('Houve um erro inesperado')
    }

    const { message, description } = actionMessages[acao]

    const UnifiedModalContent = [table, <BaseAlert key="alert" message={message} description={description} showIcon />]

    setActionModalContent({
      titulo,
      conteudo: UnifiedModalContent,
      acao,
      open: true,
      benfeitor,
      objectId,
      isTransactionId,
    })
  }

  const handleCloseActionModal = () => {
    setActionModalContent({ open: false, titulo: '', conteudo: '', acao: '', benfeitor: '', objectId: '' })
  }

  return {
    actionModalContent,
    handleOpenActionModal,
    handleCloseActionModal,
  }
}
