import { Button, ButtonProps } from 'antd'
import styled, { DefaultTheme } from 'styled-components'

interface StyledButtonProps extends ButtonProps {
  $noStyle?: boolean
  $severity?: 'error' | 'success' | 'warning' | 'info'
  danger?: boolean
  theme: DefaultTheme
}

export const IntentionLeadButton = styled(Button)<StyledButtonProps>`
  color: ${(props) => props.theme.colors['white-cream']} !important;
  background-color: #ec9b26 !important;
  border: 1px solid #ec9b26 !important;

  border-radius: 40px !important;
  font-weight: bold !important;

  &.ant-btn-loading {
    cursor: not-allowed;
  }

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #eea53c !important;
  }

  .ant-spin-dot-item {
    background-color: white;
  }
`

export const IntentionFormWrapper = styled.div`
  height: 100vh;

  background-color: transparent;
`
