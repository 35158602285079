import { Chart } from './Chart'
import { ErrorMessage, LoadingContainer } from '../../../styles'
import { useGetDonationsPerType } from '../../../../../hooks/dashboards/finances/queries/useGetDonationsPerType'

export const Content = ({ filters, setCampaignsOptions }) => {
  const { data, isPending, isError } = useGetDonationsPerType(filters)

  if (data?.campaigns) {
    setCampaignsOptions(data?.campaigns)
  }

  if (isPending) {
    return <LoadingContainer />
  }

  if (isError) {
    return <ErrorMessage />
  }

  return <Chart data={data} />
}
