import { useCallback, useMemo } from 'react'

type Option<T = any> = {
  label: string
  value: T
}

const SELECT_ALL_OPTION: Option<string> = { label: 'Selecionar todos', value: '_SELECT_ALL_OPTION' }

export function useSelectAllOption<T>(options: Option<T>[]) {
  const optionsWithAllOption = useMemo(() => [SELECT_ALL_OPTION, ...options], [options])

  const getValueFromEvent = useCallback(
    (value: Option<T>[], selections: Option<T>[]) => {
      if (!selections?.length) return []

      if (!selections.some((s) => s.value === SELECT_ALL_OPTION.value)) {
        return selections.map((option) => option.value)
      }

      return options.map((option) => option.value)
    },
    [options],
  )

  return [getValueFromEvent, optionsWithAllOption] as const
}
