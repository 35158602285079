import { handleCepAutofill, handleCpfShowValues, handlePhoneNumberFormat } from '../../../../../utility/utility'
import { useFetchUserMe, useFetchUserMeAddress } from '../../../../admin/hooks'
import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { DataService } from '../../../../../lib/dataService'

export function loadIdentificationFormValues({
  form,
  userMe,
  addressData,
}: {
  form: FormInstance
  userMe: Record<string, any>
  addressData: Record<string, any>
}) {
  if (userMe) {
    form?.setFieldsValue({
      nome: userMe?.nome,
      email: userMe?.email,
      confirmEmail: userMe?.email,
      cpf: handleCpfShowValues(userMe?.numero_documento),
      cnpj: userMe?.numero_documento,
      identidade_estrangeira: userMe?.numero_documento,
      data_nascimento: userMe?.data_nascimento,
      telefone: handlePhoneNumberFormat(userMe?.telefone),
      sexo: userMe?.sexo,
      pais: userMe?.pais || 'brasil',
      nome_social: userMe?.nome_social,
    })
  }

  if (addressData?.cep) {
    form?.setFieldsValue({
      ...addressData,
      cep: addressData?.cep ? handleCepAutofill(addressData?.cep) : null,
    })
  }

  if (addressData?.detalhe_estrangeiro) {
    form.setFieldsValue({
      detalhe_estrangeiro: addressData.detalhe_estrangeiro,
    })
  }
}

interface AddressAutoComplete {
  bairro: string
  cep: string
  cidade: string
  estado: string
  rua: string
}

export interface IAddressForm extends AddressAutoComplete {
  numero: number
  complemento: string
}

export interface AddressFormProps {
  form: FormInstance<IAddressForm>
}

export const fillAddressFromCep = async ({ form }: AddressFormProps): Promise<{ filledFields: string[] }> => {
  const values: IAddressForm = form.getFieldsValue()
  const cleanedCepValue = values?.cep?.replace(/[^0-9]/g, '')

  if (!cleanedCepValue || cleanedCepValue.length !== 8) {
    console.warn('Invalid CEP length')
    return { filledFields: [] }
  }

  try {
    const { data: addressAutoComplete }: { data: AddressAutoComplete } = await DataService.get(
      `/services/search-cep/${cleanedCepValue}`,
    )

    const filledFields = Object.keys(addressAutoComplete).filter(
      (key) => addressAutoComplete[key as keyof AddressAutoComplete],
    )

    form.setFieldsValue({
      estado: addressAutoComplete.estado || '',
      cidade: addressAutoComplete.cidade || '',
      bairro: addressAutoComplete.bairro || '',
      rua: addressAutoComplete.rua || '',
    })

    return { filledFields }
  } catch (error) {
    console.error('Error fetching address:', error)
    return { filledFields: [] }
  }
}
