export function AdminMenuConfig() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 34.372 36.265">
        <g id="settings-svgrepo-com" transform="translate(-1.067 -0.5)">
          <circle
            id="Elipse_2"
            data-name="Elipse 2"
            cx={4.5}
            cy={4.5}
            r={4.5}
            transform="translate(13.572 14.265)"
            fill="none"
            strokeWidth={3}
          />
          <path
            id="Caminho_12"
            data-name="Caminho 12"
            d="M21.189,2.253A8.3,8.3,0,0,0,18.253,2a8.3,8.3,0,0,0-2.936.253,3.327,3.327,0,0,0-1.8,1.8,4.1,4.1,0,0,0-.238,1.436,2.629,2.629,0,0,1-3.92,2.263A4.1,4.1,0,0,0,8,7.241,3.326,3.326,0,0,0,5.537,7.9a8.3,8.3,0,0,0-1.687,2.416A8.3,8.3,0,0,0,2.6,12.985a3.327,3.327,0,0,0,.659,2.459,4.1,4.1,0,0,0,1.124.924,2.629,2.629,0,0,1,0,4.527,4.1,4.1,0,0,0-1.125.924A3.326,3.326,0,0,0,2.6,24.279a8.3,8.3,0,0,0,1.249,2.669,8.3,8.3,0,0,0,1.687,2.416A3.327,3.327,0,0,0,8,30.024a4.1,4.1,0,0,0,1.363-.512,2.629,2.629,0,0,1,3.92,2.263,4.1,4.1,0,0,0,.238,1.436,3.327,3.327,0,0,0,1.8,1.8,8.3,8.3,0,0,0,2.936.253,8.3,8.3,0,0,0,2.936-.253,3.326,3.326,0,0,0,1.8-1.8,4.1,4.1,0,0,0,.238-1.436,2.629,2.629,0,0,1,3.92-2.263,4.1,4.1,0,0,0,1.362.512,3.326,3.326,0,0,0,2.459-.659,8.3,8.3,0,0,0,1.687-2.416,8.293,8.293,0,0,0,1.249-2.669,3.327,3.327,0,0,0-.659-2.459,4.1,4.1,0,0,0-1.125-.924,2.629,2.629,0,0,1,0-4.527,4.1,4.1,0,0,0,1.125-.924,3.326,3.326,0,0,0,.659-2.459,8.294,8.294,0,0,0-1.249-2.669A8.3,8.3,0,0,0,30.97,7.9a3.326,3.326,0,0,0-2.459-.659,4.1,4.1,0,0,0-1.363.512,2.629,2.629,0,0,1-3.92-2.263,4.1,4.1,0,0,0-.238-1.436A3.326,3.326,0,0,0,21.189,2.253Z"
            fill="none"
            strokeWidth={3}
          />
        </g>
      </svg>
    </div>
  )
}
