import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  .ant-form-item-required {
    color: ${({ theme }) => theme.colors['gray-450']} !important;
  }
  .ant-form-item-label {
    color: ${({ theme }) => theme.colors['gray-450']} !important;
  }

  .ant-form-item  {
    label{
      color: ${({ theme }) => theme.colors['gray-450']} !important;
    }
  }

  label {
    font-family: ${({ theme }) => theme.fonts.family.primary};
    color: #626262 !important;
    font-weight: 700;
    font-size: 16px;
  }
`;
