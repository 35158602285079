import { Col, Row } from 'antd'
import BaseButton from '../../../components/button/BaseButton'
import { PlusCircle } from '@phosphor-icons/react'
import { useState } from 'react'
import { CadastrarCicloDoacao } from './Header/CadastrarCicloDoacao'
import { CadastrarAcaoSolidaria } from './Header/CadastrarAcaoSolidaria'
import { CadastrarEstoque } from './Header/CadastrarEstoque'

export function BotoesHeaderItens() {
  const [isOpenCadastroEstoque, setIsOpenCadastroEstoque] = useState(false)
  const [isOpenCicloDoacao, setIsOpenCicloDoacao] = useState(false)
  const [isOpenCadastrarAcaoSolidaria, setIsOpenCadastrarAcaoSolidaria] = useState(false)

  function handleIsOpenCadastroEstoque() {
    setIsOpenCadastroEstoque((prev) => !prev)
  }

  function handleIsOpenCicloDoacao() {
    setIsOpenCicloDoacao((prev) => !prev)
  }

  function handleIsOpenCadastrarAcaoSolidaria() {
    setIsOpenCadastrarAcaoSolidaria((prev) => !prev)
  }

  return (
    <Row justify="center" gutter={[20, 20]}>
      <Col>
        <BaseButton
          severity="info"
          icon={<PlusCircle size={20} />}
          onClick={handleIsOpenCicloDoacao}
        >
          Cadastrar ciclo de doação
        </BaseButton>
      </Col>
      <Col>
        <BaseButton
          severity="success"
          icon={<PlusCircle size={20} />}
          onClick={handleIsOpenCadastrarAcaoSolidaria}
        >
          Cadastrar ação solidária
        </BaseButton>
      </Col>
      <Col>
        <BaseButton
          severity="warning"
          icon={<PlusCircle size={20} />}
          onClick={handleIsOpenCadastroEstoque}
        >
          Cadastrar estoque
        </BaseButton>
      </Col>

      <CadastrarEstoque
        open={isOpenCadastroEstoque}
        handleIsOpenCadastroEstoque={handleIsOpenCadastroEstoque}
      />

      <CadastrarCicloDoacao
        open={isOpenCicloDoacao}
        handleIsOpenAcaoDoacao={handleIsOpenCicloDoacao}
      />

      <CadastrarAcaoSolidaria
        open={isOpenCadastrarAcaoSolidaria}
        handleIsOpenCadastrarAcaoSolidaria={handleIsOpenCadastrarAcaoSolidaria}
      />
    </Row>
  )
}
