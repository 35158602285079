import { useQuery } from '@tanstack/react-query'

interface BarData {
  type: string
  count: number
}

export function useMessagesBarData() {
  return useQuery<BarData[]>({
    queryKey: ['messages-bar-type'],
    queryFn: async () => {
      return [
        { type: 'Email', count: 4500 },
        { type: 'SMS', count: 3200 },
        { type: 'Push', count: 2800 },
        { type: 'WhatsApp', count: 5200 }
      ]
    }
  })
} 