import { Button, ButtonProps } from 'antd';
import styled, { css, DefaultTheme } from 'styled-components';
import { addOpacity } from '../../../../utility/utility';
import { benfeitorTheme } from '../../../../styles/Themes/Benfeitor/benfeitorTheme';

interface StyledButtonProps extends ButtonProps {
  $noStyle?: boolean;
  $severity?: 'error' | 'success' | 'warning' | 'info';
  danger?: boolean;
  theme: DefaultTheme;
}

export const StyledButton = styled(Button) <StyledButtonProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.3rem !important;
  color: ${(props) => props.theme.colors['white-cream']} !important;
  background-color: ${(props) => props.theme.colors['light-brown']} !important;
  border: 1px solid ${(props) => props.theme.colors['light-brown']} !important;
  padding: 1.2rem;
  border-radius: 8px !important;
  font-weight: bold !important;

  &.ant-btn-loading {
    cursor: not-allowed;
  }

  /* &.ant-btn-background-ghost {
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      color: var(--text-secondary-color) !important;
      border-color: var(--text-secondary-color) !important;
      cursor: not-allowed;
    }

    &:hover,
    &:focus {
      background-color: var(--button-bg-hover-color) !important;
      color: ${(props) => props.theme.colors['white-cream']} !important;
      border-color: var(--button-bg-hover-color) !important;
    }
  } */

  ${(props) =>
    props.$noStyle &&
    css`
      width: unset !important;
      padding: 0 !important;
      height: unset !important;
    `}

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors['gray-200']} !important;
    background-color: ${(props) => props.theme.colors['dark-brown']} !important;
  }

  ${(props) =>
    !props.danger &&
    css`
      ${props.$severity &&
      css`
        background-color: ${({ theme }) => theme.colors[props.$severity!]} !important;
        color: ${(props) => props.theme.colors['white-cream']} !important;
        border-color: transparent !important;

        &:hover {
          background-color: ${({ theme }) => addOpacity(theme.colors[props.$severity!], 0.8)} !important;
          border-color: transparent !important;
          color: ${(props) => props.theme.colors['white-cream']} !important;
        }

        &:focus {
          background-color: ${({ theme }) => theme.colors[props.$severity!]} !important;
          border-color: transparent !important;
          color: ${(props) => props.theme.colors['white-cream']} !important;
        }
      `}

      ${props.type === 'text' &&
      css`
        background: transparent !important;
        color: ${(props) => props.theme.fonts.color.muted} !important;
        border: none !important;

        :hover,
        :active,
        :focus {
          background-color: transparent !important;
          font-weight: 500;
          color: inherit !important;
          opacity: 0.5;
        }
      `}

      ${props.type === 'ghost' &&
      css`
        color: ${(props) => props.theme.fonts.color.primary} !important;
        background-color: transparent !important;
        border: 1px solid ${(props) => props.theme.colors['dark-brown']} !important;

        &:hover {
          color: ${(props) => props.theme.colors['white-cream']} !important;
          background-color: ${(props) => props.theme.colors['dark-brown']} !important;
        }
      `}

      ${props.type === 'primary' &&
      css`
        background: var(--primary-color) !important;

        &:hover {
          background: var(--secondary-color) !important;
          border-color: var(--secondary-color) !important;
          color: var(--button-text-hover-color) !important;
        }
      `}

      ${props.type === 'link' &&
      css`
        border: none !important;
        background-color: transparent !important;
        color: ${(props) => props.theme.fonts.color.primary} !important;
        text-decoration: underline !important;
        padding: 0 !important;
        :hover,
        :active,
        :focus {
          background-color: inherit !important;
          color: inherit !important;
          opacity: 0.8;
        }
      `};
    `}

  .ant-spin-dot-item {
    background-color: white;
  }
`;
