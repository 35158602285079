import { Col, Form, Row } from 'antd'
import { StyledInput } from '../../../components/Inputs'
import { handlePhoneNumberFormat } from '../../../../../utility/utility'
import { StyledSelect } from '../../../components/Select/styles'
import { countries } from '../../../../../utility/allCountries'

type PreviousCadastroFieldsProps = {
  nome: string
  email: string
  telefone: string
  pais: string
}

export function PreviousCadastroFields({ nome, email, telefone, pais }: PreviousCadastroFieldsProps) {
  return (
    <Row gutter={20}>
      <Col xs={24}>
        <Form.Item label="País">
          <StyledSelect disabled value={pais} options={countries} />
        </Form.Item>
      </Col>

      <Col xs={8}>
        <Form.Item label="Nome">
          <StyledInput disabled value={nome} />
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item label="Email">
          <StyledInput disabled value={email} />
        </Form.Item>
      </Col>
      <Col xs={8}>
        <Form.Item label="Telefone">
          <StyledInput disabled value={pais === 'brasil' ? handlePhoneNumberFormat(telefone) : telefone} />
        </Form.Item>
      </Col>
    </Row>
  )
}
