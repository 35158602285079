import React, { useEffect, useState } from 'react';
import { Form, Switch } from 'antd';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { StyledInput } from '../../../../../components/Inputs';
import { validateCPF } from '../../../../../../../utility/utility';

export const CpfCnpjInput = ({
  nostyle,
  disabled = false,
  tipo_documento,
}: {
  nostyle?: boolean;
  disabled?: boolean;
  tipo_documento?: 'cpf' | 'cnpj';
}) => {
  const [isCpf, setIsCpf] = useState(true);

  useEffect(() => {
    if (tipo_documento === 'cpf') {
      setIsCpf(true);
    }

    if (tipo_documento === 'cnpj') {
      setIsCpf(false);
    }
  }, [tipo_documento]);

  const toggleInputType = () => {
    setIsCpf((prev) => !prev);
  };

  const validateCNPJ = (_: any, value: string) => {
    if (value && value.replace(/\D/g, '').length !== 14) {
      return Promise.reject(new Error('CNPJ deve conter 14 dígitos.'));
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      name={isCpf ? 'cpf' : 'cnpj'}
      label={
        <LabelContainer>
          <span>Documento</span>
          <Switch
            disabled={disabled}
            checked={!isCpf}
            onChange={toggleInputType}
            checkedChildren="CNPJ"
            unCheckedChildren="CPF"
          />
        </LabelContainer>
      }
      rules={[
        { required: true, message: isCpf ? '' : 'CNPJ é obrigatório' },
        { validator: isCpf ? validateCPF : validateCNPJ },
      ]}
    >
      <InputMask
        disabled={disabled}
        mask={isCpf ? '999.999.999-99' : '99.999.999/9999-99'}
        placeholder={isCpf ? 'Insira o CPF' : 'Insira o CNPJ'}
        maskChar={null}
      >
        {(inputProps) => (
          <StyledInput
            {...inputProps}
            disabled={disabled}
            style={nostyle ? undefined : { border: '2.5px solid #acacac' }}
          />
        )}
      </InputMask>
    </Form.Item>
  );
};

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
