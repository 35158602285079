import { QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/es/locale/pt_BR'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './context/Auth'
import { queryClientConfig } from './lib/react-query'
import store from './redux/store'
import { Routes } from './routes'

import "antd/dist/antd.css"

function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <Provider store={store}>
        <QueryClientProvider client={queryClientConfig}>
          <Router basename={import.meta.env.PUBLIC_URL}>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </Provider>
    </ConfigProvider>
  )
}

export default hot(App)
