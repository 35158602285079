import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface RegistrarReciboDoacaoProps {
  fk_doacao_agape_id: number

  recibos: FormData
}

export function useRegistrarReciboDoacao() {
  const queryClient = useQueryClient()

  const registrarRecibo = useMutation({
    mutationFn: async ({ fk_doacao_agape_id, recibos }: RegistrarReciboDoacaoProps) => {
      await DataService.post(`/agape/registrar-recibos-doacao-agape/${fk_doacao_agape_id}`, recibos, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    onSuccess: () => {
      notification.success({ message: 'Recibo(s) registrado(s) com sucesso!' })

      queryClient.invalidateQueries({ queryKey: ['BENEFICIADOS'] })
      queryClient.invalidateQueries({ queryKey: ['LISTAR_DOACOES_RECEBIDAS'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao registrar o recibo',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return registrarRecibo
}
