import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { useGetItensDoadosBeneficiado } from '../../../../hooks/agape/queries/useGetItensDoadosBeneficiado'

interface ListagemItensRecebimentoProps {
  fk_doacao_agape_id: number
}

export function ListagemItensRecebimento({ fk_doacao_agape_id }: ListagemItensRecebimentoProps) {
  const {
    data: itensDoadosBeneficiado,
    isLoading: isLoadingItensDoados,
    isError: isErrorItensDoados,
  } = useGetItensDoadosBeneficiado({ fk_doacao_agape_id })

  const dataSource = itensDoadosBeneficiado?.itens_doados.map((itemDoado) => {
    const { item, quantidade } = itemDoado

    return {
      item,
      quantidade,
    }
  })

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingItensDoados}
      isError={isErrorItensDoados}
    />
  )
}
