import { useState } from 'react'

export interface DonationDetails {
  open: boolean
  id: string
  data_hora: string
  comprovante_id: string
  referencia_id: string
  transacao_id: string
  status: string
  metodo: string
  gateway: string
  recorrencia: any
  tipo: string
}

export const useDonationDetailsModal = () => {
  const [donationDetailsModalContent, setDonationDetailsModalContent] = useState<DonationDetails>({
    open: false,
    id: '',
    data_hora: '',
    comprovante_id: '',
    referencia_id: '',
    transacao_id: '',
    status: '',
    metodo: '',
    gateway: '',
    recorrencia: null,
    tipo: '',
  })

  const handleOpenDetailsModal = (data: DonationDetails) => {
    setDonationDetailsModalContent({ ...data, open: true })
  }

  const handleCloseDetailsModal = () => {
    setDonationDetailsModalContent((prev) => ({ ...prev, open: false }))
  }

  return {
    donationDetailsModalContent,
    handleOpenDetailsModal,
    handleCloseDetailsModal,
  }
}
