import moment from 'moment'
import BaseCard from '../../../../../components/card/BaseCard'
import { BaseInput } from '../../../../../components/inputs/BaseInput/BaseInput'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { BaseSelect } from '../../../../../components/select/BaseSelect'
import BaseRadio from '../../../../../components/radio/BaseRadio'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'

const etapaOptions = [
  {
    label: 'Pré-Cadastro',
    value: 'pre_cadastro',
  },
  {
    label: 'Cadastro',
    value: 'cadastro',
  },
  {
    label: 'Ficha Vocacional',
    value: 'ficha_vocacional',
  },
]

type IdFilterProps = {
  value: string | undefined
  handleInputChange: ({ id }: { id: string }) => void
}

function IdFilter({ handleInputChange, value }: IdFilterProps) {
  return (
    <BaseCard>
      <h4>Id</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Id"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ id: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type NomeFilterProps = {
  value: string | undefined
  handleInputChange: ({ nome }: { nome: string }) => void
}

function NomeFilter({ handleInputChange, value }: NomeFilterProps) {
  return (
    <BaseCard>
      <h4>Nome</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Nome"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ nome: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type EmailFilterProps = {
  value: string | undefined
  handleInputChange: ({ email }: { email: string }) => void
}

function EmailFilter({ handleInputChange, value }: EmailFilterProps) {
  return (
    <BaseCard>
      <h4>Email</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="E-mail"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ email: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type TelefoneFilterProps = {
  value: string | undefined
  handleInputChange: ({ telefone }: { telefone: string }) => void
}

function TelefoneFilter({ handleInputChange, value }: TelefoneFilterProps) {
  return (
    <BaseCard>
      <h4>Telefone</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Telefone"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ telefone: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type EtapaFilterProps = {
  value: 'pre_cadastro' | 'cadastro' | 'ficha_vocacional' | undefined
  handleInputChange: ({ etapa }: { etapa: 'pre_cadastro' | 'cadastro' | 'ficha_vocacional' }) => void
}

function EtapaFilter({ handleInputChange, value }: EtapaFilterProps) {
  return (
    <BaseCard>
      <h4>Etapa</h4>
      <BaseSelect
        placeholder="Selecione a etapa"
        value={value}
        allowClear
        onChange={(value) => {
          handleInputChange({ etapa: value })
        }}
        options={etapaOptions}
      />
    </BaseCard>
  )
}

type PaisFilterProps = {
  value: string | undefined
  handleInputChange: ({ pais }: { pais: string }) => void
}

function PaisFilter({ value, handleInputChange }: PaisFilterProps) {
  return (
    <BaseCard>
      <h4>País</h4>
      <BaseRadio.Group onChange={(e) => handleInputChange({ pais: e.target.value })} value={value}>
        <BaseRadio.Button value="brasil">Brasileiro</BaseRadio.Button>
        <BaseRadio.Button value="estrangeiro">Estrangeiro</BaseRadio.Button>
        <BaseRadio.Button>X</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

type RecusadoEmProps = {
  value: { data_inicial: string; data_final: string } | undefined
  handleInputChange: ({ data_inicial, data_final }: { data_inicial: string; data_final: string }) => void
}

function RecusadoEmFitler({ handleInputChange, value }: RecusadoEmProps) {
  const formattedValue = [
    value?.data_inicial ? moment(value.data_inicial, 'YYYY-MM-DD') : null,
    value?.data_final ? moment(value.data_final, 'YYYY-MM-DD') : null,
  ]

  return (
    <BaseCard>
      <h4>Recusado em</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        style={{ width: '100%' }}
        onChange={(_: never, dateStrings: [string, string]) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

          console.log('ONCHANGE', {
            data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })

          handleInputChange({
            data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY"
        placeholder={['Inicial', 'Final']}
        disabledDate={(current: moment.Moment) => current > moment()}
      />
    </BaseCard>
  )
}

export const RecusadosFilters = {
  Id: IdFilter,
  Nome: NomeFilter,
  Telefone: TelefoneFilter,
  Pais: PaisFilter,
  RecusadoEm: RecusadoEmFitler,
  Email: EmailFilter,
  Etapa: EtapaFilter,
}
