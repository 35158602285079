import { Layout } from 'antd'
import styled from 'styled-components'
import Particula from '../../../../../../static/img/particula_plano_de_fundo.png'

export const StyledLayoutWrapper = styled(Layout)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background: url(${Particula}) repeat;

`;