import { useQuery } from '@tanstack/react-query'

interface MonthData {
  month: string
  count: number
}

export function useMessagesPerMonth() {
  return useQuery<MonthData[]>({
    queryKey: ['messages-per-month'],
    queryFn: async () => {
      return [
        { month: 'Jan', count: 1200 },
        { month: 'Fev', count: 1800 },
        { month: 'Mar', count: 1400 },
        // ... more months
      ]
    }
  })
} 