import { useState } from 'react'
import BaseModal from '../../../../../../components/modal/BaseModal'
import BaseButton from '../../../../../../components/button/BaseButton'
import { MapPin } from '@phosphor-icons/react'
import { MapaEnderecoBeneficiarios } from './MapaEnderecosBeneficiarios'

interface EnderecoBeneficiariosProps {
  idCicloDoacao: number
}

export function EnderecoBeneficiarios({ idCicloDoacao }: EnderecoBeneficiariosProps) {
  const [isOpenEnderecoBeneficiario, setIsOpenEnderecoBeneficiario] = useState(false)

  function handleIsOpenCadastrarAcaoSolidaria() {
    setIsOpenEnderecoBeneficiario((prev) => !prev)
  }

  return (
    <>
      <BaseButton type="text" onClick={handleIsOpenCadastrarAcaoSolidaria}>
        <MapPin size={30} color={'var(--info-color)'} />
      </BaseButton>

      <BaseModal
        title={<h3>Região dos beneficiados</h3>}
        open={isOpenEnderecoBeneficiario}
        onCancel={handleIsOpenCadastrarAcaoSolidaria}
        footer={false}
        width={1000}
      >
        <MapaEnderecoBeneficiarios idCicloDoacao={idCicloDoacao} />
      </BaseModal>
    </>
  )
}
