import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { useAuth } from '../../../../../context/Auth'
import { notification } from 'antd'

export const useSoftDeleteMe = () => {
  const { logout } = useAuth()

  const softDeleteMe = useMutation({
    mutationFn: () => {
      return DataService.delete(`/users/delete-self-account`)
    },
    onSuccess: () => {
      notification.destroy()

      notification.success({ message: 'Seu usuário foi excluído com sucesso' })
      logout()
    },
    onError: (error) => {
      notification.error({
        message: 'Ocorreu um erro ao excluir o usuário',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return softDeleteMe
}
