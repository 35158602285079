import { Layout, Menu } from 'antd'
import styled from 'styled-components'
import { Header } from 'antd/lib/layout/layout'
import { FONT_FAMILY, FONT_SIZE } from '../../../../styles/Themes/Admin/constants'

const dashboardPaths = ['/admin/dashboard/financeiro', '/admin/dashboard/cadastros']
const isDashboardPath = (location) => {
  return dashboardPaths.includes(location?.pathname)
}

const hexToFilter = (hex) => {
  hex = hex.replace('#', '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const brightnessValue = (r + g + b) / 3 > 128 ? 0 : 100
  const invertValue = (r + g + b) / 3 > 128 ? 5 : 95
  const saturateValue = 100 + Math.max(r, g, b) * 3
  const contrastValue = 60 + (r + g + b) / 3

  return `brightness(${brightnessValue}) saturate(${saturateValue}%) invert(${invertValue}%) sepia(1%) saturate(5440%) hue-rotate(316deg) brightness(64%) contrast(${contrastValue}%)`
}

export const StyledContent = styled(Layout.Content)`
  background-color: var(--background-color);
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;

  > div {
    max-width: ${({ location }) => (isDashboardPath(location) ? '200rem' : '140rem')};
    margin: 0 auto;
  }

  padding: ${({ location }) => (isDashboardPath(location) ? '0rem' : '1rem 2.5rem')};
`

export const StyledHeader = styled(Header)`
  background: var(--background-color);
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'end')};
  align-items: center;
  padding-inline: 1rem;
  gap: 1rem;
  position: sticky;
  top: 0;
  z-index: 4;
  right: 0;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '3.8rem' : '4.5rem')};
`

export const StyledMenu = styled(Menu)`
  background: transparent;
  border-right: 0;

  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: none;
  }

  z-index: 2;

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
    font-family: ${FONT_FAMILY.main};

    .ADMIN-MENU-ICON {
      height: 1.7rem;
    }
  }

  .ant-menu-submenu:not(.ant-menu-submenu-selected),
  .ant-menu-item {
    color: var(--sidebar-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-block: 0.7rem;

    font-weight: bold;

    margin-inline: auto;
    border-radius: 12px;

    width: 110px;
    height: 95px;

    .ant-menu-title-content {
      height: 30px;
    }

    .ADMIN-MENU-ICON {
      svg {
        fill: var(--text-secondary-color) !important;
        stroke: var(--text-secondary-color) !important;
      }
    }

    &:hover {
      .ant-menu-title-content {
        color: var(--text-main-color);
      }

      .ADMIN-MENU-ICON {
        svg {
          fill: var(--text-main-color) !important;
          stroke: var(--text-main-color) !important;
        }
      }

      transition: all 150ms ease-in;
      transform: scale(1.1);
    }
  }

  .ant-menu-submenu.ant-menu-submenu-selected {
    .ADMIN-MENU-ICON {
      svg {
        fill: var(--sidebar-selected-menu-text-color) !important;
        stroke: var(--sidebar-selected-menu-text-color) !important;
      }
    }
    color: var(--sidebar-selected-menu-text-color);

    font-weight: bold;

    .ant-menu-title-content {
      height: 30px;
    }

    &:hover {
      .ant-menu-title-content {
        color: var(--sidebar-selected-menu-text-color);
      }
    }
    background-color: var(--sidebar-selected-menu-background-color);
    margin-inline: auto;
    border-radius: 12px;

    width: 110px;
    height: 95px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-submenu-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 3rem;

    .ant-menu-submenu-arrow {
      display: none;
    }

    span.ant-menu-title-content {
      margin: 0;
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
  }

  .ant-menu-sub.ant-menu-inline {
    background: var(--sidebar-background-darker-color);
  }

  li.ant-menu-item-selected {
    &::after {
      border-right: solid #726664 4px;
    }
  }
`
