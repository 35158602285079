import { MembrosDrawer } from '../drawer/MembrosDrawer'
import { Row } from 'antd'

import { MembrosFamiliaTabela } from './MembrosFamiliaTabela'
import { useGetMembrosFamilia } from '../../../../../../hooks/agape/queries/useGetMembrosFamilia'
import { useMembrosDrawer } from '../drawer/useMembrosDrawer'
import { BaseSpin } from '../../../../../../components/spin/BaseSpin.Styles'
import BaseAlert from '../../../../../../components/alert/BaseAlert'

export function ListagemMembrosFamilia({ idFamilia }: { idFamilia: number }) {
  const {
    data: membrosFamilia,
    isLoading: isLoadingMembros,
    isError: isErrorMembros,
  } = useGetMembrosFamilia({ idFamilia })

  const { open, editarMembro, idMembro, form, tituloDrawer, showDrawer, onClose, onSubmit } = useMembrosDrawer()

  if (isLoadingMembros) {
    return (
      <Row justify="center" style={{ padding: '1rem' }}>
        <BaseSpin />
      </Row>
    )
  }

  if (isErrorMembros) {
    return (
      <Row justify="center">
        <BaseAlert
          showIcon
          message="Houve um erro ao listar os membros dessa família"
          type="error"
          children={undefined}
        />
      </Row>
    )
  }

  return (
    <>
      <MembrosDrawer
        open={open}
        titulo={tituloDrawer}
        onClose={onClose}
        onSubmit={onSubmit}
        form={form}
        editarMembro={editarMembro}
        idMembro={idMembro}
        idFamilia={idFamilia}
      />
      <MembrosFamiliaTabela lista={membrosFamilia?.membros} showDrawer={showDrawer} />
    </>
  )
}
