import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useDispatch } from 'react-redux'
import { setFormField } from '../../../../../redux/campaign/actions'
import { useGetPaymentsGateway } from '../../../hooks'
import BaseCard from '../../../components/card/BaseCard'
import BaseForm from '../../../components/forms/BaseForm/BaseForm'
import { BaseSelect } from '../../../components/select/BaseSelect'
import { BaseInput } from '../../../components/inputs/BaseInput/BaseInput'

const Step2 = ({ form, handleInputChange }) => {
  const dispatch = useDispatch()

  const { data: paymentsGateway } = useGetPaymentsGateway()

  return (
    <Row gutter={15}>
      <Col sm={24} style={{ width: '100%' }}>
        <BaseForm
          form={form}
          layout="vertical"
          // onValuesChange={(values) => {
          //   handleInputChange({ values });
          // }}
        >
          <Typography.Title level={4} style={{ marginBottom: '25px' }}>
            Doações Recorrentes:
          </Typography.Title>
          <Row gutter={30}>
            <Col xs={24} sm={8} md={8}>
              <BaseForm.Item name="pix_recorrente" label="Pix" rules={[{ required: true, message: 'Obrigatório' }]}>
                <BaseSelect
                  onChange={(e) => handleInputChange('pix_recorrente', e)}
                  style={{ width: '100%' }}
                  options={paymentsGateway?.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                  }))}
                />
              </BaseForm.Item>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <BaseForm.Item
                name="credito_recorrente"
                label="Cartão de Crédito"
                rules={[{ required: true, message: 'Obrigatório' }]}
              >
                <BaseSelect
                  onChange={(e) => handleInputChange('credito_recorrente', e)}
                  style={{ width: '100%' }}
                  options={paymentsGateway?.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                  }))}
                />
              </BaseForm.Item>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <BaseForm.Item
                name="boleto_recorrente"
                label="Boleto"
                rules={[{ required: true, message: 'Obrigatório' }]}
              >
                <BaseSelect
                  onChange={(e) => handleInputChange('boleto_recorrente', e)}
                  style={{ width: '100%' }}
                  options={paymentsGateway?.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                  }))}
                />
              </BaseForm.Item>
            </Col>
          </Row>
          <Typography.Title level={4} style={{ marginBottom: '25px' }}>
            Doações Avulsas:
          </Typography.Title>
          <Row gutter={30}>
            <Col xs={24} sm={8} md={8}>
              <BaseForm.Item name="pix_unico" label="Pix" rules={[{ required: true, message: 'Obrigatório' }]}>
                <BaseSelect
                  onChange={(e) => handleInputChange('pix_unico', e)}
                  style={{ width: '100%' }}
                  options={paymentsGateway?.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                  }))}
                />
              </BaseForm.Item>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <BaseForm.Item
                name="credito_unico"
                label="Cartão de Crédito"
                rules={[{ required: true, message: 'Obrigatório' }]}
              >
                <BaseSelect
                  onChange={(e) => handleInputChange('credito_unico', e)}
                  style={{ width: '100%' }}
                  options={paymentsGateway?.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                  }))}
                />
              </BaseForm.Item>
            </Col>
            <Col xs={24} sm={8} md={8}>
              <BaseForm.Item name="boleto_unico" label="Boleto" rules={[{ required: true, message: 'Obrigatório' }]}>
                <BaseSelect
                  onChange={(e) => handleInputChange('boleto_unico', e)}
                  style={{ width: '100%' }}
                  options={paymentsGateway?.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                  }))}
                />
              </BaseForm.Item>
            </Col>
            <Col xs={24}>
              <BaseForm.Item name="chave_pix" label="Chave Pix" rules={[{ required: true, message: 'Obrigatório' }]}>
                <BaseInput
                  placeholder="Insira sua chave pix"
                  onChange={(e) => handleInputChange('chave_pix', e.target.value)}
                />
              </BaseForm.Item>
            </Col>
          </Row>
        </BaseForm>
      </Col>
    </Row>
  )
}

export default Step2
