import { BaseResult } from '../BaseResult'

export function ErrorResult({ ...props }) {
  return (
    <BaseResult
      status={props.status || '500'}
      title={props.title || 'Erro'}
      subTitle={props.subtitle || 'Houve um erro ao carregar esse conteúdo.'}
      {...props}
    />
  )
}
