import React, { useState } from 'react'
import { FormInstance } from 'antd/es/form/Form'
import { StyledGrid } from '../../styles'
import { StyledInput } from '../../../../../components/Inputs'
import { StyledInputPassword } from '../../../../../components/Inputs/styles'
import { StyledSelect } from '../../../../../components/Select/styles'
import InputMask from 'react-input-mask'
import { countries } from '../../../../../../../utility/allCountries'
import { useFetchUserMe } from '../../../../../../admin/hooks'
import { UploadPhoto } from '../UploadPhoto'
import { validateCPF, validateDate, validatePassword } from '../../../../../../../utility/utility'
import { Form, UploadFile } from 'antd'
import { CpfCnpjInput } from './CpfCnpjInput'

export function IdentificationForm({ form, style }: { form: FormInstance; style: React.CSSProperties }) {
  const { data: userMe } = useFetchUserMe()
  const selectedCountry = Form.useWatch('pais', form)
  const [file, setFile] = useState<UploadFile[]>([])

  const commonInputStyles = {
    lineHeight: 2.5715,
    borderRadius: 12,
    border: '2.5px solid #acacac',
    size: 'large' as 'large',
    style: { gridColumn: 'span 1' },
  }

  return (
    <div style={style}>
      <StyledGrid columns={3}>
        <Form.Item
          name="nome"
          label="Nome"
          rules={[
            { required: true, message: 'Por favor, insira seu nome completo!' },
            { min: 6, message: 'O nome deve ter no mínimo 6 caracteres!' },
            { max: 100, message: 'O nome deve ter no máximo 100 caracteres!' },
          ]}
        >
          <StyledInput {...commonInputStyles} placeholder="Nome Completo" />
        </Form.Item>

        <Form.Item name="pais" label="País" rules={[{ required: true, message: 'Por favor, insira seu país!' }]}>
          <StyledSelect
            {...commonInputStyles}
            height={55}
            disabled={userMe?.pais}
            options={countries}
            placeholder="Selecione um país"
          />
        </Form.Item>

        {selectedCountry === 'brasil' ? (
          <Form.Item>
            <CpfCnpjInput />
          </Form.Item>
        ) : (
          <Form.Item
            name="identidade_estrangeira"
            label="Documento de identidade"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              { pattern: /^[0-9]+$/, message: 'Apenas números' },
              { min: 6, message: 'Mínimo 6 caracteres' },
              { max: 20, message: 'Máximo 20 caracteres' },
            ]}
          >
            <StyledInput {...commonInputStyles} disabled={userMe?.numero_identidade} />
          </Form.Item>
        )}

        {userMe && (
          <>
            <Form.Item
              name="telefone"
              label="Telefone"
              rules={[
                { required: true, message: 'Obrigatório' },
                { pattern: /^\(\d{2}\) \d{4,5}-\d{4}$/, message: 'Telefone inválido!' },
              ]}
            >
              <InputMask mask="(99) 99999-9999" placeholder="(99) 99999-9999" maskChar={null}>
                {(inputProps) => <StyledInput {...inputProps} {...commonInputStyles} />}
              </InputMask>
            </Form.Item>

            <Form.Item name="sexo" label="Sexo" rules={[{ required: true, message: 'Por favor, insira seu gênero!' }]}>
              <StyledSelect
                {...commonInputStyles}
                height={55}
                disabled={userMe?.sexo}
                placeholder="Selecione"
                defaultValue={null}
                allowClear
                options={[
                  { value: 'masculino', label: 'Masculino' },
                  { value: 'feminino', label: 'Feminino' },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="data_nascimento"
              label="Data de nascimento"
              rules={[
                { required: true, message: 'Por favor, preencha sua data de nascimento!' },
                { validator: (_, value) => validateDate(value) },
              ]}
            >
              <InputMask mask="99/99/9999" placeholder="dd/mm/yyyy">
                {(inputProps) => <StyledInput {...inputProps} {...commonInputStyles} />}
              </InputMask>
            </Form.Item>
          </>
        )}
      </StyledGrid>

      <StyledGrid columns={2}>
        {userMe && (
          <Form.Item
            name="nome_social"
            label="Como deseja ser chamado?"
            rules={[
              { min: 3, message: 'O nome social deve ter no mínimo 3 caracteres!' },
              { max: 45, message: 'O nome social deve ter no máximo 45 caracteres!' },
            ]}
          >
            <StyledInput {...commonInputStyles} placeholder="Nome Social" />
          </Form.Item>
        )}

        {userMe && <UploadPhoto file={file} setFile={setFile} secondStyleType form={form} />}

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            { required: true, message: 'Por favor, insira seu email!' },
            { type: 'email', message: 'Formato de e-mail inválido!' },
            { min: 10, message: 'O e-mail deve ter no mínimo 10 caracteres!' },
            { max: 100, message: 'O e-mail deve ter no máximo 100 caracteres!' },
          ]}
        >
          <StyledInput {...commonInputStyles} disabled={userMe?.email} placeholder="email@email.com" />
        </Form.Item>

        <Form.Item
          name="confirmEmail"
          label="Repita seu e-mail"
          dependencies={['email']}
          rules={[
            { required: true, message: 'Por favor, confirme seu email!' },
            { type: 'email', message: 'Formato de e-mail inválido!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Os e-mails não coincidem!'))
              },
            }),
          ]}
        >
          <StyledInput {...commonInputStyles} disabled={userMe?.email} placeholder="email@email.com" />
        </Form.Item>

        {!userMe && (
          <>
            <Form.Item
              name="password"
              label="Crie uma senha"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
            >
              <StyledInputPassword {...commonInputStyles} placeholder="Senha" />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Repita sua senha"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Por favor, confirme sua senha!' },
                { min: 8, message: 'Sua senha deve ter no mínimo 8 caracteres!' },
                { max: 16, message: 'Sua senha deve ter no máximo 16 caracteres!' },
                {
                  pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/,
                  message: 'A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial.',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('As senhas não coincidem!'))
                  },
                }),
              ]}
            >
              <StyledInputPassword {...commonInputStyles} placeholder="Repita sua senha" />
            </Form.Item>
          </>
        )}
      </StyledGrid>
    </div>
  )
}
