import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { AbrangenciaCicloDoacao } from '../mutations/useCadastrarCicloDoacao'

interface GetEnderecoCicloDoacaoProps {
  idCicloDoacao: number
}

export interface GetEnderecoCicloDoacaoResponse {
  abrangencia: AbrangenciaCicloDoacao
  bairro: string
  cep: string
  cidade: string
  complemento: string
  estado: string
  numero: string
  rua: string
}

export function useGetEnderecoCicloDoacao({ idCicloDoacao }: GetEnderecoCicloDoacaoProps) {
  const enderecoCicloDoacao = useQuery({
    queryKey: ['ENDERECO_CICLO_DOACAO', idCicloDoacao],
    queryFn: async () => {
      const response = await DataService.get<GetEnderecoCicloDoacaoResponse>(
        `/agape/buscar-endereco-instancia-acao-agape/${idCicloDoacao}`,
      )

      return response.data
    },
  })

  return enderecoCicloDoacao
}
