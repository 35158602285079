import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'
import { GetItensEstoqueResponse } from '../../../../../hooks/agape/queries/useGetItensEstoque'
import { ItensEstoqueFilters } from './filters'

interface TabelaItensEstoqueProps {
  itens: GetItensEstoqueResponse | undefined
  isLoading: boolean
  isError: boolean
}

export function TabelaItensEstoque({ itens, isLoading, isError }: TabelaItensEstoqueProps) {
  const dataSource = itens?.estoques?.map((estoque) => {
    const { id, item, quantidade } = estoque

    return {
      id,
      item,
      quantidade,
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      // filterDropdown: () => <ItensEstoqueFilters.Nome />
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
    },
  ]

  return <BaseTable columns={columns} dataSource={dataSource} isLoading={isLoading} isError={isError} />
}
