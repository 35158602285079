import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledAntModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.white};
  }

  .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-color: ${(props) => props.theme.colors['light-brown']};
  }

  .ant-modal-title {
    font-family: ${({ theme }) => theme.fonts.family.secondary};
    color: ${({ theme }) => theme.colors['gray-800']};
    font-weight: 900;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    border-top-color: #cfcfcf !important;
  }
`
