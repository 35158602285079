import { Tag } from 'antd'

export function BaseTag({ children, ...props }) {
  return (
    <Tag
      {...props}
      style={{ border: '1px solid transparent', borderRadius: '5px' }}
    >
      {children}
    </Tag>
  )
}
