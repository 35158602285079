import { BaseTable } from '../../../../../../../../components/table/BaseTable/BaseTable'
import { useGetListarItensRecebidos } from '../../../../../../../../hooks/agape/queries/useGetListarItensRecebidos'

interface ListagemItensRecebimentoFamiliaProps {
  fk_instancia_acao_agape_id: number
  fk_doacao_agape_id: number
}

export function ListagemItensRecebimentoFamilia({
  fk_doacao_agape_id,
  fk_instancia_acao_agape_id,
}: ListagemItensRecebimentoFamiliaProps) {
  const {
    data: itensRecebidosFamilia,
    isLoading: isLoadingItensRecebidosFamilia,
    isError: isErrorItensRecebidosFamilia,
  } = useGetListarItensRecebidos({ fk_doacao_agape_id, fk_instancia_acao_agape_id })

  const dataSource = itensRecebidosFamilia?.itens_recebidos.map((itemDoado) => {
    const { item, quantidade } = itemDoado

    return {
      item,
      quantidade,
    }
  })

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingItensRecebidosFamilia}
      isError={isErrorItensRecebidosFamilia}
    />
  )
}
