import { TextAreaProps } from 'antd/lib/input'
import { StyledTextAreaStyles } from './styles'

export interface StyledTextAreaProps extends TextAreaProps {
  strongPlaceholder?: boolean
}

export function StyledTextArea({ ...props }: StyledTextAreaProps) {
  return <StyledTextAreaStyles {...props} />
}
