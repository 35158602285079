import { Col, Form, Result, Row } from 'antd'
import { CreateCadastroProps, useCreateCadastro } from '../../../hooks/api/vocacional/mutations/useCreateCadastro'
import { StyledForm } from '../../../components/Form/styles'
import ReactInputMask from 'react-input-mask'
import { validateCPF, validateDateOfBirth } from '../../../../../utility/utility'
import { StyledInput } from '../../../components/Inputs'
import moment from 'moment'
import { AddressZipCode } from '../../../components/Address/address-zip-code'
import { StyledButton } from '../../../components/Button'
import { PreviousCadastroFields } from './PreviousCadastroFields'
import { useGetUserVocacionalByToken } from '../../../hooks/api/vocacional/queries/useGetUserVocacionalByToken'
import { StyledSpin } from '../../../components/Spin/styles'
import { AddressForm } from '../../Campaign/CampaignIdentification/components/AddressForm'
import { Title } from '../../../components/Title'

export function CadastroForm({ token }: { token: string }) {
  const [form] = Form.useForm()

  const {
    data: userByToken,
    isLoading: isLoadingGetByUserToken,
    isError: isErrorGetUserByToken,
  } = useGetUserVocacionalByToken({
    token,
  })

  const usuarioBrasileiro = userByToken?.pais === 'brasil'

  const {
    mutateAsync: createCadastro,
    isPending: isPendingCreateCadastro,
    isSuccess: isSuccessCreateCadastro,
  } = useCreateCadastro()

  const handleCadastroFormSubmit = (data: CreateCadastroProps) => {
    const {
      bairro,
      cidade,
      complemento,
      documento_identidade,
      estado,
      cep,
      numero,
      rua,
      detalhe_estrangeiro,
      data_nascimento,
    } = data

    if (userByToken?.fk_usuario_vocacional_id) {
      createCadastro({
        bairro,
        cep,
        cidade,
        complemento,
        documento_identidade: documento_identidade.replace(/\D/g, ''),
        estado,
        numero,
        rua,
        detalhe_estrangeiro,
        data_nascimento: moment(data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        fk_usuario_vocacional_id: userByToken?.fk_usuario_vocacional_id,
      })
    }
  }

  if (isLoadingGetByUserToken) {
    return (
      <Row justify="center" style={{ minHeight: '60vh', alignItems: 'center' }}>
        <StyledSpin />
      </Row>
    )
  }

  if (isErrorGetUserByToken) {
    return <Result status="500" title="Erro" subTitle="Houve um erro ao tentar acessar esse conteúdo." />
  }

  if (userByToken?.status === 'desistencia') {
    return <Result title="Você desistiu do processo vocacional" subTitle="Não é possível prosseguir." status="error" />
  }

  if (userByToken?.etapa === 'cadastro' || userByToken?.etapa === 'ficha_vocacional') {
    return (
      <Result
        title="Você já realizou essa etapa."
        subTitle="Aguarde e verifique seu e-mail periodicamente para mais atualizações do processo vocacional"
        status="403"
      />
    )
  }

  if (isSuccessCreateCadastro) {
    return (
      <Result
        status="success"
        title="Cadastro realizado com sucesso."
        subTitle="Aguarde e verifique seu e-mail com periodicidade!"
        style={{ paddingTop: '0' }}
      />
    )
  }

  if (userByToken?.etapa === 'pre_cadastro' && userByToken?.status === 'aprovado') {
    return (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => handleCadastroFormSubmit(values as CreateCadastroProps)}
        disabled={isPendingCreateCadastro}
      >
        {userByToken && (
          <PreviousCadastroFields
            nome={userByToken.nome}
            email={userByToken.email}
            telefone={userByToken.telefone}
            pais={userByToken.pais}
          />
        )}

        <Row gutter={20}>
          <Col xs={24} sm={12}>
            {usuarioBrasileiro ? (
              <Form.Item
                name="documento_identidade"
                label="CPF"
                rules={[{ required: true, message: '' }, { validator: validateCPF }]}
              >
                <ReactInputMask mask="999.999.999-99" name="cpf" maskChar={null} placeholder="999.999.999-99">
                  {(inputProps: any) => <StyledInput {...inputProps} allowClear />}
                </ReactInputMask>
              </Form.Item>
            ) : (
              <Form.Item
                label="Documento de Identidade"
                name="documento_identidade"
                rules={[{ required: true, message: 'Por favor, insira o seu documento!' }]}
              >
                <StyledInput placeholder="Digite seu documento de identidade" />
              </Form.Item>
            )}
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="data_nascimento"
              label="Data de nascimento"
              rules={[
                { required: true, message: 'Por favor, preencha sua data de nascimento!' },
                { validator: (_, value) => validateDateOfBirth(value) },
              ]}
            >
              <ReactInputMask mask="99/99/9999" placeholder="dd/mm/yyyy">
                {(inputProps) => <StyledInput {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
        </Row>

        <Title>Endereço</Title>
        {usuarioBrasileiro ? <AddressForm form={form} showBrazil hasFormBeenFetchedFromApi /> : <AddressZipCode />}

        <Row justify="center">
          <StyledButton htmlType="submit" loading={isPendingCreateCadastro}>
            Enviar
          </StyledButton>
        </Row>
      </StyledForm>
    )
  }

  return (
    <Result
      title="Você não pode visualizar esse conteúdo"
      subTitle="Por favor, siga o fluxo indicados nos e-mails"
      status="403"
    />
  )
}
