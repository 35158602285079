import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CampaignTitleContainer, ContentContainer, SubtitleContainer } from '../CampaignIdentification/styles'
import { LocationState } from '../CampaignIdentification'
import { Divider, Form, notification } from 'antd'
import { PaymentMethod } from './components/PaymentMethod'
import { ValuesOption } from './components/ValuesOptions'
import { CreditCard } from './components/CreditCard'
import { useSubmitBoletoPayment, useSubmitCreditCardPayment, useSubmitPixPayment } from './utils'
import { usePaymentMethod } from './context'
import { PixPayment } from './components/PixPayment'
import { SendButton } from './components/SendButton'
import { BoletoPayment } from './components/BoletoPayment'
import { Periodicity } from './components/Periodicity'
import { DonationContentContainer, MainContainer } from './styles'
import { CampaignCard } from '../CampaignIdentification/components/CampaignCard'
import { useGetUserDeviceId, waitForDeviceId } from '../../../../admin/hooks/users/Queries/useGetUserDeviceId'
import { useFetchSingleCampaign } from '../../../../admin/hooks'
import { useEffect } from 'react'
import { DataService } from '../../../../../lib/dataService'

export interface PaymentFormValues {
  cpf_cnpj: string
  name: string
  paymentMethod: 'pix' | 'card' | 'boleto'
  paymentValue: string
  periodicidade: 1 | 2
  valor_doacao: number
  fk_campanha_id: number
  device_id: string

  number?: string
  cvc?: string
  expiry?: string
}

export function CampaignDonation() {
  useGetUserDeviceId()

  const { campaignId } = useParams<{ campaignId: string }>()
  const { data: campaignData } = useFetchSingleCampaign(campaignId)

  const { form, activePaymentMethod, periodicidadeValue, activeValue, dispatch } = usePaymentMethod()

  const { mutate: submitCreditCardPayment, isPending: isPendingCard } = useSubmitCreditCardPayment()
  const { mutate: submitPixPayment, isPending: isPendingPix } = useSubmitPixPayment()
  const { mutate: submitBoletoPayment, isPending: isPendingBoleto } = useSubmitBoletoPayment()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token')
    async function handleRecurrenceByEmail() {
      const tipoPagamento = queryParams.get('tipo_pagamento')

      if (!token || !tipoPagamento) {
        return
      }

      if (tipoPagamento === 'pix') {
        try {
          const response = await DataService.post(`/checkout/payment/pix/recurrence/${token}`)
          dispatch({
            type: 'SET_PIX_QR_CODE',
            payload: {
              msg: response?.data?.msg,
              pix_qrcode: response?.data?.pix_qrcode,
              qr_code_base64: response?.data?.qr_code_base64,
            },
          })

          dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: 'pix' })

          notification.success({
            message: 'Pagamento gerado com sucesso!',
            description: 'Seu pagamento foi processado com sucesso.',
          })
        } catch (error) {
          notification.error({
            message: 'Ocorreu um erro ao gerar seu pagamento!',
            description: 'Por favor, tente novamente mais tarde',
          })
        }
      }

      if (tipoPagamento === 'invoice') {
        try {
          const response = await DataService.post(`/checkout/payment/invoice/recurrence/${token}`)
          dispatch({ type: 'SET_BOLETO_DATA', payload: response?.data })
          dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: 'boleto' })

          notification.success({
            message: 'Pagamento gerado com sucesso!',
            description: 'Seu pagamento foi processado com sucesso.',
          })
        } catch (error) {
          notification.error({
            message: 'Ocorreu um erro ao gerar seu boleto!',
            description: 'Por favor, tente novamente mais tarde',
          })
        }
      }
    }

    handleRecurrenceByEmail()
  }, [dispatch])

  const handleSubmit = async (values: PaymentFormValues) => {
    await waitForDeviceId()

    const formattedValues = {
      ...values,
      periodicidade: periodicidadeValue,
      paymentMethod: activePaymentMethod,
      valor_doacao: activeValue,
      device_id: window.deviceId,
    }

    if (activePaymentMethod === 'card') {
      submitCreditCardPayment(formattedValues)
    }

    if (activePaymentMethod === 'pix') {
      submitPixPayment(formattedValues)
    }

    if (activePaymentMethod === 'boleto') {
      submitBoletoPayment(formattedValues)
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <DonationContentContainer>
        <CampaignTitleContainer>{campaignData?.titulo}</CampaignTitleContainer>
        <SubtitleContainer>
          Escolha o método de pagamento
          <div style={{ width: '100px', borderBottom: '6px solid #6e6e6e', marginBottom: '2rem' }} />
        </SubtitleContainer>

        <MainContainer>
          <div>
            <div>
              <PaymentMethod />

              <Periodicity blockCreateRecurrence={campaignData?.bloquear_doacao_recorrente} />

              <ValuesOption />
            </div>

            <div style={{ maxWidth: '700px', borderBottom: '2px solid #e4e4e4', marginBottom: '2rem' }} />

            <div>
              {activePaymentMethod === 'card' && <CreditCard />}

              {activePaymentMethod === 'pix' && <PixPayment />}

              {activePaymentMethod === 'boleto' && <BoletoPayment />}

              <SendButton isPending={isPendingCard || isPendingBoleto || isPendingPix} />
            </div>
          </div>

          <div className="campaign-container">
            <CampaignCard />
          </div>
        </MainContainer>
      </DonationContentContainer>
    </Form>
  )
}
