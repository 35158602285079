import { BlurProvider } from '@/context/Blur/BlurContext'
import { Suspense } from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { NotFoundPage } from '../../container/admin/components/not-found-page'
import { PermissionDenied } from '../../container/admin/components/permission-denied-page'
import BaseSpin from '../../container/admin/components/spin/BaseSpin'
import uiComponents from '../../container/admin/components/uiComponents'
import { AdminLayoutProvider } from '../../container/admin/context/AdminLayout'
import { useFetchUserMe } from '../../container/admin/hooks'
import { AdminLayout } from '../../container/admin/layouts/admin-layout'
import Logs from '../../container/admin/pages/logs/Logs'
import { useAuth } from '../../context/Auth'
import Actions from './actions'
import { AgapeRoutesAdmin } from './agape'
import Campaigns from './campaigns'
import Dashboard from './dashboard'
import { Donations } from './donations'
import UsersGroups from './groups'
import { LivesRoute } from './lives'
import { Profile } from './profile'
import Users from './users'
import { VocacionalRoutesAdmin } from './vocacional'

function Admin() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const { data: userMe } = useFetchUserMe()

  const { userAuthState } = useAuth()

  if (!userAuthState.isAuthenticated) {
    return <Redirect to="/login" />
  }

  if (userMe?.super_perfil === false) {
    history.push('/')
  }

  return (
    <AdminLayoutProvider>
      <BlurProvider>
        <AdminLayout>
          <Suspense
            fallback={
              <div className="fallback-spin">
                <BaseSpin />
              </div>
            }
          >
            <Switch>
              <Route path={`${path}/dashboard`} component={Dashboard} />
              <Route path={`${path}/users`} component={Users} />
              <Route path={`${path}/agape`} component={AgapeRoutesAdmin} />
              <Route path={`${path}/vocacional`} component={VocacionalRoutesAdmin} />
              <Route path={`${path}/logs/view-all`} component={Logs} />
              <Route path={`${path}/permission-denied`} component={PermissionDenied} />

              {/* new routes */}
              <Route path={`${path}/campaigns`} component={Campaigns} />
              <Route path={`${path}/group`} component={UsersGroups} />
              <Route path={`${path}/requests`} component={Donations} />
              <Route path={`${path}/actions`} component={Actions} />
              <Route path={`${path}/components/view-all`} component={uiComponents} />
              <Route path={`${path}/lives`} component={LivesRoute} />
              <Route path={`${path}/profile`} component={Profile} />

              {/* Page 404 */}
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </AdminLayout>
      </BlurProvider>
    </AdminLayoutProvider>
  )
}

export default Admin
