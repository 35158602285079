import BaseModal from '../../../../../modal/BaseModal'
import { DonationDetailsContent } from './components/DonationDetailsContent'
import { DonationDetails } from '../../hooks/useDonationDetailsModal'

interface DonationDetailsModalProps {
  donationDetailsModalContent: DonationDetails
  handleCloseDetailsModal: () => void
}

export function DonationDetailsModal({
  donationDetailsModalContent,
  handleCloseDetailsModal,
}: DonationDetailsModalProps) {
  return (
    <BaseModal
      centered
      closable
      title={<span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Dados da doação</span>}
      open={donationDetailsModalContent.open}
      onCancel={() => handleCloseDetailsModal()}
      footer={false}
      width={1000}
    >
      <DonationDetailsContent donationContent={donationDetailsModalContent} />
    </BaseModal>
  )
}
