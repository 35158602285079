import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export type TipoMovimentacoesHistorico = 'entrada' | 'saida'

export interface GetHistoricoMovimentacoesProps {
  filters?: {
    queryString?: string | null
    fk_estoque_agape_id?: number | null
    tipo_movimentacao?: TipoMovimentacoesHistorico | null
    data_movimentacao_inicial?: string | null
    data_movimentacao_final?: string | null
    page?: number
    per_page?: number
  }
}

interface GetHistoricoMovimentacoesResponse {
  movimentacoes: {
    data_movimentacao: string
    id: number
    item: string
    quantidade: number
    tipo_movimentacao: TipoMovimentacoesHistorico
  }[]
  page: number
  total: number
}

export function useGetHistoricoMovimentacoes({ filters }: GetHistoricoMovimentacoesProps) {
  const movimentacoes = useQuery({
    queryKey: ['HISTORICO_MOVIMENTACOES', filters],
    queryFn: async () => {
      const response = await DataService.get<GetHistoricoMovimentacoesResponse>(
        `/agape/listar-historico-movimentacoes-agape?${filters?.queryString ?? ''}`,
      )

      return response.data
    },
  })

  return movimentacoes
}
