import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalState {
  isUpdateModalOpen: boolean
}

const initialState: ModalState = {
  isUpdateModalOpen: false,
}

const signupDataUpdateModal = createSlice({
  name: 'signupDataUpdateModal',
  initialState,
  reducers: {
    openUpdateModal: (state) => {
      state.isUpdateModalOpen = true
    },
    closeUpdateModal: (state) => {
      state.isUpdateModalOpen = false
    },
  },
})

export const { openUpdateModal, closeUpdateModal } = signupDataUpdateModal.actions
export default signupDataUpdateModal.reducer 
