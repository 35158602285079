import styled from 'styled-components'
import { Content } from 'antd/lib/layout/layout'

interface StyledContentProps {
  isRootPath: boolean
}

export const StyledContent = styled(Content)<StyledContentProps>`
  flex: 1;
  overflow: auto;
  padding: ${({ isRootPath }) => (isRootPath ? '0' : '2.5rem 0.5rem')};
`
