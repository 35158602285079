import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface DonationData {
  media_diaria: string
  porcentagem_dia: string
  quant_pedidos: number
  valor_total: string
}

export function useFetchTodayDonation() {
  const todayDonation = useQuery<DonationData>({
    queryKey: ['TODAY_TOTAL_DONATIONS'],
    queryFn: async () => {
      const response = await DataService.get('/admin/dash-board/donations/total-donations-dia-atual')

      return response?.data
    },
  })

  return todayDonation
}
