import { UploadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { UploadFile } from 'antd/es/upload/interface'
import { StyledUpload } from '../Upload'
import { StyledButton } from '../Button'
import { StyledImageCrop } from '../../../admin/components/ImageCrop/BaseImageCrop.Styles'
import { StyledModal } from '../Modal'
import StyledUploadDescription from '../Upload/UploadDescription'

export type PhotoUploadData = {
  file: File
  name: string
  status: 'done'
  uid: '-1'
  url: 'string'
}[]

interface PhotoUploadProps {
  initialFileList: UploadFile<any>[]
  setImage: (fileList: UploadFile<any>[] | PhotoUploadData) => void
  numeroFotos: number
  aspect?: number
  description?: string
  validateField?: () => void
  listType?: 'text' | 'picture' | 'picture-card'
}

export function PhotoUpload({
  initialFileList,
  setImage,
  numeroFotos,
  aspect,
  description,
  validateField,
  listType = 'picture',
}: PhotoUploadProps) {
  const [previewImage, setPreviewImage] = useState({ name: '', url: '' })
  const [previewOpen, setPreviewOpen] = useState(false)
  const [hasInteracted, setHasInteracted] = useState(false)

  const runValidateField = () => {
    if (validateField) {
      validateField()
    }
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [hasInteracted])

  const handleCroppedImage = async (file: UploadFile<any>, preview = false) => {
    const dataToTransform = preview ? file.originFileObj : file
    let src = file.url || file.thumbUrl

    if (!src) {
      src = await new Promise<string>((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(dataToTransform as Blob)
        reader.onload = () => resolve(reader.result as string)
      })
    }

    return {
      uid: '-1',
      name: file.name,
      status: 'done',
      url: src,
      file,
    } as UploadFile<any>
  }

  const onPreview = async (file: UploadFile<any>) => {
    const croppedImage = await handleCroppedImage(file, true)
    const { name, url } = croppedImage
    if (url) {
      setPreviewImage({ name, url })
      setPreviewOpen(true)
    }
  }

  const handleCancel = () => setPreviewOpen(false)

  const handleRemove = () => {
    setPreviewImage({ name: '', url: '' })
    setImage([])
    setHasInteracted(true)
  }

  const handleBeforeUpload = async (file: UploadFile<any>) => {
    const croppedImage = await handleCroppedImage(file)
    setImage([croppedImage])
    setHasInteracted(true)
    return false
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [initialFileList, hasInteracted])

  const isCard = listType === 'picture-card'

  return (
    <>
      <StyledImageCrop
        modalTitle="Editar imagem"
        modalOk="Confirmar"
        modalCancel="Cancelar"
        aspect={aspect && !isCard ? aspect : 1 / 1}
        cropShape={isCard ? 'round' : 'rect'}
        quality={1}
        showGrid
        showReset
        resetText="Restaurar"
      >
        <StyledUpload
          listType={listType}
          fileList={initialFileList}
          beforeUpload={handleBeforeUpload}
          onPreview={onPreview}
          maxCount={numeroFotos}
          onRemove={handleRemove}
        >
          {initialFileList.length === 0 && (
            <>
              {isCard ? (
                <div>
                  <UploadOutlined style={{ marginRight: '0.5rem' }} />
                  Selecione
                </div>
              ) : (
                <>
                  {description && <StyledUploadDescription text={description} />}
                  <StyledButton icon={<UploadOutlined />}>Enviar Imagem</StyledButton>
                </>
              )}
            </>
          )}
        </StyledUpload>
      </StyledImageCrop>

      <StyledModal open={previewOpen} title={previewImage.name} footer={null} onCancel={handleCancel} centered>
        <img alt="Imagem" style={{ width: '100%' }} src={previewImage.url} />
      </StyledModal>
    </>
  )
}
