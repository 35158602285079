import { Skeleton } from 'antd'
import { ListTable } from './ListTable'
import { useGetInfiniteAllTodayDonations } from '../../../../../hooks/dashboards/finances/queries/useGetInfiniteAllTodayDonations'
import { ErrorMessage, LoadingContainer } from '../../../styles'

export function Content({ filters }) {
  const query = useGetInfiniteAllTodayDonations(filters)

  if (query.isPending) {
    return <LoadingContainer />
  }

  if (query.isError) return <ErrorMessage />

  return <ListTable query={query} />
}
