export const VOCACIONAL_TABS_ENUM = {
  PRE_CADASTRADOS: 'PRE_CADASTRADOS',
  PRE_APROVADOS: 'PRE_APROVADOS',
  CADASTROS: 'CADASTROS',
  FICHA_VOCACIONAL: 'FICHA_VOCACIONAL',
  RECUSADOS: 'RECUSADOS',
  DESISTENTES: 'DESISTENTES',
}

export const StatusMap = {
  pendente: 'Pendente',
  aprovado: 'Aprovado',
}

export type VocacionalInitialFiltersProps = {
  vocacionalGender: 'masculino' | 'feminino'
}
