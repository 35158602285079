import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface Benfeitor {
  deleted_at: string | null
  fk_clifor_id: number
  nome: string
  user_id: number | null
}

interface Campanha {
  descricao: string | null
  fk_campanha_id: number | null
  imagem: string | null
  titulo: string | null
}

interface GatewayPagamento {
  id: number
  nome: string
}

interface Pedido {
  anonimo: boolean
  cancelada_em: string | null
  contabilizar_doacao: boolean
  data_doacao: string
  fk_pedido_id: number
  forma_pagamento: string
  gateway_pagamento: GatewayPagamento
  order_id: string | null
  recorrencia: boolean
  recorrencia_ativa: boolean
  status_pedido: number
  valor_doacao: string
}

interface Processamento {
  codigo_referencia: string
  fk_processamento_pedido_id: number
  id_pagamento: string
  status: string
  transaction_id: string
}

export interface Doacao {
  benfeitor: Benfeitor
  campanha: Campanha
  pedido: Pedido
  processamento: Processamento
}

export interface DonationApiResponse {
  data: Doacao[]
  page: number
  pages: number
  total: number
  total_doado: string
}

export function useFetchDonations(filters): UseQueryResult<DonationApiResponse> {
  const donations = useQuery<DonationApiResponse>({
    queryKey: ['DONATIONS', filters],
    queryFn: async () => {
      const response = await DataService.get<DonationApiResponse>(
        `checkout/listar-todas-doacoes?${filters.queryString || ''}`,
      )
      return response?.data
    },
  })

  return donations
}
