import { useState } from 'react'
import BaseCard from '../../../../../components/card/BaseCard'
import { Content } from './Content'
import { FilterModal } from './FilterModal'

export function TodaySignups() {
  const [filters, setFilters] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <BaseCard padding="0px" style={{ height: '260px', overflow: "hidden" }}>
      <FilterModal filters={filters} setFilters={setFilters} isModalOpen={isModalOpen} handleToggleModal={handleToggleModal} />
      <Content filters={filters} handleToggleModal={handleToggleModal} />
    </BaseCard>
  )
}
