import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PreCadastroVocacionalEmbed } from '../../../container/benfeitor/pages/Vocacional/PreCadastro/PreCadastroEmbed';

export function VocacionalEmbedRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/pre-cadastro-masculino`} component={PreCadastroVocacionalEmbed} />
      <Route path={`${path}/pre-cadastro-feminino`} component={PreCadastroVocacionalEmbed} />
    </Switch>
  );
}
