import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts'
import moment from 'moment'
import { useState } from 'react'
import { ChartTitle, SliderChartTitle, TooltipContainer, formatChartNumber } from '../../../styles'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { ComposedCard } from '@/container/admin/components/composed-card'
import { FilterIcon } from '@/container/admin/components/FilterIcon'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function Chart({ data, filters, onFilterClick }) {
  const [barHovered, setBarHovered] = useState(false)
  const [focusBar, setFocusBar] = useState(null)

  let formattedData = []

  const isByCampaign = filters?.visualizar_por === 'campanha'

  if (!isByCampaign) {
    formattedData = data.map((item) => ({
      hora: `${item.hora}:00`,
      valor: parseFloat(item.valor),
    }))
  } else {
    const aggregatedData = {}

    data.forEach((item) => {
      const hour = `${item.hora}:00`
      const percentual = item?.percentual || 0
      const pedidos = item?.quantidade_pedidos || 0
      const campaign = item?.campanha || 'Sem campanha'
      const valorCampanha = parseFloat(item.valor_campanha) || 0

      if (!aggregatedData[hour]) {
        aggregatedData[hour] = {
          hora: hour,
          totalValor: 0,
          totalPedidos: 0,
          campaigns: {},
        }
      }

      if (valorCampanha > 0 || pedidos > 0) {
        if (!aggregatedData[hour].campaigns[campaign]) {
          aggregatedData[hour].campaigns[campaign] = {
            valorCampanha: 0,
            percentual: 0,
            pedidos: 0,
          }
        }

        aggregatedData[hour].campaigns[campaign].valorCampanha += valorCampanha
        aggregatedData[hour].campaigns[campaign].pedidos += pedidos

        aggregatedData[hour].totalValor += valorCampanha
        aggregatedData[hour].totalPedidos += pedidos
      }
    })

    Object.values(aggregatedData).forEach((hourData) => {
      const { totalValor } = hourData
      Object.keys(hourData.campaigns).forEach((campaignKey) => {
        const campaignData = hourData.campaigns[campaignKey]
        campaignData.percentual = totalValor > 0 ? (campaignData.valorCampanha / totalValor) * 100 : 0
      })
    })

    formattedData = Object.values(aggregatedData).map((hour) => ({
      ...hour,
      ...hour.campaigns,
    }))
  }

  const campaignKeys =
    filters?.visualizar_por === 'campanha'
      ? Array.from(
        new Set(
          formattedData.flatMap((item) =>
            Object.keys(item).filter((key) => key !== 'hora' && key !== 'totalValor' && key !== 'totalPedidos'),
          ),
        ),
      )
      : []

  const ByCampaignTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      const data = payload[0]?.payload
      const { totalValor } = data
      const { totalPedidos } = data

      const campaignDataArray = campaignKeys
        .map((key, index) => {
          const campaignData = data[key] || {}
          return {
            key,
            valorCampanha: campaignData.valorCampanha || 0,
            percentual: typeof campaignData.percentual === 'number' ? campaignData.percentual.toFixed(2) : '0',
            pedidos: campaignData.pedidos || 0,
            color: getColor(index),
          }
        })
        .filter(({ valorCampanha }) => valorCampanha > 0)

      const sortedCampaignData = campaignDataArray.sort((a, b) => b.valorCampanha - a.valorCampanha)

      return (
        <TooltipContainer type="finance" style={{ alignItems: 'flex-start' }}>
          <h4>Horário: {data.hora}</h4>
          <BlurDiv>
            <p>
              <strong>
                Valor: {formatToBrazilianCurrency(totalValor)} - Doações: {totalPedidos}
              </strong>
            </p>
          </BlurDiv>
          <div
            style={{
              width: '100%',
              height: '2px',
              backgroundColor: 'var(--separator-color)',
              margin: '0rem 1rem 0.8rem 0rem',
            }}
          />
          {sortedCampaignData.map(({ key, valorCampanha, percentual, pedidos, color }) => (
            <div key={key} style={{ color }}>
              {key}:{' '}
              <strong>
                {formatToBrazilianCurrency(valorCampanha)} | {percentual}% | {pedidos} doações
              </strong>
            </div>
          ))}
        </TooltipContainer>
      )
    }

    return null
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      const data = payload[0]?.payload

      return (
        <TooltipContainer type="finance">
          <div>
            Hora: <strong>{data.hora}</strong>
          </div>
          <BlurDiv>
            Valor: <strong>{formatToBrazilianCurrency(data.valor)}</strong>
          </BlurDiv>
        </TooltipContainer>
      )
    }

    return null
  }

  const handleChartTitle = () => {
    if (filters?.data_inicio) {
      const isToday = moment().isSame(filters?.data_inicio, 'day')
      return isToday ? 'de hoje' : `${moment(filters?.data_inicio).format('DD/MM/YYYY')}`
    }
    return 'de Hoje'
  }

  const colorPalette = [
    '#4CAF50', // Green
    '#2196F3', // Blue
    '#FFC107', // Amber
    '#FF9800', // Orange
    '#f05e54', // Red
    '#9C27B0', // Purple
    '#5868c0', // Indigo
  ]

  const getColor = (index) => colorPalette[index % colorPalette.length]

  return (
    <ResponsiveContainer width="100%" height={260} style={{ overflow: 'hidden' }}>
      <SliderChartTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <div className="title-and-icon-container">
              Total {handleChartTitle()}
              <FilterIcon onClick={onFilterClick} />
            </div>
            <BlurDiv className="percentage">
              <ComposedCard.Balance isPositive={false} >
                29.65%
              </ComposedCard.Balance>
            </BlurDiv>
          </div>
          <BlurDiv className="total-value">{formatToBrazilianCurrency(15412.56)}</BlurDiv>
        </div>
      </SliderChartTitle>
      {data?.length === 0 ? (
        <div style={{ height: '260px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h4 style={{ margin: '0', color: 'gray', padding: 30, textAlign: 'center' }}>
            Por enquanto não há dados a serem carregados
          </h4>
        </div>
      ) : (
        <BarChart data={formattedData} margin={{ top: 70, left: 0, right: 0, bottom: 3 }}>
          <defs>
            <linearGradient id="greenGradientType1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="var(--finance-chart-color)" />
              <stop offset="100%" stopColor="var(--finance-chart-color-fade-stop)" />
            </linearGradient>
            <linearGradient id="greenGradientOnHover" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="var(--finance-chart-color-hover)" />
              <stop offset="100%" stopColor="var(--finance-chart-color-hover-end)" />
            </linearGradient>
          </defs>

          {/* <CartesianGrid stroke="#444" vertical={false} strokeOpacity={0.6} /> */}
          <XAxis hide={true} axisLine={false} dataKey="hora" tick={false} tickLine={false} />
          <YAxis hide={true} axisLine={false} tick={false} tickLine={false} />
          <Tooltip cursor={false} content={isByCampaign ? <ByCampaignTooltip /> : <CustomTooltip />} />

          {filters?.visualizar_por === 'campanha' ? (
            campaignKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={`campaigns.${key}.valorCampanha`}
                stackId="a"
                fill={getColor(index)}
                barSize={40}
              />
            ))
          ) : (
            <Bar
              dataKey="valor"
              fill="url(#greenGradientType1)"
              radius={[8, 8, 0, 0]}
              barSize={40}
              onMouseEnter={() => setBarHovered(true)}
              onMouseLeave={() => setBarHovered(false)}
            >
              {formattedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={focusBar === index ? 'url(#greenGradientOnHover)' : 'url(#greenGradientType1)'}
                  onMouseEnter={() => setFocusBar(index)}
                  onMouseLeave={() => setFocusBar(null)}
                />
              ))}
            </Bar>
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  )
}
