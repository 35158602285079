import styled from 'styled-components'
import { Switch as AntdSwitch } from 'antd'

export const Switch = styled(AntdSwitch)`
  .ant-switch-handle::before {
    background-color: var(--background-color); /* Cor do ícone do Switch */
  }

  &.ant-switch[aria-checked='true'] {
    background-color: var(--text-main-color);
  }

  &.ant-switch[aria-checked='false'] {
    background-color: var(--unchecked-switch-color);
  }

  .ant-switch-loading-icon {
    svg {
      color: var(--text-main-color);
    }
  }

  .ant-switch-inner {
    color: var(--background-color);
  }
`
