import { Skeleton } from 'antd'
import { Chart } from './Chart'
import { useGetDonationsCountPerHour } from '../../../../../hooks/dashboards/finances/queries/useGetDonationsCountPerHour'

export const Content = ({ filters, setCampaignsOptions }) => {
  const { data: usersByHours, isPending, isError } = useGetDonationsCountPerHour(filters)

  if (isPending) {
    return (
      <div style={{ height: '25rem', display: 'grid', placeItems: 'center', padding: '1rem' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (isError) {
    return (
      <h4 style={{ color: 'red', height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )
  }
  if (usersByHours?.campaigns) {
    setCampaignsOptions(usersByHours?.campaigns)
  }

  return <Chart data={usersByHours?.data} filters={filters} />
}
