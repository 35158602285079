import logosImage from '../../images/logos.svg'
import { useEffect, useState } from 'react'
import { Container, ContentWrapper, ImgStyled, Wrapper } from './styles'
import { Result } from 'antd'
import numsBottom from '../../images/nums_bottom.png'
import { UserResultFlow } from './components/user-result-flow'
import { useFindUserByDocumentNumber } from '../../../admin/hooks/users/Queries/useFindUserByDocumentNumber'
import { CheckUserDocumentNumber } from './components/check-user-document-number'
import { useAuth } from '../../../../context/Auth'

export enum DocumentoIdentidade {
  CPF = 'cpf',
  IDENTIDADE_ESTRANGEIRA = 'identidade_estrangeira',
}

export interface Documento {
  tipo_documento: `${DocumentoIdentidade}`
  numero_documento: string
}

export function ValidateUser() {
  const { logout } = useAuth()
  const [isForeigner, setIsForeigner] = useState<boolean>(false)
  const [document, setDocument] = useState<Documento>({
    tipo_documento: DocumentoIdentidade.CPF,
    numero_documento: '',
  })

  const { data: userData, isLoading: isPending, isError } = useFindUserByDocumentNumber(document)

  function handleSearchDocumentNumber(document: Documento) {
    setDocument(document)
  }

  useEffect(() => {
    logout()
  }, [logout])

  if (isError) {
    return (
      <Result
        status={500}
        title="Ocorreu algum erro"
        subTitle={'Estamos trabalhando nisso, tente novamente mais tarde!'}
        style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: '10rem' }}
      />
    )
  }

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <ImgStyled src={logosImage} alt="Logo" />
          {!userData ? (
            <CheckUserDocumentNumber
              handleSearchDocumentNumber={handleSearchDocumentNumber}
              isPending={isPending}
              isForeigner={isForeigner}
              setIsForeigner={setIsForeigner}
            />
          ) : (
            <UserResultFlow userData={userData} document={document} isForeigner={isForeigner} />
          )}
        </ContentWrapper>
      </Wrapper>
      {!userData && <img src={numsBottom} alt="Nums" className="nums-bottom" />}
    </Container>
  )
}
