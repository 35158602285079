import { Form, Spin, Steps, UploadFile } from 'antd'
import { ModalTitle, StyledDivider, UpdateParagraphText } from '../styles'
import { formatDateToUs } from '../../../../../../../../utility/utility'
import { StyledButton } from '../../../../../../../../container/benfeitor/components/Button'
import { useFetchUserMe, useFetchUserMeAddress } from '../../../../../../../admin/hooks'

import { StyledForm } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../redux/rootReducers'
import { closeUpdateModal, openUpdateModal } from '../updateUserDataModalSlice'
import { loadIdentificationFormValues } from '../../../../../../../../container/benfeitor/pages/Campaign/CampaignIdentification/utils'
import {
  UpdateUserReqBody,
  useUpdateUser,
} from '../../../../../../../../container/benfeitor/hooks/user/mutations/updateUser'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { AddressForm } from '../../../../../../../../container/benfeitor/pages/Campaign/CampaignIdentification/components/AddressForm'
import { useMultistepForm } from '../../../../../../../../container/benfeitor/pages/Carteira/Cadastro/hooks/useMultistepForm'
import { StepOneUpdateModal } from './StepOneUpdateModal'
import { AcceptTerms } from '../../AcceptTerms'
import { StyledModal } from '../../../../../../components/Modal'

interface UpdateRegistrationFormInput {
  data_nascimento: string
  nome: string
  telefone: string
  avatar: File
  sexo: 'masculino' | 'feminino'
  cpf: string
  cnpj: string
  identidade_estrangeira: string
  email: string
  nome_social: string
  numero_documento: number

  detalhe_estrangeiro: string

  pais: string
  cep: string
  estado: string
  cidade: string
  bairro: string
  rua: string
  numero: string
  complemento: string
}

export function UpdateRegistration() {
  const { data: userMe } = useFetchUserMe()
  const { data: addressData } = useFetchUserMeAddress()

  const { isUpdateModalOpen } = useSelector((state: RootState) => state.updateSignupModal)
  const { mutateAsync: updateUser, isPending } = useUpdateUser()

  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [hasFormBeenFetchedFromApi, setHasFormBeenFetchedFromApi] = useState<boolean>(false)
  const [file, setFile] = useState<UploadFile[]>([])

  const steps = [
    {
      title: 'Informações Pessoais',
      content: (
        <StepOneUpdateModal
          form={form}
          hasFormBeenFetchedFromApi={hasFormBeenFetchedFromApi}
          file={file}
          setFile={setFile}
        />
      ),
    },
    {
      title: 'Endereço',
      content: <AddressForm form={form} hasFormBeenFetchedFromApi showBrazil={userMe?.pais === 'brasil'} />,
    },
  ]

  const { currentStep, next, prev, isFirstStep, isLastStep } = useMultistepForm(steps, form)

  const handleFinish = async () => {
    const formData: UpdateRegistrationFormInput = form.getFieldsValue([
      'data_nascimento',
      'nome',
      'telefone',
      'avatar',
      'sexo',
      'cpf',
      'cnpj',
      'email',
      'nome_social',
      'numero_documento',
      'identidade_estrangeira',
      'detalhe_estrangeiro',

      'pais',
      'cep',
      'estado',
      'cidade',
      'bairro',
      'rua',
      'numero',
      'complemento',
    ])

    const formattedValues: UpdateUserReqBody = {
      usuario: {
        data_nascimento: formatDateToUs(formData.data_nascimento),
        nome: formData.nome,
        telefone: formData.telefone,
        sexo: formData.sexo,
        nome_social: formData.nome_social,
      },
      endereco:
        userMe?.pais === 'brasil'
          ? {
              bairro: formData.bairro,
              cep: formData.cep,
              cidade: formData.cidade,
              complemento: formData.complemento,
              estado: formData.estado,
              numero: formData.numero,
              rua: formData.rua,
            }
          : {
              detalhe_estrangeiro: formData.detalhe_estrangeiro,
            },
    }

    const imageFile: File = formData.avatar

    try {
      await updateUser({ formattedValues, imageFile })
      dispatch(closeUpdateModal())
    } catch (error) {
      console.log('error while updating user', error)
      throw error
    }
  }

  // open and load modal data
  useEffect(() => {
    if (userMe && (userMe?.obriga_atualizar_cadastro || userMe?.obriga_atualizar_endereco)) {
      dispatch(openUpdateModal())
      if (addressData && userMe && form && !hasFormBeenFetchedFromApi) {
        loadIdentificationFormValues({ form, userMe, addressData })
        setHasFormBeenFetchedFromApi(true)
      }
    }
  }, [userMe, addressData, form, hasFormBeenFetchedFromApi])

  return (
    <StyledModal
      footer={false}
      open={isUpdateModalOpen}
      onCancel={() => {
        dispatch(closeUpdateModal())
      }}
      onOk={() => {}}
      closable={true}
    >
      <StyledForm>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <ModalTitle>Atualização de dados cadastrais</ModalTitle>
          <StyledDivider plain />
          <UpdateParagraphText>
            Estamos de plataforma nova! Para garantir que possamos continuar conectados, pedimos gentilmente que
            atualize seu cadastro. Agradecemos sua colaboração e que Deus abençoe sua jornada.
          </UpdateParagraphText>
          <StyledDivider plain />

          <Steps current={currentStep}>
            {steps.map((step, index) => (
              <Steps.Step key={index} title={step.title} />
            ))}
          </Steps>

          <div style={{ marginTop: 24 }}>{steps[currentStep].content}</div>

          <div className="auth-form-action">
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error('É necessário aceitar nossos Termos de Serviços e Política de Privacidade'),
                        ),
                },
              ]}
            >
              <AcceptTerms />
            </Form.Item>
          </div>

          <div style={{ marginTop: 24, display: 'flex' }}>
            {!isFirstStep && (
              <StyledButton type="ghost" style={{ marginRight: 8 }} onClick={prev}>
                Voltar
              </StyledButton>
            )}
            {!isLastStep && <StyledButton onClick={next}>Próximo</StyledButton>}
            {isLastStep && (
              <StyledButton style={{ width: '100%' }} htmlType="submit" disabled={isPending}>
                Atualizar Cadastro {isPending && <Spin style={{ color: 'white', marginLeft: '1rem' }} />}
              </StyledButton>
            )}
          </div>
        </Form>
      </StyledForm>
    </StyledModal>
  )
}
