import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetCurrentMonthDonations(filters) {
  return useQuery({
    queryKey: ['CURRENT_MONTH_DONATIONS_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const query = await DataService.get(
        `admin/dash-board/donations/donations-actual-month?${filters?.queryString || ''}`,
      )
      return query?.data
    },
    keepPreviousData: true,
  })
}
