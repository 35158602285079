import { Col, Row, TablePaginationConfig } from 'antd'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { ListagemItensRecebimento } from './ListagemItensRecebimento'
import { GetBeneficiadosProps, useGetBeneficiados } from '../../../../hooks/agape/queries/useGetBeneficiados'
import { EnviarRecibosModal } from '../../Doacao/Recibos/EnviarRecibosModal'
import { handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import { BeneficiadosFilters } from './filters'
import { useState } from 'react'
import { VisualizarReciboModal } from '../../components/VisualizarRecibo/VisualizarReciboModal'
import { useDownloadCsvBeneficiados } from '../../../../hooks/agape/mutations/useDownloadCsvBeneficiados'
import BaseButton from '../../../../components/button/BaseButton'
import { FileExcelOutlined } from '@ant-design/icons'

interface BeneficiadosTabelaProps {
  idCicloDoacao: number
}

export function BeneficiadosTabela({ idCicloDoacao }: BeneficiadosTabelaProps) {
  const [filters, setFilters] = useState<GetBeneficiadosProps['filters']>({})

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { mutateAsync: downloadCsvBeneficiados, isPending: isPendingDownloadCsvBeneficiados } =
    useDownloadCsvBeneficiados()

  const {
    data: beneficiados,
    isLoading: isLoadingBeneficiados,
    isError: isErrorBeneficiados,
  } = useGetBeneficiados({ idCicloDoacao, filters })

  const dataSource = beneficiados?.beneficiarios?.map((item) => {
    const { fk_doacao_agape_id, nome_familia, data_hora_doacao, recibos } = item

    return {
      key: fk_doacao_agape_id,
      familia: nome_familia,
      data_hora: data_hora_doacao,
      recibo: (
        <>
          {recibos.length > 0 ? (
            <VisualizarReciboModal recibos={recibos} />
          ) : (
            <EnviarRecibosModal idDoacao={fk_doacao_agape_id} />
          )}
        </>
      ),
    }
  })

  const columns = [
    {
      title: 'Família',
      dataIndex: 'familia',
      key: 'familia',
      filterDropdown: () => <BeneficiadosFilters.Cpf value={filters?.cpf} handleInputChange={handleInputChange} />,
      filteredValue: filters?.cpf && [filters.cpf],
    },
    {
      title: 'Data e hora',
      dataIndex: 'data_hora',
      key: 'data_hora',
      filterDropdown: () => (
        <BeneficiadosFilters.DataRecebimento
          value={{ data_inicial: filters?.data_final ?? '', data_final: filters?.data_final ?? '' }}
          handleInputChange={handleInputChange}
        />
      ),
      filteredValue: filters?.data_final && filters.data_final && [filters.data_inicial, filters.data_final],
    },
    {
      title: 'Recibo',
      dataIndex: 'recibo',
      key: 'recibo',
      filterDropdown: () => (
        <BeneficiadosFilters.Recibo value={filters?.recibos} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.recibos && [filters.recibos],
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingBeneficiados}
      isError={isErrorBeneficiados}
      pagination={{
        current: beneficiados?.page,
        total: beneficiados?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
      expandable={{
        expandedRowRender: (record) => (
          <Col xs={24}>
            <Row justify="center">
              <ListagemItensRecebimento fk_doacao_agape_id={record.key} />
            </Row>
          </Col>
        ),
      }}
      footer={() => (
        <BaseButton
          disabled={Array.isArray(dataSource) ? dataSource.length === 0 : true}
          loading={isPendingDownloadCsvBeneficiados}
          severity="success"
          icon={<FileExcelOutlined />}
          onClick={() => downloadCsvBeneficiados({ fk_instancia_acao_agape_id: idCicloDoacao })}
        >
          Exportar
        </BaseButton>
      )}
    />
  )
}
