import { http, HttpResponse } from 'msw'
import { UpdateUserProps } from '../../container/benfeitor/hooks/api/user/useUpdateUserProfile'

export const usersUpdateMock = http.put<never, UpdateUserProps, { msg: string }>(
  '/users/update',
  async ({ request }) => {
    const formData = await request.formData()

    const rawData = formData.get('data') as string
    const parsedData = JSON.parse(rawData)
    const usuario = parsedData.usuario

    if (
      usuario.numero_documento === '085.868.970-78' &&
      usuario.email === 'usuario-benfeitor@gmail.com' &&
      usuario.nome === 'Usuário Benfeitor'
    ) {
      return HttpResponse.json({
        msg: 'Usuário atualizado com sucesso.',
      })
    }

    if (
      usuario.numero_documento === '6436317654' &&
      usuario.email === 'usuario-benfeitor-estrangeiro@gmail.com' &&
      usuario.nome === 'Usuário Estrangeiro'
    ) {
      return HttpResponse.json({
        msg: 'Usuário atualizado com sucesso.',
      })
    }
  },
)
