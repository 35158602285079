import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetDonationsPerType(filters) {
  return useQuery({
    queryKey: ['DONATIONS_PER_TYPE_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const response = await DataService.get(
        `admin/dash-board/donations/donations-per-type?${filters?.queryString || ''}`,
      )
      return response?.data
    },
  })
}
