import styled from 'styled-components'
import angelBlue from '../../../../images/angel-blue.png'

export const SectionWhyBecomeMemberContainer = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
  }

  position: relative;
`

export const AngelBg = styled.div`
  background-image: url(${angelBlue});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 414px;
  display: block;
  z-index: 1;

  mask-image: linear-gradient(to bottom, transparent, black 20%, black 80%, transparent);
  -webkit-mask-image: linear-gradient(to bottom, transparent, black 20%, black 80%, transparent);

  @media (min-width: 800px) {
    -webkit-mask-image: linear-gradient(to bottom, transparent, black 30%, black 80%, transparent);
    mask-image: linear-gradient(to right, transparent, black 30%, black 70%, transparent);
    width: 70%;
    height: 700px;
  }

  @media (min-width: 1920px) {
    -webkit-mask-image: linear-gradient(to bottom, transparent, black 30%, black 80%, transparent);
    mask-image: linear-gradient(to bottom, transparent, black 30%, black 70%, transparent);
    width: 70%;
    height: 700px;
  }
`
export const SectionWhyBecomeMemberContentWrapper = styled.div`
  overflow: visible;
  @media (min-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transform: translateX(-10%);
  }

  @media (min-width: 1600px) {
    transform: translateX(-20%);
  }
`

export const WhyBecomeMemberTitle = styled.div`
  background: linear-gradient(20deg, #c98f00 50%, #ffe198 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 28px;
  font-family: 'DM Serif Display', serif;
  line-height: 115%;

  z-index: 10;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media (min-width: 800px) {
    max-width: 700px;
    font-size: 36px;
    background-color: red;
    text-align: left;
  }

  @media (min-width: 1200px) {
    max-width: 700px;
    font-size: 48px;
  }
`

export const DescriptionParagraphs = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 1rem;
  color: #ffffff;
  max-width: 800px;
  padding: 0 2rem;
  font-weight: 400;
  margin: 0 auto;

  strong {
    font-weight: bold;
  }

  .last-p-desktop {
    width: 100%;
    position: absolute;
    left: -75%;
    bottom: 5%;
    text-align: center;
  }

  .icon-container {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0rem;
    }

    .icon-wrapper {
      min-width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .last-mobile {
    text-align: center;
  }

  @media (min-width: 1100px) {
    .last-mobile {
      display: none;
    }
  }
`

export const LastParagraphDesktop = styled.p`
  @media (max-width: 1100px) {
    display: none;
  }

  position: absolute;
  z-index: 999;
  bottom: -15%;

  left: 50%;
  transform: translateX(-50%);

  font-size: 20px;
  line-height: 38px;
  font-weight: 400;
  color: white;
  text-align: center;
`
