import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { PaymentFormValues } from '.';
import { usePaymentMethod } from './context';
import { DataService } from '../../../../../lib/dataService';

interface CreditCardPaymentReqBody {
  validade_cartao_mes: string;
  validade_cartao_ano: string;
  numero_cartao: string;
  codigo_seguranca_cartao: string;
  nome_titular_cartao: string;
  cpf_cnpj_titular_cartao: string;
  valor_doacao: number;
  fk_campanha_id: number;
  device_id: string;
}

export function useSubmitCreditCardPayment() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const history = useHistory();

  return useMutation({
    mutationFn: async (values: PaymentFormValues) => {
      const [validade_cartao_mes = '', validade_cartao_ano = ''] = values?.expiry?.split('/') ?? [];

      const cpfCnpjCleaned = values.cpf_cnpj.replace(/\D/g, '');

      const paymentData: CreditCardPaymentReqBody = {
        validade_cartao_mes,
        validade_cartao_ano,
        numero_cartao: values.number!,
        codigo_seguranca_cartao: values.cvc!,
        nome_titular_cartao: values.name,
        cpf_cnpj_titular_cartao: cpfCnpjCleaned,
        valor_doacao: values.valor_doacao,
        fk_campanha_id: parseInt(campaignId),
        device_id: values.device_id,
      };

      // periodicidade: 1 -> Única | 2 -> Recorrente
      if (values.periodicidade === 1) {
        const response = await DataService.post('/checkout/payment/credit-card', paymentData);
        return response;
      }

      const response = await DataService.post('checkout/payment/credit-card/recurrence', paymentData);
      return response;
    },
    onSuccess: () => {
      notification.success({
        message: 'Pagamento realizado com sucesso!',
        description: 'Seu pagamento foi processado com sucesso.',
      });
      history.push(`benfeitor/campanha/${campaignId}/doacao/success`);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error || 'Ocorreu um erro ao processar o pagamento. Tente novamente.';

      notification.error({
        message: 'Erro no pagamento',
        description: errorMessage,
      });
    },
  });
}

interface BoletoReqBody {
  periodicidade: number;
  valor_doacao: number;
  fk_campanha_id: number;
}

export const useSubmitBoletoPayment = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { dispatch } = usePaymentMethod();

  return useMutation({
    mutationFn: async (values: PaymentFormValues) => {
      const boletoPaymentData: BoletoReqBody = {
        periodicidade: values.periodicidade,
        valor_doacao: values.valor_doacao,
        fk_campanha_id: parseInt(campaignId),
      };

      const response = await DataService.post('/checkout/payment/invoice', boletoPaymentData);
      return response;
    },
    onSuccess: (response: any) => {
      dispatch({ type: 'SET_BOLETO_DATA', payload: response?.data });

      notification.success({
        message: 'Pagamento gerado com sucesso!',
        description: 'Seu pagamento foi processado com sucesso.',
      });
    },
    onError: (error: any) => {
      console.log(error);
      const errorMessage = error?.response?.data?.error || 'Ocorreu um erro ao processar o pagamento. Tente novamente.';

      notification.error({
        message: 'Erro no pagamento',
        description: errorMessage,
      });
    },
  });
};

interface PixReqBody {
  periodicidade: number;
  valor_doacao: number;
  fk_campanha_id: number;
  device_id: string;
}

export const useSubmitPixPayment = () => {
  const { dispatch } = usePaymentMethod();
  const { campaignId } = useParams<{ campaignId: string }>();

  return useMutation({
    mutationFn: async (values: { periodicidade: 1 | 2; valor_doacao: number; device_id: string }) => {
      const pixPaymentData: PixReqBody = {
        periodicidade: values.periodicidade,
        valor_doacao: values.valor_doacao,
        fk_campanha_id: parseInt(campaignId),
        device_id: values.device_id,
      };

      const response = await DataService.post('/checkout/payment/pix', pixPaymentData);
      return response;
    },
    onSuccess: (response) => {
      dispatch({
        type: 'SET_PIX_QR_CODE',
        payload: {
          msg: response?.data?.msg,
          pix_qrcode: response?.data?.pix_qrcode,
          qr_code_base64: response?.data?.qr_code_base64,
        },
      });

      notification.success({
        message: 'Pagamento gerado com sucesso!',
        description: 'Seu pagamento foi processado com sucesso.',
      });
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error || 'Ocorreu um erro ao processar o pagamento. Tente novamente.';

      notification.error({
        message: 'Erro no pagamento',
        description: errorMessage,
      });
    },
  });
};
