import { useInfiniteQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface FetchDonorsRankingProps {
  filters?: {
    queryString?: string
    page?: number
    per_page?: number
    tipo_doacao?: 'avulsa' | 'recorrente'
    fk_campanhas_ids?: string[]
    quantidade_meses?: number
  }
}

interface FetchDonorsRankingResponse {
  doadores: {
    benfeitor: string
    fk_usuario_id: number
    quantidade_doacoes: number
    valor_total_doacoes: number
  }[]
  page: number
  pages: number
  total: number
}

export function useFetchDonorsRanking({ filters }: FetchDonorsRankingProps) {
  const donorsRanking = useInfiniteQuery({
    queryKey: ['DONORS_RANKING', filters],
    queryFn: async ({ pageParam = 1 }) => {
      // Removendo "page" da queryString para usar page da pageParam
      const filteredQueryString = filters?.queryString?.replace(/(&|\?)page=\d+/g, '') ?? ''
      const separator = filteredQueryString ? '&' : ''

      const response = await DataService.get<FetchDonorsRankingResponse>(
        `/checkout/listar-ranking-doadores?page=${pageParam}${separator}${filteredQueryString}`,
      )
      return response?.data
    },

    getNextPageParam: (lastPage) => {
      return lastPage.page < lastPage.pages ? lastPage.page + 1 : null
    },

    initialPageParam: 1,
  })

  return donorsRanking
}
