import { AdminMenuAgape } from '@/static/icons/components/AdminMenuAgape/AdminMenuAgape'
import { AdminMenuCadastros } from '@/static/icons/components/AdminMenuCadastros/AdminMenuCadastros'
import { AdminMenuCampanhas } from '@/static/icons/components/AdminMenuCampanhas/AdminMenuCampanhas'
import { AdminMenuConfig } from '@/static/icons/components/AdminMenuConfig/AdminMenuConfig'
import { AdminMenuDashboard } from '@/static/icons/components/AdminMenuDashboard/AdminMenuDashboard'
import { AdminMenuDoacoes } from '@/static/icons/components/AdminMenuDoacoes/AdminMenuDoacoes'
import { AdminMenuSair } from '@/static/icons/components/AdminMenuSair/AdminMenuSair'
import { AdminMenuVocacional } from '@/static/icons/components/AdminMenuVocacional/AdminMenuVocacional'
import { NavLink } from 'react-router-dom'
import { useFetchUserMe } from '../../hooks'
import { useGetPermissions } from '../../hooks/users/Queries/useGetSelfPermissions'
import { MENU_ITEMS_KEYS } from './menuItemsKey'
import { SignOutButton } from './StandardView/SignOutButton'

export const getMenuItems = () => {
  const { data: userMe } = useFetchUserMe()

  const { data: { permissoes } = {} } = useGetPermissions()

  const acessarDashboardUsuarios = permissoes?.dash_board_users?.acessar === 1
  const acessarDashboardDoacoes = permissoes?.dash_board_donations?.acessar === 1
  const acessarDashboardMensagens = /*permissoes?.dash_board_messages?.acessar === 1*/ true

  const acessarCampanha = permissoes?.campanha?.acessar === 1
  const acessarCriarCampanha = permissoes?.campanha?.criar === 1

  const acessarUsuarios = permissoes?.usuario?.acessar === 1
  const acessarGeneraisMarechais = permissoes?.general?.acessar === 1

  const acessarAgape =
    permissoes?.acao_doacao_agape?.acessar === 1 ||
    permissoes?.doacao_agape?.acessar === 1 ||
    permissoes?.estoque_agape?.acessar === 1 ||
    permissoes?.familia_agape?.acessar === 1

  const acessarVocacional = permissoes?.vocacional?.acessar === 1

  const acessarDoacoes = permissoes?.doacoes?.acessar === 1

  const acessarPerfis = permissoes?.perfil?.acessar === 1 && userMe?.fk_perfil_id !== 11 // Perfil Administrador Ágape não pode ver a tela de perfis e permissões
  const acessarCriarPerfis = permissoes?.perfil?.criar === 1 && userMe?.fk_perfil_id !== 11 // Perfil Administrador Ágape não pode ver a tela de perfis e permissões

  const acessarLogs = permissoes?.logs?.acessar === 1

  return [
    (acessarDashboardUsuarios || acessarDashboardDoacoes || acessarDashboardMensagens) && {
      key: MENU_ITEMS_KEYS.DASHBOARDS,
      label: 'Dashboard',
      icon: <AdminMenuDashboard />,
      children: [
        acessarDashboardUsuarios && {
          key: MENU_ITEMS_KEYS.DASHBOARDS_CADASTROS,
          label: <NavLink to="/admin/dashboard/cadastros"> - Cadastros</NavLink>,
        },
        acessarDashboardDoacoes && {
          key: MENU_ITEMS_KEYS.DASHBOARDS_DOACOES,
          label: <NavLink to="/admin/dashboard/financeiro"> - Doações</NavLink>,
        },
        acessarDashboardMensagens && {
          key: MENU_ITEMS_KEYS.DASHBOARDS_MENSAGENS,
          label: <NavLink to="/admin/dashboard/mensagens"> - Mensagens</NavLink>,
        },
      ].filter(Boolean),
    },
    (acessarCampanha || acessarCriarCampanha) && {
      key: MENU_ITEMS_KEYS.CAMPANHAS_ACOES,
      label: 'Campanhas',
      icon: <AdminMenuCampanhas />,
      children: [
        acessarCampanha && {
          key: MENU_ITEMS_KEYS.CAMPANHAS_LISTA,
          label: <NavLink to="/admin/campaigns/view-all"> - Lista de Campanhas</NavLink>,
        },
        acessarCriarCampanha && {
          key: MENU_ITEMS_KEYS.CAMPANHAS_NOVA,
          label: <NavLink to="/admin/campaigns/campaigns-add"> - Nova Campanha</NavLink>,
        },
        acessarCampanha && {
          key: MENU_ITEMS_KEYS.ACOES_LISTA,
          label: <NavLink to="/admin/actions/view-all"> - Lista de Ações</NavLink>,
        },
        acessarCriarCampanha && {
          key: MENU_ITEMS_KEYS.ACOES_NOVA,
          label: <NavLink to="/admin/actions/actions-add"> - Nova Ação</NavLink>,
        },
      ].filter(Boolean),
    },
    acessarDoacoes && {
      key: MENU_ITEMS_KEYS.FINANCEIRO,
      label: 'Doações',
      icon: <AdminMenuDoacoes />,
      children: [
        {
          key: MENU_ITEMS_KEYS.FINANCEIRO_LISTA_DOACOES,
          label: <NavLink to="/admin/requests/view-all"> - Lista de Doações</NavLink>,
        },
        {
          key: MENU_ITEMS_KEYS.FINANCEIRO_PRINCIPAIS_DOADORES,
          label: <NavLink to="/admin/requests/view-donor-classification"> - Principais doadores</NavLink>,
        },
        {
          key: MENU_ITEMS_KEYS.FINANCEIRO_DOACOES_RECORRENTES,
          label: <NavLink to="/admin/requests/view-all-recurrence"> - Doações Recorrentes</NavLink>,
        },
        {
          key: MENU_ITEMS_KEYS.FINANCEIRO_DOACOES_DESCONSIDERADAS,
          label: <NavLink to="/admin/requests/view-desconsidered"> - Doações desconsideradas</NavLink>,
        },
      ],
    },
    (acessarUsuarios || acessarGeneraisMarechais) && {
      key: MENU_ITEMS_KEYS.USUARIOS,
      label: 'Cadastros',
      icon: <AdminMenuCadastros />,
      children: [
        acessarUsuarios && {
          key: MENU_ITEMS_KEYS.USUARIOS_LISTA,
          label: <NavLink to="/admin/users/view-all"> - Lista de Usuários</NavLink>,
        },
        acessarUsuarios && {
          key: MENU_ITEMS_KEYS.USUARIOS_LEADS,
          label: <NavLink to="/admin/users/imported"> - Leads</NavLink>,
        },
        acessarGeneraisMarechais && {
          key: MENU_ITEMS_KEYS.USUARIOS_GENERAIS_MARECHAIS,
          label: <NavLink to="/admin/group/generals/view-all/"> - Generais e Marechais</NavLink>,
        },
      ].filter(Boolean),
    },
    acessarAgape && {
      key: MENU_ITEMS_KEYS.AGAPE,
      label: 'Ágape',
      icon: <AdminMenuAgape />,
      children: [
        {
          key: MENU_ITEMS_KEYS.AGAPE_SOCIAL,
          label: <NavLink to="/admin/agape/assistencia/familia"> - Assistência Social</NavLink>,
        },
      ],
    },
    true && {
      key: MENU_ITEMS_KEYS.VOCACIONAL,
      label: 'Vocacional',
      icon: <AdminMenuVocacional />,
      children: [
        {
          key: MENU_ITEMS_KEYS.VOCACIONAL_MASCULINO,
          label: <NavLink to="/admin/vocacional/masculino"> - Vocacional Masculino</NavLink>,
        },
        {
          key: MENU_ITEMS_KEYS.VOCACIONAL_FEMININO,
          label: <NavLink to="/admin/vocacional/feminino"> - Vocacional Feminino</NavLink>,
        },
      ],
    },

    (acessarPerfis || acessarCriarPerfis || acessarLogs) && {
      key: MENU_ITEMS_KEYS.ACESSOS_SISTEMA,
      label: 'Configuração',
      icon: <AdminMenuConfig />,
      children: [
        (acessarPerfis || acessarCriarPerfis) && {
          key: MENU_ITEMS_KEYS.ACESSOS_SISTEMA_PERMISSOES,
          label: <NavLink to="/admin/users/permissions"> - Perfis e Permissões</NavLink>,
        },
        acessarLogs && {
          key: MENU_ITEMS_KEYS.ACESSOS_SISTEMA_LOGS,
          label: <NavLink to="/admin/logs/view-all"> - Logs</NavLink>,
        },
      ].filter(Boolean),
    },
    {
      key: 'SIGNOUT',
      label: <SignOutButton />,
      icon: <AdminMenuSair />,
    },
  ].filter(Boolean)
}
