import { useEffect, useState } from 'react'
import { Row } from 'antd'
import BaseCard from '../../../../components/card/BaseCard'
import BaseSwitch from '../../../../components/switch/BaseSwitch'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useAtualizarPermissaoVoluntarios } from '../../../../hooks/agape/mutations/useAtualizarPermissaoVoluntarios'
import { useObterPermissaoVoluntario } from '../../../../hooks/agape/queries/useObterPermissaoVoluntario'

export function SwitchGestao() {
  const { data: permissaoVoluntario, isLoading: isLoadingPermissao } = useObterPermissaoVoluntario()
  const { mutateAsync: atualizarPermissao, isPending: isLoadingAtualizarPermissao } = useAtualizarPermissaoVoluntarios()

  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if (permissaoVoluntario?.status !== undefined) {
      setChecked(permissaoVoluntario.status)
    }
  }, [permissaoVoluntario])

  async function handleAtualizarPermissao() {
    try {
      await atualizarPermissao()
      setChecked((prev) => !prev) // Alteração do switch em caso de mutation bem sucedida
    } catch (error) {}
  }

  return (
    <BaseCard>
      <Row justify={{ xs: 'center', sm: 'space-between' }} style={{ alignItems: 'center', minHeight: 48, gap: '1rem' }}>
        <div>
          <h3 style={{ margin: 0 }}>Permitir acesso aos voluntários</h3>
        </div>

        <div>
          <BaseSwitch
            checked={checked}
            onClick={handleAtualizarPermissao}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            loading={isLoadingAtualizarPermissao || isLoadingPermissao}
          />
        </div>
      </Row>
    </BaseCard>
  )
}
