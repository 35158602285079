import { Camera, Check, IdentificationBadge, List, MagnifyingGlass } from '@phosphor-icons/react'

export const CadastrarDoacaoSteps = [
  {
    title: 'Buscar responsável',
    icon: <MagnifyingGlass />,
  },
  {
    title: 'Confirmação do solicitante',
    icon: <IdentificationBadge />,
  },
  {
    title: 'Selecione os itens',
    icon: <List />,
  },
  {
    title: 'Envie o(s) recibo(s)',
    icon: <Camera />,
  },
  {
    title: 'Finalizado',
    icon: <Check />,
  },
]
