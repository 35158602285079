export const AlistamentoSmTheme = {
  colors: {
    white: "#fbf9f3",
    beige: "#efe5d8",
    gold: "#C98F00",
    lightGold: "#FFE198",
    darkGray: "#1E1E1E",
    green: "#19C78A",
    navy: "#121924",
    blueGray: "#172743",
    darkBlue: "#1A3F80",
  },
  fontSizes: {
    small: "10px",
    extraSmall: "14px",
    base: "16px",
    medium: "18px",
    large: "20px",
    xLarge: "24px",
    xxLarge: "28px",
    heading1: "34px",
    heading2: "36px",
    heading3: "42px",
    heading4: "46px",
    heading5: "48px",
    heading6: "56px",
    display: "64px",
    huge: "80px",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  fontFamily: {
    primary: "Inter",
    secondary: "DM Serif Display",
  },
} as const;
