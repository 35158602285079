import React, { useEffect, useState } from 'react'
import { Row, Col, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from '../Style'
import { setBackgroundImage, setBannerImage, setCapaImage, setFormField } from '../../../../../redux/campaign/actions'
import BaseCard from '../../../components/card/BaseCard'
import BaseForm from '../../../components/forms/BaseForm/BaseForm'
import BaseRadio from '../../../components/radio/BaseRadio'
import { BaseSelect, Option } from '../../../components/select/BaseSelect'
import { BaseInput } from '../../../components/inputs/BaseInput/BaseInput'
import BaseDatePicker from '../../../components/datepicker/BaseDatePicker'
import { BaseUploadPhoto } from '../../../components/upload/BaseUploadPhoto'
import { useParams } from 'react-router-dom'

const Step1 = ({ form, formData, handleInputChange }) => {
  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, form])

  const { id: campaignId } = useParams()

  console.log(formData)
  return (
    <Wrapper>
      <BaseForm form={form} layout="vertical">
        <Row gutter={[24, 16]}>
          <Col xs={24} md={4}>
            <Space direction="vertical" size="middle">
              <BaseForm.Item
                name="status"
                label="Campanha ativa"
                rules={[{ required: true, message: 'Por favor, selecione um status' }]}
              >
                <BaseRadio.Group value={formData.status} onChange={(e) => handleInputChange('status', e.target.value)}>
                  <BaseRadio.Button value={1}>Sim</BaseRadio.Button>
                  <BaseRadio.Button value={0}>Não</BaseRadio.Button>
                </BaseRadio.Group>
              </BaseForm.Item>
            </Space>
          </Col>

          <Col xs={24} md={4}>
            <BaseForm.Item
              name="publica"
              label="Visível na Home"
              rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
            >
              <BaseRadio.Group value={formData.publica} onChange={(e) => handleInputChange('publica', e.target.value)}>
                <BaseRadio.Button value={1}>Sim</BaseRadio.Button>
                <BaseRadio.Button value={0}>Não</BaseRadio.Button>
              </BaseRadio.Group>
            </BaseForm.Item>
          </Col>

          <Col xs={24} md={4}>
            <BaseForm.Item
              name="preenchimento_foto"
              label="Preenchimento de Foto"
              rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
            >
              <BaseRadio.Group
                value={formData.preenchimento_foto}
                onChange={(e) => {
                  console.log(e)
                  handleInputChange('preenchimento_foto', e.target.value)

                  if (e.target.value === false) {
                    handleInputChange('label_foto', null)
                  }
                }}
              >
                <BaseRadio.Button value={true}>Sim</BaseRadio.Button>
                <BaseRadio.Button value={false}>Não</BaseRadio.Button>
              </BaseRadio.Group>
            </BaseForm.Item>
          </Col>

          <Col xs={24} md={4}>
            <div className="select-container">
              <BaseForm.Item
                name="duracao"
                label="Duração"
                rules={[{ required: true, message: 'Por favor, selecione uma duração' }]}
              >
                <BaseSelect
                  value={formData.duracao}
                  onChange={(value) => {
                    handleInputChange('duracao', value)

                    if (value == 'permanente') {
                      handleInputChange('data_inicio', null)
                      handleInputChange('data_fim', null)
                    }
                  }}
                  placeholder="Selecione uma opção"
                >
                  <Option value="permanente">Permanente</Option>
                  <Option value="temporaria">Temporária</Option>
                </BaseSelect>
              </BaseForm.Item>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="select-container">
              <BaseForm.Item
                name="objetivo"
                label="Objetivo"
                rules={[{ required: true, message: 'Por favor, selecione um objetivo' }]}
              >
                <BaseSelect
                  disabled={campaignId}
                  value={formData.objetivo}
                  onChange={(value) => {
                    handleInputChange('objetivo', value)

                    if (value === 'cadastro') {
                      handleInputChange('cadastrar_landing_page', 1)
                      handleInputChange('valor_meta', null)
                    }

                    if (value === 'doacao') {
                      handleInputChange('cadastrar_landing_page', 0)
                      handleInputChange('cadastros_meta', null)

                      handleInputChange('texto_email_pos_registro', null)
                      handleInputChange('texto_pos_registro', null)
                      handleInputChange('url', null)
                      handleInputChange('tipo_cadastro', null)
                    }
                  }}
                  placeholder="Selecione uma opção"
                >
                  <Option value="cadastro">Cadastro</Option>
                  <Option value="doacao">Doação</Option>
                </BaseSelect>
              </BaseForm.Item>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <BaseForm.Item
              name="cadastrar_landing_page"
              label="Criar LandingPage"
              rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
            >
              <BaseRadio.Group
                disabled={formData.objetivo === 'cadastro' || formData.objetivo === 'doacao'}
                value={formData.cadastrar_landing_page}
                onChange={(e) => handleInputChange('cadastrar_landing_page', e.target.value)}
              >
                <BaseRadio.Button value={1}>Sim</BaseRadio.Button>
                <BaseRadio.Button value={0}>Não</BaseRadio.Button>
              </BaseRadio.Group>
            </BaseForm.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <BaseForm.Item
              name="titulo"
              label="Título"
              rules={[{ required: true, message: 'Por favor, insira um título' }]}
            >
              <BaseInput
                value={formData.titulo}
                onChange={(e) => handleInputChange('titulo', e.target.value)}
                style={{ width: '100%' }}
                placeholder="Insira um título"
              />
            </BaseForm.Item>
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={24}>
              {formData.preenchimento_foto && (
                <Col xs={24} md={formData.duracao === 'temporaria' ? 8 : 24}>
                  <BaseForm.Item
                    name="label_foto"
                    label="Label da Foto"
                    rules={[{ required: true, message: 'Por favor, insira o label da foto' }]}
                  >
                    <BaseInput
                      value={formData.label_foto}
                      onChange={(e) => handleInputChange('label_foto', e.target.value)}
                      style={{ width: '100%' }}
                      placeholder="Insira o label da foto"
                    />
                  </BaseForm.Item>
                </Col>
              )}
              {formData.duracao === 'temporaria' && (
                <>
                  <Col xs={24} md={formData.preenchimento_foto ? 8 : 12}>
                    <BaseForm.Item
                      name="data_inicio"
                      label="Data do Início"
                      rules={[
                        { required: true, message: 'Por favor, selecione a data de início' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || !getFieldValue('data_fim') || value.isBefore(getFieldValue('data_fim'))) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('A data de início não pode ser maior do que a data de fim'))
                          },
                        }),
                      ]}
                    >
                      <BaseDatePicker
                        format="DD/MM/YYYY"
                        value={formData.data_inicio}
                        onChange={(value) => handleInputChange('data_inicio', value)}
                        style={{ width: '100%' }}
                      />
                    </BaseForm.Item>
                  </Col>
                  <Col xs={24} md={formData.preenchimento_foto ? 8 : 12}>
                    <BaseForm.Item
                      name="data_fim"
                      label="Data do Fim"
                      rules={[
                        { required: true, message: 'Por favor, selecione a data de fim' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              !getFieldValue('data_inicio') ||
                              value.isAfter(getFieldValue('data_inicio'))
                            ) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('A data de fim não pode ser menor do que a data de início'))
                          },
                        }),
                      ]}
                    >
                      <BaseDatePicker
                        format="DD/MM/YYYY"
                        value={formData.data_fim}
                        onChange={(value) => handleInputChange('data_fim', value)}
                        style={{ width: '100%' }}
                      />
                    </BaseForm.Item>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 8]}>
          <Col xs={24} md={12}>
            <BaseForm.Item
              name="descricao"
              label="Descrição"
              rules={[{ required: true, message: 'Por favor, insira uma descrição' }]}
            >
              <BaseInput.TextArea
                value={formData.descricao}
                onChange={(e) => handleInputChange('descricao', e.target.value)}
                style={{ width: '100%' }}
                rows={4}
                placeholder="Insira uma descrição"
              />
            </BaseForm.Item>
          </Col>
          {formData.cadastrar_landing_page === 1 && (
            <>
              <Col xs={24} md={6}>
                <BaseForm.Item
                  name="texto_email_pos_registro"
                  label="Texto a ser enviado por e-mail"
                  rules={[{ required: true, message: 'Por favor, insira o texto a ser enviado por e-mail' }]}
                >
                  <BaseInput.TextArea
                    value={formData.texto_email_pos_registro}
                    onChange={(e) => handleInputChange('texto_email_pos_registro', e.target.value)}
                    rows={4}
                    placeholder="Texto para o e-mail pós-registro"
                  />
                </BaseForm.Item>
              </Col>
              <Col xs={24} md={6}>
                <BaseForm.Item
                  name="texto_pos_registro"
                  label="Texto Pós Registro"
                  rules={[{ required: true, message: 'Por favor, insira o texto pós registro' }]}
                >
                  <BaseInput.TextArea
                    value={formData.texto_pos_registro}
                    onChange={(e) => handleInputChange('texto_pos_registro', e.target.value)}
                    rows={4}
                    placeholder="Texto pós-registro"
                  />
                </BaseForm.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={[24, 8]}>
          {formData.cadastrar_landing_page === 1 && (
            <Col xs={24} sm={24} md={24} lg={16}>
              <BaseForm.Item
                name="url"
                label="Url da Landing Page"
                rules={[{ required: true, message: 'Por favor, insira a URL da landing page' }]}
              >
                <BaseInput
                  addonBefore="https://doe.institutohesed.org.br/landingpage/"
                  placeholder="URL"
                  style={{ overflow: 'hidden' }}
                  value={formData.url}
                  onChange={(e) => handleInputChange('url', e.target.value)}
                />
              </BaseForm.Item>
            </Col>
          )}
          {formData.objetivo === 'doacao' && (
            <Col xs={24} md={formData.cadastrar_landing_page === 1 ? 6 : 12}>
              <BaseForm.Item
                name="valor_meta"
                label="Meta de Doações"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira um número válido para a meta de doações',
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Por favor, insira apenas números',
                  },
                ]}
              >
                <BaseInput
                  value={formData.valor_meta}
                  onChange={(e) => handleInputChange('valor_meta', e.target.value)}
                  type="number"
                  placeholder="Insira o valor da meta"
                />
              </BaseForm.Item>
            </Col>
          )}
          {formData.objetivo === 'cadastro' && (
            <Col xs={24} md={formData.cadastrar_landing_page === 1 ? 6 : 12}>
              <BaseForm.Item
                name="cadastros_meta"
                label="Meta de Cadastros"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira um número válido para a meta de cadastros',
                  },
                ]}
              >
                <BaseInput
                  value={formData.cadastros_meta}
                  onChange={(e) => handleInputChange('cadastros_meta', e.target.value)}
                  placeholder="Insira o número de cadastros da meta"
                />
              </BaseForm.Item>
            </Col>
          )}
          {formData.cadastrar_landing_page === 1 && (
            <Col xs={24} md={formData.objetivo === 'doacao' || formData.objetivo === 'cadastro' ? 6 : 8}>
              <BaseForm.Item
                name="tipo_cadastro"
                label="Tipo de Cadastros"
                rules={[{ required: true, message: 'Por favor, insira o tipo de cadastros' }]}
              >
                <BaseSelect
                  size="middle"
                  style={{ width: '100%' }}
                  placeholder="Selecione uma opção"
                  value={formData.tipo_cadastro}
                  onChange={(value) => handleInputChange('tipo_cadastro', value)}
                >
                  {/* <Option value="Marechal">Marechal</Option> */}
                  <Option value="General">General</Option>
                  <Option value="Benfeitor">Benfeitor</Option>
                </BaseSelect>
              </BaseForm.Item>
            </Col>
          )}
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            gap: '18px',
            marginTop: '2rem',
          }}
        >
          <Col>
            <Row>
              <BaseForm.Item
                name="capaImage"
                label="Imagem da Capa"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, envie uma imagem!',
                    validator: () => {
                      if (formData?.capaImage.length === 0) {
                        return Promise.reject(new Error('Por favor, envie uma imagem!'))
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <BaseUploadPhoto
                  validateField={() => form.validateFields(['capaImage'])}
                  numeroFotos={1}
                  aspect={3 / 2}
                  description="Altura 1440px | Largura 320px"
                  label="Foto de Capa"
                  value={formData?.capaImage}
                  initialFileList={formData?.capaImage}
                  setImage={(value) => handleInputChange('capaImage', value)}
                />
              </BaseForm.Item>
            </Row>
          </Col>
          {formData.cadastrar_landing_page === 1 && (
            <>
              {/* <Row>
                <BaseForm.Item
                  name="backgroundImage"
                  label="Imagem de Plano de Fundo"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, envie uma imagem!',
                      validator: () => {
                        if (backgroundImage.length === 0) {
                          return Promise.reject(new Error('Por favor, envie uma imagem!'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <BaseUploadPhoto
                    initialFileList={backgroundImage}
                    setImage={(image) => dispatch(setBackgroundImage(image))}
                    validateField={() => form.validateFields(['backgroundImage'])}
                    numeroFotos={1}
                    aspect={16 / 9}
                    description="Altura 1920px | Largura 1080px"
                  />
                </BaseForm.Item>
              </Row> */}
              <Row>
                <BaseForm.Item
                  name="bannerImage"
                  label="Imagem do Banner"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, envie uma imagem!',
                      validator: () => {
                        if (formData?.bannerImage.length === 0) {
                          return Promise.reject(new Error('Por favor, envie uma imagem!'))
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <BaseUploadPhoto
                    validateField={() => form.validateFields(['bannerImage'])}
                    numeroFotos={1}
                    aspect={9 / 2}
                    description="Altura 1440px | Largura 320px"
                    label="Banner"
                    initialFileList={formData?.bannerImage}
                    value={formData?.bannerImage}
                    setImage={(value) => handleInputChange('bannerImage', value)}
                  />
                </BaseForm.Item>
              </Row>
            </>
          )}
        </Row>
        <Row />
      </BaseForm>
    </Wrapper>
  )
}

export default Step1
