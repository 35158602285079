import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import { EnderecoFormularioCadastroFamilia } from '../../../pages/agape/Familia/CadastrarFamilia/FormularioCadastrarFamilia'

interface EditarEnderecoFamiliaProps {
  fk_familia_agape_id: number
  endereco: EnderecoFormularioCadastroFamilia
}

export function useEditarEnderecoFamilia() {
  const queryClient = useQueryClient()

  const editarEnderecoFamilia = useMutation({
    mutationFn: async ({ fk_familia_agape_id, endereco }: EditarEnderecoFamiliaProps) => {
      await DataService.put(`/agape/editar-endereco-familia/${fk_familia_agape_id}`, {
        ...endereco,
      })
    },
    onSuccess: (_, { fk_familia_agape_id }) => {
      notification.success({ message: 'Endereço da família editado com sucesso!' })

      queryClient.invalidateQueries({ queryKey: ['ENDERECO_FAMILIA', fk_familia_agape_id] })
      queryClient.invalidateQueries({ queryKey: ['ENDERECOS_FAMILIAS'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao atualizar o endereço da família',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return editarEnderecoFamilia
}
