import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { Form, message, TimePicker } from 'antd'
import moment from 'moment'
import { DataService } from '../../../../../../lib/dataService'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import { BasePaginationLocale } from '../../../../components/pagination/BasePaginationLocale'
import BaseCard from '../../../../components/card/BaseCard'
import { BaseSelect } from '../../../../components/select/BaseSelect'
import BaseSpin from '../../../../components/spin/BaseSpin'
import BaseButton from '../../../../components/button/BaseButton'
import { BaseNotification } from '../../../../components/notification/BaseNotification'
import BaseModal from '../../../../components/modal/BaseModal'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { NoContentTable } from '../../../dashboard/styles'

export function ScheduledLivesTable() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const queryClient = useQueryClient()
  const [filters, setFilters] = useState()
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const [form] = Form.useForm()

  const {
    data: scheduledLives,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['scheduledLivesDataSource', filters],
    queryFn: async () => {
      try {
        const response = await DataService.get(`youtube/get-lives-schedules?${filters?.queryString || ''}`)
        return response.data
      } catch (error) {
        BaseNotification.error({ message: 'Ocorreu um erro, tente novamente mais tarde' })
        throw error
      }
    },
  })

  const deleteLiveMutation = useMutation({
    mutationFn: (item) =>
      DataService.delete(`/youtube/delete-live-scheduled/${item?.id}?tipo_programacao=${item?.tipo_programacao}`),
    onSuccess: () => {
      BaseNotification.success({ message: 'Live deletada com sucesso!' })
      queryClient.invalidateQueries(['scheduledLivesDataSource'])
      setFilters({})
    },
    onError: (error) => {
      console.log(error)
      BaseNotification.error({ message: 'Ocorreu um erro ao deletar a live.' })
    },
  })

  const editLiveMutation = useMutation({
    mutationFn: async ({ id, tipo_programacao, dia_semana, hora_inicio }) => {
      const formattedHoraInicio = hora_inicio.format('HH:mm:ss')
      const dataToSend = {
        dia_semana,
        tipo_programacao,
        data_hora_inicio: formattedHoraInicio,
      }
      await DataService.put(`/youtube/edit-live-scheduled/${id}`, dataToSend)
    },
    onSuccess: () => {
      BaseNotification.success({ message: 'Live editada com sucesso!' })
      queryClient.invalidateQueries(['scheduledLivesDataSource'])
      setIsModalOpen(false)
    },
    onError: (error) => {
      console.log(error)
      BaseNotification.error({ message: 'Ocorreu um erro ao editar a live.' })
    },
  })

  const scheduledLivesColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Dia ou Data ',
      dataIndex: 'data',
      key: 'data',
      align: 'center',
      filterDropdown: () => (
        <BaseSelect
          placeholder="Selecione um dia"
          allowClear
          onChange={(value) => handleInputChange({ filtro_dias_semana: value })}
        >
          <Option value="segunda-feira">Segunda-Feira</Option>
          <Option value="terca-feira">Terça-Feira</Option>
          <Option value="quarta-feira">Quarta-Feira</Option>
          <Option value="quinta-feira">Quinta-Feira</Option>
          <Option value="sexta-feira">Sexta-Feira</Option>
          <Option value="sabado">Sábado</Option>
          <Option value="domingo">Domingo</Option>
        </BaseSelect>
      ),
    },
    {
      title: 'Hora de Início ',
      dataIndex: 'hora',
      key: 'hora',
      align: 'center',
    },
    {
      title: 'Rede Social ',
      dataIndex: 'rede_social',
      key: 'rede_social',
      align: 'center',
      filterDropdown: () => (
        <BaseSelect
          placeholder="Selecione a rede social"
          allowClear
          prefix={<FeatherIcon icon="search" size={14} />}
          onChange={(value) => handleInputChange({ rede_social: value })}
        >
          <Option value="youtube">Youtube</Option>
          <Option value="facebook">Facebook</Option>
        </BaseSelect>
      ),
    },
    {
      title: 'Canal ',
      dataIndex: 'tag',
      key: 'tag',
      align: 'center',
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '90px',
    },
  ]

  const dataSource = scheduledLives?.data?.map((item) => {
    return {
      data: item?.data,
      id: item?.id,
      hora: item?.hora,
      rede_social: item?.rede_social,
      tag: item?.tag,
      action: (
        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
          {item?.tipo_programacao === 'periodica' && (
            <BaseButton
              severity="info"
              onClick={() => {
                setSelectedItem(item)
                setIsModalOpen(true)
              }}
            >
              Editar
            </BaseButton>
          )}
          <BaseButton
            onClick={() => {
              deleteLiveMutation.mutate(item)
            }}
            severity="info"
          >
            Excluir
          </BaseButton>
        </div>
      ),
    }
  })

  return (
    <>
      <BaseModal
        bodyStyle={{ minHeight: '15rem', height: 'auto' }}
        title="Editar Live"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => {
          setIsModalOpen(false)
          setSelectedItem(null)
          form.resetFields()
        }}
        footer={null}
        closeIcon="X"
        size="large"
      >
        <BaseForm
          form={form}
          name="channelForm"
          onFinish={(values) => {
            const { id, tipo_programacao } = selectedItem
            const { hora_inicio, dia_semana } = values

            editLiveMutation.mutate({ id, tipo_programacao, dia_semana, hora_inicio })
            form.resetFields()
          }}
        >
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center', gap: '1rem' }}>
            <BaseForm.Item
              label="Dia da Semana"
              name="dia_semana"
              rules={[{ required: true, message: 'Obrigatório' }]}
              style={{ width: '200px' }}
            >
              <BaseSelect>
                <Option value="segunda-feira">Segunda-Feira</Option>
                <Option value="terca-feira">Terça-Feira</Option>
                <Option value="quarta-feira">Quarta-Feira</Option>
                <Option value="quinta-feira">Quinta-Feira</Option>
                <Option value="sexta-feira">Sexta-Feira</Option>
                <Option value="sabado">Sábado</Option>
                <Option value="domingo">Domingo</Option>
              </BaseSelect>
            </BaseForm.Item>
            <BaseForm.Item
              label="Hora do Início"
              name="hora_inicio"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <TimePicker format="HH:mm" />
            </BaseForm.Item>
          </div>
          <BaseButton htmlType="submit" style={{ margin: '0 auto' }}>
            Enviar
          </BaseButton>
        </BaseForm>
      </BaseModal>

      <div style={{ maxWidth: '850px' }}>
        <BaseCard>
          <h2 style={{ padding: '1rem 0' }}>Lives Agendadas</h2>
          <BaseTable
            dataSource={dataSource}
            columns={scheduledLivesColumns}
            onChange={(pagination) => {
              handleInputChange({ page: pagination.current, page_size: pagination.pageSize }, true)
            }}
            pagination={{
              current: scheduledLives?.pagination?.current_page,
              total: scheduledLives?.pagination?.total_items,
              pageSize: scheduledLives?.pagination?.page_size,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20'],
              locale: BasePaginationLocale,
            }}
            loading={isLoading}
            rowKey="id"
            locale={{ emptyText: <NoContentTable>Nenhum conteúdo encontrado</NoContentTable> }}
          />
        </BaseCard>
      </div>
    </>
  )
}
