import { ComponentProps } from 'react'
import { motion } from 'framer-motion'
import { useBlur } from './BlurContext'

interface BlurDivProps extends ComponentProps<'div'> {}

export function BlurDiv({ ...props }: BlurDivProps) {
  const { isBlurred } = useBlur()

  return (
    <div {...props}>
      <motion.div
        animate={{
          filter: isBlurred ? 'blur(0.4rem)' : 'blur(0px)',
          transition: { duration: 0.5 },
        }}
      >
        {props.children}
      </motion.div>
    </div>
  )
}
