import { Form, Row } from 'antd'
import BaseCard from '../../../../components/card/BaseCard'
import BaseButton from '../../../../components/button/BaseButton'
import { PlusCircle, TrashSimple } from '@phosphor-icons/react'
import { FormularioMembro } from './FormularioMembro'

type ListaFormularioMembroProps = {
  hasInitialValue: boolean
}

export function ListaFormularioMembro({ hasInitialValue }: ListaFormularioMembroProps) {
  return (
    <Form.List name="membros" initialValue={hasInitialValue ? [{}] : undefined}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <BaseCard
              size="small"
              key={field.key}
              title={<h5>Membro {index + 1}</h5>}
              extra={
                ((index > 0 && hasInitialValue) || !hasInitialValue) && (
                  <BaseButton
                    type="text"
                    onClick={() => remove(field.name)}
                    style={{ color: 'var(--text-main-color)' }}
                  >
                    <TrashSimple size={25} />
                  </BaseButton>
                )
              }
            >
              <FormularioMembro fieldName={field.name} />
            </BaseCard>
          ))}
          <Row justify="center" style={{ marginTop: 16 }}>
            <BaseButton severity="info" onClick={() => add({})}>
              {!hasInitialValue && 'Adicionar membro'} <PlusCircle size={30} />
            </BaseButton>
          </Row>
        </>
      )}
    </Form.List>
  )
}
