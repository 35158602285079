import { Button, Upload } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import { UploadOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import { UploadChangeParam } from 'antd/es/upload/interface'
import FormItem from 'antd/es/form/FormItem'
import { useFetchUserMe } from '../../../../../../admin/hooks'
import { UploadPhotoContainer } from './styles'

interface UploadPhotoProps {
  form: any
  height?: string
  border?: string
  required?: boolean
  secondStyleType?: boolean
  hasFormBeenFetchedFromApi?: boolean
  file: UploadFile[]
  setFile: React.Dispatch<React.SetStateAction<UploadFile[]>>
}

export const UploadPhoto = ({
  hasFormBeenFetchedFromApi = false,
  form,
  height,
  border,
  secondStyleType = false,
  required = true,
  file: fileList,
  setFile,
}: UploadPhotoProps) => {
  const { data: userMe } = useFetchUserMe()

  useEffect(() => {
    if (userMe?.avatar && !hasFormBeenFetchedFromApi) {
      setFile([
        {
          type: 'image/png',
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: userMe.avatar,
        },
      ])
      form.setFieldsValue({ avatar: userMe?.avatar })
    }
  }, [userMe, form, hasFormBeenFetchedFromApi])

  const handleUploadChange = async ({ file, fileList: newFileList }: UploadChangeParam<UploadFile<any>>) => {
    setFile(newFileList)

    if (newFileList.length > 0) {
      const fileObj = file.originFileObj || (file as unknown as File)
      form.setFieldsValue({ avatar: fileObj })
    } else {
      form.setFieldsValue({ avatar: null })
    }
  }

  return (
    <FormItem
      name="avatar"
      label="Foto de Perfil"
      rules={[{ required: false, message: 'Por favor, insira uma foto de perfil!' }]}
    >
      <UploadPhotoContainer secondStyleType={secondStyleType} height={height} border={border}>
        <Upload
          beforeUpload={() => false}
          onChange={handleUploadChange}
          listType="picture"
          maxCount={1}
          fileList={fileList}
          accept="image/*"
        >
          {fileList.length < 1 && <Button icon={<UploadOutlined />}>Envie sua foto</Button>}
        </Upload>
      </UploadPhotoContainer>
    </FormItem>
  )
}
