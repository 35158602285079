import { useMutation } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export interface Endereco {
  bairro?: string
  cep?: string
  cidade?: string
  complemento?: string
  estado?: string
  numero?: string
  rua?: string
  detalhe_estrangeiro?: string
}

export interface Usuario {
  campanha_origem?: number
  data_nascimento: string
  email: string
  nome: string
  nome_social: string
  password: string
  telefone: string
  sexo: 'masculino' | 'feminino'
  numero_documento: string
  tipo_documento: 'cpf' | 'cnpj' | 'identidade_estrangeira'
}

export interface RegisterUserCompleteReqBody {
  usuario: Usuario
  endereco?: Endereco
  image?: File
  pais?: string
  pagina_redirecionamento?: 'principal' | 'membro-exercito'
}

export function useUserRegisterFullData() {
  const history = useHistory()

  const mutation = useMutation({
    mutationFn: async ({ usuario, endereco, image, pais, pagina_redirecionamento }: RegisterUserCompleteReqBody) => {
      const formData = new FormData()

      if (usuario) {
        formData.append('usuario', JSON.stringify(usuario))
      }

      if (endereco) {
        formData.append('endereco', JSON.stringify(endereco))
      }

      if (image) {
        formData.append('image', image)
      }
      if (pais) {
        formData.append('pais', pais)
      }
      if (pagina_redirecionamento) {
        formData.append('pagina_redirecionamento', pagina_redirecionamento)
      }

      const response = await DataService.post('/users/cadastrar-usuario-completo', formData)
      return response
    },
    onSuccess: () => {
      notification.success({
        message: 'Cadastro com sucesso!',
        description: 'Por favor, confirme seu e-mail para poder prosseguir!',
        duration: 30,
      })
      history.push('/benfeitor/confirmar-email')
    },
    onError: (error: any) => {
      notification.error({
        message: 'Falha ao realizar o cadastro',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente!',
        duration: 10,
      })
    },
  })

  return mutation
}
