import { GoldenHeader } from '../components/golden-header/styles'
import { useGetLandingPageById } from '../../../benfeitor/hooks/api/landingpage/queries/useGetLandingPageById'
import { Container, ImgStyled } from './styles'
import logosImage from '../../images/logos.svg'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Documento } from '../validate-user'
import { SignUpForm } from './form'
import { useFetchUserMe, useFetchUserMeAddress } from '../../../admin/hooks'
import { useRedirectIfInvalid } from '../../hooks/use-redirect-if-invalid'
import { getEsmSignUpInitialFields } from '../../utils/get-esm-sign-up-initial-fields'
import { LoadingSpinner } from '../components/loading-spinner'
import { SignUpCompleted } from '../components/sign-up-completed'
import { ErrorResult } from '../../../admin/components/result/resultStatus/ErrorResult'
import { useRegisterPresenceEffect } from '../../../admin/hooks/users/Mutations/useRegisterUserPresence'

interface LocationStateProps {
  document: Documento
  isForeigner: boolean
}

export function SignUpPage() {
  const history = useHistory()
  const location = useLocation<LocationStateProps>()

  const [isFinished, setIsFinished] = useState(false)

  const { data: userMe } = useFetchUserMe()
  const { data: userMeAddress } = useFetchUserMeAddress()
  const { document, isForeigner } = location.state || {}

  useRedirectIfInvalid(document, isForeigner)

  const initialFields = getEsmSignUpInitialFields(document, userMe, userMeAddress)

  const {
    data: landingPageData,
    isPending: isPendingLandingPage,
    isError: isLandingPageError,
  } = useGetLandingPageById({ id: '19' })

  useRegisterPresenceEffect({ userMe })

  useEffect(() => {
    if (!userMe) return
    if (isFinished) return history.push('/membro-exercito/pre-tornar-se-general-form')
  }, [isFinished, userMe])

  if (isPendingLandingPage) return <LoadingSpinner />
  if (isLandingPageError) return <ErrorResult />

  return (
    <Container>
      <div className="container-wrapper">
        <ImgStyled src={logosImage} alt="Logo" />
        {isFinished ? (
          <SignUpCompleted />
        ) : (
          <SignUpForm
            landingPageData={landingPageData}
            initialFields={initialFields}
            markAsFinished={() => setIsFinished(true)}
          />
        )}
      </div>
    </Container>
  )
}
