import { Dropdown, Layout } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { StyledSider } from './styles'
import { SiderLogo } from '../components/SiderLogo/SiderLogo'
import { StyledContent, StyledHeader, StyledMenu } from '../styles'
import { ThemePicker } from '../../../../../styles/Themes/Admin/ThemePicker'
import BaseAvatar from '../../../components/avatar/BaseAvatar'
import { useAdminContext } from '../../../context/AdminLayout'
import { getMenuItems } from '../menuItems'
import { useState } from 'react'
import { BlurToggle } from '@/context/Blur/BlurToggle'

export function StandardView({ children }) {
  const { userMe, ProfileDropdown, location } = useAdminContext()
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <>
      <StyledSider width={140}>
        <SiderLogo />
        <StyledMenu mode="vertical" items={getMenuItems(userMe)} />
      </StyledSider>

      <Layout style={{ height: '100vh' }}>
        <StyledHeader>
          <BlurToggle />

          <ThemePicker />
          <Dropdown
            menu={{
              items: ProfileDropdown,
            }}
            trigger={['click']}
            placement="bottom"
            open={dropdownVisible}
            onVisibleChange={setDropdownVisible}
          >
            <BaseAvatar icon={<UserOutlined />} size={40} src={userMe?.avatar} />
          </Dropdown>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ height: '1rem', fontWeight: 'bold' }}>{userMe?.nome}</p>
            <p>Voluntário Ágape</p>
          </div>
        </StyledHeader>

        <StyledContent location={location}>
          <div>{children}</div>
        </StyledContent>
      </Layout>
    </>
  )
}
