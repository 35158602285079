import { useMutation } from '@tanstack/react-query'
import { useRegisterPresence } from './useRegisterPresence'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import { useFetchUserMe } from '../Queries/useFetchUserMe'

interface RegisterUserLandingPageVariables {
  value: any
  image: File
  campanhaId: number
}

export function useRegisterUserLandingPage() {
  const { data: userMe } = useFetchUserMe()

  return useMutation<any, Error, RegisterUserLandingPageVariables>({
    mutationFn: ({ value, image }) => {
      const formData = new FormData()

      formData.append('data', JSON.stringify(value))

      if (image) {
        formData.append('image', image)
      }

      return DataService.post('/landpage/register', formData)
    },
    onSuccess: (_, { campanhaId }) => {
      useRegisterPresence(campanhaId, userMe?.id!)
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao concluir o cadastro.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })
}
