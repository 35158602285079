import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { FiltersUnpaidRecurrences } from '../../../pages/donations/UnpaidRecurrence/UnpaidRecurrence'

interface DoacaoNaoEfetivada {
  benfeitor: string
  benfeitor_id: number
  campanha: string
  data: string
  fk_campanha_id: number
  id: number
  valor: string
}

interface DoacoesNaoEfetivadasResponse {
  doacoes_nao_efetivadas: DoacaoNaoEfetivada[]
  page: number
  pages: number
  total: number
}

export function useFetchUnpaidRecurrences(filters: FiltersUnpaidRecurrences | undefined) {
  const unpaidRecurrences = useQuery<DoacoesNaoEfetivadasResponse>({
    queryKey: ['UNPAID_RECURRENCES', filters],
    queryFn: async () => {
      const query = await DataService.get<DoacoesNaoEfetivadasResponse>(
        `checkout/doacoes-recorrentes-nao-efetivadas?${filters?.queryString || ''}`,
      )
      return query?.data
    },
  })

  return unpaidRecurrences
}
