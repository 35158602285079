import React, { HtmlHTMLAttributes } from 'react'
import { StyledGradientHeader } from './styles'

export interface GradientHeaderProps extends HtmlHTMLAttributes<HTMLDivElement> {
  colorOne: string
  colorTwo: string
  fontSize?: number
  children?: React.ReactNode
}

export function GradientHeader({ colorOne, colorTwo, fontSize, children, ...props }: GradientHeaderProps) {
  return (
    <StyledGradientHeader colorOne={colorOne} colorTwo={colorTwo} fontSize={fontSize} {...props}>
      {children}
    </StyledGradientHeader>
  )
}
