import { env } from '@/lib/env';
import LogoHesed from '@/static/img/logo_hesed.png';
import { Button, Collapse, Result, Typography } from 'antd';
import styled from 'styled-components';

interface ErrorFallbackProps {
  error: unknown;
  componentStack: string;
  resetError: () => void;
}

const StyledButton = styled(Button)`
  &.ant-btn {
    border-radius: 8px;
  }
`;

export const ErrorFallback = ({ error, componentStack, resetError }: ErrorFallbackProps) => {
  const handleRetry = () => {
    resetError(); // First try to reset the error boundary
    // If that doesn't work, reload the page
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <FullHeightWrapper>
      <ErrorContainer>
        <div>
          <LogoImage src={LogoHesed} alt="Instituto Hesed" />
        </div>
        <StyledResult
          status="404"
          title="Ops! Parece que encontramos um pequeno obstáculo"
          subTitle={
            <>
              Não se preocupe! Nossa equipe já foi notificada e está trabalhando nisso.
              <StyledText>
                {" "}Enquanto isso, que tal tentar novamente?
                Na maioria das vezes isso resolve! 😊
              </StyledText>
            </>
          }
          extra={
            <StyledButton type="primary" size="large" onClick={handleRetry}>
              Vamos Tentar Novamente
            </StyledButton>
          }
        >
          {env.VITE_SENTRY_ENVIRONMENT !== "prod" && (
            <StyledCollapse>
              <Collapse.Panel header="Detalhes Técnicos" key="1">
                <ErrorDetails>
                  {error?.toString()}
                  {'\n\n'}
                  {componentStack}
                </ErrorDetails>
              </Collapse.Panel>
            </StyledCollapse>
          )}
        </StyledResult>
      </ErrorContainer>
    </FullHeightWrapper>
  );
};

const LogoImage = styled.img`
  width: 200px;
  opacity: 0.5;
  filter: grayscale(100%) brightness(0) contrast(0.9);
  margin-bottom: 2rem;
  transition: all 0.1s ease;

  &:hover {
    cursor: pointer;
    filter: grayscale(100%) brightness(0) contrast(2);
  }
`;

const FullHeightWrapper = styled.div`
  min-height: 100vh;
  background-color:rgb(255, 255, 255) !important;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  padding-top: 40px;
  text-align: center;
  overflow-y: auto;
`;

const StyledCollapse = styled(Collapse)`
  max-width: 800px;
  width: 100%;
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
`;

const StyledResult = styled(Result)`
  .ant-result-content {
    background-color:rgb(255, 255, 255) !important;
  }
  .ant-result-title{
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ErrorDetails = styled.pre`
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
`;

const StyledText = styled(Typography.Text)`
  margin-top: 1rem;
  color: #666;
`;
