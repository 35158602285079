import styled, { css } from 'styled-components'
import { BaseSelect } from '../select/BaseSelect'

export const StyledSelect = styled(BaseSelect)`
  ${({ style }) =>
    style?.maxHeight &&
    css`
      .ant-select-selection-overflow {
        max-height: ${style?.maxHeight};
        overflow: hidden;
      }
    `}

  .ant-select-selector {
    /* overflow: auto; */
    /* max-height: 38px; */
    border: solid #989898 1px !important;
    color: #bebebe;
  }

  &.ant-select-multiple .ant-select-selector {
    /* display: flex;
    justify-content: center;
    max-width: 230px;
    overflow: hidden; */
    min-width: 230px;
    min-height: 2rem;
    max-height: auto;
    padding: 0 8px;
  }
`

export const CustomDropDownStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
  color: #f5f7ff;
  font-weight: 600;

  &:hover {
    background-color: #dedede;
  }
`

export const StyledMenu = styled.div`
  .ant-select-item-option {
    /* .ant-select-item-option-content {
      color: #000;
      background: transparent;
    } */

    &.ant-select-item-option-selected .ant-select-item-option-content {
      /* color: #388915 !important; */
    }
  }

  /* check icon */
  .ant-select-item-option-state {
    /* color: #388915 !important; */
    display: grid;
    place-items: center;
  }
`
