import { Result } from 'antd'
import { SectionTitle } from '../styles'

export function EventoPresencialResult({ isFinished }: { isFinished: string }) {
  if (isFinished === 'LandingPageForm') {
    return (
      <SectionTitle>
        <Result
          status="success"
          title="Conta cadastrada com sucesso!"
          subTitle="Por favor, confirme o seu e-mail para validação!"
        />
      </SectionTitle>
    )
  }

  if (isFinished === 'GeneralForm') {
    return (
      <SectionTitle>
        <Result
          status="success"
          title="Solicitação para ser general enviada com sucesso!"
          
          subTitle="Entraremos em contato em breve"
        />
      </SectionTitle>
    )
  }
}
