import { notification } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'
import { AddressForm } from '../../../../components/Address/address'

export interface CreateCadastroProps extends AddressForm {
  documento_identidade: string
  data_nascimento: string
  fk_usuario_vocacional_id: number
}

export function useCreateCadastro() {
  const createCadastro = useMutation({
    mutationFn: async ({
      bairro,
      cep,
      cidade,
      complemento,
      documento_identidade,
      estado,
      numero,
      rua,
      detalhe_estrangeiro,
      data_nascimento,
      fk_usuario_vocacional_id,
    }: CreateCadastroProps) => {
      await DataService.post('vocacional/registrar-cadastro-vocacional', {
        bairro,
        cep,
        cidade,
        complemento,
        documento_identidade,
        estado,
        numero,
        rua,
        detalhe_estrangeiro,
        data_nascimento,
        fk_usuario_vocacional_id,
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao realizar o cadastro',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return createCadastro
}
