import styled from 'styled-components'
import { Input } from 'antd'
import { StyledTextAreaProps } from '.'
const { TextArea } = Input
export const StyledTextAreaStyles = styled(TextArea)<StyledTextAreaProps>`
  resize: none;

  &.ant-input,
  .ant-input {
    color: ${(props) => props.theme.fonts.color.primary};
    border-color: ${(props) => '#d9d9d9'};
    border-radius: 4px;
    padding: 10px;
    resize: none;

    &:hover,
    &:focus {
      border-color: inherit;
    }

    &:focus {
      box-shadow: 0 0 0 1px inherit;
    }

    &::placeholder {
      color: ${({ strongPlaceholder }) => (strongPlaceholder ? 'black' : '#bfbfbf')};
      font-weight: ${({ strongPlaceholder }) => (strongPlaceholder ? 'bold' : 'normal')};
    }
  }
`
