export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
  },
  {
    title: 'Data ínicio',
    dataIndex: 'data_inicio',
    key: 'data_inicio',
  },
  {
    title: 'Data último acesso',
    dataIndex: 'data_ultimo_acesso',
    key: 'data_ultimo_acesso',
  },
  {
    title: 'Ações',
    dataIndex: 'acoes',
    key: 'acoes',
  },
]
