import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useSaveProfilePhoto() {
  const uploadImage = useMutation({
    mutationFn: async ({ image }) => {
      const formData = new FormData()

      formData.append('image', image)

      await DataService.post('users/salvar-foto-perfil', formData)
    },
    onSuccess: () => {
      notification.success({
        message: 'Foto de perfil salva com sucesso',
      })
    },
    onError: (error) => {
      notification.error({
        message: 'Ocorreu um erro ao salvar a foto de perfil',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return uploadImage
}
