import { Form, Result, Row } from 'antd'
import { StyledForm } from '../../../components/Form/styles'
import { StyledButton } from '../../../components/Button'
import { StyledCard } from '../../../components/Card/styles'
import { InstitutoFormFields } from './InstitutoFormFields'
import { VidaFormFields } from './VidaFormFields'
import { useState } from 'react'
import {
  CreateFichaVocacionalProps,
  useCreateFichaVocacional,
} from '../../../hooks/api/vocacional/mutations/useCreateFichaVocacional'
import { useGetUserVocacionalByToken } from '../../../hooks/api/vocacional/queries/useGetUserVocacionalByToken'
import { StyledSpin } from '../../../components/Spin/styles'
import { PreviousCadastroFields } from '../Cadastro/PreviousCadastroFields'
import { PhotoUploadData } from '../../../components/UploadPhoto'

export function FichaVocacionalForm({ token }: { token: string }) {
  const [form] = Form.useForm()

  const [fotoVocacional, setFotoVocacional] = useState<PhotoUploadData>([])

  const {
    mutateAsync: createFichaVocacional,
    isPending: isPendingCreateFichaVocacional,
    isSuccess: isSuccessCreateFichaVocacional,
  } = useCreateFichaVocacional()

  const {
    data: userByToken,
    isLoading: isLoadingGetByUserToken,
    isError: isErrorGetUserByToken,
  } = useGetUserVocacionalByToken({
    token,
  })

  const handleFichaVocacionalSubmit = async (data: CreateFichaVocacionalProps) => {
    if (fotoVocacional[0].file && userByToken?.fk_usuario_vocacional_id) {
      await createFichaVocacional({
        ...data,
        foto_vocacional: fotoVocacional[0].file,
        fk_usuario_vocacional_id: userByToken?.fk_usuario_vocacional_id,
      })
    }
  }

  if (isLoadingGetByUserToken) {
    return (
      <Row justify="center" style={{ minHeight: '60vh', alignItems: 'center' }}>
        <StyledSpin />
      </Row>
    )
  }

  if (isErrorGetUserByToken) {
    return <Result status="500" title="Erro" subTitle="Houve um erro ao tentar acessar esse conteúdo." />
  }

  if (userByToken?.status === 'desistencia') {
    return <Result title="Você desistiu do processo vocacional" subTitle="Não é possível prosseguir." status="error" />
  }

  if (userByToken?.etapa === 'ficha_vocacional') {
    return (
      <Result
        title="Você já realizou essa etapa."
        subTitle="Aguarde e verifique seu e-mail periodicamente para mais atualizações do processo vocacional"
        status="403"
      />
    )
  }

  if (isSuccessCreateFichaVocacional) {
    return (
      <Result
        status="success"
        title="Ficha vocacional preenchida com sucesso."
        subTitle="Aguarde e verifique seu e-mail com periodicidade!"
        style={{ paddingTop: '0' }}
      />
    )
  }

  if (userByToken?.etapa === 'cadastro' && userByToken.status === 'aprovado') {
    return (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => handleFichaVocacionalSubmit(values as CreateFichaVocacionalProps)}
        style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
        disabled={isPendingCreateFichaVocacional}
      >
        {userByToken && (
          <PreviousCadastroFields
            nome={userByToken.nome}
            email={userByToken.email}
            telefone={userByToken.telefone}
            pais={userByToken.pais}
          />
        )}

        <StyledCard title="Instituto">
          <InstitutoFormFields form={form} fotoVocacional={fotoVocacional} setFotoVocacional={setFotoVocacional} />
        </StyledCard>

        <StyledCard title="Vida">
          <VidaFormFields form={form} />
        </StyledCard>

        <Row justify="center">
          <StyledButton htmlType="submit" loading={isPendingCreateFichaVocacional}>
            Enviar
          </StyledButton>
        </Row>
      </StyledForm>
    )
  }

  return (
    <Result
      title="Você não pode visualizar esse conteúdo"
      subTitle="Por favor, siga o fluxo indicados nos e-mails"
      status="403"
    />
  )
}
