import { useState } from 'react'
import { Tag } from 'antd'
import { CustomDropDownStyled, StyledMenu, StyledSelect } from './style'

export const MultipleSelect = ({ value, options, onChange, style }) => {
  const optionsList = options.map((option) => option?.value)

  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    if (selectAll) {
      onChange([])
    } else {
      onChange(optionsList)
    }
    setSelectAll(!selectAll)
  }

  const tagRender = (props) => {
    const { label, closable, onClose } = props

    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag
        color="#388915"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    )
  }

  return (
    <StyledSelect
      style={style}
      mode="multiple"
      className="ant-select-selector"
      tagRender={tagRender}
      value={value}
      onChange={onChange}
      options={options}
      dropdownRender={(menu) => (
        <StyledMenu>
          {options?.length > 0 && (
            <CustomDropDownStyled
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={handleSelectAll}
            >
              <span>{selectAll ? 'Desmarcar  Todos' : 'Selecionar Todos'}</span>
            </CustomDropDownStyled>
          )}
          {menu}
        </StyledMenu>
      )}
    />
  )
}
