import { Container, LogoStyled } from './styles'
import logosImage from '../../images/logos.svg'
import { useAlistamentoSmContext } from '../../contexts/alistamento-sm-context'
import { useHistory } from 'react-router-dom'
import { GeneralForm } from './general-form'
import { useFetchUserMe } from '../../../admin/hooks'

export function BecomeGeneralForm() {
  const history = useHistory()

  const { userData: userContextData } = useAlistamentoSmContext()
  const { data: userMe } = useFetchUserMe()

  const userMeInitialFields = { ...userMe, usuario_id: userMe?.id }
  const initialFields = { ...(userMe ? userMeInitialFields : userContextData ?? {}) }

  if (!userMe && !userContextData?.email) {
    history.push('/membro-exercito/validar-usuario')
  }

  return (
    <Container>
      <div className="container-wrapper">
        <LogoStyled src={logosImage} alt="Logo" />
        <GeneralForm initialFields={initialFields} />
      </div>
    </Container>
  )
}
