import { Row } from 'antd'
import { EnviarRecibosForm } from './EnviarRecibos'
import { useState } from 'react'
import BaseButton from '../../../../components/button/BaseButton'
import BaseModal from '../../../../components/modal/BaseModal'

interface EnviarRecibosModalProps {
  idDoacao: number
}

export function EnviarRecibosModal({ idDoacao }: EnviarRecibosModalProps) {
  const [isOpenEnviarRecibosModal, setIsOpenEnviarRecibosModal] = useState(false)

  function handleIsOpenEnviarRecibosModal() {
    setIsOpenEnviarRecibosModal((prev) => !prev)
  }

  return (
    <>
      <Row justify="center">
        <BaseButton severity="info" onClick={handleIsOpenEnviarRecibosModal}>
          Enviar recibo(s)
        </BaseButton>
      </Row>

      <BaseModal
        title={<h3>Envie o(s) recibo(s)</h3>}
        open={isOpenEnviarRecibosModal}
        onCancel={handleIsOpenEnviarRecibosModal}
        footer={false}
        width={'70vw'}
      >
        <EnviarRecibosForm
          idDoacao={idDoacao}
          acaoPosEnviarRecibo={handleIsOpenEnviarRecibosModal}
        />
      </BaseModal>
    </>
  )
}
