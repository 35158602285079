import { DatePicker } from 'antd'
import styled from 'styled-components'

export const StyledDatePicker = styled(DatePicker)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  border-color: ${({ theme }) => theme.colors['gray-400']};
  border-radius: 8px;
  width: 100%;
  &:hover {
    border-color: ${({ theme }) => theme.colors['gray-400']};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['gray-400']};
  }
`

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  border-color: ${({ theme }) => theme.colors['gray-400']};
  border-radius: 8px;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors['gray-400']};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['gray-400']};
  }

  .ant-picker-active-bar {
    background-color: ${(props) => props.theme.colors['black']};
  }

  .ant-picker-clear {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  &.ant-picker {
    .ant-picker-input {
      input {
        color: ${(props) => props.theme.colors['black']};
      }

      svg {
        color: ${(props) => props.theme.colors['black']};
      }
    }
  }
`
