import { http, HttpResponse } from 'msw'

const cepValue = '60010110'

export const searchCepMock = http.get<{ cep: string }, never>('/services/search-cep/:cep', ({ params }) => {
  if (params.cep === cepValue) {
    return HttpResponse.json(
      {
        bairro: 'Moura Brasil',
        cep: '60010110',
        cidade: 'Fortaleza',
        estado: 'CE',
        rua: 'Rua São Januário',
      },
      { status: 200 },
    )
  }
})
