import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useSendEmailResetPassword() {
  const sendEmailResetPassword = useMutation({
    mutationFn: async ({ email, url_redirect }: { email: string; url_redirect?: string }) => {
      await DataService.post('/auth/forgot-password', { email, url_redirect })
    },
    onSuccess: () => {
      notification.success({
        message: 'Redefinição de senha enviada com sucesos',
        description: 'Verifique seu e-mail.',
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Não foi possível enviar o e-mail de redefinição de senha.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })
  return sendEmailResetPassword
}
