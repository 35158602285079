import { useHistory } from 'react-router-dom'

export function MobileMenuHeaderPage() {
  const history = useHistory()

  const pathname = history.location.pathname

  if (pathname.includes('/dashboard')) {
    return 'Dashboard'
  }

  if (pathname.includes('campaigns')) {
    return 'Campanhas'
  }

  if (pathname.includes('actions')) {
    return 'Ações'
  }

  if (pathname.includes('requests')) {
    return 'Doações'
  }

  if (pathname.includes('users/permissions')) {
    return 'Permissões'
  }

  if (pathname.includes('users')) {
    return 'Cadastros'
  }

  if (pathname.includes('generals')) {
    return 'Oficiais'
  }

  if (pathname.includes('agape')) {
    return 'Ágape'
  }

  if (pathname.includes('vocacional')) {
    return 'Vocacional'
  }

  if (pathname.includes('logs')) {
    return 'Logs'
  }
}
