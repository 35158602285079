import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'
import { notification } from 'antd'
import { LandingPageProps } from '../../../../pages/LandingPage/types/LandingPageProps'

type GetLandingPageByIdProps = {
  id: string
}

export function useGetLandingPageById({ id }: GetLandingPageByIdProps) {
  const getLandingPageById = useQuery<LandingPageProps, unknown>({
    queryKey: ['GET_LANDINGPAGE_BY_ID'],
    queryFn: async () => {
      try {
        const response = await DataService.get(`landpage/${id}`)
        return response.data
      } catch (error: any) {
        const errorMessage =
          (error.response?.data as { error?: string })?.error ||
          'Ocorreu um erro ao carregar. Por favor, tente novamente'
        // notification.error({ message: errorMessage });
        throw new Error(errorMessage)
      }
    },
  })

  return getLandingPageById
}
