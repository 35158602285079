export function AdminMenuDashboard() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={35}
        viewBox="0 0 45.000000 45.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,45.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M12 438 c-16 -16 -16 -210 0 -226 16 -16 160 -16 176 0 16 16 16 210 0 226 -7 7 -42 12 -88 12 -46 0 -81 -5 -88 -12z" />
          <path d="M262 438 c-16 -16 -16 -120 0 -136 16 -16 160 -16 176 0 16 16 16 120 0 136 -7 7 -42 12 -88 12 -46 0 -81 -5 -88 -12z" />
          <path d="M262 238 c-16 -16 -16 -210 0 -226 16 -16 160 -16 176 0 16 16 16 210 0 226 -16 16 -160 16 -176 0z" />
          <path d="M12 148 c-16 -16 -16 -120 0 -136 16 -16 160 -16 176 0 16 16 16 120 0 136 -16 16 -160 16 -176 0z" />
        </g>
      </svg>
    </div>
  )
}
