import { http, HttpResponse } from 'msw'
import { GetEstatisticasFamiliaResponse } from '../../../../../hooks/agape/queries/useGetEstatisticasFamilia'

const response = {
  familias_ativas: '9 - 100%',
  familias_cadastradas: '9 - Famílias ativas',
  familias_inativas: '0 - 0%',
  membros_por_familia: '2.7 pessoas',
  renda_media: '32.1 Salários minimos',
}

export const cardsEstatisticasFamiliasMock = http.get<never, never, GetEstatisticasFamiliaResponse>(
  '/agape/cards-estatisticas-familias-agape',
  () => {
    return HttpResponse.json(response)
  },
)
