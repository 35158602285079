import { CheckCircleTwoTone } from '@ant-design/icons';

export default function SuccessPage() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        padding: '2rem',
      }}
    >
      <CheckCircleTwoTone className='successIcon'/>

      <h1>Obrigado por registrar seus dados.</h1>
    </div>
  );
}
