import { InboxOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { BaseUpload } from './BaseUpload'
import { BaseNotification } from '../notification/BaseNotification'
import { FONT_SIZE, FONT_WEIGHT } from '../../../../styles/Themes/Admin/constants'

const DraggerWrapper = styled.div`
  .ant-upload.ant-upload-drag {
    &:hover {
      border-color: var(--input-border-color);
    }
  }
`

const DraggerIconWrapper = styled.div`
  font-size: 4rem;
  color: var(--primary-color);
`
const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`
const DraggerDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  padding: 0 1rem;
`

const uploadProps = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange: (info) => {
    const { status } = info.file
    if (status !== 'uploading') {
      console.log(info.file, info.fileList)
    }
    if (status === 'done') {
      BaseNotification.success({ message: 'Sucesso' })
    } else if (status === 'error') {
      BaseNotification.error({
        message: 'Erro',
        description: 'Houve um erro ao enviar!',
      })
    }
  },
}

export default function DraggerUpload({ ...props }) {
  return (
    <DraggerWrapper>
      <BaseUpload.Dragger {...uploadProps} {...props} style={{ marginBlock: '1rem' }}>
        <DraggerIconWrapper>
          <InboxOutlined />
        </DraggerIconWrapper>
        <DraggerTitle>Clique ou arraste na área para enviar</DraggerTitle>
        <DraggerDescription>Descrição</DraggerDescription>
      </BaseUpload.Dragger>
    </DraggerWrapper>
  )
}
