import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetAllFichaVocacionalProps {
  filters?: {
    queryString?: string | null
    genero?: 'masculino' | 'feminino'
    email?: string
    nome?: string
    documento_identidade?: string
    telefone?: string
    data_inicial?: string
    data_final?: string
    status?: 'aprovado' | 'pendente'
    fk_usuario_vocacional_id?: string
    page?: number
    per_page?: number
  }
}

export interface GetAllFichaVocacionalResponse {
  fichas_vocacionais: {
    cadastro_vocacional: {
      bairro: string
      cep: string
      cidade: string
      created_at: string
      data_nascimento: string
      detalhe_estrangeiro: string
      documento_identidade: string
      email: string
      estado: string
      fk_usuario_vocacional_id: number
      genero: 'masculino' | 'feminino'
      id: number

      nome: string
      numero: string
      pais: string
      responsavel: string
      rua: string
      status: string
      telefone: string
    }
    ficha_do_vocacional: {
      aceitacao_familiar: string
      cursos: string
      responsavel?: string
      deixou_religiao_anterior_em: string
      descricao_problema_saude: string
      escolaridade: string
      estado_civil: string
      fk_usuario_vocacional_id: number
      foto_vocacional: string
      identificacao_instituto: string
      motivacao_admissao_vocacional: string
      motivacao_instituto: string
      motivo_divorcio: string
      profissao: string
      referencia_conhecimento_instituto: string
      remedio_controlado_inicio: string
      remedio_controlado_termino: string
      rotina_diaria: string
      seminario_realizado_em: string
      testemunho_conversao: string
      status: 'pendente' | 'aprovado'
      sacramentos: string[]
    }
    pre_cadastro: {
      created_at: string
      email: string
      id: number
      nome: string
      pais: string
      responsavel: string
      status: string
      telefone: string
    }
  }[]

  page: number
  total: number
}

export function useFetchGetAllFichaVocacional({ filters }: GetAllFichaVocacionalProps) {
  const allFichaVocacional = useQuery({
    queryKey: ['GET_ALL_FICHA_VOCACIONAL', filters],
    queryFn: async () => {
      const response = await DataService.get<GetAllFichaVocacionalResponse>(
        `vocacional/listar-fichas-vocacionais?${filters?.queryString ?? ''}`,
      )
      return response.data
    },
  })

  return allFichaVocacional
}
