import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import {
  EnderecoBrasil,
  EnderecoEstrangeiro,
  Usuario,
} from '../../../../admin/hooks/users/Mutations/useRegisterCompleteUser'

export interface UpdateUserProps {
  endereco: EnderecoBrasil | EnderecoEstrangeiro
  usuario: Usuario
}

export function useUpdateUser() {
  const queryClient = useQueryClient()

  return useMutation<void, Error, UpdateUserProps>({
    mutationFn: ({ usuario, endereco }) => {
      const formData = new FormData()

      if (usuario && endereco) {
        formData.append('data', JSON.stringify({ usuario, endereco }))
      }

      return DataService.put(`users/update`, formData)
    },
    onSuccess: () => {
      notification.success({
        message: 'Perfil Atualizado',
        description: 'Seu perfil foi atualizado com sucesso.',
      })
      queryClient.invalidateQueries({ queryKey: ['USER_ME'] })
      queryClient.invalidateQueries({ queryKey: ['USERME_ADDRESS'] })
    },
    onError: (error: any) => {
      const errorDescription = error?.response?.data?.error || 'Um erro ocorreu ao atualizar seu perfil.'

      notification.error({
        message: 'Atualização Falhou',
        description: errorDescription,
      })
    },
  })
}
