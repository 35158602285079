import { Col, Form, FormInstance, Row, UploadFile } from 'antd'
import { countries } from '../../../../../../../../utility/allCountries'
import { StyledInput } from '../../../../../../../../container/benfeitor/components/Inputs'
import { validateCPF, validateDate } from '../../../../../../../../utility/utility'
import InputMask from 'react-input-mask'
import { StyledSelect } from '../../../../../../../../container/benfeitor/components/Select/styles'
import { useFetchUserMe } from '../../../../../../../admin/hooks'

import { UploadPhoto } from '../../../../../../../../container/benfeitor/pages/Campaign/CampaignIdentification/components/UploadPhoto'
import React from 'react'

interface StepOneUpdateModal {
  form: FormInstance
  hasFormBeenFetchedFromApi: boolean
  file: UploadFile[]
  setFile: React.Dispatch<React.SetStateAction<UploadFile[]>>
}

export function StepOneUpdateModal({ form, hasFormBeenFetchedFromApi, file, setFile }: StepOneUpdateModal) {
  const { data: userMe } = useFetchUserMe()

  return (
    <>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="pais"
            label="País"
            initialValue={'brasil'}
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <StyledSelect borderRadius={8} defaultValue="brasil" disabled={true} options={countries} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { type: 'email', message: 'Deve ser um e-mail válido' },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <StyledInput disabled={true} placeholder="E-mail" borderRadius={8} />
          </Form.Item>
        </Col>
      </Row>

      {userMe?.pais === 'brasil' ? (
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="cpf"
              label="CPF"
              rules={[{ min: 14, message: 'Mínimo 11 caracteres', required: true }, { validator: validateCPF }]}
            >
              <InputMask mask="999.999.999-99" maskChar={null} placeholder="999.999.999-99" disabled={true}>
                {(inputProps) => {
                  return <StyledInput {...inputProps} borderRadius={8} disabled={true} />
                }}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="identidade_estrangeira"
              label="Documento de Identidade"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório, digite o número do documento',
                },
              ]}
            >
              <StyledInput disabled type="number" placeholder="Número Documento de Identificação" borderRadius={8} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item
        name="nome"
        label="Nome"
        rules={[
          { min: 3, message: 'Mínimo 3 caracteres' },
          { required: true, message: 'Obrigatório' },
        ]}
      >
        <StyledInput placeholder="Nome Completo" borderRadius={8} border={'1.5px solid #d9d9d9'} />
      </Form.Item>

      <Form.Item label="Sexo" name="sexo" rules={[{ required: true, message: 'Por favor, selecione o sexo!' }]}>
        <StyledSelect placeholder="Selecione o sexo" disabled={userMe?.sexo}>
          <StyledSelect.Option value="masculino">Masculino</StyledSelect.Option>
          <StyledSelect.Option value="feminino">Feminino</StyledSelect.Option>
        </StyledSelect>
      </Form.Item>

      <UploadPhoto
        form={form}
        required={false}
        hasFormBeenFetchedFromApi={hasFormBeenFetchedFromApi}
        file={file}
        setFile={setFile}
      />

      <Form.Item
        name="nome_social"
        label="Como deseja ser chamado?"
        rules={[
          { min: 3, message: 'O nome social deve ter no mínimo 3 caracteres!' },
          { max: 45, message: 'O nome social deve ter no máximo 45 caracteres!' },
        ]}
      >
        <StyledInput placeholder="Nome social" borderRadius={8} border={'1.5px solid #d9d9d9'} />
      </Form.Item>

      <Form.Item
        name="telefone"
        label="Telefone"
        rules={[{ required: true, message: 'Por favor, insira seu telefone!' }]}
      >
        <InputMask mask="(99)99999-9999" name="telefone" maskChar={null} placeholder="Telefone">
          {(inputProps) => <StyledInput {...inputProps} style={{ gridColumn: 'span 1' }} />}
        </InputMask>
      </Form.Item>

      <Form.Item
        name="data_nascimento"
        label="Data de nascimento"
        rules={[
          { required: true, message: 'Por favor, preencha sua data de nascimento!' },
          {
            validator: (_, value) => validateDate(value),
          },
        ]}
      >
        <InputMask mask="99/99/9999" placeholder="dd/mm/yyyy">
          {(inputProps) => <StyledInput {...inputProps} style={{ gridColumn: 'span 1' }} />}
        </InputMask>
      </Form.Item>
    </>
  )
}
