import { useState } from 'react'
import { GetAllCadastroResponse } from '../../../../../hooks/vocacional/queries/useFetchGetAllCadastro'
import BaseButton from '../../../../../components/button/BaseButton'
import { ListaDetalhesCadastro } from './ListaDetalhesCadastro'
import BaseModal from '../../../../../components/modal/BaseModal'
import { BaseDivider } from '../../../../../components/divider/BaseDivider'
import { AtualizarStatusVocacional } from '../../AtualizarStatus'

interface DetalhesPreCadastroProps {
  usuario_vocacional_id: number
  dados: GetAllCadastroResponse['cadastros_vocacionais'][number]
  atualizarStatus?: boolean
}

export function DetalhesCadastro({ usuario_vocacional_id, dados, atualizarStatus }: DetalhesPreCadastroProps) {
  const [isOpen, setIsOpen] = useState(false)

  function handleOpenModal() {
    setIsOpen((prev) => !prev)
  }

  const modalTitle = atualizarStatus ? 'Aprove ou recuse' : 'Detalhes'

  return (
    <>
      <BaseButton severity="info" onClick={handleOpenModal}>
        Ver dados
      </BaseButton>

      <BaseModal title={<h3>{modalTitle}</h3>} open={isOpen} onCancel={handleOpenModal} footer={false} width={1000}>
        <ListaDetalhesCadastro dados={dados} />

        {atualizarStatus && (
          <>
            <BaseDivider type="horizontal" style={{ height: '100%' }} />

            <AtualizarStatusVocacional
              listagem="cadastro"
              usuario_vocacional_id={usuario_vocacional_id}
              acaoPosSucesso={handleOpenModal}
            />
          </>
        )}
      </BaseModal>
    </>
  )
}
