import styled from 'styled-components';
import { Popover } from 'antd';

export const StyledPopover = styled(Popover)``;

export const Title = styled.div`
  color: var(--text-main-color);
`;

export const Content = styled.div`
  color: var(--text-main-color);
  padding-block: 1rem;
`;
