import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DataService } from '../../../../../lib/dataService'
import { BaseNotification } from '../../../components/notification/BaseNotification'
import { notification } from 'antd'

export const useUpdateUserDetailsMutation = (userId) => {
  const queryClient = useQueryClient()

  const updateUserDetailsMutation = useMutation({
    mutationFn: async (updatedUser) => {
      const userData = {
        country: updatedUser.country,
        name: updatedUser.name,
        cpf_cnpj: updatedUser.cpf_cnpj,
        profile_id: updatedUser.profile_id,
        phone: updatedUser.phone,
        email: updatedUser.email,
        company_id: 1,
        avatar: updatedUser.avatar,
      }

      const userUpdatePromises = [DataService.put(`/administradores/editar-usuario/${userId}`, userData)]

      const [userDataResponse] = await Promise.all(userUpdatePromises)

      return { userDataResponse }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['USER_BY_ID', userId])
      queryClient.invalidateQueries(['ADDRESS_BY_ID', userId])
      notification.success({ message: 'Usuário atualizado com sucesso!' })
    },
    onError: (error) => {
      notification.error({
        message: 'Ocorreu um erro ao atualizar o usuário',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return updateUserDetailsMutation
}
