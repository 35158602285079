import { Trash } from '@phosphor-icons/react'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { Row } from 'antd'
import BaseModal from '../../../../components/modal/BaseModal'
import { useState } from 'react'
import BaseButton from '../../../../components/button/BaseButton'
import { PermissoesUsuario, useListUsersByProfile } from '../../../../hooks/users/Queries/useListUsersByProfile'
import { ColumnType } from 'antd/lib/table'
import { BaseAutoComplete } from '../../../../components/autocomplete/BaseAutoComplete'
import { BaseSpin } from '../../../../components/spin/BaseSpin.Styles'
import { EmptyResult } from '../../../../components/result/resultStatus/EmptyResult'
import { useEditPermissionProfile } from '../../../../hooks/users/Mutations/useEditPermissionProfile'

interface AllUsersByProfileTableProps {
  isUsersByProfileModalOpen: boolean
  setIsUsersByProfileModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedProfileId: number
}

export function AllUsersByProfileTable({
  isUsersByProfileModalOpen,
  setIsUsersByProfileModalOpen,
  selectedProfileId,
}: AllUsersByProfileTableProps) {
  const [searchedUser, setSearchedUser] = useState('')
  const [selectedUserId, setSelectedUserId] = useState('')

  const { data, isPending, isError } = useListUsersByProfile({ selectedProfileId })
  const { data: filteredUsers, isLoading: isLoadingFilteredUsers } = useListUsersByProfile({ searchedUser })

  const { mutateAsync: editPermissionProfile } = useEditPermissionProfile()

  async function handleEditPermissionProfile() {
    await editPermissionProfile({ selectedProfileId, selectedUserId: Number(selectedUserId) })

    setSelectedUserId('')
    setSearchedUser('')
  }

  const allUsersByProfileColumns: ColumnType<{
    id: number
    nome: string
    date: string
    status: string
    excluir: PermissoesUsuario
  }>[] = [
    { title: '#ID', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'nome', key: 'nome' },
    {
      title: 'Data de Criação',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    { title: 'Status', dataIndex: 'status', key: 'status', align: 'center' },
    {
      title: 'Excluir',
      dataIndex: 'excluir',
      key: 'excluir',
      render: (user: PermissoesUsuario) => {
        return (
          <Row justify="center">
            <BaseButton
              type="text"
              onClick={() => editPermissionProfile({ selectedUserId: user?.fk_usuario_id, selectedProfileId: 2 })}
            >
              <Trash size={32} />
            </BaseButton>
          </Row>
        )
      },
    },
  ]

  const allUsersByProfileDataSource = data?.permissoes_usuarios.map((user: PermissoesUsuario) => {
    return {
      id: user.id,
      nome: user.nome_usuario,
      date: user.data_criacao_usuario,
      status: user.status_usuario ? 'Ativo' : 'Inativo',
      excluir: user,
    }
  })

  return (
    <BaseModal
      style={{ padding: '0.5rem' }}
      bodyStyle={{ height: 'auto', maxHeight: '75vh', overflow: 'auto' }}
      open={isUsersByProfileModalOpen}
      closeIcon="X"
      width="70%"
      onOk={() => setIsUsersByProfileModalOpen(false)}
      onCancel={() => setIsUsersByProfileModalOpen(false)}
    >
      <BaseTable
        isPending={isPending}
        columns={allUsersByProfileColumns}
        isError={isError}
        dataSource={allUsersByProfileDataSource}
      />

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '2rem' }}>
        <BaseAutoComplete
          placeholder="Nome, E-mail ou CPF"
          allowClear
          showSearch
          value={selectedUserId}
          style={{ width: '15rem' }}
          options={filteredUsers?.permissoes_usuarios?.map((item) => {
            const { fk_usuario_id, nome_usuario } = item
            return { label: `${nome_usuario} (${fk_usuario_id})`, value: fk_usuario_id }
          })}
          onSearch={(value: string) => {
            setSearchedUser(value)
          }}
          onChange={(value: string) => {
            setSelectedUserId(value)
          }}
          notFoundContent={
            isLoadingFilteredUsers ? (
              <Row justify="center">
                <BaseSpin />
              </Row>
            ) : (
              <EmptyResult />
            )
          }
          children={undefined}
        />

        <BaseButton onClick={handleEditPermissionProfile}>Adicionar Usuário</BaseButton>
      </div>
    </BaseModal>
  )
}
