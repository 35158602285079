import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { DataService } from '../../../../../lib/dataService';
import { AxiosError } from 'axios';
import { useAuth } from '../../../../../context/Auth';

interface IChangeUserPassword {
  payload: {
    new_password: string;
    old_password: string;
  };
}

interface ErrorResponse {
  error: string;
}

export function useChangerUserPassword() {
  const { logout } = useAuth();

  const useChangerUserPassword = useMutation({
    mutationFn: ({ payload }: IChangeUserPassword) => {
      return DataService.post(`/users/change-password`, payload);
    },
    onSuccess: () => {
      notification.success({ message: 'Senha alterada com sucesso.' });
      logout();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      notification.error({
        message: error?.response?.data.error || 'Ocorreu um erro ao alterar a senha.',
      });
    },
  });

  return useChangerUserPassword;
}
