import { MagnifyingGlass } from '@phosphor-icons/react'
import BaseCard from '../../../../../components/card/BaseCard'
import { BaseInput } from '../../../../../components/inputs/BaseInput/BaseInput'
import moment from 'moment'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'
import BaseRadio from '../../../../../components/radio/BaseRadio'

type NomeFilterProps = {
  value: string | undefined
  handleInputChange: ({ nome }: { nome: string }) => void
}

function NomeFilter({ handleInputChange, value }: NomeFilterProps) {
  return (
    <BaseCard>
      <h4>Nome</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Nome"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ nome: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type EmailFilterProps = {
  value: string | undefined
  handleInputChange: ({ email }: { email: string }) => void
}

function EmailFilter({ handleInputChange, value }: EmailFilterProps) {
  return (
    <BaseCard>
      <h4>Email</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="E-mail"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ email: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type TelefoneFilterProps = {
  value: string | undefined
  handleInputChange: ({ telefone }: { telefone: string }) => void
}

function TelefoneFilter({ handleInputChange, value }: TelefoneFilterProps) {
  return (
    <BaseCard>
      <h4>Telefone</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        placeholder="Telefone"
        prefix={<MagnifyingGlass />}
        onChange={(e) => handleInputChange({ telefone: e.target.value })}
        value={value}
      />
    </BaseCard>
  )
}

type CadastroEmFilterProps = {
  value: { data_inicial: string; data_final: string } | undefined
  handleInputChange: ({ data_inicial, data_final }: { data_inicial: string; data_final: string }) => void
}

function CadastroEmFilter({ handleInputChange, value }: CadastroEmFilterProps) {
  const formattedValue = [
    value?.data_inicial ? moment(value.data_inicial, 'YYYY-MM-DD') : null,
    value?.data_final ? moment(value.data_final, 'YYYY-MM-DD') : null,
  ]

  return (
    <BaseCard>
      <h4>Cadastro em</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        style={{ width: '100%' }}
        onChange={(_: never, dateStrings: [string, string]) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

          handleInputChange({
            data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY"
        placeholder={['Inicial', 'Final']}
      />
    </BaseCard>
  )
}

type DocumentoIdentidadeFilterProps = {
  value: string | undefined
  handleInputChange: ({ documento_identidade }: { documento_identidade: string }) => void
}

function DocumentoIdentidadeFilter({ value, handleInputChange }: DocumentoIdentidadeFilterProps) {
  return (
    <BaseCard>
      <h4>Documento</h4>
      <BaseInput
        size="middle"
        allowClear
        placeholder="Digite o documento"
        prefix={<MagnifyingGlass />}
        value={value}
        onChange={(e) => handleInputChange({ documento_identidade: e.target.value })}
      />
    </BaseCard>
  )
}

type StatusFilterProps = {
  value: 'pendente' | 'aprovado' | undefined
  handleInputChange: ({ status }: { status: 'pendente' | 'aprovado' }) => void
}

function StatusFilter({ value, handleInputChange }: StatusFilterProps) {
  return (
    <BaseCard>
      <h4>Status</h4>
      <BaseRadio.Group onChange={(e) => handleInputChange({ status: e.target.value })} value={value}>
        <BaseRadio.Button value="pendente">Pendente</BaseRadio.Button>
        <BaseRadio.Button value="aprovado">Aprovado</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

export const FichaVocacionalFilters = {
  Nome: NomeFilter,
  Email: EmailFilter,
  Telefone: TelefoneFilter,
  CadastroEm: CadastroEmFilter,
  DocumentoIdentidade: DocumentoIdentidadeFilter,
  Status: StatusFilter,
}
