import { ReactNode } from 'react'
import { ListItemNumber, ListItemText } from './styles'

interface ListItemProps {
  number: string
  children: ReactNode
}

export function ListItem({ number, children }: ListItemProps) {
  return (
    <div style={{ display: 'flex', gap: '1.2rem', alignItems: 'center' }}>
      <ListItemNumber>{number}</ListItemNumber>
      <ListItemText>{children}</ListItemText>
    </div>
  )
}
