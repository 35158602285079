import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Endereco } from '../../../../../utility/endereco'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import { AbrangenciaCicloDoacao } from './useCadastrarCicloDoacao'

interface EditarCicloDoacaoProps {
  doacoes: {
    fk_estoque_agape_id: number
    quantidade: number
  }[]
  endereco: Endereco
  fk_instancia_acao_agape_id: number
  abrangencia: AbrangenciaCicloDoacao
}

export function useEditarCicloDoacao() {
  const queryClient = useQueryClient()

  const cadastroCicloDoacao = useMutation({
    mutationFn: async ({ doacoes, endereco, fk_instancia_acao_agape_id, abrangencia }: EditarCicloDoacaoProps) => {
      await DataService.put(`/agape/editar-ciclo-acao-agape/${fk_instancia_acao_agape_id}`, {
        doacoes,
        endereco,
        abrangencia,
      })
    },
    onSuccess: () => {
      notification.success({ message: 'Ciclo de doação editado com sucesso!' })
      queryClient.invalidateQueries({ queryKey: ['ITENS_ESTOQUE'] })
      queryClient.invalidateQueries({ queryKey: ['CICLOS_DOACOES'] })
      queryClient.invalidateQueries({ queryKey: ['HISTORICO_MOVIMENTACOES'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_ESTOQUE'] })
      queryClient.invalidateQueries({ queryKey: ['ENDERECOS_BENEFICIARIOS_CICLO_DOACAO'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao editar ciclo de doação',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return cadastroCicloDoacao
}
