import { Col, Row } from 'antd'
import BaseButton from '../../../../../../components/button/BaseButton'
import { Beneficiados } from '../../../Beneficiados'
import { useState } from 'react'

interface AcoesFinalizadoProps {
  idCicloDoacao: number
  nomeAcao: string
}

export function AcoesFinalizado({ idCicloDoacao, nomeAcao }: AcoesFinalizadoProps) {
  const [isOpenBeneficiados, setIsOpenBeneficiados] = useState(false)

  function handleIsOpenBeneficiados() {
    setIsOpenBeneficiados((prev) => !prev)
  }

  return (
    <>
      <Row justify="center">
        <Col>
          <BaseButton severity="info" onClick={handleIsOpenBeneficiados}>
            Beneficiados
          </BaseButton>
        </Col>
      </Row>

      <Beneficiados
        open={isOpenBeneficiados}
        handleIsOpenBeneficiados={handleIsOpenBeneficiados}
        idCicloDoacao={idCicloDoacao}
        nomeAcao={nomeAcao}
      />
    </>
  )
}
