import React from 'react'
import PropTypes from 'prop-types'
import { PageHeaderStyle } from './style'

const PageHeader = (props) => {
  const { title, subTitle, routes, buttons, ghost, bgColor, className } = props
  return (
    <PageHeaderStyle
      // onBack={() => window.history.back()}
      className={className}
      title={title}
      subTitle={subTitle}
      breadcrumb={routes && { routes }}
      extra={buttons}
      ghost={ghost}
    />
  )
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgColor: PropTypes.string,
  className: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.array,
  ghost: PropTypes.bool,
}

export { PageHeader }
