import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { format } from 'date-fns'
import { CalendarHeader, CalendarWrapper, ColoredButton, StyledCalendar, StyledCalendarFooter } from './Style'
import 'moment/dist/locale/pt-br'

import BaseSpin from '../../../../components/spin/BaseSpin'

export function CalendarLives({
  availableLiveDates,
  livesListOnSelectedDate,
  selectedDate,
  setSelectedDate,
  setLiveDetailToBeFetched,
  liveDetailToBeFetched,
}) {
  const headerRender = ({ value, onChange }) => {
    const current = value.clone()

    return (
      <CalendarHeader key={value.format('YYYY-MM')}>
        <button
          type="button"
          onClick={() => {
            const newDate = current.subtract(1, 'month')
            onChange(newDate)
            setSelectedDate(newDate.startOf('month'))
          }}
        >
          <LeftOutlined className="icon" />
        </button>

        <h3>
          {moment(value)
            .format('MMMM - YYYY')
            .replace(/^\w/, (c) => c.toUpperCase())}
        </h3>

        <button
          type="button"
          onClick={() => {
            const newDate = current.add(1, 'month')
            onChange(newDate)
            setSelectedDate(newDate.startOf('month'))
          }}
        >
          <RightOutlined className="icon" />
        </button>
      </CalendarHeader>
    )
  }

  const isDisabledData = (current) => {
    if (!current || !availableLiveDates?.data?.datas) return true
    const formattedDate = format(current.toDate(), 'yyyy-MM-dd')
    return !availableLiveDates?.data?.datas.includes(formattedDate)
  }

  return (
    <CalendarWrapper>
      {/* {availableLiveDates?.data && ( */}
      <StyledCalendar
        value={selectedDate}
        disabledDate={isDisabledData}
        fullscreen={false}
        onSelect={(date) => setSelectedDate(date)}
        headerRender={headerRender}
      />
      {/* )} */}
      {availableLiveDates?.isPending && <BaseSpin style={{ padding: '10rem', width: '100%' }} />}
      {availableLiveDates?.isError && (
        <h4 style={{ padding: '15rem 0', margin: 'auto', color: 'red', textAlign: 'center' }}>Ocorreu um erro...</h4>
      )}

      {!livesListOnSelectedDate?.isError && livesListOnSelectedDate?.data && (
        <>
          <h4 style={{ textAlign: 'center' }}>
            {moment(selectedDate)
              .format('D [de] MMMM [de] YYYY')
              .replace(/\b\w/g, (char) => char.toUpperCase())}
          </h4>

          {livesListOnSelectedDate?.data?.titulos?.map((live) => (
            <StyledCalendarFooter key={live?.titulo}>
              <div className="live-wrapper">
                <div
                  role="presentation"
                  className="live"
                  onClick={() => {
                    setLiveDetailToBeFetched(live)
                  }}
                  style={{
                    fontWeight: liveDetailToBeFetched?.titulo === live?.titulo ? 'bold' : 'normal',
                  }}
                >
                  <ColoredButton />
                  {live?.titulo}
                </div>
                <div className="liveDate">
                  {live?.inicio} - {live?.fim}
                </div>
              </div>
            </StyledCalendarFooter>
          ))}
          {livesListOnSelectedDate?.data?.titulos?.length < 1 && (
            <h4 style={{ color: '#bcbbbb', textAlign: 'center', padding: '0.5rem' }}>
              Nenhuma live encontrada esse dia
            </h4>
          )}
        </>
      )}

      {livesListOnSelectedDate?.isError && (
        <>
          <h4 style={{ textAlign: 'center' }}>
            {moment(selectedDate)
              .format('D [de] MMMM [de] YYYY')
              .replace(/\b\w/g, (char) => char.toUpperCase())}
          </h4>
          <h4 style={{ margin: 'auto', color: 'red', padding: 30, textAlign: 'center' }}>
            Ocorreu um erro ao listar as lives desse dia...
          </h4>
        </>
      )}
    </CalendarWrapper>
  )
}
