import { Upload } from 'antd';
import styled from 'styled-components';

export const StyledUpload = styled(Upload)`
  .ant-upload-list-item {
    padding: 1rem;
    width: 15rem;
    background-color: ${(props) => props.theme.colors['white-cream']} !important;
  }

  .ant-upload-list-item.ant-upload-list-item-list-type-picture-card {
    width: 8rem;
    height: 8rem;
    border: 3px solid ${(props) => props.theme.fonts.color.primary};
    border-radius: 50%;
    overflow: hidden;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .ant-upload-select-picture-card {
    background-color: ${(props) => props.theme.colors['white-cream']};
    border: 2px solid ${(props) => props.theme.fonts.color.primary};
    width: 8rem;
    height: 8rem;
    border-radius: 50%;

    :hover {
      border-color: ${(props) => props.theme.colors['gray-400']};
    }

    .ant-upload {
      color: ${(props) => props.theme.fonts.color.primary};
    }
  }

  .ant-upload-list-item-name {
    max-width: 100px; /* Largura máxima do texto */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.theme.fonts.color.primary};
  }

  .ant-upload-list.ant-upload-list-picture {
    color: ${(props) => props.theme.fonts.color.primary};

    .ant-upload-list-item-info {
      .ant-upload-span {
        justify-content: space-between;
        .ant-upload-list-item-card-actions {
          svg {
            color: ${(props) => props.theme.fonts.color.primary};
          }
        }
      }
    }
  }
`;
