import { motion } from 'motion/react'
import { ChartSection } from '../../../styles'
import { springTransition } from '../constants'
import { CarouselChartProps } from '../types'
import { getCorrespondingCardTitle } from '../utils'

export function CarouselChart({ activeTab, slides, type }: Readonly<CarouselChartProps>) {
  return (
    <ChartSection>
      <motion.div
        key={`chart-${activeTab}`}
        layoutId={`card-${getCorrespondingCardTitle(activeTab, type)}`}
        transition={springTransition}
      >
        {slides[activeTab].chart}
      </motion.div>
    </ChartSection>
  )
}
