import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from 'recharts'
import { useState } from 'react'
import { ChartTitle, TooltipContainer, formatChartNumber } from '../../../styles'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { useBlur } from '@/context/Blur/BlurContext'

export function Chart({ data }) {
  const [barHovered, setBarHovered] = useState(false)
  const [focusBar, setFocusBar] = useState(null)

  const formattedData = data?.map(
    (day) =>
      ({
        dia: day.dia.split('-')[0].trim(),
        total: parseFloat((day.Boleto || 0) + parseFloat(day.Credito || 0) + parseFloat(day.Pix || 0)),
      } || []),
  )

  const CustomTooltip = ({ payload }) => {
    const total = payload?.[0]?.payload?.total
    const dia = payload?.[0]?.payload?.dia

    return (
      <TooltipContainer type="finance">
        <strong>{dia}</strong>
        <BlurDiv>
          <strong>{formatToBrazilianCurrency(total)}</strong>
        </BlurDiv>
      </TooltipContainer>
    )
  }

  const CustomAxis = ({ x, y, stroke, payload }) => {
    const { isBlurred } = useBlur()

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={-12}
          dy={16}
          textAnchor="end"
          fill="var(--text-main-color)"
          className={isBlurred ? 'blur' : 'no-blur'}
        >
          {formatChartNumber(payload.value)}
        </text>
      </g>
    )
  }

  return (
    <>
      <ChartTitle>Por dia da Semana</ChartTitle>
      <div style={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={formattedData || []} margin={{ top: 20, left: 0, right: 0, bottom: 20 }}>
            <CartesianGrid stroke="#444" vertical={false} strokeOpacity={0.6} />
            <XAxis
              axisLine={false}
              dataKey="dia"
              tick={{ fill: '#6d6d6d', fontWeight: 'bold' }}
              orientation="bottom"
              tickMargin={15}
            />
            <YAxis axisLine={false} tick={<CustomAxis />} tickMargin={15} />

            <Tooltip cursor={false} active={barHovered} content={CustomTooltip} />
            <Bar
              dataKey="total"
              radius={[8, 8, 0, 0]}
              barSize={40}
              onMouseEnter={() => setBarHovered(true)}
              onMouseLeave={() => setBarHovered(false)}
            >
              {formattedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={focusBar === index ? 'url(#greenGradientOnHover)' : 'url(#greenGradientType1)'}
                  onMouseEnter={() => setFocusBar(index)}
                  onMouseLeave={() => setFocusBar(null)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
