import styled from 'styled-components'
import validationPageBg from '../../images/validation-page-bg.png'

export const Container = styled.div`
  background-image: url(${validationPageBg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  /* background-attachment: fixed; */
  /* height: 100vh; */
  /* overflow: scroll; */
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5rem;

  .logo-wrapper {
    padding-top: 2.5rem;
    margin-bottom: 3rem;

    img {
      max-width: 250px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 600px) {
      img {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .content-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .video {
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 1200px;
    display: flex;
    justify-content: center;
  }

  .text-wrapper {
    text-align: center;
    color: #efe5d8;
    line-height: 30px;
    font-weight: 400;
    font-size: 18px;

    span {
      font-weight: 700;
    }

    @media (min-width: 600px) {
      font-size: 24px;
      margin-top: 4.5rem;
    }
  }

  .button-wrapper {
    margin-top: 3.5rem;

    div {
      font-size: 18px !important;
      font-weight: 700 !important;
      padding: 1rem 2rem;
    }

    @media (min-width: 600px) {
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 4.5rem;
    }
  }
`
