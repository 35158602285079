import React, { useState } from 'react'
import { Col, Image, notification, Row } from 'antd'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import FeatherIcon from 'feather-icons-react'
import { ActionDetails } from './Style'
import { useGetActionDetails } from '../../hooks/actions/queries/useGetActionDetails'
import BaseCard from '../../components/card/BaseCard'
import BaseSpin from '../../components/spin/BaseSpin'
import { handleFilterChangeAndPagination } from '../../../../utility/utility'
import { useFetchImportedUsers } from '../../hooks'
import { BaseTable } from '../../components/table/BaseTable/BaseTable'
import { ErrorResult } from '../../components/result/resultStatus/ErrorResult'
import BaseModal from '../../components/modal/BaseModal'
import BaseButton from '../../components/button/BaseButton'
import { BaseNotification } from '../../components/notification/BaseNotification'
import { BaseList, Item } from '../../components/list/BaseList'
import { BaseTag } from '../../components/tag/BaseTag'
import { BaseInput } from '../../components/inputs/BaseInput/BaseInput'
import BaseDatePicker from '../../components/datepicker/BaseDatePicker'
import BaseSwitch from '../../components/switch/BaseSwitch'
import { BaseLink } from '../../components/link/BaseLink'

const copyEmbed = (nome, id) => {
  const baseUrl = new URL(window.location.href).origin
  const embedUrl = `${baseUrl}/embed/action/${nome}/${id}`
  const iframe = `<iframe width="1800" height="500" id="embeddedFrame" src="${embedUrl}?bg-color=transparent&label-color=white&sucesso-color=green" allowfullscreen style="display: block; margin: 0 auto; border: none;"></iframe>`
  navigator.clipboard
    .writeText(iframe)
    .then(() => {
      notification.success({
        message: <div style={{ fontWeight: 'bold', color: '#50556f' }}>Código do formulário copiado</div>,
        description: (
          <div>
            <div style={{ color: '#757ca3', fontWeight: 600 }}>{nome}</div>
          </div>
        ),
      })
    })
    .catch((error) => {
      notification.error({
        message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
      })
      console.error('Falha ao copiar o valor para a área de transferência:', error)
    })
}

const IntentionModal = ({ usuario, intencao }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOpen = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <div
        style={
          intencao !== null
            ? { color: 'var(--info-color)', cursor: 'pointer', display: 'inline-block' }
            : { display: 'inline-block' }
        }
        role="button"
        tabIndex={0}
        onClick={() => {
          if (intencao !== null) {
            handleOpen()
          }
        }}
      >
        {usuario}
      </div>
      <BaseModal
        centered
        title={usuario}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <BaseButton key="back" onClick={handleCancel}>
            Fechar
          </BaseButton>,
        ]}
      >
        {intencao}
      </BaseModal>
    </>
  )
}

export default function ActionsDetails() {
  const { id, name } = useParams()

  // Gerenciamento de filtros e query string para busca de usuários importados
  const [filters, setFilters] = useState({
    origem: name,
    page: 1,
    queryString: `filtro_origem=${encodeURIComponent(name)}&page=1`,
  })

  // Query 1: Detalhes da ação
  const { data: action, isLoading: isLoadingAction, isError: isErrorActions } = useGetActionDetails(id)

  // Query 2: Usuários importados
  const { data: importedUser, isLoading: isLoadingImported, isError: isErrorImported } = useFetchImportedUsers(filters)
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  // Colunas da tabela de usuários
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      filterDropdown: () => (
        <BaseInput
          size="middle"
          allowClear
          placeholder="Nome"
          prefix={<FeatherIcon icon="search" size={14} />}
          onChange={(e) => handleInputChange({ filtro_nome: e.target.value })}
        />
      ),
      filteredValue: filters?.filtro_nome && [filters?.filtro_nome],
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      filterDropdown: () => (
        <BaseInput
          size="middle"
          allowClear
          placeholder="E-mail"
          prefix={<FeatherIcon icon="search" size={14} />}
          onChange={(e) => handleInputChange({ filtro_email: e.target.value })}
        />
      ),
      filteredValue: filters?.filtro_email && [filters?.filtro_email],
    },
    { title: 'Telefone', dataIndex: 'telefone' },
    { title: 'Origem', dataIndex: 'origem_cadastro' },
    {
      title: 'Cadastro em',
      dataIndex: 'data_criacao',
      align: 'Center',
      filterDropdown: () => (
        <BaseDatePicker
          range
          onChange={(dates, dateStrings) => {
            // console.log(dateStrings)

            const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
            const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

            handleInputChange({
              filtro_data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
              filtro_data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
            })
          }}
          format="DD/MM/YYYY"
          placeholder={['Inicial', 'Final']}
        />
      ),
      filteredValue:
        filters?.filtro_data_inicial ||
        (filters?.filtro_data_final && [filters?.filtro_data_inicial, filters?.filtro_data_final]),
    },
    {
      title: 'Download',
      dataIndex: 'download',
      align: 'center',
      filterDropdown: () => (
        <BaseCard style={{ height: '100%' }}>
          <h4>Fotos não baixadas</h4>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <BaseSwitch
              onChange={(e) => {
                handleInputChange({ filtrar_por_nao_baixadas: e })
              }}
            />
          </div>
        </BaseCard>
      ),
      filteredValue: filters?.filtrar_por_nao_baixadas && [filters?.filtrar_por_nao_baixadas],
    },

    { title: 'Sorteado', dataIndex: 'sorteado', align: 'center' },
  ]

  // Renderização condicional para leads importados (Tabela)
  const renderImportedUsersTable = () => {
    const dataSource = importedUser?.leads?.map((user) => ({
      key: user.id,
      id: user.id,
      nome: <IntentionModal intencao={user.intencao} usuario={user.nome} />,
      email: user.email,
      telefone: user.telefone,
      origem_cadastro: user.origem,
      data_criacao: user?.criacao,
      sorteado: (
        <BaseTag color={user?.sorteado ? 'success' : user?.sorteado === null ? 'default' : 'error'}>
          {user?.sorteado ? 'Sim' : user?.sorteado === null ? '-' : 'Não'}
        </BaseTag>
      ),
      download: user?.download && (
        <BaseLink href={`/admin/users/details/${user?.download_usuario_id}`}>{`${user?.download}`}</BaseLink>
      ),
    }))

    return (
      <BaseCard>
        <h3>Leads da Ação</h3>
        <BaseTable
          columns={columns}
          dataSource={dataSource}
          isLoading={isLoadingImported}
          isError={isErrorImported}
          pagination={{
            page: importedUser?.page,
            total: importedUser?.total,
            showSizeChanger: true,
          }}
          onChange={(pagination) => {
            handleInputChange({ page: pagination.current }, true)
            handleInputChange({ per_page: pagination.pageSize }, true)
          }}
        />
      </BaseCard>
    )
  }

  // Renderização condicional para detalhes da ação
  const renderActionDetails = () => {
    if (isLoadingAction) {
      return (
        <BaseCard style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <BaseSpin />
        </BaseCard>
      )
    }

    if (isErrorActions) {
      return (
        <BaseCard style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <ErrorResult subTitle="Houve um erro ao listar os detalhes desta ação." />
        </BaseCard>
      )
    }

    return (
      <BaseCard>
        <h3>Dados da ação</h3>
        <h4 style={{ textAlign: 'center', marginTop: '1rem' }}>{action.nome}</h4>
        <ActionDetails>
          <div>
            Ação ativa: <span>{action.status ? 'Sim' : 'Não'}</span>
          </div>
          <div>
            Total de Leads: <span>{action.total_leads}</span>
          </div>
          <div>
            Cadastrada em: <span>{action.criado_em}</span>
          </div>
          {action.preenchimento_foto && (
            <div>
              Label da foto: <span>{action.label_foto}</span>
            </div>
          )}
          <div>
            Descrição: <span>{action.descricao}</span>
          </div>
          {action.status && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              Embutir:{' '}
              <BaseButton
                style={{ padding: '10px', fontSize: '0.8rem' }}
                icon={<FeatherIcon icon="copy" />}
                onClick={() => copyEmbed(action.nome, action.id)}
              >
                Copiar
              </BaseButton>
            </div>
          )}
          {action.sorteados?.length > 0 && (
            <BaseList
              size="small"
              header="Sorteados"
              bordered
              dataSource={action?.sorteados}
              renderItem={(item) => <Item>{item}</Item>}
            />
          )}
          {action.banner && (
            <>
              {' '}
              Banner:
              <br />
              <Image src={action.banner} alt="Banner" />{' '}
            </>
          )}
          {action.background && (
            <>
              {' '}
              Background:
              <br />
              <Image src={action.background} alt="Background" />{' '}
            </>
          )}
        </ActionDetails>
      </BaseCard>
    )
  }

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} lg={8}>
        {renderActionDetails()}
      </Col>
      <Col xs={24} lg={16}>
        {renderImportedUsersTable()}
      </Col>
    </Row>
  )
}
