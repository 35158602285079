import { BASE_COLORS } from '../constants'

// const chartColors = {
//   chartTooltipLabel: '#6A7985',
//   chartColor1: '#01509A',
//   chartColor1Tint: '#2983D8',
//   chartColor2: '#35A0DC',
//   chartColor2Tint: '#67C5FA',
//   chartColor3: '#FFB155',
//   chartColor3Tint: '#FFA800',
//   chartColor4: '#31A652',
//   chartColor4Tint: '#89DCA0',
//   chartColor5: '#FF5252',
//   chartColor5Tint: '#FFC1C1',
//   chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
//     {
//       offset: 0,
//       color: 'rgba(0, 110, 211, 0.5)',
//     },
//     {
//       offset: 1,
//       color: 'rgba(255, 225, 255, 0)',
//     },
//   ]),
//   chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
//     {
//       offset: 0,
//       color: 'rgba(255, 82, 82, 0.5)',
//     },
//     {
//       offset: 1,
//       color: 'rgba(255, 255, 255, 0)',
//     },
//   ]),
//   chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
//     {
//       offset: 0,
//       color: 'rgba(255, 255, 255, 0)',
//     },
//     {
//       offset: 1,
//       color: 'rgba(255, 82, 82, 0.5)',
//     },
//   ]),
// };

export const newLightColorsTheme = {
  backgroundColor: '#f2f2f2',

  textMainColor: '#393939',
  textSecondaryColor: '#5C5C5C',

  logoTextColor: '#1C1211',

  sidebarBackgroundColor: '#dddddd',

  sidebarTextColor: '#1A1A1A',
  sidebarButtonBackgroundColor: '#958e86',
  sidebarSubmenuTextColor: '#2d2d2f',
  sidebarActiveSubmenuTextColor: '#000000',

  sidebarSelectedMenuBackgroundColor: '#cacaca',
  sidebarSelectedMenuColor: '#797979',
  sidebarSelectedMenuColorHover: '#676767',

  textSideButtonColor: '#2b1d1c',

  collapseBackgroundColor: '#1d1513',
  borderColor: '#2a1a17',

  tableCellColor: '#FEF9F6',
  // tableFontHoverColor: '#2D2624',
  tableCellHoverColor: '#e1e1e1',
  tableBorderColor: '#8a8a8a',
  tableHeaderBg: '#e1e1e1',
  tableHeaderColor: '#454545',

  cardBgColor: '#f2f2f2',
  cardBorder: '1px solid #bdbdbd',
  positiveBalanceColor: '#3caf41',
  negativeBalanceColor: '#752c29',
  buttonBgColor: '#686767',
  buttonBgHoverColor: '#4d4c4c',
  buttonTextColor: '#F0F0F0',
  buttonTextHoverColor: '#b0b0b0',
  successColor: '#059669',
  infoColor: '#407fcc',
  warningColor: '#e0a561',
  errorColor: '#d0544e',
  uncheckedSwitchColor: '#6b635e',
  notificationSuccess: '#d3ffe4',
  notificationInfo: '#b5d7ff',
  notificationWarning: '#ffe4c4',
  notificationError: '#ffc4c4',
  trailColor: '#766765',
  strokeColor: '#2D2624',
  inputBorderColor: '#595959',

  chartColor: '#16751e',
  chartColorFadeStop: '#1cb429',
  chartTooltipBg: '#e2e2e2',
  chartTooltipColor: '#0f5915',
  chartColorHover: '#305e38',
  chartColorHoverEnd: '#46a047',
  chartPieLegendColor: '#505050',

  modalBackgroundColor: '#eeeeee',
  modalText: BASE_COLORS.black,
  modalFirstBtnColor: '#6a6a6a',
  modalFirstBtnColorHover: BASE_COLORS.black,
  modalSecondBtnBgColor: '#6a6a6a',
  modalSecondBtnBgColorHover: '#3a3a3a',
  modalBorderColor: '#595959',

  campaignDetailsPrimaryText: '#2d2624',
  campaignDetailsSecondaryText: '#2d2624',
  campaignCardColor: '#2D2624',
  campaignCardBgColor: '#fef9f5',
  campaignDetailsBgColor: '#2d2624',
  campaignDetailsColor: '#e8e8e8',

  calendarHeader: '#d3d3d3',
  calendarWeekDays: '#1a1a1a',
  calendarDisabledDays: '#323232',
  calendarSelectedDayBg: '#0f0f0f',
  calendarSelectedDayColor: '#cfcaca',
  calendarFooterLiveColor: '#3b3b3b',
  calendarIconColor: '#454545',
  calendarFooterLiveBorderColor: '#7e7e7e',
  calendarNotDisabledDays: '#0f0f0f',

  themePickerButtonBgColor: '#cfcaca',
  themePickerSelectedThemeBgColor: '#f2f2f2',
  themePickerSelectedThemeIconColor: '#5d5d5d',
  rightSectionBgColor: '#e2e2e2',

  animatedCardBgColor: '#fafafa',
  customTableBgColor: '#c2c2c2',
  customTableBgColorHover: '#b5b5b5',
  filterIconColor: '#000000',

  signupChartColor: '#065fc9',
  signupChartColorStroke: '#065fc9',
  signupChartTooltipBg: '#e0e0e0',
  signupChartTooltipColor: '#538fc9',
  signupChartColorFadeStop: '#7aaad9',
  signupChartColorHover: '#2e5f94',
  signupChartColorHoverEnd: '#5d8ab4',

  separatorColor: '#b5a5a5',
  lighterColorChart: '#4a4a4a',

  collapseBoxBackgroundColor: '#f2f2f2',
}

export const lightColorsTheme = {
  backgroundColor: '#f4f4f4',

  textMainColor: '#2A1F1E',
  textSecondaryColor: '#838383',

  logoTextColor: '#362A28',

  sidebarBackgroundColor: '#dadada',
  sidebarBackgroundDarkerColor: '#cdcdcd',
  sidebarSelectedColor: '#272727',
  sidebarTextColor: '#272727',
  sidebarButtonBackgroundColor: '#b0a8a0',
  sidebarSubmenuTextColor: '#4e4e51',
  sidebarActiveSubmenuTextColor: 'black',

  textSideButtonColor: '#362a28',

  collapseBackgroundColor: '2a201e',
  borderColor: '#362a28',
  tableCellColor: '#3E3331',
  tableFontHoverColor: '#3E3331',
  tableCellHoverColor: '#dddddd',
  tableBorderColor: '#b3b3b3',
  tableHeaderBg: '#cbcbcb',
  tableHeaderColor: '#5d5e5e',
  cardBorder: '1px solid #dadada',
  positiveBalanceColor: '#4cc954',
  negativeBalanceColor: '#8e3935',
  buttonBgColor: '#818080',
  buttonBgHoverColor: '#6a6969',
  buttonTextColor: '#F5F5F5',
  buttonTextHoverColor: '#c2c2c2',
  successColor: '#57D682',
  infoColor: '#539AF6',
  warningColor: '#F4BA72',
  errorColor: '#EC5F59',
  uncheckedSwitchColor: '#837B76',
  notificationSuccess: '#EFFFF4',
  notificationInfo: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  trailColor: '#9A8986',
  strokeColor: '#3E3331',
  inputBorderColor: '#707070',

  chartColor: '#1E9025',
  chartColorFadeStop: '#28cc32',
  chartTooltipBg: '#F0F0F0 ',
  chartTooltipColor: '#186b1d',
  chartColorHover: '#3c7540',
  chartColorHoverEnd: '#58c258',
  chartPieLegendColor: 'gray',

  modalBackgroundColor: '#e7e7e7',
  modalText: BASE_COLORS.black,
  modalFirstBtnColor: '#7f7f7f',
  modalFirstBtnColorHover: BASE_COLORS.black,
  modalSecondBtnBgColor: '#7f7f7f',
  modalSecondBtnBgColorHover: '#4c4c4c',
  modalBorderColor: 'gray',

  campaignDetailsPrimaryText: '#3e3331',
  campaignDetailsSecondaryText: '#3e3331',
  campaignCardColor: '#3E3331',
  campaignCardBgColor: '#fef9f5',
  campaignDetailsBgColor: '#3e3331',
  campaignDetailsColor: '#f5f5f5',

  calendarHeader: '#e8e8e8',
  calendarWeekDays: 'black',
  calendarDisabledDays: '#464646',
  calendarSelectedDayBg: '#1f1d1d',
  calendarSelectedDayColor: '#DEDBD9',
  calendarFooterLiveColor: '#464646',
  calendarIconColor: '#616160',
  calendarFooterLiveBorderColor: '#a6a6a6',
  calendarNotDisabledDays: '#1f1d1d',

  themePickerButtonBgColor: '#DEDBD9',
  themePickerSelectedThemeBgColor: '#FFFFFF',
  themePickerSelectedThemeIconColor: '#767676',
  rightSectionBgColor: '#e7e7e7',

  animatedCardBgColor: '#f5f5f5',
  customTableBgColor: '#d9d9d9',
  customTableBgColorHover: '#cccccc',
  filterIconColor: 'black',

  signupChartColor: '#0874FF',
  signupChartColorStroke: '#0874FF',
  signupChartTooltipBg: '#f4f4f4',
  signupChartTooltipColor: '#64a1ef',
  signupChartColorFadeStop: '#9abeee',
  signupChartColorHover: '#4076bb',
  signupChartColorHoverEnd: '#7a9bbb',

  separatorColor: '#dcd4d4',
  lighterColorChart: 'gray',

  collapseBoxBackgroundColor: 'white',
}
