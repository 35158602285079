import { useEffect, useState } from 'react'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'
import { useGetListagemVoluntarios } from '../../../../../hooks/agape/queries/useGetListagemVoluntarios'
import { columns } from './Colunas'
import { RemoverVoluntario } from './RemoverVoluntario'
import { TablePaginationConfig } from 'antd'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'

interface TabelaVoluntariosProps {
  setNumVoluntarios: (num: number | null) => void
}

export function TabelaVoluntarios({ setNumVoluntarios }: TabelaVoluntariosProps) {
  const [filters, setFilters] = useState({})

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const {
    data: listagemVoluntarios,
    isLoading: isLoadingVoluntarios,
    isError: isErrorVoluntarios,
  } = useGetListagemVoluntarios({ filters })

  useEffect(() => {
    if (listagemVoluntarios?.total) {
      setNumVoluntarios(listagemVoluntarios?.total)
    }
  }, [listagemVoluntarios?.total])

  const dataSource = listagemVoluntarios?.voluntarios.map((item) => {
    const { id, nome, data_inicio, data_ultimo_acesso } = item

    return {
      id,
      nome,
      data_inicio,
      data_ultimo_acesso: data_ultimo_acesso ?? ' - ',
      acoes: <RemoverVoluntario fk_usuario_id={id} nome_voluntario={nome} />,
    }
  })

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingVoluntarios}
      isError={isErrorVoluntarios}
      pagination={{
        current: listagemVoluntarios?.page,
        total: listagemVoluntarios?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
    />
  )
}
