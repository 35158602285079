import { Divider, Form } from 'antd'
import { DividerText, ModalTitle, NoAccountTitle } from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { closeModals, openSignUpModal } from '../authModalsSlice'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../../../../../../context/Auth'
import { StyledModal } from '../../../../../../../../container/benfeitor/components/Modal'
import { StyledInput } from '../../../../../../../../container/benfeitor/components/Inputs'
import { StyledButton } from '../../../../../../../../container/benfeitor/components/Button'
import { AppDispatch, RootState } from '../../../../../../../../redux/rootReducers'
import { useFetchUserMe } from '../../../../../../../admin/hooks'

export function LoginModal() {
  const dispatch = useDispatch<AppDispatch>()

  const history = useHistory()
  const userMe = useFetchUserMe()

  const [form] = Form.useForm()
  const { isLoginModalOpen } = useSelector((state: RootState) => state.authModals)
  const { login } = useAuth()

  const handleSubmit = async (values: { username: string; password: string }) => {
    const payload = {
      email: values.username,
      password: values.password,
    }

    await login(payload)
      .then(() => dispatch(closeModals()))
      .then(() => {
        userMe
          .refetch()
          .then((res) => res.data)
          .then((user) => {
            if (user?.super_perfil) {
              if (user?.fk_perfil_id === 1) {
                history.push('/admin/dashboard/financeiro')
                return
              }

              history.push('/admin')
            }
          })
      })
  }

  return (
    <StyledModal
      footer={false}
      open={isLoginModalOpen}
      onCancel={() => dispatch(closeModals())}
      onOk={() => dispatch(closeModals())}
    >
      <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
        <ModalTitle>Login</ModalTitle>
        <NoAccountTitle>
          Ainda não tem conta? <span onClick={() => dispatch(openSignUpModal())}>Cadastre-se</span>
        </NoAccountTitle>
        <Divider plain>
          <DividerText>Ou entre com seus dados abaixo</DividerText>
        </Divider>

        {/* Email Field */}
        <Form.Item
          name="username"
          rules={[{ message: 'Por favor, insira seu e-mail!', required: true }]}
          label="E-mail"
        >
          <StyledInput
            lineHeight={2.5715}
            borderRadius={8}
            border={'1.5px solid #d9d9d9'}
            placeholder="nome@email.com"
          />
        </Form.Item>

        {/* Password Field */}
        <Form.Item
          style={{ marginBottom: '12px' }}
          name="password"
          rules={[{ message: 'Por favor, insira sua senha!', required: true }]}
          label="Senha"
        >
          <StyledInput.Password
            lineHeight={2.5715}
            borderRadius={8}
            border={'1.5px solid #d9d9d9'}
            placeholder="********"
          />
        </Form.Item>

        {/* Forgot Password Link */}
        <a href="/login/forgotPassword">
          <div
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              color: '#ef4a3b',
              marginBottom: '1rem',
            }}
          >
            Esqueceu sua senha?
          </div>
        </a>

        {/* <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        'É necessário aceitar nossos Termos de Serviços e Política de Privacidade',
                      ),
                    ),
            },
          ]}
        >
          <AcceptTerms />
        </Form.Item> */}

        {/* Submit Button */}
        <Form.Item>
          <StyledButton htmlType="submit" style={{ backgroundColor: 'red', width: '100%' }}>
            Entrar
          </StyledButton>
        </Form.Item>
      </Form>
    </StyledModal>
  )
}
