import { Skeleton } from 'antd'
import moment from 'moment'
import { Chart } from './Chart'
import { useGetMonthlyRecurrenceCount } from '../../../../../hooks/dashboards/finances/queries/useGetMonthlyRecurrenceCount'

const gerarDados = (efetuadas, nao_efetuadas) => {
  return efetuadas
    ?.sort((a, b) => moment(a.dia, 'YYYY-MM-DD').diff(moment(b.dia, 'YYYY-MM-DD')))
    .map((efetuada) => {
      const correspondenteEsperada = nao_efetuadas?.find((esperada) => esperada?.dia === efetuada?.dia)

      const diaFormatado = moment(efetuada.dia, 'YYYY-MM-DD').format('DD/MM')
      const valorPago = parseFloat(efetuada.valor)
      const valorNaoPago = correspondenteEsperada ? parseFloat(correspondenteEsperada.valor) : 0

      return {
        dia: diaFormatado,
        pago: valorPago,
        nao_pago: valorNaoPago,
      }
    })
}

export function Content({ filters, setCampaignsOptions, onFilterClick }) {
  const { data: recurrenceData, isPending, isError } = useGetMonthlyRecurrenceCount(filters)

  const formattedData = gerarDados(recurrenceData?.recorrentes_efetuadas, recurrenceData?.recorrentes_nao_efetuadas)

  if (isPending) {
    return (
      <div style={{ height: '260px', display: 'grid', placeItems: 'center' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (isError)
    return (
      <h4 style={{ height: '260px', margin: '0', color: 'red', padding: 30, textAlign: 'center', display: "grid", placeItems: "center" }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )

  if (recurrenceData?.campaigns) {
    setCampaignsOptions(recurrenceData?.campaigns)
  }

  return <Chart formattedData={formattedData} filters={filters} onFilterClick={onFilterClick} />
}
