import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

type ExcluirFamiliaProps = {
  idFamilia: number
}

export function useExcluirFamilia() {
  const queryClient = useQueryClient()

  const excluirFamilia = useMutation({
    mutationFn: async ({ idFamilia }: ExcluirFamiliaProps) => {
      await DataService.delete(`agape/deletar-familia/${idFamilia}`)
    },
    onSuccess: () => {
      notification.success({ message: 'Família excluída com sucesso!' })
      queryClient.invalidateQueries({ queryKey: ['GET_ALL_FAMILIAS'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_FAMILIA'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao excluir a família.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return excluirFamilia
}
