import { useQuery } from '@tanstack/react-query'

interface UserTypeData {
  name: string
  value: number
}

export function useMessagesByUserType() {
  return useQuery<UserTypeData[]>({
    queryKey: ['messages-by-user-type'],
    queryFn: async () => {
      return [
        { name: 'Membros', value: 5840 },
        { name: 'Visitantes', value: 2450 },
        { name: 'Administradores', value: 980 }
      ]
    }
  })
} 