import { Form, Input, Select, DatePicker, Button } from 'antd'
import { StyledCard } from '../../../components/Card/styles'
import moment from 'moment'

import { StyledForm } from '../../../components/Form/styles'
import { StyledSelect } from '../../../components/Select/styles'
import { StyledDatePicker } from '../../../components/DatePicker/styles'
import { countries } from '../../../../../utility/allCountries'
import { useFetchUserMe, useFetchUserMeAddress } from '../../../../admin/hooks'
import ReactInputMask from 'react-input-mask'
import { useEffect } from 'react'
import { StyledButton } from '../../../components/Button'
import { MainCard } from '../../../components/Card'
import { StyledInput } from '../../../components/Inputs'
import { UpdateUserReqBody, useUpdateUser } from '../../../hooks/user/mutations/updateUser'
import { AddressForm } from '../../Campaign/CampaignIdentification/components/AddressForm'
import { validateDate } from '../../../../../utility/utility'

export interface ProfileFormValues {
  cpf?: string
  cnpj?: string
  identidade_estrangeira?: string

  data_nascimento: string
  email: string
  pais: string
  nome: string
  telefone: string
  sexo: 'masculino' | 'feminino'

  bairro?: string
  cep?: string
  cidade?: string
  estado?: string
  rua?: string
  complemento?: string
  detalhe_estrangeiro?: string
  numero?: string
}

export function ProfileView() {
  const [form] = Form.useForm()
  const userMe = useFetchUserMe()
  const { data: userAddress, isLoading: isLoadingUserAddress, isError } = useFetchUserMeAddress()
  const { mutateAsync: updateProfile, isPending } = useUpdateUser()
  const selectedCountry = Form.useWatch('pais', form)

  const onFinish = async (values: ProfileFormValues) => {
    const formattedValues: UpdateUserReqBody = {
      usuario: {
        nome: values.nome,
        telefone: values.telefone,
        data_nascimento: moment(values.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        sexo: values.sexo,
      },
      endereco:
        selectedCountry === 'brasil'
          ? {
              rua: values.rua,
              bairro: values.bairro,
              cep: values.cep,
              cidade: values.cidade,
              complemento: values.complemento,
              estado: values.estado,
              numero: values.numero,
            }
          : {
              detalhe_estrangeiro: values.detalhe_estrangeiro,
            },
    }

    updateProfile({ formattedValues })
  }

  useEffect(() => {
    if (userMe?.data) {
      form.setFieldsValue({
        pais: userMe?.data?.pais || '',
        nome: userMe?.data?.nome || '',
        numero_documento: userMe?.data?.numero_documento || '',
        cnpj: userMe?.data?.numero_documento || '',
        identidade_estrangeira: userMe?.data?.numero_documento || '',
        email: userMe?.data?.email || '',
        data_nascimento: userMe?.data?.data_nascimento,
        sexo: userMe?.data?.sexo || '',
        telefone: userMe?.data?.telefone || '',
        ...userAddress,
      })
    }
  }, [userMe?.data, form])

  return (
    <MainCard isPending={userMe?.isPending} isError={userMe?.isError} title="Dados cadastrais">
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="País" name="pais" rules={[{ required: true, message: 'Por favor, insira o país!' }]}>
          <StyledSelect disabled defaultValue="brasil" options={countries} placeholder="Selecione um país" />
        </Form.Item>

        {/* Nome */}
        <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Por favor, insira o nome!' }]}>
          <StyledInput placeholder="Digite seu nome" />
        </Form.Item>

        {/* Documento */}
        {userMe?.data?.pais === 'brasil' ? (
          <Form.Item
            label="CPF"
            name="numero_documento"
            rules={[{ required: true, message: 'Por favor, insira o CPF!' }]}
          >
            <ReactInputMask mask="999.999.999-99" disabled>
              {(inputProps) => <StyledInput disabled {...inputProps} placeholder="Digite seu CPF ou CNPJ" />}
            </ReactInputMask>
          </Form.Item>
        ) : (
          <Form.Item
            label="Documento de Identidade"
            name="numero_documento"
            rules={[{ required: true, message: 'Por favor, insira o seu documento!' }]}
          >
            <StyledInput disabled placeholder="Digite seu documento de identidade" />
          </Form.Item>
        )}

        {/* Email */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Por favor, insira o email!' },
            { type: 'email', message: 'Por favor, insira um email válido!' },
          ]}
        >
          <StyledInput disabled placeholder="Digite seu email" />
        </Form.Item>

        <Form.Item
          label="Telefone"
          name="telefone"
          rules={[
            { required: true, message: 'Por favor, insira o telefone!' },
            {
              validator: (_, value) =>
                value && value.replace(/\D/g, '').length === 11
                  ? Promise.resolve()
                  : Promise.reject(new Error('O telefone deve conter 11 dígitos!')),
            },
          ]}
        >
          <ReactInputMask mask="(99) 99999-9999" maskChar={null}>
            {(inputProps) => <StyledInput {...inputProps} placeholder="Digite seu telefone" />}
          </ReactInputMask>
        </Form.Item>

        <Form.Item
          name="data_nascimento"
          label="Data de nascimento"
          rules={[
            { required: true, message: 'Por favor, preencha sua data de nascimento!' },
            {
              validator: (_, value) => validateDate(value),
            },
          ]}
        >
          <ReactInputMask mask="99/99/9999" placeholder="dd/mm/yyyy">
            {(inputProps) => <StyledInput {...inputProps} style={{ gridColumn: 'span 1' }} />}
          </ReactInputMask>
        </Form.Item>

        <Form.Item label="Sexo" name="sexo" rules={[{ required: true, message: 'Por favor, selecione o sexo!' }]}>
          <StyledSelect placeholder="Selecione o sexo" disabled={userMe?.data?.sexo}>
            <Select.Option value="masculino">Masculino</Select.Option>
            <Select.Option value="feminino">Feminino</Select.Option>
            <Select.Option value="outro">Outro</Select.Option>
          </StyledSelect>
        </Form.Item>

        <AddressForm form={form} />

        <Form.Item>
          <StyledButton htmlType="submit" style={{ margin: '0 auto' }} disabled={isPending} loading={isPending}>
            Enviar
          </StyledButton>
        </Form.Item>
      </StyledForm>
    </MainCard>
  )
}
