import { Col, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import BaseCard from '../../components/card/BaseCard'
import BaseSpin from '../../components/spin/BaseSpin'
import { BaseUploadPhoto } from '../../components/upload/BaseUploadPhoto'
import { useFetchUserMe } from '../../hooks'
import BaseButton from '../../components/button/BaseButton'
import { useSaveProfilePhoto } from '../../hooks/images/mutations/useSaveProfilePhoto'
import { ErrorResult } from '../../components/result/resultStatus/ErrorResult'
import { useDeleteProfilePhoto } from '../../hooks/images/mutations/useDeleteProfilePhoto'

export function ProfileLayout({ children }) {
  const { data: userMe, isLoading, isError, refetch } = useFetchUserMe()

  const {
    mutateAsync: uploadImage,
    isPending: isPendingUploadPhoto,
    isSuccess: isSucessUploadPhoto,
  } = useSaveProfilePhoto()

  const {
    mutateAsync: deleteImage,
    isPending: isPendingDeletePhoto,
    isSuccess: isSucessDeletePhoto,
  } = useDeleteProfilePhoto()

  const [profileImage, setProfileImage] = useState([])

  useEffect(() => {
    if (userMe) {
      if (userMe.avatar) {
        setProfileImage([{ url: userMe.avatar }])
      }
    }
  }, [userMe])

  const handleUploadImage = () => {
    if (profileImage.length === 0) {
      deleteImage()
      return
    }

    uploadImage({
      image: profileImage[0]?.file,
    })
  }

  useEffect(() => {
    if (isSucessUploadPhoto || isSucessDeletePhoto) {
      setProfileImage([])
      refetch()
    }
  }, [isSucessUploadPhoto, isSucessDeletePhoto])

  const { pathname } = useLocation()
  const history = useHistory()

  let locale

  switch (pathname) {
    case '/admin/profile/view':
      locale = 'profile'
      break
    case '/admin/profile/address':
      locale = 'address'
      break
    default:
      locale = 'donations'
      break
  }

  const showSaveImageButton =
    (profileImage.length > 0 && profileImage[0]?.url !== userMe?.avatar) || (!profileImage.length && userMe?.avatar)

  return (
    <Row gutter={[30, 20]}>
      <Col xs={24} md={8}>
        <BaseCard style={{ minHeight: '80vh', height: '100%' }}>
          {isLoading && <BaseSpin style={{ width: '100%', display: 'grid', placeItems: 'center', height: '60vh' }} />}
          {isError && <ErrorResult style={{ width: '100%', display: 'grid', placeItems: 'center', height: '60vh' }} />}
          {!isLoading && userMe && (
            <>
              <Row justify="center">
                <BaseUploadPhoto
                  listType="picture-card"
                  initialFileList={profileImage}
                  setImage={(image) => setProfileImage(image)}
                  numeroFotos={1}
                />
              </Row>
              {showSaveImageButton && (
                <Row justify="center" style={{ marginTop: '3rem' }}>
                  <BaseButton onClick={handleUploadImage} loading={isPendingUploadPhoto || isPendingDeletePhoto}>
                    Salvar
                  </BaseButton>
                </Row>
              )}

              <div style={{ marginTop: '2rem' }}>
                <Typography.Title style={{ fontSize: '2rem', textAlign: 'center' }} level={2}>
                  {userMe?.nome}
                </Typography.Title>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                  width: '100%',
                  marginTop: '2.5rem',
                }}
              >
                <BaseButton
                  type={locale === 'profile' ? 'ghost' : 'text'}
                  icon={<FeatherIcon icon="user" />}
                  onClick={() => history.push('/admin/profile/view')}
                  style={{ minWidth: '50%', justifyContent: 'start' }}
                >
                  Dados cadastrais
                </BaseButton>
                <BaseButton
                  type={locale === 'address' ? 'ghost' : 'text'}
                  icon={<FeatherIcon icon="map-pin" />}
                  onClick={() => history.push('/admin/profile/address')}
                  style={{ minWidth: '50%', justifyContent: 'start' }}
                >
                  Endereço
                </BaseButton>
                <BaseButton
                  type={locale === 'donations' ? 'ghost' : 'text'}
                  icon={<FeatherIcon icon="dollar-sign" />}
                  onClick={() => history.push('/admin/profile/donations')}
                  style={{ minWidth: '50%', justifyContent: 'start' }}
                >
                  Minhas doações
                </BaseButton>
              </div>
            </>
          )}
        </BaseCard>
      </Col>
      <Col xs={24} md={16} style={{ minHeight: '80vh', height: '100%' }}>
        {children}
      </Col>
    </Row>
  )
}
