import { Result, Row } from 'antd';
import { Title } from '../../components/Title';

export function ConfirmEmail() {
  return (
    <Row justify="center" style={{ minHeight: '50vh', alignItems: 'center' }}>
      <Result
        status="info"
        title={<Title>Por favor, confirme seu e-mail</Title>}
        subTitle="Verifique o link que enviamos no seu e-mail para prosseguir com o cadastro."
      />
    </Row>
  );
}
