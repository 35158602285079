import styled from 'styled-components'
import { SmBaseCard } from '../../../components/card/styles'

export const Container = styled.div`
  padding: 0 2rem;

  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
`

export const StyledPrayerImpactCard = styled(SmBaseCard)`
  padding: 40px 20px;
  line-height: 24px;
  max-width: 1200px;

  span {
    font-weight: 700;
    line-height: 24px;
  }

  .header {
    margin-bottom: 1rem;
  }

  @media (min-width: 600px) {
    display: flex;
    gap: 2rem;

    .header {
      text-align: initial;
    }
  }
`
