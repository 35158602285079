import styled from 'styled-components'
import { BORDER_RADIUS } from '../../../../../styles/Themes/Admin/constants'

export const StyledProfileMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--background-color);
  padding: 1rem 1.5rem;
  border-radius: ${BORDER_RADIUS};
`
