import { useQuery } from '@tanstack/react-query';
import { DataService } from '../../../../../lib/dataService';

interface MonthDonationsResponse {
  media_mensal: string;
  porcentagem_valor_mes: string;
  quant_pedidos_mes: number;
  valor_total_mes: string;
}

export const useFetchCurrentMonthDonations = () => {
  return useQuery<MonthDonationsResponse>({
    queryKey: ['DONATIONS_ACTUAL_MONTH_DASHBOARD'],
    queryFn: async () => {
      const response = await DataService.get<MonthDonationsResponse>('admin/dash-board/donations/total-donations-mes-atual');
      return response?.data;
    },
  });
}; 