import { UserMeResponse } from '../../admin/hooks/users/Queries/useFetchUserMe'
import { UserMeAddress } from '../../admin/hooks/users/Queries/useFetchUserMeAddress'
import { formatCEP, formatTelefone } from '../../../utility/utility'
import { Documento } from '../pages/validate-user'

export function getEsmSignUpInitialFields(
  document: Documento | undefined,
  userMe: UserMeResponse | undefined,
  userMeAddress: UserMeAddress | undefined,
) {
  if ((userMe && userMeAddress) || !userMe)
    return {
      numero_documento: document?.numero_documento ?? userMe?.numero_documento,
      tipo_documento: document?.tipo_documento ?? (userMe?.pais === 'brasil' ? 'cpf' : 'identidade_estrangeira'),
      nome: userMe?.nome,
      email: userMe?.email,
      sexo: userMe?.sexo,
      data_nascimento: userMe?.data_nascimento,
      telefone: userMe?.pais === 'brasil' ? formatTelefone(userMe?.telefone) : userMe?.telefone,
      cep: formatCEP(userMeAddress?.cep),
      estado: userMeAddress?.estado,
      cidade: userMeAddress?.cidade,
      bairro: userMeAddress?.bairro,
      rua: userMeAddress?.rua,
      numero: userMeAddress?.numero,
      complemento: userMeAddress?.complemento,
      detalhe_estrangeiro: userMeAddress?.detalhe_estrangeiro,
    }
}
