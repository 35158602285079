import { Card, CardProps } from 'antd'
import { ReactNode } from 'react'
import * as S from './BaseCard.Styles'

interface BaseCardProps extends CardProps {
  children?: ReactNode
  bordered?: boolean
  error?: boolean
  padding?: string | number
}

export default function BaseCard({ children, bordered = false, error = false, padding, ...props }: BaseCardProps) {
  return (
    <S.StyledCard bordered={bordered} padding={padding} {...props}>
      {error ? (
        <div>
          <h2
            style={{
              display: 'grid',
              placeItems: 'center',
              color: 'var(--text-secondary-color)',
              height: '300px',
            }}
          >
            Ocorreu um erro
          </h2>
        </div>
      ) : (
        children
      )}
    </S.StyledCard>
  )
}

BaseCard.Meta = Card.Meta
