import { Package } from '@phosphor-icons/react'
import { GroupCard } from '../../../../../../../../components/GroupCard'
import { useGetCardTotalRecebimentos } from '../../../../../../../../hooks/agape/queries/useGetCardTotalRecebimentos'
import { Row } from 'antd'
import { HistoricoRecebimentosTabela } from './HistoricoRecebimentosTabela'

interface HistoricoRecebimentosProps {
  idFamilia: number
}

export function HistoricoRecebimentos({ idFamilia }: HistoricoRecebimentosProps) {
  const {
    data: totalRecebimentos,
    isLoading: isLoadingTotalRecebimentos,
    isError: isErrorTotalRecebimentos,
  } = useGetCardTotalRecebimentos({ fk_familia_agape_id: idFamilia })

  return (
    <>
      <Row style={{ marginBottom: '1rem' }}>
        <GroupCard
          title="Total de recebimentos"
          icon={Package}
          number={totalRecebimentos?.total_itens_recebidos}
          balance={undefined}
          isLoading={isLoadingTotalRecebimentos}
          isError={isErrorTotalRecebimentos}
        />
      </Row>

      <HistoricoRecebimentosTabela idFamilia={idFamilia} />
    </>
  )
}
