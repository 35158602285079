import { http, HttpResponse } from 'msw'

export const cadastrarFamiliaMock = http.post('/agape/cadastrar-familia', async ({ request }) => {
  const formData = await request.formData()

  const membros = JSON.parse(formData.get('membros') as string) || []
  const endereco = JSON.parse(formData.get('endereco') as string) || {}
  const observacao = formData.get('observacao') ? JSON.parse(formData.get('observacao') as string) : null
  const foto = formData.get('foto') as File | null

  const possuiMembros = membros.length > 0 && membros[0]?.nome === 'Responsavel Familia'
  const possuiInformacoes = foto && observacao

  const cadastroSucesso = (possuiMembros && possuiInformacoes) || possuiMembros

  if (cadastroSucesso) {
    return new HttpResponse(null, { status: 201 })
  }

  return new HttpResponse(null, { status: 400 })
})
