import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { DataService } from '../../../../../lib/dataService'

export interface UserMeResponse {
  avatar: string
  campanha_origem: string | null | number
  data_nascimento: string
  email: string
  fk_clifor_id: number
  fk_perfil_id: number
  id: number
  nome: string
  nome_social: string | null
  numero_documento: string
  obriga_atualizar_cadastro: boolean
  obriga_atualizar_endereco: boolean
  pais: string
  sexo: string
  super_perfil: boolean
  telefone: string
}

export function useFetchUserMe() {
  const userMe = useQuery<UserMeResponse>({
    queryKey: ['USER_ME'],
    queryFn: async () => {
      const response = await DataService.get('/users/me')
      return response?.data
    },
    refetchOnWindowFocus: true,
    gcTime: 0,
    staleTime: 0,
    enabled: !!Cookies.get('access_token'),
  })

  return userMe
}
