import { Col, Form, FormInstance, Row } from 'antd'
import { useEffect, useState } from 'react'
import { fillAddressFromCep } from '../../../../../benfeitor/pages/Campaign/CampaignIdentification/utils'
import { DataService } from '../../../../../../lib/dataService'
import ReactInputMask from 'react-input-mask'
import { BaseInput } from '../../../inputs/BaseInput/BaseInput'
import BaseForm from '../../BaseForm/BaseForm'
import { formatCEP } from '../../../../../../utility/utility'

interface AddressAutoComplete {
  bairro: string
  cidade: string
  estado: string
  rua: string
}

interface IAddressForm extends AddressAutoComplete {
  numero: number | string
  complemento?: string
  cep: string
}

interface AddressFormProps {
  form: FormInstance
  address?: IAddressForm

  commonInputStyles?: any
  showBrazil?: boolean

  disabled?: boolean
}

export function AddressForm({ address, form, showBrazil = true, disabled }: AddressFormProps) {
  const [disabledFields, setDisabledFields] = useState<string[]>([])
  const selectedCountry = Form.useWatch('pais', form)

  const handleCepChange = async () => {
    fetchAddress()
  }

  const fetchAddress = async () => {
    try {
      const { filledFields } = await fillAddressFromCep({ form })
      setDisabledFields(filledFields)
    } catch (error) {
      console.error('Error fetching address from CEP:', error)
    }
  }

  async function disableFieldsComingFromApi() {
    const { data: addressAutoComplete }: { data: AddressAutoComplete } = await DataService.get(
      `/services/search-cep/${form.getFieldValue('cep')}`,
    )
    disableFields(addressAutoComplete)
  }

  function disableFields(address: AddressAutoComplete) {
    const filledFields = Object.keys(address).filter((key) => address[key as keyof AddressAutoComplete])

    setDisabledFields(filledFields)
  }

  useEffect(() => {
    disableFieldsComingFromApi()

    if (address) {
      form.setFieldsValue({
        bairro: address.bairro,
        cep: formatCEP(address.cep),
        cidade: address.cidade,
        complemento: address.complemento,
        estado: address.estado,
        numero: address?.numero,
        rua: address?.rua,
      })

      disableFields(address)
    }
  }, [form, address])

  useEffect(() => {
    const cep = form.getFieldValue('cep')
    if (cep) {
      disableFieldsComingFromApi()
    }
  }, [form.getFieldValue('cep')])

  return (
    <Col xs={24}>
      {selectedCountry === 'brasil' || showBrazil ? (
        <>
          <Row justify="center" gutter={20}>
            <Col xs={24} sm={8}>
              <BaseForm.Item
                name="cep"
                label="CEP"
                rules={[
                  { required: true, message: 'Por favor, insira seu CEP!' },
                  {
                    pattern: /^[0-9]{5}-[0-9]{3}$/,
                    message: 'Formato inválido! Utilize 00000-000.',
                  },
                ]}
              >
                <ReactInputMask mask="99999-999" maskChar={null} placeholder="00000-000" onChange={handleCepChange}>
                  {(inputProps) => <BaseInput {...inputProps} />}
                </ReactInputMask>
              </BaseForm.Item>
            </Col>
          </Row>

          <Row gutter={[20, 10]}>
            <Col xs={24} sm={8}>
              <BaseForm.Item
                name="estado"
                label="Estado"
                rules={[{ required: true, message: 'Por favor, insira seu estado!' }]}
              >
                <BaseInput placeholder="Apenas texto" disabled={disabledFields.includes('estado') || disabled} />
              </BaseForm.Item>
            </Col>

            <Col xs={24} sm={8}>
              <BaseForm.Item
                name="cidade"
                label="Cidade"
                rules={[{ required: true, message: 'Por favor, insira sua cidade!' }]}
              >
                <BaseInput placeholder="Apenas texto" disabled={disabledFields.includes('cidade') || disabled} />
              </BaseForm.Item>
            </Col>

            <Col xs={24} sm={8}>
              <BaseForm.Item
                name="bairro"
                label="Bairro"
                rules={[{ required: true, message: 'Por favor, insira seu bairro!' }]}
              >
                <BaseInput placeholder="Apenas texto" disabled={disabledFields.includes('bairro') || disabled} />
              </BaseForm.Item>
            </Col>
          </Row>

          <Row gutter={[20, 10]}>
            <Col xs={24} sm={8}>
              <BaseForm.Item name="rua" label="Rua" rules={[{ required: true, message: 'Por favor, insira sua rua!' }]}>
                <BaseInput placeholder="Logradouro" disabled={disabledFields.includes('rua') || disabled} />
              </BaseForm.Item>
            </Col>
            <Col xs={24} sm={8}>
              <BaseForm.Item
                name="numero"
                label="Número"
                rules={[
                  { required: true, message: 'Por favor, insira seu número!' },
                  { pattern: /^[0-9]*$/, message: 'Apenas números são permitidos!' },
                ]}
              >
                <BaseInput placeholder="000" />
              </BaseForm.Item>
            </Col>

            <Col xs={24} sm={8}>
              <BaseForm.Item name="complemento" label="Complemento">
                <BaseInput placeholder="Casa ou apt" />
              </BaseForm.Item>
            </Col>
          </Row>
        </>
      ) : (
        <Col xs={24}>
          <BaseForm.Item
            name="detalhe_estrangeiro"
            label="Código postal ou ZIP Code"
            rules={[{ required: true, message: 'Por favor, insira sua localização!' }]}
          >
            <BaseInput placeholder="Insira o estado ou região" />
          </BaseForm.Item>
        </Col>
      )}
    </Col>
  )
}
