import { StyledButton } from '../../../../../components/Button';
import { Spin } from '../../../../../components/Spin';
import { usePaymentMethod } from '../../context';

interface SendButtonProps {
  isPending: boolean;
}

export function SendButton({ isPending }: SendButtonProps) {
  const { activePaymentMethod, pixQrCode, boletoData } = usePaymentMethod();

  if (pixQrCode || boletoData) return null;

  return (
    <div style={{ maxWidth: '700px' }}>
      <StyledButton
        htmlType="submit"
        style={{ margin: '0 auto', padding: '1.4rem 3rem', width: '100%' }}
        disabled={isPending}
      >
        {isPending && <Spin style={{ marginRight: '0.5rem' }} color="white" />}
        {activePaymentMethod === 'card' && 'Enviar'}
        {activePaymentMethod === 'pix' && 'Gerar QRCode'}
        {activePaymentMethod === 'boleto' && 'Gerar Boleto'}
      </StyledButton>
    </div>
  );
}
