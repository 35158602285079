import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetDonationsCountPerHour(filters) {
  return useQuery({
    queryKey: ['DONATIONS_COUNT_PER_HOUR_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const response = await DataService.get(
        `admin/dash-board/donations/donations-per-hours?${filters?.queryString || ''}`,
      )

      const data = response?.data?.donations_by_hour
      const formattedData = Object.entries(data)
        .map(([hora, info]) => ({ hora: `${hora}:00`, Doações: info.doacoes }))
        .sort((a, b) => parseInt(a.hora) - parseInt(b.hora))

      return { data: formattedData, campaigns: response?.data?.campaigns }
    },
  })
}
