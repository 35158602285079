import {
  getLabelFromCountryValue,
  handleCpfShowValues,
  handlePhoneNumberFormat,
} from '../../../../../../../utility/utility'
import { BaseDescription } from '../../../../../components/description/BaseDescription'
import { GetAllFichaVocacionalResponse } from '../../../../../hooks/vocacional/queries/useFetchGetAllFichaVocacional'

interface ListaDetalhesFichaVocacionalProps {
  dados: GetAllFichaVocacionalResponse['fichas_vocacionais'][number]
}

const StatusGenero = {
  masculino: 'Masculino',
  feminino: 'Feminino',
}

export function ListaDetalhesAnteriores({ dados }: ListaDetalhesFichaVocacionalProps) {
  const usuarioBrasileiro = dados.pre_cadastro.pais === 'brasil'

  return (
    <div>
      <h3>Informações Pessoais</h3>
      <BaseDescription bordered column={{ xs: 1, md: 2 }} size="small" layout="vertical">
        <BaseDescription.Item label="Documento de Identidade">
          {usuarioBrasileiro
            ? handleCpfShowValues(dados.cadastro_vocacional.documento_identidade)
            : dados.cadastro_vocacional.documento_identidade}
        </BaseDescription.Item>
        <BaseDescription.Item label="Email">{dados.pre_cadastro.email}</BaseDescription.Item>
        <BaseDescription.Item label="Telefone">
          {usuarioBrasileiro ? handlePhoneNumberFormat(dados.pre_cadastro.telefone) : dados.pre_cadastro.telefone}
        </BaseDescription.Item>
        <BaseDescription.Item label="Data de Nascimento">
          {dados.cadastro_vocacional.data_nascimento}
        </BaseDescription.Item>
        <BaseDescription.Item label="Gênero">{StatusGenero[dados.cadastro_vocacional.genero]}</BaseDescription.Item>
        <BaseDescription.Item label="Data de cadastro">{dados.cadastro_vocacional.created_at}</BaseDescription.Item>
      </BaseDescription>

      <h3 style={{ marginTop: '24px' }}>Endereço</h3>
      <BaseDescription bordered column={{ xs: 1, md: 2 }} size="small" layout="vertical">
        <BaseDescription.Item label="País">{getLabelFromCountryValue(dados.pre_cadastro.pais)}</BaseDescription.Item>

        {!usuarioBrasileiro && (
          <BaseDescription.Item label={'ZipCode'}>{dados.cadastro_vocacional.detalhe_estrangeiro}</BaseDescription.Item>
        )}

        <BaseDescription.Item label="CEP">{dados.cadastro_vocacional.cep}</BaseDescription.Item>
        <BaseDescription.Item label="Estado">{dados.cadastro_vocacional.estado}</BaseDescription.Item>
        <BaseDescription.Item label="Bairro">{dados.cadastro_vocacional.bairro}</BaseDescription.Item>
        <BaseDescription.Item label="Cidade">{dados.cadastro_vocacional.cidade}</BaseDescription.Item>
        <BaseDescription.Item label="Rua">{dados.cadastro_vocacional.rua}</BaseDescription.Item>
        <BaseDescription.Item label="Número">{dados.cadastro_vocacional.numero}</BaseDescription.Item>
      </BaseDescription>
    </div>
  )
}
