import { useState } from 'react'
import { useResendEmailToApproved } from '../../../../hooks/vocacional/mutations/useResendEmailToApproved'
import BaseButton from '../../../../components/button/BaseButton'

interface ReenviarEmailVocacionalProps {
  usuario_vocacional_id: number
}

export function ReenviarEmailVocacional({ usuario_vocacional_id }: ReenviarEmailVocacionalProps) {
  const { mutateAsync: resendEmail } = useResendEmailToApproved()

  const [loadingRow, setLoadingRow] = useState<number | null>(null)

  async function handleResendEmail() {
    try {
      setLoadingRow(usuario_vocacional_id)
      await resendEmail({ usuario_vocacional_id })
    } finally {
      setLoadingRow(null)
    }
  }

  return (
    <BaseButton severity="success" onClick={handleResendEmail} loading={loadingRow === usuario_vocacional_id}>
      Reenviar e-mail
    </BaseButton>
  )
}
