import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Endereco } from '../../../../../utility/endereco'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export type AbrangenciaCicloDoacao = 'cep' | 'rua' | 'bairro' | 'cidade' | 'estado' | 'sem_restricao'

interface CadastrarCicloDoacaoProps {
  doacoes: {
    fk_estoque_agape_id: number
    quantidade: number
  }[]
  endereco: Endereco
  fk_acao_agape_id: number
  abrangencia: AbrangenciaCicloDoacao
}

export function useCadastrarCicloDoacao() {
  const queryClient = useQueryClient()

  const cadastroCicloDoacao = useMutation({
    mutationFn: async ({ doacoes, endereco, fk_acao_agape_id, abrangencia }: CadastrarCicloDoacaoProps) => {
      await DataService.post('/agape/cadastrar-acao-agape', {
        doacoes,
        endereco,
        fk_acao_agape_id,
        abrangencia,
      })
    },
    onSuccess: () => {
      notification.success({ message: 'Ciclo de doação cadastrado com sucesso!' })
      queryClient.invalidateQueries({ queryKey: ['ITENS_ESTOQUE'] })
      queryClient.invalidateQueries({ queryKey: ['CICLOS_DOACOES'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_ESTOQUE'] })
      queryClient.invalidateQueries({ queryKey: ['HISTORICO_MOVIMENTACOES'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar ciclo de doação',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return cadastroCicloDoacao
}
