import moment from 'moment'
import 'moment/locale/pt-br'
import ptBR from 'antd/lib/locale/pt_BR'

moment.locale('pt-br')
export const BaseDatePickerLocale = {
  ...ptBR,
  lang: {
    ...ptBR.locale,
    placeholder: 'Selecionar data',
    rangePlaceholder: ['Data inicial', 'Data final'],
    today: 'Hoje',
    now: 'Agora',
    backToToday: 'Voltar para hoje',
    ok: 'Ok',
    clear: 'Limpar',
    month: 'Mês',
    year: 'Ano',
    timeSelect: 'Selecionar hora',
    dateSelect: 'Selecionar data',
    monthSelect: 'Selecionar mês',
    yearSelect: 'Selecionar ano',
    weekSelect: 'Selecionar semana',
    decadeSelect: 'Selecionar década',
    yearFormat: 'YYYY',
    dateFormat: 'DD/MM/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Mês anterior (PageUp)',
    nextMonth: 'Próximo mês (PageDown)',
    previousYear: 'Ano anterior (Control + left)',
    nextYear: 'Próximo ano (Control + right)',
    previousDecade: 'Década anterior',
    nextDecade: 'Próxima década',
    previousCentury: 'Século anterior',
    nextCentury: 'Próximo século',
  },
  timePickerLocale: {
    placeholder: 'Selecionar hora',
  },
}
