import React from 'react';
import * as S from './BasePopover.Styles';

export default function BasePopover({ children, ...props }) {
  return (
    <S.StyledPopover
      overlayInnerStyle={{ backgroundColor: 'var(--background-color)' }}
      {...props}
      content={props.content}
      title={<S.Title>{props.title}</S.Title>}
    >
      {children}
    </S.StyledPopover>
  );
}
