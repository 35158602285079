// import { graphic } from 'echarts';
// import { BASE_COLORS } from '../constants';

import { BASE_COLORS } from '../constants'

// const chartColors = {
//   chartTooltipLabel: '#6a7985',
//   chartColor1: '#339CFD',
//   chartColor1Tint: '#339CFD', // update
//   chartColor2: '#dc88f5',
//   chartColor2Tint: '#dc88f5', // update
//   chartColor3: '#FFB765',
//   chartColor3Tint: '#FFB765', // update
//   chartColor4: '#306955',
//   chartColor4Tint: '#306955', // update
//   chartColor5: '#ff3d71',
//   chartColor5Tint: '#ff3d71', // update
//   chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
//     {
//       offset: 0,
//       color: 'rgba(51, 156, 253, 0.35)',
//     },
//     {
//       offset: 1,
//       color: 'rgba(51, 156, 253, 0)',
//     },
//   ]),
//   chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
//     {
//       offset: 0,
//       color: 'rgba(255, 82, 82, 0.35)',
//     },
//     {
//       offset: 1,
//       color: 'rgba(255, 82, 82, 0)',
//     },
//   ]),
//   chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
//     {
//       offset: 0,
//       color: 'rgba(255, 255, 255, 0)',
//     },
//     {
//       offset: 1,
//       color: 'rgba(255, 82, 82, 0.5)',
//     },
//   ]),
// };

export const darkColorsTheme = {
  backgroundColor: '#171717',

  textMainColor: '#FFFFFF',
  textSecondaryColor: '#B1B1B1',
  logoTextColor: '#FEF9F6',
  sidebarBackgroundColor: '#393939',
  sidebarBackgroundDarkerColor: '#262424',
  sidebarSelectedColor: '#FFFFFF',
  sidebarTextColor: '#a5a5a5',
  sidebarSubmenuTextColor: '#8f8e8e',
  sidebarActiveSubmenuTextColor: '#ececec',

  sidebarSelectedMenuBackgroundColor: '#7C7C7C',
  sidebarSelectedMenuColor: '#393939',
  sidebarSelectedMenuColorHover: '#d7d7d7',

  collapseBackgroundColor: '#2a201e',
  borderColor: '#e1e1e1',

  tableCellColor: '#2D2624',
  // tableFontHoverColor: '#3E3331',
  tableCellHoverColor: '#3c3c3c',
  tableBorderColor: '#6b6b6b',
  tableHeaderBg: '#3c3c3c',
  tableHeaderColor: 'white',

  cardBgColor: '#222222',
  cardBorder: '2px solid #494949',
  positiveBalanceColor: '#4cc954',
  negativeBalanceColor: '#ed6761',
  buttonTextColor: '#ffffff',
  buttonTextHoverColor: '#cccccc',
  buttonBgColor: '#5d5a5a',
  buttonBgHoverColor: '#403e3e',
  successColor: '#57D682',
  infoColor: '#539AF6',
  warningColor: '#F4BA72',
  errorColor: '#EC5F59',
  uncheckedSwitchColor: '#837B76',
  notificationSuccess: '#EFFFF4',
  notificationInfo: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  trailColor: '#FFFFFF',
  strokeColor: '#837B76',
  inputBorderColor: '#707070',
  calendarHeader: '#1f1c1c',
  calendarWeekDays: 'white',
  calendarDisabledDays: '#837B76',
  calendarSelectedDayBg: '#ffffff',
  calendarSelectedDayColor: '#362A28',
  calendarFooterLiveColor: '#FFFFFF',
  calendarIconColor: '#FFFFFF',
  calendarFooterLiveBorderColor: '#E2E2E280',
  calendarNotDisabledDays: '#dedbd9',
  themePickerButtonBgColor: '#1F1C1C',
  themePickerSelectedThemeBgColor: '#4A4646',
  themePickerSelectedThemeIconColor: '#FFFFFF',
  // text
  // textMain: '#ffffff',
  // textLight: '#9A9B9F',
  // textSuperLight: '#444',
  // textSecondary: '#ffffff',
  // textDark: '#404040',
  // textNftLight: '#797C9A',
  // textSiderPrimary: '#339CFD',
  // textSiderSecondary: '#797C9A',
  // subText: '#a9a9a9',
  textSideButtonColor: '#f4f3f3',
  sidebarButtonBackgroundColor: '#5e5553',

  modalBackgroundColor: '#1f1f1f',
  modalText: BASE_COLORS.white,
  modalFirstBtnColor: '#a8827c',
  modalFirstBtnColorHover: BASE_COLORS.white,
  modalSecondBtnBgColor: '#826560',
  modalSecondBtnBgColorHover: '#a8827c',
  modalBorderColor: 'white',
  campaignDetailsPrimaryText: '#fef9f6a9',
  campaignDetailsSecondaryText: '#fef9e3',
  campaignCardColor: '#ffffff',
  campaignCardBgColor: '#383838',
  campaignDetailsBgColor: '#c7b8b6',
  campaignDetailsColor: '#3e3331',
  rightSectionBgColor: '#1f1f1f',
  animatedCardBgColor: '#2b2a2a40',
  customTableBgColor: '#2a2929',
  customTableBgColorHover: '#4a4a4a',
  filterIconColor: 'white',
  chartColor: '#70e870',
  chartColorFadeStop: '#072207',
  chartColorHover: '#3e9d3e',
  chartColorHoverEnd: '#051a05',
  chartTooltipBg: '#1f1f1f',
  chartTooltipColor: '#59EC63',
  chartPieLegendColor: '#cccccc',

  signupChartColor: '#00f7f7',
  signupChartColorStroke: '#00f7f7',
  signupChartTooltipBg: '#2b2a2a',
  signupChartTooltipColor: '#01f1f1',
  signupChartColorFadeStop: '#003535',
  signupChartColorHover: '#019191',
  signupChartColorHoverEnd: '#001f1f',
  separatorColor: '#353333',

  collapseBoxBackgroundColor: '#2a2929',
  // primary: '#339CFD',
  // primary1: '#7568f6',
  // primaryGradient: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 48.97%)',
  // light: '#696969',
  // secondary: '#0072DD',
  // error: '#FF5252',
  // warning: '#FFB765',
  // success: '#57D682',
  // background: '#25284B',
  // secondaryBackground: '#1c2137',
  // secondaryBackgroundSelected: shadeColor('#1c2137', -5),
  // additionalBackground: '#1D203E',
  // collapseBackground: '#1D203E',
  // timelineBackground: '#f5f5f5',
  // siderBackground: '#121430',
  // spinnerBase: '#339CFD',
  // scroll: '#797C9A',
  // border: '#ffffff',
  // borderNft: '#797C9A',
  // textMain: '#ffffff',
  // textLight: '#9A9B9F',
  // textSuperLight: '#444',
  // textSecondary: '#ffffff',
  // textDark: '#404040',
  // textNftLight: '#797C9A',
  // textSiderPrimary: '#339CFD',
  // textSiderSecondary: '#797C9A',
  // subText: '#a9a9a9',
  // shadow: 'rgba(0, 0, 0, 0.07)',
  // boxShadow: 'none',
  // boxShadowHover: 'none',
  // boxShadowNft: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  // boxShadowNftSecondary:
  //   '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  // dashboardMapBackground: '#25284b',
  // dashboardMapCircleColor: '#ACAEC1',
  // dashboardMapControlDisabledBackground: '#7e7e7e',
  // notificationSuccess: '#EFFFF4',
  // notificationPrimary: '#D7EBFF',
  // notificationWarning: '#FFF4E7',
  // notificationError: '#FFE2E2',
  // heading: BASE_COLORS.white,
  // borderBase: '#a9a9a9',
  // disable: '#7e7e7e',
  // disabledBg: '#1c2137',
  // layoutBodyBg: '#1e2142',
  // layoutHeaderBg: '#1e2142',
  // layoutSiderBg: '#121430',
  // inputPlaceholder: 'rgba(255, 255, 255, 0.5)',
  // itemHoverBg: '#1c2137',
  // backgroundColorBase: '#1c2137',
  // avatarBg: '#1c2137',
  // alertTextColor: '#000',
  // breadcrumb: '#a9a9a9',
  // icon: '#a9a9a9',
  // iconHover: '#ffffff',
  // ...chartColors,
  lighterColorChart: 'white',
}

export const antDarkColorsTheme = {
  successBg: '#e6fff2',
  successBorder: '#79fcc4',
}
