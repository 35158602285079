import { GoldenHeader } from '../../../components/golden-header/styles'
import { FirstSectionStyled, HeaderWrapper, LogoWrapper, MainImageWrapper } from './styles'
import nunsAndAngelImg from '../../../../images/nums-and-blue-angel.png'
import logosImage from '../../../../images/logos.svg'

export function FirstSection() {
  return (
    <FirstSectionStyled>
      <LogoWrapper>
        <img src={logosImage} alt="Logo" className="mobile" />
      </LogoWrapper>
      <MainImageWrapper>
        <img src={nunsAndAngelImg} />
      </MainImageWrapper>
      <div className="content">
        <LogoWrapper>
          <img src={logosImage} alt="Logo" className="desktop" />
        </LogoWrapper>
        <HeaderWrapper>
          <GoldenHeader>DEUS ESCOLHEU VOCÊ PARA UMA MISSÃO ÚNICA</GoldenHeader>
        </HeaderWrapper>
        <p style={{ marginTop: '1.5rem', marginBottom: '2.5rem' }}>
          Torne-se um General Oficial do Exército de São Miguel e leve o amor e a misericórdia de Deus a outros
          corações. 
        </p>
      </div>
    </FirstSectionStyled>
  )
}
