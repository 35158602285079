import { useState } from 'react'
import { FilterModal } from './FilterModal'
import { Content } from './Content'
import { AnimatedBaseCard } from '../../../styles'
import { getCurrentMonthQueryString } from '../../../../users/Imported/utils'

export function DonationsPerType() {
  const initialQueryString = getCurrentMonthQueryString()
  const [filters, setFilters] = useState(initialQueryString)
  const [campaignsOptions, setCampaignsOptions] = useState([])

  return (
    <AnimatedBaseCard>
      <FilterModal filters={filters} setFilters={setFilters} campaignsOptions={campaignsOptions} />
      <Content filters={filters} setCampaignsOptions={setCampaignsOptions} />
    </AnimatedBaseCard>
  )
}
