import styled from 'styled-components'
import { FONT_WEIGHT } from '../../../../styles/Themes/Admin/constants'
import BaseCard from '../../components/card/BaseCard'

export const ActionDetails = styled.div`
  margin-top: 1rem;
  color: var(--text-main-color);
  font-weight: ${FONT_WEIGHT.extraLight};

  span {
    font-weight: ${FONT_WEIGHT.bold};
  }

  div:not(.ant-image-mask) {
    margin-bottom: 1rem;
  }
`

export const StatusWrapper = styled(BaseCard)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

// style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}
