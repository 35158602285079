import { Steps } from 'antd'
import { Step } from '../../Carteira/Cadastro/hooks/useMultistepForm'
import styled from 'styled-components'

interface StepsProgresProps {
  steps: Step[]
  currentStep: number
}

export function StepsProgress({ steps, currentStep }: StepsProgresProps) {
  return (
    <StyledSteps current={currentStep} labelPlacement="vertical">
      {steps.map((item, index) => (
        <Steps.Step icon={item.icon} key={index} title={item.title} />
      ))}
    </StyledSteps>
  )
}


const StyledSteps = styled(Steps)`
  @media (max-width: 576px) {
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
    height: 130px;
  }

  .ant-steps-item-wait {
    .anticon {
      background: ${(props) => props.theme.colors['black-70']};
    }

    .ant-steps-item-title h1 {
      color: ${(props) => props.theme.colors['black-70']};
    }
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${(props) => props.theme.colors['dark-brown']} !important;
    border: 2px solid ${(props) => props.theme.colors['dark-brown']} !important;
  }

  .ant-steps-item-tail::after {
    background-color: ${(props) => props.theme.colors['black-70']} !important;
  }

  .anticon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    align-items: center;
    color: #fff;
    background: ${(props) => props.theme.colors['dark-brown']};
    border-radius: 50%;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
