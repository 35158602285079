import { Col, Form, Row } from 'antd'
import { StyledInput } from '../Inputs'
import { estadosBrasil } from '../../../../utility/estados'

export function AddressFields({ required }: { required: boolean }) {
  return (
    <>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="rua"
            label="Rua"
            rules={[
              { min: 3, message: 'Mínimo 3 caracteres' },
              { required, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="Digite a rua" />
          </Form.Item>
        </Col>

        <Col xl={12} md={12} xs={24}>
          <Form.Item
            name="numero"
            label="Número"
            rules={[
              { min: 2, message: 'Mínimo 2 caracteres' },
              { required, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="99" type="number" />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item name="complemento" label="Complemento">
            <StyledInput placeholder="Digite o complemento" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="bairro"
            label="Bairro"
            rules={[
              { min: 3, message: 'Mínimo de 3 caracteres' },
              { required, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="Digite o bairro" />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            name="cidade"
            label="Cidade"
            rules={[
              { min: 3, message: 'Mínimo 3 caracteres' },
              { required, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="Digite a cidade" />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            name="estado"
            label="Estado"
            rules={[
              { required, message: 'Por favor, insira seu estado!' },
              {
                pattern: new RegExp(`^(${estadosBrasil.join('|')})$`, 'i'),
                message: 'O estado deve ser uma abreviação de 2 letras (ex: SP, RJ)!',
              },
            ]}
          >
            <StyledInput placeholder="Apenas texto" />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
