import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetDonationsByUserType(filters) {
  return useQuery({
    queryKey: ['DONATIONS_BY_USER_TYPE_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const response = await DataService.get(
        `admin/dash-board/donations/values-donations-anonimous?${filters?.queryString || ''}`,
      )
      const { data } = response

      const formattedData = [
        {
          id: 'doacoes_anonimas',
          label: 'Doações Anônimas',
          value: data.donations_anonymous.anonimo_total,
          percentage: data.donations_anonymous.anonimo_percentage,
          count: data.donations_anonymous.anonimo_count,
        },
        {
          id: 'doacoes_identificadas',
          label: 'Doações Identificadas',
          value: data.donations_non_anonymous.nao_anonimo_total,
          percentage: data.donations_non_anonymous.nao_anonimo_percentage,
          count: data.donations_non_anonymous.nao_anonimo_count,
        },
      ]

      return { data: formattedData, campaigns: data?.campaigns }
    },
  })
}
