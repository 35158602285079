import { ChartCard } from '@/container/admin/components/Cards/ChartCard'
import { RootState } from '@/redux/rootReducers'
import { useSelector } from 'react-redux'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useMessagesSummary } from '../../hooks/use-messages-summary'
import styled from 'styled-components'

const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`

const SummaryCard = styled.div<{ isDark: boolean }>`
  background: ${({ isDark }) => isDark ? '#1E1E1E' : '#fff'};
  border: 1px solid ${({ isDark }) => isDark ? '#333333' : '#f0f0f0'};
  border-radius: 8px;
  padding: 1rem;

  h3 {
    color: ${({ isDark }) => isDark ? '#E1E1E1' : '#000'};
    margin: 0;
    font-size: 0.875rem;
  }

  p {
    color: #FF6B00;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0 0;
  }
`

export function MessagesSummary() {
  const { data, isLoading } = useMessagesSummary()
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  return (
    <ChartCard title="Resumo de Mensagens" loading={isLoading}>
      <SummaryContainer>
        <SummaryCard isDark={isDark}>
          <h3>Total Hoje</h3>
          <p>{data?.today}</p>
        </SummaryCard>
        <SummaryCard isDark={isDark}>
          <h3>Média por Hora</h3>
          <p>{data?.averagePerHour}</p>
        </SummaryCard>
        <SummaryCard isDark={isDark}>
          <h3>Taxa de Entrega</h3>
          <p>{data?.deliveryRate}%</p>
        </SummaryCard>
        <SummaryCard isDark={isDark}>
          <h3>Taxa de Leitura</h3>
          <p>{data?.readRate}%</p>
        </SummaryCard>
      </SummaryContainer>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={data?.chart}>
          <XAxis 
            dataKey="hour" 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <YAxis 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: isDark ? '#1E1E1E' : '#fff',
              border: `1px solid ${isDark ? '#333333' : '#f0f0f0'}`,
              color: isDark ? '#E1E1E1' : '#000'
            }}
          />
          <Area type="monotone" dataKey="count" stroke="#FF6B00" fill="#FF6B0033" />
        </AreaChart>
      </ResponsiveContainer>
    </ChartCard>
  )
} 