import { Col, Form, Row } from 'antd';
import { StyledInput } from '../Inputs';
import { AddressFields } from './address-fields';
export function AddressZipCode() {
  return (
    <>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item name="detalhe_estrangeiro" label="Zip Code" rules={[{ required: true, message: 'Obrigatório' }]}>
            <StyledInput placeholder="Digite o seu endereço completo" />
          </Form.Item>
        </Col>
      </Row>

      <AddressFields required={false} />
    </>
  );
}
