import { Spin } from 'antd';
import styled, { css } from 'styled-components';
import { StyledSpinProps } from '.';

export const StyledSpin = styled(Spin)<StyledSpinProps>`
  .ant-spin-dot-item {
    background-color: ${(props) => props.theme.fonts.color.primary};
  }

  .ant-spin-text {
    font-size: 16px;
    color: ${(props) => props.theme.fonts.color.primary};
    margin-top: 10px;
    font-weight: bold;
  }

  ${({ color, theme }) =>
    color &&
    css`
      .ant-spin-dot-item {
        background-color: ${theme.colors[color]} !important;
      }
    `}
`;
