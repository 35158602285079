import styled from "styled-components"

export const PaymentOptionsContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 2.5rem;
  }
`

export const CardContainer = styled.div`
  display: flex;
  max-width: 700px;
  max-height: 3.5rem;
  flex: 1;
  gap: 0.5rem;
`

export const CustomValueOption = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-family: 'Roboto';
  max-width: 700px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`