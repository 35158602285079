import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useHandleSendRecurrenceRemind() {
  const queryClient = useQueryClient()

  const recurrenceRemind = useMutation({
    mutationFn: async ({ lista_processamento_pedido }) => {
      await DataService.post('/checkout/enviar-lembrete-email-doacao-nao-paga', { lista_processamento_pedido })
      return lista_processamento_pedido.length
    },
    onSuccess: (num_pedidos) => {
      queryClient.invalidateQueries({ queryKey: ['RECURRENCE_DONATIONS_NAO_EFETUADAS'] })
      notification.success({
        message: `${num_pedidos} lembrete(s) enviado(s) com sucesso!`,
      })
    },
    onError: () => {
      notification.error({
        message: 'Ocorreu um erro ao enviar o(s) lembrete(s).',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return recurrenceRemind
}
