import moment from 'moment'
import BaseCard from '../../../../components/card/BaseCard'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { RecibosFilterValues } from '../../../../hooks/agape/queries/useGetBeneficiados'
import BaseRadio from '../../../../components/radio/BaseRadio'

type CpfFilterProps = {
  value?: string
  handleInputChange: ({ cpf }: { cpf: string }) => void
}

function CpfFilter({ value, handleInputChange }: CpfFilterProps) {
  return (
    <BaseCard>
      <h4>CPF</h4>
      <BaseInput
        size="middle"
        allowClear
        placeholder="Digite o documento"
        prefix={<MagnifyingGlass />}
        value={value}
        onChange={(e) => handleInputChange({ cpf: e.target.value })}
      />
    </BaseCard>
  )
}

type DataRecebimentoFilterProps = {
  value: { data_inicial: string; data_final: string } | null
  handleInputChange: ({ data_inicial, data_final }: { data_inicial: string; data_final: string }) => void
}

function DataRecebimentoFilter({ value, handleInputChange }: DataRecebimentoFilterProps) {
  const formattedValue = [
    value?.data_inicial ? moment(value.data_inicial, 'YYYY-MM-DD') : null,
    value?.data_final ? moment(value.data_final, 'YYYY-MM-DD') : null,
  ]

  return (
    <BaseCard>
      <h4>Data do Recebimento</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        style={{ width: '100%' }}
        onChange={(_: never, dateStrings: [string, string]) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

          handleInputChange({
            data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY"
        placeholder={['Inicial', 'Final']}
      />
    </BaseCard>
  )
}

interface ReciboFilterProps {
  value?: RecibosFilterValues | null
  handleInputChange: ({ recibos }: { recibos: RecibosFilterValues }) => void
}

function RecibosFilter({ value, handleInputChange }: ReciboFilterProps) {
  return (
    <BaseCard>
      <h4>Recibos</h4>
      <BaseRadio.Group value={value} onChange={(e) => handleInputChange({ recibos: e.target.value })}>
        <BaseRadio.Button value="com_recibo">Com recibo</BaseRadio.Button>
        <BaseRadio.Button value="sem_recibo">Sem recibo</BaseRadio.Button>
        <BaseRadio.Button>X</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

export const BeneficiadosFilters = {
  Cpf: CpfFilter,
  DataRecebimento: DataRecebimentoFilter,
  Recibo: RecibosFilter,
}
