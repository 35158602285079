import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts'
import { ChartTitle, SliderChartTitle, TooltipContainer } from '../../../styles'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { getCurrentMonthAndYear } from '../CurentMonthDonations/utils'
import { ComposedCard } from '@/container/admin/components/composed-card'
import { FilterIcon } from '@/container/admin/components/FilterIcon'
import { BlurDiv } from '@/context/Blur/BlurDiv'

const CustomTooltip = ({ payload }) => {
  const paidValue = payload?.[0]?.payload?.pago
  const unPaidValue = payload?.[1]?.payload?.nao_pago

  const date = payload?.[0]?.payload?.dia

  return (
    <TooltipContainer type="finance">
      <strong style={{ color: 'var(--text-main-color)' }}>{date}</strong>
      <BlurDiv>
        <div>
          <strong>Pago: </strong> {formatToBrazilianCurrency(paidValue)}
        </div>
        <div className="finance_nao_pago">
          <strong>Não pago: </strong> {formatToBrazilianCurrency(unPaidValue)}
        </div>
      </BlurDiv>
    </TooltipContainer>
  )
}

export function Chart({ formattedData, onFilterClick }) {
  return (
    <ResponsiveContainer width="100%" height={260} style={{ overflow: 'hidden' }}>
      <SliderChartTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <div className='title-and-icon-container'>
              {/* Total do mês - {getCurrentMonthAndYear()} */}
              Recorrências do mês
              <FilterIcon onClick={onFilterClick} />
            </div>
            <BlurDiv className="percentage">
              <ComposedCard.Balance isPositive={false} >
                14.63%
              </ComposedCard.Balance>
            </BlurDiv>
          </div>
          <BlurDiv className="total-value">{formatToBrazilianCurrency(15412.56)}</BlurDiv>
        </div>
      </SliderChartTitle>
      {/* <ChartTitle style={{ fontWeight: 'bold', position: 'absolute', padding: '1rem 2rem' }}></ChartTitle> */}
      {formattedData?.length > 0 ? (
        <LineChart data={formattedData} margin={{ top: 80, left: 0, right: 0, bottom: 10 }}>
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Line type="monotone" dataKey="pago" stroke="var(--success-color)" dot={false} />
          <Line type="monotone" dataKey="nao_pago" stroke="var(--error-color)" dot={false} />
        </LineChart>
      ) : (
        <div style={{ height: '20rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h4 style={{ margin: '0', color: 'gray', textAlign: 'center' }}>
            Por enquanto não há dados a serem carregados
          </h4>
        </div>
      )}
    </ResponsiveContainer>
  )
}
