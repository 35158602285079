import { formatNumber } from '@/utility/utility';
import { FunnelDatum, FunnelPartWithHandlers, ResponsiveFunnel } from '@nivo/funnel';
import { AnimatedBaseCard, PieChartTitle, TooltipContainer } from '../../../styles';

const data = [
  {
    "id": "leads",
    "value": 84879,
    "label": "Leads"
  },
  {
    "id": "membros",
    "value": 55656,
    "label": "Membros"
  },
  {
    "id": "benfeitores",
    "value": 35780,
    "label": "Benfeitores"
  },
]
function FunnelTooltip<D extends FunnelDatum>({ part }: Readonly<{ part: FunnelPartWithHandlers<D> }>) {
  return (
    <TooltipContainer type="signup">
      <strong>{part?.data?.label}</strong>
      <div>{formatNumber(part?.data?.value)} Cadastros</div>
    </TooltipContainer>
  );
}

export function Funnel() {
  return (
    <AnimatedBaseCard padding="0px">
      <PieChartTitle style={{ padding: '2rem 0 0 2rem' }}>Benfeitores, Membros e Leads</PieChartTitle>
      <div style={{ height: "350px" }}>
        <ResponsiveFunnel
          data={data}
          margin={{ top: 10, right: 140, bottom: 30, left: 140 }}
          interpolation="smooth"
          shapeBlending={0.2}
          colors={["#3dadff", "#2cd997", "#ff9e42"]}
          borderWidth={20}
          borderOpacity={0.5}
          enableLabel={false}
          tooltip={({ part }) => FunnelTooltip({ part })}
          labelColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                3
              ]
            ]
          }}
          enableBeforeSeparators={false}
          enableAfterSeparators={false}
          // beforeSeparatorLength={50}
          // beforeSeparatorOffset={50}
          // afterSeparatorLength={25}
          // afterSeparatorOffset={75}
          currentPartSizeExtension={10}
          currentBorderWidth={25}
          motionConfig="wobbly"
        />
      </div>
    </AnimatedBaseCard>
  )
}