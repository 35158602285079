import { http, HttpResponse } from 'msw'
import { GetFamiliasResponse } from '../../../../container/admin/hooks/agape/queries/useGetFamilias'

const response: GetFamiliasResponse['familias'] = Array.from({ length: 30 }).map((_, i) => {
  return {
    cadastrado_em: new Date().toISOString(),
    familia: `Família ${i + 1}`,
    fk_endereco_id: Math.floor(Math.random() * 1000) + 1,
    id: i,
    membros: Math.floor(Math.random() * 10) + 2,
    recebimentos: i % 2 === 0 ? i : 0,
    renda: Math.floor(Math.random() * 1000) + i,
    ultimo_recebimento: null,
  }
})

export const listarFamiliasMock = http.get<never, never, GetFamiliasResponse>(
  '/agape/listar-familias',
  ({ request }) => {
    const { searchParams } = new URL(request.url)

    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1
    const itemsPerPage = Number(searchParams.get('per_page')) || 10

    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const paginateFamily = response.slice(startIndex, endIndex)

    return HttpResponse.json({
      familias: paginateFamily,
      page,
      total: response.length,
    })
  },
)
