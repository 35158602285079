import styled, { css } from 'styled-components';

import { Button, Card } from 'antd';
import { NavLink } from 'react-router-dom';

const InnerComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #6d6d6d;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &:link {
    text-decoration: none;
    color: inherit;
  }
`;

const Description = styled.div`
  ${({ wrapper }) =>
    wrapper &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5; /* Número de linhas a exibir antes de aplicar o elipse */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 10rem; /* Ajuste conforme necessário para acomodar 5 linhas */
      line-height: 1.2em; /* Ajuste conforme o tamanho da fonte para corresponder às linhas desejadas */
      max-height: calc(1.2em * 5); /* Ajuste conforme o número de linhas e tamanho da fonte */
    `};
`;
const CampaingCardButton = styled(Button)`
  background-color: #28201e !important;
  font-weight: normal;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.family.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 24px 6px !important;
  gap: 5px;
  color: #fff;
  border: none;
  width: 70% !important;
  margin: 1rem auto 0px auto;

  :hover {
    color: #fff;
    opacity: 0.8;
  }

  :disabled {
    color: #fff;
    background: #cbcbcb;
  }

  :hover:disabled {
    color: #fff;
    opacity: 0.4;
  }

  :focus {
    color: #fff;
    opacity: 0.8;
  }
`;

const CampaignCardMoreInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border: none;
  color: #28201e;

  :hover {
    color: #28201e;
    opacity: 0.8;
  }
`;

const CampaingCard = styled(Card)`
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 320px;
  /* min-width: 260px; */

  border-radius: 16px !important;
  border: 0;
  overflow: hidden !important;

  .ant-card-meta-title {
    text-align: center;
    font-weight: 700;
    letter-spacing: 0px;
    color: #404040;
  }

  .ant-card-body {
    padding: 6px 12px;
  }

  /* @media (max-width: 768px) {
    max-width: 300px;
    max-height: 300px;
  } */

  .ant-card-cover img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1.5;
  }

  .ant-skeleton-element {
    width: 100%;
  }

  .ant-skeleton-image {
    width: 100%;
    min-height: 149px;
    min-width: 300px;
  }

  .headercard-styled {
    height: 100px;
  }
`;

export { InnerComponent, Description, CampaingCardButton, CampaignCardMoreInfo, StyledNavLink, CampaingCard };
