import { ReactNode } from 'react'

import * as S from './style'

interface LayoutWrapperProps {
  children: ReactNode
}

export function LayoutWrapper({ children }: LayoutWrapperProps) {
  return <S.StyledLayoutWrapper>{children}</S.StyledLayoutWrapper>
}
