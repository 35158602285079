import { http, HttpResponse } from 'msw'
import { RegisterCompleteUser } from '../../container/admin/hooks/users/Mutations/useRegisterCompleteUser'

export const cadastrarUsuarioCompletoMock = http.post<never, RegisterCompleteUser, { msg: string }>(
  '/users/cadastrar-usuario-completo',
  async () => {
    return HttpResponse.json({
      msg: 'Usu\u00e1rio cadastrado com sucesso.',
    })
  },
)
