import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Step1 from './Step1'
import Step2 from './Step2'
import { useCreateCampaign, useMultistepForm, useRedirectOnPermDenied } from '../../../hooks'
import { resetForm } from '../../../../../redux/campaign/actions'
import { ButtonsWrapper } from '../Style'
import BaseButton from '../../../components/button/BaseButton'
import BaseCard from '../../../components/card/BaseCard'
import { useFetchAdminSingleCampaign } from '../../../hooks/campaigns/queries/useFetchAdminSingleCampaign'
import moment from 'moment'

export const CreateOrEditCampaign = ({ match }) => {
  useRedirectOnPermDenied('campanha', 'criar')
  const dispatch = useDispatch()
  const campaignId = match.params.id

  const [form] = Form.useForm()
  const location = useLocation()

  const { mutate: createCampaign, isPending } = useCreateCampaign()
  const { data: campaign } = useFetchAdminSingleCampaign(campaignId)

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  const [formData, setFormData] = useState({
    status: null,
    publica: null,
    preenchimento_foto: null,
    duracao: null,
    objetivo: null,
    cadastrar_landing_page: null,
    titulo: null,
    label_foto: null,
    data_inicio: null,
    data_fim: null,
    descricao: null,
    texto_email_pos_registro: null,
    texto_pos_registro: null,
    url: null,
    valor_meta: null,
    cadastros_meta: null,
    tipo_cadastro: null,
    capaImage: [],
    bannerImage: [],
  })

  useEffect(() => {
    if (campaign) {
      const dataInicio = moment(campaign?.dados_campanha?.data_inicio, 'DD-MM-YYYY HH:mm:ss')
      const dataFim = moment(campaign?.dados_campanha?.data_fim, 'DD-MM-YYYY HH:mm:ss')

      console.log(campaign)

      setFormData({
        capaImage: [{ url: campaign?.dados_campanha?.filename }],
        bannerImage: [{ url: campaign?.dados_landing_page?.banner }],
        ...campaign?.dados_landing_page,
        ...campaign?.dados_setup_pagamento,
        ...campaign?.dados_campanha,
        status: Number(campaign.dados_campanha.status),
        titulo: campaign.dados_campanha.campanha_nome,
        publica: Number(campaign.dados_campanha.publica),
        data_inicio: dataInicio.isValid() ? dataInicio : null,
        data_fim: dataFim.isValid() ? dataFim : null,
        // if strings with dots, remove the dot and the numbers after it:
        valor_meta: parseInt(
          campaign?.dados_campanha.valor_meta?.indexOf('.') > -1
            ? campaign?.dados_campanha.valor_meta?.substring(0, campaign?.dados_campanha.valor_meta?.indexOf('.'))
            : null,
          10,
        ),
        // if dados_landing_page object is empty then send cadastrar landing_page as false, also implying that is a donation campaign:
        cadastrar_landing_page:
          campaign?.dados_landing_page && Object.keys(campaign.dados_landing_page).length === 0 ? 0 : 1,
      })
    }
  }, [campaign, dispatch])

  const { step, isFirstStep, isLastStep, back, next } = useMultistepForm(
    [
      <Step1 form={form} formData={formData} handleInputChange={handleInputChange} />,
      <Step2 form={form} formData={formData} handleInputChange={handleInputChange} />,
    ],
    form,
    formData,
  )

  const handleFormFinish = async () => {
    try {
      await form.validateFields()

      if (campaignId) {
        return createCampaign({
          formData,
          capaImage: formData.capaImage,
          bannerImage: formData.bannerImage,
          campaignId: campaign.dados_campanha.id,
          update: true,
        })
      }

      createCampaign({
        formData,
        capaImage: formData.capaImage,
        bannerImage: formData.bannerImage,
      })
    } catch (error) {
      console.error('Validation failed:', error)
    }
  }

  return (
    <>
      <BaseCard
        title="Nova Campanha"
        extra={
          <BaseButton>
            <Link to="/admin/campaigns/view-all">Listagem</Link>
          </BaseButton>
        }
      >
        <div>
          {step}
          <ButtonsWrapper>
            {!isFirstStep && <BaseButton onClick={back}>Voltar</BaseButton>}
            <BaseButton
              onClick={isLastStep || formData?.objetivo === 'cadastro' ? handleFormFinish : next}
              disabled={isPending}
              loading={isPending}
            >
              {isLastStep || formData?.objetivo === 'cadastro' ? 'Enviar' : 'Avançar'}
            </BaseButton>
          </ButtonsWrapper>
        </div>
      </BaseCard>
    </>
  )
}
