import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface GetItensDoadosBeneficiadoProps {
  fk_doacao_agape_id: number
}

interface GetItensDoadosBeneficiadoResponse {
  itens_doados: {
    item: string
    quantidade: number
  }[]
}

export function useGetItensDoadosBeneficiado({ fk_doacao_agape_id }: GetItensDoadosBeneficiadoProps) {
  const itensDoadosBeneficiado = useQuery({
    queryKey: ['ITENS_DOADOS_BENEFICIADO', fk_doacao_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetItensDoadosBeneficiadoResponse>(
        `/agape/listar-itens-doados-beneficiario/${fk_doacao_agape_id}`,
      )

      return response.data
    },
  })

  return itensDoadosBeneficiado
}
