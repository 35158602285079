import { DetalhesFichaVocacional } from '../detalhes'
import { ReenviarEmailVocacional } from '../../ReenviarEmail'
import { StatusMap } from '../../../utils'
import { FichaVocacionalFilters } from './filters'
import { ExcluirUsuarioVocacional } from '../../ExcluirUsuario'
import {
  GetAllFichaVocacionalProps,
  GetAllFichaVocacionalResponse,
} from '../../../../../hooks/vocacional/queries/useFetchGetAllFichaVocacional'
import { handleCpfShowValues, handlePhoneNumberFormat } from '../../../../../../../utility/utility'
import { Col, Row, TablePaginationConfig } from 'antd'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'

interface CadastroTableProps {
  data: GetAllFichaVocacionalResponse | undefined
  handleInputChange: (filter: GetAllFichaVocacionalProps['filters'], pagination?: boolean) => void

  isLoading: boolean
  isError: boolean
  filters: GetAllFichaVocacionalProps['filters']
}

export function FichaVocacionalTable({ data, handleInputChange, isLoading, isError, filters }: CadastroTableProps) {
  const visualizandoAprovados = filters?.status === 'aprovado'

  const dataSource = data?.fichas_vocacionais.map((item) => {
    return {
      id: item.cadastro_vocacional.fk_usuario_vocacional_id,
      nome: item.pre_cadastro.nome,
      email: item.pre_cadastro.email,
      numero_documento:
        item.pre_cadastro.pais === 'brasil'
          ? handleCpfShowValues(item.cadastro_vocacional.documento_identidade)
          : item.cadastro_vocacional.documento_identidade,
      data_nascimento: item.cadastro_vocacional.data_nascimento,
      telefone:
        item.pre_cadastro.pais === 'brasil'
          ? handlePhoneNumberFormat(item.pre_cadastro.telefone)
          : item.pre_cadastro.telefone,
      status: StatusMap[item.ficha_do_vocacional.status],
      cadastro: item.pre_cadastro.created_at,
      responsavel: item.ficha_do_vocacional.responsavel ?? ' - ',
      acoes: (
        <Row justify="center" gutter={[20, 20]}>
          <Col>
            <DetalhesFichaVocacional
              atualizarStatus={!visualizandoAprovados}
              usuario_vocacional_id={item.cadastro_vocacional.fk_usuario_vocacional_id}
              dados={item}
            />
          </Col>

          {visualizandoAprovados && (
            <ReenviarEmailVocacional usuario_vocacional_id={item.cadastro_vocacional.fk_usuario_vocacional_id} />
          )}

          <Col>
            <ExcluirUsuarioVocacional
              fk_usuario_vocacional_id={item.cadastro_vocacional.fk_usuario_vocacional_id}
              nome_usuario={item.pre_cadastro.nome}
              listagem="ficha-vocacional"
            />
          </Col>
        </Row>
      ),
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      align: 'center',
      filterDropdown: () => <FichaVocacionalFilters.Nome value={filters?.nome} handleInputChange={handleInputChange} />,
      filteredValue: filters?.nome && [filters.nome],
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      filterDropdown: () => (
        <FichaVocacionalFilters.Email value={filters?.email} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.email && [filters.email],
    },
    {
      title: 'Documento',
      dataIndex: 'numero_documento',
      key: 'numero_documento',
      align: 'center',
      filterDropdown: () => (
        <FichaVocacionalFilters.DocumentoIdentidade
          value={filters?.documento_identidade}
          handleInputChange={handleInputChange}
        />
      ),
      filteredValue: filters?.documento_identidade && [filters.documento_identidade],
    },
    {
      title: 'Data de nascimento',
      dataIndex: 'data_nascimento',
      key: 'data_nascimento',
      align: 'center',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      align: 'center',
      filterDropdown: () => (
        <FichaVocacionalFilters.Telefone value={filters?.telefone} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.telefone && [filters.telefone],
    },
    {
      title: 'Cadastro em',
      dataIndex: 'cadastro',
      key: 'cadastro',
      align: 'center',
      filterDropdown: () => (
        <FichaVocacionalFilters.CadastroEm
          value={{ data_inicial: filters?.data_inicial ?? '', data_final: filters?.data_final ?? '' }}
          handleInputChange={handleInputChange}
        />
      ),
      filteredValue: filters?.data_inicial && filters?.data_final && [filters?.data_inicial, filters?.data_final],
    },
    ...(filters?.status === 'aprovado'
      ? [
          {
            title: 'Responsável',
            dataIndex: 'responsavel',
            key: 'responsavel',
            align: 'center',
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filterDropdown: () => (
        <FichaVocacionalFilters.Status value={filters?.status} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.status && [filters.status],
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      align: 'center',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoading}
      isError={isError}
      pagination={{
        current: data?.page,
        total: data?.total,

        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
    />
  )
}
