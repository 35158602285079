import BaseCard from '../../../../../components/card/BaseCard'
import { Content } from './Content'

export function CurrentMonthSignups() {
  return (
    <BaseCard padding="0px">
      <Content />
    </BaseCard>
  )
}
