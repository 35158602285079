import { Result, Row } from 'antd';
import { Title } from '../../../components/Title';

export function CarteiraConfirmada() {
  return (
    <Row justify="center" style={{ minHeight: '60vh', alignItems: 'center' }}>
      <Result
        status="success"
        title={<Title>Carteira confirmada</Title>}
        subTitle="Localizamos seu cadastro, em breve nossa equipe estará enviando sua carteirinha!"
      />
    </Row>
  );
}
