import { useQuery } from '@tanstack/react-query';
import { DataService } from '../../../../../../lib/dataService';

type Doacao = {
  data_doacao: string;
  descricao: string;
  forma_pagamento: string;
  id: number;
  imagem_campanha: string;
  recorrencia: string;
  recorrencia_ativa: boolean;
  titulo: string;
  valor_doacao: string;
};

type DonationsMeFilters = {
  queryString: string;
};

export function useFetchDonationsMe(filters: DonationsMeFilters) {
  const donationsMe = useQuery<{ data: Doacao[] }>({
    queryKey: ['users/doacoes', filters],
    queryFn: async () => {
      const response = await DataService.get(`users/doacoes?${filters?.queryString}`);

      return response.data;
    },
  });

  return donationsMe;
}
