import { useMutation, useQueryClient } from '@tanstack/react-query'
import BaseButton from '../../../../components/button/BaseButton'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import BaseModal from '../../../../components/modal/BaseModal'
import { BaseSelect } from '../../../../components/select/BaseSelect'
import { DataService } from '../../../../../../lib/dataService'
import { BaseNotification } from '../../../../components/notification/BaseNotification'

export function CreateChannelModal({ isChannelModalOpen, setIsChannelModalOpen }) {
  const queryClient = useQueryClient()

  const createChannelMutation = useMutation({
    mutationFn: (channelData) => DataService.post('youtube/create-channel', channelData),
    onSuccess: () => {
      BaseNotification.success({ message: 'Canal criado com sucesso!' })
      queryClient.invalidateQueries(['CHANNELS'])
      setIsChannelModalOpen(false)
    },
    onError: (error) => {
      console.error(error)
      BaseNotification.error({ message: 'Ocorreu um erro ao criar o canal.' })
    },
  })

  return (
    <BaseModal
      bodyStyle={{ height: 'auto' }}
      title="Novo Canal"
      open={isChannelModalOpen}
      onOk={() => setIsChannelModalOpen(false)}
      onCancel={() => setIsChannelModalOpen(false)}
      footer={null}
      closeIcon="X"
      size="large"
    >
      <BaseForm
        name="channelForm"
        onFinish={(values) => {
          createChannelMutation.mutate(values)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2rem',
            flex: '1',
            justifyContent: 'center',
          }}
        >
          <BaseForm.Item
            label="Nome do Canal"
            name="tag"
            rules={[{ required: true, message: 'Nome do canal é obrigatório' }]}
          >
            <BaseInput placeholder="Insira o nome do canal" style={{ height: 40 }} />
          </BaseForm.Item>

          <BaseForm.Item
            label="Rede Social"
            name="rede_social"
            rules={[
              {
                required: true,
                message: 'Rede social é obrigatória',
                validator: (_, value) => {
                  if (value !== undefined) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Obrigatório'))
                },
              },
            ]}
          >
            <BaseSelect placeholder="Selecione a rede" style={{ minWidth: '200px' }}>
              <Option value="">Ambos</Option>
              <Option value="youtube">Youtube</Option>
              <Option value="facebook">Facebook</Option>
            </BaseSelect>
          </BaseForm.Item>
        </div>

        <BaseButton htmlType="submit" style={{ margin: '0 auto' }}>
          Adicionar Canal
        </BaseButton>
      </BaseForm>
    </BaseModal>
  )
}
