import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../lib/dataService'
import { notification } from 'antd'

export function useUpdateProfilePermission(selectedProfileId: number) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (updatedProfilePermission: { permission: string; fk_menu_id: number; value: boolean }) => {
      const { permission, fk_menu_id, value } = updatedProfilePermission
      await DataService.put(`/administradores/editar-permissoes-perfil/${selectedProfileId}`, {
        [permission]: value,
        fk_menu_id,
      })
    },
    onMutate: async (variables) => {
      const previousData = queryClient.getQueryData(['buscar-perfil', selectedProfileId])
      queryClient.setQueryData(['buscar-perfil', selectedProfileId], (oldData: any) => {
        return {
          ...oldData,
          menus: oldData.menus.map((menu: any) => {
            if (menu.fk_menu_id === variables.fk_menu_id) {
              return {
                ...menu,
                [variables.permission]: variables.value,
              }
            }
            return menu
          }),
        }
      })
      return { previousData }
    },
    onError: (error: any, _, context) => {
      queryClient.setQueryData(['buscar-perfil', selectedProfileId], context?.previousData)

      notification.error({
        message: 'Ocorreu um erro ao atualizar a permissão.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['buscar-perfil', selectedProfileId] })
    },
  })
}
