import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface GetCsvBeneficiadosResponse {
  url: string
}

interface GetCsvBeneficiadosProps {
  fk_instancia_acao_agape_id: number
}

export function useDownloadCsvBeneficiados() {
  const csvBeneficiados = useMutation({
    mutationFn: async ({ fk_instancia_acao_agape_id }: GetCsvBeneficiadosProps) => {
      const response = await DataService.get<GetCsvBeneficiadosResponse>(
        `/agape/exportar-doacoes-beneficiados/${fk_instancia_acao_agape_id}`,
      )

      return response.data
    },
    onSuccess: async ({ url }) => {
      const a = document.createElement('a')
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    onError: (error: any) => {
      notification.error({
        message: 'Houve um erro ao fazer o download do CSV.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return csvBeneficiados
}
