import { Result } from 'antd'
import { GoldenHeader } from './golden-header/styles'

export const SignUpCompleted = () => (
  <Result
    status="info"
    title={
      <GoldenHeader>
        Acabamos de te enviar um e-mail, pedimos que o acesse e clique no link de confirmação.
      </GoldenHeader>
    }
    subTitle={
      <div style={{ marginTop: '2rem' }}>
        Essa ação é necessária para que possamos ativar sua conta, não esqueça de checar a caixa de spam.
      </div>
    }
    style={{ paddingTop: '10rem' }}
  />
)
