import styled from 'styled-components'
import mobileBgImage from '../../../../images/bg-1-mobile.png'
import desktopBgImage from '../../../../images/lp-member-pc-bg.png'

export const FirstSectionStyled = styled.div`
  background-image: url(${mobileBgImage});
  mask-image: linear-gradient(to bottom, transparent, black 0%, black 95%, transparent);

  /* height: 95vh; */
  /* height: 100vh; */
  min-height: 100vh;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;

    span {
      font-weight: 700;
    }
  }

  .content {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 900px) {
    flex-direction: row-reverse;
    justify-content: center;
    background-image: url(${desktopBgImage});

    .content {
      max-width: 500px;
    }

    p {
      text-align: left;
      font-size: 28px;
      line-height: 37px;
    }

    img {
      margin-left: 0;
    }
  }
`

export const HeaderWrapper = styled.div`
  /* max-width: 340px; */
  margin-top: -3rem;
  margin-left: auto;
  margin-right: auto;

  div {
    font-size: 40px !important;
    line-height: 46px;
  }

  @media (min-width: 900px) {
    div {
      text-align: left;
    }

    div {
      font-size: 56px !important;
      line-height: 64px;
    }
  }
`

export const LogoWrapper = styled.div`
  display: block;

  .mobile {
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    max-width: 350px;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: -2.8rem;
  }

  .desktop {
    display: none;
    width: 100%;
    max-width: 348px;
  }

  @media (min-width: 900px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
      margin-bottom: 6rem;
    }
  }
`

export const MainImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* transform: scale(1.2); */
  /* width: 110%; */

  img {
    @media (min-width: 900px) {
      max-width: 600px;
      transform: scale(1.5);
    }
  }
`
