import { useState } from 'react'

import { PreCadastradosTable } from './tabela'
import { VocacionalInitialFiltersProps } from '../../utils'
import { generateQueryString, handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import {
  GetAllPreCadastroProps,
  useFetchGetAllPreCadastro,
} from '../../../../hooks/vocacional/queries/useFetchGetAllPreCadastro'

export function PreCadastrados({ vocacionalGender }: VocacionalInitialFiltersProps) {
  const FiltrosPreCadastros = { genero: vocacionalGender, status: 'pendente' as const }

  const initialFilters = {
    queryString: generateQueryString(FiltrosPreCadastros),
    ...FiltrosPreCadastros,
  } as const

  const [filters, setFilters] = useState<GetAllPreCadastroProps['filters']>(initialFilters)

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: preCadastros, isLoading, isError } = useFetchGetAllPreCadastro({ filters })

  return (
    <PreCadastradosTable
      data={preCadastros}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      isError={isError}
      filters={filters}
    />
  )
}
