import styled from 'styled-components'

export const Wrapper = styled.div`
  h3,
  h2 {
    color: ${({ iframeStyles }) => iframeStyles.labelColor};
  }

  label {
    color: ${({ iframeStyles }) => iframeStyles.labelColor};
  }
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .ant-input-number {
    :not(.ant-input-status-error, .ant-input-affix-wrapper-status-error) {
      border: ${({ border }) => border || `1.5px solid #d9d9d9`};
    }

    border-radius: ${({ borderRadius = 8 }) => `${borderRadius}px`} !important;

    line-height: ${({ lineHeight }) => lineHeight || 3} !important;

    min-width: ${({ minWidth }) => `${minWidth}rem`};

    background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.white} !important;

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.colors['gray-400']} !important;
    }

    &:focus {
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors['gray-800']} !important;
    }

    &:disabled {
      background-color: #f0f0f0 !important;
    }
  }
`

export const BannndeContainer = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;

  .title-wrapper {
    margin-top: 7rem;

    @media (max-width: 1200px) {
      margin-top: 5rem;
    }

    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
`

export const ShadowBanner = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 100%;
`

export const BackgroundContainer = styled.div`
  background: ${({ background }) => `url(${background})`};
  background-size: 100% auto;
  background-repeat: no-repeat;
`
