import { Result } from 'antd'
import { GeneralStepForm } from '../../LandingPage/components/GeneralForm'
import { LandingPageStepForm } from '../../LandingPage/components/LandingPageForm'
import { LandingPageProps } from '../../LandingPage/types/LandingPageProps'
import { SectionTitle } from '../styles'
import { useFindUserByCpfResponse } from '../../../../admin/hooks/users/Queries/useFindUserByDocumentNumber'

interface UserFlowProps {
  userData: useFindUserByCpfResponse
  landingPageData: LandingPageProps
  markAsFinished: Function
}

export function UserFlow({ userData, landingPageData, markAsFinished }: UserFlowProps) {
  if (!userData.is_user && !userData.is_general) {
    return (
      <div>
        <SectionTitle data-testid="users-flow">E-mail não encontrado, efetue seu cadastro:</SectionTitle>
        <LandingPageStepForm
          landingPageData={landingPageData}
          showUserInfoSection={false}
          markAsFinished={() => markAsFinished('LandingPageForm')}
        />
      </div>
    )
  }

  if (userData.is_user && !userData.is_general) {
    return (
      <div>
        <SectionTitle data-testid="users-flow">Torne-se General:</SectionTitle>
        <GeneralStepForm markAsFinished={() => markAsFinished('GeneralForm')} initialFields={userData} />
      </div>
    )
  }

  if (userData.is_general) {
    return (
      <div>
        <SectionTitle data-testid="users-flow">
          <Result status="success" title="Parabéns, você já é general!" subTitle="Entraremos em contato em breve" />
        </SectionTitle>
      </div>
    )
  }
}
