import { DashboardWrapper, LeftSection, RightSection } from '../styles';
import { AnimatedCarousel } from './components/AnimatedCarousel';
import { useDashboardSlides } from './components/AnimatedCarousel/hooks/use-dashboard-slides';
import { AnimatedChartWrapper } from './components/AnimatedChartWrapper';
import { DonationsBarPerCampaign } from './components/DonationsBarPerCampaign';
import { DonationsByUserType } from './components/DonationsByUserType';
import { DonationsCountByHour } from './components/DonationsCountByHour';
import { DonationsPerDayOfWeek } from './components/DonationsPerDayOfWeek';
import { DonationsPerMonth } from './components/DonationsPerMonth';
import { DonationsPerType } from './components/DonationsPerType';
import { PaymentMethod } from './components/PaymentMethod';
import { TodayDonationsList } from './components/TodayDonationsList';

export function FinancesDashboard(): JSX.Element {

  const slides = useDashboardSlides()

  return (
    <DashboardWrapper>
      <LeftSection>
        <div>
          <AnimatedChartWrapper index={0}>
            <AnimatedCarousel slides={slides} type='finance' />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={1} >
            <DonationsBarPerCampaign />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={2}>
            <TodayDonationsList />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={3}>
            <DonationsPerMonth />
          </AnimatedChartWrapper>
        </div>
      </LeftSection>

      <RightSection>
        <div>
          <AnimatedChartWrapper index={0}>
            <PaymentMethod />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={1}>
            <DonationsPerType />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={2}>
            <DonationsPerDayOfWeek />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={3}>
            <DonationsCountByHour />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={4}>
            <DonationsByUserType />
          </AnimatedChartWrapper>
        </div>
      </RightSection>
    </DashboardWrapper >
  );
}