import { UserOutlined, HeartOutlined, LockOutlined, CaretDownOutlined, PushpinOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Avatar, Dropdown, Menu } from 'antd'
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
// import { HeaderDropDownMenuSyled } from '../../../benfeitor/components/container/styled'
import { HeaderAvatar } from './styles'
import { useAuth } from '../../../../../../context/Auth'
import styled from 'styled-components'
import { useFetchUserMe } from '../../../../../admin/hooks'

// Styled Components
const StyledDropdownMenu = styled(Menu)`
  background-color: #fff9ed;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .ant-dropdown-menu-item {
    padding: 10px 16px;
    font-size: 14px;

    &:hover {
      background-color: #ffe9bb;
      color: #cb7031 !important;
    }

    .anticon {
      transition: color 0.2s ease-in-out;
    }
  }
`

const StyledButton = styled.button`
  border: none;
  background-color: #28201e;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #1d1716;
    color: gray !important;
    transition: 0.2s;
    width: 100%;
  }
`

export const DropdownMenuHeader = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: userMe } = useFetchUserMe()
  const { logout } = useAuth()

  const items = [
    ...(userMe?.super_perfil === true
      ? [
          {
            key: '1',
            icon: <LockOutlined />,
            label: <NavLink to="/admin">Admin</NavLink>,
          },
        ]
      : []),
    {
      key: '2',
      icon: <UserOutlined />,
      label: <NavLink to="/benfeitor/profile/view">Meu Perfil</NavLink>,
    },
    // {
    //   key: '3',
    //   icon: <PushpinOutlined />,
    //   label: <NavLink to="/benfeitor/profile/address">Endereço</NavLink>,
    // },
    {
      key: '4',
      icon: <HeartOutlined />,
      label: <NavLink to="/benfeitor/profile/donations">Minhas Doações</NavLink>,
    },
    // {
    //   key: '4',
    //   icon: <CreditCardOutlined />,
    //   label: <NavLink to="/benfeitor/auth/card/">Formas de Pagamento</NavLink>,
    // },
    {
      key: '5',
      icon: <LockOutlined />,
      label: <NavLink to="/benfeitor/profile/security">Segurança</NavLink>,
    },
    // {
    //   key: '6',
    //   icon: <UsergroupAddOutlined />,
    //   label: <NavLink to="/benfeitor/auth/generals">Generais</NavLink>,
    // },
    {
      key: '7',
      label: (
        <StyledButton type="button" onClick={logout}>
          Sair
        </StyledButton>
      ),
    },
  ]

  return (
    <div>
      <Dropdown overlay={<StyledDropdownMenu items={items} />} trigger={['click']}>
        <div>
          <HeaderAvatar src={userMe?.avatar} size={45} icon={<UserOutlined />} />
          <CaretDownOutlined />
        </div>
      </Dropdown>
    </div>
  )
}
