import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

interface BlurContextType {
  isBlurred: boolean
  changeBlur: () => void
}

const BlurContext = createContext<BlurContextType | undefined>(undefined)

interface BlurProps {
  children: ReactNode
}

const LOCAL_STORAGE_BLUR_ITEM_KEY = '@acutis-blur'

function getInitialState() {
  const isBlurred = localStorage.getItem(LOCAL_STORAGE_BLUR_ITEM_KEY)

  if (!isBlurred) {
    localStorage.setItem(LOCAL_STORAGE_BLUR_ITEM_KEY, 'false')
  }

  return isBlurred ? JSON.parse(isBlurred) : false
}

export function BlurProvider({ children }: BlurProps) {
  const [isBlurred, setIsBlurred] = useState(getInitialState())

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_BLUR_ITEM_KEY, JSON.stringify(isBlurred))
  }, [isBlurred])

  function changeBlur() {
    setIsBlurred((prev: boolean) => !prev)
  }

  const value: BlurContextType = useMemo(() => {
    return {
      isBlurred,
      changeBlur,
    }
  }, [isBlurred, changeBlur])

  return <BlurContext.Provider value={value}>{children}</BlurContext.Provider>
}

export function useBlur(): BlurContextType {
  const blurContext = useContext(BlurContext)

  if (!blurContext) {
    throw new Error('useBlur must be used within a BlurProvider')
  }

  return blurContext
}
