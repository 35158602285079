import { Skeleton } from 'antd'
import * as S from './GroupCard.Styles'

export default function GroupCardRoot({ children, ...props }) {
  if (props.isLoading) {
    return (
      <S.Card {...props}>
        <Skeleton.Avatar
          loading
          avatar
          active
          style={{ marginTop: '0.5rem' }}
        />
        <div
          className="skeleton-details-wrapper"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px',
            marginTop: '0.5rem',
          }}
        >
          <Skeleton.Button
            active
            size="small"
            style={{ width: '100%', height: '20px' }}
          />
          <Skeleton.Button
            active
            size="small"
            style={{ width: '100%', height: '7px' }}
          />
        </div>
      </S.Card>
    )
  }
  if (props.isError) {
    return (
      <S.Card {...props}>
        <div
          className="skeleton-details-wrapper"
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <h4 style={{ margin: 'auto', color: 'var(--error-color)' }}>
            Ocorreu um erro...
          </h4>
        </div>
      </S.Card>
    )
  }
  return <S.Card {...props}>{children}</S.Card>
}
