import { Col, Row } from 'antd'
import BaseButton from '../../../../components/button/BaseButton'
import { BaseSteps } from '../../../../components/steps'
import { CadastrarDoacaoSteps } from './utils/CadastrarCicloDoacaoStepItems'
import { useHistory } from 'react-router-dom'

interface FamiliaNaoEncontradaProps {
  cpf: string
  retornarParaBusca: () => void
}

export function FamiliaNaoEncontrada({ cpf, retornarParaBusca }: FamiliaNaoEncontradaProps) {
  const history = useHistory()

  return (
    <>
      <BaseSteps status="error" current={0} items={CadastrarDoacaoSteps} />
      <Row justify="center">
        <h3 style={{ textAlign: 'center', marginBlock: '3rem' }}> Não localizamos uma família com o CPF: {cpf}</h3>
      </Row>

      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} sm={12}>
          <Row justify="center">
            <BaseButton block type="ghost" onClick={() => history.push('/admin/agape/assistencia/familia')}>
              Cadastrar família
            </BaseButton>
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row justify="center">
            <BaseButton block type="ghost" onClick={retornarParaBusca}>
              Buscar por CPF novamente
            </BaseButton>
          </Row>
        </Col>
      </Row>
    </>
  )
}
