import { Col, Row, Form, Input, Checkbox } from 'antd'
import ReactInputMask from 'react-input-mask'
import { useCallback, useState } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import { ActionWrapper, ContainerWapperLanding } from './ActionWrapper'
import { WrapperContainerImageLanding } from './WrapperContainerImageLanding'
// import { ContentWrapper } from '../../../../layout/benfeitor/components/container/styled'
import bannerImage from '../../../../../static/img/RegisterAction/1440x320.png'
import backgroundImage from '../../../../../static/img/RegisterAction/Banner QSM.png'

import { useRegisterLeadUser } from '../../../hooks/users/Mutations/useRegisterLeadUser'
import { validateTelefone } from '../../../../../utility/utility'
import { StyledForm } from '../../../../benfeitor/components/Form/styles'
import { StyledButton } from '../../../../benfeitor/components/Button'
import { StyledInput } from '../../../../benfeitor/components/Inputs'
import { StyledTextArea } from '../../../../benfeitor/components/TextArea'
import { HeaderLandingPage } from '../../../../benfeitor/layouts/landing-page-layout/components/HeaderLandingPage'
import { BackgroundContainer } from '../../../../benfeitor/pages/LandingPage/embed/style'
import { PhotoUpload } from '../../../../benfeitor/components/UploadPhoto'
import { Footer } from '../../../../benfeitor/layouts/benfeitor-layout/components/Footer'
import { BannerLandingStyled } from '../../../../benfeitor/pages/LandingPage/components/BannerLandingPage/styles'

function ActionEmbedForm({ origem }) {
  const [form] = Form.useForm()

  const [isFinished, setIsFinished] = useState(false)

  const [imageUrl, setImageUrl] = useState([])

  const { mutateAsync: registerLead, isPending } = useRegisterLeadUser()

  const handleFormSubmit = async (values) => {
    try {
      const user_intencao = form.getFieldsValue(['intencao'])
      const formData = new FormData()
      formData.append('nome', values.nome)
      formData.append('email', values.email)

      formData.append('telefone', values.telefone)
      formData.append('intencao', user_intencao?.intencao)
      formData.append('origem', origem)
      formData.append('foto', imageUrl[0]?.file)

      await registerLead(formData)
      setIsFinished(true)
    } catch (error) {
      console.error(error)
      setIsFinished(false)
    }
  }

  return (
    <>
      {isFinished ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            padding: '2rem',
            minHeight: '35vh',
          }}
        >
          <h1 style={{ color: 'rgba(0,0,0, 0.5)' }}>Obrigado por registrar seus dados.</h1>
        </div>
      ) : (
        <StyledForm form={form} onFinish={handleFormSubmit} layout="vertical" style={{ width: '100%' }}>
          <Form.Item name="nome" label="Nome" rules={[{ required: true, message: 'Obrigatório' }]}>
            <StyledInput placeholder="Digite seu nome completo" />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { type: 'email', message: 'Deve ser um e-mail válido', whitespace: true },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="E-mail" />
          </Form.Item>

          <Form.Item
            name="new_email"
            label="Confirmar E-mail"
            dependencies={['email']}
            rules={[
              { type: 'email', message: '' },
              { required: true, message: 'Obrigatório' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
                },
              }),
            ]}
          >
            <StyledInput placeholder="Confirmar" />
          </Form.Item>

          <Form.Item
            name="telefone"
            label="Telefone"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
              {
                validator: validateTelefone,
              },
            ]}
          >
            <ReactInputMask mask="(99) 99999-9999" maskChar="">
              {(inputProps) => <StyledInput {...inputProps} placeholder="Digite seu telefone" />}
            </ReactInputMask>
          </Form.Item>

          <Form.Item
            label="Envie a foto da sua família"
            name="image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                required: true,
                message: 'Por favor, envie uma imagem!',
                validator: () => {
                  if (imageUrl.length === 0) {
                    return Promise.reject(new Error('Por favor, envie uma imagem!'))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <PhotoUpload
              initialFileList={imageUrl}
              validateField={() => form.validateFields(['image'])}
              setImage={(image) => setImageUrl(image)}
              numeroFotos={1}
            />
          </Form.Item>

          <Form.Item label="Coloque sua intenção" name="intencao">
            <StyledTextArea rows={5} maxLength={250} />
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            required
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('É necessário autorizar para realizar o pré-cadastro.')),
              },
            ]}
          >
            <Checkbox>
              Autorizo que essa fotografia seja utilizada nas transmissões do YouTube do canal do Instituto Hesed.
            </Checkbox>
          </Form.Item>

          <StyledButton htmlType="submit" loading={isPending}>
            Enviar
          </StyledButton>
        </StyledForm>
      )}
    </>
  )
}

export default function RegisterAction() {
  return (
    <>
      <HeaderLandingPage />
      <BannerLandingStyled banner={backgroundImage}>
        <Row justify="center" className="title-wrapper">
          <Col xs={20} sm={20} md={24} lg={24} xl={24}>
            <span className="title-landing">Nós vamos interceder juntos pela sua família.</span>
          </Col>

          <Col xs={20} sm={20} md={16} lg={16} xl={16} className="description-landing">
            <div>
              A Quaresma de São Miguel está chegando e como família de oração, queremos a imagem da sua família diante
              do Altar do Senhor, para que nós e milhares de pessoas possamos interceder por ela.
            </div>
          </Col>
        </Row>
      </BannerLandingStyled>

      <BackgroundContainer background={bannerImage}>
        <WrapperContainerImageLanding>
          <ContainerWapperLanding>
            <div>
              <ActionWrapper>
                <ActionEmbedForm origem={3} />
              </ActionWrapper>
            </div>
          </ContainerWapperLanding>
        </WrapperContainerImageLanding>
      </BackgroundContainer>
      <Footer />
    </>
  )
}
