/* eslint-disable react/destructuring-assignment */
import { Wrapper } from './style'
import { LandingPage } from '..'
import { LoginModal } from '../../../layouts/benfeitor-layout/components/Header/Modals/LoginModal'

const LandingPageEmbedForm = (url) => {
  const landingPageId = url.match.params.id
  const queryParams = new URLSearchParams(url.location.search)

  const bgColor = queryParams.get('bg-color')
  const labelColor = queryParams.get('label-color')

  document.body.style.backgroundColor = `${bgColor || 'transparent'}`

  const iframeStyles = {
    labelColor: labelColor || 'white',
  }

  return (
    <>
      <Wrapper iframeStyles={iframeStyles}>
        <LoginModal />
        {<LandingPage embed={true} />}
      </Wrapper>
    </>
  )
}

export default LandingPageEmbedForm
