import React from 'react'
import { Chart } from './Chart'
import BaseCard from '../../../../../components/card/BaseCard'
import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../../lib/dataService'

// Data from API:

const apiData = {
  Brasil: {
    AC: 34,
    AL: 785,
    AM: 344,
    AP: 101,
    BA: 2163,
    CE: 4910,
    DF: 1243,
    ES: 875,
    GO: 1389,
    MA: 683,
    MG: 6140,
    MS: 355,
    MT: 569,
    PA: 1093,
    PB: 1004,
    PE: 1560,
    PI: 867,
    PR: 2328,
    RJ: 2496,
    RN: 1118,
    RO: 145,
    RR: 27,
    RS: 538,
    SC: 781,
    SE: 619,
    SP: 9184,
    TO: 267,
  },
  'Outros Países': {
    Alemanha: 1,
    'Estados Unidos': 3,
    Japão: 1,
    Portugal: 5,
    'Reino Unido': 1,
    Suécia: 2,
    Itália: 1,
    França: 1,
  },
}

const brazilStateMapping = {
  AC: 'br-ac',
  AL: 'br-al',
  AM: 'br-am',
  AP: 'br-ap',
  BA: 'br-ba',
  CE: 'br-ce',
  DF: 'br-df',
  ES: 'br-es',
  GO: 'br-go',
  MA: 'br-ma',
  MG: 'br-mg',
  MS: 'br-ms',
  MT: 'br-mt',
  PA: 'br-pa',
  PB: 'br-pb',
  PE: 'br-pe',
  PI: 'br-pi',
  PR: 'br-pr',
  RJ: 'br-rj',
  RN: 'br-rn',
  RO: 'br-ro',
  RR: 'br-rr',
  RS: 'br-rs',
  SC: 'br-sc',
  SE: 'br-se',
  SP: 'br-sp',
  TO: 'br-to',
}

const countryMapping = {
  Alemanha: 'de',
  'Estados Unidos': 'us',
  Japão: 'jp',
  Portugal: 'pt',
  'Reino Unido': 'gb',
  Suécia: 'se',
  Itália: 'it',
  França: 'fr',
}

const mockBrazilStateData = Object.entries(apiData.Brasil).map(([state, value]) => ({
  'hc-key': brazilStateMapping[state],
  value: value,
}))

const mockGlobalData = Object.entries(apiData['Outros Países']).map(([country, value]) => ({
  'hc-key': countryMapping[country],
  value: value,
}))

export const WorldSignupsChart = () => {
  return (
    <BaseCard BaseCard padding="16px 0px">
      <Chart globalData={mockGlobalData} brazilStateData={mockBrazilStateData} />
    </BaseCard>
  )
}
