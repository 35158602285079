import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetDonationsPerMonth(filters) {
  return useQuery({
    queryKey: ['DONATIONS_PER_MONTH_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const response = await DataService.get(
        `/admin/dash-board/donations/donations-by-months?${filters?.queryString || ''}`,
      )
      return response.data
    },
  })
}
