import { useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import {
  PieChartContainer,
  PieChartLegendColor,
  PieChartLegendContainer,
  PieChartLegendItem,
  PieChartTitle,
} from '../../../styles'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { useBlur } from '@/context/Blur/BlurContext'

export function Chart({ chartData, pieChartData }) {
  const { isBlurred } = useBlur()

  const [nodeId, setNodeId] = useState()
  const [nodeColor, setNodeColor] = useState()

  const renderCustomLegend = () => {
    const formattedData = pieChartData
    const colors = ['var(--gray)', '#6064f1', '#e58401']

    return (
      <PieChartLegendContainer>
        {formattedData.map((datum, index) => (
          <PieChartLegendItem
            key={datum.id}
            color={colors[index]}
            onMouseEnter={() => {
              setNodeId(datum.id)
              setNodeColor(colors[index])
            }}
            onMouseLeave={() => {
              setNodeId(undefined)
              setNodeColor(undefined)
            }}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <PieChartLegendColor color={colors[index]} />
            <span style={{ marginLeft: '5px' }}>{datum.id}</span>
          </PieChartLegendItem>
        ))}
      </PieChartLegendContainer>
    )
  }

  return (
    <div>
      <div>
        <PieChartTitle>Por Forma de Pagamento</PieChartTitle>
      </div>
      <PieChartContainer>
        <ResponsivePie
          margin={{ top: 30, right: 0, bottom: 30, left: 0 }}
          startAngle={-90}
          endAngle={270}
          innerRadius={0.9}
          padAngle={1}
          cornerRadius={2}
          fit={false}
          activeOuterRadiusOffset={12}
          colors={['var(--gray)', '#6064f1', '#e58401']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', '0.2']] }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsStraightLength={25}
          arcLinkLabelsColor="black"
          enableArcLabels={false}
          arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
          legends={[]}
          tooltip={({ datum }) => (
            <div
              style={{
                color: datum.color === 'black',
                background: datum.color,
                border: '1px solid #000000',
                padding: '10px 20px',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.64)',
                opacity: '0.9',
              }}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <strong>{datum.label}:</strong>
                <BlurDiv>
                  <span>{formatToBrazilianCurrency(datum.value)}</span>
                </BlurDiv>
              </div>
            </div>
          )}
          onMouseEnter={(node) => {
            setNodeColor(node.color)
            setNodeId(node.id)
          }}
          layers={[
            'arcs',
            'arcLabels',
            'arcLinkLabels',
            'legends',
            ({ centerX, centerY, radius }) => {
              const outerRadius = radius

              return (
                <text
                  x={centerX}
                  y={centerY}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{ fill: nodeColor || '#e58401' }}
                >
                  <tspan
                    x={centerX}
                    dy={-outerRadius * 0.1}
                    style={{ fontSize: `${outerRadius * 0.3}px`, fontWeight: 'bold' }}
                    className={isBlurred ? 'blur' : 'no-blur'}
                  >
                    {!nodeId && `${chartData?.total?.pix_percentage}%`}
                    {nodeId === 'Pix' && `${chartData?.total?.pix_percentage}%`}
                    {nodeId === 'Boleto' && `${chartData?.total?.boleto_percentage}%`}
                    {nodeId === 'Cartão' && `${chartData?.total?.credito_percentage}%`}
                  </tspan>
                  <tspan
                    x={centerX}
                    dy={outerRadius * 0.3}
                    style={{ fontSize: `${outerRadius * 0.12}px`, fontWeight: 'bold' }}
                    className={isBlurred ? 'blur' : 'no-blur'}
                  >
                    {!nodeId && formatToBrazilianCurrency(chartData?.data[0].data.find((item) => item.x === 'Pix').y)}
                    {nodeId === 'Pix' &&
                      `${formatToBrazilianCurrency(chartData?.data[0].data.find((item) => item.x === 'Pix').y)}`}
                    {nodeId === 'Boleto' &&
                      `${formatToBrazilianCurrency(chartData?.data[0].data.find((item) => item.x === 'Boleto').y)}`}
                    {nodeId === 'Cartão' &&
                      `${formatToBrazilianCurrency(chartData?.data[0].data.find((item) => item.x === 'Cartão').y)}`}
                  </tspan>
                </text>
              )
            },
          ]}
          data={pieChartData}
          style={{ width: '100%', height: '100%' }}
        />
      </PieChartContainer>
      {renderCustomLegend()}
    </div>
  )
}
