import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export interface EnderecoBrasil {
  bairro?: string | null
  cep?: string | null
  cidade?: string | null
  complemento?: string | null
  estado?: string | null
  numero?: string | null
  rua?: string | null
  ponto_referencia?: string | null
}

export interface EnderecoEstrangeiro {
  detalhe_estrangeiro: string
}

export interface Usuario {
  campanha_origem?: number
  data_nascimento?: string
  email: string
  nome: string
  nome_social?: string
  numero_documento: string
  password: string
  sexo?: string
  telefone: string
  tipo_documento?: string
}

export interface RegisterCompleteUser {
  endereco: EnderecoBrasil | EnderecoEstrangeiro
  usuario: Usuario
  pagina_redirecionamento?: 'principal' | 'membro-exercito'
  pais?: string
  image?: File
}

export function useRegisterCompleteUser() {
  return useMutation<any, Error, RegisterCompleteUser>({
    mutationFn: ({ usuario, endereco, pais, image, pagina_redirecionamento }) => {
      const formData = new FormData()

      if (image) formData.append('image', image)
      if (usuario) formData.append('usuario', JSON.stringify(usuario))
      if (endereco) formData.append('endereco', JSON.stringify(endereco))
      if (pais) formData.append('pais', pais)
      if (pagina_redirecionamento) formData.append('pagina_redirecionamento', pagina_redirecionamento)

      return DataService.post('/users/cadastrar-usuario-completo', formData)
    },
    onSuccess: () => {
      notification.success({ message: 'Cadastro realizado com sucesso' })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao realizar o cadastro',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })
}
