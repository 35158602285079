import styled, { css } from 'styled-components'
import mobileBgImage from '../../../../images/bg-1-mobile.png'
import desktopBgImage from '../../../../images/bg-1-pc.png'

export const CallToActionText = styled.div`
  color: #ffffff;
  margin-top: 1rem;
  font-size: 22px;
  line-height: 29px;
  font-weight: 400;

  @media (min-width: 600px) {
    line-height: 42.51px;
    font-size: 32px;
  }

  span {
    font-weight: 700;
  }
`
export const NunsImageWrapper = styled.img`
  width: 100%;
  transform: scale(1.5);
  object-fit: cover;
  z-index: 1;
  margin-top: 40px;

  @media (min-width: 600px) {
    z-index: 1;
    transform: scale(1.25);
    width: 100%;
    max-width: 640px;
  }
`

interface ResponsiveImageProps {
  showOn: 'desktop' | 'mobile'
}

export const MemberTextImage = styled.img<ResponsiveImageProps>`
  z-index: 2;
  ${(props) =>
    props.showOn === 'mobile'
      ? css`
          width: 95%;
          transform: scale(1.1);
          margin-bottom: 16px;
        `
      : css`
          max-width: 450px;
        `}
`
export const SectionWrapper = styled.div`
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media (min-width: 600px) {
    max-width: 13000px;
    display: flex;
    flex-direction: row-reverse;
  }
`
export const DescriptionWrapper = styled.div`
  max-width: 450px;
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
  }
`
export const AdditionalDescription = styled.div`
  color: #ffffff;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.25px;

  @media (min-width: 600px) {
    font-size: 20px;
    line-height: 26.57px;
  }
`
export const BackgroundImageWrapper = styled.div`
  background-image: url(${mobileBgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 770px;
  display: block;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent, black 0%, black 95%, transparent);

  @media (min-width: 600px) {
    background-image: url(${desktopBgImage});
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    /* justify-content: center; */
  }
`

export const MainSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 600px) {
    max-width: 450px;
    margin: 0 auto;
  }
`

export const MobileLogo = styled.img`
  margin-top: 2rem;
  width: 60%;
  object-fit: cover;

  @media (min-width: 600px) {
    /* display: none; */
    margin-bottom: 3.5rem;
  }
`
