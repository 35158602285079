import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface IniciarCicloDoacaoProps {
  idCicloDoacao: number
}

export function useIniciarCicloDoacao() {
  const queryClient = useQueryClient()

  const iniciarCicloDoacao = useMutation({
    mutationFn: async ({ idCicloDoacao }: IniciarCicloDoacaoProps) => {
      await DataService.put(`/agape/iniciar-ciclo-acao-agape/${idCicloDoacao}`)
    },
    onSuccess: () => {
      notification.success({ message: 'Ciclo de doação iniciado com sucesso!' })

      queryClient.invalidateQueries({ queryKey: ['CICLOS_DOACOES'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao iniciar o ciclo de doação',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return iniciarCicloDoacao
}
