import { useEffect } from 'react'
import { Form, FormInstance, Row, Modal } from 'antd'
import moment from 'moment'
import { TrashSimple } from '@phosphor-icons/react'
import BaseButton from '../../../../../../components/button/BaseButton'
import { BaseSpin } from '../../../../../../components/spin/BaseSpin.Styles'
import { ErrorResult } from '../../../../../../components/result/resultStatus/ErrorResult'

import { useGetMembroFamilia } from '../../../../../../hooks/agape/queries/useGetMembroFamilia'
import { useEditarMembro } from '../../../../../../hooks/agape/mutations/useEditarMembro'
import { useExcluirMembro } from '../../../../../../hooks/agape/mutations/useExcluirMembro'

import { FormularioCadastroFamiliaData } from '../../../CadastrarFamilia/FormularioCadastrarFamilia'
import { FormularioMembro } from '../../../components/FormularioMembro'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useGetPermissions } from '../../../../../../hooks/users/Queries/useGetSelfPermissions'
import { formatNumber, formatTelefone } from '../../../../../../../../utility/utility'
import { FormularioResponsavel } from '../../../components/FormularioResponsavel'

type EditarMembroProps = {
  idMembro: number
  form: FormInstance
  handleIsPending: (isPending: boolean) => void
  onClose: () => void
}

const { confirm } = Modal

export function EditarMembro({ idMembro, form, handleIsPending, onClose }: EditarMembroProps) {
  const { data: permissions } = useGetPermissions()

  const podeEditarMembro = permissions?.permissoes.familia_agape.editar === 1
  const podeApagarMembro = permissions?.permissoes.familia_agape.deletar === 1

  const { data: membro, isLoading: isLoadingMembro, isError: isErrorMembro } = useGetMembroFamilia({ idMembro })
  const { mutateAsync: editarMembro, isPending: isPendingEditarMembro } = useEditarMembro()
  const { mutateAsync: excluirMembro, isPending: isPendingExcluirMembro } = useExcluirMembro()

  useEffect(() => {
    handleIsPending(isPendingEditarMembro || isPendingExcluirMembro)
  }, [isPendingEditarMembro, isPendingExcluirMembro])

  useEffect(() => {
    if (membro) {
      form.setFieldsValue({
        [idMembro]: {
          ...membro,
          data_nascimento: membro.data_nascimento
            ? moment(membro.data_nascimento, 'DD/MM/YYYY').format('DD/MM/YYYY')
            : null,
          telefone: formatTelefone(membro.telefone),
        },
      })
    }
  }, [membro, form, idMembro])

  const editarMembroFormSubmit = async (data: FormularioCadastroFamiliaData['membros']) => {
    try {
      const dadosEdicaoMembro = data[idMembro]

      const dadosEdicaoMembroFormatado = {
        ...dadosEdicaoMembro,
        data_nascimento: moment(dadosEdicaoMembro.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }

      await editarMembro({ idMembro, membro: { responsavel: membro?.responsavel!, ...dadosEdicaoMembroFormatado } })
      onClose()
    } catch {}
  }

  const handleExcluirMembro = async () => {
    try {
      await excluirMembro({ idMembro })
      onClose()
    } catch {}
  }

  const confirmarExcluirMembro = () => {
    confirm({
      title: `Tem certeza que deseja excluir o  ${membro?.responsavel ? 'responsável' : 'membro'} ${membro?.nome}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação não pode ser desfeita.',
      okType: 'danger',
      onOk() {
        handleExcluirMembro()
      },
    })
  }

  if (isLoadingMembro) {
    return (
      <Row justify="center">
        <BaseSpin />
      </Row>
    )
  }

  if (isErrorMembro) {
    return <ErrorResult />
  }

  return (
    <Form layout="vertical" form={form} disabled={!podeEditarMembro} onFinish={editarMembroFormSubmit}>
      {membro?.responsavel ? <FormularioResponsavel fieldName={idMembro} /> : <FormularioMembro fieldName={idMembro} />}

      <Row justify="center">
        {podeApagarMembro && (
          <BaseButton severity="error" loading={isPendingExcluirMembro} onClick={confirmarExcluirMembro}>
            Apagar {membro?.responsavel ? 'responsável' : 'membro'} <TrashSimple size={25} />
          </BaseButton>
        )}
      </Row>
    </Form>
  )
}
