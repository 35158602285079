import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetMembroFamiliaResponse {
  responsavel: boolean
  cadastrado_em: string
  cpf: string
  data_nascimento: string
  email: string
  escolaridade: string
  funcao_familiar: string
  nome: string
  ocupacao: string
  renda: string
  telefone: string
}

export function useGetMembroFamilia({ idMembro }: { idMembro: number }): UseQueryResult<GetMembroFamiliaResponse> {
  const membro = useQuery({
    queryKey: ['GET_MEMBRO', idMembro],
    queryFn: async () => {
      const response = await DataService.get(`agape/buscar-membro/${idMembro}`)
      return response.data
    },
  })

  return membro
}
