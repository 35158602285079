import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface CadastrarDoacaoProps {
  doacoes: {
    fk_item_instancia_agape_id: number
    quantidade: number
  }[]
  fk_familia_agape_id: number
  fk_instancia_acao_agape_id: number
}

interface CadastrarDoacaoResponse {
  fk_doacao_agape_id: number
  msg: string
}

export function useCadastrarDoacao() {
  const queryClient = useQueryClient()

  const cadastrarDoacao = useMutation({
    mutationFn: async ({ doacoes, fk_familia_agape_id, fk_instancia_acao_agape_id }: CadastrarDoacaoProps) => {
      const response = await DataService.post<CadastrarDoacaoResponse>('/agape/registrar-doacao', {
        doacoes,
        fk_familia_agape_id,
        fk_instancia_acao_agape_id,
      })

      return response.data
    },
    onSuccess: (_, { fk_instancia_acao_agape_id }) => {
      notification.success({ message: 'Doação cadastrada com sucesso' })

      queryClient.invalidateQueries({
        queryKey: ['ITENS_CICLO_ACAO_DOACAO', fk_instancia_acao_agape_id],
      })

      queryClient.invalidateQueries({
        queryKey: ['BENEFICIADOS', fk_instancia_acao_agape_id],
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar a doação',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return cadastrarDoacao
}
