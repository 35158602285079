import React, { lazy, Suspense } from 'react'
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom'
import { StyledSpin } from '../container/benfeitor/components/Spin/styles'
import { Row } from 'antd'
import { RegisterPage } from '../container/benfeitor/pages/Profile/Authentication/Register'
import { LoginPage } from '../container/benfeitor/pages/Profile/Authentication/LoginPage/LoginPage'
import ForgotPassword from '../container/benfeitor/pages/Profile/Authentication/ForgotPassword'
import { ResetPassword } from '../container/alistamento-sm/pages/reset-password'

function NotFound() {
  return <Redirect to="/" />
}

export function AuthRoutes() {
  const { path } = useRouteMatch()

  return (
    <Suspense
      fallback={
        <Row justify="center">
          <StyledSpin />
        </Row>
      }
    >
      <Switch>
        <Route exact path={`${path}/forgotPassword`} component={ForgotPassword} />
        <Route exact path={`${path}/register`} component={RegisterPage} />
        <Route exact path={`${path}`} component={LoginPage} />
        <Route exact path={`${path}/reset-password/:token`} component={ResetPassword} />
      </Switch>
    </Suspense>
  )
}
