import { Carousel } from 'antd'
import styled from 'styled-components'

const CarouselWrapper = styled(Carousel)`
  .slick-slide > div {
    height: 100%;
  }

  .slick-track > div:not(:last-child) {
    margin-right: 20px;
    height: 100%;
  }

  .slick-track {
    padding-block: 40px !important;
    margin-top: -40px;
    margin-bottom: -40px;
    display: flex !important;
  }

  .slick-slide {
    .user-table-avatar-wrapper {
      display: flex !important;
      justify-content: center !important;
    }

    height: inherit !important;
  }
`

const ArrowsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const Arrow = styled.button`
  background-color: transparent;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 25px;
  color: ${(props) =>
    props.disabled ? 'var(--text-secondary-color)' : 'var(--text-main-color)'};
`

export { CarouselWrapper, ArrowsContainer, Arrow }
