import { useQuery } from '@tanstack/react-query'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'
import { DataService } from '../../../../../../../lib/dataService'
import { formatNumber } from '../../../../../../../utility/utility'
import BaseCard from '../../../../../components/card/BaseCard'
import { ChartTitle, ErrorMessage, LoadingContainer, TooltipContainer } from '../../../styles'

export function Evolution() {
  const { data, isPending, isError } = useQuery({
    queryKey: ['progressCountUsers'],
    queryFn: async () => {
      const response = await DataService.get(`admin/dash-board/users/progress-count-users`)
      return response?.data?.cadastros_por_mes
    },
  })

  if (isPending) {
    return (
      <BaseCard>
        <LoadingContainer />
      </BaseCard>
    )
  }

  if (isError) {
    return (
      <BaseCard>
        <ErrorMessage />
      </BaseCard>
    )
  }

  const formattedData =
    data?.map((item) => ({
      date: `${item.year}-${item.month < 10 ? `0${item.month}` : item.month}`,
      users: item.total_users,
    })) || []

  const CustomTooltip = ({ payload }) => {
    if (!payload || payload.length === 0) return null

    const { users } = payload[0].payload
    const { date } = payload[0].payload

    const formattedDate = date?.split('-').reverse().join('/')

    return (
      <TooltipContainer type="signup">
        <strong>{formattedDate}</strong>
        <div>{formatNumber(users)} Cadastros</div>
      </TooltipContainer>
    )
  }

  return (
    <BaseCard padding="0px" >
      <ChartTitle style={{ fontWeight: 'bold', padding: '2rem', position: 'absolute' }}>Total de Cadastros - 59.183</ChartTitle>
      <div style={{ position: 'relative', height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={formattedData} margin={{ top: 40, right: 0, bottom: 20, left: 0 }}>
            {/* The following def id works for all charts */}
            <defs>
              <linearGradient id="signupChartGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="var(--signup-chart-color)" stopOpacity={0.8} />
                <stop offset="95%" stopColor="var(--signup-chart-color)" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="users"
              stroke="var(--signup-chart-color-stroke)"
              fill="url(#signupChartGradient)"
              strokeWidth={2}
              activeDot={(props) => {
                const { cx, cy } = props
                return (
                  <circle
                    cx={cx}
                    cy={cy}
                    r={8}
                    stroke="var(--signup-chart-color)"
                    strokeWidth={2}
                    fill="white"
                    fillOpacity={1}
                  />
                )
              }}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </BaseCard>
  )
}
