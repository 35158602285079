import { useEffect, useState } from 'react'
import { useGetFamiliaPorCpf } from '../../../../hooks/agape/queries/useGetFamiliaPorCpf'
import { AxiosError } from 'axios'

import { VerificarOsDados } from './VerificarOsDados/VerificarOsDados'
import { EnviarRecibos } from './EnviarRecibos'
import { SelecionarItens } from './SelecionarItens'
import { SucessoDoacao } from './Sucesso'
import BaseCard from '../../../../components/card/BaseCard'
import { Link, useParams } from 'react-router-dom'
import { Row } from 'antd'
import BaseButton from '../../../../components/button/BaseButton'
import { ArrowLeft } from '@phosphor-icons/react'
import { BuscarPorCpf } from './BuscarPorCpf'
import { FamiliaNaoEncontrada } from './FamiliaNaoEncontrada'
import { useQueryClient } from '@tanstack/react-query'

interface CadastrarDoacaoRouteParams {
  idCicloDoacao: string
  nomeAcao: string
}

export enum CadastrarDoacaoItensEnum {
  BUSCA = 'BUSCA',
  FAMILIA_NAO_ENCONTRADA = 'FAMILIA_NAO_ENCONTRADA',
  VERIFICAR_DADOS = 'VERIFICAR_DADOS',
  SELECIONAR_ITENS = 'SELECIONAR_ITENS',
  SUCESSO = 'SUCESSO',
  ENVIAR_RECIBO = 'ENVIAR_RECIBO',
}

export function RegistrarDoacao() {
  const queryClient = useQueryClient()

  const { idCicloDoacao, nomeAcao } = useParams<CadastrarDoacaoRouteParams>()

  const [cpf, setCpf] = useState<string>('')
  const [idDoacao, setIdDoacao] = useState<number | null>(null)

  const [isRefetching, setIsRefetching] = useState(false)

  const {
    data: familia,
    refetch: executarBuscaFamiliaPorCpf,
    isLoading: isLoadingBuscarFamiliarPorCpf,
    isError: isErrorBuscarFamiliarPorCpf,
    error: errorBuscarFamiliaPorCpf,
  } = useGetFamiliaPorCpf({ cpf, idCicloDoacao: Number(idCicloDoacao) })

  const [etapaAtual, setEtapaAtual] = useState<CadastrarDoacaoItensEnum>(CadastrarDoacaoItensEnum.BUSCA)

  useEffect(() => {
    if (isRefetching) return

    const familiaNaoEncontrada =
      isErrorBuscarFamiliarPorCpf && (errorBuscarFamiliaPorCpf as AxiosError)?.response?.status === 404

    if (familiaNaoEncontrada && cpf === '') {
      setEtapaAtual(CadastrarDoacaoItensEnum.BUSCA)
      return
    }

    if (familiaNaoEncontrada) {
      setEtapaAtual(CadastrarDoacaoItensEnum.FAMILIA_NAO_ENCONTRADA)
      return
    }

    if (familia && !familiaNaoEncontrada) {
      setEtapaAtual(CadastrarDoacaoItensEnum.VERIFICAR_DADOS)
    }
  }, [familia, isErrorBuscarFamiliarPorCpf, errorBuscarFamiliaPorCpf, isRefetching])

  async function buscarFamiliaPorCpf() {
    setIsRefetching(true)
    try {
      await executarBuscaFamiliaPorCpf()
    } finally {
      setIsRefetching(false)
    }
  }

  function invalidarCpf() {
    queryClient.removeQueries({ queryKey: ['FAMILIA_CPF'] })
  }

  function retornarParaBusca() {
    setEtapaAtual(CadastrarDoacaoItensEnum.BUSCA)
    setCpf('')
    setIdDoacao(null)
    invalidarCpf()
  }

  function avancarParaRecibo() {
    setEtapaAtual(CadastrarDoacaoItensEnum.ENVIAR_RECIBO)

    invalidarCpf()
    setCpf('')
  }

  const itens = {
    [CadastrarDoacaoItensEnum.BUSCA]: (
      <BuscarPorCpf
        cpf={cpf}
        setCpf={setCpf}
        executarBuscaFamiliaPorCpf={buscarFamiliaPorCpf}
        isLoadingBuscarFamiliarPorCpf={isLoadingBuscarFamiliarPorCpf}
      />
    ),

    [CadastrarDoacaoItensEnum.FAMILIA_NAO_ENCONTRADA]: (
      <FamiliaNaoEncontrada cpf={cpf} retornarParaBusca={retornarParaBusca} />
    ),

    [CadastrarDoacaoItensEnum.VERIFICAR_DADOS]: (
      <VerificarOsDados
        retornarParaBusca={retornarParaBusca}
        dados={familia}
        irParaSelecioarItens={() => setEtapaAtual(CadastrarDoacaoItensEnum.SELECIONAR_ITENS)}
        idCicloDoacao={Number(idCicloDoacao)}
      />
    ),

    [CadastrarDoacaoItensEnum.SELECIONAR_ITENS]: (
      <SelecionarItens
        retornarParaBusca={retornarParaBusca}
        avancarParaRecibo={avancarParaRecibo}
        obterIdDoacao={setIdDoacao}
        idCicloDoacao={Number(idCicloDoacao)}
        idFamilia={familia?.familia.id!}
        nomeFamilia={familia?.familia.nome_familia!}
      />
    ),
    [CadastrarDoacaoItensEnum.ENVIAR_RECIBO]: (
      <EnviarRecibos
        idDoacao={idDoacao!}
        avancarParaSucesso={() => {
          setEtapaAtual(CadastrarDoacaoItensEnum.SUCESSO)

          invalidarCpf()
        }}
      />
    ),

    [CadastrarDoacaoItensEnum.SUCESSO]: <SucessoDoacao retornarParaBusca={retornarParaBusca} />,
  }

  return (
    <BaseCard
      title={
        <Row justify="space-between">
          <div>
            <h4>
              Cadastrar doação <br /> <span style={{ color: 'var(--text-secondary-color)' }}> {nomeAcao}</span>
            </h4>
          </div>

          <div>
            <Link to="/admin/agape/assistencia/doacao" onClick={invalidarCpf}>
              <BaseButton severity="info">
                <ArrowLeft /> Voltar para ciclos de doação
              </BaseButton>
            </Link>
          </div>
        </Row>
      }
      style={{ minHeight: '80vh' }}
    >
      <div style={{ marginTop: '1rem' }}>{itens[etapaAtual]}</div>
    </BaseCard>
  )
}
