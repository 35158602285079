import { Col, Row } from 'antd'
import BaseCard from '../../../../components/card/BaseCard'
import { BaseSpin } from '../../../../components/spin/BaseSpin.Styles'
import BaseButton from '../../../../components/button/BaseButton'
import { useAdicionarVoluntarioAgape } from '../../../../hooks/agape/mutations/useAdicionarVoluntarioAgape'
import { useSearchUser } from '../../../../hooks/users/Queries/useSearchUsers'
import { useState } from 'react'
import { BaseAutoComplete } from '../../../../components/autocomplete/BaseAutoComplete'
import { EmptyResult } from '../../../../components/result/resultStatus/EmptyResult'
import { Option } from '../../../../components/select/BaseSelect'

interface SearchedUserFormData {
  label: string
  value: string
  key: string
}
export function AdicionarVoluntario() {
  const [searchedUser, setSearchedUser] = useState<undefined | SearchedUserFormData>()

  const { mutateAsync: adicionarVoluntario, isPending: isPendingAdicionarVoluntario } = useAdicionarVoluntarioAgape()

  const { data: searchedUserResult, isLoading: isLoadingSearch } = useSearchUser({ user: searchedUser?.value })

  function handleSearchedUserChange(value: SearchedUserFormData) {
    setSearchedUser(value)
  }

  async function handleAdicionarVoluntario() {
    if (searchedUser?.value) {
      await adicionarVoluntario({ fk_usuario_id: searchedUser?.value })

      setSearchedUser(undefined)
    }
  }

  return (
    <BaseCard>
      <Row justify={{ xs: 'center', sm: 'space-between' }} gutter={[30, 20]} style={{ alignItems: 'center' }}>
        <Col xs={24} sm={14}>
          <BaseAutoComplete
            placeholder="Nome, E-mail ou CPF"
            allowClear
            showSearch
            style={{ width: '100%' }}
            labelInValue
            value={searchedUser?.label}
            onChange={handleSearchedUserChange}
            notFoundContent={
              isLoadingSearch ? (
                <Row justify="center">
                  <BaseSpin />
                </Row>
              ) : (
                <EmptyResult />
              )
            }
          >
            {searchedUserResult?.permissoes_usuarios.map((item) => {
              const { fk_usuario_id, nome_usuario } = item
              return (
                <Option key={fk_usuario_id} value={fk_usuario_id} label={nome_usuario}>
                  {nome_usuario}
                </Option>
              )
            })}
          </BaseAutoComplete>
        </Col>

        <Col xs={24} sm={10}>
          <BaseButton block loading={isPendingAdicionarVoluntario} onClick={handleAdicionarVoluntario}>
            Adicionar
          </BaseButton>
        </Col>
      </Row>
    </BaseCard>
  )
}
