import { Form } from 'antd';
import styled from 'styled-components';
import { StyledForm } from '../../../components/Form/styles';

export const ContentContainer = styled.div`
  display: flex;
  gap: 3rem;
  margin-bottom: 2rem;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: 26px;
  font-weight: 700;
  color: #434343;
  margin-bottom: 2rem;

  @media (max-width: 576px) {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  max-width: 700px;
  flex: 1;

  .ant-form-item-required {
    color: ${({ theme }) => theme.colors['gray-450']} !important;
  }

  .ant-form-item-label {
    padding: 0 0 4px !important;
  }

  label.ant-form-item-required {
    font-family: ${({ theme }) => theme.fonts.family.primary};
    color: #626262 !important;
    font-weight: 700;
    font-size: 16px;
  }

  label {
    margin-bottom: 0.7rem !important;
  }

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .number-card-item {
    grid-column: 1 / 2;
  }

  .cvc-item {
    grid-column: 2 / 3;
  }

  .expiry-item {
    grid-column: 3 / 4;
  }

  .first-row {
    grid-column: 1/-1;
    display: flex;
    flex: 1;
    /* gap: 1rem; */
    max-width: 700px;
  }

  .cpf-cnpj-item {
    padding-right: 1rem !important;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;

    .cpf-cnpj-item {
      padding-right: 0rem !important;
    }

    /* padding: 0 1rem; */

    .ant-row.ant-form-item-row {
      width: 100% !important;
    }

    .ant-form-item {
      margin-bottom: 0px;
    }

    .number-card-item {
      grid-column: auto;
    }

    .cvc-item {
      grid-column: auto;
    }

    .expiry-item {
      grid-column: auto;
    }

    .first-row {
      grid-column: auto;
      display: flex;
      flex-direction: column;
      /* gap: 1rem; */
      min-width: auto;
    }
  }
`;

export const FirstRowContainer = styled.div``;
