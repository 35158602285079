import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import {
  EnderecoFormularioCadastroFamilia,
  Membro,
  Responsavel,
} from '../../../pages/agape/Familia/CadastrarFamilia/FormularioCadastrarFamilia'

type MembrosArray = (Responsavel | Membro)[]

export type CadastrarFamiliaBody = {
  endereco: EnderecoFormularioCadastroFamilia
  membros: MembrosArray
  foto?: File
  observacao?: string
}

export function useCadastrarFamilia() {
  const queryClient = useQueryClient()

  const cadastrarFamilia = useMutation({
    mutationFn: async ({ endereco, membros, foto, observacao }: CadastrarFamiliaBody) => {
      const cadastroFormData = new FormData()

      cadastroFormData.append('membros', JSON.stringify(membros))
      cadastroFormData.append('endereco', JSON.stringify(endereco))

      if (observacao) {
        cadastroFormData.append('observacao', JSON.stringify(observacao))
      }

      if (foto) {
        cadastroFormData.append('foto', foto)
      }

      await DataService.post('agape/cadastrar-familia', cadastroFormData)
    },
    onSuccess: () => {
      notification.success({ message: 'Família cadastrada com sucesso!' })
      queryClient.invalidateQueries({ queryKey: ['GET_ALL_FAMILIAS'] })
      queryClient.invalidateQueries({ queryKey: ['ENDERECOS_FAMILIAS'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_FAMILIA'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar família',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return cadastrarFamilia
}
