import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { AbrangenciaCicloDoacao } from '../mutations/useCadastrarCicloDoacao'

interface GetCicloDoacaoAnteriorProps {
  fk_acao_agape_id: string
}

interface GetCicloDoacaoAnteriorResponse {
  abrangencia: AbrangenciaCicloDoacao
  doacoes: [
    {
      fk_estoque_agape_id: number
      item: string
      quantidade: number
    },
  ]
  endereco: {
    bairro: string
    cep: string
    cidade: string
    complemento: string
    estado: string
    numero: string
    rua: string
  }
}

export function useGetCicloDoacaoAnterior({ fk_acao_agape_id }: GetCicloDoacaoAnteriorProps) {
  const cicloDoacaoAnterior = useQuery({
    queryKey: ['CICLO_DOACAO_ANTERIOR', fk_acao_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetCicloDoacaoAnteriorResponse>(
        `/agape/buscar-acao-agape/${fk_acao_agape_id}`,
      )

      return response.data
    },

    enabled: !!fk_acao_agape_id,
  })

  return cicloDoacaoAnterior
}
