// eslint-disable-next-line import/no-absolute-path
import LogoHesed from '../../../../../static/img/logo_hesed.png';
import { Logo, StyledHeader } from '../../benfeitor-layout/components/Header/styles';

export function HeaderLandingPage() {
  return (
    <StyledHeader>
      <Logo>
        <a href="/">
          <img src={LogoHesed} alt="Instituto Hesed" />
        </a>
      </Logo>
    </StyledHeader>
  );
}
