import { Skeleton } from 'antd'
import { Chart } from './Chart'
import { useGetDonationsPerCampaign } from '../../../../../hooks/dashboards/finances/queries/useGetDonationsPerCampaign'

const colors = [
  '#5868c0',
  '#4CAF50',
  '#2196F3',
  '#FFC107',
  '#FF9800',
  '#f05e54',
  '#9C27B0',
  '#795548',
  '#3F51B5',
  '#FF5722',
  '#009688',
  '#CDDC39',
  '#673AB7',
  '#00BCD4',
  '#8BC34A',
  '#E91E63',
  '#607D8B',
]

export function Content({ filters }) {
  const { data: campaignData, isPending, isError } = useGetDonationsPerCampaign(colors, filters)

  const formattedData = campaignData
    ?.sort((a, b) => b.value - a.value)
    ?.map((item, index) => ({
      titulo: item.label,
      valor_arrecadado: item.value,
      qnt_doacoes: item.quantidade_doacoes,
      cor: colors[index % colors.length],
    }))

  if (isPending) {
    return (
      <div style={{ height: '25rem', display: 'grid', placeItems: 'center', padding: '1rem' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (isError) {
    return (
      <h4 style={{ color: 'red', height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )
  }

  return <Chart colors={colors} campaignData={formattedData} />
}
