import styled from "styled-components";

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .ant-switch {
    background-color: #463b3a;
  }

  .ant-switch-checked {
    background-color: #463b3a !important;
  }
`;
