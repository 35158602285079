import { useHistory } from 'react-router-dom'
import { useGetPermissions } from '../../../hooks/users/Queries/useGetSelfPermissions'
import { AnimatedCarousel } from '../financesDashboard/components/AnimatedCarousel'
import { AnimatedChartWrapper } from '../financesDashboard/components/AnimatedChartWrapper'
import { DashboardWrapper, LeftSection, RightSection } from '../styles'
import { CitiesRank } from './components/CitiesRank'
import { Evolution } from './components/Evolution'
import { Funnel } from './components/Funnel'
import { ByGenreAndAge } from './components/PerGenreAndAge'
import { SignupsCountPerHour } from './components/SignupsCountPerHour'
import { SignupsPerCampaign } from './components/SignupsPerCampaign'
import { SignupsPerDayOfWeek } from './components/SignupsPerDayOfWeek'
import { SignupsPerMonth } from './components/SignupsPerMonth'
import { Status } from './components/Status'
import { WorldSignupsChart } from './components/WorldChart'
import { useSignupDashboardSlides } from './hooks/use-signup-dashboard'

export function SignUpDashboard() {
  const history = useHistory()

  const { data: { permissoes } = {}, isLoading } = useGetPermissions()

  if (permissoes) {
    const hasPermission = permissoes?.dash_board_users.acessar === 1
    if (!hasPermission) history.push('/admin')
  }

  const slides = useSignupDashboardSlides()

  return (
    <DashboardWrapper>
      <LeftSection>
        <div>
          <AnimatedChartWrapper index={0}>
            <AnimatedCarousel slides={slides} type='signup' />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={1}>
            <Evolution />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={2}>
            <SignupsPerMonth />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={3}>
            <WorldSignupsChart />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={4}>
            <CitiesRank />
          </AnimatedChartWrapper>
        </div>
      </LeftSection>

      <RightSection>
        <div>
          <AnimatedChartWrapper index={1}>
            <Funnel />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={2}>
            <Status />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={3}>
            <SignupsPerCampaign />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={4}>
            <ByGenreAndAge />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={5}>
            <SignupsCountPerHour />
          </AnimatedChartWrapper>

          <AnimatedChartWrapper index={6}>
            <SignupsPerDayOfWeek />
          </AnimatedChartWrapper>
        </div>
      </RightSection>
    </DashboardWrapper>
  )
}
