import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { Doacao } from './useFetchDonations'

interface DoacaoResponse {
  data: Doacao[]
  page: number
  pages: number
  per_page: number
  total: number
}

export function useFetchDesconsideredDonations({ filters }: { filters: Record<string, any> | undefined }) {
  return useQuery({
    queryKey: ['DESCONSIDERED_DONATIONS', filters],
    queryFn: async () => {
      const response = await DataService.get<DoacaoResponse>(
        `checkout/doacoes-desconsideradas?${filters?.queryString || ''}`,
      )
      return response?.data
    },
  })
}
