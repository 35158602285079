import logosImage from '../../images/logos.svg'
import { Button, Form, Input } from 'antd'
import { useSendEmailResetPassword } from '../../../admin/hooks/users/Mutations/useSendEmailResetPassword'
import { Container } from '../create-group-video/styles'
import { GoldenHeader } from '../components/golden-header/styles'

export function RecoverPassword() {
  const [form] = Form.useForm()
  const { mutateAsync: recoverPassword, isPending } = useSendEmailResetPassword()

  const handleSubmit = async (values: { email: string }) => {
    recoverPassword({ email: values.email, url_redirect: '/membro-exercito/redefinir-senha' })
  }

  return (
    <Container>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={logosImage} alt="Logo" />
        </div>
        <div style={{ maxWidth: '500px', marginInline: 'auto', marginTop: '7rem' }}>
          <GoldenHeader style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Recupere sua senha: </GoldenHeader>
          <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  message: 'Por favor, insira seu e-mail!',
                  required: true,
                },
                {
                  type: 'email',
                  message: 'Deve ser um e-mail válido',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="seuemail@gmail.com" />
            </Form.Item>

            <Button disabled={isPending} loading={isPending} type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enviar
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  )
}
