import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import {
  BackgroundImage,
  ContentWrapper,
  ImageContainer,
  MessageContainer,
  SliderWrapper,
  TextureContainer,
  TitleStyled,
  WrapperCampaigns,
} from './styles'

import { useGetPublicCampaigns } from '../../hooks/api/campaign/useGetPublicCampaigns'
import { authUserFromEmailRedirect } from '../../hooks/AuthUserFromEmailRedirect'
import { CampaignCard } from './components/CampaignCard'
import { ImageStepsDonation } from './components/ImageStepsDonation'
import backgroundImage from './images/background.png'
import backgroundTexture from './images/bg_textura.png'

export const Home = () => {
  const { data: campaignsResponse, isLoading, isError } = useGetPublicCampaigns()
  const sliderSettings = HomeSliderSettings(campaignsResponse?.campanhas?.length || 0)

  authUserFromEmailRedirect()

  return (
    <>
      <BackgroundImage backgroundImage={backgroundImage}>
        <ContentWrapper>
          <>
            <MessageContainer>
              <p>A sua doação se transforma em sorrisos!</p>
              <p>
                "Na verdade, os homens perdem tudo o que deixam nesse mundo, mas levam consigo o preço da sua caridade e
                das esmolas que fizeram, e por elas receberão do Senhor recompensa e digna remuneração".
              </p>
              <p>- São Francisco de Assis</p>
            </MessageContainer>

            <WrapperCampaigns>
              <TitleStyled>Campanhas Atuais</TitleStyled>
              <SliderWrapper>
                <Slider {...sliderSettings}>
                  {isError || isLoading || !campaignsResponse
                    ? Array.from({ length: 2 }).map((_, index) => <CampaignCard key={index} isCampaignsLoading />)
                    : campaignsResponse?.campanhas?.map((c) => {
                      return (
                        <div key={c.id}>
                          <CampaignCard {...c} wrapDesc />
                        </div>
                      )
                    })}
                </Slider>
              </SliderWrapper>
            </WrapperCampaigns>
          </>
        </ContentWrapper>
      </BackgroundImage>

      <TextureContainer texture={backgroundTexture}>
        <ImageContainer>
          <TitleStyled>Como funcionam as doações</TitleStyled>
          <ImageStepsDonation />
        </ImageContainer>
      </TextureContainer>
    </>
  )
}

function HomeSliderSettings(length: number) {
  return {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: length || 0,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    arrows: false,
    responsive: [
      { breakpoint: 768, settings: { infinite: true, centerMode: true, slidesToShow: 1 } },
      { breakpoint: 480, settings: { infinite: true, centerMode: true, slidesToShow: 1 } },
    ],
  };
}
