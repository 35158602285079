import { Row, Form, Col } from 'antd'
import ReactInputMask from 'react-input-mask'
import { useEffect } from 'react'
import { useFetchUserMe } from '../../hooks'
import BaseButton from '../../components/button/BaseButton'
import BaseCard from '../../components/card/BaseCard'
import BaseSpin from '../../components/spin/BaseSpin'
import BaseForm from '../../components/forms/BaseForm/BaseForm'
import { BaseSelect } from '../../components/select/BaseSelect'
import { BaseInput } from '../../components/inputs/BaseInput/BaseInput'
import { countries } from '../../../../utility/allCountries'
import { useUpdateUserDetailsMutation } from '../../hooks/users/Mutations/useUpdateUserData'
import { ErrorResult } from '../../components/result/resultStatus/ErrorResult'

export const ProfileView = () => {
  const userMe = useFetchUserMe()

  const userId = userMe?.data?.id
  const updateUserMutation = useUpdateUserDetailsMutation(userId)

  const [form] = Form.useForm()

  useEffect(() => {
    if (userMe?.data) {
      form.setFieldsValue({
        ...userMe.data,
        name: userMe.data.nome,
        cpf_cnpj: userMe.data.cpf_cnpj,
        phone: userMe.data.telefone,
      })
    }
  }, [form, userMe])

  if (userMe.isError) {
    return (
      <BaseCard style={{ height: '100%' }} title="Dados cadastrais">
        <ErrorResult />
      </BaseCard>
    )
  }

  if (userMe.isLoading || updateUserMutation.isPending) {
    ;<BaseCard style={{ height: '100%' }} title="Dados cadastrais">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <BaseSpin />
      </div>
    </BaseCard>
  }

  return (
    <BaseForm
      style={{ width: '100%' }}
      layout="vertical"
      form={form}
      name="addnew"
      onFinish={(values) => {
        const updatedValues = {
          ...values,
          company_id: 1,
          profile_id: userMe?.data?.fk_perfil_id,
          avatar: userMe?.data?.avatar,
        }
        updateUserMutation.mutate(updatedValues)
      }}
    >
      <BaseCard style={{ minHeight: '80vh' }} title="Dados cadastrais">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BaseForm.Item name="pais" label="País">
              <BaseSelect placeholder="Selecione um País" options={countries} />
            </BaseForm.Item>
          </Col>

          <Col span={24}>
            <BaseForm.Item name="name" label="Nome" rules={[{ min: 10, message: 'Mínimo 10 caracteres' }]}>
              <BaseInput placeholder="Nome do usuário" />
            </BaseForm.Item>
          </Col>

          <Col span={24}>
            <BaseForm.Item name="numero_documento" label="CPF" disabled>
              <ReactInputMask mask="999.999.999-99" name="numero_documento" maskChar={null} placeholder="CPF" disabled>
                {(inputProps) => <BaseInput {...inputProps} />}
              </ReactInputMask>
            </BaseForm.Item>
          </Col>

          <Col span={24}>
            <BaseForm.Item name="phone" label="Telefone" disabled>
              <ReactInputMask mask="(99)99999-9999" name="telefone" maskChar={null} placeholder="Telefone">
                {(inputProps) => <BaseInput {...inputProps} />}
              </ReactInputMask>
            </BaseForm.Item>
          </Col>

          <Col span={24}>
            <BaseForm.Item
              name="email"
              label="E-mail"
              rules={[{ type: 'email', message: 'Deve ser um e-mail válido' }]}
            >
              <BaseInput placeholder="E-mail" />
            </BaseForm.Item>
          </Col>
        </Row>
        <Row justify="center">
          <BaseButton htmlType="submit" loading={updateUserMutation.isPending}>
            Enviar
          </BaseButton>
        </Row>
      </BaseCard>
    </BaseForm>
  )
}
