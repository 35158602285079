import { Upload } from 'antd'
import styled, { css } from 'styled-components'
import BaseUplaodDescription from './BaseUploadDescription'

export { Upload } from 'antd'

export const BaseUpload = styled(Upload)`
  /* .ant-upload-list-item {
    padding: 1rem;
    width: 15rem;
  } */

  .ant-upload.ant-upload-select {
    display: block;
  }

  .ant-upload-list-item.ant-upload-list-item-list-type-picture-card {
    border: 3px solid var(--text-main-color);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .ant-upload-select-picture-card {
    background-color: var(--background-color);
    border: 2px solid var(--text-main-color);
    width: 8rem;
    height: 8rem;
    border-radius: 50%;

    :hover {
      border-color: var(--input-border-color);
    }

    .ant-upload {
      color: var(--text-main-color);
    }
  }

  .ant-upload-list-item-name {
    max-width: 100px; /* Largura máxima do texto */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-main-color);
  }

  .ant-upload-list.ant-upload-list-picture {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${({ listInRow }) =>
      listInRow &&
      css`
        flex-direction: row;
        gap: 0.5rem;
        flex-wrap: wrap;
      `}

    color: var(--text-main-color);

    .ant-upload-list-item-info {
      .ant-upload-span {
        justify-content: space-between;
        .ant-upload-list-item-card-actions {
          svg {
            color: var(--text-main-color);
          }
        }
      }
    }
  }
`

BaseUpload.Description = BaseUplaodDescription
