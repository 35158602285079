import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'

export interface EsmUserData {
  nome: string
  email: string
  telefone: string
  usuario_id: number
  pais: string
}

interface AlistamentoSmContextType {
  userData: EsmUserData
  setUserData: Dispatch<SetStateAction<EsmUserData>>
}

// Context provider component
export const AlistamentoSmProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = useState<EsmUserData>({})

  const contextValue = {
    userData,
    setUserData,
  }

  return <AlistamentoSmContext.Provider value={contextValue}>{children}</AlistamentoSmContext.Provider>
}

// Create the context
const AlistamentoSmContext = createContext<AlistamentoSmContextType | undefined>(undefined)

// Custom hook to use the context
export const useAlistamentoSmContext = () => {
  const context = useContext(AlistamentoSmContext)
  if (!context) {
    throw new Error('useAlistamentoSmContext must be used within an AlistamentoSmProvider')
  }
  return context
}
