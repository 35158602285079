import { createGlobalStyle } from 'styled-components'
import { resetCss } from './resetCss'
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media, BORDER_RADIUS } from './constants'
import {
  // antOverrideCssVariables,
  commonThemeVariables,
  darkThemeVariables,
  lightThemeVariables,
} from './themeVariables'

export default createGlobalStyle`
  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
    h1, h2, h3, h4, h5, h6 {
      color: var(--text-main-color);  
      font-weight: ${FONT_WEIGHT.extraBold} !important;  
    }
   
    p {
      color: var(--text-main-color); 
      margin: 0;
    }

  }

  [data-theme='dark'] {
    ${darkThemeVariables}
    h1, h2, h3, h4, h5, h6 {
      color: var(--text-main-color);  
      font-weight: ${FONT_WEIGHT.extraBold} !important;  
    }

    p {
      color: var(--text-main-color);  
      margin: 0;
    }

    
  }

  .blur {
  -webkit-filter: blur(4px);
  filter: blur(4px);
  opacity: 0.5;
  transition: filter 0.5s ease, opacity 0.5s ease, -webkit-filter 0.5s ease;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.no-blur {
  -webkit-filter: blur(0px);
  filter: blur(0px);
  opacity: 1;
  transition: filter 0.5s ease, opacity 0.5s ease, -webkit-filter 0.5s ease;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}




  .fallback-spin{
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
  }


  #mobile-popup-container {

.ant-menu-submenu-popup > .ant-menu {
  position: absolute !important;
  bottom: 0 !important;
  right: -20px !important;
  z-index: -1 !important;
  
  
  background-color: var(--sidebar-background-color) !important;
  border-radius: 30px 30px 0px 0px !important;
  padding: 8px 12px !important;
  width: 12rem !important;
}
} 

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {  
  background-color: transparent !important; 
}

.ant-menu-submenu-popup {
  left: 120px ; 

  z-index: 1;
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: var(--sidebar-background-color);
  border-radius: 0px 10px 10px 0px;
  padding: 8px 12px;
  width: fit-content;
  
  border-left: none;
}

.ant-menu-submenu-popup > .ant-menu::before {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  right: 90%;
  background-color: var(--sidebar-background-color);
  
  border-left: none;
  border-right: none;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active.ant-dropdown-menu-item-only-child {
  background-color: transparent;
}

  .ant-menu-title-content a{
    color: var(--sidebar-text-color) !important;
  }
  
  .ant-menu-title-content a:hover{
    color: var(--sidebar-submenu-text-color) !important;
  }

  .ant-menu-item-selected {
    background-color: var(--sidebar-background-darker-color) !important;
  }

  .ant-menu-item:active,
  .ant-menu-item:focus,
  .ant-menu-sub .ant-menu-item:active,
  .ant-menu-sub .ant-menu-item:focus {
    background-color: var(--sidebar-background-darker-color) !important;
    color: var(--sidebar-text-color) !important;
  }
  /* sidebar submenu popup end */

  .ant-typography {
    color: var(--text-main-color);
    .ant-typography-copy:not(.ant-typography-copy-success):not(.ant-typography-copy-error) {
  color: var(--text-main-color);
}
  } 

  :root {
    ${commonThemeVariables};
    /* {antOverrideCssVariables}; */
  } 

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  

  /* Chrome, Edge, and Safari */
  /* *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: #504e4e;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #504e4e;
    border-radius: 50%;
    border: 10px none #504e4e;
  } */
  /* ===== Scrollbar CSS ===== */


  /* Dropdown */
  .ant-dropdown-menu {
    background-color: var(--card-bg-color);
    
    .ant-dropdown-menu-item:hover {
      background-color: var(--button-bg-hover-color);
    }

    .ant-dropdown-menu-title-content {
      color: var(--text-main-color);

      :hover {
        color: var(--button-text-hover-color);
      }
    }


  }

/* DatePicker começa */
.ant-picker-panel-container {
  background-color: var(--background-color);
  
  border-radius: ${BORDER_RADIUS};

  button {
    color: var(--text-main-color);

    :hover {
      color: var(--text-secondary-color);
    }
  }
  
  .ant-picker-header {
    color: var(--text-main-color);
  }

  .ant-picker-now-btn {
        color: var(--text-main-color);
  }

  .ant-picker-ok {
    .ant-btn {
      color: var(--button-text-color) !important;
      background-color: var(--button-bg-color) !important;
      border: none;
    }
  }

  .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
      background-color: var(--button-bg-color);
    }
  }

  .ant-picker-time-panel-cell-inner {
    color: var(--text-main-color) !important;

    :hover {
      background-color: var(--button-bg-color) !important;
    }
  }

  .ant-picker-body {
    .ant-picker-content {
      thead th {
        color: var(--text-main-color);
      }



      .ant-picker-cell {
        color: var(--text-secondary-color);
        
        :hover {
          .ant-picker-cell-inner {
            color: var(--button-text-color);
          }
        }

        &.ant-picker-cell-in-view {
          color: var(--text-main-color);
        }

        &.ant-picker-cell-selected {
          .ant-picker-cell-inner {
            color: var(--button-text-color);
            background-color: var(--button-bg-color);
            font-weight: bolder;
          }
        }

        &-in-range::before {
          background: var(--text-secondary-color);
        }

        &:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
        &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
          background: var(--text-secondary-color);
          color: var(--button-text-color);
        }
      }

 

      .ant-picker-cell-inner::before {
        border-color: var(--text-main-color);
      }

      .ant-picker-cell-inner:hover {
        color: var(--button-text-color);
        background-color: var(--button-bg-color);
      }
    }
  }

  .ant-picker-week-panel-row {

    :hover {
      td {
        background-color: var(--button-bg-color);

        div {
          color: var(--button-text-color);
        }

      }

      .ant-picker-cell.ant-picker-cell-week {
        color: var(--button-text-hover-color);
        background-color: var(--button-bg-hover-color);
        font-weight: 700;
      }
    }

    
    &.ant-picker-week-panel-row-selected {
      .ant-picker-cell {
        color: var(--button-text-color);
        background-color: var(--button-bg-color);
      }

      .ant-picker-cell-selected.ant-picker-cell-inner {
        color: var(--button-text-color);
        font-weight: normal;
      }
      .ant-picker-cell-week {
        background-color: var(--button-bg-hover-color);
        font-weight: bolder;
      }

      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          color: var(--text-main-color) !important;
          font-weight: normal !important;
        }
      }
    }
  }

  .ant-picker-footer .ant-picker-today-btn {
    color: var(--text-main-color);
  }

  .ant-picker-cell-range-start.ant-picker-cell-selected,
  .ant-picker-cell-range-end,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start {
    ::before {
      background-color: var(--text-secondary-color);
    }

    .ant-picker-cell-inner {
      color: var(--button-text-color);
      background-color: var(--button-bg-color);
      font-weight: 700;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: var(--text-secondary-color);
  }

  .ant-picker-cell-disabled {
    .ant-picker-cell-inner {
      opacity: 0.5;
    }
  }
}

/* DatePicker termina */

/* Ícone do filtro da coluna da tabela, quando está INATIVO */
.ant-table-filter-trigger {
  color: var(--text-secondary-color);
}

/* Ícone do filtro da coluna da tabela, quando está ATIVO */
.ant-table-filter-trigger.active {
    color: var(--text-main-color);
}


/* textarea começa */

textarea.ant-input {
  color: var(--text-main-color) !important;
  background-color: var(--background-color) !important;
  border-color: var(--input-border-color) ;
  border-radius: ${BORDER_RADIUS} !important;

  :hover {
    border-color: var(--input-border-color);
  }

  :focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color) !important;
  }
}

/* textarea termina */

/* Menu do mobile inicia */
.menu-drawer-mobile {
  .ant-drawer-content {
    width: 100vw;
   .ant-drawer-body {
    
   }
  }
}
/* Menu do mobile termina */

/* Dropdown inicia */
  .ant-table-filter-dropdown {
    background-color: var(--card-bg-color);
    border-radius: 8px;
  }

  .ant-select-dropdown {
    background-color: var(--background-color);
    .ant-select-item.ant-select-item-option {
      color: var(--text-main-color);

    }
    .ant-select-item-option-active {
      background-color: var(--button-bg-color);
    }
  }
  /* Dropdown termina */

  .ant-page-header {
    padding-inline: 0.5rem;
  }

  .ant-card-head-title{
    font-weight: bolder;
  }


  .ant-modal-confirm {
    .ant-modal-body {
      background-color: var(--modal-background-color);
      .ant-modal-confirm-title {
        color: var(--text-main-color) ;
      }
      .ant-modal-confirm-content {
        color: var(--text-main-color) ;

      }
    }
   
  }

`
