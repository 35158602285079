import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export interface GetUserVocacionalByTokenProps {
  token: string
}

export interface GetUserVocacionalByTokenResponse {
  fk_usuario_vocacional_id: number
  nome: string
  email: string
  telefone: string
  pais: string
  etapa: 'pre_cadastro' | 'cadastro' | 'ficha_vocacional'
  status: 'pendente' | 'aprovado' | 'reprovado' | 'desistencia'
}

export function useGetUserVocacionalByToken({ token }: GetUserVocacionalByTokenProps) {
  const userByToken = useQuery({
    queryKey: ['USER_BY_TOKEN', token],
    queryFn: async () => {
      const response = await DataService.get<GetUserVocacionalByTokenResponse>(
        `vocacional/decodificar-token-vocacional/${token}`,
      )
      return response.data
    },
  })

  return userByToken
}
