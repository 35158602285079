import { combineReducers } from 'redux'
import formReducer from './campaign/reducers'
import filterReducer from './filter/reducers'
import themeReducer from './theme/theme'
import authModalsReducer from '../container/benfeitor/layouts/benfeitor-layout/components/Header/Modals/authModalsSlice'
import updateSignupModalsReducer from '../container/benfeitor/layouts/benfeitor-layout/components/Header/Modals/updateUserDataModalSlice'
import { configureStore } from '@reduxjs/toolkit'

const rootReducers = combineReducers({
  form: formReducer,
  filter: filterReducer,
  theme: themeReducer,
  authModals: authModalsReducer,
  updateSignupModal: updateSignupModalsReducer,
})

const store = configureStore({
  reducer: rootReducers,
})

export default rootReducers

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
