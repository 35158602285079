import React, { forwardRef } from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import * as S from './BaseInput.Styles';

const { Group, Search, TextArea, Password } = AntdInput;

interface BaseInputProps extends AntdInputProps {
  className?: string;
  children?: React.ReactNode;
}

const InternalInput = forwardRef<HTMLInputElement, BaseInputProps>(({ className, children, ...props }, ref) => (
  <S.Input ref={ref} className={className} {...props}>
    {children}
  </S.Input>
));

const BaseInput = InternalInput as React.ForwardRefExoticComponent<BaseInputProps> & {
  Group: typeof Group;
  Search: typeof Search;
  TextArea: typeof TextArea;
  Password: typeof Password;
};

BaseInput.Group = Group;
BaseInput.Search = Search;
BaseInput.TextArea = TextArea;
BaseInput.Password = Password;

export { BaseInput };
