import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface CardDonationsPrevistasResponse {
  quantidade: number
  valor: string
}

export function useGetPrevistaDonationsCards() {
  return useQuery<CardDonationsPrevistasResponse>({
    queryKey: ['RECURRENCE_DONATIONS_PREVISTAS_CARD'],
    queryFn: async () => {
      const response = await DataService.get<CardDonationsPrevistasResponse>(`checkout/card-donations-previstas`)

      return response?.data
    },
  })
}
