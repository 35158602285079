import { Progress } from 'antd'
import styled from 'styled-components'

export const BaseProgress = styled(Progress)`
  .ant-progress-text {
    color: var(--text-main-color);
  }

  .ant-progress-inner {
    background-color: var(--card-bg-color);
  }
`
