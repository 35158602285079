import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2rem;
  max-width: 450px;
  padding-right: 2rem;
  padding-left: 2rem;

  @media (min-width: 600px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1264px;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (min-width: 1100px) {
    margin-top: 10rem;
  }
`

export const CardContent = styled.div`
  padding: 2rem 1rem;
`

export const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  color: #c98f00;
  font-size: 34px;
  line-height: 40px;
  font-weight: 400;
  font-family: 'DM Serif Display';
`

export const ListWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

export const StyledImage = styled.img`
  margin: 0 auto;

  @media (min-width: 600px) {
    max-width: 1300px;
    width: 100%;

    min-width: 240px;
    height: 425px;

    margin-top: -65px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
`

export const CardWrapper = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`
