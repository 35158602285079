import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetEstatisticasEstoqueResponse {
  itens_em_estoque: string
  ultima_acao: string
  ultima_entrada: string
}

export function useGetEstatisticasEstoque() {
  const estatisticasEstoque = useQuery({
    queryKey: ['ESTATISTICAS_ESTOQUE'],
    queryFn: async () => {
      const response = await DataService.get<GetEstatisticasEstoqueResponse>('/agape/cards-estatisticas-itens-estoque')

      return response.data
    },
  })

  return estatisticasEstoque
}
