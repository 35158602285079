import { DollarOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { ReactNode } from 'react'
import { CardProps } from 'antd'
import { MainCard } from '../Card'

interface GroupCardProps extends CardProps {
  title: string
  amount: string
  content?: string
  icon: ReactNode
}

export function GroupCard({
  title,
  amount,
  content,
  icon,
  ...props
}: GroupCardProps) {
  return (
    <Card shadow={false} {...props}>
      <IconTitle>
        {icon}
        <Title>{title}</Title>
      </IconTitle>
      <Content>
        <Amount>{amount}</Amount>
        {content && (
          <>
            <Divider>|</Divider>
            <Donations>{content}</Donations>
          </>
        )}
      </Content>
    </Card>
  )
}

const Card = styled(MainCard)`
  height: 100%;

  :not(.ant-card-loading) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.fonts.color.primary};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
`

const Amount = styled.span`
  color: ${(props) => props.theme.fonts.color.secondary};
`

const Divider = styled.span`
  margin: 0 10px;
  font-size: 20px;
  color: #bbb;
`

const Donations = styled.span`
  color: #555;
`
