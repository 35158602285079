import React, { useEffect, useState } from 'react'
import { AreaChart, Line, XAxis, Tooltip, ResponsiveContainer, Area } from 'recharts'
import { formatNumber } from '../../../../../../utility/utility'
import BaseSpin from '../../../../components/spin/BaseSpin'

export function LiveMetricsChart({ liveDetails }) {
  const liveData = liveDetails?.data?.data?.live_data[0]?.dados || []

  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    const newFormattedData = liveData.map((item) => ({
      hora: item.horario.split(' ')[1],
      audiencia: item.audiencia,
    }))
    setFormattedData(newFormattedData)
  }, [liveData])

  return (
    <div
      style={{
        marginTop: '1.5rem',
        marginBottom: '2rem',
        padding: '0',
        backgroundColor: 'var(--card-bg-color)',
        borderRadius: '15px',
        boxShadow: '0 0px 16px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div style={{ height: '400px', overflow: 'hidden', display: 'flex' }}>
        {liveDetails?.data?.data && (
          <>
            <h2 style={{ position: 'absolute', padding: '1.5rem' }}>Audiência da Live</h2>
            <ResponsiveContainer key={JSON.stringify(formattedData)} width="100%" height="100%">
              <AreaChart data={formattedData} margin={{ top: 40, right: 0, bottom: 20, left: 5 }}>
                <XAxis dataKey="hora" />

                <Line
                  type="monotone"
                  dataKey="audiencia"
                  stroke="#a327e6"
                  strokeWidth={2}
                  dot={{ stroke: '#1f1d1d', strokeWidth: 2, r: 8 }}
                  activeDot={{ r: 10 }}
                />

                <Tooltip
                  content={({ payload }) => {
                    const hour = payload?.[0]?.payload?.hora
                    const spectators = payload?.[0]?.payload?.audiencia
                    return (
                      <div
                        style={{
                          color: '#a327e6',
                          background: 'white',
                          border: '1px solid #a327e6',
                          padding: '10px 20px',
                          borderRadius: '5px',
                          opacity: '0.9',
                        }}
                      >
                        <div style={{ fontSize: '1.2rem' }}>
                          <strong style={{ margin: '0 auto' }}>{hour || ''}</strong>
                          <br />
                          {`${formatNumber(spectators)} Espectadores` || ''}
                        </div>
                      </div>
                    )
                  }}
                />

                <defs>
                  <linearGradient id="gradientA" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#ac12ff" />
                    <stop offset="100%" stopColor="rgba(180, 45, 255, 0)" />
                  </linearGradient>
                </defs>

                <Area type="monotone" dataKey="audiencia" stroke="#ac12ff" fill="url(#gradientA)" />
              </AreaChart>
            </ResponsiveContainer>
          </>
        )}
        {liveDetails?.isPending && <BaseSpin style={{ margin: 'auto' }} />}
        {liveDetails?.isError && <h4 style={{ margin: 'auto', color: 'red' }}>Ocorreu um erro...</h4>}
      </div>
    </div>
  )
}
