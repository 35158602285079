export default function GroupCardNumber({ info }) {
  return (
    <>
      <p
        style={{
          margin: 0,
          fontWeight: '600',
        }}
        className="text-secondary-color"
      >
        {info}
      </p>
    </>
  )
}
