import { FormInstance } from 'antd'
import { StyledForm } from '../../../components/Form/styles'
import { Step } from '../../Carteira/Cadastro/hooks/useMultistepForm'

interface StepsContentProps {
  steps: Step[]
  currentStep: number
  form: FormInstance
}

export function StepsContent({ currentStep, steps, form }: StepsContentProps) {
  return (
    <StyledForm form={form} layout="vertical">
      <div>{steps[currentStep].content}</div>
    </StyledForm>
  )
}
