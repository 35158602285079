import { ResponsivePie } from '@nivo/pie'
import { useEffect, useState } from 'react'
import {
  PieChartContainer,
  PieChartLegendColor,
  PieChartLegendContainer,
  PieChartLegendItem,
  PieChartTitle,
} from '../../../styles'
import { formatNumber, formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { useBlur } from '@/context/Blur/BlurContext'

export function Chart({ data }) {
  const { isBlurred } = useBlur()

  const [chartState, setChartState] = useState({
    value: 'R$ 0.00',
    percentage: '0%',
    count: 0,
    color: '#4f95eb',
    label: 'Sem Dados...',
  })

  useEffect(() => {
    if (data && data.length > 0) {
      setChartState({
        value: data[0].value,
        percentage: `${data[0].percentage}%`,
        label: data[0].label,
        color: '#4f95eb',
        count: data[0].count,
      })
    }
  }, [data])

  const renderCustomLegend = () => {
    return (
      <PieChartLegendContainer>
        {data.map((item, index) => (
          <PieChartLegendItem
            key={item.id}
            color={['#4f95eb', '#f3844d'][index % 2]}
            onMouseEnter={() => {
              setChartState({
                value: item.value,
                percentage: `${item.percentage}%`,
                label: item.label,
                color: ['#4f95eb', '#f3844d'][index % 2],
                count: item.count,
              })
            }}
          >
            <PieChartLegendColor color={['#4f95eb', '#f3844d'][index % 2]} />
            <span>{item.label}</span>
          </PieChartLegendItem>
        ))}
      </PieChartLegendContainer>
    )
  }

  return (
    <>
      <PieChartTitle>Anônimas e Identificadas</PieChartTitle>
      <PieChartContainer>
        <ResponsivePie
          data={data || []}
          responsive={true}
          margin={{ top: 30, right: 0, bottom: 30, left: 0 }}
          startAngle={0}
          endAngle={360}
          innerRadius={0.9}
          padAngle={0}
          cornerRadius={0}
          fit={false}
          activeOuterRadiusOffset={12}
          colors={['#4f95eb', '#f3844d']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', '0.2']] }}
          enableArcLinkLabels={false}
          arcLabelsTextColor="#ffffff"
          tooltip={({ datum }) => (
            <div
              style={{
                color: 'white',
                background: datum.color,
                border: '1px solid #000000',
                padding: '10px 20px',
                borderRadius: '5px',
                opacity: '0.9',
              }}
            >
              <strong>
                {datum.label}
                <br />
                <BlurDiv>
                  {formatToBrazilianCurrency(datum.value)} ({datum.data.percentage}%)
                </BlurDiv>
              </strong>
            </div>
          )}
          onMouseEnter={(node) => {
            setChartState({
              value: node.data.value,
              percentage: `${node.data.percentage}%`,
              color: node.color,
              label: node.data.label,
              count: node.data.count,
            })
          }}
          layers={[
            'arcs',
            'arcLinkLabels',
            ({ centerX, centerY, radius }) => (
              <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fill: chartState.color }}
                className={isBlurred ? 'blur' : 'no-blur'}
              >
                <tspan
                  key={chartState.value}
                  x={centerX}
                  dy={-20}
                  style={{ fontWeight: 'bold', fontSize: radius * 0.18 }}
                >
                  {formatToBrazilianCurrency(chartState.value)}
                </tspan>
                <tspan key={chartState.percentage} x={centerX} dy={35} style={{ fontSize: radius * 0.09 }}>
                  {formatNumber(chartState.count)} {chartState.label} | {chartState.percentage}
                </tspan>
              </text>
            ),
          ]}
        />
      </PieChartContainer>
      {renderCustomLegend()}
    </>
  )
}
