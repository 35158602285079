import { Input, InputProps } from 'antd'
import { StyledInputStyles } from './styles'
import { benfeitorTheme } from '../../../../styles/Themes/Benfeitor/benfeitorTheme'

export interface StylesProps {
  backgroundColor?: keyof typeof benfeitorTheme.colors
  lineHeight?: number
  borderRadius?: number
  border?: any
  minWidth?: number
  strongPlaceholder?: boolean
}

export interface StyledInputInterface extends InputProps, StylesProps {}

export function StyledInput(props: StyledInputInterface) {
  return <StyledInputStyles {...props} />
}

StyledInput.Password = (props: StyledInputInterface) => {
  return <StyledInputStyles as={Input.Password} {...props} />
}
