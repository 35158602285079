import { Checkbox, Col, Form, Input, Row, Select } from 'antd'
import ReactInputMask from 'react-input-mask'
import { validateCPF, validateDateOfBirth, validatePassword } from '../../../../../../utility/utility'
import { countries } from '../../../../../../utility/allCountries'
import { useFetchUserMe } from '../../../../../admin/hooks'

interface StepOneLandingPage {
  initialFields?: Record<string, any>
  isForeigner?: boolean
}

export function StepOneSignUpForm({ initialFields }: Readonly<StepOneLandingPage>) {
  const { data: userMe } = useFetchUserMe()

  const loggedIn = !!userMe
  const isForeigner = initialFields?.tipo_documento === 'identidade_estrangeira'

  return (
    <div>
      {!loggedIn && isForeigner && (
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item name={'pais'} label="País" required>
              <Select options={countries.filter((country) => country.value !== 'brasil').map((country) => country)} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="nome"
            label="Nome Completo"
            rules={[
              { min: 6, message: 'Mínimo 6 caracteres' },
              { max: 100, message: 'Máximo 100 caracteres' },
              { required: true, message: 'Obrigatório' },
              {
                pattern: /^[A-Za-zÀ-ÿ\s]+$/,
                message: 'Apenas caracteres alfabéticos',
              },
            ]}
          >
            <Input placeholder="Nome Completo" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Deve ser um e-mail válido',
                whitespace: true,
              },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <Input placeholder="E-mail" disabled={initialFields?.email} />
          </Form.Item>
        </Col>
      </Row>

      {!initialFields?.email && (
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="new_email"
              label="Confirmar E-mail"
              dependencies={['email']}
              rules={[
                { type: 'email', message: '' },
                { required: true, message: 'Obrigatório' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
                  },
                }),
              ]}
            >
              <Input placeholder="Confirmar" disabled={initialFields?.email} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={30}>
        {isForeigner ? (
          <Col xs={24} md={12}>
            <Form.Item
              name="numero_documento"
              label="Numero do Documento"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled={initialFields?.numero_documento} />
            </Form.Item>
          </Col>
        ) : (
          <Col xs={24} md={12}>
            <Form.Item
              name="numero_documento"
              label="CPF"
              rules={[{ required: true, message: '' }, { validator: validateCPF }]}
            >
              <ReactInputMask
                mask="999.999.999-99"
                name="numero_documento"
                maskChar={null}
                placeholder="999.999.999-99"
                disabled={initialFields?.numero_documento}
              >
                {(inputProps) => <Input {...inputProps} disabled={initialFields?.numero_documento} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
        )}
        <Row />

        <Col xs={24} md={12}>
          <Form.Item
            name="data_nascimento"
            label="Data de nascimento"
            rules={[
              { required: true, message: 'Por favor, preencha sua data de nascimento!' },
              { validator: (_, value) => validateDateOfBirth(value) },
            ]}
          >
            <ReactInputMask mask="99/99/9999" placeholder="Dia/Mês/Ano" maskChar={null}>
              {(inputProps) => <Input {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col xl={12} md={12} xs={12}>
          <Form.Item
            name="telefone"
            label="Telefone"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o número de telefone!',
              },
              {
                ...(!isForeigner && {
                  min: 15,
                  message: 'O número deve ter 11 dígitos!',
                }),
              },
              {
                ...(isForeigner && {
                  max: 30,
                  message: 'O número deve ter no máximo 30 dígitos!',
                }),
              },
              {
                ...(isForeigner && {
                  min: 6,
                  message: 'O número deve ter pelo menos 6 dígitos!',
                }),
              },
            ]}
          >
            {isForeigner ? (
              <Input placeholder="Telefone" />
            ) : (
              <ReactInputMask mask="(99) 99999-9999" maskChar={null}>
                {() => <Input placeholder="(99) 99999-9999" />}
              </ReactInputMask>
            )}
          </Form.Item>
        </Col>

        <Col xl={12} md={12} xs={12}>
          <Form.Item
            name="sexo"
            label="Sexo"
            rules={[{ required: true, message: 'Obrigatório' }]}
            // initialValue="masculino"
          >
            <Select>
              <Select.Option value="masculino">Masculino</Select.Option>
              <Select.Option value="feminino">Feminino</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {!loggedIn && (
        <Row gutter={30}>
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              name="password"
              label="Crie uma senha"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
            >
              <Input.Password placeholder="Senha" />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              name="new_password"
              label="Confirmar senha"
              dependencies={['password']}
              rules={[
                { message: 'Por favor, confirme sua senha!', required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('As senhas digitadas não coincidem!'))
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirmar" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error('É necessário aceitar nossos Termos de Serviços e Política de Privacidade')),
          },
        ]}
      >
        <Checkbox style={{ color: 'white' }}>
          Criar uma conta significa que você concorda com nossos Termos de Uso e Política de Privacidade.
        </Checkbox>
      </Form.Item>
    </div>
  )
}
