import styled from 'styled-components'

interface GoldenHeaderProps {
  fontSize?: number | string
  lineHeight?: number | string
}

export const GoldenHeader = styled.div<GoldenHeaderProps>`
  background: linear-gradient(20deg, #c98f00 40%, #ffe198 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-size: ${(props) => (typeof props.fontSize === 'number' ? `${props.fontSize}px` : props.fontSize || '24px')};
  font-family: 'DM Serif Display', serif;
  line-height: ${(props) =>
    typeof props.lineHeight === 'number' ? `${props.lineHeight}%` : props.lineHeight || '115%'};

  z-index: 10;
`
