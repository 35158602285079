import {
  SET_FORM_DATA,
  SET_CAPA_IMAGE,
  SET_BACKGROUND_IMAGE,
  SET_BANNER_IMAGE,
  SET_FORM_FIELD,
  RESET_FORM,
} from './actions'

const initialState = {
  formData: { fk_empresa_id: 1 },
  capaImage: [],
  backgroundImage: [],
  bannerImage: [],
}

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      }
    case SET_CAPA_IMAGE:
      return {
        ...state,
        capaImage: action.payload,
      }
    case SET_BACKGROUND_IMAGE:
      return {
        ...state,
        backgroundImage: action.payload,
      }
    case SET_BANNER_IMAGE:
      return {
        ...state,
        bannerImage: action.payload,
      }
    case SET_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }
    case RESET_FORM:
      return initialState
    default:
      return state
  }
}

export default formReducer
