import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 1.5rem;

  @media (min-width: 800px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 1200px;
  }
`

export const Image = styled.img`
  width: 100%;
  display: flex;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;

  @media (min-width: 800px) {
    display: flex;
    margin-right: auto;
    margin-bottom: 2rem;
    max-width: max-content;
    /* margin-left: auto; */
    /* width: 500px; */
    /* height: 505px; */
    /* margin-top: 5rem; */
  }
  @media (min-width: 1100px) {
    margin-top: -5rem;
  }
`

export const ContentWrapper = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  > div:nth-child(1) {
    margin-bottom: 1.5rem;
  }

  > div:nth-child(2) {
    margin-bottom: 2.5rem;
  }

  > div:nth-child(4) {
    margin-top: 1.5rem;
  }

  @media (min-width: 600px) {
    padding: 0 0 0 2rem;
    /* margin-top: 5rem; */
    margin-bottom: 2rem;
    max-width: 380px;
  }

  .header {
    text-align: start;
  }
`

export const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #efe5d8;

  span {
    font-weight: 700;
  }
`

export const ListWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  font-weight: 400;
  font-family: 'DM Serif Display';
  font-size: 16px;
  line-height: 30px;

  li {
    position: relative;
    padding-left: 1.5rem; /* Space for the orange point */
    font-size: 18px;
    line-height: 27px;
    color: #efe5d8;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 8px; /* Adjust size of the orange point */
      height: 8px; /* Adjust size of the orange point */
      background-color: orange; /* Orange color for the point */
      border-radius: 50%; /* Makes it circular */
    }
  }
`
