import { Image, Row } from 'antd'
import { sacramentosOptions } from '../../../../../../benfeitor/pages/Vocacional/FichaVocacional/VidaFormFields'
import BaseCard from '../../../../../components/card/BaseCard'
import { BaseDescription } from '../../../../../components/description/BaseDescription'
import { GetAllFichaVocacionalResponse } from '../../../../../hooks/vocacional/queries/useFetchGetAllFichaVocacional'
import { ListaDetalhesAnteriores } from './ListaDetalhesAnteriores'

interface ListaDetalhesFichaVocacionalProps {
  dados: GetAllFichaVocacionalResponse['fichas_vocacionais'][number]
}

export function ListaDetalhesFichaVocacional({ dados }: ListaDetalhesFichaVocacionalProps) {
  const sacramentosLabels = dados.ficha_do_vocacional.sacramentos
    .map((sacramento) => {
      const option = sacramentosOptions.find((opt) => opt.value === sacramento)
      return option ? option.label : sacramento
    })
    .join(', ')

  return (
    <BaseCard title={dados.pre_cadastro.nome}>
      <ListaDetalhesAnteriores dados={dados} />

      <h3 style={{ marginTop: '24px' }}>Ficha Vocacional</h3>
      <BaseDescription bordered column={{ xs: 1, md: 2 }} size="small" layout="vertical">
        <BaseDescription.Item label="Foto" span={2}>
          <Row justify="center">
            <Image src={dados.ficha_do_vocacional.foto_vocacional} width={250} />
          </Row>
        </BaseDescription.Item>
        <BaseDescription.Item label="Motivação da Admissão Vocacional">
          {dados.ficha_do_vocacional.motivacao_admissao_vocacional}
        </BaseDescription.Item>
        <BaseDescription.Item label="Escolaridade">{dados.ficha_do_vocacional.escolaridade}</BaseDescription.Item>
        <BaseDescription.Item label="Profissão">{dados.ficha_do_vocacional.profissao}</BaseDescription.Item>
        <BaseDescription.Item label="Identificação com o Instituto">
          {dados.ficha_do_vocacional.identificacao_instituto}
        </BaseDescription.Item>
        <BaseDescription.Item label="Referência conhecimento Instituto">
          {dados.ficha_do_vocacional.referencia_conhecimento_instituto}
        </BaseDescription.Item>
        <BaseDescription.Item label="Aceitação familiar">
          {dados.ficha_do_vocacional.aceitacao_familiar}
        </BaseDescription.Item>
        <BaseDescription.Item label="Estado Civil">{dados.ficha_do_vocacional.estado_civil}</BaseDescription.Item>
        {dados.ficha_do_vocacional.estado_civil === 'divorciado' && (
          <BaseDescription.Item label="Justificativa do divórcio">
            {dados.ficha_do_vocacional.motivo_divorcio}
          </BaseDescription.Item>
        )}
        <BaseDescription.Item label="Descrição do problema de saúde">
          {dados.ficha_do_vocacional.descricao_problema_saude ?? ' - '}
        </BaseDescription.Item>
        <BaseDescription.Item label="Remédio Controlado">
          {dados.ficha_do_vocacional.remedio_controlado_inicio &&
          dados.ficha_do_vocacional.remedio_controlado_termino ? (
            <>
              Início: {dados.ficha_do_vocacional.remedio_controlado_inicio} | Fim:{' '}
              {dados.ficha_do_vocacional.remedio_controlado_termino}
            </>
          ) : (
            ' - '
          )}
        </BaseDescription.Item>
        <BaseDescription.Item label="Deixou a religião anterior em">
          {dados.ficha_do_vocacional.deixou_religiao_anterior_em ?? ' - '}
        </BaseDescription.Item>

        <BaseDescription.Item label="Sacramentos">{sacramentosLabels}</BaseDescription.Item>
        <BaseDescription.Item label="Seminário de Vida no Espírito Santo realizado em">
          {dados.ficha_do_vocacional.seminario_realizado_em}
        </BaseDescription.Item>
        <BaseDescription.Item label="Testemunho de conversão">
          {dados.ficha_do_vocacional.testemunho_conversao}
        </BaseDescription.Item>

        <BaseDescription.Item label="Rotina diária">{dados.ficha_do_vocacional.rotina_diaria}</BaseDescription.Item>
      </BaseDescription>
    </BaseCard>
  )
}
