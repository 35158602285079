import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../context/Auth';
import { env } from './env';

const BASE_URL = env.VITE_API_OLD_ENDPOINT;

// Axios Instance
const DataService = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': '7777777777777',
  },
});

// Function to handle authentication error
function handleAuthError() {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  Cookies.remove('logedIn');
  Cookies.remove('profile');
  Cookies.remove('superProfile');

  const { logout } = useAuth();
  logout();
}

// Function to fetch the refresh token
async function fetchRefreshToken() {
  try {
    const response = await axios.post(
      `${BASE_URL}auth/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('refresh_token')}`,
        },
      },
    );

    const newAccessToken = response.data.access_token;
    Cookies.set('access_token', newAccessToken);
    return newAccessToken;
  } catch (error) {
    // Handle Invalid Refresh Token
    handleAuthError();
    window.location.href = '/login';
    throw error;
  }
}

// Middleware to Check Access Token
let accessTokenPromise = null;

DataService.interceptors.request.use(
  async (config) => {
    const accessToken = Cookies.get('access_token');

    if (config.bypassAuth) {
      return config;
    }

    if (Cookies.get('logedIn') && !accessToken) {
      handleAuthError();
      window.location.href = '/login';
      return config;
    }

    if (!accessToken) {
      return config;
    }

    let user;
    try {
      user = jwtDecode(accessToken);
    } catch (decodeError) {
      // Handle JWT decode error
      handleAuthError();
      window.location.href = '/login';
      return config;
    }

    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 10000;

    if (!isExpired) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }

    if (!accessTokenPromise) {
      accessTokenPromise = fetchRefreshToken()
        .then((newToken) => {
          accessTokenPromise = null;
          return newToken;
        })
        .catch((error) => {
          accessTokenPromise = null;
          return Promise.reject(error);
        });
    }

    const newAccessToken = await accessTokenPromise;
    config.headers.Authorization = `Bearer ${newAccessToken}`;
    return config;
  },
  (error) => Promise.reject(error),
);

// Response Interceptor for Token Expiry
DataService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401 && error.response.data?.msg === 'Token expirado.') {
      try {
        const newAccessToken = await fetchRefreshToken();

        error.config.headers.Authorization = `Bearer ${newAccessToken}`;

        console.log('Retrying request with new access token:', error.config);

        return DataService(error.config);
      } catch (err) {
        console.error('Failed to refresh token', err);
        handleAuthError();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  },
);

export async function apiRequest<T>(config: AxiosRequestConfig): Promise<T> {
  const response: AxiosResponse<T> = await DataService(config);
  return response.data;
}

export { DataService };

