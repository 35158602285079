import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface GetListarItensRecebidosProps {
  fk_doacao_agape_id: number
  fk_instancia_acao_agape_id: number
}

interface GetListarItensRecebidosResponse {
  itens_recebidos: {
    item: string
    quantidade: number
  }[]
}

export function useGetListarItensRecebidos({
  fk_doacao_agape_id,
  fk_instancia_acao_agape_id,
}: GetListarItensRecebidosProps) {
  const itensRecebidos = useQuery({
    queryKey: ['LISTAR_ITENS_RECEBIDOS', fk_doacao_agape_id, fk_instancia_acao_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetListarItensRecebidosResponse>(
        `/agape/listar-itens-recebidos/${fk_instancia_acao_agape_id}/${fk_doacao_agape_id}`,
      )

      return response.data
    },
  })

  return itensRecebidos
}
