import { Chart } from './Chart'
import { AnimatedBaseCard, ErrorMessage, LoadingContainer } from '../../../styles'
import { useGetDonationsByPaymentMethod } from '../../../../../hooks/dashboards/finances/queries/useGetDonationsByPaymentMethod'

export const Content = ({ filters, setCampaignsOptions }) => {
  const { data: response, isPending, isError } = useGetDonationsByPaymentMethod(filters)

  const totalDonations = response?.data?.total_donations_per_method || {}
  const chartData = {
    total: response?.data?.payment_methods_percentage || {},
    data: [
      {
        id: 'status',
        data: Object.entries(totalDonations).map(([method, total]) => ({
          x: method === 'total_boleto' ? 'Boleto' : method === 'total_credito' ? 'Cartão' : 'Pix',
          y: total || 0,
        })),
      },
    ],
  }

  const pieChartData = chartData.data[0].data.map(({ x: id, y: value }) => ({ id, value }))

  if (response?.data?.campaigns) {
    setCampaignsOptions(response?.data?.campaigns)
  }

  if (isPending) {
    return (
      <AnimatedBaseCard>
        <LoadingContainer />
      </AnimatedBaseCard>
    )
  }

  if (isError) {
    return (
      <AnimatedBaseCard>
        <ErrorMessage />
      </AnimatedBaseCard>
    )
  }
  return <Chart chartData={chartData} pieChartData={pieChartData} />
}
