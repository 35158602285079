import { Result, Row } from 'antd'
import { useParams } from 'react-router-dom'
import { StyledButton } from '../../../components/Button'
import { useWithdrawalVocacional } from '../../../hooks/api/vocacional/mutations/useWithdrawalVocacional'
import { useGetUserVocacionalByToken } from '../../../hooks/api/vocacional/queries/useGetUserVocacionalByToken'
import { StyledSpin } from '../../../components/Spin/styles'
import { Title } from '../../../components/Title'

type DesistenciaVocacionalUseParams = {
  token: string
}

export function DesistenciaVocacional() {
  const { token } = useParams<DesistenciaVocacionalUseParams>()

  const {
    data: userVocacional,
    isLoading: isLoadingUserVocacional,
    isError: isErrorUserVocacional,
  } = useGetUserVocacionalByToken({
    token,
  })

  const {
    mutateAsync: withDrawalVocacional,
    isPending: isPendingWithDrawalVocacional,
    isSuccess: isSuccessWithDrawalVocacional,
  } = useWithdrawalVocacional()

  function handleWithDrawalVocacional() {
    if (userVocacional) {
      withDrawalVocacional({ fk_usuario_vocacional_id: userVocacional.fk_usuario_vocacional_id })
    }
  }

  if (isLoadingUserVocacional) {
    return (
      <>
        <Title>Desistência do Vocacional</Title>
        <Row justify="center" style={{ minHeight: '60vh', alignItems: 'center' }}>
          <StyledSpin />
        </Row>
      </>
    )
  }

  if (isErrorUserVocacional) {
    return (
      <>
        <Title>Desistência do Vocacional</Title>
        <Result title="Erro" subTitle="Houve um erro ao tentar acessar esse conteúdo." status="500" />
      </>
    )
  }

  if (userVocacional?.status === 'desistencia') {
    return (
      <>
        <Title>Desistência do Vocacional</Title>
        <Result title="Você já desistiu do processo vocacional" subTitle="Não é possível prosseguir." status="error" />
      </>
    )
  }

  if (isSuccessWithDrawalVocacional) {
    return (
      <>
        <Title>Desistência do Vocacional</Title>
        <Result
          title="Desistência realizada."
          subTitle="Você optou desistir do processo vocacional."
          status="success"
          style={{ paddingTop: '0' }}
        />
      </>
    )
  }

  return (
    <>
      <Title>Desistência do Vocacional</Title>
      <Result
        title="Tem certeza que deseja realizar a desistência?"
        subTitle="Ao clicar no botão abaixo, você sinaliza desistência do processo vocacional"
        extra={
          <Row justify="center">
            <StyledButton
              $severity="error"
              onClick={handleWithDrawalVocacional}
              loading={isPendingWithDrawalVocacional}
            >
              Desistir
            </StyledButton>
          </Row>
        }
      />
    </>
  )
}
