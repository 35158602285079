import { Row } from 'antd';
import { StyledButton } from '../../components/Button';
import { StyledResult } from '../../components/Result';
import { Link } from 'react-router-dom';

export function NotFound() {
  return (
    <StyledResult
      status="404"
      title="Página não encontrada"
      subTitle="A página que você tentou acessar não existe."
      extra={
        <Row justify="center">
          <Link to="/" children={<StyledButton>Voltar</StyledButton>} />
        </Row>
      }
      style={{minHeight: '70vh'}}
    />
  );
}
