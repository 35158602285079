import Slider from 'react-slick'

import getGroupLinkStepOne from '../../images/get_group_link_step_1.jpeg'
import getGroupLinkStepTwo from '../../images/get_group_link_step_2.jpeg'
import getGroupLinkStepThree from '../../images/get_group_link_step_3.jpeg'
import getGroupLinkStepFour from '../../images/get_group_link_step_4.jpeg'
import getGroupLinkStepFive from '../../images/get_group_link_step_5.jpeg'

import { Modal } from 'antd'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
}

interface TutorialModalProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function TutorialModal({ isModalOpen, setIsModalOpen }: TutorialModalProps) {
  return (
    <Modal
      title="Como obter o Link do Grupo?"
      footer={null}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      style={{ top: 15 }}
      width={300}
      bodyStyle={{ paddingBottom: '3rem' }}
    >
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={getGroupLinkStepOne}
                style={{ border: 'solid gray 2px', borderRadius: '25px', maxWidth: '95%' }}
              />
              <div style={{ color: '#4c4c4c', fontSize: '14px', marginTop: '1rem', marginBottom: '0.5rem' }}>
                Passo 1 - Após criar o grupo, clique sobre ele na página inicial com todas as conversas do seu WhatsApp.
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={getGroupLinkStepTwo}
                style={{ border: 'solid gray 2px', borderRadius: '25px', maxWidth: '95%' }}
              />
              <div style={{ color: '#4c4c4c', fontSize: '14px', marginTop: '1rem', marginBottom: '0.5rem' }}>
                Passo 2 - Na parte de conversa do grupo, clique sobre o nome do grupo para abrir os detalhes dele.
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={getGroupLinkStepThree}
                style={{ border: 'solid gray 2px', borderRadius: '25px', maxWidth: '95%' }}
              />
              <div style={{ color: '#4c4c4c', fontSize: '14px', marginTop: '1rem', marginBottom: '0.5rem' }}>
                Passo 3 - Na janela aberta terá todas as informações do seu grupo, desça um pouco para a área onde é
                informado os membros do grupo.
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={getGroupLinkStepFour}
                style={{ border: 'solid gray 2px', borderRadius: '25px', maxWidth: '95%' }}
              />
              <div style={{ color: '#4c4c4c', fontSize: '14px', marginTop: '1rem', marginBottom: '0.5rem' }}>
                Passo 4 - Terá uma opção chamada “Convidar via link”. Clique sobre ela.
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={getGroupLinkStepFive}
                style={{ border: 'solid gray 2px', borderRadius: '25px', maxWidth: '95%' }}
              />
              <div style={{ color: '#4c4c4c', fontSize: '14px', marginTop: '1rem', marginBottom: '0.5rem' }}>
                Passo 5 - Na janela aberta terá as opções para convite. Clique sobre a opção “Copiar link”. Pronto, seu
                link estará copiado, cole-o na área solicitada.
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </Modal>
  )
}
