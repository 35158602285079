import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { AbrangenciaCicloDoacao } from '../mutations/useCadastrarCicloDoacao'

interface GetCicloDoacaoAnteriorProps {
  fk_instancia_acao_agape_id: number
  open: boolean
}

interface GetInformacoesCicloResponse {
  fk_acao_agape_id: number
  abrangencia: AbrangenciaCicloDoacao
  doacoes: {
    fk_estoque_agape_id: number
    item: string
    quantidade: number
  }[]
  endereco: {
    bairro: string
    cep: string
    cidade: string
    complemento: string
    estado: string
    numero: string
    rua: string
  }
}

export function useGetInformacoesCiclo({ fk_instancia_acao_agape_id, open }: GetCicloDoacaoAnteriorProps) {
  const informacoesCiclo = useQuery({
    queryKey: ['INFORMACOES_CICLO', fk_instancia_acao_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetInformacoesCicloResponse>(
        `/agape/buscar-ciclo-acao-agape/${fk_instancia_acao_agape_id}`,
      )

      return response.data
    },
    enabled: open,
  })

  return informacoesCiclo
}
