import { Content } from './Content'
import BaseCard from '../../../../../components/card/BaseCard'

export function SignupsPerMonth() {
  return (
    <BaseCard padding="0px">
      <Content />
    </BaseCard>
  )
}
