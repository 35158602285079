import { useState } from 'react'
import { handleRes } from './utils'
import { SectionBecomeMember } from './sections/become-member-section'
import { SmFooter } from '../components/footer'
import { SectionWhyBecomeMember } from './sections/why-become-member-section'
import { CardWhatIsSaoMiguel } from './sections/card-what-is-sao-miguel'
import { FirstCta } from './sections/first-cta'
import { SecondCta } from './sections/second-cta/second-cta'
import { SectionHowToJoin } from './sections/how-to-join-section'
import { PrayerCard } from './sections/prayer-card'

export function AlistamentoSmLandingPage() {
  const [res, setRes] = useState<'desktop' | 'mobile'>('mobile')
  handleRes({ setRes })

  return (
    <div>
      <SectionBecomeMember res={res} />
      <PrayerCard />
      <SectionWhyBecomeMember />
      <SectionHowToJoin />
      <FirstCta />
      <SecondCta />
      <CardWhatIsSaoMiguel />
      <SmFooter />
    </div>
  )
}
