import { DollarOutlined } from '@ant-design/icons'
import { BaseCarousel } from '../../../../components/carousel/BaseCarousel'
import { GroupCard } from '../../../../components/GroupCard'
import { formatToBrazilianCurrency } from '../../../../../../utility/utility'
import { useFetchRecurrenceInfo } from '../../../../hooks/donations/queries/useFetchRecurrenceInfo'
import { useFetchRememberRecurrenceCard } from '../../../../hooks/donations/queries/useFetchRememberRecurrenceCard'
import { useFetchCanceledRecurrenceCard } from '../../../../hooks/donations/queries/useFetchCanceledRecurrenceCard'
import { RecorrenteEfetuadasResponse } from '../../../../hooks/donations/queries/useFetchRecurrenceEfetuadas'
import { UseQueryResult } from '@tanstack/react-query'
import { RecorrenteNaoEfetuadasResponse } from '../../../../hooks/donations/queries/useFetchRecurrenceNaoEfetuadas'
import { CardDonationsPrevistasResponse } from '../../../../hooks/donations/queries/useGetPrevistaDonationsCard'
import { BlurDiv } from '@/context/Blur/BlurDiv'

interface RecurrenceCardsProps {
  recorrentesEfetuadas: UseQueryResult<RecorrenteEfetuadasResponse>
  recorrentesPrevistasCard: UseQueryResult<CardDonationsPrevistasResponse>
  recorrentesNaoPagas: UseQueryResult<RecorrenteNaoEfetuadasResponse>
}

export function RecurrenceCards({
  recorrentesEfetuadas,
  recorrentesPrevistasCard,
  recorrentesNaoPagas,
}: RecurrenceCardsProps) {
  const totalRecorrente = useFetchRecurrenceInfo()
  const cardLembretesEfetivos = useFetchRememberRecurrenceCard()
  const cardRecorrenciasCanceladas = useFetchCanceledRecurrenceCard()

  return (
    <div style={{ marginBottom: '1.2rem' }}>
      <BaseCarousel slidesToShow={3} slidesToScroll={3} title={''} style={{}}>
        <GroupCard
          title="Efetuadas no mês"
          icon={DollarOutlined}
          isLoading={recorrentesEfetuadas?.isPending}
          isError={recorrentesEfetuadas?.isError}
          number={
            <BlurDiv>
              {`${
                recorrentesEfetuadas?.data?.info_card?.valor_total
                  ? formatToBrazilianCurrency(recorrentesEfetuadas?.data?.info_card?.valor_total)
                  : '-'
              } | ${recorrentesEfetuadas?.data?.info_card?.total_items} doações`}
            </BlurDiv>
          }
        />

        <GroupCard
          title="Previstas no mês"
          icon={DollarOutlined}
          isLoading={recorrentesPrevistasCard.isPending}
          isError={recorrentesPrevistasCard.isError}
          number={
            <BlurDiv>
              {`${formatToBrazilianCurrency(recorrentesPrevistasCard?.data?.valor)} | ${
                recorrentesPrevistasCard?.data?.quantidade
              } doações`}
            </BlurDiv>
          }
        />

        <GroupCard
          title="Não pagas no mês"
          icon={DollarOutlined}
          isLoading={recorrentesNaoPagas.isPending}
          isError={recorrentesNaoPagas.isError}
          number={
            <BlurDiv>
              {`${
                recorrentesNaoPagas?.data?.info_card.valor_total
                  ? formatToBrazilianCurrency(recorrentesNaoPagas?.data?.info_card.valor_total)
                  : '-'
              } | ${recorrentesNaoPagas?.data?.info_card.total_items} doações`}
            </BlurDiv>
          }
        />

        <GroupCard
          title="Canceladas"
          icon={DollarOutlined}
          isLoading={cardRecorrenciasCanceladas.isPending}
          isError={cardRecorrenciasCanceladas.isError}
          number={
            <BlurDiv>
              `$
              {cardRecorrenciasCanceladas?.data?.valor_total_canceladas
                ? formatToBrazilianCurrency(cardRecorrenciasCanceladas?.data?.valor_total_canceladas)
                : '-'}{' '}
              | ${cardRecorrenciasCanceladas?.data?.quantidade_canceladas} doações`
            </BlurDiv>
          }
        />

        <GroupCard
          title="Lembretes efetivos"
          icon={DollarOutlined}
          isLoading={cardLembretesEfetivos.isPending}
          isError={cardLembretesEfetivos.isError}
          number={
            <BlurDiv style={{ fontSize: '0.8rem' }}>
              {cardLembretesEfetivos?.data?.valor_total
                ? formatToBrazilianCurrency(cardLembretesEfetivos?.data?.valor_total)
                : '0'}
              {' | '}
              {cardLembretesEfetivos?.data?.qtd_doacoes} doações{' | '}
              {cardLembretesEfetivos?.data?.qtd_doadores} benfeitores
            </BlurDiv>
          }
        />

        <GroupCard
          title="Total"
          icon={DollarOutlined}
          isLoading={totalRecorrente.isPending}
          isError={totalRecorrente.isError}
          number={
            <BlurDiv style={{ fontSize: '0.8rem' }}>
              {totalRecorrente?.data?.valor_recorrencia_prospectado
                ? formatToBrazilianCurrency(totalRecorrente?.data?.valor_recorrencia_prospectado)
                : '-'}
              {' | '}
              {totalRecorrente?.data?.quantidade_doacoes_recorrentes} doações{' | '}
              {totalRecorrente?.data?.quantidade_doadores} doadores
            </BlurDiv>
          }
        />
      </BaseCarousel>
    </div>
  )
}
