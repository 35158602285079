import { Col, Form, Row } from 'antd'
import {
  EstoqueAgapeItemFormResponse,
  ListaFormularioItensDoacao,
  transformarArrayDoacoes,
} from '../../../Itens/Header/CadastrarCicloDoacao/ListaFormularioItensDoacao'
import { UsersFour } from '@phosphor-icons/react'
import { CadastrarDoacaoSteps } from '../utils/CadastrarCicloDoacaoStepItems'
import { BaseSteps } from '../../../../../components/steps'
import BaseButton from '../../../../../components/button/BaseButton'
import { useGetItensCiclo } from '../../../../../hooks/agape/queries/useGetItensCiclo'
import { useCadastrarDoacao } from '../../../../../hooks/agape/mutations/useCadastrarDoacao'

interface SelecionarItensForm {
  doacoes: EstoqueAgapeItemFormResponse[]
}

interface SelecionarItensProps {
  retornarParaBusca: () => void
  avancarParaRecibo: () => void
  obterIdDoacao: (idDoacao: number) => void
  nomeFamilia: string
  idCicloDoacao: number
  idFamilia: number
}

export function SelecionarItens({
  retornarParaBusca,
  avancarParaRecibo,
  obterIdDoacao,
  idCicloDoacao,
  idFamilia,
  nomeFamilia,
}: SelecionarItensProps) {
  const [form] = Form.useForm()

  const { mutateAsync: cadastrarDoacao, isPending: isPendingCadastrarDoacao } = useCadastrarDoacao()

  async function handleEnvioDoacao(data: SelecionarItensForm) {
    const doacoesFormatado = transformarArrayDoacoes(data.doacoes).map((item) => {
      return {
        fk_item_instancia_agape_id: item.fk_estoque_agape_id,
        quantidade: item.quantidade,
      }
    })

    const doacao = await cadastrarDoacao({
      doacoes: doacoesFormatado,
      fk_familia_agape_id: idFamilia,
      fk_instancia_acao_agape_id: idCicloDoacao,
    })

    obterIdDoacao(doacao.fk_doacao_agape_id)

    avancarParaRecibo()
  }

  const { data: itensCiclo } = useGetItensCiclo({ fk_instancia_acao_agape_id: idCicloDoacao })

  const itensCicloFormatado = itensCiclo?.itens_ciclo_agape?.map((item) => {
    return {
      id: item.fk_item_instancia_agape_id,
      item: item.item,
      quantidade: item.quantidade,
    }
  })

  return (
    <>
      <BaseSteps current={2} items={CadastrarDoacaoSteps} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: '0.5rem',
          marginTop: '2rem',
        }}
      >
        <h2>Família {nomeFamilia}</h2>
        <UsersFour size={32} color="var(--text-main-color)" />
      </div>

      <Form form={form} layout="vertical" onFinish={handleEnvioDoacao}>
        <ListaFormularioItensDoacao form={form} itensEstoque={itensCicloFormatado!} />

        <Row justify="center" gutter={[20, 20]} style={{ marginTop: '1rem' }}>
          <Col xs={24} sm={12}>
            <Row justify="center">
              <BaseButton block type="ghost" onClick={retornarParaBusca}>
                Buscar por CPF novamente
              </BaseButton>
            </Row>
          </Col>
          <Col xs={24} sm={12}>
            <Row justify="center">
              <BaseButton block htmlType="submit" loading={isPendingCadastrarDoacao}>
                Confirmar
              </BaseButton>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}
