import styled from 'styled-components'
import { BaseInput } from '../BaseInput/BaseInput'
import { FONT_SIZE, FONT_WEIGHT } from '../../../../../styles/Themes/Admin/constants'

export const SearchInput = styled(BaseInput.Search)`
  svg {
    color: var(--text-main-color);
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  & input {
    font-weight: 600;
    color: var(--text-main-color);
    background-color: var(--background-color);

    &::placeholder {
      color: var(--text-main-color);
      background-color: var(--background-color);
      font-weight: 500;
    }
  }

  .ant-input-affix-wrapper {
    background-color: var(--background-color);
  }

  .ant-input-group-addon {
    min-width: 5.5rem;
    color: var(--primary-color);
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.lg};
  }

  .ant-input-search-button {
    &.ant-btn .anticon {
      color: var(--text-main-color);
    }
    width: 100%;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    color: var(--text-main-color);
  }
`
