import * as S from './BaseSpin.Styles'
import React, { FC, ReactNode } from 'react'
import { SpinProps } from 'antd/es/spin'

interface BaseSpinProps extends SpinProps {
  children?: ReactNode
}

const BaseSpin = ({ children, ...props }: BaseSpinProps) => {
  return <S.BaseSpin {...props}>{children}</S.BaseSpin>
}

export default BaseSpin
