import { ReactNode } from 'react';
import { HeaderLandingPage } from './components/HeaderLandingPage';
import { Footer } from '../benfeitor-layout/components/Footer';
import { LayoutWrapperLandingPage, StyledContent } from './components/styles';

export function LandingPageLayout({ children }: { children: ReactNode }) {
  return (
    <LayoutWrapperLandingPage>
      <HeaderLandingPage />
      {children}
      <Footer />
    </LayoutWrapperLandingPage>
  );
}
