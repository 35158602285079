import { FormInstance, Space } from 'antd'

import { useState } from 'react'
import { BaseDrawer } from '../../../../../../components/drawer/BaseDrawer'
import BaseButton from '../../../../../../components/button/BaseButton'
import { EditarMembro } from '../editar/EditarMembro'
import { AdicionarMembros } from '../adicionar/AdicionarMembros'
import { useGetPermissions } from '../../../../../../hooks/users/Queries/useGetSelfPermissions'

type MembrosDrawerProps = {
  open: boolean
  titulo: string
  onClose: () => void
  onSubmit: () => void
  form: FormInstance
  editarMembro: boolean
  idMembro?: number
  idFamilia: number
}

export function MembrosDrawer({
  open,
  titulo,
  onClose,
  onSubmit,
  form,
  editarMembro,
  idMembro,
  idFamilia,
}: MembrosDrawerProps) {
  const { data: permissions } = useGetPermissions()

  const [isPending, setIsPending] = useState(false)

  const handleIsPending = (isPending: boolean) => {
    setIsPending(isPending)
  }

  const podeEditarMembro = permissions?.permissoes.familia_agape.editar === 1
  const podeExcluirMembro = permissions?.permissoes.familia_agape.deletar === 1

  return (
    <BaseDrawer
      title={<h3> {!podeEditarMembro && !podeExcluirMembro ? 'Visualizar membro' : titulo}</h3>}
      placement="bottom"
      height={650}
      closable={false}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <BaseButton onClick={onClose}>Fechar</BaseButton>

          {podeEditarMembro && (
            <BaseButton onClick={onSubmit} loading={isPending}>
              Enviar
            </BaseButton>
          )}
        </Space>
      }
    >
      {editarMembro ? (
        <EditarMembro form={form} idMembro={idMembro!} handleIsPending={handleIsPending} onClose={onClose} />
      ) : (
        <AdicionarMembros form={form} idFamilia={idFamilia} handleIsPending={handleIsPending} onClose={onClose} />
      )}
    </BaseDrawer>
  )
}
