import { env } from '@/lib/env'
import { useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import axios from 'axios'
import Cookies from 'js-cookie'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFetchUserMe } from '../container/admin/hooks'
import { DataService } from '../lib/dataService'

const COOKIE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  LOGGED_IN: 'logedIn',
  SUPER_PROFILE: 'superProfile',
}

export interface LoginPayload {
  email: string
  password: string
}

interface LoginAfterRegisterPayload {
  access_token: string
  refresh_token: string
}

interface User {
  profileId: string | null
  superProfile?: string | null
  isAuthenticated: boolean
}
interface AuthContextType {
  userAuthState: User
  login: (payload: LoginPayload) => Promise<void>
  loginAfterRegister: (payload: LoginAfterRegisterPayload) => Promise<void>
  logout: () => Promise<void>
  resetPassword: (payload: string) => Promise<void>
}
interface ErrorResponse {
  error: string
}
const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient()

  const history = useHistory()
  const userMe = useFetchUserMe()

  const [userAuthState, setUserAuthState] = useState<User>({
    isAuthenticated: Cookies.get(COOKIE_KEYS.LOGGED_IN) === 'true',
    profileId: null,
    superProfile: null,
  })

  // EFFECT TO LOAD SESSION:
  useEffect(() => {
    const loggedIn = Cookies.get(COOKIE_KEYS.LOGGED_IN)
    const superProfileCookie = Cookies.get(COOKIE_KEYS.SUPER_PROFILE)

    if (loggedIn) {
      setUserAuthState({
        isAuthenticated: true,
        profileId: userMe?.data?.fk_perfil_id,
        superProfile: userMe?.data?.super_perfil,
      })

      if (userMe?.data?.super_perfil !== superProfileCookie) {
        Cookies.set(COOKIE_KEYS.SUPER_PROFILE, userMe?.data?.super_perfil ? 'true' : 'false')
      }
    }
  }, [userMe?.data?.super_perfil, userMe?.data?.fk_perfil_id])

  // HANDLERS FUNCTIONS:
  const login = useCallback(async (payload: LoginPayload): Promise<void> => {
    try {
      const response = await DataService.post('auth/login', payload)
      const { access_token, refresh_token, perfil_id } = response.data

      if (access_token) {
        Cookies.set(COOKIE_KEYS.ACCESS_TOKEN, access_token)
        Cookies.set(COOKIE_KEYS.REFRESH_TOKEN, refresh_token)
        Cookies.set(COOKIE_KEYS.LOGGED_IN, 'true')

        setUserAuthState({
          isAuthenticated: true,
          profileId: perfil_id,
        })
      }
    } catch (error: any) {
      console.log(error)
      notification.error({
        message: error?.response?.data.error || 'Ocorreu um erro no login. Por favor, tente novamente',
      })

      throw error
    }
  }, [])

  const loginAfterRegister = useCallback(
    async ({ access_token, refresh_token }: LoginAfterRegisterPayload): Promise<void> => {
      try {
        if (access_token) {
          Cookies.set(COOKIE_KEYS.ACCESS_TOKEN, access_token)
          Cookies.set(COOKIE_KEYS.REFRESH_TOKEN, refresh_token)
          Cookies.set(COOKIE_KEYS.LOGGED_IN, 'true')
          setUserAuthState((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            profileId: prevState.profileId || null,
            superProfile: prevState.superProfile || null,
          }))
        }
      } catch (error: any) {
        console.log(error)
        notification.error({
          message: error?.response?.data?.error || 'Ocorreu um erro no login. Por favor, tente novamente',
        })
        throw error
      }
    },
    [],
  )

  const resetPassword = useCallback(async (payload: string): Promise<void> => {
    try {
      const response = await DataService.post('auth/forgot-password', payload)
      notification.success({ message: response.data.msg })
      history.push('/')
    } catch (error: unknown) {
      console.error('Reset password error:', error)
      notification.error({
        message: 'Falha ao enviar o email para resetar senha. Por favor tente novamente.',
      })
    }
  }, [])

  const logout = useCallback(async (): Promise<void> => {
    try {
      const refreshToken = Cookies.get(COOKIE_KEYS.REFRESH_TOKEN)
      const accessToken = Cookies.get(COOKIE_KEYS.ACCESS_TOKEN)

      const logoutRequests = []

      if (refreshToken) {
        logoutRequests.push(
          axios.post(
            `${env.VITE_API_OLD_ENDPOINT}auth/logout`,
            {},
            { headers: { Authorization: `Bearer ${refreshToken}` } },
          ),
        )
      }

      if (accessToken) {
        logoutRequests.push(
          axios.post(
            `${env.VITE_API_OLD_ENDPOINT}auth/logout`,
            {},
            { headers: { Authorization: `Bearer ${accessToken}` } },
          ),
        )
      }

      if (logoutRequests.length > 0) {
        await Promise.all(logoutRequests)
      }
    } catch (error: unknown) {
      console.error('Logout error:', error)
    } finally {
      Object.values(COOKIE_KEYS).forEach((cookie) => Cookies.remove(cookie))
    }

    setUserAuthState({
      isAuthenticated: false,
      profileId: null,
      superProfile: 'false',
    })

    const queryKeys = [{ queryKey: ['USER_ME'] }, { queryKey: ['userMe'] }]

    queryKeys.forEach((key) => {
      queryClient.invalidateQueries(key)
      queryClient.removeQueries(key)
    })
  }, [])

  const values = {
    userAuthState,
    login,
    logout,
    resetPassword,
    loginAfterRegister,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
