import { Col, Row } from "antd";
import BaseCard from "../../../components/card/BaseCard";
import DonationTable from "../../../components/tables/donations/DonationTable";
import moment from "moment";
import { DonationsListCards } from "./components/donations-list-cards";

export function DonationsList() {
  return (
    <>
      {/* CARDS */}
      <DonationsListCards />

      {/* TABLE */}
      <Col md={24}>
        <BaseCard title="Lista de Doações">
          <DonationTable
            visibleColumns={['id', 'recorrencia', 'cliente', 'data', 'valor', 'status', 'campanha', 'acoes']}
            visibleFilters={[
              'nome',
              'campanha',
              'status',
              'data',
              'forma_pagamento',
              'tipo_doacao',
              'gateway',
              'codigo_referencia',
              'transaction_id',
              'recorrencia',
            ]}
            defaultFilters={{
              status: '1', // Pagos
              data_inicial: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
              data_final: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            }}
          />
        </BaseCard>
      </Col>
    </>
  );
}
