import { GoldenHeader } from '../../../components/golden-header/styles'
import { ThirdSectionStyled } from './styles'

export function ThirdSection() {
  return (
    <ThirdSectionStyled>
      <>
        <GoldenHeader className="header">QUERIDO(A) GUERREIRO(A),</GoldenHeader>
        <p>
          Se você está lendo esta mensagem, não é por acaso. Deus tem manifestado Seu amor na sua vida através do
          Exército de São Miguel, e agora Ele o chama para uma missão ainda mais especial: tornar-se um General Oficial
          desta família de oração. 
        </p>
      </>
    </ThirdSectionStyled>
  )
}
