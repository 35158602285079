import React from 'react'
import { Layout } from 'antd'
import { StandardView } from './StandardView'
import { useAdminContext } from '../../context/AdminLayout'
import { MobileView } from './MobileView'

export const AdminLayout = ({ children }) => {
  const { isMobile } = useAdminContext()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isMobile ? <MobileView>{children}</MobileView> : <StandardView>{children}</StandardView>}
    </Layout>
  )
}
