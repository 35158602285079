import DOMPurify from 'dompurify'
import moment from 'moment'
import debounce from 'lodash.debounce'
import FeatherIcon from 'feather-icons-react'
import { countries } from './allCountries'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`
}

const dateParse = (dateString) => {
  const dataDoBanco = new Date(dateString)
  const dia = dataDoBanco.getUTCDate().toString().padStart(2, '0')
  const mes = (dataDoBanco.getUTCMonth() + 1).toString().padStart(2, '0')
  const ano = dataDoBanco.getUTCFullYear()

  return `${dia}/${mes}/${ano}`
}

function getDaysBetweenDates(initialDate, endDate) {
  const oneDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day

  // Convert the date strings to Date objects
  const startDateObj = new Date(initialDate)
  const endDateObj = new Date(endDate)

  if (startDateObj >= endDateObj) return 0

  // Calculate the time difference between the dates in days
  const timeDifference = Math.round(Math.abs((startDateObj - endDateObj) / oneDay))

  return timeDifference
}

function formatBoletoCode(code) {
  return code?.replace(/(\d{5})(\d{5})(\d{6})(\d)(\d{14})/, '$1.$2 $3.$4$5 ')
}

const validateDateRange = (_, value) => {
  if (!value) {
    return Promise.resolve()
  }

  const selectedDate = moment(value, 'DD/MM/YYYY', false)
  const currentDate = moment()
  const minYear = 1900
  const minValidDate = moment().subtract(10, 'years')

  if (!selectedDate.isValid()) {
    return Promise.reject(new Error('Data inválida'))
  }

  if (selectedDate.year() < minYear) {
    return Promise.reject(new Error('O ano não pode ser menor que 1900'))
  }

  if (selectedDate.isAfter(currentDate, 'day')) {
    return Promise.reject(new Error('A data não pode ser maior que a data atual'))
  }

  if (selectedDate.isAfter(minValidDate, 'day')) {
    return Promise.reject(new Error('Idade mínima insuficiente'))
  }

  return Promise.resolve()
}

export const validateDate = (value) => {
  if (!value) {
    return Promise.resolve()
  }

  const numericValue = value.replace(/\D/g, '')
  if (numericValue.length < 8) {
    return Promise.reject(new Error('Por favor, insira todos os dígitos.'))
  }

  const formattedDate = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4)}`

  const isValidDate = moment(formattedDate, 'DD/MM/YYYY', true).isValid()
  if (!isValidDate) {
    return Promise.reject(new Error('Por favor, insira uma data válida!'))
  }

  const year = moment(formattedDate, 'DD/MM/YYYY').year()
  const currentYear = moment().year()
  if (year < 1900 || year > currentYear) {
    return Promise.reject(new Error('Ano inválido! Por favor, insira um ano entre 1900 e o ano atual.'))
  }

  return Promise.resolve()
}

const handleCpfShowValues = (numbers) => {
  const formattedInput = String(numbers)?.replace(/\D/g, '')
  const regex = /(\d{3})(\d{3})(\d{3})(\d{2})/
  return formattedInput.replace(regex, '$1.$2.$3-$4')
}

const handlePhoneNumberFormat = (numbers) => {
  const formattedInput = String(numbers)?.replace(/\D/g, '')
  const regex = /(\d{2})(\d{5})(\d{4})/
  return formattedInput.replace(regex, '($1) $2-$3')
}

export const handleCepAutofill = (numbers) => {
  const formattedCep = numbers.replace(/(\d{5})(\d{3})/, '$1-$2')
  return formattedCep
}

const formatCurrencyBR = (value) => {
  if (typeof value !== 'number') {
    throw new Error('Input must be a number')
  }

  const formattedValue = value.toFixed(2).replace('.', ',')
  const parts = formattedValue.split(',')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return `R$ ${parts.join(',')}`
}

const validateDateInputCreditCard = (rule, value, callback) => {
  if (!value) {
    callback()
  } else {
    const parts = value.split('/')
    const month = parseInt(parts[0], 10)
    if (month < 1 || month > 12) {
      callback('O mês deve estar entre 01 e 12.')
    } else {
      callback()
    }
  }
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const sanitizeHTML = (html) => {
  return { sanitize: DOMPurify.sanitize(html) }
}

const clearImagesUrl = (img) => {
  if (!img) {
    return []
  }

  const partes = img?.split('/')
  return partes[partes.length - 1]
}

const getNumber = (str) => {
  const regex = /\d+/g
  return str?.match(regex)[0]
}

const handleNumber = (valor) => {
  const valorSemZeros = valor?.replace(/^0+/, '')

  const parteInteira = valorSemZeros?.slice(0, -2)
  const parteDecimal = valorSemZeros?.slice(-2)

  const valorFormatado = `${parteInteira},${parteDecimal}`
  return valorFormatado
}

const validateCPF = (_, value, callback) => {
  if (!value) {
    callback('Obrigatório')
  }

  if (value) {
    const cpf = value.replace(/[^\d]/g, '')

    if (cpf.length !== 11) {
      callback('CPF deve conter 11 dígitos')
    }

    if (/^(\d)\1{10}$/.test(cpf)) {
      callback('CPF inválido')
    }

    let sum = 0
    for (let i = 0; i < 9; i += 1) {
      sum += parseInt(cpf.charAt(i)) * (10 - i)
    }
    let remainder = 11 - (sum % 11)
    const firstDigit = remainder >= 10 ? 0 : remainder

    if (parseInt(cpf.charAt(9)) !== firstDigit) {
      callback('CPF inválido')
    }

    sum = 0
    for (let i = 0; i < 10; i += 1) {
      sum += parseInt(cpf.charAt(i)) * (11 - i)
    }
    remainder = 11 - (sum % 11)
    const secondDigit = remainder >= 10 ? 0 : remainder

    if (parseInt(cpf.charAt(10)) !== secondDigit) {
      callback('CPF inválido')
    }

    callback()
  }
}

export const validateCNPJ = (_, value) => {
  if (value && value.replace(/\D/g, '').length !== 14) {
    return Promise.reject(new Error('CNPJ deve conter 14 dígitos.'))
  }
  return Promise.resolve()
}

const validateTelefone = (_, telefone) => {
  const normalizedTelefone = telefone ? telefone.replace(/\D/g, '') : ''
  return new Promise((resolve, reject) => {
    if (!telefone) {
      reject(new Error(''))
    } else if (normalizedTelefone.length < 11) {
      reject(new Error('Mínimo de 11 dígitos'))
    } else {
      resolve()
    }
  })
}

function formatToBrazilianCurrency(data) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(data)) {
    return 'Erro...'
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(data)
}

function formatNumber(data) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(data)) {
    return '...'
  }

  return new Intl.NumberFormat('pt-BR').format(data)
}

const formatDateTimeToBrazilian = (date) => {
  // Verifica se a data é uma string e converte para objeto Date
  const dateObj = typeof date === 'string' ? new Date(date) : date

  // Extrai o dia, mês e ano
  const day = dateObj.getDate().toString().padStart(2, '0')
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
  const year = dateObj.getFullYear()

  // Extrai as horas, minutos e segundos.
  const hours = dateObj.getHours().toString().padStart(2, '0')
  const minutes = dateObj.getMinutes().toString().padStart(2, '0')
  const seconds = dateObj.getSeconds().toString().padStart(2, '0')

  // Retorna a data e hora no formato dd/mm/yyyy hh:mm:ss
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

const handleFilterChangeAndPagination = ({ setFilters }, defaultDebounceDelay = 0) => {
  const updateFilters = (prevFilters, values, resetPage = true) => {
    const updatedFilters = { ...prevFilters, ...values }
    if (resetPage) updatedFilters.page = 1

    const { queryString, ...filtersWithoutQueryString } = updatedFilters
    const newQueryString = Object.entries(filtersWithoutQueryString)
      .filter(([, v]) => v !== undefined && v !== null && v !== '')
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&')

    const newUrl = `${window.location.pathname}?${newQueryString}`
    window.history.replaceState(null, '', newUrl)

    return { ...updatedFilters, queryString: newQueryString }
  }

  const debouncedFilterChange = debounce((filtersValues) => {
    setFilters((prevFilters) => updateFilters(prevFilters, filtersValues))
  }, defaultDebounceDelay)

  const handleChange = (values, isPagination = false) => {
    setFilters((prevFilters) => updateFilters(prevFilters, values, !isPagination))

    if (!isPagination) {
      debouncedFilterChange(values)
    }
  }

  return handleChange
}

export function loadQueries({ location, filters, setFilters }) {
  const searchParams = new URLSearchParams(location.search)
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value
  })

  const newFilters = {
    ...filters,
    ...params,
    queryString: searchParams.toString(),
  }

  setFilters(newFilters)
}

export function useLoadQueryStringFromUrl({ filters, setFilters }) {
  const location = useLocation()

  useEffect(() => {
    loadQueries({ location, filters, setFilters })
  }, [])
}

const generateQueryString = (filters) => {
  return Object.entries(filters)
    .filter(([, v]) => v)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&')
}

const hexToRGB = (hex) => {
  // const r = parseInt(hex.slice(1, 3), 16);
  // const g = parseInt(hex.slice(3, 5), 16);
  // const b = parseInt(hex.slice(5, 7), 16);

  // return `${r}, ${g}, ${b}`;
  return hex
}

const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = parseInt(((R * (100 + percent)) / 100).toString())
  G = parseInt(((G * (100 + percent)) / 100).toString())
  B = parseInt(((B * (100 + percent)) / 100).toString())

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)

  return `#${RR}${GG}${BB}`
}

const hexToRgba = (hex, opacity = 1) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const getHexFromCssVar = (varName) => {
  const hex = getComputedStyle(document.documentElement).getPropertyValue(varName).trim()
  return hex
}

const defineColorBySeverity = (severity, opacity = 1) => {
  const colorVarName = `--${severity}-color`
  const hexColor = getHexFromCssVar(colorVarName)

  if (!hexColor) {
    return hexToRgba(getHexFromCssVar('--info-color'), opacity)
  }

  return hexToRgba(hexColor, opacity)
}

const addOpacity = (color, opacity) => {
  const hexToRgb = (hex) => {
    let r = 0,
      g = 0,
      b = 0

    // Remover o '#' se presente
    hex = hex.replace(/^#/, '')

    // Parsing dos valores hexadecimais
    if (hex.length === 6) {
      r = parseInt(hex.substr(0, 2), 16)
      g = parseInt(hex.substr(2, 2), 16)
      b = parseInt(hex.substr(4, 2), 16)
    }

    return { r, g, b }
  }

  const { r, g, b } = hexToRgb(color)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const mapBadgeStatus = (status) => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info'
  }
  return status
}

const handleUnpaidRecurrenceStatus = (status) => {
  switch (status) {
    case 1:
      return <FeatherIcon icon="check-circle" fill="var(--success-color)" />
    case 0:
      return <FeatherIcon icon="loader" fill="red" />
    default:
      return <FeatherIcon icon="x-circle" fill="var(--error-color)" />
  }
}

const getFirstAndSecondName = (name, css = true) => {
  const nameParts = name.trim().split(' ')
  const firstName = nameParts[0]
  const secondName = nameParts.length > 1 ? nameParts[1] : ''
  if (css) {
    return `${firstName} \\A ${secondName}`
  }

  return `${firstName}\n${secondName}`
}

const getFirstAndLastName = (name, css = true) => {
  const nameParts = name.trim().split(' ')
  const firstName = nameParts[0]
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : ''
  if (css) {
    return `${firstName} \\A ${lastName}`
  }
  return `${firstName}\n${lastName}`
}

function getInitials(fullName) {
  const nameParts = fullName.split(' ')

  const firstInitial = nameParts[0] ? nameParts[0][0] : ''
  const secondInitial = nameParts[1] ? nameParts[1][0] : ''

  return (firstInitial + secondInitial).toUpperCase()
}

function manageChatWidget(location) {
  const forbiddenPaths = ['/admin', '/landingpage', '/embed', '/membro-exercito']
  const isForbidden = forbiddenPaths.some((path) => location.pathname.startsWith(path))
  const mdChatDiv = document.getElementsByClassName('md-chat-widget-btn-container')[0]

  if (isForbidden) {
    if (mdChatDiv) mdChatDiv.remove()

    const script1 = document.querySelector('script[src="https://chat.movidesk.com/Scripts/chat-widget.min.js"]')
    const script2 = Array.from(document.getElementsByTagName('script')).find((script) =>
      script.innerHTML.includes('var mdChatClient="7DAC4C88220B47A38570DAC7896D6688";'),
    )

    if (script1) document.body.removeChild(script1)
    if (script2) document.body.removeChild(script2)
  } else if (!mdChatDiv) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `var mdChatClient="7DAC4C88220B47A38570DAC7896D6688";`
    document.body.appendChild(script)

    const script2 = document.createElement('script')
    script2.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js'
    document.body.appendChild(script2)
  }
}

const phoneWithoutMask = (telefone) => {
  return telefone.replace(/[^\d]/g, '')
}

function convertBase64ToFile(base64) {
  if (!base64) return

  const matches = base64.match(/^data:(.+);base64,(.+)$/)

  if (!matches || matches.length < 3) {
    throw new Error('Invalid base64 format.')
  }

  const type = matches[1]
  const data = matches[2]
  const size = (data.length * 3) / 4 - (data.match(/==$/) || []).length

  return {
    lastModified: Date.now(),
    name: 'imagem.png',
    size: Math.round(size),
    type,
    uid: `rc-upload-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
    webkitRelativePath: '',
  }
}

function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString())
      } else {
        reject(new Error('Failed to read file as Base64'))
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

const formatDateToUs = (date) => {
  // format from dd/mm/yyyy to yyyy-mm-dd
  if (!date) return date

  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}

export const formatCEP = (cep) => {
  return cep?.length === 8 ? `${cep.slice(0, 5)}-${cep.slice(5)}` : cep
}

const escolaridadeOptions = [
  { label: 'Ensino Fundamental Incompleto', value: 'fundamental incompleto' },
  { label: 'Ensino Fundamental Completo', value: 'fundamental completo' },
  { label: 'Ensino Médio Incompleto', value: 'medio incompleto' },
  { label: 'Ensino Médio Completo', value: 'medio completo' },
  { label: 'Ensino Superior Incompleto', value: 'superior incompleto' },
  { label: 'Ensino Superior Completo', value: 'superior completo' },
  { label: 'Pós-Graduação', value: 'pos graduacao' },
  { label: 'Mestrado', value: 'mestrado' },
  { label: 'Doutorado', value: 'doutorado' },
]

export const validateDateOfBirth = (value) => {
  const numericValue = value.replace(/\D/g, '')

  if (!value || numericValue.length < 8) {
    return Promise.reject('Por favor, insira todos os dígitos.')
  }

  const formattedDate = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4)}`

  const isValidDate = moment(formattedDate, 'DD/MM/YYYY', true).isValid()
  if (!isValidDate) {
    return Promise.reject('Por favor, insira uma data válida!')
  }

  const year = moment(formattedDate, 'DD/MM/YYYY').year()
  const currentYear = moment().year()
  if (year < 1900 || year > currentYear) {
    return Promise.reject('Ano inválido! Por favor, insira um ano entre 1900 e o ano atual.')
  }

  return Promise.resolve()
}

export const validatePassword = (_, value) => {
  if (!value) {
    return Promise.resolve()
  }

  const rules = [
    { regex: /[A-Z]/, message: 'A senha deve conter pelo menos uma letra maiúscula.' },
    { regex: /[a-z]/, message: 'A senha deve conter pelo menos uma letra minúscula.' },
    { regex: /\d/, message: 'A senha deve conter pelo menos um número.' },
    {
      regex: /[@$!%*#?&]/,
      message: 'A senha deve conter pelo menos um caractere especial. @$!%*#?&',
    },
    { regex: /.{8,}/, message: 'A senha deve ter no mínimo 8 caracteres.' },
    { regex: /^.{1,16}$/, message: 'A senha deve ter no máximo 16 caracteres.' },
  ]

  const failingRule = rules.find((rule) => !rule.regex.test(value))
  return failingRule ? Promise.reject(failingRule.message) : Promise.resolve()
}

function baixarImagem(imageUrl) {
  const link = document.createElement('a')

  link.href = imageUrl

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

async function baixarImagensSequencialmente(imagens) {
  for (const imagem of imagens) {
    const imageUrl = imagem

    baixarImagem(imageUrl)

    await new Promise((resolve) => setTimeout(resolve, 500))
  }
}

function formatTelefone(telefone) {
  if (!telefone) return ''

  const somenteNumeros = telefone.replace(/\D/g, '')

  if (somenteNumeros.length === 11) {
    return somenteNumeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  }

  return telefone
}

function compressImage(file, quality = 0.7) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target.result

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const maxWidth = 800
        const scale = maxWidth / img.width
        canvas.width = maxWidth
        canvas.height = img.height * scale

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(
          (blob) => {
            resolve(new File([blob], file.name, { type: 'image/jpeg' }))
          },
          'image/jpeg',
          quality,
        )
      }
    }

    reader.onerror = (error) => reject(error)
  })
}

function getLabelFromCountryValue(pais) {
  return countries.filter((country) => country.value === pais)[0]?.label ?? pais
}

export {
  ellipsis,
  dateParse,
  getDaysBetweenDates,
  formatBoletoCode,
  validateDateRange,
  handleCpfShowValues,
  handlePhoneNumberFormat,
  formatCurrencyBR,
  validateDateInputCreditCard,
  getBase64,
  sanitizeHTML,
  getNumber,
  clearImagesUrl,
  handleNumber,
  validateCPF,
  formatNumber,
  formatToBrazilianCurrency,
  formatDateTimeToBrazilian,
  handleFilterChangeAndPagination,
  generateQueryString,
  validateTelefone,
  hexToRGB,
  shadeColor,
  defineColorBySeverity,
  addOpacity,
  hexToRgba,
  getHexFromCssVar,
  handleUnpaidRecurrenceStatus,
  getFirstAndSecondName,
  getFirstAndLastName,
  getInitials,
  manageChatWidget,
  phoneWithoutMask,
  convertBase64ToFile,
  convertFileToBase64,
  formatDateToUs,
  escolaridadeOptions,
  baixarImagem,
  baixarImagensSequencialmente,
  formatTelefone,
  compressImage,
  getLabelFromCountryValue,
}
