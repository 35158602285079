import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { closeUpdateModal } from '../Modals/updateUserDataModalSlice';
import { closeModals } from '../Modals/authModalsSlice';
import { Checkbox } from 'antd';

export const AcceptTerms = ({ onChange, checked }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  function closeAllModals() {
    dispatch(closeUpdateModal());
    dispatch(closeModals());
  }

  return (
    <CheckBoxContainer>
      <Checkbox checked={checked} onChange={onChange} />
      <div className="checkbox">
        Ao prosseguir significa que você concorda com nossos
        <br />
        <span
          className="underline"
          onClick={() => {
            closeAllModals();
            history.push('/benfeitor/termos-de-uso');
          }}
        >
          Termos de Serviços
        </span>{' '}
        e{' '}
        <span
          className="underline"
          onClick={() => {
            closeAllModals();
            history.push('/benfeitor/politicas-de-privacidade');
          }}
        >
          Políticas de Privacidade.
        </span>
      </div>
    </CheckBoxContainer>
  );
};


export const CheckBoxContainer = styled.div`
  display: flex;
  gap: 1rem;

  .checkbox {
    color: #727272 !important;
    font-weight: 500;

    .underline {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }
  }
`
