import styled from 'styled-components'
import { getHexFromCssVar, hexToRgba } from '../../../utility/utility'

export const Container = styled.div`
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-weight: 800;

  .ant-progress-circle-path {
    stroke: ${() => {
      const textColor = getHexFromCssVar('--stroke-color')
      return hexToRgba(textColor)
    }} !important;
  }
  .ant-progress-circle-trail {
    stroke: ${() => {
      const textColor = getHexFromCssVar('--trail-color')
      return hexToRgba(textColor)
    }};
  }
`
