import React from 'react'
import { Col, Row } from 'antd'
import BaseButton from '../../../button/BaseButton'

export default function BaseButtonsGroup({
  className,
  onCancel,
  loading,
  cancelText,
  submitText,
  ...props
}) {
  return (
    <Row className={className} gutter={[10, 10]} wrap={false}>
      <Col span={12}>
        <BaseButton block type="ghost" onClick={onCancel} {...props}>
          {cancelText}
        </BaseButton>
      </Col>
      <Col span={12}>
        <BaseButton
          block
          type="primary"
          loading={loading}
          htmlType="submit"
          {...props}
        >
          {submitText}
        </BaseButton>
      </Col>
    </Row>
  )
}
