import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { VocacionalMasculino } from '../../container/admin/pages/vocacional/vocacional-masculino'
import { VocacionalFeminino } from '../../container/admin/pages/vocacional/vocacional-feminino'

export function VocacionalRoutesAdmin() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/masculino`} component={VocacionalMasculino} />
      <Route exact path={`${path}/feminino`} component={VocacionalFeminino} />
    </Switch>
  )
}
