import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface EnderecosBeneficiariosCicloDoacaoProps {
  fk_instancia_acao_agape_id: number
}

export interface EnderecosBeneficiariosCicloDoacaoResponse {
  beneficiarios: {
    latitude: number
    latitude_nordeste: number
    latitude_sudoeste: number
    longitude: number
    longitude_nordeste: number
    longitude_sudoeste: number
    nome_familia: string
  }[]
  ciclo_acao_agape: {
    latitude: number
    latitude_nordeste: number
    latitude_sudoeste: number
    longitude: number
    longitude_nordeste: number
    longitude_sudoeste: number
  }
}

export function useGetEnderecosBeneficiariosCicloDoacao({
  fk_instancia_acao_agape_id,
}: EnderecosBeneficiariosCicloDoacaoProps) {
  const enderecosBeneficarios = useQuery({
    queryKey: ['ENDERECOS_BENEFICIARIOS_CICLO_DOACAO', fk_instancia_acao_agape_id],
    queryFn: async () => {
      const response = await DataService.get<EnderecosBeneficiariosCicloDoacaoResponse>(
        `/agape/listar-geolocalizacoes-beneficiarios-ciclo-acao-agape/${fk_instancia_acao_agape_id}`,
      )

      return response.data
    },
  })

  return enderecosBeneficarios
}
