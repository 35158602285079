import { Card as AntCard } from 'antd'
import styled from 'styled-components'

export const Card = styled(AntCard)`
  display: ${(props) => (props.isLoading ? 'block' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 0;
  color: var(--text-main-color);
  background-color: var(--card-bg-color);
  border: none;
  border-radius: 20px;
  /* box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2); */

  &.ant-card {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  strong {
    font-size: 1.2rem;
  }

  .text-secondary-color {
    color: var(--text-secondary-color);
  }

  .skeleton-details-wrapper {
    gap: 15px;
  }

  .ant-skeleton-element {
    height: 15px;
  }

  .ant-card-body {
    display: flex;
    gap: 1rem;
    color: var(--text-main-color);
    padding: 1rem 0.8rem;
  }

  .increase-info {
    color: var(--positive-balance-color);
  }

  .negative-info {
    color: var(--negative-balance-color);
  }
`
