import { motion, PanInfo, useMotionValue } from 'motion/react'
import { useRef, useState } from 'react'
import { CarouselContainer, SliderWrapper } from '../../styles'
import { CarouselCards } from './components/carousel-cards'
import { CarouselChart } from './components/carousel-chart'
import { CarouselTabButtons } from './components/carousel-tab-buttons'
import { useAutoSlideEffect } from './hooks/use-auto-slide-effect'
import { SlideProps } from './types'
import { handleDragEnd } from './utils'

export function AnimatedCarousel({ slides, type }: Readonly<{ slides: SlideProps[], type: 'finance' | 'signup' }>) {
  const [activeTab, setActiveTab] = useState<number>(0)

  const x = useMotionValue(0)
  const dragConstraints = useRef(null)

  useAutoSlideEffect({ setActiveTab, slides, activeTab })

  return (
    <SliderWrapper>
      <motion.div ref={dragConstraints} style={{ overflow: 'visible' }}>
        <motion.div
          drag="x"
          dragConstraints={dragConstraints}
          dragElastic={0.2}
          onDragEnd={(_, info: PanInfo) => handleDragEnd({ _, info, activeTab, setActiveTab, slides })}
          style={{ x }}
        >
          <CarouselContainer>
            <div className="content-wrapper">
              <CarouselChart activeTab={activeTab} slides={slides} type={type} />
              <CarouselCards activeTab={activeTab} slides={slides} type={type} />
            </div>
          </CarouselContainer>
        </motion.div>
      </motion.div>
      <CarouselTabButtons activeTab={activeTab} setActiveTab={setActiveTab} slides={slides} />
    </SliderWrapper>
  )
}



