import React from 'react'
import Barcode from 'react-barcode'
import './styleboleto.css'
import { useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import logoitau from './logo-itau-256.png'
import { handleNumber } from '../../../../utility/utility'
import { useLocation } from 'react-router-dom'

export const RenderBoleto = () => {
  const location = useLocation()
  const data = location?.state?.boletoData

  return (
    <div id="containerb">
      <Row>
        <Col md={7} className="payment-data-card" style={{ width: '194px' }}>
          <img src={`data:image/jpeg;base64,${data.dados_qrcode.base64}`} width={140} alt="QR Code" />
        </Col>
        <Col md={17} className="payment-title-card" style={{ width: '450px' }}>
          <p />
          <p>Abra o aplicativo do seu banco, escolha a opção de pagar com PIX e aponte a câmera para o QR Code.</p>
          <p>Em caso de análise, o pagamento via PIX pode levar até 60 minutos para ser confirmado.</p>
        </Col>
      </Row>
      <div id="boleto-gera">
        <div className="boleto">
          <table className="header" cellSpacing="0" cellPadding="0">
            <tr>
              <td className="logoBanco">
                <img width={60} src={logoitau} alt="Itaú" />
              </td>
              <td className="codBanco">
                <div>341-7</div>
              </td>
              <td className="linhaDigitavel">{data.dado_boleto?.dados_individuais_boleto[0].numero_linha_digitavel}</td>
            </tr>
          </table>
          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="cedente">Cedente</td>
              <td className="agCedente">Agência / Código do Cedente</td>
              <td className="especie">Espécie</td>
              <td className="qtd">Quantidade</td>
              <td className="nossoNumero">Nosso número</td>
            </tr>
            <tr className="campos">
              <td className="cedente">{data.beneficiario.nome_cobranca}</td>
              <td className="agCedente">3827 / 99864-6</td>
              <td className="especie">R$</td>
              <td className="qtd"> </td>
              <td className="nossoNumero">{data.dado_boleto?.dados_individuais_boleto[0].numero_nosso_numero}</td>
            </tr>
          </table>
          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="numDoc">Número do documento</td>
              <td className="contrato">Contrato</td>
              <td className="cpfCnpj">CPF/CEI/CNPJ</td>
              <td className="vencimento">Vencimento</td>
              <td className="valorDoc">Valor documento</td>
            </tr>
            <tr className="campos">
              <td className="numDoc">{data.dado_boleto?.dados_individuais_boleto[0].dac_titulo}</td>
              <td className="contrato"> </td>
              <td className="cpfCnpj">{data.beneficiario.tipo_pessoa.numero_cadastro_nacional_pessoa_juridica}</td>
              <td className="vencimento">{data.dado_boleto?.dados_individuais_boleto[0].data_vencimento}</td>
              <td className="valorDoc">{handleNumber(data.dado_boleto?.dados_individuais_boleto[0].valor_titulo)}</td>
            </tr>
          </table>
          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="desconto">(-) Desconto / Abatimento</td>
              <td className="outrasDeducoes">(-) Outras deduções</td>
              <td className="moraMulta">(+) Mora / Multa</td>
              <td className="outrosAcrescimos">(+) Outros acréscimos</td>
              <td className="valorCobrado">(=) Valor cobrado</td>
            </tr>
            <tr className="campos">
              <td className="desconto">&nbsp;</td>
              <td className="outrasDeducoes">&nbsp;</td>
              <td className="moraMulta">&nbsp;</td>
              <td className="outrosAcrescimos">&nbsp;</td>
              <td className="valorCobrado">&nbsp;</td>
            </tr>
          </table>
          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="sacado">Sacado</td>
            </tr>
            <tr className="campos">
              <td className="sacado">{data.dado_boleto.pagador.pessoa.nome_pessoa}</td>
            </tr>
          </table>
          <div className="footer">
            <p>Autenticação mecânica</p>
          </div>
          <div className="cut">
            <p>Corte na linha pontilhada</p>
          </div>
          <table className="header" cellSpacing="0" cellPadding="0">
            <tr>
              <td className="logoBanco">
                <img width={60} src={logoitau} alt="teste" />
              </td>
              <td className="codBanco">
                <div>341-7</div>
              </td>
              <td className="linhaDigitavel">{data.dado_boleto?.dados_individuais_boleto[0].numero_linha_digitavel}</td>
            </tr>
          </table>
          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="localPgto">Local de pagamento</td>
              <td className="vencimento2">Vencimento</td>
            </tr>
            <tr className="campos">
              <td className="localPgto">QUALQUER BANCO ATÉ O VENCIMENTO</td>
              <td className="vencimento2">
                {data.beneficiario.dado_boleto?.dados_individuais_boleto[0].data_vencimento}
              </td>
            </tr>
          </table>

          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="cedente2">Cedente</td>
              <td className="agCedente2">Agência/Código cedente</td>
            </tr>
            <tr className="campos">
              <td className="cedente2">{data.beneficiario.nome_cobranca}</td>
              <td className="agCedente2">3827 / 99864-6</td>
            </tr>
          </table>

          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="dataDoc">Data do documento</td>
              <td className="numDoc2">No. documento</td>
              <td className="especieDoc">Espécie doc.</td>
              <td className="aceite">Aceite</td>
              <td className="dataProcess">Data process.</td>
              <td className="nossoNumero2">Nosso número</td>
            </tr>
            <tr className="campos">
              <td className="dataDoc">{data.dado_boleto?.data_emissao}</td>
              <td className="numDoc2"> </td>
              <td className="especieDoc">R$</td>
              <td className="aceite"> </td>
              <td className="dataProcess">{data.dado_boleto?.data_emissao}</td>
              <td className="nossoNumero2">{data.dado_boleto?.dados_individuais_boleto[0].numero_nosso_numero}</td>
            </tr>
          </table>

          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="reservado">Uso do banco</td>
              <td className="carteira">Carteira</td>
              <td className="especie2">Espécie</td>
              <td className="qtd2">Quantidade</td>
              <td className="xvalor">Valor</td>
              <td className="valorDoc">(=) Valor documento</td>
            </tr>
            <tr className="campos">
              <td className="reservado">&nbsp;</td>
              <td className="carteira">{data.dado_boleto?.codigo_carteira}</td>
              <td className="especie2">{data.dado_boleto?.codigo_especie}</td>
              <td className="qtd2"> </td>
              <td className="xvalor"> </td>
              <td className="valorDoc">{handleNumber(data.dado_boleto?.dados_individuais_boleto[0].valor_titulo)}</td>
            </tr>
          </table>

          <table className="line" cellSpacing="0" cellPadding="0">
            <tr>
              <td className="lastLine" rowSpan="6">
                <table className="line" cellSpacing="0" cellPadding="0">
                  <tr className="titulos">
                    <td className="instrucoes">Instruções (Texto de responsabilidade do cedente)</td>
                  </tr>
                  <tr className="campos">
                    <td className="instrucoes" rowSpan="5">
                      <p>
                        Pague até a data de vencimento: O boleto deve ser pago até a data indicada para evitar multas e
                        juros.
                      </p>
                      <p>
                        Use o código de barras: Utilize o código de barras para efetuar o pagamento em caixas
                        eletrônicos, internet banking ou aplicativos de pagamento.
                      </p>
                      <p>
                        Dúvidas? Entre em contato: Em caso de dúvidas ou problemas, entre em contato conosco pelos meios
                        de contato fornecidos.
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="line" cellSpacing="0" cellPadding="0">
                  <tr className="titulos">
                    <td className="desconto2">(-) Desconto / Abatimento</td>
                  </tr>
                  <tr className="campos">
                    <td className="desconto2">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <table className="line" cellSpacing="0" cellPadding="0">
                  <tr className="titulos">
                    <td className="outras_deducoes2">(-) Outras deduções</td>
                  </tr>
                  <tr className="campos">
                    <td className="outrasDeducoes2">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="line" cellSpacing="0" cellPadding="0">
                  <tr className="titulos">
                    <td className="moraMulta2">(+) Mora / Multa</td>
                  </tr>
                  <tr className="campos">
                    <td className="moraMulta2">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <table className="line" cellSpacing="0" cellPadding="0">
                  <tr className="titulos">
                    <td className="outrosAcrescimos2">(+) Outros Acréscimos</td>
                  </tr>
                  <tr className="campos">
                    <td className="outrosAcrescimos2">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td className="lastLine">
                <table className="line" cellSpacing="0" cellPadding="0">
                  <tr className="titulos">
                    <td className="valorCobrado2">(=) Valor cobrado</td>
                  </tr>
                  <tr className="campos">
                    <td className="valorCobrado2">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="sacado2">Sacado</td>
            </tr>
            <tr className="campos">
              <td className="sacado2">
                <p>{data.dado_boleto.pagador.pessoa.nome_pessoa}</p>
              </td>
            </tr>
          </table>

          <table className="line" cellSpacing="0" cellPadding="0">
            <tr className="titulos">
              <td className="sacadorAvalista" colSpan="2">
                Sacador/Avalista
              </td>
            </tr>
            <tr className="campos">
              <td className="sacadorAvalista">&nbsp;</td>
              <td className="codBaixa">Cód. baixa</td>
            </tr>
          </table>
          <div className="footer2">Autenticação mecânica - Ficha de Compensação</div>
          <div className="barcode">
            <div style={{ width: 600, textAlign: 'right' }}>
              <Barcode
                value={data.dado_boleto?.dados_individuais_boleto[0]?.codigo_barras}
                format="CODE128"
                classNameName="ss"
                displayValue={false}
                width={1.5}
                height={50}
              />
            </div>
          </div>
          <div className="cut">
            <p>Corte na linha pontilhada</p>
          </div>
        </div>
      </div>
    </div>
  )
}
