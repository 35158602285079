import React, { useState } from 'react';
import { Divider, Form } from 'antd';
import CreditCards, { Focused } from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { validateDateInputCreditCard } from '../../../../../../../utility/utility';
import { ContainerTitle, ContentContainer, FormContainer } from './styles';
import { StyledInput } from '../../../../../components/Inputs';
import { CpfCnpjInput } from '../CpfCnpjInput';

export interface ReactCreditCardDetails {
  cpf_cnpj: string;
  name: string;

  number: string;
  cvc: string;
  expiry: string;

  focused: Focused | '';
}

export function CreditCard() {
  const [formValues, setFormValues] = useState<ReactCreditCardDetails>({
    cpf_cnpj: '',
    name: '',
    number: '',
    cvc: '',
    expiry: '',
    focused: '',
  });

  const handleInputChange = (fieldName: keyof ReactCreditCardDetails, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <ContainerTitle>Dados do seu Cartão</ContainerTitle>
      <ContentContainer>
        <FormContainer layout="vertical">
          <div className="first-row">
            <CpfCnpjInput />

            <Form.Item
              className="name-item"
              name="name"
              label="Nome impresso"
              style={{ flex: '1' }}
              rules={[
                { min: 3, message: 'Mínimo 3 caracteres' },
                { required: true, message: 'Obrigatório' },
              ]}
            >
              <StyledInput
                border="2.5px solid #acacac"
                lineHeight={2.5}
                placeholder="Nome completo"
                onChange={(e) => handleInputChange('name', e.target.value)}
                onFocus={() => handleInputChange('focused', 'name')}
              />
            </Form.Item>
          </div>

          <Form.Item
            className="number-card-item"
            name="number"
            label="Número"
            rules={[
              { min: 16, message: 'Mínimo 16 caracteres' },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <ReactInputMask
              mask="9999 9999 9999 9999"
              maskChar={null}
              placeholder="0000 0000 0000 0000"
              onChange={(e) => handleInputChange('number', e.target.value)}
              onFocus={() => handleInputChange('focused', 'number')}
            >
              {(inputProps) => <StyledInput border="2.5px solid #acacac" lineHeight={2.5} {...inputProps} />}
            </ReactInputMask>
          </Form.Item>

          <Form.Item
            className="cvc-item"
            name="cvc"
            label="CVV"
            rules={[
              { min: 3, message: 'Mínimo 3 caracteres' },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <ReactInputMask
              mask="999"
              maskChar={null}
              placeholder="CVV"
              onChange={(e) => handleInputChange('cvc', e.target.value)}
              onFocus={() => handleInputChange('focused', 'cvc')}
            >
              {(inputProps) => <StyledInput border="2.5px solid #acacac" lineHeight={2.5} {...inputProps} />}
            </ReactInputMask>
          </Form.Item>

          <Form.Item
            className="expiry-item"
            name="expiry"
            label="Vencimento"
            rules={[
              {
                pattern: /^(0[1-9]|1[0-2])\/\d{2}$/,
                message: 'Formato inválido. Use MM/AA.',
              },
              { validator: validateDateInputCreditCard },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <ReactInputMask
              mask="99/99"
              maskChar={null}
              placeholder="MM/AA"
              onChange={(e) => handleInputChange('expiry', e.target.value)}
              onFocus={() => handleInputChange('focused', 'expiry')}
            >
              {(inputProps) => <StyledInput border="2.5px solid #acacac" lineHeight={2.5} {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </FormContainer>

        <div style={{ maxWidth: '700px' }}>
          <CreditCards
            locale={{ valid: 'válido até' }}
            placeholders={{ name: 'SEU NOME AQUI' }}
            name={formValues.name}
            number={formValues.number}
            cvc={formValues.cvc}
            expiry={formValues.expiry}
            focused={formValues.focused}
          />
        </div>
      </ContentContainer>
    </>
  );
}
