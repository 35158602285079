import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

type ExcluirMembroProps = {
  idMembro: number
}

export function useExcluirMembro() {
  const queryClient = useQueryClient()

  const excluirMembro = useMutation({
    mutationFn: async ({ idMembro }: ExcluirMembroProps) => {
      await DataService.delete(`agape/deletar-membro/${idMembro}`)
    },
    onSuccess: () => {
      notification.success({ message: 'Membro excluído com sucesso!' })
      queryClient.invalidateQueries({ queryKey: ['GET_MEMBROS_FAMILIA'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_FAMILIA'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao excluir o membro.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return excluirMembro
}
