import { useParams } from 'react-router-dom';
import { Title } from '../../../components/Title';
import { FichaVocacionalForm } from './FichaVocacionalForm';

type FichaVocacionalUseParams = {
  token: string;
};

export function FichaVocacional() {
  const { token } = useParams<FichaVocacionalUseParams>();

  return (
    <>
      <Title>Ficha Vocacional</Title>
      <FichaVocacionalForm token={token} />
    </>
  );
}
