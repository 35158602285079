import { Row } from 'antd'

import { AtualizarStatusVocacional } from '../../AtualizarStatus'
import { useState } from 'react'
import BaseButton from '../../../../../components/button/BaseButton'
import BaseModal from '../../../../../components/modal/BaseModal'

interface DetalhesPreCadastroProps {
  usuario_vocacional_id: number
}

export function DetalhesPreCadastro({ usuario_vocacional_id }: DetalhesPreCadastroProps) {
  const [isOpen, setIsOpen] = useState(false)

  function handleOpenModal() {
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Row justify="center">
        <BaseButton severity="info" onClick={handleOpenModal}>
          Atualizar status
        </BaseButton>
      </Row>

      <BaseModal title={<h3>Aprove ou recuse</h3>} open={isOpen} onCancel={handleOpenModal} footer={false} width={1000}>
        <AtualizarStatusVocacional
          listagem="pre-cadastro"
          usuario_vocacional_id={usuario_vocacional_id}
          acaoPosSucesso={handleOpenModal}
        />
      </BaseModal>
    </>
  )
}
