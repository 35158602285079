import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useFetchRecurrenceChart() {
  const recurrenceChart = useQuery({
    queryKey: ['RECURRENCE_CHART'],
    queryFn: async () => {
      const response = await DataService.get(`/admin/dash-board/donations/donations-mes-recorrentes`)

      return response?.data
    },
  })

  return recurrenceChart
}
