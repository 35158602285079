import { useEffect, useState } from 'react'
import { Form, UploadFile } from 'antd'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
// import { StepWrapper } from '../../../../../benfeitores/LandingPage/components/Steps/StepWrapper'
import { Title } from '../../../../components/Title'
import { useMultistepForm } from '../../../Carteira/Cadastro/hooks/useMultistepForm'
import { AddressForm } from '../../../Campaign/CampaignIdentification/components/AddressForm'
import { StepOneGeneral } from './components/StepOne'
import { StepsContent } from '../StepsContent'
import { StepsButtons } from '../StepsButtons'
import { useAuth } from '../../../../../../context/Auth'
import {
  RegisterGroupUserBodySchema,
  useRegisterGroupUser,
} from '../../../../../admin/hooks/users/Mutations/useRegisterGroupUser'
import { StepsProgress } from '../StepsProgress'
import { StepWrapper } from '../../embed/style'

export type ImageFile = UploadFile & {
  file: File
}

interface GeneralStepFormProps {
  markAsFinished: Function
  initialFields: Record<string, any>
}

export function GeneralStepForm({ markAsFinished, initialFields }: GeneralStepFormProps) {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState<ImageFile[]>([])

  const { logout } = useAuth()

  const { mutateAsync: registerGroupUser, isPending: isPendingGroupUser } = useRegisterGroupUser()

  const steps = [
    {
      title: <Title>Identificação</Title>,
      content: <StepOneGeneral imageUrl={imageUrl} setImageUrl={setImageUrl} initialFields={initialFields} />,
      icon: <UserOutlined />,
    },
    {
      title: <Title>Endereço</Title>,
      content: <AddressForm form={form} showBrazil={true} />,
      icon: <HomeOutlined />,
    },
  ]

  const { currentStep, next, prev, isFirstStep, isLastStep } = useMultistepForm(steps, form)

  async function handleFinish() {
    await form.validateFields()

    const formFields: RegisterGroupUserBodySchema['data'] = form.getFieldsValue([
      'nome',
      'email',
      'telefone',
      'quant_membros_grupo',
      'tempo_de_membro',

      'cep',
      'estado',
      'cidade',
      'bairro',
      'rua',
      'numero',
      'complemento',
    ])

    const formattedData: RegisterGroupUserBodySchema['data'] = {
      ...formFields,
      pais: 'brasil',
      brasileiro: true,
      telefone: formFields.telefone.replace(/\D/g, ''),
      cep: formFields?.cep.replace(/\D/g, ''),
    }

    try {
      await registerGroupUser({
        data: formattedData,
        image: imageUrl?.[0]?.file,
      })
      markAsFinished()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // this form is not for update, only register:
    logout()

    if (initialFields) {
      return form.setFieldsValue({
        ...initialFields,
        telefone: initialFields?.telefone?.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3'),
      })
    }
  }, [form])

  return (
    <StepWrapper>
      <StepsProgress steps={steps} currentStep={currentStep} />
      <StepsContent steps={steps} currentStep={currentStep} form={form} />
      <StepsButtons
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        next={next}
        prev={prev}
        handleFinish={handleFinish}
        isPending={isPendingGroupUser}
      />
    </StepWrapper>
  )
}
