import { Col, Row, Modal } from 'antd'
import BaseButton from '../../../../../../components/button/BaseButton'
import { useState } from 'react'
import { useEncerrarCicloDoacao } from '../../../../../../hooks/agape/mutations/useEncerrarCicloDoacao'
import { Beneficiados } from '../../../Beneficiados'
import { Link } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useGetPermissions } from '../../../../../../hooks/users/Queries/useGetSelfPermissions'
import { EditarCicloDoacao } from '../EditarCicloDoacao/EditarCicloDoacao'

const { confirm } = Modal

interface AcoesEmAndamentoProps {
  idCicloDoacao: number
  nomeAcao: string
}

export function AcoesEmAndamento({ idCicloDoacao, nomeAcao }: AcoesEmAndamentoProps) {
  const { data: permissions } = useGetPermissions()

  const { mutateAsync: encerrarCicloDoacao } = useEncerrarCicloDoacao()
  const [loadingRow, setLoadingRow] = useState<number | null>(null)

  const [isOpenBeneficiados, setIsOpenBeneficiados] = useState(false)

  function handleIsOpenBeneficiados() {
    setIsOpenBeneficiados((prev) => !prev)
  }

  async function handleEncerrarCicloDoacao() {
    try {
      setLoadingRow(idCicloDoacao)
      await encerrarCicloDoacao({ idCicloDoacao })
    } finally {
      setLoadingRow(null)
    }
  }

  const confirmarEncerrarCicloDoacao = () => {
    confirm({
      title: `Tem certeza que deseja encerrar o ciclo de doação para ${nomeAcao}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Sim',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        handleEncerrarCicloDoacao()
      },
    })
  }

  const [isOpenEditarCicloDoacao, setIsOpenEditarCicloDoacao] = useState(false)

  function handleIsOpenEditarCicloDoacao() {
    setIsOpenEditarCicloDoacao((prev) => !prev)
  }

  return (
    <>
      <Row justify="center" gutter={[10, 10]}>
        <Col>
          <Link to={`/admin/agape/assistencia/doacao/${idCicloDoacao}/${nomeAcao}`}>
            <BaseButton severity="success">Nova doação</BaseButton>
          </Link>
        </Col>

        {permissions?.permissoes.acao_doacao_agape.editar === 1 && (
          <Col>
            <BaseButton severity="error" onClick={confirmarEncerrarCicloDoacao} loading={loadingRow === idCicloDoacao}>
              Encerrar
            </BaseButton>
          </Col>
        )}
        <Col>
          <BaseButton severity="info" onClick={handleIsOpenBeneficiados}>
            Beneficiados
          </BaseButton>
        </Col>

        <Col>
          <BaseButton severity="warning" onClick={handleIsOpenEditarCicloDoacao}>
            Detalhes
          </BaseButton>
        </Col>
      </Row>

      <Beneficiados
        open={isOpenBeneficiados}
        handleIsOpenBeneficiados={handleIsOpenBeneficiados}
        idCicloDoacao={idCicloDoacao}
        nomeAcao={nomeAcao}
      />

      <EditarCicloDoacao
        open={isOpenEditarCicloDoacao}
        handleIsOpenAcaoDoacao={handleIsOpenEditarCicloDoacao}
        idCicloDoacao={idCicloDoacao}
        nomeAcao={nomeAcao}
        podeEditar={false}
      />
    </>
  )
}
