import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface RecorrenciasCanceladasCard {
  quantidade_canceladas: number
  valor_total_canceladas: number
}

export function useFetchCanceledRecurrenceCard() {
  const recurrenceCanceledCard = useQuery<RecorrenciasCanceladasCard>({
    queryKey: ['RECURRENCE_CANCELED_CARD'],
    queryFn: async () => {
      const response = await DataService.get<RecorrenciasCanceladasCard>(`/checkout/card-recorrencias-canceladas`)

      return response?.data
    },
  })

  return recurrenceCanceledCard
}
