import { useQuery } from '@tanstack/react-query'
import { ResponsiveRadar } from '@nivo/radar'
import styled from 'styled-components'
import {
  AnimatedBaseCard,
  ChartTitle,
  ErrorMessage,
  LoadingContainer,
  RadarContainer,
  TooltipContainer,
} from '../../../styles'
import { DataService } from '../../../../../../../lib/dataService'
import { formatNumber } from '../../../../../../../utility/utility'

export function SignupsCountPerHour() {
  const {
    data: usersByHours,
    isPending: isUsersByHoursPending,
    isError: isUsersByHoursError,
  } = useQuery({
    queryKey: ['usersByHours'],
    queryFn: () =>
      DataService.get(`admin/dash-board/users/users-by-hours`).then((response) => {
        const data = response.data.users_per_hour
        const formattedData = Object.entries(data).map(([hora, cadastros]) => ({ hora, Cadastros: cadastros }))
        return formattedData
      }),
    staleTime: 0,
    cacheTime: 0,
  })

  const keys = usersByHours?.length > 0 ? Object.keys(usersByHours?.[0]).filter((key) => key !== 'hora') : []

  if (isUsersByHoursPending) {
    return (
      <AnimatedBaseCard>
        <LoadingContainer />
      </AnimatedBaseCard>
    )
  }

  if (isUsersByHoursError) {
    return (
      <AnimatedBaseCard>
        <ErrorMessage />
      </AnimatedBaseCard>
    )
  }

  return (
    <AnimatedBaseCard>
      <ChartTitle>Por Hora do Dia</ChartTitle>
      <RadarContainer>
        <ResponsiveRadar
          data={usersByHours}
          keys={keys}
          margin={{ top: 25, right: 10, bottom: 25, left: 10 }}
          indexBy="hora"
          valueFormat=">-.2f"
          borderColor={{ from: 'color' }}
          gridLabelOffset={16}
          dotSize={5.5}
          fillOpacity={0.5}
          dotColor="#ffffff"
          dotBorderWidth={2}
          dotBorderColor="var(--signup-chart-color)"
          colors={['var(--signup-chart-color)']}
          blendMode="multiply"
          motionConfig="wobbly"
          sliceTooltip={(props) => (
            <TooltipContainer type="signup">
              <strong>Horário: {props.index}</strong>
              <br />
              <strong>{formatNumber(props.data[0].value)} Cadastros</strong>
            </TooltipContainer>
          )}
        />
      </RadarContainer>
    </AnimatedBaseCard>
  )
}
