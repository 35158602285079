import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import * as S from './SearchInput.Styles'
import BaseSpin from '../../spin/BaseSpin'

export const SearchInput = React.forwardRef((props, ref) => {
  const { loading, filter, ...rest } = props

  return (
    <S.SearchInput
      ref={ref}
      prefix={<SearchOutlined />}
      {...(filter && {
        suffix: (
          <S.Space align="center">
            {loading && <BaseSpin size="small" />}
            {filter}
          </S.Space>
        ),
      })}
      {...rest}
    />
  )
})
