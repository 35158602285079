import { http, HttpResponse } from 'msw'
import { PermissionsResponse } from '../../container/admin/hooks/users/Queries/useGetSelfPermissions'

const response = {
  permissoes: {
    acao_doacao_agape: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 32,
    },
    campanha: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 10,
    },
    dash_board_donations: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 22,
    },
    dash_board_users: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 21,
    },
    doacao_agape: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 30,
    },
    doacoes: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 26,
    },
    empresa: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 11,
    },
    endereco: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 25,
    },
    estoque_agape: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 31,
    },
    familia_agape: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 29,
    },
    general: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 24,
    },
    logs: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 27,
    },
    marechal: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 23,
    },
    mensageria: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 34,
    },
    perfil: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 13,
    },
    usuario: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 12,
    },
    vocacional: {
      acessar: 1,
      criar: 1,
      deletar: 1,
      editar: 1,
      menu_id: 33,
    },
  },
}

export const userPermissionsMock = http.get<never, never, PermissionsResponse>('/users/me/permissions', () => {
  return HttpResponse.json(response)
})
