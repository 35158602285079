import { Skeleton } from 'antd'
import { Chart } from './Chart'
import { useGetTodayDonationsPerHour } from '../../../../../hooks/dashboards/finances/queries/useGetTodayDonationsPerHour'

export function Content({ filters, setCampaignsOptions, onFilterClick }) {
  const query = useGetTodayDonationsPerHour(filters)

  if (query?.data?.campaigns) {
    setCampaignsOptions(query?.data?.campaigns)
  }

  if (query?.isPending) {
    return (
      <div style={{ height: '260px', display: 'grid', placeItems: 'center', padding: '1rem' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (query?.isError) {
    return (
      <h4 style={{ color: 'red', height: '260px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )
  }

  const dataToChart = filters?.visualizar_por === 'campanha' ? query?.data : query?.data?.doacoes_por_hora

  return (
    <Chart
      data={dataToChart}
      filters={filters}
      onFilterClick={onFilterClick}
    />
  )
}
