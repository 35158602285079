export const countries = [
  { value: 'brasil', label: '🇧🇷 Brasil' },
  { value: 'afeganistao', label: '🇦🇫 Afeganistão' },
  { value: 'africa_do_sul', label: '🇿🇦 África do Sul' },
  { value: 'albania', label: '🇦🇱 Albânia' },
  { value: 'alemanha', label: '🇩🇪 Alemanha' },
  { value: 'andorra', label: '🇦🇩 Andorra' },
  { value: 'angola', label: '🇦🇴 Angola' },
  { value: 'anguilla', label: '🇦🇮 Anguilla' },
  { value: 'antartida', label: '🇦🇶 Antártida' },
  { value: 'antigua_e_barbuda', label: '🇦🇬 Antígua e Barbuda' },
  { value: 'arabia_saudita', label: '🇸🇦 Arábia Saudita' },
  { value: 'argelia', label: '🇩🇿 Argélia' },
  { value: 'argentina', label: '🇦🇷 Argentina' },
  { value: 'armenia', label: '🇦🇲 Armênia' },
  { value: 'aruba', label: '🇦🇼 Aruba' },
  { value: 'australia', label: '🇦🇺 Austrália' },
  { value: 'austria', label: '🇦🇹 Áustria' },
  { value: 'azerbaijao', label: '🇦🇿 Azerbaijão' },
  { value: 'bahamas', label: '🇧🇸 Bahamas' },
  { value: 'bangladesh', label: '🇧🇩 Bangladesh' },
  { value: 'barbados', label: '🇧🇧 Barbados' },
  { value: 'bareine', label: '🇧🇭 Barém' },
  { value: 'belgica', label: '🇧🇪 Bélgica' },
  { value: 'belize', label: '🇧🇿 Belize' },
  { value: 'benin', label: '🇧🇯 Benin' },
  { value: 'bermudas', label: '🇧🇲 Bermudas' },
  { value: 'bielorussia', label: '🇧🇾 Bielorrússia' },
  { value: 'bolivia', label: '🇧🇴 Bolívia' },
  { value: 'bosnia_e_herzegovina', label: '🇧🇦 Bósnia e Herzegovina' },
  { value: 'botsuana', label: '🇧🇼 Botsuana' },
  { value: 'brunei', label: '🇧🇳 Brunei' },
  { value: 'bulgaria', label: '🇧🇬 Bulgária' },
  { value: 'burquina_faso', label: '🇧🇫 Burquina Faso' },
  { value: 'burundi', label: '🇧🇮 Burundi' },
  { value: 'butao', label: '🇧🇹 Butão' },
  { value: 'cabo_verde', label: '🇨🇻 Cabo Verde' },
  { value: 'camaroes', label: '🇨🇲 Camarões' },
  { value: 'camboja', label: '🇰🇭 Camboja' },
  { value: 'canada', label: '🇨🇦 Canadá' },
  { value: 'catar', label: '🇶🇦 Catar' },
  { value: 'cazaquistao', label: '🇰🇿 Cazaquistão' },
  { value: 'chade', label: '🇹🇩 Chade' },
  { value: 'chile', label: '🇨🇱 Chile' },
  { value: 'china', label: '🇨🇳 China' },
  { value: 'chipre', label: '🇨🇾 Chipre' },
  { value: 'colombia', label: '🇨🇴 Colômbia' },
  { value: 'comores', label: '🇰🇲 Comores' },
  { value: 'congo', label: '🇨🇬 Congo' },
  { value: 'coreia_do_norte', label: '🇰🇵 Coreia do Norte' },
  { value: 'coreia_do_sul', label: '🇰🇷 Coreia do Sul' },
  { value: 'cosovo', label: '🇽🇰 Cosovo' },
  { value: 'costa_do_marfim', label: '🇨🇮 Costa do Marfim' },
  { value: 'costa_rica', label: '🇨🇷 Costa Rica' },
  { value: 'croacia', label: '🇭🇷 Croácia' },
  { value: 'cuba', label: '🇨🇺 Cuba' },
  { value: 'curdistao', label: '🏴 Curdistão' },
  { value: 'curacao', label: '🇨🇼 Curaçao' },
  { value: 'dinamarca', label: '🇩🇰 Dinamarca' },
  { value: 'dominica', label: '🇩🇲 Dominica' },
  { value: 'egito', label: '🇪🇬 Egito' },
  { value: 'emirados_arabes_unidos', label: '🇦🇪 Emirados Árabes Unidos' },
  { value: 'equador', label: '🇪🇨 Equador' },
  { value: 'eritreia', label: '🇪🇷 Eritreia' },
  { value: 'eslovaquia', label: '🇸🇰 Eslováquia' },
  { value: 'eslovenia', label: '🇸🇮 Eslovênia' },
  { value: 'espanha', label: '🇪🇸 Espanha' },
  {
    value: 'estado_da_cidade_do_vaticano',
    label: '🇻🇦 Estado da Cidade do Vaticano',
  },
  {
    value: 'estados_federados_da_micronesia',
    label: '🇫🇲 Estados Federados da Micronésia',
  },
  { value: 'estados_unidos', label: '🇺🇸 Estados Unidos' },
  { value: 'estonia', label: '🇪🇪 Estônia' },
  { value: 'etiopia', label: '🇪🇹 Etiópia' },
  { value: 'fiji', label: '🇫🇯 Fiji' },
  { value: 'filipinas', label: '🇵🇭 Filipinas' },
  { value: 'finlandia', label: '🇫🇮 Finlândia' },
  { value: 'franca', label: '🇫🇷 França' },
  { value: 'gabao', label: '🇬🇦 Gabão' },
  { value: 'gambia', label: '🇬🇲 Gâmbia' },
  { value: 'gana', label: '🇬🇭 Gana' },
  { value: 'georgia', label: '🇬🇪 Geórgia' },
  { value: 'gibraltar', label: '🇬🇮 Gibraltar' },
  { value: 'granada', label: '🇬🇩 Granada' },
  { value: 'gregoia', label: '🇬🇷 Grécia' },
  { value: 'groelandia', label: '🇬🇱 Groelândia' },
  { value: 'guadalupe', label: '🇬🇵 Guadalupe' },
  { value: 'guam', label: '🇬🇺 Guam' },
  { value: 'guatemala', label: '🇬🇹 Guatemala' },
  { value: 'guiana', label: '🇬🇾 Guiana' },
  { value: 'guiana_francesa', label: '🇬🇫 Guiana Francesa' },
  { value: 'guine', label: '🇬🇳 Guiné' },
  { value: 'guine-bissau', label: '🇬🇼 Guiné-Bissau' },
  { value: 'haiti', label: '🇭🇹 Haiti' },
  { value: 'holanda', label: '🇳🇱 Holanda' },
  { value: 'holanda_caribenha', label: '🇳🇱 Holanda Caribenha' },
  { value: 'honduras', label: '🇭🇳 Honduras' },
  { value: 'hong_kong', label: '🇭🇰 Hong Kong' },
  { value: 'hungria', label: '🇭🇺 Hungria' },
  { value: 'iemen', label: '🇾🇪 Iêmen' },
  { value: 'ilha_bouvet', label: '🇧🇻 Ilha Bouvet' },
  { value: 'ilha_christmas', label: '🇨🇽 Ilha Christmas' },
  { value: 'ilha_de_man', label: '🇮🇲 Ilha de Man' },
  { value: 'ilha_norfolk', label: '🇳🇫 Ilha Norfolk' },
  { value: 'ilhas_caimao', label: '🇰🇾 Ilhas Caimão' },
  { value: 'ilhas_cocos', label: '🇨🇨 Ilhas Cocos' },
  { value: 'ilhas_cook', label: '🇨🇰 Ilhas Cook' },
  { value: 'ilhas_falkland', label: '🇫🇰 Ilhas Falkland' },
  { value: 'ilhas_faroe', label: '🇫🇴 Ilhas Faroe' },
  {
    value: 'ilhas_georgia_do_sul_e_sandwich_do_sul',
    label: '🇬🇸 Ilhas Geórgia do Sul e Sandwich do Sul',
  },
  { value: 'ilhas_heard_e_mcdonald', label: '🇭🇲 Ilhas Heard e McDonald' },
  { value: 'ilhas_marianas_do_norte', label: '🇲🇵 Ilhas Marianas do Norte' },
  { value: 'ilhas_marshall', label: '🇲🇭 Ilhas Marshall' },
  { value: 'ilhas_menores_dos_eua', label: '🇺🇲 Ilhas Menores dos EUA' },
  { value: 'ilhas_pacífico', label: '🇵🇬 Ilhas Pacífico' },
  { value: 'ilhas_palau', label: '🇵🇼 Ilhas Palau' },
  { value: 'ilhas_paracel', label: '🇨🇳 Ilhas Paracel' },
  { value: 'ilhas_pitcairn', label: '🇵🇳 Ilhas Pitcairn' },
  { value: 'ilhas_reuniao', label: '🇷🇪 Ilhas Reunião' },
  { value: 'ilhas_salomao', label: '🇸🇧 Ilhas Salomão' },
  {
    value: 'ilhas_svalbard_e_jan_mayen',
    label: '🇸🇯 Ilhas Svalbard e Jan Mayen',
  },
  { value: 'ilhas_tokelau', label: '🇹🇰 Ilhas Tokelau' },
  { value: 'ilhas_turcas_e_caicos', label: '🇹🇨 Ilhas Turcas e Caicos' },
  { value: 'ilhas_virgens_americanas', label: '🇻🇮 Ilhas Virgens Americanas' },
  { value: 'ilhas_virgens_britanicas', label: '🇻🇬 Ilhas Virgens Britânicas' },
  { value: 'ilhas_wallis_e_futuna', label: '🇼🇫 Ilhas Wallis e Futuna' },
  { value: 'india', label: '🇮🇳 Índia' },
  { value: 'indonesia', label: '🇮🇩 Indonésia' },
  { value: 'reino_unido', label: '🇬🇧 Inglaterra' },
  { value: 'ira', label: '🇮🇶 Irã' },
  { value: 'iraque', label: '🇮🇶 Iraque' },
  { value: 'irlanda', label: '🇮🇪 Irlanda' },
  { value: 'islandia', label: '🇮🇸 Islândia' },
  { value: 'israel', label: '🇮🇱 Israel' },
  { value: 'italia', label: '🇮🇹 Itália' },
  { value: 'jamaica', label: '🇯🇲 Jamaica' },
  { value: 'japao', label: '🇯🇵 Japão' },
  { value: 'jordania', label: '🇯🇴 Jordânia' },
  { value: 'kosovo', label: '🇽🇰 Kosovo' },
  { value: 'kuwait', label: '🇰🇼 Kuwait' },
  { value: 'laos', label: '🇱🇦 Laos' },
  { value: 'lesoto', label: '🇱🇸 Lesoto' },
  { value: 'letonia', label: '🇱🇻 Letônia' },
  { value: 'libano', label: '🇱🇧 Líbano' },
  { value: 'liberia', label: '🇱🇷 Libéria' },
  { value: 'libia', label: '🇱🇾 Líbia' },
  { value: 'liechtenstein', label: '🇱🇮 Liechtenstein' },
  { value: 'lituania', label: '🇱🇹 Lituânia' },
  { value: 'luxemburgo', label: '🇱🇺 Luxemburgo' },
  { value: 'macau', label: '🇲🇴 Macau' },
  { value: 'macedonia', label: '🇲🇰 Macedônia' },
  { value: 'madagascar', label: '🇲🇬 Madagascar' },
  { value: 'malasia', label: '🇲🇾 Malásia' },
  { value: 'malavi', label: '🇲🇼 Malawi' },
  { value: 'maldivas', label: '🇲🇻 Maldivas' },
  { value: 'mali', label: '🇲🇱 Mali' },
  { value: 'malta', label: '🇲🇹 Malta' },
  { value: 'marrocos', label: '🇲🇦 Marrocos' },
  { value: 'martinica', label: '🇲🇶 Martinica' },
  { value: 'mauricia', label: '🇲🇺 Maurícia' },
  { value: 'mauritania', label: '🇲🇷 Mauritânia' },
  { value: 'mayotte', label: '🇾🇹 Mayotte' },
  { value: 'mexico', label: '🇲🇽 México' },
  { value: 'mianmar', label: '🇲🇲 Mianmar' },
  { value: 'micronesia', label: '🇫🇲 Micronésia' },
  { value: 'mocambique', label: '🇲🇿 Moçambique' },
  { value: 'moldavia', label: '🇲🇩 Moldávia' },
  { value: 'monaco', label: '🇲🇨 Mônaco' },
  { value: 'mongolia', label: '🇲🇳 Mongólia' },
  { value: 'montenegro', label: '🇲🇪 Montenegro' },
  { value: 'montserrat', label: '🇲🇸 Montserrat' },
  { value: 'namibia', label: '🇳🇦 Namíbia' },
  { value: 'nauru', label: '🇳🇷 Nauru' },
  { value: 'nepal', label: '🇳🇵 Nepal' },
  { value: 'nicaragua', label: '🇳🇮 Nicarágua' },
  { value: 'niger', label: '🇳🇪 Níger' },
  { value: 'nigeria', label: '🇳🇬 Nigéria' },
  { value: 'niue', label: '🇳🇺 Niue' },
  { value: 'noruega', label: '🇳🇴 Noruega' },
  { value: 'nova_caledonia', label: '🇳🇨 Nova Caledônia' },
  { value: 'nova_zelandia', label: '🇳🇿 Nova Zelândia' },
  { value: 'oman', label: '🇴🇲 Omã' },
  { value: 'paises_baixos', label: '🇳🇱 Países Baixos' },
  { value: 'palau', label: '🇵🇼 Palau' },
  { value: 'palestina', label: '🇵🇸 Palestina' },
  { value: 'panama', label: '🇵🇦 Panamá' },
  { value: 'papua_nova_guiné', label: '🇵🇬 Papua-Nova Guiné' },
  { value: 'paquistao', label: '🇵🇰 Paquistão' },
  { value: 'paraguai', label: '🇵🇾 Paraguai' },
  { value: 'peru', label: '🇵🇪 Peru' },
  { value: 'polinesia_francesa', label: '🇵🇫 Polinésia Francesa' },
  { value: 'polonia', label: '🇵🇱 Polônia' },
  { value: 'porto_rico', label: '🇵🇷 Porto Rico' },
  { value: 'portugal', label: '🇵🇹 Portugal' },
  { value: 'quenia', label: '🇰🇪 Quênia' },
  { value: 'quirguistao', label: '🇰🇬 Quirguistão' },
  { value: 'reino_unido', label: '🇬🇧 Reino Unido' },
  { value: 'republica_centro-africana', label: '🇨🇫 República Centro-Africana' },
  { value: 'republica_checa', label: '🇨🇿 República Checa' },
  { value: 'republica_dominicana', label: '🇩🇴 República Dominicana' },
  { value: 'romenia', label: '🇷🇴 Romênia' },
  { value: 'ruanda', label: '🇷🇼 Ruanda' },
  { value: 'russia', label: '🇷🇺 Rússia' },
  { value: 'saara_ocidental', label: '🇪🇭 Saara Ocidental' },
  { value: 'salomao', label: '🇸🇧 Salomão' },
  { value: 'samoa_americana', label: '🇦🇸 Samoa Americana' },
  { value: 'samoa_ocidental', label: '🇼🇸 Samoa Ocidental' },
  {
    value: 'santa_helena_ascensao_e_tristao_da_cunha',
    label: '🇸🇭 Santa Helena, Ascensão e Tristão da Cunha',
  },
  { value: 'santa_lucia', label: '🇱🇨 Santa Lúcia' },
  { value: 'sao_bartolomeu', label: '🇧🇱 São Bartolomeu' },
  { value: 'sao_cristovao_e_nevis', label: '🇰🇳 São Cristóvão e Nevis' },
  { value: 'sao_martinho', label: '🇲🇫 São Martinho' },
  { value: 'sao_pierre_e_miquelon', label: '🇵🇲 São Pedro e Miquelão' },
  { value: 'sao_tome_e_principe', label: '🇸🇹 São Tomé e Príncipe' },
  { value: 'sao_vicente_e_granadinas', label: '🇻🇨 São Vicente e Granadinas' },
  { value: 'seicheles', label: '🇸🇨 Seicheles' },
  { value: 'senegal', label: '🇸🇳 Senegal' },
  { value: 'serra_leoa', label: '🇸🇱 Serra Leoa' },
  { value: 'servia', label: '🇷🇸 Sérvia' },
  { value: 'singapura', label: '🇸🇬 Singapura' },
  { value: 'siria', label: '🇸🇾 Síria' },
  { value: 'somalia', label: '🇸🇴 Somália' },
  { value: 'sri_lanka', label: '🇱🇰 Sri Lanka' },
  { value: 'suazilandia', label: '🇸🇿 Suazilândia' },
  { value: 'sudao', label: '🇸🇩 Sudão' },
  { value: 'sudao_do_sul', label: '🇸🇸 Sudão do Sul' },
  { value: 'suecia', label: '🇸🇪 Suécia' },
  { value: 'suica', label: '🇨🇭 Suíça' },
  { value: 'suriname', label: '🇸🇷 Suriname' },
  { value: 'tadjiquistao', label: '🇹🇯 Tadjiquistão' },
  { value: 'tailandia', label: '🇹🇭 Tailândia' },
  { value: 'taiwan', label: '🇹🇼 Taiwan' },
  { value: 'tanzania', label: '🇹🇿 Tanzânia' },
  {
    value: 'territorio_britanico_do_oceano_indico',
    label: '🇮🇴 Território Britânico do Oceano Índico',
  },
  {
    value: 'territorios_austais_franceses',
    label: '🇹🇫 Territórios Austrais Franceses',
  },
  { value: 'timor-leste', label: '🇹🇱 Timor-Leste' },
  { value: 'togo', label: '🇹🇬 Togo' },
  { value: 'tonga', label: '🇹🇴 Tonga' },
  { value: 'toquelau', label: '🇹🇰 Toquelau' },
  { value: 'trinidad_e_tobago', label: '🇹🇹 Trinidad e Tobago' },
  { value: 'tunisia', label: '🇹🇳 Tunísia' },
  { value: 'turcomenistao', label: '🇹🇲 Turcomenistão' },
  { value: 'turquia', label: '🇹🇷 Turquia' },
  { value: 'tuvalu', label: '🇹🇻 Tuvalu' },
  { value: 'ucrania', label: '🇺🇦 Ucrânia' },
  { value: 'uganda', label: '🇺🇬 Uganda' },
  { value: 'uruguai', label: '🇺🇾 Uruguai' },
  { value: 'usbequistao', label: '🇺🇿 Usbequistão' },
  { value: 'vanuatu', label: '🇻🇺 Vanuatu' },
  { value: 'vaticano', label: '🇻🇦 Vaticano' },
  { value: 'venezuela', label: '🇻🇪 Venezuela' },
  { value: 'vietna', label: '🇻🇳 Vietnã' },
  { value: 'wallis_e_futuna', label: '🇼🇫 Wallis e Futuna' },
  { value: 'zambia', label: '🇿🇲 Zâmbia' },
  { value: 'zimbabue', label: '🇿🇼 Zimbábue' },
]
