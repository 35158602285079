import { useState } from 'react'
import { StyledMenuFinance, Label, ModalContent } from '../../../styles'
import { paymentMethodOptions } from '../../../utils-constants'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import BaseRadio from '../../../../../components/radio/BaseRadio'
import BaseModal from '../../../../../components/modal/BaseModal'
import { BaseSelect } from '../../../../../components/select/BaseSelect'
import { StyledFilterIcon } from '../../../../../components/FilterIcon'

export function FilterModal({ filters, setFilters, campaignsOptions }) {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const isFilterActive = Boolean(filters?.forma_pagamento) || filters?.fk_campanha_id

  const campaignsOptionsFormatted = campaignsOptions
    .filter((item) => item.id !== null && item.titulo !== null)
    .map((item) => ({ value: item.id, label: item.titulo }))

  return (
    <>
      <StyledFilterIcon className="absolute" isFilterActive={isFilterActive} onClick={() => setIsModalOpen(true)} />
      <BaseModal
        title="Filtros das Doações Mês a Mês"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        closeIcon="X"
        size="large"
      >
        <ModalContent>
          <div>
            <Label>Visualizar por:</Label>
            <BaseRadio.Group onChange={(e) => handleInputChange({ visualizar_por: e.target.value })}>
              <BaseRadio.Button value="" defaultChecked>
                Total
              </BaseRadio.Button>
              <BaseRadio.Button value="campanha">Campanha</BaseRadio.Button>
            </BaseRadio.Group>
          </div>
          {filters?.visualizar_por !== 'campanha' && (
            <>
              <div>
                <Label>Método de Pagamento:</Label>
                <BaseSelect
                  width="11rem"
                  dropdownRender={(menu) => <StyledMenuFinance>{menu}</StyledMenuFinance>}
                  allowClear
                  options={paymentMethodOptions}
                  value={filters?.forma_pagamento || ''}
                  onChange={(value) => handleInputChange({ forma_pagamento: value })}
                />
              </div>
              <div>
                <Label>Campanhas:</Label>
                <BaseSelect
                  width="15rem"
                  allowClear
                  options={campaignsOptionsFormatted}
                  value={filters?.fk_campanha_id}
                  onChange={(value) => handleInputChange({ fk_campanha_id: value })}
                />
              </div>
            </>
          )}
        </ModalContent>
      </BaseModal>
    </>
  )
}
