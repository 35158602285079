import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useFetchAllCampaigns() {
  const campaigns = useQuery({
    queryKey: ['ALL_CAMPAIGNS'],
    queryFn: async () => {
      const response = await DataService.get(`admin/dash-board/users/all-campaigns`)
      return response.data
    },
  })

  return campaigns
}
