import { useQuery } from '@tanstack/react-query'

interface DayData {
  day: string
  count: number
}

export function useMessagesPerDayOfWeek() {
  return useQuery<DayData[]>({
    queryKey: ['messages-per-day'],
    queryFn: async () => {
      return [
        { day: 'Dom', count: 1200 },
        { day: 'Seg', count: 2300 },
        { day: 'Ter', count: 2100 },
        { day: 'Qua', count: 2500 },
        { day: 'Qui', count: 2400 },
        { day: 'Sex', count: 2200 },
        { day: 'Sáb', count: 1500 }
      ]
    }
  })
} 