import { useEffect, useState } from 'react';

export function useGetUserDeviceId() {
  const [deviceId, setDeviceId] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.mercadopago.com/v2/security.js';
    script.setAttribute('view', 'checkout');
    script.setAttribute('output', 'deviceId');

    script.onload = () => {
      const id = window.MP_DEVICE_SESSION_ID;
      setDeviceId(id);
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return deviceId;

  // access the value through window.deviceId
}

export const waitForDeviceId = async (retries = 10, interval = 1000) => {
  return new Promise((resolve, reject) => {
    const checkDeviceId = (attempt = 0) => {
      if (window.deviceId) {
        resolve(window.deviceId);
      } else if (attempt < retries) {
        setTimeout(() => checkDeviceId(attempt + 1), interval);
      } else {
        reject(new Error('Por favor, tente novamente!'));
      }
    };
    checkDeviceId();
  });
};
