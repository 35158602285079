import firstIcon from '../../images/firstIcon.svg';
import secondIcon from '../../images/secondIcon.svg';
import thirdIcon from '../../images/thirdIcon.svg';
import fourthIcon from '../../images/fourthIcon.svg';

import circleWhite from '../../images/circleBgWhite.svg';
import circleGray from '../../images/circleBgGray.svg';
import whiteLines from '../../images/whiteLines.svg';

// Utility function to adjust positions based on the original top value
const adjustPosition = (originalTop, offset = 0) => {
  const numericValue = parseFloat(originalTop); // Convert 'px' value to number
  return `${numericValue - offset}px`; // Subtract offset and return new 'px' value
};

export function ImageStepsDonationHorizontal() {
  const imagePositions = [
    { src: circleGray, top: '20px', left: '155px', rotate: 0 },
    { src: firstIcon, top: '60px', left: '155px' },

    { src: circleWhite, top: '20px', left: '435px', rotate: 0, transform: 'translate(-50%)' },
    { src: secondIcon, top: '205px', left: '440px' },

    { src: circleGray, top: '20px', left: '715px', rotate: 0 },
    { src: thirdIcon, top: '70px', left: '715px' },

    { src: circleWhite, top: '20px', left: '995px', rotate: 0 },
    { src: fourthIcon, top: '225px', left: '995px' },
  ];

  const ImageOverlay = ({ src, top, left, rotate = 0, transform = '' }) => (
    <img
      src={src}
      alt=""
      style={{
        position: 'absolute',
        top: adjustPosition(top),
        left: left,
        transform: transform || `translate(-50%) rotate(${rotate}deg)`,
        zIndex: 999, // Ensure images stay below the text
      }}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        width: '1200px',
        margin: '0 auto',
        fontFamily: 'MontSerrat',
      }}
    >
      <div style={{ position: 'relative' }}>
        <img src={whiteLines} alt="White Lines" style={{ position: 'relative', zIndex: 1 }} />

        {imagePositions.map((image, index) => (
          <ImageOverlay
            key={index}
            src={image.src}
            top={image.top}
            left={image.left}
            rotate={image.rotate}
            transform={image.transform}
          />
        ))}

        <div
          style={{
            position: 'absolute',
            top: adjustPosition('100px'),
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            display: 'flex',
          }}
        >
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              fontWeight: '400',
              color: 'white',
              fontSize: '22px',
              left: -420,
              top: 40,
            }}
          >
            Clique no botão <br />
            <div style={{ fontWeight: '600' }}>fazer doação!</div>
            <div style={{ position: 'absolute', top: 100, left: 0, right: 0 }}>01</div>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            top: adjustPosition('100px'),
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              color: '#434343',
              fontSize: '18px',
              left: -140,
              top: -15,
            }}
          >
            <div>
              Na página de <br /> pagamentos escolha <br /> Se deseja{' '}
              <span style={{ fontWeight: '600' }}>
                doar <br />
                uma única vez,
              </span>{' '}
              ou de <br />
              <div style={{ fontWeight: '600' }}>forma recorrente</div>
            </div>
            <div style={{ position: 'absolute', top: -35, left: 0, right: 0 }}>02</div>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            top: adjustPosition('100px'),
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              color: 'white',
              fontSize: '18px',
              left: '140px',
              top: '40px',
            }}
          >
            Escolha o valor. Não
            <br />
            importa qual a quantia, <br />
            <div style={{ fontWeight: '600' }}>
              só não deixe de fazer a <br /> sua doação.
            </div>
            <div style={{ position: 'absolute', top: 105, left: 0, right: 0 }}>03</div>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            top: adjustPosition('100px'),
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            fontSize: '20px',
            color: '#434343',
          }}
        >
          <div style={{ position: 'relative', left: '420px', textAlign: 'center' }}>
            Preencha seus dados
            <br /> E clique em <br />
            <span style={{ fontWeight: '600' }}>
              "Quero ser <br />
              benfeitor Hesed"
            </span>
            <div style={{ position: 'absolute', top: -40, left: 0, right: 0 }}>04</div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: adjustPosition('45px'),
          textAlign: 'center',
          color: 'white',
          paddingBottom: '3rem',
        }}
      >
        Caso queira contribuir de outra forma,{' '}
        <a
          href="https://institutohesed.org.br/contact/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: 'white' }}
        >
          entre em contato
        </a>{' '}
        com nossas irmãs.
      </div>
    </div>
  );
}
