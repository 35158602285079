import { http, HttpResponse } from 'msw'
import { CreatePreCadastroProps } from '../../../container/benfeitor/hooks/api/vocacional/mutations/useCreatePreCadastro'

export const createPreCadastroMock = http.post<never, CreatePreCadastroProps>(
  '/vocacional/registrar-pre-cadastro',
  async ({ request }) => {
    const { nome } = await request.json()

    if (nome === 'Usuario Vocacional') {
      return new HttpResponse(null, { status: 201 })
    }

    return new HttpResponse(null, { status: 400 })
  },
)
