import { useBlur } from '@/context/Blur/BlurContext'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { useMemo, useState } from 'react'
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatNumber, formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { ChartTitle, TooltipContainer } from '../../../styles'
import { Gradients } from '../../../utils-constants'

export function Chart({ data, filters }) {
  const [barHovered, setBarHovered] = useState(false)
  const [focusBar, setFocusBar] = useState(null)

  const formatMonthYear = (value) => {
    const [, month] = value.split('-')
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    return `${months[Number(month) - 1]}`
  }

  const aggregatedData = useMemo(() => {
    if (filters?.visualizar_por === 'campanha') {
      const titles = Object.keys(data)
      return titles.map((date) => ({
        date,
        campaign: data[date],
      }))
    }

    return data.map((item) => ({
      mês: item?.mês,
      Total: parseFloat(
        (
          parseFloat(item?.Boleto || 0) +
          parseFloat(item['Cartão de Crédito'] || 0) +
          parseFloat(item?.PIX || 0)
        ).toFixed(2),
      ),
    }))
  }, [data, filters])

  const processedData = useMemo(() => {
    if (filters?.visualizar_por === 'campanha') {
      return aggregatedData
        .map((item) => {
          if (!item.campaign) return null

          const campaignData = {}
          item.campaign.forEach((camp) => {
            campaignData[camp.campanha] = parseFloat(camp.valor_campanha)
          })
          return {
            date: item.date,
            campaigns: campaignData,
          }
        })
        .filter((item) => item !== null)
    }
    return aggregatedData
  }, [aggregatedData])

  const CustomTooltip = ({ payload }) => {
    if (!payload || payload.length === 0) return null

    const { date } = payload[0].payload
    const campaignData = payload[0].payload

    const formattedDate = campaignData.mês.split('-').reverse().join('/')

    return (
      <TooltipContainer type="finance">
        <strong>{date}</strong>
        <>
          <strong>{formattedDate}</strong>

          <BlurDiv>{formatToBrazilianCurrency(campaignData.Total)}</BlurDiv>
        </>
      </TooltipContainer>
    )
  }

  const CampaignTooltip = ({ active, payload }) => {
    if (active && payload && Array.isArray(payload) && payload.length) {
      const totalPedidos = payload.reduce((sum, entry) => {
        const campaignName = entry.name.split('.')[0]
        const campaignDetails = entry.payload[campaignName]

        if (campaignDetails && campaignDetails.quantidade_pedidos) {
          return sum + parseInt(campaignDetails.quantidade_pedidos, 10)
        }
        return sum
      }, 0)

      const total = payload.reduce((sum, entry) => sum + entry.value, 0)

      const sortedCampaigns = payload
        .map((entry) => {
          const campaignName = entry.name.split('.')[0]
          const campaignDetails = entry.payload[campaignName]

          return {
            campaignName,
            campaignDetails,
            color: entry.color,
          }
        })
        .filter(({ campaignDetails }) => campaignDetails)
        .sort((a, b) => b.campaignDetails.valor_campanha - a.campaignDetails.valor_campanha)

      return (
        <TooltipContainer
          type="finance"
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '1rem' }}
        >
          <div>
            {/* date: */}
            <h4>{`${payload[0].payload.date.split('-')[1]}/${payload[0].payload.date.split('-')[0]}`}</h4>
            <BlurDiv>
              <p style={{ fontWeight: 'bold' }}>{`Total: ${formatToBrazilianCurrency(total)} - ${formatNumber(
                totalPedidos,
              )} Doações`}</p>
            </BlurDiv>
          </div>

          <div
            style={{
              width: '100%',
              height: '2px',
              backgroundColor: 'var(--separator-color)',
              margin: '0rem 1rem 0.8rem 0rem',
            }}
          />

          {sortedCampaigns.map(({ campaignName, campaignDetails, color }) => (
            <BlurDiv key={campaignName}>
              <p style={{ fontWeight: 'bold', color }}>
                {campaignName === 'null' ? 'sem campanha' : campaignName}:{' '}
                {formatToBrazilianCurrency(campaignDetails.valor_campanha)} | {campaignDetails.percentual}% |{' '}
                {`${formatNumber(campaignDetails.quantidade_pedidos)} Doações`}
              </p>
            </BlurDiv>
          ))}
        </TooltipContainer>
      )
    }

    return null
  }

  const formatChartNumber = (value) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}mi`
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(0)}k`
    }
    return value
  }

  const CustomAxis = ({ x, y, stroke, payload }) => {
    const { isBlurred } = useBlur()

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={-12}
          dy={16}
          textAnchor="end"
          fill="var(--text-main-color)"
          className={isBlurred ? 'blur' : 'no-blur'}
        >
          {formatChartNumber(payload.value)}
        </text>
      </g>
    )
  }

  const transformData = (data) => {
    const transformedData = []
    const campaignNames = new Set()

    data.forEach(({ date, campaign }) => {
      const campaignData = { date }

      campaign?.forEach(({ campanha, valor_campanha, quantidade_pedidos, percentual }) => {
        const campaignName = campanha === null ? 'sem campanha' : campanha

        campaignNames.add(campaignName)

        campaignData[campaignName] = {
          valor_campanha: parseFloat(valor_campanha),
          quantidade_pedidos,
          percentual: parseFloat(percentual),
        }
      })

      transformedData.push(campaignData)
    })

    return {
      transformedData,
      campaignNames: Array.from(campaignNames),
    }
  }

  const { transformedData, campaignNames } = transformData(aggregatedData)

  const colorPalette = [
    '#4CAF50', // Green
    '#2196F3', // Blue
    '#FFC107', // Amber
    '#FF9800', // Orange
    '#f05e54', // Red
    '#9C27B0', // Purple
    '#5868c0', // Indigo
  ]

  return (
    <>
      <ChartTitle>Doações Mês a Mês</ChartTitle>
      <div style={{ width: '100%', height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          {filters?.visualizar_por !== 'campanha' && (
            <BarChart data={processedData} margin={{ top: 30, left: 20, right: 40, bottom: 40 }}>
              {Gradients()}
              <CartesianGrid stroke="#444" vertical={false} opacity={0.5} />
              <XAxis
                axisLine={false}
                dataKey="mês"
                tickFormatter={formatMonthYear}
                tick={{ fill: 'var(--finance-chart-color)', fontWeight: 'bold' }}
                orientation="top"
                tickMargin={20}
              />
              <YAxis axisLine={false} tick={<CustomAxis />} domain={[0, 'dataMax']} type="number" tickMargin={15} />
              <Tooltip cursor={false} active={barHovered} content={<CustomTooltip />} />

              <Bar
                dataKey="Total"
                fill="red"
                radius={[8, 8, 0, 0]}
                barSize={40}
                onMouseEnter={() => setBarHovered(true)}
                onMouseLeave={() => setBarHovered(false)}
              >
                {aggregatedData?.map((_, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={focusBar === index ? 'url(#greenGradientOnHover)' : 'url(#greenGradientType1)'}
                      onMouseEnter={() => setFocusBar(index)}
                      onMouseLeave={() => setFocusBar(null)}
                    />
                  )
                })}
              </Bar>
            </BarChart>
          )}

          {filters?.visualizar_por === 'campanha' && (
            <BarChart data={transformedData} margin={{ top: 30, left: 20, right: 40, bottom: 40 }}>
              <CartesianGrid stroke="#444" vertical={false} opacity={0.5} />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickFormatter={formatMonthYear}
                tick={{ fill: 'var(--finance-chart-color)', fontWeight: 'bold' }}
                orientation="top"
                tickMargin={20}
              />
              <YAxis axisLine={false} tick={<CustomAxis />} domain={[0, 'dataMax']} type="number" tickMargin={15} />
              <Tooltip wrapperStyle={{ top: -200 }} cursor={false} content={<CampaignTooltip />} />
              {campaignNames.map((name, index) => {
                const dataKey = name === 'null' ? null : name
                return (
                  <Bar
                    barSize={40}
                    key={name}
                    dataKey={`${dataKey}.valor_campanha`}
                    stackId="a"
                    fill={colorPalette[index % colorPalette.length]}
                  />
                )
              })}
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </>
  )
}
