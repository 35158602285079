import { FONT_FAMILY, FONT_SIZE } from '@/styles/Themes/Admin/constants'
import { Menu } from 'antd'
import styled from 'styled-components'

export const BottomMenu = styled(Menu)`
  background-color: var(--sidebar-background-color);

  display: flex;
  align-items: center;

  z-index: 10;

  height: 5rem;

  gap: 0.5rem;

  overflow-x: auto;

  scrollbar-width: none; /* Esconde scrollbar no Firefox */
  -ms-overflow-style: none; /* Esconde scrollbar no IE e Edge */

  &::-webkit-scrollbar {
    display: none; /* Esconde scrollbar no Chrome, Safari e Edge */
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
    font-family: ${FONT_FAMILY.main};

    .ADMIN-MENU-ICON {
      height: 1.7rem;
    }
  }

  .ant-menu-item {
    min-width: 5rem !important;
    min-height: 4rem !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-submenu:not(.ant-menu-submenu-selected),
  .ant-menu-item {
    color: var(--sidebar-text-color);

    font-weight: bold;

    .ADMIN-MENU-ICON {
      svg {
        fill: var(--text-secondary-color) !important;
        stroke: var(--text-secondary-color) !important;
      }
    }

    .ant-menu-title-content {
      height: 30px;

      :focus,
      :active {
        color: var(--sidebar-text-color);
      }
    }

    :focus,
    :active {
      background-color: transparent;
    }
  }

  .ant-menu-submenu-active,
  .ant-menu-submenu.ant-menu-submenu-selected {
    .ADMIN-MENU-ICON {
      svg {
        fill: var(--sidebar-selected-menu-text-color) !important;
        stroke: var(--sidebar-selected-menu-text-color) !important;
      }
    }
    .ant-menu-title-content {
      color: var(--sidebar-selected-menu-text-color) !important;
      height: 30px;
    }

    background-color: var(--sidebar-selected-menu-background-color);

    font-weight: bold;

    border-radius: 12px;
  }

  .ant-menu-submenu-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2.5rem;

    .ant-menu-submenu-arrow {
      display: none;
    }

    span.ant-menu-title-content {
      margin: 0;
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
  }

  .ant-menu-sub.ant-menu-inline {
    background: var(--sidebar-background-darker-color);
  }

  li.ant-menu-item-selected {
    &::after {
      border-right: solid #726664 4px;
    }
  }
`
