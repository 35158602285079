import { Button } from 'antd'
import styled, { css } from 'styled-components'

const Btn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid transparent;
  border-radius: 6px !important;

  &.ant-btn-icon-only.ant-btn-sm {
    width: 2rem;
    height: 2rem;
  }
`

const ButtonGroup = styled.div`
  /* position: absolute;
  right: 50px;
  top: 25px;
  z-index: 999; */

  display: inline-flex;
  padding: 0.1505rem;
  column-gap: 0.225rem;

  border-radius: 6px;

  background-color: var(--theme-picker-button-bg-color);

  @media (max-width: 768px) {
    position: relative;
    top: initial;
    right: initial;
  }

  ${(props) =>
    props.$isFirstActive
      ? css`
          & > ${Btn}:first-of-type {
            background: var(--theme-picker-selected-theme-bg-color);
            color: #fef9f6;
          }

          & > ${Btn}:first-of-type svg {
            color: var(--theme-picker-selected-theme-icon-color);
          }

          & > ${Btn}:last-of-type svg {
            color: #80726f;
          }
        `
      : css`
          & > ${Btn}:last-of-type {
            background: var(--theme-picker-selected-theme-bg-color);
            color: #fcf0e4;
          }

          & > ${Btn}:first-of-type svg {
            color: #4a4646;
          }

          & > ${Btn}:last-of-type svg {
            color: var(--theme-picker-selected-theme-icon-color);
          }
        `}
`

export { Btn, ButtonGroup }
