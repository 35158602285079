import {
  AngelBg,
  DescriptionParagraphs,
  LastParagraphDesktop,
  SectionWhyBecomeMemberContainer,
  SectionWhyBecomeMemberContentWrapper,
  WhyBecomeMemberTitle,
} from './styles'

import bibleIcon from '../../../../images/icon_bible.svg'
import handsIcon from '../../../../images/icon_hands.svg'
import medalIcon from '../../../../images/icon_medal.svg'
import wingsIcon from '../../../../images/icon_wings.svg'

export function SectionWhyBecomeMember() {
  return (
    <SectionWhyBecomeMemberContainer>
      <AngelBg />
      <SectionWhyBecomeMemberContentWrapper>
        <div className="wrapper">
          <DescriptionParagraphs>
            <WhyBecomeMemberTitle>POR QUE TORNAR-SE UM MEMBRO OFICIAL:</WhyBecomeMemberTitle>
            <div className="icon-container">
              <div className="icon-wrapper">{<img src={bibleIcon} alt="Biblia" width={58} height={71} />}</div>
              <p>
                Tenha o <strong>seu nome inscrito no Livro Oficial do Exército</strong> de São Miguel e tenha a sua
                família consagrada ao Arcanjo Miguel.
              </p>
            </div>
            <div className="icon-container">
              <div className="icon-wrapper">{<img src={handsIcon} alt="Maos" width={70} height={71} />}</div>
              <p>
                Receba os benefícios espirituais de <strong>orações ininterruptas rezadas por suas intenções.</strong>
              </p>
            </div>
            <div className="icon-container">
              <div className="icon-wrapper">{<img src={medalIcon} alt="Medalhas" width={59} height={76} />}</div>
              <p>Receba o certificado oficial de membro diretamente na sua casa.</p>
            </div>
            <div className="icon-container">
              <div className="icon-wrapper">{<img src={wingsIcon} alt="Asas" width={90} height={44} />}</div>
              <p>
                <strong>Colha os frutos</strong> da força de um exército mundial de oração.
              </p>
            </div>
            <p className="last-mobile">
              Milhares de vidas e famílias já foram <strong>transformadas através da oração</strong>. Curas, conversões
              e libertações acontecem diariamente através dessa família espiritual.
            </p>
          </DescriptionParagraphs>
        </div>
      </SectionWhyBecomeMemberContentWrapper>
      <LastParagraphDesktop>
        Milhares de vidas e famílias já foram <strong>transformadas através da oração</strong>. Curas, conversões e
        libertações acontecem diariamente através dessa família espiritual.
      </LastParagraphDesktop>
    </SectionWhyBecomeMemberContainer>
  )
}
