import BaseModal from '../../../../../modal/BaseModal'
import { useDonationAction } from '../../hooks/useDonationAction'
import { DonationActionModalState } from '../../hooks/useDonationActionModal'

interface DonationActionsModal {
  actionModalContent: DonationActionModalState
  handleCloseActionModal: () => void
}

export function DonationActionsModal({ actionModalContent, handleCloseActionModal }: DonationActionsModal) {
  const { handleDonateAction } = useDonationAction()

  if (!actionModalContent) return

  return (
    <BaseModal
      centered
      title={<span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{actionModalContent.titulo}</span>}
      open={actionModalContent.open}
      onOk={() =>
        handleDonateAction(
          actionModalContent.acao,
          actionModalContent.objectId,
          actionModalContent.benfeitor,
          actionModalContent.isTransactionId,
          handleCloseActionModal,
        )
      }
      okText="Sim"
      onCancel={() => handleCloseActionModal()}
      cancelText="Não"
      width={1000}
    >
      {actionModalContent.conteudo}
    </BaseModal>
  )
}
