import { DataService } from '../../../../../lib/dataService'

interface RegisterPresenceData {
  fk_campanha_id: number
  user_id: number
}

export async function useRegisterPresence(campanhaId: number, userId: number): Promise<void> {
  if (userId == null) {
    return
  }

  const data: RegisterPresenceData = {
    fk_campanha_id: campanhaId,
    user_id: userId,
  }

  await DataService.post('/users/registrar-presenca', data)
}
