import { useState } from 'react';
import { Form } from 'antd';

export function useMembrosDrawer() {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [editarMembro, setEditarMembro] = useState(false);
  const [idMembro, setIdMembro] = useState<number | undefined>();

  const tituloDrawer = editarMembro ? 'Editar membro' : 'Adicionar membro';

  const showDrawer = ({ acao, idMembro }: { acao: 'editar' | 'adicionar'; idMembro?: number }) => {
    setEditarMembro(acao === 'editar');
    if (idMembro) setIdMembro(idMembro);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => form.submit();

  return {
    form,
    open,
    editarMembro,
    idMembro,
    tituloDrawer,
    showDrawer,
    onClose,
    onSubmit,
  };
}
