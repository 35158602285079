import { useState } from 'react'
import { Content } from './Content'
import { FilterModal } from './FilterModal'
import BaseCard from '../../../../../components/card/BaseCard'

export function TodayDonationsList() {
  const [filters, setFilters] = useState({})

  return (
    <BaseCard padding="0px">
      <FilterModal filters={filters} setFilters={setFilters} />
      <Content filters={filters} />
    </BaseCard>
  )
}
