import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface GetCardRendaFamiliarProps {
  fk_familia_agape_id: number
}

interface GetCardRendaFamiliarResponse {
  renda_familiar: string
  renda_per_capta: string
}

export function useGetCardRendaFamiliar({ fk_familia_agape_id }: GetCardRendaFamiliarProps) {
  const rendaFamiliar = useQuery({
    queryKey: ['RENDA_FAMILIAR', fk_familia_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetCardRendaFamiliarResponse>(
        `/agape/card-renda-familiar-agape/${fk_familia_agape_id}`,
      )

      return response.data
    },

    enabled: !!fk_familia_agape_id,
  })

  return rendaFamiliar
}
