import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface AbastecerEstoqueProps {
  item: string
  fk_estoque_agape_id: number
  quantidade: number
}

export function useAbastecerEstoque() {
  const queryClient = useQueryClient()

  const abastercerEstoque = useMutation({
    mutationFn: async ({ fk_estoque_agape_id, quantidade }: AbastecerEstoqueProps) => {
      await DataService.put(`/agape/abastecer-estoque/${fk_estoque_agape_id}`, { quantidade })
    },
    onSuccess: (_, { item, quantidade }) => {
      const moreThanOne = quantidade > 1

      notification.success({
        message: `Sucesso!`,
        description: `${quantidade} ${item}${moreThanOne ? ' foram' : ' foi'} adicionada${moreThanOne ? 's' : ''}.`,
      })

      queryClient.invalidateQueries({ queryKey: ['ITENS_ESTOQUE'] })
      queryClient.invalidateQueries({ queryKey: ['HISTORICO_MOVIMENTACOES'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_ESTOQUE'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Houve um erro ao atualizar o estoque',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return abastercerEstoque
}
