import { Segmented, SegmentedProps } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { BaseSegmentedStyles } from './styles'

export const BaseSegmented = styled(
  React.forwardRef<HTMLDivElement, SegmentedProps>((props, ref) => (
    <Segmented ref={ref} {...props} />
  )),
)`
  ${BaseSegmentedStyles}
`
