import { useEffect, useState } from 'react'
import { Form, notification } from 'antd'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { useFetchUserMe, useFetchUserMeAddress } from '../../../../../admin/hooks'
import { useRegisterUserLandingPage } from '../../../../../admin/hooks/users/Mutations/useRegisterUserLandingPage'
import { handleCpfShowValues, handlePhoneNumberFormat } from '../../../../../../utility/utility'
import { Title } from '../../../../components/Title'
import moment from 'moment'
import { useMultistepForm } from '../../../Carteira/Cadastro/hooks/useMultistepForm'
import { AddressForm } from '../../../Campaign/CampaignIdentification/components/AddressForm'
import { StepsContent } from '../StepsContent'
import { UserInfoSection } from './components/UserInfoSection'
import { StepOneLandingPage } from './components/StepOne'
import { StepsButtons } from '../StepsButtons'
import { ImageFile } from '../GeneralForm'
import { LandingPageProps } from '../../types/LandingPageProps'
import { StepsProgress } from '../StepsProgress'
import { useUpdateUserLandingPage } from '../../../../../admin/hooks/users/Mutations/useUpdateUserLandingPage'
import { StepWrapper } from '../../embed/style'

interface LandingPageStepForm {
  markAsFinished: Function
  landingPageData: LandingPageProps
  showUserInfoSection?: Boolean
}

export function LandingPageStepForm({
  markAsFinished,
  landingPageData,
  showUserInfoSection = true,
}: LandingPageStepForm) {
  const [form] = Form.useForm()

  const [imageUrl, setImageUrl] = useState<ImageFile[]>([])
  const [selectedCountry, setSelectedCountry] = useState('brasil')

  const [hasStepOneFormBeenFilled, setHasStepOneFormBeenFilled] = useState<boolean>(false)
  const [hasStepTwoFormBeenFilled, setHasStepTwoFormBeenFilled] = useState<boolean>(false)

  const { data: userMe } = useFetchUserMe()
  const { data: addressData } = useFetchUserMeAddress()

  const { mutateAsync: registerUser, isPending: isPendingRegisterUser } = useRegisterUserLandingPage()
  const { mutateAsync: updateUser, isPending: isPendingUpdateUser } = useUpdateUserLandingPage()

  const logedIn = !!userMe

  function setStepsBeenFilledFalse() {
    setHasStepOneFormBeenFilled(false)
    setHasStepTwoFormBeenFilled(false)
  }

  const steps = [
    {
      title: <Title>Identificação</Title>,
      content: (
        <StepOneLandingPage
          landingPageData={landingPageData}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      ),
      icon: <UserOutlined />,
    },
    {
      title: <Title>Endereço</Title>,
      content: (
        <AddressForm
          form={form}
          showBrazil={selectedCountry === 'brasil'}
          hasFormBeenFetchedFromApi={hasStepTwoFormBeenFilled}
        />
      ),
      icon: <HomeOutlined />,
    },
  ]

  const { currentStep, next, prev, isFirstStep, isLastStep } = useMultistepForm(steps, form)

  async function handleFinish() {
    await form.validateFields()

    const formFields = form.getFieldsValue([
      'nome',
      'email',
      'cpf',
      'data_nascimento',
      'telefone',
      'sexo',
      'password',
      'cep',
      'estado',
      'cidade',
      'bairro',
      'rua',
      'numero',
      'complemento',
    ])

    const campanhaId = landingPageData?.campanha_id

    const formattedData = {
      ...formFields,
      pais: selectedCountry,
      data_nascimento: moment(formFields.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      brasileiro: selectedCountry === 'brasil',
      tipo_clifor: 'f',
      cep: formFields?.cep?.replace(/\D/g, ''),
      cpf: formFields?.cpf?.replace(/\D/g, ''),
      telefone: formFields?.telefone?.replace(/\D/g, ''),
      fk_empresa_id: 1,
      fk_landpage_id: landingPageData?.id,
      fk_campanha_id: campanhaId,
    }

    try {
      if (logedIn) {
        await updateUser({
          value: formattedData,
          image: imageUrl?.[0]?.file,
          campanhaId,
        })
      } else {
        await registerUser({
          value: formattedData,
          image: imageUrl?.[0]?.file,
          campanhaId,
        })
      }

      const message = logedIn ? 'Cadastro atualizado com sucesso' : 'Cadastro realizado com sucesso'
      notification.success({ message })

      markAsFinished()
    } catch (error) {
      console.error(error)
    }
  }

  // load form data when user is logged:
  useEffect(() => {
    if (logedIn && !hasStepOneFormBeenFilled) {
      form.setFieldsValue({
        nome: userMe?.nome,
        email: userMe?.email,
        numero_documento: handleCpfShowValues(userMe?.numero_documento),
        data_nascimento: userMe?.data_nascimento ?? null,
        sexo: userMe?.sexo,
        pais: userMe?.pais,
        cpf: userMe?.numero_documento,
        ...addressData,
        telefone:
          selectedCountry === 'brasil'
            ? userMe.telefone.replace(/\D/g, '').replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
            : userMe.telefone,
      })
      setHasStepOneFormBeenFilled(true)
    }

    if (addressData && !hasStepTwoFormBeenFilled) {
      form.setFieldsValue({
        ...addressData,
      })
      setHasStepTwoFormBeenFilled(true)
    }
  }, [form, userMe, addressData])

  return (
    <StepWrapper data-testid="LandingPageStepForm">
      {showUserInfoSection && (
        <UserInfoSection prev={prev} form={form} setStepsBeenFilledFalse={setStepsBeenFilledFalse} />
      )}
      <StepsProgress steps={steps} currentStep={currentStep} />
      <StepsContent steps={steps} currentStep={currentStep} form={form} />
      <StepsButtons
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        next={next}
        prev={prev}
        handleFinish={handleFinish}
        isPending={isPendingRegisterUser || isPendingUpdateUser}
      />
    </StepWrapper>
  )
}
