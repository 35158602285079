import { Spin } from 'antd'
import styled from 'styled-components'

export const BaseSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: var(--text-main-color);
  }

  .ant-spin-text {
    font-size: 16px;
    color: var(--text-main-color);
    margin-top: 10px;
    font-weight: bold;
  }
`
