import { useQuery } from '@tanstack/react-query'

import { DataService } from '../../../../../lib/dataService'

export function useFetchImportedUsers(filters) {
  const fetchImportedUsers = async () => {
    const response = await DataService.get(`administradores/listar-leads?${filters?.queryString || ''}`)
    return response?.data
  }

  const importedUsers = useQuery({
    queryKey: ['IMPORTED_USERS', filters],
    queryFn: fetchImportedUsers,
  })

  return importedUsers
}
