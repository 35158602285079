import { FilterOutlined } from '@ant-design/icons'
import { DatePicker, Skeleton } from 'antd'
import styled from 'styled-components'
import BaseCard from '../../components/card/BaseCard'
import { BaseSelect } from '../../components/select/BaseSelect'

const { RangePicker } = DatePicker

export const CardHeader = styled.div`
  margin-top: 1rem;
  background: white;
  padding: 1rem 0 0.4rem 1rem;
  margin-bottom: 0.1rem;
  border-radius: 15px 15px 0 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChartWrapper = styled.div`
  height: ${(props) => props.height || '272px'};
  background-color: ${(props) => props.backgroundColor || 'white'};
  position: relative;
`

export const PercentageContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 25px;

  h1 {
    font-size: 30px;
    position: relative;
    color: white;
  }

  span {
    color: white;
    position: absolute;
    top: 60px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

export const CardBody = styled.div`
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height};
`

export const ChartContainer = styled.div``

export const StyledTooltip = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundColor};
  border: 1px solid #000000;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.64);
  opacity: 1;
`

export const StyledSelect = styled(BaseSelect)`
  min-width: ${(props) => props.minWidth || '92px'};
  max-width: ${(props) => props.maxWidth};
  margin-right: 1rem;

  .ant-select-selector {
    max-height: 41px;
    border: solid #989898 1px !important;
    color: #bebebe;
  }

  &.ant-select-multiple .ant-select-selector {
    display: flex;
    justify-content: center;
    min-width: 230px;
    max-width: 230px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterTitle = styled.strong`
  color: #8e8e8e;
  margin-right: 0.8rem;
  align-self: center;
`

export const CenterText = styled.text`
  /* text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5); */
  fill: ${(props) => props.fill};
  text-anchor: middle;
  dominant-baseline: central;
`

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

export const CardTitle = styled.div`
  font-size: 20;
`

export const NoContentTable = styled.div`
  color: var(--text-main-color);
  padding: 50px;
`

export const StyledRangePicker = styled(RangePicker)`
  border: solid #989898 1px !important;
  /* color: #bebebe;
  font-weight: 600; */
`

export const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    min-width: 40px;
    &.ant-picker input {
      color: rgb(190, 190, 190) !important;
    }
  }

  border: solid #989898 1px !important;
  width: 140px;
  height: 40px;
`

export const StyledMenuFinance = styled.div`
  .ant-select-item-option {
    /* .ant-select-item-option-content {
      color: #000;
      background: transparent;
    } */

    &.ant-select-item-option-selected .ant-select-item-option-content {
      color: #388915 !important;
    }
  }

  /* check icon */
  .ant-select-item-option-state {
    color: #388915 !important;
    display: grid;
    place-items: center;
  }
`

export const formatChartNumber = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}mi`
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(0)}k`
  }
  return value
}

export const DashboardWrapper = styled.div`
  display: flex;
  height: 95vh;
  width: 100%;

  @media (max-width: 992px) {
    padding: 0;
    height: auto;
    flex-direction: column;
    gap: 3rem;
  }
`

export const LeftSection = styled.div`
  width: 100%;
  flex: 2;
  overflow-y: auto;
  background-color: var(--background-color);
  padding: 2rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  scrollbar-width: none;

  @media (max-width: 991px) {
    padding: 1rem 1rem;
    /* padding: 1.5rem 1.5rem 0rem 1.5rem; */
    overflow-y: visible;
  }

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 3rem;
    min-width: 100%;
    max-width: 90rem;
    margin: 0;
  }

  @media (min-width: 2400px) {
    > div {
      margin: 0 auto;
    }
  }
`

export const RightSection = styled.div`
  scrollbar-width: none;
  flex: 1;
  overflow-y: auto;
  height: 100%;
  background-color: var(--right-section-bg-color);
  padding: 2rem 0;

  border-radius: 12px;

  > div {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 60rem;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    > div {
      padding: 0 1rem;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    margin: 0 2.1rem;
  }
`

export const AnimatedBaseCard = styled(BaseCard)`
  transition: 0.3s ease; /* Smooth transition for scaling */
  overflow: visible;
  padding: 0;

  background-color: var(--animated-card-bg-color);

  @media (min-width: 480px) {
    &:hover {
      padding: 0.5rem;
    }
  }
`

export const StyledFilterIcon = styled(FilterOutlined)`
  position: absolute;
  top: 40px;
  right: 30px;
  color: var(--filter-icon-color);
  font-size: 1rem;
  cursor: pointer;
  z-index: 999;

  @media (max-width: 480px) {
    top: 40px;
    right: 8px;
  }
`

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 576px) {
    flex-direction: column;

    .ant-select {
      width: 100%;
    }
  }
`

export const Label = styled.label`
  margin-bottom: 0.5rem;
  display: block;
`

export const TooltipContainer = styled.div`
  /* prop signup or donation  */
  background: var(--chart-tooltip-bg);
  color: ${({ gender, type }) => (gender === 'Mulheres' ? 'hsl(303, 43%, 68%)' : `var(--${type}-chart-color)`)};
  border: solid #585858 2px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .finance_nao_pago {
    color: var(--error-color);
  }
`

export const TodayDonationTooltipContainer = styled.div`
  /* color: red; */
  background: var(--chart-tooltip-bg);
  border: solid #585858 2px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

export const RadarContainer = styled.div`
  /* cursor: pointer; */
  height: 25rem;

  g text {
    fill: var(--chart-pie-legend-color) !important;
    display: none;
  }
`

export const ErrorMessage = styled.div.attrs({
  children: <p>Ocorreu um erro ao listar os dados</p>,
})`
  color: red;
  height: ${({ height }) => height || '25rem'};
  align-items: center;
  justify-content: center;

  p {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    font-size: 1.5rem;
    margin: 0;
  }
`

const LoadingContainerStyled = styled.div`
  height: 25rem;
  display: grid;
  place-items: center;
  padding: 1rem;
`

export const LoadingContainer = () => {
  return (
    <LoadingContainerStyled>
      <Skeleton active paragraph={{ rows: 6 }} />
    </LoadingContainerStyled>
  )
}

export const PieChartWrapper = styled.div`
  height: 22rem;
  display: flex;
  flex-direction: column;
  padding: 0rem;
`

export const PieChartContainer = styled.div`
  width: 100%;
  height: 18rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: visible;

  text {
    tspan {
      transition: 0.1s;

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
`

export const PieChartTitle = styled.h2`
  position: relative;
  padding: 0.6rem;

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }

  @media (min-width: 481px) {
    font-size: 1.3rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }
`

export const ChartTitle = styled(PieChartTitle)`
  padding: 2rem;

  @media (max-width: 480px) {
    padding: 2rem 1rem;
  }
`

export const SliderChartTitle = styled.div`
  color: var(--text-secondary-color) !important;
  font-size: 24px !important;
  font-weight: bold;
  padding: 1rem;
  position: absolute;
  width: 100%;

  .title-and-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 24px;
    margin-top: 0.5rem;
    line-height: 0;
    /* white-space: nowrap;
    flex-wrap: nowrap; */

    .title {
      line-height: 0.4;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  .percentage {
    font-size: 14px;
    font-weight: normal;
    text-align: end;

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }

  .balance-value {
    margin-top: 0.4rem;
    @media (max-width: 480px) {
      font-size: 13.5px !important;
      margin-top: 0.2rem;
      /* line-height: 20px !important; */
      font-weight: 400 !important;
    }
  }

  .total-value {
    font-size: 28px;
    color: var(--text-main-color);

    @media (max-width: 480px) {
      margin-top: 0.2rem;
      font-size: 19px;
    }
  }

  @media (max-width: 480px) {
    svg {
      font-size: 13.5px !important;
    }
  }
`

export const PieChartLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  overflow: hidden;
`

export const PieChartLegendItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  color: var(--chart-pie-legend-color);

  &:hover {
    color: ${({ color }) => color};
  }

  span {
    white-space: nowrap;
  }
`

export const PieChartLegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`

export const Tooltip = styled.div`
  color: white;
  background: ${({ color }) => color};
  border: 1px solid #000;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0.9;
`

export const paymentMethodOptions = [
  { value: '', label: 'Todos' },
  { value: '1', label: 'Cartão de Crédito' },
  { value: '2', label: 'Pix' },
  { value: '3', label: 'Boleto' },
]

export const TooltipDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: var(--separator-color);
  margin: 0 1rem 0.8rem 0;
`

/* <div
            style={{
              width: '100%',
              height: '2px',
              backgroundColor: 'var(--separator-color)',
              margin: '0rem 1rem 0.8rem 0rem',
            }}
          />; */
