import { HandHeart, Package, UserGear, UsersFour } from '@phosphor-icons/react'
import { GroupCard } from '../../components/GroupCard'
import { BaseSegmented } from '../../components/segmented'
import { Col } from 'antd'
import { FamiliaPage } from './Familia'
import { ItensPage } from './Itens'
import { useHistory, useLocation } from 'react-router-dom'
import {
  GetEstatisticasFamiliaResponse,
  useGetEstatisticasFamilia,
} from '../../hooks/agape/queries/useGetEstatisticasFamilia'
import {
  GetEstatisticasEstoqueResponse,
  useGetEstatisticasEstoque,
} from '../../hooks/agape/queries/useGetEstatisticasEstoque'
import { DoacaoPage } from './Doacao'
import { PermissionsResponse, useGetPermissions } from '../../hooks/users/Queries/useGetSelfPermissions'
import { UseQueryResult } from '@tanstack/react-query'
import { GestaoPage } from './Gestao'
import { useFetchUserMe } from '../../hooks'

type ItemKeys = 'familia' | 'itens' | 'doacao' | 'gestao'

function useSelectedPage(location: string): ItemKeys {
  if (location.includes('doacao')) return 'doacao'
  if (location.includes('itens')) return 'itens'
  if (location.includes('gestao')) return 'gestao'
  return 'familia'
}

function hasAccessTo(key: ItemKeys, permissions: PermissionsResponse['permissoes'], isAdmin: boolean) {
  const accessMap = {
    familia: permissions?.familia_agape?.acessar === 1,
    itens: permissions?.estoque_agape?.acessar === 1,
    doacao: permissions?.doacao_agape?.acessar === 1 && permissions?.acao_doacao_agape?.acessar === 1,
    gestao: isAdmin,
  }
  return accessMap[key]
}

function getPageContent(hasAccess: any) {
  return {
    familia: hasAccess('familia') ? <FamiliaPage /> : null,
    itens: hasAccess('itens') ? <ItensPage /> : null,
    doacao: hasAccess('doacao') ? <DoacaoPage /> : null,
    gestao: hasAccess('gestao') ? <GestaoPage /> : null,
  }
}

function getOptions(
  hasAccess: any,
  estatisticasFamilia: UseQueryResult<GetEstatisticasFamiliaResponse, Error>,
  estatisticasEstoque: UseQueryResult<GetEstatisticasEstoqueResponse, Error>,
) {
  return [
    hasAccess('familia') && {
      label: (
        <Col xs={24}>
          <GroupCard
            title="Famílias"
            icon={UsersFour}
            number={estatisticasFamilia?.data?.familias_cadastradas}
            balance={undefined}
            isLoading={estatisticasFamilia.isLoading}
            isError={estatisticasFamilia.isError}
          />
        </Col>
      ),
      value: 'familia',
    },
    hasAccess('itens') && {
      label: (
        <Col xs={24}>
          <GroupCard
            title="Itens"
            icon={Package}
            number={estatisticasEstoque?.data?.itens_em_estoque}
            balance={undefined}
            isLoading={estatisticasEstoque.isLoading}
            isError={estatisticasEstoque.isError}
          />
        </Col>
      ),
      value: 'itens',
    },
    hasAccess('gestao') && {
      label: (
        <Col xs={24}>
          <GroupCard
            title="Voluntários"
            icon={UserGear}
            number={'Gestão'}
            balance={undefined}
            isLoading={false}
            isError={false}
          />
        </Col>
      ),
      value: 'gestao',
    },
    hasAccess('doacao') && {
      label: (
        <Col xs={24}>
          <GroupCard
            title="Doação"
            icon={HandHeart}
            number={'Realizar doação'}
            balance={undefined}
            isLoading={false}
            isError={false}
          />
        </Col>
      ),
      value: 'doacao',
    },
  ].filter(Boolean) as { label: JSX.Element; value: string }[]
}

export function AgapeAssistencia() {
  const { data: userMe } = useFetchUserMe()

  const location = useLocation()
  const history = useHistory()

  const { data: permissions } = useGetPermissions()

  const itemSelecionado = useSelectedPage(location.pathname)

  const isAdmin = userMe?.fk_perfil_id === 11 || userMe?.fk_perfil_id === 1 // Administrador Ágape ou Administrador

  const hasAccess = (key: ItemKeys) => hasAccessTo(key, permissions?.permissoes!, isAdmin)

  const estatisticasFamilia = useGetEstatisticasFamilia()

  const estatisticasEstoque = useGetEstatisticasEstoque()

  const itens = getPageContent(hasAccess)
  const options = getOptions(hasAccess, estatisticasFamilia, estatisticasEstoque)

  if (permissions && !itens[itemSelecionado]) {
    history.push('/admin/permission-denied')
  }

  return (
    <>
      <h2 style={{ fontSize: '1.6rem' }}>Projeto Ágape</h2>
      <BaseSegmented value={itemSelecionado} onChange={(value) => history.push(`${value}`)} options={options} />

      <div style={{ marginTop: '2rem', minHeight: '80vh', padding: '1rem' }}>{itens[itemSelecionado]}</div>
    </>
  )
}
