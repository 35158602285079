import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export type CiclosDoacoesStatus = 'nao_iniciado' | 'em_andamento' | 'finalizado'

export interface GetCiclosDoacoesProps {
  filters?: {
    queryString?: string | null
    fk_acao_agape_id?: number | null
    status?: CiclosDoacoesStatus
    page?: number
    per_page?: number
  }
}

interface GetCiclosDoacoesResponse {
  ciclos: {
    id: number
    nome_acao_agape: string
    status: CiclosDoacoesStatus
  }[]
  page: number
  total: number
}

export function useGetCiclosDoacoes({ filters }: GetCiclosDoacoesProps) {
  const ciclosDoacoes = useQuery({
    queryKey: ['CICLOS_DOACOES', filters],
    queryFn: async () => {
      const response = await DataService.get<GetCiclosDoacoesResponse>(
        `/agape/listar-ciclos-acoes-agape?${filters?.queryString ?? ''}`,
      )

      return response.data
    },
  })

  return ciclosDoacoes
}
