import { Card } from 'antd'
import styled from 'styled-components'

export const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 3rem;

  @media (max-width: 576px) {
    /* padding: 0 1rem; */
    padding: 0 0.5rem;
  }
`

export const CampaignTitleContainer = styled.div`
  font-size: 36px;
  color: ${({ theme }) => theme.fonts.color.primary};
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 900;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 8rem;

  @media (max-width: 576px) {
    margin-bottom: 4rem;
    font-size: 30px;
  }
`

export const SubtitleContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: 36px;
  font-weight: 700;
  color: #434343;
  margin-bottom: 4rem;
  max-width: 700px;

  @media (max-width: 576px) {
    font-size: 24px;
    margin-bottom: 2rem;
  }
`

export const PixTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: 24px;
  font-weight: 700;
  color: #1f1f1f;
  margin-bottom: 1.5rem;
  text-align: center;
  max-width: 700px;
  color: #454545;

  @media (max-width: 576px) {
    font-size: 18px;
    margin-bottom: 2rem;
  }
`

export const PixText = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: 18px;
  font-weight: 600;
  color: #454545;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 700px;

  @media (max-width: 576px) {
    font-size: 18px;
    margin-bottom: 2rem;
  }
`

export const SignInTitleContainer = styled.div`
  color: #626262;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2.5rem;

  div {
    display: inline;
    margin-right: 10px;
  }

  span {
    color: white;
    background-color: #1f1f1f;
    padding: 3px 10px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
`

export const StyledGrid = styled.div<{ columns: number }>`
  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};

    column-gap: 2rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }
`

export const CardDescriptionContainer = styled.div`
  max-width: 25rem;
  overflow: hidden;
`

export const CardTitleContainer = styled.div`
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: #404040;
`

export const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledCard = styled(Card)`
  min-width: 450px;
  overflow: hidden !important;
  border-end-start-radius: 16px !important;
  border-end-end-radius: 16px !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;

  .ant-card-cover {
    width: 100%;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    min-width: auto;
    max-width: 450px !important;
    margin-top: 2.5rem;
  }
`

export const FormImageContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 5rem;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    /* flex-direction: column-reverse; */
  }
`
