import FeatherIcon from 'feather-icons-react'

export const handleStatusMask = (status: string) => {
  switch (status) {
    case 'Pago':
      return <FeatherIcon icon="check-circle" fill="var(--success-color)" />
    case 'Em processamento':
      return <FeatherIcon icon="loader" fill="red" />
    case 'Não efetuado':
    case 'Expirado':
      return <FeatherIcon icon="x-circle" fill="var(--error-color)" />
    default:
      return '---'
  }
}
