import { useEffect, useState } from 'react'
import { Form, FormInstance, Input } from 'antd'
import { formatCEP } from '../../../../../../utility/utility'
import { StyledGrid } from '../../styles'
import ReactInputMask from 'react-input-mask'
import { useFetchUserMeAddress } from '../../../../../admin/hooks'
import { fillAddressFromCep } from '../../../../../benfeitor/pages/Campaign/CampaignIdentification/utils'
import { DataService } from '../../../../../../lib/dataService'

interface AddressAutoComplete {
  bairro: string
  cidade: string
  estado: string
  rua: string
}

interface IAddressForm extends AddressAutoComplete {
  numero: number
  complemento: string
  cep: string
}

interface AddressFormProps {
  form: FormInstance<IAddressForm>
  style?: React.CSSProperties
  commonInputStyles?: any
  showBrazil?: boolean
  hasFormBeenFetchedFromApi?: boolean
  title?: React.ReactNode
}

export function AddressForm({
  form,
  style,
  commonInputStyles = {},
  showBrazil = false,
  hasFormBeenFetchedFromApi = false,
  title,
}: Readonly<AddressFormProps>) {
  const [disabledFields, setDisabledFields] = useState<string[]>([])
  const { data: userMeAddress } = useFetchUserMeAddress()
  const selectedCountry = Form.useWatch('pais', form)

  const handleCepChange = async () => {
    fetchAddress()
  }

  const fetchAddress = async () => {
    try {
      const { filledFields } = await fillAddressFromCep({ form })
      setDisabledFields(filledFields)
    } catch (error) {
      console.error('Error fetching address from CEP:', error)
    }
  }

  async function disableFieldsComingFromApi() {
    const { data: addressAutoComplete }: { data: AddressAutoComplete } = await DataService.get(
      `/services/search-cep/${form.getFieldValue('cep')}`,
    )

    const filledFields = Object.keys(addressAutoComplete).filter(
      (key) => addressAutoComplete[key as keyof AddressAutoComplete],
    )

    setDisabledFields(filledFields)
  }

  useEffect(() => {
    disableFieldsComingFromApi()

    if (!hasFormBeenFetchedFromApi && userMeAddress) {
      form.setFieldsValue({
        bairro: userMeAddress?.bairro,
        cep: formatCEP(userMeAddress?.cep),
        cidade: userMeAddress?.cidade,
        complemento: userMeAddress?.complemento,
        estado: userMeAddress?.estado,
        numero: userMeAddress?.numero,
        rua: userMeAddress?.rua,
      })
    }
  }, [form, userMeAddress, hasFormBeenFetchedFromApi])

  const estadosBrasil = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]

  return (
    <>
      {title}
      <div style={style}>
        {selectedCountry === 'brasil' || showBrazil ? (
          <div>
            <StyledGrid columns={1}>
              <Form.Item
                name="cep"
                label="CEP"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  flexDirection: 'column',
                }}
                rules={[
                  { required: true, message: 'Por favor, insira seu CEP!' },
                  { pattern: /^[0-9]{5}-[0-9]{3}$/, message: 'Formato inválido! Utilize 00000-000.' },
                ]}
              >
                <ReactInputMask mask="99999-999" maskChar={null} placeholder="00000-000" onChange={handleCepChange}>
                  {(inputProps) => <Input {...inputProps} {...commonInputStyles} minWidth={20} />}
                </ReactInputMask>
              </Form.Item>
            </StyledGrid>

            <StyledGrid columns={3}>
              <Form.Item
                name="estado"
                label="Estado"
                rules={[
                  { required: true, message: 'Por favor, insira seu estado!' },
                  {
                    pattern: new RegExp(`^(${estadosBrasil.join('|')})$`, 'i'),
                    message: 'O estado deve ser uma abreviação de 2 letras (ex: SP, RJ)!',
                  },
                ]}
              >
                <Input {...commonInputStyles} placeholder="Apenas texto" disabled={disabledFields.includes('estado')} />
              </Form.Item>

              <Form.Item
                name="cidade"
                label="Cidade"
                rules={[
                  { required: true, message: 'Por favor, insira sua cidade!' },
                  { min: 5, message: 'A cidade deve ter pelo menos 5 caracteres!' },
                  { max: 32, message: 'A cidade deve ter no máximo 32 caracteres!' },
                  {
                    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
                    message: 'A cidade deve conter apenas letras!',
                  },
                ]}
              >
                <Input {...commonInputStyles} placeholder="Apenas texto" disabled={disabledFields.includes('cidade')} />
              </Form.Item>

              <Form.Item
                name="bairro"
                label="Bairro"
                rules={[
                  { required: true, message: 'Por favor, insira seu bairro!' },
                  { min: 3, message: 'O bairro deve ter pelo menos 3 caracteres!' },
                  { max: 50, message: 'O bairro deve ter no máximo 50 caracteres!' },
                  {
                    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
                    message: 'O bairro deve conter apenas letras!',
                  },
                ]}
              >
                <Input {...commonInputStyles} placeholder="Apenas texto" disabled={disabledFields.includes('bairro')} />
              </Form.Item>
            </StyledGrid>

            <StyledGrid columns={3}>
              <Form.Item
                name="rua"
                label="Rua"
                rules={[
                  { required: true, message: 'Por favor, insira sua rua!' },
                  { min: 3, message: 'O nome da rua deve ter pelo menos 3 caracteres!' },
                  { max: 100, message: 'O nome da rua deve ter no máximo 100 caracteres!' },
                  {
                    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
                    message: 'A rua deve conter apenas letras!',
                  },
                ]}
              >
                <Input {...commonInputStyles} placeholder="Logradouro" disabled={disabledFields.includes('rua')} />
              </Form.Item>

              <Form.Item
                name="numero"
                label="Número"
                rules={[
                  { required: true, message: 'Por favor, insira seu número!' },
                  { max: 8, message: 'Máximo de 8 dígitos' },

                  { pattern: /^[0-9]*$/, message: 'Apenas números são permitidos!' },
                ]}
              >
                <Input {...commonInputStyles} placeholder="000" />
              </Form.Item>

              <Form.Item
                name="complemento"
                label="Complemento"
                rules={[{ max: 60, message: 'O nome da rua deve ter no máximo 60 caracteres!' }]}
              >
                <Input {...commonInputStyles} placeholder="Casa ou apt" />
              </Form.Item>
            </StyledGrid>
          </div>
        ) : (
          <StyledGrid columns={1}>
            <Form.Item
              name="detalhe_estrangeiro"
              label="Código postal ou ZIP Code"
              rules={[
                { required: true, message: 'Por favor, insira sua localização!' },
                { min: 4, message: 'Máximo de 4 caracteres' },
                { max: 100, message: 'Máximo de 100 caracteres' },
              ]}
            >
              <Input {...commonInputStyles} lineHeight={2.2} placeholder="Insira o estado ou região" />
            </Form.Item>
          </StyledGrid>
        )}
      </div>
    </>
  )
}
