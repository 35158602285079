import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CarteiraPrimeiraEtapa } from '../../../container/benfeitor/pages/Carteira/PrimeiraEtapa';
import { CarteiraConfirmada } from '../../../container/benfeitor/pages/Carteira/Confirmada';
import { CarteiraCadastro } from '../../../container/benfeitor/pages/Carteira/Cadastro';

export function CarteiraRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/primeira-etapa`} component={CarteiraPrimeiraEtapa} />
      <Route path={`${path}/cadastro`} component={CarteiraCadastro} />
      <Route path={`${path}/confirmada`} component={CarteiraConfirmada} />
    </Switch>
  );
}
