import { useEffect, useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { formatNumber, formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import {
  PieChartContainer,
  PieChartTitle,
  PieChartLegendContainer,
  PieChartLegendItem,
  PieChartLegendColor,
} from '../../../styles'
import { useBlur } from '@/context/Blur/BlurContext'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function Chart({ data }) {
  const { isBlurred } = useBlur()

  const formattedData = [
    {
      id: 'doacoes_unicas',
      label: 'Doações Avulsas',
      value: data?.donations_one_time?.avulsa_total,
      percentage: data?.donations_one_time?.avulsa_percentage,
      count: data?.donations_one_time?.avulsa_count,
    },
    {
      id: 'doacoes_recorrentes',
      label: 'Doações Recorrentes',
      value: data?.donations_recurrent?.recorrente_total,
      percentage: data?.donations_recurrent?.recorrente_percentage,
      count: data?.donations_recurrent?.recorrente_count,
    },
  ]

  const [chartState, setChartState] = useState({
    value: 'R$ 0.00',
    percentage: '0%',
    count: 0,
    color: '#4f95eb',
    label: 'Sem Dados...',
  })

  useEffect(() => {
    if (formattedData && formattedData.length > 0) {
      setChartState({
        value: formatToBrazilianCurrency(formattedData?.[0].value),
        percentage: `${formattedData?.[0].percentage}%`,
        count: formattedData?.[0].count,
        color: '#4f95eb',
        label: formattedData?.[0].label,
      })
    }
  }, [data])

  const renderCustomLegend = () => {
    const colors = ['#4f95eb', '#DDA612']

    return (
      <PieChartLegendContainer>
        {formattedData.map((datum, index) => (
          <PieChartLegendItem
            key={datum.id}
            color={colors[index]}
            onMouseEnter={() => {
              setChartState({
                value: formatToBrazilianCurrency(datum.value),
                percentage: `${datum.percentage}%`,
                count: datum.count,
                color: colors[index],
                label: datum.label,
              })
            }}
          >
            <PieChartLegendColor color={colors[index]} />
            <span style={{ marginLeft: '5px' }}>{datum.label}</span>
          </PieChartLegendItem>
        ))}
      </PieChartLegendContainer>
    )
  }

  return (
    <>
      <PieChartTitle>Avulsas e Recorrentes</PieChartTitle>
      <PieChartContainer>
        <ResponsivePie
          responsive={true}
          margin={{ top: 30, right: 0, bottom: 30, left: 0 }}
          startAngle={-90}
          endAngle={270}
          innerRadius={0.9}
          padAngle={1}
          cornerRadius={2}
          fit={false}
          activeOuterRadiusOffset={12}
          colors={['#4f95eb', '#DDA612']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', '0.2']] }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsStraightLength={25}
          arcLinkLabelsColor="black"
          enableArcLabels={true}
          arcLabelsTextColor="#ffffff"
          tooltip={({ datum }) => {
            return (
              <div
                style={{
                  color: 'white',
                  background: datum.color,
                  border: '1px solid #000000',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  opacity: '0.9',
                }}
              >
                <div>
                  <strong>
                    {datum.label}
                    <br />
                    <BlurDiv>
                      {formatToBrazilianCurrency(datum.value)} ({datum.data.percentage}%)
                    </BlurDiv>
                  </strong>
                </div>
              </div>
            )
          }}
          onMouseEnter={(node) => {
            setChartState({
              value: formatToBrazilianCurrency(node.data.value),
              percentage: `${node.data.percentage}%`,
              color: node.color,
              count: node.data.count,
              label: node.data.label,
            })
          }}
          legends={[]}
          layers={[
            'arcs',
            'arcLinkLabels',
            'legends',
            ({ centerX, centerY, radius }) => (
              <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fill: chartState?.color }}
                className={isBlurred ? 'blur' : 'no-blur'}
              >
                <tspan x={centerX} dy={-20} style={{ fontWeight: 'bold', fontSize: radius * 0.18 }}>
                  {chartState?.value}
                </tspan>
                <tspan x={centerX} dy={35} style={{ fontWeight: 'bold', fontSize: radius * 0.09 }}>
                  {formatNumber(chartState?.count)} {chartState?.label} | {chartState?.percentage}
                </tspan>
              </text>
            ),
          ]}
          data={formattedData || []}
        />
      </PieChartContainer>
      {renderCustomLegend()}
    </>
  )
}
