import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts'
import { useState } from 'react'
import {
  ChartTitle,
  PieChartLegendColor,
  PieChartLegendItem,
  TooltipContainer,
  formatChartNumber,
} from '../../../styles'
import { formatNumber, formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { useBlur } from '@/context/Blur/BlurContext'

export function Chart({ campaignData }) {
  const [barHovered, setBarHovered] = useState(false)

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      const campanha = payload[0]?.payload?.titulo
      const valor = payload[0]?.payload?.valor_arrecadado
      const qnt_doacoes = payload[0]?.payload?.qnt_doacoes
      const color = payload[0]?.payload?.cor

      return (
        <TooltipContainer type="finance">
          <div style={{ color }}>
            <strong>{campanha}</strong>
          </div>
          <BlurDiv>
            <div style={{ color }}>
              Valor: <strong>{formatToBrazilianCurrency(valor)}</strong>
            </div>
            <div style={{ color }}>
              Doações: <strong>{formatNumber(qnt_doacoes)}</strong>
            </div>
          </BlurDiv>
        </TooltipContainer>
      )
    }

    return null
  }

  const CustomLabel = ({ x, y, width, height, value }) => {
    const { isBlurred } = useBlur()

    const adjustedX = x + width + 10
    const adjustedY = y + height / 2 + 3

    return (
      <text
        x={adjustedX}
        y={adjustedY}
        fill="var(--text-main-color)"
        textAnchor="start"
        className={isBlurred ? 'blur' : 'no-blur'}
        fontSize={12}
      >
        {formatToBrazilianCurrency(value)}
      </text>
    )
  }

  const CustomAxis = ({ x, y, stroke, payload }) => {
    const { isBlurred } = useBlur()

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="var(--text-main-color)"
          className={isBlurred ? 'blur' : 'no-blur'}
        >
          {formatChartNumber(payload.value)}
        </text>
      </g>
    )
  }

  return (
    <>
      <ChartTitle>Doações por Campanha</ChartTitle>

      <div style={{ width: '100%', height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          {campaignData?.length === 0 ? (
            <div style={{ height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4 style={{ margin: '0', color: 'gray', padding: 30, textAlign: 'center' }}>
                Por enquanto não há dados a serem carregados
              </h4>
            </div>
          ) : (
            <BarChart
              layout="vertical"
              data={campaignData || []}
              margin={{ top: 0, left: 150, right: 80, bottom: 25 }}
              barCategoryGap="40px"
            >
              <CartesianGrid stroke="#444" horizontal={false} strokeOpacity={0.6} />

              <YAxis
                type="category"
                dataKey="titulo"
                interval={0}
                tickLine={false}
                tick={{
                  fill: '#6d6d6d',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  width: 200,
                }}
                angle={-20}
              />

              <XAxis type="number" axisLine={false} tick={<CustomAxis />} />

              <Tooltip cursor={false} active={barHovered} content={CustomTooltip} />

              <Bar
                dataKey="valor_arrecadado"
                radius={[0, 8, 8, 0]}
                barSize={20}
                minPointSize={10}
                label={<CustomLabel />}
                onMouseEnter={() => setBarHovered(true)}
                onMouseLeave={() => setBarHovered(false)}
              >
                {campaignData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.cor} />
                ))}
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
      <div style={{ padding: '1rem' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: '1rem',
            textAlign: 'left',
          }}
        >
          {campaignData.map((item, index) => (
            <PieChartLegendItem key={item.id} color={item.cor}>
              <PieChartLegendColor color={item.cor} />
              <span>
                {index + 1}º - {item.titulo}
              </span>
            </PieChartLegendItem>
          ))}
        </div>
      </div>
    </>
  )
}
