import React from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
import { ProfileAddress } from '../../container/admin/pages/profile/ProfileAddress'
import { ProfileLayout } from '../../container/admin/pages/profile/ProfileLayout'
import { ProfileView } from '../../container/admin/pages/profile/ProfileView'
import { ProfileDonations } from '../../container/admin/pages/profile/ProfileDonations'

export const Profile = () => {
  const { path } = useRouteMatch()
  return (
    <ProfileLayout>
      <Switch>
        <Route path={`${path}/view`} component={ProfileView} />
        <Route path={`${path}/address`} component={ProfileAddress} />
        <Route path={`${path}/donations`} component={ProfileDonations} />
      </Switch>
    </ProfileLayout>
  )
}
