import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { useEffect } from 'react'
import { UserMeResponse } from '../Queries/useFetchUserMe'

interface RegisterPresenceData {
  fk_campanha_id: number
  user_id: number
}

const registerPresence = async (data: RegisterPresenceData): Promise<void> => {
  if (data.user_id == null) return
  await DataService.post('/users/registrar-presenca', data)
}

function useRegisterUserPresence() {
  return useMutation({
    mutationFn: registerPresence,
  })
}

export function useRegisterPresenceEffect({ userMe }: { userMe: UserMeResponse | undefined }) {
  const { mutate: registerPresence } = useRegisterUserPresence()

  useEffect(() => {
    if (!userMe) return

    registerPresence({ user_id: userMe.id, fk_campanha_id: 43 })
  }, [userMe])
}
