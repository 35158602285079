import styled from 'styled-components'

export const FourthSectionStyled = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;

  color: #fbf9f3;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  .header {
    font-size: 40px;
    line-height: 40px;
    text-align: left;
  }

  .card {
    padding: 2rem 1.5rem;
    background-color: #131d2d;
    border: solid #c98f00 2px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  span {
    font-weight: 700;
  }

  p {
    margin-bottom: 0rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    list-style: none;

    li {
      position: relative;
      padding-left: 1rem;

      &::before {
        content: '•';
        color: #c98f00;
        position: absolute;
        left: 0;
      }
    }
  }

  @media (min-width: 900px) {
    .card {
      max-width: 765px;
      margin-left: auto;
      margin-right: auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      font-size: 64px;
      line-height: 76px;
      text-align: center;
    }

    p {
      font-size: 32px;
      line-height: 38px;
      max-width: 605px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    ul {
      gap: 2.5rem;
      font-size: 28px;
      line-height: 33px;
      max-width: 600px;
      align-items: initial;
    }

    li {
      padding-left: 3rem !important;
    }
  }
`
