import React, { useState } from 'react'
import moment from 'moment'
import { UserRecurrenceDetails } from './user-recurrence-details'
import BaseCard from '../../../../components/card/BaseCard'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import BaseButton from '../../../../components/button/BaseButton'
import { formatToBrazilianCurrency, handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import { BaseSelect, Option } from '../../../../components/select/BaseSelect'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import { useFetchUnpaidRecurrences } from '../../../../hooks/donations/queries/useFetchUnpaidRecurrences'
import { useCancelRecurrence } from '../../../../hooks/donations/mutations/useCancelRecurrence'
import { BaseLink } from '../../../../components/link/BaseLink'
import { useFetchCampaignsOptions } from '../../../../hooks'
import { useHistory } from 'react-router-dom'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export interface FiltersUnpaidRecurrences {
  data_inicial?: string
  nome_usuario?: string
  data_final?: string
  campanha?: string
  queryString?: string
}

export default function UnpaidRecurrence() {
  const { push } = useHistory()

  const [pedidoId, setPedidoId] = useState<number>()

  const [filters, setFilters] = useState<FiltersUnpaidRecurrences>()
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: campaignsOptions } = useFetchCampaignsOptions()

  const { data, isPending, isError } = useFetchUnpaidRecurrences(filters)

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Benfeitor',
      dataIndex: 'benfeitor',
      key: 'benfeitor',
      align: 'center',
      filterDropdown: () => (
        <BaseCard>
          <BaseInput
            placeholder="Digite o nome do usuário"
            allowClear
            onChange={(e) => handleInputChange({ nome_usuario: e.target.value })}
          />
        </BaseCard>
      ),
      filteredValue: filters?.nome_usuario && [filters?.nome_usuario],
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      align: 'center',
      filterDropdown: () => (
        <BaseCard>
          <BaseDatePicker
            format="DD/MM/YYYY"
            placeholder={['Inicial', 'Final']}
            range
            onChange={(_: null, dateStrings: string[]) => {
              const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
              const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

              handleInputChange({
                data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
                data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
              })
            }}
          />
        </BaseCard>
      ),
      filteredValue: filters?.data_inicial && filters?.data_final && [filters?.data_inicial, filters?.data_final],
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      align: 'center',
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
    {
      title: 'Campanha',
      dataIndex: 'campanha',
      key: 'campanha',
      align: 'center',
      filterDropdown: () => (
        <BaseCard>
          <BaseSelect
            showSearch
            placeholder="Selecione a campanha"
            allowClear
            onChange={(value) => handleInputChange({ campanha: value })}
          >
            {campaignsOptions?.map((c, key) => {
              return (
                <Option value={c.value} key={key}>
                  {c.label}
                </Option>
              )
            })}
          </BaseSelect>
        </BaseCard>
      ),
      filteredValue: filters?.campanha && [filters?.campanha],
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'campanha',
      align: 'center',
    },
  ]

  const { mutateAsync: cancelRecurrence } = useCancelRecurrence()

  const dataSource = data?.doacoes_nao_efetivadas.map((doacao) => {
    const { id, benfeitor_id, benfeitor, data, valor, campanha } = doacao
    return {
      id: (
        <div
          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--info-color)' }}
          role="button"
          tabIndex={0}
          onClick={() => {
            setPedidoId(id)
          }}
        >
          {id}
        </div>
      ),
      benfeitor: <BaseLink onClick={() => push(`/admin/users/details/${benfeitor_id}`)}>{benfeitor}</BaseLink>,
      data,
      valor: formatToBrazilianCurrency(valor),
      campanha,
      acoes: (
        <BaseButton
          onClick={() => {
            cancelRecurrence(doacao?.id)
          }}
          style={{ margin: '0 auto' }}
          severity="error"
        >
          Cancelar Recorrência
        </BaseButton>
      ),
    }
  })

  return (
    <>
      <UserRecurrenceDetails pedidoId={pedidoId} setPedidoId={setPedidoId} />
      <BaseTable
        columns={columns}
        dataSource={dataSource}
        isPending={isPending}
        isError={isError}
        onChange={(pagination) => {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }}
        pagination={{
          current: data?.page,
          total: data?.total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    </>
  )
}
