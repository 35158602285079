import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { DataService } from '../../../../../lib/dataService'

export interface UserMeAddress {
  bairro?: string | null
  cep?: string | null
  cidade?: string | null
  complemento?: string | null
  data_alteracao?: string | null
  data_criacao: string
  detalhe_estrangeiro?: string | null
  estado?: string | null
  fk_clifor_id: number
  id: number
  numero?: string | null
  obriga_atualizar_endereco: boolean
  rua?: string | null
  ultima_atualizacao_endereco?: string | null
  usuario_alteracao?: string | null | number
  usuario_criacao: number
}

export function useFetchUserMeAddress() {
  const userAdress = useQuery<UserMeAddress>({
    queryKey: ['USERME_ADDRESS'],
    queryFn: async () => {
      const userAddress = await DataService.get(`/addresses/me`)
      return userAddress?.data
    },
    gcTime: 0,
    staleTime: 0,
    enabled: !!Cookies.get('access_token'),
  })

  return userAdress
}
