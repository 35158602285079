import { useHistory } from 'react-router-dom'
import { Container } from './styles'
import logosImage from '../../images/logos.svg'
import { SmBaseButton } from '../components/button/styles'
import { GoldenHeader } from '../components/golden-header/styles'

export function CheckForGroup() {
  const history = useHistory()

  return (
    <Container>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={logosImage} alt="Logo" />
        </div>
        <GoldenHeader className="header" style={{ marginBottom: '1.5rem' }}>
          SEJA BEM VINDO!
        </GoldenHeader>
        <div
          className="text-wrapper"
          style={{ marginBottom: '3rem', maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <p>
            Deus se alegra pelo seu sim em responder a esse apelo amoroso de tornar-se um valioso instrumento nas mãos
            do Senhor para <span>realizar uma magnífica missão,</span> tornando-se um GENERAL OFICIAL DO EXÉRCITO DE SÃO
            MIGUEL. 
          </p>
          <p>
            Estamos apenas a alguns passos desse momento que marcará a sua história dentro do Exército de São Miguel. 
          </p>
        </div>
        <GoldenHeader
          className="header-two"
          style={{ marginBottom: '1.5rem', maxWidth: '1300px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          O General do Exército de São Miguel
        </GoldenHeader>
        <div className="text-wrapper-two">
          <p style={{ maxWidth: '1100px', marginLeft: 'auto', marginRight: 'auto' }}>
            O General oficial do Exército de São Miguel é aquele que, após ter sido alcançado pela Misericórdia de Deus,
            <span> deseja ser um instrumento ativo na evangelização</span>, sendo responsável por organizar e liderar um
            grupo no Whatsapp, compartilhando diariamente os links das transmissões diárias do Instituto Hesed, e
            <span> conduzindo essas almas para Deus. </span>
            <div style={{ marginTop: '3rem', marginBottom: '4rem', fontWeight: '700' }}>Vamos ao próximo passo?</div>
          </p>
        </div>

        <div
          className="text-wrapper-two"
          style={{ marginBottom: '1.5rem', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <span>Caso você já seja administrador de um grupo no Whatsapp, clique no botão abaixo: </span>
        </div>

        <div className="button-wrapper">
          <SmBaseButton
            onClick={() => {
              history.push('/membro-exercito/pre-tornar-se-general-form')
            }}
          >
            JÁ SOU ADMINISTRADOR DE UM GRUPO
          </SmBaseButton>
        </div>

        <div
          className="text-wrapper-two"
          style={{ marginTop: '5rem', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <span>
            Caso você tenha o desejo de se tornar um general oficial, mas ainda não administra um grupo no Whatsapp,
            clique abaixo: 
          </span>
        </div>

        <div className="button-wrapper" style={{ marginTop: '1.5rem' }}>
          <SmBaseButton
            onClick={() => {
              history.push('/membro-exercito/criar-grupo-video')
            }}
          >
            AINDA NÃO TENHO UM GRUPO NO WHATSAPP
          </SmBaseButton>
        </div>
      </div>
    </Container>
  )
}
