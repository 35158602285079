import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetDonationsByPaymentMethod(filters) {
  return useQuery({
    queryKey: ['DONATIONS_BY_PAYMENT_METHOD_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const response = await DataService.get(
        `admin/dash-board/donations/donations-values-by-method-payment?${filters?.queryString || ''}`,
      )

      return response
    },
  })
}
