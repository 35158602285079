import React, { lazy } from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
import { CreateOrEditCampaign } from '../../container/admin/pages/campaigns/Add/Add'

const CampaignsView = lazy(() => import('../../container/admin/pages/campaigns/View'))
const CampaingDetails = lazy(() => import('../../container/admin/pages/campaigns/Details'))

const Campaigns = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/view-all`} component={CampaignsView} />
      <Route path={`${path}/campaigns-add`} component={CreateOrEditCampaign} />
      <Route exact path={`${path}/campaigns-edit/:id`} component={CreateOrEditCampaign} />
      <Route exact path={`${path}/campaigns-detail/:id`} component={CampaingDetails} />
    </Switch>
  )
}

export default Campaigns
