import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useGetLivesListOnSelectedDate(selectedDate, setLiveDetailToBeFetched) {
  const livesListOnSelectedDate = useQuery({
    queryKey: ['LIVES_ON_SELECTED_DATE', selectedDate],
    queryFn: async () => {
      const formattedDate = selectedDate.format('YYYY-MM-DD')
      const response = await DataService.get(`youtube/lives/titulos?data=${formattedDate}`)
      if (response?.data?.titulos?.length > 0) {
        const titulosLastIndexArray = response?.data?.titulos?.length - 1
        const latestCampaignOfTheDay = response?.data?.titulos[titulosLastIndexArray]
        setLiveDetailToBeFetched(latestCampaignOfTheDay)
      }

      return response?.data
    },
    enabled: !!selectedDate,
  })

  return livesListOnSelectedDate
}
