import { CurrentMonthDonations } from '../../CurentMonthDonations'
import { RecurrentDonations } from '../../RecurrentDonations'
import { TodayDonationsBar } from '../../TodayDonationsBar'
import { SlideProps } from '../types'
import { useFinanceDashboardCardsData } from './use-dashboard-cards-data'

export function useDashboardSlides(): SlideProps[] {
  const { currentMonthDonations, todayDonations, monthAverageDonation, dailyAverageDonation } =
    useFinanceDashboardCardsData()

  return [
    {
      chart: <CurrentMonthDonations />,
      cards: [
        {
          value: Number(todayDonations?.data?.valor_total) || 0,
          title: 'Doações de hoje',
          percentage: Number(todayDonations?.data?.porcentagem_dia) || 0,
        },
        {
          value: Number(monthAverageDonation?.data?.media_valor_mensal) || 0,
          title: 'Média mensal',
        },
        {
          value: Number(monthAverageDonation?.data?.media_valor_mensal) || 0,
          title: 'Recorrências do mês',
          percentage: Number(monthAverageDonation?.data?.media_quant_mensal || 0),
        },
        {
          value: Number(dailyAverageDonation?.data?.media_valores_diaria) || 0,
          title: 'Média diária',
        },
      ],
    },
    {
      chart: <TodayDonationsBar />,
      cards: [
        {
          value: Number(currentMonthDonations?.data?.valor_total_mes) || 0,
          title: 'Doações do mês',
          percentage: Number(currentMonthDonations?.data?.porcentagem_valor_mes) || 0,
        },
        {
          value: Number(monthAverageDonation?.data?.media_valor_mensal) || 0,
          title: 'Média mensal',
        },
        {
          value: Number(monthAverageDonation?.data?.media_valor_mensal || 0),
          title: 'Recorrências do mês',
          percentage: Number(currentMonthDonations?.data?.media_mensal || 0),
        },
        {
          value: Number(dailyAverageDonation?.data?.media_valores_diaria) || 0,
          title: 'Média diária',
        },
      ],
    },
    {
      chart: <RecurrentDonations />,
      cards: [
        {
          value: Number(currentMonthDonations?.data?.valor_total_mes) || 0,
          title: 'Doações do mês',
          percentage: Number(currentMonthDonations?.data?.porcentagem_valor_mes) || 0,
        },
        {
          value: Number(monthAverageDonation?.data?.media_valor_mensal) || 0,
          title: 'Média mensal',
        },
        {
          value: Number(todayDonations?.data?.valor_total) || 0,
          title: 'Doações de hoje',
          percentage: Number(todayDonations?.data?.porcentagem_dia) || 0,
        },
        {
          value: Number(dailyAverageDonation?.data?.media_valores_diaria) || 0,
          title: 'Média diária',
        },
      ],
    },
  ]
}
