import styled from 'styled-components'

export const Container = styled.div`
  background-color: #c98f00;
  padding: 2rem 3rem;
  margin-top: 6rem;
`
export const Text = styled.div`
  color: white;
  text-align: center;
  font-family: 'DM Serif Display';
  font-weight: 500;
  font-size: 20px;
`
