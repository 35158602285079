import { Image } from 'antd'
import styled from 'styled-components'
import { FONT_WEIGHT } from '../../../../styles/Themes/Admin/constants'
import BaseCard from '../../components/card/BaseCard'

const RecordViewWrapper = styled.div`
  .styled-select .ant-select-selector {
    height: 47px !important;
    border: solid #e4e7f0 1px !important;
    color: #272b41;
    display: flex;
    align-items: center;
    width: 165px;
  }

  .styled-select .ant-select-selection-placeholder {
    color: '#dddddd' !important;
    opacity: 0.5;
  }

  &.active {
    color: green;
    text-decoration: line-through;
  }
  .btn-add_new {
    a {
      display: inline-flex;
      align-items: center;
    }
  }
  .search-box {
    position: relative;
    box-shadow: 0 5px 5px rgba(#9299b8, 0.3);
    .search-icon {
      position: absolute;
      ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 18px;
      top: 50%;
      transform: translateY(-50%);
      svg,
      img {
        margin-top: 6px;
        min-width: 16px;
        color: #9299b8;
      }
    }
    input {
      border: 0 none;
      height: 40px;
      min-width: 280px;
      padding: ${({ theme }) => (theme.rtl ? '0 45px 0 20px' : '0 20px 0 45px')};
      border-radius: 6px;
      &::placeholder {
        color: #adb4d2;
      }
      &:focus {
        outline: none;
      }
    }
  }
`

const RecordFormWrapper = styled.div`
  .add-record-form {
    .info {
      background-color: transparent;
    }
  }
  .pro-image {
    position: relative;
    margin-bottom: 30px;
    .ant-spin.ant-spin-spinning {
      position: absolute;
      top: 0;
      ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
      width: 120px;
      height: 120px;
      background: #ffffff90;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 10;
      &:after {
        position: absolute;
        ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #272b4120;
        content: '';
        z-index: -1;
      }
      .ant-spin-dot {
        position: relative;
        z-index: 10;
      }
    }
    img {
      max-width: 120px;
      min-width: 120px;
      min-height: 120px;
      border-radius: 50%;
    }
    .ant-spin {
      height: 120px;
      width: 120px;
      display: flex;
      align-items: center;
    }
    .ant-upload-select {
      position: absolute;
      ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 80px;
      bottom: -5px;
      height: 40px;
      width: 40px;
      background: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 222;
      span {
        display: inline-flex;
        height: 32px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 32px;
        background: #5f63f2;
      }
    }
    .upload-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .info {
      ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
      h1 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    .ant-upload-list-item {
      margin-top: 0;
      &:hover {
        .ant-upload-list-item-info {
          background-color: transparent;
        }
      }
      .ant-upload-list-item-info {
        > span {
          display: flex;
          align-items: center;
          /* ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 14px; */
          /* ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px; */
        }
        .ant-upload-list-item-card-actions {
          /* // top: -8px; */
        }
      }
    }
  }
  .record-spin {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .select-container {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center;  */
    flex-direction: column;
    gap: 5px;
  }

  .ant-picker {
    min-width: 30px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ButtonsWrapper = styled.div`
  margin-top: -10px;
  padding: 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  justify-content: center;
  gap: 30px;
`

export const DetailsCampaignStyled = styled.div`
  width: 100%;
  max-width: 100%;

  strong {
    color: #616682;
  }

  div {
    color: #616682;
  }

  .campaign-container {
    width: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 0.8rem;
  }

  .image {
    /* display: grid; */
    /* place-items: center; */
    /* position: absolute;  */
  }
  .ant-image-mask {
    border-radius: 15px;
    p {
      color: white;
    }
  }
  .menu-title {
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 15px;
    color: #4347d9;
  }
`

export const StyledCampaignImage = styled(Image)`
  border-radius: 15px;
  padding: 3px;
  border: solid #c7c7c7 2px;
  max-width: 300px;

  .ant-image-mask {
    border-radius: 300px;
    p {
      color: white;
    }
  }

  @media (max-width: 576px) {
    width: 150px;

    .ant-image-mask {
      width: 150px;
    }
  }
`

export const DetailsContainer = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;

  strong {
    margin-bottom: 1rem;
    color: var(--campaing-details-primary-text);
    font-weight: ${FONT_WEIGHT.extraLight};

    span {
      font-weight: ${FONT_WEIGHT.bold};
      color: var(--campaing-details-secondary-text);
    }
  }

  .embutir {
    color: red !important;
  }

  /* .ant-image-mask {
    border-radius: 15px;
    p {
      color: white;
    }
  } */
`

export const BannerImage = styled(Image)`
  max-width: 350px;
`

export const CampaignDetailsCard = styled(BaseCard)`
  /* width: 100rem; */

  .ant-card-body {
    min-height: 45rem;
    display: flex;
    flex-direction: column;
  }

  .ant-spin {
    min-height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  overflow-y: auto;
`

export const EmbutirButtonStyled = styled.button`
  color: #000000;
  background-color: #cbcbcb;
  padding: 5px 10px;
  border: none;
  border-radius: 2rem;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;

  cursor: pointer;
`

export const EmbutirContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 1rem;
  color: var(--campaing-details-primary-text);
`

export { RecordViewWrapper, RecordFormWrapper, Wrapper, ButtonsWrapper }
