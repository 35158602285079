import * as S from './ProgressPerformance.Styles'
import BaseProgress from '../../../container/admin/components/progress/BaseProgress'
import BaseCard from '../../../container/admin/components/card/BaseCard'

export default function ProgressPerformance({ percent }) {
  return (
    <BaseCard bordered={false}>
      <S.Container>
        <BaseProgress type="circle" percent={percent} strokeWidth={7} />
      </S.Container>
    </BaseCard>
  )
}
