import React from 'react'
import { Modal } from 'antd'
import * as S from './BaseModal.styles'

export const modalSizes = {
  small: '400px',
  medium: '600px',
  large: '800px',
  xl: '1000px',
  xxl: '1200px',
}

export default function BaseModal({ size = 'medium', children, ...props }) {
  const modalSize = Object.entries(modalSizes).find((sz) => sz[0] === size)?.[1]
  return (
    <S.StyledModal width={modalSize} {...props}>
      {children}
    </S.StyledModal>
  )
}

BaseModal.info = Modal.info
BaseModal.success = Modal.success
BaseModal.warning = Modal.warning
BaseModal.error = Modal.error
