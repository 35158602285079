import { Select, SelectProps } from 'antd'
import styled, { css } from 'styled-components'

interface SelectStyledProps extends SelectProps {
  backgroundColor?: string
  height?: number
  borderRadius?: number
  border?: string
}

export const StyledSelect = styled(Select)<SelectStyledProps>`
  .ant-select-selector {
    border: 1.5px solid ${(props) => props.theme.colors['gray-400']} !important;

    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '8px')} !important;

    background-color: ${({ backgroundColor, disabled }) =>
      disabled ? '#f0f0f0' : backgroundColor ?? 'white'} !important;

    ${({ border }) => {
      return (
        border &&
        css`
          border: ${border} !important;
        `
      )
    }};
  }

  .ant-select-selector {
    min-height: ${({ height }) => (height ? `${height}px` : '45px')} !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  &:hover .ant-select-selector {
    background-color: ${({ backgroundColor, disabled }) =>
      disabled ? '#f0f0f0' : backgroundColor ?? 'white'} !important;
  }
`
