export function AdminMenuCampanhas() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 44.368 44.325">
        <g id="Layer_2" transform="translate(-2 -2)">
          <g id="Grupo_3" data-name="Grupo 3" transform="translate(2 2)">
            <path
              id="Caminho_1"
              data-name="Caminho 1"
              d="M19.3,10.433a8.8,8.8,0,0,1,8.643,6.87,2.177,2.177,0,0,0,4.211-1.108A12.849,12.849,0,0,0,19.3,6a13.322,13.322,0,0,0-1.108,26.6h.222A2.357,2.357,0,0,0,20.627,30.6c.222-1.108-.887-2.216-1.995-2.438a9.27,9.27,0,0,1-8.2-8.865A8.891,8.891,0,0,1,19.3,10.433Z"
              transform="translate(2.865 2.865)"
            />
            <path
              id="Caminho_2"
              data-name="Caminho 2"
              d="M24.163,41.893a17.73,17.73,0,1,1,17.73-18.838,2,2,0,0,0,2.438,1.995,2,2,0,0,0,1.995-2.438A22.189,22.189,0,1,0,24.163,46.325a2.216,2.216,0,1,0,0-4.433Z"
              transform="translate(-2 -2)"
            />
            <path
              id="Caminho_3"
              data-name="Caminho 3"
              d="M32.452,17.512a1.68,1.68,0,0,0-.887-.443L17.159,12.193A3.964,3.964,0,0,0,13.17,13.3a3.388,3.388,0,0,0-.665,3.989l6.649,14.627a3.88,3.88,0,0,0,3.324,2.216h.665a3.554,3.554,0,0,0,3.1-3.324l.665-5.319,4.433-1.33a3.854,3.854,0,0,0,2.66-3.1A3.641,3.641,0,0,0,32.452,17.512ZM25.36,21.28a3.541,3.541,0,0,0-2.66,3.1l-.443,3.324-4.876-10.86,10.638,3.546Z"
              transform="translate(10.322 10.191)"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
