import { Area, AreaChart, Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import {
  extractCampaignNames,
  generateColorMap,
  getCurrentMonthAndYear,
  renderAreaTooltip,
  renderBarTooltip,
  transformCampaignData,
  transformDailyDonations,
} from './utils'
import { SliderChartTitle } from '../../../styles'
import { ComposedCard } from '@/container/admin/components/composed-card'
import { FilterIcon } from '@/container/admin/components/FilterIcon'
import { formatToBrazilianCurrency } from '@/utility/utility'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function Chart({ data, filters, onFilterClick }) {
  const currentMonthAndYear = getCurrentMonthAndYear()
  const chartTotalData = transformDailyDonations(data)

  const chartCampaignData = transformCampaignData(data)
  const allCampaignNames = extractCampaignNames(data)

  const colorMap = generateColorMap(allCampaignNames)

  return (
    <ResponsiveContainer width="100%" height={260} style={{ overflow: 'hidden' }}>
      <SliderChartTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <div className="title-and-icon-container">
              Total do mês
              <FilterIcon onClick={onFilterClick} />
            </div>
            <BlurDiv className="percentage">
              <ComposedCard.Balance isPositive={false}>14.43%</ComposedCard.Balance>
            </BlurDiv>
          </div>
          <BlurDiv className="total-value">{formatToBrazilianCurrency(15412.56)}</BlurDiv>
        </div>
      </SliderChartTitle>

      {filters?.visualizar_por !== 'campanha' ? (
        <AreaChart data={chartTotalData} margin={{ top: 70, right: 0, bottom: 20, left: 0 }}>
          <defs>
            <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="var(--finance-chart-color)" stopOpacity={1} />
              <stop offset="100%" stopColor="var(--finance-chart-color)" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="donation"
            stroke="var(--finance-chart-color)"
            fill="url(#greenGradient)"
            strokeWidth={2}
          />
          <Tooltip cursor={false} content={({ payload }) => renderAreaTooltip(payload)} />
        </AreaChart>
      ) : (
        <BarChart data={chartCampaignData} margin={{ top: 60, right: 0, bottom: 3, left: 0 }}>
          <XAxis hide={true} dataKey="day" />
          <YAxis hide={true} />
          <Tooltip
            wrapperStyle={{ top: -170 }}
            cursor={false}
            content={({ payload }) => renderBarTooltip(payload, data, colorMap)}
          />

          {allCampaignNames.map((campaignName) => {
            return <Bar key={campaignName} dataKey={campaignName} fill={colorMap[campaignName]} stackId="a" />
          })}
        </BarChart>
      )}
    </ResponsiveContainer>
  )
}
