import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { EnderecoFamiliaResponse } from './useGetEnderecoFamilia'

interface GetFamiliaPorCpfProps {
  idCicloDoacao: number
  cpf: string
}

export interface GetFamiliaPorCpfResponse {
  familia: {
    cadastrado_em: string
    id: number
    nome_familia: string
    status: boolean
    ultimo_recebimento?: string
    foto?: string
    observacao?: string
  }

  endereco: EnderecoFamiliaResponse
}

export function useGetFamiliaPorCpf({ cpf, idCicloDoacao }: GetFamiliaPorCpfProps) {
  const familiaCpf = useQuery({
    queryKey: ['FAMILIA_CPF'],
    queryFn: async () => {
      const response = await DataService.get<GetFamiliaPorCpfResponse>(
        `/agape/buscar-familia-agape-por-cpf/${cpf}/${idCicloDoacao}`,
      )

      return response.data
    },
    enabled: false,
  })

  return familiaCpf
}
