import styled from 'styled-components';

export const DonationCardsContainer = styled.div`
  .ant-card-body {
    padding: 0 !important;
  }

  display: flex;
  flex-direction: column;
  gap: 2rem;

  .ant-collapse-header {
    display: none !important;
  }
`;

export const CardTitleContainer = styled.div`
  padding: 1rem 1.5rem;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 900;
  font-size: 1rem;
`;

export const CardSubtitleContainer = styled.div`
  color: #949494;
  padding: 0 1.5rem;
  line-height: 16px;
  font-size: 0.8rem;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CollapseContentContainer = styled.div`
  .descricao {
    color: #949494;
    font-size: 0.8rem;
    line-height: 20px;
  }

  .title {
    color: #6d6d6d;
    font-weight: 600;
    margin-top: 2rem;
  }

  .ant-divider {
    margin: 12px 0;
    border-top: solid #cccccc 1px;
  }
`;

export const DonationCardContainer = styled.div`
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
  border: solid #d9d9d9 1px;
  border-bottom: solid transparent 0px;

  display: flex;

  img {
    width: 240px;
    height: 100%;
  }

  .content-container {
    /* style={{ display: 'flex', flexDirection: 'column', gap: '2rem', width: '100%' }} */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .content-bottom {
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    img {
      width: 100%;
    }
  }
`;

export const RecurrenceButton = styled.button`
  background-color: white;
  border: solid red 2px;
  color: #ff4d4d;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  max-width: 15rem;
  margin: 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    color: #580000;
    border: solid #580000 2px;

    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 77, 77, 0.6);
  }
`;
