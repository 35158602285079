import React from 'react';
import { SwitchProps } from 'antd';
import * as S from './BaseSwitch.Styles';

interface BaseSwitchProps extends SwitchProps {}

const BaseSwitch: React.FC<BaseSwitchProps> = (props) => {
  return <S.Switch {...props} />;
};

export default BaseSwitch;
