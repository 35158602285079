import { Col, Row } from 'antd'

import { useState } from 'react'
import {
  ListagemVocacional,
  useUpdateVocacionalStatus,
} from '../../../../hooks/vocacional/mutations/useUpdateVocacionalStatus'
import BaseButton from '../../../../components/button/BaseButton'
import { BaseDivider } from '../../../../components/divider/BaseDivider'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'

interface AtualizarStatusVocacionalProps {
  usuario_vocacional_id: number
  acaoPosSucesso?: () => void
  listagem: ListagemVocacional
}

export function AtualizarStatusVocacional({
  usuario_vocacional_id,
  acaoPosSucesso,
  listagem,
}: AtualizarStatusVocacionalProps) {
  const { mutateAsync: updateVocacionalStatus } = useUpdateVocacionalStatus(listagem)

  const [justificativa, setJustificativa] = useState<string | undefined>()
  const [isLoadingApprove, setIsLoadingApprove] = useState(false)
  const [isLoadingRefuse, setIsLoadingRefuse] = useState(false)

  async function handleUpdateVocacionalStatus(acao: 'aprovar' | 'reprovar') {
    try {
      if (acao === 'aprovar') {
        setIsLoadingApprove(true)
      }
      if (acao === 'reprovar') {
        setIsLoadingRefuse(true)
      }

      await updateVocacionalStatus({ acao, usuario_vocacional_id, justificativa })

      setJustificativa(undefined)

      if (acaoPosSucesso) {
        acaoPosSucesso()
      }
    } finally {
      setIsLoadingApprove(false)
      setIsLoadingRefuse(false)
    }
  }

  return (
    <Row justify="center" gutter={[20, 20]}>
      <Col xs={24} md={10}>
        <Row gutter={[20, 15]}>
          <h4>Aprovação</h4>
          <BaseButton
            severity="success"
            style={{ width: '100%' }}
            onClick={() => handleUpdateVocacionalStatus('aprovar')}
            loading={isLoadingApprove}
            disabled={isLoadingRefuse || isLoadingApprove}
          >
            Aprovar
          </BaseButton>
        </Row>
      </Col>

      <Col xs={0} md={1}>
        <BaseDivider type="vertical" style={{ height: '100%' }} />
      </Col>

      <Col xs={24} md={10}>
        <Row gutter={[20, 15]}>
          <h4>Justificativa (Não-obrigatório)</h4>

          <BaseInput
            value={justificativa}
            onChange={(e) => setJustificativa(e.target.value)}
            placeholder="Justificativa da recusa"
            allowClear
            disabled={isLoadingRefuse || isLoadingApprove}
          />
          <BaseButton
            severity="error"
            style={{ width: '100%' }}
            onClick={() => handleUpdateVocacionalStatus('reprovar')}
            loading={isLoadingRefuse}
            disabled={isLoadingRefuse || isLoadingApprove}
          >
            Recusar
          </BaseButton>
        </Row>
      </Col>
    </Row>
  )
}
