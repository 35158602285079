import { MinusSquare, PlusSquare } from '@phosphor-icons/react';
import { BaseCollapse } from '../../components/collapse/BaseCollapse';
import BaseModal from '../../components/modal/BaseModal';
import { BaseTable } from '../../components/table/BaseTable/BaseTable';

interface LogsDetailsProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  selectedRecord: {
    id: string;
    user: string;
    profile: string;
    action: string;
    date: string;
    field: string;
    ipuser: string;
    details: {
      method: string;
      route: string;
      status_code: number;
      request_data: any;
      response_data: any;
    };
  };
}

export function LogsDetails({ isModalOpen, setIsModalOpen, selectedRecord }: LogsDetailsProps) {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Usuário',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
    },
    {
      title: 'Perfil',
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
    },
    {
      title: 'Data e Hora',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },

    {
      title: 'Método',
      dataIndex: 'method',
      key: 'method',
      align: 'center',
    },
    {
      title: 'Rota',
      dataIndex: 'route',
      key: 'route',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status_code',
      key: 'status_code',
      align: 'center',
    },
    {
      title: 'IP do usuário',
      dataIndex: 'ipuser',
      key: 'ipuser',
      align: 'center',
    },
  ];

  const dataSource = selectedRecord
    ? {
      id: selectedRecord.id,
      user: selectedRecord.user,
      profile: selectedRecord.profile,
      action: selectedRecord.action,
      date: selectedRecord.date,
      ipuser: selectedRecord.ipuser,
      method: selectedRecord.details.method,
      route: selectedRecord.details.route,
      status_code: selectedRecord.details.status_code,
    }
    : {};

  const JsonViewer = ({ data, parentKey = "" }) => {
    if (data === null || typeof data !== "object") {
      // Render primitive values
      return (
        <div style={{ paddingLeft: 16 }}>
          <strong>{parentKey}: </strong>
          {String(data)}
        </div>
      );
    }

    if (Array.isArray(data)) {
      // If the array contains objects, render collapsible panels for each object
      const hasObjectItems = data.some((item) => typeof item === "object" && item !== null);
      if (hasObjectItems) {
        return (
          <BaseCollapse>
            {data.map((item, index) => (
              <BaseCollapse.Panel header={`${index + 1}`} key={`${parentKey}-${index}`}>
                <JsonViewer data={item} parentKey={`${parentKey}[${index}]`} />
              </BaseCollapse.Panel>
            ))}
          </BaseCollapse>
        );
      }

      // For simple arrays, display them as-is
      return (
        <div style={{ paddingLeft: 16, }}>
          <strong>{parentKey}: </strong>
          {JSON.stringify(data)}
        </div>
      );
    }

    // Render objects
    return (
      <div style={{ paddingLeft: 16 }}>
        {Object.entries(data).map(([key, value]) => {
          // For arrays of objects, make them collapsible
          if (Array.isArray(value) && value.some((item) => typeof item === "object" && item !== null)) {
            return (
              <BaseCollapse key={key}>
                <BaseCollapse.Panel header={key} key={`${key}-panel`}>
                  <JsonViewer data={value} parentKey={key} />
                </BaseCollapse.Panel>
              </BaseCollapse>
            );
          }

          // Render other fields directly
          return (
            <div key={key}>
              <strong>{key}: </strong>
              {typeof value === "object" ? (
                <JsonViewer data={value} parentKey={key} />
              ) : (
                String(value)
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <BaseModal
      title="Detalhes do Registro"
      onCancel={() => setIsModalOpen(false)}
      open={isModalOpen}
      footer={null}
      width={1000}
    >
      <BaseTable columns={columns} dataSource={[dataSource]} />

      <BaseCollapse
        ghost
        expandIcon={({ isActive }: { isActive: boolean }) =>
          isActive ? <MinusSquare size={20} /> : <PlusSquare size={20} />
        }
      >
        <BaseCollapse.Panel header="Dados enviados pelo usuário" key={'request_data'}>
          {/* <pre>{JSON.stringify(selectedRecord?.details?.request_data, null, 2)}</pre> */}
          <JsonViewer data={selectedRecord?.details?.request_data} />
        </BaseCollapse.Panel>

        <BaseCollapse.Panel header="Dados enviados para o usuário" key={'response_data'}>
          {/* <pre>{JSON.stringify(selectedRecord?.details?.response_data, null, 2)}</pre> */}
          <JsonViewer data={selectedRecord?.details?.response_data?.[0]} />
        </BaseCollapse.Panel>
      </BaseCollapse>
    </BaseModal>
  );
}
