import { http, HttpResponse } from 'msw'
import { UserMeResponse } from '../../container/admin/hooks/users/Queries/useFetchUserMe'
import {
  currentUserEmail,
  EMAIL_ADMIN_MOCK,
  EMAIL_BENFEITOR_ESTRANGEIRO_MOCK,
  EMAIL_BENFEITOR_MOCK,
} from './login-mock'

const BENFEITOR_RESPONSE: UserMeResponse = {
  avatar: 'https://avatars.githubusercontent.com/u/163329090?s=200&v=4',
  campanha_origem: null,
  data_nascimento: '20/01/2000',
  email: EMAIL_BENFEITOR_MOCK,
  fk_clifor_id: 2,
  fk_perfil_id: 2,
  id: 2,
  nome: 'Usuário Benfeitor',
  nome_social: null,
  numero_documento: '08586897078',
  obriga_atualizar_cadastro: false,
  obriga_atualizar_endereco: false,
  pais: 'brasil',
  sexo: 'masculino',
  super_perfil: false,
  telefone: '88888888888',
}

const ADMIN_RESPONSE: UserMeResponse = {
  avatar: 'https://avatars.githubusercontent.com/u/163329090?s=200&v=4',
  campanha_origem: null,
  data_nascimento: '20/01/2000',
  email: EMAIL_ADMIN_MOCK,
  fk_clifor_id: 1,
  fk_perfil_id: 1,
  id: 1,
  nome: 'Usuário Admin',
  nome_social: null,
  numero_documento: '31308069030',
  obriga_atualizar_cadastro: false,
  obriga_atualizar_endereco: false,
  pais: 'brasil',
  sexo: 'masculino',
  super_perfil: true,
  telefone: '99999999999',
}

const ESTRANGEIRO_RESPONSE: UserMeResponse = {
  avatar: 'https://avatars.githubusercontent.com/u/163329090?s=200&v=4',
  campanha_origem: 43,
  data_nascimento: '19/10/2000',
  email: EMAIL_BENFEITOR_ESTRANGEIRO_MOCK,
  fk_clifor_id: 140005,
  fk_perfil_id: 2,
  id: 69064,
  nome: 'Usuário Estrangeiro',
  nome_social: null,
  numero_documento: '6436317654',
  obriga_atualizar_cadastro: false,
  obriga_atualizar_endereco: false,
  pais: 'antartida',
  sexo: 'masculino',
  super_perfil: false,
  telefone: '7643264782378',
}

export const userMeMock = http.get<never, never, UserMeResponse>('/users/me', () => {
  if (currentUserEmail === EMAIL_BENFEITOR_MOCK) {
    return HttpResponse.json(BENFEITOR_RESPONSE, { status: 200 })
  }

  if (currentUserEmail === EMAIL_BENFEITOR_ESTRANGEIRO_MOCK) {
    return HttpResponse.json(ESTRANGEIRO_RESPONSE, { status: 200 })
  }

  return HttpResponse.json(ADMIN_RESPONSE, { status: 200 })
})
