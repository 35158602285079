import { Badge as AntBadge } from 'antd'
import styled from 'styled-components'
import { defineColorBySeverity } from '../../../../utility/utility'
// import { NotificationType } from '../BaseNotification/BaseNotification';

export const Badge = styled(AntBadge)`
  color: inherit;

  & .ant-badge-count {
    background: ${(props) => defineColorBySeverity(props.severity)};
  }

  .ant-badge-status-text {
    color: var(--text-main-color);
  }
`
