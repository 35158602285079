import { UploadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { BaseUpload } from './BaseUpload'
import BaseButton from '../button/BaseButton'
import { BaseImageCrop } from '../ImageCrop/BaseImageCrop'
import BaseModal from '../modal/BaseModal'
import { Col, Row } from 'antd'

export function BaseUploadPhoto({
  initialFileList,
  setImage,
  numeroFotos,
  aspect,
  description,
  validateField,
  listType = 'picture',
}) {
  const [previewImage, setPreviewImage] = useState({ name: '', url: '' })
  const [previewOpen, setPreviewOpen] = useState(false)
  const [hasInteracted, setHasInteracted] = useState(false)

  const runValidateField = () => {
    if (validateField) {
      validateField()
    }
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [hasInteracted])

  const handleCroppedImage = async (file, preview) => {
    const dataToTransform = preview ? file.originFileObj : file

    let src = file.url || file.thumbUrl
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(dataToTransform)
        reader.onload = () => resolve(reader.result)
      })
    }

    return {
      uid: '-1',
      name: file.name,
      status: 'done',
      url: src,
      file,
    }
  }

  const onPreview = async (file) => {
    const croppedImage = await handleCroppedImage(file, true)
    setPreviewImage(croppedImage)
    setPreviewOpen(true)
  }

  const handleCancel = () => setPreviewOpen(false)

  const handleRemove = () => {
    setPreviewImage({ name: '', url: '' })
    setImage([])
    setHasInteracted(true)
  }

  const handleBeforeUpload = async (file) => {
    const croppedImage = await handleCroppedImage(file)
    setImage([croppedImage])
    setHasInteracted(true)
    return false
  }

  useEffect(() => {
    if (hasInteracted) {
      runValidateField()
    }
  }, [initialFileList, hasInteracted])

  const isCard = listType === 'picture-card'

  return (
    <>
      <BaseImageCrop
        modalTitle="Editar imagem"
        modalOk="Confirmar"
        modalCancel="Cancelar"
        aspect={aspect && !isCard ? aspect || 16 / 9 : 1 / 1}
        cropShape={isCard ? 'round' : 'rect'}
      >
        <BaseUpload
          listType={listType}
          fileList={initialFileList}
          beforeUpload={handleBeforeUpload}
          onPreview={onPreview}
          maxCount={numeroFotos}
          onRemove={handleRemove}
        >
          {initialFileList.length === 0 && (
            <>
              {isCard ? (
                <div>
                  <UploadOutlined style={{ marginRight: '0.5rem' }} />
                  Selecione
                </div>
              ) : (
                <Col xs={24}>
                  <Row justify="center">
                    <BaseUpload.Description text={description} />
                  </Row>

                  <Row justify="center">
                    <BaseButton icon={<UploadOutlined />}>Enviar Imagem</BaseButton>
                  </Row>
                </Col>
              )}
            </>
          )}
        </BaseUpload>
      </BaseImageCrop>

      <BaseModal
        open={previewOpen}
        title={previewImage.name}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <img alt="Imagem" style={{ width: '100%' }} src={previewImage.url} />
      </BaseModal>
    </>
  )
}
