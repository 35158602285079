import FeatherIcon from 'feather-icons-react'
import moment from 'moment'
import { TimePicker } from 'antd'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import BaseModal from '../../../../components/modal/BaseModal'
import { BaseSelect } from '../../../../components/select/BaseSelect'
import BaseButton from '../../../../components/button/BaseButton'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import { StyledChannelList } from '../../Style'
import BaseSpin from '../../../../components/spin/BaseSpin'
import { BaseTimePicker } from '../../../../components/BaseTimePicker'

export function CreateNewLiveModal({
  registerLiveMutation,
  isModalOpen,
  setIsModalOpen,
  clearModalState,
  form,
  periodicaBody,
  setPeriodicaBody,
  socialMedia,
  onFinish,
  setSocialMedia,
  channels,
  setTipo,
  tipo,
}) {
  return (
    <BaseModal
      bodyStyle={{ height: 'auto' }}
      title="Nova Live"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={clearModalState}
      footer={null}
      closeIcon="X"
      size="large"
    >
      <BaseForm form={form} name="basic" onFinish={onFinish}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', flexWrap: 'wrap' }}>
          <BaseForm.Item
            label="Rede Social"
            name="rede_social"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
                validator: (_, value) => {
                  if (value !== undefined) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Obrigatório'))
                },
              },
            ]}
          >
            <BaseSelect
              disabled={periodicaBody?.programacoes && periodicaBody.programacoes.length > 0}
              value={socialMedia}
              onChange={(value) => {
                setSocialMedia(value)
                form.setFieldsValue({ canal: undefined })
              }}
              placeholder="Selecione uma rede"
              style={{ minWidth: '200px' }}
            >
              <Option value="">Ambos</Option>
              <Option value="youtube">Youtube</Option>
              <Option value="facebook">Facebook</Option>
            </BaseSelect>
          </BaseForm.Item>

          {channels && (
            <BaseForm.Item
              label="Canal"
              name="canal"
              rules={[{ required: true, message: 'Obrigatório' }]}
              style={{ width: '200px' }}
            >
              <BaseSelect mode="tags" disabled={periodicaBody?.programacoes && periodicaBody.programacoes.length > 0}>
                {channels?.map((channel) => {
                  return <Option value={channel?.id}>{channel?.tag}</Option>
                })}
              </BaseSelect>
            </BaseForm.Item>
          )}
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', gap: '1rem' }}>
          <BaseForm.Item label="Programação" name="tipo" rules={[{ required: true, message: 'Obrigatório' }]}>
            <BaseSelect
              onChange={(value) => {
                setTipo(value)
                setPeriodicaBody({})
              }}
              placeholder="Selecione o tipo"
              style={{ minWidth: '200px' }}
            >
              <Option value="avulsa">Avulsa</Option>
              <Option value="periodica">Periodica</Option>
            </BaseSelect>
          </BaseForm.Item>

          {tipo === 'avulsa' && (
            <BaseForm.Item
              label="Data da Live"
              name="data_da_live"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <BaseDatePicker
                format="DD/MM/YYYY"
                disabledDate={(current) => current && current.isBefore(moment().startOf('day'))}
              />
            </BaseForm.Item>
          )}

          {tipo === 'periodica' && (
            <BaseForm.Item
              label="Dia da Semana"
              name="dia_semana"
              rules={[{ required: true, message: 'Obrigatório' }]}
              style={{ width: '200px' }}
            >
              <BaseSelect>
                <Option value="segunda-feira">Segunda-Feira</Option>;<Option value="terca-feira">Terça-Feira</Option>;
                <Option value="quarta-feira">Quarta-Feira</Option>;<Option value="quinta-feira">Quinta-Feira</Option>;
                <Option value="sexta-feira">Sexta-Feira</Option>;<Option value="sabado">Sábado</Option>;
                <Option value="domingo">Domingo</Option>;
              </BaseSelect>
            </BaseForm.Item>
          )}

          <BaseForm.Item label="Hora do Início" name="hora_inicio" rules={[{ required: true, message: 'Obrigatório' }]}>
            <BaseTimePicker format="HH:mm" />
          </BaseForm.Item>

          {tipo === 'periodica' && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <button
                type="button"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      const { canal, tipo, data_da_live, hora_inicio, dia_semana } = values

                      const formattedDate = moment(data_da_live).format('YYYY-MM-DD')
                      const formattedTime = moment(hora_inicio).format('HH:mm:ss')
                      const data_hora_inicio = `${formattedDate} ${formattedTime}`

                      const periodicaReqBody = {
                        canais_ids: [...canal],
                        tipo,
                        programacoes: [...(periodicaBody?.programacoes || []), { dia_semana, data_hora_inicio }],
                      }

                      setPeriodicaBody(periodicaReqBody)
                    })
                    .catch((errorInfo) => {
                      console.error('Validation failed:', errorInfo)
                    })
                }}
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#c5b6b4',
                  borderRadius: '50%',
                  padding: 6,
                }}
              >
                <FeatherIcon icon="plus" size={20} style={{ display: 'inlineFlex' }} />
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '1rem',
          }}
        >
          {periodicaBody?.programacoes?.map((programacao) => {
            const programacaoHorario = `${programacao.dia_semana} ${programacao.data_hora_inicio}`

            return <StyledChannelList>{programacaoHorario}</StyledChannelList>
          })}
        </div>
        <BaseButton
          htmlType="submit"
          style={{ width: '10%', margin: '0 auto' }}
          disabled={registerLiveMutation.isPending}
        >
          {registerLiveMutation.isPending ? <BaseSpin /> : 'Enviar'}
        </BaseButton>
      </BaseForm>
    </BaseModal>
  )
}
