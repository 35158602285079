import BaseCard from '@/container/admin/components/card/BaseCard'
import { ComposedCard } from '@/container/admin/components/composed-card'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { formatNumber } from '@/utility/utility'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { TooltipProps } from 'recharts/types/component/Tooltip'
import { SliderChartTitle, TooltipContainer } from '../../../styles'

interface LeadsTooltipProps extends TooltipProps<ValueType, NameType> {
  campaignNames: string[]
  colorPalette: string[]
}

export function LeadsTooltip({
  active,
  payload,
  label,
  campaignNames,
  colorPalette,
}: Readonly<LeadsTooltipProps>) {
  if (!active || !payload || payload.length === 0) return null

  const formattedLabel = new Date(label + '-01').toLocaleString('pt-BR', {
    month: 'long',
    year: 'numeric',
  })

  const dataObject = payload[0].payload

  return (
    <TooltipContainer type="signup">
      <h4 style={{ textTransform: 'capitalize' }}>{formattedLabel}</h4>
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {campaignNames.map((campaignName, index) => {
          const value = dataObject[campaignName]
          if (value === undefined) return null
          return (
            <li
              key={campaignName}
              style={{
                color: colorPalette[index % colorPalette.length],
                margin: '4px 0',
              }}
            >
              <strong>{campaignName}:</strong> {formatNumber(value)} Cadastros
            </li>
          )
        })}
      </ul>
    </TooltipContainer>
  )
}

export function CurrentMonthLeads() {
  const transformedData = [
    {
      date: '2024-01',
      "Live 4 - Terço do Combate": 4000,
      "Live 1 - Rosário da Madrugada": 2400,
      "Live 3 - Terço da Misericórdia": 2400,
      "Live 2 - Novena de Pompeia": 1000,
      "Campanha Mensal": 500,
    },
    {
      date: '2024-02',
      "Live 4 - Terço do Combate": 3000,
      "Live 1 - Rosário da Madrugada": 1398,
      "Live 3 - Terço da Misericórdia": 2210,
      "Live 2 - Novena de Pompeia": 900,
      "Campanha Mensal": 700,
    },
    {
      date: '2024-03',
      "Live 4 - Terço do Combate": 2000,
      "Live 1 - Rosário da Madrugada": 9800,
      "Live 3 - Terço da Misericórdia": 2290,
      "Live 2 - Novena de Pompeia": 1200,
      "Campanha Mensal": 800,
    },
  ]

  const campaignNames = [
    "Live 4 - Terço do Combate",
    "Live 1 - Rosário da Madrugada",
    "Live 3 - Terço da Misericórdia",
    "Live 2 - Novena de Pompeia",
    "Campanha Mensal",
    "Centro Social São Miguel",
    "Misericordia Brasil Online 2024",
    "Evento Presencial",
    "Campanha Ajuda Rio Grande do Sul",
    "Oferta Missas Permanente",
    "Festa de São Miguel Arcanjo",
    "Presentes - Votos",
    "Patrocionio Misericordia Brasil"
  ]

  const colorPalette = [
    '#4CAF50',
    '#2196F3',
    '#FFC107',
    '#FF9800',
    '#f05e54',
    '#9C27B0',
    '#5868c0',
    '#a3d3f5',
    '#d3f5a3',
    '#f5a3d3',
    '#d3a3f5',
    '#f5d3a3',
    '#a3f5d3'
  ]

  return (
    <BaseCard style={{ overflow: 'hidden', height: '260px' }} padding="0px">
      <SliderChartTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <div className="title-and-icon-container">
              <div className="title">Leads do Mês</div>
            </div>
            <BlurDiv className="percentage">
              <ComposedCard.Balance isPositive={false}>29.65%</ComposedCard.Balance>
            </BlurDiv>
          </div>
          <BlurDiv className="total-value">{formatNumber(4652)}</BlurDiv>
        </div>
      </SliderChartTitle>
      <ResponsiveContainer width="100%" height={260}>
        <BarChart data={transformedData} margin={{ top: 80, left: 10, right: 10, bottom: 0 }}>
          <XAxis dataKey="date" hide />
          <Tooltip
            wrapperStyle={{ top: 0 }}
            cursor={false}
            content={<LeadsTooltip campaignNames={campaignNames} colorPalette={colorPalette} />}
          />
          {campaignNames.map((campaignName, index) => (
            <Bar
              key={campaignName}
              dataKey={campaignName}
              stackId="a"
              barSize={45}
              fill={colorPalette[index % colorPalette.length]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </BaseCard>
  )
}
