export function AdminMenuCadastros() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 45.368 39.682">
        <g id="group-svgrepo-com" transform="translate(0.5 0.5)">
          <path
            id="Caminho_4"
            data-name="Caminho 4"
            d="M36.569,24.09a11.565,11.565,0,0,0,2.567-7.383c0-5.811-4.038-10.538-9-10.538a8.941,8.941,0,0,0-7.951,5.61,8.941,8.941,0,0,0-7.951-5.61c-4.964,0-9,4.728-9,10.538A11.542,11.542,0,0,0,7.8,24.089C4.976,25.417,0,27.906,0,35.4v2.765H8.49a13.5,13.5,0,0,0-.541,3.918V44.85H36.416V42.085a13.5,13.5,0,0,0-.541-3.918h8.492V35.4C44.368,27.907,39.392,25.417,36.569,24.09ZM9.781,35.4H2.765c0-8.327,7.313-8.325,8.936-10.5l.186-.993A7.959,7.959,0,0,1,8,16.706c0-4.292,2.792-7.773,6.236-7.773,2.439,0,4.545,1.751,5.568,4.3-3.811,1.226-6.62,5.316-6.62,10.161a11.539,11.539,0,0,0,2.567,7.382A13.717,13.717,0,0,0,9.781,35.4Zm12.483,6.684H10.715c0-8.327,7.313-8.325,8.936-10.5l.186-.993a7.959,7.959,0,0,1-3.89-7.2c0-4.292,2.792-7.773,6.236-7.773s6.236,3.48,6.236,7.773a7.985,7.985,0,0,1-3.831,7.172l.212,1.129c1.78,2.072,8.85,2.207,8.85,10.394ZM34.585,35.4a13.716,13.716,0,0,0-5.968-4.628,11.565,11.565,0,0,0,2.567-7.383c0-4.845-2.808-8.934-6.618-10.161,1.024-2.545,3.13-4.3,5.569-4.3,3.444,0,6.236,3.48,6.236,7.773a7.986,7.986,0,0,1-3.831,7.173l.212,1.129c1.78,2.072,8.85,2.207,8.85,10.394Z"
            transform="translate(0 -6.168)"
            strokeLinecap="round"
            strokeWidth={1}
          />
        </g>
      </svg>
    </div>
  )
}
