import styled from 'styled-components'

export const SmBaseButton = styled.div`
  background-color: #19c78a;
  color: white;
  display: flex;
  border: 0;
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
  border-radius: 40px;
  user-select: none;
  div {
    font-weight: 700;
    line-height: 1.5;
  }
  text-shadow: 1px 1px 3px #052d1f; /* Apply text shadow here */

  display: flex;
  justify-content: center !important;

  transition: all 0.3s;

  &:hover {
    background-color: #129366;
    cursor: pointer;

    transform: scale(1.05);

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

    div {
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    }
  }
`
