import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface MonthDonationData {
  media_mensal: string
  porcentagem_valor_mes: string
  quant_pedidos_mes: number
  valor_total_mes: string
}

export function useFetchMonthTotalDonations() {
  const totalMonthDonations = useQuery<MonthDonationData>({
    queryKey: ['MONTH_TOTAL_DONATIONS'],
    queryFn: async () => {
      const response = await DataService.get('/admin/dash-board/donations/total-donations-mes-atual')

      return response?.data
    },
  })

  return totalMonthDonations
}
