import { StyledContent } from '../styles'
import { useAdminContext } from '../../../context/AdminLayout'
import { MobileLayout } from './MobileMenu'
import { ReactNode } from 'react'

interface MobileViewProps {
  children: ReactNode
}

export function MobileView({ children }: MobileViewProps) {
  const { location } = useAdminContext()

  return (
    <MobileLayout>
      <StyledContent location={location}>{children}</StyledContent>
    </MobileLayout>
  )
}
