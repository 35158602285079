import BaseButton from '@/container/admin/components/button/BaseButton'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { useBlur } from './BlurContext'

export function BlurToggle() {
  const { isBlurred, changeBlur } = useBlur()

  return (
    <BaseButton type="text" onClick={changeBlur}>
      {isBlurred ? (
        <Eye size={30} style={{ color: 'var(--text-main-color)' }} />
      ) : (
        <EyeSlash size={30} style={{ color: 'var(--text-main-color)' }} />
      )}
    </BaseButton>
  )
}
