import styled from 'styled-components'

export const SmBaseCard = styled.div`
  border: solid #c98f00 2px;
  background-color: #f3ebe1;
  border-radius: 24px;
  width: 100%;

  color: #1e1e1e;
  z-index: 999;
`
