import { Form, UploadFile } from 'antd'
import { StyledSelect } from '../../../../components/Select/styles'
import { countries } from '../../../../../../utility/allCountries'
import { CpfCnpjInput } from '../../../Campaign/CampaignIdentification/components/IdentificationForm/CpfCnpjInput'
import { StyledInput } from '../../../../components/Inputs'
import { validateDate, validatePassword } from '../../../../../../utility/utility'
import InputMask from 'react-input-mask'
import { StyledInputPassword } from '../../../../components/Inputs/styles'
import { FormInstance } from 'antd/es/form/Form'
import { useLocation } from 'react-router-dom'
import { CarteiraLocationState } from '..'
import { UploadPhoto } from '../../../Campaign/CampaignIdentification/components/UploadPhoto'

export function StepOne({
  form,
  file,
  setFile,
}: {
  form: FormInstance
  file: UploadFile[]
  setFile: React.Dispatch<React.SetStateAction<UploadFile[]>>
}) {
  const location = useLocation()

  const { tipo_documento } = location.state as CarteiraLocationState

  return (
    <>
      <Form.Item name="pais" label="País" initialValue="brasil" rules={[{ required: true, message: 'Obrigatório' }]}>
        <StyledSelect options={countries} disabled />
      </Form.Item>

      <CpfCnpjInput form={form} nostyle disabled={true} tipo_documento={tipo_documento} />

      <Form.Item
        name="nome"
        label="Nome"
        rules={[
          { required: true, message: 'Obrigatório' },
          { min: 6, message: 'Mínimo 6 caracteres' },
          { max: 100, message: 'Máximo 100 caracteres' },
        ]}
      >
        <StyledInput />
      </Form.Item>
      <Form.Item name="nome_social" label="Como deseja ser chamado?">
        <StyledInput />
      </Form.Item>

      <Form.Item name="sexo" label="Sexo" rules={[{ required: true, message: 'Obrigatório' }]} initialValue="masculino">
        <StyledSelect>
          <StyledSelect.Option value="masculino">Masculino</StyledSelect.Option>
          <StyledSelect.Option value="feminino">Feminino</StyledSelect.Option>
        </StyledSelect>
      </Form.Item>

      <Form.Item
        name="data_nascimento"
        label="Data de nascimento"
        rules={[
          { required: true, message: 'Por favor, preencha sua data de nascimento!' },
          { validator: (_, value) => validateDate(value) },
        ]}
      >
        <InputMask maskChar={null} mask="99/99/9999" placeholder="dd/mm/yyyy">
          {(inputProps) => <StyledInput {...inputProps} />}
        </InputMask>
      </Form.Item>

      <UploadPhoto file={file} setFile={setFile} required={false} form={form} />

      <Form.Item name="telefone" label="Telefone" rules={[{ required: true, message: 'Obrigatório' }]}>
        <InputMask mask="(99) 99999-9999" placeholder="(99) 99999-9999" maskChar={null}>
          {(inputProps) => <StyledInput {...inputProps} />}
        </InputMask>
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          { required: true, message: 'Por favor, insira seu email!' },
          { type: 'email', message: 'Formato de e-mail inválido!' },
          { min: 10, message: 'O e-mail deve ter no mínimo 10 caracteres!' },
          { max: 100, message: 'O e-mail deve ter no máximo 100 caracteres!' },
        ]}
      >
        <StyledInput placeholder="email@email.com" />
      </Form.Item>

      <Form.Item
        name="confirmEmail"
        label="Repita seu e-mail"
        dependencies={['email']}
        rules={[
          { required: true, message: 'Por favor, confirme seu email!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Os e-mails não coincidem!'))
            },
          }),
        ]}
      >
        <StyledInput placeholder="email@email.com" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Crie uma senha"
        rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
      >
        <StyledInputPassword placeholder="Senha" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Repita sua senha"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Por favor, confirme sua senha!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('As senhas não coincidem!'))
            },
          }),
        ]}
      >
        <StyledInputPassword placeholder="Repita sua senha" />
      </Form.Item>
    </>
  )
}
