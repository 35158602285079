import { Tabs } from 'antd'
import * as S from './BaseTabs.Styles'

interface BaseTabsProps {
  children?: React.ReactNode
  [key: string]: any
}

export default function BaseTabs({ children, ...props }: BaseTabsProps) {
  return <S.Tabs {...props}>{children}</S.Tabs>
}

export const { TabPane } = Tabs
