import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface GetCardTotalRecebimentosProps {
  fk_familia_agape_id: number
}

interface GetCardTotalRecebimentosResponse {
  total_itens_recebidos: string
}

export function useGetCardTotalRecebimentos({ fk_familia_agape_id }: GetCardTotalRecebimentosProps) {
  const totalRecebimentos = useQuery({
    queryKey: ['TOTAL_RECEBIMENTOS', fk_familia_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetCardTotalRecebimentosResponse>(
        `/agape/card-total-recebimentos/${fk_familia_agape_id}`,
      )

      return response.data
    },

    enabled: !!fk_familia_agape_id,
  })

  return totalRecebimentos
}
