import { ChartCard } from '@/container/admin/components/Cards/ChartCard'
import { RootState } from '@/redux/rootReducers'
import { useSelector } from 'react-redux'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useMessagesPerMonth } from '../../hooks/use-messages-per-month'

export function MessagesPerMonth() {
  const { data, isLoading } = useMessagesPerMonth()
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  return (
    <ChartCard title="Mensagens por Mês" loading={isLoading}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <XAxis 
            dataKey="month" 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <YAxis 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: isDark ? '#1E1E1E' : '#fff',
              border: `1px solid ${isDark ? '#333333' : '#f0f0f0'}`,
              color: isDark ? '#E1E1E1' : '#000'
            }}
          />
          <Area type="monotone" dataKey="count" stroke="#FF6B00" fill="#FF6B0033" />
        </AreaChart>
      </ResponsiveContainer>
    </ChartCard>
  )
} 