import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

type PermissionItem = {
  acessar: number
  criar: number
  deletar: number
  editar: number
  menu_id: number
}

type PermissionCategory =
  | 'acao_doacao_agape'
  | 'campanha'
  | 'dash_board_donations'
  | 'dash_board_users'
  | 'doacao_agape'
  | 'doacoes'
  | 'empresa'
  | 'endereco'
  | 'estoque_agape'
  | 'familia_agape'
  | 'general'
  | 'logs'
  | 'marechal'
  | 'perfil'
  | 'usuario'

type PermissionsResponseRecord = Record<PermissionCategory, PermissionItem>

export type PermissionsResponse = {
  permissoes: PermissionsResponseRecord
}

export function useGetPermissions(): UseQueryResult<PermissionsResponse> {
  return useQuery({
    queryKey: ['permissoes'],
    queryFn: async () => {
      const response = await DataService.get('users/me/permissions')
      return response?.data
    },
  })
}
