import { http, HttpResponse } from 'msw'
import { RegisterGroupUserBodySchema } from '../../container/admin/hooks/users/Mutations/useRegisterGroupUser'

export const registrarGeneralMock = http.post<never, RegisterGroupUserBodySchema['data'], { msg: string }>(
  '/groups/registrar-general',
  async ({ request }) => {
    const { fk_usuario_id, link_grupo, nome_grupo, quant_membros_grupo, tempo_de_administrador } = await request.json()

    if (
      fk_usuario_id === 2 &&
      link_grupo === 'https://chat.whatsapp.com/7843847412312312312312' &&
      nome_grupo === 'grupo teste general' &&
      quant_membros_grupo === 150 &&
      tempo_de_administrador === 10
    ) {
      return HttpResponse.json({
        msg: 'General criado com sucesso!',
      })
    }

    if (
      fk_usuario_id === 69064 &&
      link_grupo === 'https://chat.whatsapp.com/7843847412312312312312' &&
      nome_grupo === 'grupo general estrangeiro' &&
      quant_membros_grupo === 20 &&
      tempo_de_administrador === 1
    ) {
      return HttpResponse.json({
        msg: 'General criado com sucesso!',
      })
    }

    if (
      quant_membros_grupo === 20 &&
      tempo_de_administrador === 1 &&
      nome_grupo === 'token from email teste' &&
      link_grupo === 'https://chat.whatsapp.com/bondade412312312312312' &&
      fk_usuario_id === 69066
    ) {
      return HttpResponse.json({
        msg: 'General criado com sucesso!',
      })
    }
  },
)
