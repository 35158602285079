import { Skeleton } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { Chart } from './Chart'
import { DataService } from '../../../../../../../lib/dataService'

export function Content() {
  const query = useQuery({
    queryKey: 'SIGNUPS_PER_MONTH_DASHBOARD',
    queryFn: async () => {
      const response = await DataService.get(`admin/dash-board/users/users-by-month`)
      return response?.data?.cadastros_by_months
    },
  })

  if (query?.isPending) {
    return (
      <div style={{ height: '25rem', display: 'grid', placeItems: 'center', padding: '1rem' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (query?.isError) {
    return (
      <h4 style={{ color: 'red', height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )
  }

  return <Chart data={query?.data} />
}
