import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface EnderecosFamiliasResponse {
  enderecos: {
    bairro: string
    cep: string
    cidade: string
    complemento: string | null
    estado: string
    latitude: number
    longitude: number
    numero: string
    ponto_referencia: string
    rua: string
  }[]
}

export function useGetEnderecosFamilias() {
  const enderecosFamilias = useQuery({
    queryKey: ['ENDERECOS_FAMILIAS'],
    queryFn: async () => {
      const response = await DataService.get<EnderecosFamiliasResponse>(`/agape/listar-enderecos-familias-agape`)

      return response.data
    },
  })

  return enderecosFamilias
}
