import { useEffect, useRef } from "react";
import { SlideProps } from "../types";

interface UseAutoSlideEffectProps {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  slides: SlideProps[];
  activeTab: number;
}

export const useAutoSlideEffect = ({ setActiveTab, slides, activeTab }: UseAutoSlideEffectProps) => {
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setActiveTab((current: number) => {
        if (current >= slides.length - 1) {
          return 0;
        }
        return current + 1;
      });
    }, 10000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [slides.length, setActiveTab, activeTab]);
}
