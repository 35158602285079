import { useRef } from 'react'
import styled from 'styled-components'
import { useInfiniteQuery } from '@tanstack/react-query'
import BaseSpin from '../../../../../components/spin/BaseSpin'
import BaseCard from '../../../../../components/card/BaseCard'
import { DataService } from '../../../../../../../lib/dataService'
import { ChartTitle, ErrorMessage } from '../../../styles'

export function CitiesRank() {
  const query = useInfiniteQuery({
    queryKey: ['TODAY_SIGNUPS_INFITE'],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await DataService.get(`admin/dash-board/users/users-by-region?page=${pageParam}`)
      return response?.data
    },
    getNextPageParam: (lastPage) => {
      return lastPage.page < lastPage.total_pages ? lastPage.page + 1 : null
    },
  })

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending, isError } = query

  const tableRef = useRef(null)

  const handleScroll = () => {
    const bottom = tableRef.current.scrollHeight === tableRef.current.scrollTop + tableRef.current.clientHeight
    if (bottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  if (isError) {
    return (
      <BaseCard>
        <ErrorMessage />
      </BaseCard>
    )
  }

  return (
    <BaseCard padding="0px">
      <ChartTitle style={{ marginLeft: '1.5%', padding: '20px 0px 0px 20px' }}>Ranking de cidades</ChartTitle>
      <ScrollableTable ref={tableRef} onScroll={handleScroll}>
        <table>
          <thead>
            <tr>
              <th>Cidade</th>
              <th>Cadastros</th>
              <th>%</th>
              <th>Campanha Relevante</th>
            </tr>
          </thead>
          <tbody>
            {isPending ? (
              <tr>
                <td colSpan="4" style={{ textAlign: 'center', padding: '8px', marginTop: '0.5rem' }}>
                  <BaseSpin tip="Carregando..." />
                </td>
              </tr>
            ) : (
              data?.pages.map((page) => {
                return page?.users_and_campaigns_by_region?.map((city) => {
                  if (city?.cidade) {
                    return (
                      <tr key={city?.cidade}>
                        <td>{city?.cidade}</td>
                        <td>{city?.quantidade_usuarios}</td>
                        <td>{city?.percentage}</td>
                        <td>{city?.quantidade_campanha_destaque}</td>
                      </tr>
                    )
                  }
                  return null
                })
              })
            )}
            {isFetchingNextPage && (
              <tr>
                <td colSpan="4" style={{ textAlign: 'center', padding: '1rem', color: 'gray' }}>
                  <div>Carregando mais...</div>
                  <BaseSpin />
                </td>
              </tr>
            )}
            {data && !isPending && !isFetchingNextPage && !hasNextPage && (
              <tr>
                <td colSpan="4" style={{ textAlign: 'center', padding: '16px', color: 'gray' }}>
                  Não há mais itens para carregar...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScrollableTable>
    </BaseCard>
  )
}

const ScrollableTable = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border: none !important;
  color: var(--text-main-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th {
      position: sticky;
      top: 0;
      background-color: var(--custom-table-bg-color);
      color: var(--text-main-color);
      padding: 1rem;
      font-weight: 600;
      text-align: center;
      border-bottom: 1px solid #4a4a4a;
    }

    tbody {
      tr {
        background-color: var(--card-bg-color);

        &:hover {
          background-color: var(--custom-table-bg-color-hover) !important;
          cursor: pointer;
        }

        td {
          padding: 1rem;
          text-align: center;
          border-top: 1px solid #4a4a4a;
          overflow: hidden;
        }
      }
    }
  }
`
