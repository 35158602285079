import React from 'react'
import BaseForm from '../BaseForm/BaseForm'
import BaseButtonsGroup from '../components/BaseButtonsGroup/BaseButtonsGroup'
import { BaseFormTitle } from '../components/BaseFormTitle/BaseFormTitle'
import { BaseFormItem } from '../components/BaseFormItem/BaseFormItem'
import { BaseFormList } from '../components/BaseFormList/BaseFormList'

export default function BaseButtonsForm({
  form,
  isFieldsChanged,
  setFieldsChanged,
  footer,
  loading = false,
  children,
  ...props
}) {
  const [formDefault] = BaseForm.useForm()
  const currentForm = form || formDefault

  const onCancel = () => {
    currentForm?.resetFields()
    // eslint-disable-next-line no-unused-expressions
    setFieldsChanged && setFieldsChanged(false)
  }

  return (
    <BaseForm form={currentForm} {...props}>
      {children}
      {isFieldsChanged &&
        (footer || <BaseButtonsGroup loading={loading} onCancel={onCancel} />)}
    </BaseForm>
  )
}

BaseButtonsForm.Title = BaseFormTitle
BaseButtonsForm.Item = BaseFormItem
BaseButtonsForm.List = BaseFormList
BaseButtonsForm.useForm = BaseForm.useForm
BaseButtonsForm.Provider = BaseForm.Provider
