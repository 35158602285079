import BaseCard from '../../../components/card/BaseCard'
import { CiclosDeDoacao } from '../Itens/Tabelas/CiclosDeDoacao'

export function DoacaoPage() {
  return (
    <BaseCard title="Listagem de ciclos ativos">
      <CiclosDeDoacao />
    </BaseCard>
  )
}
