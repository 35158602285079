import { motion } from "motion/react";

interface AnimatedChartWrapperProps {
  children: React.ReactNode;
  index: number;
}

export function AnimatedChartWrapper({ children, index }: AnimatedChartWrapperProps) {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 50,
        scale: 0.65,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
        scale: 1,
      }}
      transition={{
        duration: 0.5,
        delay: index * 0.25,
        type: "spring",
        damping: 15,
        stiffness: 100,
      }}
    >
      {children}
    </motion.div>
  );
}