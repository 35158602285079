import { http, HttpResponse } from 'msw'
import { CreateFichaVocacionalProps } from '../../../container/benfeitor/hooks/api/vocacional/mutations/useCreateFichaVocacional'

export const createFichaVocacionalMock = http.post<never, CreateFichaVocacionalProps>(
  '/vocacional/registrar-ficha-vocacional',
  async ({ request }) => {
    const body = await request.formData()

    const dadosFicha = body.get('ficha_vocacional')

    if (!dadosFicha || typeof dadosFicha !== 'string') {
      throw new Error('Os dados da ficha vocacional são inválidos ou não foram fornecidos.')
    }

    const dadosFichaJson = JSON.parse(dadosFicha)

    if (dadosFichaJson.motivacao_instituto === 'Minha motivação pelo Instituto') {
      return new HttpResponse(null, { status: 201 })
    }

    return new HttpResponse(null, { status: 500 })
  },
)
