import styled from 'styled-components'

interface IndicadorMovimentacaoProps {
  entrada: boolean
}

export const IndicadorMovimentacao = styled('span')<IndicadorMovimentacaoProps>`
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  color: ${(props) => (props.entrada ? 'var(--success-color)' : 'var(--error-color)')};
`
