export function getCurrentMonthQueryString(page = 1) {
  const formatDateTime = (date, startOfDay = false) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = startOfDay ? '00' : '23';
    const minutes = startOfDay ? '00' : '59';
    const seconds = startOfDay ? '00' : '59';

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const now = new Date();

  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  const data_inicio = formatDateTime(startOfMonth, true);
  const data_fim = formatDateTime(now, false);

  return {
    data_fim,
    data_inicio,
    page,
    queryString: `data_inicio=${encodeURIComponent(data_inicio)}&data_fim=${encodeURIComponent(data_fim)}&page=${page}`,
  };
}

export function getCurrentDayQueryString(page = 1) {
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const now = new Date();

  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const data_inicio = formatDateTime(startOfDay);

  return {
    data_inicio,
    page,
    queryString: `data_inicio=${encodeURIComponent(data_inicio)}&page=${page}`,
  };
}

export const downloadImageFromList = async (url, nome, id) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image from URL: ${url}`);
    }
    const blob = await response.blob();
    const urlBlob = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlBlob;
    a.download = `${nome}.jpg`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(urlBlob);
    return id;
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};

export const generateTextFileContent = (data, nomeDownload, idDownload, data_hora) => {
  const header = `Intenções - Download (${data_hora}) - ${nomeDownload} - ${idDownload}\n\n`;

  const body = data.map((item) => `[${item.username} - ${item.key}]\n${item.intencao}`).join('\n\n');

  return header + body;
};

export const downloadTextFile = (filename, content) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export function pause(msec) {
  return new Promise((resolve) => {
    setTimeout(resolve, msec || 1000);
  });
}
