import { Col, Row } from 'antd'
import moment from 'moment'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'
import BaseModal from '../../../../../components/modal/BaseModal'
import { MultipleSelect } from '../../../../../components/multiple-select/multiple-select'
import { useFetchCampaignsOptions } from '../../../../../hooks'
import { Label, ModalContent } from '../../../styles'

export function FilterModal({ filters, setFilters, isModalOpen, handleToggleModal }) {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })
  const { data: allCampaigns = [] } = useFetchCampaignsOptions()

  const isFilterActive = Boolean(filters?.campanha?.length > 0 || filters?.data_inicio)

  return (
    <BaseModal
      title="Filtros dos Cadastros do Dia"
      open={isModalOpen}
      onOk={() => handleToggleModal(false)}
      onCancel={() => handleToggleModal(false)}
      closeIcon="X"
      size="large"
    >
      <ModalContent>
        <Col xs={24} sm={10}>
          <Label>Campanhas:</Label>
          <MultipleSelect
            style={{ width: '100%' }}
            list={allCampaigns?.map((campaign) => campaign.value)}
            options={allCampaigns}
            value={filters?.campanha}
            onChange={(value) => handleInputChange({ campanha: value })}
          />
        </Col>
      </ModalContent>
      <Row justify="center" style={{ marginTop: '1rem' }}>
        <Col xs={24} sm={10}>
          <Label>Data:</Label>
          <BaseDatePicker
            type="day"
            style={{ width: '100%' }}
            defaultValue={moment()}
            onChange={(dates, dateStrings) => {
              const formattedStartDate = moment(dateStrings, 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
              handleInputChange({
                data_inicio: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
              })
            }}
            s
            format="DD/MM/YYYY"
            placeholder={['Inicial', 'Final']}
          />
        </Col>
      </Row>
    </BaseModal>
  )
}
