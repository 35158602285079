import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { DataService } from '../../../../../../../lib/dataService'

interface UseDonationActionVariablesProps {
  benfeitor: string
  payload: Record<string, any> | null
  successDescription: string
  successTitle: string
  url: string
}

export const useDonationAction = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ({ url, payload }: { url: string; payload: Record<string, any> | null }) => {
      return DataService.post(url, payload)
    },
    onSuccess: (_data, variables: UseDonationActionVariablesProps) => {
      const { successTitle, benfeitor, successDescription } = variables
      queryClient.invalidateQueries({ queryKey: ['DONATIONS'] })

      notification.success({
        message: <div style={{ fontWeight: 'bold', color: '#50556f' }}>{successTitle}</div>,
        description: (
          <div>
            <div style={{ color: '#757ca3', fontWeight: 600 }}>{benfeitor}</div>
            <div style={{ color: '#8b8ea4' }}>{successDescription}</div>
          </div>
        ),
      })
    },
    onError: (error: any) => {
      notification.error({
        message: (
          <div style={{ fontWeight: 'bold', color: 'red' }}>
            Ocorreu um erro ao processar essa ação: {error.response?.data?.error || 'Erro desconhecido'}
          </div>
        ),
      })
      console.error('Falha ao realizar ação: ', error)
    },
  })

  const handleDonateAction = (
    acao: string,
    objectId: number | string,
    benfeitor: string,
    isTransactionId: string | boolean | undefined,
    handleCloseActionModal: () => void,
  ) => {
    const actionConfig: Record<
      string,
      { endpoint: string; successTitle: string; successDescription: string; isResendEmail: boolean }
    > = {
      Reenviar: {
        endpoint: '/checkout/payment/resend-email',
        successTitle: 'Dados para pagamento reenviados',
        successDescription: 'Verifique seu e-mail.',
        isResendEmail: true,
      },
      Estornar: {
        endpoint: '/checkout/payment/credit-card/reversal',
        successTitle: 'Transação estornada',
        successDescription: '',
        isResendEmail: false,
      },
      'Cancelar recorrência': {
        endpoint: '/checkout/payment/cancel-recurrence',
        successTitle: 'Recorrência cancelada',
        successDescription: 'O usuário não será mais cobrado.',
        isResendEmail: false,
      },
    }

    const selectedAction = actionConfig[acao]

    if (!selectedAction) {
      throw new Error('Houve um erro inesperado')
    }

    const { endpoint, successTitle, successDescription, isResendEmail } = selectedAction
    const url = isResendEmail ? endpoint : `${endpoint}/${objectId}`

    const payload = isResendEmail
      ? isTransactionId
        ? { transaction_id: objectId }
        : { fk_processamento_pedido_id: objectId }
      : null

    mutation.mutate({ url, payload, successTitle, benfeitor, successDescription })

    handleCloseActionModal()
  }

  return { handleDonateAction }
}
