import { Empty, Result } from 'antd'
import styled from 'styled-components'

const resultStatus = {
  404: 'var(--error-color, #6f6f6f)',
  403: 'var(--error-color, #6f6f6f)',
  500: 'var(--error-color, #6f6f6f)',
  success: 'var(--success-color, #6f6f6f)',
  warning: 'var(--warning-color, #6f6f6f)',
}

export const StyledResult = styled(Result)`
  padding: 0;

  .ant-result-title,
  .ant-result-subtitle {
    ${(props) => {
      return `color: ${resultStatus[props.status] || 'var(--text-main-color)'}`
    }}
  }
`

export const StyledEmpty = styled(Empty)`
  color: var(--text-main-color);
`
