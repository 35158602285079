import { useQuery } from '@tanstack/react-query';
import { DataService } from '../../../../../lib/dataService';

interface TodayDonationsResponse {
  media_diaria: string;
  porcentagem_dia: string;
  quant_pedidos: number;
  valor_total: string;
}

export const useFetchTodayDonations = () => {
  return useQuery<TodayDonationsResponse>({
    queryKey: ['DONATIONS_TODAY_DASHBOARD'],
    queryFn: async () => {
      const response = await DataService.get<TodayDonationsResponse>('admin/dash-board/donations/total-donations-dia-atual');
      return response?.data;
    },
  });
}; 