import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface ObterPermissaoVoluntarioResponse {
  status: false
}

export function useObterPermissaoVoluntario() {
  const permissaoVoluntario = useQuery({
    queryKey: ['PERMISSAO_VOLUNTARIO'],
    queryFn: async () => {
      const response = await DataService.get<ObterPermissaoVoluntarioResponse>('/agape/status-permissao-voluntarios')

      return response.data
    },
  })

  return permissaoVoluntario
}
