import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Col, DatePicker, Input, Row, Select, Spin } from 'antd'
import FeatherIcon from 'feather-icons-react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { RecordViewWrapper, FiltersContainer } from './Style'
import { useFetchProfiles, useRedirectOnPermDenied } from '../../hooks'
import { useGetAllLogs } from '../../hooks/system/useGetAllLogs'
import { handleFilterChangeAndPagination, loadQueries } from '../../../../utility/utility'
import { BaseTable } from '../../components/table/BaseTable/BaseTable'
import BaseModal from '../../components/modal/BaseModal'
import BaseCard from '../../components/card/BaseCard'
import { BasePaginationLocale } from '../../components/pagination/BasePaginationLocale'
import { BaseSelect } from '../../components/select/BaseSelect'
import BaseSpin from '../../components/spin/BaseSpin'
import { BaseInput } from '../../components/inputs/BaseInput/BaseInput'
import BaseDatePicker from '../../components/datepicker/BaseDatePicker'
import { LogsDetails } from './LogsDetails'
import BaseButton from '../../components/button/BaseButton'
import { FilterOutlined } from '@ant-design/icons'

const Logs = () => {
  useRedirectOnPermDenied('logs')
  const [showFilters, setShowFilters] = useState(true)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const location = useLocation()
  const [filters, setFilters] = useState({})
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  useEffect(() => {
    loadQueries({ location, filters, setFilters })
  }, [])

  const { data: logs, isError, isLoading } = useGetAllLogs(filters)
  const { data: allProfiles, refetch: fetchProfiles } = useFetchProfiles({
    enabled: false,
  })

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id', align: 'center' },
    { title: 'Data e Hora', dataIndex: 'date', key: 'date', align: 'center' },
    { title: 'Usuário', dataIndex: 'user', key: 'user', align: 'center' },
    { title: 'Perfil', dataIndex: 'profile', key: 'profile', align: 'center' },
    { title: 'Área', dataIndex: 'field', key: 'field', align: 'center' },
    { title: 'Ação', dataIndex: 'action', key: 'action', align: 'center' },
    { title: 'IP DO USUÁRIO', dataIndex: 'ipuser', key: 'ipuser', align: 'center' },
    {
      title: 'DETALHES',
      dataIndex: 'details',
      key: 'details',
      align: 'center',
      render: (text, record) => (
        <FeatherIcon
          style={{ cursor: 'pointer' }}
          icon="bar-chart-2"
          onClick={() => {
            setSelectedRecord(record)
            setIsModalOpen(true)
          }}
        />
      ),
    },
  ]

  const dataSource = logs?.logs?.map((log, index) => ({
    key: index.toString(),
    id: log.user_id,
    user: log.username,
    profile: log.cargo,
    field: log.route,
    date: new Date(log.timestamp).toLocaleString('pt-BR'),
    details: log,
    action:
      {
        GET: 'Visualização',
        POST: 'Cadastro',
        PUT: 'Atualização',
        DELETE: 'Exclusão',
        PATCH: 'Atualização Parcial',
      }[log.method] || '',
    ipuser: log.ip_address,
  }))

  return (
    <BaseCard
      style={{ marginTop: '2rem' }}
      title={<strong>Logs de Acessos</strong>}
      extra={
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '1rem' }}>
          <BaseButton onClick={() => setShowFilters((prev) => !prev)}>
            <FilterOutlined />
          </BaseButton>
        </div>
      }
    >
      <LogsDetails isModalOpen={isModalOpen} selectedRecord={selectedRecord} setIsModalOpen={setIsModalOpen} />

      {showFilters && (
        <FiltersContainer showFilters={showFilters}>
          <>
            <BaseSelect
              allowClear
              placeholder="Ação"
              style={{ flex: '1 1 150px', minWidth: '115px' }}
              value={filters?.action}
              onChange={(value) => handleInputChange({ action: value })}
            >
              <Select.Option value="Cadastro">Cadastro</Select.Option>
              <Select.Option value="Visualizar">Visualizar</Select.Option>
              <Select.Option value="Deletar">Deletar</Select.Option>
              <Select.Option value="Atualizar">Atualizar</Select.Option>
            </BaseSelect>

            <BaseSelect
              allowClear
              placeholder="Perfil"
              style={{ flex: '1 1 150px', minWidth: '115px' }}
              value={filters?.cargo}
              onChange={(value) => handleInputChange({ cargo: value })}
              onClick={() => {
                fetchProfiles()
              }}
            >
              {allProfiles?.perfis?.map((profile) => (
                <Select.Option key={profile.id} value={profile.nome}>
                  {profile.nome}
                </Select.Option>
              ))}
            </BaseSelect>

            <BaseInput
              placeholder="Usuário"
              style={{ flex: '1 1 150px', minWidth: '115px' }}
              allowClear
              value={filters?.username}
              onChange={(e) => handleInputChange({ username: e.target.value })}
            />

            <BaseInput
              placeholder="Área"
              style={{ flex: '1 1 150px', minWidth: '115px' }}
              allowClear
              value={filters?.route}
              onChange={(e) => handleInputChange({ route: e.target.value })}
            />

            <BaseDatePicker
              range
              style={{ flex: '2 1 150px', minWidth: '115px' }}
              value={[
                filters?.start_date ? moment(filters.start_date, 'YYYY-MM-DD') : null,
                filters?.end_date ? moment(filters.end_date, 'YYYY-MM-DD') : null,
              ]}
              onChange={(dates, dateStrings) => {
                const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
                const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD')

                handleInputChange({
                  start_date: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
                  end_date: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
                })
              }}
              format="DD/MM/YYYY"
              placeholder={['Inicial', 'Final']}
            />
          </>
        </FiltersContainer>
      )}
      <BaseTable
        style={{ paddingTop: '2rem' }}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: logs?.page,
          total: logs?.total,
          pageSize: filters?.per_page,
          showSizeChanger: true,
        }}
        isLoading={isLoading}
        isError={isError}
        onChange={(pagination) => {
          handleInputChange({ page: pagination.current }, 'pagination')
          handleInputChange({ per_page: pagination.pageSize }, 'pagination')
        }}
      />
    </BaseCard>
  )
}

export default Logs
