import { Form, InputNumber } from 'antd';
import { PaymentCard } from '../PaymentCard';
import { CardContainer, PaymentOptionsContainer } from './styles';
import { usePaymentMethod } from '../../context';
import React, { useState } from 'react';

export function ValuesOption() {
  const { dispatch, pixQrCode, boletoData, activeValue } = usePaymentMethod();
  const [isCustomInputActive, setCustomInputActive] = useState(false);

  if (pixQrCode || boletoData) return null;

  const handlePaymentValueClick = (value: string | number) => {
    if (value === 'OUTRO VALOR') {
      setCustomInputActive(true);
      dispatch({ type: 'SET_ACTIVE_VALUE', payload: 'OUTRO VALOR' });
    } else {
      setCustomInputActive(false);
      dispatch({ type: 'SET_ACTIVE_VALUE', payload: value });
    }
  };

  const paymentValues = [20, 60, 100];

  return (
    <>
      <Form.Item noStyle>
        <PaymentOptionsContainer>
          <CardContainer>
            {paymentValues.map((value) => (
              <PaymentCard
                key={value}
                style={{ padding: '1rem' }}
                text={`R$ ${value},00`}
                active={activeValue === value && !isCustomInputActive}
                onClick={() => handlePaymentValueClick(value)}
              />
            ))}
            <PaymentCard
              style={{ padding: '1rem' }}
              text="Outro Valor"
              active={isCustomInputActive}
              onClick={() => handlePaymentValueClick('OUTRO VALOR')}
            />
          </CardContainer>
        </PaymentOptionsContainer>
      </Form.Item>

      {isCustomInputActive && (
        <div style={{ maxWidth: '700px', marginRight: 'auto' }}>
          <Form.Item
            name="custom-value"
            label="Informe um valor a partir de R$ 10,00"
            required
            labelCol={{ span: 24 }}
            rules={[
              { required: true, message: '' },
              {
                validator: (_, value) =>
                  value >= 10
                    ? Promise.resolve()
                    : Promise.reject(new Error('O valor deve ser maior ou igual a R$ 10,00.')),
              },
            ]}
          >
            <InputNumber
              controls={false}
              addonBefore="R$"
              formatter={(value) => {
                if (!value) return '';
                return new Intl.NumberFormat('pt-BR', {
                  maximumFractionDigits: 2,
                })
                  .format(value)
                  .replace(/\D00(?=\D*$)/, '');
              }}
              parser={(value) => {
                const cleanedValue = value.replace(/[^\d,]/g, '').replace(',', '.');
                const parsedValue = parseFloat(cleanedValue);

                if (!Number.isNaN(parsedValue) && parsedValue >= 10) {
                  dispatch({ type: 'SET_ACTIVE_VALUE', payload: parsedValue });
                }

                return Number.isNaN(parsedValue) ? 0 : parsedValue;
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      )}
    </>
  );
}
