import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, notification } from 'antd'
import {
  CampaignTitleContainer,
  ContentContainer,
  FormImageContainer,
  SignInTitleContainer,
  SubtitleContainer,
} from './styles'
import { StyledForm } from '../../../components/Form/styles'
import { StyledButton } from '../../../components/Button'
import { Spin } from '../../../components/Spin'

import { IdentificationForm } from './components/IdentificationForm'
import { CampaignCard } from './components/CampaignCard'
import { AddressForm } from './components/AddressForm'

import { useFetchUserMe, useFetchUserMeAddress } from '../../../../admin/hooks'
import { useRegisterUser } from '../../../hooks/user/mutations/registerUser'
import { useRegisterPresence } from '../../../../admin/hooks/users/Mutations/useRegisterPresence'
import { loadIdentificationFormValues } from './utils'

import { useFetchSingleCampaign } from '../../../../admin/hooks/campaigns/queries/useFetchSingleCampaign'
import { RegisterUserReqBody } from '../../../hooks/user/mutations/registerUser'
import { UpdateUserReqBody, useUpdateUser } from '../../../hooks/user/mutations/updateUser'
import { formatDateToUs } from '../../../../../utility/utility'
import { openLoginModal } from '../../../layouts/benfeitor-layout/components/Header/Modals/authModalsSlice'

interface IdentificationFormFields {
  pais: string
  email: string
  nome: string
  numero_documento: string
  password: string
  data_nascimento: string
  telefone: string
  sexo: 'masculino' | 'feminino'

  cpf?: string
  cnpj?: string
  identidade_estrangeira: string

  bairro?: string
  cep?: string
  cidade?: string
  complemento?: string
  estado?: string
  numero?: string
  rua?: string

  avatar: File

  detalhe_estrangeiro?: string
}

export function CampaignIdentification() {
  const [form] = Form.useForm()

  const history = useHistory()
  const dispatch = useDispatch()

  const { data: userMe } = useFetchUserMe()
  const { data: addressData } = useFetchUserMeAddress()

  const { campaignId } = useParams<{ campaignId: string }>()
  const { data: campaignData } = useFetchSingleCampaign(campaignId)

  const { mutateAsync: registerUser, isPending } = useRegisterUser()
  const { mutateAsync: updateUser, isPending: isPendingUpdate } = useUpdateUser()

  useRegisterPresence(parseFloat(campaignId), userMe?.id)

  useEffect(() => {
    loadIdentificationFormValues({ form, addressData, userMe })
  }, [form, userMe, addressData])

  const [formState, setFormState] = useState({
    step: 1,
    data: {},
  })

  const handleFinish = async (values: IdentificationFormFields) => {
    // register user in case its not logged in
    if (!userMe) {
      const registerUserReqBody: RegisterUserReqBody = {
        campanha_origem: parseInt(campaignId),
        email: values.email,
        nome: values.nome,
        numero_documento: values.cpf || values.cnpj || values.identidade_estrangeira!,
        pais: values.pais,
        password: values.password,
        tipo_documento:
          (values.cpf && 'cpf') ||
          (values.cnpj && 'cnpj') ||
          ((values.identidade_estrangeira && 'identidade_estrangeira') as 'cpf' | 'cnpj' | 'identidade_estrangeira'),
      }

      await registerUser(registerUserReqBody)
      return
    }

    // this is for user update, only in case its logged in - step 1
    if (formState.step === 1) {
      const currentValues = await form.validateFields()
      setFormState({ data: currentValues, step: 2 })
      return
    }

    // this is for user update, only in case its logged in - step 2
    if (formState.step === 2) {
      const allValues = { ...formState.data, ...values }

      const user = {
        data_nascimento: formatDateToUs(allValues.data_nascimento),
        nome: allValues.nome,
        telefone: allValues.telefone,
        sexo: allValues.sexo,
      }

      const userPayload: UpdateUserReqBody = {
        usuario: user,
        endereco:
          userMe?.pais === 'brasil'
            ? {
                bairro: values.bairro,
                cep: values.cep,
                cidade: values.cidade,
                complemento: values.complemento,
                estado: values.estado,
                numero: values.numero,
                rua: values.rua,
              }
            : {
                detalhe_estrangeiro: allValues.detalhe_estrangeiro,
              },
      }

      const imageFile: File = allValues.avatar

      try {
        await updateUser({ formattedValues: userPayload, imageFile: imageFile })
        history.replace(campaignData?.objetivo === 'doacao' ? `/benfeitor/campanha/${campaignId}/doacao` : '/')
      } catch (error) {
        console.log('error while updating user', error)
        throw error
      }
    }
  }

  const commonInputStyles = {
    lineHeight: 2.5715,
    borderRadius: 12,
    border: '2.5px solid #acacac',
    size: 'large' as const,
  }

  return (
    <ContentContainer>
      <CampaignTitleContainer>{campaignData?.titulo}</CampaignTitleContainer>
      <SubtitleContainer>Preencha com seus dados</SubtitleContainer>

      {!userMe && (
        <SignInTitleContainer>
          <div>Já possui uma conta?</div>
          <span onClick={() => dispatch(openLoginModal())}>Entrar!</span>
        </SignInTitleContainer>
      )}

      <FormImageContainer>
        <StyledForm layout="vertical" form={form} onFinish={handleFinish}>
          {<IdentificationForm style={{ display: formState.step === 1 ? 'block' : 'none' }} form={form} />}
          {userMe && formState.step === 2 && (
            <AddressForm
              style={{ display: formState.step === 2 ? 'block' : 'none' }}
              commonInputStyles={commonInputStyles}
              form={form}
            />
          )}

          <StyledButton
            style={{
              margin: '0 auto',
              padding: '1.25rem 2.5rem',
              minWidth: '125px',
              width: '100%',
            }}
            htmlType="submit"
            disabled={isPending || isPendingUpdate}
          >
            {isPending || isPendingUpdate ? <Spin color="white" /> : 'Enviar'}
          </StyledButton>
        </StyledForm>

        <CampaignCard />
      </FormImageContainer>
    </ContentContainer>
  )
}
