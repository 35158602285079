/* eslint-disable import/no-unresolved */
import '@/lib/sentry/instrument'
import { createRoot } from 'react-dom/client'
import App from './App'
import { enableBrowserMocking } from './lib/msw/enable-browser-mocking'
import { ErrorBoundary } from './lib/sentry/error-boundary'
import reportWebVitals from './reportWebVitals'

enableBrowserMocking().then(() => {
  const root = createRoot(document.getElementById('root'))
  reportWebVitals()
  root.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  )
})
