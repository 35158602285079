import { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import moment from 'moment'
import { formatToBrazilianCurrency, handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import {
  CanceledRecurrenceFilters,
  useFetchCanceledRecurrence,
} from '../../../../hooks/donations/queries/useFetchCanceledRecurrency'
import { useFetchAllCampaigns } from '../../../../hooks/campaigns/queries/useFetchAllCampaigns'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import { BaseSelect } from '../../../../components/select/BaseSelect'
import BaseSpin from '../../../../components/spin/BaseSpin'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import BaseCard from '../../../../components/card/BaseCard'
import { useFetchCampaignsOptions } from '../../../../hooks'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function RecurrencyCanceledTable() {
  const [filters, setFilters] = useState<CanceledRecurrenceFilters>()
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })
  const campaignsOptions = useFetchCampaignsOptions()

  const { data: canceledDonations, isPending, isError } = useFetchCanceledRecurrence(filters)

  const campaignsData = useFetchAllCampaigns()

  const dataSource = canceledDonations?.doacoes_recorrentes_canceladas.map((donation) => {
    return {
      key: donation?.id,
      id: donation?.id,
      forma_pagamento: donation?.metodo,
      data_doacao: donation?.data_pedido,
      titulo_campanha: donation?.campanha,
      cancelada_em: donation?.cancelada_em,
      valor_doacao: formatToBrazilianCurrency(donation?.valor),
      nome: donation?.benfeitor,
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Método',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
      align: 'center',
      width: 150,
    },
    {
      title: 'Cliente',
      dataIndex: 'nome',
      key: 'nome',
      filterDropdown: () => (
        <BaseCard>
          <BaseInput
            placeholder="Nome"
            prefix={<FeatherIcon icon="search" size={14} />}
            onChange={(e) => handleInputChange({ nome_usuario: e.target.value })}
            allowClear
          />
        </BaseCard>
      ),
      filteredValue: filters?.nome_usuario ? [filters.nome_usuario] : null,
    },
    {
      title: 'Data',
      dataIndex: 'data_doacao',
      key: 'data_doacao',
      filterDropdown: () => (
        <BaseCard>
          <BaseDatePicker
            range
            style={{ width: 280, height: 47 }}
            onChange={(_: null, dateStrings: string[]) => {
              const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00') || ''
              const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DDT23:59:59') || ''

              handleInputChange({
                data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
                data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
              })
            }}
            format="DD/MM/YYYY"
            placeholder={['Inicial', 'Final']}
          />
        </BaseCard>
      ),
      filteredValue: filters?.data_inicial && filters?.data_final ? [filters?.data_inicial, filters?.data_final] : null,
    },
    {
      title: 'Valor',
      dataIndex: 'valor_doacao',
      key: 'valor_doacao',
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
    {
      title: 'Campanha',
      dataIndex: 'titulo_campanha',
      key: 'titulo_campanha',
      align: 'left',
      filterDropdown: () => (
        <BaseCard style={{ minWidth: '15rem' }}>
          <BaseSelect
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder="Campanha"
            suffixIcon={<FeatherIcon icon="search" size={14} />}
            onChange={(value) => handleInputChange({ campanha_id: value })}
            onSearch={(value) => handleInputChange({ campanha_id: value })}
            notFoundContent={
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {campaignsData?.isLoading ? <BaseSpin /> : <p style={{ margin: 0 }}>Sem resultados.</p>}
              </div>
            }
          >
            {campaignsOptions?.data?.map((c, key) => {
              if (c.objetivo === 'doacao') {
                return (
                  <Option value={c.value} key={key}>
                    {c.label}
                  </Option>
                )
              }
              return null
            })}
          </BaseSelect>
        </BaseCard>
      ),
      filteredValue: filters?.campanha_id && [filters?.campanha_id],
    },
    {
      title: 'Cancelada em',
      dataIndex: 'cancelada_em',
      key: 'cancelada_em',
      align: 'center',
    },
  ]

  return (
    <BaseTable
      dataSource={dataSource}
      columns={columns}
      isPending={isPending}
      isError={isError}
      pagination={{
        current: canceledDonations?.page,
        total: canceledDonations?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination) => {
        handleInputChange({ page: pagination.current }, true)
        handleInputChange({ per_page: pagination.pageSize }, true)
      }}
    />
  )
}
