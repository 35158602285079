import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import { AxiosError } from 'axios'

interface ConsiderDonationParams {
  fk_processamento_pedido_id: number
  considerar: boolean
  benfeitor: string
  filters: Record<string, any> | undefined
}

export const useConsiderDesconsiderDonation = () => {
  const queryClient = useQueryClient()

  const considerDesconsiderDonation = useMutation<
    void, // Mutation response type (none in this case)
    Error, // Error type
    ConsiderDonationParams // Variables type
  >({
    mutationFn: async ({ fk_processamento_pedido_id, considerar, benfeitor }) => {
      try {
        await DataService.put(`checkout/include-donation/${fk_processamento_pedido_id}`)

        notification.success({
          message: 'Sucesso!',
          description: `Doação de ${benfeitor} ${considerar ? 'desconsiderada' : 'reconsiderada'}`,
        })

        queryClient.invalidateQueries({
          queryKey: ['DONATIONS'],
        })
        queryClient.invalidateQueries({
          queryKey: ['DESCONSIDERED_DONATIONS'],
        })
      } catch (error) {
        const errorMessage =
          (error as AxiosError<{ error: string }>).response?.data?.error ?? 'Por favor, tente novamente'

        notification.error({
          message: `Ocorreu um erro ao ${considerar ? 'reconsiderar' : 'desconsiderar'} a doação de ${benfeitor}`,
          description: errorMessage,
        })

        throw error
      }
    },
  })

  return considerDesconsiderDonation
}
