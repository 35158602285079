import { Col, Form, Row } from 'antd'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../../components/inputs/BaseInput/BaseInput'
import BaseButton from '../../../../../components/button/BaseButton'
import BaseModal from '../../../../../components/modal/BaseModal'
import {
  CadastrarAcaoSolidariaRequestData,
  useCadastrarAcaoSolidaria,
} from '../../../../../hooks/agape/mutations/useCadastrarAcaoSolidaria'

interface CadastrarAcaoSolidariaProps {
  open: boolean
  handleIsOpenCadastrarAcaoSolidaria: () => void
}

export function CadastrarAcaoSolidaria({ open, handleIsOpenCadastrarAcaoSolidaria }: CadastrarAcaoSolidariaProps) {
  const [form] = Form.useForm()

  const { mutateAsync: cadastrarAcaoSolidaria, isPending: isPendingCadastrarAcaoSolidaria } =
    useCadastrarAcaoSolidaria()

  async function handleCadastrarAcaoSolidaria(data: CadastrarAcaoSolidariaRequestData) {
    await cadastrarAcaoSolidaria({ nome: data.nome })

    form.resetFields()
  }

  return (
    <BaseModal
      title={<h3>Cadastrar ação solidária</h3>}
      open={open}
      onCancel={handleIsOpenCadastrarAcaoSolidaria}
      footer={false}
      width={1000}
    >
      <Col xs={24}>
        <Form form={form} layout="vertical" onFinish={handleCadastrarAcaoSolidaria}>
          <Row justify="center" gutter={[20, 20]}>
            <Col xs={8}>
              <BaseForm.Item
                name="nome"
                label="Nome da nova ação solidária"
                rules={[{ required: true, message: 'Obrigatório' }]}
              >
                <BaseInput placeholder="Digite" />
              </BaseForm.Item>
            </Col>
          </Row>

          <Row justify="center">
            <BaseButton htmlType="submit" loading={isPendingCadastrarAcaoSolidaria}>
              Enviar
            </BaseButton>
          </Row>
        </Form>
      </Col>
    </BaseModal>
  )
}
