import styled from 'styled-components'

export const CardWrapper = styled.div`
  background: var(--card-bg-color);
  border: var(--card-border);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  /* display: flex;
  flex-direction: column; */
  gap: 8px;
`

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: var(--text-main-color);

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

export const SubtitleWrapper = styled.div`
  font-size: 14px;
  color: var(--text-secondary-color);
/* 
  @media (max-width: 480px) {
    font-size: 12px;
  } */
`

export const BalanceWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  
  &.positive {
    color: var(--success-color);
  }
  
  &.negative {
    color: var(--error-color);
  }
` 