import { Col, Form, Row } from 'antd'
import { UploadFile } from 'antd/es/upload'
import { useState } from 'react'
import { useRegistrarReciboDoacao } from '../../../../hooks/agape/mutations/useRegistrarReciboDoacao'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { BaseMultiplePhotos } from '../../../../components/upload/BaseMultiplePhotoUpload'
import BaseButton from '../../../../components/button/BaseButton'
import { compressImage } from '../../../../../../utility/utility'

interface EnviarRecibosProps {
  idDoacao: number
  acaoPosEnviarRecibo?: () => void
}

export function EnviarRecibosForm({ idDoacao, acaoPosEnviarRecibo }: EnviarRecibosProps) {
  const [form] = Form.useForm()

  const [imagensRecibo, setImagensRecibo] = useState<UploadFile<any>[]>([])

  const { mutateAsync: registrarRecibo, isPending: isPendingRegistrarRecibo } = useRegistrarReciboDoacao()

  async function enviarFotosRecibo() {
    const imagensComprimidas = await Promise.all(imagensRecibo.map((image) => compressImage(image.originFileObj, 0.6)))

    if (idDoacao) {
      const formData = new FormData()
      imagensComprimidas.forEach((file) => {
        formData.append('recibos', file)
      })

      await registrarRecibo({ fk_doacao_agape_id: idDoacao, recibos: formData })

      if (acaoPosEnviarRecibo) {
        acaoPosEnviarRecibo()
      }
    }
  }

  return (
    <Col xs={24} style={{ marginTop: '2rem' }}>
      <h2 style={{ textAlign: 'center' }}>Fotos do Recibo</h2>
      <Row justify="center">
        <Form form={form} layout="vertical" onFinish={enviarFotosRecibo}>
          <BaseForm.Item
            name="fotos_recibo"
            label={'Recibo(s)'}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                required: true,
                message: 'Por favor, envie pelo menos imagem!',
                validator: () => {
                  if (imagensRecibo.length === 0) {
                    return Promise.reject(new Error('Por favor, envie pelo menos imagem!'))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <BaseMultiplePhotos
              description="Envie a foto do recibo e/ou registro do recebimento dos itens"
              initialFileList={imagensRecibo}
              numeroFotos={2}
              setImage={setImagensRecibo}
              validateField={() => form.validateFields(['fotos_recibo'])}
              listInRow
            />
          </BaseForm.Item>

          <Row justify="center" style={{ marginTop: '2.5rem' }}>
            <BaseButton htmlType="submit" loading={isPendingRegistrarRecibo}>
              Enviar
            </BaseButton>
          </Row>
        </Form>
      </Row>
    </Col>
  )
}
