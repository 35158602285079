import { useState } from 'react'
import { Content } from './Content'
import { FilterModal } from './FilterModal'
import { getCurrentMonthAndYear } from '../CurentMonthDonations/utils'
import BaseCard from '../../../../../components/card/BaseCard'

export function RecurrentDonations() {
  const [filters, setFilters] = useState()
  const [campaignsOptions, setCampaignsOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    console.log('handleOpenModal')
    setIsModalOpen(true)
  }

  return (
    <BaseCard padding="0px" style={{ overflow: "hidden" }}>
      <FilterModal
        filters={filters}
        setFilters={setFilters}
        campaignsOptions={campaignsOptions}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Content
        filters={filters}
        setCampaignsOptions={setCampaignsOptions}
        onFilterClick={handleOpenModal}
      />
    </BaseCard>
  )
}
