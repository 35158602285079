import { Form } from 'antd'
import { BaseFormItem } from '../components/BaseFormItem/BaseFormItem'
import { BaseFormList } from '../components/BaseFormList/BaseFormList'
import { BaseFormTitle } from '../components/BaseFormTitle/BaseFormTitle'

export default function BaseForm({
  onFinishFailed,
  layout = 'vertical',
  ...props
}) {
  return <Form layout={layout} {...props} />
}

BaseForm.Title = BaseFormTitle
BaseForm.Item = BaseFormItem
BaseForm.List = BaseFormList
BaseForm.useForm = Form.useForm
BaseForm.Provider = Form.Provider
