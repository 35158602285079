import { ChartCard } from '@/container/admin/components/Cards/ChartCard'
import { RootState } from '@/redux/rootReducers'
import { useSelector } from 'react-redux'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useMessagesPerDayOfWeek } from '../../hooks/use-messages-per-day-of-week'

export function MessagesPerDayOfWeek() {
  const { data, isLoading } = useMessagesPerDayOfWeek()
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  return (
    <ChartCard title="Mensagens por Dia da Semana" loading={isLoading}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis 
            dataKey="day" 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <YAxis 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: isDark ? '#1E1E1E' : '#fff',
              border: `1px solid ${isDark ? '#333333' : '#f0f0f0'}`,
              color: isDark ? '#E1E1E1' : '#000'
            }}
          />
          <Bar dataKey="count" fill="#FF6B00" />
        </BarChart>
      </ResponsiveContainer>
    </ChartCard>
  )
} 