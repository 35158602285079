import { CopyFilled } from '@ant-design/icons';
import { usePaymentMethod } from '../../context';
import { PixText, PixTitle } from '../../../CampaignIdentification/styles';
import { StyledButton } from '../../../../../components/Button';

export function PixPayment() {
  const { pixQrCode, copied, copyToClipboard } = usePaymentMethod();

  return (
    <>
      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '1rem',
          marginBottom: '1.5rem',
          borderRadius: '12px',
          border: 'solid #cccccc 2px',
          maxWidth: '700px',
        }}
      >
        <PixTitle>O QRCODE a ser gerado vence no dia {new Date(Date.now() + 7 * 864e5).toLocaleDateString()}</PixTitle>
        <PixText>
          Clique em Gerar QR CODE abaixo e você vai receber as instruções de como Pagar com Pix de forma prática e
          instantânea, direto pelo app do seu banco
        </PixText>
        {pixQrCode && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '800px',
              alignItems: 'center',
            }}
          >
            <img src={`data:image/jpeg;base64,${pixQrCode?.qr_code_base64}`} alt="QR Code" style={{ width: '250px' }} />
            <StyledButton
              onClick={() => copyToClipboard(pixQrCode?.pix_qrcode)}
              icon={<CopyFilled />}
              style={{ marginTop: '2.5rem', color: '#0f996a', width: '90%' }}
            >
              {copied ? 'Código Copiado!' : 'Copiar Código'}
            </StyledButton>
          </div>
        )}
      </div>
    </>
  );
}
