import { Row } from 'antd'
import BaseButton from '../../../components/button/BaseButton'
import { PlusCircle } from '@phosphor-icons/react'
import { CadastrarFamilia } from './CadastrarFamilia/CadastrarFamilia'
import { useCallback, useState } from 'react'
import { FamiliasCadastradas } from './FamiliasCadastradas/familia/FamiliasCadastradas'
import { CardsFamilia } from './FamiliasCadastradas/cards'
import { DistribuicaoGeografica } from './DistribuicaoGeografica'

export function FamiliaPage() {
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const cadastrarFamiliaMemo = useCallback(() => <CadastrarFamilia onClose={onClose} open={open} />, [open, onClose])

  return (
    <>
      <h2>Lista de famílias</h2>

      <CardsFamilia />

      <Row justify="center" style={{ marginBlock: '2rem' }}>
        <BaseButton severity="info" icon={<PlusCircle size={20} />} onClick={showDrawer}>
          Cadastrar família
        </BaseButton>
      </Row>

      {cadastrarFamiliaMemo()}

      <FamiliasCadastradas />

      <div style={{ marginTop: '2rem' }}>
        <DistribuicaoGeografica />
      </div>
    </>
  )
}
