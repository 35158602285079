import { DonationApiResponse } from '../../../../../hooks/donations/queries/useFetchDonations'
import BaseButton from '../../../../button/BaseButton'
import { BaseTag } from '../../../../tag/BaseTag'
import { BaseLink } from '../../../../link/BaseLink'
import { handleStatusMask } from '../utils/handleStatusMask'
import { formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { transformDate } from '../utils/transformDate'
import { useConsiderDesconsiderDonation } from '../../../../../hooks/donations/mutations/useConsiderDesconsiderDonation'
import { useHistory } from 'react-router-dom'
import { copyDonationInfoToClipboard } from '../utils/copyDonationInfoToClipBoard'

export interface handleOpenActionModalProps {
  titulo: string
  acao: string
  benfeitor: string
  metodo: string
  valor: string
  recorrencia: boolean
  campanha: string | undefined
  objectId: string | number
  isTransactionId?: string | boolean
}

export const useDonationsDataSource = (
  donations: DonationApiResponse | undefined,
  handleOpenDetailsModal: (args: any) => void,
  handleOpenActionModal: (args: handleOpenActionModalProps) => void,
  filters: any,
) => {
  const history = useHistory()
  const { mutateAsync: desconsiderDonation } = useConsiderDesconsiderDonation()

  if (!donations) return

  return donations?.data?.map((donation) => {
    return {
      // if this key is duplicated it will bug
      key: donation?.processamento?.fk_processamento_pedido_id,
      id: (
        <BaseButton
          type="link"
          onClick={() =>
            handleOpenDetailsModal({
              open: true,
              id: donation?.pedido?.fk_pedido_id,
              data_hora: transformDate(donation?.pedido?.data_doacao),
              comprovante_id: donation?.processamento?.id_pagamento,
              referencia_id: donation?.processamento?.codigo_referencia,
              transacao_id: donation?.processamento?.transaction_id,
              status: donation?.processamento?.status,
              metodo: donation?.pedido?.forma_pagamento,
              gateway: donation?.pedido?.gateway_pagamento?.nome,
              recorrencia: donation?.pedido?.recorrencia ? 'Sim' : 'Não',
              tipo: donation?.benfeitor?.user_id ? 'Usuário cadastrado' : 'Anônimo',
            })
          }
        >
          {donation?.pedido?.fk_pedido_id}
        </BaseButton>
      ),
      codigo_referencia: {
        copyValue: () =>
          copyDonationInfoToClipboard({
            value: donation?.processamento?.codigo_referencia,
            successMessage: 'Código de Referñecia',
            donation,
          }),
      },
      recorrencia: {
        recurrency: () => (
          <BaseTag color={donation?.pedido?.recorrencia ? 'processing' : 'default'}>
            {donation?.pedido?.recorrencia ? 'Sim' : 'Não'}
          </BaseTag>
        ),
      },
      transaction_id: {
        hasTransactionId: donation?.processamento?.transaction_id,
        copyValue: () =>
          copyDonationInfoToClipboard({
            value: donation?.processamento?.transaction_id,
            successMessage: 'Código de Transação',
            donation,
          }),
      },
      acoes: {
        ac: [
          donation?.pedido?.recorrencia_ativa && (
            <BaseButton
              severity="error"
              shape="round"
              onClick={() =>
                handleOpenActionModal({
                  titulo: 'Cancelar recorrência',
                  acao: 'Cancelar recorrência',
                  benfeitor: donation?.benfeitor?.nome,
                  metodo: donation?.pedido?.forma_pagamento,
                  valor: formatToBrazilianCurrency(donation?.pedido?.valor_doacao),
                  recorrencia: donation?.pedido?.recorrencia,
                  campanha: donation?.campanha?.titulo!,
                  objectId: donation?.pedido?.fk_pedido_id,
                })
              }
            >
              Cancelar recorrência
            </BaseButton>
          ),
          donation?.pedido?.forma_pagamento === 'Credito' &&
            donation?.processamento?.status === 'Pago' &&
            !donation?.pedido?.recorrencia && (
              <BaseButton
                severity="error"
                shape="round"
                onClick={() =>
                  handleOpenActionModal({
                    titulo: 'Estornar',
                    acao: 'Estornar',
                    benfeitor: donation?.benfeitor?.nome,
                    metodo: donation?.pedido?.forma_pagamento,
                    valor: formatToBrazilianCurrency(donation?.pedido?.valor_doacao),
                    recorrencia: donation?.pedido?.recorrencia,
                    campanha: donation?.campanha?.titulo!,
                    objectId: donation?.pedido?.fk_pedido_id,
                  })
                }
              >
                Estornar
              </BaseButton>
            ),
          (donation?.pedido?.forma_pagamento === 'Pix' || donation?.pedido?.forma_pagamento === 'Boleto') &&
            donation?.processamento?.status !== 'Pago' &&
            donation?.processamento?.status !== 'Expirado' &&
            donation?.pedido?.recorrencia &&
            donation?.pedido?.recorrencia_ativa && (
              <BaseButton
                severity="info"
                shape="round"
                onClick={() =>
                  handleOpenActionModal({
                    titulo: 'Reenviar',
                    acao: 'Reenviar',
                    benfeitor: donation?.benfeitor?.nome,
                    metodo: donation?.pedido?.forma_pagamento,
                    valor: formatToBrazilianCurrency(donation?.pedido?.valor_doacao),
                    recorrencia: donation?.pedido?.recorrencia,
                    campanha: donation?.campanha?.titulo!,
                    objectId:
                      donation?.processamento?.transaction_id || donation?.processamento?.fk_processamento_pedido_id,
                    isTransactionId: !!donation?.processamento?.transaction_id,
                  })
                }
              >
                Reenviar
              </BaseButton>
            ),
          donation?.pedido?.status_pedido === 2 && (
            <BaseButton type="ghost" shape="round">
              Recorrência Cancelada
            </BaseButton>
          ),
          <BaseButton
            type="ghost"
            danger={donation?.pedido?.contabilizar_doacao}
            shape="round"
            onClick={() =>
              desconsiderDonation({
                fk_processamento_pedido_id: donation?.processamento?.fk_processamento_pedido_id,
                considerar: donation?.pedido?.contabilizar_doacao,
                benfeitor: donation?.benfeitor?.nome,
                filters,
              })
            }
          >
            {donation?.pedido?.contabilizar_doacao ? 'Desconsiderar' : 'Reconsiderar'}
          </BaseButton>,
        ].filter(Boolean),
      },
      nome: (
        <>
          {donation?.benfeitor?.user_id ? (
            <BaseLink
              onClick={() => {
                history.push(`/admin/users/details/${donation?.benfeitor?.user_id}`)
              }}
            >
              {donation?.benfeitor?.nome}
            </BaseLink>
          ) : (
            <span>{donation?.benfeitor?.nome}</span>
          )}
        </>
      ),
      forma_pagamento: donation?.pedido?.forma_pagamento,
      data_doacao: donation?.pedido?.data_doacao,
      titulo_campanha: donation?.campanha?.titulo,
      status: handleStatusMask(donation?.processamento?.status),
      valor_doacao: formatToBrazilianCurrency(donation?.pedido?.valor_doacao),
      gateway: donation?.pedido?.gateway_pagamento?.nome,
      user_name: donation?.benfeitor?.nome,
    }
  })
}
