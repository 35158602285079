export const benfeitorTheme = {
  colors: {
    white: '#FFFFFF',
    'white-cream': '#FEF9F5',

    'light-brown': '#3e3331',
    'dark-brown': '#24201B',
    black: '#000000',
    mustard: '#9A8E20',

    'gray-100': '#D0D0D0',
    'gray-200': '#E0E0E0',
    'gray-400': '#8D8D8D',
    'gray-450': '#8a827d',
    'gray-500': '#434343',
    'gray-300': '#74747433',
    'gray-800': '#1F1F1F',
    'black-70': '#00000072',
    'black-light': '#141617',

    info: '#0ea5e9',
    warning: '#f59e0b',
    success: '#10b981',
    error: '#f43f5e',
  },
  fonts: {
    color: {
      primary: '#1F1F1F',
      secondary: '#404040',
      muted: '#8D8D8D',
    },
    size: {
      'extra-small': '8px',
      small: '12px',
      medium: '16px',
      large: '20px',
      'extra-large': '24px',
      xxl: '28px',
    },
    family: {
      primary: "'Roboto Medium', sans-serif",
      secondary: "'Montserrat', sans-serif",
    },
  },
} as const;
