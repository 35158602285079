import styled from 'styled-components'

export const SecondSectionStyled = styled.div`
  background-color: #f2eadf;
  border-top: solid #c98f00 3px;
  border-bottom: solid #c98f00 3px;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  .header {
    font-weight: 400;
    line-height: 34px;
    font-size: 30px !important;
  }

  p {
    color: #1e1e1e;
    font-weight: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  @media (min-width: 900px) {
    padding-top: 2rem;
    padding-bottom: 2rem;

    p {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;

      font-size: 22px;
      line-height: 30px;
    }

    .header {
      font-size: 47px !important;
      line-height: 47px;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
