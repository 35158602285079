
import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/highmaps';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsExporting from 'highcharts/modules/exporting';

HighchartsExporting(Highcharts);
HighchartsDrilldown(Highcharts);

const getResponsiveFontSize = () => {
  if (window.innerWidth < 480) {
    return '1.1rem';
  } if (window.innerWidth < 1200) {
    return '1.3rem';
  }
  return '1.5rem';

};

export const Chart = ({ globalData, brazilStateData }) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const worldResponse = await fetch('https://code.highcharts.com/mapdata/custom/world.topo.json');
        const worldTopology = await worldResponse.json();

        const brazilResponse = await fetch('https://code.highcharts.com/mapdata/countries/br/br-all.topo.json');
        const brazilTopology = await brazilResponse.json();

        HighchartsMap.mapChart('container', {
          chart: {
            backgroundColor: 'var(--card-bg-color)',
          },
          mapView: {
            projection: {
              name: 'WebMercator'
            },
            // zoom: 3.8,
            // center: [-60, -15]
          },
          plotOptions: {
            map: {
              borderColor: '#717171',
              borderWidth: 0.9,
            }
          },
          title: {
            text: 'Cadastros por Região',
            align: 'left',
            x: 23,
            style: {


              color: 'var(--text-main-color)',
              fontSize: getResponsiveFontSize(),
              fontWeight: 'bold',
            },
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              verticalAlign: 'bottom',
            },
          },
          colorAxis: {
            min: 0,
            minColor: '#e2ffff',
            maxColor: '#0062ff',
          },
          tooltip: {
            pointFormat: '{point.name}: <b>{point.value}</b>',
          },
          credits: {
            enabled: false
          },
          series: [
            {
              data: globalData,
              name: 'Cadastros',
              mapData: worldTopology,
              joinBy: ['hc-key', 'hc-key'],
              states: {
                hover: {
                  color: '#afb1f8',
                },
              },
              dataLabels: {
                enabled: false,
                format: '{point.name}',
              },
            },
            {
              type: 'map',
              name: 'Cadastros',
              mapData: brazilTopology,
              data: brazilStateData,
              joinBy: ['hc-key', 'hc-key'],
              states: {
                hover: {
                  color: '#afb1f8',
                },
              },
              dataLabels: {
                enabled: false,
                format: '{point.name}',
              },
            },
          ],
        });
      } catch (error) {
        console.error('Error loading map data:', error);
      }
    };

    fetchData();

    return () => {
      HighchartsMap.charts.forEach((chart) => {
        if (chart) {
          chart.destroy();
        }
      });
    };
  }, [globalData, brazilStateData]);

  return <div id="container" style={{ height: '45rem', width: "100%" }} />;
};