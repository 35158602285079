import { notification } from 'antd'
import { Doacao } from '../../../../../hooks/donations/queries/useFetchDonations'

interface CopyDonationInfoToClipboardProps {
  value: string
  successMessage: string
  donation: Doacao
}

export const copyDonationInfoToClipboard = ({
  value,
  successMessage,
  donation,
}: CopyDonationInfoToClipboardProps): void => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      notification.success({
        message: <div>{successMessage} Copiado</div>,
        description: (
          <div>
            <div>{donation?.benfeitor?.nome}</div>
            <div>{value}</div>
          </div>
        ),
      })
    })
    .catch((error) => {
      notification.error({
        message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
      })
      console.error('Falha ao copiar:', error)
    })
}
