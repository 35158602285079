import { Form } from 'antd'
import { StyledForm } from '../../../components/Form/styles'
import { StyledInput } from '../../../components/Inputs'
import { StyledButton } from '../../../components/Button'
import { Spin } from '../../../components/Spin'

interface CheckEmailProps {
  handleEmailSubmit: Function
  isPending: boolean
}

export function CheckEmail({ handleEmailSubmit, isPending }: CheckEmailProps) {
  const [form] = Form.useForm()

  return (
    <StyledForm
      name="email-form"
      layout="vertical"
      form={form}
      onFinish={(values) => handleEmailSubmit(values)}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}
    >
      <StyledForm.Item
        data-testid="check-email-input"
        name="email"
        label="E-mail"
        rules={[
          { required: true, message: 'E-mail é obrigatório!' },
          { type: 'email', message: 'Por favor, insira um e-mail válido!' },
        ]}
      >
        <StyledInput placeholder="Digite o seu e-mail" />
      </StyledForm.Item>

      <StyledButton htmlType="submit" disabled={isPending}>
        {isPending && <Spin style={{ marginRight: '0.5rem' }} color="white" />}
        Enviar
      </StyledButton>
    </StyledForm>
  )
}
