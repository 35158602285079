import styled from 'styled-components'

export const Container = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex: 1 3;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 2.5rem;
  }
`

export const StatusWrapper = styled.div`
  background-color: #3e3331;
  width: 100%;
  padding: 10rem;
  border-radius: 8px;

  h3 {
    text-align: center;
    color: #9a8986 !important;
  }
`

export const StyledChannelList = styled.div`
  background-color: #282424;
  color: #ababab;
  border-radius: 10px;
  padding: 2px 10px;
`
