import {
  FacebookFilled, InstagramFilled, YoutubeFilled
} from '@ant-design/icons';
import {
  BottomFooterContainer,
  FooterContainer,
  InfoContainer,
  PagesContainer,
  SocialMediaContainer,
  StyledLogo,
} from './styles';
import LogoHesed from '../../../../../../static/img/logo_hesed.png';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <FooterContainer>
      <div>
        <InfoContainer>
          <Link to="/">
            <StyledLogo src={LogoHesed} />
          </Link>
          <div>
            <h3>Instituto Hesed | Casa Mãe</h3>
            <div>Av. Dionisio Leonel Alencar, 1443</div>
            <div>Parque Santa Maria</div>
            <div>Cep: 60873-073</div>
            <div>Fortaleza-Ceará</div>
            <div>(85) 3274-4513</div>
            <div>contato@institutohesed.org.br</div>
          </div>
        </InfoContainer>

        <SocialMediaContainer>
          <a href="https://www.instagram.com/irkelly_institutohesed_oficial" target="_blank">
            <InstagramFilled />
          </a>

          <a href="https://www.facebook.com/institutohesed" target="_blank">
            <FacebookFilled />
          </a>
          <a href="https://youtube.com/@institutohesed?si=D5a3vTLahi3woR6P" target="_blank">
            <YoutubeFilled />
          </a>
        </SocialMediaContainer>
      </div>

      <BottomFooterContainer>
        <div>
          <div>Todos os direitos reservados ©️ {new Date().getFullYear()} Instituto Hesed</div>
          <PagesContainer>
            <Link to="/benfeitor/institucional">Institucional</Link>
            <Link to="/benfeitor/termos-de-uso">Termos de Uso</Link>
            <Link to="/benfeitor/politicas-de-privacidade">Politicas de Privacidade</Link>
          </PagesContainer>
        </div>
      </BottomFooterContainer>
    </FooterContainer>
  );
}
