import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface AdicionarVoluntarioAgapeProps {
  fk_usuario_id: string
}

export function useAdicionarVoluntarioAgape() {
  const queryClient = useQueryClient()

  const adicionarVoluntario = useMutation({
    mutationFn: async ({ fk_usuario_id }: AdicionarVoluntarioAgapeProps) => {
      await DataService.put(`/agape/adicionar-voluntario-agape/${fk_usuario_id}`)
    },
    onSuccess: () => {
      notification.success({ message: 'Voluntário adicionado com sucesso!' })

      queryClient.invalidateQueries({ queryKey: ['LISTAGEM_VOLUNTARIOS'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao adicionar voluntário',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return adicionarVoluntario
}
