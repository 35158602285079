import { Skeleton } from 'antd'
import { Chart } from './Chart'
import { useGetDonationsCountPerWeekday } from '../../../../../hooks/dashboards/finances/queries/useGetDonationsCountPerWeekday'

export function Content({ filters, setCampaignsOptions }) {
  const { data, isPending, isError } = useGetDonationsCountPerWeekday(filters)

  if (isPending) {
    return (
      <div style={{ height: '25rem', display: 'grid', placeItems: 'center', padding: '1rem' }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    )
  }

  if (isError) {
    return (
      <h4 style={{ color: 'red', height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Ocorreu um erro ao listar os dados
      </h4>
    )
  }

  if (data?.campaigns) {
    setCampaignsOptions(data?.campaigns)
  }

  return <Chart data={data?.donations_by_payment_method_per_day} />
}
