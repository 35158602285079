import { UserOutlined } from '@ant-design/icons'
import { StyledButton } from '../../../../../components/Button'
import { Avatar, FormInstance } from 'antd'
import { useAuth } from '../../../../../../../context/Auth'
import { useFetchUserMe } from '../../../../../../admin/hooks'
import { useDispatch } from 'react-redux'
import { openLoginModal } from '../../../../../layouts/benfeitor-layout/components/Header/Modals/authModalsSlice'

interface UserInfoSectionProps {
  prev: Function
  setStepsBeenFilledFalse: Function
  form: FormInstance
}

export function UserInfoSection({ prev, form, setStepsBeenFilledFalse }: UserInfoSectionProps) {
  const dispatch = useDispatch()

  const { data: userMe } = useFetchUserMe()
  const { logout } = useAuth()

  return (
    <>
      {userMe ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '3rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Avatar size="large" icon={<UserOutlined />} src={userMe?.avatar} />
            <h2 style={{ margin: 0, fontSize: '20px' }}>{userMe?.nome}</h2>
          </div>
          <StyledButton
            onClick={() => {
              logout()
              prev()
              form.resetFields()
              setStepsBeenFilledFalse()
            }}
          >
            Sair
          </StyledButton>
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <h3>Já possui uma conta?</h3>
          <StyledButton style={{ margin: '0 auto' }} htmlType="button" onClick={() => dispatch(openLoginModal())}>
            Entrar
          </StyledButton>
        </div>
      )}
    </>
  )
}
