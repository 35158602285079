import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useFetchTodaySignupsPerHour(filters) {
  return useQuery({
    queryKey: ['TODAY_SIGNUPS_PER_HOUR', filters],
    queryFn: async () => {
      const query = await DataService.get(
        `admin/dash-board/users/cadastros-diario-per-hour?${filters?.queryString || ''}`,
      )
      return query?.data
    },
  })
}
