import { Col, Form, Row } from 'antd'
import { useListarNomeAcoesSolidarias } from '../../../../../../hooks/agape/queries/useListarNomeAcoesSolidarias'
import { useEditarCicloDoacao } from '../../../../../../hooks/agape/mutations/useEditarCicloDoacao'
import { formatCEP } from '../../../../../../../../utility/utility'
import BaseModal from '../../../../../../components/modal/BaseModal'
import BaseForm from '../../../../../../components/forms/BaseForm/BaseForm'
import { BaseSelect, Option } from '../../../../../../components/select/BaseSelect'
import { ErrorResult } from '../../../../../../components/result/resultStatus/ErrorResult'
import { BaseSpin } from '../../../../../../components/spin/BaseSpin.Styles'
import { BaseDivider } from '../../../../../../components/divider/BaseDivider'
import BaseButton from '../../../../../../components/button/BaseButton'
import { useEffect, useState } from 'react'
import { Pen } from '@phosphor-icons/react'
import { useGetInformacoesCiclo } from '../../../../../../hooks/agape/queries/useGetInformacoesCiclo'
import { CicloDoacaoFormulario } from '../../../Header/CadastrarCicloDoacao/CicloDoacaoFormulario'
import {
  EstoqueAgapeItemFormResponse,
  transformarArrayDoacoes,
} from '../../../Header/CadastrarCicloDoacao/ListaFormularioItensDoacao'
import { AbrangenciaCicloDoacao } from '../../../../../../hooks/agape/mutations/useCadastrarCicloDoacao'

interface CadastrarEstoqueProps {
  open: boolean
  handleIsOpenAcaoDoacao: () => void
  idCicloDoacao: number
  nomeAcao: string
  podeEditar: boolean
}

interface EditarAcaoFormData {
  bairro: string
  cep: string
  cidade: string
  complemento?: string
  estado: string
  numero: string
  rua: string
  fk_acao_agape_id: number
  doacoes: EstoqueAgapeItemFormResponse[]
  abrangencia: AbrangenciaCicloDoacao
}

export function EditarCicloDoacao({
  open,
  handleIsOpenAcaoDoacao,
  idCicloDoacao,
  nomeAcao,
  podeEditar,
}: CadastrarEstoqueProps) {
  const [isEditing, setIsEditing] = useState(false)

  const [isFormInitialized, setIsFormInitialized] = useState(false)

  function handleIsEditing() {
    setIsFormInitialized(false)
    setIsEditing((prev) => !prev)
    preencherFormCicloDoacao()
  }

  const [form] = Form.useForm()

  const { data: nomeAcoesSolidarias } = useListarNomeAcoesSolidarias()

  const { mutateAsync: editarCicloDoacao, isPending: isPendingEditarCicloDoacao } = useEditarCicloDoacao()

  const {
    data: infoCiclo,
    isLoading: isLoadingInfoCiclo,
    isError: isErrorInfoCiclo,
    error,
  } = useGetInformacoesCiclo({ fk_instancia_acao_agape_id: idCicloDoacao, open })

  useEffect(() => {
    if (infoCiclo) {
      preencherFormCicloDoacao()
    }
  }, [infoCiclo])

  function preencherFormCicloDoacao() {
    if (infoCiclo && !isFormInitialized) {
      const formData = {
        doacoes: infoCiclo.doacoes.map((doacao) => ({
          fk_estoque_agape_id: {
            value: doacao.fk_estoque_agape_id,
            label: doacao.item,
          },
          quantidade: doacao.quantidade,
        })),
        ...infoCiclo.endereco,
        cep: formatCEP(infoCiclo.endereco.cep),
        fk_acao_agape_id: infoCiclo.fk_acao_agape_id,
        abrangencia: infoCiclo.abrangencia,
      }

      form.setFieldsValue(formData)
      setIsFormInitialized(true)
    }
  }

  async function handleEditarAcaoDoacao(data: EditarAcaoFormData) {
    const endereco = {
      bairro: data.bairro,
      cep: data.cep,
      cidade: data.cidade,
      complemento: data.complemento,
      estado: data.estado,
      numero: data.numero,
      rua: data.rua,
    }

    const arrayDoacoes = transformarArrayDoacoes(data.doacoes)

    await editarCicloDoacao({
      doacoes: arrayDoacoes,
      endereco,
      fk_instancia_acao_agape_id: idCicloDoacao,
      abrangencia: data.abrangencia,
    })

    handleIsOpenAcaoDoacao()

    setIsEditing(false)
  }

  return (
    <BaseModal
      title={
        <Col xs={24}>
          <span>Detalhes ciclo de doação - {nomeAcao}</span>

          {podeEditar && (
            <BaseButton style={{ width: '50%' }} type="ghost" onClick={handleIsEditing}>
              <Pen size={18} /> {isEditing ? 'Cancelar editar' : 'Editar'}
            </BaseButton>
          )}
        </Col>
      }
      open={open}
      onCancel={handleIsOpenAcaoDoacao}
      footer={false}
      size="large"
    >
      <Form form={form} layout="vertical" disabled={!isEditing || !podeEditar} onFinish={handleEditarAcaoDoacao}>
        <Row justify="center">
          <BaseForm.Item
            label={
              <h3 style={{ textAlign: 'center', margin: 0 }}>Localize a ação solidária que esse ciclo fará parte</h3>
            }
            name="fk_acao_agape_id"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <BaseSelect placeholder="Pesquise e selecione uma opção" showSearch allowClear disabled>
              {nomeAcoesSolidarias?.acoes_agape.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </Row>

        {isErrorInfoCiclo && error?.response?.status !== 404 && <ErrorResult style={{ margin: '1rem 0 2rem' }} />}

        {isLoadingInfoCiclo && (
          <Row justify="center" style={{ margin: '1rem 0 2rem' }}>
            <BaseSpin />
          </Row>
        )}

        {infoCiclo && (
          <>
            <BaseDivider />

            <CicloDoacaoFormulario form={form} desativarAdicionarItem={!isEditing || !podeEditar} />
          </>
        )}

        <Row justify="center">
          <BaseButton htmlType="submit" loading={isPendingEditarCicloDoacao}>
            Enviar
          </BaseButton>
        </Row>
      </Form>
    </BaseModal>
  )
}
