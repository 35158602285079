import { http, HttpResponse } from 'msw'
import { CreateCadastroProps } from '../../../container/benfeitor/hooks/api/vocacional/mutations/useCreateCadastro'

export const createCadastroMock = http.post<never, CreateCadastroProps>(
  '/vocacional/registrar-cadastro-vocacional',
  async ({ request }) => {
    const body = await request.json()

    const pathname = request.referrer
    const token = pathname.split('/').pop()

    // Estrangeiro
    if (token === 'FOREIGN') {
      if (body.documento_identidade === '0123456789') {
        return new HttpResponse(null, { status: 201 })
      }

      return new HttpResponse(null, { status: 422 })
    }

    // Brasileiro
    if (body.documento_identidade === '44114542035') {
      return new HttpResponse(null, { status: 201 })
    }

    return new HttpResponse(null, { status: 500 })
  },
)
