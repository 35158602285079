import { useHistory } from 'react-router-dom'
import { SmBaseButton } from '../../../components/button/styles'
import { FooterStyled } from './styles'

export function Footer() {
  const history = useHistory()

  return (
    <FooterStyled>
      <p>Deus está preparando algo especial para esta nova fase de sua vida. Responda ao Seu chamado hoje.</p>

      <SmBaseButton
        className="button"
        onClick={() => {
          history.push(`/membro-exercito/checar-grupo`)
        }}
      >
        QUERO ME TORNAR UM GENERAL OFICIAL.
      </SmBaseButton>
    </FooterStyled>
  )
}
