import { http, HttpResponse } from 'msw'

interface Request {
  email: string
  url_redirect: string
}
interface Response {
  msg: string
}

export const forgotPasswordMock = http.post<never, Request, Response>('/auth/forgot-password', async ({ request }) => {
  const { email, url_redirect } = await request.json()

  if (email === 'usuario-benfeitor@gmail.com' && url_redirect === '/membro-exercito/redefinir-senha') {
    return HttpResponse.json({
      msg: 'Email enviado com sucesso.',
    })
  }
})
