import { Mensageria } from '@/container/admin/pages/mensageria'
import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { FinancesDashboard } from '../../container/admin/pages/dashboard/financesDashboard'
import { SignUpDashboard } from '../../container/admin/pages/dashboard/signupDashboard'

function DashboardRoutes() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/cadastros`} component={SignUpDashboard} />
      <Route path={`${path}/financeiro`} component={FinancesDashboard} />
      <Route path={`${path}/mensagens`} component={Mensageria} />
    </Switch>
  )
}

export default DashboardRoutes
