import FeatherIcon from 'feather-icons-react'
import { List, Skeleton } from 'antd'
import { LiveMetricsContainer, MetricsCard } from './Style'
import { formatNumber } from '../../../../../../utility/utility'
import BaseSpin from '../../../../components/spin/BaseSpin'

const metricsData = [
  { label: 'Audiência Média', value: 'audiencia_media' },
  { label: 'Audiência Máxima', value: 'audiencia_maxima' },
  { label: 'Audiência Mínima', value: 'audiencia_minima' },
]

export function LiveMetricsCards({ liveDetails }) {
  return (
    <LiveMetricsContainer>
      {metricsData.map(({ label, value }) => (
        <MetricsCard key={value}>
          {liveDetails?.data && (
            <>
              <FeatherIcon className="icon" icon="users" size={32} />
              <div>
                <h4>{label}</h4>
                <p>{formatNumber(liveDetails?.data?.data?.[value])} - Expectadores</p>
              </div>
            </>
          )}
          {liveDetails?.isError && <h4 style={{ margin: 'auto', color: 'red' }}>Ocorreu um erro...</h4>}
          <Skeleton loading={liveDetails?.isPending} active avatar paragraph={{ rows: 1 }} />
        </MetricsCard>
      ))}
    </LiveMetricsContainer>
  )
}
