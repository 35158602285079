import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface RecurrenceInfoProps {
  quantidade_doacoes_recorrentes: number
  quantidade_doadores: number
  valor_recorrencia_prospectado: string
}

export function useFetchRecurrenceInfo() {
  const recurrenceInfo = useQuery<RecurrenceInfoProps>({
    queryKey: ['RECURRENCE_DONATIONS_INFO'],
    queryFn: async () => {
      const response = await DataService.get<RecurrenceInfoProps>(`checkout/card-recurrence-donations`)

      return response?.data
    },
  })

  return recurrenceInfo
}
