/* eslint-disable react/no-danger */
import { Col, Row } from 'antd';

import DOMPurify from 'dompurify';
import { BannerLandingStyled } from './styles';

export const BannerLandingPage = ({ data }) => {
  const sanitizedHTML = DOMPurify.sanitize(data?.descricao);

  return (
    <BannerLandingStyled banner={data?.banner} showShadow={data?.campanha_id !== 35}>
      {data?.campanha_id !== 35 && (
        <Row justify="center">
          <Col xs={20} sm={20} md={24} lg={24} xl={24}>
            <span className="title-landing">{data?.titulo}</span>
          </Col>

          <Col xs={20} sm={20} md={16} lg={16} xl={16} className="description-landing">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
          </Col>
        </Row>
      )}
    </BannerLandingStyled>
  );
};

