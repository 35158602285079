import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FirstSection } from './components/first-section'
import { SecondSection } from './components/second-section'
import { ThirdSection } from './components/third-section'
import { FourthSection } from './components/fourth-section'
import { FifthSection } from './components/fifth-section'
import { Footer } from './components/footer'
import { EsmUserData, useAlistamentoSmContext } from '../../contexts/alistamento-sm-context'
import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../lib/dataService'

interface ActiveAccountResponse {
  telefone: string
  nome: string
  email: string
  usuario_id: number
  pais: string
}

export function MemberLandingPage() {
  const location = useLocation()
  const { setUserData } = useAlistamentoSmContext()

  const urlToken = new URLSearchParams(location?.search).get('alistamento_esm_token')

  const { data: token_data } = useQuery({
    queryKey: ['token_email', urlToken],
    queryFn: async () => {
      const response = await DataService.post<ActiveAccountResponse>(`users/active-account/${urlToken}`)
      return response.data
    },
    enabled: !!urlToken,
  })

  const userData: EsmUserData = {
    telefone: token_data?.telefone!,
    nome: token_data?.nome!,
    email: token_data?.email!,
    usuario_id: token_data?.usuario_id!,
    pais: token_data?.pais!,
  }

  useEffect(() => {
    if (userData?.email && userData?.usuario_id) {
      setUserData(userData)
    }
  }, [token_data, setUserData])

  return (
    <div style={{ overflow: 'hidden' }}>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <Footer />
    </div>
  )
}
