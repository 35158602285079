export interface Env {
  VITE_API_OLD_ENDPOINT: string;
  SENTRY_AUTH_TOKEN: string;
  VITE_SENTRY_DSN: string;
  VITE_SENTRY_ENABLED: 'true' | 'false';
  VITE_SENTRY_ENVIRONMENT: 'local' | 'dev' | 'lab' | 'homolog' | 'prod';
  VITE_API_MOCK: 'true' | 'false';
}

export function validateEnv(env: Record<string, unknown>): Env {
  const errors: string[] = [];

  // Validate DSN
  if (!env.VITE_SENTRY_DSN || typeof env.VITE_SENTRY_DSN !== 'string') {
    errors.push('VITE_SENTRY_DSN must be a valid URL string');
  }

  // Validate ENABLED
  if (!['true', 'false'].includes(env.VITE_SENTRY_ENABLED as string)) {
    errors.push('VITE_SENTRY_ENABLED must be either "true" or "false"');
  }

  // Validate ENVIRONMENT
  if (!['local', 'dev', 'lab', 'homolog', 'prod'].includes(env.VITE_SENTRY_ENVIRONMENT as string)) {
    errors.push('VITE_SENTRY_ENVIRONMENT must be one of: local, dev, lab, homolog, prod');
  }

  // Validate API_MOCK
  if (!['true', 'false'].includes(env.VITE_API_MOCK as string)) {
    errors.push('VITE_API_MOCK must be either "true" or "false"');
  }

  if (errors.length > 0) {
    console.error('❌ Invalid environment variables:', errors);
    throw new Error(errors.join('\n'));
  }

  return env as unknown as Env;
} 