import React, { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-use'
import { useAuth } from '../../../context/Auth'
import { useFetchUserMe } from '../hooks/users/Queries/useFetchUserMe'
import GlobalStyles from '../../../styles/Themes/Admin/GlobalStyles'
import { BlurToggle } from '@/context/Blur/BlurToggle'
import { ThemePicker } from '@/styles/Themes/Admin/ThemePicker'
import { BaseDivider } from '../components/divider/BaseDivider'

const AdminContext = createContext()

export const AdminLayoutProvider = ({ children }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { logout } = useAuth()

  const TO_MOBILE_MENU_BREAKPOINT = 768

  const { data: userMe } = useFetchUserMe()

  const [openKeys, setOpenKeys] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= TO_MOBILE_MENU_BREAKPOINT)

  const toggleSider = () => {
    if (isMobile) {
      setDrawerVisible(!drawerVisible)
    } else {
      setCollapsed(!collapsed)
    }
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
  }

  const ProfileDropdown = [
    {
      label: <NavLink to="/">Benfeitor</NavLink>,
      key: 'BENFEITOR_MOBILE',
    },
    {
      label: <NavLink to="/admin/profile/view">Perfil</NavLink>,
      key: 'PERFIL_MOBILE',
    },
    {
      label: (
        <NavLink to="/" onClick={() => logout()} style={{ width: '100%' }}>
          Sair
        </NavLink>
      ),
      key: 'SAIR_MOBILE',
    },
  ]

  const MobileProfileDropdown = [
    {
      label: <NavLink to="/">Benfeitor</NavLink>,
      key: 'BENFEITOR_MOBILE',
    },
    {
      label: <NavLink to="/admin/profile/view">Perfil</NavLink>,
      key: 'PERFIL_MOBILE',
    },
    {
      label: (
        <NavLink to="/" onClick={() => logout()} style={{ width: '100%' }}>
          Sair
        </NavLink>
      ),
      key: 'SAIR_MOBILE',
    },
    {
      type: 'divider',
    },
    {
      label: <BlurToggle />,
      key: 'BLUR_TOGGLE_MOBILE',
    },
    {
      label: <ThemePicker />,
      key: 'THEME_PICKER_MOBILE',
    },
  ]

  const handleResize = () => {
    setIsMobile(window.innerWidth <= TO_MOBILE_MENU_BREAKPOINT)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isMobile) {
      setDrawerVisible(false)
    }
  }, [location, isMobile])

  const value = useMemo(
    () => ({
      collapsed,
      setCollapsed,
      drawerVisible,
      setDrawerVisible,
      openKeys,
      setOpenKeys,
      toggleSider,
      onOpenChange,
      ProfileDropdown,
      MobileProfileDropdown,
      userMe,
      isMobile,
      location,
    }),
    [collapsed, drawerVisible, openKeys, userMe, isMobile, location],
  )

  return (
    <AdminContext.Provider value={value}>
      <GlobalStyles />
      {children}
    </AdminContext.Provider>
  )
}

export const useAdminContext = () => {
  return useContext(AdminContext)
}
