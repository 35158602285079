import React, { useEffect } from 'react'
import { AboutSection, ContainerWrapper, ContentWrapper, InstitutionalHeaderTittle, SectionWrapper } from './styles'

export function Politicas  ()  {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <ContentWrapper>
      <ContainerWrapper>
        <InstitutionalHeaderTittle>
        Política de Privacidade
          </InstitutionalHeaderTittle>
        <h3 style={{ textAlign: 'right' }}>Data Atualização: 31/07/2024</h3>
        <SectionWrapper>
          <p>
            Seja sempre bem-vindo!! O intuito desta “Política de Privacidade” é
            para lhe apresentar o nosso compromisso com a segurança, boa-fé,
            confidencialidade, transparência no tratamento dos dados pessoais de
            quem visitar o site{' '}
            <a
              href="http://www.institutohesed.org.br"
              target="_blank"
              rel="noreferrer"
            >
              http://www.institutohesed.org.br
            </a>{' '}
            e as demais plataformas digitais, tais como: aplicativos, redes
            sociais etc. Estas plataformas do Instituto Hesed respeita o direito
            à privacidade e zela pela segurança dos dados pessoais de seus
            usuários, em conformidade com a LGPD — Lei Geral de Proteção de
            Dados (Lei n.º 13.709/2018) — e suas definições.
          </p>
          <p>
            Todos os nossos serviços estão, continuamente, em processo de
            adequação à LGPD – Lei Geral de Proteção de Dados Pessoais, e, por
            esse motivo, alguns termos de uso e novos procedimentos poderão ser
            adotados e atualizados, sempre que necessário.
          </p>
          <p>
            A nossa Política de Privacidade esclarece como serão coletadas,
            armazenadas e utilizadas as informações pessoais dos nossos
            usuários. Suas disposições são válidas a todos os usuários de nossa
            plataforma.
          </p>
          <p>
            Com o intuito de proporcionar maior transparência e segurança,
            poderemos alterar, periodicamente, esta Política de Privacidade.
          </p>
          <p>
            Eventuais atualizações posteriores serão informadas aos nossos
            usuários por meios razoáveis.
          </p>
          <h2>1. Do Tratamento e Coleta de Dados</h2>
          <p>
            1.1. As plataformas digitais do Instituto Hesed poderão coletar e
            armazenar dados pessoais de diferentes formas, dentre estas por meio
            do acesso e preenchimento, pelo titular, de formulários e
            questionários em e-mails, sites, aplicativos, contratos e demais
            formas.
          </p>
          <p>
          1.2. As informações coletadas de usuários que se utilizam das
          plataformas digitais do Instituto Hesed, por meio de cookies, incluem,
          dentre outros:
          </p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '3rem' }}>
            <li>o navegador de acesso do usuário;</li>
            <li>o endereço do protocolo de internet (IP);</li>
            <li>a data e hora do acesso;</li>
            <li>a localização do usuário;</li>
            <li>as ações do usuário no site.</li>
          </ul>
          <p>
            1.3. Os titulares de dados pessoais podem estar associados a alguns
            identificadores online fornecidos por seus dispositivos,
            aplicativos, ferramentas e protocolos, como endereços de IP e
            cookies. Estas informações, quando combinadas com identificadores
            únicos e outros dados recebidos por servidores, podem ser usadas
            para identificá-los.
          </p>
          <p>
            1.4. Os dados serão tratados pelas plataformas digitais do Instituto
            Hesed, especificamente, para as finalidades determinadas por esta
            Política de Privacidade, mediante o consentimento do titular ou em
            decorrência das demais hipóteses legalmente previstas.
          </p>
          <p>
            1.5. As plataformas digitais do Instituto Hesed pautam-se no
            princípio da transparência, informam que a coleta de cookies, no
            momento do acesso às suas aplicações, terá por finalidade a
            otimização de funcionalidades e a personalização do acesso,
            customizando a experiência do titular, garantindo que será
            inteiramente responsável pelos seus dados e sendo vedado qualquer
            tratamento em desconformidade com esta política.
          </p>
          1.6. Em conformidade com a LGPD, o Instituto Hesed poderá utilizar as
          fotos e vídeos que os usuários realizarem upload em suas plataformas
          digitais. Esta utilização está condicionada à obtenção de autorização
          explícita do usuário, que deverá aplicar o seu consentimento durante o
          processo de cadastro. As fotos e vídeos dos usuários poderão ser
          empregados para diversas finalidades, incluindo, mas não se limitando
          a:
          <ul style={{ listStyleType: 'disc', paddingLeft: '3rem' }}>
            <li>
              Lives no YouTube: Transmissões ao vivo para promover eventos,
              campanhas e atividades do Instituto Hesed.
            </li>
            <li>
              Sorteios e Promoções: Publicação de imagens relacionadas a
              sorteios, concursos e outras atividades interativas promovidas
              pelo Instituto Hesed.
            </li>
            <li>
              Redes Sociais: Postagens em redes sociais como Facebook, Instagram
              e Twitter para engajar a comunidade e divulgar eventos e
              atividades.
            </li>
            <li>
              Sites e Aplicativos: Exibição das imagens nos sites e aplicativos
              do Instituto Hesed, para ilustrar conteúdos e melhorar a
              experiência do usuário.
            </li>
          </ul>
          <p>
            1.7. Para autorizar a utilização de sua imagem (fotos) para essas
            finalidades, o usuário deverá selecionar, durante o cadastro, uma
            caixa de consentimento, autorizando explicitamente o uso de sua
            imagem conforme descrito nesta política de privacidade.
          </p>
          <h2>2. Finalidade da coleta e utilização de dados pessoais</h2>
          <p>
            2.1. As aplicações digitais do Instituto Hesed poderão utilizar as
            suas informações pessoais para enviar conteúdos, informações,
            novidades etc. O e-mail e telefone serão utilizados para envio de
            conteúdo requisitado por você quando do preenchimento de
            formulários. Também podem ser utilizados para envio de newsletters,
            para contato via WhatsApp, ligação, mensagens instantâneas e
            comunicação via e-mail, sempre contendo informações relacionadas aos
            assuntos inseridos no escopo geral deste site e, geralmente,
            relacionados aos interesses manifestados pelo usuário. O e-mail e
            telefone serão utilizados, ainda, para comunicar o lançamento de
            novos produtos, eventos e novidades em geral sobre o Instituto
            Hesed. Nossa equipe poderá entrar em contato via e-mail ou telefone
            para apresentar produtos e serviços.
          </p>
          <p>
            2.2. Mediante o seu consentimento, podemos utilizar os seus dados
            pessoais para enviar comunicações de marketing via e-mail, telefone
            ou outras formas previamente consentidas (incluindo campanhas em
            redes sociais), para assegurar que você esteja sempre ciente sobre
            nossos serviços e produtos.
          </p>
          2.3. As aplicações digitais do Instituto Hesed poderão, ainda,
          compartilhar informações pessoais com pessoas externas à empresa caso
          isso seja necessário para:
          <ul style={{ listStyleType: 'disc', paddingLeft: '3rem' }}>
            <li>
              cumprir mandados legais, regulatórias ou judiciais, quando assim
              determinados;
            </li>
            <li>
              proteger direitos dos usuários, das plataformas digitais do
              Instituto Hesed ou do público em geral, conforme previsão legal e
              inclusive para apuração de eventuais violações.
            </li>
          </ul>
          <h2>3. Consentimento</h2>
          <p>
            3.1. Caso o tratamento de dados pessoais necessite do seu
            consentimento, este ato será livremente manifestado por você, de
            maneira informada e inequívoca e para finalidade determinada, por
            meio de nosso site ou aplicativo.
          </p>
          <p>
            3.2. O consentimento para utilização de imagem será obtido de forma
            explícita, através de uma seleção durante o cadastro, em que o
            usuário deverá confirmar que autoriza o uso de sua imagem (fotos e
            ou vídeos) para as finalidades determinadas nesta política de
            privacidade.
          </p>
          <h2>
            4. Compartilhamento de dados pessoais, Segurança e Privacidade
          </h2>
          <p>
            4.1. Quaisquer informações que os usuários transfiram para as
            plataformas digitais do Instituto Hesed, serão coletadas por meios
            éticos e legais, guardadas de acordo com padrões rígidos de
            segurança e confidencialidade, podendo ter um ou mais propósitos de
            utilização, sobre os quais os usuários serão previamente informados.
          </p>
          <p>
            4.2. Os visitantes e usuários das plataformas digitais do Instituto
            Hesed aceitam e consentem com a eventual análise dos dados
            recolhidos quando do acesso ao nosso site e aplicativo, para fins
            estatísticos, de forma a melhorar e aprimorar os nossos serviços.
          </p>
          <p>
            4.3. Quando recebemos e transferimos certos tipos de informações
            confidenciais, como, por exemplo, informações financeiras, dados de
            cartão de crédito etc., redirecionamos os usuários a servidores
            seguros. Sempre que solicitamos um número de cartão de crédito, esse
            número será transmitido no formato criptografado padrão da
            indústria, SSL (Secure Sockets Layer, camada de soquetes de
            segurança).
          </p>
          <p>
            4.4. Sua senha é secreta e intransferível, com isso, mantenha-a em
            segurança e procure não a divulgar a ninguém. Caso você esqueça a
            sua senha, uma nova será fornecida, mediante solicitação prévia, e
            enviada para a sua conta de e-mail informada. Sugerimos que você
            escolha uma senha não óbvia, misturando letras e números. Lembre-se
            de que você é responsável por manter o sigilo de suas senhas e
            qualquer informação da sua conta nas plataformas do Instituto Hesed.
          </p>
          <p>
            4.5. Não solicitaremos sua senha pessoal em nenhuma hipótese, seja
            por e-mail, telefone ou redes sociais.
          </p>
          <p>
            4.6. Recomendamos que você se desconecte de sua conta nas
            plataformas do Instituto Hesed e feche o site quando concluir a
            utilização de nossa plataforma. Este procedimento evita que
            terceiros tenham acesso aos seus dados pessoais, caso você
            compartilhe um celular, smartphone ou tablet com alguém, ou tenha
            acessado o site em local público.
          </p>
          <p>
            4.7. Observados os critérios definidos nesta Política, as
            plataformas digitais do Instituto Hesed não divulgarão dados
            pessoais dos seus usuários a terceiros,{' '}
            <span style={{ textDecoration: 'underline' }}>exceto para</span>:
          </p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '3rem' }}>
            <li>
              Cumprimento de obrigação legal, regulatória ou judicial, caso
              assim seja determinada pelo Poder Judiciário;
            </li>
            <li>
              Execução de contrato ou de procedimentos preliminares relacionados
              a contrato do qual seja parte o titular, a pedido do titular dos
              dados;
            </li>
            <li>
              Proteção do crédito, inclusive quanto ao disposto na legislação
              pertinente.
            </li>
            <li>
              Proteção de direitos dos usuários, das plataformas digitais do
              Instituto Hesed ou do público em geral, conforme previsão legal e
              inclusive para apuração de eventuais violações;
            </li>
            <li>
              Atendimento aos interesses legítimos do controlador ou de
              terceiros, quando necessário, exceto no caso de prevalecerem
              direitos e liberdades fundamentais do titular que exijam a
              proteção dos dados pessoais;
            </li>
          </ul>
          <p>
            4.8. As entidades para as quais eventualmente serão compartilhadas
            informações pessoais nos termos do disposto acima, deverão executar
            o processamento de forma a garantir o cumprimento das obrigações
            legais. Ainda assim, exigimos que as organizações que manuseiam ou
            obtenham esses dados pessoais, reconheçam a importância desta
            informação, respeitem todos os direitos individuais de privacidade e
            cumpram com todas as leis de proteção de dados.
          </p>
          <p>
            4.9. As entidades poderão representar prestadores de serviços
            comerciais e consultores especializados, contratados para serviços
            administrativos, financeiros, legais, segurança, pesquisa, geração e
            controle de pagamento e outros serviços. Poderão incluir, ainda,
            qualquer pessoa ou empresa, desde que tenhamos o seu consentimento.
          </p>
          <p>
            4.10. Na eventualidade de divulgarmos os seus dados pessoais a
            partes empresariais, tais como empresas de pagamentos, de forma a
            desempenhar os serviços solicitados, as referidas partes poderão
            armazenar a sua informação de forma a cumprir com as suas obrigações
            nos termos da LGPD.
          </p>
          <p>
            4.11. As aplicações digitais do Instituto Hesed deverão manter as
            suas informações pessoais pelo tempo necessário ao cumprimento da
            finalidade proposta ou por outros motivos legalmente permitidos. Sem
            prejuízo dos termos anteriores, os dados serão eliminados logo que o
            titular solicite a sua exclusão, sendo-lhe notificado, exceto se os
            mesmos se destinarem ao exercício de um direito ou contrato legal.
          </p>
          <h2>5. Crianças e Adolescentes</h2>
          <p>
            5.1. A Plataforma não se destina a crianças e adolescentes, isto é,
            indivíduos com idade inferior a 18 anos.
          </p>
          <p>
            5.2. Não coletamos deliberadamente dados pessoais de crianças e
            adolescentes. Se o Usuário tiver idade inferior a 18 anos, não
            deverá utilizar a Plataforma e nem nos fornecer quaisquer dados
            pessoais.
          </p>
          <p>
            5.3. Se tomarmos conhecimento de que coletamos dados pessoais de uma
            criança ou adolescente, tomaremos as medidas razoáveis para eliminar
            os dados pessoais em questão.
          </p>
          <h2>6. Prerrogativas do usuário e titular dos dados</h2>
          <p>
            6.1. As aplicações digitais do Instituto Hesed se comprometem,
            perante os seus usuários, salvo impedimento legal, resguardar os
            direitos destes de acesso aos dados que lhe digam respeito, bem com
            a sua retificação, eliminação e limitação do tratamento, nos termos
            da Lei Geral de Proteção de Dados (LGPD).
          </p>
          <p>
            6.2. Ademais, o titular dos dados deverá informar à equipe das
            plataformas digitais do Instituto Hesed, por e-mail:{' '}
            <strong>
              <a href="mailto:privacidade@institutohesed.org.br">
                privacidade@institutohesed.org.br
              </a>
            </strong>{' '}
            acerca de quaisquer das prerrogativas acima, bem como de seu
            eventual não interesse em receber anúncios, inclusive por e-mail.
            Tratando-se de cancelamento do envio de comunicados e e-mails de
            marketing etc, o titular poderá exercer o seu direito através da
            opção de cancelamento presente no rodapé dos e-mails.
          </p>
          <p>
            6.3. Você é responsável por manter suas informações de registro
            completas e atualizadas. Para atualizar as suas informações de
            registro, acesse a sua conta na aplicação digital do Instituto Hesed
            e faça a alteração desejada para modificar as suas preferências de
            usuário ou, ainda, cancelar a sua inscrição
          </p>
          <p>
            6.4. As aplicações digitais do Instituto Hesed não são responsáveis
            pelo uso indevido ou perda dos dados pessoais a que não tem acesso
            ou controle. Ficamos isentos de responsabilidade diante do uso
            ilegal e não autorizado dessa informação como consequência de uso
            indevido ou desvio das suas credenciais de acesso, conduta, atos ou
            omissões negligentes ou maliciosas da sua parte ou de alguém
            autorizado a representá-lo.
          </p>
          <p>
            Obrigado por ler a nossa Política de Privacidade. O{' '}
            <strong>
              INSTITUTO HESED DOS IRMAOS E IRMAS DA STA CRUZ E DA B. A. V. MARIA
              DO MONTE CARMELO
            </strong>
            , associação privada com sede localizada à AV DIONISIO LEONEL
            ALENCAR, 1443 – Ancuri, Fortaleza (CE), inscrita no CNPJ/MF sob o nº{' '}
            <strong>02.779.337/0001-74</strong>, é responsável pelo tratamento
            de dados para fins dos dados pessoais do Usuário processados nos
            termos deste Aviso.
          </p>
          <p>
            Seus comentários são sempre bem-vindos. Caso você tenha dúvidas ou
            reclamações sobre a nossa Política de Privacidade, entre em contato
            conosco através do e-mail:{' '}
            <strong>
              <a href="mailto:ti@institutohesed.org.br">
                ti@institutohesed.org.br
              </a>
            </strong>
          </p>
        </SectionWrapper>
      </ContainerWrapper>
    </ContentWrapper>
  )
}
