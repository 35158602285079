import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProfileLayout } from '../../../container/benfeitor/layouts/profile-layout';
import { ProfileView } from '../../../container/benfeitor/pages/Profile/View';
import { DonationsView } from '../../../container/benfeitor/pages/Profile/Donations';
import { SecurityView } from '../../../container/benfeitor/pages/Profile/Security';
import { useRedirectOnUnauthenticated } from '../../../container/benfeitor/hooks/RedirectOnUnauthenticated';

export function ProfileRoutes() {
  const { path } = useRouteMatch();

  useRedirectOnUnauthenticated();

  return (
    <ProfileLayout>
      <Switch>
        <Route path={`${path}/view`} component={ProfileView} />
        {/* <Route path={`${path}/address`} component={AddressView} /> */}
        <Route path={`${path}/donations`} component={DonationsView} />
        <Route path={`${path}/security`} component={SecurityView} />
      </Switch>
    </ProfileLayout>
  );
}
