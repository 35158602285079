import { useState } from 'react'
import { generateQueryString, handleFilterChangeAndPagination } from '../../../../../../utility/utility'
import {
  GetAllFichaVocacionalProps,
  useFetchGetAllFichaVocacional,
} from '../../../../hooks/vocacional/queries/useFetchGetAllFichaVocacional'
import { VocacionalInitialFiltersProps } from '../../utils'
import { FichaVocacionalTable } from './tabela'

export function Ficha({ vocacionalGender }: VocacionalInitialFiltersProps) {
  const FiltrosFichaVocacional = { genero: vocacionalGender, status: 'pendente' as const }

  const initialFilters = {
    queryString: generateQueryString(FiltrosFichaVocacional),
    ...FiltrosFichaVocacional,
  } as const

  const [filters, setFilters] = useState<GetAllFichaVocacionalProps['filters']>(initialFilters)

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: fichaVocacional, isLoading, isError } = useFetchGetAllFichaVocacional({ filters })

  return (
    <FichaVocacionalTable
      data={fichaVocacional}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      isError={isError}
      filters={filters}
    />
  )
}
