import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface DailyAverageDonationData {
  media_quantidade_diaria: number
  media_valores_diaria: string
}

export function useFetchDailyAverageDonation() {
  const dailyAverageDonation = useQuery<DailyAverageDonationData>({
    queryKey: ['DAILY_AVERAGE_DONATION'],
    queryFn: async () => {
      const response = await DataService.get('admin/dash-board/donations/doacoes-media-diaria')

      return response?.data
    },
  })

  return dailyAverageDonation
}
