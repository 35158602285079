import standingAngelImg from '../../../../images/standing_angel.png'
import { SmBaseCard } from '../../../components/card/styles'
import { ListItem } from '../../../components/list-item'
import { CardContent, CardWrapper, Container, ImageWrapper, ListWrapper, StyledImage, TextWrapper } from './styles'

export function SectionHowToJoin() {
  const listItems = [
    {
      number: '01.',
      text: 'Clique no botão abaixo e aliste-se,',
      highlight: 'tendo seu nome inscrito no livro;',
    },
    {
      number: '02.',
      text: 'Receba seu',
      highlight: 'Certificado Oficial ',
      suffix: 'em casa;',
    },
    {
      number: '03.',
      text: 'Mantenha-se unido ao Exército de São Miguel através das',
      highlight: 'transmissões diárias',
      suffix: '.',
    },
    {
      number: '04.',
      text: 'Inscreva-se no canal',
      highlight: 'do Youtube: Ir Kelly Patrícia OFICIAL/ INSTITUTO HESED.',
    },
    {
      number: '05.',
      text: 'Faça parte oficialmente',
      highlight: 'desta família mundial de oração',
    },
  ]

  return (
    <Container>
      <SmBaseCard>
        <CardWrapper>
          <CardContent>
            <TextWrapper>Como fazer parte?</TextWrapper>
            <ListWrapper>
              {listItems.map(({ number, text, highlight, suffix }, index) => (
                <ListItem key={index} number={number}>
                  {text}
                  {highlight && <span> {highlight}</span>}
                  {suffix && suffix}
                </ListItem>
              ))}
            </ListWrapper>
          </CardContent>
          <ImageWrapper>
            <StyledImage src={standingAngelImg} alt="Standing Angel" />
          </ImageWrapper>
        </CardWrapper>
      </SmBaseCard>
    </Container>
  )
}
