import { Form, Space } from 'antd'
import BaseButton from '../../../../components/button/BaseButton'
import { BaseDrawer } from '../../../../components/drawer/BaseDrawer'
import { FormularioCadastrarFamilia, FormularioCadastroFamiliaData } from './FormularioCadastrarFamilia'
import { useCadastrarFamilia } from '../../../../hooks/agape/mutations/useCadastrarFamilia'
import moment from 'moment'
import { compressImage } from '../../../../../../utility/utility'

type CadastrarFamiliaProps = {
  open: boolean
  onClose: () => void
}

const formatarResponsaveis = (arrayResponsaveis: FormularioCadastroFamiliaData['responsaveis']) => {
  return arrayResponsaveis.map((item) => {
    return {
      responsavel: true,
      ...item,
    }
  })
}

export function CadastrarFamilia({ open, onClose }: CadastrarFamiliaProps) {
  const [form] = Form.useForm()

  const { mutateAsync: cadastrarFamilia, isPending: isPendingCadastrarFamilia } = useCadastrarFamilia()

  const handleFormSubmit = async (data: FormularioCadastroFamiliaData) => {
    const endereco = {
      bairro: data.bairro,
      cep: data.cep,
      cidade: data.cidade,
      complemento: data.complemento,
      estado: data.estado,
      numero: data.numero,
      ponto_referencia: data.ponto_referencia,
      rua: data.rua,
    }

    const responsaveisFormatados = formatarResponsaveis(data.responsaveis)

    const arrayMembrosEResponsaveis = [...responsaveisFormatados, ...data.membros]

    const arrayMembrosEResponsaveisFormatado = arrayMembrosEResponsaveis.map((item) => {
      return {
        ...item,
        data_nascimento: String(moment(item.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')),
      }
    })

    const dadosEnvioCadastroFamilia = {
      endereco,
      membros: arrayMembrosEResponsaveisFormatado,
    }

    const fotoComprimida = data.imagens_observacao
      ? await compressImage(data.imagens_observacao[0]?.originFileObj)
      : null

    try {
      await cadastrarFamilia({
        endereco: dadosEnvioCadastroFamilia.endereco,
        membros: dadosEnvioCadastroFamilia.membros,
        observacao: data?.observacao,
        foto: fotoComprimida,
      })

      setTimeout(() => form.resetFields(), 0)

      onClose()
    } catch (error) {}
  }

  return (
    <BaseDrawer
      title={<h3>Cadastrar Família</h3>}
      placement="bottom"
      height={650}
      closable={false}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <BaseButton onClick={onClose} severity={undefined}>
            Fechar
          </BaseButton>
          <BaseButton onClick={() => form.submit()} severity={undefined} loading={isPendingCadastrarFamilia}>
            Enviar
          </BaseButton>
        </Space>
      }
    >
      <FormularioCadastrarFamilia form={form} handleFormSubmit={handleFormSubmit} />
    </BaseDrawer>
  )
}
