import { Col, Row, Tooltip } from 'antd'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import ReactInputMask from 'react-input-mask'
import { escolaridadeOptions, validateCPF, validateDate } from '../../../../../../utility/utility'
import { Info } from '@phosphor-icons/react'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import moment from 'moment'
import { BaseSelect, Option } from '../../../../components/select/BaseSelect'
import { ResponsavelFuncaoFamiliarOptions } from './utils'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { profissoes } from '../../../../../../utility/profissoes'
import { BaseInputNumber } from '../../../../components/inputs/InputNumber/BaseInputNumber'

export function FormularioResponsavel({ fieldName }: { fieldName?: number }) {
  return (
    <>
      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} sm={8}>
          <BaseForm.Item name={[fieldName, 'nome']} label="Nome" rules={[{ required: true, message: 'Obrigatório' }]}>
            <BaseInput placeholder="Nome do responsável" />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'email']}
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Deve ser um e-mail válido',
                whitespace: true,
              },
            ]}
          >
            <BaseInput placeholder="E-mail" />
          </BaseForm.Item>
        </Col>

        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'telefone']}
            label="Telefone"
            rules={[
              { required: true, message: 'Obrigatório' },
              { pattern: /^\(\d{2}\) \d{4,5}-\d{4}$/, message: 'Telefone inválido!' },
            ]}
          >
            <ReactInputMask mask="(99) 99999-9999" placeholder="(99) 99999-9999" maskChar={null}>
              {(inputProps: any) => <BaseInput {...inputProps} />}
            </ReactInputMask>
          </BaseForm.Item>
        </Col>
      </Row>

      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'cpf']}
            label="CPF"
            rules={[{ required: true, message: 'Obrigatório' }, { validator: validateCPF }]}
          >
            <ReactInputMask mask="999.999.999-99" name="cpf_cnpj" maskChar={null} placeholder="999.999.999-99">
              {(inputProps) => <BaseInput {...inputProps} />}
            </ReactInputMask>
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'data_nascimento']}
            label="Data de nascimento"
            rules={[
              { required: true, message: 'Por favor, preencha sua data de nascimento!' },
              { validator: (_, value) => validateDate(value) },
            ]}
          >
            <ReactInputMask mask="99/99/9999" placeholder="dd/mm/yyyy">
              {(inputProps) => <BaseInput {...inputProps} />}
            </ReactInputMask>
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'funcao_familiar']}
            label="Função Familiar"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <BaseSelect placeholder="Selecione uma opção" allowClear>
              {ResponsavelFuncaoFamiliarOptions.map((item) => (
                <Option key={item.value} value={item.label}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </Col>
      </Row>

      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'escolaridade']}
            label="Escolaridade"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <BaseSelect placeholder="Selecione uma opção">
              <Option key={'Infantil'} value={'Infantil'}>
                Infantil
              </Option>
              {escolaridadeOptions.map((item) => (
                <Option key={item.value} value={item.label}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'ocupacao']}
            label="Ocupação"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <BaseSelect placeholder="Pesquise e selecione uma opção" showSearch allowClear>
              {profissoes.map((item) => (
                <Option key={item.value} value={item.label}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </Col>

        <Col xs={24} sm={8}>
          <BaseForm.Item
            name={[fieldName, 'renda']}
            label={
              <span style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                Renda
                <Tooltip title="Apenas reais, exemplo R$1.000">
                  <Info />
                </Tooltip>
              </span>
            }
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <BaseInputNumber
              block
              controls={false}
              prefix="R$ "
              formatter={(value) =>
                value
                  ? `${value
                    .toString()
                    .replace(/\D/g, '')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                    .replace(/\.(\d{2})$/, ',$1')}`
                  : ''
              }
              parser={(value) => (value ? Number(value.replace(/\./g, '').replace(',', '.')) : '')}
            />
          </BaseForm.Item>
        </Col>
      </Row>
    </>
  )
}
