export default function GroupCardTitle({ title, Icon }) {
  return (
    <strong
      style={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        letterSpacing: '0.3px',
      }}
    >
      <Icon /> {title}
    </strong>
  )
}
