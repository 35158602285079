import React from 'react'
import { Badge } from 'antd'
import * as S from './BaseBadge.Styles'
import { mapBadgeStatus } from '../../../../utility/utility'

export const BaseBadge = ({ status, children, count, ...props }) => (
  <S.Badge {...(status ? (count ? { count, severity: mapBadgeStatus(status) } : { status }) : { count })} {...props}>
    {children}
  </S.Badge>
)

BaseBadge.Ribbon = Badge.Ribbon
