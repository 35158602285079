import { Col, Form, Result, Row } from 'antd'
import ReactInputMask from 'react-input-mask'
import { useCreatePreCadastro } from '../../../hooks/api/vocacional/mutations/useCreatePreCadastro'
import { phoneWithoutMask } from '../../../../../utility/utility'
import { StyledButton } from '../../../components/Button'
import { StyledInput } from '../../../components/Inputs'
import { StyledForm } from '../../../components/Form/styles'
import { StyledSelect } from '../../../components/Select/styles'
import { countries } from '../../../../../utility/allCountries'
import { useState } from 'react'

interface PreCadastroFormData {
  nome: string
  email: string
  telefone: string
  pais: string
}

export function PreCadastroForm({ vocacional }: { vocacional: 'feminino' | 'masculino' }) {
  const [form] = Form.useForm()

  const [pais, setPais] = useState('brasil')

  function handleCountryChange(selectedCountry: string) {
    // Verifica se o usuário estava no Brasil e foi para outro país
    // ou se estava em outro país e foi para o Brasil
    if ((pais === 'brasil' && selectedCountry !== 'brasil') || (pais !== 'brasil' && selectedCountry === 'brasil')) {
      form.resetFields(['telefone'])
    }
    setPais(selectedCountry)
  }

  const brasileiro = pais === 'brasil'

  const {
    mutateAsync: createPreCadastro,
    isPending: isPendingCreatePreCadastro,
    isSuccess: isPreCadastroCreated,
  } = useCreatePreCadastro()

  const handleFormSubmit = (data: PreCadastroFormData) => {
    const { nome, email, telefone, pais } = data

    createPreCadastro({
      nome,
      email,
      telefone: phoneWithoutMask(telefone),
      genero: vocacional,
      pais,
    })
  }

  if (isPreCadastroCreated) {
    return (
      <Result
        status="success"
        title="Pré-cadastro criado com sucesso."
        subTitle="Aguarde e verifique seu e-mail com periodicidade!"
        style={{ paddingTop: '0' }}
      />
    )
  }

  return (
    <StyledForm form={form} layout="vertical" onFinish={(values) => handleFormSubmit(values as PreCadastroFormData)}>
      <Form.Item
        label="País"
        name="pais"
        initialValue="brasil"
        rules={[{ required: true, message: 'Por favor, insira o país!' }]}
      >
        <StyledSelect
          options={countries}
          value={pais}
          onChange={(value) => handleCountryChange(value)}
          placeholder="Selecione um país"
        />
      </Form.Item>

      <Form.Item name="nome" label="Nome" rules={[{ required: true, message: 'Obrigatório' }]}>
        <StyledInput placeholder="Nome" />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          { type: 'email', message: 'Deve ser um e-mail válido', whitespace: true },
          { required: true, message: 'Obrigatório' },
        ]}
      >
        <StyledInput placeholder="E-mail" />
      </Form.Item>

      <Form.Item
        name="confirm_email"
        label="Confirmar E-mail"
        dependencies={['email']}
        rules={[
          { type: 'email', message: '' },
          { required: true, message: 'Obrigatório' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
            },
          }),
        ]}
      >
        <StyledInput placeholder="Confirmar" />
      </Form.Item>

      <Col xs={24}>
        {brasileiro ? (
          <Form.Item
            name="telefone"
            label="Telefone"
            rules={[
              { required: true, message: 'Obrigatório' },
              { pattern: /^\(\d{2}\) \d{4,5}-\d{4}$/, message: 'Telefone inválido!' },
            ]}
          >
            <ReactInputMask mask="(99) 99999-9999" placeholder="(99) 99999-9999" maskChar={null}>
              {(inputProps: any) => <StyledInput {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        ) : (
          <Form.Item name="telefone" label="Telefone" rules={[{ required: true, message: 'Obrigatório' }]}>
            <StyledInput placeholder="Phone number" />
          </Form.Item>
        )}
      </Col>

      <Row justify="center">
        <StyledButton htmlType="submit" loading={isPendingCreatePreCadastro}>
          Enviar
        </StyledButton>
      </Row>
    </StyledForm>
  )
}
