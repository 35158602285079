import { Col, Row, TablePaginationConfig } from 'antd'

import { VocacionalPreCadastroFilters } from './filters'

import { DetalhesPreCadastro } from '../detalhes'
import { ReenviarEmailVocacional } from '../../ReenviarEmail'
import { StatusMap } from '../../../utils'
import { ExcluirUsuarioVocacional } from '../../ExcluirUsuario'
import {
  GetAllPreCadastroProps,
  GetAllPreCadastroResponse,
} from '../../../../../hooks/vocacional/queries/useFetchGetAllPreCadastro'
import { getLabelFromCountryValue, handlePhoneNumberFormat } from '../../../../../../../utility/utility'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'

export interface PreCadastradosTableProps {
  data?: GetAllPreCadastroResponse
  handleInputChange: (filter: GetAllPreCadastroProps['filters'], pagination?: boolean) => void
  isLoading: boolean
  isError: boolean
  filters: GetAllPreCadastroProps['filters']
}

export function PreCadastradosTable({
  data,
  handleInputChange,
  isLoading,
  isError,
  filters,
}: PreCadastradosTableProps) {
  const visualizandoAprovados = filters?.status === 'aprovado'

  const dataSource = data?.pre_cadastros.map((item) => {
    const { id, nome, email, telefone, pais, created_at, status, responsavel } = item

    return {
      id,
      nome,
      email,
      pais: getLabelFromCountryValue(pais),
      telefone: pais === 'brasil' ? handlePhoneNumberFormat(telefone) : telefone,
      status: StatusMap[status],
      cadastro: created_at,
      responsavel: responsavel ?? ' - ',
      acao: (
        <Row justify="center" gutter={[20, 20]}>
          {visualizandoAprovados ? (
            <ReenviarEmailVocacional usuario_vocacional_id={id} />
          ) : (
            <Col>
              <DetalhesPreCadastro usuario_vocacional_id={id} />
            </Col>
          )}

          <Col>
            <ExcluirUsuarioVocacional fk_usuario_vocacional_id={id} nome_usuario={nome} listagem="pre-cadastro" />
          </Col>
        </Row>
      ),
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      align: 'center',
      filterDropdown: () => (
        <VocacionalPreCadastroFilters.Nome value={filters?.nome} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.nome && [filters.nome],
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      filterDropdown: () => (
        <VocacionalPreCadastroFilters.Email value={filters?.email} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.email && [filters.email],
    },
    {
      title: 'País',
      dataIndex: 'pais',
      key: 'pais',
      align: 'center',
      filterDropdown: () => (
        <VocacionalPreCadastroFilters.Pais value={filters?.pais} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.pais && [filters.pais],
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      align: 'center',
      filterDropdown: () => (
        <VocacionalPreCadastroFilters.Telefone value={filters?.telefone} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.telefone && [filters.telefone],
    },
    {
      title: 'Cadastro em',
      dataIndex: 'cadastro',
      key: 'cadastro',
      align: 'center',
    },
    ...(visualizandoAprovados
      ? [
          {
            title: 'Responsável',
            dataIndex: 'responsavel',
            key: 'responsavel',
            align: 'center',
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filterDropdown: () => (
        <VocacionalPreCadastroFilters.Status value={filters?.status} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.status && [filters.status],
    },
    {
      title: 'Ação',
      dataIndex: 'acao',
      key: 'acao',
      align: 'center',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoading}
      isError={isError}
      pagination={{
        current: data?.current_page,
        total: data?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
    />
  )
}
