export const BasePaginationLocale = {
  items_per_page: 'por página',
  jump_to: 'Ir para',
  jump_to_confirm: 'Confirmar',
  page: 'Página',
  prev_page: 'Página anterior',
  next_page: 'Próxima página',
  // eslint-disable-next-line camelcase
  prev_5: '5 páginas anteriores',
  // eslint-disable-next-line camelcase
  next_5: 'Próximas 5 páginas',
  // eslint-disable-next-line camelcase
  prev_3: '3 páginas anteriores',
  // eslint-disable-next-line camelcase
  next_3: 'Próximas 3 páginas',
}
