import { useQuery } from '@tanstack/react-query'

interface ChartData {
  hour: string
  count: number
}

interface SummaryData {
  today: number
  averagePerHour: number
  deliveryRate: number
  readRate: number
  chart: ChartData[]
}

export function useMessagesSummary() {
  return useQuery<SummaryData>({
    queryKey: ['messages-summary'],
    queryFn: async () => {
      return {
        today: 2456,
        averagePerHour: 102,
        deliveryRate: 98.5,
        readRate: 76.2,
        chart: [
          { hour: '08:00', count: 85 },
          { hour: '09:00', count: 120 },
          { hour: '10:00', count: 150 },
          { hour: '11:00', count: 135 },
          { hour: '12:00', count: 90 },
          { hour: '13:00', count: 110 },
          { hour: '14:00', count: 140 }
        ]
      }
    }
  })
} 