import { GradientHeader } from '../../../components/gradient-header'
import { SecondSectionStyled } from './styles'

export function SecondSection() {
  return (
    <SecondSectionStyled>
      <>
        <GradientHeader className="header" colorOne="#121924" colorTwo="#1A3F80">
          UM NOVO CAPÍTULO NA SUA HISTÓRIA COM DEUS{' '}
        </GradientHeader>
        <p>
          Você já experimentou o poder da oração. Agora, chegou a hora de dar um passo a mais e liderar outros
          corações. 
        </p>
      </>
    </SecondSectionStyled>
  )
}
