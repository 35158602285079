import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { Documento } from '../../../../alistamento-sm/pages/validate-user'

export interface UseFindUserDocumentNumberRequest {
  document: Documento
}

export interface UseFindUserDocumentNumberResponse {
  general: boolean
  usuario: boolean
  email?: string
}

export function useFindUserByDocumentNumber({
  tipo_documento,
  numero_documento,
}: Documento): UseQueryResult<UseFindUserDocumentNumberResponse> {
  return useQuery({
    queryKey: ['users/buscar-usuario-por-numero-documento', tipo_documento, numero_documento],
    queryFn: async () => {
      if (!numero_documento) return
      const response = await DataService.post<UseFindUserDocumentNumberRequest>(
        '/users/buscar-usuario-por-numero-documento',
        {
          numero_documento,
          tipo_documento,
        },
      )
      return response?.data
    },
    enabled: !!numero_documento,
    staleTime: 0,
    gcTime: 0,
    retry: false,
  })
}
