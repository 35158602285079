import { DetalhesCadastro } from '../detalhes'
import { ReenviarEmailVocacional } from '../../ReenviarEmail'
import { ExcluirUsuarioVocacional } from '../../ExcluirUsuario'
import {
  GetAllCadastroProps,
  GetAllCadastroResponse,
} from '../../../../../hooks/vocacional/queries/useFetchGetAllCadastro'
import {
  getLabelFromCountryValue,
  handleCpfShowValues,
  handlePhoneNumberFormat,
} from '../../../../../../../utility/utility'
import { StatusMap } from '../../../utils'
import { Col, Row, TablePaginationConfig } from 'antd'
import { VocacionalCadastroFilters } from './filters'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'

interface CadastroTableProps {
  data?: GetAllCadastroResponse
  handleInputChange: (filter: GetAllCadastroProps['filters'], pagination?: boolean) => void
  isLoading: boolean
  isError: boolean
  filters: GetAllCadastroProps['filters']
}

export function CadastrosTable({ data, handleInputChange, isLoading, isError, filters }: CadastroTableProps) {
  const visualizandoAprovados = filters?.status === 'aprovado'

  const dataSource = data?.cadastros_vocacionais.map((item) => {
    const {
      nome,
      fk_usuario_vocacional_id,
      created_at,
      data_nascimento,
      email,
      documento_identidade,
      status,
      telefone,
      pais,
      responsavel,
    } = item

    const usuarioBrasileiro = pais === 'brasil'

    return {
      id: fk_usuario_vocacional_id,
      nome,
      email,
      documento_identidade: usuarioBrasileiro ? handleCpfShowValues(documento_identidade) : documento_identidade,
      data_nascimento,
      pais: getLabelFromCountryValue(pais),
      telefone: usuarioBrasileiro ? handlePhoneNumberFormat(telefone) : telefone,
      status: StatusMap[status],
      responsavel: responsavel ?? ' - ',
      cadastro: created_at,
      acoes: (
        <Row justify="center" gutter={[20, 20]}>
          <Col>
            <DetalhesCadastro
              atualizarStatus={!visualizandoAprovados}
              usuario_vocacional_id={fk_usuario_vocacional_id}
              dados={item}
            />
          </Col>

          {visualizandoAprovados && <ReenviarEmailVocacional usuario_vocacional_id={fk_usuario_vocacional_id} />}

          <Col>
            <ExcluirUsuarioVocacional
              fk_usuario_vocacional_id={fk_usuario_vocacional_id}
              nome_usuario={nome}
              listagem="cadastro"
            />
          </Col>
        </Row>
      ),
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      align: 'center',
      filterDropdown: () => (
        <VocacionalCadastroFilters.Nome value={filters?.nome} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.nome && [filters.nome],
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      filterDropdown: () => (
        <VocacionalCadastroFilters.Email value={filters?.email} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.email && [filters.email],
    },
    {
      title: 'País',
      dataIndex: 'pais',
      key: 'pais',
      align: 'center',
      filterDropdown: () => (
        <VocacionalCadastroFilters.Pais value={filters?.pais} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.pais && [filters?.pais],
    },
    {
      title: 'Documento',
      dataIndex: 'documento_identidade',
      key: 'documento_identidade',
      align: 'center',
      filterDropdown: () => (
        <VocacionalCadastroFilters.DocumentoIdentidade
          value={filters?.documento_identidade}
          handleInputChange={handleInputChange}
        />
      ),
      filteredValue: filters?.documento_identidade && [filters?.documento_identidade],
    },
    {
      title: 'Data de nascimento',
      dataIndex: 'data_nascimento',
      key: 'data_nascimento',
      align: 'center',
    },

    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      align: 'center',
    },
    {
      title: 'Cadastro em',
      dataIndex: 'cadastro',
      key: 'cadastro',
      align: 'center',
      // filterDropdown: () => (
      //   <VocacionalCadastroFilters.CadastroEm
      //     value={{ data_inicio: filters.data_inicio || '', data_fim: filters.data_fim || '' }}
      //     handleInputChange={handleInputChange}
      //   />
      // ),
      // filteredValue: filters?.data_inicio && filters?.data_fim && [filters.data_inicio, filters.data_fim],
    },
    ...(filters?.status === 'aprovado'
      ? [
          {
            title: 'Responsável',
            dataIndex: 'responsavel',
            key: 'responsavel',
            align: 'center',
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filterDropdown: () => (
        <VocacionalCadastroFilters.Status value={filters?.status} handleInputChange={handleInputChange} />
      ),
      filteredValue: filters?.status && [filters?.status],
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      align: 'center',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoading}
      isError={isError}
      pagination={{
        current: data?.page,
        total: data?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
    />
  )
}
