import { useQuery } from '@tanstack/react-query'

interface DeliveryData {
  date: string
  rate: number
}

export function useMessageDeliveryRate() {
  return useQuery<DeliveryData[]>({
    queryKey: ['message-delivery-rate'],
    queryFn: async () => {
      return [
        { date: '01/03', rate: 98 },
        { date: '02/03', rate: 97 },
        { date: '03/03', rate: 99 },
        { date: '04/03', rate: 98 },
        { date: '05/03', rate: 96 },
        { date: '06/03', rate: 97 },
        { date: '07/03', rate: 98 }
      ]
    }
  })
} 