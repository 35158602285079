import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { DataService } from '../../../../../lib/dataService'

export type UpdateVocacionalStatusProps = {
  usuario_vocacional_id: number
  acao: 'aprovar' | 'reprovar'
  justificativa?: string
}

export type ListagemVocacional = 'pre-cadastro' | 'cadastro' | 'ficha-vocacional'

export function useUpdateVocacionalStatus(listagem: ListagemVocacional) {
  const queryClient = useQueryClient()

  const updateVocacionalStatus = useMutation({
    mutationFn: async ({ usuario_vocacional_id, acao, justificativa }: UpdateVocacionalStatusProps) => {
      await DataService.put('/vocacional/atualizar-andamento-vocacional', {
        acao,
        usuario_vocacional_id,
        justificativa,
      })
    },
    onSuccess: (_, { acao }) => {
      const actionMessage = acao === 'aprovar' ? 'aprovado' : 'recusado'

      notification.success({ message: `Usuário ${actionMessage} com sucesso.` })

      if (listagem === 'pre-cadastro') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_PRE_CADASTRO'] })
      }

      if (listagem === 'cadastro') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_CADASTRO'] })
      }

      if (listagem === 'ficha-vocacional') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_FICHA_VOCACIONAL'] })
      }

      if (acao === 'reprovar') {
        queryClient.invalidateQueries({ queryKey: ['GET_ALL_RECUSADOS'] })
      }
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao atualizar etapa.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return updateVocacionalStatus
}
