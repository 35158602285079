import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useGetPermissions } from './Queries/useGetSelfPermissions'

export function useRedirectOnPermDenied(permission, type = 'acessar') {
  const history = useHistory()

  const { data: { permissoes } = {}, isLoading } = useGetPermissions()

  useEffect(() => {
    if (!isLoading) {
      const hasPermission = permissoes?.[permission]?.[type] === 1

      if (!hasPermission) {
        history.push('/admin/permission-denied')
      }
    }
  }, [isLoading, permissoes, permission, type, history])
}
