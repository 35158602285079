import Paragraph from 'antd/lib/typography/Paragraph'
import { BaseDescription } from '../../../../../../description/BaseDescription'

export function DonationDetailsContent({ donationContent }) {
  return (
    <BaseDescription bordered column={1}>
      <BaseDescription.Item label="Id">{donationContent.id}</BaseDescription.Item>
      <BaseDescription.Item label="Data e hora">{donationContent.data_hora}</BaseDescription.Item>
      <BaseDescription.Item label="Comprovante Id">
        {donationContent.comprovante_id ? (
          <Paragraph
            copyable={{
              tooltips: ['Copiar', 'Copiado!'],
            }}
          >
            {donationContent.comprovante_id}
          </Paragraph>
        ) : (
          <span> - </span>
        )}
      </BaseDescription.Item>
      <BaseDescription.Item label="Referência Id">
        {donationContent.referencia_id ? (
          <Paragraph
            copyable={{
              tooltips: ['Copiar', 'Copiado!'],
            }}
          >
            {donationContent.referencia_id}
          </Paragraph>
        ) : (
          <span> - </span>
        )}
      </BaseDescription.Item>
      <BaseDescription.Item label="Transação Id">
        {donationContent.transacao_id ? (
          <Paragraph
            copyable={{
              tooltips: ['Copiar', 'Copiado!'],
            }}
          >
            {donationContent.transacao_id}
          </Paragraph>
        ) : (
          <span> - </span>
        )}
      </BaseDescription.Item>
      <BaseDescription.Item label="Status">{donationContent.status}</BaseDescription.Item>
      <BaseDescription.Item label="Método">{donationContent.metodo}</BaseDescription.Item>
      <BaseDescription.Item label="Gateway (banco)">{donationContent.gateway}</BaseDescription.Item>
      <BaseDescription.Item label="Recorrência">{donationContent.recorrencia}</BaseDescription.Item>
      <BaseDescription.Item label="Tipo">{donationContent.tipo}</BaseDescription.Item>
    </BaseDescription>
  )
}
