import { useParams } from 'react-router-dom'
import { useGetLandingPageById } from '../../hooks/api/landingpage/queries/useGetLandingPageById'
import { StyledSpin } from '../../components/Spin/styles'
import { Result, Row } from 'antd'
import { LandingPageStepForm } from './components/LandingPageForm'
// import { BannerLanding } from '../../../benfeitores/LandingPage/components/BannnerLanding/BannerLanding'
import { StyledContent } from '../../layouts/landing-page-layout/components/styles'
import { useState } from 'react'
import LandingPageStepFormFinished from './components/FormFinished'
import { GeneralStepForm } from './components/GeneralForm'
import { LoginModal } from '../../layouts/benfeitor-layout/components/Header/Modals/LoginModal'
import { BannerLandingPage } from './components/BannerLandingPage'

type LandingPagePropsParams = {
  id: string
  name: string
}

export function LandingPage({ embed }: { embed: boolean }) {
  const { id } = useParams<LandingPagePropsParams>()
  const [isFinished, setIsFinished] = useState(false)

  const {
    data: landingPageData,
    isLoading: isLoadingLandingPageData,
    isError: isErrorLandingPageData,
  } = useGetLandingPageById({ id })

  if (isLoadingLandingPageData) {
    return (
      <Row justify="center" style={{ minHeight: '80vh', alignItems: 'center' }}>
        <StyledSpin />
      </Row>
    )
  }

  if (isErrorLandingPageData) {
    return (
      <Result
        status="500"
        title="Ocorreu um erro ao exibir esse conteúdo."
        subTitle="Por favor, tente novamente"
      />
    )
  }

  return (
    <>
      <LoginModal />
      {!embed && <BannerLandingPage data={landingPageData} />}
      <StyledContent>
        {!isFinished && landingPageData?.tipo_cadastro === "General" && <GeneralStepForm markAsFinished={() => { setIsFinished(true) }} />}
        {!isFinished && landingPageData?.tipo_cadastro != "General" && <LandingPageStepForm landingPageData={landingPageData!} markAsFinished={() => { setIsFinished(true) }} />}

        {isFinished && <LandingPageStepFormFinished landingPageData={landingPageData!} />}
      </StyledContent>
    </>
  )
}
