interface ProfissaoOption {
  label: string;
  value: string;
}

export const profissoes: ProfissaoOption[] = [
  {
    label: 'Desempregado',
    value: 'desempregado',
  },
  {
    label: 'Estudante',
    value: 'estudante',
  },
  {
    label: 'Abastecedor de Linha de Produção',
    value: 'abastecedor_de_linha_de_producao',
  },
  {
    label: 'Abastecedor de Máquinas',
    value: 'abastecedor_de_maquinas',
  },
  {
    label: 'Acabador de Embalagens',
    value: 'acabador_de_embalagens',
  },
  {
    label: 'Acrilista',
    value: 'acrilista',
  },
  {
    label: 'Acupunturista',
    value: 'acupunturista',
  },
  {
    label: 'Aderecista',
    value: 'aderecista',
  },
  {
    label: 'Adesivador',
    value: 'adesivador',
  },
  {
    label: 'Adestrador',
    value: 'adestrador',
  },
  {
    label: 'Administrador de Contratos',
    value: 'administrador_de_contratos',
  },
  {
    label: 'Administrador de Dados',
    value: 'administrador_de_dados',
  },
  {
    label: 'Administrador de E-commerce',
    value: 'administrador_de_ecommerce',
  },
  {
    label: 'Administrador de Empresas',
    value: 'administrador_de_empresas',
  },
  {
    label: 'Administrador de Sistemas',
    value: 'administrador_de_sistemas',
  },
  {
    label: 'Administrador Financeiro',
    value: 'administrador_financeiro',
  },
  {
    label: 'Administrador Hospitalar',
    value: 'administrador_hospitalar',
  },
  {
    label: 'Administrador Predial',
    value: 'administrador_predial',
  },
  {
    label: 'Administrador',
    value: 'administrador',
  },
  {
    label: 'Administrativo de Obras',
    value: 'administrativo_de_obras',
  },
  {
    label: 'Advogado Administrativo',
    value: 'advogado_administrativo',
  },
  {
    label: 'Advogado Ambientalista',
    value: 'advogado_ambientalista',
  },
  {
    label: 'Advogado Consumerista',
    value: 'advogado_consumerista',
  },
  {
    label: 'Advogado Criminalista',
    value: 'advogado_criminalista',
  },
  {
    label: 'Advogado Cível',
    value: 'advogado_civel',
  },
  {
    label: 'Advogado de Contratos',
    value: 'advogado_de_contratos',
  },
  {
    label: 'Advogado de Propriedade Intelectual',
    value: 'advogado_de_propriedade_intelectual',
  },
  {
    label: 'Advogado Eleitoral',
    value: 'advogado_eleitoral',
  },
  {
    label: 'Advogado Empresarial',
    value: 'advogado_empresarial',
  },
  {
    label: 'Advogado Imobiliário',
    value: 'advogado_imobiliario',
  },
  {
    label: 'Advogado Securitário',
    value: 'advogado_securitario',
  },
  {
    label: 'Advogado Societário',
    value: 'advogado_societario',
  },
  {
    label: 'Advogado Trabalhista',
    value: 'advogado_trabalhista',
  },
  {
    label: 'Advogado Tributarista',
    value: 'advogado_tributarista',
  },
  {
    label: 'Advogado',
    value: 'advogado',
  },
  {
    label: 'Afiador de Ferramentas',
    value: 'afiador_de_ferramentas',
  },
  {
    label: 'Agente de Aeroporto',
    value: 'agente_de_aeroporto',
  },
  {
    label: 'Agente de Segurança',
    value: 'agente_de_seguranca',
  },
  {
    label: 'Agente de Serviços',
    value: 'agente_de_servicos',
  },
  {
    label: 'Agente de Viagens',
    value: 'agente_de_viagens',
  },
  {
    label: 'Agente Funerário',
    value: 'agente_funerario',
  },
  {
    label: 'Ajudante de Caldeiraria',
    value: 'ajudante_de_caldeiraria',
  },
  {
    label: 'Ajudante de Carga E Descarga',
    value: 'ajudante_de_carga_e_descarga',
  },
  {
    label: 'Ajudante de Carpinteiro',
    value: 'ajudante_de_carpinteiro',
  },
  {
    label: 'Ajudante de Churrasqueiro',
    value: 'ajudante_de_churrasqueiro',
  },
  {
    label: 'Ajudante de Despachante Aduaneiro',
    value: 'ajudante_de_despachante_aduaneiro',
  },
  {
    label: 'Ajudante de Encanador',
    value: 'ajudante_de_encanador',
  },
  {
    label: 'Ajudante de Estamparia',
    value: 'ajudante_de_estamparia',
  },
  {
    label: 'Ajudante de Fundição',
    value: 'ajudante_de_fundicao',
  },
  {
    label: 'Ajudante de Funilaria',
    value: 'ajudante_de_funilaria',
  },
  {
    label: 'Ajudante de Instalação',
    value: 'ajudante_de_instalacao',
  },
  {
    label: 'Ajudante de Lubrificação',
    value: 'ajudante_de_lubrificacao',
  },
  {
    label: 'Ajudante de Marceneiro',
    value: 'ajudante_de_marceneiro',
  },
  {
    label: 'Ajudante de Metalúrgico',
    value: 'ajudante_de_metalurgico',
  },
  {
    label: 'Ajudante de Motorista',
    value: 'ajudante_de_motorista',
  },
  {
    label: 'Ajudante de Obras',
    value: 'ajudante_de_obras',
  },
  {
    label: 'Ajudante de Off-set',
    value: 'ajudante_de_offset',
  },
  {
    label: 'Ajudante de Pizzaiolo',
    value: 'ajudante_de_pizzaiolo',
  },
  {
    label: 'Ajudante de Serralheiro',
    value: 'ajudante_de_serralheiro',
  },
  {
    label: 'Ajudante de Tapeceiro',
    value: 'ajudante_de_tapeceiro',
  },
  {
    label: 'Ajudante de Vidraceiro',
    value: 'ajudante_de_vidraceiro',
  },
  {
    label: 'Ajudante Geral',
    value: 'ajudante_geral',
  },
  {
    label: 'Ajudante Operacional',
    value: 'ajudante_operacional',
  },
  {
    label: 'Ajustador Mecânico',
    value: 'ajustador_mecanico',
  },
  {
    label: 'Alfaiate',
    value: 'alfaiate',
  },
  {
    label: 'Alinhador',
    value: 'alinhador',
  },
  {
    label: 'Almoxarife',
    value: 'almoxarife',
  },
  {
    label: 'Alpinista Industrial',
    value: 'alpinista_industrial',
  },
  {
    label: 'Amarrador',
    value: 'amarrador',
  },
  {
    label: 'Analista Administrativo de Obras',
    value: 'analista_administrativo_de_obras',
  },
  {
    label: 'Analista Administrativo',
    value: 'analista_administrativo',
  },
  {
    label: 'Analista Ambiental',
    value: 'analista_ambiental',
  },
  {
    label: 'Analista Comercial',
    value: 'analista_comercial',
  },
  {
    label: 'Analista Contábil',
    value: 'analista_contabil',
  },
  {
    label: 'Analista de Almoxarifado',
    value: 'analista_de_almoxarifado',
  },
  {
    label: 'Analista de Apuração de Resultados',
    value: 'analista_de_apuracao_de_resultados',
  },
  {
    label: 'Analista de Arquitetura',
    value: 'analista_de_arquitetura',
  },
  {
    label: 'Analista de Assistência Técnica',
    value: 'analista_de_assistencia_tecnica',
  },
  {
    label: 'Analista de Assuntos Regulatórios',
    value: 'analista_de_assuntos_regulatorios',
  },
  {
    label: 'Analista de Atendimento',
    value: 'analista_de_atendimento',
  },
  {
    label: 'Analista de Ativo Fixo',
    value: 'analista_de_ativo_fixo',
  },
  {
    label: 'Analista de Auditoria',
    value: 'analista_de_auditoria',
  },
  {
    label: 'Analista de Automação',
    value: 'analista_de_automacao',
  },
  {
    label: 'Analista de Back Office',
    value: 'analista_de_back_office',
  },
  {
    label: 'Analista de Banco de Dados',
    value: 'analista_de_banco_de_dados',
  },
  {
    label: 'Analista de Benefícios',
    value: 'analista_de_beneficios',
  },
  {
    label: 'Analista de Business Intelligence',
    value: 'analista_de_business_intelligence',
  },
  {
    label: 'Analista de Cadastro',
    value: 'analista_de_cadastro',
  },
  {
    label: 'Analista de Campo',
    value: 'analista_de_campo',
  },
  {
    label: 'Analista de Captação de Recursos',
    value: 'analista_de_captacao_de_recursos',
  },
  {
    label: 'Analista de Cargos E Salários',
    value: 'analista_de_cargos_e_salarios',
  },
  {
    label: 'Analista de Cobrança',
    value: 'analista_de_cobranca',
  },
  {
    label: 'Analista de Compliance',
    value: 'analista_de_compliance',
  },
  {
    label: 'Analista de Compras',
    value: 'analista_de_compras',
  },
  {
    label: 'Analista de Comunicação E Marketing',
    value: 'analista_de_comunicacao_e_marketing',
  },
  {
    label: 'Analista de Comunicação Interna',
    value: 'analista_de_comunicacao_interna',
  },
  {
    label: 'Analista de Comunicação',
    value: 'analista_de_comunicacao',
  },
  {
    label: 'Analista de Comércio Exterior',
    value: 'analista_de_comercio_exterior',
  },
  {
    label: 'Analista de Contas A Pagar E Receber',
    value: 'analista_de_contas_a_pagar_e_receber',
  },
  {
    label: 'Analista de Contas A Pagar',
    value: 'analista_de_contas_a_pagar',
  },
  {
    label: 'Analista de Contas A Receber',
    value: 'analista_de_contas_a_receber',
  },
  {
    label: 'Analista de Contas Médicas',
    value: 'analista_de_contas_medicas',
  },
  {
    label: 'Analista de Contas',
    value: 'analista_de_contas',
  },
  {
    label: 'Analista de Conteúdo',
    value: 'analista_de_conteudo',
  },
  {
    label: 'Analista de Contratos',
    value: 'analista_de_contratos',
  },
  {
    label: 'Analista de Control Desk',
    value: 'analista_de_control_desk',
  },
  {
    label: 'Analista de Controladoria',
    value: 'analista_de_controladoria',
  },
  {
    label: 'Analista de Controle de Qualidade',
    value: 'analista_de_controle_de_qualidade',
  },
  {
    label: 'Analista de Controles Internos',
    value: 'analista_de_controles_internos',
  },
  {
    label: 'Analista de Credenciamento',
    value: 'analista_de_credenciamento',
  },
  {
    label: 'Analista de Criação',
    value: 'analista_de_criacao',
  },
  {
    label: 'Analista de Crm',
    value: 'analista_de_crm',
  },
  {
    label: 'Analista de Crédito E Cobrança',
    value: 'analista_de_credito_e_cobranca',
  },
  {
    label: 'Analista de Crédito Imobiliário',
    value: 'analista_de_credito_imobiliario',
  },
  {
    label: 'Analista de Crédito',
    value: 'analista_de_credito',
  },
  {
    label: 'Analista de Customer Service',
    value: 'analista_de_customer_service',
  },
  {
    label: 'Analista de Custos',
    value: 'analista_de_custos',
  },
  {
    label: 'Analista de Câmbio',
    value: 'analista_de_cambio',
  },
  {
    label: 'Analista de Dbm',
    value: 'analista_de_dbm',
  },
  {
    label: 'Analista de Departamento Pessoal',
    value: 'analista_de_departamento_pessoal',
  },
  {
    label: 'Analista de Desembaraço Aduaneiro',
    value: 'analista_de_desembaraco_aduaneiro',
  },
  {
    label: 'Analista de Desenvolvimento Analítico',
    value: 'analista_de_desenvolvimento_analitico',
  },
  {
    label: 'Analista de Documentação',
    value: 'analista_de_documentacao',
  },
  {
    label: 'Analista de E-commerce',
    value: 'analista_de_ecommerce',
  },
  {
    label: 'Analista de Endomarketing',
    value: 'analista_de_endomarketing',
  },
  {
    label: 'Analista de Engenharia de Produto',
    value: 'analista_de_engenharia_de_produto',
  },
  {
    label: 'Analista de Engenharia',
    value: 'analista_de_engenharia',
  },
  {
    label: 'Analista de Estoque',
    value: 'analista_de_estoque',
  },
  {
    label: 'Analista de Estratégia de Mercado',
    value: 'analista_de_estrategia_de_mercado',
  },
  {
    label: 'Analista de Eventos',
    value: 'analista_de_eventos',
  },
  {
    label: 'Analista de Expatriados',
    value: 'analista_de_expatriados',
  },
  {
    label: 'Analista de Expedição',
    value: 'analista_de_expedicao',
  },
  {
    label: 'Analista de Facilities',
    value: 'analista_de_facilities',
  },
  {
    label: 'Analista de Farmacovigilância',
    value: 'analista_de_farmacovigilancia',
  },
  {
    label: 'Analista de Faturamento',
    value: 'analista_de_faturamento',
  },
  {
    label: 'Analista de Folha de Pagamento',
    value: 'analista_de_folha_de_pagamento',
  },
  {
    label: 'Analista de Franquias',
    value: 'analista_de_franquias',
  },
  {
    label: 'Analista de Frota',
    value: 'analista_de_frota',
  },
  {
    label: 'Analista de Fusões E Aquisições',
    value: 'analista_de_fusoes_e_aquisicoes',
  },
  {
    label: 'Analista de Garantia Da Qualidade',
    value: 'analista_de_garantia_da_qualidade',
  },
  {
    label: 'Analista de Garantia',
    value: 'analista_de_garantia',
  },
  {
    label: 'Analista de Geoprocessamento',
    value: 'analista_de_geoprocessamento',
  },
  {
    label: 'Analista de Gestão de Incidentes',
    value: 'analista_de_gestao_de_incidentes',
  },
  {
    label: 'Analista de Governança de Ti',
    value: 'analista_de_governanca_de_ti',
  },
  {
    label: 'Analista de Help Desk',
    value: 'analista_de_help_desk',
  },
  {
    label: 'Analista de Implantação',
    value: 'analista_de_implantacao',
  },
  {
    label: 'Analista de Incorporação',
    value: 'analista_de_incorporacao',
  },
  {
    label: 'Analista de Indicadores',
    value: 'analista_de_indicadores',
  },
  {
    label: 'Analista de Informação',
    value: 'analista_de_informacao',
  },
  {
    label: 'Analista de Informações Gerenciais',
    value: 'analista_de_informacoes_gerenciais',
  },
  {
    label: 'Analista de Infraestrutura',
    value: 'analista_de_infraestrutura',
  },
  {
    label: 'Analista de Inteligência Comercial',
    value: 'analista_de_inteligencia_comercial',
  },
  {
    label: 'Analista de Inteligência de Mercado',
    value: 'analista_de_inteligencia_de_mercado',
  },
  {
    label: 'Analista de Inventário',
    value: 'analista_de_inventario',
  },
  {
    label: 'Analista de Investimentos',
    value: 'analista_de_investimentos',
  },
  {
    label: 'Analista de Laboratório',
    value: 'analista_de_laboratorio',
  },
  {
    label: 'Analista de Legalização',
    value: 'analista_de_legalizacao',
  },
  {
    label: 'Analista de Licitação',
    value: 'analista_de_licitacao',
  },
  {
    label: 'Analista de Logística',
    value: 'analista_de_logistica',
  },
  {
    label: 'Analista de Manutenção',
    value: 'analista_de_manutencao',
  },
  {
    label: 'Analista de Marcas E Patentes',
    value: 'analista_de_marcas_e_patentes',
  },
  {
    label: 'Analista de Marketing Digital',
    value: 'analista_de_marketing_digital',
  },
  {
    label: 'Analista de Marketing Online',
    value: 'analista_de_marketing_online',
  },
  {
    label: 'Analista de Marketing',
    value: 'analista_de_marketing',
  },
  {
    label: 'Analista de Melhoria Contínua',
    value: 'analista_de_melhoria_continua',
  },
  {
    label: 'Analista de Mercado',
    value: 'analista_de_mercado',
  },
  {
    label: 'Analista de Merchandising',
    value: 'analista_de_merchandising',
  },
  {
    label: 'Analista de Mis',
    value: 'analista_de_mis',
  },
  {
    label: 'Analista de Monitoramento',
    value: 'analista_de_monitoramento',
  },
  {
    label: 'Analista de Métricas',
    value: 'analista_de_metricas',
  },
  {
    label: 'Analista de Mídia Online',
    value: 'analista_de_midia_online',
  },
  {
    label: 'Analista de Mídia',
    value: 'analista_de_midia',
  },
  {
    label: 'Analista de Mídias Sociais',
    value: 'analista_de_midias_sociais',
  },
  {
    label: 'Analista de Negócios de Ti',
    value: 'analista_de_negocios_de_ti',
  },
  {
    label: 'Analista de Negócios',
    value: 'analista_de_negocios',
  },
  {
    label: 'Analista de Operações',
    value: 'analista_de_operacoes',
  },
  {
    label: 'Analista de Outsourcing',
    value: 'analista_de_outsourcing',
  },
  {
    label: 'Analista de Ouvidoria',
    value: 'analista_de_ouvidoria',
  },
  {
    label: 'Analista de Patrimônio',
    value: 'analista_de_patrimonio',
  },
  {
    label: 'Analista de Pcm',
    value: 'analista_de_pcm',
  },
  {
    label: 'Analista de Pcp',
    value: 'analista_de_pcp',
  },
  {
    label: 'Analista de Pesquisa de Mercado',
    value: 'analista_de_pesquisa_de_mercado',
  },
  {
    label: 'Analista de Planejamento Estratégico',
    value: 'analista_de_planejamento_estrategico',
  },
  {
    label: 'Analista de Planejamento Financeiro',
    value: 'analista_de_planejamento_financeiro',
  },
  {
    label: 'Analista de Planejamento',
    value: 'analista_de_planejamento',
  },
  {
    label: 'Analista de Prevenção de Perdas',
    value: 'analista_de_prevencao_de_perdas',
  },
  {
    label: 'Analista de Pricing',
    value: 'analista_de_pricing',
  },
  {
    label: 'Analista de Processos E Qualidade',
    value: 'analista_de_processos_e_qualidade',
  },
  {
    label: 'Analista de Processos Gerenciais',
    value: 'analista_de_processos_gerenciais',
  },
  {
    label: 'Analista de Processos Industriais',
    value: 'analista_de_processos_industriais',
  },
  {
    label: 'Analista de Processos',
    value: 'analista_de_processos',
  },
  {
    label: 'Analista de Produto',
    value: 'analista_de_produto',
  },
  {
    label: 'Analista de Produção',
    value: 'analista_de_producao',
  },
  {
    label: 'Analista de Projetos',
    value: 'analista_de_projetos',
  },
  {
    label: 'Analista de Publicidade E Propaganda',
    value: 'analista_de_publicidade_e_propaganda',
  },
  {
    label: 'Analista de Pós-venda',
    value: 'analista_de_posvenda',
  },
  {
    label: 'Analista de Qsms',
    value: 'analista_de_qsms',
  },
  {
    label: 'Analista de Qualidade',
    value: 'analista_de_qualidade',
  },
  {
    label: 'Analista de Recrutamento E Seleção',
    value: 'analista_de_recrutamento_e_selecao',
  },
  {
    label: 'Analista de Recursos Humanos',
    value: 'analista_de_recursos_humanos',
  },
  {
    label: 'Analista de Redes Sociais',
    value: 'analista_de_redes_sociais',
  },
  {
    label: 'Analista de Redes',
    value: 'analista_de_redes',
  },
  {
    label: 'Analista de Relacionamento',
    value: 'analista_de_relacionamento',
  },
  {
    label: 'Analista de Relatórios',
    value: 'analista_de_relatorios',
  },
  {
    label: 'Analista de Relações Institucionais',
    value: 'analista_de_relacoes_institucionais',
  },
  {
    label: 'Analista de Relações Internacionais',
    value: 'analista_de_relacoes_internacionais',
  },
  {
    label: 'Analista de Relações Trabalhistas',
    value: 'analista_de_relacoes_trabalhistas',
  },
  {
    label: 'Analista de Remuneração',
    value: 'analista_de_remuneracao',
  },
  {
    label: 'Analista de Requisitos',
    value: 'analista_de_requisitos',
  },
  {
    label: 'Analista de Responsabilidade Social',
    value: 'analista_de_responsabilidade_social',
  },
  {
    label: 'Analista de Retenção',
    value: 'analista_de_retencao',
  },
  {
    label: 'Analista de Risco',
    value: 'analista_de_risco',
  },
  {
    label: 'Analista de Sac',
    value: 'analista_de_sac',
  },
  {
    label: 'Analista de Segurança Da Informação',
    value: 'analista_de_seguranca_da_informacao',
  },
  {
    label: 'Analista de Segurança Do Trabalho',
    value: 'analista_de_seguranca_do_trabalho',
  },
  {
    label: 'Analista de Segurança Patrimonial',
    value: 'analista_de_seguranca_patrimonial',
  },
  {
    label: 'Analista de Seguros',
    value: 'analista_de_seguros',
  },
  {
    label: 'Analista de Sem',
    value: 'analista_de_sem',
  },
  {
    label: 'Analista de Seo',
    value: 'analista_de_seo',
  },
  {
    label: 'Analista de Service Desk',
    value: 'analista_de_service_desk',
  },
  {
    label: 'Analista de Sinistro',
    value: 'analista_de_sinistro',
  },
  {
    label: 'Analista de Sistema de Gestão Integrada',
    value: 'analista_de_sistema_de_gestao_integrada',
  },
  {
    label: 'Analista de Sistemas .net',
    value: 'analista_de_sistemas_net',
  },
  {
    label: 'Analista de Sistemas Cobol',
    value: 'analista_de_sistemas_cobol',
  },
  {
    label: 'Analista de Sistemas Delphi',
    value: 'analista_de_sistemas_delphi',
  },
  {
    label: 'Analista de Sistemas Erp',
    value: 'analista_de_sistemas_erp',
  },
  {
    label: 'Analista de Sistemas Java',
    value: 'analista_de_sistemas_java',
  },
  {
    label: 'Analista de Sistemas Linux',
    value: 'analista_de_sistemas_linux',
  },
  {
    label: 'Analista de Sistemas Mainframe',
    value: 'analista_de_sistemas_mainframe',
  },
  {
    label: 'Analista de Sistemas Microsiga',
    value: 'analista_de_sistemas_microsiga',
  },
  {
    label: 'Analista de Sistemas Oracle',
    value: 'analista_de_sistemas_oracle',
  },
  {
    label: 'Analista de Sistemas Php',
    value: 'analista_de_sistemas_php',
  },
  {
    label: 'Analista de Sistemas Pl Sql',
    value: 'analista_de_sistemas_pl_sql',
  },
  {
    label: 'Analista de Sistemas Sharepoint',
    value: 'analista_de_sistemas_sharepoint',
  },
  {
    label: 'Analista de Sistemas Sql',
    value: 'analista_de_sistemas_sql',
  },
  {
    label: 'Analista de Sistemas Web',
    value: 'analista_de_sistemas_web',
  },
  {
    label: 'Analista de Sistemas',
    value: 'analista_de_sistemas',
  },
  {
    label: 'Analista de Subscrição',
    value: 'analista_de_subscricao',
  },
  {
    label: 'Analista de Suporte Mainframe',
    value: 'analista_de_suporte_mainframe',
  },
  {
    label: 'Analista de Suporte Middleware',
    value: 'analista_de_suporte_middleware',
  },
  {
    label: 'Analista de Suporte Técnico',
    value: 'analista_de_suporte_tecnico',
  },
  {
    label: 'Analista de Suporte Websphere',
    value: 'analista_de_suporte_websphere',
  },
  {
    label: 'Analista de Suprimentos',
    value: 'analista_de_suprimentos',
  },
  {
    label: 'Analista de Sustentabilidade',
    value: 'analista_de_sustentabilidade',
  },
  {
    label: 'Analista de Tecnologia Da Informação',
    value: 'analista_de_tecnologia_da_informacao',
  },
  {
    label: 'Analista de Telecomunicações',
    value: 'analista_de_telecomunicacoes',
  },
  {
    label: 'Analista de Tesouraria',
    value: 'analista_de_tesouraria',
  },
  {
    label: 'Analista de Testes',
    value: 'analista_de_testes',
  },
  {
    label: 'Analista de Trade Marketing',
    value: 'analista_de_trade_marketing',
  },
  {
    label: 'Analista de Transportes',
    value: 'analista_de_transportes',
  },
  {
    label: 'Analista de Treinamento E Desenvolvimento',
    value: 'analista_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Analista de Usabilidade',
    value: 'analista_de_usabilidade',
  },
  {
    label: 'Analista de Validação',
    value: 'analista_de_validacao',
  },
  {
    label: 'Analista de Vendas',
    value: 'analista_de_vendas',
  },
  {
    label: 'Analista de Viagens',
    value: 'analista_de_viagens',
  },
  {
    label: 'Analista de Visual Merchandising',
    value: 'analista_de_visual_merchandising',
  },
  {
    label: 'Analista de Web Analytics',
    value: 'analista_de_web_analytics',
  },
  {
    label: 'Analista de Web Design',
    value: 'analista_de_web_design',
  },
  {
    label: 'Analista Desenvolvedor Mobile',
    value: 'analista_desenvolvedor_mobile',
  },
  {
    label: 'Analista Econômico Financeiro',
    value: 'analista_economico_financeiro',
  },
  {
    label: 'Analista Financeiro',
    value: 'analista_financeiro',
  },
  {
    label: 'Analista Fiscal',
    value: 'analista_fiscal',
  },
  {
    label: 'Analista Funcional',
    value: 'analista_funcional',
  },
  {
    label: 'Analista Industrial',
    value: 'analista_industrial',
  },
  {
    label: 'Analista Jurídico',
    value: 'analista_juridico',
  },
  {
    label: 'Analista Pedagógico',
    value: 'analista_pedagogico',
  },
  {
    label: 'Analista Planejamento E Orçamento',
    value: 'analista_planejamento_e_orcamento',
  },
  {
    label: 'Analista Programador .net',
    value: 'analista_programador_net',
  },
  {
    label: 'Analista Programador Advpl',
    value: 'analista_programador_advpl',
  },
  {
    label: 'Analista Programador Android',
    value: 'analista_programador_android',
  },
  {
    label: 'Analista Programador Asp.net',
    value: 'analista_programador_aspnet',
  },
  {
    label: 'Analista Programador C#',
    value: 'analista_programador_c#',
  },
  {
    label: 'Analista Programador C++',
    value: 'analista_programador_c++',
  },
  {
    label: 'Analista Programador Cobol',
    value: 'analista_programador_cobol',
  },
  {
    label: 'Analista Programador Delphi',
    value: 'analista_programador_delphi',
  },
  {
    label: 'Analista Programador Front-end',
    value: 'analista_programador_frontend',
  },
  {
    label: 'Analista Programador Java',
    value: 'analista_programador_java',
  },
  {
    label: 'Analista Programador Mainframe',
    value: 'analista_programador_mainframe',
  },
  {
    label: 'Analista Programador Oracle',
    value: 'analista_programador_oracle',
  },
  {
    label: 'Analista Programador Php',
    value: 'analista_programador_php',
  },
  {
    label: 'Analista Programador Pl Sql',
    value: 'analista_programador_pl_sql',
  },
  {
    label: 'Analista Programador Progress',
    value: 'analista_programador_progress',
  },
  {
    label: 'Analista Programador Protheus',
    value: 'analista_programador_protheus',
  },
  {
    label: 'Analista Programador Sharepoint',
    value: 'analista_programador_sharepoint',
  },
  {
    label: 'Analista Programador Sql',
    value: 'analista_programador_sql',
  },
  {
    label: 'Analista Programador Vb6',
    value: 'analista_programador_vb6',
  },
  {
    label: 'Analista Programador Web',
    value: 'analista_programador_web',
  },
  {
    label: 'Analista Programador Websphere',
    value: 'analista_programador_websphere',
  },
  {
    label: 'Analista Programador',
    value: 'analista_programador',
  },
  {
    label: 'Analista Químico',
    value: 'analista_quimico',
  },
  {
    label: 'Analista Sap',
    value: 'analista_sap',
  },
  {
    label: 'Analista Soa',
    value: 'analista_soa',
  },
  {
    label: 'Analista Tributário',
    value: 'analista_tributario',
  },
  {
    label: 'Analista Técnico',
    value: 'analista_tecnico',
  },
  {
    label: 'Animador de Festas',
    value: 'animador_de_festas',
  },
  {
    label: 'Animador Digital',
    value: 'animador_digital',
  },
  {
    label: 'Antropólogo',
    value: 'antropologo',
  },
  {
    label: 'Apontador de Obras',
    value: 'apontador_de_obras',
  },
  {
    label: 'Apontador de Produção',
    value: 'apontador_de_producao',
  },
  {
    label: 'Aprendiz Na Área Administrativa',
    value: 'aprendiz_na_area_administrativa',
  },
  {
    label: 'Aprendiz Na Área Comercial',
    value: 'aprendiz_na_area_comercial',
  },
  {
    label: 'Aprendiz Na Área Contábil',
    value: 'aprendiz_na_area_contabil',
  },
  {
    label: 'Aprendiz Na Área de Atendimento',
    value: 'aprendiz_na_area_de_atendimento',
  },
  {
    label: 'Aprendiz Na Área de Cobrança',
    value: 'aprendiz_na_area_de_cobranca',
  },
  {
    label: 'Aprendiz Na Área de Construção Civil',
    value: 'aprendiz_na_area_de_construcao_civil',
  },
  {
    label: 'Aprendiz Na Área de Cozinha',
    value: 'aprendiz_na_area_de_cozinha',
  },
  {
    label: 'Aprendiz Na Área de Departamento Pessoal',
    value: 'aprendiz_na_area_de_departamento_pessoal',
  },
  {
    label: 'Aprendiz Na Área de Informática',
    value: 'aprendiz_na_area_de_informatica',
  },
  {
    label: 'Aprendiz Na Área de Logística',
    value: 'aprendiz_na_area_de_logistica',
  },
  {
    label: 'Aprendiz Na Área de Marketing',
    value: 'aprendiz_na_area_de_marketing',
  },
  {
    label: 'Aprendiz Na Área de Produção',
    value: 'aprendiz_na_area_de_producao',
  },
  {
    label: 'Aprendiz Na Área de Recepção',
    value: 'aprendiz_na_area_de_recepcao',
  },
  {
    label: 'Aprendiz Na Área de Recursos Humanos',
    value: 'aprendiz_na_area_de_recursos_humanos',
  },
  {
    label: 'Aprendiz Na Área de Vendas',
    value: 'aprendiz_na_area_de_vendas',
  },
  {
    label: 'Aprendiz Na Área Elétrica',
    value: 'aprendiz_na_area_eletrica',
  },
  {
    label: 'Aprendiz Na Área Financeira',
    value: 'aprendiz_na_area_financeira',
  },
  {
    label: 'Aprendiz Na Área Jurídica',
    value: 'aprendiz_na_area_juridica',
  },
  {
    label: 'Aprendiz Na Área Mecânica',
    value: 'aprendiz_na_area_mecanica',
  },
  {
    label: 'Aprendiz Na Área Operacional',
    value: 'aprendiz_na_area_operacional',
  },
  {
    label: 'Aprendiz',
    value: 'aprendiz',
  },
  {
    label: 'Apresentador de Programa de Televisão',
    value: 'apresentador_de_programa_de_televisao',
  },
  {
    label: 'Aquarista',
    value: 'aquarista',
  },
  {
    label: 'Armador',
    value: 'armador',
  },
  {
    label: 'Armazenista',
    value: 'armazenista',
  },
  {
    label: 'Arqueólogo',
    value: 'arqueologo',
  },
  {
    label: 'Arquiteto .net',
    value: 'arquiteto_net',
  },
  {
    label: 'Arquiteto Coordenador',
    value: 'arquiteto_coordenador',
  },
  {
    label: 'Arquiteto de Edificações',
    value: 'arquiteto_de_edificacoes',
  },
  {
    label: 'Arquiteto de Informação',
    value: 'arquiteto_de_informacao',
  },
  {
    label: 'Arquiteto de Interiores',
    value: 'arquiteto_de_interiores',
  },
  {
    label: 'Arquiteto de Sistemas',
    value: 'arquiteto_de_sistemas',
  },
  {
    label: 'Arquiteto de Soluções',
    value: 'arquiteto_de_solucoes',
  },
  {
    label: 'Arquiteto Especificador',
    value: 'arquiteto_especificador',
  },
  {
    label: 'Arquiteto Java',
    value: 'arquiteto_java',
  },
  {
    label: 'Arquiteto Paisagista',
    value: 'arquiteto_paisagista',
  },
  {
    label: 'Arquiteto Urbanista',
    value: 'arquiteto_urbanista',
  },
  {
    label: 'Arquiteto Vendedor',
    value: 'arquiteto_vendedor',
  },
  {
    label: 'Arquiteto',
    value: 'arquiteto',
  },
  {
    label: 'Arquivista',
    value: 'arquivista',
  },
  {
    label: 'Arrematador',
    value: 'arrematador',
  },
  {
    label: 'Arte-finalista',
    value: 'artefinalista',
  },
  {
    label: 'Artesão',
    value: 'artesao',
  },
  {
    label: 'Articulador Social',
    value: 'articulador_social',
  },
  {
    label: 'Ascensorista',
    value: 'ascensorista',
  },
  {
    label: 'Assessor Científico',
    value: 'assessor_cientifico',
  },
  {
    label: 'Assessor de Comunicação',
    value: 'assessor_de_comunicacao',
  },
  {
    label: 'Assessor de Imprensa',
    value: 'assessor_de_imprensa',
  },
  {
    label: 'Assessor de Investimentos',
    value: 'assessor_de_investimentos',
  },
  {
    label: 'Assessor de Marketing',
    value: 'assessor_de_marketing',
  },
  {
    label: 'Assessor Jurídico',
    value: 'assessor_juridico',
  },
  {
    label: 'Assistente Administrativo de Manutenção',
    value: 'assistente_administrativo_de_manutencao',
  },
  {
    label: 'Assistente Administrativo',
    value: 'assistente_administrativo',
  },
  {
    label: 'Assistente Comercial',
    value: 'assistente_comercial',
  },
  {
    label: 'Assistente Contábil',
    value: 'assistente_contabil',
  },
  {
    label: 'Assistente de Alimentos E Bebidas',
    value: 'assistente_de_alimentos_e_bebidas',
  },
  {
    label: 'Assistente de Almoxarifado',
    value: 'assistente_de_almoxarifado',
  },
  {
    label: 'Assistente de Arquitetura',
    value: 'assistente_de_arquitetura',
  },
  {
    label: 'Assistente de Arquivo',
    value: 'assistente_de_arquivo',
  },
  {
    label: 'Assistente de Arte',
    value: 'assistente_de_arte',
  },
  {
    label: 'Assistente de Assuntos Regulatórios',
    value: 'assistente_de_assuntos_regulatorios',
  },
  {
    label: 'Assistente de Atendimento Publicitário',
    value: 'assistente_de_atendimento_publicitario',
  },
  {
    label: 'Assistente de Atendimento',
    value: 'assistente_de_atendimento',
  },
  {
    label: 'Assistente de Ativo Fixo',
    value: 'assistente_de_ativo_fixo',
  },
  {
    label: 'Assistente de Auditoria Contábil',
    value: 'assistente_de_auditoria_contabil',
  },
  {
    label: 'Assistente de Auditoria',
    value: 'assistente_de_auditoria',
  },
  {
    label: 'Assistente de Back Office',
    value: 'assistente_de_back_office',
  },
  {
    label: 'Assistente de Benefícios',
    value: 'assistente_de_beneficios',
  },
  {
    label: 'Assistente de Cadastro',
    value: 'assistente_de_cadastro',
  },
  {
    label: 'Assistente de Cargos E Salários',
    value: 'assistente_de_cargos_e_salarios',
  },
  {
    label: 'Assistente de Cobrança',
    value: 'assistente_de_cobranca',
  },
  {
    label: 'Assistente de Compliance',
    value: 'assistente_de_compliance',
  },
  {
    label: 'Assistente de Compras',
    value: 'assistente_de_compras',
  },
  {
    label: 'Assistente de Comunicação E Marketing',
    value: 'assistente_de_comunicacao_e_marketing',
  },
  {
    label: 'Assistente de Comunicação',
    value: 'assistente_de_comunicacao',
  },
  {
    label: 'Assistente de Comércio Exterior',
    value: 'assistente_de_comercio_exterior',
  },
  {
    label: 'Assistente de Condomínio',
    value: 'assistente_de_condominio',
  },
  {
    label: 'Assistente de Contas A Pagar E Receber',
    value: 'assistente_de_contas_a_pagar_e_receber',
  },
  {
    label: 'Assistente de Contas A Pagar',
    value: 'assistente_de_contas_a_pagar',
  },
  {
    label: 'Assistente de Contas A Receber',
    value: 'assistente_de_contas_a_receber',
  },
  {
    label: 'Assistente de Contas Médicas',
    value: 'assistente_de_contas_medicas',
  },
  {
    label: 'Assistente de Conteúdo Web',
    value: 'assistente_de_conteudo_web',
  },
  {
    label: 'Assistente de Contratos',
    value: 'assistente_de_contratos',
  },
  {
    label: 'Assistente de Control Desk',
    value: 'assistente_de_control_desk',
  },
  {
    label: 'Assistente de Controladoria',
    value: 'assistente_de_controladoria',
  },
  {
    label: 'Assistente de Controle de Qualidade',
    value: 'assistente_de_controle_de_qualidade',
  },
  {
    label: 'Assistente de Controles Internos',
    value: 'assistente_de_controles_internos',
  },
  {
    label: 'Assistente de Credenciamento',
    value: 'assistente_de_credenciamento',
  },
  {
    label: 'Assistente de Criação',
    value: 'assistente_de_criacao',
  },
  {
    label: 'Assistente de Crm',
    value: 'assistente_de_crm',
  },
  {
    label: 'Assistente de Crédito E Cobrança',
    value: 'assistente_de_credito_e_cobranca',
  },
  {
    label: 'Assistente de Crédito Imobiliário',
    value: 'assistente_de_credito_imobiliario',
  },
  {
    label: 'Assistente de Customer Service',
    value: 'assistente_de_customer_service',
  },
  {
    label: 'Assistente de Custos',
    value: 'assistente_de_custos',
  },
  {
    label: 'Assistente de Câmbio',
    value: 'assistente_de_cambio',
  },
  {
    label: 'Assistente de Departamento Pessoal',
    value: 'assistente_de_departamento_pessoal',
  },
  {
    label: 'Assistente de Desembaraço Aduaneiro',
    value: 'assistente_de_desembaraco_aduaneiro',
  },
  {
    label: 'Assistente de Design Gráfico',
    value: 'assistente_de_design_grafico',
  },
  {
    label: 'Assistente de Design',
    value: 'assistente_de_design',
  },
  {
    label: 'Assistente de Diretoria',
    value: 'assistente_de_diretoria',
  },
  {
    label: 'Assistente de Documentação',
    value: 'assistente_de_documentacao',
  },
  {
    label: 'Assistente de E-commerce',
    value: 'assistente_de_ecommerce',
  },
  {
    label: 'Assistente de Endomarketing',
    value: 'assistente_de_endomarketing',
  },
  {
    label: 'Assistente de Engenharia',
    value: 'assistente_de_engenharia',
  },
  {
    label: 'Assistente de Estilo',
    value: 'assistente_de_estilo',
  },
  {
    label: 'Assistente de Estoque',
    value: 'assistente_de_estoque',
  },
  {
    label: 'Assistente de Eventos',
    value: 'assistente_de_eventos',
  },
  {
    label: 'Assistente de Expedição',
    value: 'assistente_de_expedicao',
  },
  {
    label: 'Assistente de Facilities',
    value: 'assistente_de_facilities',
  },
  {
    label: 'Assistente de Faturamento Hospitalar',
    value: 'assistente_de_faturamento_hospitalar',
  },
  {
    label: 'Assistente de Faturamento',
    value: 'assistente_de_faturamento',
  },
  {
    label: 'Assistente de Fotógrafo',
    value: 'assistente_de_fotografo',
  },
  {
    label: 'Assistente de Franquias',
    value: 'assistente_de_franquias',
  },
  {
    label: 'Assistente de Frota',
    value: 'assistente_de_frota',
  },
  {
    label: 'Assistente de Garantia Da Qualidade',
    value: 'assistente_de_garantia_da_qualidade',
  },
  {
    label: 'Assistente de Governança',
    value: 'assistente_de_governanca',
  },
  {
    label: 'Assistente de Implantação',
    value: 'assistente_de_implantacao',
  },
  {
    label: 'Assistente de Informática',
    value: 'assistente_de_informatica',
  },
  {
    label: 'Assistente de Infraestrutura',
    value: 'assistente_de_infraestrutura',
  },
  {
    label: 'Assistente de Inteligência de Mercado',
    value: 'assistente_de_inteligencia_de_mercado',
  },
  {
    label: 'Assistente de Inventário',
    value: 'assistente_de_inventario',
  },
  {
    label: 'Assistente de Legalização',
    value: 'assistente_de_legalizacao',
  },
  {
    label: 'Assistente de Licitação',
    value: 'assistente_de_licitacao',
  },
  {
    label: 'Assistente de Locação',
    value: 'assistente_de_locacao',
  },
  {
    label: 'Assistente de Logística',
    value: 'assistente_de_logistica',
  },
  {
    label: 'Assistente de Loja',
    value: 'assistente_de_loja',
  },
  {
    label: 'Assistente de Manutenção Predial',
    value: 'assistente_de_manutencao_predial',
  },
  {
    label: 'Assistente de Marketing Digital',
    value: 'assistente_de_marketing_digital',
  },
  {
    label: 'Assistente de Marketing',
    value: 'assistente_de_marketing',
  },
  {
    label: 'Assistente de Meio Ambiente',
    value: 'assistente_de_meio_ambiente',
  },
  {
    label: 'Assistente de Merchandising',
    value: 'assistente_de_merchandising',
  },
  {
    label: 'Assistente de Modelagem',
    value: 'assistente_de_modelagem',
  },
  {
    label: 'Assistente de Monitoramento',
    value: 'assistente_de_monitoramento',
  },
  {
    label: 'Assistente de Mídia',
    value: 'assistente_de_midia',
  },
  {
    label: 'Assistente de Mídias Sociais',
    value: 'assistente_de_midias_sociais',
  },
  {
    label: 'Assistente de Negócios',
    value: 'assistente_de_negocios',
  },
  {
    label: 'Assistente de Nutrição',
    value: 'assistente_de_nutricao',
  },
  {
    label: 'Assistente de Orçamento',
    value: 'assistente_de_orcamento',
  },
  {
    label: 'Assistente de Ouvidoria',
    value: 'assistente_de_ouvidoria',
  },
  {
    label: 'Assistente de Patrimônio',
    value: 'assistente_de_patrimonio',
  },
  {
    label: 'Assistente de Pcp',
    value: 'assistente_de_pcp',
  },
  {
    label: 'Assistente de Pesquisa de Mercado',
    value: 'assistente_de_pesquisa_de_mercado',
  },
  {
    label: 'Assistente de Pesquisa E Desenvolvimento',
    value: 'assistente_de_pesquisa_e_desenvolvimento',
  },
  {
    label: 'Assistente de Pesquisa',
    value: 'assistente_de_pesquisa',
  },
  {
    label: 'Assistente de Planejamento',
    value: 'assistente_de_planejamento',
  },
  {
    label: 'Assistente de Prevenção de Perdas',
    value: 'assistente_de_prevencao_de_perdas',
  },
  {
    label: 'Assistente de Pricing',
    value: 'assistente_de_pricing',
  },
  {
    label: 'Assistente de Processamento de Dados',
    value: 'assistente_de_processamento_de_dados',
  },
  {
    label: 'Assistente de Processos',
    value: 'assistente_de_processos',
  },
  {
    label: 'Assistente de Produto',
    value: 'assistente_de_produto',
  },
  {
    label: 'Assistente de Produção Editorial',
    value: 'assistente_de_producao_editorial',
  },
  {
    label: 'Assistente de Produção',
    value: 'assistente_de_producao',
  },
  {
    label: 'Assistente de Programação',
    value: 'assistente_de_programacao',
  },
  {
    label: 'Assistente de Projetos',
    value: 'assistente_de_projetos',
  },
  {
    label: 'Assistente de Publicidade E Propaganda',
    value: 'assistente_de_publicidade_e_propaganda',
  },
  {
    label: 'Assistente de Pós-venda',
    value: 'assistente_de_posvenda',
  },
  {
    label: 'Assistente de Rastreamento',
    value: 'assistente_de_rastreamento',
  },
  {
    label: 'Assistente de Recebimento Fiscal',
    value: 'assistente_de_recebimento_fiscal',
  },
  {
    label: 'Assistente de Recrutamento E Seleção',
    value: 'assistente_de_recrutamento_e_selecao',
  },
  {
    label: 'Assistente de Recursos Humanos',
    value: 'assistente_de_recursos_humanos',
  },
  {
    label: 'Assistente de Relacionamento',
    value: 'assistente_de_relacionamento',
  },
  {
    label: 'Assistente de Relações Públicas',
    value: 'assistente_de_relacoes_publicas',
  },
  {
    label: 'Assistente de Repartição Pública',
    value: 'assistente_de_reparticao_publica',
  },
  {
    label: 'Assistente de Reservas',
    value: 'assistente_de_reservas',
  },
  {
    label: 'Assistente de Risco',
    value: 'assistente_de_risco',
  },
  {
    label: 'Assistente de Sac',
    value: 'assistente_de_sac',
  },
  {
    label: 'Assistente de Secretaria Escolar',
    value: 'assistente_de_secretaria_escolar',
  },
  {
    label: 'Assistente de Secretaria',
    value: 'assistente_de_secretaria',
  },
  {
    label: 'Assistente de Segurança Da Informação',
    value: 'assistente_de_seguranca_da_informacao',
  },
  {
    label: 'Assistente de Segurança Do Trabalho',
    value: 'assistente_de_seguranca_do_trabalho',
  },
  {
    label: 'Assistente de Segurança Patrimonial',
    value: 'assistente_de_seguranca_patrimonial',
  },
  {
    label: 'Assistente de Seguros',
    value: 'assistente_de_seguros',
  },
  {
    label: 'Assistente de Service Desk',
    value: 'assistente_de_service_desk',
  },
  {
    label: 'Assistente de Sinistro',
    value: 'assistente_de_sinistro',
  },
  {
    label: 'Assistente de Sistemas',
    value: 'assistente_de_sistemas',
  },
  {
    label: 'Assistente de Supervisão',
    value: 'assistente_de_supervisao',
  },
  {
    label: 'Assistente de Suporte Técnico',
    value: 'assistente_de_suporte_tecnico',
  },
  {
    label: 'Assistente de Suprimentos',
    value: 'assistente_de_suprimentos',
  },
  {
    label: 'Assistente de Tecnologia Da Informação',
    value: 'assistente_de_tecnologia_da_informacao',
  },
  {
    label: 'Assistente de Telecomunicações',
    value: 'assistente_de_telecomunicacoes',
  },
  {
    label: 'Assistente de Telemarketing',
    value: 'assistente_de_telemarketing',
  },
  {
    label: 'Assistente de Televendas',
    value: 'assistente_de_televendas',
  },
  {
    label: 'Assistente de Tesouraria',
    value: 'assistente_de_tesouraria',
  },
  {
    label: 'Assistente de Testes',
    value: 'assistente_de_testes',
  },
  {
    label: 'Assistente de Trade Marketing',
    value: 'assistente_de_trade_marketing',
  },
  {
    label: 'Assistente de Transportes',
    value: 'assistente_de_transportes',
  },
  {
    label: 'Assistente de Treinamento E Desenvolvimento',
    value: 'assistente_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Assistente de Vendas',
    value: 'assistente_de_vendas',
  },
  {
    label: 'Assistente de Visual Merchandising',
    value: 'assistente_de_visual_merchandising',
  },
  {
    label: 'Assistente de Web Design',
    value: 'assistente_de_web_design',
  },
  {
    label: 'Assistente Editorial',
    value: 'assistente_editorial',
  },
  {
    label: 'Assistente Executivo',
    value: 'assistente_executivo',
  },
  {
    label: 'Assistente Financeiro',
    value: 'assistente_financeiro',
  },
  {
    label: 'Assistente Fiscal',
    value: 'assistente_fiscal',
  },
  {
    label: 'Assistente Industrial',
    value: 'assistente_industrial',
  },
  {
    label: 'Assistente Jurídico',
    value: 'assistente_juridico',
  },
  {
    label: 'Assistente Operacional',
    value: 'assistente_operacional',
  },
  {
    label: 'Assistente Paralegal',
    value: 'assistente_paralegal',
  },
  {
    label: 'Assistente Pedagógico',
    value: 'assistente_pedagogico',
  },
  {
    label: 'Assistente Social',
    value: 'assistente_social',
  },
  {
    label: 'Assistente Societário',
    value: 'assistente_societario',
  },
  {
    label: 'Assistente Tributário',
    value: 'assistente_tributario',
  },
  {
    label: 'Assistente Técnico de Campo',
    value: 'assistente_tecnico_de_campo',
  },
  {
    label: 'Assistente Técnico de Edificações',
    value: 'assistente_tecnico_de_edificacoes',
  },
  {
    label: 'Assistente Técnico de Vendas',
    value: 'assistente_tecnico_de_vendas',
  },
  {
    label: 'Assistente Técnico em Eletrônica',
    value: 'assistente_tecnico_em_eletronica',
  },
  {
    label: 'Assistente Técnico',
    value: 'assistente_tecnico',
  },
  {
    label: 'Assoalhador',
    value: 'assoalhador',
  },
  {
    label: 'Atendente de Assistência Técnica',
    value: 'atendente_de_assistencia_tecnica',
  },
  {
    label: 'Atendente de Câmbio',
    value: 'atendente_de_cambio',
  },
  {
    label: 'Atendente de Delivery',
    value: 'atendente_de_delivery',
  },
  {
    label: 'Atendente de E-commerce',
    value: 'atendente_de_ecommerce',
  },
  {
    label: 'Atendente de Help Desk',
    value: 'atendente_de_help_desk',
  },
  {
    label: 'Atendente de Marketing',
    value: 'atendente_de_marketing',
  },
  {
    label: 'Atendente de Nutrição',
    value: 'atendente_de_nutricao',
  },
  {
    label: 'Atendente de Pista',
    value: 'atendente_de_pista',
  },
  {
    label: 'Atendente de Pós-venda',
    value: 'atendente_de_posvenda',
  },
  {
    label: 'Atendente de Restaurante',
    value: 'atendente_de_restaurante',
  },
  {
    label: 'Atendente de Sac',
    value: 'atendente_de_sac',
  },
  {
    label: 'Atendente de Service Desk',
    value: 'atendente_de_service_desk',
  },
  {
    label: 'Atendente de Sinistro',
    value: 'atendente_de_sinistro',
  },
  {
    label: 'Atendente de Suporte Técnico',
    value: 'atendente_de_suporte_tecnico',
  },
  {
    label: 'Atendente de Turismo',
    value: 'atendente_de_turismo',
  },
  {
    label: 'Atendente',
    value: 'atendente',
  },
  {
    label: 'Atendimento Publicitário',
    value: 'atendimento_publicitario',
  },
  {
    label: 'Ator',
    value: 'ator',
  },
  {
    label: 'Atuário',
    value: 'atuario',
  },
  {
    label: 'Auditor Contábil',
    value: 'auditor_contabil',
  },
  {
    label: 'Auditor de Contas Médicas',
    value: 'auditor_de_contas_medicas',
  },
  {
    label: 'Auditor de Estoque',
    value: 'auditor_de_estoque',
  },
  {
    label: 'Auditor de Loja',
    value: 'auditor_de_loja',
  },
  {
    label: 'Auditor de Processos',
    value: 'auditor_de_processos',
  },
  {
    label: 'Auditor de Qualidade',
    value: 'auditor_de_qualidade',
  },
  {
    label: 'Auditor de Sistemas',
    value: 'auditor_de_sistemas',
  },
  {
    label: 'Auditor Externo',
    value: 'auditor_externo',
  },
  {
    label: 'Auditor Financeiro',
    value: 'auditor_financeiro',
  },
  {
    label: 'Auditor Fiscal',
    value: 'auditor_fiscal',
  },
  {
    label: 'Auditor Interno',
    value: 'auditor_interno',
  },
  {
    label: 'Auditor Noturno',
    value: 'auditor_noturno',
  },
  {
    label: 'Auditor Trainee',
    value: 'auditor_trainee',
  },
  {
    label: 'Auditor',
    value: 'auditor',
  },
  {
    label: 'Auxiliar Administrativo',
    value: 'auxiliar_administrativo',
  },
  {
    label: 'Auxiliar Comercial',
    value: 'auxiliar_comercial',
  },
  {
    label: 'Auxiliar Contábil',
    value: 'auxiliar_contabil',
  },
  {
    label: 'Auxiliar de Acabamento Gráfico',
    value: 'auxiliar_de_acabamento_grafico',
  },
  {
    label: 'Auxiliar de Almoxarifado',
    value: 'auxiliar_de_almoxarifado',
  },
  {
    label: 'Auxiliar de Arquitetura',
    value: 'auxiliar_de_arquitetura',
  },
  {
    label: 'Auxiliar de Arquivo',
    value: 'auxiliar_de_arquivo',
  },
  {
    label: 'Auxiliar de Assistência Técnica',
    value: 'auxiliar_de_assistencia_tecnica',
  },
  {
    label: 'Auxiliar de Atendimento',
    value: 'auxiliar_de_atendimento',
  },
  {
    label: 'Auxiliar de Auditoria',
    value: 'auxiliar_de_auditoria',
  },
  {
    label: 'Auxiliar de Açougue',
    value: 'auxiliar_de_acougue',
  },
  {
    label: 'Auxiliar de Back Office',
    value: 'auxiliar_de_back_office',
  },
  {
    label: 'Auxiliar de Banco de Dados',
    value: 'auxiliar_de_banco_de_dados',
  },
  {
    label: 'Auxiliar de Banco de Sangue',
    value: 'auxiliar_de_banco_de_sangue',
  },
  {
    label: 'Auxiliar de Barman',
    value: 'auxiliar_de_barman',
  },
  {
    label: 'Auxiliar de Benefícios',
    value: 'auxiliar_de_beneficios',
  },
  {
    label: 'Auxiliar de Biblioteca',
    value: 'auxiliar_de_biblioteca',
  },
  {
    label: 'Auxiliar de Biotério',
    value: 'auxiliar_de_bioterio',
  },
  {
    label: 'Auxiliar de Bordado',
    value: 'auxiliar_de_bordado',
  },
  {
    label: 'Auxiliar de Borracharia',
    value: 'auxiliar_de_borracharia',
  },
  {
    label: 'Auxiliar de Cabeleireiro',
    value: 'auxiliar_de_cabeleireiro',
  },
  {
    label: 'Auxiliar de Campo',
    value: 'auxiliar_de_campo',
  },
  {
    label: 'Auxiliar de Carga E Descarga',
    value: 'auxiliar_de_carga_e_descarga',
  },
  {
    label: 'Auxiliar de Cartório',
    value: 'auxiliar_de_cartorio',
  },
  {
    label: 'Auxiliar de Classe',
    value: 'auxiliar_de_classe',
  },
  {
    label: 'Auxiliar de Cobrança',
    value: 'auxiliar_de_cobranca',
  },
  {
    label: 'Auxiliar de Coleta',
    value: 'auxiliar_de_coleta',
  },
  {
    label: 'Auxiliar de Compras',
    value: 'auxiliar_de_compras',
  },
  {
    label: 'Auxiliar de Comércio Exterior',
    value: 'auxiliar_de_comercio_exterior',
  },
  {
    label: 'Auxiliar de Confeitaria',
    value: 'auxiliar_de_confeitaria',
  },
  {
    label: 'Auxiliar de Contas A Pagar E Receber',
    value: 'auxiliar_de_contas_a_pagar_e_receber',
  },
  {
    label: 'Auxiliar de Contas A Pagar',
    value: 'auxiliar_de_contas_a_pagar',
  },
  {
    label: 'Auxiliar de Contas A Receber',
    value: 'auxiliar_de_contas_a_receber',
  },
  {
    label: 'Auxiliar de Contas Médicas',
    value: 'auxiliar_de_contas_medicas',
  },
  {
    label: 'Auxiliar de Contratos',
    value: 'auxiliar_de_contratos',
  },
  {
    label: 'Auxiliar de Controle de Qualidade',
    value: 'auxiliar_de_controle_de_qualidade',
  },
  {
    label: 'Auxiliar de Corte',
    value: 'auxiliar_de_corte',
  },
  {
    label: 'Auxiliar de Costura',
    value: 'auxiliar_de_costura',
  },
  {
    label: 'Auxiliar de Cozinha',
    value: 'auxiliar_de_cozinha',
  },
  {
    label: 'Auxiliar de Criação',
    value: 'auxiliar_de_criacao',
  },
  {
    label: 'Auxiliar de Crédito E Cobrança',
    value: 'auxiliar_de_credito_e_cobranca',
  },
  {
    label: 'Auxiliar de Crédito',
    value: 'auxiliar_de_credito',
  },
  {
    label: 'Auxiliar de Departamento Pessoal',
    value: 'auxiliar_de_departamento_pessoal',
  },
  {
    label: 'Auxiliar de Desenhista',
    value: 'auxiliar_de_desenhista',
  },
  {
    label: 'Auxiliar de Desenvolvimento de Produto',
    value: 'auxiliar_de_desenvolvimento_de_produto',
  },
  {
    label: 'Auxiliar de Designer Gráfico',
    value: 'auxiliar_de_designer_grafico',
  },
  {
    label: 'Auxiliar de Despachante',
    value: 'auxiliar_de_despachante',
  },
  {
    label: 'Auxiliar de E-commerce',
    value: 'auxiliar_de_ecommerce',
  },
  {
    label: 'Auxiliar de Eletricista',
    value: 'auxiliar_de_eletricista',
  },
  {
    label: 'Auxiliar de Enfermagem Do Trabalho',
    value: 'auxiliar_de_enfermagem_do_trabalho',
  },
  {
    label: 'Auxiliar de Enfermagem',
    value: 'auxiliar_de_enfermagem',
  },
  {
    label: 'Auxiliar de Engenharia Civil',
    value: 'auxiliar_de_engenharia_civil',
  },
  {
    label: 'Auxiliar de Engenharia',
    value: 'auxiliar_de_engenharia',
  },
  {
    label: 'Auxiliar de Escritório',
    value: 'auxiliar_de_escritorio',
  },
  {
    label: 'Auxiliar de Estilo',
    value: 'auxiliar_de_estilo',
  },
  {
    label: 'Auxiliar de Estoque',
    value: 'auxiliar_de_estoque',
  },
  {
    label: 'Auxiliar de Estética',
    value: 'auxiliar_de_estetica',
  },
  {
    label: 'Auxiliar de Expedição',
    value: 'auxiliar_de_expedicao',
  },
  {
    label: 'Auxiliar de Extrusão',
    value: 'auxiliar_de_extrusao',
  },
  {
    label: 'Auxiliar de Farmácia',
    value: 'auxiliar_de_farmacia',
  },
  {
    label: 'Auxiliar de Faturamento Hospitalar',
    value: 'auxiliar_de_faturamento_hospitalar',
  },
  {
    label: 'Auxiliar de Faturamento',
    value: 'auxiliar_de_faturamento',
  },
  {
    label: 'Auxiliar de Folha de Pagamento',
    value: 'auxiliar_de_folha_de_pagamento',
  },
  {
    label: 'Auxiliar de Fotografia',
    value: 'auxiliar_de_fotografia',
  },
  {
    label: 'Auxiliar de Frota',
    value: 'auxiliar_de_frota',
  },
  {
    label: 'Auxiliar de Garantia',
    value: 'auxiliar_de_garantia',
  },
  {
    label: 'Auxiliar de Governança',
    value: 'auxiliar_de_governanca',
  },
  {
    label: 'Auxiliar de Impressão',
    value: 'auxiliar_de_impressao',
  },
  {
    label: 'Auxiliar de Informática',
    value: 'auxiliar_de_informatica',
  },
  {
    label: 'Auxiliar de Infraestrutura',
    value: 'auxiliar_de_infraestrutura',
  },
  {
    label: 'Auxiliar de Instalação',
    value: 'auxiliar_de_instalacao',
  },
  {
    label: 'Auxiliar de Inventário',
    value: 'auxiliar_de_inventario',
  },
  {
    label: 'Auxiliar de Jardinagem',
    value: 'auxiliar_de_jardinagem',
  },
  {
    label: 'Auxiliar de Laboratório',
    value: 'auxiliar_de_laboratorio',
  },
  {
    label: 'Auxiliar de Lavanderia',
    value: 'auxiliar_de_lavanderia',
  },
  {
    label: 'Auxiliar de Legalização',
    value: 'auxiliar_de_legalizacao',
  },
  {
    label: 'Auxiliar de Limpeza',
    value: 'auxiliar_de_limpeza',
  },
  {
    label: 'Auxiliar de Locação',
    value: 'auxiliar_de_locacao',
  },
  {
    label: 'Auxiliar de Logística',
    value: 'auxiliar_de_logistica',
  },
  {
    label: 'Auxiliar de Loja',
    value: 'auxiliar_de_loja',
  },
  {
    label: 'Auxiliar de Manipulação',
    value: 'auxiliar_de_manipulacao',
  },
  {
    label: 'Auxiliar de Manutenção Elétrica',
    value: 'auxiliar_de_manutencao_eletrica',
  },
  {
    label: 'Auxiliar de Manutenção Hidráulica',
    value: 'auxiliar_de_manutencao_hidraulica',
  },
  {
    label: 'Auxiliar de Manutenção Industrial',
    value: 'auxiliar_de_manutencao_industrial',
  },
  {
    label: 'Auxiliar de Manutenção Mecânica',
    value: 'auxiliar_de_manutencao_mecanica',
  },
  {
    label: 'Auxiliar de Manutenção Predial',
    value: 'auxiliar_de_manutencao_predial',
  },
  {
    label: 'Auxiliar de Manutenção',
    value: 'auxiliar_de_manutencao',
  },
  {
    label: 'Auxiliar de Marketing Digital',
    value: 'auxiliar_de_marketing_digital',
  },
  {
    label: 'Auxiliar de Marketing',
    value: 'auxiliar_de_marketing',
  },
  {
    label: 'Auxiliar de Mecânico',
    value: 'auxiliar_de_mecanico',
  },
  {
    label: 'Auxiliar de Merchandising',
    value: 'auxiliar_de_merchandising',
  },
  {
    label: 'Auxiliar de Modelagem',
    value: 'auxiliar_de_modelagem',
  },
  {
    label: 'Auxiliar de Modelista',
    value: 'auxiliar_de_modelista',
  },
  {
    label: 'Auxiliar de Monitoramento',
    value: 'auxiliar_de_monitoramento',
  },
  {
    label: 'Auxiliar de Montagem',
    value: 'auxiliar_de_montagem',
  },
  {
    label: 'Auxiliar de Mídia',
    value: 'auxiliar_de_midia',
  },
  {
    label: 'Auxiliar de Oftalmologia',
    value: 'auxiliar_de_oftalmologia',
  },
  {
    label: 'Auxiliar de Orçamento',
    value: 'auxiliar_de_orcamento',
  },
  {
    label: 'Auxiliar de Ourives',
    value: 'auxiliar_de_ourives',
  },
  {
    label: 'Auxiliar de Padeiro',
    value: 'auxiliar_de_padeiro',
  },
  {
    label: 'Auxiliar de Patrimônio',
    value: 'auxiliar_de_patrimonio',
  },
  {
    label: 'Auxiliar de Pcp',
    value: 'auxiliar_de_pcp',
  },
  {
    label: 'Auxiliar de Perecíveis',
    value: 'auxiliar_de_pereciveis',
  },
  {
    label: 'Auxiliar de Pesagem',
    value: 'auxiliar_de_pesagem',
  },
  {
    label: 'Auxiliar de Pesquisa de Mercado',
    value: 'auxiliar_de_pesquisa_de_mercado',
  },
  {
    label: 'Auxiliar de Pintura',
    value: 'auxiliar_de_pintura',
  },
  {
    label: 'Auxiliar de Processos',
    value: 'auxiliar_de_processos',
  },
  {
    label: 'Auxiliar de Produção',
    value: 'auxiliar_de_producao',
  },
  {
    label: 'Auxiliar de Prótese Dentária',
    value: 'auxiliar_de_protese_dentaria',
  },
  {
    label: 'Auxiliar de Publicidade E Propaganda',
    value: 'auxiliar_de_publicidade_e_propaganda',
  },
  {
    label: 'Auxiliar de Pátio',
    value: 'auxiliar_de_patio',
  },
  {
    label: 'Auxiliar de Pós-venda',
    value: 'auxiliar_de_posvenda',
  },
  {
    label: 'Auxiliar de Qualidade',
    value: 'auxiliar_de_qualidade',
  },
  {
    label: 'Auxiliar de Rastreamento',
    value: 'auxiliar_de_rastreamento',
  },
  {
    label: 'Auxiliar de Recebimento Fiscal',
    value: 'auxiliar_de_recebimento_fiscal',
  },
  {
    label: 'Auxiliar de Recebimento',
    value: 'auxiliar_de_recebimento',
  },
  {
    label: 'Auxiliar de Recreação',
    value: 'auxiliar_de_recreacao',
  },
  {
    label: 'Auxiliar de Recrutamento E Seleção',
    value: 'auxiliar_de_recrutamento_e_selecao',
  },
  {
    label: 'Auxiliar de Recursos Humanos',
    value: 'auxiliar_de_recursos_humanos',
  },
  {
    label: 'Auxiliar de Refrigeração',
    value: 'auxiliar_de_refrigeracao',
  },
  {
    label: 'Auxiliar de Reposição',
    value: 'auxiliar_de_reposicao',
  },
  {
    label: 'Auxiliar de Restaurante',
    value: 'auxiliar_de_restaurante',
  },
  {
    label: 'Auxiliar de Saúde Bucal',
    value: 'auxiliar_de_saude_bucal',
  },
  {
    label: 'Auxiliar de Secretaria Escolar',
    value: 'auxiliar_de_secretaria_escolar',
  },
  {
    label: 'Auxiliar de Segurança Patrimonial',
    value: 'auxiliar_de_seguranca_patrimonial',
  },
  {
    label: 'Auxiliar de Seguros',
    value: 'auxiliar_de_seguros',
  },
  {
    label: 'Auxiliar de Serviços Gerais',
    value: 'auxiliar_de_servicos_gerais',
  },
  {
    label: 'Auxiliar de Sinistro',
    value: 'auxiliar_de_sinistro',
  },
  {
    label: 'Auxiliar de Soldador',
    value: 'auxiliar_de_soldador',
  },
  {
    label: 'Auxiliar de Sondagem',
    value: 'auxiliar_de_sondagem',
  },
  {
    label: 'Auxiliar de Supervisão',
    value: 'auxiliar_de_supervisao',
  },
  {
    label: 'Auxiliar de Suporte Técnico',
    value: 'auxiliar_de_suporte_tecnico',
  },
  {
    label: 'Auxiliar de Suprimentos',
    value: 'auxiliar_de_suprimentos',
  },
  {
    label: 'Auxiliar de Sushiman',
    value: 'auxiliar_de_sushiman',
  },
  {
    label: 'Auxiliar de Tecelão',
    value: 'auxiliar_de_tecelao',
  },
  {
    label: 'Auxiliar de Tecnologia Da Informação',
    value: 'auxiliar_de_tecnologia_da_informacao',
  },
  {
    label: 'Auxiliar de Televendas',
    value: 'auxiliar_de_televendas',
  },
  {
    label: 'Auxiliar de Tesouraria',
    value: 'auxiliar_de_tesouraria',
  },
  {
    label: 'Auxiliar de Topografia',
    value: 'auxiliar_de_topografia',
  },
  {
    label: 'Auxiliar de Trade Marketing',
    value: 'auxiliar_de_trade_marketing',
  },
  {
    label: 'Auxiliar de Transportes',
    value: 'auxiliar_de_transportes',
  },
  {
    label: 'Auxiliar de Treinamento E Desenvolvimento',
    value: 'auxiliar_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Auxiliar de Triagem',
    value: 'auxiliar_de_triagem',
  },
  {
    label: 'Auxiliar de Usinagem',
    value: 'auxiliar_de_usinagem',
  },
  {
    label: 'Auxiliar de Vendas',
    value: 'auxiliar_de_vendas',
  },
  {
    label: 'Auxiliar de Veterinário',
    value: 'auxiliar_de_veterinario',
  },
  {
    label: 'Auxiliar Editorial',
    value: 'auxiliar_editorial',
  },
  {
    label: 'Auxiliar Eletrotécnico',
    value: 'auxiliar_eletrotecnico',
  },
  {
    label: 'Auxiliar Financeiro',
    value: 'auxiliar_financeiro',
  },
  {
    label: 'Auxiliar Fiscal',
    value: 'auxiliar_fiscal',
  },
  {
    label: 'Auxiliar Industrial',
    value: 'auxiliar_industrial',
  },
  {
    label: 'Auxiliar Operacional',
    value: 'auxiliar_operacional',
  },
  {
    label: 'Auxiliar Pedagógico',
    value: 'auxiliar_pedagogico',
  },
  {
    label: 'Auxiliar Projetista',
    value: 'auxiliar_projetista',
  },
  {
    label: 'Auxiliar Químico',
    value: 'auxiliar_quimico',
  },
  {
    label: 'Auxiliar Tributário',
    value: 'auxiliar_tributario',
  },
  {
    label: 'Auxiliar Técnico de Mecânica',
    value: 'auxiliar_tecnico_de_mecanica',
  },
  {
    label: 'Auxiliar Técnico Eletrônico',
    value: 'auxiliar_tecnico_eletronico',
  },
  {
    label: 'Auxiliar Técnico em Edificações',
    value: 'auxiliar_tecnico_em_edificacoes',
  },
  {
    label: 'Auxiliar Técnico em Mecatrônica',
    value: 'auxiliar_tecnico_em_mecatronica',
  },
  {
    label: 'Auxiliar Técnico em Segurança Do Trabalho',
    value: 'auxiliar_tecnico_em_seguranca_do_trabalho',
  },
  {
    label: 'Auxiliar Técnico',
    value: 'auxiliar_tecnico',
  },
  {
    label: 'Avaliador de Imóveis',
    value: 'avaliador_de_imoveis',
  },
  {
    label: 'Avaliador de Veículos',
    value: 'avaliador_de_veiculos',
  },
  {
    label: 'Avaliador Físico',
    value: 'avaliador_fisico',
  },
  {
    label: 'Azulejista',
    value: 'azulejista',
  },
  {
    label: 'Açougueiro',
    value: 'acougueiro',
  },
  {
    label: 'Babá',
    value: 'baba',
  },
  {
    label: 'Back Office de Vendas',
    value: 'back_office_de_vendas',
  },
  {
    label: 'Back Office',
    value: 'back_office',
  },
  {
    label: 'Balanceiro',
    value: 'balanceiro',
  },
  {
    label: 'Balconista',
    value: 'balconista',
  },
  {
    label: 'Bamburista',
    value: 'bamburista',
  },
  {
    label: 'Barista',
    value: 'barista',
  },
  {
    label: 'Barman',
    value: 'barman',
  },
  {
    label: 'Berçarista',
    value: 'bercarista',
  },
  {
    label: 'Bibliotecário',
    value: 'bibliotecario',
  },
  {
    label: 'Bilheteiro',
    value: 'bilheteiro',
  },
  {
    label: 'Biologista',
    value: 'biologista',
  },
  {
    label: 'Biomédico',
    value: 'biomedico',
  },
  {
    label: 'Bioquímico',
    value: 'bioquimico',
  },
  {
    label: 'Biotecnólogo',
    value: 'biotecnologo',
  },
  {
    label: 'Biólogo',
    value: 'biologo',
  },
  {
    label: 'Blaster',
    value: 'blaster',
  },
  {
    label: 'Blogueiro',
    value: 'blogueiro',
  },
  {
    label: 'Bloquista',
    value: 'bloquista',
  },
  {
    label: 'Bombeiro Civil',
    value: 'bombeiro_civil',
  },
  {
    label: 'Bombeiro Industrial',
    value: 'bombeiro_industrial',
  },
  {
    label: 'Booker',
    value: 'booker',
  },
  {
    label: 'Bordador',
    value: 'bordador',
  },
  {
    label: 'Borracheiro',
    value: 'borracheiro',
  },
  {
    label: 'Business Partner',
    value: 'business_partner',
  },
  {
    label: 'Cabeleireiro',
    value: 'cabeleireiro',
  },
  {
    label: 'Cabista',
    value: 'cabista',
  },
  {
    label: 'Caixa de Banco',
    value: 'caixa_de_banco',
  },
  {
    label: 'Calceteiro',
    value: 'calceteiro',
  },
  {
    label: 'Calculista',
    value: 'calculista',
  },
  {
    label: 'Caldeireiro',
    value: 'caldeireiro',
  },
  {
    label: 'Camareiro',
    value: 'camareiro',
  },
  {
    label: 'Caracterizador',
    value: 'caracterizador',
  },
  {
    label: 'Carpinteiro',
    value: 'carpinteiro',
  },
  {
    label: 'Cartazista',
    value: 'cartazista',
  },
  {
    label: 'Carteiro',
    value: 'carteiro',
  },
  {
    label: 'Caseiro',
    value: 'caseiro',
  },
  {
    label: 'Cenotécnico',
    value: 'cenotecnico',
  },
  {
    label: 'Cenógrafo',
    value: 'cenografo',
  },
  {
    label: 'Ceo',
    value: 'ceo',
  },
  {
    label: 'Chapeiro',
    value: 'chapeiro',
  },
  {
    label: 'Chaveiro',
    value: 'chaveiro',
  },
  {
    label: 'Chefe de Bar',
    value: 'chefe_de_bar',
  },
  {
    label: 'Chefe de Compras',
    value: 'chefe_de_compras',
  },
  {
    label: 'Chefe de Confeitaria',
    value: 'chefe_de_confeitaria',
  },
  {
    label: 'Chefe de Costura',
    value: 'chefe_de_costura',
  },
  {
    label: 'Chefe de Cozinha',
    value: 'chefe_de_cozinha',
  },
  {
    label: 'Chefe de Departamento Pessoal',
    value: 'chefe_de_departamento_pessoal',
  },
  {
    label: 'Chefe de Expedição',
    value: 'chefe_de_expedicao',
  },
  {
    label: 'Chefe de Fila',
    value: 'chefe_de_fila',
  },
  {
    label: 'Chefe de Plantão Médico',
    value: 'chefe_de_plantao_medico',
  },
  {
    label: 'Chefe de Produção',
    value: 'chefe_de_producao',
  },
  {
    label: 'Chefe de Recepção',
    value: 'chefe_de_recepcao',
  },
  {
    label: 'Churrasqueiro',
    value: 'churrasqueiro',
  },
  {
    label: 'Cilindrista',
    value: 'cilindrista',
  },
  {
    label: 'Cinegrafista',
    value: 'cinegrafista',
  },
  {
    label: 'Classificador de Grãos',
    value: 'classificador_de_graos',
  },
  {
    label: 'Cliente Oculto',
    value: 'cliente_oculto',
  },
  {
    label: 'Coach',
    value: 'coach',
  },
  {
    label: 'Cobrador de Ônibus',
    value: 'cobrador_de_onibus',
  },
  {
    label: 'Codificador de Dados',
    value: 'codificador_de_dados',
  },
  {
    label: 'Colchoeiro',
    value: 'colchoeiro',
  },
  {
    label: 'Coletor de Amostras',
    value: 'coletor_de_amostras',
  },
  {
    label: 'Coletor de Lixo',
    value: 'coletor_de_lixo',
  },
  {
    label: 'Colocador de Papel de Parede',
    value: 'colocador_de_papel_de_parede',
  },
  {
    label: 'Colorista de Papel',
    value: 'colorista_de_papel',
  },
  {
    label: 'Colorista Têxtil',
    value: 'colorista_textil',
  },
  {
    label: 'Colorista',
    value: 'colorista',
  },
  {
    label: 'Comissário de Avarias',
    value: 'comissario_de_avarias',
  },
  {
    label: 'Comissário de Bordo',
    value: 'comissario_de_bordo',
  },
  {
    label: 'Comprador de Medicamentos',
    value: 'comprador_de_medicamentos',
  },
  {
    label: 'Comprador Internacional',
    value: 'comprador_internacional',
  },
  {
    label: 'Comprador Técnico',
    value: 'comprador_tecnico',
  },
  {
    label: 'Comprador',
    value: 'comprador',
  },
  {
    label: 'Comunicador Social',
    value: 'comunicador_social',
  },
  {
    label: 'Concierge',
    value: 'concierge',
  },
  {
    label: 'Confeiteiro',
    value: 'confeiteiro',
  },
  {
    label: 'Conferente de Tesouraria',
    value: 'conferente_de_tesouraria',
  },
  {
    label: 'Conferente Líder',
    value: 'conferente_lider',
  },
  {
    label: 'Conferente',
    value: 'conferente',
  },
  {
    label: 'Conselheiro Tutelar',
    value: 'conselheiro_tutelar',
  },
  {
    label: 'Consultor Abap',
    value: 'consultor_abap',
  },
  {
    label: 'Consultor Ambiental',
    value: 'consultor_ambiental',
  },
  {
    label: 'Consultor Contábil',
    value: 'consultor_contabil',
  },
  {
    label: 'Consultor Crm',
    value: 'consultor_crm',
  },
  {
    label: 'Consultor de Atendimento',
    value: 'consultor_de_atendimento',
  },
  {
    label: 'Consultor de Beleza',
    value: 'consultor_de_beleza',
  },
  {
    label: 'Consultor de Benefícios',
    value: 'consultor_de_beneficios',
  },
  {
    label: 'Consultor de Comunicação',
    value: 'consultor_de_comunicacao',
  },
  {
    label: 'Consultor de Comércio Exterior',
    value: 'consultor_de_comercio_exterior',
  },
  {
    label: 'Consultor de Controladoria',
    value: 'consultor_de_controladoria',
  },
  {
    label: 'Consultor de Crédito Imobiliário',
    value: 'consultor_de_credito_imobiliario',
  },
  {
    label: 'Consultor de Estética',
    value: 'consultor_de_estetica',
  },
  {
    label: 'Consultor de Eventos',
    value: 'consultor_de_eventos',
  },
  {
    label: 'Consultor de Franquias',
    value: 'consultor_de_franquias',
  },
  {
    label: 'Consultor de Imigração',
    value: 'consultor_de_imigracao',
  },
  {
    label: 'Consultor de Implantação',
    value: 'consultor_de_implantacao',
  },
  {
    label: 'Consultor de Infraestrutura',
    value: 'consultor_de_infraestrutura',
  },
  {
    label: 'Consultor de Inteligência de Mercado',
    value: 'consultor_de_inteligencia_de_mercado',
  },
  {
    label: 'Consultor de Intercâmbio',
    value: 'consultor_de_intercambio',
  },
  {
    label: 'Consultor de Investimentos',
    value: 'consultor_de_investimentos',
  },
  {
    label: 'Consultor de Logística',
    value: 'consultor_de_logistica',
  },
  {
    label: 'Consultor de Loja',
    value: 'consultor_de_loja',
  },
  {
    label: 'Consultor de Marketing',
    value: 'consultor_de_marketing',
  },
  {
    label: 'Consultor de Merchandising',
    value: 'consultor_de_merchandising',
  },
  {
    label: 'Consultor de Moda',
    value: 'consultor_de_moda',
  },
  {
    label: 'Consultor de Negócios',
    value: 'consultor_de_negocios',
  },
  {
    label: 'Consultor de Operações',
    value: 'consultor_de_operacoes',
  },
  {
    label: 'Consultor de Planejamento Estratégico',
    value: 'consultor_de_planejamento_estrategico',
  },
  {
    label: 'Consultor de Processos',
    value: 'consultor_de_processos',
  },
  {
    label: 'Consultor de Projetos',
    value: 'consultor_de_projetos',
  },
  {
    label: 'Consultor de Pós-venda',
    value: 'consultor_de_posvenda',
  },
  {
    label: 'Consultor de Qualidade',
    value: 'consultor_de_qualidade',
  },
  {
    label: 'Consultor de Recrutamento E Seleção',
    value: 'consultor_de_recrutamento_e_selecao',
  },
  {
    label: 'Consultor de Recursos Humanos',
    value: 'consultor_de_recursos_humanos',
  },
  {
    label: 'Consultor de Redes',
    value: 'consultor_de_redes',
  },
  {
    label: 'Consultor de Relacionamento',
    value: 'consultor_de_relacionamento',
  },
  {
    label: 'Consultor de Responsabilidade Social',
    value: 'consultor_de_responsabilidade_social',
  },
  {
    label: 'Consultor de Segurança Da Informação',
    value: 'consultor_de_seguranca_da_informacao',
  },
  {
    label: 'Consultor de Seguros',
    value: 'consultor_de_seguros',
  },
  {
    label: 'Consultor de Sistemas',
    value: 'consultor_de_sistemas',
  },
  {
    label: 'Consultor de Tecnologia Da Informação',
    value: 'consultor_de_tecnologia_da_informacao',
  },
  {
    label: 'Consultor de Trade Marketing',
    value: 'consultor_de_trade_marketing',
  },
  {
    label: 'Consultor de Treinamento E Desenvolvimento',
    value: 'consultor_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Consultor de Vendas',
    value: 'consultor_de_vendas',
  },
  {
    label: 'Consultor Educacional',
    value: 'consultor_educacional',
  },
  {
    label: 'Consultor Empresarial',
    value: 'consultor_empresarial',
  },
  {
    label: 'Consultor Erp',
    value: 'consultor_erp',
  },
  {
    label: 'Consultor Financeiro',
    value: 'consultor_financeiro',
  },
  {
    label: 'Consultor Fiscal',
    value: 'consultor_fiscal',
  },
  {
    label: 'Consultor Funcional',
    value: 'consultor_funcional',
  },
  {
    label: 'Consultor Gastronômico',
    value: 'consultor_gastronomico',
  },
  {
    label: 'Consultor Jurídico',
    value: 'consultor_juridico',
  },
  {
    label: 'Consultor Mastersaf',
    value: 'consultor_mastersaf',
  },
  {
    label: 'Consultor Oracle',
    value: 'consultor_oracle',
  },
  {
    label: 'Consultor Pedagógico',
    value: 'consultor_pedagogico',
  },
  {
    label: 'Consultor Sap',
    value: 'consultor_sap',
  },
  {
    label: 'Consultor Siebel',
    value: 'consultor_siebel',
  },
  {
    label: 'Consultor Tributário',
    value: 'consultor_tributario',
  },
  {
    label: 'Consultor Técnico de Veículos',
    value: 'consultor_tecnico_de_veiculos',
  },
  {
    label: 'Consultor Técnico Industrial',
    value: 'consultor_tecnico_industrial',
  },
  {
    label: 'Consultor Técnico',
    value: 'consultor_tecnico',
  },
  {
    label: 'Contabilista',
    value: 'contabilista',
  },
  {
    label: 'Contador Gerencial',
    value: 'contador_gerencial',
  },
  {
    label: 'Contador',
    value: 'contador',
  },
  {
    label: 'Contato Publicitário',
    value: 'contato_publicitario',
  },
  {
    label: 'Conteudista',
    value: 'conteudista',
  },
  {
    label: 'Contra-regra',
    value: 'contraregra',
  },
  {
    label: 'Controlador de Custos',
    value: 'controlador_de_custos',
  },
  {
    label: 'Controlador de Produção',
    value: 'controlador_de_producao',
  },
  {
    label: 'Controlador de Pátio',
    value: 'controlador_de_patio',
  },
  {
    label: 'Controlador de Qualidade',
    value: 'controlador_de_qualidade',
  },
  {
    label: 'Controlador de Tráfego Aéreo',
    value: 'controlador_de_trafego_aereo',
  },
  {
    label: 'Controlador de Tráfego',
    value: 'controlador_de_trafego',
  },
  {
    label: 'Controller',
    value: 'controller',
  },
  {
    label: 'Contínuo',
    value: 'continuo',
  },
  {
    label: 'Coordenador Administrativo de Obras',
    value: 'coordenador_administrativo_de_obras',
  },
  {
    label: 'Coordenador Administrativo',
    value: 'coordenador_administrativo',
  },
  {
    label: 'Coordenador Comercial',
    value: 'coordenador_comercial',
  },
  {
    label: 'Coordenador Contábil',
    value: 'coordenador_contabil',
  },
  {
    label: 'Coordenador de Alimentos E Bebidas',
    value: 'coordenador_de_alimentos_e_bebidas',
  },
  {
    label: 'Coordenador de Almoxarifado',
    value: 'coordenador_de_almoxarifado',
  },
  {
    label: 'Coordenador de Armazém',
    value: 'coordenador_de_armazem',
  },
  {
    label: 'Coordenador de Assistência Técnica',
    value: 'coordenador_de_assistencia_tecnica',
  },
  {
    label: 'Coordenador de Assuntos Regulatórios',
    value: 'coordenador_de_assuntos_regulatorios',
  },
  {
    label: 'Coordenador de Atendimento Ao Cliente',
    value: 'coordenador_de_atendimento_ao_cliente',
  },
  {
    label: 'Coordenador de Auditoria',
    value: 'coordenador_de_auditoria',
  },
  {
    label: 'Coordenador de Benefícios',
    value: 'coordenador_de_beneficios',
  },
  {
    label: 'Coordenador de Biblioteca',
    value: 'coordenador_de_biblioteca',
  },
  {
    label: 'Coordenador de Business Intelligence',
    value: 'coordenador_de_business_intelligence',
  },
  {
    label: 'Coordenador de Campo',
    value: 'coordenador_de_campo',
  },
  {
    label: 'Coordenador de Cargos E Salários',
    value: 'coordenador_de_cargos_e_salarios',
  },
  {
    label: 'Coordenador de Cobrança',
    value: 'coordenador_de_cobranca',
  },
  {
    label: 'Coordenador de Compras',
    value: 'coordenador_de_compras',
  },
  {
    label: 'Coordenador de Comunicação',
    value: 'coordenador_de_comunicacao',
  },
  {
    label: 'Coordenador de Comércio Exterior',
    value: 'coordenador_de_comercio_exterior',
  },
  {
    label: 'Coordenador de Contas A Pagar',
    value: 'coordenador_de_contas_a_pagar',
  },
  {
    label: 'Coordenador de Contas A Receber',
    value: 'coordenador_de_contas_a_receber',
  },
  {
    label: 'Coordenador de Contas Médicas',
    value: 'coordenador_de_contas_medicas',
  },
  {
    label: 'Coordenador de Contas',
    value: 'coordenador_de_contas',
  },
  {
    label: 'Coordenador de Contratos',
    value: 'coordenador_de_contratos',
  },
  {
    label: 'Coordenador de Controladoria',
    value: 'coordenador_de_controladoria',
  },
  {
    label: 'Coordenador de Controles Internos',
    value: 'coordenador_de_controles_internos',
  },
  {
    label: 'Coordenador de Credenciamento',
    value: 'coordenador_de_credenciamento',
  },
  {
    label: 'Coordenador de Crm',
    value: 'coordenador_de_crm',
  },
  {
    label: 'Coordenador de Crédito E Cobrança',
    value: 'coordenador_de_credito_e_cobranca',
  },
  {
    label: 'Coordenador de Crédito Imobiliário',
    value: 'coordenador_de_credito_imobiliario',
  },
  {
    label: 'Coordenador de Crédito',
    value: 'coordenador_de_credito',
  },
  {
    label: 'Coordenador de Customer Service',
    value: 'coordenador_de_customer_service',
  },
  {
    label: 'Coordenador de Custos',
    value: 'coordenador_de_custos',
  },
  {
    label: 'Coordenador de Departamento Pessoal',
    value: 'coordenador_de_departamento_pessoal',
  },
  {
    label: 'Coordenador de Desenvolvimento de Sistemas',
    value: 'coordenador_de_desenvolvimento_de_sistemas',
  },
  {
    label: 'Coordenador de E-commerce',
    value: 'coordenador_de_ecommerce',
  },
  {
    label: 'Coordenador de Enfermagem',
    value: 'coordenador_de_enfermagem',
  },
  {
    label: 'Coordenador de Engenharia',
    value: 'coordenador_de_engenharia',
  },
  {
    label: 'Coordenador de Estilo',
    value: 'coordenador_de_estilo',
  },
  {
    label: 'Coordenador de Estoque',
    value: 'coordenador_de_estoque',
  },
  {
    label: 'Coordenador de Eventos',
    value: 'coordenador_de_eventos',
  },
  {
    label: 'Coordenador de Expedição',
    value: 'coordenador_de_expedicao',
  },
  {
    label: 'Coordenador de Facilities',
    value: 'coordenador_de_facilities',
  },
  {
    label: 'Coordenador de Faturamento',
    value: 'coordenador_de_faturamento',
  },
  {
    label: 'Coordenador de Ferramentaria',
    value: 'coordenador_de_ferramentaria',
  },
  {
    label: 'Coordenador de Filial',
    value: 'coordenador_de_filial',
  },
  {
    label: 'Coordenador de Folha de Pagamento',
    value: 'coordenador_de_folha_de_pagamento',
  },
  {
    label: 'Coordenador de Garantia Da Qualidade',
    value: 'coordenador_de_garantia_da_qualidade',
  },
  {
    label: 'Coordenador de Help Desk',
    value: 'coordenador_de_help_desk',
  },
  {
    label: 'Coordenador de Hotelaria',
    value: 'coordenador_de_hotelaria',
  },
  {
    label: 'Coordenador de Implantação',
    value: 'coordenador_de_implantacao',
  },
  {
    label: 'Coordenador de Incorporação',
    value: 'coordenador_de_incorporacao',
  },
  {
    label: 'Coordenador de Infraestrutura',
    value: 'coordenador_de_infraestrutura',
  },
  {
    label: 'Coordenador de Instalações',
    value: 'coordenador_de_instalacoes',
  },
  {
    label: 'Coordenador de Inteligência de Mercado',
    value: 'coordenador_de_inteligencia_de_mercado',
  },
  {
    label: 'Coordenador de Inventário',
    value: 'coordenador_de_inventario',
  },
  {
    label: 'Coordenador de Investimentos',
    value: 'coordenador_de_investimentos',
  },
  {
    label: 'Coordenador de Laboratório',
    value: 'coordenador_de_laboratorio',
  },
  {
    label: 'Coordenador de Legalização',
    value: 'coordenador_de_legalizacao',
  },
  {
    label: 'Coordenador de Licitação',
    value: 'coordenador_de_licitacao',
  },
  {
    label: 'Coordenador de Limpeza',
    value: 'coordenador_de_limpeza',
  },
  {
    label: 'Coordenador de Logística',
    value: 'coordenador_de_logistica',
  },
  {
    label: 'Coordenador de Loja',
    value: 'coordenador_de_loja',
  },
  {
    label: 'Coordenador de Manutenção Automotiva',
    value: 'coordenador_de_manutencao_automotiva',
  },
  {
    label: 'Coordenador de Manutenção Industrial',
    value: 'coordenador_de_manutencao_industrial',
  },
  {
    label: 'Coordenador de Manutenção Predial',
    value: 'coordenador_de_manutencao_predial',
  },
  {
    label: 'Coordenador de Manutenção',
    value: 'coordenador_de_manutencao',
  },
  {
    label: 'Coordenador de Marketing Digital',
    value: 'coordenador_de_marketing_digital',
  },
  {
    label: 'Coordenador de Marketing',
    value: 'coordenador_de_marketing',
  },
  {
    label: 'Coordenador de Meio Ambiente',
    value: 'coordenador_de_meio_ambiente',
  },
  {
    label: 'Coordenador de Melhoria Contínua',
    value: 'coordenador_de_melhoria_continua',
  },
  {
    label: 'Coordenador de Merchandising',
    value: 'coordenador_de_merchandising',
  },
  {
    label: 'Coordenador de Mídia',
    value: 'coordenador_de_midia',
  },
  {
    label: 'Coordenador de Mídias Sociais',
    value: 'coordenador_de_midias_sociais',
  },
  {
    label: 'Coordenador de Negócios',
    value: 'coordenador_de_negocios',
  },
  {
    label: 'Coordenador de Obras',
    value: 'coordenador_de_obras',
  },
  {
    label: 'Coordenador de Orçamento',
    value: 'coordenador_de_orcamento',
  },
  {
    label: 'Coordenador de Patrimônio',
    value: 'coordenador_de_patrimonio',
  },
  {
    label: 'Coordenador de Pcp',
    value: 'coordenador_de_pcp',
  },
  {
    label: 'Coordenador de Pesquisa de Mercado',
    value: 'coordenador_de_pesquisa_de_mercado',
  },
  {
    label: 'Coordenador de Pesquisa E Desenvolvimento',
    value: 'coordenador_de_pesquisa_e_desenvolvimento',
  },
  {
    label: 'Coordenador de Planejamento',
    value: 'coordenador_de_planejamento',
  },
  {
    label: 'Coordenador de Prevenção de Perdas',
    value: 'coordenador_de_prevencao_de_perdas',
  },
  {
    label: 'Coordenador de Pricing',
    value: 'coordenador_de_pricing',
  },
  {
    label: 'Coordenador de Processos',
    value: 'coordenador_de_processos',
  },
  {
    label: 'Coordenador de Produto',
    value: 'coordenador_de_produto',
  },
  {
    label: 'Coordenador de Produção',
    value: 'coordenador_de_producao',
  },
  {
    label: 'Coordenador de Projeto Social',
    value: 'coordenador_de_projeto_social',
  },
  {
    label: 'Coordenador de Projetos de Construção Civil',
    value: 'coordenador_de_projetos_de_construcao_civil',
  },
  {
    label: 'Coordenador de Projetos',
    value: 'coordenador_de_projetos',
  },
  {
    label: 'Coordenador de Pós-venda',
    value: 'coordenador_de_posvenda',
  },
  {
    label: 'Coordenador de Qualidade',
    value: 'coordenador_de_qualidade',
  },
  {
    label: 'Coordenador de Recepção',
    value: 'coordenador_de_recepcao',
  },
  {
    label: 'Coordenador de Recrutamento E Seleção',
    value: 'coordenador_de_recrutamento_e_selecao',
  },
  {
    label: 'Coordenador de Recursos Humanos',
    value: 'coordenador_de_recursos_humanos',
  },
  {
    label: 'Coordenador de Redes',
    value: 'coordenador_de_redes',
  },
  {
    label: 'Coordenador de Reservas',
    value: 'coordenador_de_reservas',
  },
  {
    label: 'Coordenador de Responsabilidade Social',
    value: 'coordenador_de_responsabilidade_social',
  },
  {
    label: 'Coordenador de Restaurante',
    value: 'coordenador_de_restaurante',
  },
  {
    label: 'Coordenador de Saúde, Segurança E Meio Ambiente',
    value: 'coordenador_de_saude_seguranca_e_meio_ambiente',
  },
  {
    label: 'Coordenador de Segurança Da Informação',
    value: 'coordenador_de_seguranca_da_informacao',
  },
  {
    label: 'Coordenador de Segurança Do Trabalho',
    value: 'coordenador_de_seguranca_do_trabalho',
  },
  {
    label: 'Coordenador de Segurança Patrimonial',
    value: 'coordenador_de_seguranca_patrimonial',
  },
  {
    label: 'Coordenador de Seguros',
    value: 'coordenador_de_seguros',
  },
  {
    label: 'Coordenador de Service Desk',
    value: 'coordenador_de_service_desk',
  },
  {
    label: 'Coordenador de Serviços Gerais',
    value: 'coordenador_de_servicos_gerais',
  },
  {
    label: 'Coordenador de Sinistro',
    value: 'coordenador_de_sinistro',
  },
  {
    label: 'Coordenador de Suporte Técnico',
    value: 'coordenador_de_suporte_tecnico',
  },
  {
    label: 'Coordenador de Suprimentos',
    value: 'coordenador_de_suprimentos',
  },
  {
    label: 'Coordenador de Sustentabilidade',
    value: 'coordenador_de_sustentabilidade',
  },
  {
    label: 'Coordenador de Tecnologia Da Informação',
    value: 'coordenador_de_tecnologia_da_informacao',
  },
  {
    label: 'Coordenador de Telecomunicações',
    value: 'coordenador_de_telecomunicacoes',
  },
  {
    label: 'Coordenador de Tesouraria',
    value: 'coordenador_de_tesouraria',
  },
  {
    label: 'Coordenador de Topografia',
    value: 'coordenador_de_topografia',
  },
  {
    label: 'Coordenador de Trade Marketing',
    value: 'coordenador_de_trade_marketing',
  },
  {
    label: 'Coordenador de Transportes',
    value: 'coordenador_de_transportes',
  },
  {
    label: 'Coordenador de Treinamento E Desenvolvimento',
    value: 'coordenador_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Coordenador de Usinagem',
    value: 'coordenador_de_usinagem',
  },
  {
    label: 'Coordenador de Vendas',
    value: 'coordenador_de_vendas',
  },
  {
    label: 'Coordenador de Visual Merchandising',
    value: 'coordenador_de_visual_merchandising',
  },
  {
    label: 'Coordenador Editorial',
    value: 'coordenador_editorial',
  },
  {
    label: 'Coordenador Eletrônico',
    value: 'coordenador_eletronico',
  },
  {
    label: 'Coordenador Financeiro',
    value: 'coordenador_financeiro',
  },
  {
    label: 'Coordenador Fiscal',
    value: 'coordenador_fiscal',
  },
  {
    label: 'Coordenador Imobiliário',
    value: 'coordenador_imobiliario',
  },
  {
    label: 'Coordenador Industrial',
    value: 'coordenador_industrial',
  },
  {
    label: 'Coordenador Jurídico',
    value: 'coordenador_juridico',
  },
  {
    label: 'Coordenador Médico',
    value: 'coordenador_medico',
  },
  {
    label: 'Coordenador Operacional',
    value: 'coordenador_operacional',
  },
  {
    label: 'Coordenador Pedagógico',
    value: 'coordenador_pedagogico',
  },
  {
    label: 'Coordenador Tributário',
    value: 'coordenador_tributario',
  },
  {
    label: 'Copeiro',
    value: 'copeiro',
  },
  {
    label: 'Copiador de Chapa',
    value: 'copiador_de_chapa',
  },
  {
    label: 'Coreógrafo',
    value: 'coreografo',
  },
  {
    label: 'Corretor de Imóveis',
    value: 'corretor_de_imoveis',
  },
  {
    label: 'Corretor de Seguros',
    value: 'corretor_de_seguros',
  },
  {
    label: 'Cortador de Tecidos',
    value: 'cortador_de_tecidos',
  },
  {
    label: 'Cortador de Vidro',
    value: 'cortador_de_vidro',
  },
  {
    label: 'Cortador',
    value: 'cortador',
  },
  {
    label: 'Costureiro',
    value: 'costureiro',
  },
  {
    label: 'Cozinheiro',
    value: 'cozinheiro',
  },
  {
    label: 'Crepeiro',
    value: 'crepeiro',
  },
  {
    label: 'Cronoanalista',
    value: 'cronoanalista',
  },
  {
    label: 'Cuidador de Crianças',
    value: 'cuidador_de_criancas',
  },
  {
    label: 'Cuidador de Idosos',
    value: 'cuidador_de_idosos',
  },
  {
    label: 'Cumim',
    value: 'cumim',
  },
  {
    label: 'Curador de Arte',
    value: 'curador_de_arte',
  },
  {
    label: 'Dançarino',
    value: 'dancarino',
  },
  {
    label: 'Data Scientist',
    value: 'data_scientist',
  },
  {
    label: 'Dba Oracle',
    value: 'dba_oracle',
  },
  {
    label: 'Dba Sql',
    value: 'dba_sql',
  },
  {
    label: 'Dba',
    value: 'dba',
  },
  {
    label: 'Decorador',
    value: 'decorador',
  },
  {
    label: 'Dedetizador',
    value: 'dedetizador',
  },
  {
    label: 'Degustador',
    value: 'degustador',
  },
  {
    label: 'Dentista Auditor',
    value: 'dentista_auditor',
  },
  {
    label: 'Dentista Endodontista',
    value: 'dentista_endodontista',
  },
  {
    label: 'Dentista Estomatologista',
    value: 'dentista_estomatologista',
  },
  {
    label: 'Dentista Implantodontista',
    value: 'dentista_implantodontista',
  },
  {
    label: 'Dentista Odontopediatra',
    value: 'dentista_odontopediatra',
  },
  {
    label: 'Dentista Ortodontista',
    value: 'dentista_ortodontista',
  },
  {
    label: 'Dentista Periodontista',
    value: 'dentista_periodontista',
  },
  {
    label: 'Dentista Protesista',
    value: 'dentista_protesista',
  },
  {
    label: 'Dentista Traumatologista',
    value: 'dentista_traumatologista',
  },
  {
    label: 'Dentista',
    value: 'dentista',
  },
  {
    label: 'Departamento Pessoal',
    value: 'departamento_pessoal',
  },
  {
    label: 'Depiladora',
    value: 'depiladora',
  },
  {
    label: 'Dermoconsultor',
    value: 'dermoconsultor',
  },
  {
    label: 'Desenhista Cadista',
    value: 'desenhista_cadista',
  },
  {
    label: 'Desenhista Copista',
    value: 'desenhista_copista',
  },
  {
    label: 'Desenhista Elétrico',
    value: 'desenhista_eletrico',
  },
  {
    label: 'Desenhista Industrial',
    value: 'desenhista_industrial',
  },
  {
    label: 'Desenhista Mecânico',
    value: 'desenhista_mecanico',
  },
  {
    label: 'Desenhista Orçamentista',
    value: 'desenhista_orcamentista',
  },
  {
    label: 'Desenhista Projetista Civil',
    value: 'desenhista_projetista_civil',
  },
  {
    label: 'Desenhista Projetista Elétrico',
    value: 'desenhista_projetista_eletrico',
  },
  {
    label: 'Desenhista Projetista Hidráulico',
    value: 'desenhista_projetista_hidraulico',
  },
  {
    label: 'Desenhista Projetista Mecânico',
    value: 'desenhista_projetista_mecanico',
  },
  {
    label: 'Desenhista Projetista',
    value: 'desenhista_projetista',
  },
  {
    label: 'Desenhista Técnico',
    value: 'desenhista_tecnico',
  },
  {
    label: 'Desenhista',
    value: 'desenhista',
  },
  {
    label: 'Desentupidor',
    value: 'desentupidor',
  },
  {
    label: 'Design',
    value: 'design',
  },
  {
    label: 'Designer de Acessórios',
    value: 'designer_de_acessorios',
  },
  {
    label: 'Designer de Calçados',
    value: 'designer_de_calcados',
  },
  {
    label: 'Designer de Embalagem',
    value: 'designer_de_embalagem',
  },
  {
    label: 'Designer de Interação',
    value: 'designer_de_interacao',
  },
  {
    label: 'Designer de Interface',
    value: 'designer_de_interface',
  },
  {
    label: 'Designer de Interiores',
    value: 'designer_de_interiores',
  },
  {
    label: 'Designer de Jogos',
    value: 'designer_de_jogos',
  },
  {
    label: 'Designer de Joias',
    value: 'designer_de_joias',
  },
  {
    label: 'Designer de Moda',
    value: 'designer_de_moda',
  },
  {
    label: 'Designer de Produto',
    value: 'designer_de_produto',
  },
  {
    label: 'Designer de Sobrancelha',
    value: 'designer_de_sobrancelha',
  },
  {
    label: 'Designer Digital',
    value: 'designer_digital',
  },
  {
    label: 'Designer Gráfico',
    value: 'designer_grafico',
  },
  {
    label: 'Designer Instrucional',
    value: 'designer_instrucional',
  },
  {
    label: 'Designer Multimídia',
    value: 'designer_multimidia',
  },
  {
    label: 'Designer Têxtil',
    value: 'designer_textil',
  },
  {
    label: 'Designer',
    value: 'designer',
  },
  {
    label: 'Despachante Aduaneiro',
    value: 'despachante_aduaneiro',
  },
  {
    label: 'Despachante Imobiliário',
    value: 'despachante_imobiliario',
  },
  {
    label: 'Despachante Operacional de Voo',
    value: 'despachante_operacional_de_voo',
  },
  {
    label: 'Despachante',
    value: 'despachante',
  },
  {
    label: 'Diagramador',
    value: 'diagramador',
  },
  {
    label: 'Digitador de Laudos',
    value: 'digitador_de_laudos',
  },
  {
    label: 'Digitador',
    value: 'digitador',
  },
  {
    label: 'Digitalizador',
    value: 'digitalizador',
  },
  {
    label: 'Diligenciador',
    value: 'diligenciador',
  },
  {
    label: 'Diretor Administrativo',
    value: 'diretor_administrativo',
  },
  {
    label: 'Diretor Comercial',
    value: 'diretor_comercial',
  },
  {
    label: 'Diretor de Arte',
    value: 'diretor_de_arte',
  },
  {
    label: 'Diretor de Cinema',
    value: 'diretor_de_cinema',
  },
  {
    label: 'Diretor de Compras',
    value: 'diretor_de_compras',
  },
  {
    label: 'Diretor de Criação',
    value: 'diretor_de_criacao',
  },
  {
    label: 'Diretor de Logística',
    value: 'diretor_de_logistica',
  },
  {
    label: 'Diretor de Loja',
    value: 'diretor_de_loja',
  },
  {
    label: 'Diretor de Marketing',
    value: 'diretor_de_marketing',
  },
  {
    label: 'Diretor de Negócios',
    value: 'diretor_de_negocios',
  },
  {
    label: 'Diretor de Obras',
    value: 'diretor_de_obras',
  },
  {
    label: 'Diretor de Operações',
    value: 'diretor_de_operacoes',
  },
  {
    label: 'Diretor de Recursos Humanos',
    value: 'diretor_de_recursos_humanos',
  },
  {
    label: 'Diretor de Teatro',
    value: 'diretor_de_teatro',
  },
  {
    label: 'Diretor de Tecnologia Da Informação',
    value: 'diretor_de_tecnologia_da_informacao',
  },
  {
    label: 'Diretor de Vendas',
    value: 'diretor_de_vendas',
  },
  {
    label: 'Diretor Financeiro',
    value: 'diretor_financeiro',
  },
  {
    label: 'Diretor Geral',
    value: 'diretor_geral',
  },
  {
    label: 'Diretor Hospitalar',
    value: 'diretor_hospitalar',
  },
  {
    label: 'Diretor Industrial',
    value: 'diretor_industrial',
  },
  {
    label: 'Diretor Jurídico',
    value: 'diretor_juridico',
  },
  {
    label: 'Diretor Pedagógico',
    value: 'diretor_pedagogico',
  },
  {
    label: 'Divulgador',
    value: 'divulgador',
  },
  {
    label: 'Dj',
    value: 'dj',
  },
  {
    label: 'Documentador',
    value: 'documentador',
  },
  {
    label: 'Doméstica',
    value: 'domestica',
  },
  {
    label: 'Duteiro',
    value: 'duteiro',
  },
  {
    label: 'Economista',
    value: 'economista',
  },
  {
    label: 'Ecólogo',
    value: 'ecologo',
  },
  {
    label: 'Editor de Imagens',
    value: 'editor_de_imagens',
  },
  {
    label: 'Editor de Moda',
    value: 'editor_de_moda',
  },
  {
    label: 'Editor de Texto',
    value: 'editor_de_texto',
  },
  {
    label: 'Editor de Vídeo',
    value: 'editor_de_video',
  },
  {
    label: 'Educador Ambiental',
    value: 'educador_ambiental',
  },
  {
    label: 'Educador Social',
    value: 'educador_social',
  },
  {
    label: 'Eletricista de Ar Condicionado',
    value: 'eletricista_de_ar_condicionado',
  },
  {
    label: 'Eletricista de Empilhadeira',
    value: 'eletricista_de_empilhadeira',
  },
  {
    label: 'Eletricista de Força E Controle',
    value: 'eletricista_de_forca_e_controle',
  },
  {
    label: 'Eletricista de Instalações',
    value: 'eletricista_de_instalacoes',
  },
  {
    label: 'Eletricista de Veículos',
    value: 'eletricista_de_veiculos',
  },
  {
    label: 'Eletricista Eletrônico',
    value: 'eletricista_eletronico',
  },
  {
    label: 'Eletricista Industrial',
    value: 'eletricista_industrial',
  },
  {
    label: 'Eletricista Montador de Painel',
    value: 'eletricista_montador_de_painel',
  },
  {
    label: 'Eletricista Montador',
    value: 'eletricista_montador',
  },
  {
    label: 'Eletricista Predial',
    value: 'eletricista_predial',
  },
  {
    label: 'Eletricista',
    value: 'eletricista',
  },
  {
    label: 'Eletromecânico de Manutenção',
    value: 'eletromecanico_de_manutencao',
  },
  {
    label: 'Eletromecânico',
    value: 'eletromecanico',
  },
  {
    label: 'Eletrotécnico',
    value: 'eletrotecnico',
  },
  {
    label: 'Embalador',
    value: 'embalador',
  },
  {
    label: 'Embriologista',
    value: 'embriologista',
  },
  {
    label: 'Emissor de Ctrc',
    value: 'emissor_de_ctrc',
  },
  {
    label: 'Emissor de Passagens',
    value: 'emissor_de_passagens',
  },
  {
    label: 'Empacotador',
    value: 'empacotador',
  },
  {
    label: 'Encadernador',
    value: 'encadernador',
  },
  {
    label: 'Encanador Industrial',
    value: 'encanador_industrial',
  },
  {
    label: 'Encanador',
    value: 'encanador',
  },
  {
    label: 'Encapsulador',
    value: 'encapsulador',
  },
  {
    label: 'Encarregado Administrativo de Obras',
    value: 'encarregado_administrativo_de_obras',
  },
  {
    label: 'Encarregado Administrativo',
    value: 'encarregado_administrativo',
  },
  {
    label: 'Encarregado Comercial',
    value: 'encarregado_comercial',
  },
  {
    label: 'Encarregado Contábil',
    value: 'encarregado_contabil',
  },
  {
    label: 'Encarregado de Almoxarifado',
    value: 'encarregado_de_almoxarifado',
  },
  {
    label: 'Encarregado de Assistência Técnica',
    value: 'encarregado_de_assistencia_tecnica',
  },
  {
    label: 'Encarregado de Açougue',
    value: 'encarregado_de_acougue',
  },
  {
    label: 'Encarregado de Caldeiraria',
    value: 'encarregado_de_caldeiraria',
  },
  {
    label: 'Encarregado de Carpintaria',
    value: 'encarregado_de_carpintaria',
  },
  {
    label: 'Encarregado de Cobrança',
    value: 'encarregado_de_cobranca',
  },
  {
    label: 'Encarregado de Compras',
    value: 'encarregado_de_compras',
  },
  {
    label: 'Encarregado de Corte',
    value: 'encarregado_de_corte',
  },
  {
    label: 'Encarregado de Elétrica',
    value: 'encarregado_de_eletrica',
  },
  {
    label: 'Encarregado de Estacionamento',
    value: 'encarregado_de_estacionamento',
  },
  {
    label: 'Encarregado de Estamparia',
    value: 'encarregado_de_estamparia',
  },
  {
    label: 'Encarregado de Estoque',
    value: 'encarregado_de_estoque',
  },
  {
    label: 'Encarregado de Expedição',
    value: 'encarregado_de_expedicao',
  },
  {
    label: 'Encarregado de Faturamento',
    value: 'encarregado_de_faturamento',
  },
  {
    label: 'Encarregado de Frota',
    value: 'encarregado_de_frota',
  },
  {
    label: 'Encarregado de Hidráulica',
    value: 'encarregado_de_hidraulica',
  },
  {
    label: 'Encarregado de Injeção Plástica',
    value: 'encarregado_de_injecao_plastica',
  },
  {
    label: 'Encarregado de Instalações',
    value: 'encarregado_de_instalacoes',
  },
  {
    label: 'Encarregado de Jardinagem',
    value: 'encarregado_de_jardinagem',
  },
  {
    label: 'Encarregado de Limpeza',
    value: 'encarregado_de_limpeza',
  },
  {
    label: 'Encarregado de Logística',
    value: 'encarregado_de_logistica',
  },
  {
    label: 'Encarregado de Loja',
    value: 'encarregado_de_loja',
  },
  {
    label: 'Encarregado de Manutenção Predial',
    value: 'encarregado_de_manutencao_predial',
  },
  {
    label: 'Encarregado de Manutenção',
    value: 'encarregado_de_manutencao',
  },
  {
    label: 'Encarregado de Marcenaria',
    value: 'encarregado_de_marcenaria',
  },
  {
    label: 'Encarregado de Mecânica',
    value: 'encarregado_de_mecanica',
  },
  {
    label: 'Encarregado de Montagem',
    value: 'encarregado_de_montagem',
  },
  {
    label: 'Encarregado de Obras',
    value: 'encarregado_de_obras',
  },
  {
    label: 'Encarregado de Oficina',
    value: 'encarregado_de_oficina',
  },
  {
    label: 'Encarregado de Operações',
    value: 'encarregado_de_operacoes',
  },
  {
    label: 'Encarregado de Padaria',
    value: 'encarregado_de_padaria',
  },
  {
    label: 'Encarregado de Peixaria',
    value: 'encarregado_de_peixaria',
  },
  {
    label: 'Encarregado de Perecíveis',
    value: 'encarregado_de_pereciveis',
  },
  {
    label: 'Encarregado de Prevenção de Perdas',
    value: 'encarregado_de_prevencao_de_perdas',
  },
  {
    label: 'Encarregado de Produção',
    value: 'encarregado_de_producao',
  },
  {
    label: 'Encarregado de Pátio',
    value: 'encarregado_de_patio',
  },
  {
    label: 'Encarregado de Serralheria',
    value: 'encarregado_de_serralheria',
  },
  {
    label: 'Encarregado de Serviços Gerais',
    value: 'encarregado_de_servicos_gerais',
  },
  {
    label: 'Encarregado de Solda',
    value: 'encarregado_de_solda',
  },
  {
    label: 'Encarregado de Sondagem',
    value: 'encarregado_de_sondagem',
  },
  {
    label: 'Encarregado de Transportes',
    value: 'encarregado_de_transportes',
  },
  {
    label: 'Encarregado de Usinagem',
    value: 'encarregado_de_usinagem',
  },
  {
    label: 'Encarregado Financeiro',
    value: 'encarregado_financeiro',
  },
  {
    label: 'Encarregado Industrial',
    value: 'encarregado_industrial',
  },
  {
    label: 'Encarregado Operacional',
    value: 'encarregado_operacional',
  },
  {
    label: 'Enfermeiro Auditor',
    value: 'enfermeiro_auditor',
  },
  {
    label: 'Enfermeiro Ccih',
    value: 'enfermeiro_ccih',
  },
  {
    label: 'Enfermeiro Chefe',
    value: 'enfermeiro_chefe',
  },
  {
    label: 'Enfermeiro Cme',
    value: 'enfermeiro_cme',
  },
  {
    label: 'Enfermeiro de Centro Cirúrgico',
    value: 'enfermeiro_de_centro_cirurgico',
  },
  {
    label: 'Enfermeiro de Educação Continuada',
    value: 'enfermeiro_de_educacao_continuada',
  },
  {
    label: 'Enfermeiro de Uti',
    value: 'enfermeiro_de_uti',
  },
  {
    label: 'Enfermeiro Do Trabalho',
    value: 'enfermeiro_do_trabalho',
  },
  {
    label: 'Enfermeiro Estomaterapeuta',
    value: 'enfermeiro_estomaterapeuta',
  },
  {
    label: 'Enfermeiro Nefrologista',
    value: 'enfermeiro_nefrologista',
  },
  {
    label: 'Enfermeiro Obstetra',
    value: 'enfermeiro_obstetra',
  },
  {
    label: 'Enfermeiro Responsável Técnico',
    value: 'enfermeiro_responsavel_tecnico',
  },
  {
    label: 'Enfermeiro Scih',
    value: 'enfermeiro_scih',
  },
  {
    label: 'Enfermeiro Visitador',
    value: 'enfermeiro_visitador',
  },
  {
    label: 'Enfermeiro',
    value: 'enfermeiro',
  },
  {
    label: 'Enfestador',
    value: 'enfestador',
  },
  {
    label: 'Engenheiro Aeronáutico',
    value: 'engenheiro_aeronautico',
  },
  {
    label: 'Engenheiro Agrimensor',
    value: 'engenheiro_agrimensor',
  },
  {
    label: 'Engenheiro Agrícola',
    value: 'engenheiro_agricola',
  },
  {
    label: 'Engenheiro Agrônomo',
    value: 'engenheiro_agronomo',
  },
  {
    label: 'Engenheiro Ambiental',
    value: 'engenheiro_ambiental',
  },
  {
    label: 'Engenheiro Automobilístico',
    value: 'engenheiro_automobilistico',
  },
  {
    label: 'Engenheiro Biomédico',
    value: 'engenheiro_biomedico',
  },
  {
    label: 'Engenheiro Calculista',
    value: 'engenheiro_calculista',
  },
  {
    label: 'Engenheiro Cartógrafo',
    value: 'engenheiro_cartografo',
  },
  {
    label: 'Engenheiro Civil',
    value: 'engenheiro_civil',
  },
  {
    label: 'Engenheiro Clínico',
    value: 'engenheiro_clinico',
  },
  {
    label: 'Engenheiro Da Qualidade',
    value: 'engenheiro_da_qualidade',
  },
  {
    label: 'Engenheiro de Alimentos',
    value: 'engenheiro_de_alimentos',
  },
  {
    label: 'Engenheiro de Aplicação',
    value: 'engenheiro_de_aplicacao',
  },
  {
    label: 'Engenheiro de Ar Condicionado',
    value: 'engenheiro_de_ar_condicionado',
  },
  {
    label: 'Engenheiro de Assistência Técnica',
    value: 'engenheiro_de_assistencia_tecnica',
  },
  {
    label: 'Engenheiro de Automação Industrial',
    value: 'engenheiro_de_automacao_industrial',
  },
  {
    label: 'Engenheiro de Campo',
    value: 'engenheiro_de_campo',
  },
  {
    label: 'Engenheiro de Compras',
    value: 'engenheiro_de_compras',
  },
  {
    label: 'Engenheiro de Computação',
    value: 'engenheiro_de_computacao',
  },
  {
    label: 'Engenheiro de Controle E Automação',
    value: 'engenheiro_de_controle_e_automacao',
  },
  {
    label: 'Engenheiro de Custos',
    value: 'engenheiro_de_custos',
  },
  {
    label: 'Engenheiro de Instalações',
    value: 'engenheiro_de_instalacoes',
  },
  {
    label: 'Engenheiro de Instrumentação',
    value: 'engenheiro_de_instrumentacao',
  },
  {
    label: 'Engenheiro de Manufatura',
    value: 'engenheiro_de_manufatura',
  },
  {
    label: 'Engenheiro de Manutenção Predial',
    value: 'engenheiro_de_manutencao_predial',
  },
  {
    label: 'Engenheiro de Manutenção',
    value: 'engenheiro_de_manutencao',
  },
  {
    label: 'Engenheiro de Materiais',
    value: 'engenheiro_de_materiais',
  },
  {
    label: 'Engenheiro de Minas',
    value: 'engenheiro_de_minas',
  },
  {
    label: 'Engenheiro de Pesca',
    value: 'engenheiro_de_pesca',
  },
  {
    label: 'Engenheiro de Petróleo',
    value: 'engenheiro_de_petroleo',
  },
  {
    label: 'Engenheiro de Planejamento',
    value: 'engenheiro_de_planejamento',
  },
  {
    label: 'Engenheiro de Processos',
    value: 'engenheiro_de_processos',
  },
  {
    label: 'Engenheiro de Produto',
    value: 'engenheiro_de_produto',
  },
  {
    label: 'Engenheiro de Produção',
    value: 'engenheiro_de_producao',
  },
  {
    label: 'Engenheiro de Projetos',
    value: 'engenheiro_de_projetos',
  },
  {
    label: 'Engenheiro de Propostas',
    value: 'engenheiro_de_propostas',
  },
  {
    label: 'Engenheiro de Segurança Do Trabalho',
    value: 'engenheiro_de_seguranca_do_trabalho',
  },
  {
    label: 'Engenheiro de Serviços',
    value: 'engenheiro_de_servicos',
  },
  {
    label: 'Engenheiro de Sistemas',
    value: 'engenheiro_de_sistemas',
  },
  {
    label: 'Engenheiro de Software',
    value: 'engenheiro_de_software',
  },
  {
    label: 'Engenheiro de Suprimentos',
    value: 'engenheiro_de_suprimentos',
  },
  {
    label: 'Engenheiro de Telecomunicações',
    value: 'engenheiro_de_telecomunicacoes',
  },
  {
    label: 'Engenheiro de Testes',
    value: 'engenheiro_de_testes',
  },
  {
    label: 'Engenheiro de Vendas',
    value: 'engenheiro_de_vendas',
  },
  {
    label: 'Engenheiro Eletricista',
    value: 'engenheiro_eletricista',
  },
  {
    label: 'Engenheiro Eletroeletrônico',
    value: 'engenheiro_eletroeletronico',
  },
  {
    label: 'Engenheiro Eletrônico',
    value: 'engenheiro_eletronico',
  },
  {
    label: 'Engenheiro Ferroviário',
    value: 'engenheiro_ferroviario',
  },
  {
    label: 'Engenheiro Florestal',
    value: 'engenheiro_florestal',
  },
  {
    label: 'Engenheiro Geotécnico',
    value: 'engenheiro_geotecnico',
  },
  {
    label: 'Engenheiro Hidráulico',
    value: 'engenheiro_hidraulico',
  },
  {
    label: 'Engenheiro Hídrico',
    value: 'engenheiro_hidrico',
  },
  {
    label: 'Engenheiro Industrial',
    value: 'engenheiro_industrial',
  },
  {
    label: 'Engenheiro Mecatrônico',
    value: 'engenheiro_mecatronico',
  },
  {
    label: 'Engenheiro Mecânico',
    value: 'engenheiro_mecanico',
  },
  {
    label: 'Engenheiro Metalúrgico',
    value: 'engenheiro_metalurgico',
  },
  {
    label: 'Engenheiro Naval',
    value: 'engenheiro_naval',
  },
  {
    label: 'Engenheiro Orçamentista',
    value: 'engenheiro_orcamentista',
  },
  {
    label: 'Engenheiro Químico',
    value: 'engenheiro_quimico',
  },
  {
    label: 'Engenheiro Rodoviário',
    value: 'engenheiro_rodoviario',
  },
  {
    label: 'Engenheiro Sanitarista',
    value: 'engenheiro_sanitarista',
  },
  {
    label: 'Engenheiro Têxtil',
    value: 'engenheiro_textil',
  },
  {
    label: 'Entregador',
    value: 'entregador',
  },
  {
    label: 'Ergonomista',
    value: 'ergonomista',
  },
  {
    label: 'Escrevente',
    value: 'escrevente',
  },
  {
    label: 'Escriturário Hospitalar',
    value: 'escriturario_hospitalar',
  },
  {
    label: 'Escriturário',
    value: 'escriturario',
  },
  {
    label: 'Especialista Contábil',
    value: 'especialista_contabil',
  },
  {
    label: 'Especialista de Produto',
    value: 'especialista_de_produto',
  },
  {
    label: 'Especialista de Projetos',
    value: 'especialista_de_projetos',
  },
  {
    label: 'Especialista de Suprimentos',
    value: 'especialista_de_suprimentos',
  },
  {
    label: 'Especialista em Compras',
    value: 'especialista_em_compras',
  },
  {
    label: 'Especialista em E-commerce',
    value: 'especialista_em_ecommerce',
  },
  {
    label: 'Especialista em Marketing Digital',
    value: 'especialista_em_marketing_digital',
  },
  {
    label: 'Especialista em Marketing',
    value: 'especialista_em_marketing',
  },
  {
    label: 'Especialista em Mídia',
    value: 'especialista_em_midia',
  },
  {
    label: 'Especialista em Recrutamento E Seleção',
    value: 'especialista_em_recrutamento_e_selecao',
  },
  {
    label: 'Especialista em Recursos Humanos',
    value: 'especialista_em_recursos_humanos',
  },
  {
    label: 'Especialista em Segurança Da Informação',
    value: 'especialista_em_seguranca_da_informacao',
  },
  {
    label: 'Especialista em Telecomunicações',
    value: 'especialista_em_telecomunicacoes',
  },
  {
    label: 'Especialista em Tesouraria',
    value: 'especialista_em_tesouraria',
  },
  {
    label: 'Especialista em Treinamento E Desenvolvimento',
    value: 'especialista_em_treinamento_e_desenvolvimento',
  },
  {
    label: 'Especialista Financeiro',
    value: 'especialista_financeiro',
  },
  {
    label: 'Especialista Fiscal',
    value: 'especialista_fiscal',
  },
  {
    label: 'Especialista Tributário',
    value: 'especialista_tributario',
  },
  {
    label: 'Estampador',
    value: 'estampador',
  },
  {
    label: 'Estatístico Trainee',
    value: 'estatistico_trainee',
  },
  {
    label: 'Estatístico',
    value: 'estatistico',
  },
  {
    label: 'Esteticista',
    value: 'esteticista',
  },
  {
    label: 'Estilista',
    value: 'estilista',
  },
  {
    label: 'Estofador',
    value: 'estofador',
  },
  {
    label: 'Estoquista',
    value: 'estoquista',
  },
  {
    label: 'Estágio em Administração de Empresas',
    value: 'estagio_em_administracao_de_empresas',
  },
  {
    label: 'Estágio em Administração Hospitalar',
    value: 'estagio_em_administracao_hospitalar',
  },
  {
    label: 'Estágio em Antropologia',
    value: 'estagio_em_antropologia',
  },
  {
    label: 'Estágio em Análise de Sistemas',
    value: 'estagio_em_analise_de_sistemas',
  },
  {
    label: 'Estágio em Análise E Desenvolvimento de Sistemas',
    value: 'estagio_em_analise_e_desenvolvimento_de_sistemas',
  },
  {
    label: 'Estágio em Arquitetura',
    value: 'estagio_em_arquitetura',
  },
  {
    label: 'Estágio em Arquivologia',
    value: 'estagio_em_arquivologia',
  },
  {
    label: 'Estágio em Artes Cênicas',
    value: 'estagio_em_artes_cenicas',
  },
  {
    label: 'Estágio em Artes Gráficas',
    value: 'estagio_em_artes_graficas',
  },
  {
    label: 'Estágio em Artes Plásticas',
    value: 'estagio_em_artes_plasticas',
  },
  {
    label: 'Estágio em Autocad',
    value: 'estagio_em_autocad',
  },
  {
    label: 'Estágio em Banco de Dados',
    value: 'estagio_em_banco_de_dados',
  },
  {
    label: 'Estágio em Biblioteconomia',
    value: 'estagio_em_biblioteconomia',
  },
  {
    label: 'Estágio em Biologia',
    value: 'estagio_em_biologia',
  },
  {
    label: 'Estágio em Bioquímica',
    value: 'estagio_em_bioquimica',
  },
  {
    label: 'Estágio em Cinema',
    value: 'estagio_em_cinema',
  },
  {
    label: 'Estágio em Ciência Da Computação',
    value: 'estagio_em_ciencia_da_computacao',
  },
  {
    label: 'Estágio em Ciências Aeronáuticas',
    value: 'estagio_em_ciencias_aeronauticas',
  },
  {
    label: 'Estágio em Ciências Atuariais',
    value: 'estagio_em_ciencias_atuariais',
  },
  {
    label: 'Estágio em Ciências Biomédicas',
    value: 'estagio_em_ciencias_biomedicas',
  },
  {
    label: 'Estágio em Ciências Contábeis',
    value: 'estagio_em_ciencias_contabeis',
  },
  {
    label: 'Estágio em Ciências Sociais',
    value: 'estagio_em_ciencias_sociais',
  },
  {
    label: 'Estágio em Comunicação E Marketing',
    value: 'estagio_em_comunicacao_e_marketing',
  },
  {
    label: 'Estágio em Comunicação Social',
    value: 'estagio_em_comunicacao_social',
  },
  {
    label: 'Estágio em Comunicação',
    value: 'estagio_em_comunicacao',
  },
  {
    label: 'Estágio em Comércio Exterior',
    value: 'estagio_em_comercio_exterior',
  },
  {
    label: 'Estágio em Controle de Qualidade',
    value: 'estagio_em_controle_de_qualidade',
  },
  {
    label: 'Estágio em Desenho Industrial',
    value: 'estagio_em_desenho_industrial',
  },
  {
    label: 'Estágio em Design de Interiores',
    value: 'estagio_em_design_de_interiores',
  },
  {
    label: 'Estágio em Design de Joias',
    value: 'estagio_em_design_de_joias',
  },
  {
    label: 'Estágio em Design de Produto',
    value: 'estagio_em_design_de_produto',
  },
  {
    label: 'Estágio em Design Digital',
    value: 'estagio_em_design_digital',
  },
  {
    label: 'Estágio em Design Gráfico',
    value: 'estagio_em_design_grafico',
  },
  {
    label: 'Estágio em Design',
    value: 'estagio_em_design',
  },
  {
    label: 'Estágio em Direito Cível',
    value: 'estagio_em_direito_civel',
  },
  {
    label: 'Estágio em Direito Imobiliário',
    value: 'estagio_em_direito_imobiliario',
  },
  {
    label: 'Estágio em Direito Societário',
    value: 'estagio_em_direito_societario',
  },
  {
    label: 'Estágio em Direito Trabalhista',
    value: 'estagio_em_direito_trabalhista',
  },
  {
    label: 'Estágio em Direito Tributário',
    value: 'estagio_em_direito_tributario',
  },
  {
    label: 'Estágio em Direito',
    value: 'estagio_em_direito',
  },
  {
    label: 'Estágio em Economia',
    value: 'estagio_em_economia',
  },
  {
    label: 'Estágio em Educação Física',
    value: 'estagio_em_educacao_fisica',
  },
  {
    label: 'Estágio em Eletroeletrônica',
    value: 'estagio_em_eletroeletronica',
  },
  {
    label: 'Estágio em Eletrotécnica',
    value: 'estagio_em_eletrotecnica',
  },
  {
    label: 'Estágio em Eletrônica',
    value: 'estagio_em_eletronica',
  },
  {
    label: 'Estágio em Elétrica',
    value: 'estagio_em_eletrica',
  },
  {
    label: 'Estágio em Enfermagem',
    value: 'estagio_em_enfermagem',
  },
  {
    label: 'Estágio em Engenharia Aeronáutica',
    value: 'estagio_em_engenharia_aeronautica',
  },
  {
    label: 'Estágio em Engenharia Agronômica',
    value: 'estagio_em_engenharia_agronomica',
  },
  {
    label: 'Estágio em Engenharia Ambiental',
    value: 'estagio_em_engenharia_ambiental',
  },
  {
    label: 'Estágio em Engenharia Automobilística',
    value: 'estagio_em_engenharia_automobilistica',
  },
  {
    label: 'Estágio em Engenharia Biomédica',
    value: 'estagio_em_engenharia_biomedica',
  },
  {
    label: 'Estágio em Engenharia Cartográfica',
    value: 'estagio_em_engenharia_cartografica',
  },
  {
    label: 'Estágio em Engenharia Civil',
    value: 'estagio_em_engenharia_civil',
  },
  {
    label: 'Estágio em Engenharia Da Computação',
    value: 'estagio_em_engenharia_da_computacao',
  },
  {
    label: 'Estágio em Engenharia Da Qualidade',
    value: 'estagio_em_engenharia_da_qualidade',
  },
  {
    label: 'Estágio em Engenharia de Agrimensura',
    value: 'estagio_em_engenharia_de_agrimensura',
  },
  {
    label: 'Estágio em Engenharia de Alimentos',
    value: 'estagio_em_engenharia_de_alimentos',
  },
  {
    label: 'Estágio em Engenharia de Controle E Automação',
    value: 'estagio_em_engenharia_de_controle_e_automacao',
  },
  {
    label: 'Estágio em Engenharia de Materiais',
    value: 'estagio_em_engenharia_de_materiais',
  },
  {
    label: 'Estágio em Engenharia de Minas',
    value: 'estagio_em_engenharia_de_minas',
  },
  {
    label: 'Estágio em Engenharia de Produto',
    value: 'estagio_em_engenharia_de_produto',
  },
  {
    label: 'Estágio em Engenharia de Produção',
    value: 'estagio_em_engenharia_de_producao',
  },
  {
    label: 'Estágio em Engenharia de Segurança Do Trabalho',
    value: 'estagio_em_engenharia_de_seguranca_do_trabalho',
  },
  {
    label: 'Estágio em Engenharia de Telecomunicações',
    value: 'estagio_em_engenharia_de_telecomunicacoes',
  },
  {
    label: 'Estágio em Engenharia Eletrônica',
    value: 'estagio_em_engenharia_eletronica',
  },
  {
    label: 'Estágio em Engenharia Elétrica E Eletrônica',
    value: 'estagio_em_engenharia_eletrica_e_eletronica',
  },
  {
    label: 'Estágio em Engenharia Elétrica',
    value: 'estagio_em_engenharia_eletrica',
  },
  {
    label: 'Estágio em Engenharia Florestal',
    value: 'estagio_em_engenharia_florestal',
  },
  {
    label: 'Estágio em Engenharia Industrial',
    value: 'estagio_em_engenharia_industrial',
  },
  {
    label: 'Estágio em Engenharia Mecatrônica',
    value: 'estagio_em_engenharia_mecatronica',
  },
  {
    label: 'Estágio em Engenharia Mecânica',
    value: 'estagio_em_engenharia_mecanica',
  },
  {
    label: 'Estágio em Engenharia Metalúrgica',
    value: 'estagio_em_engenharia_metalurgica',
  },
  {
    label: 'Estágio em Engenharia Naval',
    value: 'estagio_em_engenharia_naval',
  },
  {
    label: 'Estágio em Engenharia Química',
    value: 'estagio_em_engenharia_quimica',
  },
  {
    label: 'Estágio em Engenharia Têxtil',
    value: 'estagio_em_engenharia_textil',
  },
  {
    label: 'Estágio em Engenharia',
    value: 'estagio_em_engenharia',
  },
  {
    label: 'Estágio em Ensino Médio',
    value: 'estagio_em_ensino_medio',
  },
  {
    label: 'Estágio em Estatística',
    value: 'estagio_em_estatistica',
  },
  {
    label: 'Estágio em Farmácia Com Ênfase em Bioquímica',
    value: 'estagio_em_farmacia_com_enfase_em_bioquimica',
  },
  {
    label: 'Estágio em Farmácia',
    value: 'estagio_em_farmacia',
  },
  {
    label: 'Estágio em Filosofia',
    value: 'estagio_em_filosofia',
  },
  {
    label: 'Estágio em Fisioterapia',
    value: 'estagio_em_fisioterapia',
  },
  {
    label: 'Estágio em Fonoaudiologia',
    value: 'estagio_em_fonoaudiologia',
  },
  {
    label: 'Estágio em Fotografia',
    value: 'estagio_em_fotografia',
  },
  {
    label: 'Estágio em Física',
    value: 'estagio_em_fisica',
  },
  {
    label: 'Estágio em Gastronomia',
    value: 'estagio_em_gastronomia',
  },
  {
    label: 'Estágio em Geografia',
    value: 'estagio_em_geografia',
  },
  {
    label: 'Estágio em Geologia',
    value: 'estagio_em_geologia',
  },
  {
    label: 'Estágio em Gestão Comercial',
    value: 'estagio_em_gestao_comercial',
  },
  {
    label: 'Estágio em Gestão Da Qualidade',
    value: 'estagio_em_gestao_da_qualidade',
  },
  {
    label: 'Estágio em Gestão Financeira',
    value: 'estagio_em_gestao_financeira',
  },
  {
    label: 'Estágio em Gestão Pública',
    value: 'estagio_em_gestao_publica',
  },
  {
    label: 'Estágio em História',
    value: 'estagio_em_historia',
  },
  {
    label: 'Estágio em Hotelaria E Turismo',
    value: 'estagio_em_hotelaria_e_turismo',
  },
  {
    label: 'Estágio em Hotelaria',
    value: 'estagio_em_hotelaria',
  },
  {
    label: 'Estágio em Informática',
    value: 'estagio_em_informatica',
  },
  {
    label: 'Estágio em Jornalismo',
    value: 'estagio_em_jornalismo',
  },
  {
    label: 'Estágio em Letras',
    value: 'estagio_em_letras',
  },
  {
    label: 'Estágio em Logística',
    value: 'estagio_em_logistica',
  },
  {
    label: 'Estágio em Magistério',
    value: 'estagio_em_magisterio',
  },
  {
    label: 'Estágio em Marketing',
    value: 'estagio_em_marketing',
  },
  {
    label: 'Estágio em Matemática',
    value: 'estagio_em_matematica',
  },
  {
    label: 'Estágio em Medicina',
    value: 'estagio_em_medicina',
  },
  {
    label: 'Estágio em Meio Ambiente',
    value: 'estagio_em_meio_ambiente',
  },
  {
    label: 'Estágio em Moda',
    value: 'estagio_em_moda',
  },
  {
    label: 'Estágio em Museologia',
    value: 'estagio_em_museologia',
  },
  {
    label: 'Estágio em Música',
    value: 'estagio_em_musica',
  },
  {
    label: 'Estágio em Naturologia',
    value: 'estagio_em_naturologia',
  },
  {
    label: 'Estágio em Nutrição',
    value: 'estagio_em_nutricao',
  },
  {
    label: 'Estágio em Oceanografia',
    value: 'estagio_em_oceanografia',
  },
  {
    label: 'Estágio em Odontologia',
    value: 'estagio_em_odontologia',
  },
  {
    label: 'Estágio em Pedagogia',
    value: 'estagio_em_pedagogia',
  },
  {
    label: 'Estágio em Processamento de Dados',
    value: 'estagio_em_processamento_de_dados',
  },
  {
    label: 'Estágio em Processos Gerenciais',
    value: 'estagio_em_processos_gerenciais',
  },
  {
    label: 'Estágio em Produção Editorial',
    value: 'estagio_em_producao_editorial',
  },
  {
    label: 'Estágio em Psicologia',
    value: 'estagio_em_psicologia',
  },
  {
    label: 'Estágio em Psicopedagogia',
    value: 'estagio_em_psicopedagogia',
  },
  {
    label: 'Estágio em Publicidade E Propaganda',
    value: 'estagio_em_publicidade_e_propaganda',
  },
  {
    label: 'Estágio em Publicidade',
    value: 'estagio_em_publicidade',
  },
  {
    label: 'Estágio em Química',
    value: 'estagio_em_quimica',
  },
  {
    label: 'Estágio em Radiologia',
    value: 'estagio_em_radiologia',
  },
  {
    label: 'Estágio em Recursos Humanos',
    value: 'estagio_em_recursos_humanos',
  },
  {
    label: 'Estágio em Redes de Computadores',
    value: 'estagio_em_redes_de_computadores',
  },
  {
    label: 'Estágio em Relações Internacionais',
    value: 'estagio_em_relacoes_internacionais',
  },
  {
    label: 'Estágio em Relações Públicas',
    value: 'estagio_em_relacoes_publicas',
  },
  {
    label: 'Estágio em Rádio E Tv',
    value: 'estagio_em_radio_e_tv',
  },
  {
    label: 'Estágio em Secretariado Executivo',
    value: 'estagio_em_secretariado_executivo',
  },
  {
    label: 'Estágio em Secretariado',
    value: 'estagio_em_secretariado',
  },
  {
    label: 'Estágio em Serviço Social',
    value: 'estagio_em_servico_social',
  },
  {
    label: 'Estágio em Sistemas de Informação',
    value: 'estagio_em_sistemas_de_informacao',
  },
  {
    label: 'Estágio em Sociologia',
    value: 'estagio_em_sociologia',
  },
  {
    label: 'Estágio em Telecomunicações',
    value: 'estagio_em_telecomunicacoes',
  },
  {
    label: 'Estágio em Terapia Ocupacional',
    value: 'estagio_em_terapia_ocupacional',
  },
  {
    label: 'Estágio em Tradução',
    value: 'estagio_em_traducao',
  },
  {
    label: 'Estágio em Transações Imobiliárias',
    value: 'estagio_em_transacoes_imobiliarias',
  },
  {
    label: 'Estágio em Turismo',
    value: 'estagio_em_turismo',
  },
  {
    label: 'Estágio em Veterinária',
    value: 'estagio_em_veterinaria',
  },
  {
    label: 'Estágio em Web Design',
    value: 'estagio_em_web_design',
  },
  {
    label: 'Estágio em Zootecnia',
    value: 'estagio_em_zootecnia',
  },
  {
    label: 'Estágio Na Área Administrativa Financeira',
    value: 'estagio_na_area_administrativa_financeira',
  },
  {
    label: 'Estágio Na Área Administrativa',
    value: 'estagio_na_area_administrativa',
  },
  {
    label: 'Estágio Na Área Audiovisual',
    value: 'estagio_na_area_audiovisual',
  },
  {
    label: 'Estágio Na Área Bancária',
    value: 'estagio_na_area_bancaria',
  },
  {
    label: 'Estágio Na Área Comercial',
    value: 'estagio_na_area_comercial',
  },
  {
    label: 'Estágio Na Área Da Qualidade E Meio Ambiente',
    value: 'estagio_na_area_da_qualidade_e_meio_ambiente',
  },
  {
    label: 'Estágio Na Área de Alimentos E Bebidas',
    value: 'estagio_na_area_de_alimentos_e_bebidas',
  },
  {
    label: 'Estágio Na Área de Alimentos',
    value: 'estagio_na_area_de_alimentos',
  },
  {
    label: 'Estágio Na Área de Almoxarifado',
    value: 'estagio_na_area_de_almoxarifado',
  },
  {
    label: 'Estágio Na Área de Arte',
    value: 'estagio_na_area_de_arte',
  },
  {
    label: 'Estágio Na Área de Assessoria de Imprensa',
    value: 'estagio_na_area_de_assessoria_de_imprensa',
  },
  {
    label: 'Estágio Na Área de Assistência Técnica',
    value: 'estagio_na_area_de_assistencia_tecnica',
  },
  {
    label: 'Estágio Na Área de Assuntos Regulatórios',
    value: 'estagio_na_area_de_assuntos_regulatorios',
  },
  {
    label: 'Estágio Na Área de Atendimento Publicitário',
    value: 'estagio_na_area_de_atendimento_publicitario',
  },
  {
    label: 'Estágio Na Área de Atendimento',
    value: 'estagio_na_area_de_atendimento',
  },
  {
    label: 'Estágio Na Área de Auditoria',
    value: 'estagio_na_area_de_auditoria',
  },
  {
    label: 'Estágio Na Área de Automação Industrial',
    value: 'estagio_na_area_de_automacao_industrial',
  },
  {
    label: 'Estágio Na Área de Back Office',
    value: 'estagio_na_area_de_back_office',
  },
  {
    label: 'Estágio Na Área de Benefícios',
    value: 'estagio_na_area_de_beneficios',
  },
  {
    label: 'Estágio Na Área de Business Intelligence',
    value: 'estagio_na_area_de_business_intelligence',
  },
  {
    label: 'Estágio Na Área de Cobrança',
    value: 'estagio_na_area_de_cobranca',
  },
  {
    label: 'Estágio Na Área de Compliance',
    value: 'estagio_na_area_de_compliance',
  },
  {
    label: 'Estágio Na Área de Compras',
    value: 'estagio_na_area_de_compras',
  },
  {
    label: 'Estágio Na Área de Contas A Pagar',
    value: 'estagio_na_area_de_contas_a_pagar',
  },
  {
    label: 'Estágio Na Área de Contas A Receber',
    value: 'estagio_na_area_de_contas_a_receber',
  },
  {
    label: 'Estágio Na Área de Contratos',
    value: 'estagio_na_area_de_contratos',
  },
  {
    label: 'Estágio Na Área de Controladoria',
    value: 'estagio_na_area_de_controladoria',
  },
  {
    label: 'Estágio Na Área de Controles Internos',
    value: 'estagio_na_area_de_controles_internos',
  },
  {
    label: 'Estágio Na Área de Criação',
    value: 'estagio_na_area_de_criacao',
  },
  {
    label: 'Estágio Na Área de Crm',
    value: 'estagio_na_area_de_crm',
  },
  {
    label: 'Estágio Na Área de Crédito E Cobrança',
    value: 'estagio_na_area_de_credito_e_cobranca',
  },
  {
    label: 'Estágio Na Área de Custos',
    value: 'estagio_na_area_de_custos',
  },
  {
    label: 'Estágio Na Área de Departamento Pessoal',
    value: 'estagio_na_area_de_departamento_pessoal',
  },
  {
    label: 'Estágio Na Área de Desenvolvimento .net',
    value: 'estagio_na_area_de_desenvolvimento_net',
  },
  {
    label: 'Estágio Na Área de Desenvolvimento de Sistemas',
    value: 'estagio_na_area_de_desenvolvimento_de_sistemas',
  },
  {
    label: 'Estágio Na Área de Desenvolvimento de Software',
    value: 'estagio_na_area_de_desenvolvimento_de_software',
  },
  {
    label: 'Estágio Na Área de Diagramação',
    value: 'estagio_na_area_de_diagramacao',
  },
  {
    label: 'Estágio Na Área de E-commerce',
    value: 'estagio_na_area_de_ecommerce',
  },
  {
    label: 'Estágio Na Área de Edificações',
    value: 'estagio_na_area_de_edificacoes',
  },
  {
    label: 'Estágio Na Área de Edição de Imagens',
    value: 'estagio_na_area_de_edicao_de_imagens',
  },
  {
    label: 'Estágio Na Área de Edição de Vídeo',
    value: 'estagio_na_area_de_edicao_de_video',
  },
  {
    label: 'Estágio Na Área de Educação Infantil',
    value: 'estagio_na_area_de_educacao_infantil',
  },
  {
    label: 'Estágio Na Área de Educação',
    value: 'estagio_na_area_de_educacao',
  },
  {
    label: 'Estágio Na Área de Engenharia de Processos',
    value: 'estagio_na_area_de_engenharia_de_processos',
  },
  {
    label: 'Estágio Na Área de Engenharia de Projetos',
    value: 'estagio_na_area_de_engenharia_de_projetos',
  },
  {
    label: 'Estágio Na Área de Estoque',
    value: 'estagio_na_area_de_estoque',
  },
  {
    label: 'Estágio Na Área de Estética',
    value: 'estagio_na_area_de_estetica',
  },
  {
    label: 'Estágio Na Área de Eventos',
    value: 'estagio_na_area_de_eventos',
  },
  {
    label: 'Estágio Na Área de Expedição',
    value: 'estagio_na_area_de_expedicao',
  },
  {
    label: 'Estágio Na Área de Exportação',
    value: 'estagio_na_area_de_exportacao',
  },
  {
    label: 'Estágio Na Área de Facilities',
    value: 'estagio_na_area_de_facilities',
  },
  {
    label: 'Estágio Na Área de Faturamento',
    value: 'estagio_na_area_de_faturamento',
  },
  {
    label: 'Estágio Na Área de Fusões E Aquisições',
    value: 'estagio_na_area_de_fusoes_e_aquisicoes',
  },
  {
    label: 'Estágio Na Área de Geoprocessamento',
    value: 'estagio_na_area_de_geoprocessamento',
  },
  {
    label: 'Estágio Na Área de Gestão Ambiental',
    value: 'estagio_na_area_de_gestao_ambiental',
  },
  {
    label: 'Estágio Na Área de Ginástica',
    value: 'estagio_na_area_de_ginastica',
  },
  {
    label: 'Estágio Na Área de Governança',
    value: 'estagio_na_area_de_governanca',
  },
  {
    label: 'Estágio Na Área de Implantação de Sistemas',
    value: 'estagio_na_area_de_implantacao_de_sistemas',
  },
  {
    label: 'Estágio Na Área de Importação E Exportação',
    value: 'estagio_na_area_de_importacao_e_exportacao',
  },
  {
    label: 'Estágio Na Área de Importação',
    value: 'estagio_na_area_de_importacao',
  },
  {
    label: 'Estágio Na Área de Infraestrutura',
    value: 'estagio_na_area_de_infraestrutura',
  },
  {
    label: 'Estágio Na Área de Inteligência de Mercado',
    value: 'estagio_na_area_de_inteligencia_de_mercado',
  },
  {
    label: 'Estágio Na Área de Investimentos',
    value: 'estagio_na_area_de_investimentos',
  },
  {
    label: 'Estágio Na Área de Laboratório',
    value: 'estagio_na_area_de_laboratorio',
  },
  {
    label: 'Estágio Na Área de Licitação',
    value: 'estagio_na_area_de_licitacao',
  },
  {
    label: 'Estágio Na Área de Links Patrocinados',
    value: 'estagio_na_area_de_links_patrocinados',
  },
  {
    label: 'Estágio Na Área de Manutenção',
    value: 'estagio_na_area_de_manutencao',
  },
  {
    label: 'Estágio Na Área de Marketing Digital',
    value: 'estagio_na_area_de_marketing_digital',
  },
  {
    label: 'Estágio Na Área de Metrologia',
    value: 'estagio_na_area_de_metrologia',
  },
  {
    label: 'Estágio Na Área de Microbiologia',
    value: 'estagio_na_area_de_microbiologia',
  },
  {
    label: 'Estágio Na Área de Musculação',
    value: 'estagio_na_area_de_musculacao',
  },
  {
    label: 'Estágio Na Área de Mídias Digitais',
    value: 'estagio_na_area_de_midias_digitais',
  },
  {
    label: 'Estágio Na Área de Natação',
    value: 'estagio_na_area_de_natacao',
  },
  {
    label: 'Estágio Na Área de Negócios',
    value: 'estagio_na_area_de_negocios',
  },
  {
    label: 'Estágio Na Área de Obras',
    value: 'estagio_na_area_de_obras',
  },
  {
    label: 'Estágio Na Área de Orçamentos',
    value: 'estagio_na_area_de_orcamentos',
  },
  {
    label: 'Estágio Na Área de Pcp',
    value: 'estagio_na_area_de_pcp',
  },
  {
    label: 'Estágio Na Área de Pesquisa de Mercado',
    value: 'estagio_na_area_de_pesquisa_de_mercado',
  },
  {
    label: 'Estágio Na Área de Planejamento Estratégico',
    value: 'estagio_na_area_de_planejamento_estrategico',
  },
  {
    label: 'Estágio Na Área de Planejamento Financeiro',
    value: 'estagio_na_area_de_planejamento_financeiro',
  },
  {
    label: 'Estágio Na Área de Planejamento',
    value: 'estagio_na_area_de_planejamento',
  },
  {
    label: 'Estágio Na Área de Processos Industriais',
    value: 'estagio_na_area_de_processos_industriais',
  },
  {
    label: 'Estágio Na Área de Processos',
    value: 'estagio_na_area_de_processos',
  },
  {
    label: 'Estágio Na Área de Produtos',
    value: 'estagio_na_area_de_produtos',
  },
  {
    label: 'Estágio Na Área de Produção',
    value: 'estagio_na_area_de_producao',
  },
  {
    label: 'Estágio Na Área de Programação Delphi',
    value: 'estagio_na_area_de_programacao_delphi',
  },
  {
    label: 'Estágio Na Área de Programação Java',
    value: 'estagio_na_area_de_programacao_java',
  },
  {
    label: 'Estágio Na Área de Programação Php',
    value: 'estagio_na_area_de_programacao_php',
  },
  {
    label: 'Estágio Na Área de Programação Web',
    value: 'estagio_na_area_de_programacao_web',
  },
  {
    label: 'Estágio Na Área de Programação',
    value: 'estagio_na_area_de_programacao',
  },
  {
    label: 'Estágio Na Área de Projetos',
    value: 'estagio_na_area_de_projetos',
  },
  {
    label: 'Estágio Na Área de Qualidade',
    value: 'estagio_na_area_de_qualidade',
  },
  {
    label: 'Estágio Na Área de Recepção',
    value: 'estagio_na_area_de_recepcao',
  },
  {
    label: 'Estágio Na Área de Recreação',
    value: 'estagio_na_area_de_recreacao',
  },
  {
    label: 'Estágio Na Área de Recrutamento E Seleção',
    value: 'estagio_na_area_de_recrutamento_e_selecao',
  },
  {
    label: 'Estágio Na Área de Redação Publicitária',
    value: 'estagio_na_area_de_redacao_publicitaria',
  },
  {
    label: 'Estágio Na Área de Redação',
    value: 'estagio_na_area_de_redacao',
  },
  {
    label: 'Estágio Na Área de Redes Sociais',
    value: 'estagio_na_area_de_redes_sociais',
  },
  {
    label: 'Estágio Na Área de Redes',
    value: 'estagio_na_area_de_redes',
  },
  {
    label: 'Estágio Na Área de Relacionamento',
    value: 'estagio_na_area_de_relacionamento',
  },
  {
    label: 'Estágio Na Área de Remuneração E Benefícios',
    value: 'estagio_na_area_de_remuneracao_e_beneficios',
  },
  {
    label: 'Estágio Na Área de Remuneração',
    value: 'estagio_na_area_de_remuneracao',
  },
  {
    label: 'Estágio Na Área de Reservas',
    value: 'estagio_na_area_de_reservas',
  },
  {
    label: 'Estágio Na Área de Responsabilidade Social',
    value: 'estagio_na_area_de_responsabilidade_social',
  },
  {
    label: 'Estágio Na Área de Revisão de Texto',
    value: 'estagio_na_area_de_revisao_de_texto',
  },
  {
    label: 'Estágio Na Área de Risco',
    value: 'estagio_na_area_de_risco',
  },
  {
    label: 'Estágio Na Área de Saúde Bucal',
    value: 'estagio_na_area_de_saude_bucal',
  },
  {
    label: 'Estágio Na Área de Segurança Da Informação',
    value: 'estagio_na_area_de_seguranca_da_informacao',
  },
  {
    label: 'Estágio Na Área de Segurança Do Trabalho',
    value: 'estagio_na_area_de_seguranca_do_trabalho',
  },
  {
    label: 'Estágio Na Área de Seguros',
    value: 'estagio_na_area_de_seguros',
  },
  {
    label: 'Estágio Na Área de Sistemas',
    value: 'estagio_na_area_de_sistemas',
  },
  {
    label: 'Estágio Na Área de Suporte Técnico',
    value: 'estagio_na_area_de_suporte_tecnico',
  },
  {
    label: 'Estágio Na Área de Suprimentos',
    value: 'estagio_na_area_de_suprimentos',
  },
  {
    label: 'Estágio Na Área de Sustentabilidade',
    value: 'estagio_na_area_de_sustentabilidade',
  },
  {
    label: 'Estágio Na Área de Tecnologia Da Informação',
    value: 'estagio_na_area_de_tecnologia_da_informacao',
  },
  {
    label: 'Estágio Na Área de Tesouraria',
    value: 'estagio_na_area_de_tesouraria',
  },
  {
    label: 'Estágio Na Área de Testes',
    value: 'estagio_na_area_de_testes',
  },
  {
    label: 'Estágio Na Área de Trade Marketing',
    value: 'estagio_na_area_de_trade_marketing',
  },
  {
    label: 'Estágio Na Área de Transportes',
    value: 'estagio_na_area_de_transportes',
  },
  {
    label: 'Estágio Na Área de Treinamento E Desenvolvimento',
    value: 'estagio_na_area_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Estágio Na Área de Vendas',
    value: 'estagio_na_area_de_vendas',
  },
  {
    label: 'Estágio Na Área Financeira',
    value: 'estagio_na_area_financeira',
  },
  {
    label: 'Estágio Na Área Fiscal',
    value: 'estagio_na_area_fiscal',
  },
  {
    label: 'Estágio Na Área Imobiliária',
    value: 'estagio_na_area_imobiliaria',
  },
  {
    label: 'Estágio Na Área Industrial',
    value: 'estagio_na_area_industrial',
  },
  {
    label: 'Estágio Na Área Mecânica',
    value: 'estagio_na_area_mecanica',
  },
  {
    label: 'Estágio Na Área Tributária',
    value: 'estagio_na_area_tributaria',
  },
  {
    label: 'Estágio Na Área Técnica',
    value: 'estagio_na_area_tecnica',
  },
  {
    label: 'Estágio Técnico em Administração de Empresas',
    value: 'estagio_tecnico_em_administracao_de_empresas',
  },
  {
    label: 'Estágio Técnico em Agrimensura',
    value: 'estagio_tecnico_em_agrimensura',
  },
  {
    label: 'Estágio Técnico em Alimentos',
    value: 'estagio_tecnico_em_alimentos',
  },
  {
    label: 'Estágio Técnico em Análises Clínicas',
    value: 'estagio_tecnico_em_analises_clinicas',
  },
  {
    label: 'Estágio Técnico em Contabilidade',
    value: 'estagio_tecnico_em_contabilidade',
  },
  {
    label: 'Estágio Técnico em Edificações',
    value: 'estagio_tecnico_em_edificacoes',
  },
  {
    label: 'Estágio Técnico em Eletroeletrônica',
    value: 'estagio_tecnico_em_eletroeletronica',
  },
  {
    label: 'Estágio Técnico em Eletromecânica',
    value: 'estagio_tecnico_em_eletromecanica',
  },
  {
    label: 'Estágio Técnico em Eletrotécnica',
    value: 'estagio_tecnico_em_eletrotecnica',
  },
  {
    label: 'Estágio Técnico em Eletrônica',
    value: 'estagio_tecnico_em_eletronica',
  },
  {
    label: 'Estágio Técnico em Elétrica',
    value: 'estagio_tecnico_em_eletrica',
  },
  {
    label: 'Estágio Técnico em Enfermagem Do Trabalho',
    value: 'estagio_tecnico_em_enfermagem_do_trabalho',
  },
  {
    label: 'Estágio Técnico em Enfermagem',
    value: 'estagio_tecnico_em_enfermagem',
  },
  {
    label: 'Estágio Técnico em Farmácia',
    value: 'estagio_tecnico_em_farmacia',
  },
  {
    label: 'Estágio Técnico em Gestão Empresarial',
    value: 'estagio_tecnico_em_gestao_empresarial',
  },
  {
    label: 'Estágio Técnico em Informática',
    value: 'estagio_tecnico_em_informatica',
  },
  {
    label: 'Estágio Técnico em Logística',
    value: 'estagio_tecnico_em_logistica',
  },
  {
    label: 'Estágio Técnico em Mecatrônica',
    value: 'estagio_tecnico_em_mecatronica',
  },
  {
    label: 'Estágio Técnico em Mecânica',
    value: 'estagio_tecnico_em_mecanica',
  },
  {
    label: 'Estágio Técnico em Meio Ambiente',
    value: 'estagio_tecnico_em_meio_ambiente',
  },
  {
    label: 'Estágio Técnico em Nutrição',
    value: 'estagio_tecnico_em_nutricao',
  },
  {
    label: 'Estágio Técnico em Plásticos',
    value: 'estagio_tecnico_em_plasticos',
  },
  {
    label: 'Estágio Técnico em Prótese Dentária',
    value: 'estagio_tecnico_em_protese_dentaria',
  },
  {
    label: 'Estágio Técnico em Publicidade E Propaganda',
    value: 'estagio_tecnico_em_publicidade_e_propaganda',
  },
  {
    label: 'Estágio Técnico em Qualidade',
    value: 'estagio_tecnico_em_qualidade',
  },
  {
    label: 'Estágio Técnico em Química',
    value: 'estagio_tecnico_em_quimica',
  },
  {
    label: 'Estágio Técnico em Secretariado',
    value: 'estagio_tecnico_em_secretariado',
  },
  {
    label: 'Estágio Técnico em Segurança Do Trabalho',
    value: 'estagio_tecnico_em_seguranca_do_trabalho',
  },
  {
    label: 'Estágio Técnico em Telecomunicações',
    value: 'estagio_tecnico_em_telecomunicacoes',
  },
  {
    label: 'Estágio Técnico Na Área de Manutenção',
    value: 'estagio_tecnico_na_area_de_manutencao',
  },
  {
    label: 'Estágio Técnico Na Área de Recursos Humanos',
    value: 'estagio_tecnico_na_area_de_recursos_humanos',
  },
  {
    label: 'Estágio Técnico Na Área de Vendas',
    value: 'estagio_tecnico_na_area_de_vendas',
  },
  {
    label: 'Etiquetador',
    value: 'etiquetador',
  },
  {
    label: 'Executivo de Contas',
    value: 'executivo_de_contas',
  },
  {
    label: 'Executivo de Vendas',
    value: 'executivo_de_vendas',
  },
  {
    label: 'Extrusor',
    value: 'extrusor',
  },
  {
    label: 'Farmacêutico Bioquímico',
    value: 'farmaceutico_bioquimico',
  },
  {
    label: 'Farmacêutico Homeopata',
    value: 'farmaceutico_homeopata',
  },
  {
    label: 'Farmacêutico Hospitalar',
    value: 'farmaceutico_hospitalar',
  },
  {
    label: 'Farmacêutico Industrial',
    value: 'farmaceutico_industrial',
  },
  {
    label: 'Farmacêutico Trainee',
    value: 'farmaceutico_trainee',
  },
  {
    label: 'Farmacêutico',
    value: 'farmaceutico',
  },
  {
    label: 'Faturista Hospitalar',
    value: 'faturista_hospitalar',
  },
  {
    label: 'Faturista',
    value: 'faturista',
  },
  {
    label: 'Ferramenteiro de Moldes Plásticos',
    value: 'ferramenteiro_de_moldes_plasticos',
  },
  {
    label: 'Ferramenteiro',
    value: 'ferramenteiro',
  },
  {
    label: 'Ferreiro',
    value: 'ferreiro',
  },
  {
    label: 'Figurinista',
    value: 'figurinista',
  },
  {
    label: 'Fiscal de Caixa',
    value: 'fiscal_de_caixa',
  },
  {
    label: 'Fiscal de Campo',
    value: 'fiscal_de_campo',
  },
  {
    label: 'Fiscal de Loja',
    value: 'fiscal_de_loja',
  },
  {
    label: 'Fiscal de Obras',
    value: 'fiscal_de_obras',
  },
  {
    label: 'Fiscal de Prevenção de Perdas',
    value: 'fiscal_de_prevencao_de_perdas',
  },
  {
    label: 'Fiscal de Tráfego',
    value: 'fiscal_de_trafego',
  },
  {
    label: 'Fisioterapeuta Acupunturista',
    value: 'fisioterapeuta_acupunturista',
  },
  {
    label: 'Fisioterapeuta Dermato Funcional',
    value: 'fisioterapeuta_dermato_funcional',
  },
  {
    label: 'Fisioterapeuta Do Trabalho',
    value: 'fisioterapeuta_do_trabalho',
  },
  {
    label: 'Fisioterapeuta Esportivo',
    value: 'fisioterapeuta_esportivo',
  },
  {
    label: 'Fisioterapeuta Home Care',
    value: 'fisioterapeuta_home_care',
  },
  {
    label: 'Fisioterapeuta Hospitalar',
    value: 'fisioterapeuta_hospitalar',
  },
  {
    label: 'Fisioterapeuta Neurofuncional',
    value: 'fisioterapeuta_neurofuncional',
  },
  {
    label: 'Fisioterapeuta Oncofuncional',
    value: 'fisioterapeuta_oncofuncional',
  },
  {
    label: 'Fisioterapeuta Respiratório',
    value: 'fisioterapeuta_respiratorio',
  },
  {
    label: 'Fisioterapeuta Rpg',
    value: 'fisioterapeuta_rpg',
  },
  {
    label: 'Fisioterapeuta Vendedor',
    value: 'fisioterapeuta_vendedor',
  },
  {
    label: 'Fisioterapeuta',
    value: 'fisioterapeuta',
  },
  {
    label: 'Florista',
    value: 'florista',
  },
  {
    label: 'Fonoaudiólogo',
    value: 'fonoaudiologo',
  },
  {
    label: 'Forneiro',
    value: 'forneiro',
  },
  {
    label: 'Fotogravador',
    value: 'fotogravador',
  },
  {
    label: 'Fotógrafo',
    value: 'fotografo',
  },
  {
    label: 'Fracionador',
    value: 'fracionador',
  },
  {
    label: 'Frentista',
    value: 'frentista',
  },
  {
    label: 'Fresador Cnc',
    value: 'fresador_cnc',
  },
  {
    label: 'Fresador',
    value: 'fresador',
  },
  {
    label: 'Fundidor de Metais',
    value: 'fundidor_de_metais',
  },
  {
    label: 'Funileiro de Veículos',
    value: 'funileiro_de_veiculos',
  },
  {
    label: 'Funileiro',
    value: 'funileiro',
  },
  {
    label: 'Físico',
    value: 'fisico',
  },
  {
    label: 'Galvanizador',
    value: 'galvanizador',
  },
  {
    label: 'Garantista de Veículos',
    value: 'garantista_de_veiculos',
  },
  {
    label: 'Garantista',
    value: 'garantista',
  },
  {
    label: 'Garde Manger',
    value: 'garde_manger',
  },
  {
    label: 'Garçom',
    value: 'garcom',
  },
  {
    label: 'Gastrônomo',
    value: 'gastronomo',
  },
  {
    label: 'Gemólogo',
    value: 'gemologo',
  },
  {
    label: 'Geofísico',
    value: 'geofisico',
  },
  {
    label: 'Gerente Administrativo',
    value: 'gerente_administrativo',
  },
  {
    label: 'Gerente Agrícola',
    value: 'gerente_agricola',
  },
  {
    label: 'Gerente Bancário',
    value: 'gerente_bancario',
  },
  {
    label: 'Gerente Comercial',
    value: 'gerente_comercial',
  },
  {
    label: 'Gerente de Aeroporto',
    value: 'gerente_de_aeroporto',
  },
  {
    label: 'Gerente de Alimentos E Bebidas',
    value: 'gerente_de_alimentos_e_bebidas',
  },
  {
    label: 'Gerente de Almoxarifado',
    value: 'gerente_de_almoxarifado',
  },
  {
    label: 'Gerente de Armazém',
    value: 'gerente_de_armazem',
  },
  {
    label: 'Gerente de Assistência Técnica',
    value: 'gerente_de_assistencia_tecnica',
  },
  {
    label: 'Gerente de Assuntos Regulatórios',
    value: 'gerente_de_assuntos_regulatorios',
  },
  {
    label: 'Gerente de Atendimento',
    value: 'gerente_de_atendimento',
  },
  {
    label: 'Gerente de Auditoria',
    value: 'gerente_de_auditoria',
  },
  {
    label: 'Gerente de Benefícios',
    value: 'gerente_de_beneficios',
  },
  {
    label: 'Gerente de Canais',
    value: 'gerente_de_canais',
  },
  {
    label: 'Gerente de Cinema',
    value: 'gerente_de_cinema',
  },
  {
    label: 'Gerente de Cobrança',
    value: 'gerente_de_cobranca',
  },
  {
    label: 'Gerente de Compliance',
    value: 'gerente_de_compliance',
  },
  {
    label: 'Gerente de Compras',
    value: 'gerente_de_compras',
  },
  {
    label: 'Gerente de Comunicação',
    value: 'gerente_de_comunicacao',
  },
  {
    label: 'Gerente de Comércio Exterior',
    value: 'gerente_de_comercio_exterior',
  },
  {
    label: 'Gerente de Condomínio',
    value: 'gerente_de_condominio',
  },
  {
    label: 'Gerente de Confecção',
    value: 'gerente_de_confeccao',
  },
  {
    label: 'Gerente de Contabilidade',
    value: 'gerente_de_contabilidade',
  },
  {
    label: 'Gerente de Contas',
    value: 'gerente_de_contas',
  },
  {
    label: 'Gerente de Conteúdo',
    value: 'gerente_de_conteudo',
  },
  {
    label: 'Gerente de Contratos',
    value: 'gerente_de_contratos',
  },
  {
    label: 'Gerente de Controladoria',
    value: 'gerente_de_controladoria',
  },
  {
    label: 'Gerente de Controle de Qualidade',
    value: 'gerente_de_controle_de_qualidade',
  },
  {
    label: 'Gerente de Crm',
    value: 'gerente_de_crm',
  },
  {
    label: 'Gerente de Crédito E Cobrança',
    value: 'gerente_de_credito_e_cobranca',
  },
  {
    label: 'Gerente de Crédito Imobiliário',
    value: 'gerente_de_credito_imobiliario',
  },
  {
    label: 'Gerente de Custos',
    value: 'gerente_de_custos',
  },
  {
    label: 'Gerente de Departamento Pessoal',
    value: 'gerente_de_departamento_pessoal',
  },
  {
    label: 'Gerente de E-commerce',
    value: 'gerente_de_ecommerce',
  },
  {
    label: 'Gerente de Enfermagem',
    value: 'gerente_de_enfermagem',
  },
  {
    label: 'Gerente de Engenharia',
    value: 'gerente_de_engenharia',
  },
  {
    label: 'Gerente de Estoque',
    value: 'gerente_de_estoque',
  },
  {
    label: 'Gerente de Eventos',
    value: 'gerente_de_eventos',
  },
  {
    label: 'Gerente de Expedição',
    value: 'gerente_de_expedicao',
  },
  {
    label: 'Gerente de Facilities',
    value: 'gerente_de_facilities',
  },
  {
    label: 'Gerente de Factoring',
    value: 'gerente_de_factoring',
  },
  {
    label: 'Gerente de Farmácia',
    value: 'gerente_de_farmacia',
  },
  {
    label: 'Gerente de Faturamento',
    value: 'gerente_de_faturamento',
  },
  {
    label: 'Gerente de Filial',
    value: 'gerente_de_filial',
  },
  {
    label: 'Gerente de Frota',
    value: 'gerente_de_frota',
  },
  {
    label: 'Gerente de Fusões E Aquisições',
    value: 'gerente_de_fusoes_e_aquisicoes',
  },
  {
    label: 'Gerente de Garantia Da Qualidade',
    value: 'gerente_de_garantia_da_qualidade',
  },
  {
    label: 'Gerente de Hotel',
    value: 'gerente_de_hotel',
  },
  {
    label: 'Gerente de Infraestrutura',
    value: 'gerente_de_infraestrutura',
  },
  {
    label: 'Gerente de Inteligência de Mercado',
    value: 'gerente_de_inteligencia_de_mercado',
  },
  {
    label: 'Gerente de Inventário',
    value: 'gerente_de_inventario',
  },
  {
    label: 'Gerente de Investimentos',
    value: 'gerente_de_investimentos',
  },
  {
    label: 'Gerente de Lavanderia',
    value: 'gerente_de_lavanderia',
  },
  {
    label: 'Gerente de Licitação',
    value: 'gerente_de_licitacao',
  },
  {
    label: 'Gerente de Limpeza',
    value: 'gerente_de_limpeza',
  },
  {
    label: 'Gerente de Locação',
    value: 'gerente_de_locacao',
  },
  {
    label: 'Gerente de Logística',
    value: 'gerente_de_logistica',
  },
  {
    label: 'Gerente de Loja',
    value: 'gerente_de_loja',
  },
  {
    label: 'Gerente de Manutenção Industrial',
    value: 'gerente_de_manutencao_industrial',
  },
  {
    label: 'Gerente de Manutenção Mecânica',
    value: 'gerente_de_manutencao_mecanica',
  },
  {
    label: 'Gerente de Manutenção Predial',
    value: 'gerente_de_manutencao_predial',
  },
  {
    label: 'Gerente de Manutenção',
    value: 'gerente_de_manutencao',
  },
  {
    label: 'Gerente de Marketing Digital',
    value: 'gerente_de_marketing_digital',
  },
  {
    label: 'Gerente de Marketing',
    value: 'gerente_de_marketing',
  },
  {
    label: 'Gerente de Merchandising',
    value: 'gerente_de_merchandising',
  },
  {
    label: 'Gerente de Moda',
    value: 'gerente_de_moda',
  },
  {
    label: 'Gerente de Mídias Sociais',
    value: 'gerente_de_midias_sociais',
  },
  {
    label: 'Gerente de Negócios',
    value: 'gerente_de_negocios',
  },
  {
    label: 'Gerente de Nutrição',
    value: 'gerente_de_nutricao',
  },
  {
    label: 'Gerente de Obras',
    value: 'gerente_de_obras',
  },
  {
    label: 'Gerente de Oficina',
    value: 'gerente_de_oficina',
  },
  {
    label: 'Gerente de Orçamento',
    value: 'gerente_de_orcamento',
  },
  {
    label: 'Gerente de Patrimônio',
    value: 'gerente_de_patrimonio',
  },
  {
    label: 'Gerente de Pcp',
    value: 'gerente_de_pcp',
  },
  {
    label: 'Gerente de Pesquisa E Desenvolvimento',
    value: 'gerente_de_pesquisa_e_desenvolvimento',
  },
  {
    label: 'Gerente de Peças',
    value: 'gerente_de_pecas',
  },
  {
    label: 'Gerente de Planejamento',
    value: 'gerente_de_planejamento',
  },
  {
    label: 'Gerente de Prevenção de Perdas',
    value: 'gerente_de_prevencao_de_perdas',
  },
  {
    label: 'Gerente de Pricing',
    value: 'gerente_de_pricing',
  },
  {
    label: 'Gerente de Processos',
    value: 'gerente_de_processos',
  },
  {
    label: 'Gerente de Produto',
    value: 'gerente_de_produto',
  },
  {
    label: 'Gerente de Produção',
    value: 'gerente_de_producao',
  },
  {
    label: 'Gerente de Projeto',
    value: 'gerente_de_projeto',
  },
  {
    label: 'Gerente de Projetos Pmo',
    value: 'gerente_de_projetos_pmo',
  },
  {
    label: 'Gerente de Projetos Web',
    value: 'gerente_de_projetos_web',
  },
  {
    label: 'Gerente de Projetos',
    value: 'gerente_de_projetos',
  },
  {
    label: 'Gerente de Pós-venda',
    value: 'gerente_de_posvenda',
  },
  {
    label: 'Gerente de Qsms',
    value: 'gerente_de_qsms',
  },
  {
    label: 'Gerente de Qualidade',
    value: 'gerente_de_qualidade',
  },
  {
    label: 'Gerente de Recepção',
    value: 'gerente_de_recepcao',
  },
  {
    label: 'Gerente de Recrutamento E Seleção',
    value: 'gerente_de_recrutamento_e_selecao',
  },
  {
    label: 'Gerente de Recursos Humanos',
    value: 'gerente_de_recursos_humanos',
  },
  {
    label: 'Gerente de Reservas',
    value: 'gerente_de_reservas',
  },
  {
    label: 'Gerente de Responsabilidade Social',
    value: 'gerente_de_responsabilidade_social',
  },
  {
    label: 'Gerente de Restaurante',
    value: 'gerente_de_restaurante',
  },
  {
    label: 'Gerente de Saúde, Segurança E Meio Ambiente',
    value: 'gerente_de_saude_seguranca_e_meio_ambiente',
  },
  {
    label: 'Gerente de Segurança Da Informação',
    value: 'gerente_de_seguranca_da_informacao',
  },
  {
    label: 'Gerente de Segurança Do Trabalho',
    value: 'gerente_de_seguranca_do_trabalho',
  },
  {
    label: 'Gerente de Segurança Patrimonial',
    value: 'gerente_de_seguranca_patrimonial',
  },
  {
    label: 'Gerente de Seguros',
    value: 'gerente_de_seguros',
  },
  {
    label: 'Gerente de Sem',
    value: 'gerente_de_sem',
  },
  {
    label: 'Gerente de Seo',
    value: 'gerente_de_seo',
  },
  {
    label: 'Gerente de Serviços',
    value: 'gerente_de_servicos',
  },
  {
    label: 'Gerente de Sinistros',
    value: 'gerente_de_sinistros',
  },
  {
    label: 'Gerente de Suporte Técnico',
    value: 'gerente_de_suporte_tecnico',
  },
  {
    label: 'Gerente de Suprimentos',
    value: 'gerente_de_suprimentos',
  },
  {
    label: 'Gerente de Tecnologia Da Informação',
    value: 'gerente_de_tecnologia_da_informacao',
  },
  {
    label: 'Gerente de Telecomunicações',
    value: 'gerente_de_telecomunicacoes',
  },
  {
    label: 'Gerente de Tesouraria',
    value: 'gerente_de_tesouraria',
  },
  {
    label: 'Gerente de Trade Marketing',
    value: 'gerente_de_trade_marketing',
  },
  {
    label: 'Gerente de Transportes',
    value: 'gerente_de_transportes',
  },
  {
    label: 'Gerente de Treinamento E Desenvolvimento',
    value: 'gerente_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Gerente de Turismo',
    value: 'gerente_de_turismo',
  },
  {
    label: 'Gerente de Vendas',
    value: 'gerente_de_vendas',
  },
  {
    label: 'Gerente Financeiro',
    value: 'gerente_financeiro',
  },
  {
    label: 'Gerente Fiscal',
    value: 'gerente_fiscal',
  },
  {
    label: 'Gerente Geral',
    value: 'gerente_geral',
  },
  {
    label: 'Gerente Imobiliário',
    value: 'gerente_imobiliario',
  },
  {
    label: 'Gerente Industrial',
    value: 'gerente_industrial',
  },
  {
    label: 'Gerente Jurídico',
    value: 'gerente_juridico',
  },
  {
    label: 'Gerente Médico',
    value: 'gerente_medico',
  },
  {
    label: 'Gerente Operacional',
    value: 'gerente_operacional',
  },
  {
    label: 'Gerente Pedagógico',
    value: 'gerente_pedagogico',
  },
  {
    label: 'Gerente Predial',
    value: 'gerente_predial',
  },
  {
    label: 'Gerente Tributário',
    value: 'gerente_tributario',
  },
  {
    label: 'Gerente Técnico',
    value: 'gerente_tecnico',
  },
  {
    label: 'Gerontólogo',
    value: 'gerontologo',
  },
  {
    label: 'Gesseiro',
    value: 'gesseiro',
  },
  {
    label: 'Gestor Ambiental',
    value: 'gestor_ambiental',
  },
  {
    label: 'Gestor Portuário',
    value: 'gestor_portuario',
  },
  {
    label: 'Geógrafo',
    value: 'geografo',
  },
  {
    label: 'Geólogo',
    value: 'geologo',
  },
  {
    label: 'Governanta',
    value: 'governanta',
  },
  {
    label: 'Greidista',
    value: 'greidista',
  },
  {
    label: 'Guia de Turismo',
    value: 'guia_de_turismo',
  },
  {
    label: 'Historiador',
    value: 'historiador',
  },
  {
    label: 'Ilustrador',
    value: 'ilustrador',
  },
  {
    label: 'Impermeabilizador',
    value: 'impermeabilizador',
  },
  {
    label: 'Implantador de Sistemas',
    value: 'implantador_de_sistemas',
  },
  {
    label: 'Impressor de Rotogravura',
    value: 'impressor_de_rotogravura',
  },
  {
    label: 'Impressor Digital',
    value: 'impressor_digital',
  },
  {
    label: 'Impressor Flexográfico',
    value: 'impressor_flexografico',
  },
  {
    label: 'Impressor Off-set',
    value: 'impressor_offset',
  },
  {
    label: 'Impressor Serigráfico',
    value: 'impressor_serigrafico',
  },
  {
    label: 'Impressor Tampográfico',
    value: 'impressor_tampografico',
  },
  {
    label: 'Impressor',
    value: 'impressor',
  },
  {
    label: 'Inspetor de Alunos',
    value: 'inspetor_de_alunos',
  },
  {
    label: 'Inspetor de Equipamentos',
    value: 'inspetor_de_equipamentos',
  },
  {
    label: 'Inspetor de Fabricação',
    value: 'inspetor_de_fabricacao',
  },
  {
    label: 'Inspetor de Frota',
    value: 'inspetor_de_frota',
  },
  {
    label: 'Inspetor de Pintura',
    value: 'inspetor_de_pintura',
  },
  {
    label: 'Inspetor de Qualidade',
    value: 'inspetor_de_qualidade',
  },
  {
    label: 'Inspetor de Riscos',
    value: 'inspetor_de_riscos',
  },
  {
    label: 'Inspetor de Segurança',
    value: 'inspetor_de_seguranca',
  },
  {
    label: 'Inspetor de Solda',
    value: 'inspetor_de_solda',
  },
  {
    label: 'Inspetor Veicular',
    value: 'inspetor_veicular',
  },
  {
    label: 'Instalador de Acessórios',
    value: 'instalador_de_acessorios',
  },
  {
    label: 'Instalador de Alarmes',
    value: 'instalador_de_alarmes',
  },
  {
    label: 'Instalador de Ar Condicionado',
    value: 'instalador_de_ar_condicionado',
  },
  {
    label: 'Instalador de Comunicação Visual',
    value: 'instalador_de_comunicacao_visual',
  },
  {
    label: 'Instalador de Equipamentos Eletrônicos',
    value: 'instalador_de_equipamentos_eletronicos',
  },
  {
    label: 'Instalador de Insulfilm',
    value: 'instalador_de_insulfilm',
  },
  {
    label: 'Instalador de Rastreador Veicular',
    value: 'instalador_de_rastreador_veicular',
  },
  {
    label: 'Instalador de Telecomunicações',
    value: 'instalador_de_telecomunicacoes',
  },
  {
    label: 'Instalador de Tv A Cabo',
    value: 'instalador_de_tv_a_cabo',
  },
  {
    label: 'Instalador de Vidros Automotivos',
    value: 'instalador_de_vidros_automotivos',
  },
  {
    label: 'Instalador de Vidros',
    value: 'instalador_de_vidros',
  },
  {
    label: 'Instalador',
    value: 'instalador',
  },
  {
    label: 'Instrumentador Cirúrgico',
    value: 'instrumentador_cirurgico',
  },
  {
    label: 'Instrumentista',
    value: 'instrumentista',
  },
  {
    label: 'Instrutor de Autoescola',
    value: 'instrutor_de_autoescola',
  },
  {
    label: 'Instrutor de Aviação',
    value: 'instrutor_de_aviacao',
  },
  {
    label: 'Instrutor de Cabeleireiro',
    value: 'instrutor_de_cabeleireiro',
  },
  {
    label: 'Instrutor de Curso',
    value: 'instrutor_de_curso',
  },
  {
    label: 'Instrutor de Excel',
    value: 'instrutor_de_excel',
  },
  {
    label: 'Instrutor de Motorista',
    value: 'instrutor_de_motorista',
  },
  {
    label: 'Instrutor de Pilates',
    value: 'instrutor_de_pilates',
  },
  {
    label: 'Instrutor de Qualidade',
    value: 'instrutor_de_qualidade',
  },
  {
    label: 'Instrutor de Treinamento',
    value: 'instrutor_de_treinamento',
  },
  {
    label: 'Intérprete de Libras',
    value: 'interprete_de_libras',
  },
  {
    label: 'Intérprete',
    value: 'interprete',
  },
  {
    label: 'Jardineiro',
    value: 'jardineiro',
  },
  {
    label: 'Jatista',
    value: 'jatista',
  },
  {
    label: 'Jornaleiro',
    value: 'jornaleiro',
  },
  {
    label: 'Jornalista',
    value: 'jornalista',
  },
  {
    label: 'Laboratorista de Concreto',
    value: 'laboratorista_de_concreto',
  },
  {
    label: 'Laboratorista de Solos',
    value: 'laboratorista_de_solos',
  },
  {
    label: 'Laboratorista Fotográfico',
    value: 'laboratorista_fotografico',
  },
  {
    label: 'Lactarista',
    value: 'lactarista',
  },
  {
    label: 'Laminador',
    value: 'laminador',
  },
  {
    label: 'Lancheiro',
    value: 'lancheiro',
  },
  {
    label: 'Lapidador de Gemas',
    value: 'lapidador_de_gemas',
  },
  {
    label: 'Lapidador de Vidros',
    value: 'lapidador_de_vidros',
  },
  {
    label: 'Laqueador',
    value: 'laqueador',
  },
  {
    label: 'Lavadeiro',
    value: 'lavadeiro',
  },
  {
    label: 'Lavador de Veículos',
    value: 'lavador_de_veiculos',
  },
  {
    label: 'Lavador',
    value: 'lavador',
  },
  {
    label: 'Layoutista',
    value: 'layoutista',
  },
  {
    label: 'Leiturista',
    value: 'leiturista',
  },
  {
    label: 'Letrista',
    value: 'letrista',
  },
  {
    label: 'Limpador de Vidros',
    value: 'limpador_de_vidros',
  },
  {
    label: 'Limpador',
    value: 'limpador',
  },
  {
    label: 'Lingotador',
    value: 'lingotador',
  },
  {
    label: 'Lixador',
    value: 'lixador',
  },
  {
    label: 'Locutor',
    value: 'locutor',
  },
  {
    label: 'Lubrificador',
    value: 'lubrificador',
  },
  {
    label: 'Lustrador de Móveis',
    value: 'lustrador_de_moveis',
  },
  {
    label: 'Líder Comercial',
    value: 'lider_comercial',
  },
  {
    label: 'Líder de Atendimento',
    value: 'lider_de_atendimento',
  },
  {
    label: 'Líder de Caixa',
    value: 'lider_de_caixa',
  },
  {
    label: 'Líder de Cobrança',
    value: 'lider_de_cobranca',
  },
  {
    label: 'Líder de Controle de Qualidade',
    value: 'lider_de_controle_de_qualidade',
  },
  {
    label: 'Líder de Equipe',
    value: 'lider_de_equipe',
  },
  {
    label: 'Líder de Expedição',
    value: 'lider_de_expedicao',
  },
  {
    label: 'Líder de Extrusão',
    value: 'lider_de_extrusao',
  },
  {
    label: 'Líder de Help Desk',
    value: 'lider_de_help_desk',
  },
  {
    label: 'Líder de Logística',
    value: 'lider_de_logistica',
  },
  {
    label: 'Líder de Loja',
    value: 'lider_de_loja',
  },
  {
    label: 'Líder de Manutenção Elétrica',
    value: 'lider_de_manutencao_eletrica',
  },
  {
    label: 'Líder de Manutenção',
    value: 'lider_de_manutencao',
  },
  {
    label: 'Líder de Merchandising',
    value: 'lider_de_merchandising',
  },
  {
    label: 'Líder de Montagem',
    value: 'lider_de_montagem',
  },
  {
    label: 'Líder de Pintura',
    value: 'lider_de_pintura',
  },
  {
    label: 'Líder de Portaria',
    value: 'lider_de_portaria',
  },
  {
    label: 'Líder de Produção',
    value: 'lider_de_producao',
  },
  {
    label: 'Líder de Recepção',
    value: 'lider_de_recepcao',
  },
  {
    label: 'Líder de Refrigeração',
    value: 'lider_de_refrigeracao',
  },
  {
    label: 'Líder de Testes',
    value: 'lider_de_testes',
  },
  {
    label: 'Líder de Usinagem',
    value: 'lider_de_usinagem',
  },
  {
    label: 'Líder de Vendas',
    value: 'lider_de_vendas',
  },
  {
    label: 'Líder de Visual Merchandising',
    value: 'lider_de_visual_merchandising',
  },
  {
    label: 'Magarefe',
    value: 'magarefe',
  },
  {
    label: 'Mandrilhador',
    value: 'mandrilhador',
  },
  {
    label: 'Manicure E Pedicure',
    value: 'manicure_e_pedicure',
  },
  {
    label: 'Manipulador de Cosméticos',
    value: 'manipulador_de_cosmeticos',
  },
  {
    label: 'Manipulador de Farmácia',
    value: 'manipulador_de_farmacia',
  },
  {
    label: 'Manobrista',
    value: 'manobrista',
  },
  {
    label: 'Maqueiro',
    value: 'maqueiro',
  },
  {
    label: 'Maquetista',
    value: 'maquetista',
  },
  {
    label: 'Maquiador',
    value: 'maquiador',
  },
  {
    label: 'Maquinista de Trem',
    value: 'maquinista_de_trem',
  },
  {
    label: 'Marceneiro',
    value: 'marceneiro',
  },
  {
    label: 'Marinheiro',
    value: 'marinheiro',
  },
  {
    label: 'Marmorista',
    value: 'marmorista',
  },
  {
    label: 'Marteleteiro',
    value: 'marteleteiro',
  },
  {
    label: 'Masseiro',
    value: 'masseiro',
  },
  {
    label: 'Massoterapeuta',
    value: 'massoterapeuta',
  },
  {
    label: 'Matemático',
    value: 'matematico',
  },
  {
    label: 'Maçariqueiro',
    value: 'macariqueiro',
  },
  {
    label: 'Maître',
    value: 'maitre',
  },
  {
    label: 'Mecânico Compressor',
    value: 'mecanico_compressor',
  },
  {
    label: 'Mecânico de Ar Condicionado',
    value: 'mecanico_de_ar_condicionado',
  },
  {
    label: 'Mecânico de Bicicletas',
    value: 'mecanico_de_bicicletas',
  },
  {
    label: 'Mecânico de Empilhadeira',
    value: 'mecanico_de_empilhadeira',
  },
  {
    label: 'Mecânico de Manutenção de Aeronaves',
    value: 'mecanico_de_manutencao_de_aeronaves',
  },
  {
    label: 'Mecânico de Manutenção Hidráulica',
    value: 'mecanico_de_manutencao_hidraulica',
  },
  {
    label: 'Mecânico de Manutenção Predial',
    value: 'mecanico_de_manutencao_predial',
  },
  {
    label: 'Mecânico de Manutenção',
    value: 'mecanico_de_manutencao',
  },
  {
    label: 'Mecânico de Máquinas',
    value: 'mecanico_de_maquinas',
  },
  {
    label: 'Mecânico de Refrigeração',
    value: 'mecanico_de_refrigeracao',
  },
  {
    label: 'Mecânico de Sopro',
    value: 'mecanico_de_sopro',
  },
  {
    label: 'Mecânico de Usinagem',
    value: 'mecanico_de_usinagem',
  },
  {
    label: 'Mecânico de Veículos',
    value: 'mecanico_de_veiculos',
  },
  {
    label: 'Mecânico Eletricista',
    value: 'mecanico_eletricista',
  },
  {
    label: 'Mecânico Hidráulico',
    value: 'mecanico_hidraulico',
  },
  {
    label: 'Mecânico Industrial',
    value: 'mecanico_industrial',
  },
  {
    label: 'Mecânico Lubrificador',
    value: 'mecanico_lubrificador',
  },
  {
    label: 'Mecânico Montador',
    value: 'mecanico_montador',
  },
  {
    label: 'Mecânico',
    value: 'mecanico',
  },
  {
    label: 'Medidor de Obras',
    value: 'medidor_de_obras',
  },
  {
    label: 'Meio Oficial Ajustador Mecânico',
    value: 'meio_oficial_ajustador_mecanico',
  },
  {
    label: 'Meio Oficial Carpinteiro',
    value: 'meio_oficial_carpinteiro',
  },
  {
    label: 'Meio Oficial de Caldeiraria',
    value: 'meio_oficial_de_caldeiraria',
  },
  {
    label: 'Meio Oficial de Ferramentaria',
    value: 'meio_oficial_de_ferramentaria',
  },
  {
    label: 'Meio Oficial de Impressão Flexográfica',
    value: 'meio_oficial_de_impressao_flexografica',
  },
  {
    label: 'Meio Oficial de Manutenção Predial',
    value: 'meio_oficial_de_manutencao_predial',
  },
  {
    label: 'Meio Oficial de Manutenção',
    value: 'meio_oficial_de_manutencao',
  },
  {
    label: 'Meio Oficial de Pintura',
    value: 'meio_oficial_de_pintura',
  },
  {
    label: 'Meio Oficial de Refrigeração',
    value: 'meio_oficial_de_refrigeracao',
  },
  {
    label: 'Meio Oficial Eletricista',
    value: 'meio_oficial_eletricista',
  },
  {
    label: 'Meio Oficial Fresador Cnc',
    value: 'meio_oficial_fresador_cnc',
  },
  {
    label: 'Meio Oficial Fresador',
    value: 'meio_oficial_fresador',
  },
  {
    label: 'Meio Oficial Funileiro',
    value: 'meio_oficial_funileiro',
  },
  {
    label: 'Meio Oficial Hidráulico',
    value: 'meio_oficial_hidraulico',
  },
  {
    label: 'Meio Oficial Marceneiro',
    value: 'meio_oficial_marceneiro',
  },
  {
    label: 'Meio Oficial Mecânico de Manutenção',
    value: 'meio_oficial_mecanico_de_manutencao',
  },
  {
    label: 'Meio Oficial Mecânico',
    value: 'meio_oficial_mecanico',
  },
  {
    label: 'Meio Oficial Montador',
    value: 'meio_oficial_montador',
  },
  {
    label: 'Meio Oficial Serralheiro',
    value: 'meio_oficial_serralheiro',
  },
  {
    label: 'Meio Oficial Soldador',
    value: 'meio_oficial_soldador',
  },
  {
    label: 'Meio Oficial Torneiro Mecânico',
    value: 'meio_oficial_torneiro_mecanico',
  },
  {
    label: 'Mensageiro de Hotel',
    value: 'mensageiro_de_hotel',
  },
  {
    label: 'Mensageiro',
    value: 'mensageiro',
  },
  {
    label: 'Merendeira',
    value: 'merendeira',
  },
  {
    label: 'Mestre Cervejeiro',
    value: 'mestre_cervejeiro',
  },
  {
    label: 'Mestre de Cabotagem',
    value: 'mestre_de_cabotagem',
  },
  {
    label: 'Mestre de Obras',
    value: 'mestre_de_obras',
  },
  {
    label: 'Meteorologista',
    value: 'meteorologista',
  },
  {
    label: 'Metrologista',
    value: 'metrologista',
  },
  {
    label: 'Microbiologista',
    value: 'microbiologista',
  },
  {
    label: 'Modelista',
    value: 'modelista',
  },
  {
    label: 'Modelo de Prova',
    value: 'modelo_de_prova',
  },
  {
    label: 'Modelo',
    value: 'modelo',
  },
  {
    label: 'Moldador',
    value: 'moldador',
  },
  {
    label: 'Moldureiro',
    value: 'moldureiro',
  },
  {
    label: 'Moleiro',
    value: 'moleiro',
  },
  {
    label: 'Monitor de Alunos',
    value: 'monitor_de_alunos',
  },
  {
    label: 'Monitor de Informática',
    value: 'monitor_de_informatica',
  },
  {
    label: 'Monitor de Qualidade',
    value: 'monitor_de_qualidade',
  },
  {
    label: 'Montador de Calçados',
    value: 'montador_de_calcados',
  },
  {
    label: 'Montador de Elevador',
    value: 'montador_de_elevador',
  },
  {
    label: 'Montador de Equipamentos',
    value: 'montador_de_equipamentos',
  },
  {
    label: 'Montador de Esquadrias',
    value: 'montador_de_esquadrias',
  },
  {
    label: 'Montador de Estruturas Metálicas',
    value: 'montador_de_estruturas_metalicas',
  },
  {
    label: 'Montador de Máquinas',
    value: 'montador_de_maquinas',
  },
  {
    label: 'Montador de Móveis',
    value: 'montador_de_moveis',
  },
  {
    label: 'Montador de Painéis',
    value: 'montador_de_paineis',
  },
  {
    label: 'Montador de Veículos',
    value: 'montador_de_veiculos',
  },
  {
    label: 'Montador Eletrônico',
    value: 'montador_eletronico',
  },
  {
    label: 'Montador Elétrico',
    value: 'montador_eletrico',
  },
  {
    label: 'Montador Hidráulico',
    value: 'montador_hidraulico',
  },
  {
    label: 'Montador',
    value: 'montador',
  },
  {
    label: 'Mordomo',
    value: 'mordomo',
  },
  {
    label: 'Motoboy',
    value: 'motoboy',
  },
  {
    label: 'Motorista Administrativo',
    value: 'motorista_administrativo',
  },
  {
    label: 'Motorista Categoria B',
    value: 'motorista_categoria_b',
  },
  {
    label: 'Motorista Categoria C',
    value: 'motorista_categoria_c',
  },
  {
    label: 'Motorista Categoria D',
    value: 'motorista_categoria_d',
  },
  {
    label: 'Motorista Categoria E',
    value: 'motorista_categoria_e',
  },
  {
    label: 'Motorista de Ambulância',
    value: 'motorista_de_ambulancia',
  },
  {
    label: 'Motorista de Caminhão',
    value: 'motorista_de_caminhao',
  },
  {
    label: 'Motorista de Carro Forte',
    value: 'motorista_de_carro_forte',
  },
  {
    label: 'Motorista de Guincho',
    value: 'motorista_de_guincho',
  },
  {
    label: 'Motorista de Ônibus',
    value: 'motorista_de_onibus',
  },
  {
    label: 'Motorista Entregador',
    value: 'motorista_entregador',
  },
  {
    label: 'Motorista Escolar',
    value: 'motorista_escolar',
  },
  {
    label: 'Motorista Executivo',
    value: 'motorista_executivo',
  },
  {
    label: 'Motorista Operador de Betoneira',
    value: 'motorista_operador_de_betoneira',
  },
  {
    label: 'Motorista',
    value: 'motorista',
  },
  {
    label: 'Museólogo',
    value: 'museologo',
  },
  {
    label: 'Musicoterapeuta',
    value: 'musicoterapeuta',
  },
  {
    label: 'Mãe Social',
    value: 'mae_social',
  },
  {
    label: 'Médico Acupunturista',
    value: 'medico_acupunturista',
  },
  {
    label: 'Médico Alergologista',
    value: 'medico_alergologista',
  },
  {
    label: 'Médico Ambulatorial',
    value: 'medico_ambulatorial',
  },
  {
    label: 'Médico Anestesista',
    value: 'medico_anestesista',
  },
  {
    label: 'Médico Auditor',
    value: 'medico_auditor',
  },
  {
    label: 'Médico Cardiologista',
    value: 'medico_cardiologista',
  },
  {
    label: 'Médico Cirurgião Plástico',
    value: 'medico_cirurgiao_plastico',
  },
  {
    label: 'Médico Cirurgião Torácico',
    value: 'medico_cirurgiao_toracico',
  },
  {
    label: 'Médico Cirurgião Vascular',
    value: 'medico_cirurgiao_vascular',
  },
  {
    label: 'Médico Cirurgião',
    value: 'medico_cirurgiao',
  },
  {
    label: 'Médico Clínico Geral',
    value: 'medico_clinico_geral',
  },
  {
    label: 'Médico Colonoscopista',
    value: 'medico_colonoscopista',
  },
  {
    label: 'Médico Dermatologista',
    value: 'medico_dermatologista',
  },
  {
    label: 'Médico Do Esporte',
    value: 'medico_do_esporte',
  },
  {
    label: 'Médico Do Trabalho',
    value: 'medico_do_trabalho',
  },
  {
    label: 'Médico Do Tráfego',
    value: 'medico_do_trafego',
  },
  {
    label: 'Médico Ecocardiografista',
    value: 'medico_ecocardiografista',
  },
  {
    label: 'Médico Emergencista',
    value: 'medico_emergencista',
  },
  {
    label: 'Médico Endocrinologista',
    value: 'medico_endocrinologista',
  },
  {
    label: 'Médico Esteticista',
    value: 'medico_esteticista',
  },
  {
    label: 'Médico Fisiatra',
    value: 'medico_fisiatra',
  },
  {
    label: 'Médico Gastroenterologista',
    value: 'medico_gastroenterologista',
  },
  {
    label: 'Médico Gastropediatra',
    value: 'medico_gastropediatra',
  },
  {
    label: 'Médico Geneticista',
    value: 'medico_geneticista',
  },
  {
    label: 'Médico Geriatra',
    value: 'medico_geriatra',
  },
  {
    label: 'Médico Ginecologista',
    value: 'medico_ginecologista',
  },
  {
    label: 'Médico Hebiatra',
    value: 'medico_hebiatra',
  },
  {
    label: 'Médico Hematologista',
    value: 'medico_hematologista',
  },
  {
    label: 'Médico Hemoterapeuta',
    value: 'medico_hemoterapeuta',
  },
  {
    label: 'Médico Homeopata',
    value: 'medico_homeopata',
  },
  {
    label: 'Médico Imunologista',
    value: 'medico_imunologista',
  },
  {
    label: 'Médico Infectologista',
    value: 'medico_infectologista',
  },
  {
    label: 'Médico Intensivista',
    value: 'medico_intensivista',
  },
  {
    label: 'Médico Mamografista',
    value: 'medico_mamografista',
  },
  {
    label: 'Médico Mastologista',
    value: 'medico_mastologista',
  },
  {
    label: 'Médico Nefrologista',
    value: 'medico_nefrologista',
  },
  {
    label: 'Médico Neurocirurgião',
    value: 'medico_neurocirurgiao',
  },
  {
    label: 'Médico Neurologista',
    value: 'medico_neurologista',
  },
  {
    label: 'Médico Nutrólogo',
    value: 'medico_nutrologo',
  },
  {
    label: 'Médico Obstetra',
    value: 'medico_obstetra',
  },
  {
    label: 'Médico Oftalmologista',
    value: 'medico_oftalmologista',
  },
  {
    label: 'Médico Oncologista',
    value: 'medico_oncologista',
  },
  {
    label: 'Médico Ortomolecular',
    value: 'medico_ortomolecular',
  },
  {
    label: 'Médico Ortopedista',
    value: 'medico_ortopedista',
  },
  {
    label: 'Médico Otorrinolaringologista',
    value: 'medico_otorrinolaringologista',
  },
  {
    label: 'Médico Patologista',
    value: 'medico_patologista',
  },
  {
    label: 'Médico Pediatra',
    value: 'medico_pediatra',
  },
  {
    label: 'Médico Plantonista',
    value: 'medico_plantonista',
  },
  {
    label: 'Médico Pneumologista',
    value: 'medico_pneumologista',
  },
  {
    label: 'Médico Proctologista',
    value: 'medico_proctologista',
  },
  {
    label: 'Médico Psiquiatra',
    value: 'medico_psiquiatra',
  },
  {
    label: 'Médico Radiologista',
    value: 'medico_radiologista',
  },
  {
    label: 'Médico Regulador',
    value: 'medico_regulador',
  },
  {
    label: 'Médico Reumatologista',
    value: 'medico_reumatologista',
  },
  {
    label: 'Médico Traumatologista',
    value: 'medico_traumatologista',
  },
  {
    label: 'Médico Ultrassonografista',
    value: 'medico_ultrassonografista',
  },
  {
    label: 'Médico Urologista',
    value: 'medico_urologista',
  },
  {
    label: 'Médico Visitador',
    value: 'medico_visitador',
  },
  {
    label: 'Músico',
    value: 'musico',
  },
  {
    label: 'Naturólogo',
    value: 'naturologo',
  },
  {
    label: 'Neuropsicólogo',
    value: 'neuropsicologo',
  },
  {
    label: 'Nivelador',
    value: 'nivelador',
  },
  {
    label: 'Nutricionista Clínico',
    value: 'nutricionista_clinico',
  },
  {
    label: 'Nutricionista Comercial',
    value: 'nutricionista_comercial',
  },
  {
    label: 'Nutricionista de Produção',
    value: 'nutricionista_de_producao',
  },
  {
    label: 'Nutricionista de Qualidade',
    value: 'nutricionista_de_qualidade',
  },
  {
    label: 'Nutricionista Do Esporte',
    value: 'nutricionista_do_esporte',
  },
  {
    label: 'Nutricionista',
    value: 'nutricionista',
  },
  {
    label: 'Oceanógrafo',
    value: 'oceanografo',
  },
  {
    label: 'Office-boy',
    value: 'officeboy',
  },
  {
    label: 'Oficial de Manutenção Predial',
    value: 'oficial_de_manutencao_predial',
  },
  {
    label: 'Oficial de Manutenção',
    value: 'oficial_de_manutencao',
  },
  {
    label: 'Oficial de Redes',
    value: 'oficial_de_redes',
  },
  {
    label: 'Oficial de Refrigeração',
    value: 'oficial_de_refrigeracao',
  },
  {
    label: 'Oficial Eletricista',
    value: 'oficial_eletricista',
  },
  {
    label: 'Oficial Mecânico',
    value: 'oficial_mecanico',
  },
  {
    label: 'Oficial Serralheiro',
    value: 'oficial_serralheiro',
  },
  {
    label: 'Operador Audiovisual',
    value: 'operador_audiovisual',
  },
  {
    label: 'Operador de Balança Rodoviária',
    value: 'operador_de_balanca_rodoviaria',
  },
  {
    label: 'Operador de Bomba de Concreto',
    value: 'operador_de_bomba_de_concreto',
  },
  {
    label: 'Operador de Caixa',
    value: 'operador_de_caixa',
  },
  {
    label: 'Operador de Calandra',
    value: 'operador_de_calandra',
  },
  {
    label: 'Operador de Caldeira',
    value: 'operador_de_caldeira',
  },
  {
    label: 'Operador de Call Center',
    value: 'operador_de_call_center',
  },
  {
    label: 'Operador de Central de Concreto',
    value: 'operador_de_central_de_concreto',
  },
  {
    label: 'Operador de Centro de Usinagem',
    value: 'operador_de_centro_de_usinagem',
  },
  {
    label: 'Operador de Cftv',
    value: 'operador_de_cftv',
  },
  {
    label: 'Operador de Cnc',
    value: 'operador_de_cnc',
  },
  {
    label: 'Operador de Cobrança',
    value: 'operador_de_cobranca',
  },
  {
    label: 'Operador de Coladeira',
    value: 'operador_de_coladeira',
  },
  {
    label: 'Operador de Computador',
    value: 'operador_de_computador',
  },
  {
    label: 'Operador de Controle Mestre',
    value: 'operador_de_controle_mestre',
  },
  {
    label: 'Operador de Corte E Dobra',
    value: 'operador_de_corte_e_dobra',
  },
  {
    label: 'Operador de Corte E Solda',
    value: 'operador_de_corte_e_solda',
  },
  {
    label: 'Operador de Corte E Vinco',
    value: 'operador_de_corte_e_vinco',
  },
  {
    label: 'Operador de Câmaras Frias',
    value: 'operador_de_camaras_frias',
  },
  {
    label: 'Operador de Câmbio',
    value: 'operador_de_cambio',
  },
  {
    label: 'Operador de Dobradeira',
    value: 'operador_de_dobradeira',
  },
  {
    label: 'Operador de Eletroerosão',
    value: 'operador_de_eletroerosao',
  },
  {
    label: 'Operador de Empilhadeira',
    value: 'operador_de_empilhadeira',
  },
  {
    label: 'Operador de Equipamento',
    value: 'operador_de_equipamento',
  },
  {
    label: 'Operador de Escavadeira',
    value: 'operador_de_escavadeira',
  },
  {
    label: 'Operador de Estacionamento',
    value: 'operador_de_estacionamento',
  },
  {
    label: 'Operador de Estação de Tratamento de Águas E Efluentes',
    value: 'operador_de_estacao_de_tratamento_de_aguas_e_efluentes',
  },
  {
    label: 'Operador de Extrusora',
    value: 'operador_de_extrusora',
  },
  {
    label: 'Operador de Factoring',
    value: 'operador_de_factoring',
  },
  {
    label: 'Operador de Furadeira',
    value: 'operador_de_furadeira',
  },
  {
    label: 'Operador de Guilhotina',
    value: 'operador_de_guilhotina',
  },
  {
    label: 'Operador de Guincho',
    value: 'operador_de_guincho',
  },
  {
    label: 'Operador de Guindaste',
    value: 'operador_de_guindaste',
  },
  {
    label: 'Operador de Injetora',
    value: 'operador_de_injetora',
  },
  {
    label: 'Operador de Laminação',
    value: 'operador_de_laminacao',
  },
  {
    label: 'Operador de Lixadeira',
    value: 'operador_de_lixadeira',
  },
  {
    label: 'Operador de Logística',
    value: 'operador_de_logistica',
  },
  {
    label: 'Operador de Mesa',
    value: 'operador_de_mesa',
  },
  {
    label: 'Operador de Monitoramento',
    value: 'operador_de_monitoramento',
  },
  {
    label: 'Operador de Montagem',
    value: 'operador_de_montagem',
  },
  {
    label: 'Operador de Motoniveladora',
    value: 'operador_de_motoniveladora',
  },
  {
    label: 'Operador de Motosserra',
    value: 'operador_de_motosserra',
  },
  {
    label: 'Operador de Máquina Agrícola',
    value: 'operador_de_maquina_agricola',
  },
  {
    label: 'Operador de Máquina Copiadora',
    value: 'operador_de_maquina_copiadora',
  },
  {
    label: 'Operador de Máquina de Corte',
    value: 'operador_de_maquina_de_corte',
  },
  {
    label: 'Operador de Máquina de Sopro',
    value: 'operador_de_maquina_de_sopro',
  },
  {
    label: 'Operador de Máquina Perfuratriz',
    value: 'operador_de_maquina_perfuratriz',
  },
  {
    label: 'Operador de Máquina',
    value: 'operador_de_maquina',
  },
  {
    label: 'Operador de Negócios',
    value: 'operador_de_negocios',
  },
  {
    label: 'Operador de Onduladeira',
    value: 'operador_de_onduladeira',
  },
  {
    label: 'Operador de Perfiladeira',
    value: 'operador_de_perfiladeira',
  },
  {
    label: 'Operador de Photoshop',
    value: 'operador_de_photoshop',
  },
  {
    label: 'Operador de Plotter',
    value: 'operador_de_plotter',
  },
  {
    label: 'Operador de Ponte Rolante',
    value: 'operador_de_ponte_rolante',
  },
  {
    label: 'Operador de Prensa',
    value: 'operador_de_prensa',
  },
  {
    label: 'Operador de Processos Químicos',
    value: 'operador_de_processos_quimicos',
  },
  {
    label: 'Operador de Puncionadeira',
    value: 'operador_de_puncionadeira',
  },
  {
    label: 'Operador de Pá Carregadeira',
    value: 'operador_de_pa_carregadeira',
  },
  {
    label: 'Operador de Rama',
    value: 'operador_de_rama',
  },
  {
    label: 'Operador de Rastreamento',
    value: 'operador_de_rastreamento',
  },
  {
    label: 'Operador de Rebocador',
    value: 'operador_de_rebocador',
  },
  {
    label: 'Operador de Redes',
    value: 'operador_de_redes',
  },
  {
    label: 'Operador de Refrigeração',
    value: 'operador_de_refrigeracao',
  },
  {
    label: 'Operador de Retificadora',
    value: 'operador_de_retificadora',
  },
  {
    label: 'Operador de Retroescavadeira',
    value: 'operador_de_retroescavadeira',
  },
  {
    label: 'Operador de Rolo Compactador',
    value: 'operador_de_rolo_compactador',
  },
  {
    label: 'Operador de Rádio',
    value: 'operador_de_radio',
  },
  {
    label: 'Operador de Sala de Máquinas',
    value: 'operador_de_sala_de_maquinas',
  },
  {
    label: 'Operador de Serra',
    value: 'operador_de_serra',
  },
  {
    label: 'Operador de Telemarketing',
    value: 'operador_de_telemarketing',
  },
  {
    label: 'Operador de Televendas',
    value: 'operador_de_televendas',
  },
  {
    label: 'Operador de Torno Automático',
    value: 'operador_de_torno_automatico',
  },
  {
    label: 'Operador de Torno Cnc',
    value: 'operador_de_torno_cnc',
  },
  {
    label: 'Operador de Torno',
    value: 'operador_de_torno',
  },
  {
    label: 'Operador de Tráfego',
    value: 'operador_de_trafego',
  },
  {
    label: 'Operador de Turismo',
    value: 'operador_de_turismo',
  },
  {
    label: 'Operador de Utilidades',
    value: 'operador_de_utilidades',
  },
  {
    label: 'Operador de Zincagem',
    value: 'operador_de_zincagem',
  },
  {
    label: 'Operador de Áudio',
    value: 'operador_de_audio',
  },
  {
    label: 'Operador Mantenedor',
    value: 'operador_mantenedor',
  },
  {
    label: 'Orientador Educacional',
    value: 'orientador_educacional',
  },
  {
    label: 'Orientador Sócio Educativo',
    value: 'orientador_socio_educativo',
  },
  {
    label: 'Orçamentista Civil',
    value: 'orcamentista_civil',
  },
  {
    label: 'Orçamentista',
    value: 'orcamentista',
  },
  {
    label: 'Ourives',
    value: 'ourives',
  },
  {
    label: 'Padeiro',
    value: 'padeiro',
  },
  {
    label: 'Paisagista',
    value: 'paisagista',
  },
  {
    label: 'Palestrante',
    value: 'palestrante',
  },
  {
    label: 'Panfleteiro',
    value: 'panfleteiro',
  },
  {
    label: 'Passador',
    value: 'passador',
  },
  {
    label: 'Pedagogo Empresarial',
    value: 'pedagogo_empresarial',
  },
  {
    label: 'Pedagogo',
    value: 'pedagogo',
  },
  {
    label: 'Pedreiro',
    value: 'pedreiro',
  },
  {
    label: 'Peixeiro',
    value: 'peixeiro',
  },
  {
    label: 'Perfumista',
    value: 'perfumista',
  },
  {
    label: 'Perito Judicial',
    value: 'perito_judicial',
  },
  {
    label: 'Personal Stylist',
    value: 'personal_stylist',
  },
  {
    label: 'Personal Trainer',
    value: 'personal_trainer',
  },
  {
    label: 'Pesador',
    value: 'pesador',
  },
  {
    label: 'Pesquisador de Mercado',
    value: 'pesquisador_de_mercado',
  },
  {
    label: 'Pesquisador',
    value: 'pesquisador',
  },
  {
    label: 'Piloteiro',
    value: 'piloteiro',
  },
  {
    label: 'Piloto Agrícola',
    value: 'piloto_agricola',
  },
  {
    label: 'Piloto de Avião',
    value: 'piloto_de_aviao',
  },
  {
    label: 'Piloto de Helicóptero',
    value: 'piloto_de_helicoptero',
  },
  {
    label: 'Piloto de Testes',
    value: 'piloto_de_testes',
  },
  {
    label: 'Pintor Automotivo',
    value: 'pintor_automotivo',
  },
  {
    label: 'Pintor de Estruturas Metálicas',
    value: 'pintor_de_estruturas_metalicas',
  },
  {
    label: 'Pintor de Móveis',
    value: 'pintor_de_moveis',
  },
  {
    label: 'Pintor de Obras',
    value: 'pintor_de_obras',
  },
  {
    label: 'Pintor Eletrostático',
    value: 'pintor_eletrostatico',
  },
  {
    label: 'Pintor',
    value: 'pintor',
  },
  {
    label: 'Pizzaiolo',
    value: 'pizzaiolo',
  },
  {
    label: 'Planejador de Produção',
    value: 'planejador_de_producao',
  },
  {
    label: 'Planejador de Projetos',
    value: 'planejador_de_projetos',
  },
  {
    label: 'Podólogo',
    value: 'podologo',
  },
  {
    label: 'Polidor de Metais',
    value: 'polidor_de_metais',
  },
  {
    label: 'Polidor de Veículos',
    value: 'polidor_de_veiculos',
  },
  {
    label: 'Polidor',
    value: 'polidor',
  },
  {
    label: 'Porteiro',
    value: 'porteiro',
  },
  {
    label: 'Prensista',
    value: 'prensista',
  },
  {
    label: 'Preparador de Autos',
    value: 'preparador_de_autos',
  },
  {
    label: 'Preparador de Cnc',
    value: 'preparador_de_cnc',
  },
  {
    label: 'Preparador de Máquina Injetora',
    value: 'preparador_de_maquina_injetora',
  },
  {
    label: 'Preparador de Máquinas',
    value: 'preparador_de_maquinas',
  },
  {
    label: 'Preparador de Pintura',
    value: 'preparador_de_pintura',
  },
  {
    label: 'Preparador de Tintas',
    value: 'preparador_de_tintas',
  },
  {
    label: 'Preparador de Torno',
    value: 'preparador_de_torno',
  },
  {
    label: 'Preparador Físico',
    value: 'preparador_fisico',
  },
  {
    label: 'Processista',
    value: 'processista',
  },
  {
    label: 'Produtor Artístico',
    value: 'produtor_artistico',
  },
  {
    label: 'Produtor Cultural',
    value: 'produtor_cultural',
  },
  {
    label: 'Produtor de Casting',
    value: 'produtor_de_casting',
  },
  {
    label: 'Produtor de Conteúdo',
    value: 'produtor_de_conteudo',
  },
  {
    label: 'Produtor de Eventos',
    value: 'produtor_de_eventos',
  },
  {
    label: 'Produtor de Moda',
    value: 'produtor_de_moda',
  },
  {
    label: 'Produtor de Televisão',
    value: 'produtor_de_televisao',
  },
  {
    label: 'Produtor de Vídeo',
    value: 'produtor_de_video',
  },
  {
    label: 'Produtor Editorial',
    value: 'produtor_editorial',
  },
  {
    label: 'Produtor Gráfico',
    value: 'produtor_grafico',
  },
  {
    label: 'Produtor Musical',
    value: 'produtor_musical',
  },
  {
    label: 'Professor Auxiliar',
    value: 'professor_auxiliar',
  },
  {
    label: 'Professor de Administração de Empresas',
    value: 'professor_de_administracao_de_empresas',
  },
  {
    label: 'Professor de Administração Pública',
    value: 'professor_de_administracao_publica',
  },
  {
    label: 'Professor de Agronomia',
    value: 'professor_de_agronomia',
  },
  {
    label: 'Professor de Alemão',
    value: 'professor_de_alemao',
  },
  {
    label: 'Professor de Anatomia',
    value: 'professor_de_anatomia',
  },
  {
    label: 'Professor de Arquitetura E Urbanismo',
    value: 'professor_de_arquitetura_e_urbanismo',
  },
  {
    label: 'Professor de Artes Cênicas',
    value: 'professor_de_artes_cenicas',
  },
  {
    label: 'Professor de Artes Marciais',
    value: 'professor_de_artes_marciais',
  },
  {
    label: 'Professor de Artesanato',
    value: 'professor_de_artesanato',
  },
  {
    label: 'Professor de Autocad',
    value: 'professor_de_autocad',
  },
  {
    label: 'Professor de Automação Industrial',
    value: 'professor_de_automacao_industrial',
  },
  {
    label: 'Professor de Biologia',
    value: 'professor_de_biologia',
  },
  {
    label: 'Professor de Biomedicina',
    value: 'professor_de_biomedicina',
  },
  {
    label: 'Professor de Canto',
    value: 'professor_de_canto',
  },
  {
    label: 'Professor de Capoeira',
    value: 'professor_de_capoeira',
  },
  {
    label: 'Professor de Circo',
    value: 'professor_de_circo',
  },
  {
    label: 'Professor de Ciência Da Computação',
    value: 'professor_de_ciencia_da_computacao',
  },
  {
    label: 'Professor de Ciências Contábeis',
    value: 'professor_de_ciencias_contabeis',
  },
  {
    label: 'Professor de Computação Gráfica',
    value: 'professor_de_computacao_grafica',
  },
  {
    label: 'Professor de Comunicação Social',
    value: 'professor_de_comunicacao_social',
  },
  {
    label: 'Professor de Comunicação Visual',
    value: 'professor_de_comunicacao_visual',
  },
  {
    label: 'Professor de Comércio Exterior',
    value: 'professor_de_comercio_exterior',
  },
  {
    label: 'Professor de Construção Civil',
    value: 'professor_de_construcao_civil',
  },
  {
    label: 'Professor de Coreldraw',
    value: 'professor_de_coreldraw',
  },
  {
    label: 'Professor de Costura',
    value: 'professor_de_costura',
  },
  {
    label: 'Professor de Cálculo',
    value: 'professor_de_calculo',
  },
  {
    label: 'Professor de Dança',
    value: 'professor_de_danca',
  },
  {
    label: 'Professor de Departamento Pessoal',
    value: 'professor_de_departamento_pessoal',
  },
  {
    label: 'Professor de Desenho Técnico',
    value: 'professor_de_desenho_tecnico',
  },
  {
    label: 'Professor de Design de Interiores',
    value: 'professor_de_design_de_interiores',
  },
  {
    label: 'Professor de Design Gráfico',
    value: 'professor_de_design_grafico',
  },
  {
    label: 'Professor de Direito Administrativo',
    value: 'professor_de_direito_administrativo',
  },
  {
    label: 'Professor de Direito Constitucional',
    value: 'professor_de_direito_constitucional',
  },
  {
    label: 'Professor de Direito Penal',
    value: 'professor_de_direito_penal',
  },
  {
    label: 'Professor de Direito Tributário',
    value: 'professor_de_direito_tributario',
  },
  {
    label: 'Professor de Direito',
    value: 'professor_de_direito',
  },
  {
    label: 'Professor de Economia',
    value: 'professor_de_economia',
  },
  {
    label: 'Professor de Edificações',
    value: 'professor_de_edificacoes',
  },
  {
    label: 'Professor de Educação Artística',
    value: 'professor_de_educacao_artistica',
  },
  {
    label: 'Professor de Educação Especial',
    value: 'professor_de_educacao_especial',
  },
  {
    label: 'Professor de Educação Física',
    value: 'professor_de_educacao_fisica',
  },
  {
    label: 'Professor de Educação Infantil',
    value: 'professor_de_educacao_infantil',
  },
  {
    label: 'Professor de Eletrotécnica',
    value: 'professor_de_eletrotecnica',
  },
  {
    label: 'Professor de Eletrônica',
    value: 'professor_de_eletronica',
  },
  {
    label: 'Professor de Elétrica',
    value: 'professor_de_eletrica',
  },
  {
    label: 'Professor de Empreendedorismo',
    value: 'professor_de_empreendedorismo',
  },
  {
    label: 'Professor de Enfermagem',
    value: 'professor_de_enfermagem',
  },
  {
    label: 'Professor de Engenharia Ambiental',
    value: 'professor_de_engenharia_ambiental',
  },
  {
    label: 'Professor de Engenharia Civil',
    value: 'professor_de_engenharia_civil',
  },
  {
    label: 'Professor de Engenharia Da Computação',
    value: 'professor_de_engenharia_da_computacao',
  },
  {
    label: 'Professor de Engenharia de Produção',
    value: 'professor_de_engenharia_de_producao',
  },
  {
    label: 'Professor de Engenharia Elétrica',
    value: 'professor_de_engenharia_eletrica',
  },
  {
    label: 'Professor de Engenharia Mecatrônica',
    value: 'professor_de_engenharia_mecatronica',
  },
  {
    label: 'Professor de Engenharia Mecânica',
    value: 'professor_de_engenharia_mecanica',
  },
  {
    label: 'Professor de Engenharia',
    value: 'professor_de_engenharia',
  },
  {
    label: 'Professor de Ensino A Distância',
    value: 'professor_de_ensino_a_distancia',
  },
  {
    label: 'Professor de Ensino Fundamental',
    value: 'professor_de_ensino_fundamental',
  },
  {
    label: 'Professor de Ensino Médio',
    value: 'professor_de_ensino_medio',
  },
  {
    label: 'Professor de Ensino Religioso',
    value: 'professor_de_ensino_religioso',
  },
  {
    label: 'Professor de Ensino Técnico',
    value: 'professor_de_ensino_tecnico',
  },
  {
    label: 'Professor de Espanhol',
    value: 'professor_de_espanhol',
  },
  {
    label: 'Professor de Estatística',
    value: 'professor_de_estatistica',
  },
  {
    label: 'Professor de Estética E Cosmetologia',
    value: 'professor_de_estetica_e_cosmetologia',
  },
  {
    label: 'Professor de Expressão Corporal',
    value: 'professor_de_expressao_corporal',
  },
  {
    label: 'Professor de Farmácia',
    value: 'professor_de_farmacia',
  },
  {
    label: 'Professor de Filosofia',
    value: 'professor_de_filosofia',
  },
  {
    label: 'Professor de Fisioterapia',
    value: 'professor_de_fisioterapia',
  },
  {
    label: 'Professor de Fotografia',
    value: 'professor_de_fotografia',
  },
  {
    label: 'Professor de Francês',
    value: 'professor_de_frances',
  },
  {
    label: 'Professor de Futebol',
    value: 'professor_de_futebol',
  },
  {
    label: 'Professor de Física',
    value: 'professor_de_fisica',
  },
  {
    label: 'Professor de Gastronomia',
    value: 'professor_de_gastronomia',
  },
  {
    label: 'Professor de Geografia',
    value: 'professor_de_geografia',
  },
  {
    label: 'Professor de Gestão Ambiental',
    value: 'professor_de_gestao_ambiental',
  },
  {
    label: 'Professor de Gestão Da Qualidade',
    value: 'professor_de_gestao_da_qualidade',
  },
  {
    label: 'Professor de Gestão de Projetos',
    value: 'professor_de_gestao_de_projetos',
  },
  {
    label: 'Professor de Gestão Financeira',
    value: 'professor_de_gestao_financeira',
  },
  {
    label: 'Professor de Gestão Hospitalar',
    value: 'professor_de_gestao_hospitalar',
  },
  {
    label: 'Professor de Ginástica Laboral',
    value: 'professor_de_ginastica_laboral',
  },
  {
    label: 'Professor de Ginástica',
    value: 'professor_de_ginastica',
  },
  {
    label: 'Professor de Grego',
    value: 'professor_de_grego',
  },
  {
    label: 'Professor de Hebraico',
    value: 'professor_de_hebraico',
  },
  {
    label: 'Professor de Hidroginástica',
    value: 'professor_de_hidroginastica',
  },
  {
    label: 'Professor de História',
    value: 'professor_de_historia',
  },
  {
    label: 'Professor de Holandês',
    value: 'professor_de_holandes',
  },
  {
    label: 'Professor de Hotelaria E Turismo',
    value: 'professor_de_hotelaria_e_turismo',
  },
  {
    label: 'Professor de Hotelaria',
    value: 'professor_de_hotelaria',
  },
  {
    label: 'Professor de Idiomas',
    value: 'professor_de_idiomas',
  },
  {
    label: 'Professor de Informática',
    value: 'professor_de_informatica',
  },
  {
    label: 'Professor de Inglês E Espanhol',
    value: 'professor_de_ingles_e_espanhol',
  },
  {
    label: 'Professor de Inglês E Português',
    value: 'professor_de_ingles_e_portugues',
  },
  {
    label: 'Professor de Inglês',
    value: 'professor_de_ingles',
  },
  {
    label: 'Professor de Italiano',
    value: 'professor_de_italiano',
  },
  {
    label: 'Professor de Japonês',
    value: 'professor_de_japones',
  },
  {
    label: 'Professor de Jornalismo',
    value: 'professor_de_jornalismo',
  },
  {
    label: 'Professor de Letras',
    value: 'professor_de_letras',
  },
  {
    label: 'Professor de Libras',
    value: 'professor_de_libras',
  },
  {
    label: 'Professor de Literatura',
    value: 'professor_de_literatura',
  },
  {
    label: 'Professor de Logística',
    value: 'professor_de_logistica',
  },
  {
    label: 'Professor de Mandarim',
    value: 'professor_de_mandarim',
  },
  {
    label: 'Professor de Manicure',
    value: 'professor_de_manicure',
  },
  {
    label: 'Professor de Maquiagem',
    value: 'professor_de_maquiagem',
  },
  {
    label: 'Professor de Marketing',
    value: 'professor_de_marketing',
  },
  {
    label: 'Professor de Massoterapia',
    value: 'professor_de_massoterapia',
  },
  {
    label: 'Professor de Matemática Financeira',
    value: 'professor_de_matematica_financeira',
  },
  {
    label: 'Professor de Matemática',
    value: 'professor_de_matematica',
  },
  {
    label: 'Professor de Mecânica Aeronáutica',
    value: 'professor_de_mecanica_aeronautica',
  },
  {
    label: 'Professor de Mecânica',
    value: 'professor_de_mecanica',
  },
  {
    label: 'Professor de Medicina Do Trabalho',
    value: 'professor_de_medicina_do_trabalho',
  },
  {
    label: 'Professor de Medicina',
    value: 'professor_de_medicina',
  },
  {
    label: 'Professor de Moda',
    value: 'professor_de_moda',
  },
  {
    label: 'Professor de Musculação',
    value: 'professor_de_musculacao',
  },
  {
    label: 'Professor de Métodos E Processos',
    value: 'professor_de_metodos_e_processos',
  },
  {
    label: 'Professor de Música',
    value: 'professor_de_musica',
  },
  {
    label: 'Professor de Natação',
    value: 'professor_de_natacao',
  },
  {
    label: 'Professor de Norueguês',
    value: 'professor_de_noruegues',
  },
  {
    label: 'Professor de Nutrição',
    value: 'professor_de_nutricao',
  },
  {
    label: 'Professor de Odontologia',
    value: 'professor_de_odontologia',
  },
  {
    label: 'Professor de Oracle',
    value: 'professor_de_oracle',
  },
  {
    label: 'Professor de Pedagogia',
    value: 'professor_de_pedagogia',
  },
  {
    label: 'Professor de Petróleo E Gás',
    value: 'professor_de_petroleo_e_gas',
  },
  {
    label: 'Professor de Photoshop',
    value: 'professor_de_photoshop',
  },
  {
    label: 'Professor de Php',
    value: 'professor_de_php',
  },
  {
    label: 'Professor de Pintura em Tela',
    value: 'professor_de_pintura_em_tela',
  },
  {
    label: 'Professor de Podologia',
    value: 'professor_de_podologia',
  },
  {
    label: 'Professor de Português',
    value: 'professor_de_portugues',
  },
  {
    label: 'Professor de Programação de Computadores',
    value: 'professor_de_programacao_de_computadores',
  },
  {
    label: 'Professor de Programação de Redes',
    value: 'professor_de_programacao_de_redes',
  },
  {
    label: 'Professor de Prótese Dentária',
    value: 'professor_de_protese_dentaria',
  },
  {
    label: 'Professor de Psicanálise',
    value: 'professor_de_psicanalise',
  },
  {
    label: 'Professor de Psicologia',
    value: 'professor_de_psicologia',
  },
  {
    label: 'Professor de Psicopedagogia',
    value: 'professor_de_psicopedagogia',
  },
  {
    label: 'Professor de Publicidade E Propaganda',
    value: 'professor_de_publicidade_e_propaganda',
  },
  {
    label: 'Professor de Química',
    value: 'professor_de_quimica',
  },
  {
    label: 'Professor de Radiologia',
    value: 'professor_de_radiologia',
  },
  {
    label: 'Professor de Recreação',
    value: 'professor_de_recreacao',
  },
  {
    label: 'Professor de Recursos Humanos',
    value: 'professor_de_recursos_humanos',
  },
  {
    label: 'Professor de Redação',
    value: 'professor_de_redacao',
  },
  {
    label: 'Professor de Relações Públicas',
    value: 'professor_de_relacoes_publicas',
  },
  {
    label: 'Professor de Robótica',
    value: 'professor_de_robotica',
  },
  {
    label: 'Professor de Russo',
    value: 'professor_de_russo',
  },
  {
    label: 'Professor de Secretariado',
    value: 'professor_de_secretariado',
  },
  {
    label: 'Professor de Segurança Do Trabalho',
    value: 'professor_de_seguranca_do_trabalho',
  },
  {
    label: 'Professor de Serviço Social',
    value: 'professor_de_servico_social',
  },
  {
    label: 'Professor de Sistemas de Informação',
    value: 'professor_de_sistemas_de_informacao',
  },
  {
    label: 'Professor de Sociologia',
    value: 'professor_de_sociologia',
  },
  {
    label: 'Professor de Sql',
    value: 'professor_de_sql',
  },
  {
    label: 'Professor de Sueco',
    value: 'professor_de_sueco',
  },
  {
    label: 'Professor de Teatro',
    value: 'professor_de_teatro',
  },
  {
    label: 'Professor de Tecnologia Da Informação',
    value: 'professor_de_tecnologia_da_informacao',
  },
  {
    label: 'Professor de Telemarketing',
    value: 'professor_de_telemarketing',
  },
  {
    label: 'Professor de Teologia',
    value: 'professor_de_teologia',
  },
  {
    label: 'Professor de Turismo',
    value: 'professor_de_turismo',
  },
  {
    label: 'Professor de Tênis',
    value: 'professor_de_tenis',
  },
  {
    label: 'Professor de Vendas',
    value: 'professor_de_vendas',
  },
  {
    label: 'Professor de Veterinária',
    value: 'professor_de_veterinaria',
  },
  {
    label: 'Professor de Violão',
    value: 'professor_de_violao',
  },
  {
    label: 'Professor de Visual Basic',
    value: 'professor_de_visual_basic',
  },
  {
    label: 'Professor de Web Design',
    value: 'professor_de_web_design',
  },
  {
    label: 'Professor de Windows',
    value: 'professor_de_windows',
  },
  {
    label: 'Professor de Xadrez',
    value: 'professor_de_xadrez',
  },
  {
    label: 'Professor de Yoga',
    value: 'professor_de_yoga',
  },
  {
    label: 'Professor de Zootecnia',
    value: 'professor_de_zootecnia',
  },
  {
    label: 'Professor de Árabe',
    value: 'professor_de_arabe',
  },
  {
    label: 'Professor de Ética',
    value: 'professor_de_etica',
  },
  {
    label: 'Professor Universitário',
    value: 'professor_universitario',
  },
  {
    label: 'Professor',
    value: 'professor',
  },
  {
    label: 'Programador .net',
    value: 'programador_net',
  },
  {
    label: 'Programador Abap',
    value: 'programador_abap',
  },
  {
    label: 'Programador Advpl',
    value: 'programador_advpl',
  },
  {
    label: 'Programador Android',
    value: 'programador_android',
  },
  {
    label: 'Programador Asp',
    value: 'programador_asp',
  },
  {
    label: 'Programador Asp.net',
    value: 'programador_aspnet',
  },
  {
    label: 'Programador Back-end',
    value: 'programador_backend',
  },
  {
    label: 'Programador Bpm',
    value: 'programador_bpm',
  },
  {
    label: 'Programador C',
    value: 'programador_c',
  },
  {
    label: 'Programador Clipper',
    value: 'programador_clipper',
  },
  {
    label: 'Programador Clp',
    value: 'programador_clp',
  },
  {
    label: 'Programador Cnc',
    value: 'programador_cnc',
  },
  {
    label: 'Programador Cobol',
    value: 'programador_cobol',
  },
  {
    label: 'Programador de Banco de Dados',
    value: 'programador_de_banco_de_dados',
  },
  {
    label: 'Programador de Bordado',
    value: 'programador_de_bordado',
  },
  {
    label: 'Programador de Business Intelligence',
    value: 'programador_de_business_intelligence',
  },
  {
    label: 'Programador de Centro de Usinagem',
    value: 'programador_de_centro_de_usinagem',
  },
  {
    label: 'Programador de Jogos',
    value: 'programador_de_jogos',
  },
  {
    label: 'Programador de Manutenção',
    value: 'programador_de_manutencao',
  },
  {
    label: 'Programador de Materiais',
    value: 'programador_de_materiais',
  },
  {
    label: 'Programador de Produção',
    value: 'programador_de_producao',
  },
  {
    label: 'Programador de Software',
    value: 'programador_de_software',
  },
  {
    label: 'Programador Delphi',
    value: 'programador_delphi',
  },
  {
    label: 'Programador Dynamics',
    value: 'programador_dynamics',
  },
  {
    label: 'Programador Etl',
    value: 'programador_etl',
  },
  {
    label: 'Programador Flash',
    value: 'programador_flash',
  },
  {
    label: 'Programador Flex',
    value: 'programador_flex',
  },
  {
    label: 'Programador Forms Reports',
    value: 'programador_forms_reports',
  },
  {
    label: 'Programador Front-end',
    value: 'programador_frontend',
  },
  {
    label: 'Programador Genexus',
    value: 'programador_genexus',
  },
  {
    label: 'Programador Ios',
    value: 'programador_ios',
  },
  {
    label: 'Programador Java',
    value: 'programador_java',
  },
  {
    label: 'Programador Javascript',
    value: 'programador_javascript',
  },
  {
    label: 'Programador Linux',
    value: 'programador_linux',
  },
  {
    label: 'Programador Microsiga',
    value: 'programador_microsiga',
  },
  {
    label: 'Programador Mobile',
    value: 'programador_mobile',
  },
  {
    label: 'Programador Node.js',
    value: 'programador_nodejs',
  },
  {
    label: 'Programador Oracle',
    value: 'programador_oracle',
  },
  {
    label: 'Programador Php',
    value: 'programador_php',
  },
  {
    label: 'Programador Pl Sql',
    value: 'programador_pl_sql',
  },
  {
    label: 'Programador Powerbuilder',
    value: 'programador_powerbuilder',
  },
  {
    label: 'Programador Progress',
    value: 'programador_progress',
  },
  {
    label: 'Programador Protheus',
    value: 'programador_protheus',
  },
  {
    label: 'Programador Python',
    value: 'programador_python',
  },
  {
    label: 'Programador Qlikview',
    value: 'programador_qlikview',
  },
  {
    label: 'Programador Ruby On Rails',
    value: 'programador_ruby_on_rails',
  },
  {
    label: 'Programador Sap',
    value: 'programador_sap',
  },
  {
    label: 'Programador Sharepoint',
    value: 'programador_sharepoint',
  },
  {
    label: 'Programador Soa',
    value: 'programador_soa',
  },
  {
    label: 'Programador Sql',
    value: 'programador_sql',
  },
  {
    label: 'Programador Trainee',
    value: 'programador_trainee',
  },
  {
    label: 'Programador Visual Basic',
    value: 'programador_visual_basic',
  },
  {
    label: 'Programador Visual Foxpro',
    value: 'programador_visual_foxpro',
  },
  {
    label: 'Programador Visual',
    value: 'programador_visual',
  },
  {
    label: 'Programador Web',
    value: 'programador_web',
  },
  {
    label: 'Programador',
    value: 'programador',
  },
  {
    label: 'Projetista 3d',
    value: 'projetista_3d',
  },
  {
    label: 'Projetista Cadista',
    value: 'projetista_cadista',
  },
  {
    label: 'Projetista Civil',
    value: 'projetista_civil',
  },
  {
    label: 'Projetista de Ar Condicionado',
    value: 'projetista_de_ar_condicionado',
  },
  {
    label: 'Projetista de Automação',
    value: 'projetista_de_automacao',
  },
  {
    label: 'Projetista de Estruturas Metálicas',
    value: 'projetista_de_estruturas_metalicas',
  },
  {
    label: 'Projetista de Ferramentas',
    value: 'projetista_de_ferramentas',
  },
  {
    label: 'Projetista de Iluminação',
    value: 'projetista_de_iluminacao',
  },
  {
    label: 'Projetista de Instalações',
    value: 'projetista_de_instalacoes',
  },
  {
    label: 'Projetista de Moldes',
    value: 'projetista_de_moldes',
  },
  {
    label: 'Projetista de Máquinas',
    value: 'projetista_de_maquinas',
  },
  {
    label: 'Projetista de Móveis',
    value: 'projetista_de_moveis',
  },
  {
    label: 'Projetista de Produto',
    value: 'projetista_de_produto',
  },
  {
    label: 'Projetista de Telecomunicações',
    value: 'projetista_de_telecomunicacoes',
  },
  {
    label: 'Projetista Eletrônico',
    value: 'projetista_eletronico',
  },
  {
    label: 'Projetista Elétrico',
    value: 'projetista_eletrico',
  },
  {
    label: 'Projetista Hidráulico',
    value: 'projetista_hidraulico',
  },
  {
    label: 'Projetista Mecânico',
    value: 'projetista_mecanico',
  },
  {
    label: 'Projetista Orçamentista',
    value: 'projetista_orcamentista',
  },
  {
    label: 'Projetista',
    value: 'projetista',
  },
  {
    label: 'Promotor de Crédito',
    value: 'promotor_de_credito',
  },
  {
    label: 'Promotor de Eventos',
    value: 'promotor_de_eventos',
  },
  {
    label: 'Promotor de Financiamento',
    value: 'promotor_de_financiamento',
  },
  {
    label: 'Promotor de Merchandising',
    value: 'promotor_de_merchandising',
  },
  {
    label: 'Promotor de Vendas',
    value: 'promotor_de_vendas',
  },
  {
    label: 'Promotor Técnico',
    value: 'promotor_tecnico',
  },
  {
    label: 'Propagandista',
    value: 'propagandista',
  },
  {
    label: 'Protético',
    value: 'protetico',
  },
  {
    label: 'Psicomotricista',
    value: 'psicomotricista',
  },
  {
    label: 'Psicopedagogo',
    value: 'psicopedagogo',
  },
  {
    label: 'Psicólogo Clínico',
    value: 'psicologo_clinico',
  },
  {
    label: 'Psicólogo Do Trânsito',
    value: 'psicologo_do_transito',
  },
  {
    label: 'Psicólogo Escolar',
    value: 'psicologo_escolar',
  },
  {
    label: 'Psicólogo Hospitalar',
    value: 'psicologo_hospitalar',
  },
  {
    label: 'Psicólogo Organizacional',
    value: 'psicologo_organizacional',
  },
  {
    label: 'Psicólogo Social',
    value: 'psicologo_social',
  },
  {
    label: 'Psicólogo',
    value: 'psicologo',
  },
  {
    label: 'Publicitário',
    value: 'publicitario',
  },
  {
    label: 'Químico Industrial',
    value: 'quimico_industrial',
  },
  {
    label: 'Químico Responsável',
    value: 'quimico_responsavel',
  },
  {
    label: 'Químico',
    value: 'quimico',
  },
  {
    label: 'Radialista',
    value: 'radialista',
  },
  {
    label: 'Rasteleiro',
    value: 'rasteleiro',
  },
  {
    label: 'Rebarbador de Metais',
    value: 'rebarbador_de_metais',
  },
  {
    label: 'Rebobinador',
    value: 'rebobinador',
  },
  {
    label: 'Recebedor',
    value: 'recebedor',
  },
  {
    label: 'Recepcionista de Hotel',
    value: 'recepcionista_de_hotel',
  },
  {
    label: 'Recepcionista Hospitalar',
    value: 'recepcionista_hospitalar',
  },
  {
    label: 'Recepcionista',
    value: 'recepcionista',
  },
  {
    label: 'Recreador',
    value: 'recreador',
  },
  {
    label: 'Recuperador de Crédito',
    value: 'recuperador_de_credito',
  },
  {
    label: 'Redator Publicitário',
    value: 'redator_publicitario',
  },
  {
    label: 'Redator Web',
    value: 'redator_web',
  },
  {
    label: 'Redator',
    value: 'redator',
  },
  {
    label: 'Regulador de Sinistros',
    value: 'regulador_de_sinistros',
  },
  {
    label: 'Relações Públicas',
    value: 'relacoes_publicas',
  },
  {
    label: 'Relojoeiro',
    value: 'relojoeiro',
  },
  {
    label: 'Repositor de Perecíveis',
    value: 'repositor_de_pereciveis',
  },
  {
    label: 'Repositor',
    value: 'repositor',
  },
  {
    label: 'Representante Comercial',
    value: 'representante_comercial',
  },
  {
    label: 'Repórter',
    value: 'reporter',
  },
  {
    label: 'Retificador',
    value: 'retificador',
  },
  {
    label: 'Revisor de Tecidos',
    value: 'revisor_de_tecidos',
  },
  {
    label: 'Revisor de Texto',
    value: 'revisor_de_texto',
  },
  {
    label: 'Rigger',
    value: 'rigger',
  },
  {
    label: 'Robotista',
    value: 'robotista',
  },
  {
    label: 'Roteirista',
    value: 'roteirista',
  },
  {
    label: 'Roteirizador',
    value: 'roteirizador',
  },
  {
    label: 'Saladeiro',
    value: 'saladeiro',
  },
  {
    label: 'Salgadeiro',
    value: 'salgadeiro',
  },
  {
    label: 'Salva Vidas',
    value: 'salva_vidas',
  },
  {
    label: 'Sapateiro',
    value: 'sapateiro',
  },
  {
    label: 'Scouter',
    value: 'scouter',
  },
  {
    label: 'Scrum Master',
    value: 'scrum_master',
  },
  {
    label: 'Secretária Comercial',
    value: 'secretaria_comercial',
  },
  {
    label: 'Secretária Escolar',
    value: 'secretaria_escolar',
  },
  {
    label: 'Secretária Executiva',
    value: 'secretaria_executiva',
  },
  {
    label: 'Secretária Financeira',
    value: 'secretaria_financeira',
  },
  {
    label: 'Secretária',
    value: 'secretaria',
  },
  {
    label: 'Segurança Patrimonial',
    value: 'seguranca_patrimonial',
  },
  {
    label: 'Segurança',
    value: 'seguranca',
  },
  {
    label: 'Selecionador',
    value: 'selecionador',
  },
  {
    label: 'Separador de Mercadorias',
    value: 'separador_de_mercadorias',
  },
  {
    label: 'Serralheiro',
    value: 'serralheiro',
  },
  {
    label: 'Servente de Obras',
    value: 'servente_de_obras',
  },
  {
    label: 'Sinaleiro',
    value: 'sinaleiro',
  },
  {
    label: 'Sociólogo',
    value: 'sociologo',
  },
  {
    label: 'Socorrista',
    value: 'socorrista',
  },
  {
    label: 'Soldador Montador',
    value: 'soldador_montador',
  },
  {
    label: 'Soldador',
    value: 'soldador',
  },
  {
    label: 'Sommelier',
    value: 'sommelier',
  },
  {
    label: 'Sondador',
    value: 'sondador',
  },
  {
    label: 'Sonoplasta',
    value: 'sonoplasta',
  },
  {
    label: 'Sorveteiro',
    value: 'sorveteiro',
  },
  {
    label: 'Steward',
    value: 'steward',
  },
  {
    label: 'Superintendente Comercial',
    value: 'superintendente_comercial',
  },
  {
    label: 'Superintendente de Operações',
    value: 'superintendente_de_operacoes',
  },
  {
    label: 'Supervisor Administrativo',
    value: 'supervisor_administrativo',
  },
  {
    label: 'Supervisor Agrícola',
    value: 'supervisor_agricola',
  },
  {
    label: 'Supervisor Comercial',
    value: 'supervisor_comercial',
  },
  {
    label: 'Supervisor Contábil',
    value: 'supervisor_contabil',
  },
  {
    label: 'Supervisor de Alimentos E Bebidas',
    value: 'supervisor_de_alimentos_e_bebidas',
  },
  {
    label: 'Supervisor de Almoxarifado',
    value: 'supervisor_de_almoxarifado',
  },
  {
    label: 'Supervisor de Arquivo',
    value: 'supervisor_de_arquivo',
  },
  {
    label: 'Supervisor de Assistência Técnica',
    value: 'supervisor_de_assistencia_tecnica',
  },
  {
    label: 'Supervisor de Atendimento Ao Cliente',
    value: 'supervisor_de_atendimento_ao_cliente',
  },
  {
    label: 'Supervisor de Auditoria',
    value: 'supervisor_de_auditoria',
  },
  {
    label: 'Supervisor de Automação',
    value: 'supervisor_de_automacao',
  },
  {
    label: 'Supervisor de Back Office',
    value: 'supervisor_de_back_office',
  },
  {
    label: 'Supervisor de Caixa',
    value: 'supervisor_de_caixa',
  },
  {
    label: 'Supervisor de Caldeiraria',
    value: 'supervisor_de_caldeiraria',
  },
  {
    label: 'Supervisor de Call Center',
    value: 'supervisor_de_call_center',
  },
  {
    label: 'Supervisor de Campo',
    value: 'supervisor_de_campo',
  },
  {
    label: 'Supervisor de Cobrança',
    value: 'supervisor_de_cobranca',
  },
  {
    label: 'Supervisor de Compras',
    value: 'supervisor_de_compras',
  },
  {
    label: 'Supervisor de Comércio Exterior',
    value: 'supervisor_de_comercio_exterior',
  },
  {
    label: 'Supervisor de Condomínio',
    value: 'supervisor_de_condominio',
  },
  {
    label: 'Supervisor de Contas A Pagar',
    value: 'supervisor_de_contas_a_pagar',
  },
  {
    label: 'Supervisor de Contas A Receber',
    value: 'supervisor_de_contas_a_receber',
  },
  {
    label: 'Supervisor de Contas',
    value: 'supervisor_de_contas',
  },
  {
    label: 'Supervisor de Contratos',
    value: 'supervisor_de_contratos',
  },
  {
    label: 'Supervisor de Controladoria',
    value: 'supervisor_de_controladoria',
  },
  {
    label: 'Supervisor de Controle de Qualidade',
    value: 'supervisor_de_controle_de_qualidade',
  },
  {
    label: 'Supervisor de Costura',
    value: 'supervisor_de_costura',
  },
  {
    label: 'Supervisor de Crédito E Cobrança',
    value: 'supervisor_de_credito_e_cobranca',
  },
  {
    label: 'Supervisor de Crédito Imobiliário',
    value: 'supervisor_de_credito_imobiliario',
  },
  {
    label: 'Supervisor de Crédito',
    value: 'supervisor_de_credito',
  },
  {
    label: 'Supervisor de Custos',
    value: 'supervisor_de_custos',
  },
  {
    label: 'Supervisor de Departamento Pessoal',
    value: 'supervisor_de_departamento_pessoal',
  },
  {
    label: 'Supervisor de E-commerce',
    value: 'supervisor_de_ecommerce',
  },
  {
    label: 'Supervisor de Elétrica',
    value: 'supervisor_de_eletrica',
  },
  {
    label: 'Supervisor de Engenharia',
    value: 'supervisor_de_engenharia',
  },
  {
    label: 'Supervisor de Estamparia',
    value: 'supervisor_de_estamparia',
  },
  {
    label: 'Supervisor de Eventos',
    value: 'supervisor_de_eventos',
  },
  {
    label: 'Supervisor de Expedição',
    value: 'supervisor_de_expedicao',
  },
  {
    label: 'Supervisor de Facilities',
    value: 'supervisor_de_facilities',
  },
  {
    label: 'Supervisor de Farmácia',
    value: 'supervisor_de_farmacia',
  },
  {
    label: 'Supervisor de Faturamento',
    value: 'supervisor_de_faturamento',
  },
  {
    label: 'Supervisor de Ferramentaria',
    value: 'supervisor_de_ferramentaria',
  },
  {
    label: 'Supervisor de Filial',
    value: 'supervisor_de_filial',
  },
  {
    label: 'Supervisor de Folha de Pagamento',
    value: 'supervisor_de_folha_de_pagamento',
  },
  {
    label: 'Supervisor de Frota',
    value: 'supervisor_de_frota',
  },
  {
    label: 'Supervisor de Fundição',
    value: 'supervisor_de_fundicao',
  },
  {
    label: 'Supervisor de Garantia Da Qualidade',
    value: 'supervisor_de_garantia_da_qualidade',
  },
  {
    label: 'Supervisor de Hotelaria',
    value: 'supervisor_de_hotelaria',
  },
  {
    label: 'Supervisor de Infraestrutura',
    value: 'supervisor_de_infraestrutura',
  },
  {
    label: 'Supervisor de Injeção Plástica',
    value: 'supervisor_de_injecao_plastica',
  },
  {
    label: 'Supervisor de Instalação',
    value: 'supervisor_de_instalacao',
  },
  {
    label: 'Supervisor de Inteligência de Mercado',
    value: 'supervisor_de_inteligencia_de_mercado',
  },
  {
    label: 'Supervisor de Laboratório',
    value: 'supervisor_de_laboratorio',
  },
  {
    label: 'Supervisor de Lavanderia',
    value: 'supervisor_de_lavanderia',
  },
  {
    label: 'Supervisor de Limpeza',
    value: 'supervisor_de_limpeza',
  },
  {
    label: 'Supervisor de Locação',
    value: 'supervisor_de_locacao',
  },
  {
    label: 'Supervisor de Logística',
    value: 'supervisor_de_logistica',
  },
  {
    label: 'Supervisor de Loja',
    value: 'supervisor_de_loja',
  },
  {
    label: 'Supervisor de Manutenção Automotiva',
    value: 'supervisor_de_manutencao_automotiva',
  },
  {
    label: 'Supervisor de Manutenção Eletromecânica',
    value: 'supervisor_de_manutencao_eletromecanica',
  },
  {
    label: 'Supervisor de Manutenção Elétrica',
    value: 'supervisor_de_manutencao_eletrica',
  },
  {
    label: 'Supervisor de Manutenção Industrial',
    value: 'supervisor_de_manutencao_industrial',
  },
  {
    label: 'Supervisor de Manutenção Mecânica',
    value: 'supervisor_de_manutencao_mecanica',
  },
  {
    label: 'Supervisor de Manutenção Predial',
    value: 'supervisor_de_manutencao_predial',
  },
  {
    label: 'Supervisor de Manutenção',
    value: 'supervisor_de_manutencao',
  },
  {
    label: 'Supervisor de Marketing',
    value: 'supervisor_de_marketing',
  },
  {
    label: 'Supervisor de Meio Ambiente',
    value: 'supervisor_de_meio_ambiente',
  },
  {
    label: 'Supervisor de Merchandising',
    value: 'supervisor_de_merchandising',
  },
  {
    label: 'Supervisor de Mineração',
    value: 'supervisor_de_mineracao',
  },
  {
    label: 'Supervisor de Monitoramento',
    value: 'supervisor_de_monitoramento',
  },
  {
    label: 'Supervisor de Monitoria',
    value: 'supervisor_de_monitoria',
  },
  {
    label: 'Supervisor de Montagem',
    value: 'supervisor_de_montagem',
  },
  {
    label: 'Supervisor de Negócios',
    value: 'supervisor_de_negocios',
  },
  {
    label: 'Supervisor de Nutrição',
    value: 'supervisor_de_nutricao',
  },
  {
    label: 'Supervisor de Obras',
    value: 'supervisor_de_obras',
  },
  {
    label: 'Supervisor de Operações',
    value: 'supervisor_de_operacoes',
  },
  {
    label: 'Supervisor de Ouvidoria',
    value: 'supervisor_de_ouvidoria',
  },
  {
    label: 'Supervisor de Patrimônio',
    value: 'supervisor_de_patrimonio',
  },
  {
    label: 'Supervisor de Pcp',
    value: 'supervisor_de_pcp',
  },
  {
    label: 'Supervisor de Peças',
    value: 'supervisor_de_pecas',
  },
  {
    label: 'Supervisor de Pintura',
    value: 'supervisor_de_pintura',
  },
  {
    label: 'Supervisor de Planejamento',
    value: 'supervisor_de_planejamento',
  },
  {
    label: 'Supervisor de Prevenção de Perdas',
    value: 'supervisor_de_prevencao_de_perdas',
  },
  {
    label: 'Supervisor de Processos',
    value: 'supervisor_de_processos',
  },
  {
    label: 'Supervisor de Produtos',
    value: 'supervisor_de_produtos',
  },
  {
    label: 'Supervisor de Produção',
    value: 'supervisor_de_producao',
  },
  {
    label: 'Supervisor de Projeto Social',
    value: 'supervisor_de_projeto_social',
  },
  {
    label: 'Supervisor de Projetos',
    value: 'supervisor_de_projetos',
  },
  {
    label: 'Supervisor de Pós-venda',
    value: 'supervisor_de_posvenda',
  },
  {
    label: 'Supervisor de Qualidade',
    value: 'supervisor_de_qualidade',
  },
  {
    label: 'Supervisor de Radiologia',
    value: 'supervisor_de_radiologia',
  },
  {
    label: 'Supervisor de Recepção',
    value: 'supervisor_de_recepcao',
  },
  {
    label: 'Supervisor de Recrutamento E Seleção',
    value: 'supervisor_de_recrutamento_e_selecao',
  },
  {
    label: 'Supervisor de Recursos Humanos',
    value: 'supervisor_de_recursos_humanos',
  },
  {
    label: 'Supervisor de Refrigeração',
    value: 'supervisor_de_refrigeracao',
  },
  {
    label: 'Supervisor de Relacionamento',
    value: 'supervisor_de_relacionamento',
  },
  {
    label: 'Supervisor de Reservas',
    value: 'supervisor_de_reservas',
  },
  {
    label: 'Supervisor de Restaurante',
    value: 'supervisor_de_restaurante',
  },
  {
    label: 'Supervisor de Sac',
    value: 'supervisor_de_sac',
  },
  {
    label: 'Supervisor de Segurança Do Trabalho',
    value: 'supervisor_de_seguranca_do_trabalho',
  },
  {
    label: 'Supervisor de Segurança Patrimonial',
    value: 'supervisor_de_seguranca_patrimonial',
  },
  {
    label: 'Supervisor de Seguros',
    value: 'supervisor_de_seguros',
  },
  {
    label: 'Supervisor de Serviços Gerais',
    value: 'supervisor_de_servicos_gerais',
  },
  {
    label: 'Supervisor de Suprimentos',
    value: 'supervisor_de_suprimentos',
  },
  {
    label: 'Supervisor de Tecnologia Da Informação',
    value: 'supervisor_de_tecnologia_da_informacao',
  },
  {
    label: 'Supervisor de Tesouraria',
    value: 'supervisor_de_tesouraria',
  },
  {
    label: 'Supervisor de Trade Marketing',
    value: 'supervisor_de_trade_marketing',
  },
  {
    label: 'Supervisor de Transportes',
    value: 'supervisor_de_transportes',
  },
  {
    label: 'Supervisor de Treinamento E Desenvolvimento',
    value: 'supervisor_de_treinamento_e_desenvolvimento',
  },
  {
    label: 'Supervisor de Usinagem',
    value: 'supervisor_de_usinagem',
  },
  {
    label: 'Supervisor de Vendas',
    value: 'supervisor_de_vendas',
  },
  {
    label: 'Supervisor Financeiro',
    value: 'supervisor_financeiro',
  },
  {
    label: 'Supervisor Florestal',
    value: 'supervisor_florestal',
  },
  {
    label: 'Supervisor Industrial',
    value: 'supervisor_industrial',
  },
  {
    label: 'Supervisor Pedagógico',
    value: 'supervisor_pedagogico',
  },
  {
    label: 'Supervisor Tributário',
    value: 'supervisor_tributario',
  },
  {
    label: 'Supervisor Técnico',
    value: 'supervisor_tecnico',
  },
  {
    label: 'Suporte Técnico',
    value: 'suporte_tecnico',
  },
  {
    label: 'Sushiman',
    value: 'sushiman',
  },
  {
    label: 'Síndico',
    value: 'sindico',
  },
  {
    label: 'Tapeceiro de Veículos',
    value: 'tapeceiro_de_veiculos',
  },
  {
    label: 'Tapeceiro',
    value: 'tapeceiro',
  },
  {
    label: 'Tecelão',
    value: 'tecelao',
  },
  {
    label: 'Tecnólogo em Alimentos',
    value: 'tecnologo_em_alimentos',
  },
  {
    label: 'Tecnólogo em Construção Civil',
    value: 'tecnologo_em_construcao_civil',
  },
  {
    label: 'Tecnólogo em Mecânica',
    value: 'tecnologo_em_mecanica',
  },
  {
    label: 'Tecnólogo em Radiologia',
    value: 'tecnologo_em_radiologia',
  },
  {
    label: 'Tecnólogo em Redes de Computadores',
    value: 'tecnologo_em_redes_de_computadores',
  },
  {
    label: 'Tecnólogo em Saneamento Ambiental',
    value: 'tecnologo_em_saneamento_ambiental',
  },
  {
    label: 'Telefonista Recepcionista',
    value: 'telefonista_recepcionista',
  },
  {
    label: 'Telefonista',
    value: 'telefonista',
  },
  {
    label: 'Telhadista',
    value: 'telhadista',
  },
  {
    label: 'Terapeuta Ocupacional',
    value: 'terapeuta_ocupacional',
  },
  {
    label: 'Tesoureiro',
    value: 'tesoureiro',
  },
  {
    label: 'Tintureiro',
    value: 'tintureiro',
  },
  {
    label: 'Topógrafo',
    value: 'topografo',
  },
  {
    label: 'Torneiro Ferramenteiro',
    value: 'torneiro_ferramenteiro',
  },
  {
    label: 'Torneiro Mecânico',
    value: 'torneiro_mecanico',
  },
  {
    label: 'Tosador',
    value: 'tosador',
  },
  {
    label: 'Trader',
    value: 'trader',
  },
  {
    label: 'Tradutor de Alemão',
    value: 'tradutor_de_alemao',
  },
  {
    label: 'Tradutor de Chinês',
    value: 'tradutor_de_chines',
  },
  {
    label: 'Tradutor de Coreano',
    value: 'tradutor_de_coreano',
  },
  {
    label: 'Tradutor de Espanhol',
    value: 'tradutor_de_espanhol',
  },
  {
    label: 'Tradutor de Francês',
    value: 'tradutor_de_frances',
  },
  {
    label: 'Tradutor de Inglês',
    value: 'tradutor_de_ingles',
  },
  {
    label: 'Tradutor de Italiano',
    value: 'tradutor_de_italiano',
  },
  {
    label: 'Tradutor de Japonês',
    value: 'tradutor_de_japones',
  },
  {
    label: 'Tradutor de Mandarim',
    value: 'tradutor_de_mandarim',
  },
  {
    label: 'Tradutor de Árabe',
    value: 'tradutor_de_arabe',
  },
  {
    label: 'Tradutor',
    value: 'tradutor',
  },
  {
    label: 'Trainee em Administração de Empresas',
    value: 'trainee_em_administracao_de_empresas',
  },
  {
    label: 'Trainee em Agronomia',
    value: 'trainee_em_agronomia',
  },
  {
    label: 'Trainee em Arquitetura E Urbanismo',
    value: 'trainee_em_arquitetura_e_urbanismo',
  },
  {
    label: 'Trainee em Ciências Contábeis',
    value: 'trainee_em_ciencias_contabeis',
  },
  {
    label: 'Trainee em Direito',
    value: 'trainee_em_direito',
  },
  {
    label: 'Trainee em Economia',
    value: 'trainee_em_economia',
  },
  {
    label: 'Trainee em Engenharia Civil',
    value: 'trainee_em_engenharia_civil',
  },
  {
    label: 'Trainee em Engenharia de Produção',
    value: 'trainee_em_engenharia_de_producao',
  },
  {
    label: 'Trainee em Engenharia Elétrica',
    value: 'trainee_em_engenharia_eletrica',
  },
  {
    label: 'Trainee em Engenharia Mecânica',
    value: 'trainee_em_engenharia_mecanica',
  },
  {
    label: 'Trainee em Engenharia Química',
    value: 'trainee_em_engenharia_quimica',
  },
  {
    label: 'Trainee em Engenharia',
    value: 'trainee_em_engenharia',
  },
  {
    label: 'Trainee em Marketing',
    value: 'trainee_em_marketing',
  },
  {
    label: 'Trainee em Nutrição',
    value: 'trainee_em_nutricao',
  },
  {
    label: 'Trainee em Recursos Humanos',
    value: 'trainee_em_recursos_humanos',
  },
  {
    label: 'Trainee em Suporte Técnico',
    value: 'trainee_em_suporte_tecnico',
  },
  {
    label: 'Trainee em Vendas',
    value: 'trainee_em_vendas',
  },
  {
    label: 'Trainee Na Área Comercial',
    value: 'trainee_na_area_comercial',
  },
  {
    label: 'Trainee Na Área de Compras',
    value: 'trainee_na_area_de_compras',
  },
  {
    label: 'Trainee Na Área de Departamento Pessoal',
    value: 'trainee_na_area_de_departamento_pessoal',
  },
  {
    label: 'Trainee Na Área de Tecnologia Da Informação',
    value: 'trainee_na_area_de_tecnologia_da_informacao',
  },
  {
    label: 'Trainee Na Área Financeira',
    value: 'trainee_na_area_financeira',
  },
  {
    label: 'Trainee Na Área Fiscal',
    value: 'trainee_na_area_fiscal',
  },
  {
    label: 'Trainee Na Área Industrial',
    value: 'trainee_na_area_industrial',
  },
  {
    label: 'Tratador de Piscina',
    value: 'tratador_de_piscina',
  },
  {
    label: 'Tratorista',
    value: 'tratorista',
  },
  {
    label: 'Traçador de Caldeiraria',
    value: 'tracador_de_caldeiraria',
  },
  {
    label: 'Trefilador',
    value: 'trefilador',
  },
  {
    label: 'Trocador de Moldes',
    value: 'trocador_de_moldes',
  },
  {
    label: 'Turismólogo',
    value: 'turismologo',
  },
  {
    label: 'Técnico Administrativo',
    value: 'tecnico_administrativo',
  },
  {
    label: 'Técnico Agrícola',
    value: 'tecnico_agricola',
  },
  {
    label: 'Técnico Bancário',
    value: 'tecnico_bancario',
  },
  {
    label: 'Técnico Comercial',
    value: 'tecnico_comercial',
  },
  {
    label: 'Técnico Contábil',
    value: 'tecnico_contabil',
  },
  {
    label: 'Técnico Da Qualidade',
    value: 'tecnico_da_qualidade',
  },
  {
    label: 'Técnico de Arquivo',
    value: 'tecnico_de_arquivo',
  },
  {
    label: 'Técnico de Assistência Técnica',
    value: 'tecnico_de_assistencia_tecnica',
  },
  {
    label: 'Técnico de Atendimento',
    value: 'tecnico_de_atendimento',
  },
  {
    label: 'Técnico de Campo',
    value: 'tecnico_de_campo',
  },
  {
    label: 'Técnico de Celular',
    value: 'tecnico_de_celular',
  },
  {
    label: 'Técnico de Controle de Qualidade',
    value: 'tecnico_de_controle_de_qualidade',
  },
  {
    label: 'Técnico de Equipamentos Médicos',
    value: 'tecnico_de_equipamentos_medicos',
  },
  {
    label: 'Técnico de Equipamentos',
    value: 'tecnico_de_equipamentos',
  },
  {
    label: 'Técnico de Eventos',
    value: 'tecnico_de_eventos',
  },
  {
    label: 'Técnico de Gasoterapia',
    value: 'tecnico_de_gasoterapia',
  },
  {
    label: 'Técnico de Gesso',
    value: 'tecnico_de_gesso',
  },
  {
    label: 'Técnico de Help Desk',
    value: 'tecnico_de_help_desk',
  },
  {
    label: 'Técnico de Iluminação',
    value: 'tecnico_de_iluminacao',
  },
  {
    label: 'Técnico de Implantação',
    value: 'tecnico_de_implantacao',
  },
  {
    label: 'Técnico de Impressora',
    value: 'tecnico_de_impressora',
  },
  {
    label: 'Técnico de Infraestrutura',
    value: 'tecnico_de_infraestrutura',
  },
  {
    label: 'Técnico de Instalação',
    value: 'tecnico_de_instalacao',
  },
  {
    label: 'Técnico de Laboratório Industrial',
    value: 'tecnico_de_laboratorio_industrial',
  },
  {
    label: 'Técnico de Laboratório',
    value: 'tecnico_de_laboratorio',
  },
  {
    label: 'Técnico de Manutenção de Computadores',
    value: 'tecnico_de_manutencao_de_computadores',
  },
  {
    label: 'Técnico de Manutenção Elétrica',
    value: 'tecnico_de_manutencao_eletrica',
  },
  {
    label: 'Técnico de Manutenção Industrial',
    value: 'tecnico_de_manutencao_industrial',
  },
  {
    label: 'Técnico de Manutenção Predial',
    value: 'tecnico_de_manutencao_predial',
  },
  {
    label: 'Técnico de Materiais',
    value: 'tecnico_de_materiais',
  },
  {
    label: 'Técnico de Monitoramento',
    value: 'tecnico_de_monitoramento',
  },
  {
    label: 'Técnico de Montagem',
    value: 'tecnico_de_montagem',
  },
  {
    label: 'Técnico de Planejamento de Manutenção',
    value: 'tecnico_de_planejamento_de_manutencao',
  },
  {
    label: 'Técnico de Planejamento',
    value: 'tecnico_de_planejamento',
  },
  {
    label: 'Técnico de Processos',
    value: 'tecnico_de_processos',
  },
  {
    label: 'Técnico de Produto',
    value: 'tecnico_de_produto',
  },
  {
    label: 'Técnico de Produção',
    value: 'tecnico_de_producao',
  },
  {
    label: 'Técnico de Redes',
    value: 'tecnico_de_redes',
  },
  {
    label: 'Técnico de Service Desk',
    value: 'tecnico_de_service_desk',
  },
  {
    label: 'Técnico de Sinistro',
    value: 'tecnico_de_sinistro',
  },
  {
    label: 'Técnico de Som',
    value: 'tecnico_de_som',
  },
  {
    label: 'Técnico de Suporte',
    value: 'tecnico_de_suporte',
  },
  {
    label: 'Técnico de Tacógrafo',
    value: 'tecnico_de_tacografo',
  },
  {
    label: 'Técnico de Testes',
    value: 'tecnico_de_testes',
  },
  {
    label: 'Técnico de Vendas',
    value: 'tecnico_de_vendas',
  },
  {
    label: 'Técnico Eletricista',
    value: 'tecnico_eletricista',
  },
  {
    label: 'Técnico Eletroeletrônico',
    value: 'tecnico_eletroeletronico',
  },
  {
    label: 'Técnico Eletromecânico',
    value: 'tecnico_eletromecanico',
  },
  {
    label: 'Técnico em Agrimensura',
    value: 'tecnico_em_agrimensura',
  },
  {
    label: 'Técnico em Agropecuária',
    value: 'tecnico_em_agropecuaria',
  },
  {
    label: 'Técnico em Alimentos',
    value: 'tecnico_em_alimentos',
  },
  {
    label: 'Técnico em Análises Clínicas',
    value: 'tecnico_em_analises_clinicas',
  },
  {
    label: 'Técnico em Automação Bancária',
    value: 'tecnico_em_automacao_bancaria',
  },
  {
    label: 'Técnico em Automação Industrial',
    value: 'tecnico_em_automacao_industrial',
  },
  {
    label: 'Técnico em Automação Predial',
    value: 'tecnico_em_automacao_predial',
  },
  {
    label: 'Técnico em Biblioteconomia',
    value: 'tecnico_em_biblioteconomia',
  },
  {
    label: 'Técnico em Cabeamento',
    value: 'tecnico_em_cabeamento',
  },
  {
    label: 'Técnico em Cftv',
    value: 'tecnico_em_cftv',
  },
  {
    label: 'Técnico em Documentação',
    value: 'tecnico_em_documentacao',
  },
  {
    label: 'Técnico em Edificações',
    value: 'tecnico_em_edificacoes',
  },
  {
    label: 'Técnico em Eletrocardiograma',
    value: 'tecnico_em_eletrocardiograma',
  },
  {
    label: 'Técnico em Eletroencefalograma',
    value: 'tecnico_em_eletroencefalograma',
  },
  {
    label: 'Técnico em Eletrotécnica',
    value: 'tecnico_em_eletrotecnica',
  },
  {
    label: 'Técnico em Eletrônica',
    value: 'tecnico_em_eletronica',
  },
  {
    label: 'Técnico em Enfermagem Do Trabalho',
    value: 'tecnico_em_enfermagem_do_trabalho',
  },
  {
    label: 'Técnico em Enfermagem',
    value: 'tecnico_em_enfermagem',
  },
  {
    label: 'Técnico em Espirometria',
    value: 'tecnico_em_espirometria',
  },
  {
    label: 'Técnico em Esquadrias de Alumínio',
    value: 'tecnico_em_esquadrias_de_aluminio',
  },
  {
    label: 'Técnico em Estradas',
    value: 'tecnico_em_estradas',
  },
  {
    label: 'Técnico em Farmácia',
    value: 'tecnico_em_farmacia',
  },
  {
    label: 'Técnico em Fibras Ópticas',
    value: 'tecnico_em_fibras_opticas',
  },
  {
    label: 'Técnico em Gastronomia',
    value: 'tecnico_em_gastronomia',
  },
  {
    label: 'Técnico em Geoprocessamento',
    value: 'tecnico_em_geoprocessamento',
  },
  {
    label: 'Técnico em Hemoterapia',
    value: 'tecnico_em_hemoterapia',
  },
  {
    label: 'Técnico em Imobilização Ortopédica',
    value: 'tecnico_em_imobilizacao_ortopedica',
  },
  {
    label: 'Técnico em Informática',
    value: 'tecnico_em_informatica',
  },
  {
    label: 'Técnico em Inspeção de Equipamentos',
    value: 'tecnico_em_inspecao_de_equipamentos',
  },
  {
    label: 'Técnico em Instrumentação',
    value: 'tecnico_em_instrumentacao',
  },
  {
    label: 'Técnico em Licitação',
    value: 'tecnico_em_licitacao',
  },
  {
    label: 'Técnico em Logística',
    value: 'tecnico_em_logistica',
  },
  {
    label: 'Técnico em Lubrificação',
    value: 'tecnico_em_lubrificacao',
  },
  {
    label: 'Técnico em Manutenção Automotiva',
    value: 'tecnico_em_manutencao_automotiva',
  },
  {
    label: 'Técnico em Manutenção de Elevadores',
    value: 'tecnico_em_manutencao_de_elevadores',
  },
  {
    label: 'Técnico em Manutenção',
    value: 'tecnico_em_manutencao',
  },
  {
    label: 'Técnico em Mecatrônica',
    value: 'tecnico_em_mecatronica',
  },
  {
    label: 'Técnico em Mecânica',
    value: 'tecnico_em_mecanica',
  },
  {
    label: 'Técnico em Medição',
    value: 'tecnico_em_medicao',
  },
  {
    label: 'Técnico em Meio Ambiente',
    value: 'tecnico_em_meio_ambiente',
  },
  {
    label: 'Técnico em Metalurgia',
    value: 'tecnico_em_metalurgia',
  },
  {
    label: 'Técnico em Metrologia',
    value: 'tecnico_em_metrologia',
  },
  {
    label: 'Técnico em Mineração',
    value: 'tecnico_em_mineracao',
  },
  {
    label: 'Técnico em Nutrição',
    value: 'tecnico_em_nutricao',
  },
  {
    label: 'Técnico em Optometria',
    value: 'tecnico_em_optometria',
  },
  {
    label: 'Técnico em Orientação E Mobilidade',
    value: 'tecnico_em_orientacao_e_mobilidade',
  },
  {
    label: 'Técnico em Panificação',
    value: 'tecnico_em_panificacao',
  },
  {
    label: 'Técnico em Plásticos',
    value: 'tecnico_em_plasticos',
  },
  {
    label: 'Técnico em Polissonografia',
    value: 'tecnico_em_polissonografia',
  },
  {
    label: 'Técnico em Processos Industriais',
    value: 'tecnico_em_processos_industriais',
  },
  {
    label: 'Técnico em Projetos',
    value: 'tecnico_em_projetos',
  },
  {
    label: 'Técnico em Química',
    value: 'tecnico_em_quimica',
  },
  {
    label: 'Técnico em Radiologia',
    value: 'tecnico_em_radiologia',
  },
  {
    label: 'Técnico em Recursos Humanos',
    value: 'tecnico_em_recursos_humanos',
  },
  {
    label: 'Técnico em Refrigeração',
    value: 'tecnico_em_refrigeracao',
  },
  {
    label: 'Técnico em Saneamento',
    value: 'tecnico_em_saneamento',
  },
  {
    label: 'Técnico em Saúde Bucal',
    value: 'tecnico_em_saude_bucal',
  },
  {
    label: 'Técnico em Secretariado',
    value: 'tecnico_em_secretariado',
  },
  {
    label: 'Técnico em Segurança Do Trabalho',
    value: 'tecnico_em_seguranca_do_trabalho',
  },
  {
    label: 'Técnico em Segurança E Meio Ambiente',
    value: 'tecnico_em_seguranca_e_meio_ambiente',
  },
  {
    label: 'Técnico em Segurança Eletrônica',
    value: 'tecnico_em_seguranca_eletronica',
  },
  {
    label: 'Técnico em Seguros',
    value: 'tecnico_em_seguros',
  },
  {
    label: 'Técnico em Tecnologia Da Informação',
    value: 'tecnico_em_tecnologia_da_informacao',
  },
  {
    label: 'Técnico em Telecomunicações',
    value: 'tecnico_em_telecomunicacoes',
  },
  {
    label: 'Técnico em Turismo',
    value: 'tecnico_em_turismo',
  },
  {
    label: 'Técnico em Áudio E Vídeo',
    value: 'tecnico_em_audio_e_video',
  },
  {
    label: 'Técnico em Óptica',
    value: 'tecnico_em_optica',
  },
  {
    label: 'Técnico Financeiro',
    value: 'tecnico_financeiro',
  },
  {
    label: 'Técnico Florestal',
    value: 'tecnico_florestal',
  },
  {
    label: 'Técnico Hidráulico',
    value: 'tecnico_hidraulico',
  },
  {
    label: 'Técnico Industrial',
    value: 'tecnico_industrial',
  },
  {
    label: 'Técnico Mecânico de Manutenção',
    value: 'tecnico_mecanico_de_manutencao',
  },
  {
    label: 'Técnico Operacional',
    value: 'tecnico_operacional',
  },
  {
    label: 'Técnico Orçamentista',
    value: 'tecnico_orcamentista',
  },
  {
    label: 'Técnico Projetista',
    value: 'tecnico_projetista',
  },
  {
    label: 'Técnico Têxtil',
    value: 'tecnico_textil',
  },
  {
    label: 'Vendedor de Consórcio',
    value: 'vendedor_de_consorcio',
  },
  {
    label: 'Vendedor de E-commerce',
    value: 'vendedor_de_ecommerce',
  },
  {
    label: 'Vendedor de Franquias',
    value: 'vendedor_de_franquias',
  },
  {
    label: 'Vendedor de Frete',
    value: 'vendedor_de_frete',
  },
  {
    label: 'Vendedor de Informática',
    value: 'vendedor_de_informatica',
  },
  {
    label: 'Vendedor de Loja',
    value: 'vendedor_de_loja',
  },
  {
    label: 'Vendedor de Máquinas',
    value: 'vendedor_de_maquinas',
  },
  {
    label: 'Vendedor de Seguros',
    value: 'vendedor_de_seguros',
  },
  {
    label: 'Vendedor de Telecomunicações',
    value: 'vendedor_de_telecomunicacoes',
  },
  {
    label: 'Vendedor de Veículos',
    value: 'vendedor_de_veiculos',
  },
  {
    label: 'Vendedor Externo',
    value: 'vendedor_externo',
  },
  {
    label: 'Vendedor Industrial',
    value: 'vendedor_industrial',
  },
  {
    label: 'Vendedor Projetista',
    value: 'vendedor_projetista',
  },
  {
    label: 'Vendedor Técnico',
    value: 'vendedor_tecnico',
  },
  {
    label: 'Vendedor',
    value: 'vendedor',
  },
  {
    label: 'Vendendor',
    value: 'vendendor',
  },
  {
    label: 'Veterinário Patologista',
    value: 'veterinario_patologista',
  },
  {
    label: 'Veterinário',
    value: 'veterinario',
  },
  {
    label: 'Videografista',
    value: 'videografista',
  },
  {
    label: 'Vidraceiro',
    value: 'vidraceiro',
  },
  {
    label: 'Vigia',
    value: 'vigia',
  },
  {
    label: 'Vigilante de Escolta',
    value: 'vigilante_de_escolta',
  },
  {
    label: 'Vigilante Líder',
    value: 'vigilante_lider',
  },
  {
    label: 'Vigilante',
    value: 'vigilante',
  },
  {
    label: 'Visitador de Navios',
    value: 'visitador_de_navios',
  },
  {
    label: 'Vistoriador de Imóveis',
    value: 'vistoriador_de_imoveis',
  },
  {
    label: 'Vistoriador de Veículos',
    value: 'vistoriador_de_veiculos',
  },
  {
    label: 'Visual Merchandiser',
    value: 'visual_merchandiser',
  },
  {
    label: 'Vitrinista',
    value: 'vitrinista',
  },
  {
    label: 'Web Designer Trainee',
    value: 'web_designer_trainee',
  },
  {
    label: 'Web Designer',
    value: 'web_designer',
  },
  {
    label: 'Web Developer',
    value: 'web_developer',
  },
  {
    label: 'Webdesign',
    value: 'webdesign',
  },
  {
    label: 'Webmaster',
    value: 'webmaster',
  },
  {
    label: 'Zelador',
    value: 'zelador',
  },
  {
    label: 'Zootecnista',
    value: 'zootecnista',
  },
];
