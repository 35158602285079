import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts'
import { useState } from 'react'
import { formatNumber } from '../../../../../../../utility/utility'
import { ChartTitle, formatChartNumber, TooltipContainer } from '../../../styles'

export function Chart({ data }) {
  const [barHovered, setBarHovered] = useState(false)
  const [focusBar, setFocusBar] = useState(null)

  const formattedData = data?.map((item) => {
    const [key, value] = Object.entries(item)[0]
    const [month, year] = key.split('/')
    return {
      hora: `${month}/${year.slice(-2)}`,
      valor: parseFloat(value),
    }
  })

  const CustomTooltip = ({ payload }) => {
    const valor = payload?.[0]?.payload?.valor
    const data = payload?.[0]?.payload?.hora

    return (
      <TooltipContainer type="signup">
        <strong>{data}</strong>
        <strong>{formatNumber(valor)} Cadastros</strong>
      </TooltipContainer>
    )
  }

  return (
    <>
      <ChartTitle style={{ fontWeight: 'bold', padding: '2rem 0 0 2rem' }}>Cadastros Mês a Mês</ChartTitle>
      <div style={{ width: '100%', height: '25rem' }}>
        <ResponsiveContainer width="100%" height="100%">
          {data?.length === 0 ? (
            <div style={{ height: '25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4 style={{ margin: '0', color: 'gray', padding: 30, textAlign: 'center' }}>
                Por enquanto não há dados a serem carregados
              </h4>
            </div>
          ) : (
            <BarChart data={formattedData || []} margin={{ top: 20, left: 20, right: 20, bottom: 40 }}>
              <CartesianGrid stroke="#444" vertical={false} strokeOpacity={0.6} />
              <XAxis
                axisLine={false}
                dataKey="hora"
                tick={{ fill: 'var(--signup-chart-color)', fontWeight: 'bold' }}
                orientation="top"
                tickMargin={15}
              />
              <YAxis axisLine={false} tick={{ fill: '#888888' }} tickFormatter={formatChartNumber} tickMargin={15} />

              <defs>
                <linearGradient id="signupGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="30%" stopColor="var(--signup-chart-color)" />
                  <stop offset="100%" stopColor="var(--signup-chart-color-fade-stop)" />
                </linearGradient>
                <linearGradient id="signupGradientOnHover" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="30%" stopColor="var(--signup-chart-color-hover)" />
                  <stop offset="100%" stopColor="var(--signup-chart-color-hover-end)" />
                </linearGradient>
                {/* <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="var(--signup-chart-color)" stopOpacity={1} />
                    <stop offset="100%" stopColor="var(--signup-chart-color)" stopOpacity={0} />
                  </linearGradient> */}
              </defs>

              <Tooltip cursor={false} active={barHovered} content={<CustomTooltip />} />

              <Bar
                dataKey="valor"
                radius={[8, 8, 0, 0]}
                barSize={40}
                onMouseEnter={() => setBarHovered(true)}
                onMouseLeave={() => setBarHovered(false)}
              >
                {formattedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={focusBar === index ? 'url(#signupGradientOnHover)' : 'url(#signupGradient)'}
                    onMouseEnter={() => setFocusBar(index)}
                    onMouseLeave={() => setFocusBar(null)}
                  />
                ))}
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </>
  )
}
