import { Col, Form, Row } from 'antd'
import { StyledTextArea } from '../../../components/TextArea'
import { PhotoUpload, PhotoUploadData } from '../../../components/UploadPhoto'
import { FormInstance } from 'antd/es/form/Form'
import { validateDate } from '../../../../../utility/utility'
import ReactInputMask from 'react-input-mask'
import { StyledInput } from '../../../components/Inputs'

type InstitutoFormFieldsProps = {
  form: FormInstance
  fotoVocacional: PhotoUploadData
  setFotoVocacional: (foto: PhotoUploadData) => void
}

export function InstitutoFormFields({ form, fotoVocacional, setFotoVocacional }: InstitutoFormFieldsProps) {
  return (
    <>
      <Row gutter={25}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Motivação pelo Instituto"
            name="motivacao_instituto"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
          >
            <StyledTextArea placeholder="Motivação" showCount maxLength={100} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label="Motivação da Admissão Vocacional"
            name="motivacao_admissao_vocacional"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
          >
            <StyledTextArea placeholder="Motivação" showCount maxLength={100} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={25}>
        <Col xs={24} md={12}>
          <Form.Item
            name="referencia_conhecimento_instituto"
            label="Referência de conhecimento do Instituto"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <StyledTextArea placeholder="Digite" showCount maxLength={100} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label="Identificação com o Instituto"
            name="identificacao_instituto"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
          >
            <StyledTextArea placeholder="Identificação" showCount maxLength={100} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={25} style={{ alignItems: 'center' }}>
        <Col xs={24} md={12}>
          <Row justify="center">
            <Form.Item
              name="foto_vocacional"
              label="Foto do vocacional"
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}
              rules={[
                {
                  required: true,
                  message: 'Por favor, envie uma imagem!',
                  validator: () => {
                    if (fotoVocacional.length === 0) {
                      return Promise.reject(new Error('Por favor, envie uma imagem!'))
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <PhotoUpload
                initialFileList={fotoVocacional}
                setImage={(image) => setFotoVocacional(image as PhotoUploadData)}
                validateField={() => form.validateFields(['foto_vocacional'])}
                numeroFotos={1}
                aspect={3 / 2}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Seminário de Vida no Espírito Santo realizado em"
            name="seminario_realizado_em"
            rules={[
              { required: true, message: 'Por favor, preencha sua data de nascimento!' },
              { validator: (_, value) => validateDate(value) },
            ]}
          >
            <ReactInputMask mask="99/99/9999" placeholder="Dia/Mês/Ano">
              {(inputProps) => <StyledInput {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label="Testemunho da conversão"
            name="testemunho_conversao"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
          >
            <StyledTextArea placeholder="Testemunho" />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label="Deixou a religião anterior em"
            name="deixou_religiao_anterior_em"
            rules={[{ validator: (_, value) => validateDate(value) }]}
          >
            <ReactInputMask mask="99/99/9999" placeholder="Dia/Mês/Ano">
              {(inputProps) => <StyledInput {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
