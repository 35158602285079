import { Row } from 'antd'

import { useState } from 'react'

import { AdicionarItem } from './AdicionarItem'
import { AtualizarItem } from './AtualizarItem'
import BaseModal from '../../../../../components/modal/BaseModal'
import BaseRadio from '../../../../../components/radio/BaseRadio'

interface CadastrarEstoqueProps {
  open: boolean
  handleIsOpenCadastroEstoque: () => void
}

export function CadastrarEstoque({ open, handleIsOpenCadastroEstoque }: CadastrarEstoqueProps) {
  const [selectedItem, setSelectedItem] = useState<'novo' | 'existente'>('novo')

  const pages = {
    existente: <AtualizarItem />,
    novo: <AdicionarItem />,
  }

  return (
    <BaseModal
      title={<h3>Cadastrar estoque</h3>}
      open={open}
      onCancel={handleIsOpenCadastroEstoque}
      footer={false}
      width={1000}
    >
      <Row justify="center" style={{ marginBottom: '1.5rem' }}>
        <BaseRadio.Group value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}>
          <BaseRadio.Button value="novo">Novo</BaseRadio.Button>
          <BaseRadio.Button value="existente">Existente</BaseRadio.Button>
        </BaseRadio.Group>
      </Row>

      <Row justify="center">{pages[selectedItem]}</Row>
    </BaseModal>
  )
}
