import { ReactNode, useState } from 'react'
import { FormInstance } from 'antd'
import { notification } from 'antd'

export interface Step {
  title: ReactNode
  content: JSX.Element
  icon?: ReactNode
}

export interface MultiStepFormProps {
  currentStep: number
  next: Function
  prev: Function
  isFirstStep: boolean
  isLastStep: boolean
}

export function useMultistepForm(steps: Step[], form: FormInstance) {
  const [currentStep, setCurrentStep] = useState(0)

  const next = async () => {
    try {
      await form.validateFields()
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
    } catch {
      notification.error({
        message: 'Revise os campos preenchidos',
        description: 'Corrija antes de prosseguir.',
      })
    }
  }

  const prev = () => setCurrentStep((prev) => Math.max(prev - 1, 0))

  const isLastStep = currentStep === steps.length - 1
  const isFirstStep = currentStep === 0

  return { currentStep, next, prev, isFirstStep, isLastStep }
}
