import { FormInstance } from 'antd'
import { BaseDivider } from '../../../../../components/divider/BaseDivider'
import { AddressForm } from '../../../../../components/forms/components/Address/AddressAdmin'
import { ListaFormularioItensDoacao } from './ListaFormularioItensDoacao'
import { useGetItensEstoque } from '../../../../../hooks/agape/queries/useGetItensEstoque'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import { EnderecoCicloDoacao } from './EnderecoCicloDoacao'

interface CicloDoacaoFormularioProps {
  form: FormInstance
  desativarAdicionarItem: boolean
}

const AbrangenciaOptions = [
  { value: 'sem_restricao', label: 'Sem restrição' },
  { value: 'cep', label: 'CEP' },
  { value: 'estado', label: 'Estado' },
  { value: 'cidade', label: 'Cidade' },
  { value: 'bairro', label: 'Bairro' },
  { value: 'rua', label: 'Rua' },
] as const

export function CicloDoacaoFormulario({ form, desativarAdicionarItem }: CicloDoacaoFormularioProps) {
  const { data: itensEstoque } = useGetItensEstoque()

  return (
    <>
      <div>
        <h3>Endereço</h3>

        <EnderecoCicloDoacao form={form} />

        <BaseForm.Item label="Abrangência" name="abrangencia" rules={[{ required: true, message: 'Obrigatório' }]}>
          <BaseSelect placeholder="Área de abrangência do ciclo de doação">
            {AbrangenciaOptions.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </BaseSelect>
        </BaseForm.Item>
      </div>

      <BaseDivider />

      <div>
        <h3>Doação</h3>

        <ListaFormularioItensDoacao
          form={form}
          itensEstoque={itensEstoque?.estoques!}
          desativarAdicionarItem={desativarAdicionarItem}
        />
      </div>
    </>
  )
}
