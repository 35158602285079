import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment' // Import Moment.js
import { Form } from 'antd'
import { Container } from './Style'
import { LiveMetricsCards } from './components/LiveMetricsCards/LiveMetricsCards'
import { LiveMetricsChart } from './components/LiveMetricsChart/LiveMetricsChart'
import { CreateNewLiveModal } from './components/CreateNewLiveModal/CreateNewLiveModal'
import { CreateChannelModal } from './components/CreateChannelModal/CreateChannelModal'
import { ScheduledLivesTable } from './components/ScheduledLivesTable/ScheduledLivesTable'
import { CalendarLives } from './components/CalendarLives/CalendarLives'
import { DataService } from '../../../../lib/dataService'
import BaseButton from '../../components/button/BaseButton'
import { BaseNotification } from '../../components/notification/BaseNotification'

import { useGetAvailableLiveDates } from '../../hooks/lives/queries/useGetAvailableLiveDates'
import { useGetLiveDetails } from '../../hooks/lives/queries/useGetLiveDetails'
import { useGetLivesChannels } from '../../hooks/lives/queries/useGetLivesChannels'
import { useGetLivesListOnSelectedDate } from '../../hooks/lives/queries/useGetLivesListOnSelectedDate'

export const Lives = () => {
  const queryClient = useQueryClient()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false)

  const [selectedDate, setSelectedDate] = useState()
  const [socialMedia, setSocialMedia] = useState('')
  const [tipo, setTipo] = useState('')
  const [periodicaBody, setPeriodicaBody] = useState({})
  const [liveDetailToBeFetched, setLiveDetailToBeFetched] = useState({})

  const [form] = Form.useForm()

  const availableLiveDates = useGetAvailableLiveDates(setSelectedDate)

  const livesListOnSelectedDate = useGetLivesListOnSelectedDate(selectedDate, setLiveDetailToBeFetched)

  const liveDetails = useGetLiveDetails(liveDetailToBeFetched)

  const { data: channels } = useGetLivesChannels(socialMedia)

  function clearModalState() {
    setSocialMedia('')
    setTipo('')
    setPeriodicaBody({})
    setIsModalOpen(false)
    form.resetFields()
  }

  const registerLiveMutation = useMutation({
    mutationFn: (body) => DataService.post(`youtube/register-live`, body),
    onSuccess: () => {
      BaseNotification.success({ message: 'Live registrada com sucesso!' })
      queryClient.invalidateQueries(['scheduledLivesDataSource'])
      clearModalState()
    },
    onError: (error) => {
      BaseNotification.error({ message: 'Ocorreu um erro ao cadastrar a live.' })
    },
  })

  const onFinish = (values) => {
    const { rede_social, canal, tipo, data_da_live, hora_inicio, dia_semana } = values

    const formattedDate = moment(data_da_live).format('YYYY-MM-DD')

    const formattedTime = moment(hora_inicio).format('HH:mm:ss')

    const data_hora_inicio = `${formattedDate} ${formattedTime}`

    const avulsoReqBody = {
      canais_ids: [...canal],
      tipo,
      data_hora_inicio,
    }

    let body

    if (tipo === 'periodica' && periodicaBody?.programacoes === undefined) {
      BaseNotification.error({ message: 'Para lives avulsas, adicione à lista antes de enviar' })
      return
    }
    tipo === 'avulsa' ? (body = avulsoReqBody) : (body = periodicaBody)

    registerLiveMutation.mutate(body)

    clearModalState()
    setSocialMedia('')
    setTipo('')
    setPeriodicaBody({})
    setIsModalOpen(false)
    form.resetFields() // possible mac error
  }

  return (
    <>
      <CreateNewLiveModal
        registerLiveMutation={registerLiveMutation}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        clearModalState={clearModalState}
        form={form}
        onFinish={onFinish}
        periodicaBody={periodicaBody}
        setPeriodicaBody={setPeriodicaBody}
        socialMedia={socialMedia}
        setSocialMedia={setSocialMedia}
        channels={channels}
        setTipo={setTipo}
        tipo={tipo}
      />

      <CreateChannelModal isChannelModalOpen={isChannelModalOpen} setIsChannelModalOpen={setIsChannelModalOpen} />

      <Container>
        <CalendarLives
          availableLiveDates={availableLiveDates}
          setSelectedDate={setSelectedDate}
          livesListOnSelectedDate={livesListOnSelectedDate}
          selectedDate={selectedDate}
          setLiveDetailToBeFetched={setLiveDetailToBeFetched}
          liveDetailToBeFetched={liveDetailToBeFetched}
        />
        <>
          <div>
            {livesListOnSelectedDate?.data?.titulos?.length > 0 && (
              <>
                <h3>{liveDetails?.data?.data?.live_data?.[0]?.titulo}</h3>
                <LiveMetricsCards liveDetails={liveDetails} />
                <LiveMetricsChart liveDetails={liveDetails} />
              </>
            )}
            <ScheduledLivesTable />
            <div style={{ padding: '1.5rem', display: 'flex', gap: '1rem', justifyContent: 'center' }}>
              <BaseButton onClick={() => setIsModalOpen(true)} severity="info">
                Adicionar Live
              </BaseButton>
              <BaseButton onClick={() => setIsChannelModalOpen(true)} severity="info">
                Adicionar Canal
              </BaseButton>
            </div>
          </div>
        </>
      </Container>
    </>
  )
}
