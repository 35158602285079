import { FilterOutlined } from '@ant-design/icons'
import { formatToBrazilianCurrency } from '../../../../../../../../utility/utility'
import BaseButton from '../../../../../button/BaseButton'
import { BaseSpin } from '../../../../../spin/BaseSpin.Styles'
import { BaseTable } from '../../../../../table/BaseTable/BaseTable'
import { useState } from 'react'
import { DonationsTableVisibleColumns, DonationsTableVisibleFilters } from '../..'
import { DonationApiResponse } from '../../../../../../hooks/donations/queries/useFetchDonations'
import { UseQueryResult } from '@tanstack/react-query'
import { useGetDonationsColumns } from '../../hooks/useGetDonationsColumns'
import { BlurDiv } from '@/context/Blur/BlurDiv'

const fieldMap = {
  id: 'id',
  metodo: 'forma_pagamento',
  referencia: 'codigo_referencia',
  recorrencia: 'recorrencia',
  recorrencia_status: 'recorrencia_status',
  transacao_id: 'transaction_id',
  cliente: 'nome',
  data: 'data_doacao',
  valor: 'valor_doacao',
  status: 'status',
  campanha: 'titulo_campanha',
  acoes: 'acoes',
  gateway: 'gateway',
  comprovante: 'comprovante',
}

export interface DonationRow {
  key: string | number
  id: React.ReactNode
  codigo_referencia: {
    copyValue: () => void
  }
  recorrencia: {
    recurrency: () => React.ReactNode
  }
  transaction_id: {
    hasTransactionId: string
    copyValue: () => void
  }
  acoes: {
    ac: React.ReactNode[]
  }
  nome: React.ReactNode
  forma_pagamento: string
  data_doacao: string
  titulo_campanha: string | null
  status: string | React.ReactNode
  valor_doacao: string
  gateway: string
  user_name: string
}

interface DonationsListTableProps {
  visibleColumns: DonationsTableVisibleColumns[]
  visibleFilters: DonationsTableVisibleFilters[]
  handleInputChange: Function
  handleOpenFiltersModal: () => void
  dataSource: DonationRow[] | undefined
  donations: UseQueryResult<DonationApiResponse>
  filters: any
}

export function DonationsListTable({
  visibleColumns,
  handleInputChange,
  handleOpenFiltersModal,
  donations,
  visibleFilters,
  dataSource,
  filters,
}: DonationsListTableProps) {
  const [pageSize, setPageSize] = useState(10)

  const columns = useGetDonationsColumns({
    filters,
    visibleFilters,
    dataSource,
    handleInputChange,
  })

  return (
    <div className="w-100">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '0.8rem' }}>
        <div>
          <h3>
            Total de doações:
            {donations?.isLoading ? (
              <BaseSpin />
            ) : (
              <BlurDiv style={{ color: 'var(--text-main-color)' }}>
                {formatToBrazilianCurrency(donations?.data?.total_doado ?? 0)}
              </BlurDiv>
            )}
          </h3>
        </div>

        <BaseButton onClick={() => handleOpenFiltersModal()}>
          <FilterOutlined />
        </BaseButton>
      </div>
      <BaseTable
        dataSource={dataSource}
        isLoading={donations?.isLoading}
        isError={donations?.isError}
        columns={columns.filter((column) => {
          const matchedKey = Object.keys(fieldMap).find(
            (key) => fieldMap[key as keyof typeof fieldMap] === column.dataIndex,
          )

          return matchedKey && visibleColumns.includes(matchedKey as DonationsTableVisibleColumns)
        })}
        pagination={{
          current: donations?.data?.page || 1,
          total: donations?.data?.total,
          pageSize,
          showSizeChanger: true,
        }}
        onChange={(pagination) => {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
          setPageSize(pagination?.pageSize!)
        }}
      />
    </div>
  )
}
