import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../lib/dataService'
import { notification } from 'antd'

interface CreateProfileReqBody {
  nome: string
  status: boolean
  super_perfil: boolean
}

export function useCreateProfile() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: CreateProfileReqBody) => {
      await DataService.post(`administradores/cadastrar-perfil`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['listar-perfis'],
      })
      notification.success({ message: 'Perfil criado com sucesso.' })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao criar o perfil',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })
}
