export default function GroupCardAnalytics({ children }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'stretch',
        gap: '8px',
        fontSize: '14px',
        flexWrap: 'wrap',
      }}
    >
      {children}
    </div>
  )
}
