import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetMonthlyRecurrenceCount(filters) {
  return useQuery({
    queryKey: ['MONTHLY_RECURRENCE_COUNT', filters],
    queryFn: async () => {
      const query = await DataService.get(
        `admin/dash-board/donations/actual-donations-recorrentes?${filters?.queryString || ''}`,
      )
      return query?.data
    },
  })
}
