import { useEffect, useState } from 'react'
import { ChartContainer, LegendColor, LegendContainer, LegendItem, Title } from './styles'
import { Chart } from './Chart'
import {
  AnimatedBaseCard,
  ErrorMessage,
  LoadingContainer,
  PieChartContainer,
  PieChartLegendColor,
  PieChartLegendContainer,
  PieChartLegendItem,
  PieChartTitle,
} from '../../../styles'
import { useGetUsersPerCampaign } from '../../../../../hooks/dashboards/signups/queries/useGetUsersPerCampaign'

export function SignupsPerCampaign() {
  const { data, isPending: isUsersByCampaignPending, isError: isUsersByCampaignError } = useGetUsersPerCampaign()

  const [campaignsStats, setCampaignsState] = useState({
    formattedData: [],
    statusColor: '',
    value: '',
    percentage: '',
  })

  useEffect(() => {
    if (data && data.length > 0) {
      const initialData = {
        formattedData: data?.map((object) => ({
          id: object.campanha,
          label: object.campanha,
          value: object.quantidade_usuarios,
          percentage: object.percentual,
        })),
        statusColor: 'var(--signup-chart-color)',
        value: data?.[0]?.quantidade_usuarios,
        percentage: data?.[0]?.percentual,
      }

      setCampaignsState(initialData)
    }
  }, [data])

  const renderCustomLegend = () => {
    const colors = ['var(--signup-chart-color)', '#8405f4', '#ea5472', '#5fc70b', '#efb108', '#e67f4f']

    return (
      <PieChartLegendContainer>
        {campaignsStats.formattedData.map((datum, index) => (
          <PieChartLegendItem
            style={{ zIndex: '9999' }}
            key={datum.id}
            color={colors[index % colors.length]}
            onMouseEnter={() => {
              setCampaignsState((prevState) => ({
                ...prevState,
                statusColor: colors[index % colors.length],
                percentage: datum.percentage,
                value: datum.value,
              }))
            }}
          >
            <PieChartLegendColor color={colors[index % colors.length]} />
            <span>{datum.label}</span>
          </PieChartLegendItem>
        ))}
      </PieChartLegendContainer>
    )
  }

  return (
    <AnimatedBaseCard>
      {isUsersByCampaignPending ? (
        <LoadingContainer height="20rem" />
      ) : isUsersByCampaignError ? (
        <ErrorMessage height="20rem" />
      ) : (
        <>
          <PieChartTitle>Por Campanha</PieChartTitle>
          <PieChartContainer>
            <Chart campaignsStats={campaignsStats} setCampaignsState={setCampaignsState} />
          </PieChartContainer>
          {renderCustomLegend()}
        </>
      )}
    </AnimatedBaseCard>
  )
}
