import styled from 'styled-components'
import { Pagination as AntdPagination } from 'antd'

export const Pagination = styled(AntdPagination)`
  .ant-pagination-item {
    background-color: var(--background-color); /* Cor de fundo das opções */
    border: 1px solid var(--text-secondary-color); /* Borda das opções */
  }

  .ant-pagination-item:hover {
    background-color: var(
      --text-secondary-color
    ); /* Cor de fundo quando a opção é destacada (hover) */
  }

  .ant-pagination-item a:hover {
    color: var(--text-main-color);
    background-color: var(
      --text-secondary-color
    ); /* Cor de fundo quando a opção é destacada (hover) */
  }

  .ant-pagination-item-active {
    background-color: var(
      --text-secondary-color
    ); /* Cor de fundo da opção selecionada */
    border-color: var(--text-secondary-color); /* Borda da opção selecionada */
  }

  .ant-pagination-item-link {
    color: var(--text-main-color);
    background-color: var(--background-color);

    :hover {
      color: var(--text-main-color);
      border-color: var(--text-secondary-color);
      background-color: var(--text-secondary-color);
    }
  }

  .ant-pagination-item.ant-pagination-item-active a {
    color: var(--text-main-color); /* Cor do texto da opção selecionada */
  }

  .ant-pagination-item a {
    color: var(
      --text-secondary-color
    ); /* Cor do texto das opções que não estão selecionadas */
  }

  .ant-pagination-disabled {
    color: var(--text-secondary-color); /* Cor do texto quando desativado */
  }

  .ant-pagination-disabled .ant-pagination-item {
    background-color: var(
      --disabled-color
    ); /* Cor de fundo quando desativado */
    border-color: var(--disabled-color); /* Borda quando desativado */
  }

  .ant-pagination-disabled .ant-pagination-item a {
    color: var(--disabled-color); /* Cor do texto quando desativado */
  }

  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: var(--text-main-color);
  }

  .ant-pagination-item-container {
    .ant-pagination-item-ellipsis {
      color: var(--text-main-color); // Cor do ellipsis
    }
  }

  .ant-pagination-options-quick-jumper {
    color: var(--text-main-color);
  }

  /* SELECT */

  .ant-select-selector {
    border-radius: 4px; // Ajusta o border-radius do seletor
    padding: 4px 8px; // Ajusta o padding interno
    background-color: var(
      --background-color
    ) !important; // Cor de fundo do seletor
    color: var(--text-main-color); // Cor do texto do seletor
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 0px var(--input-border-color) !important;

    &:hover {
      border-color: var(--input-border-color) !important;
      box-shadow: 0 0 0 1px var(--input-border-color) !important;
    }
  }

  &.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open {
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 1px var(--input-border-color) !important;
  }

  .ant-select-selection-item {
    font-size: 14px; // Ajusta o tamanho da fonte dos itens selecionados
    color: var(
      --text-main-color
    ); // Cor do texto dos itens selecionados (no select fechado)
  }

  .ant-select-arrow {
    color: var(--text-main-color); // Cor da seta
  }

  .ant-select-dropdown {
    border-radius: 4px; // Ajusta o border-radius do dropdown
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); // Adiciona sombra ao dropdown
    background-color: var(--background-color); // Cor de fundo do dropdown

    .ant-select-item-option-content {
      color: var(--text-main-color); // Cor do texto dos itens
    }

    .ant-select-item-option-disabled {
      .ant-select-item-option-content {
        color: var(--text-secondary-color);
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-selected) {
      background-color: var(
        --text-secondary-color
      ); // Cor de fundo ao passar o mouse em um item não selecionado
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
      background-color: var(--button-bg-color);
      .ant-select-item-option-content {
        color: var(--button-text-color);
      }
    }
  }

  .ant-select-clear {
    background-color: var(
      --background-color
    ) !important; // Cor de fundo do botão de fechar

    svg {
      color: var(--text-main-color) !important; // Cor do ícone
    }
  }

  .ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-item {
        color: var(--disabled-color);
      }
    }
  }
`
