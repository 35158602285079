import { StatusValues } from '../utils/statusMap'
import { AcoesNaoIniciado } from './AcoesNaoIniciado'
import { AcoesEmAndamento } from './AcoesEmAndamento'
import { AcoesFinalizado } from './AcoesFinalizado'

interface GetActionsProps {
  status: StatusValues
  actionId: number
  nomeAcao: string
}

export function getActions({ status, actionId, nomeAcao }: GetActionsProps) {
  const actionsMap = {
    'Não iniciado': <AcoesNaoIniciado idCicloDoacao={actionId} nomeAcao={nomeAcao} />,
    'Em andamento': <AcoesEmAndamento idCicloDoacao={actionId} nomeAcao={nomeAcao} />,
    Finalizado: <AcoesFinalizado idCicloDoacao={actionId} nomeAcao={nomeAcao} />,
  }

  return actionsMap[status]
}
