export const MENU_ITEMS_KEYS = {
  DASHBOARDS: 'DASHBOARDS',
  DASHBOARDS_CADASTROS: 'DASHBOARDS_CADASTROS',
  DASHBOARDS_DOACOES: 'DASHBOARDS_DOACOES',

  CAMPANHAS_ACOES: 'CAMPANHAS_ACOES',
  CAMPANHAS_LISTA: 'CAMPANHAS_LISTA',
  CAMPANHAS_NOVA: 'CAMPANHAS_NOVA',
  ACOES_LISTA: 'ACOES_LISTA',
  ACOES_NOVA: 'ACOES_NOVA',

  USUARIOS: 'USUARIOS',
  USUARIOS_LISTA: 'USUARIOS_LISTA',
  USUARIOS_LEADS: 'USUARIOS_LEADS',
  USUARIOS_GENERAIS_MARECHAIS: 'USUARIOS_GENERAIS_MARECHAIS',

  AGAPE: 'AGAPE',
  AGAPE_SOCIAL: 'AGAPE_SOCIAL',

  VOCACIONAL: 'VOCACIONAL',
  VOCACIONAL_MASCULINO: 'VOCACIONAL_MASCULINO',
  VOCACIONAL_FEMININO: 'VOCACIONAL_FEMININO',

  FINANCEIRO: 'FINANCEIRO',
  FINANCEIRO_LISTA_DOACOES: 'FINANCEIRO_LISTA_DOACOES',
  FINANCEIRO_PRINCIPAIS_DOADORES: 'FINANCEIRO_PRINCIPAIS_DOADORES',
  FINANCEIRO_DOACOES_RECORRENTES: 'FINANCEIRO_DOACOES_RECORRENTES',
  FINANCEIRO_DOACOES_DESCONSIDERADAS: 'FINANCEIRO_DOACOES_DESCONSIDERADAS',

  ACESSOS_SISTEMA: 'ACESSOS_SISTEMA',
  ACESSOS_SISTEMA_PERMISSOES: 'ACESSOS_SISTEMA_PERMISSOES',
  ACESSOS_SISTEMA_LOGS: 'ACESSOS_SISTEMA_LOGS',
};
