import { ModalProps } from "antd";
import { ReactNode } from "react";
import { StyledAntModal } from "./styles";

interface StyledModalProps extends ModalProps {
    children?: ReactNode
}

export function StyledModal({  children, ...props } : StyledModalProps) {
    return (
        <StyledAntModal {...props}>
            { children }
        </StyledAntModal>
    )
}