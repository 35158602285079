import { Card } from 'antd'
import styled, { css } from 'styled-components'

export const StyledCard = styled(Card) < { padding?: string | number } > `
  /* color: var(--text-main-color); */
  background-color: var(--card-bg-color);
  border-radius: 16px;
  border: var(--card-border);

  .ant-card-body {
    padding: ${(props) => props?.padding || '24px'};
  }

  .ant-card-head-title {
    color: var(--text-main-color);
    font-size: 1.5rem;
    padding-top: 1.2rem;
  }

  .ant-card-head {
    border-radius: 16px;
    border-bottom: none;
    background-color: var(--card-bg-color);
  }
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); */
  /* box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1); */

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
`
