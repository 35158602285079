import { Form, Row } from 'antd'
import BaseModal from '../../../../../components/modal/BaseModal'
import BaseButton from '../../../../../components/button/BaseButton'
import {
  AbrangenciaCicloDoacao,
  useCadastrarCicloDoacao,
} from '../../../../../hooks/agape/mutations/useCadastrarCicloDoacao'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import { CicloDoacaoFormulario } from './CicloDoacaoFormulario'
import { BaseDivider } from '../../../../../components/divider/BaseDivider'
import { useGetCicloDoacaoAnterior } from '../../../../../hooks/agape/queries/useGetCicloDoacaoAnterior'
import { BaseSpin } from '../../../../../components/spin/BaseSpin.Styles'
import { ErrorResult } from '../../../../../components/result/resultStatus/ErrorResult'
import { EstoqueAgapeItemFormResponse, transformarArrayDoacoes } from './ListaFormularioItensDoacao'
import { useListarNomeAcoesSolidarias } from '../../../../../hooks/agape/queries/useListarNomeAcoesSolidarias'
import { formatCEP } from '../../../../../../../utility/utility'
import { useEffect, useState } from 'react'

interface CadastrarEstoqueProps {
  open: boolean
  handleIsOpenAcaoDoacao: () => void
}

interface CadastraraAcaoDoacaoFormData {
  bairro: string
  cep: string
  cidade: string
  complemento?: string
  estado: string
  numero: string
  rua: string
  fk_acao_agape_id: number
  doacoes: EstoqueAgapeItemFormResponse[]
  abrangencia: AbrangenciaCicloDoacao
}

export function CadastrarCicloDoacao({ open, handleIsOpenAcaoDoacao }: CadastrarEstoqueProps) {
  const [form] = Form.useForm()

  const { data: nomeAcoesSolidarias } = useListarNomeAcoesSolidarias()

  const { mutateAsync: cadastrarCicloDoacao, isPending: isPendingCadastrarCicloDoacao } = useCadastrarCicloDoacao()

  const acaoSelecionada = Form.useWatch('fk_acao_agape_id', form)

  const [isFormInitialized, setIsFormInitialized] = useState(false)
  const {
    data: cicloAnterior,
    isLoading: isLoadingCicloAnterior,
    isError: isErrorCicloAnterior,
    error,
  } = useGetCicloDoacaoAnterior({ fk_acao_agape_id: acaoSelecionada })

  useEffect(() => {
    if (cicloAnterior && !isFormInitialized) {
      const formData = {
        doacoes: cicloAnterior.doacoes.map((doacao) => ({
          fk_estoque_agape_id: {
            value: doacao.fk_estoque_agape_id,
            label: doacao.item,
          },
          quantidade: doacao.quantidade,
        })),
        ...cicloAnterior.endereco,
        cep: formatCEP(cicloAnterior.endereco.cep),
        abrangencia: cicloAnterior.abrangencia,
      }

      form.setFieldsValue(formData)
      setIsFormInitialized(true)
    }
  }, [cicloAnterior, form, isFormInitialized])

  async function handleCadastroAcaoDoacao(data: CadastraraAcaoDoacaoFormData) {
    const endereco = {
      bairro: data.bairro,
      cep: data.cep,
      cidade: data.cidade,
      complemento: data.complemento,
      estado: data.estado,
      numero: data.numero,
      rua: data.rua,
    }

    const arrayDoacoes = transformarArrayDoacoes(data.doacoes)

    await cadastrarCicloDoacao({
      doacoes: arrayDoacoes,
      endereco,
      fk_acao_agape_id: data.fk_acao_agape_id,
      abrangencia: data.abrangencia,
    })

    form.resetFields()

    handleIsOpenAcaoDoacao()
  }

  return (
    <BaseModal
      title={<h3>Cadastrar ciclo de doação</h3>}
      open={open}
      onCancel={handleIsOpenAcaoDoacao}
      footer={false}
      size="large"
    >
      <Form form={form} layout="vertical" onFinish={handleCadastroAcaoDoacao}>
        <Row justify="center">
          <BaseForm.Item
            label={
              <h3 style={{ textAlign: 'center', margin: 0 }}>Localize a ação solidária que esse ciclo fará parte</h3>
            }
            name="fk_acao_agape_id"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <BaseSelect placeholder="Pesquise e selecione uma opção" showSearch allowClear>
              {nomeAcoesSolidarias?.acoes_agape.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </BaseSelect>
          </BaseForm.Item>
        </Row>

        {isErrorCicloAnterior && error?.response?.status !== 404 && <ErrorResult style={{ margin: '1rem 0 2rem' }} />}

        {isLoadingCicloAnterior && (
          <Row justify="center" style={{ margin: '1rem 0 2rem' }}>
            <BaseSpin />
          </Row>
        )}

        {(cicloAnterior || (isErrorCicloAnterior && error?.response?.status === 404)) && (
          <>
            <BaseDivider />
            <CicloDoacaoFormulario form={form} desativarAdicionarItem={false} />
          </>
        )}

        <Row justify="center">
          <BaseButton htmlType="submit" loading={isPendingCadastrarCicloDoacao}>
            Enviar
          </BaseButton>
        </Row>
      </Form>
    </BaseModal>
  )
}
