import BaseCard from '../../../../../components/card/BaseCard'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'
import moment from 'moment'
import { useListarNomeAcoesSolidarias } from '../../../../../hooks/agape/queries/useListarNomeAcoesSolidarias'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'

interface NomeFilterProps {
  value?: number | null
  handleInputChange: ({ fk_acao_agape_id }: { fk_acao_agape_id: number }) => void
}

function NomeFilter({ value, handleInputChange }: NomeFilterProps) {
  const { data: acoesSolidarias } = useListarNomeAcoesSolidarias()

  return (
    <BaseCard>
      <h4>Nome</h4>

      <BaseSelect
        placeholder="Nome da ação solidária"
        value={value}
        onChange={(value) => handleInputChange({ fk_acao_agape_id: value })}
        allowClear
      >
        {acoesSolidarias?.acoes_agape.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.nome}
          </Option>
        ))}
      </BaseSelect>
    </BaseCard>
  )
}

type CadastroEmFilterProps = {
  value: { data_cadastro_inicial: string; data_cadastro_final: string } | null
  handleInputChange: ({
    data_cadastro_inicial,
    data_cadastro_final,
  }: {
    data_cadastro_inicial: string
    data_cadastro_final: string
  }) => void
}

function CadastroEmFilter({ value, handleInputChange }: CadastroEmFilterProps) {
  const formattedValue = [
    value?.data_cadastro_inicial ? moment(value.data_cadastro_inicial, 'YYYY-MM-DD') : null,
    value?.data_cadastro_final ? moment(value.data_cadastro_final, 'YYYY-MM-DD') : null,
  ]

  return (
    <BaseCard>
      <h4>Cadastro em</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        style={{ width: '100%' }}
        onChange={(_: never, dateStrings: [string, string]) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

          handleInputChange({
            data_cadastro_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_cadastro_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY"
        placeholder={['Inicial', 'Final']}
      />
    </BaseCard>
  )
}

export const AcoesSolidariasFilters = {
  Nome: NomeFilter,
  Cadastro: CadastroEmFilter,
}
