import { Card } from 'antd'
import styled from 'styled-components'

interface StyledCardProps {
  shadow?: boolean
}

export const StyledCard = styled(Card).attrs((props: StyledCardProps) => ({
  shadow: props.shadow !== undefined ? props.shadow : true,
}))<StyledCardProps>`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 16px;

  border: 1px solid #D5D5D5;

  .ant-card-body {
    padding: 24px;
  }

  .ant-card-head-title {
    padding-top: 1.2rem;
    font-size: ${({ theme }) => theme.fonts.size.large};
    font-family: ${({ theme }) => theme.fonts.family.secondary};
    color: ${({ theme }) => theme.colors['dark-brown']};
    font-weight: 900;
  }

  .ant-card-head {
    border-radius: 16px;
    border-bottom: none;
    background-color: ${(props) => props.theme.colors.white};
  }

  /* .error-message {
    width: 100%;
    min-height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: ${({ theme }) => theme.fonts.size['extra-large']};
      font-weight: 700;
      color: ${({ theme }) => theme.fonts.color.muted};
    }
  } */

  ${(props) =>
    props.shadow &&
    `
  
  `}
`
