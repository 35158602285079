import React from 'react';
import { Button, ButtonProps } from 'antd';
import * as S from './BaseButton.Styles';

type Severity = 'success' | 'info' | 'warning' | 'error';

interface BaseButtonProps extends ButtonProps {
  severity?: Severity;
  children: React.ReactNode;
}

export default function BaseButton({ severity, children, ...props }: BaseButtonProps) {
  return (
    <S.StyledButton $severity={severity} {...props}>
      {children}
    </S.StyledButton>
  );
}
