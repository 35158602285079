import React from 'react'
import styled from 'styled-components'
import { Input, Radio, Space } from 'antd'
import { SearchOutlined, FilterOutlined } from '@ant-design/icons'
import * as S from './BaseTable.Styles'
import { BaseTable } from './BaseTable'
import { BasePaginationLocale } from '../../pagination/BasePaginationLocale'
import { BaseSelect } from '../../select/BaseSelect'

const FilterDropdownWrapper = styled.div`
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);

  .ant-space-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .ant-input-affix-wrapper {
    margin-bottom: 8px;
    width: '100%';
    display: flex;
    &:focus {
      box-shadow: none;
      border-color: black;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: black;

    &:hover {
      border-color: black;
    }
  }
`

const CustomFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
}) => (
  <FilterDropdownWrapper>
    <Input
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={confirm}
      allowClear
    />
    <Space>
      <S.FilterButton onClick={confirm} size="small" icon={<SearchOutlined />}>
        Filtrar
      </S.FilterButton>
      <S.FilterButton
        onClick={() => {
          clearFilters()
          confirm()
        }}
        size="small"
        className="clear-button"
      >
        Limpar
      </S.FilterButton>
      <S.FilterButton onClick={close} size="small" className="cancel-button">
        Fechar
      </S.FilterButton>
    </Space>
  </FilterDropdownWrapper>
)

const CustomRadio = styled(Radio)`
  &.ant-radio-wrapper {
    .ant-radio-checked .ant-radio-inner::after {
      background-color: var(
        --black
      ); // Cor de fundo do radio button selecionado
    }
    .ant-radio-inner {
      background-color: var(--white);
      border-color: var(
        --black
      ); // Cor da borda do radio button não selecionado
    }
  }
`

const RadioFilter = ({
  filters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
}) => {
  const handleChange = (e) => {
    setSelectedKeys(e.target.value ? [e.target.value] : [])
    confirm()
    close() // Fecha o dropdown após aplicar o filtro
  }

  return (
    <FilterDropdownWrapper>
      <Radio.Group
        onChange={handleChange}
        value={selectedKeys[0]} // Predefine o valor selecionado
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start',
          gap: '0.5rem',
          width: '100%',
        }}
      >
        {filters.map((filter) => (
          <CustomRadio key={filter.value} value={filter.value}>
            {filter.text}
          </CustomRadio>
        ))}
      </Radio.Group>
      <Space style={{ marginTop: '1rem' }}>
        <S.FilterButton
          onClick={() => {
            clearFilters() // Limpa os filtros
            confirm() // Aplica a busca com filtros limpos
            close() // Fecha o dropdown após limpar os filtros
          }}
          size="small"
          className="clear-button"
        >
          Limpar
        </S.FilterButton>
        <S.FilterButton onClick={close} size="small" className="cancel-button">
          Fechar
        </S.FilterButton>
      </Space>
    </FilterDropdownWrapper>
  )
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <CustomFilterDropdown
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        close={close}
      />
    ),
    filterIcon: <FilterOutlined />,
    onFilter: (value, record) =>
      record.name.toLowerCase().includes(value.toLowerCase()),
    filterMode: 'tree',
    filterSearch: true,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <RadioFilter
        filters={[
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
        ]}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        close={close}
      />
    ),
    filterIcon: <FilterOutlined />,
    onFilter: (value, record) => record.address.includes(value),
  },
  {
    title: 'A',
    dataIndex: 'a',
    key: 'a',
  },
  {
    title: 'b',
    dataIndex: 'b',
    key: 'b',
  },
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    a: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    b: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '5',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '6',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '7',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '8',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '9',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '10',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '11',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '12',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '13',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '14',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '15',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '16',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
  {
    key: '17',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(selectedRowKeys, selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows) => {
    console.log(selected, selectedRows)
  },
}

export const BaseTableUsage = () => {
  return (
    <BaseTable
      columns={columns}
      dataSource={data}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        locale: BasePaginationLocale,
        // Customiza o select de tamanho da página
        itemRender: (current, type, originalElement) => {
          if (type === 'sizeChanger') {
            return (
              <BaseSelect
                defaultValue={10}
                style={{ width: 90 }}
                options={[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                  { label: '50', value: 50 },
                  { label: '100', value: 100 },
                ]}
              />
            )
          }
          return originalElement
        },
      }}
      rowSelection={{ ...rowSelection }}
      expandable={{
        expandedRowRender: (record) => (
          <p
            style={{
              margin: 0,
            }}
          >
            {record.description}
          </p>
        ),
        rowExpandable: (record) => record.name !== 'Jim Red',
      }}
    />
  )
}
