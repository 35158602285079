import { Calendar } from 'antd';
import styled from 'styled-components';

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--calendar-bg);
  /* color: white; */

  button {
    border: none;
    background-color: transparent;
  }

  .icon {
    color: var(--calendar-icon-color);
  }
`;

export const CalendarWrapper = styled.div`
  background-color: var(--calendar-bg);
  height: fit-content;
  width: auto;
  max-width: 25rem;
  border-radius: 8px;
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);
`;

export const StyledCalendar = styled(Calendar)`
  padding: 10px;
  background-color: var(--calendar-bg); // calendar background (outside content)

  .ant-picker-body {
    background-color: var(--calendar-bg);
  }

  .ant-picker-content th {
    color: var(--calendar-weekdays); // weekdays on top: ;
    font-weight: 600;
  }

  .ant-picker-cell.ant-picker-cell-disabled {
    color: var(--calendar-disabled-days); //disabled days
    font-weight: 400;
    cursor: not-allowed !important;
  }

  .ant-picker-content td {
    color: var(--calendar-not-disabled-days); // other days
    font-weight: 800;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--calendar-selected-day-bg);
    color: var(--calendar-selected-day-color);
    font-weight: bolder;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner::before {
    border: none; // border color of selected day
  }

  /* WeekDay Calendar Header */

  table.ant-picker-content thead tr th {
    position: relative; /* Required for absolute positioning */
    color: transparent; /* Hide original text */
  }

  table.ant-picker-content thead tr th::before {
    color: var(--calendar-weekdays);
    position: absolute; /* Position the new text over the original */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
  }

  /* Set content for each weekday */
  table.ant-picker-content thead tr th:nth-child(1)::before {
    content: 'Dom';
  } /* Domingo */
  table.ant-picker-content thead tr th:nth-child(2)::before {
    content: 'Seg';
  } /* Segunda-feira */
  table.ant-picker-content thead tr th:nth-child(3)::before {
    content: 'Ter';
  } /* Terça-feira */
  table.ant-picker-content thead tr th:nth-child(4)::before {
    content: 'Qua';
  } /* Quarta-feira */
  table.ant-picker-content thead tr th:nth-child(5)::before {
    content: 'Qui';
  } /* Quinta-feira */
  table.ant-picker-content thead tr th:nth-child(6)::before {
    content: 'Sex';
  } /* Sexta-feira */
  table.ant-picker-content thead tr th:nth-child(7)::before {
    content: 'Sab';
  } /* Sábado */

  /* .ant-picker-cell-inner.ant-picker-calendar-date { */
  /* color: red; */
  /* } */

  .ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today::before {
    border: none;
  }
`;

export const StyledCalendarFooter = styled.div`
  /* color: #FFFFFF ||# ; */
  color: var(--calendar-footer-live-color);
  padding: 10px;

  .currentDay {
    color: #bfbfbf;
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .liveDate {
    text-align: right;
    color: var(--calendar-footer-live-color);
    margin-top: 0.5rem;
    opacity: 70%;
  }

  .live {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .live-wrapper {
    border-top: 1px solid var(--calendar-footer-live-border-color);
    padding-top: 10px;
    font-size: 12px;
  }
`;

export const ColoredButton = styled.div`
  min-width: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: purple;
  margin-right: 5px;
`;
