import { Col, Form, Radio, Row, Select } from 'antd'
import { StyledSelect } from '../../../components/Select/styles'
import { profissoes } from '../../../../../utility/profissoes'
import { StyledTextArea } from '../../../components/TextArea'
import { useState } from 'react'
import { StyledRangePicker } from '../../../components/DatePicker/styles'
import { escolaridadeOptions, validateDate } from '../../../../../utility/utility'
import { StyledInput } from '../../../components/Inputs'
import ReactInputMask from 'react-input-mask'
import { useSelectAllOption } from '../../../components/Select/selectAll'
import { FormInstance } from 'antd/es/form/Form'

const { Option } = Select

const estadoCivilOptions = [
  { label: 'Solteiro(a)', value: 'solteiro' },
  { label: 'Casado(a)', value: 'casado' },
  { label: 'Divorciado(a)', value: 'divorciado' },
  { label: 'Viúvo(a)', value: 'viuvo' },
  { label: 'União Estável', value: 'uniao_estavel' },
]

export const sacramentosOptions = [
  { label: 'Batismo', value: 'batismo' },
  { label: 'Eucaristia', value: 'eucaristia' },
  { label: 'Crisma', value: 'crisma' },
  { label: 'Matrimônio', value: 'matrimonio' },
  { label: 'Ordem', value: 'ordem' },
  { label: 'Unção dos Enfermos', value: 'uncao_dos_enfermos' },
]

interface VidaFormFieldsProps {
  form: FormInstance
}

export function VidaFormFields({ form }: VidaFormFieldsProps) {
  const [estadoCivilValue, setEstadoCivilValue] = useState<string>('')

  const [getValueFromEvent, optionsWithAllOption] = useSelectAllOption(sacramentosOptions)

  const remedioControlado = Form.useWatch('possui_remedio_controlado', form)

  return (
    <>
      <Row gutter={25}>
        <Col xs={24} md={12}>
          <Form.Item name="escolaridade" label="Escolaridade" rules={[{ required: true, message: 'Obrigatório' }]}>
            <StyledSelect placeholder="Selecione uma opção">
              {escolaridadeOptions.map((item) => (
                <Option key={item.value} value={item.label}>
                  {item.label}
                </Option>
              ))}
            </StyledSelect>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name="profissao" label="Profissão" rules={[{ required: true, message: 'Obrigatório' }]}>
            <StyledSelect placeholder="Pesquise e selecione uma opção" showSearch allowClear>
              {profissoes.map((item) => (
                <Option key={item.value} value={item.label}>
                  {item.label}
                </Option>
              ))}
            </StyledSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={25}>
        <Col xs={24} md={12}>
          <Form.Item name="cursos" label="Cursos">
            <StyledTextArea placeholder="Digite os cursos" showCount maxLength={100} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Rotina Diária"
            name="rotina_diaria"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
          >
            <StyledTextArea placeholder="Digite sua rotina" showCount maxLength={100} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={25}>
        <Col xs={24} md={12}>
          <Form.Item
            name="sacramentos"
            label="Sacramentos"
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <StyledSelect placeholder="Selecione" mode="multiple" allowClear options={optionsWithAllOption} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="aceitacao_familiar"
            label="Aceitação Familiar"
            rules={[{ required: true, message: 'Obrigatório' }]}
          >
            <StyledTextArea placeholder="Digite" showCount maxLength={100} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={25}>
        <Col xs={24} md={12}>
          <Form.Item name="estado_civil" label="Estado Civil" rules={[{ required: true, message: 'Obrigatório' }]}>
            <StyledSelect
              placeholder="Selecione uma opção"
              allowClear
              onChange={(value) => {
                setEstadoCivilValue(value)
              }}
            >
              {estadoCivilOptions.map((item) => (
                <Option key={item.value} value={item.label}>
                  {item.label}
                </Option>
              ))}
            </StyledSelect>
          </Form.Item>
        </Col>
        {estadoCivilValue === 'Divorciado(a)' && (
          <Col xs={24} md={12}>
            <Form.Item
              name="justificativa_divorcio"
              label="Motivo do divórcio"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <StyledTextArea placeholder="Digite" showCount maxLength={100} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row justify="center">
        <Form.Item label="Utiliza remédio controlado?" name="possui_remedio_controlado" initialValue={false}>
          <Radio.Group>
            <Radio value={true}>
              <span>Sim</span>
            </Radio>

            <Radio value={false}>
              <span>Não</span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      <Row justify="center" gutter={[20, 10]}>
        {remedioControlado && (
          <>
            <Col xs={24} md={12}>
              <Form.Item label="Remédio Controlado" name="remedio_controlado">
                <StyledRangePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Descrição do problema de saúde" name="descricao_problema_saude">
                <StyledTextArea placeholder="Digite a descrição" showCount maxLength={100} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </>
  )
}
