import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { VocacionalEmbedRoutes } from './vocacional/routes'
import ActionEmbed from '../../container/admin/pages/actions/embed/ActionEmbed'
import { CarteiraRoutes } from '../_benfeitor/Carteira'
import { ConfirmEmail } from '../../container/benfeitor/pages/ConfirmEmail'
import { CadastroLeadIntencao } from '../../container/benfeitor/pages/CadastroLeadIntencao'
import LandingPageEmbedForm from '../../container/benfeitor/pages/LandingPage/embed/LandingPageEmbedForm'

export function EmbedRoutes() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/vocacional`} component={VocacionalEmbedRoutes} />
      <Route path={`${path}/landingpage/:name/:id/`} component={LandingPageEmbedForm} />
      <Route path={`${path}/action/:name/:id/`} component={ActionEmbed} />
      <Route path={`${path}/carteira`} component={CarteiraRoutes} />
      <Route path={`${path}/confirmar-email`} component={ConfirmEmail} />
      <Route path={`${path}/cadastro-lead-intencao`} component={CadastroLeadIntencao} />
    </Switch>
  )
}
