import BaseCard from '../../../../components/card/BaseCard'
import { MapaDistribuicaoGeografica } from './MapaDistribuicaoGeografica'
import { useGetEnderecosFamilias } from '../../../../hooks/agape/queries/useGetEnderecosFamilias'
import { Col, Row, Skeleton } from 'antd'
import { ErrorResult } from '../../../../components/result/resultStatus/ErrorResult'

export function DistribuicaoGeografica() {
  const {
    data: enderecosFamilias,
    isLoading: isLoadingEnderecosFamilia,
    isError: isErrorEnderecosFamilia,
  } = useGetEnderecosFamilias()

  if (isLoadingEnderecosFamilia) {
    return (
      <BaseCard title="Distribuição geográfica" style={{ height: '80vh', width: '100%' }}>
        <Col xs={24}>
          <Row justify="center" style={{ overflow: 'hidden' }}>
            <Skeleton.Node active style={{ width: '90vw', height: '50vh', fontSize: '8rem' }} />
          </Row>
        </Col>
      </BaseCard>
    )
  }

  if (isErrorEnderecosFamilia) {
    return (
      <BaseCard title="Distribuição geográfica">
        <ErrorResult />
      </BaseCard>
    )
  }

  const arrayEnderecos = enderecosFamilias?.enderecos.map((item) => {
    const { bairro, cep, cidade, complemento, estado, latitude, longitude, numero, ponto_referencia, rua } = item

    return {
      endereco: [cep, estado, cidade, bairro, rua, numero, complemento || null, ponto_referencia || null]
        .filter((parte) => parte)
        .join(' - '),
      latitude,
      longitude,
    }
  })

  return (
    <BaseCard title="Distribuição geográfica">
      <MapaDistribuicaoGeografica arrayEnderecos={arrayEnderecos} />
    </BaseCard>
  )
}
