import { useQuery } from '@tanstack/react-query'

interface HourData {
  hour: string
  count: number
}

export function useMessagesCountByHour() {
  return useQuery<HourData[]>({
    queryKey: ['messages-count-by-hour'],
    queryFn: async () => {
      return [
        { hour: '00:00', count: 120 },
        { hour: '03:00', count: 80 },
        { hour: '06:00', count: 200 },
        { hour: '09:00', count: 450 },
        { hour: '12:00', count: 380 },
        { hour: '15:00', count: 420 },
        { hour: '18:00', count: 350 },
        { hour: '21:00', count: 250 }
      ]
    }
  })
} 