import { GradientHeader } from '../../../components/gradient-header'
import { Container, StyledPrayerImpactCard } from './styles'

export function PrayerCard() {
  return (
    <Container>
      <StyledPrayerImpactCard>
        <GradientHeader className="header" colorOne="#121924" colorTwo="#2956a3">
          O PODER DE UM EXÉRCITO INCANSÁVEL NA ORAÇÃO:
        </GradientHeader>
        <div className="text" style={{ marginTop: '0.5rem' }}>
          Imagine o impacto espiritual de ter milhares de pessoas rezando pelas suas intenções, em todos os momentos do
          dia? Como membro oficial do Exército de São Miguel,
          <span>{''} você nunca mais estará sozinho nas suas lutas. </span>
        </div>
      </StyledPrayerImpactCard>
    </Container>
  )
}
