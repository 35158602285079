import styled from 'styled-components'
import { BaseInput } from '../BaseInput/BaseInput'
import { BORDER_RADIUS } from '../../../../../styles/Themes/Admin/constants'

export const InputPassword = styled(BaseInput.Password)`
  background-color: var(--background-color) !important;
  border-color: var(--input-border-color);
  border-radius: ${BORDER_RADIUS};
  height: 3rem;

  .ant-input {
    color: var(--text-main-color) !important;
    background-color: var(--background-color) !important;
  }

  .ant-input:hover {
    border-color: var(--input-border-color) !important;
  }

  .ant-input-suffix {
    color: var(--text-main-color) !important;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper.ant-input-affix-wrapper-focused.ant-input-password {
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 1px var(--input-border-color) !important;
  }

  &:hover {
    border-color: var(--input-border-color) !important;
  }

  .ant-input-affix-wrapper .ant-input-password .ant-input {
    background-color: var(--background-color) !important;
  }
  .ant-input-password-icon.anticon {
    color: var(--disabled-color) !important;
    &:hover {
      color: var(--text-main-color) !important;
    }
  }
`
