import styled from 'styled-components';

const Wrapper = styled.div`
  h1,
  h2,
  h3 {
    color: ${({ iframeStyles }) => iframeStyles.labelColor};
  }

  label {
    color: ${({ iframeStyles }) => iframeStyles.labelColor};
  }

  .successIcon {
    font-size: 3.5rem;

    path:first-of-type {
      fill: ${({ iframeStyles }) => iframeStyles.sucessoColor || 'green'};
    }

    path:last-of-type {
      fill: ${({ iframeStyles }) => iframeStyles.sucessoColor || 'green'};
    }
  }
`;

export { Wrapper };
