import { ResponsiveRadar } from '@nivo/radar'
import { PieChartTitle, RadarContainer } from '../../../styles'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function Chart({ filters, data: usersByHours }) {
  const color = [
    filters?.forma_pagamento === '1'
      ? '#679dff'
      : filters?.forma_pagamento === '2'
      ? '#e58400'
      : filters?.forma_pagamento === '3'
      ? '#535353'
      : '#38891D',
  ]

  return (
    <div style={{ height: '30rem' }}>
      <PieChartTitle style={{ padding: '2rem 1rem' }}>Por Hora do Dia</PieChartTitle>
      <RadarContainer>
        <ResponsiveRadar
          margin={{ top: 25, right: 30, bottom: 25, left: 30 }}
          data={usersByHours || []}
          keys={['Doações']}
          indexBy="hora"
          borderColor={{ from: 'color' }}
          gridLabelOffset={16}
          dotSize={5.5}
          fillOpacity={0.5}
          dotColor="#ffffff"
          dotBorderWidth={2}
          dotBorderColor={color}
          colors={color}
          blendMode="multiply"
          motionConfig="wobbly"
          sliceTooltip={(props) => {
            return (
              <div
                style={{
                  color: 'white',
                  backgroundColor: color,
                  border: '1px solid #000000',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  opacity: '0.9',
                }}
              >
                <strong>Horário: {props?.index}</strong>
                <br />
                <strong> {props?.data?.[0].id}: </strong>
                <BlurDiv>
                  <strong>{props?.data?.[0].value}</strong>
                </BlurDiv>
              </div>
            )
          }}
        />
      </RadarContainer>
    </div>
  )
}
