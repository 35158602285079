import { ChartCard } from '@/container/admin/components/Cards/ChartCard'
import { RootState } from '@/redux/rootReducers'
import { useSelector } from 'react-redux'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useMessagesCountByHour } from '../../hooks/use-messages-count-by-hour'

export function MessagesCountByHour() {
  const { data, isLoading } = useMessagesCountByHour()
  const isDark = useSelector((state: RootState) => state.theme.theme === 'dark')

  return (
    <ChartCard title="Mensagens por Hora" loading={isLoading}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <XAxis 
            dataKey="hour" 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <YAxis 
            stroke={isDark ? '#E1E1E1' : '#000'}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: isDark ? '#1E1E1E' : '#fff',
              border: `1px solid ${isDark ? '#333333' : '#f0f0f0'}`,
              color: isDark ? '#E1E1E1' : '#000'
            }}
          />
          <Line type="monotone" dataKey="count" stroke="#FF6B00" />
        </LineChart>
      </ResponsiveContainer>
    </ChartCard>
  )
} 