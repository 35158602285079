import { Col, Form, Row } from 'antd'
import { useAbastecerEstoque } from '../../../../../hooks/agape/mutations/useAbastecerEstoque'
import { useGetItensEstoque } from '../../../../../hooks/agape/queries/useGetItensEstoque'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'
import { BaseInputNumber } from '../../../../../components/inputs/InputNumber/BaseInputNumber'
import BaseButton from '../../../../../components/button/BaseButton'

interface AtualizarEstoqueFormData {
  fk_estoque_agape_id: number
  quantidade: number
}

export function AtualizarItem() {
  const [form] = Form.useForm()

  const { data: itensEstoque } = useGetItensEstoque()

  const { mutateAsync: abastecerEstoque, isPending: isPendingAbastecerEstoque } = useAbastecerEstoque()

  async function handleAtualizarEstoqueItem(data: AtualizarEstoqueFormData) {
    await abastecerEstoque({
      fk_estoque_agape_id: data.fk_estoque_agape_id,
      quantidade: data.quantidade,
      item: itensEstoque?.estoques.find((item) => item.id === data.fk_estoque_agape_id)?.item!,
    })

    form.resetFields()
  }

  return (
    <Col xs={24}>
      <Form form={form} layout="vertical" onFinish={handleAtualizarEstoqueItem}>
        <Row justify="center" gutter={[20, 20]}>
          <Col xs={10}>
            <BaseForm.Item name="fk_estoque_agape_id" label="Item" rules={[{ required: true, message: 'Obrigatório' }]}>
              <BaseSelect placeholder="Pesquise e selecione uma opção" showSearch allowClear>
                {itensEstoque?.estoques.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.item}
                  </Option>
                ))}
              </BaseSelect>
            </BaseForm.Item>
          </Col>

          <Col xs={10}>
            <BaseForm.Item name="quantidade" label="Quantidade" rules={[{ required: true, message: 'Obrigatório' }]}>
              <BaseInputNumber
                block
                controls={false}
                prefix=""
                placeholder="Mínimo: 1"
                min={1}
                formatter={(value) =>
                  value
                    ? `${value
                        .toString()
                        .replace(/\D/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                        .replace(/\.(\d{2})$/, ',$1')}`
                    : ''
                }
                parser={(value) => (value ? Number(value.replace(/\./g, '').replace(',', '.')) : 0)}
              />
            </BaseForm.Item>
          </Col>

          {/* <Col xs={4}>
            <BaseForm.Item
              name="data_aquisicao"
              label="Data de aquisição"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <BaseDatePicker
                format="DD/MM/YYYY"
                disabledDate={(current: Moment) => current && current > moment().endOf('day')}
              />
            </BaseForm.Item>
          </Col> */}
        </Row>

        <Row justify="center">
          <BaseButton htmlType="submit" loading={isPendingAbastecerEstoque}>
            Enviar
          </BaseButton>
        </Row>
      </Form>
    </Col>
  )
}
