import { LandingPageProps } from "../types/LandingPageProps"

interface LandingPageStepFormFinishedProps {
  landingPageData: LandingPageProps
}

export default function LandingPageStepFormFinished({ landingPageData }: LandingPageStepFormFinishedProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '20vh',
      }}
    >
      <div>
        <h1>{landingPageData.texto_pos_registro}</h1>
      </div>
    </div>
  )
}
