import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CheckCircle, XCircle } from '@phosphor-icons/react'
import { Row } from 'antd'
import BaseButton from '../../../../components/button/BaseButton'
import {
  FetchAssiduousDonorsProps,
  useFetchAssiduousDonors,
} from '../../../../hooks/donations/queries/useFetchAssiduousDonors'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { BaseSpin } from '../../../../components/spin/BaseSpin.Styles'

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

const initialColumns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: 'Benfeitor',
    dataIndex: 'benfeitor',
    key: 'benfeitor',
  },
  {
    title: 'Doações',
    dataIndex: 'doacoes',
    key: 'doacoes',
    align: 'center' as const,
  },
]

interface RankingAssiduosProps {
  filters: FetchAssiduousDonorsProps['filters']
}

export function RankingAssiduos({ filters }: RankingAssiduosProps) {
  const { filters: newFilters } = { filters }

  const [donorsRankingFilters, setDonorsRankingFilters] = useState({ ...newFilters })

  useEffect(() => {
    setDonorsRankingFilters({ ...newFilters })
  }, [filters])

  const {
    data: assiduousDonors,
    isLoading: isLoadingAssiduousDonors,
    isError: isErrorAssiduousDonors,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFetchAssiduousDonors({ filters: donorsRankingFilters })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasNextPage && !isFetchingNextPage) {
          fetchNextPage()
        }
      }
    }

    const container = tableContainerRef.current
    container?.addEventListener('scroll', handleScroll)
    return () => container?.removeEventListener('scroll', handleScroll)
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const history = useHistory()

  const [columns, setColumns] = useState(initialColumns)

  const donorsAssiduous = assiduousDonors?.pages?.flatMap((page) => page.doadores_assiduos) || []

  const dataSource = donorsAssiduous?.map((user, index) => {
    const { fk_usuario_id, benfeitor, meses, doacoes } = user
    return {
      rank: `${index + 1}º`,
      key: index,
      benfeitor: fk_usuario_id ? (
        <Row justify="center">
          <BaseButton
            type="link"
            onClick={() => {
              history.push(`/admin/users/details/${fk_usuario_id}`)
            }}
          >
            {benfeitor}
          </BaseButton>
        </Row>
      ) : (
        <span>{benfeitor}</span>
      ),
      doacoes,
      ...meses,
    }
  })

  const getColumns = (months: any) => {
    if (months) {
      return Object.keys(months).map((monthKey) => ({
        title: capitalize(moment(monthKey, 'YYYY-MM').format('MMM')),
        dataIndex: monthKey,
        key: monthKey,
        align: 'center' as const,
        render: (value: boolean) => (
          <span>
            {value ? (
              <CheckCircle size={20} color="var(--success-color)" />
            ) : (
              <XCircle size={20} color="var(--error-color)" />
            )}
          </span>
        ),
      }))
    }
    return []
  }

  useEffect(() => {
    if (donorsAssiduous && donorsAssiduous.length > 0) {
      const monthColumns = getColumns(donorsAssiduous[0].meses)
      setColumns((prev) => [...prev.slice(0, initialColumns.length), ...monthColumns])
    }
  }, [donorsAssiduous])

  return (
    <div ref={tableContainerRef} style={{ height: 500, overflowY: 'auto' }}>
      <BaseTable
        columns={columns}
        dataSource={dataSource}
        isLoading={isLoadingAssiduousDonors}
        isError={isErrorAssiduousDonors}
        pagination={false}
      />

      {isFetchingNextPage && (
        <Row justify="center" style={{ marginBlock: '1rem' }}>
          <BaseSpin />
        </Row>
      )}
    </div>
  )
}
