import { useQuery } from '@tanstack/react-query'

interface CategoryData {
  name: string
  value: number
}

export function useMessagesPerCategory() {
  return useQuery<CategoryData[]>({
    queryKey: ['messages-per-category'],
    queryFn: async () => {
      return [
        { name: 'Campanhas', value: 3500 },
        { name: 'Notificações', value: 2800 },
        { name: 'Atualizações', value: 1500 },
        { name: 'Outros', value: 1200 }
      ]
    }
  })
} 