import { env } from '@/lib/env';
import * as Sentry from "@sentry/react";

Sentry.init({
  release: "release-1",
  debug: true,
  dsn: env.VITE_SENTRY_DSN,
  enabled: env.VITE_SENTRY_ENABLED === 'true',
  environment: env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Configure Session Replay
      stickySession: true,
      minReplayDuration: 1000,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0, // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
  // Learn more at https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate

  tracePropagationTargets: [ // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    /^\//, // Match all paths in your app
  ],

  replaysSessionSampleRate: 0.1, // Capture Replay for 10% of all sessions,
  replaysOnErrorSampleRate: 1.0, // plus for 100% of sessions with an error
  // Learn more at https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
});