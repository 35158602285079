import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useAtualizarPermissaoVoluntarios() {
  const queryClient = useQueryClient()

  const atualizarPermissao = useMutation({
    mutationFn: async () => {
      await DataService.put('/agape/atualizar-permissoes-voluntarios')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['PERMISSAO_VOLUNTARIO'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao atualizar a permissão dos voluntários',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return atualizarPermissao
}
