import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetDonationsPerCampaign(colors, filters) {
  return useQuery({
    queryKey: ['DONATIONS_PER_CAMPAIGN_DASHBOARD_FINANCE', filters],
    queryFn: async () => {
      const response = await DataService.get(`admin/dash-board/donations/campaigns-donations?${filters?.queryString}`)

      const campaignData = response?.data?.campaigns_data?.map((object) => ({
        id: object.titulo,
        label: object.titulo,
        quantidade_doacoes: object.quant_doacoes,
        value: parseFloat(object.valor_arrecadado),
        percentage: object.percentual_meta_alcancada,
      }))

      return campaignData?.map((campaign, index) => ({
        ...campaign,
        color: colors[index % colors.length],
      }))
    },
  })
}
