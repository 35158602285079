import React from 'react'
import * as S from './BaseTable.Styles'
import { BasePaginationLocale } from '../../pagination/BasePaginationLocale'
import BaseSpin from '../../spin/BaseSpin'
import { EmptyResult } from '../../result/resultStatus/EmptyResult'
import { ErrorResult } from '../../result/resultStatus/ErrorResult'
import { TableProps } from 'antd'

export type BaseTableProps<T> = Omit<TableProps<T>, 'columns'> & {
  columns?: any
  scrollX?: number
  scrollY?: number
  isPending?: boolean
  isLoading?: boolean
  isError?: boolean
}

export const BaseTable: React.FC<BaseTableProps<any>> = ({
  columns,
  scrollX = 800,
  scrollY,
  isPending,
  isLoading,
  isError,
  locale,
  pagination,
  ...restProps
}) => {
  const adjustedLocale = {
    emptyText:
      isPending || isLoading ? <BaseSpin style={{ padding: '50px' }} /> : isError ? <ErrorResult /> : <EmptyResult />,
    ...locale,
  }

  return (
    <S.Table
      {...restProps}
      bordered
      columns={columns}
      scroll={{ x: scrollX, y: scrollY }}
      rowClassName="rowteste"
      pagination={
        pagination
          ? {
              ...pagination,
              locale: BasePaginationLocale,
            }
          : false
      }
      locale={adjustedLocale}
    />
  )
}
