import React, { lazy } from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
import ActionsDetails from '../../container/admin/pages/actions/Details'
import EditActions from '../../container/admin/pages/actions/Edit'

const ActionsView = lazy(() => import('../../container/admin/pages/actions/View'))
const ActionsAdd = lazy(() => import('../../container/admin/pages/actions/Add'))

const Actions = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/view-all`} component={ActionsView} />
      <Route path={`${path}/actions-add`} component={ActionsAdd} />
      <Route path={`${path}/details/:id/:name`} component={ActionsDetails} />
      <Route path={`${path}/edit/:id`} component={EditActions} />
    </Switch>
  )
}

export default Actions
