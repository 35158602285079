import { useState } from 'react'
import { GetAllFamiliasFilters, GetFamiliasResponse } from '../../../../../../hooks/agape/queries/useGetFamilias'
import { formatToBrazilianCurrency } from '../../../../../../../../utility/utility'
import { Col, Row, Space, Modal, TablePaginationConfig } from 'antd'
import BaseButton from '../../../../../../components/button/BaseButton'
import { DetalhesDaFamiliaModal } from './modal/DetalhesDaFamiliaModal'
import { BaseTable } from '../../../../../../components/table/BaseTable/BaseTable'
import { BaseSpin } from '../../../../../../components/spin/BaseSpin.Styles'
import { ListagemMembrosFamilia } from '../../membros/listagem/ListagemMembrosFamilia'
import { useExcluirFamilia } from '../../../../../../hooks/agape/mutations/useExcluirFamilia'
import { ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons'
import { useGetPermissions } from '../../../../../../hooks/users/Queries/useGetSelfPermissions'
import { useGetCsvFamilias } from '../../../../../../hooks/agape/mutations/useDownloadCsvFamilias'
import { BlurDiv } from '@/context/Blur/BlurDiv'

const { confirm } = Modal

export interface FamiliasCadastradasTableProps {
  lista: GetFamiliasResponse['familias'] | undefined
  handleInputChange: (filter: GetAllFamiliasFilters, pagination?: boolean) => void
  pagination: {
    page?: number
    total?: number
  }
  isLoading: boolean
  isError: boolean
  filters: GetAllFamiliasFilters['filters']
}

export interface InformacoesFamilia {
  foto: string
  observacao: string
}

export function FamiliasCadastradasTabela({
  lista,
  pagination,
  handleInputChange,
  isLoading,
  isError,
  filters,
}: FamiliasCadastradasTableProps) {
  const { data: permissions } = useGetPermissions()

  const [modalDetalhesVisivel, setModalDetalhesVisivel] = useState(false)
  const [idFamiliaSelecionada, setIdFamiliaSelecionada] = useState<number | null>()
  const [informacoes, setInformacoes] = useState<InformacoesFamilia | null>()

  const handleViewDetails = (idFamilia: number, { foto, observacao }: InformacoesFamilia) => {
    setIdFamiliaSelecionada(idFamilia)
    setInformacoes({ foto, observacao })
    setModalDetalhesVisivel(true)
  }

  const handleCloseModal = () => {
    setModalDetalhesVisivel(false)
    setIdFamiliaSelecionada(null)
    setInformacoes(null)
  }

  const { mutateAsync: downloadCsvFamilia, isPending: isPendingDownloadCsvFamilia } = useGetCsvFamilias()

  const { mutateAsync: excluirFamilia, isPending: isPendingExcluirFamilia } = useExcluirFamilia()

  const handleExcluirFamilia = ({ idFamilia }: { idFamilia: number }) => {
    excluirFamilia({ idFamilia })
  }

  const confirmarExclusaoFamilia = (idFamilia: number, nomeFamilia: string) => {
    confirm({
      title: `Tem certeza que deseja apagar a Família ${nomeFamilia}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Apagar',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        handleExcluirFamilia({ idFamilia })
      },
    })
  }

  const dataSource = lista?.map((item) => {
    const {
      id,
      cadastrado_em,
      familia,
      membros,
      recebimentos,
      renda,
      ultimo_recebimento,
      cadastrada_por,
      foto,
      observacao,
    } = item

    return {
      key: id,
      id,
      cadastrado_em,
      familia,
      recebimentos,
      membros,
      cadastrada_por,
      ultimo_recebimento: ultimo_recebimento ?? ' - ',
      renda: renda && formatToBrazilianCurrency(renda),
      acoes: (
        <Row justify="center">
          <Space size="middle">
            <BaseButton severity="info" onClick={() => handleViewDetails(id, { foto, observacao })}>
              Ver detalhes
            </BaseButton>

            {permissions?.permissoes.familia_agape.deletar === 1 && (
              <BaseButton onClick={() => confirmarExclusaoFamilia(id, familia)} severity="error">
                Excluir
              </BaseButton>
            )}
          </Space>
        </Row>
      ),
    }
  })

  const columns = [
    {
      title: 'Família',
      dataIndex: 'familia',
      key: 'familia',
      align: 'center' as const,
      //   filterDropdown: () => (
      //     <BaseCard>
      //       <VocacionalPreCadastroFilters.Nome value={filters?.nome} handleInputChange={handleInputChange} />
      //     </BaseCard>
      //   ),
      //   filteredValue: filters?.nome && [filters.nome],
    },
    {
      title: 'Membros',
      dataIndex: 'membros',
      key: 'membros',
      align: 'center' as const,
      //   filterDropdown: () => (
      //     <BaseCard>
      //       <VocacionalPreCadastroFilters.Email value={filters?.email} handleInputChange={handleInputChange} />
      //     </BaseCard>
      //   ),
      //   filteredValue: filters?.email && [filters.email],
    },
    {
      title: 'Renda',
      dataIndex: 'renda',
      key: 'renda',
      align: 'center' as const,
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
    {
      title: 'Cadastro em',
      dataIndex: 'cadastrado_em',
      key: 'cadastrado_em',
      align: 'center' as const,
    },
    {
      title: 'Cadastro por',
      dataIndex: 'cadastrada_por',
      key: 'cadastrada_por',
      align: 'center' as const,
    },
    {
      title: 'Último recebimento',
      dataIndex: 'ultimo_recebimento',
      key: 'ultimo_recebimento',
      align: 'center' as const,
    },
    {
      title: 'Recebimentos',
      dataIndex: 'recebimentos',
      key: 'recebimentos',
      align: 'center' as const,
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      align: 'center' as const,
    },
  ]

  return (
    <>
      <DetalhesDaFamiliaModal
        visible={modalDetalhesVisivel}
        onClose={handleCloseModal}
        idFamilia={idFamiliaSelecionada!}
        informacoes={informacoes!}
      />

      <BaseTable
        columns={columns}
        dataSource={dataSource}
        isLoading={isLoading}
        isError={isError}
        loading={{
          spinning: isPendingExcluirFamilia,
          indicator: <BaseSpin />,
        }}
        pagination={{
          current: pagination?.page,
          total: pagination?.total,
          showSizeChanger: true,
        }}
        onChange={(pagination: TablePaginationConfig) => {
          if (pagination.current && pagination.pageSize) {
            handleInputChange({ page: pagination.current }, true)
            handleInputChange({ per_page: pagination.pageSize }, true)
          }
        }}
        {...(Array.isArray(dataSource) &&
          dataSource?.length > 0 && {
            expandable: {
              expandedRowRender: (record: GetFamiliasResponse['familias'][number]) => (
                <Col xs={24}>
                  <ListagemMembrosFamilia idFamilia={record.id} />
                </Col>
              ),
            },
          })}
        footer={() => (
          <BaseButton
            disabled={Array.isArray(dataSource) ? dataSource.length === 0 : true}
            loading={isPendingDownloadCsvFamilia}
            severity="success"
            icon={<FileExcelOutlined />}
            onClick={() => downloadCsvFamilia()}
          >
            Exportar
          </BaseButton>
        )}
      />
    </>
  )
}
