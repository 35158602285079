import { FormInstance, notification } from 'antd'

import { useEffect } from 'react'
import { FormularioAdicionarMembrosResponsavel } from './FormularioAdicionarMembrosResponsavel'
import {
  FormularioCadastroFamiliaData,
  Membro,
  Responsavel,
} from '../../../CadastrarFamilia/FormularioCadastrarFamilia'
import { useAdicionarMembros } from '../../../../../../hooks/agape/mutations/useAdicionarMembros'
import moment from 'moment'

type AdicionarMembrosForm = {
  responsaveis: Responsavel[]
  membros: Membro[]
}

type AdicionarMembroProps = {
  form: FormInstance
  handleIsPending: (isPending: boolean) => void
  idFamilia: number
  onClose: () => void
}

const formatarResponsaveis = (arrayResponsaveis: FormularioCadastroFamiliaData['responsaveis']) => {
  return arrayResponsaveis.map((item) => {
    return {
      responsavel: true,
      ...item,
    }
  })
}

export function AdicionarMembros({ form, handleIsPending, idFamilia, onClose }: AdicionarMembroProps) {
  const { mutateAsync: adicionarMembros, isPending: isPendingAdicionarMembros } = useAdicionarMembros()

  useEffect(() => {
    handleIsPending(isPendingAdicionarMembros)
  }, [isPendingAdicionarMembros])

  const adicionarMembrosFormSubmit = async (data: AdicionarMembrosForm) => {
    const formularioVazio =
      (!data.membros && !data.responsaveis) || (data.membros?.length === 0 && data.responsaveis?.length === 0)

    if (formularioVazio) {
      notification.warning({ message: 'Adicione ao menos um responsável ou membro.' })
      return
    }

    const responsaveisFormatados = data?.responsaveis ? formatarResponsaveis(data.responsaveis) : []

    const arrayMembrosEResponsaveis = [...responsaveisFormatados, ...(data.membros ?? [])]

    const arrayMembrosEResponsaveisFormatado = arrayMembrosEResponsaveis.map((item) => {
      return {
        ...item,
        data_nascimento: moment(item.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
    })

    const adicionarMembrosEnvio = {
      idFamilia,
      membros: arrayMembrosEResponsaveisFormatado,
    }

    try {
      await adicionarMembros(adicionarMembrosEnvio)

      setTimeout(() => form.resetFields(), 0)

      onClose()
    } catch (error) {}
  }

  return <FormularioAdicionarMembrosResponsavel form={form} handleFormSubmit={adicionarMembrosFormSubmit} />
}
