import { useState } from 'react'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'
import {
  GetAcoesSolidariasProps,
  useGetAcoesSolidarias,
} from '../../../../../hooks/agape/queries/useGetAcoesSolidarias'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import { AcoesSolidariasFilters } from './filters'

export function AcoesSolidarias() {
  const [filters, setFilters] = useState<GetAcoesSolidariasProps['filters']>({})

  const {
    data: acoesSolidarias,
    isLoading: isLoadingAcoesSolidarias,
    isError: isErrorAcoesSolidarias,
  } = useGetAcoesSolidarias({ filters })

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const dataSource = acoesSolidarias?.acoes_agape.map((item) => {
    const { id, nome, data_cadastro, ciclos_finalizados } = item

    return {
      key: id,
      id,
      nome,
      data_cadastro,
      ciclos_finalizados,
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      filterDropdown: () => (
        <AcoesSolidariasFilters.Nome value={filters?.fk_acao_agape_id} handleInputChange={handleInputChange} />
      ),
    },
    {
      title: 'Data de cadastro',
      dataIndex: 'data_cadastro',
      key: 'data_cadastro',
      filterDropdown: () => (
        <AcoesSolidariasFilters.Cadastro
          value={{
            data_cadastro_inicial: filters?.data_cadastro_inicial ?? '',
            data_cadastro_final: filters?.data_cadastro_final ?? '',
          }}
          handleInputChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Ciclos finalizados',
      dataIndex: 'ciclos_finalizados',
      key: 'ciclos_finalizados',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingAcoesSolidarias}
      isError={isErrorAcoesSolidarias}
      pagination={{
        current: acoesSolidarias?.page,
        total: acoesSolidarias?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: { current: number; pageSize: number }) => {
        handleInputChange({ page: pagination.current }, true)
        handleInputChange({ per_page: pagination.pageSize }, true)
      }}
    />
  )
}
