import { DollarOutlined } from '@ant-design/icons';
import { Card, Col, Collapse, Divider, Image, notification, Pagination, Row, Skeleton } from 'antd';
import { useState } from 'react';
import { GroupCard } from '../../../components/GroupCard';
import {
  formatToBrazilianCurrency,
  generateQueryString,
  handleFilterChangeAndPagination,
} from '../../../../../utility/utility';
import moment from 'moment';
import { MainCard } from '../../../components/Card';
import { DataService } from '../../../../../lib/dataService';
import {
  CardSubtitleContainer,
  CardTitleContainer,
  CollapseContentContainer,
  DonationCardContainer,
  DonationCardsContainer,
  RecurrenceButton,
} from './styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchDonationsMe } from '../../../hooks/api/doacoes/queries';

type DonationHistory = {
  data_doacao: string;
  forma_pagamento: string;
  status_processamento: string;
  valor_doacao: string;
};

type DonationHistoryPageResponse = {
  data: {
    historico_doacoes: DonationHistory[];
    page: number;
    total: number;
  };
};

export function DonationsView() {
  const initialFilters = {
    queryString: generateQueryString({ per_page: 3 }),
  };

  const queryClient = useQueryClient();

  const [filters, setFilters] = useState(initialFilters);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [donationHistories, setDonationHistories] = useState<Record<number, DonationHistoryPageResponse>>({});

  const handleInputChange = handleFilterChangeAndPagination({ setFilters });

  const { data: userDonationsInfo, isLoading: isLoadingUserTotalDonation } = useQuery({
    queryKey: ['/users/card-doacoes'],
    queryFn: async () => {
      const response = await DataService.get('/users/card-doacoes');
      return response.data;
    },
  });

  const { data: response, isLoading: isLoadingDonations, isError: isErrorDonations } = useFetchDonationsMe(filters);

  const fetchDonationHistory = async (donationId: number) => {
    const donationHistoryResponse = await DataService.get(`users/historico-doacoes/${donationId}`);
    setDonationHistories((prev) => ({
      ...prev,
      [donationId]: donationHistoryResponse,
    }));
  };

  function truncateText(text: string, wordLimit: number) {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  }

  const handleCollapseChange = (key: string) => {
    const newActiveKeys = activeKeys.includes(key) ? activeKeys.filter((k) => k !== key) : [...activeKeys, key];

    setActiveKeys(newActiveKeys);

    const donationId = parseInt(key);
    if (newActiveKeys.includes(key) && !donationHistories[donationId]) {
      fetchDonationHistory(donationId);
    }
  };

  const { mutateAsync: cancelRecurrence, isPending: isCancelingRecurrence } = useMutation({
    mutationKey: ['checkout/payment/cancel-recurrence'],
    mutationFn: (id) => {
      return DataService.post(`/checkout/payment/cancel-recurrence/${id}`);
    },
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: [`users/historico-doacoes/${id}`] });
      notification.success({ message: 'Recorrência cancelada com sucesso!' });
    },
    onError: () => {
      notification.error({ message: 'Ocorreu um erro' });
    },
  });

  return (
    <>
      <Row style={{ marginBottom: '1rem' }} gutter={[10, 10]}>
        <Col xs={24} sm={24} md={12}>
          <GroupCard
            loading={isLoadingUserTotalDonation}
            title="Total pago em doações"
            amount={formatToBrazilianCurrency(userDonationsInfo?.total_pago_doacoes?.valor_doado || '')}
            icon={<DollarOutlined />}
            content={`${userDonationsInfo?.total_pago_doacoes?.quant_doacoes || ''} doações`}
          />
        </Col>

        <Col xs={24} sm={24} md={12}>
          <GroupCard
            loading={isLoadingUserTotalDonation}
            title="Última doação paga"
            amount={
              userDonationsInfo?.ultima_doacao_paga?.ultima_doacao
                ? moment(userDonationsInfo?.ultima_doacao_paga?.ultima_doacao, 'DD/MM/YYYY HH:mm:ss').format(
                  'DD/MM/YYYY',
                )
                : ' - '
            }
            icon={<DollarOutlined />}
          />
        </Col>
      </Row>

      <MainCard
        title="Minhas doações"
        isPending={isLoadingDonations}
        isError={isErrorDonations}
        empty={response?.doacoes?.length === 0}
      >
        <DonationCardsContainer>
          {response?.doacoes?.map((item) => (
            <Card key={item.id} style={{ /*borderRadius: '8px'*/ overflow: 'hidden' }}>
              <DonationCardContainer>
                <Image src={item?.imagem_campanha} />
                <div className="content-container">
                  <div>
                    <CardTitleContainer>{item.titulo}</CardTitleContainer>
                    <CardSubtitleContainer>{truncateText(item.descricao, 20)}</CardSubtitleContainer>
                  </div>
                  <div className="content-bottom">
                    <div style={{ color: '#359e00' }}>{item.recorrencia}</div>
                    <div
                      style={{ cursor: 'pointer', color: '#c9a446' }}
                      onClick={() => handleCollapseChange(item.id.toString())}
                    >
                      Mais informações
                    </div>
                  </div>
                </div>
              </DonationCardContainer>
              <Collapse activeKey={activeKeys} onChange={() => handleCollapseChange(item.id.toString())}>
                <Collapse.Panel key={item.id.toString()} header="">
                  <CollapseContentContainer>
                    <p className="descricao">{item.descricao}</p>
                    <p className="title">Histórico de doações</p>
                    <Divider />
                    {donationHistories[item.id] ? (
                      <>
                        {donationHistories[item.id]?.data?.historico_doacoes.map((doacao, index) => {
                          return (
                            <div key={index} style={{ marginBottom: '1rem', color: '#6d6d6d' }}>
                              <div
                                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.25rem' }}
                              >
                                <span>{doacao.data_doacao}</span>
                                <span style={{ color: '#c9a446', fontWeight: 'bold' }}>
                                  {formatToBrazilianCurrency(doacao.valor_doacao)}
                                </span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{doacao.forma_pagamento}</span>
                                <span>{doacao.status_processamento}</span>
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                        <div style={{ textAlign: 'center' }} onClick={() => cancelRecurrence(item.id)}>
                          {item.recorrencia === 'Recorrente' && item.recorrencia_ativa === true && (
                            <RecurrenceButton disabled={isCancelingRecurrence}>Cancelar Recorrência</RecurrenceButton>
                          )}
                          {item.recorrencia === 'Recorrente' && item.recorrencia_ativa === false && (
                            <div
                              style={{
                                color: '#8a0010',
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                              }}
                            >
                              Recorrência Cancelada
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <Skeleton active paragraph={{ rows: 1 }} />
                    )}
                  </CollapseContentContainer>
                </Collapse.Panel>
              </Collapse>
            </Card>
          ))}
        </DonationCardsContainer>

        <Row justify="end" style={{ position: 'relative', bottom: 0, marginTop: '2rem' }}>
          <Pagination
            onChange={(page, pageSize) => {
              handleInputChange({ page: page }, 'pagination');
              handleInputChange({ per_page: pageSize }, 'pagination');
            }}
            total={response?.total}
            current={response?.page}
            pageSize={filters?.per_page || 3}
          />
        </Row>
      </MainCard>
    </>
  );
}
