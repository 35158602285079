import React, { lazy } from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
import { Lives } from '../../container/admin/pages/lives/Lives'

export const LivesRoute = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}`} component={Lives} />
    </Switch>
  )
}
