import { Col, Row } from 'antd'
import BaseCard from '../../../components/card/BaseCard'
import { ItensEmEstoque } from './Tabelas/ItensEmEstoque/ItensEmEstoque'
import { HistoricoMovimentacoes } from './Tabelas/HistoricoMovimentacoes'
import { CiclosDeDoacao } from './Tabelas/CiclosDeDoacao'
import { AcoesSolidarias } from './Tabelas/AcoesSolidarias'

export function SecaoTabelas() {
  return (
    <Row gutter={[20, 20]}>
      <Col xs={24}>
        <BaseCard title="Ações solidárias">
          <AcoesSolidarias />
        </BaseCard>
      </Col>
      <Col xs={24}>
        <BaseCard title="Ciclos de doação">
          <CiclosDeDoacao />
        </BaseCard>
      </Col>
      <Col xs={24}>
        <BaseCard title="Itens em estoque">
          <ItensEmEstoque />
        </BaseCard>
      </Col>
      <Col xs={24}>
        <BaseCard title="Histórico de movimentações">
          <HistoricoMovimentacoes />
        </BaseCard>
      </Col>
    </Row>
  )
}
