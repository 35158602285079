import { FormInstance } from 'antd'
import { UserMeAddress } from '../../admin/hooks/users/Queries/useFetchUserMeAddress'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { RegisterCompleteUser } from '../../admin/hooks/users/Mutations/useRegisterCompleteUser'
import { UserMeResponse } from '../../admin/hooks/users/Queries/useFetchUserMe'

interface UseSignUpFormLogicProps {
  initialFields?: any
  campanhaId: number
  markAsFinished: Function
  form: FormInstance
  sendForm: (data: RegisterCompleteUser) => Promise<void>
  isForeigner: boolean
  userMe: UserMeResponse | undefined
}

interface FormFields extends Partial<UserMeAddress>, Partial<RegisterCompleteUser['usuario']> {}

export function useEsmSignUpFormLogic({
  initialFields,
  campanhaId,
  markAsFinished,
  form,
  sendForm,
  isForeigner,
  userMe,
}: UseSignUpFormLogicProps) {
  const [hasStepTwoFormBeenFilled, setHasStepTwoFormBeenFilled] = useState(false)

  async function handleFinish() {
    try {
      await form.validateFields()
      const formFields = form.getFieldsValue([
        'nome',
        'email',
        'numero_documento',
        'data_nascimento',
        'telefone',
        'sexo',
        'password',
        'cep',
        'estado',
        'cidade',
        'bairro',
        'rua',
        'numero',
        'complemento',
        'detalhe_estrangeiro',
        'pais',
      ])

      const formattedData = formatSubmissionData(formFields, form, campanhaId, initialFields, isForeigner)

      await sendForm(formattedData).then(() => {
        markAsFinished()
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!initialFields || hasStepTwoFormBeenFilled) return

    try {
      form.setFieldsValue(initialFields)

      if (userMe?.numero_documento) {
        setHasStepTwoFormBeenFilled(true)
      }
    } catch (error) {
      console.error('Error initializing form:', error)
    }
  }, [initialFields, form, userMe?.numero_documento, hasStepTwoFormBeenFilled])

  return { handleFinish, hasStepTwoFormBeenFilled }
}

function formatSubmissionData(
  formFields: FormFields,
  form: FormInstance,
  campanhaId: number,
  initialFields?: any,
  isForeigner?: boolean,
): RegisterCompleteUser {
  const pais = form.getFieldValue('pais') || 'brasil'

  const endereco = isForeigner
    ? {
        detalhe_estrangeiro: formFields?.detalhe_estrangeiro,
      }
    : {
        bairro: formFields?.bairro,
        cep: formFields?.cep,
        cidade: formFields?.cidade,
        complemento: formFields?.complemento,
        estado: formFields?.estado,
        numero: formFields?.numero,
        rua: formFields?.rua,
      }

  return {
    endereco,
    usuario: {
      numero_documento: formFields?.numero_documento!,
      data_nascimento: formFields?.data_nascimento
        ? moment(formFields?.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      email: formFields?.email!,
      nome: formFields?.nome!,
      password: formFields?.password!,
      sexo: formFields?.sexo!,
      telefone: formFields?.telefone!,
      campanha_origem: campanhaId,
      tipo_documento: initialFields?.tipo_documento,
    },
    pagina_redirecionamento: 'membro-exercito',
    pais,
  }
}
