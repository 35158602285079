import { useMutation } from '@tanstack/react-query'
import { notification } from 'antd'
import { DataService } from '../../../../../lib/dataService'

export interface RegisterGroupUserBodySchema {
  data: {
    quant_membros_grupo: number
    tempo_de_administrador: number
    fk_usuario_id: number
    nome_grupo: string
    link_grupo: string
  }
}

export function useRegisterGroupUser() {
  return useMutation({
    mutationFn: ({ data }: RegisterGroupUserBodySchema) => {
      return DataService.post('/groups/registrar-general', data)
    },
    onSuccess: () => {
      notification.success({ message: 'Cadastro realizado com sucesso!' })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao realizar o cadastro',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })
}
