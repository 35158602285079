import { Checkbox, Col, Form, Row } from 'antd'
import ReactInputMask from 'react-input-mask'
import { countries } from '../../../../../../../utility/allCountries'
import { validateCPF, validateDateOfBirth, validatePassword } from '../../../../../../../utility/utility'
import { StyledSelect } from '../../../../../components/Select/styles'
import { StyledInput } from '../../../../../components/Inputs'
import { useFetchUserMe } from '../../../../../../admin/hooks'
import { Dispatch, SetStateAction } from 'react'
import { ImageFile } from '../../GeneralForm'
import { PhotoUpload } from '../../../../../components/UploadPhoto'
import { LandingPageProps } from '../../../types/LandingPageProps'

interface StepOneLandingPage {
  landingPageData: LandingPageProps
  imageUrl: ImageFile[]
  selectedCountry: string
  setImageUrl: React.Dispatch<React.SetStateAction<ImageFile[]>>
  setSelectedCountry: Dispatch<SetStateAction<string>>
}

export function StepOneLandingPage({
  landingPageData,
  imageUrl,
  setImageUrl,
  selectedCountry,
  setSelectedCountry,
}: StepOneLandingPage) {
  const { data: userMe } = useFetchUserMe()
  const loggedIn = !!userMe

  return (
    <div>
      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item label="País" required>
            <StyledSelect
              disabled={loggedIn}
              options={countries}
              value={selectedCountry}
              onChange={(value) => {
                setSelectedCountry(value)
              }}
            />
          </Form.Item>
          <Form.Item
            name="nome"
            label="Nome Completo"
            rules={[
              { min: 3, message: 'Mínimo 3 caracteres' },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="Nome Completo" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Deve ser um e-mail válido',
                whitespace: true,
              },
              { required: true, message: 'Obrigatório' },
            ]}
          >
            <StyledInput placeholder="E-mail" disabled={loggedIn} />
          </Form.Item>
        </Col>
      </Row>

      {!loggedIn && (
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="new_email"
              label="Confirmar E-mail"
              dependencies={['email']}
              rules={[
                { type: 'email', message: '' },
                { required: true, message: 'Obrigatório' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Os e-mails devem ser idênticos.'))
                  },
                }),
              ]}
            >
              <StyledInput placeholder="Confirmar" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={30}>
        {selectedCountry === 'brasil' ? (
          <Col xs={24} md={12}>
            <Form.Item name="cpf" label="CPF" rules={[{ required: true, message: '' }, { validator: validateCPF }]}>
              <ReactInputMask
                mask="999.999.999-99"
                name="cpf"
                maskChar={null}
                placeholder="999.999.999-99"
                disabled={loggedIn}
              >
                {(inputProps) => <StyledInput {...inputProps} disabled={loggedIn} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
        ) : (
          <Col xs={24} md={12}>
            <Form.Item
              name="cpf"
              label="Documento de Identidade"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório, digite o número do documento',
                },
              ]}
            >
              <StyledInput
                name="cpf"
                type="number"
                placeholder="Número Documento de Identificação"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #d9d9d9',
                  height: 48,
                }}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} md={12}>
          <Form.Item
            name="data_nascimento"
            label="Data de nascimento"
            rules={[
              { required: true, message: 'Por favor, preencha sua data de nascimento!' },
              { validator: (_, value) => validateDateOfBirth(value) },
            ]}
          >
            <ReactInputMask mask="99/99/9999" placeholder="Dia/Mês/Ano" maskChar={null}>
              {(inputProps) => <StyledInput {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col xl={12} md={12} xs={12}>
          <Form.Item
            name="telefone"
            label="Telefone"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o número de telefone!',
              },
              ...(selectedCountry === 'brasil'
                ? [
                    {
                      min: 15,
                      message: 'O número deve ter 10 dígitos!',
                    },
                  ]
                : []),
            ]}
          >
            {selectedCountry === 'brasil' ? (
              <ReactInputMask mask="(99) 99999-9999" maskChar={null}>
                {() => <StyledInput placeholder="(99) 99999-9999" />}
              </ReactInputMask>
            ) : (
              <StyledInput placeholder="Telefone" />
            )}
          </Form.Item>
        </Col>

        <Col xl={12} md={12} xs={12}>
          <Form.Item
            name="sexo"
            label="Sexo"
            rules={[{ required: true, message: 'Obrigatório' }]}
            initialValue="masculino"
          >
            <StyledSelect disabled={loggedIn}>
              <StyledSelect.Option value="masculino">Masculino</StyledSelect.Option>
              <StyledSelect.Option value="feminino">Feminino</StyledSelect.Option>
            </StyledSelect>
          </Form.Item>
        </Col>
      </Row>

      {!loggedIn && (
        <Row gutter={30}>
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              name="password"
              label="Crie uma senha"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
            >
              <StyledInput.Password placeholder="Senha" />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              name="new_password"
              label="Confirmar senha"
              dependencies={['password']}
              rules={[
                { message: 'Por favor, confirme sua senha!', required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('As senhas digitadas não coincidem!'))
                  },
                }),
              ]}
            >
              <StyledInput.Password placeholder="Confirmar" />
            </Form.Item>
          </Col>
        </Row>
      )}

      {landingPageData?.preenchimento_foto && (
        <Row justify="center">
          <Form.Item
            label={`${landingPageData?.label_foto}`}
            name="image"
            required={landingPageData?.preenchimento_foto}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                required: landingPageData?.preenchimento_foto,
                message: 'Por favor, envie uma imagem!',
                validator: () => {
                  if (imageUrl.length === 0) {
                    return Promise.reject(new Error('Por favor, envie uma imagem!'))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PhotoUpload
                initialFileList={imageUrl}
                setImage={(image) => {
                  setImageUrl(image as ImageFile[])
                }}
                numeroFotos={1}
              />
            </div>
          </Form.Item>
        </Row>
      )}

      {!loggedIn && (
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('É necessário aceitar nossos Termos de Serviços e Política de Privacidade'),
                    ),
            },
          ]}
        >
          <Checkbox>
            Criar uma conta significa que você concorda com nossos Termos de Uso e Política de Privacidade.
          </Checkbox>
        </Form.Item>
      )}
    </div>
  )
}
