import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const GroupsViewGenerals = lazy(() => import('../../container/admin/pages/groups/Generals/index'))
function Users() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/generals/view-all/:id?`} component={GroupsViewGenerals} />
    </Switch>
  )
}

export default Users
