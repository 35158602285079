import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export const useUpdateUserAddressMutation = (userId) => {
  const queryClient = useQueryClient()

  const updateUserAddressMutation = useMutation({
    mutationFn: async (updatedUser) => {
      const userAddress = {
        bairro: updatedUser.bairro,
        cep: updatedUser.cep,
        cidade: updatedUser.cidade,
        complemento: updatedUser.complemento,
        estado: updatedUser.estado,
        numero: updatedUser.numero,
        rua: updatedUser.rua,
      }

      const userUpdatePromises = [DataService.put(`/administradores/editar-endereco-usuario/${userId}`, userAddress)]

      const [userDataResponse] = await Promise.all(userUpdatePromises)

      return { userDataResponse }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['USER_BY_ID', userId])
      queryClient.invalidateQueries(['ADDRESS_BY_ID', userId])
      notification.success({ message: 'Endereço atualizado com sucesso!' })
    },
    onError: (error) => {
      notification.error({
        message: 'Ocorreu um erro ao atualizar o endereço',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return updateUserAddressMutation
}
