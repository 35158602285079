import styled from 'styled-components'
import { Table as AntdTable, Button } from 'antd'
import { BORDER_RADIUS, FONT_SIZE } from '../../../../../styles/Themes/Admin/constants'

export const FilterButton = styled(Button)`
  color: var(--black);

  &:hover,
  &:focus {
    color: var(--table-cell-color);
    background-color: var(--table-cell-hover-color);
    border-color: var(--table-cell-hover-color);
  }

  &:focus::after,
  &:active::after {
    display: none;
  }
`

export const Table = styled(AntdTable)`
  .rowteste {
    .ant-table-cell {
      border: 1px solid var(--text-secondary-color) !important;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      border: 1px solid var(--text-secondary-color) !important;
    }
  }

  thead .ant-table-cell {
    color: var(--table-header-color) !important;
  }

  a {
    color: var(--text-main-color);
  }

  .ant-table-measure-row {
    display: none;
  }

  .ant-spin-show-text {
    max-height: 90% !important;
  }

  .ant-spin-text {
    color: var(--text-main-color);
    font-size: 20px !important;
  }

  & thead .ant-table-cell {
    & .anticon {
      color: var(--text-secondary-color);
      opacity: 0.4;
    }
  }

  .active {
    .anticon.anticon-filter {
      color: var(--text-main-color);
      opacity: 1;
    }
  }

  & tbody .ant-table-cell {
    color: var(--text-main-color);
    background-color: transparent;
    font-size: ${FONT_SIZE.xs};
    text-align: center;

    /* line-height: 1.25rem; */
  }

  & tbody .ant-table-row:hover .ant-table-cell {
    background-color: var(--table-cell-hover-color) !important;
  }

  .ant-table-row-selected .ant-table-cell {
    background-color: var(--sidebar-background-color); /* Cor de fundo na linha selecionada quando não está em hover */
  }

  & tbody .ant-table-placeholder .ant-table-cell {
    &:hover {
      background-color: var(--table-cell-hover-color);
    }
  }

  & tbody .ant-empty-description {
    color: var(--text-main-color);
  }

  .ant-table {
    background: none;
  }

  // ======================================

  .ant-table-expanded-row {
    .ant-table-cell {
      background-color: transparent !important;

      .ant-table-thead {
        background-color: var(--table-header-bg);
      }
    }
  }

  table {
    thead {
      tr {
        th {
          text-align: center;
          background-color: var(--table-header-bg) !important;
        }
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: var(--sidebar-background-color);
  }

  // ======================================

  // Override default antd selector
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--primary-color);
  }

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
    border-radius: 0;
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
    height: 2.2rem;
  }

  & .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.75rem;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-pagination {
    .ant-pagination-item-link,
    .ant-pagination-item,
    a {
      color: var(--text-main-color);
      background-color: var(--background-color);

      :hover {
        border-color: var(--table-cell-hover-color);
      }
    }
  }

  .ant-pagination-item {
    height: 2.2rem;
  }

  .ant-pagination-item-active {
    border-color: var(--table-cell-hover-color);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--table-cell-hover-color); // Cor de fundo no estado indeterminado
  }

  .ant-checkbox-checked:after {
    border-color: var(--sidebar-background-darker-color);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--table-cell-hover-color); // Cor de fundo do checkbox quando marcado
    border-color: var(--table-cell-color);
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--table-cell-color); // Cor do ícone
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: transparent; // Cor da borda no hover
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: var(--text-secondary-color);
    }
  }

  .ant-table-expanded-row .ant-table-cell {
    background-color: var(--table-cell-hover-color);
  }

  .ant-table-row-expand-icon {
    color: var(--black);
    background-color: var(--white);
  }

  .ant-table-footer {
    background-color: transparent;
    border: none !important;
  }

  /* PAGINATION */

  .ant-pagination-item {
    background-color: var(--background-color); /* Cor de fundo das opções */
    border: 1px solid var(--text-secondary-color); /* Borda das opções */
  }

  .ant-pagination-item:hover {
    background-color: var(--text-secondary-color); /* Cor de fundo quando a opção é destacada (hover) */
  }

  .ant-pagination-item a:hover {
    color: var(--text-main-color);
    background-color: var(--text-secondary-color); /* Cor de fundo quando a opção é destacada (hover) */
  }

  .ant-pagination-item-link {
    color: var(--text-main-color);
    background-color: var(--background-color);

    :hover {
      color: var(--text-main-color);
      border-color: var(--text-secondary-color);
      background-color: var(--text-secondary-color);
    }
  }

  button.ant-pagination-item-link {
    border: 1px solid var(--text-secondary-color);
  }

  .ant-pagination-item.ant-pagination-item-active a {
    color: var(--table-cell-color); /* Cor do texto da opção selecionada */
    background-color: var(--text-main-color); /* Cor de fundo da opção selecionada */
    border-color: var(--text-secondary-color); /* Borda da opção selecionada */
    height: 100%;
  }

  .ant-pagination-item a {
    color: var(--text-secondary-color); /* Cor do texto das opções que não estão selecionadas */
  }

  .ant-pagination-disabled {
    color: var(--text-secondary-color); /* Cor do texto quando desativado */
  }

  .ant-pagination-disabled .ant-pagination-item {
    background-color: var(--disabled-color); /* Cor de fundo quando desativado */
    border-color: var(--disabled-color); /* Borda quando desativado */
  }

  .ant-pagination-disabled .ant-pagination-item a {
    color: var(--disabled-color); /* Cor do texto quando desativado */
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--text-main-color);
  }

  .ant-pagination-item-container {
    .ant-pagination-item-ellipsis {
      color: var(--text-main-color); // Cor do ellipsis
    }
  }

  .ant-pagination-options-quick-jumper {
    color: var(--text-main-color);
  }

  .ant-pagination-total-text {
    color: var(--text-main-color);
  }

  /* SELECT */

  .ant-select-selector {
    border-radius: ${BORDER_RADIUS}; // Ajusta o border-radius do seletor
    padding: 4px 8px; // Ajusta o padding interno
    background-color: var(--background-color) !important; // Cor de fundo do seletor
    color: var(--text-main-color); // Cor do texto do seletor
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 0px var(--input-border-color) !important;
    height: 36px !important;
    align-items: center;

    &:hover {
      border-color: var(--input-border-color) !important;
      box-shadow: 0 0 0 1px var(--input-border-color) !important;
    }
  }

  &.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open {
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 1px var(--input-border-color) !important;
  }

  .ant-select-selection-item {
    font-size: 14px; // Ajusta o tamanho da fonte dos itens selecionados
    color: var(--text-main-color); // Cor do texto dos itens selecionados (no select fechado)
  }

  .ant-select-arrow {
    color: var(--text-main-color); // Cor da seta
  }

  .ant-select-dropdown {
    border-radius: ${BORDER_RADIUS}; // Ajusta o border-radius do dropdown
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); // Adiciona sombra ao dropdown
    background-color: var(--background-color); // Cor de fundo do dropdown

    .ant-select-item-option-content {
      color: var(--text-main-color); // Cor do texto dos itens
    }

    .ant-select-item-option-disabled {
      .ant-select-item-option-content {
        color: var(--text-secondary-color);
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-selected) {
      background-color: var(--text-secondary-color); // Cor de fundo ao passar o mouse em um item não selecionado
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
      background-color: var(--button-bg-color);
      .ant-select-item-option-content {
        color: var(--button-text-color);
      }
    }
  }

  .ant-select-clear {
    background-color: var(--background-color) !important; // Cor de fundo do botão de fechar

    svg {
      color: var(--text-main-color) !important; // Cor do ícone
    }
  }

  .ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-item {
        color: var(--disabled-color);
      }
    }
  }
`
