import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useGetLivesChannels(socialMedia) {
  const livesChannels = useQuery({
    queryKey: ['CHANNELS', socialMedia],
    queryFn: async () => {
      const response = await DataService.get(`youtube/get-channels?rede_social=${socialMedia}`)
      return response?.data
    },
  })

  return livesChannels
}
