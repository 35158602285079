import { http, HttpResponse } from 'msw'
import {
  GetUserVocacionalByTokenProps,
  GetUserVocacionalByTokenResponse,
} from '../../../container/benfeitor/hooks/api/vocacional/queries/useGetUserVocacionalByToken'

const response = {
  fk_usuario_vocacional_id: 1,
  nome: 'Usuario Vocacional',
  email: 'usuario.vocacional@gmail.com',
  telefone: '99999999999',
  pais: 'brasil',
  etapa: 'pre_cadastro',
  status: 'aprovado',
} as const

export const VOCACIONAL_TOKENS = {
  INVALID_TOKEN: 'INVALID_TOKEN',

  WITHDRAWAL_TOKEN: 'WITHDRAWAL_TOKEN',

  ALREADY_REGISTER: 'ALREADY_REGISTER',

  FOREIGN: 'FOREIGN',

  ANOTHER_ID: 'ANOTHER_ID',

  FICHA: 'FICHA_ID',

  ALREADY_REGISTRATION_FORM: 'ALREADY_REGISTRATION_FORM',
}

export const decodeTokenVocacionalMock = http.get<
  GetUserVocacionalByTokenProps,
  never,
  GetUserVocacionalByTokenResponse
>('/vocacional/decodificar-token-vocacional/:token', ({ params }) => {
  if (params.token === VOCACIONAL_TOKENS.INVALID_TOKEN) {
    return HttpResponse.json(null as never, { status: 500 })
  }

  if (params.token === VOCACIONAL_TOKENS.WITHDRAWAL_TOKEN) {
    return HttpResponse.json({ ...response, status: 'desistencia' }, { status: 200 })
  }

  if (params.token === VOCACIONAL_TOKENS.ALREADY_REGISTER) {
    return HttpResponse.json({ ...response, etapa: 'cadastro', status: 'pendente' }, { status: 200 })
  }

  if (params.token === VOCACIONAL_TOKENS.FOREIGN) {
    return HttpResponse.json({ ...response, pais: 'afeganistao' }, { status: 200 })
  }

  if (params.token === VOCACIONAL_TOKENS.ANOTHER_ID) {
    return HttpResponse.json({ ...response, fk_usuario_vocacional_id: 2 }, { status: 200 })
  }

  if (params.token === VOCACIONAL_TOKENS.FICHA) {
    return HttpResponse.json({ ...response, etapa: 'cadastro', status: 'aprovado' }, { status: 200 })
  }

  if (params.token === VOCACIONAL_TOKENS.ALREADY_REGISTRATION_FORM) {
    return HttpResponse.json({ ...response, etapa: 'ficha_vocacional', status: 'pendente' }, { status: 200 })
  }

  return HttpResponse.json(response)
})
