import { useState } from 'react'

import { VOCACIONAL_TABS_ENUM } from '../utils'
import { PreCadastrados } from '../components/PreCadastrados'
import { Cadastros } from '../components/Cadastros'
import { Desistentes } from '../components/Desistentes'
import { Recusados } from '../components/Recusados'
import { Ficha } from '../components/FichaVocacional'
import { useRedirectOnPermDenied } from '../../../hooks'
import BaseCard from '../../../components/card/BaseCard'
import BaseTabs from '../../../components/tabs/BaseTabs'

export function VocacionalMasculino() {
  useRedirectOnPermDenied('vocacional')

  const [activeTabKey, setActiveTabKey] = useState(VOCACIONAL_TABS_ENUM.PRE_CADASTRADOS)

  const handleTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  const tabsItems = [
    {
      label: 'Pré-cadastrados',
      key: VOCACIONAL_TABS_ENUM.PRE_CADASTRADOS,
      children: <PreCadastrados vocacionalGender="masculino" />,
    },

    {
      label: 'Cadastros',
      key: VOCACIONAL_TABS_ENUM.CADASTROS,
      children: <Cadastros vocacionalGender="masculino" />,
    },
    {
      label: 'Fichas vocacionais',
      key: VOCACIONAL_TABS_ENUM.FICHA_VOCACIONAL,
      children: <Ficha vocacionalGender="masculino" />,
    },
    {
      label: 'Recusados',
      key: VOCACIONAL_TABS_ENUM.RECUSADOS,
      children: <Recusados vocacionalGender="masculino" />,
    },
    {
      label: 'Desistentes',
      key: VOCACIONAL_TABS_ENUM.DESISTENTES,
      children: <Desistentes vocacionalGender="masculino" />,
    },
  ]

  return (
    <BaseCard title="Vocacional Masculino" error={undefined}>
      <BaseTabs items={tabsItems} activeKey={activeTabKey} onChange={handleTabChange} children={undefined} />
    </BaseCard>
  )
}
