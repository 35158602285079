import { StyledResult } from '../Result'
import { ResultEmpty } from '../Result/EmptyResult'
import { StyledSpin } from '../Spin/styles'
import { StyledCard } from './styles'
import { Spin, Card as AntdCard, CardProps as AntdCardProps } from 'antd'

interface CardProps extends AntdCardProps {
  isPending?: boolean
  isError?: boolean
  empty?: boolean
  children: React.ReactNode
  shadow?: boolean
}

export function MainCard({
  isPending,
  isError,
  children,
  shadow,
  empty,
  ...props
}: CardProps) {
  if (isPending) {
    return (
      <StyledCard shadow={shadow} {...props}>
        <StyledSpin
          style={{
            width: '100%',
            minHeight: '65vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          size="default"
        />
      </StyledCard>
    )
  }

  if (isError) {
    return (
      <StyledCard shadow={shadow} {...props}>
        <StyledResult />
      </StyledCard>
    )
  }

  if (empty) {
    return (
      <StyledCard shadow={shadow} {...props}>
        <ResultEmpty />
      </StyledCard>
    )
  }

  return (
    <StyledCard shadow={shadow} {...props}>
      {children}
    </StyledCard>
  )
}
