import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../lib/dataService'

export interface Perfil {
  data_criacao: string
  id: number
  nome: string
  quantidade_usuarios: number
  status: boolean
  super_perfil: boolean
}

interface ListAllProfilesResponse {
  perfis: Perfil[]
  page: number
  total: number
}

export function useListAllProfiles() {
  return useQuery({
    queryKey: ['listar-perfis'],
    queryFn: async (): Promise<ListAllProfilesResponse> => {
      const response = await DataService.get('administradores/listar-perfis')
      return response?.data
    },
  })
}
