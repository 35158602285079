import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetAllFamiliasFilters {
  filters?: {
    queryString?: string | null
    data_inicio?: string
    data_fim?: string
    email?: string
    nome?: string
    cpf?: string
    data_nascimento?: string
    vocacional?: 'masculino' | 'feminino'
    id?: string
    etapa?: string
    filter_status?: string
    filter_desistencia?: boolean
  }
  page?: number
  per_page?: number
}

export interface GetFamiliasResponse {
  familias: {
    cadastrada_por: string
    cadastrado_em: string
    familia: string
    fk_endereco_id: number
    foto: string
    id: number
    membros: number
    observacao: string
    recebimentos: number
    renda: number
    ultimo_recebimento: string
  }[]
  page: number
  pages: number
  total: number
}

export function useGetFamilias({ filters }: GetAllFamiliasFilters) {
  const familias = useQuery({
    queryKey: ['GET_ALL_FAMILIAS', filters],
    queryFn: async () => {
      const response = await DataService.get<GetFamiliasResponse>(`agape/listar-familias?${filters?.queryString ?? ''}`)
      return response.data
    },
  })

  return familias
}
