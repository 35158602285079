import React, { useState } from 'react'
import { Col, Row } from 'antd'
import moment from 'moment'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import { Label, ModalContent, StyledMenuFinance } from '../../../styles'
import BaseModal from '../../../../../components/modal/BaseModal'
import { BaseSelect } from '../../../../../components/select/BaseSelect'
import { StyledFilterIcon } from '../../../../../components/FilterIcon'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'

export function FilterModal({ filters, setFilters, campaignsOptions }) {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const isFilterActive = Boolean(filters?.fk_campanha_id) || (filters?.data_inicio && filters?.data_fim)

  const campaignsOptionsFormatted = campaignsOptions
    .map((item) => ({ value: item.id, label: item.titulo }))
    .sort((a, b) => a.label.localeCompare(b.label))
  return (
    <>
      <StyledFilterIcon className="absolute" onClick={() => setIsModalOpen(true)} isFilterActive={isFilterActive} />
      <BaseModal
        title="Filtros por Forma de Pagamento"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        closeIcon="X"
        size="large"
      >
        <ModalContent>
          <div>
            <Label>Campanhas:</Label>
            <BaseSelect
              width="20rem"
              dropdownRender={(menu) => <StyledMenuFinance>{menu}</StyledMenuFinance>}
              allowClear
              options={campaignsOptionsFormatted}
              value={filters?.fk_campanha_id}
              onChange={(value) => handleInputChange({ fk_campanha_id: value })}
            />
          </div>
        </ModalContent>
        <Row justify="center" style={{ marginTop: '1rem' }}>
          <Col xs={10}>
            <Label>Data:</Label>
            <BaseDatePicker
              range
              style={{ width: '100%' }}
              defaultValue={[moment().startOf('month'), moment()]}
              onChange={(dates, dateStrings) => {
                const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
                const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

                handleInputChange({
                  data_inicio: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
                  data_fim: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
                })
              }}
              format="DD/MM/YYYY"
              placeholder={['Inicial', 'Final']}
            />
          </Col>
        </Row>
      </BaseModal>
    </>
  )
}
