import BaseModal from '../../../../components/modal/BaseModal'
import { BeneficiadosTabela } from './BeneficiadosTabela'

interface BeneficiadosProps {
  open: boolean
  nomeAcao: string
  idCicloDoacao: number
  handleIsOpenBeneficiados: () => void
}

export function Beneficiados({
  open,
  nomeAcao,
  idCicloDoacao,
  handleIsOpenBeneficiados,
}: BeneficiadosProps) {
  return (
    <BaseModal
      title={<h3>Beneficiados - {nomeAcao}</h3>}
      open={open}
      onCancel={handleIsOpenBeneficiados}
      footer={false}
      width={'70vw'}
    >
      <BeneficiadosTabela idCicloDoacao={idCicloDoacao} />
    </BaseModal>
  )
}
