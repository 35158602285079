import { Layout } from 'antd'
import styled from 'styled-components'

export const StyledSider = styled(Layout.Sider)`
  background-color: var(--sidebar-background-color);

  height: 100vh;
  overflow: hidden;

  z-index: 2;
`
