import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { PermissionsPage } from '../../container/admin/pages/users/PermissionsPanel'

const UsersView = lazy(() => import('../../container/admin/pages/users/View'))
const UsersEdit = lazy(() => import('../../container/admin/pages/users/Edit'))
const UsersDetails = lazy(() => import('../../container/admin/pages/users/Details'))
const UsersImported = lazy(() => import('../../container/admin/pages/users/Imported/index'))

function Users() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/view-all`} component={UsersView} />
      {/* <Route path={`${path}/add`} component={UsersAdd} /> */}
      <Route path={`${path}/imported`} component={UsersImported} />
      <Route exact path={`${path}/edit/:id`} component={UsersEdit} />
      <Route exact path={`${path}/details/:id`} component={UsersDetails} />
      <Route path={`${path}/permissions`} component={PermissionsPage} />
    </Switch>
  )
}

export default Users
