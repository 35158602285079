import { useHistory } from 'react-router-dom'
import { Container } from './styles'
import logosImage from '../../images/logos.svg'
import { SmBaseButton } from '../components/button/styles'

export function CreateGroupVideo() {
  const history = useHistory()

  return (
    <Container>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={logosImage} alt="Logo" />
        </div>
        <div className="text-wrapper">
          <p>
            Deus se alegra pelo seu sim em desejar multiplicar as graças que você recebe diariamente, levando a outros
            corações a experiência com a Misericórdia de Deus.
          </p>
          <span>Assista ao vídeo abaixo e veja como é fácil criar o seu grupo no Whatsapp.</span>
        </div>
        <div className="video">
          <iframe
            width="445"
            height="250"
            src="https://www.youtube.com/embed/eiFpQL3Rk5g"
            title="Tutorial grupo whatsapp"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="text-wrapper">
          <p>
            Agora que você já criou o seu grupo no Whatsapp, estamos a um passo para contemplar você se tornando um
            GENERAL OFICIAL DO EXÉRCITO DE SÃO MIGUEL.
          </p>
          <p>Vamos juntos dar esse passo na vontade de Deus?</p>
        </div>
        <div className="button-wrapper">
          <SmBaseButton
            onClick={() => {
              history.push('/membro-exercito/tornar-se-general-form')
            }}
          >
            QUERO DAR ESSE PASSO
          </SmBaseButton>
        </div>
      </div>
    </Container>
  )
}
