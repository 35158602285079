import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export interface AdicionarItemProps {
  item: string
}

export function useCadastrarItemEstoque() {
  const cadastrarItem = useMutation({
    mutationFn: async ({ item }: AdicionarItemProps) => {
      await DataService.post(`/agape/cadastrar-item-estoque`, { item })
    },
    onSuccess: (_, { item }) => {
      notification.success({ message: `${item} adicionado com sucesso` })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao adicionar o item ao estoque',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return cadastrarItem
}
