import { env } from "../env"
import { workerBrowser } from "./workers/msw-browser"

export async function enableBrowserMocking() {
  if (env.VITE_API_MOCK !== 'true') {
    return
  }

  return workerBrowser.start()
}
