import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { DataService } from '../../../../../lib/dataService'; // Make sure the path is correct

export interface UpdateUserReqBody {
  usuario: {
    data_nascimento: string;
    nome: string;
    nome_social?: string;
    sexo: 'masculino' | 'feminino';
    telefone: string;
  };
  endereco?: {
    bairro?: string;
    cep?: string;
    cidade?: string;
    complemento?: string;
    estado?: string;
    numero?: string;
    rua?: string;

    detalhe_estrangeiro?: string;
  };
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ formattedValues, imageFile }: { formattedValues: UpdateUserReqBody; imageFile?: File }) => {
      const formData = new FormData();

      formData.append('data', JSON.stringify(formattedValues));

      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await DataService.put('/users/update', formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['USER_ME'] });
      queryClient.invalidateQueries({ queryKey: ['USERME_ADDRESS'] });

      notification.success({
        message: 'Perfil atualizado com sucesso!',
      });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Falha ao atualizar Perfil',
        description: error?.response?.data?.error || 'Algo deu errado, por favor, tente novamente!',
      });
    },
  });
}
