import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

type Donation = {
  campanha: string
  clifor_id: number
  data_donation: string
  metodo_pagamento: string
  nome: string
  pedido_id: number
  valor: string
}

type Pagination = {
  current_page: number
  pages: number
  per_page: number
  total_items: number
}

export interface PrevistasRecurrenceDonationsResponse {
  lista: Donation[]
  pagination: Pagination
}

export function useFetchRecurrencePrevistas(filters: { queryString?: string }) {
  const donationsPrevistas = useQuery<PrevistasRecurrenceDonationsResponse>({
    queryKey: ['RECURRENCE_DONATIONS_PREVISTAS', filters],
    queryFn: async () => {
      const response = await DataService.get(`checkout/list-doacoes-previstas?${filters.queryString || ''}`)
      return response?.data
    },
  })

  return donationsPrevistas
}
