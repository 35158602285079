import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useAuth } from '../context/Auth'

export function ProtectedRoute({ component, path }) {
  // console.log('superProfileProtected', user.superProfile)
  // if (user.superProfile !== true) {
  //   return <Redirect to="/" />;
  // }

  // if (user.superProfile === false && path === '/benfeitor/auth') {
  //   return <Route component={component} path={path} />;
  // }

  // if ((user?.superProfile && path === '/admin') || path === '/benfeitor/auth') {
  //   return <Route component={component} path={path} />;
  // }

  return <Route component={component} path={path} />
}
