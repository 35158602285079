import styled from 'styled-components';
import BaseCard from '../../../components/card/BaseCard';

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: auto;
  overflow: visible;

  @media (min-width: 1850px) {
    .content-wrapper {
      display: flex;
      flex-direction: row;
      height: 260px;
      gap: 2rem;
    }
  }
`;

export const SliderWrapper = styled.div`
  .tab-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2.5rem;
  }

  button {
    padding: 0.5rem;
    border: 2px solid #777;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    color: var(--text-main-color) !important;
    opacity: 0.7;

    &.active {
      background: #777;
      color: var(--text-main-color) !important;
      opacity: 1;
    }
  }
`;

export const ChartSection = styled.div`
  width: 100%;
  height: 260px;
  margin-bottom: 1.5rem;

  @media (min-width: 1850px) {
    max-width: 45%;
  }
`;

export const CardsSection = styled.div`
  width: 100%;
  /* margin-bottom: 1rem; */

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
  }

  @media (min-width: 600px) {
    .cards-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      height: 100%;
    }
  }

  @media (min-width: 1850px) {
    max-width: 55%;
    gap: 2rem;
  }
`;

// New chart-related styles
export const ChartWrapper = styled.div`
  height: 265px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartErrorMessage = styled.h4`
  margin: 0;
  color: red;
  text-align: center;
`;

export const ChartEmptyMessage = styled.h4`
  margin: 0;
  color: gray;
  text-align: center;
  padding: 30px;
`; 