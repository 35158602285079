import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useCreateCampaign() {
  const queryClient = useQueryClient()
  const history = useHistory()

  const createCampaign = useMutation({
    mutationFn: ({ formData, capaImage, bannerImage, update, campaignId }) => {
      const updatedFormData = {
        ...formData,
        data_inicio: formData.data_inicio ? moment(formData.data_inicio).format('YYYY-MM-DD') : null,
        data_fim: formData.data_fim ? moment(formData.data_fim).format('YYYY-MM-DD') : null,
      }

      delete updatedFormData.capaImage
      delete updatedFormData.bannerImage

      const formDataInstance = new FormData()
      formDataInstance.append('data', JSON.stringify(updatedFormData))
      formDataInstance.append('imagem_capa', capaImage[0]?.file)
      formDataInstance.append('banner', bannerImage[0]?.file)

      const postRequest = !update && DataService.post('/campaigns', formDataInstance)
      const putRequest = update && DataService.put(`/campaigns/${campaignId}`, formDataInstance)

      return postRequest || putRequest
    },
    onSuccess: () => {
      history.push('/admin/campaigns/view-all')
      notification.success({ message: 'Cadastrado com Sucesso' })
      queryClient.invalidateQueries(['CAMPAIGNS_LIST'])
    },
    onError: (error) => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar a campanha',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return createCampaign
}
