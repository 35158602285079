import { Col, Row, Form } from 'antd'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import TextArea from 'antd/lib/input/TextArea'
import { UploadFile } from 'antd/es/upload'
import { FormInstance } from 'antd/es/form/Form'
import { BaseMultiplePhotos } from '../../../../components/upload/BaseMultiplePhotoUpload'
import BaseCard from '../../../../components/card/BaseCard'

interface FormularioInformacoesProps {
  form: FormInstance
}

export function FormularioInformacoes({ form }: FormularioInformacoesProps) {
  function setImageInForm(files: UploadFile<any>[]) {
    form.setFieldValue('imagens_observacao', files)
  }

  const imagens = Form.useWatch('imagens_observacao', form) || []

  return (
    <BaseCard title={<h5>Observações</h5>} size="small">
      <Row justify="center" gutter={[0, 20]}>
        <Col xs={24} sm={12}>
          <BaseForm.Item
            name="imagens_observacao"
            label="Imagens"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <BaseMultiplePhotos
              description="Envie uma foto sobre a família"
              initialFileList={imagens}
              numeroFotos={1}
              setImage={setImageInForm}
              validateField={() => form.validateFields(['imagens_observacao'])}
              listInRow={false}
            />
          </BaseForm.Item>
        </Col>

        <Col xs={24} sm={12}>
          <BaseForm.Item label="Observação" name="observacao">
            <TextArea placeholder="Informações sobre a família" />
          </BaseForm.Item>
        </Col>
      </Row>
    </BaseCard>
  )
}
