import { notification } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export interface WithdrawalVocacionalProps {
  fk_usuario_vocacional_id: number
}

export function useWithdrawalVocacional() {
  const withdrawalVocacional = useMutation({
    mutationFn: async ({ fk_usuario_vocacional_id }: WithdrawalVocacionalProps) => {
      await DataService.post(`vocacional/registrar-desistencia/${fk_usuario_vocacional_id}`)
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao realizar desistência.',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return withdrawalVocacional
}
