import { http, HttpResponse } from 'msw'
import { EnderecosFamiliasResponse } from '../../../../../hooks/agape/queries/useGetEnderecosFamilias'

const response = {
  enderecos: [
    {
      bairro: 'Farias Brito',
      cep: '60010-835',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.7257108,
      longitude: -38.5446084,
      numero: '160',
      ponto_referencia: 'Casa verde..',
      rua: 'Rua Epifânio Leite',
    },
    {
      bairro: 'Boa Vista-Castelão',
      cep: '60861-128',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.7983652,
      longitude: -38.5270478,
      numero: '101',
      ponto_referencia: 'Casa rosa.',
      rua: 'Rua Antônio Ferreira',
    },
    {
      bairro: 'Conjunto Palmeiras',
      cep: '60870-515',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.8419564,
      longitude: -38.529657,
      numero: '93',
      ponto_referencia: 'Casa de Kamilly',
      rua: 'Rua Isabela Moreira',
    },
    {
      bairro: 'Farias Brito',
      cep: '60015-141',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.7227153,
      longitude: -38.5353376,
      numero: '233',
      ponto_referencia: 'Em frente à padaria.',
      rua: 'Rua Teresa Cristina',
    },
    {
      bairro: 'Messejana',
      cep: '60841-270',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.822822999999999,
      longitude: -38.491985,
      numero: '79',
      ponto_referencia: 'Mercadinho Boa Esperança.',
      rua: 'Rua Pero Vaz',
    },
    {
      bairro: 'Carlito Pamplona',
      cep: '60311-710',
      cidade: 'Fortaleza',
      complemento: '',
      estado: 'CE',
      latitude: -3.715600499999999,
      longitude: -38.5597203,
      numero: '400',
      ponto_referencia: 'Apartamento 505.',
      rua: 'Rua Onze de Julho',
    },
    {
      bairro: 'Centro',
      cep: '60020-030',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.7213993,
      longitude: -38.52954340000001,
      numero: '150',
      ponto_referencia: 'Casa de esquina..',
      rua: 'Rua General Sampaio',
    },
    {
      bairro: 'Boa Vista-Castelão',
      cep: '60861-212',
      cidade: 'Fortaleza',
      complemento: 'Reserva Jardim.',
      estado: 'CE',
      latitude: -3.7892043,
      longitude: -38.5177138,
      numero: '1240',
      ponto_referencia: 'Casa de Neville Guimaraes Desenvolvedor',
      rua: 'Avenida Alberto Craveiro',
    },
    {
      bairro: 'José Bonifácio',
      cep: '60040030',
      cidade: 'Fortaleza',
      complemento: null,
      estado: 'CE',
      latitude: -3.7378715,
      longitude: -38.5248559,
      numero: '669',
      ponto_referencia: 'Embaixo do DentePlus',
      rua: 'Rua Dom Bosco',
    },
  ],
}

export const listarEnderecosFamiliasMock = http.get<never, never, EnderecosFamiliasResponse>(
  '/agape/listar-enderecos-familias-agape',
  () => {
    return HttpResponse.json(response)
  },
)
