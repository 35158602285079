import { Content } from "./Content";
import { AnimatedBaseCard } from "../../../styles";

export function SignupsPerDayOfWeek() {

  return (
    <AnimatedBaseCard padding="0.5rem">
      <Content />
    </AnimatedBaseCard>
  );
}