import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface Pagination {
  page: number
  per_page: number
  total_pages: number
  total_items: number
  current_page: number
}

export interface RecorrenteNaoEfetuadasResponse {
  info_card: {
    total_items: number
    valor_total: string
  }
  lista: {
    campanha: string
    clifor_id: number
    cpf_cnpj: string
    data_lembrete_doacao: string | null
    data_prevista: string
    descricao_campanha: string
    lembrete_enviado_por: string | null
    metodo_pagamento: string
    nome: string
    pedido_criado_em: string
    pedido_id: number
    processamento_pedido: number
    valor: string
  }[]
  pagination: Pagination
}

interface Filters {
  queryString?: string
}

export function useFetchRecurrenteNaoEfetuadas(filters: Filters): UseQueryResult<RecorrenteNaoEfetuadasResponse> {
  return useQuery<RecorrenteNaoEfetuadasResponse>({
    queryKey: ['RECURRENCE_DONATIONS_NAO_EFETUADAS', filters],
    queryFn: async () => {
      const response = await DataService.get<RecorrenteNaoEfetuadasResponse>(
        `checkout/list-doacoes-nao-efetuadas?${filters.queryString || ''}`,
      )
      return response?.data
    },
  })
}
