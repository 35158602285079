import { MapPin } from '@phosphor-icons/react'
import { BaseTable } from '../../../../../../components/table/BaseTable/BaseTable'
import { GetFamiliaPorCpfResponse } from '../../../../../../hooks/agape/queries/useGetFamiliaPorCpf'
import { VisualizacaoEnderecoFamilia } from './VisualizacaoEnderecoFamilia'
import BaseButton from '../../../../../../components/button/BaseButton'
import {
  GetEnderecoCicloDoacaoResponse,
  useGetEnderecoCicloDoacao,
} from '../../../../../../hooks/agape/queries/useGetEnderecoCicloDoacao'
import { EnderecoFamiliaResponse } from '../../../../../../hooks/agape/queries/useGetEnderecoFamilia'
import { VisualizarFotoObservacao } from './VisualizarFotoObservacao'

interface ConfirmarDadosTabelaProps {
  dados: GetFamiliaPorCpfResponse | undefined
  idCicloDoacao: number
}

function defineMapPinColor({
  enderecoUsuario,
  enderecoCicloDoacao,
}: {
  enderecoUsuario: EnderecoFamiliaResponse
  enderecoCicloDoacao: GetEnderecoCicloDoacaoResponse
}): string {
  if (enderecoCicloDoacao === undefined || enderecoUsuario === undefined) {
    return ''
  }

  const { abrangencia, cep, rua, bairro, cidade, estado } = enderecoCicloDoacao

  switch (abrangencia) {
    case 'cep':
      return enderecoUsuario.cep === cep ? 'var(--success-color)' : 'var(--error-color)'
    case 'rua':
      return enderecoUsuario.rua === rua ? 'var(--success-color)' : 'var(--error-color)'
    case 'bairro':
      return enderecoUsuario.bairro === bairro ? 'var(--success-color)' : 'var(--error-color)'
    case 'cidade':
      return enderecoUsuario.cidade === cidade ? 'var(--success-color)' : 'var(--error-color)'
    case 'estado':
      return enderecoUsuario.estado === estado ? 'var(--success-color)' : 'var(--error-color)'
    case 'sem_restricao':
      return 'var(--success-color)'
    default:
      return 'var(--error-color)'
  }
}

export function ConfirmarDadosTabela({ dados, idCicloDoacao }: ConfirmarDadosTabelaProps) {
  const { data: enderecoCicloDoacao } = useGetEnderecoCicloDoacao({ idCicloDoacao })

  const dataSource = [
    {
      key: dados?.familia.id,
      nome_familia: dados?.familia.nome_familia,
      cadastro_em: dados?.familia.cadastrado_em,
      status: dados?.familia.status ? 'Ativo' : 'Inativo',
      ultimo_recebimento: dados?.familia.ultimo_recebimento ?? ' - ',
      observacoes: dados?.familia.observacao ?? ' - ',
      foto: dados?.familia.foto ? <VisualizarFotoObservacao foto={dados?.familia.foto} /> : ' - ',
      endereco: dados?.endereco,
    },
  ]

  const columns = [
    {
      title: 'Família',
      dataIndex: 'nome_familia',
      key: 'nome_familia',
    },
    {
      title: 'Cadastro em',
      dataIndex: 'cadastro_em',
      key: 'cadastro_em',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Último recebimento neste ciclo de doação',
      dataIndex: 'ultimo_recebimento',
      key: 'ultimo_recebimento',
    },
    {
      title: 'Observações',
      dataIndex: 'observacoes',
      key: 'observacoes',
    },
    {
      title: 'Foto',
      dataIndex: 'foto',
      key: 'foto',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      expandable={{
        expandedRowRender: (record: ConfirmarDadosTabelaProps['dados']) => (
          <VisualizacaoEnderecoFamilia enderecoFamilia={record?.endereco!} enderecoCicloDoacao={enderecoCicloDoacao!} />
        ),
        expandIcon: ({ onExpand, record }) => (
          <BaseButton type="text" onClick={(e) => onExpand(record, e as React.MouseEvent<HTMLElement>)}>
            <MapPin
              size={30}
              color={defineMapPinColor({
                enderecoCicloDoacao: enderecoCicloDoacao!,
                enderecoUsuario: record?.endereco!,
              })}
            />
          </BaseButton>
        ),
      }}
    />
  )
}
