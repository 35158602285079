import { Dropdown } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import * as S from './ProfileMobile.Styles'
import { ThemePicker } from '../../../../../styles/Themes/Admin/ThemePicker'
import BaseAvatar from '../../../components/avatar/BaseAvatar'
import { BlurToggle } from '@/context/Blur/BlurToggle'

export function ProfileMobile({ userAvatar, userName, menuItems }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Dropdown
        menu={{
          items: menuItems,
        }}
        trigger={['click']}
        placement="bottom"
      >
        <BaseAvatar style={{ cursor: 'pointer' }} icon={<UserOutlined />} size={50} src={userAvatar} />
      </Dropdown>
    </div>
  )
}
