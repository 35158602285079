import { Row } from 'antd'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatToBrazilianCurrency } from '../../../../../../utility/utility'
import BaseButton from '../../../../components/button/BaseButton'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { BaseSpin } from '../../../../components/spin/BaseSpin.Styles'
import {
  FetchDonorsRankingProps,
  useFetchDonorsRanking,
} from '../../../../hooks/donations/queries/useFetchDonorsRanking'
import { BlurDiv } from '@/context/Blur/BlurDiv'

interface RankingDonorProps {
  filters: FetchDonorsRankingProps['filters']
}

export function RankingDonor({ filters }: RankingDonorProps) {
  const { filters: newFilters } = { filters }

  const [donorsRankingFilters, setDonorsRankingFilters] = useState({ ...newFilters })

  useEffect(() => {
    setDonorsRankingFilters({ ...newFilters })
  }, [filters])

  const {
    data: donorsRanking,
    isLoading: isLoadingDonorsRanking,
    isError: isErrorDonorsRanking,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFetchDonorsRanking({ filters: donorsRankingFilters })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasNextPage && !isFetchingNextPage) {
          fetchNextPage()
        }
      }
    }

    const container = tableContainerRef.current
    container?.addEventListener('scroll', handleScroll)
    return () => container?.removeEventListener('scroll', handleScroll)
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const history = useHistory()

  const donorsData = donorsRanking?.pages?.flatMap((page) => page.doadores) || []

  const dataSource = donorsData.map((item, index) => {
    const { benfeitor, fk_usuario_id, quantidade_doacoes, valor_total_doacoes } = item

    return {
      rank: `${index + 1}º`,
      key: index,
      benfeitor: fk_usuario_id ? (
        <BaseButton
          type="link"
          onClick={() => {
            history.push(`/admin/users/details/${fk_usuario_id}`)
          }}
        >
          {benfeitor}
        </BaseButton>
      ) : (
        <span>{benfeitor}</span>
      ),
      quantidade_doacoes,
      total: formatToBrazilianCurrency(valor_total_doacoes),
    }
  })

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center' as const,
    },
    {
      title: 'Benfeitor',
      dataIndex: 'benfeitor',
      key: 'benfeitor',
      render: (item: ReactNode) => <Row justify="center">{item}</Row>,
      align: 'center' as const,
    },
    {
      title: 'Doações',
      dataIndex: 'quantidade_doacoes',
      key: 'quantidade_doacoes',
      align: 'center' as const,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center' as const,
      render: (total: string) => <BlurDiv>{total}</BlurDiv>,
    },
  ]

  return (
    <div ref={tableContainerRef} style={{ height: 500, overflowY: 'auto' }}>
      <BaseTable
        columns={columns}
        dataSource={dataSource}
        isLoading={isLoadingDonorsRanking}
        isError={isErrorDonorsRanking}
        pagination={false}
      />

      {isFetchingNextPage && (
        <Row justify="center" style={{ marginBlock: '1rem' }}>
          <BaseSpin />
        </Row>
      )}
    </div>
  )
}
