import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LandingPage } from '../../../container/benfeitor/pages/LandingPage';
import { LandingPageLayout } from '../../../container/benfeitor/layouts/landing-page-layout';
import RegisterAction from '../../../container/admin/pages/actions/registerAction/RegisterAction';

export function LandingPageRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/oracaofamilia`} component={RegisterAction} />
      <LandingPageLayout>
        <Route exact path={`${path}/:name/:id`} component={LandingPage} />
      </LandingPageLayout>
    </Switch>
  );
}
