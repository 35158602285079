import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface RegisterIntentionLeadProps {
  nome: string
  email: string
  telefone: string
  intencao: string
}

export function useRegisterIntentionLead() {
  const registerIntentionLead = useMutation({
    mutationFn: async ({ nome, email, telefone, intencao }: RegisterIntentionLeadProps) => {
      const formData = new FormData()

      formData.append('nome', nome)
      formData.append('email', email)
      formData.append('telefone', telefone)
      formData.append('intencao', intencao)
      formData.append('origem', '6') // -> HARDCODED !! -> ORIGEM "Consagração à São José"

      await DataService.post('/users/create-lead', formData)
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao concluir o cadastro.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return registerIntentionLead
}
