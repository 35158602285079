import { Container } from './styles'
import { SmBaseButton } from '../components/button/styles'
import { useHistory, useLocation } from 'react-router-dom'
import logosImage from '../../images/logos.svg'

export function PreBecomeGeneralForm() {
  const history = useHistory()
  const location = useLocation()

  return (
    <Container>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={logosImage} alt="Logo" />
        </div>
        <div className="text-wrapper">
          <p>
            Que alegria saber que você já administra um grupo do Exército de São Miguel no Whatsapp. Não temos dúvidas
            de que muitas pessoas já foram alcançadas por meio do seu sim.
          </p>
          <p>
            O <span>próximo passo é muito simples</span>: Para se tornar um GENERAL OFICIAL, basta validar a existência
            do seu grupo no Whatsapp respondendo às perguntas a seguir.
          </p>
          <p>Vamos juntos dar esse passo na vontade de Deus?</p>
        </div>
        <div className="button-wrapper">
          <SmBaseButton
            onClick={() => {
              history.push(`/membro-exercito/tornar-se-general-form${location.search}`)
            }}
          >
            QUERO DAR ESSE PASSO
          </SmBaseButton>
        </div>
      </div>
    </Container>
  )
}
