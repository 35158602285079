import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export function useGetUsersPerCampaign() {
  return useQuery({
    queryKey: ['usersByCampaign'],
    queryFn: () =>
      DataService.get(`admin/dash-board/users/users-per-campaign`).then((response) => {
        const data = response.data.usuarios_por_campanha
        const filteredData = data.filter((item) => item.campanha !== null)
        return filteredData
      }),
  })
}
