import { Form, FormInstance } from 'antd';
import React, { createContext, useContext, useReducer, ReactNode } from 'react';

export type PaymentMethodState = {
  activePaymentMethod: 'pix' | 'card' | 'boleto';
  pixQrCode: { msg: string; pix_qrcode: string; qr_code_base64: string } | null;
  copied: boolean;
  periodicidadeValue: 1 | 2;
  boletoData: any;
  form: FormInstance;
  activeValue: 20 | 60 | 100 | number;
};

type PaymentMethodAction =
  | { type: 'SET_ACTIVE_PAYMENT_METHOD'; payload: PaymentMethodState['activePaymentMethod'] }
  | { type: 'SET_PIX_QR_CODE'; payload: PaymentMethodState['pixQrCode'] }
  | { type: 'SET_COPIED'; payload: boolean }
  | { type: 'SET_PERIODICIDADE_VALUE'; payload: PaymentMethodState['periodicidadeValue'] }
  | { type: 'SET_BOLETO_DATA'; payload: unknown }
  | { type: 'SET_ACTIVE_VALUE'; payload: PaymentMethodState['activeValue'] };

const initialState: Omit<PaymentMethodState, 'form'> = {
  activePaymentMethod: 'card',
  pixQrCode: null,
  copied: false,
  periodicidadeValue: undefined!,
  boletoData: null,
  activeValue: 20,
};

function paymentMethodReducer(state: PaymentMethodState, action: PaymentMethodAction): PaymentMethodState {
  switch (action.type) {
    case 'SET_ACTIVE_PAYMENT_METHOD':
      return { ...state, activePaymentMethod: action.payload };
    case 'SET_PIX_QR_CODE':
      return { ...state, pixQrCode: action.payload };
    case 'SET_COPIED':
      return { ...state, copied: action.payload };
    case 'SET_PERIODICIDADE_VALUE':
      return { ...state, periodicidadeValue: action.payload };
    case 'SET_BOLETO_DATA':
      return { ...state, boletoData: action.payload };
    case 'SET_ACTIVE_VALUE':
      return {
        ...state,
        activeValue: action.payload,
      };
    default:
      return state;
  }
}

interface PaymentMethodContextType extends PaymentMethodState {
  dispatch: React.Dispatch<PaymentMethodAction>;
  copyToClipboard: (text: string) => void;
}

const PaymentMethodContext = createContext<PaymentMethodContextType | undefined>(undefined);

export function PaymentMethodProvider({ children }: { children: ReactNode }) {
  const [form] = Form.useForm<FormInstance>();
  const [state, dispatch] = useReducer(paymentMethodReducer, { ...initialState, form });

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      dispatch({ type: 'SET_COPIED', payload: true });
      setTimeout(() => dispatch({ type: 'SET_COPIED', payload: false }), 4000);
    });
  };

  return (
    <PaymentMethodContext.Provider value={{ ...state, dispatch, copyToClipboard }}>
      {children}
    </PaymentMethodContext.Provider>
  );
}

export const usePaymentMethod = () => {
  const context = useContext(PaymentMethodContext);

  if (!context) {
    throw new Error('usePaymentMethod must be used within a PaymentMethodProvider');
  }
  return context;
};
