import { useEffect, useRef } from 'react'
import { ConfigProvider } from 'antd'
import { useSelector } from 'react-redux'
import { themeObject } from '../../../styles/Themes/Admin/themeVariables'

export const useThemeWatcher = () => {
  const { theme } = useSelector((state) => ({
    theme: state.theme.theme,
  }))
  const root = useRef(document.querySelector(':root'))

  useEffect(() => {
    const html = root.current
    if (html) {
      html.setAttribute('data-no-transition', '')
      html.setAttribute('data-theme', theme)
      // remove transition after layout update
      requestAnimationFrame(() => {
        html.removeAttribute('data-no-transition')
      })
    }

    ConfigProvider.config({
      theme: {
        primaryColor: themeObject[theme].primary,
        infoColor: themeObject[theme].primary,
        successColor: themeObject[theme].success,
        errorColor: themeObject[theme].error,
        warningColor: themeObject[theme].warning,
      },
    })
  }, [theme])
}
