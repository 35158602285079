import { useState } from 'react'
import { Col, Row } from 'antd'
import moment from 'moment'
import { StyledMenuFinance, Label, ModalContent } from '../../../styles'

import BaseRadio from '../../../../../components/radio/BaseRadio'
import BaseDatePicker from '../../../../../components/datepicker/BaseDatePicker'
import { StyledFilterIcon } from '../../../../../components/FilterIcon'
import { MultipleSelect } from '../../../../../components/multiple-select/multiple-select'
import { BaseSelect } from '../../../../../components/select/BaseSelect'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import BaseModal from '../../../../../components/modal/BaseModal'

export function FilterModal({ filters, setFilters, campaignsOptions, isModalOpen, setIsModalOpen }) {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const paymentMethodOptions = [
    { value: '', label: 'Todos' },
    { value: '1', label: 'Cartão de Crédito' },
    { value: '2', label: 'Pix' },
    { value: '3', label: 'Boleto' },
  ]

  const isFilterActive = Boolean(filters?.metodo_pagamento) || filters?.campanha?.length > 0 || filters?.data_inicio

  const campaignsOptionsFormatted = campaignsOptions
    .map((item) => ({ value: item.id, label: item.titulo }))
    .sort((a, b) => a.label.localeCompare(b.label))
  return (
    <BaseModal
      title="Filtros das Doações do Dia"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      closeIcon="X"
      size="large"
    >
      <ModalContent>
        <div>
          <Label>Visualizar por:</Label>
          <BaseRadio.Group onChange={(e) => handleInputChange({ visualizar_por: e.target.value })}>
            <BaseRadio.Button value="" defaultChecked>
              Total
            </BaseRadio.Button>
            <BaseRadio.Button value="campanha">Campanha</BaseRadio.Button>
          </BaseRadio.Group>
        </div>

        {filters?.visualizar_por !== 'campanha' && (
          <>
            <div>
              <Label>Método de Pagamento:</Label>
              <BaseSelect
                width="11rem"
                dropdownRender={(menu) => <StyledMenuFinance>{menu}</StyledMenuFinance>}
                allowClear
                options={paymentMethodOptions}
                value={filters?.metodo_pagamento || ''}
                onChange={(value) => handleInputChange({ metodo_pagamento: value })}
              />
            </div>
            <div>
              <Label>Campanhas:</Label>
              <MultipleSelect
                list={campaignsOptionsFormatted}
                options={campaignsOptionsFormatted}
                value={filters?.campanha}
                onChange={(value) => handleInputChange({ campanha: value })}
              />
            </div>
          </>
        )}
      </ModalContent>
      <Row justify="center" style={{ marginTop: '1rem' }}>
        <Col xs={13}>
          <Label>Data:</Label>
          <BaseDatePicker
            type="day"
            style={{ width: '100%' }}
            defaultValue={moment()}
            onChange={(dates, dateStrings) => {
              const formattedStartDate = moment(dateStrings, 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
              handleInputChange({
                data_inicio: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
              })
            }}
            s
            format="DD/MM/YYYY"
            placeholder={['Inicial', 'Final']}
          />
        </Col>
      </Row>
    </BaseModal>
  )
}
