export function AdminMenuAgape() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 47.523 47.533">
        <g id="hand-heart-svgrepo-com" transform="translate(1.5 0)">
          <path
            id="Caminho_5"
            data-name="Caminho 5"
            d="M14.9,18l-.98,1.344Zm4.1-12.636L17.88,6.594a1.664,1.664,0,0,0,2.252,0ZM23.11,18l.98,1.344Zm-4.1,1.938v1.664h0ZM15.881,16.66a26.751,26.751,0,0,1-4.4-3.9A6.436,6.436,0,0,1,9.578,8.937H6.25A9.6,9.6,0,0,0,8.963,14.93a30.009,30.009,0,0,0,4.958,4.419Zm-6.3-7.723c0-2.348,1.154-3.688,2.541-4.155,1.446-.487,3.6-.172,5.761,1.811l2.252-2.45C17.3,1.539,13.909.669,11.057,1.628,8.147,2.608,6.25,5.347,6.25,8.937ZM24.09,19.349a30.013,30.013,0,0,0,4.958-4.419,9.6,9.6,0,0,0,2.713-5.993H28.434a6.436,6.436,0,0,1-1.905,3.819,26.751,26.751,0,0,1-4.4,3.9ZM31.761,8.937c0-3.591-1.9-6.329-4.807-7.309C24.1.669,20.713,1.539,17.88,4.144l2.252,2.45c2.158-1.983,4.315-2.3,5.761-1.811,1.388.467,2.541,1.806,2.541,4.155ZM13.921,19.349c1.675,1.221,3,2.258,5.085,2.258V18.279c-.81,0-1.254-.256-3.125-1.619ZM22.13,16.66c-1.871,1.363-2.315,1.619-3.125,1.619v3.328c2.081,0,3.41-1.037,5.085-2.258Z"
            transform="translate(3.178 -1.249)"
          />
          <path
            id="Caminho_6"
            data-name="Caminho 6"
            d="M5,28.172h5.013a33.343,33.343,0,0,1,6.691.683,32.949,32.949,0,0,0,11.825.26A20.06,20.06,0,0,0,34.055,27.5a20.319,20.319,0,0,0,4.708-2.974,24.325,24.325,0,0,0,3.529-4.6,2.842,2.842,0,0,0-.857-3.833,4.187,4.187,0,0,0-4.925,0L32.5,19.121A12.013,12.013,0,0,1,27.23,21.7q-.365.058-.764.1m0,0-.243.027m.243-.027a2.214,2.214,0,0,0,.946-.532,3.31,3.31,0,0,0,.279-4.735,4.214,4.214,0,0,0-1-.815C20.488,12.02,10.833,14.839,5,18.976M26.466,21.8a1.163,1.163,0,0,1-.243.027m0,0a20.575,20.575,0,0,1-4.026.008"
            transform="translate(1.655 14.286)"
            fill="none"
            strokeLinecap="round"
            strokeWidth={3}
          />
          <rect
            id="Ret\xE2ngulo_6"
            data-name="Ret\xE2ngulo 6"
            width={6.655}
            height={17.747}
            rx={1.5}
            transform="translate(0 28.286)"
            fill="none"
            strokeWidth={3}
          />
        </g>
      </svg>
    </div>
  )
}
