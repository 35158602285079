import React from 'react'
import { CardWrapper, TitleWrapper, SubtitleWrapper, BalanceWrapper } from './styles'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

interface DashboardCardProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

interface TitleProps {
  children: React.ReactNode
}

interface SubtitleProps {
  children: React.ReactNode
}

interface BalanceProps {
  children: React.ReactNode
  isPositive?: boolean
}

export function ComposedCard({ children, style }: DashboardCardProps) {
  return (
    <CardWrapper style={style}>
      {children}
    </CardWrapper>
  )
}

ComposedCard.Title = function Title({ children }: TitleProps) {
  return (
    <TitleWrapper>
      {children}
    </TitleWrapper>
  )
}

ComposedCard.Subtitle = function Subtitle({ children }: SubtitleProps) {
  return (
    <SubtitleWrapper>
      {children}
    </SubtitleWrapper>
  )
}

ComposedCard.Balance = function Balance({ children, isPositive = true }: BalanceProps) {
  return (
    <BalanceWrapper className={`${isPositive ? 'positive' : 'negative'} balance-value`}>
      {isPositive ? <CaretUpOutlined /> : <CaretDownOutlined />}{" "}
      {children}
    </BalanceWrapper>
  )
} 