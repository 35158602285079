import { useState } from 'react'
import BaseCard from '../../../../components/card/BaseCard'
import { TabelaVoluntarios } from './Tabela/TabelaVoluntarios'

export function ListaVoluntarios() {
  const [numVoluntarios, setNumVoluntarios] = useState<number | null>(null)

  const message = numVoluntarios ? `- ${numVoluntarios ?? ' - '} cadastrados` : ''

  return (
    <BaseCard title={`Voluntários ${message}`}>
      <TabelaVoluntarios setNumVoluntarios={setNumVoluntarios} />
    </BaseCard>
  )
}
