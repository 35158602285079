import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useResetPassword() {
  const resetPassword = useMutation({
    mutationFn: async ({ new_password, token }: { new_password: string; token: string }) => {
      await DataService.post(`/auth/new-password?token=${token}`, { new_password })
    },
    onSuccess: () => {
      notification.success({
        message: 'Sucesso',
        description: 'Senha redefinida.',
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao redefinir a senha',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })
  return resetPassword
}
