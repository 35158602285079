import { BaseTable } from '../../../../../../components/table/BaseTable/BaseTable'
import {
  formatToBrazilianCurrency,
  handleCpfShowValues,
  handlePhoneNumberFormat,
} from '../../../../../../../../utility/utility'
import { Row } from 'antd'
import BaseButton from '../../../../../../components/button/BaseButton'
import { GetMembrosFamiliaResponse } from '../../../../../../hooks/agape/queries/useGetMembrosFamilia'
import { Asterisk, PlusCircle } from '@phosphor-icons/react'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export interface MembrosFamiliaTableProps {
  lista: GetMembrosFamiliaResponse['membros'] | undefined
  showDrawer: ({ acao, idMembro }: { acao: 'editar' | 'adicionar'; idMembro?: number }) => void
}

export function MembrosFamiliaTabela({ lista, showDrawer }: MembrosFamiliaTableProps) {
  const dataSource = lista?.map((item) => {
    const { id, cpf, email, idade, nome, ocupacao, renda, telefone, responsavel } = item

    return {
      key: id,
      id,
      cpf: handleCpfShowValues(cpf),
      email,
      idade,
      nome: (
        <Row justify="start">
          <BaseButton type="link" onClick={() => showDrawer({ acao: 'editar', idMembro: id })}>
            {<Asterisk style={{ opacity: responsavel ? 1 : 0 }} />} {nome}
          </BaseButton>
        </Row>
      ),
      ocupacao,
      telefone: handlePhoneNumberFormat(telefone),
      renda: renda && formatToBrazilianCurrency(renda),
    }
  })

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      align: 'center',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      align: 'center',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      align: 'center',
    },
    {
      title: 'Idade',
      dataIndex: 'idade',
      key: 'idade',
      align: 'center',
    },
    {
      title: 'Ocupação',
      dataIndex: 'ocupacao',
      key: 'ocupacao',
      align: 'center',
    },
    {
      title: 'Renda',
      dataIndex: 'renda',
      key: 'renda',
      align: 'center',
      render: (value: string) => <BlurDiv>{value}</BlurDiv>,
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      footer={() => (
        <Row justify="center">
          <BaseButton severity="info" onClick={() => showDrawer({ acao: 'adicionar' })}>
            <PlusCircle size={30} />
          </BaseButton>
        </Row>
      )}
    />
  )
}
