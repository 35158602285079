import styled from "styled-components";

export const BannerLandingStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  justify-content: center;
  background: ${({ banner, showShadow }) => `
    ${showShadow ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%),' : ''}
    url(${banner})
  `};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 350px;

  @media (min-width: 1920px) {
    min-height: 400px;
  }

  @media (max-width: 768px) {
    min-height: 250px;
  }

  @media (max-width: 586px) {
    min-height: 200px;
  }

  .title-landing {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    text-shadow: 2px 2px 2px #4a4a4a;
    @media (max-width: 586px) {
      font-size: 24px !important;
    }

    @media (max-width: 768px) {
      font-size: 38px;
    }
  }

  .description-landing {
    margin-top: 20px;
    font-family: Montserrat;

    h1,
    h2,
    h3,
    p {
      color: #fff;
      font-weight: normal;
      text-shadow: 1px 1px 1px #4a4a4a;
      @media (max-width: 586px) {
        font-size: 18px !important;
      }

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    text-align: center;
  }
`
