import React, { lazy } from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
import { DonationsList } from '../../container/admin/pages/donations/donations-list'
import RecurringDonations from '../../container/admin/pages/donations/recurrence'
import UnpaidRecurrence from '../../container/admin/pages/donations/recurrence/components/unpaid-recurrence'
import DesconsideredDonations from '../../container/admin/pages/donations/desconsidered-donations'
import MainDonors from '../../container/admin/pages/donations/main-donors'

export const Donations = () => {
  const { path } = useRouteMatch<{ path: 'requests' }>()
  return (
    <Switch>
      <Route path={`${path}/view-all`} component={DonationsList} />
      <Route path={`${path}/view-donor-classification`} component={MainDonors} />
      <Route path={`${path}/view-all-recurrence`} component={RecurringDonations} />
      <Route path={`${path}/view-desconsidered`} component={DesconsideredDonations} />
      <Route path={`${path}/unpaid-recurrence`} component={UnpaidRecurrence} />
    </Switch>
  )
}
