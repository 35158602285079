import { Form, Row } from 'antd'
import BaseCard from '../../../../components/card/BaseCard'
import { FormularioResponsavel } from './FormularioResponsavel'
import BaseButton from '../../../../components/button/BaseButton'
import { PlusCircle, TrashSimple } from '@phosphor-icons/react'

type ListaFormularioResponsavelProps = {
  hasInitialValue: boolean
}

export function ListaFormularioResponsavel({ hasInitialValue }: ListaFormularioResponsavelProps) {
  return (
    <Form.List name="responsaveis" initialValue={hasInitialValue ? [{}] : undefined}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <BaseCard
              size="small"
              key={field.key}
              title={<h5> Responsável {index + 1}</h5>}
              extra={
                ((index > 0 && hasInitialValue) || !hasInitialValue) && (
                  <BaseButton
                    type="text"
                    onClick={() => remove(field.name)}
                    style={{ color: 'var(--text-main-color)' }}
                  >
                    <TrashSimple size={25} />
                  </BaseButton>
                )
              }
            >
              <FormularioResponsavel fieldName={field.name} />
            </BaseCard>
          ))}
          <Row justify="center" style={{ marginTop: 16 }}>
            <BaseButton severity="info" onClick={() => add({})}>
              {!hasInitialValue && 'Adicionar responsável'} <PlusCircle size={30} />
            </BaseButton>
          </Row>
        </>
      )}
    </Form.List>
  )
}
