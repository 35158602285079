export function AdminMenuVocacional() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 32.699 43.364">
        <g id="rosary-svgrepo-com" transform="translate(-7.337)">
          <path
            id="Caminho_13"
            data-name="Caminho 13"
            d="M30.192,44.8l2.47-1.4a.727.727,0,0,0,.273-.99l-1.762-3.1a.726.726,0,0,0-.993-.271l-2.436,1.4-1.41-2.484a.725.725,0,0,0-.988-.275l-3.141,1.764a.727.727,0,0,0-.276.993l1.411,2.487-2.472,1.4a.727.727,0,0,0-.273.99l1.762,3.1a.727.727,0,0,0,.99.273l2.472-1.4,3.11,5.479a1.73,1.73,0,0,0,2.36.652l1.381-.784a1.732,1.732,0,0,0,.647-2.367Z"
            transform="translate(-3.598 -10.271)"
          />
          <path
            id="Caminho_14"
            data-name="Caminho 14"
            d="M24.244,5.813a2.907,2.907,0,1,0-2.907-2.907A2.91,2.91,0,0,0,24.244,5.813Z"
            transform="translate(-3.827)"
          />
          <path
            id="Caminho_15"
            data-name="Caminho 15"
            d="M33.244,6.813a2.907,2.907,0,1,0-2.907-2.907A2.91,2.91,0,0,0,33.244,6.813Z"
            transform="translate(-6.287 -0.273)"
          />
          <path
            id="Caminho_16"
            data-name="Caminho 16"
            d="M42.244,8.813a2.907,2.907,0,1,0-2.907-2.907A2.91,2.91,0,0,0,42.244,8.813Z"
            transform="translate(-8.747 -0.82)"
          />
          <path
            id="Caminho_17"
            data-name="Caminho 17"
            d="M47.244,10a2.907,2.907,0,1,0,2.907,2.907A2.91,2.91,0,0,0,47.244,10Z"
            transform="translate(-10.114 -2.734)"
          />
          <path
            id="Caminho_18"
            data-name="Caminho 18"
            d="M44.244,18a2.907,2.907,0,1,0,2.907,2.907A2.91,2.91,0,0,0,44.244,18Z"
            transform="translate(-9.294 -4.92)"
          />
          <path
            id="Caminho_19"
            data-name="Caminho 19"
            d="M37.244,24a2.907,2.907,0,1,0,2.907,2.907A2.91,2.91,0,0,0,37.244,24Z"
            transform="translate(-7.381 -6.56)"
          />
          <path
            id="Caminho_20"
            data-name="Caminho 20"
            d="M27.337,32.907A2.907,2.907,0,1,0,30.244,30,2.91,2.91,0,0,0,27.337,32.907Z"
            transform="translate(-5.467 -8.201)"
          />
          <path
            id="Caminho_21"
            data-name="Caminho 21"
            d="M16.244,8.813a2.907,2.907,0,1,0-2.907-2.907A2.91,2.91,0,0,0,16.244,8.813Z"
            transform="translate(-1.64 -0.82)"
          />
          <path
            id="Caminho_22"
            data-name="Caminho 22"
            d="M11.244,15.813a2.907,2.907,0,1,0-2.907-2.907A2.91,2.91,0,0,0,11.244,15.813Z"
            transform="translate(-0.273 -2.734)"
          />
          <path
            id="Caminho_23"
            data-name="Caminho 23"
            d="M13.15,21.907a2.907,2.907,0,1,0-2.907,2.907A2.91,2.91,0,0,0,13.15,21.907Z"
            transform="translate(0 -5.194)"
          />
          <path
            id="Caminho_24"
            data-name="Caminho 24"
            d="M15.15,30.907a2.907,2.907,0,1,0-2.907,2.907A2.91,2.91,0,0,0,15.15,30.907Z"
            transform="translate(-0.547 -7.654)"
          />
          <path
            id="Caminho_25"
            data-name="Caminho 25"
            d="M16.244,36a2.907,2.907,0,1,0,2.907,2.907A2.91,2.91,0,0,0,16.244,36Z"
            transform="translate(-1.64 -9.841)"
          />
        </g>
      </svg>
    </div>
  )
}
