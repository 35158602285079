import { MinusSquare, PlusSquare } from '@phosphor-icons/react'

import BaseModal from '../../../../../../../components/modal/BaseModal'
import { BaseCollapse } from '../../../../../../../components/collapse/BaseCollapse'
import { EnderecoFamilia } from './EnderecoFamilia/EnderecoFamilia'
import { MembrosFamiliaModal } from './MembrosFamilia'
import { HistoricoRecebimentos } from './HistoricoRecebimentos'
import { InformacoesFamilia } from '../FamiliasCadastradasTabela'
import { ObservacoesFamilia } from './InformacoesFamilia/ObservacoesFamilia'

export type DetalhesDaFamiliaModalProps = {
  visible: boolean
  onClose: () => void
  idFamilia: number
  informacoes: InformacoesFamilia | null
}

export function DetalhesDaFamiliaModal({ visible, onClose, idFamilia, informacoes }: DetalhesDaFamiliaModalProps) {
  return (
    <BaseModal title="Detalhes da família" open={visible} onCancel={onClose} footer={null} width={1000}>
      <BaseCollapse
        accordion
        ghost
        expandIcon={({ isActive }: { isActive: boolean }) =>
          isActive ? <MinusSquare size={20} /> : <PlusSquare size={20} />
        }
      >
        <BaseCollapse.Panel header="Informações da família" key={`informacoes_da_familia_${idFamilia}`}>
          <ObservacoesFamilia foto={informacoes?.foto} observacao={informacoes?.observacao} />
        </BaseCollapse.Panel>
        <BaseCollapse.Panel header="Endereço da família" key={`endereco_da_familia_${idFamilia}`}>
          <EnderecoFamilia idFamilia={idFamilia} />
        </BaseCollapse.Panel>
        <BaseCollapse.Panel header="Membros da família e Dados Socioeconômicos" key={`membros_familia_${idFamilia}`}>
          <MembrosFamiliaModal idFamilia={idFamilia} />
        </BaseCollapse.Panel>
        <BaseCollapse.Panel header="Histórico de recebimentos" key={`historico_recebimentos_${idFamilia}`}>
          <HistoricoRecebimentos idFamilia={idFamilia} />
        </BaseCollapse.Panel>
      </BaseCollapse>
    </BaseModal>
  )
}
