import * as S from './styles';
// eslint-disable-next-line import/no-absolute-path
import LogoHesed from '../../../../../../static/img/logo_hesed.png';
import { useHistory, useLocation } from 'react-router-dom';
import { DropdownMenuHeader } from './DropdownMenuHeader';
import { LoginModal } from './Modals/LoginModal';
import { SignUpModal } from './Modals/SignUpModal';
import { UpdateRegistration } from './Modals/UpdateRegistration';
import { openLoginModal, openSignUpModal } from './Modals/authModalsSlice';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../../../context/Auth';

export function HeaderBenfeitor() {
  const {
    userAuthState: { isAuthenticated },
  } = useAuth();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const hideAuthModalsPaths = ['/carteira/primeira-etapa'];
  const hideAuthModals = hideAuthModalsPaths.includes(location.pathname);

  const hideUpdateModalPaths = ['/identificacao'];
  const hideUpdateModal = hideUpdateModalPaths.includes(location.pathname);

  // useEffect(() => {
  //   if (hideUpdateModal) {
  //     dispatch(closeUpdateModal());
  //   }

  //   if (hideAuthModals) {
  //     dispatch(closeModals());
  //   }
  // }, [dispatch, location, hideUpdateModal, hideAuthModals]);

  return (
    <>
      <S.StyledHeader>
        <S.Logo>
          <a onClick={() => history.push('/')}>
            <img src={LogoHesed} alt="Instituto Hesed" />
          </a>
        </S.Logo>

        <S.ActionsHeaderWrapper>
          {isAuthenticated ? (
            <DropdownMenuHeader />
          ) : (
            <>
              {!hideAuthModals && (
                <>
                  <S.HeaderButton onClick={() => dispatch(openSignUpModal())}>Cadastre-se</S.HeaderButton>
                  <S.HeaderButton onClick={() => dispatch(openLoginModal())}>Entrar</S.HeaderButton>
                </>
              )}
            </>
          )}
        </S.ActionsHeaderWrapper>
      </S.StyledHeader>
      <LoginModal />
      <SignUpModal />
      {!hideUpdateModal && <UpdateRegistration />}
    </>
  );
}
