import { Card, Image } from 'antd'
import { CardDescriptionContainer, CardTitleContainer, StyledCard, StyledCardContainer } from '../../styles'
import { useLocation, useParams } from 'react-router-dom'
import { LocationState } from '../..'
import { useFetchSingleCampaign } from '../../../../../../admin/hooks'

export function CampaignCard() {
  const { campaignId } = useParams<{ campaignId: string }>()
  const { data: campaignData } = useFetchSingleCampaign(campaignId)

  return (
    <StyledCardContainer>
      <StyledCard cover={<Image alt="Campaign" src={campaignData?.filename} />}>
        <Card.Meta
          title={<CardTitleContainer>{campaignData?.titulo}</CardTitleContainer>}
          description={<CardDescriptionContainer>{campaignData?.descricao}</CardDescriptionContainer>}
        />
      </StyledCard>
    </StyledCardContainer>
  )
}
