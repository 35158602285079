import { Image, Row } from 'antd'
import { useState } from 'react'
import BaseButton from '../../../../../../components/button/BaseButton'
import { Eye } from '@phosphor-icons/react'
import BaseModal from '../../../../../../components/modal/BaseModal'

interface VisualizarFotoObservacaoProps {
  foto: string
}

export function VisualizarFotoObservacao({ foto }: VisualizarFotoObservacaoProps) {
  const [isOpen, setIsOpen] = useState(false)

  function handleOpenModal() {
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Row justify="center">
        <BaseButton type="text" onClick={handleOpenModal}>
          <Eye size={20} />
        </BaseButton>
      </Row>

      <BaseModal title={<h3>Foto observação</h3>} open={isOpen} onCancel={handleOpenModal} footer={false}>
        <Row justify="center">
          <Image src={foto} width={400} height={400} />
        </Row>
      </BaseModal>
    </>
  )
}
