import { GoldenHeader } from '../../../components/golden-header/styles'
import { FourthSectionStyled } from './styles'

export function FourthSection() {
  return (
    <FourthSectionStyled>
      <div className="card">
        <GoldenHeader className="header">Um chamado de Deus</GoldenHeader>
        <div style={{ width: '100%' }}>
          <p>Como General Oficial, você será:</p>
        </div>
        <ul>
          <li>
            Um <span>farol de esperança</span> para outros;
          </li>

          <li>
            <span>Membro de uma comunidade exclusiva</span> de Generais do Exército de São Miguel;
          </li>

          <li>
            Um <span>multiplicador</span> das graças de Deus
          </li>
        </ul>
      </div>
    </FourthSectionStyled>
  )
}
