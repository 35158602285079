import { http, HttpResponse } from 'msw'
import { Documento } from '../../container/alistamento-sm/pages/validate-user'

export const buscarUsuarioPorNumeroDocumentoMock = http.post<
  never,
  Documento,
  { email: string | null; general: boolean; usuario: boolean }
>('/users/buscar-usuario-por-numero-documento', async ({ request }) => {
  const { numero_documento, tipo_documento } = await request.json()

  if (numero_documento === '864.846.290-80' && tipo_documento === 'cpf') {
    return HttpResponse.json({
      email: null,
      general: false,
      usuario: false,
    })
  }

  if (numero_documento === '634643643' && tipo_documento === 'identidade_estrangeira') {
    return HttpResponse.json({
      email: null,
      general: false,
      usuario: false,
    })
  }

  if (numero_documento === '085.868.970-78' && tipo_documento === 'cpf') {
    return HttpResponse.json({
      email: 'usuario-benfeitor@gmail.com',
      general: false,
      usuario: true,
    })
  }

  if (numero_documento === '6436317654' && tipo_documento === 'identidade_estrangeira') {
    return HttpResponse.json({
      email: 'usuario-benfeitor-estrangeiro@gmail.com',
      general: false,
      usuario: true,
    })
  }

  if (numero_documento === '416.467.770-85' && tipo_documento === 'cpf') {
    return HttpResponse.json({
      email: 'usuario-already-general@gmail.com',
      general: true,
      usuario: true,
    })
  }
})
