import styled from 'styled-components'
import validationPageBg from '../../images/validation-page-bg.png'

export const Container = styled.div`
  background-image: url(${validationPageBg});
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;

  padding: 2rem;

  .container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 900px) {
      gap: 3rem;
    }
  }
`

export const LogoStyled = styled.img`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`
