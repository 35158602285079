import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface RemoverVoluntarioProps {
  fk_usuario_id: number
}

export function useRemoverVoluntario() {
  const queryClient = useQueryClient()

  const removerVoluntario = useMutation({
    mutationFn: async ({ fk_usuario_id }: RemoverVoluntarioProps) => {
      await DataService.delete(`/agape/remover-voluntario-agape/${fk_usuario_id}`)
    },
    onSuccess: () => {
      notification.success({ message: 'Usuário removido com sucesso' })
      queryClient.invalidateQueries({ queryKey: ['LISTAGEM_VOLUNTARIOS'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao remover o voluntário',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return removerVoluntario
}
