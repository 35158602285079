export function AdminMenuSair() {
  return (
    <div className="ADMIN-MENU-ICON">
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 39.835">
        <g id="off-on-power-svgrepo-com" transform="translate(-5.997)">
          <path
            id="Caminho_2069"
            data-name="Caminho 2069"
            d="M44.483,14.9a2.481,2.481,0,0,0,2.483-2.483V2.483a2.483,2.483,0,0,0-4.965,0v9.93A2.481,2.481,0,0,0,44.483,14.9Z"
            transform="translate(-21.106 0)"
            fill="var(--text-main-color)"
          />
          <path
            id="Caminho_2070"
            data-name="Caminho 2070"
            d="M34.965,22.074a2.483,2.483,0,1,0-3.317,3.695,12.161,12.161,0,0,1,4.141,9.142,12.413,12.413,0,1,1-24.826,0,12.161,12.161,0,0,1,4.142-9.143,2.483,2.483,0,1,0-3.317-3.694,17.379,17.379,0,1,0,23.177,0Z"
            transform="translate(0 -12.568)"
            fill="var(--text-main-color)"
          />
        </g>
      </svg>
    </div>
  )
}
