import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import BaseModal from '../../../../components/modal/BaseModal'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import { handleFilterChangeAndPagination, handleUnpaidRecurrenceStatus } from '../../../../../../utility/utility'
import { BaseNotification } from '../../../../components/notification/BaseNotification'
import { useFetchUnpaidRecurrencesByDonation } from '../../../../hooks/donations/queries/useFetchUnpaidRecurrencesDetails'
import { useCancelRecurrence } from '../../../../hooks/donations/mutations/useCancelRecurrence'
import BaseButton from '../../../../components/button/BaseButton'

export function UserRecurrenceDetails({
  pedidoId,
  setPedidoId,
}: {
  pedidoId: number | undefined
  setPedidoId: React.Dispatch<React.SetStateAction<number | undefined>>
}) {
  const [filters, setFilters] = useState()
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })
  const { mutateAsync: cancelRecurrence } = useCancelRecurrence()

  const detailsColumn = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'T.id',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      align: 'center',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      align: 'center',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
    },
    {
      title: 'Método',
      dataIndex: 'metodo',
      key: 'metodo',
      align: 'center',
    },
  ]

  const {
    data: details,
    isPending: isPendingTransactionDetails,
    isError: isErrorTransactionDetails,
  } = useFetchUnpaidRecurrencesByDonation(filters, pedidoId)

  const detailsDataSource = details?.transacoes?.map((transacao) => {
    const { id, transaction_id, data, valor, status, metodo } = transacao
    return {
      id,
      transaction_id: transaction_id && (
        <FeatherIcon
          icon="copy"
          size={20}
          style={{ margin: '0 auto', cursor: 'pointer', color: '#5a5f7d' }}
          onClick={() => {
            navigator.clipboard
              .writeText(transaction_id)
              .then(() => {
                BaseNotification.success({
                  message: <div>Código de Referência Copiado</div>,
                  description: (
                    <div>
                      <div>{transaction_id}</div>
                    </div>
                  ),
                })
              })
              .catch((error) => {
                BaseNotification.error({
                  message: <div style={{ fontWeight: 'bold', color: 'red' }}>Ocorreu um erro...</div>,
                })
                console.error('Falha ao copiar o valor para a área de transferência:', error)
              })
          }}
        />
      ),
      data,
      valor,
      status: handleUnpaidRecurrenceStatus(status),
      metodo,
    }
  })

  return (
    <BaseModal
      title="Transações"
      open={!!pedidoId}
      onOk={() => setPedidoId(undefined)}
      onCancel={() => setPedidoId(undefined)}
      size="xxl"
      footer={[
        <BaseButton
          severity="error"
          onClick={() => {
            if (pedidoId) {
              cancelRecurrence(pedidoId)
              setPedidoId(undefined)
            }
          }}
        >
          Cancelar recorrência
        </BaseButton>,
      ]}
    >
      <BaseTable
        columns={detailsColumn}
        dataSource={detailsDataSource}
        isPending={isPendingTransactionDetails}
        isError={isErrorTransactionDetails}
        pagination={{
          current: details?.page,
          total: details?.total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        onChange={(pagination) => {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }}
      />
    </BaseModal>
  )
}
