import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface Transacao {
  data: string
  id: number
  metodo: string
  status: number
  transaction_id: string | null
  valor: string
}

interface TransacoesResponse {
  page: number
  pages: number
  total: number
  transacoes: Transacao[]
}

export function useFetchUnpaidRecurrencesByDonation(filters: any, pedidoId: number | undefined) {
  const unpaidRecurrencesByDonation = useQuery<TransacoesResponse>({
    queryKey: ['TRANSACTION_DETAILS', pedidoId, filters],
    queryFn: async () => {
      const query = await DataService.get<TransacoesResponse>(
        `checkout/transacoes/${pedidoId}?${filters?.queryString || ''}`,
      )
      return query?.data
    },
    enabled: !!pedidoId,
  })

  return unpaidRecurrencesByDonation
}
