import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BaseNotification } from '../components/notification/BaseNotification'

export function useMultistepForm(steps, form, formData) {
  const { capaImage, backgroundImage, bannerImage } = useSelector((state) => state.form)

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  async function next() {
    try {
      await form.validateFields()

      setCurrentStepIndex((i) => {
        if (i >= steps.length - 1) return i
        return i + 1
      })
    } catch (error) {
      console.error('Validation failed:', error)
    }
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) return
      return i - 1
    })
  }

  function goTo(index) {
    setCurrentStepIndex(index)
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  }
}
