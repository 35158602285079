import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

const ContentWrapper = styled(Content)`
  padding-bottom: 72px;
  @media (min-width: 576px) {
    margin: 0 auto;
    max-width: 570px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const ContainerWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (max-width: 576px) {
    padding: 0px 5px;
  }
`;

const InstitutionalHeaderTittle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  color: ${({ theme }) => theme.colors['gray-800']};
  font-weight: 900;
`;

const SectionWrapper = styled.section`
  background-color: ${(props) => props.theme.colors['gray-200']};
  padding: 20px 50px;
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 5px;
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f7f7f7;
`;

const Header = styled.header`
  background-color: #333;
  color: #fff;
  padding: 10px 0;
`;

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
`;

const Section = styled.section`
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const AboutSection = styled(Section)`
  background-color: #f9f9f9;
`;

const Footer = styled.footer`
  text-align: center;
  padding: 10px 0;
  background-color: #333;
  color: #fff;
`;

export {
  Container,
  Header,
  Nav,
  AboutSection,
  Footer,
  Section,
  ContentWrapper,
  ContainerWrapper,
  InstitutionalHeaderTittle,
  SectionWrapper,
};
