import { useState } from 'react'
import { Content } from './Content'
import { FilterModal } from './FilterModal'
import BaseCard from '../../../../../components/card/BaseCard'

export function DonationsPerMonth() {
  const [filters, setFilters] = useState()

  const [campaignsOptions, setCampaignsOptions] = useState([])

  return (
    <BaseCard padding="0px">
      <FilterModal filters={filters} setFilters={setFilters} campaignsOptions={campaignsOptions} />
      <Content filters={filters} setCampaignsOptions={setCampaignsOptions} />
    </BaseCard>
  )
}
