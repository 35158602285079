import React from 'react';
import { CardContainer, IconContainer, TextContainer, ValueContainer } from './styles';
interface PaymentCardProps {
  icon?: React.ReactElement;
  text?: string;
  active: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
  value?: number | string;
}

export function PaymentCard({ icon, text, active, onClick, style, value, textFontSize }: PaymentCardProps) {
  const iconWeight = active ? 'regular' : 'fill';

  return (
    <CardContainer onClick={onClick} active={active} style={style}>
      <IconContainer active={active}>
        <TextContainer active={active}>{text}</TextContainer>

        {icon && (
          <div style={{ padding: '0.5rem 0.8rem' }}>{React?.cloneElement(icon, { weight: iconWeight, size: 40 })}</div>
        )}
      </IconContainer>
    </CardContainer>
  );
}
