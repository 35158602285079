import styled from "styled-components";

export const Title = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #999999;
  position: relative;
`;

export const ChartContainer = styled.div`
  height: 272px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  margin-bottom: 1.5rem;

  overflow: hidden;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
  color: var(--chart-pie-legend-color);

  &:hover {
    color: ${({ color }) => color};
  }

  span{
    white-space: nowrap;
  }
`;

export const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`;

export const Tooltip = styled.div`
  color: white;
  background: ${({ color }) => color};
  border: 1px solid #000;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0.9;
`;
