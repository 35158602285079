import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'
import { Membro } from '../../../pages/agape/Familia/CadastrarFamilia/FormularioCadastrarFamilia'

type EditarMembroProps = {
  membro: Membro & {
    responsavel: boolean
  }
  idMembro: number
}

export function useEditarMembro() {
  const queryClient = useQueryClient()

  const editarMembro = useMutation({
    mutationFn: async ({ idMembro, membro }: EditarMembroProps) => {
      await DataService.put(`agape/editar-membro/${idMembro}`, { ...membro })
    },
    onSuccess: (_, { membro }) => {
      const usuarioResponsavel = membro.responsavel

      notification.success({ message: `${usuarioResponsavel ? 'Responsável' : 'Membro'} editado com sucesso!` })
      queryClient.invalidateQueries({ queryKey: ['GET_MEMBROS_FAMILIA'] })
      queryClient.invalidateQueries({ queryKey: ['ESTATISTICAS_FAMILIA'] })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao editar o membro.',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })

  return editarMembro
}
