import styled from 'styled-components';

import Particula from '../../../../../static/img/particula_plano_de_fundo.png';

export const StyledContent = styled.div`
  margin-inline: auto;
  min-height: 80vh;
  width: 100%;
  max-width: 1120px;
  padding: 2.5rem;
`;

export const LayoutWrapperLandingPage = styled.div`
  background: url(${Particula}) repeat;
`;
