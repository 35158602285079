import { ComposedCard } from '@/container/admin/components/composed-card'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'
import { formatNumber } from '../../../../../../../utility/utility'
import { SliderChartTitle, TooltipContainer } from '../../../styles'

export function Chart({ data }) {
  const formattedData = Object.entries(data).map((item) => {
    const formattedDate = item?.[0]?.replace(/-/g, '/')
    const signups = item?.[1]

    return {
      date: formattedDate,
      signups,
    }
  })

  const CustomTooltip = ({ payload }) => {
    const cadastros = payload?.[0]?.payload?.signups
    const data = payload?.[0]?.payload?.date

    return (
      <TooltipContainer type="signup">
        <strong>{data}</strong>
        <strong>{`${formatNumber(cadastros)} Cadastros`}</strong>
      </TooltipContainer>
    )
  }

  const currentMonthAndYear = `${new Date()
    .toLocaleString('pt-BR', { month: 'long' })
    .replace(/^\w/, (c) => c.toUpperCase())} ${new Date().getFullYear()}`

  return (
    <>
      <div style={{ position: 'relative', height: 260 }}>
        <SliderChartTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div>
              <div className="title-and-icon-container">
                <div className="title">Cadastros - {currentMonthAndYear}</div>
                {/* <FilterIcon onClick={handleToggleModal} /> */}
              </div>
              <BlurDiv className="percentage">
                <ComposedCard.Balance isPositive={false} >
                  29.65%
                </ComposedCard.Balance>
              </BlurDiv>
            </div>
            <BlurDiv className="total-value">{formatNumber(12412)}</BlurDiv>
          </div>
        </SliderChartTitle>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={formattedData} margin={{ top: 90, right: 0, bottom: 0, left: 0 }}>
            {/* The following def id works for all charts */}

            <defs>
              <linearGradient id="signupChartGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="var(--signup-chart-color)" stopOpacity={0.8} />
                <stop offset="95%" stopColor="var(--signup-chart-color)" stopOpacity={0} />
              </linearGradient>
            </defs>

            <Area
              type="monotone"
              dataKey="signups"
              stroke="var(--signup-chart-color-stroke)"
              fill="url(#signupChartGradient)"
              strokeWidth={2}
              activeDot={(props) => {
                const { cx, cy } = props
                return (
                  <circle
                    cx={cx}
                    cy={cy}
                    r={8}
                    stroke="var(--signup-chart-color)"
                    strokeWidth={2}
                    fill="white"
                    fillOpacity={1}
                  />
                )
              }}
            />

            <Tooltip cursor={false} content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
