import styled from 'styled-components';

export const PeriodicityContainer = styled.div`
  margin-top: 2rem;

  .ant-radio-wrapper {
    font-size: 1rem;
    padding: 0rem 1rem 0.5rem 0rem;
  }

  .ant-radio-inner {
    border-color: #1f1f1f;
    border-width: 1.5px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #1f1f1f !important;
    color: #1f1f1f !important;
  }

  .ant-radio-inner::after {
    background-color: #1f1f1f;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    background-color: white;
  }
`;
