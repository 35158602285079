import { Row } from 'antd'
import BaseModal from '../../../../components/modal/BaseModal'
import { useState } from 'react'
import BaseButton from '../../../../components/button/BaseButton'
import { Eye } from '@phosphor-icons/react'
import { SliderRecibos } from './SliderRecibos'

interface VisualizarReciboModalProps {
  recibos: string[]
}

export function VisualizarReciboModal({ recibos }: VisualizarReciboModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  function handleOpenModal() {
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Row justify="center">
        <BaseButton type="text" onClick={handleOpenModal}>
          <Eye size={20} />
        </BaseButton>
      </Row>

      <BaseModal
        title={<h3>Visualizar recibo(s)</h3>}
        open={isOpen}
        onCancel={handleOpenModal}
        footer={false}
        width={1000}
      >
        <SliderRecibos recibos={recibos} />
      </BaseModal>
    </>
  )
}
