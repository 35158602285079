import { useState } from 'react'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import BaseModal from '../../../../../components/modal/BaseModal'
import { StyledMenuFinance, Label, ModalContent } from '../../../styles'
import { BaseSelect } from '../../../../../components/select/BaseSelect'
import { StyledFilterIcon } from '../../../../../components/FilterIcon'
import { paymentMethodOptions } from '../../../utils-constants'

export function FilterModal({
  filters,
  setFilters,
  campaignsOptions,
  isModalOpen,
  setIsModalOpen
}) {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const isFilterActive = Boolean(filters?.forma_pagamento) || filters?.fk_campanha_id

  const campaignsOptionsFormatted = campaignsOptions
    .map((item) => ({ value: item.id, label: item.titulo }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <>
      <BaseModal
        title="Filtros das Doações Recorrentes"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        closeIcon="X"
        size="large"
      >
        <ModalContent>
          <div>
            <Label>Método de Pagamento:</Label>
            <BaseSelect
              width="11rem"
              dropdownRender={(menu) => <StyledMenuFinance>{menu}</StyledMenuFinance>}
              allowClear
              options={paymentMethodOptions}
              value={filters?.forma_pagamento || ''}
              onChange={(value) => handleInputChange({ forma_pagamento: value })}
            />
          </div>
          <div>
            <Label>Campanhas:</Label>
            <BaseSelect
              width="15rem"
              allowClear
              options={campaignsOptionsFormatted}
              value={filters?.fk_campanha_id}
              onChange={(value) => handleInputChange({ fk_campanha_id: value })}
            />
          </div>
        </ModalContent>
      </BaseModal>
    </>
  )
}
