import { AnimatedChartWrapper } from '../dashboard/financesDashboard/components/AnimatedChartWrapper'
import { DashboardWrapper, LeftSection, RightSection } from '../dashboard/styles'
import { AnimatedCarousel } from './components/AnimatedCarousel'
import { MessageDeliveryRate } from './components/MessageDeliveryRate'
import { MessagesBarPerType } from './components/MessagesBarPerType'
import { MessagesByUserType } from './components/MessagesByUserType'
import { MessagesCountByHour } from './components/MessagesCountByHour'
import { MessagesPerCategory } from './components/MessagesPerCategory'
import { MessagesPerDayOfWeek } from './components/MessagesPerDayOfWeek'
import { MessagesPerMonth } from './components/MessagesPerMonth'
import { MessagesSummary } from './components/MessagesSummary'
import { TodayMessagesList } from './components/TodayMessagesList'

export function Mensageria(): JSX.Element {
  return (
    <DashboardWrapper>
      <LeftSection>
        <div>
          <AnimatedChartWrapper index={0}>
            <AnimatedCarousel />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={1}>
            <MessagesSummary />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={2}>
            <MessagesBarPerType />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={3}>
            <TodayMessagesList />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={4}>
            <MessagesPerMonth />
          </AnimatedChartWrapper>
        </div>
      </LeftSection>

      <RightSection>
        <div>
          <AnimatedChartWrapper index={0}>
            <MessageDeliveryRate />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={1}>
            <MessagesPerCategory />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={2}>
            <MessagesPerDayOfWeek />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={3}>
            <MessagesCountByHour />
          </AnimatedChartWrapper>
          <AnimatedChartWrapper index={4}>
            <MessagesByUserType />
          </AnimatedChartWrapper>
        </div>
      </RightSection>
    </DashboardWrapper>
  )
}
