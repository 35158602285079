import { useState } from 'react'

import { VocacionalInitialFiltersProps } from '../../utils'
import { CadastrosTable } from './tabela'
import {
  GetAllCadastroProps,
  useFetchGetAllCadastros,
} from '../../../../hooks/vocacional/queries/useFetchGetAllCadastro'
import { generateQueryString, handleFilterChangeAndPagination } from '../../../../../../utility/utility'

export function Cadastros({ vocacionalGender }: VocacionalInitialFiltersProps) {
  const FiltrosCadastros = { genero: vocacionalGender, status: 'pendente' as const }

  const initialFilters = {
    queryString: generateQueryString(FiltrosCadastros),
    ...FiltrosCadastros,
  } as const

  const [filters, setFilters] = useState<GetAllCadastroProps['filters']>(initialFilters)

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const { data: cadastros, isLoading, isError } = useFetchGetAllCadastros({ filters })

  return (
    <CadastrosTable
      data={cadastros}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      isError={isError}
      filters={filters}
    />
  )
}
