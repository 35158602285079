import { Radio } from 'antd'
import { PeriodicityContainer } from './styles'
import { PaymentMethodState, usePaymentMethod } from '../../context'
import { useEffect, useState } from 'react'

export function Periodicity({ blockCreateRecurrence }: { blockCreateRecurrence: boolean }) {
  const { dispatch, periodicidadeValue, pixQrCode, boletoData } = usePaymentMethod()

  const handleRadioChange = (e: any) => {
    const value: PaymentMethodState['periodicidadeValue'] = e.target.value
    dispatch({ type: 'SET_PERIODICIDADE_VALUE', payload: value })
  }

  useEffect(() => {
    const value = 1 // ÚNICA
    dispatch({ type: 'SET_PERIODICIDADE_VALUE', payload: value })
  }, [blockCreateRecurrence, dispatch])

  if (pixQrCode || boletoData) return null

  return (
    <PeriodicityContainer>
      <div>
        <Radio.Group value={periodicidadeValue} onChange={handleRadioChange}>
          {!blockCreateRecurrence && (
            <Radio value={2}>
              <span>Mensal</span>
            </Radio>
          )}
          <Radio value={1}>
            <span>Única</span>
          </Radio>
        </Radio.Group>
      </div>
    </PeriodicityContainer>
  )
}
