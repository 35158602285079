import { Col, Image, Row, TablePaginationConfig } from 'antd'
import { BaseTable } from '../../../../../../../../components/table/BaseTable/BaseTable'
import {
  GetListarDoacoesRecebidasProps,
  useGetListarDoacoesRecebidas,
} from '../../../../../../../../hooks/agape/queries/useGetListarDoacoesRecebidas'
import BaseButton from '../../../../../../../../components/button/BaseButton'
import { DownloadSimple } from '@phosphor-icons/react'
import {
  baixarImagensSequencialmente,
  handleFilterChangeAndPagination,
} from '../../../../../../../../../../utility/utility'
import { ListagemItensRecebimentoFamilia } from './ListagemItensRecebimentoFamilia'
import { EnviarRecibosModal } from '../../../../../../Doacao/Recibos/EnviarRecibosModal'
import { useEffect, useState } from 'react'
import { VisualizarReciboModal } from '../../../../../../components/VisualizarRecibo/VisualizarReciboModal'

interface HistoricoRecebimentosTabelaProps {
  idFamilia: number
}

export function HistoricoRecebimentosTabela({ idFamilia }: HistoricoRecebimentosTabelaProps) {
  const [filters, setFilters] = useState<GetListarDoacoesRecebidasProps['filters']>({})

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  useEffect(() => {
    setFilters({ page: 1 })
  }, [idFamilia])

  const {
    data: doacoesRecebidas,
    isLoading: isLoadingDoacoesRecebidas,
    isError: isErrorDoacoesRecebidas,
  } = useGetListarDoacoesRecebidas({ fk_familia_agape_id: idFamilia, filters })

  const dataSource = doacoesRecebidas?.doacoes_recebidas.map((item) => {
    const { nome_acao, dia_horario, recibos, fk_instancia_acao_agape_id, fk_doacao_agape_id } = item

    return {
      key: dia_horario,
      nome_acao,
      dia_horario,
      fk_instancia_acao_agape_id,
      fk_doacao_agape_id,
      recibos: (
        <>
          {recibos.length > 0 ? (
            <VisualizarReciboModal recibos={recibos} />
          ) : (
            <EnviarRecibosModal idDoacao={fk_doacao_agape_id} />
          )}
        </>
      ),
    }
  })

  const columns = [
    {
      title: 'Ação',
      dataIndex: 'nome_acao',
      key: 'nome_acao',
    },
    {
      title: 'Dia e horário',
      dataIndex: 'dia_horario',
      key: 'dia_horario',
    },
    {
      title: 'Recibos',
      dataIndex: 'recibos',
      key: 'recibos',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingDoacoesRecebidas}
      isError={isErrorDoacoesRecebidas}
      pagination={{
        current: doacoesRecebidas?.page,
        total: doacoesRecebidas?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
      expandable={{
        expandedRowRender: (record) => (
          <Col xs={24}>
            <Row justify="center">
              <ListagemItensRecebimentoFamilia
                fk_doacao_agape_id={record.fk_doacao_agape_id}
                fk_instancia_acao_agape_id={record.fk_instancia_acao_agape_id}
              />
            </Row>
          </Col>
        ),
      }}
    />
  )
}
