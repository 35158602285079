import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors['dark-brown']};
  color: ${({ theme }) => theme.colors.white};

  > div {
    padding: 2rem 2rem;
    max-width: 150rem;
    width: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
`;

export const StyledLogo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 4rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 2rem;
  color: ${({ theme }) => theme.colors.white};

  h3 {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 2rem;

  .anticon {
    background-color: ${({ theme }) => theme.colors['gray-400']};
    color: ${({ theme }) => theme.colors['dark-brown']};
    padding: 8px;
    border-radius: 999px;
    cursor: pointer;
    transition: 0.2s;

    :hover {
      background-color: ${({ theme }) => theme.colors['gray-100']};
    }

    svg {
      width: 24px;
      height: 24px;
    }

    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const BottomFooterContainer = styled.section`
  background-color: ${({ theme }) => theme.colors['black-light']};
  color: ${({ theme }) => theme.colors['gray-400']};
  font-size: 0.8rem;
  width: 100%;
  padding: 1rem;

  > div {
    max-width: 150rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    line-height: 1;
  }
`;

export const PagesContainer = styled.div`
  display: flex;
  gap: 2rem;
  font-weight: 200;
  font-size: 0.75rem;

  a {
    color: ${({ theme }) => theme.colors['gray-400']};

    line-height: 1;

    &:hover {
      color: ${(props) => props.theme.colors['gray-500']};
    }
  }
`;
