import { notification } from 'antd'
import { DataService } from '../../../../lib/dataService'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useDeleteProfile() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: number) => {
      await DataService.delete(`administradores/deletar-perfil/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['listar-perfis'],
      })
      notification.success({ message: 'Perfil excluído com sucesso.' })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao excluir o perfil',
        description: error?.response?.data?.error ?? 'Por favor, tente novamente',
      })
    },
  })
}
