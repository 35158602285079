import { useState } from 'react'
import { Content } from './Content'
import { FilterModal } from './FilterModal'
import BaseCard from '../../../../../components/card/BaseCard'
import { getCurrentDayQueryString } from '../../../../users/Imported/utils'

export function TodayDonationsBar() {
  const initialQueryString = getCurrentDayQueryString()

  const [filters, setFilters] = useState(initialQueryString)
  const [campaignsOptions, setCampaignsOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <BaseCard padding="0px" style={{ height: '260px', overflow: 'hidden' }}>
      <FilterModal
        filters={filters}
        setFilters={setFilters}
        campaignsOptions={campaignsOptions}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Content filters={filters} setCampaignsOptions={setCampaignsOptions} onFilterClick={handleOpenModal} />
    </BaseCard>
  )
}
