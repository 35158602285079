import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

interface EditPermissionProfileProps {
  selectedUserId: number
  selectedProfileId: number
}

export function useEditPermissionProfile() {
  const queryClient = useQueryClient()

  const editPermissionProfile = useMutation({
    mutationFn: async ({ selectedProfileId, selectedUserId }: EditPermissionProfileProps) => {
      await DataService.put(`administradores/editar-usuario-perfil/${selectedUserId}`, {
        fk_perfil_id: selectedProfileId,
      })
    },
    onSuccess: (_, { selectedProfileId }) => {
      queryClient.invalidateQueries({
        queryKey: ['listar-usuarios-perfil', selectedProfileId],
      })

      queryClient.invalidateQueries({
        queryKey: ['listar-perfis'],
      })

      const message = selectedProfileId === 2 ? 'Usuário removido com sucesso' : 'Usuário adicionado com sucesso'

      notification.success({ message })
    },
    onError: (error: any, { selectedProfileId }) => {
      const message =
        selectedProfileId === 2 ? 'Não foi possível remover o usuário' : 'Não foi possível adicionar o usuário'

      notification.error({ message, description: error?.response?.data?.error ?? 'Por favor, tente novamente' })
    },
  })

  return editPermissionProfile
}
