import { useState } from 'react'
import BaseCard from '../../../components/card/BaseCard'
import { BaseTable } from '../../../components/table/BaseTable/BaseTable'
import { Pen, Users } from '@phosphor-icons/react'
import { Trash } from '@phosphor-icons/react/dist/ssr'
import { AllUsersByProfileTable } from './components/AllUsersByProfileTable'
import BaseButton from '../../../components/button/BaseButton'
import { BaseInput } from '../../../components/inputs/BaseInput/BaseInput'
import { useCreateProfile } from '../../../hooks/system/useCreateProfile'
import { Perfil, useListAllProfiles } from '../../../hooks/system/useListAllProfiles'
import { useDeleteProfile } from '../../../hooks/system/useDeleteProfile'
import { ColumnType } from 'antd/lib/table'
import { ProfilePermissionsTableModal } from './components/ProfilePermissionsTableModal'

export const PermissionsPage = () => {
  const [selectedProfileId, setSelectedProfileId] = useState<number>()
  const [createProfileInput, setCreateProfileInput] = useState<string>('')

  const [isUsersByProfileModalOpen, setIsUsersByProfileModalOpen] = useState<boolean>(false)
  const [isProfilePermissionsTableModalOpen, setIsProfilePermissionsTableModalOpen] = useState<boolean>(false)

  const { data: allPerfis, isPending, isError } = useListAllProfiles()

  const { mutate: createProfile, isPending: isPendingCreateProfile } = useCreateProfile()
  const { mutate: deleteProfile, isPending: isPendingDeleteProfile } = useDeleteProfile()

  const usersByProfileTableColumns: ColumnType<{
    id: number
    nome: string
    date: string
    quantidadeUsuarios: number
    usuarios: Perfil
    permissoes: Perfil
    excluir: Perfil
  }>[] = [
    { title: '#ID', dataIndex: 'id', key: 'id' },
    { title: 'Nome', dataIndex: 'nome', key: 'nome' },
    {
      title: 'Data de Criação',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    {
      title: 'Usuários Inscritos',
      dataIndex: 'quantidadeUsuarios',
      key: 'quantidadeUsuarios',
      align: 'center',
    },
    { title: 'Status', dataIndex: 'status', key: 'status', align: 'center' },
    {
      title: 'Usuários',
      dataIndex: 'usuarios',
      key: 'usuarios',
      width: '90px',
      align: 'center',
      render: (perfil: Perfil) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            setIsUsersByProfileModalOpen(true)
            setSelectedProfileId(perfil?.id)
          }}
        >
          {<Users size={32} />}
        </div>
      ),
    },
    {
      title: 'Permissões',
      dataIndex: 'permissoes',
      key: 'permissoes',
      width: '90px',
      align: 'center',
      render: (perfil: Perfil) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            setIsProfilePermissionsTableModalOpen(true)
            setSelectedProfileId(perfil?.id)
          }}
        >
          <Pen size={32} />
        </div>
      ),
    },

    {
      title: 'Excluir',
      dataIndex: 'excluir',
      key: 'excluir',
      render: (perfil: Perfil) => {
        return (
          <button
            disabled={isPendingDeleteProfile}
            style={{ cursor: 'pointer', border: 0, backgroundColor: 'transparent' }}
            onClick={() => {
              deleteProfile(perfil?.id)
            }}
          >
            <Trash size={32} />
          </button>
        )
      },
    },
  ]

  const usersByProfileTableDataSource = allPerfis?.perfis
    ?.map((perfil) => ({
      id: perfil.id,
      nome: perfil.nome,
      date: perfil.data_criacao,
      quantidadeUsuarios: perfil.quantidade_usuarios,
      status: perfil.status ? 'Ativo' : 'Inativo',
      usuarios: perfil,
      permissoes: perfil,
      excluir: perfil,
    }))
    .filter((perfil) => perfil.nome !== 'Benfeitor')

  return (
    <>
      {/* Modal: */}
      <AllUsersByProfileTable
        isUsersByProfileModalOpen={isUsersByProfileModalOpen}
        setIsUsersByProfileModalOpen={setIsUsersByProfileModalOpen}
        selectedProfileId={selectedProfileId!}
      />

      <ProfilePermissionsTableModal
        isProfilePermissionsTableModalOpen={isProfilePermissionsTableModalOpen}
        setIsProfilePermissionsTableModalOpen={setIsProfilePermissionsTableModalOpen}
        selectedProfileId={selectedProfileId!}
      />

      <BaseCard style={{ minHeight: '85vh' }} title="Perfis e Permissões">
        <BaseTable
          columns={usersByProfileTableColumns}
          dataSource={usersByProfileTableDataSource}
          isLoading={isPending}
          isError={isError}
        />

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '2rem' }}>
          <BaseButton
            disabled={isPendingCreateProfile || !createProfileInput}
            onClick={async () => {
              await createProfile({
                nome: createProfileInput,
                status: true,
                super_perfil: true,
              })
              setCreateProfileInput('')
            }}
          >
            Criar Perfil
          </BaseButton>
          <BaseInput
            style={{ width: '300px' }}
            value={createProfileInput}
            placeholder="Exemplo: Secretaria"
            onChange={(e) => {
              setCreateProfileInput(e.target.value)
            }}
          />
        </div>
      </BaseCard>
    </>
  )
}
