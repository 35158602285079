import { Col, Form, Row } from 'antd'
import BaseModal from '../../../../components/modal/BaseModal'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { BaseSelect } from '../../../../components/select/BaseSelect'
import { EmptyResult } from '../../../../components/result/resultStatus/EmptyResult'
import BaseDatePicker from '../../../../components/datepicker/BaseDatePicker'
import BaseButton from '../../../../components/button/BaseButton'
import { useState } from 'react'
import moment, { Moment } from 'moment'
import { useFetchCampaignsOptions } from '../../../../hooks'
import { FilterTwoTone } from '@ant-design/icons'
import { HandleFilterChangeData } from '..'

interface CampaignResponse {
  value: string
  label: string
  objetivo: string
}

const options = [
  {
    value: 'avulsa',
    label: 'Avulsa',
  },
  {
    value: 'recorrente',
    label: 'Recorrentes',
  },
]

const disableMonths = (current: Moment) => {
  if (!current || !current.isValid()) return false

  const today = moment().startOf('month')
  const lastMonth = today.subtract(1, 'month')
  const twelveMonthsAgo = today.clone().subtract(12, 'months')

  return current.isBefore(twelveMonthsAgo, 'month') || current.isAfter(lastMonth, 'month')
}

interface DonorClassificationFiltersProps {
  handleFilterChange: ({ numMeses, dados }: { numMeses: number; dados: HandleFilterChangeData }) => void
}

interface DonorClassificationFormData {
  tipo_doacao?: 'avulsa' | 'recorrente'
  mes?: string
  campanhas?: string[]
}

export function DonorClassificationFilters({ handleFilterChange }: DonorClassificationFiltersProps) {
  const [form] = Form.useForm()

  const [open, setOpen] = useState(false)

  function handleModalShow() {
    setOpen((prev) => !prev)
  }

  const { data: campaignsData } = useFetchCampaignsOptions()

  const handleSubmit = (values: DonorClassificationFormData) => {
    let numMeses = null

    if (values.mes) {
      const mes = moment(values.mes, 'YYYY-MM').format('YYYY-MM')

      // Calcula o início do mês passado
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM')

      // Verifica se a data fornecida é válida, caso contrário usa o mês atual menos 6 meses
      const formattedMes = mes === 'Data inválida' ? moment().subtract(6, 'months').format('YYYY-MM') : mes

      // Calcula a diferença em meses entre o mês fornecido e o início do mês passado, incluindo o mês selecionado
      numMeses = Math.abs(moment(formattedMes, 'YYYY-MM').diff(moment(startOfLastMonth, 'YYYY-MM'), 'months')) + 1
    }

    const dadosTratados = {
      fk_campanhas_ids: values.campanhas === undefined ? [] : values.campanhas.join(','),
      quantidade_meses: numMeses,
      tipo_doacao: values.tipo_doacao,
    }

    handleFilterChange({ numMeses: numMeses ?? 6, dados: { ...dadosTratados } })

    handleModalShow()
  }

  return (
    <>
      <BaseButton type="ghost" onClick={handleModalShow}>
        <FilterTwoTone twoToneColor="var(--text-main-color)" />
      </BaseButton>

      <BaseModal title="Filtrar" centered open={open} footer={null} width={1000} onCancel={handleModalShow}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row gutter={[20, 20]} justify="center">
            <Col xs={24} md={8}>
              <BaseForm.Item label="Tipo de doação" name="tipo_doacao">
                <BaseSelect
                  allowClear
                  size="large"
                  placeholder="Selecione"
                  notFoundContent={<EmptyResult />}
                  options={options}
                />
              </BaseForm.Item>
            </Col>

            <Col xs={24} md={8}>
              <BaseForm.Item label="Mês" name="mes">
                <BaseDatePicker
                  size="large"
                  picker="month"
                  format="MM/YYYY"
                  style={{ width: '100%' }}
                  disabledDate={disableMonths}
                />
              </BaseForm.Item>
            </Col>

            <Col xs={24} md={8}>
              <BaseForm.Item label="Campanhas" name="campanhas">
                <BaseSelect
                  mode="multiple"
                  allowClear
                  placeholder="Selecione"
                  notFoundContent={<EmptyResult />}
                  options={campaignsData?.map((campanha: CampaignResponse) => ({
                    label: campanha.label,
                    value: campanha.value,
                  }))}
                />
              </BaseForm.Item>
            </Col>
          </Row>

          <Row justify="center" style={{ marginTop: '2rem' }}>
            <BaseButton htmlType="submit">Enviar</BaseButton>
          </Row>
        </Form>
      </BaseModal>
    </>
  )
}
