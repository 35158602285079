import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from 'recharts'
import { useState } from 'react'
import { formatNumber, formatToBrazilianCurrency } from '../../../../../../../utility/utility'
import { ChartTitle, TooltipContainer, formatChartNumber } from '../../../styles'

export function Chart({ data }) {
  const [barHovered, setBarHovered] = useState(false)
  const [focusBar, setFocusBar] = useState(null)

  const formattedData = Array.isArray(data)
    ? data.map((entry) => {
        const day = Object.keys(entry)[0]
        const total = Object.values(entry)[0]
        return {
          dia: day.split('-')[0].trim(),
          total,
        }
      })
    : []

  const CustomTooltip = ({ payload }) => {
    if (!payload || !payload.length) return null

    const { dia, total } = payload[0]?.payload || {}

    return (
      <TooltipContainer type="signup">
        <strong>{dia}</strong>
        <strong>{formatNumber(total)}</strong>
      </TooltipContainer>
    )
  }

  return (
    <>
      <ChartTitle style={{ padding: '2rem' }}>Por dia da semana</ChartTitle>
      <div style={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={formattedData} margin={{ top: 20, left: 0, right: 0, bottom: 20 }}>
            <CartesianGrid stroke="#444" vertical={false} strokeOpacity={0.6} />
            <XAxis
              axisLine={false}
              dataKey="dia"
              tick={{ fill: '#6d6d6d', fontWeight: 'bold' }}
              orientation="bottom"
              tickMargin={15}
            />
            <YAxis axisLine={false} tick={{ fill: '#888888' }} tickFormatter={formatChartNumber} tickMargin={15} />

            <defs>
              <linearGradient id="signupGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="var(--signup-chart-color)" />
                <stop offset="100%" stopColor="var(--signup-chart-color-fade-stop)" />
              </linearGradient>
              <linearGradient id="signupGradientOnHover" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="var(--signup-chart-color-hover)" />
                <stop offset="100%" stopColor="var(--signup-chart-color-hover-end)" />
              </linearGradient>
            </defs>

            <Tooltip cursor={false} active={barHovered} content={<CustomTooltip />} />
            <Bar
              dataKey="total"
              radius={[8, 8, 0, 0]}
              barSize={40}
              onMouseEnter={() => setBarHovered(true)}
              onMouseLeave={() => setBarHovered(false)}
            >
              {formattedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={focusBar === index ? 'url(#signupGradientOnHover)' : 'url(#signupGradient)'}
                  onMouseEnter={() => setFocusBar(index)}
                  onMouseLeave={() => setFocusBar(null)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
