import { useHistory } from 'react-router-dom'
import { Documento } from '../pages/validate-user'
import { useEffect } from 'react'

export const useRedirectIfInvalid = (document: Documento | undefined, isForeigner: boolean | undefined) => {
  const history = useHistory()

  useEffect(() => {
    if (!document || isForeigner === undefined) {
      history.push('/membro-exercito/validar-usuario')
    }
  }, [document, isForeigner, history])
}
