import { memo } from 'react'
import { StyledMenuFinance, Label, ModalContent, paymentMethodOptions } from '../../../styles'
import BaseModal from '../../../../../components/modal/BaseModal'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import { MultipleSelect } from '../../../../../components/multiple-select/multiple-select'
import { StyledFilterIcon } from '../../../../../components/FilterIcon'
import BaseRadio from '../../../../../components/radio/BaseRadio'
import { BaseSelect } from '../../../../../components/select/BaseSelect'

export const FilterModal = memo(({
  filters,
  setFilters,
  campaignOptions,
  isModalOpen,
  setIsModalOpen
}) => {
  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const isFilterActive = Boolean(filters?.forma_pagamento) || filters?.fk_campanha_id?.length > 0

  const formattedCampaignOptions = campaignOptions.map((item) => ({
    value: item.id,
    label: item.titulo,
  }))

  return (
    <BaseModal
      title="Filtros das doações do mês"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      closeIcon="X"
      size="large"
    >
      <ModalContent>
        <div>
          <Label>Visualizar por:</Label>
          <BaseRadio.Group onChange={(e) => handleInputChange({ visualizar_por: e.target.value })}>
            <BaseRadio.Button value="" defaultChecked>
              Total
            </BaseRadio.Button>
            <BaseRadio.Button value="campanha">Campanha</BaseRadio.Button>
          </BaseRadio.Group>
        </div>
        {filters?.visualizar_por !== 'campanha' && (
          <>
            <div>
              <Label>Método de Pagamento:</Label>
              <BaseSelect
                width="11rem"
                dropdownRender={(menu) => <StyledMenuFinance>{menu}</StyledMenuFinance>}
                allowClear
                options={paymentMethodOptions}
                value={filters.forma_pagamento}
                onChange={(value) => handleInputChange({ forma_pagamento: value })}
              />
            </div>
            <div>
              <Label>Campanhas:</Label>
              <MultipleSelect
                options={formattedCampaignOptions}
                value={filters.fk_campanha_id}
                onChange={(value) => handleInputChange({ fk_campanha_id: value })}
              />
            </div>
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
})
