import { Divider } from 'antd';
import styled from 'styled-components';

export const CreateAccountTitle = styled.div`
  text-align: center;
  font-size: 0.9rem;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 600;
  color: #4d4d4d;

  span,
  a {
    color: #9e3127;
    cursor: pointer;
  }
`;

export const NoAccountTitle = styled(CreateAccountTitle)``;

export const ModalTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 800;
  text-align: center;
`;

export const DividerText = styled.h2`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 600;
  text-align: center;
  color: gray;
  font-size: 0.9rem;
`;

export const StyledDivider = styled(Divider)`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 600;
  text-align: center;
  border-top: solid #a7a6a6 1px;
  font-size: 0.9rem;
  margin: 1rem 0;
`;

export const UpdateParagraphText = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  color: #3e3331;
  font-weight: 500;
  font-size: 0.85rem;
  text-align: center;
`;
