import styled from 'styled-components';

export const VocacionalWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  margin-inline: auto;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 70vh;
`;
