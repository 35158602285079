import { DataService } from '../../../../../lib/dataService'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

export interface SingleCampaignResponse {
  id: number
  bloquear_doacao_recorrente: boolean
  descricao: string
  filename: string
  objetivo: 'doacao' | 'cadastro'
  titulo: string
}

export function useFetchSingleCampaign(id: string): UseQueryResult<SingleCampaignResponse, Error> {
  const singleCampaign = useQuery<SingleCampaignResponse, Error>({
    queryKey: ['CAMPAIGN-DATA', id],
    queryFn: async () => {
      const response = await DataService.get(`campaigns/campanha-publica/${id}`)

      return {
        ...response.data,
        id: parseInt(id, 10),
      }
    },
    enabled: !!id,
  })

  return singleCampaign
}
