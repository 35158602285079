import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export function useFetchProfiles({ enabled }) {
  const profiles = useQuery({
    queryKey: ['PROFILES'],
    queryFn: async () => {
      const profilesResponse = await DataService.get('/administradores/listar-perfis')
      return profilesResponse?.data
    },

    enabled,
  })

  return profiles
}
