import { Row } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import {
  ButtonRow,
  CampaignContainer,
  CampaignContent,
  CampaignDescription,
  CampaignDetailTitle,
  CampaignImage,
  CampaignImageWrapper,
  CampaignSkeletonImage,
} from './styles'
import { useFetchSingleCampaign } from '../../../../admin/hooks'
import { StyledSpin } from '../../../components/Spin/styles'
import { StyledResult } from '../../../components/Result'
import { StyledButton } from '../../../components/Button'
import { Cross } from '@phosphor-icons/react'

export function CampaignDetail() {
  const { campaignId } = useParams<{ campaignId: string }>()
  const history = useHistory()

  const {
    data: campaignData,
    isLoading: isLoadingCampaignData,
    isError: isErrorCampaignData,
  } = useFetchSingleCampaign(campaignId)

  if (isLoadingCampaignData) {
    return (
      <Row justify="center" style={{ alignItems: 'center', height: '60vh' }}>
        <StyledSpin />
      </Row>
    )
  }

  if (isErrorCampaignData) {
    return (
      <Row justify="center">
        <StyledResult />
      </Row>
    )
  }

  const objetivo = campaignData?.objetivo

  return (
    <CampaignContainer>
      <Row justify="center">
        <CampaignDetailTitle>{campaignData?.titulo}</CampaignDetailTitle>
      </Row>
      <CampaignContent>
        <CampaignImageWrapper>
          {isLoadingCampaignData ? <CampaignSkeletonImage active /> : <CampaignImage src={campaignData?.filename} />}
          <CampaignDescription>{campaignData?.descricao}</CampaignDescription>
        </CampaignImageWrapper>

        <ButtonRow>
          <StyledButton
            icon={objetivo === 'doacao' && <Cross size={20} />}
            type={(objetivo === 'cadastro' && 'ghost') || undefined}
            onClick={() => history.push(`/benfeitor/campanha/${campaignId}/identificacao`)}
          >
            {objetivo === 'doacao' && 'Fazer doação'}
            {objetivo === 'cadastro' && 'Cadastrar'}
          </StyledButton>
        </ButtonRow>
      </CampaignContent>
    </CampaignContainer>
  )
}
