import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface DadosCampanha {
  cadastros_meta: number | null
  cadastros_total_atingido: number
  cadastros_total_mes_atual: number
  campanha_nome: string
  chave_pix: string
  data_alteracao: string
  data_criacao: string
  data_fim: string | null
  data_inicio: string | null
  descricao: string
  duracao: string
  filename: string
  id: number
  label_foto: string | null
  objetivo: 'doacao' | 'cadastro'
  preenchimento_foto: boolean | null
  publica: boolean
  status: boolean
  valor_meta: string | null
  valor_total: string | null
  valor_total_mes_atual: string | null
}

interface DadosLandingPage {
  banner: string
  descricao: string
  id: number
  texto_email_pos_registro: string
  texto_pos_registro: string
  titulo: string
  url: string
}

interface DadosSetupPagamento {
  boleto_recorrente: number
  boleto_unico: number
  credito_recorrente: number
  credito_unico: number
  id: number
  pix_recorrente: number
  pix_unico: number
}

interface CampaignData {
  dados_campanha: DadosCampanha
  dados_landing_page: DadosCampanha['objetivo'] extends 'cadastro' ? DadosLandingPage : null
  dados_setup_pagamento: DadosCampanha['objetivo'] extends 'doacao' ? DadosSetupPagamento : null
}

export function useFetchAdminSingleCampaign(id: string): UseQueryResult<CampaignData, Error> {
  const singleCampaign = useQuery({
    queryKey: ['CAMPAIGN-DATA', id],
    queryFn: async () => {
      const response = await DataService.get(`campaigns/admin/${id}`)

      return {
        dados_campanha: {
          ...response.data.dados_campanha,
          id: parseInt(id, 10),
        },
        dados_landing_page: response.data.dados_landing_page,
        dados_setup_pagamento: response.data.dados_setup_pagamento,
      }
    },
  })

  return singleCampaign
}
