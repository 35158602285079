import logosImage from '../../../../images/logos.svg'
import nunsImage from '../../../../images/nuns.png'
import { GoldenHeader } from '../../../components/golden-header/styles'

import {
  AdditionalDescription,
  BackgroundImageWrapper,
  CallToActionText,
  DescriptionWrapper,
  MainSectionWrapper,
  MobileLogo,
  NunsImageWrapper,
  SectionWrapper,
} from './styles'

export function SectionBecomeMember({ res }: { res: 'desktop' | 'mobile' }) {
  return (
    <>
      <BackgroundImageWrapper>
        <MainSectionWrapper>
          <SectionWrapper>
            {res === 'mobile' && <MobileLogo src={logosImage} alt="Logo" />}
            <NunsImageWrapper src={nunsImage} alt="Nuns" />
            {res === 'mobile' && (
              <GoldenHeader fontSize={28} lineHeight={'32px'}>
                TORNE-SE MEMBRO OFICIAL DO EXÉRCITO DE SÃO MIGUEL
              </GoldenHeader>
            )}

            <div>
              {res === 'desktop' && (
                <>
                  <MobileLogo src={logosImage} alt="Logo" />
                  <div style={{ maxWidth: '550px' }}>
                    <GoldenHeader fontSize={46} lineHeight={'53px'} style={{ textAlign: 'initial' }}>
                      TORNE-SE MEMBRO OFICIAL DO EXÉRCITO DE SÃO MIGUEL
                    </GoldenHeader>
                  </div>
                </>
              )}
              <DescriptionWrapper>
                <CallToActionText>
                  E veja <span>milagres acontecerem </span>
                  pelo <span>poder da oração.</span>
                </CallToActionText>
                <AdditionalDescription>
                  Milhares de pessoas já experimentaram curas e libertações através do poder da oração. Agora, é a sua
                  vez.
                </AdditionalDescription>
              </DescriptionWrapper>
            </div>
          </SectionWrapper>
        </MainSectionWrapper>
      </BackgroundImageWrapper>
      {/* {res === 'desktop' && <PrayerImpactCard showOn="desktop" />} */}
    </>
  )
}
