import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface PermissoesUsuario {
  data_criacao_usuario: string
  fk_perfil_id: number
  fk_usuario_id: number
  id: number
  nome_usuario: string
  status_usuario: boolean
}

interface ListarUsuariosPerfilResponse {
  permissoes_usuarios: PermissoesUsuario[]
  page: number
  total: number
}

export function useListUsersByProfile({
  selectedProfileId,
  searchedUser,
}: {
  selectedProfileId?: number
  searchedUser?: string
}) {
  return useQuery({
    queryKey: ['listar-usuarios-perfil', selectedProfileId || searchedUser],
    queryFn: async (): Promise<ListarUsuariosPerfilResponse> => {
      let params = ''

      if (selectedProfileId) {
        params = `filtro_fk_perfil_id=${selectedProfileId}`
      } else if (searchedUser) {
        params = `filtro_nome_email_cpf=${searchedUser}`
      }

      const response = await DataService.get(`administradores/listar-usuarios-perfil?${params}`)
      return response?.data || { permissoes_usuarios: [] }
    },
    enabled: !!selectedProfileId || !!searchedUser,
  })
}
