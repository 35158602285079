import styled from 'styled-components'
import { Radio as AntdRadio } from 'antd'
import { BORDER_RADIUS } from '../../../../styles/Themes/Admin/constants'

// Estilo para o Radio
export const Radio = styled(AntdRadio)`
  .ant-radio-disabled + span {
    color: var(--disabled-color);
  }
`

// Estilo para o RadioButton
export const RadioButton = styled(AntdRadio.Button)`
  color: var(--text-secondary-color);
  background-color: var(--background-color);
  border-color: var(--unchecked-switch-color) !important;

  &:hover {
    border-color: var(--text-main-color);
    color: var(--text-main-color);
  }

  &.ant-radio-button-wrapper:first-child {
    border-radius: ${BORDER_RADIUS} 0 0 ${BORDER_RADIUS};
  }

  &.ant-radio-button-wrapper:last-child {
    border-radius: 0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0;
  }

  &.ant-radio-button-wrapper-checked {
    font-weight: bold;
    background-color: var(--background-color); /* Cor de fundo quando marcado */
    color: var(--text-main-color); /* Cor do texto quando marcado */
    border-color: var(--text-main-color) !important; /* Forçar a cor da borda */

    &::before {
      background-color: var(--text-main-color);
    }

    &:hover {
      color: var(--button-text-color);
      background-color: var(--text-secondary-color);
      border-color: var(--text-main-color) !important;

      &::before {
        background-color: var(--text-main-color);
      }
    }

    &:focus {
      color: var(--text-main-color);
      border-color: var(--text-main-color) !important;
    }
  }

  // Estilizando o estado desabilitado
  &.ant-radio-button-wrapper-disabled {
    color: var(--text-secondary-color) !important;
    cursor: not-allowed;
    opacity: 0.7;

    &:hover {
      color: inherit;
      border-color: inherit;
      background-color: inherit;
    }

    .ant-radio-button-disabled {
      background-color: var(--background-color) !important;
    }
  }
`
