import { DownloadSimple } from '@phosphor-icons/react'
import { Col, Image, Row } from 'antd'
import Slider from 'react-slick'
import { baixarImagem } from '../../../../../../utility/utility'
import BaseButton from '../../../../components/button/BaseButton'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
}

interface SliderRecibosProps {
  recibos: string[]
}

export function SliderRecibos({ recibos }: SliderRecibosProps) {
  return (
    <Col xs={18} style={{ margin: '0 auto' }}>
      <div className="slider-container">
        <Slider {...settings}>
          {recibos.map((recibo) => (
            <div key={recibo}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Row justify="center">
                  <Image width={500} height={500} src={recibo} />
                </Row>

                <Row justify="center">
                  <BaseButton onClick={() => baixarImagem(recibo)} style={{ width: '32rem' }}>
                    <DownloadSimple />
                  </BaseButton>
                </Row>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Col>
  )
}
