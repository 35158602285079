import 'leaflet/dist/leaflet.css'

import { LayersControl, MapContainer, Marker, Popup, Rectangle, useMap } from 'react-leaflet'
import { useGetEnderecosBeneficiariosCicloDoacao } from '../../../../../../hooks/agape/queries/useGetEnderecosBeneficiariosCicloDoacao'
import { Row } from 'antd'
import { BaseSpin } from '../../../../../../components/spin/BaseSpin.Styles'
import { ErrorResult } from '../../../../../../components/result/resultStatus/ErrorResult'
import MarkerClusterGroup from 'react-leaflet-cluster'
import BaseButton from '../../../../../../components/button/BaseButton'
import { BaseLayers, BasePinIcon } from '../../../../../../../../lib/react-leaflet'

interface MapaEnderecoBeneficiariosProps {
  idCicloDoacao: number
}

function VoltarAoCentro({ center }: { center: [number, number] }) {
  const map = useMap()

  const handleClick = () => {
    map.setView(center, 13)
  }

  return (
    <BaseButton
      onClick={handleClick}
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 1000,
      }}
    >
      Voltar ao Centro
    </BaseButton>
  )
}

const ComponentResize = () => {
  const map = useMap()

  setTimeout(() => {
    map.invalidateSize()
  }, 0)

  return null
}

export function MapaEnderecoBeneficiarios({ idCicloDoacao }: MapaEnderecoBeneficiariosProps) {
  const {
    data: enderecosBeneficiarios,
    isLoading: isLoadingEnderecos,
    isError: isErrorEnderecos,
  } = useGetEnderecosBeneficiariosCicloDoacao({
    fk_instancia_acao_agape_id: idCicloDoacao,
  })

  const centroCicloDoacao = [
    enderecosBeneficiarios?.ciclo_acao_agape.latitude,
    enderecosBeneficiarios?.ciclo_acao_agape.longitude,
  ] as [number, number]

  const areaCicloDoacao = [
    [
      enderecosBeneficiarios?.ciclo_acao_agape.latitude_sudoeste,
      enderecosBeneficiarios?.ciclo_acao_agape.longitude_sudoeste,
    ], // Sudoeste
    [
      enderecosBeneficiarios?.ciclo_acao_agape.latitude_nordeste,
      enderecosBeneficiarios?.ciclo_acao_agape.longitude_nordeste,
    ], // Nordeste
  ]

  if (isLoadingEnderecos) {
    return (
      <Row justify="center">
        <BaseSpin />
      </Row>
    )
  }

  if (isErrorEnderecos) {
    return <ErrorResult />
  }

  return (
    <div style={{ height: '100vh', width: '100%', position: 'relative' }}>
      <MapContainer
        center={centroCicloDoacao}
        zoom={13}
        style={{
          height: '90vh',
          margin: '0 auto',
          borderRadius: '8px',
        }}
      >
        <ComponentResize />

        <VoltarAoCentro center={centroCicloDoacao} />

        <LayersControl position="topleft">
          <LayersControl.BaseLayer checked name="Normal">
            {BaseLayers.googleRegular}
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satélite">{BaseLayers.googleSatelite}</LayersControl.BaseLayer>
        </LayersControl>

        <Rectangle bounds={areaCicloDoacao} />

        <MarkerClusterGroup chunkedLoading>
          {enderecosBeneficiarios?.beneficiarios.map((marker, index) => (
            <Marker position={[marker.latitude, marker.longitude]} key={index} icon={BasePinIcon}>
              <Popup>{marker.nome_familia}</Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  )
}
