import styled, { css } from 'styled-components';

interface StyledProps {
  height?: string;
  border?: string;
  secondStyleType?: boolean;
}

export const UploadPhotoContainer = styled.div<StyledProps>`
  .ant-upload.ant-upload-select {
    display: block;
  }

  .ant-upload-list-item {
    margin-top: 0rem !important;
  }

  .ant-upload.ant-upload-select .ant-btn {
    border: ${({ border }) => border || '1.5px solid #d9d9d9'};
    border-radius: 8px;
    width: 100%;
    height: ${({ height }) => height || '44.5px'} !important;

    ${({ secondStyleType }) =>
    secondStyleType &&
    css`
        border: ${({ border }) => border || '2px solid #acacac'};
        height: ${({ height }) => height || '57.5px'} !important;
      `}
  }

  .ant-upload-list-item {
    margin-top: 0rem !important;
    border-radius: 8px !important;
    height: ${({ height }) => height || '44.5px'} !important;
    background-color: #ffffff !important;
    border: ${({ border }) => border || '1.5px solid #d9d9d9'};

    ${({ secondStyleType }) =>
    secondStyleType &&
    css`
        height: ${({ height }) => height || '57.5px'} !important;
        border: ${({ border }) => border || '2px solid #acacac'};
      `}
  }

  .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    height: calc(${({ height }) => height || '44.5px'} - 15%) !important;

    ${({ secondStyleType }) =>
    secondStyleType &&
    css`
        height: calc(${({ height }) => height || '57.5px'} - 15%) !important;
      `}
  }
`;
