import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT, LAYOUT, media } from '../../../../../../styles/Themes/Admin/constants'

export const SiderLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  gap: 10px;
`

export const SiderLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-block: 1rem;

  @media only screen and ${media.md} {
    height: ${LAYOUT.desktop.headerHeight};
    padding-top: ${LAYOUT.desktop.paddingVertical};
    padding-bottom: ${LAYOUT.desktop.paddingVertical};
  }
`

export const BrandSpan = styled.span`
  margin: 0 1rem;
  font-family: ${FONT_FAMILY.main};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xxxxl};
  color: var(--logo-text-color);
`

export const CollapseButton = styled(Button)`
  background: var(--sidebar-background-color);
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
  position: absolute !important;
  right: 0.5rem;
  z-index: 999 !important;

  ${(props) =>
    props.$isCollapsed &&
    css`
      right: -0.8rem;
    `}

  color: var(--text-sidebutton-color);

  &:hover,
  &:focus {
    color: var(--text-sidebutton-color);
    background: var(--sidebar-button-background-color);
    border: 1px solid var(--border-color);
    opacity: 0.7;
  }
`
