import * as S from './styles';
import { ReactNode } from 'react';
import { SpinProps } from 'antd/es/spin';
import { benfeitorTheme } from '../../../../styles/Themes/Benfeitor/benfeitorTheme';

export interface StyledSpinProps extends SpinProps {
  children?: ReactNode;
  color?: keyof typeof benfeitorTheme.colors;
}

export function Spin({ children, ...props }: StyledSpinProps) {
  return <S.StyledSpin {...props}>{children}</S.StyledSpin>;
}
