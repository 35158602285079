import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'
import { notification } from 'antd'

export function useRegisterLeadUser() {
  const useRegisterLeadUser = useMutation({
    mutationFn: async (formData) => {
      await DataService.post('/users/create-lead', formData)
    },
    onSuccess: (_, variables) => {
      notification.success({ message: 'Pré-cadastro realizado com sucesso!' })
    },
    onError: (error) => {
      notification.error({
        message: 'Ocorreu um erro ao realizar o pré-cadastro',
        description: error?.response?.data?.error ?? 'Ocorreu um erro ao concluir o cadastro.',
      })
    },
  })

  return useRegisterLeadUser
}
