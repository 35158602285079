import { Col, Form, Row } from 'antd'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import {
  AdicionarItemProps,
  useCadastrarItemEstoque,
} from '../../../../../hooks/agape/mutations/useCadastrarItemEstoque'
import { BaseInput } from '../../../../../components/inputs/BaseInput/BaseInput'
import BaseButton from '../../../../../components/button/BaseButton'

export function AdicionarItem() {
  const [form] = Form.useForm()

  const { mutateAsync: cadastrarItem, isPending: isPendingCadastrarItem } = useCadastrarItemEstoque()

  async function handleCadastrarItem(data: AdicionarItemProps) {
    await cadastrarItem({ item: data.item })

    form.resetFields()
  }

  return (
    <Col xs={24}>
      <Form form={form} layout="vertical" onFinish={handleCadastrarItem}>
        <Row justify="center" gutter={[20, 20]}>
          <Col xs={8}>
            <BaseForm.Item name="item" label="Item" rules={[{ required: true, message: 'Obrigatório' }]}>
              <BaseInput placeholder="Nome" />
            </BaseForm.Item>
          </Col>
        </Row>

        <Row justify="center">
          <BaseButton htmlType="submit" loading={isPendingCadastrarItem}>
            Enviar
          </BaseButton>
        </Row>
      </Form>
    </Col>
  )
}
