import { Form, FormInstance } from 'antd';
import { ListaFormularioResponsavel } from '../../../components/ListaFormularioResponsavel';
import { BaseDivider } from '../../../../../../components/divider/BaseDivider';
import { ListaFormularioMembro } from '../../../components/ListaFormularioMembro';

type FormularioCadastrarFamiliaProps = {
  form: FormInstance;
  handleFormSubmit: (values: any) => void;
};

export function FormularioAdicionarMembrosResponsavel({ form, handleFormSubmit }: FormularioCadastrarFamiliaProps) {
  return (
    <Form form={form} onFinish={(values) => handleFormSubmit(values)} layout="vertical">
      <ListaFormularioResponsavel hasInitialValue={false} />

      <BaseDivider />

      <ListaFormularioMembro hasInitialValue={false} />
    </Form>
  );
}
