import { ReactNode, useEffect, useState } from 'react'
import { Button, Col, notification, Row, Typography } from 'antd'
import { Spin } from '../../components/Spin'
import { useFetchUserMe } from '../../../admin/hooks'
import FeatherIcon from 'feather-icons-react'
import { useSaveProfilePhoto } from '../../../admin/hooks/images/mutations/useSaveProfilePhoto'
import { StyledCard } from '../../components/Card/styles'
import { StyledButton } from '../../components/Button'
import { AvatarPhotoUpload } from '../../components/AvatarPhotoUpload'
import { StyledResult } from '../../components/Result'
import { useSoftDeleteMe } from '../../../admin/hooks/users/Mutations/useSoftDeleteMe'
import { useDeleteProfilePhoto } from '../../../admin/hooks/images/mutations/useDeleteProfilePhoto'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export function ProfileLayout({ children }: { children: ReactNode }) {
  const { data: userMe, isLoading, isError, refetch } = useFetchUserMe()

  const {
    mutateAsync: uploadImage,
    isPending: isPendingUploadPhoto,
    isSuccess: isSuccessUploadPhoto,
  } = useSaveProfilePhoto()
  const {
    mutateAsync: deleteImage,
    isPending: isPendingDeleteImage,
    isSuccess: isSuccessDeleteImage,
  } = useDeleteProfilePhoto()

  const [profileImage, setProfileImage] = useState<{ url: string; name?: string; file?: File }[]>([])

  useEffect(() => {
    if (userMe?.avatar) {
      setProfileImage([{ url: userMe?.avatar }])
    }
  }, [userMe])

  const handleUploadImage = () => {
    if (profileImage.length === 0) {
      deleteImage()
      return
    }

    uploadImage({
      image: profileImage[0].file,
    })
  }

  useEffect(() => {
    if (isSuccessUploadPhoto || isSuccessDeleteImage) {
      setProfileImage([])
      refetch()
    }
  }, [isSuccessUploadPhoto, isSuccessDeleteImage])

  const showSaveImageButton =
    (profileImage.length > 0 && profileImage[0]?.url !== userMe?.avatar) || (!profileImage.length && userMe?.avatar)

  const { mutateAsync: deleteMe } = useSoftDeleteMe()

  return (
    <Row gutter={[30, 20]} style={{ maxWidth: '1180px', margin: '0 auto' }}>
      <Col xs={24} md={8}>
        <StyledCard style={{ minHeight: '80vh', height: '100%' }}>
          {isLoading && (
            <Spin
              style={{
                width: '100%',
                display: 'grid',
                placeItems: 'center',
                height: '60vh',
              }}
            />
          )}
          {isError && (
            <p
              style={{
                width: '100%',
                display: 'grid',
                placeItems: 'center',
                height: '60vh',
              }}
            >
              {' '}
              <StyledResult />{' '}
            </p>
          )}

          {!isLoading && userMe && !isError && (
            <>
              <Row justify="center">
                <AvatarPhotoUpload
                  listType="picture-card"
                  initialFileList={profileImage}
                  setImage={setProfileImage}
                  numeroFotos={1}
                />
              </Row>

              {showSaveImageButton && (
                <Row justify="center" style={{ marginTop: '3rem' }}>
                  <StyledButton onClick={handleUploadImage} loading={isPendingUploadPhoto || isPendingDeleteImage}>
                    Salvar
                  </StyledButton>
                </Row>
              )}

              <div style={{ marginTop: '2rem' }}>
                <Typography.Title
                  style={{
                    fontSize: '1.2rem',
                    textAlign: 'center',
                    fontWeight: '900',
                    fontFamily: 'MontSerrat',
                  }}
                  level={2}
                >
                  {userMe?.nome}
                </Typography.Title>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                  width: '100%',
                  marginTop: '2.5rem',
                }}
              >
                <StyledProfileNav to="/benfeitor/profile/view">
                  <NavIconWrapper>
                    <FeatherIcon size={20} icon="user" />
                  </NavIconWrapper>
                  Dados cadastrais
                </StyledProfileNav>

                {/* <StyledProfileNav to="/benfeitor/profile/address">
                  <NavIconWrapper>
                    <FeatherIcon size={20} icon="map-pin" />
                  </NavIconWrapper>
                  Endereço
                </StyledProfileNav> */}

                <StyledProfileNav to="/benfeitor/profile/donations">
                  <NavIconWrapper>
                    <FeatherIcon size={20} icon="dollar-sign" />
                  </NavIconWrapper>
                  Minhas doações
                </StyledProfileNav>
                <StyledProfileNav to="/benfeitor/profile/security">
                  <NavIconWrapper>
                    <FeatherIcon size={20} icon="lock" />
                  </NavIconWrapper>
                  Segurança
                </StyledProfileNav>
              </div>
            </>
          )}

          <Row justify="center" style={{ marginTop: '4rem' }}>
            <StyledButton
              type="text"
              style={{ position: 'absolute', bottom: 1 }}
              onClick={() => ConfirmDeleteMyAccountNotification(deleteMe)}
            >
              Excluir conta
            </StyledButton>
          </Row>
        </StyledCard>
      </Col>
      <Col xs={24} md={16} style={{ minHeight: '80vh', height: '100%' }}>
        {children}
      </Col>
    </Row>
  )
}

const ConfirmDeleteMyAccountNotification = (deleteMe: () => Promise<void>) => {
  notification.warning({
    message: 'Atenção',
    description: 'Ao clicar no botão abaixo, sua conta será excluída. Essa ação não pode ser desfeita.',
    placement: 'top',
    btn: (
      <Button danger onClick={deleteMe}>
        Excluir
      </Button>
    ),
  })
}

const StyledProfileNav = styled(NavLink)`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;

  color: ${(props) => props.theme.colors['black-70']};
  font-weight: bold;
  padding: 0.5rem 0.2rem;

  min-width: 10rem;
  width: 50%;

  border-radius: 10px;

  &.active {
    color: ${(props) => props.theme.colors['black-light']};
    background-color: ${(props) => props.theme.colors['white-cream']};
  }

  :hover {
    color: inherit;
  }
`

const NavIconWrapper = styled.div`
  padding: 0.2rem 0.1rem 0rem;
  border-radius: 7px;
  background-color: ${(props) => props.theme.colors.white};
`
