import React, { useRef, useState, useEffect } from 'react'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import * as S from './BaseCarousel.Styles'
import { BREAKPOINTS } from '../../../../styles/Themes/Admin/constants'

export function BaseCarousel({ children, slidesToShow, slidesToScroll, infinite = false, title, style, ...props }) {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideCount, setSlideCount] = useState(0)
  const [responsiveSettings, setResponsiveSettings] = useState({
    slidesToShow,
    slidesToScroll,
  })

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.next()
    }
  }

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.prev()
    }
  }

  const handleAfterChange = (current) => {
    setCurrentSlide(current)
  }

  const updateResponsiveSettings = () => {
    const width = window.innerWidth
    if (width >= BREAKPOINTS.xl) {
      setResponsiveSettings({
        slidesToShow: slidesToShow || 6,
        slidesToScroll: slidesToScroll || 6,
      })
    } else if (width >= BREAKPOINTS.md) {
      setResponsiveSettings({ slidesToShow: 3, slidesToScroll: 3 })
    } else if (width >= BREAKPOINTS.sm) {
      setResponsiveSettings({ slidesToShow: 2, slidesToScroll: 2 })
    } else {
      setResponsiveSettings({ slidesToShow: 1, slidesToScroll: 1 })
    }
  }

  useEffect(() => {
    updateResponsiveSettings()
    window.addEventListener('resize', updateResponsiveSettings)

    return () => {
      window.removeEventListener('resize', updateResponsiveSettings)
    }
  }, [slidesToShow, slidesToScroll])

  useEffect(() => {
    setSlideCount(sliderRef.current.innerSlider.props.children.length)
  }, [children])

  return (
    <header style={style}>
      <S.ArrowsContainer>
        <div style={{ width: '100%' }}>{title}</div>
        {/* Only show the arrow container if there are more slides than can be displayed */}
        {slideCount > responsiveSettings.slidesToShow && (
          <>
            <S.Arrow onClick={previous} disabled={!infinite && currentSlide === 0}>
              <LeftOutlined />
            </S.Arrow>
            <S.Arrow
              onClick={next}
              disabled={!infinite && currentSlide >= slideCount - responsiveSettings.slidesToShow}
            >
              <RightOutlined />
            </S.Arrow>
          </>
        )}
      </S.ArrowsContainer>
      <div className="slider-container">
        <S.CarouselWrapper
          {...props}
          dots={false}
          adaptiveHeight
          ref={sliderRef}
          afterChange={handleAfterChange}
          slidesToShow={responsiveSettings.slidesToShow}
          slidesToScroll={responsiveSettings.slidesToScroll}
          infinite={infinite}
        >
          {children}
        </S.CarouselWrapper>
      </div>
    </header>
  )
}
