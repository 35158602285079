import { useEffect } from 'react';
import { AboutSection, ContainerWrapper, ContentWrapper, InstitutionalHeaderTittle, Section } from './styles';

export function Institucional() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <ContentWrapper>
      <ContainerWrapper>
        <InstitutionalHeaderTittle>Institucional</InstitutionalHeaderTittle>

        <AboutSection>
          <h1>Sobre Nós</h1>
          <p>
            Em peregrinação a santuários católicos da Europa, especialmente os marianos,no ano de 1996, Kelly Patrícia e
            Jane Madeleine conheceram-se e, devido a uma grande identificação espiritual, iniciou-se uma amizade que as
            conduziu a rezarem, jejuarem e fazerem vigílias juntas. Foi durante esse período, de dezembro de 1996 a
            novembro de 1997 que, com fecundidade e clareza, Deus confirmou e amadureceu a fundação de uma nova forma de
            vida de oração e expiação pela Igreja, que já havia sido inspirada por Nossa Senhora, enquanto estavam em
            Mediugórie, através desta passagem do Diário de Santa Faustina: “Hoje vi o convento dessa nova Congregação
            (…) onde as almas isoladas do mundo arderão em sacrifício diante do trono de Deus e pedirão misericórdia
            para o mundo inteiro… e pedirão a bênção para os sacerdotes; por sua oração prepararão o mundo para a última
            vinda de Cristo” (D 1154 – 1158)
          </p>

          <p>
            Nascia o INSTITUTO HESED DAS IRMÃS DA SANTA CRUZ E DA BEM-AVENTURADA VIRGEM MARIA DO MONTE CARMELO, chamado
            de forma breve de Instituto Hesed (Misericórdia em hebraico) dentro do triênio preparatório ao grande
            Jubileu do ano 2000, e aos 04 de setembro de 2001 nascia o INSTITUTO HESED DOS IRMÃOS DA SANTA CRUZ E DA
            BEM-AVENTURADA VIRGEM MARIA DO MONTE CARMELO.
          </p>

          <p>
            Após a fundação a 30 de novembro de 1997, em janeiro de 1998, Irmã Kelly Patrícia e Irmã Jane Madeleine
            fazem o primeiro retiro onde lhes é inspirado o nome “HESED”. Toma posse, então, D. Cláudio Hummes como
            arcebispo de Fortaleza e as recebe em audiência, abençoa-as e autoriza-as a receber vocações.
          </p>

          <p>
            Aos 25 dias do mês de março de 1999, às 18 horas (dia da Encarnação do Verbo e tradicionalmente à mesma
            hora), chega o VERBO, o centro de tudo, Jesus no Santíssimo Sacramento, permanecendo conosco até hoje: é o
            primeiro milagre. Na ocasião, como a Arquidiocese estava sem bispo, o pároco nos manda ficar com JESUS,
            enquanto não saísse a permissão oficial do novo bispo. Chega, então, nosso caríssimo D. José Antônio, que
            nos concede uma audiência, abençoa-nos e, oportunamente, confere-nos, por escrito, a permissão da
            conservação das Sagradas Reservas.
          </p>

          <p>
            Ainda em 1999, após uma novena a Santa Teresinha, alcançamos a graça de ter em nossa Capela a Santa Missa
            diariamente. Eis aí o segundo acontecimento miraculoso: antes do término da referida novena e até a presente
            data, nenhum dia sequer deixou de haver Santa Missa nesta casa.
          </p>

          <p>
            Ao longo dos anos, as vocações foram chegando e em 4 de setembro de 2001 ingressa em nossa comunidade, o
            primeiro irmão.
          </p>

          <p>
            Seguem-se as fundações de Anápolis (GO), Itajubá (MG) e Teresina (PI) e a entrada de novos irmãos e irmãs.
          </p>

          <p>
            Hoje somos mais de 120 irmãos e irmãs! Nas cinco casas femininas e na casa masculina, buscamos viver o nosso
            carisma com alegria, irradiando para o mundo, por meio da oração, expiação e missão, o amor de Deus que é
            continuamente derramado em nossos corações.
          </p>
        </AboutSection>

        <Section className="contact">
          <h2>Entre em Contato</h2>
          <p>
            Instituto Hesed | Casa Mãe
            <br />
            Av. Dionisio Leonel Alencar, 1443
            <br />
            Parque Santa Maria
            <br />
            Cep.: 60873-073
            <br />
            Fortaleza – Ceará
            <br />
            (85) 3274 5767
            <br />
            <a href="mailto:contato@institutohesed.org.br">contato@institutohesed.org.br</a>
          </p>
        </Section>
      </ContainerWrapper>
    </ContentWrapper>
  );
}
