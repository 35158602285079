import { createGlobalStyle } from 'styled-components'

export const AlistamentoGlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :focus {
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

   body, input, textarea, button{
    font: 400 1rem Inter, sans-serif;
    background-color: #121924;
  }

  .header-pc {
    font-size: 25px !important;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  /* input */

  .ant-input{
    background-color:rgb(255, 255, 255) !important;
    border-radius: 10px;
    border: solid #C98F00 1px;
    height: 45px;

    &:hover{
      border-color: #c98f00 !important;
    }
  }

  .ant-input::placeholder {
    color: #909090 !important;
    font-weight: 400;
    font-family: Inter;
    line-height: 23px;
    font-style: italic;
    text-align: center; 
  }

  .ant-input:focus {
    border-color: #c98f00 !important;
    box-shadow:0 0 0 2px rgba(255, 174, 0, 0.2);
  }

  .ant-input:outline {
    border-color: #c98f00 !important;
  }

  /* btn */

  .ant-btn {
  border: solid #c98f00 0.5px;
  outline: solid #c98f00 1px;
  transition: all 0.3s ease;
  border-radius: 10px;
  color:rgb(255, 255, 255);
  background-color: #1c253a;
  height: 45px;

  &:hover {
    color:rgb(255, 255, 255);
    background-color:rgb(16, 21, 34);
    border: solid #c98f00 1px;
    transform: scale(1.02);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  }

  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }
}

  .ant-btn:focus {
    border-color: #c98f00 !important;
    color: #c98f00 !important;
    background-color:rgb(16, 21, 34);
  }

  /* steps */

  .ant-steps-item-icon{
    width: 38px !important;
    height: 38px !important;
  }
  
  .ant-steps-item-icon {
    background: #22344f !important;
    border-radius: 999px;

    svg {
      color: #1890ff;
      line-height: 24px;
    }
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: 18px;
    height: 120%;
    color: #2c4268;
  }

  .ant-steps-item-icon {
    background-color: #22344f;
    border-color: #c98f00;
  }
  .ant-steps-item-title {
    color: white !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon{
    border-color: #c98f00 !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
    color: #c98f00
  }

  .ant-steps-item-finish .ant-steps-item-title::after {
    background-color: #c98f00 !important;
  }

  /* form */

  .ant-form-item-required {
    color: white !important;
  }
  .ant-form-item-label {
    label{
      color: white !important;
    }
  }


  /* select */
  .ant-select-selector {
    border-radius: 10px !important;
    transition: all 0.2s ease-in-out;      
    border-color: #c98f00 !important;
    height: 45px  !important;

    &:hover {
      color: #c98f00 !important;
      border: solid #c98f00 1px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    }

    &:active {
      transform: scale(0.98);
      box-shadow: none;
    }

    &:focus {
      border-color: #c98f00 !important;
      color: #c98f00 !important;
      outline: 2px solid rgba(201, 143, 0, 0.6); /* Add an outline for focus accessibility */
      box-shadow: 0 0 4px rgba(201, 143, 0, 0.8); /* Slight glow effect */
    }

    .ant-select-selection-item{
      line-height: 42px !important;
    }
}

 /* password input */

.ant-input-password {
  border-radius: 10px !important;
  transition: all 0.2s ease-in-out;      
  border-color: #c98f00 !important;
  height: 45px  !important;
  }



  .ant-input {
    height: 45px;
  }

  .ant-input[type="password"] {  
    height: 38px;  
  }

  .ant-input#new_password {
    height: 38px;
  }

  .ant-input#password {
    height: 38px;
  }

  .ant-input#password {
    height: 38px;
  }

  .ant-input#login_password {
    height: 38px;
  }

  /* input number */

  .ant-input-number-input{
    height: 45px;
    border-radius: 10px !important;
    width: 100% !important;
  }

  .ant-input-number {
    height: 45px;
    border-radius: 10px !important;
    width: 100% !important;
  }

  .ant-result-title{
    color: white;
  }

  .ant-result-subtitle{
    color:rgb(175, 175, 175);
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`
