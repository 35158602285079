import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import styled from 'styled-components'
import { AnimatedBaseCard, ChartTitle, ErrorMessage, LoadingContainer, TooltipContainer } from '../../../styles'
import { formatNumber } from '../../../../../../../utility/utility'
import { DataService } from '../../../../../../../lib/dataService'

// Styled Components
const Container = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 30rem;
  height: 180px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const GenderContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
  gap: 1rem;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const Icon = styled.svg`
  width: 47.714px;
  height: 47.714px;
`

const TextContainer = styled.div`
  color: ${(props) => props.color};
`

const LineSeparator = styled.div`
  height: 1px;
  width: 100%;
  border: 0.5px solid var(--separator-color);
  margin-top: 1.7rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  /* margin: 3rem 0; */
  /* margin-top: 5;rem 0; */
`

const Separator = styled.div`
  height: 1px;
  width: 90%;
  border: 0.5px solid var(--separator-color);
  transform: rotate(-25deg);
  position: absolute;
  top: 6rem;
`

const NotIdentified = styled.div`
  color: var(--text-secondary-color);
  margin-top: 3.5rem;
  font-size: 0.8rem;
  display: grid;
  place-items: center;
`

const ChartContainer = styled.div`
  height: 300px;

  text {
    fill: var(--chart-pie-legend-color) !important;
  }
`

const useStatusMetrics = () => {
  return useQuery({
    queryKey: ['statusMetricDataGenres'],
    queryFn: async () => {
      const response = await DataService.get(`admin/dash-board/users/active-users`)
      return response?.data
    },
  })
}

const useProcessAgeData = () => {
  return useQuery({
    queryKey: ['processAgeData'],
    queryFn: async () => {
      const response = await DataService.get(`admin/dash-board/users/users-by-age`)
      const data = response.data.user_by_age

      return data.map((singleData) => ({
        key: singleData.age_range,
        Mulheres: singleData.female_count,
        MulheresColor: 'hsl(303, 43%, 68%)',
        HomensColor: 'var(--signup-chart-color)',
        Homens: singleData.male_count,
      }))
    },
  })
}

// Icon Component
const GenderIcon = ({ color }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.714 47.714" fill={color}>
    <path d="M46.4,35.605l.01-.01a35.668,35.668,0,0,0-15.277-7.248,10.4,10.4,0,0,0,3.157-7.472,2.236,2.236,0,0,0,2.237-2.237V17.147a2.223,2.223,0,0,0-.746-1.657V8.946A8.947,8.947,0,0,0,26.839,0H20.875a8.947,8.947,0,0,0-8.946,8.946v6.544a2.223,2.223,0,0,0-.746,1.657v1.491a2.236,2.236,0,0,0,2.237,2.237,10.4,10.4,0,0,0,3.158,7.472A35.671,35.671,0,0,0,1.3,35.595l.01.01A4.459,4.459,0,0,0,0,38.768v5.964a2.98,2.98,0,0,0,2.982,2.982h41.75a2.98,2.98,0,0,0,2.982-2.982V38.768A4.459,4.459,0,0,0,46.4,35.605ZM14.911,20.875v-.746a.745.745,0,0,0-.746-.746H13.42a.745.745,0,0,1-.746-.746V17.147a.745.745,0,0,1,.746-.746h.746a.745.745,0,0,0,.746-.746V14.165a2.982,2.982,0,0,1,2.982-2.982,4.466,4.466,0,0,0,3.728-2,4.466,4.466,0,0,0,3.728,2h4.473A2.982,2.982,0,0,1,32.8,14.165v1.491a.745.745,0,0,0,.746.746h.746a.745.745,0,0,1,.746.746v1.491a.745.745,0,0,1-.746.746h-.746a.745.745,0,0,0-.746.746v.746a8.946,8.946,0,1,1-17.893,0Zm3.195,8.706a10.421,10.421,0,0,0,11.5,0,34.127,34.127,0,0,1,3.647.808,13.311,13.311,0,0,1-18.828.009A34.079,34.079,0,0,1,18.105,29.581Z" />
  </Icon>
)

// Main Component
export const ByGenreAndAge = () => {
  const {
    data: statusMetricsData,
    isLoading: isStatusMetricsDataPending,
    isError: isStatusMetricsDataError,
  } = useStatusMetrics()

  const { data: processAgeData, isLoading: isProcessAgeDataPending, isError: isProcessDataError } = useProcessAgeData()

  if (isStatusMetricsDataPending || isProcessAgeDataPending) {
    return (
      <AnimatedBaseCard>
        <LoadingContainer />
      </AnimatedBaseCard>
    )
  }

  if (isStatusMetricsDataError || isProcessDataError) {
    return (
      <AnimatedBaseCard>
        <ErrorMessage />
      </AnimatedBaseCard>
    )
  }

  return (
    <AnimatedBaseCard padding="0px">
      <ChartTitle style={{ padding: '2rem' }}>Por Gênero</ChartTitle>
      <Container>
        <GenderContainer>
          <IconWrapper>
            <GenderIcon color="var(--signup-chart-color)" />
          </IconWrapper>
          <TextContainer color="var(--signup-chart-color)">
            <strong>{`${statusMetricsData?.percent_male_users}%`}</strong>
            <div>Masculino</div>
          </TextContainer>
        </GenderContainer>

        <GenderContainer alignRight>
          <IconWrapper>
            <GenderIcon color="#d08acd" />
          </IconWrapper>
          <TextContainer color="#d08acd">
            <strong>{`${statusMetricsData?.percent_female_users}%`}</strong>
            <div>Feminino</div>
          </TextContainer>
        </GenderContainer>

        <Separator />
      </Container>

      <NotIdentified>{statusMetricsData?.percent_null_sex_users}% Não Identificados</NotIdentified>

      <LineSeparator />

      <>
        <ChartTitle style={{ padding: '0 2rem' }}>Por Faixa Etária</ChartTitle>
        <ChartContainer>
          <ResponsiveBar
            data={processAgeData}
            keys={['Mulheres', 'Homens']}
            indexBy="key"
            margin={{ top: 25, right: 35, bottom: 70, left: 15 }}
            padding={0.7}
            colors={({ id, data }) => data[`${id}Color`] || 'gray'}
            borderRadius={2}
            borderWidth={1}
            borderColor="white"
            enableLabel={false}
            axisTop={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 50,
            }}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            tooltip={({ id, value }) => {
              return (
                <TooltipContainer gender={id} type="signup">
                  <strong>{formatNumber(value)} Cadastros</strong>
                </TooltipContainer>
              )
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"
            isInteractive
            animate
            motionConfig="gentle"
          />
        </ChartContainer>
      </>
    </AnimatedBaseCard>
  )
}
