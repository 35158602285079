import { notification } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { DataService } from '../../../../../../lib/dataService'

export interface CreatePreCadastroProps {
  nome: string
  email: string
  telefone: string
  genero: 'masculino' | 'feminino'
  pais: string
}

export function useCreatePreCadastro() {
  const createPreCadastro = useMutation({
    mutationFn: async ({ nome, email, telefone, genero, pais }: CreatePreCadastroProps) => {
      await DataService.post('vocacional/registrar-pre-cadastro', {
        nome,
        email,
        telefone,
        genero,
        pais,
      })
    },
    onError: (error: any) => {
      notification.error({
        message: 'Ocorreu um erro ao realizar o pré-cadastro',
        description: error?.response?.data?.error || 'Por favor, tente novamente.',
      })
    },
  })

  return createPreCadastro
}
