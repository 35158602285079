import { useQuery } from '@tanstack/react-query'

interface Message {
  key: string
  user: string
  type: string
  status: string
  time: string
}

export function useTodayMessages() {
  return useQuery<Message[]>({
    queryKey: ['today-messages'],
    queryFn: async () => {
      return [
        { key: '1', user: 'João Silva', type: 'Email', status: 'Enviado', time: '09:30' },
        { key: '2', user: 'Maria Santos', type: 'SMS', status: 'Entregue', time: '10:15' },
        { key: '3', user: 'Pedro Costa', type: 'Push', status: 'Lido', time: '11:45' },
        { key: '4', user: 'Ana Oliveira', type: 'WhatsApp', status: 'Enviado', time: '12:30' },
        { key: '5', user: 'Carlos Lima', type: 'Email', status: 'Lido', time: '13:15' }
      ]
    }
  })
} 