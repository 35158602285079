import { StyledButton } from '../../../components/Button'
import { StyledSpin } from '../../../components/Spin/styles'

interface StepsContentProps {
  isFirstStep: boolean
  isLastStep: boolean
  isPending: boolean
  next: Function
  prev: Function
  handleFinish: Function
}

export function StepsButtons({
  isFirstStep,
  isLastStep,
  next,
  prev,
  handleFinish,
  isPending,
}: StepsContentProps) {
  return (
    <div style={{ marginTop: 24, display: 'flex' }}>
      {!isFirstStep && (
        <StyledButton type="ghost" style={{ marginRight: 8 }} onClick={() => prev()}>
          Voltar
        </StyledButton>
      )}
      {!isLastStep && <StyledButton onClick={() => next()}>Próximo</StyledButton>}
      {isLastStep && (
        <StyledButton onClick={() => handleFinish()} disabled={isPending}>
          {isPending && <StyledSpin color='white' />} Concluir
        </StyledButton>
      )}
    </div>
  )
}
