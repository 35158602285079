import { Form } from 'antd'
import { BaseDivider } from '../../../../components/divider/BaseDivider'
import { FormInstance } from 'antd/es/form/Form'
import { ListaFormularioResponsavel } from '../components/ListaFormularioResponsavel'
import { ListaFormularioMembro } from '../components/ListaFormularioMembro'
import BaseForm from '../../../../components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../components/inputs/BaseInput/BaseInput'
import { AddressForm } from '../../../../components/forms/components/Address/AddressAdmin'
import { FormularioInformacoes } from './FormularioInformacoesFamilia'
import BaseCard from '../../../../components/card/BaseCard'
import { UploadFile } from 'antd/es/upload'

type FormularioCadastrarFamiliaProps = {
  form: FormInstance
  handleFormSubmit: (values: any) => void
}

export interface Responsavel {
  nome: string
  email: string
  telefone: string
  cpf: string
  data_nascimento: string
  funcao_familiar: string
  escolaridade: string
  ocupacao: string
  renda: number
}

export interface Membro {
  nome: string
  email: string
  telefone: string
  data_nascimento: string
  escolaridade: string
  ocupacao: string
  funcao_familiar: string
  cpf: string
}

export type EnderecoFormularioCadastroFamilia = {
  cep: string
  rua: string
  numero: string
  complemento?: string
  bairro: string
  cidade: string
  estado: string
  ponto_referencia: string
}

export type FormularioCadastroFamiliaData = {
  responsaveis: Responsavel[]
  cep: string
  rua: string
  numero: string
  complemento?: string
  bairro: string
  cidade: string
  estado: string
  ponto_referencia: string
  membros: Membro[]
  observacao?: string
  imagens_observacao?: UploadFile<any>[]
}

export function FormularioCadastrarFamilia({ form, handleFormSubmit }: FormularioCadastrarFamiliaProps) {
  return (
    <Form form={form} onFinish={(values) => handleFormSubmit(values)} layout="vertical">
      <BaseCard title={<h5>Endereço</h5>} size="small">
        <AddressForm form={form} />
        <BaseForm.Item name="ponto_referencia" label="Ponto de referência">
          <BaseInput placeholder="Local de referência" />
        </BaseForm.Item>
      </BaseCard>

      <BaseDivider />

      <FormularioInformacoes form={form} />

      <BaseDivider />

      <ListaFormularioResponsavel hasInitialValue />

      <BaseDivider />

      <ListaFormularioMembro hasInitialValue />
    </Form>
  )
}
