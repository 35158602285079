import { Col, Form, Input, Row } from 'antd'
import { StyledCard } from '../../../components/Card/styles'
import { StyledButton } from '../../../components/Button'
import { SaveOutlined } from '@ant-design/icons'
import { useFetchUserMe } from '../../../../admin/hooks'
import { useChangerUserPassword } from '../../../hooks/user/mutations/ChangePassword'
import { StyledInputPassword } from '../../../components/Inputs/styles'
import { validatePassword } from '../../../../../utility/utility'

export function SecurityView() {
  const [form] = Form.useForm()

  // const { data: userMe } = useFetchUserMe();

  const { mutateAsync: changePassword, isSuccess, isPending } = useChangerUserPassword()

  const handleSubmit = async (values: { senha_atual: string; nova_senha: string }) => {
    const payload = {
      new_password: values.nova_senha,
      old_password: values.senha_atual,
    }

    await changePassword({ payload })

    if (isSuccess) {
      form.resetFields()
    }
  }

  return (
    <StyledCard title="Segurança" style={{ minHeight: '80vh', height: '100%' }}>
      <Form className="add-record-form" style={{ width: '100%' }} layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <h3 style={{ color: 'gray' }}>Alterar Senha</h3>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="senha_atual"
              rules={[
                {
                  message: 'Por favor, insira sua senha atual!',
                  required: true,
                },
                { min: 8, message: 'Mínimo de 8 caracteres.' },
              ]}
              label="Senha atual"
            >
              <StyledInputPassword placeholder="" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }, { validator: validatePassword }]}
              label="Senha nova"
            >
              <StyledInputPassword placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xl={24} md={24} xs={24}>
            <Form.Item
              name="nova_senha"
              label="Repita a senha nova"
              dependencies={['password']}
              rules={[
                {
                  message: 'Por favor, repita sua nova senha!',
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('As senhas digitadas não coincidem!'))
                  },
                }),
              ]}
            >
              <StyledInputPassword placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <StyledButton htmlType="submit" icon={<SaveOutlined />} loading={isPending}>
          Salvar
        </StyledButton>
      </Form>
    </StyledCard>
  )
}
