import { css } from 'styled-components';

export const BaseSegmentedStyles = css`
  background-color: transparent;
  box-shadow: none;
  transition: none;

  animation: none;

  padding: 0 !important;

  &:hover {
    background-color: inherit !important;
  }

  .ant-segmented-thumb {
    background-color: inherit;
    box-shadow: inherit;
  }

  .ant-segmented-item.ant-segmented-item-selected {
    background-color: inherit;
    transform: scale(1);
    filter: brightness(0.9);
    box-shadow: none;
    border: none;
    transition: all 0.2s ease;
  }

  .ant-segmented-item:not(.ant-segmented-item-selected) {
    filter: brightness(1);
    transition: filter 0.2s ease;
    transform: scale(0.9);
  }

  .ant-segmented-group {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 579px) {
    .ant-segmented-item {
      width: 100%;
    }

    .ant-segmented-item.ant-segmented-item-selected {
      width: 100%;
    }
  }
`;
