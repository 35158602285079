import { Spin } from 'antd'
import styled from 'styled-components'

export const SectionTitle = styled.h3`
  margin-bottom: 2.5rem;
  font-family: montserrat;
  font-weight: bold;
  color: 24201b;
  text-align: center;
`

export const CenteredSpin = styled(Spin)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
`

export const MainContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1.3rem;
  min-height: 60vh;
`
