import { useState } from 'react'
import { Col, Row } from 'antd'
import BaseCard from '../../../components/card/BaseCard'
import BaseRadio from '../../../components/radio/BaseRadio'
import { handleFilterChangeAndPagination } from '../../../../../utility/utility'
import { useRedirectOnPermDenied } from '../../../hooks'
import { DonorClassificationFilters } from './components/filters'
import { TopDonorsCarousel } from './components/top-donors-carousel'
import { RankingDonor } from './components/ranking-donor'
import { RankingAssiduos } from './components/ranking-assiduos'

export type HandleFilterChangeData = {
  fk_campanhas_ids: any
  quantidade_meses: number | null
  tipo_doacao: any
}

interface FilterChangeProps {
  numMeses: number
  dados: HandleFilterChangeData
}

export default function MainDonors() {
  useRedirectOnPermDenied('doacoes')

  const [numMeses, setNumMeses] = useState(6)

  const [filters, setFilters] = useState({})

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  function handleFilterChange({ numMeses, dados }: FilterChangeProps) {
    setNumMeses(numMeses)

    handleInputChange(dados)
  }

  const realignCards = numMeses > 6

  return (
    <>
      <BaseCard
        title={<strong>Doadores Assíduos</strong>}
        extra={<DonorClassificationFilters handleFilterChange={handleFilterChange} />}
      >
        <TopDonorsCarousel filters={filters} />

        <Row justify="center" style={{ marginTop: '3rem' }}>
          <BaseRadio.Group
            defaultValue="frequencia"
            onChange={(e) => handleInputChange({ tipo_ordenacao: e.target.value })}
          >
            <BaseRadio.Button value="frequencia">Frequência</BaseRadio.Button>
            <BaseRadio.Button value="valor">Valor</BaseRadio.Button>
          </BaseRadio.Group>
        </Row>
      </BaseCard>

      <Row gutter={[20, 20]} style={{ marginTop: '1rem' }}>
        <Col xs={24} md={realignCards ? 24 : 12}>
          <BaseCard title="Ranking de doadores">
            <RankingDonor filters={filters} />
          </BaseCard>
        </Col>
        <Col xs={24} md={realignCards ? 24 : 12}>
          <BaseCard title="Doadores Assíduos">
            <RankingAssiduos filters={filters} />
          </BaseCard>
        </Col>
      </Row>
    </>
  )
}
