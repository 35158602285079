import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface FetchTopDonorsProps {
  filters?: {
    queryString?: string
    page?: number
    per_page?: number
    tipo_ordenacao?: 'frequencia' | 'valor'
    tipo_doacao?: 'avulsa' | 'recorrente'
    limite_top_doadores?: number
    fk_campanhas_ids?: string[]
    quantidade_meses?: number
  }
}

interface FetchTopDonorsResponse {
  top_doadores: {
    avatar: string
    fk_usuario_id: number
    nome: string
  }[]
}

export function useFetchTopDonors({ filters }: FetchTopDonorsProps) {
  const topDonors = useQuery({
    queryKey: ['TOP_DONORS', filters],
    queryFn: async () => {
      const response = await DataService.get<FetchTopDonorsResponse>(
        `/checkout/listar-top-doadores-assiduos?${filters?.queryString ?? ''}`,
      )
      return response?.data
    },
  })

  return topDonors
}
