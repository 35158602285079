import { Col, Row, TablePaginationConfig } from 'antd'

import { RecusadosFilters } from './filters'
import { ExcluirUsuarioVocacional } from '../../ExcluirUsuario'
import {
  GetAllRecusadosProps,
  GetAllRecusadosResponse,
} from '../../../../../hooks/vocacional/queries/useFetchGetAllRecusados'
import { getLabelFromCountryValue } from '../../../../../../../utility/utility'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'

export interface RecusadosTabelaProps {
  data?: GetAllRecusadosResponse
  handleInputChange: (filter: GetAllRecusadosProps['filters'], pagination?: boolean) => void
  isLoading: boolean
  isError: boolean
  filters: GetAllRecusadosProps['filters']
}

const EtapaMap = {
  pre_cadastro: 'Pré-Cadastro',
  cadastro: 'Cadastro',
  ficha_vocacional: 'Ficha Vocacional',
}

export function RecusadosTabela({ data, handleInputChange, isLoading, isError, filters }: RecusadosTabelaProps) {
  const dataSource = data?.recusados?.map((item) => {
    const { fk_usuario_vocacional_id, responsavel, nome, email, etapa, reprovado_em, pais, justificativa } = item

    return {
      id: fk_usuario_vocacional_id,
      nome,
      email,
      pais: getLabelFromCountryValue(pais),
      etapa: EtapaMap[etapa],
      reprovado_em,
      responsavel,
      justificativa: justificativa ?? ' - ',
      acoes: (
        <Row justify="center" gutter={[20, 20]}>
          <Col>
            <ExcluirUsuarioVocacional
              fk_usuario_vocacional_id={fk_usuario_vocacional_id}
              nome_usuario={nome}
              listagem="recusados"
            />
          </Col>
        </Row>
      ),
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      align: 'center',
      filterDropdown: () => <RecusadosFilters.Nome value={filters?.nome} handleInputChange={handleInputChange} />,
      filteredValue: filters?.nome && [filters.nome],
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      filterDropdown: () => <RecusadosFilters.Email value={filters?.email} handleInputChange={handleInputChange} />,
      filteredValue: filters?.email && [filters.email],
    },
    {
      title: 'País',
      dataIndex: 'pais',
      key: 'pais',
      align: 'center',
      filterDropdown: () => <RecusadosFilters.Pais value={filters?.pais} handleInputChange={handleInputChange} />,
      filteredValue: filters?.pais && [filters.pais],
    },
    {
      title: 'Etapa recusado',
      dataIndex: 'etapa',
      key: 'etapa',
      align: 'center',
      filterDropdown: () => <RecusadosFilters.Etapa value={filters?.etapa} handleInputChange={handleInputChange} />,
      filteredValue: filters?.etapa && [filters.etapa],
    },
    {
      title: 'Recusado em',
      dataIndex: 'reprovado_em',
      key: 'reprovado_em',
      align: 'center',
      filterDropdown: () => (
        <RecusadosFilters.RecusadoEm
          value={{ data_inicial: filters?.data_inicial ?? '', data_final: filters?.data_final ?? '' }}
          handleInputChange={handleInputChange}
        />
      ),
      filteredValue: filters?.data_inicial && filters?.data_final && [filters?.data_inicial, filters?.data_final],
    },
    {
      title: 'Responsável',
      dataIndex: 'responsavel',
      key: 'responsavel',
      align: 'center',
    },
    {
      title: 'Justificativa',
      dataIndex: 'justificativa',
      key: 'justificativa',
      align: 'center',
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      align: 'center',
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoading}
      isError={isError}
      pagination={{
        current: data?.page,
        total: data?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
          handleInputChange({ page: pagination.current }, true)
          handleInputChange({ per_page: pagination.pageSize }, true)
        }
      }}
    />
  )
}
