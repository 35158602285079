import { InputNumber as AntInputNumber } from 'antd'
import styled from 'styled-components'
import { BORDER_RADIUS } from '../../../../../styles/Themes/Admin/constants'

interface InputNumberProps {
  $block?: boolean
}

export const InputNumber = styled(AntInputNumber) <InputNumberProps>`
  ${(props) => props.$block && 'width: 100%'};

  color: var(--text-main-color) !important;
  background-color: var(--background-color) !important;
  border-color: var(--input-border-color);
  border-radius: ${BORDER_RADIUS};

  display: flex;
  justify-content: start;
  align-items: center;
  height: 2rem !important;

  &.ant-input-number-affix-wrapper {
    color: var(--text-main-color);
    background-color: inherit;
    height: 3rem !important;
  }

  &.ant-input-number-affix-wrapper:hover {
    border-color: var(--input-border-color) !important;
  }

  &.ant-input-number-affix-wrapper:focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  &.ant-input-number-disabled {
    opacity: 0.7;
  }
`
