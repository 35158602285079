import { BaseCarousel } from '@/container/admin/components/carousel/BaseCarousel'
import { GroupCard } from '@/container/admin/components/GroupCard'
import { EnvelopeSimple, Eye, X, Clock } from '@phosphor-icons/react'
import { useCarouselData } from '../../hooks/use-carousel-data'

export function AnimatedCarousel() {
  const { data, isLoading, isError } = useCarouselData()

  return (
    <BaseCarousel
      slidesToShow={4}
      slidesToScroll={1}
      infinite={false}
      title={<h2>Visão Geral</h2>}
      // style={{ margin: '2.5rem 0 5rem' }}
    >
      <GroupCard
        icon={EnvelopeSimple}
        title="Total Enviadas"
        number={data?.totalSent.toString()}
        isLoading={isLoading}
        isError={isError}
      />
      <GroupCard
        icon={Eye}
        title="Total Lidas"
        number={data?.totalRead.toString()}
        isLoading={isLoading}
        isError={isError}
      />
      <GroupCard
        icon={X}
        title="Ignoradas"
        number={data?.totalIgnored.toString()}
        isLoading={isLoading}
        isError={isError}
      />
      <GroupCard
        icon={Clock}
        title="Pendentes"
        number={data?.totalPending.toString()}
        isLoading={isLoading}
        isError={isError}
      />
    </BaseCarousel>
  )
} 