import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface MonthAverageDonationData {
  media_quant_mensal: number
  media_valor_mensal: string
}

export function useFetchMonthAverageDonation() {
  const monthAverageDonation = useQuery<MonthAverageDonationData>({
    queryKey: ['MONTH_AVERAGE_DONATION'],
    queryFn: async () => {
      const response = await DataService.get('/admin/dash-board/donations/doacoes-media-mensal')

      return response?.data
    },
  })

  return monthAverageDonation
}
