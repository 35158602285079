import { Row, Form, Typography } from 'antd'
import { useFetchUserAddress, useFetchUserMe, useFetchUserMeAddress } from '../../hooks'
import BaseButton from '../../components/button/BaseButton'
import BaseCard from '../../components/card/BaseCard'
import BaseSpin from '../../components/spin/BaseSpin'
import BaseForm from '../../components/forms/BaseForm/BaseForm'
import { useUpdateUserAddressMutation } from '../../hooks/users/Mutations/useUpdateUserAddress'
import { ErrorResult } from '../../components/result/resultStatus/ErrorResult'
import { AddressForm } from '../../components/forms/components/Address/AddressAdmin'

export const ProfileAddress = () => {
  const userMe = useFetchUserMe()

  const userAddress = useFetchUserMeAddress()

  const userId = userMe?.data?.id

  const updateUserMutation = useUpdateUserAddressMutation(userId)

  const [form] = Form.useForm()

  if (userAddress.isError) {
    return (
      <BaseCard style={{ height: '100%' }} title="Endereço do usuário">
        <ErrorResult />
      </BaseCard>
    )
  }

  if (userAddress.isLoading) {
    return (
      <BaseCard style={{ height: '100%', minHeight: '80vh' }} title="Endereço do usuário">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <BaseSpin />
        </div>
      </BaseCard>
    )
  }

  return (
    <BaseForm
      style={{ width: '100%' }}
      layout="vertical"
      form={form}
      name="addnew"
      onFinish={(values) => {
        const updatedValues = {
          ...values,
          company_id: 1,
          profile_id: userMe?.data?.fk_perfil_id,
        }
        updateUserMutation.mutate(updatedValues)
      }}
    >
      <BaseCard style={{ minHeight: '80vh' }} title="Endereço do usuário">
        <Typography.Title style={{ fontSize: '1.75rem', textAlign: 'center' }} level={2}>
          {userMe?.data?.nome}
        </Typography.Title>

        <AddressForm form={form} address={userAddress?.data} hasFormBeenFetchedFromApi={!!userAddress?.data} />

        <Row justify="center">
          <BaseButton htmlType="submit" loading={updateUserMutation.isPending}>
            Enviar
          </BaseButton>
        </Row>
      </BaseCard>
    </BaseForm>
  )
}
