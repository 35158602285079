import { CopyFilled } from '@ant-design/icons';
import { StyledButton } from '../../../../../components/Button';
import { PixText, PixTitle } from '../../../CampaignIdentification/styles';
import { usePaymentMethod } from '../../context';
import { useHistory } from 'react-router-dom';

export function BoletoPayment() {
  const history = useHistory();

  const { boletoData, copied, copyToClipboard } = usePaymentMethod();

  return (
    <>
      {!boletoData ? (
        <div
          style={{
            backgroundColor: '#f2f2f2',
            padding: '1rem',
            marginBottom: '1.5rem',
            borderRadius: '12px',
            border: 'solid #cccccc 2px',
            maxWidth: '700px',
          }}
        >
          <PixTitle>
            O BOLETO a ser gerado vence no dia {new Date(Date.now() + 7 * 864e5).toLocaleDateString()}
          </PixTitle>
          <PixText>
            Ao gerar o boleto, o instituto Hesed já está contando com o seu apoio. Pague até a data de vencimento pela
            internet, lotéricas, caixas eletrônicos ou agência bancária
          </PixText>
        </div>
      ) : (
        <div style={{ maxWidth: '900px', marginTop: '2rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

              backgroundColor: '#f2f2f2',
              padding: '1rem',
              marginBottom: '1.5rem',
              borderRadius: '12px',
              border: 'solid #cccccc 2px',
              maxWidth: '700px',
            }}
          >
            <div
              style={{
                padding: '0.5rem',
                backgroundColor: 'white',
                color: '#555555',
                width: '90%',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                border: 'solid black 1px',
                borderRadius: '8px',
                overflow: 'hidden',
                userSelect: 'none',
              }}
            >
              {boletoData?.dado_boleto?.dados_individuais_boleto[0].numero_linha_digitavel}
            </div>
            <StyledButton
              onClick={() =>
                copyToClipboard(boletoData?.dado_boleto?.dados_individuais_boleto[0].numero_linha_digitavel)
              }
              icon={<CopyFilled />}
              style={{ marginTop: '0.5rem', color: '#0f996a', width: '90%' }}
            >
              {copied ? 'Código Copiado!' : 'Copiar Código'}
            </StyledButton>
            <StyledButton
              icon={<CopyFilled />}
              style={{ marginTop: '0.5rem', color: '#0f996a', width: '90%' }}
              onClick={() => history.push('/boleto', { boletoData })}
            >
              Abrir Boleto
            </StyledButton>
          </div>
        </div>
      )}
    </>
  );
}
