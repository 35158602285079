import {
  getLabelFromCountryValue,
  handleCpfShowValues,
  handlePhoneNumberFormat,
} from '../../../../../../../utility/utility'
import BaseCard from '../../../../../components/card/BaseCard'
import { BaseDescription } from '../../../../../components/description/BaseDescription'
import { GetAllCadastroResponse } from '../../../../../hooks/vocacional/queries/useFetchGetAllCadastro'

interface ListaDetalhesCadastroProps {
  dados: GetAllCadastroResponse['cadastros_vocacionais'][number]
}

const StatusGenero = {
  masculino: 'Masculino',
  feminino: 'Feminino',
}

export function ListaDetalhesCadastro({ dados }: ListaDetalhesCadastroProps) {
  const usuarioBrasileiro = dados.pais === 'brasil'

  return (
    <BaseCard title={dados.nome}>
      <h3>Informações Pessoais</h3>
      <BaseDescription bordered column={{ xs: 1, md: 2 }} size="small">
        <BaseDescription.Item label="Documento de Identidade">
          {usuarioBrasileiro ? handleCpfShowValues(dados.documento_identidade) : dados.documento_identidade}
        </BaseDescription.Item>
        <BaseDescription.Item label="Email">{dados.email}</BaseDescription.Item>
        <BaseDescription.Item label="Telefone">
          {usuarioBrasileiro ? handlePhoneNumberFormat(dados.telefone) : dados.telefone}
        </BaseDescription.Item>
        <BaseDescription.Item label="Data de Nascimento">{dados.data_nascimento}</BaseDescription.Item>
        <BaseDescription.Item label="Gênero">{StatusGenero[dados.genero]}</BaseDescription.Item>
        <BaseDescription.Item label="Data de cadastro">{dados.created_at}</BaseDescription.Item>
      </BaseDescription>

      <h3 style={{ marginTop: '24px' }}>Endereço</h3>
      <BaseDescription bordered column={1} size="small">
        <BaseDescription.Item label="País">{getLabelFromCountryValue(dados.pais)}</BaseDescription.Item>

        {!usuarioBrasileiro ? (
          <BaseDescription.Item label={'ZipCode'}>{dados.detalhe_estrangeiro}</BaseDescription.Item>
        ) : (
          <BaseDescription.Item label="CEP">{dados.cep}</BaseDescription.Item>
        )}

        <BaseDescription.Item label="Estado">{dados.estado}</BaseDescription.Item>
        <BaseDescription.Item label="Bairro">{dados.bairro}</BaseDescription.Item>
        <BaseDescription.Item label="Cidade">{dados.cidade}</BaseDescription.Item>
        <BaseDescription.Item label="Rua">{dados.rua}</BaseDescription.Item>
        <BaseDescription.Item label="Número">{dados.numero}</BaseDescription.Item>
        <BaseDescription.Item label="Complemento">{dados.complemento}</BaseDescription.Item>
      </BaseDescription>
    </BaseCard>
  )
}
