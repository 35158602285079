import React from 'react'
import BaseModal from '../../../../components/modal/BaseModal'
import { BaseTable } from '../../../../components/table/BaseTable/BaseTable'
import BaseSwitch from '../../../../components/switch/BaseSwitch'
import { useUpdateProfilePermission } from '../../../../hooks/system/useUpdateProfilePermission'
import { useGetProfileById } from '../../../../hooks/system/useGetProfileById'

interface Permissions {
  acessar: boolean
  criar: boolean
  editar: boolean
  deletar: boolean
}

interface ProfilePermissionsTableModalProps {
  isProfilePermissionsTableModalOpen: boolean
  setIsProfilePermissionsTableModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedProfileId: number
}

export function ProfilePermissionsTableModal({
  isProfilePermissionsTableModalOpen,
  setIsProfilePermissionsTableModalOpen,
  selectedProfileId,
}: ProfilePermissionsTableModalProps) {
  const { data: selectedProfileData, isPending, isError } = useGetProfileById(selectedProfileId)
  const { mutateAsync: updatePermissions } = useUpdateProfilePermission(selectedProfileId)

  const renderSwitchColumn = (permission: keyof Permissions) => {
    return (data: Permissions & { fk_menu_id: number }) => {
      const hasFamilyAgapeMenu = selectedProfileData?.menus.some(
        (menu) => menu.fk_menu_id === data.fk_menu_id && menu.nome_menu === 'Família Ágape',
      )

      const disableFamilyPermission = selectedProfileData?.perfil.nome === 'Voluntario Agape' && hasFamilyAgapeMenu

      return (
        <BaseSwitch
          disabled={selectedProfileId === 1 || disableFamilyPermission} // admin && agape family perms should not be changed
          checked={data[permission]}
          onChange={async () => {
            updatePermissions({
              permission,
              fk_menu_id: data.fk_menu_id,
              value: !data[permission],
            })
          }}
        />
      )
    }
  }

  const dataSource =
    selectedProfileData?.menus?.map((menu) => ({
      nome: menu.nome_menu,
      acessar: { acessar: menu.acessar, fk_menu_id: menu.fk_menu_id },
      criar: { criar: menu.criar, fk_menu_id: menu.fk_menu_id },
      deletar: { deletar: menu.deletar, fk_menu_id: menu.fk_menu_id },
      editar: { editar: menu.editar, fk_menu_id: menu.fk_menu_id },
    })) || []

  const profilePermissionsTableColumns = [
    { title: 'Menu', dataIndex: 'nome', key: 'nome' },
    { title: 'Listar', dataIndex: 'acessar', key: 'acessar', render: renderSwitchColumn('acessar') },
    { title: 'Cadastrar', dataIndex: 'criar', key: 'criar', render: renderSwitchColumn('criar') },
    { title: 'Editar', dataIndex: 'editar', key: 'editar', render: renderSwitchColumn('editar') },
    { title: 'Excluir', dataIndex: 'deletar', key: 'deletar', render: renderSwitchColumn('deletar') },
  ]

  return (
    <BaseModal
      style={{ padding: '0.5rem' }}
      bodyStyle={{ height: 'auto', maxHeight: '75vh', overflow: 'auto' }}
      open={isProfilePermissionsTableModalOpen}
      closeIcon="X"
      width="70%"
      onOk={() => setIsProfilePermissionsTableModalOpen(false)}
      onCancel={() => setIsProfilePermissionsTableModalOpen(false)}
    >
      <BaseTable
        columns={profilePermissionsTableColumns}
        dataSource={dataSource}
        isPending={isPending}
        isError={isError}
      />
    </BaseModal>
  )
}
