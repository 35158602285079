import { Col, Row } from 'antd'
import { GroupCard } from '../../../../../components/GroupCard'
import { UsersFour } from '@phosphor-icons/react'
import { useGetEstatisticasFamilia } from '../../../../../hooks/agape/queries/useGetEstatisticasFamilia'
import { BlurDiv } from '@/context/Blur/BlurDiv'

export function CardsFamilia() {
  const {
    data: estatisticasFamilia,
    isLoading: isLoadingEstatisticasFamilia,
    isError: isErrorEstatisticasFamilia,
  } = useGetEstatisticasFamilia()

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} sm={12} md={6}>
        <GroupCard
          title="Renda média"
          icon={UsersFour}
          number={<BlurDiv>{estatisticasFamilia?.renda_media}</BlurDiv>}
          balance={undefined}
          isLoading={isLoadingEstatisticasFamilia}
          isError={isErrorEstatisticasFamilia}
        />
      </Col>

      <Col xs={24} sm={12} md={6}>
        <GroupCard
          title="Membros por família (média)"
          icon={UsersFour}
          number={estatisticasFamilia?.membros_por_familia}
          balance={undefined}
          isLoading={isLoadingEstatisticasFamilia}
          isError={isErrorEstatisticasFamilia}
        />
      </Col>

      <Col xs={24} sm={12} md={6}>
        <GroupCard
          title="Famílias ativas"
          icon={UsersFour}
          number={estatisticasFamilia?.familias_ativas}
          balance={undefined}
          isLoading={isLoadingEstatisticasFamilia}
          isError={isErrorEstatisticasFamilia}
        />
      </Col>

      <Col xs={24} sm={12} md={6}>
        <GroupCard
          title="Famílias inativas"
          icon={UsersFour}
          number={estatisticasFamilia?.familias_inativas}
          balance={undefined}
          isLoading={isLoadingEstatisticasFamilia}
          isError={isErrorEstatisticasFamilia}
        />
      </Col>
    </Row>
  )
}
