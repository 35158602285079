import { SlideProps } from "../types"

interface CarouselTabButtonsProps {
  activeTab: number
  slides: SlideProps[]
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
}

export function CarouselTabButtons({ activeTab, setActiveTab, slides }: Readonly<CarouselTabButtonsProps>) {
  return (
    <div className="tab-buttons">
      {slides.map((_, index) => {
        return (
          <button key={index} onClick={() => setActiveTab(index)} className={activeTab === index ? 'active' : ''} />
        )
      })}
    </div>
  )
}