import styled from 'styled-components'
import { Steps as AntdSteps } from 'antd'

export const StyledSteps = styled(AntdSteps)`
  .ant-steps-item-wait {
    svg,
    .ant-steps-item-title {
      color: var(--text-secondary-color) !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-title,
    svg {
      color: var(--text-main-color) !important;
      font-weight: normal;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-title,
    svg {
      color: var(--text-main-color) !important;
      font-weight: bold;
    }
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--text-main-color) !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--text-secondary-color) !important;
  }

  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--error-color);
  }
`
