import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface LembretesEfetivosCardProps {
  qtd_doacoes: number
  qtd_doadores: number
  valor_total: number
}

export function useFetchRememberRecurrenceCard() {
  const rememberRecurrenceCard = useQuery<LembretesEfetivosCardProps>({
    queryKey: ['REMEMBER_RECURRENCE_CARD'],
    queryFn: async () => {
      const response = await DataService.get<LembretesEfetivosCardProps>(`/checkout/card-lembretes-efetivos`)

      return response?.data
    },
  })

  return rememberRecurrenceCard
}
