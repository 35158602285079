import { useState } from 'react'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import { HistoricoMovimentacoesFilters } from './filters'
import {
  GetHistoricoMovimentacoesProps,
  useGetHistoricoMovimentacoes,
} from '../../../../../hooks/agape/queries/useGetHistoricoMovimentacoes'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import { IndicadorMovimentacao } from './styles'

export function HistoricoMovimentacoes() {
  const [filters, setFilters] = useState<GetHistoricoMovimentacoesProps['filters']>({})

  const {
    data: historico,
    isLoading: isLoadingHistorico,
    isError: isErrorHistorico,
  } = useGetHistoricoMovimentacoes({ filters })

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const dataSource = historico?.movimentacoes.map((itemHistorico) => {
    const { id, item, quantidade, data_movimentacao, tipo_movimentacao } = itemHistorico

    const movimentacaoEntrada = tipo_movimentacao === 'entrada'

    return {
      key: id,
      id,
      item,
      data_movimentacao,
      quantidade: (
        <IndicadorMovimentacao entrada={movimentacaoEntrada}>
          {quantidade} {movimentacaoEntrada ? <CaretUpFilled /> : <CaretDownFilled />}
        </IndicadorMovimentacao>
      ),
    }
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      filterDropdown: () => (
        <HistoricoMovimentacoesFilters.Nome
          value={filters?.fk_estoque_agape_id}
          handleInputChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
      filterDropdown: () => (
        <HistoricoMovimentacoesFilters.TipoMovimentacao
          value={filters?.tipo_movimentacao}
          handleInputChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Data',
      dataIndex: 'data_movimentacao',
      key: 'data_movimentacao',
      filterDropdown: () => (
        <HistoricoMovimentacoesFilters.Data
          value={{
            data_movimentacao_inicial: filters?.data_movimentacao_inicial ?? '',
            data_movimentacao_final: filters?.data_movimentacao_final ?? '',
          }}
          handleInputChange={handleInputChange}
        />
      ),
    },
  ]

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingHistorico}
      isError={isErrorHistorico}
      pagination={{
        current: historico?.page,
        total: historico?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: { current: number; pageSize: number }) => {
        handleInputChange({ page: pagination.current }, true)
        handleInputChange({ per_page: pagination.pageSize }, true)
      }}
    />
  )
}
