import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

interface GetItensCicloProps {
  fk_instancia_acao_agape_id: number
}

export interface GetItensCicloResponse {
  itens_ciclo_agape: {
    fk_item_instancia_agape_id: number
    item: string
    quantidade: number
  }[]
}

export function useGetItensCiclo({ fk_instancia_acao_agape_id }: GetItensCicloProps) {
  const itensEstoqueCiclo = useQuery({
    queryKey: ['ITENS_CICLO_ACAO_DOACAO', fk_instancia_acao_agape_id],
    queryFn: async () => {
      const response = await DataService.get<GetItensCicloResponse>(
        `/agape/buscar-itens-ciclo-acao-agape/${fk_instancia_acao_agape_id}`,
      )

      return response.data
    },
    enabled: !!fk_instancia_acao_agape_id,
  })

  return itensEstoqueCiclo
}
