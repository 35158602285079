import BaseCard from '../../../../../components/card/BaseCard'
import BaseRadio from '../../../../../components/radio/BaseRadio'
import { BaseSelect, Option } from '../../../../../components/select/BaseSelect'
import { CiclosDoacoesStatus } from '../../../../../hooks/agape/queries/useGetCiclosDoacoes'
import { useListarNomeAcoesSolidarias } from '../../../../../hooks/agape/queries/useListarNomeAcoesSolidarias'

interface NomeFilterProps {
  value?: number | null
  handleInputChange: ({ fk_acao_agape_id }: { fk_acao_agape_id: number }) => void
}

function NomeFilter({ value, handleInputChange }: NomeFilterProps) {
  const { data: acoesSolidarias } = useListarNomeAcoesSolidarias()

  return (
    <BaseCard>
      <h4>Nome</h4>

      <BaseSelect
        placeholder="Nome da ação solidária"
        value={value}
        onChange={(value) => handleInputChange({ fk_acao_agape_id: value })}
        allowClear
      >
        {acoesSolidarias?.acoes_agape.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.nome}
          </Option>
        ))}
      </BaseSelect>
    </BaseCard>
  )
}

interface StatusFilterProps {
  value?: CiclosDoacoesStatus | null
  handleInputChange: ({ status }: { status: CiclosDoacoesStatus }) => void
}

function StatusFilter({ value, handleInputChange }: StatusFilterProps) {
  return (
    <BaseCard>
      <h4>Status</h4>
      <BaseRadio.Group value={value} onChange={(e) => handleInputChange({ status: e.target.value })}>
        <BaseRadio.Button value="nao_iniciado">Não iniciado</BaseRadio.Button>
        <BaseRadio.Button value="em_andamento">Em andamento</BaseRadio.Button>
        <BaseRadio.Button value="finalizado">Finalizado</BaseRadio.Button>
        <BaseRadio.Button>X</BaseRadio.Button>
      </BaseRadio.Group>
    </BaseCard>
  )
}

export const CiclosDeDoacaoFilters = {
  Nome: NomeFilter,
  Status: StatusFilter,
}
