import { Col, FormInstance, Row } from 'antd'
import { useEffect, useState } from 'react'
import { fillAddressFromCep } from '../../../../../../benfeitor/pages/Campaign/CampaignIdentification/utils'
import { DataService } from '../../../../../../../lib/dataService'
import { formatCEP } from '../../../../../../../utility/utility'
import BaseForm from '../../../../../components/forms/BaseForm/BaseForm'
import { BaseInput } from '../../../../../components/inputs/BaseInput/BaseInput'
import ReactInputMask from 'react-input-mask'

interface AddressAutoComplete {
  bairro: string
  cidade: string
  estado: string
  rua: string
}

interface IAddressForm extends AddressAutoComplete {
  numero: number | string
  complemento?: string
  cep: string
}

interface AddressFormProps {
  form: FormInstance
  address?: IAddressForm
}

export function EnderecoCicloDoacao({ address, form }: AddressFormProps) {
  const [disabledFields, setDisabledFields] = useState<string[]>([])

  const handleCepChange = async () => {
    fetchAddress()
  }

  const fetchAddress = async () => {
    try {
      const { filledFields } = await fillAddressFromCep({ form })
      setDisabledFields(filledFields)
    } catch (error) {
      console.error('Error fetching address from CEP:', error)
    }
  }

  async function disableFieldsComingFromApi() {
    const { data: addressAutoComplete }: { data: AddressAutoComplete } = await DataService.get(
      `/services/search-cep/${form.getFieldValue('cep')}`,
    )
    disableFields(addressAutoComplete)
  }

  function disableFields(address: AddressAutoComplete) {
    const filledFields = Object.keys(address).filter((key) => address[key as keyof AddressAutoComplete])

    setDisabledFields(filledFields)
  }

  useEffect(() => {
    disableFieldsComingFromApi()

    if (address) {
      form.setFieldsValue({
        bairro: address.bairro,
        cep: formatCEP(address.cep),
        cidade: address.cidade,
        complemento: address.complemento,
        estado: address.estado,
        numero: address?.numero,
        rua: address?.rua,
      })

      disableFields(address)
    }
  }, [form, address])

  useEffect(() => {
    const cep = form.getFieldValue('cep')
    if (cep) {
      disableFieldsComingFromApi()
    }
  }, [form.getFieldValue('cep')])

  return (
    <Col xs={24}>
      <Col xs={24}>
        <Row justify="center">
          <BaseForm.Item
            name="cep"
            label="CEP"
            rules={[
              { required: true, message: 'Por favor, insira seu CEP!' },
              {
                pattern: /^[0-9]{5}-[0-9]{3}$/,
                message: 'Formato inválido! Utilize 00000-000.',
              },
            ]}
          >
            <ReactInputMask mask="99999-999" maskChar={null} placeholder="00000-000" onChange={handleCepChange}>
              {(inputProps) => <BaseInput {...inputProps} />}
            </ReactInputMask>
          </BaseForm.Item>
        </Row>
      </Col>

      <Row gutter={[20, 20]}>
        <Col xs={8}>
          <BaseForm.Item
            name="estado"
            label="Estado"
            rules={[{ required: true, message: 'Por favor, insira seu estado!' }]}
          >
            <BaseInput placeholder="Apenas texto" disabled={disabledFields.includes('estado')} />
          </BaseForm.Item>
        </Col>

        <Col xs={8}>
          <BaseForm.Item
            name="cidade"
            label="Cidade"
            rules={[{ required: true, message: 'Por favor, insira sua cidade!' }]}
          >
            <BaseInput placeholder="Apenas texto" disabled={disabledFields.includes('cidade')} />
          </BaseForm.Item>
        </Col>

        <Col xs={8}>
          <BaseForm.Item
            name="bairro"
            label="Bairro"
            rules={[{ required: true, message: 'Por favor, insira seu bairro!' }]}
          >
            <BaseInput placeholder="Apenas texto" disabled={disabledFields.includes('bairro')} />
          </BaseForm.Item>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={8}>
          <BaseForm.Item name="rua" label="Rua" rules={[{ required: true, message: 'Por favor, insira sua rua!' }]}>
            <BaseInput placeholder="Logradouro" disabled={disabledFields.includes('rua')} />
          </BaseForm.Item>
        </Col>
        <Col xs={8}>
          <BaseForm.Item name="numero" label="Número">
            <BaseInput placeholder="000" />
          </BaseForm.Item>
        </Col>

        <Col xs={8}>
          <BaseForm.Item name="complemento" label="Complemento">
            <BaseInput placeholder="Casa ou apt" />
          </BaseForm.Item>
        </Col>
      </Row>
    </Col>
  )
}
