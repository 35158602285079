import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetAcoesSolidariasProps {
  filters?: {
    queryString?: string | null
    fk_acao_agape_id?: number | null
    data_cadastro_inicial?: string | null
    data_cadastro_final?: string | null
    page?: number
    per_page?: number
  }
}

interface GetAcoesSolidariasResponse {
  acoes_agape: [
    {
      ciclos_finalizados: number
      data_cadastro: string
      id: number
      nome: string
    },
    {
      ciclos_finalizados: number
      data_cadastro: string
      id: number
      nome: string
    },
  ]
  page: number
  total: number
}

export function useGetAcoesSolidarias({ filters }: GetAcoesSolidariasProps) {
  const acoesSolidarias = useQuery({
    queryKey: ['ACOES_SOLIDARIAS', filters],
    queryFn: async () => {
      const response = await DataService.get<GetAcoesSolidariasResponse>(
        `agape/listar-acoes-agape?${filters?.queryString ?? ''}`,
      )

      return response.data
    },
  })

  return acoesSolidarias
}
