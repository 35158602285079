import { ComposedCard } from '@/container/admin/components/composed-card'
import { FilterIcon } from '@/container/admin/components/FilterIcon'
import { BlurDiv } from '@/context/Blur/BlurDiv'
import moment from 'moment'
import { useState } from 'react'
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { formatNumber } from '../../../../../../../utility/utility'
import { SliderChartTitle, TooltipContainer } from '../../../styles'

export function Chart({ data, filters, handleToggleModal }) {
  const [barHovered, setBarHovered] = useState(false)
  const [focusBar, setFocusBar] = useState(null)

  const formattedData = Array.isArray(data)
    ? data.map((item) => ({
      hora: `${item.hora}:00`,
      valor: parseFloat(item.total_cadastros),
    }))
    : []

  const CustomTooltip = ({ payload }) => {
    const valor = payload?.[0]?.payload?.valor
    const hora = payload?.[0]?.payload?.hora

    return (
      <TooltipContainer type="signup">
        <strong>{String(hora).padStart(5, '0')}</strong>
        <strong>{formatNumber(valor)}</strong>
      </TooltipContainer>
    )
  }

  const handleChartTitle = () => {
    if (filters?.data_inicio) {
      const isToday = moment().isSame(filters?.data_inicio, 'day')

      return isToday ? 'de Hoje' : `${moment(filters?.data_inicio).format('DD/MM/YYYY')}`
    }

    return 'de Hoje'
  }

  return (
    <>
      {/* <ChartTitle>Cadastros {handleChartTitle()}</ChartTitle> */}
      <SliderChartTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <div className="title-and-icon-container">
              Cadastros {handleChartTitle()}
              <FilterIcon onClick={handleToggleModal} />
            </div>
            <BlurDiv className="percentage">
              <ComposedCard.Balance isPositive={false} >
                29.65%
              </ComposedCard.Balance>
            </BlurDiv>
          </div>
          <BlurDiv className="total-value">{formatNumber(6920)}</BlurDiv>
        </div>
      </SliderChartTitle>
      <div style={{ width: '100%', height: '260px' }}>
        <ResponsiveContainer width="100%" height="100%">
          {data?.length === 0 ? (
            <div style={{ height: '20rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4 style={{ margin: '0', color: 'gray', padding: 30, textAlign: 'center' }}>
                Por enquanto não há dados a serem carregados
              </h4>
            </div>
          ) : (
            <BarChart data={formattedData || []} margin={{ top: 80, left: 0, right: 0, bottom: 5 }}>
              {/* <CartesianGrid stroke="#444" vertical={false} strokeOpacity={0.6} /> */}
              {/* <XAxis
                axisLine={false}
                dataKey="hora"
                tick={{ fill: 'var(--signup-chart-color)', fontWeight: 'bold' }}
                orientation="top"
                tickMargin={15}
              /> */}
              {/* <YAxis axisLine={false} tick={{ fill: '#888888' }} tickFormatter={formatChartNumber} tickMargin={15} /> */}

              <Tooltip cursor={false} active={barHovered} content={<CustomTooltip />} />

              <Bar
                dataKey="valor"
                radius={[8, 8, 0, 0]}
                barSize={40}
                onMouseEnter={() => setBarHovered(true)}
                onMouseLeave={() => setBarHovered(false)}
              >
                {formattedData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={focusBar === index ? 'url(#signupGradientOnHover)' : 'url(#signupGradient)'}
                    onMouseEnter={() => setFocusBar(index)}
                    onMouseLeave={() => setFocusBar(null)}
                  />
                ))}
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </>
  )
}
