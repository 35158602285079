import { useQuery } from '@tanstack/react-query';
import { DataService } from '../../../../../lib/dataService';
import { Campaign } from '../../../interfaces/campaign';

export function useGetPublicCampaigns() {
  interface CampaignsReq {
    campanhas: Campaign[];
    page: number;
    total: number;
  }

  return useQuery<CampaignsReq>({
    queryKey: ['campaigns_home'],
    queryFn: async () => {
      const response = await DataService.get<CampaignsReq>('campaigns/campanhas-publicas');
      return response.data;
    },
  });
}
