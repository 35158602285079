import { useState } from 'react';
import { FiltersModal } from './FiltersModal';
import { Content } from './Content';
import { AnimatedBaseCard } from '../../../styles';
import { getCurrentMonthQueryString } from '../../../../users/Imported/utils'

export function DonationsByUserType() {
  const initialQueryString = getCurrentMonthQueryString();
  const [filters, setFilters] = useState(initialQueryString);

  const [campaignsOptions, setCampaignsOptions] = useState([]);

  return (
    <AnimatedBaseCard>
      <FiltersModal filters={filters} setFilters={setFilters} campaignsOptions={campaignsOptions} />
      <Content filters={filters} setCampaignsOptions={setCampaignsOptions} />
    </AnimatedBaseCard>
  );
}
