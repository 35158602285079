import { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { AlistamentoSmLandingPage } from '../../container/alistamento-sm/pages/Home'
import { AlistamentoSmTheme } from '../../styles/Themes/AlistamentoSm/alistamento-sm-theme'
import { AlistamentoGlobalStyles } from '../../styles/Themes/AlistamentoSm/alistamento-global-styles'
import { MemberLandingPage } from '../../container/alistamento-sm/pages/member-landing-page'
import { PreBecomeGeneralForm } from '../../container/alistamento-sm/pages/pre-become-general-form'
import { BecomeGeneralForm } from '../../container/alistamento-sm/pages/form-general'
import { WaitForApprove } from '../../container/alistamento-sm/pages/wait-for-approve'
import { CreateGroupVideo } from '../../container/alistamento-sm/pages/create-group-video'
import { AlistamentoSmProvider } from '../../container/alistamento-sm/contexts/alistamento-sm-context'
import { CheckForGroup } from '../../container/alistamento-sm/pages/check-for-group'
import { ValidateUser } from '../../container/alistamento-sm/pages/validate-user'
import { SignUpPage } from '../../container/alistamento-sm/pages/sign-up'
import { Spin } from '../../container/benfeitor/components/Spin'
import { RecoverPassword } from '../../container/alistamento-sm/pages/recover-password'
import { ResetPassword } from '../../container/alistamento-sm/pages/reset-password'

export function AlistamentoSmRoutes() {
  return (
    <ThemeProvider theme={AlistamentoSmTheme}>
      <AlistamentoGlobalStyles />
      <Suspense fallback={<Spin />}>
        <Switch>
          <Route exact path="/membro-exercito/" component={AlistamentoSmLandingPage} />
          <Route path="/membro-exercito/validar-usuario" component={ValidateUser} />
          <Route path="/membro-exercito/cadastro-usuario" component={SignUpPage} />
          <Route path="/membro-exercito/aguardar-aprovacao" component={WaitForApprove} />
          <AlistamentoSmProvider>
            <Route exact path="/membro-exercito/landing-page-membros" component={MemberLandingPage} />
            <Route exact path="/membro-exercito/pre-tornar-se-general-form" component={PreBecomeGeneralForm} />
            <Route path="/membro-exercito/tornar-se-general-form" component={BecomeGeneralForm} />
            <Route path="/membro-exercito/checar-grupo" component={CheckForGroup} />
            <Route path="/membro-exercito/criar-grupo-video" component={CreateGroupVideo} />
            <Route path="/membro-exercito/recuperar-senha" component={RecoverPassword} />
            <Route path="/membro-exercito/redefinir-senha/:token" component={ResetPassword} />
          </AlistamentoSmProvider>
        </Switch>
      </Suspense>
    </ThemeProvider>
  )
}
