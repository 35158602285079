import styled from 'styled-components';

interface EmbedWrapperProps {
  iframeStyles: {
    labelColor: string;
  };
}

export const Wrapper = styled.div<EmbedWrapperProps>`
  body,
  p,
  div,
  label {
    color: ${({ iframeStyles }) => iframeStyles.labelColor};
  }
`;
