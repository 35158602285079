import { useQuery } from '@tanstack/react-query'
import { DataService } from '../../../../../lib/dataService'

export interface GetAllDesistenciasProps {
  filters?: {
    queryString?: string | null
    telefone?: string
    nome?: string
    email?: string
    data_inicial?: string
    data_final?: string
    etapa?: 'pre_cadastro' | 'cadastro' | 'ficha_vocacional'
    pais?: string
    page?: number
    per_page?: number
  }
}

export interface GetAllDesistenciasResponse {
  desistencias: {
    fk_usuario_vocacional_id: number
    desistencia_em: string
    email: string
    etapa: 'pre_cadastro' | 'cadastro' | 'ficha_vocacional'
    nome: string
    pais: string
    telefone: string
  }[]
  page: number
  total: number
}

export function useFetchGetllAllDesistencias({ filters }: GetAllDesistenciasProps) {
  const allDesistencia = useQuery({
    queryKey: ['GET_ALL_DESISTENCIA', filters],
    queryFn: async () => {
      const response = await DataService.get<GetAllDesistenciasResponse>(
        `/vocacional/listar-desistencias-vocacionais?${filters?.queryString ?? ''}`,
      )
      return response.data
    },
  })

  return allDesistencia
}
