import React from 'react'
import { FilterOutlined, FilterFilled } from '@ant-design/icons'
import styled from 'styled-components'

export const StyledFilterIcon = ({ ...props }) => {
  return (
    <StyledFilterIconContainer {...props}>
      {props?.isFilterActive ? <FilterFilled /> : <FilterOutlined />}
    </StyledFilterIconContainer>
  )
}

const StyledFilterIconContainer = styled.div`
  /* position: absolute; */
  /* top: 30px; */
  /* right: 30px; */
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  svg {
    font-size: 1rem;
    color: var(--filter-icon-color);
  }

  &.absolute {
    position: absolute;
    top: 50px;
    right: 30px;
    z-index: 999;
  }
`

const InlineFilterIconContainer = styled(StyledFilterIconContainer)`
  /* position: static; */
  /* top: auto; */
  /* right: auto; */
  color: var(--text-secondary-color) !important;
  line-height: 0.4;
  z-index: 999;
`

export function FilterIcon({ onClick, absolute }) {
  return (
    <InlineFilterIconContainer
      onClick={onClick}
      className={absolute ? 'absolute' : ''}
    >
      <FilterOutlined />
    </InlineFilterIconContainer>
  )
}
