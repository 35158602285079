/* eslint-disable import/no-absolute-path */
import React from 'react'
import { useSelector } from 'react-redux'
import * as S from './SiderLogo.styles'

import darkLogoImage from '../../../../../../static/logo/dark/dark-logo-new.png'
import lightLogoImage from '../../../../../../static/logo/light/light-logo-new.png'

import { Link } from 'react-router-dom'

export const SiderLogo = () => {
  const theme = useSelector((state) => state.theme.theme)

  const logoImg = theme === 'dark' ? darkLogoImage : lightLogoImage

  return (
    <S.SiderLogoDiv>
      <Link to="/admin">
        <img src={logoImg} alt="Logo Acutis" width={50} height={50} />
      </Link>
    </S.SiderLogoDiv>
  )
}
