import { useQuery } from '@tanstack/react-query'
import { Perfil } from './useListAllProfiles'
import { DataService } from '../../../../lib/dataService'

interface BuscarPerfilResponse {
  menus: {
    acessar: boolean
    criar: boolean
    deletar: boolean
    editar: boolean
    fk_menu_id: number
    nome_menu: string
  }[]
  perfil: Perfil
}

export function useGetProfileById(selectedProfileId: number) {
  return useQuery({
    queryKey: ['buscar-perfil', selectedProfileId],
    queryFn: async (): Promise<BuscarPerfilResponse> => {
      const response = await DataService.get(`administradores/buscar-perfil/${selectedProfileId}`)
      return response?.data
    },
    enabled: !!selectedProfileId,
  })
}
