import styled from 'styled-components'
import { Input as AntdInput } from 'antd'
import { BORDER_RADIUS } from '../../../../../styles/Themes/Admin/constants'

// Estiliza o Input base
export const Input = styled(AntdInput)`
  color: var(--text-main-color) !important;
  background-color: var(--background-color) !important;
  border-color: var(--input-border-color);
  border-radius: ${BORDER_RADIUS};

  .ant-input-prefix {
    color: var(--text-main-color);
  }

  &.ant-input-affix-wrapper:hover {
    border-color: var(--input-border-color);
  }

  &.ant-input {
    color: var(--text-main-color);
    background-color: inherit;
    height: 3rem;
  }

  .ant-input:hover {
    border-color: var(--input-border-color);
  }

  .ant-input:focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  &.ant-input-affix-wrapper {
    border-radius: 4px;
    height: 3rem;
    transition: border-color 0.3s, box-shadow 0.3s;

    .ant-input {
      color: inherit;
      background-color: inherit;
    }
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  &:hover {
    border-color: var(--input-border-color);
  }

  &:active,
  &:focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 1px var(--input-border-color);
  }

  &[disabled],
  &.ant-input[disabled] {
    opacity: 0.7;
  }

  .ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-item {
        color: var(--text-secondary-color);
      }
    }
  }

  &.ant-input-group-wrapper {
    overflow: visible !important;
  }

  .ant-input-wrapper.ant-input-group {
    background-color: var(--button-bg-color);
    height: 3rem !important;

    @media (max-width: 519px) {
      display: block;
      text-align: left;
    }

    .ant-input-affix-wrapper {
      background-color: inherit;
    }

    .ant-input-group-addon {
      height: inherit !important;
      color: var(--button-text-color) !important;
      background-color: var(--button-bg-color) !important;

      @media (max-width: 768px) {
        font-size: 0.85rem;
      }

      @media (max-width: 576px) {
        font-size: 0.75rem;
      }

      @media (max-width: 519px) {
        font-size: 0.75rem;
      }

      @media (max-width: 400px) {
        font-size: 0.59rem;
      }
    }

    .ant-input {
      background-color: var(--background-color) !important;
      height: inherit !important;
      color: var(--text-main-color);
      border-color: var(--input-border-color);
    }
  }

  svg {
    color: var(--text-main-color);
  }
`
