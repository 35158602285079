import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import {
  AnimatedBaseCard,
  ErrorMessage,
  LoadingContainer,
  PieChartContainer,
  PieChartLegendColor,
  PieChartLegendContainer,
  PieChartLegendItem,
  PieChartTitle,
} from '../../../styles'
import { formatNumber } from '../../../../../../../utility/utility'
import { DataService } from '../../../../../../../lib/dataService'

export function Status() {
  const { data, isPending, isError } = useQuery({
    queryKey: ['statusMetricData'],
    queryFn: () =>
      DataService.get(`admin/dash-board/users/active-users`).then((response) => {
        const { data } = response
        const {
          users_active: activeUsers,
          user_inactive: inactiveUsers,
          percent_active_users,
          percent_inactive_users,
        } = data

        return {
          total: [percent_active_users, percent_inactive_users],
          ativos: activeUsers,
          data: [
            {
              id: 'status',
              data: [
                { x: 'Inativos', y: inactiveUsers },
                { x: 'Ativos', y: activeUsers },
              ],
            },
          ],
        }
      }),
    staleTime: 0,
    cacheTime: 0,
  })

  const pieChartData = data?.data?.[0]?.data?.map((item) => ({
    id: item.x,
    value: item.y,
  }))

  const [statusColor, setStatusColor] = useState()
  const [currentValueCenter, setCurrentValueCenter] = useState('carregando...')
  const [nodeId, setNodeId] = useState('Ativos')

  useEffect(() => {
    setCurrentValueCenter(data?.ativos)
  }, [data?.ativos])

  const renderCustomLegend = () => {
    return (
      <PieChartLegendContainer>
        {pieChartData.map((item, index) => (
          <PieChartLegendItem
            key={item.id}
            color={['var(--lighter-color-chart)', 'var(--signup-chart-color)'][index]}
            onMouseEnter={() => {
              setCurrentValueCenter(formatNumber(item.value))
              setStatusColor(['var(--lighter-color-chart)', 'var(--signup-chart-color)'][index])
              setNodeId(item.id)
            }}
          >
            <PieChartLegendColor color={['var(--lighter-color-chart)', 'var(--signup-chart-color)'][index]} />
            <span>{item.id}</span>
          </PieChartLegendItem>
        ))}
      </PieChartLegendContainer>
    )
  }

  const extendedConfig = {
    margin: { top: 30, right: 0, bottom: 30, left: 0 },
    startAngle: -90,
    endAngle: 270,
    innerRadius: 0.9,
    padAngle: 1,
    cornerRadius: 2,
    fit: false,
    activeOuterRadiusOffset: 12,
    colors: ['var(--lighter-color-chart)', 'var(--signup-chart-color)'],
    borderWidth: 1,
    borderColor: {
      from: 'color',
      modifiers: [['darker', '0.2']],
    },
    enableArcLinkLabels: false,
    enableArcLabels: false,
    tooltip: ({ datum }) => (
      <div
        style={{
          color: datum.color === 'black',
          background: datum.color,
          border: '1px solid #000000',
          padding: '10px 20px',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.64)',
          opacity: '0.9',
        }}
      >
        <div>
          <strong>{datum.label}: </strong>
          <strong>{datum.value} </strong>
        </div>
      </div>
    ),
    onMouseEnter: (node) => {
      setCurrentValueCenter(formatNumber(node.data.value))
      setStatusColor(node.color)
      setNodeId(node.id)
    },
    layers: [
      'arcs',
      'arcLabels',
      'arcLinkLabels',
      ({ centerX, centerY, radius }) => (
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{ fill: statusColor || 'var(--signup-chart-color)' }}
        >
          <tspan x={centerX} dy={-15} style={{ fontWeight: 'bold', fontSize: radius * 0.4 }}>
            {nodeId === 'Ativos' ? data.total?.[0]?.toFixed(2) : data?.total?.[1]?.toFixed(2)}%
          </tspan>
          <tspan x={centerX} dy={40} style={{ fontSize: radius * 0.25 }}>
            {formatNumber(currentValueCenter)}
          </tspan>
        </text>
      ),
    ],
  }

  return (
    <AnimatedBaseCard>
      {isPending ? (
        <LoadingContainer />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <>
          <PieChartTitle>Por Status</PieChartTitle>
          <PieChartContainer>
            <ResponsivePie {...extendedConfig} data={pieChartData} />
          </PieChartContainer>
          {renderCustomLegend()}
        </>
      )}
    </AnimatedBaseCard>
  )
}
