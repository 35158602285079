import { Col, Row } from 'antd'
import { SwitchGestao } from './SwitchGestao/SwitchGestao'
import { AdicionarVoluntario } from './AdicionarVoluntario/AdicionarVoluntario'
import { ListaVoluntarios } from './ListaVoluntarios'

export function GestaoPage() {
  return (
    <>
      <h2>Gestão</h2>
      <Row justify="center" gutter={[30, 20]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <SwitchGestao />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <AdicionarVoluntario />
        </Col>

        <Col xs={24}>
          <ListaVoluntarios />
        </Col>
      </Row>
    </>
  )
}
