import FeatherIcon from 'feather-icons-react'
import { Col, Row } from 'antd'
import moment from 'moment'
import { BaseInput } from '../../../../../inputs/BaseInput/BaseInput'
import { BaseSelect, Option } from '../../../../../select/BaseSelect'
import BaseDatePicker from '../../../../../datepicker/BaseDatePicker'
import { BaseAutoComplete } from '../../../../../autocomplete/BaseAutoComplete'
import { useFetchCampaignsOptions, useGetPaymentsGateway } from '../../../../../../hooks'
import BaseModal from '../../../../../modal/BaseModal'

function MetodoPagamentoFilter({ handleInputChange, value }) {
  return (
    <div>
      <h4>Método de pagamento</h4>
      <BaseSelect
        label="Método de pagamento"
        placeholder="Selecione o método"
        allowClear
        style={{ width: '100%' }}
        onChange={(value) => handleInputChange({ tipo_pagamento: value })}
        value={value}
      >
        <Option value="2">Pix</Option>
        <Option value="3">Boleto</Option>
        <Option value="1">Cartão de crédito</Option>
      </BaseSelect>
    </div>
  )
}

function CodigoReferenciaFilter({ handleInputChange, value }) {
  return (
    <div>
      <h4>Código de Referência</h4>
      <BaseInput
        allowClear
        style={{ width: '100%' }}
        label="Pesquise o código de referência"
        placeholder="Código de referência"
        prefix={<FeatherIcon icon="search" size={14} />}
        onChange={(e) => handleInputChange({ codigo_referencial: e.target.value })}
        value={value}
      />
    </div>
  )
}

function RecorrenciaFilter({ handleInputChange, value }) {
  return (
    <div>
      <h4>Recorrência</h4>
      <BaseSelect
        label="Recorrência"
        placeholder="Selecione a recorrência"
        style={{ width: '100%' }}
        allowClear
        onChange={(value) => handleInputChange({ recorrencia: value })}
        value={value}
      >
        <Option value="recorrente">Com Recorrência</Option>
        <Option value="nao_recorrente">Sem Recorrência</Option>
      </BaseSelect>
    </div>
  )
}

function TransactionFilter({ handleInputChange, value }) {
  return (
    <div>
      <h4>Transação</h4>
      <BaseInput
        style={{ width: '100%' }}
        allowClear
        placeholder="Id da transação"
        prefix={<FeatherIcon icon="search" size={14} />}
        onChange={(e) => handleInputChange({ transaction_id: e.target.value })}
        value={value}
      />
    </div>
  )
}

function NomeFilter({ handleInputChange, value, suggestions }) {
  const options = Array.from(new Set(suggestions?.map((item) => item.user_name))).map((nomeUnico) => ({
    value: nomeUnico,
  }))

  return (
    <div>
      <h4>Nome, E-mail ou CPF</h4>

      <BaseAutoComplete
        value={value}
        options={options}
        style={{ width: '100%' }}
        onChange={(text) => handleInputChange({ filter_multiple: text })}
        placeholder="Nome, E-mail ou CPF"
        allowClear
      />
    </div>
  )
}

function TipoDoacaoFilter({ handleInputChange, value }) {
  return (
    <div>
      <h4>Tipo de doação</h4>
      <BaseSelect
        label="Tipo de Doação"
        placeholder="Selecione o tipo"
        allowClear
        style={{ width: '100%' }}
        onChange={(value) => handleInputChange({ doacao_anonima: value })}
        value={value}
      >
        <Option value="1">Anônima</Option>
        <Option value="0">Identificada</Option>
      </BaseSelect>
    </div>
  )
}

function DataHoraFilter({ handleInputChange, value }) {
  const formattedValue = [
    value.data_inicial ? moment(value.data_inicial, 'YYYY-MM-DDTHH:mm:ss') : null,
    value.data_final ? moment(value.data_final, 'YYYY-MM-DDTHH:mm:ss') : null,
  ]

  return (
    <div>
      <h4>Data e hora</h4>
      <BaseDatePicker
        value={formattedValue}
        range
        showTime
        style={{ width: '100%' }}
        onChange={(dates, dateStrings) => {
          const formattedStartDate = moment(dateStrings[0], 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss') || ''
          const formattedEndDate = moment(dateStrings[1], 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss') || ''

          handleInputChange({
            data_inicial: formattedStartDate !== 'Data inválida' ? formattedStartDate : '',
            data_final: formattedEndDate !== 'Data inválida' ? formattedEndDate : '',
          })
        }}
        format="DD/MM/YYYY HH:mm"
        placeholder={['Inicial', 'Final']}
      />
    </div>
  )
}

function StatusProcessamentoFilter({ handleInputChange, value }) {
  return (
    <div>
      <h4>Status</h4>
      <BaseSelect
        placeholder="Selecione o status"
        style={{ width: '100%' }}
        allowClear
        value={value}
        onChange={(value) => handleInputChange({ status: value })}
      >
        <Option value="0">Em Processamento</Option>
        <Option value="1">Pago</Option>
        <Option value="2">Não Efetuado</Option>
        <Option value="3">Expirado</Option>
      </BaseSelect>
    </div>
  )
}

function GatewayFilter({ handleInputChange, value }) {
  const { data: paymentsGateway } = useGetPaymentsGateway()

  return (
    <div>
      <h4>Gateway</h4>
      <BaseSelect
        label="Gateway"
        placeholder="Selecione o Gateway"
        allowClear
        style={{ width: '100%' }}
        onChange={(value) => handleInputChange({ fk_gateway_pagamento_id: value })}
        value={value}
        options={paymentsGateway?.map((option) => ({
          label: option.descricao,
          value: option.id,
        }))}
      />
    </div>
  )
}

function CampanhasFilter({ handleInputChange, campaignsData, value }) {
  const { data: campaignsOptions } = useFetchCampaignsOptions()
  return (
    <div>
      <h4>Campanha</h4>
      <BaseSelect
        showSearch
        label="Campanha"
        placeholder="Selecione a campanha"
        allowClear
        style={{ width: '100%' }}
        onChange={(value) => handleInputChange({ campanha_id: value })}
        value={value}
      >
        {campaignsOptions?.map((c, key) => {
          return (
            <Option value={c.value} key={key}>
              {c.label}
            </Option>
          )
        })}
      </BaseSelect>
    </div>
  )
}

export const DonationTableFilters = {
  MetodoPagamento: MetodoPagamentoFilter,
  CodigoReferencia: CodigoReferenciaFilter,
  Recorencia: RecorrenciaFilter,
  Transaction: TransactionFilter,
  NomeAutoComplete: NomeFilter,
  TipoDoacao: TipoDoacaoFilter,
  DataHora: DataHoraFilter,
  StatusProcessamento: StatusProcessamentoFilter,
  Gateway: GatewayFilter,
  Campanhas: CampanhasFilter,
}

export const DonationTableFiltersModal = ({
  open,
  handleClose,
  filters,
  handleInputChange,
  visibleFilters,
  dataSource,
}) => {
  return (
    <BaseModal title="Filtrar" open={open} footer={null} width={1200} onCancel={handleClose}>
      <Row gutter={[30, 25]} justify="center">
        {visibleFilters.includes('nome') && (
          <>
            <Col xs={24} md={8}>
              <DonationTableFilters.NomeAutoComplete
                suggestions={dataSource}
                value={filters?.filter_multiple}
                handleInputChange={handleInputChange}
              />
            </Col>
            <Col xs={24} md={8}>
              <DonationTableFilters.TipoDoacao value={filters.doacao_anonima} handleInputChange={handleInputChange} />
            </Col>
          </>
        )}
        {visibleFilters.includes('forma_pagamento') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.MetodoPagamento
              value={filters?.tipo_pagamento}
              handleInputChange={handleInputChange}
            />
          </Col>
        )}
        {visibleFilters.includes('status') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.StatusProcessamento
              handleInputChange={handleInputChange}
              value={filters?.status}
            />
          </Col>
        )}

        {visibleFilters.includes('codigo_referencia') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.CodigoReferencia
              value={filters?.codigo_referencial}
              handleInputChange={handleInputChange}
            />
          </Col>
        )}

        {visibleFilters.includes('transaction_id') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.Transaction handleInputChange={handleInputChange} value={filters?.transaction_id} />
          </Col>
        )}
        {visibleFilters.includes('gateway') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.Gateway
              handleInputChange={handleInputChange}
              value={filters?.fk_gateway_pagamento_id}
            />
          </Col>
        )}
        {visibleFilters.includes('data') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.DataHora
              value={{ data_inicial: filters?.data_inicial, data_final: filters?.data_final }}
              handleInputChange={handleInputChange}
            />
          </Col>
        )}

        {visibleFilters.includes('campanha') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.Campanhas handleInputChange={handleInputChange} value={filters?.campanha_id} />
          </Col>
        )}

        {visibleFilters.includes('recorrencia') && (
          <Col xs={24} md={8}>
            <DonationTableFilters.Recorencia value={filters?.recorrencia} handleInputChange={handleInputChange} />
          </Col>
        )}
      </Row>
    </BaseModal>
  )
}
