import { useParams } from 'react-router-dom';
import { Title } from '../../../components/Title';
import { CadastroForm } from './CadastroForm';

type CadastroVocacionalUseParams = {
  token: string;
};

export function CadastroVocacional() {
  const { token } = useParams<CadastroVocacionalUseParams>();

  return (
    <>
      <Title>Cadastro Vocacional</Title>
      <CadastroForm token={token} />
    </>
  );
}
