import styled from 'styled-components'
import { Select as AntSelect } from 'antd'
import { BORDER_RADIUS } from '../../../../styles/Themes/Admin/constants'

export const Select = styled(AntSelect)`
  width: ${(props) => props.$width || 'auto'};
  min-width: 160px;
  box-shadow: ${(props) => (props.$shadow ? '0px 2px 8px rgba(0, 0, 0, 0.15)' : 'none')}; // Adiciona sombra condicional
  border-radius: ${BORDER_RADIUS} !important;

  .ant-select-selector {
    border-radius: ${BORDER_RADIUS} !important;
    min-height: 3rem !important;
    line-height: 3rem !important;
    background-color: var(--background-color) !important;
    color: var(--text-main-color);
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 0px var(--input-border-color) !important;

    &:hover {
      border-color: var(--input-border-color) !important;
      box-shadow: 0 0 0 1px var(--input-border-color) !important;
    }
  }

  &.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open {
    border-color: var(--input-border-color) !important;
    box-shadow: 0 0 0 1px var(--input-border-color) !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    font-size: 14px; // Ajusta o tamanho da fonte dos itens selecionados
    color: var(--text-main-color); // Cor do texto dos itens selecionados (no select fechado)
    display: flex;
    align-items: center;
    line-height: 3rem !important;
  }

  .ant-select-arrow {
    color: var(--text-main-color); // Cor da seta
  }

  .ant-select-dropdown {
    border-radius: 4px; // Ajusta o border-radius do dropdown
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); // Adiciona sombra ao dropdown
    background-color: var(--background-color); // Cor de fundo do dropdown

    .ant-select-item-option-content {
      color: var(--text-main-color); // Cor do texto dos itens
    }

    .ant-select-item-option-disabled {
      .ant-select-item-option-content {
        color: var(--text-secondary-color);
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-selected) {
      background-color: var(
        --text-secondary-color
      ); // Cor de fundo ao passar o mouse (HOVER) em um item não selecionado
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
      background-color: var(--button-bg-color);
      .ant-select-item-option-content {
        color: var(--button-text-color);
      }
    }
  }

  .ant-select-clear {
    background-color: var(--background-color) !important; // Cor de fundo do botão de fechar

    svg {
      color: var(--text-main-color) !important; // Cor do ícone
    }
  }

  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      color: var(--button-text-color);
      background-color: var(--button-bg-color);
      font-weight: bold;
    }

    .anticon-close {
      color: var(--button-text-color);
    }
  }

  .ant-select-item-option-state {
    .anticon-check {
      color: var(--button-text-color);
    }
  }

  &.ant-select-disabled {
    opacity: 0.7;
  }
`
