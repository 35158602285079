/* eslint-disable react/destructuring-assignment */
import { Spin } from 'antd';
import { Wrapper } from './Wrapper';
import ActionEmbedForm from './ActionEmbedForm';

const ActionEmbed = (url) => {
  const actionId = url.match.params.id;
  // const actionName = url.match.params.name;
  const queryParams = new URLSearchParams(url.location.search);

  const bgColor = queryParams.get('bg-color');
  const labelColor = queryParams.get('label-color');
  const sucessoColor = queryParams.get('sucesso-color');

  document.body.style.backgroundColor = `${bgColor || 'transparent'}`;

  const iframeStyles = {
    labelColor: labelColor || 'white',
    sucessoColor: sucessoColor || 'green',
  };

  return (
    <>
      <Wrapper iframeStyles={iframeStyles}>
        {false && (
          <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
            <Spin />
          </div>
        )}
        {true && <ActionEmbedForm origem={actionId} />}
        {false && 'Erro'}
      </Wrapper>
    </>
  );
};

export default ActionEmbed;
