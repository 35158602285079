import { Col, Row } from 'antd'
import { ListagemMembrosFamilia } from '../../../../membros/listagem/ListagemMembrosFamilia'
import { useGetCardRendaFamiliar } from '../../../../../../../../hooks/agape/queries/useGetCardRendaFamiliar'
import { UsersFour } from '@phosphor-icons/react'
import { GroupCard } from '../../../../../../../../components/GroupCard'
import { BlurDiv } from '@/context/Blur/BlurDiv'

interface MembrosFamiliaModalProps {
  idFamilia: number
}

export function MembrosFamiliaModal({ idFamilia }: MembrosFamiliaModalProps) {
  const {
    data: rendaFamiliar,
    isLoading: isLoadingRendaFamiliar,
    isError: isErrorRendaFamiliar,
  } = useGetCardRendaFamiliar({ fk_familia_agape_id: idFamilia })

  return (
    <>
      <Row style={{ marginBottom: '1rem', gap: '0.5rem' }}>
        <Col style={{ border: '1px solid var(--input-border-color)', borderRadius: '16px' }}>
          <GroupCard
            title="Renda familiar"
            icon={UsersFour}
            number={<BlurDiv>{rendaFamiliar?.renda_familiar}</BlurDiv>}
            balance={undefined}
            isLoading={isLoadingRendaFamiliar}
            isError={isErrorRendaFamiliar}
          />
        </Col>

        <Col style={{ border: '1px solid var(--input-border-color)', borderRadius: '16px' }}>
          <GroupCard
            title="Renda per capta"
            icon={UsersFour}
            number={<BlurDiv>{rendaFamiliar?.renda_per_capta}</BlurDiv>}
            balance={undefined}
            isLoading={isLoadingRendaFamiliar}
            isError={isErrorRendaFamiliar}
          />
        </Col>
      </Row>

      <Col xs={24}>
        <ListagemMembrosFamilia idFamilia={idFamilia} />
      </Col>
    </>
  )
}
