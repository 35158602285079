import { useState } from 'react'
import { BaseTable } from '../../../../../components/table/BaseTable/BaseTable'
import { getActions } from './CiclosDeDoacaoAcoes/getActions'
import { getColumns } from './utils/getColumns'
import { GetCiclosDoacoesProps, useGetCiclosDoacoes } from '../../../../../hooks/agape/queries/useGetCiclosDoacoes'
import { handleFilterChangeAndPagination } from '../../../../../../../utility/utility'
import { statusMap } from './utils/statusMap'
import { EnderecoBeneficiarios } from './EnderecoBeneficiarios'
import { Row } from 'antd'

export function CiclosDeDoacao() {
  const [filters, setFilters] = useState<GetCiclosDoacoesProps['filters']>({})

  const {
    data: ciclosDoacoes,
    isLoading: isLoadingCiclosDoacoes,
    isError: isErrorCiclosDoacoes,
  } = useGetCiclosDoacoes({ filters })

  const handleInputChange = handleFilterChangeAndPagination({ setFilters })

  const dataSource = ciclosDoacoes?.ciclos.map((item) => {
    const { id, nome_acao_agape, status } = item

    return {
      id: id,
      status: statusMap[status],
      acao_solidaria: nome_acao_agape,
      acoes: getActions({
        nomeAcao: nome_acao_agape,
        actionId: id,
        status: statusMap[status],
      }),
      localizacao: (
        <Row justify="center">
          <EnderecoBeneficiarios idCicloDoacao={id} />
        </Row>
      ),
    }
  })

  const columns = getColumns({ filters, handleInputChange })

  return (
    <BaseTable
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoadingCiclosDoacoes}
      isError={isErrorCiclosDoacoes}
      pagination={{
        current: ciclosDoacoes?.page,
        total: ciclosDoacoes?.total,
        showSizeChanger: true,
      }}
      onChange={(pagination: { current: number; pageSize: number }) => {
        handleInputChange({ page: pagination.current }, true)
        handleInputChange({ per_page: pagination.pageSize }, true)
      }}
    />
  )
}
