import { Avatar, Layout } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

const HEADER_HEIGHT = '80px';

export const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors['gray-800']};
  padding: 0 30px;
  height: ${HEADER_HEIGHT};

  @media (max-width: 786px) {
    padding: 0 10px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 35%;
    height: ${HEADER_HEIGHT};
    background: ${(props) => props.theme.colors['gray-500']};
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);

    @media (max-width: 786px) {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  width: 30%;

  @media (max-width: 786px) {
    width: 35%;
  }

  img {
    height: 60px;

    @media (max-width: 786px) {
      height: 50px;
    }
  }
`;

export const HeaderAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.colors['gray-500']};
`;

export const ActionsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const HeaderButton = styled.button`
  background-color: transparent;
  padding: 0.5rem 0.8rem;
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors['gray-200']};
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s;

  @media (max-width: 576px) {
    font-size: 12px;
  }

  :hover {
    background-color: ${(props) => props.theme.colors['gray-500']};
    border-color: transparent;
    cursor: pointer;
  }
`;
